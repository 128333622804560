import { useParams } from 'react-router'
import {
  AmazonPpcCampaignType,
  Marketplace,
  SponsoredBrandsCampaignAdFormat,
} from '../../../generated/graphql'
import { getMaximumBid } from '../helpers'

export const useDefaultMaximumBid = (
  campaignType: AmazonPpcCampaignType,
  adFormat?: SponsoredBrandsCampaignAdFormat | undefined
) => {
  const params = useParams<{ marketplace: string }>()
  const marketplace = params.marketplace?.toUpperCase()! as Marketplace
  return getMaximumBid(marketplace, campaignType, adFormat)
}
