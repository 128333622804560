import { Button, Collapse, CollapseProps, Space, Typography } from 'antd'
import { Storage } from 'aws-amplify'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../config/i18n'

export const BrandManagementCollapse = () => {
  const { t } = useTranslation()

  const items: CollapseProps['items'] = [
    {
      key: 'templates',
      label: t('settings.brand.collapse.templates.label'),
      children: (
        <>
          <Typography.Paragraph>
            {t('settings.brand.collapse.templates.description1')}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('settings.brand.collapse.templates.description2')}
          </Typography.Paragraph>
          <Typography.Paragraph strong>
            {t('settings.brand.collapse.templates.templates')}
          </Typography.Paragraph>
          <Space>
            <Button onClick={() => downloadTemplate('Varento')}>Varento</Button>
            <Button onClick={() => downloadTemplate('SPACEGOATS_US')}>SPACEGOATS US</Button>
          </Space>
        </>
      ),
    },
    {
      key: 'guide',
      label: t('settings.brand.collapse.guide.label'),
      children: (
        <>
          <Typography.Paragraph>
            {t('settings.brand.collapse.guide.description')}
          </Typography.Paragraph>
          <Button onClick={() => downloadGuide()}>
            {t('settings.brand.collapse.guide.button')}
          </Button>
        </>
      ),
    },
  ]

  const downloadTemplate = async (amazonAccount: 'Varento' | 'SPACEGOATS_US') => {
    try {
      const key = `letter-of-authorizations/LOA_Template_${amazonAccount}.docx`

      const file = await Storage.get(key, {
        bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME_TEMPLATES,
      })

      window.open(file, '_blank')
    } catch (error) {
      console.error(error)
    }
  }

  const downloadGuide = async () => {
    try {
      const languageCode = i18n.language
      const key = `amazon-brand-registry/amazon_brand_registry_guide_${languageCode}.pdf`

      const file = await Storage.get(key, {
        bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME_TEMPLATES,
      })

      window.open(file, '_blank')
    } catch (error) {
      console.error(error)
    }
  }

  return <Collapse items={items} />
}
