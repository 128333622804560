import { useMutation, useQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import { Button, Col, Flex, Row } from 'antd'
import ButtonGroup from 'antd/es/button/button-group'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'sonner'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import {
  GetWholesaleOrderTemplateDocument,
  GetWholesaleOrderTemplateQuery,
  UpdateWholesaleOrderTemplateDocument,
} from '../../generated/graphql'
import { WholesaleOrderWarehouseDetailsCard } from './components/WholesaleOrderDetails/WholesaleOrderWarehouseDetailsCard'
import { WholesaleOrderTemplateGeneralDetailsCard } from './components/WholesaleOrdersTemplates/WholesaleOrderTemplateGeneralDetailsCard'
import { WholesaleOrderTemplateLineItemsTable } from './components/WholesaleOrdersTemplates/WholesaleOrderTemplateLineItemsTable'

export type WholesaleOrderTemplate = GetWholesaleOrderTemplateQuery['wholesaleOrderTemplate']
type WholesaleOrderTemplateLineItem =
  GetWholesaleOrderTemplateQuery['wholesaleOrderTemplate']['lineItems'][number]

export const WholesaleOrderTemplateDetailsView = () => {
  const { identifier } = useParams<{
    identifier: string
  }>()
  const { t } = useTranslation(['wholesale-order', 'translation'])
  const theme = useTheme()

  const [editable, setEditable] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [modifiedRows, setModifiedRows] = useState<FormattedWholesaleOrderTemplateLineItem[]>([])

  const {
    data,
    error,
    loading: loadingData,
    refetch,
  } = useQuery(GetWholesaleOrderTemplateDocument, {
    skip: !identifier,
    fetchPolicy: 'cache-first',
    variables: { identifier: identifier! },
  })

  const [updateWholesaleOrderTemplate] = useMutation(UpdateWholesaleOrderTemplateDocument, {
    onCompleted: async () => {
      await refetch()
    },
  })

  useEffect(() => {
    if (data) {
      setModifiedRows(
        prepareWholesaleOrderTemplateLineItems(data.wholesaleOrderTemplate?.lineItems)
      )
    } else {
      setModifiedRows([])
    }
  }, [data])

  if (loadingData) {
    return <LoadingSpinner />
  }

  if (error || !data?.wholesaleOrderTemplate) {
    return (
      <StatusResult status="500" title="500" subTitle={t('translation:shared.error.message')} />
    )
  }

  const handleOnSave = async () => {
    if (editable && identifier) {
      setLoading(true)
      try {
        await updateWholesaleOrderTemplate({
          variables: {
            identifier: identifier,
            input: {
              lineItems: modifiedRows.map((row) => ({
                identifier: row.identifier,
                defaultOrderQuantity: row.defaultOrderQuantity,
                productVariantUuid: row.productVariant.uuid,
              })),
            },
          },
        })
        toast.success(t('wholesaleOrdersTemplatesList.notification.updateSuccess'))
      } catch (error) {
        console.error(error)
        toast.error(t('wholesaleOrdersTemplatesList.notification.updateError'))
      } finally {
        setEditable(false)
        setLoading(false)
        setModifiedRows([])
      }
    } else {
      setEditable(true)
    }
  }

  return (
    <>
      <SubHeader
        heading={data?.wholesaleOrderTemplate?.identifier || ''}
        withBackButton
        onBackUrl="/wholesale/orders?view=templates"
        rightContent={
          <Flex gap={2}>
            {editable ? (
              <ButtonGroup>
                <Button
                  onClick={handleOnSave}
                  loading={loading}
                  style={{ color: theme.colors.success }}
                >
                  {t('wholesaleOrderDetailsView.buttons.saveChanges')}
                </Button>
                <Button onClick={() => setEditable(false)} style={{ color: theme.colors.error }}>
                  {t('wholesaleOrderDetailsView.buttons.cancel')}
                </Button>
              </ButtonGroup>
            ) : (
              <Button onClick={() => setEditable(true)}>
                {t('wholesaleOrderDetailsView.buttons.edit')}
              </Button>
            )}
          </Flex>
        }
      />
      <ViewContainer>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12} style={{ display: 'grid', gridTemplateRows: '1fr' }}>
            <WholesaleOrderTemplateGeneralDetailsCard
              wholesaleOrderTemplateDetails={data.wholesaleOrderTemplate}
            />
          </Col>
          <Col xs={24} xl={12} style={{ display: 'grid', gridTemplateRows: '1fr' }}>
            <WholesaleOrderWarehouseDetailsCard wholesaleOrder={data.wholesaleOrderTemplate} />
          </Col>
          <Col span={24}>
            <WholesaleOrderTemplateLineItemsTable
              lineItems={modifiedRows}
              editable={editable}
              setModifiedRows={setModifiedRows}
            />
          </Col>
        </Row>
      </ViewContainer>
    </>
  )
}

const prepareWholesaleOrderTemplateLineItems = (
  lineItems: WholesaleOrderTemplateLineItem[] | null
): FormattedWholesaleOrderTemplateLineItem[] => {
  if (!lineItems?.length) {
    return []
  }

  return lineItems.map((lineItem) => {
    const skus: string[] = []
    const fnskus: string[] = []

    lineItem.productVariant.amazonProducts?.forEach((amazonProduct) => {
      amazonProduct.amazonListings?.forEach((amazonListing) => {
        if (amazonListing.sku && !skus.includes(amazonListing.sku)) {
          skus.push(amazonListing.sku)
        }
        if (amazonListing.fnsku && !fnskus.includes(amazonListing.fnsku)) {
          fnskus.push(amazonListing.fnsku)
        }
      })
    })

    return {
      identifier: lineItem.identifier,
      defaultOrderQuantity: lineItem.defaultOrderQuantity,
      productVariant: {
        uuid: lineItem.productVariant.uuid,
        identifier: lineItem.productVariant.identifier,
        internalSku: lineItem.productVariant.internalSku,
        ean: lineItem.productVariant.ean,
        netPurchasePrice: lineItem.productVariant.netPurchasePrice,
        asins:
          lineItem.productVariant.amazonProducts?.map((amazonProduct) => amazonProduct.asin) || [],
        attributes:
          lineItem.productVariant.attributes?.map(
            (attribute) => `${attribute.option}: ${attribute.value}`
          ) || [],
        product: {
          name: lineItem.productVariant.product.name,
        },
        skus,
        fnSkus: fnskus,
      },
    }
  })
}

export type FormattedWholesaleOrderTemplateLineItem = {
  identifier: string
  defaultOrderQuantity: number
  productVariant: {
    uuid: string
    identifier: string
    internalSku: string | null | undefined
    netPurchasePrice: number | null | undefined
    ean: string | null | undefined
    asins: string[]
    attributes: string[]
    product: {
      name: string
    }
    skus: string[]
    fnSkus: string[]
  }
}
