import { useMutation, useQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import {
  CellValueChangedEvent,
  ColDef,
  GetRowIdParams,
  GridApi,
  ICellRendererParams,
  IRowNode,
  RowSelectionOptions,
  SelectionColumnDef,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Badge, Button, Flex, Space, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { CircleAlert, Pen, Plus, Save, Trash } from 'lucide-react'
import { forwardRef, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { FormattedDate } from '../../../components/FormattedDate'
import { Table } from '../../../components/Table/Table'
import {
  CreateServiceInvoiceLineItemDocument,
  Currency,
  GetAllActiveCompaniesDocument,
  GetCompanyDocument,
  GetInvoiceArticlesDocument,
  ServiceInvoiceLineItemPaymentStatus,
  UpdateServiceInvoiceLineItemDocument,
} from '../../../generated/graphql'
import { formatMoney } from '../../../helpers/formatMoney'
import { formatPercentage } from '../../../helpers/formatPercentage'
import { dateComparator, dateFilterParams, findInsertionIndex } from '../../../helpers/tableHelpers'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { TablePersistKey } from '../../../stores/useTableStore'
import { InvoiceLineItemRecord } from './InvoiceLineItemsView'

type InvoiceLineItemsTableProps = {
  lineItems: InvoiceLineItemRecord[]
  onSaveSuccess?: () => Promise<void>
  onSelectionChanged?: () => void
}

type ActionHeaderProps = {
  onAddEmptyRow: () => void
  onSaveNewRows: () => void
  api: GridApi<InvoiceLineItemRecord> | undefined
}

const currencyOptions = Object.values(Currency).map((value) => ({
  value,
  label: value,
}))

const paymentStatusOptions = Object.values(ServiceInvoiceLineItemPaymentStatus).map((value) => ({
  value,
  label: value,
}))

export const InvoiceLineItemsTable = forwardRef<
  AgGridReact<InvoiceLineItemRecord>,
  InvoiceLineItemsTableProps
>(({ lineItems: initialLineItems, onSaveSuccess, onSelectionChanged }, gridRef) => {
  const { t } = useTranslation(['translation', 'invoices'])
  const [rowData, setRowData] = useState<InvoiceLineItemRecord[]>(initialLineItems)
  const theme = useTheme()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const [updateLineItem] = useMutation(UpdateServiceInvoiceLineItemDocument)
  const [createLineItem] = useMutation(CreateServiceInvoiceLineItemDocument)

  const { data: invoiceArticlesData } = useQuery(GetInvoiceArticlesDocument, {
    skip: !selectedCompany?.uuid,
    variables: {
      companyUuid: selectedCompany?.uuid,
    },
  })

  const { data: companiesData } = useQuery(GetAllActiveCompaniesDocument, {
    skip: !selectedCompany?.uuid,
  })

  const sellerCompanyUuid = useMemo(() => {
    const firstSeller = rowData[0]?.sellerId
    return companiesData?.companies?.find((company) => company.sellerId === firstSeller)?.uuid
  }, [companiesData, rowData])

  const { data: sellerCompanyData } = useQuery(GetCompanyDocument, {
    skip: !sellerCompanyUuid,
    variables: {
      companyUuid: sellerCompanyUuid ?? '',
    },
  })

  const companyOptions = useMemo(
    () => companiesData?.companies?.map((company) => ({ value: company.sellerId })) ?? [],
    [companiesData]
  )

  const articleNameOptions = useMemo(
    () =>
      invoiceArticlesData?.invoiceArticles
        ?.filter((article) => article.isActive)
        ?.map((article) => {
          const sellerCompany = sellerCompanyData?.company
          const countryCode = sellerCompany?.taxationCountry || ''

          const bookingAccount = article.invoiceArticleCategory?.bookingAccounts?.find((account) =>
            account.countries.includes(countryCode)
          )

          const taxRate = bookingAccount?.taxRate ?? 0

          return {
            displayName: article.name,
            value: article.uuid,
            taxRate,
            sellerId: article.invoiceArticleCategory?.company?.sellerId,
          }
        }) ?? [],
    [invoiceArticlesData, sellerCompanyData]
  )

  const onCellValueChanged = useCallback(
    async (event: CellValueChangedEvent<InvoiceLineItemRecord>) => {
      if (!event?.colDef.field || event.newValue === event.oldValue) return false

      const { colDef, newValue, oldValue, data, api } = event
      const field = colDef.field as keyof InvoiceLineItemRecord

      try {
        let value = newValue
        if (
          ['issueDate', 'performancePeriodStartDate', 'performancePeriodEndDate'].includes(field)
        ) {
          value = dayjs(newValue instanceof Date ? newValue : newValue).format('YYYY-MM-DD')
        } else if (typeof newValue === 'string') {
          value = newValue.trim()
        }

        if (field === 'sellerId') {
          const selectedCompany = companiesData?.companies?.find(
            (company) => company.sellerId === value
          )
          if (selectedCompany) {
            const taxRate =
              articleNameOptions.find((opt) => opt.value === data.article)?.taxRate ?? 0
            api.applyTransaction({
              update: [{ ...data, sellerId: value, taxRate }],
            })
            return true
          }
        }

        // Handle article selection for new rows
        if (field === 'article' && data.uuid.startsWith('temp-')) {
          const selectedArticle = articleNameOptions.find((opt) => opt.value === value)
          if (selectedArticle) {
            api.applyTransaction({
              update: [
                {
                  ...data,
                  article: value,
                  title: selectedArticle.displayName,
                },
              ],
            })
            return true
          }
        }

        api.applyTransaction({ update: [{ ...data, [field]: value }] })

        if (!data.uuid.startsWith('temp-')) {
          await updateLineItem({
            variables: { uuid: data.uuid, input: { [field]: value } },
            update: (cache, { data }) => {
              if (data?.updateServiceInvoiceLineItem) {
                cache.modify({
                  id: cache.identify(data.updateServiceInvoiceLineItem),
                  fields: { [field]: () => value },
                })
              }
            },
          })
          toast.success(t('invoices:lineItems.update.success'))
        }
      } catch (error) {
        api.applyTransaction({ update: [{ ...data, [field]: oldValue }] })
        console.error(error)
        toast.error(t('invoices:lineItems.update.error'))
      }
    },
    [t, updateLineItem, articleNameOptions, companiesData]
  )

  const handleAddEmptyRow = useCallback(() => {
    const api = (gridRef as MutableRefObject<AgGridReact<InvoiceLineItemRecord>>)?.current?.api
    if (!api) return

    const newEmptyRow: InvoiceLineItemRecord = {
      uuid: `temp-${Date.now()}`,
      title: '',
      article: '',
      sellerId: '',
      netAmount: 1,
      currency: 'EUR',
      commissionInPercent: 0,
      quantity: 1,
      taxRate: 0,
      bookingNumber: 0,
      paymentStatus: ServiceInvoiceLineItemPaymentStatus.UNPAID,
      issueDate: new Date().toISOString(),
      performancePeriodStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
      performancePeriodEndDate: dayjs().endOf('month').format('YYYY-MM-DD'),
      comment: null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      invoiceUuid: null,
    }

    const insertIndex = findInsertionIndex(api)
    api.applyTransaction({ add: [newEmptyRow], addIndex: insertIndex })
  }, [])

  const handleSaveNewRows = useCallback(
    async (gridApi: GridApi<InvoiceLineItemRecord>) => {
      try {
        const currentRowData: InvoiceLineItemRecord[] = []
        gridApi.forEachNodeAfterFilterAndSort((node) => {
          if (node.data) {
            currentRowData.push(node.data)
          }
        })

        const newRows = currentRowData.filter((row) => row.uuid.startsWith('temp-'))

        if (newRows.length === 0) {
          toast.info(t('invoices:lineItems.save.noNewRows'))
          return
        }

        const isValid = newRows.every(
          (row) =>
            row.article &&
            row.title &&
            row.netAmount > 0 &&
            row.quantity > 0 &&
            row.taxRate >= 0 &&
            row.sellerId
        )

        if (!isValid) {
          toast.error(t('invoices:lineItems.save.invalidRows'))
          return
        }

        for (const newRow of newRows) {
          const buyerCompany = companiesData?.companies?.find(
            (company) => company.sellerId === newRow.sellerId
          )

          if (!buyerCompany) {
            throw new Error('Selected company not found')
          }

          await createLineItem({
            variables: {
              input: {
                invoiceArticleUuid: newRow.article,
                buyerCompanyUuid: buyerCompany.uuid,
                title: newRow.title,
                netAmount: newRow.netAmount,
                currency: newRow.currency as Currency,
                commissionInPercent: newRow.commissionInPercent,
                quantity: newRow.quantity,
                taxRate: newRow.taxRate,
                bookingNumber: newRow.bookingNumber,
                paymentStatus: newRow.paymentStatus as ServiceInvoiceLineItemPaymentStatus,
                issueDate: newRow.issueDate,
                performancePeriodStartDate: newRow.performancePeriodStartDate,
                performancePeriodEndDate: newRow.performancePeriodEndDate,
                comment: newRow.comment || undefined,
              },
            },
          })
        }

        toast.success(t('invoices:lineItems.save.success'))

        if (onSaveSuccess) {
          await onSaveSuccess()
        }
      } catch (error) {
        console.error(error)
        toast.error(t('invoices:lineItems.save.error'))
      }
    },
    [t, createLineItem, companiesData, onSaveSuccess]
  )

  const columnDefs = useMemo<ColDef<InvoiceLineItemRecord>[]>(
    () => [
      {
        field: 'uuid',
        sort: 'asc',
        sortingOrder: ['asc'],
        valueGetter: (params) => {
          return params.data?.uuid.startsWith('temp-')
            ? '0' + params.data?.uuid
            : '1' + params.data?.uuid
        },
        hide: true,
      },
      {
        lockPosition: true,
        width: 96,
        resizable: false,
        sortable: false,
        filter: false,
        pinned: 'left',
        headerComponent: ActionHeader,
        headerComponentParams: {
          onAddEmptyRow: handleAddEmptyRow,
          onSaveNewRows: () => {
            const api = (gridRef as MutableRefObject<AgGridReact<InvoiceLineItemRecord>>)?.current
              ?.api
            if (api) handleSaveNewRows(api)
          },
          api: (gridRef as MutableRefObject<AgGridReact<InvoiceLineItemRecord>>)?.current?.api,
        },
        cellRenderer: ({ data }: ICellRendererParams<InvoiceLineItemRecord>) => {
          const uuid = data?.uuid
          const isNewRow = uuid?.startsWith('temp-')
          const api = (gridRef as MutableRefObject<AgGridReact<InvoiceLineItemRecord>>)?.current
            ?.api

          if (!uuid) return null

          if (isNewRow) {
            const newRows: InvoiceLineItemRecord[] = []
            api?.forEachNode((rowNode) => {
              if (rowNode.data?.uuid?.startsWith('temp-')) {
                newRows.push(rowNode.data)
              }
            })
            newRows.sort((a, b) => {
              const timeA = parseInt(a.uuid?.split('-')[1] ?? '0')
              const timeB = parseInt(b.uuid?.split('-')[1] ?? '0')
              return timeA - timeB
            })
            const index = newRows.findIndex((row) => row.uuid === uuid) + 1

            return (
              <Flex gap={16} align="center" style={{ width: '100%' }}>
                <Button
                  icon={<Trash size={16} />}
                  danger
                  onClick={() => {
                    if (api) {
                      api.applyTransaction({ remove: [data] })
                      api.refreshCells({ force: true })
                    }
                  }}
                />
                <Badge count={index} />
              </Flex>
            )
          }

          return null
        },
      },
      {
        field: 'article',
        headerName: t('invoices:lineItems.table.article'),
        filter: 'agTextColumnFilter',
        minWidth: 250,
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellEditor: 'agRichSelectCellEditor',
        valueParser: (params) => {
          const value = params.newValue?.trim()
          return articleNameOptions.find((option) => option.displayName === value)?.value ?? null
        },
        cellEditorParams: {
          values: articleNameOptions.map((option) => option.value),
          formatValue: (value: string) => {
            const article = articleNameOptions.find((opt) => opt.value === value)
            return article?.displayName ?? value
          },
          highlightMatch: true,
          searchType: 'matchAny',
          allowTyping: true,
          filterList: true,
        },
        valueFormatter: (params) => {
          const article = articleNameOptions.find((opt) => opt.value === params.value)
          return article?.displayName ?? params.value
        },
        cellRenderer: ({ value }: ICellRendererParams<InvoiceLineItemRecord>) => {
          const article = articleNameOptions.find((opt) => opt.value === value)
          return <Space>{article?.displayName ?? value}</Space>
        },
        cellStyle: (params) =>
          params.data?.uuid?.startsWith('temp-')
            ? { backgroundColor: `${theme.colors.warning}20` }
            : undefined,
      },
      {
        field: 'sellerId',
        headerName: t('invoices:lineItems.table.sellerId'),
        filter: 'agTextColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') ?? false,
        cellEditor: 'agRichSelectCellEditor',
        valueParser: (params) => {
          const value = params.newValue?.trim()
          return companyOptions.find((option) => option.value === value)?.value ?? null
        },
        cellEditorParams: {
          values: companyOptions.map((option) => option.value),
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
          searchType: 'matchAny',
        },
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceLineItemRecord>) => {
          return (
            <Space>
              {data?.uuid.startsWith('temp-') && <Pen size={16} />}
              {value}
            </Space>
          )
        },
        cellStyle: (params) =>
          params.data?.uuid?.startsWith('temp-')
            ? { backgroundColor: `${theme.colors.warning}20` }
            : undefined,
      },
      {
        field: 'title',
        headerName: t('invoices:lineItems.table.title'),
        headerTooltip: t('invoices:lineItems.table.titleTooltip'),
        filter: 'agTextColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceLineItemRecord>) => (
          <Space>
            {(data?.uuid.startsWith('temp-') || !data?.invoiceUuid) && <Pen size={16} />}
            {value ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        ),
        cellStyle: (params) =>
          params.data?.uuid?.startsWith('temp-')
            ? { backgroundColor: `${theme.colors.warning}20` }
            : undefined,
      },
      {
        field: 'netAmount',
        headerName: t('invoices:lineItems.table.netAmount'),
        filter: 'agNumberColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellRenderer: ({ data, value }: ICellRendererParams<InvoiceLineItemRecord>) => (
          <Space>
            {(data?.uuid.startsWith('temp-') || !data?.invoiceUuid) && <Pen size={16} />}
            {formatMoney(value, data?.currency ?? 'EUR') ??
              t('shared.button.edit', { ns: 'translation' })}
          </Space>
        ),
      },
      {
        field: 'currency',
        headerName: t('invoices:lineItems.table.currency'),
        filter: 'agTextColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: currencyOptions.map((option) => option.value),
        },
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceLineItemRecord>) => (
          <Space>
            {(data?.uuid.startsWith('temp-') || !data?.invoiceUuid) && <Pen size={16} />}
            {value ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        ),
      },
      {
        field: 'quantity',
        headerName: t('invoices:lineItems.table.quantity'),
        filter: 'agNumberColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceLineItemRecord>) => (
          <Space>
            {(data?.uuid.startsWith('temp-') || !data?.invoiceUuid) && <Pen size={16} />}
            {value ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        ),
      },
      {
        field: 'taxRate',
        headerName: t('invoices:lineItems.table.taxRate'),
        filter: 'agNumberColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceLineItemRecord>) => (
          <Space>
            {(data?.uuid.startsWith('temp-') || !data?.invoiceUuid) && <Pen size={16} />}
            {formatPercentage(value / 100) ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        ),
      },
      {
        field: 'paymentStatus',
        headerName: t('invoices:lineItems.table.paymentStatus'),
        filter: 'agTextColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: paymentStatusOptions.map((option) => option.value),
        },
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceLineItemRecord>) => (
          <Space>
            {(data?.uuid.startsWith('temp-') || !data?.invoiceUuid) && <Pen size={16} />}
            {value
              ? t(`invoices:paymentStatus.${value.toLowerCase()}`)
              : t('shared.button.edit', { ns: 'translation' })}
          </Space>
        ),
      },
      {
        field: 'issueDate',
        headerName: t('invoices:lineItems.table.issueDate'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellEditor: 'agDateStringCellEditor',
        valueFormatter: (params) => (params.value ? dayjs(params.value).format('DD.MM.YYYY') : ''),
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceLineItemRecord>) => (
          <Space>
            {(data?.uuid.startsWith('temp-') || !data?.invoiceUuid) && <Pen size={16} />}
            {value ? (
              <FormattedDate date={value} format="DD.MM.YYYY" />
            ) : (
              t('shared.button.edit', { ns: 'translation' })
            )}
          </Space>
        ),
      },
      {
        field: 'performancePeriodStartDate',
        headerName: t('invoices:lineItems.table.performancePeriodStart'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellEditor: 'agDateStringCellEditor',
        valueFormatter: (params) => (params.value ? dayjs(params.value).format('DD.MM.YYYY') : ''),
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceLineItemRecord>) => (
          <Space>
            {(data?.uuid.startsWith('temp-') || !data?.invoiceUuid) && <Pen size={16} />}
            {value ? (
              <FormattedDate date={value} format="DD.MM.YYYY" />
            ) : (
              t('shared.button.edit', { ns: 'translation' })
            )}
          </Space>
        ),
      },
      {
        field: 'performancePeriodEndDate',
        headerName: t('invoices:lineItems.table.performancePeriodEnd'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellEditor: 'agDateStringCellEditor',
        valueFormatter: (params) => (params.value ? dayjs(params.value).format('DD.MM.YYYY') : ''),
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceLineItemRecord>) => (
          <Space>
            {(data?.uuid.startsWith('temp-') || !data?.invoiceUuid) && <Pen size={16} />}
            {value ? (
              <FormattedDate date={value} format="DD.MM.YYYY" />
            ) : (
              t('shared.button.edit', { ns: 'translation' })
            )}
          </Space>
        ),
      },
      {
        field: 'comment',
        headerName: t('invoices:lineItems.table.comment'),
        filter: 'agTextColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') || !params.data?.invoiceUuid,
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceLineItemRecord>) => (
          <Space>
            {(data?.uuid.startsWith('temp-') || !data?.invoiceUuid) && <Pen size={16} />}
            {value ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        ),
      },
      {
        field: 'createdAt',
        headerName: t('invoices:lineItems.table.createdAt'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        sort: 'desc',
        cellRenderer: ({ value }: ICellRendererParams<InvoiceLineItemRecord>) => {
          if (value) {
            return <FormattedDate date={value} withRelativeTime={true} />
          }
        },
        hide: true,
      },
      {
        field: 'updatedAt',
        headerName: t('invoices:lineItems.table.updatedAt'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        cellRenderer: ({ value }: ICellRendererParams<InvoiceLineItemRecord>) => {
          if (value) {
            return <FormattedDate date={value} withRelativeTime={true} />
          }
        },
      },
    ],
    [handleAddEmptyRow, handleSaveNewRows, articleNameOptions, companyOptions]
  )

  const isRowSelectable = useCallback((params: IRowNode<InvoiceLineItemRecord>) => {
    if (!params.data) {
      return false
    }
    return !params.data.invoiceUuid
  }, [])

  const rowSelection = useMemo<RowSelectionOptions<InvoiceLineItemRecord>>(() => {
    return {
      mode: 'multiRow',
      isRowSelectable,
      selectAll: 'filtered',
    }
  }, [isRowSelectable])

  const selectionColumnDef = useMemo<SelectionColumnDef>(() => {
    return {
      minWidth: 66,
      maxWidth: 66,
      pinned: 'left',
      cellRenderer: (params: ICellRendererParams<InvoiceLineItemRecord>) => {
        if (params.data && !params.node.selectable) {
          return (
            <Tooltip title={t('invoices:lineItems.table.selectionTooltip.alreadyInvoiced')}>
              <CircleAlert size={16} color={theme.colors.warning} />
            </Tooltip>
          )
        }
        return null
      },
    }
  }, [t, theme.colors.warning])

  const getRowId = useCallback((params: GetRowIdParams<InvoiceLineItemRecord>) => {
    return params.data.uuid
  }, [])

  useEffect(() => {
    setRowData(initialLineItems)
  }, [initialLineItems])

  return (
    <Table<InvoiceLineItemRecord>
      ref={gridRef}
      columnDefs={columnDefs}
      defaultExcelExportParams={{
        fileName: `invoice-line-items-${dayjs().format('DD-MM-YYYY')}`,
      }}
      fullHeight
      onCellValueChanged={onCellValueChanged}
      persist={{
        key: TablePersistKey.INVOICE_LINE_ITEMS,
        storage: localStorage,
      }}
      rowData={rowData}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
      rowSelection={rowSelection}
      selectionColumnDef={selectionColumnDef}
      getRowId={getRowId}
      onSelectionChanged={onSelectionChanged}
    />
  )
})

const ActionHeader = ({ onAddEmptyRow, onSaveNewRows, api }: ActionHeaderProps) => {
  const [hasNewRows, setHasNewRows] = useState(false)

  useEffect(() => {
    const checkForNewRows = () => {
      if (api) {
        const rowData: InvoiceLineItemRecord[] = []
        api.forEachNode((node) => {
          if (node.data) {
            rowData.push(node.data)
          }
        })
        const newRows = rowData.some((row) => row.uuid.startsWith('temp-'))
        setHasNewRows(newRows)
      }
    }

    checkForNewRows()
    const intervalId = setInterval(checkForNewRows, 500)

    return () => clearInterval(intervalId)
  }, [api])

  const handleSaveNewRows = useCallback(() => {
    if (hasNewRows) {
      onSaveNewRows()
    }
  }, [hasNewRows, onSaveNewRows])

  return (
    <Space>
      <Button onClick={onAddEmptyRow} icon={<Plus size={16} />} type="primary" />
      <Button
        onClick={handleSaveNewRows}
        icon={<Save size={16} />}
        type="primary"
        disabled={!hasNewRows}
      />
    </Space>
  )
}
