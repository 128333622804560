import { useMutation } from '@apollo/client'
import { Button, Form, Modal, Popconfirm, Space } from 'antd'
import React, { useState } from 'react'
import { toast } from 'sonner'
import {
  Marketplace,
  UpdateSponsoredProductsKeywordInput,
  UpdateSponsoredProductsKeywordsDocument,
} from '../../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../../stores/useGlobalStore'
import PPCStateFormFields from '../../../../PPCStateFormFields/PPCStateFormFields'
type BulkEditSponsoredProductsKeywordsStateModalProps = {
  campaignId: string
  keywordsIn: UpdateSponsoredProductsKeywordInput[]
  updateKeywordList: Function
  marketplace: Marketplace
}

const BulkEditSponsoredProductsKeywordsStateModal = ({
  campaignId,
  keywordsIn,
  updateKeywordList,
  marketplace,
}: BulkEditSponsoredProductsKeywordsStateModalProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const [updateSponsoredProductsKeywords, { loading: loadingUpdate }] = useMutation(
    UpdateSponsoredProductsKeywordsDocument
  )

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleOnFinish = async (values: any) => {
    const keywords = keywordsIn.map((keyword: UpdateSponsoredProductsKeywordInput) => {
      return {
        keywordId: keyword.keywordId,
        state: values.state,
        bid: keyword.bid,
      }
    })

    updateSponsoredProductsKeywords({
      variables: { companyUuid: selectedCompany?.uuid || '', campaignId, marketplace, keywords },
    })
      .then(({ data }) => {
        const updatedKeywords = data?.updateSponsoredProductsKeywords
        if (!updatedKeywords) {
          throw new Error('No keywords were updated')
        }
        updatedKeywords.forEach((keyword) => {
          updateKeywordList(keyword)
        })
        toast.success('Keywords updated.')
        setModalVisible(false)
      })
      .catch((e) => {
        console.error(e)
        if (e.networkError?.statusCode === 429) {
          toast.error("Amazon's servers are currently busy, please try again in a few minutes.")
        } else {
          toast.error('Error updating keyword(s).')
        }
      })
  }

  const handleFormValuesChange = () => {
    const formState = form.getFieldValue('state')

    setSaveButtonDisabled(formState === undefined || formState === null)
  }

  return (
    <>
      <Button key="huihif" onClick={showModal} disabled={!keywordsIn?.length}>
        Change state
      </Button>

      <div
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}
        key="u90u90u"
      >
        <Modal
          title={`Change state of (${keywordsIn.length}) selected keywords`}
          open={modalVisible}
          onCancel={handleCancel}
          footer={
            <Space>
              <Button onClick={handleCancel} key="cancel">
                Cancel
              </Button>
              <Popconfirm
                key="u8f8f"
                placement="top"
                title={'Please confirm changes to all selected keywords'}
                onConfirm={form.submit}
                okText="Confirm"
                cancelText="Go back"
                disabled={saveButtonDisabled}
              >
                <Button
                  key="savel"
                  disabled={saveButtonDisabled}
                  type={'primary'}
                  loading={loadingUpdate}
                >
                  Save
                </Button>
              </Popconfirm>
            </Space>
          }
        >
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            onFinish={handleOnFinish}
            onValuesChange={handleFormValuesChange}
          >
            <PPCStateFormFields />
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default BulkEditSponsoredProductsKeywordsStateModal
