import { useMutation } from '@apollo/client'
import { Button, Form, Input, Modal, TourProps } from 'antd'
import dayjs from 'dayjs'
import { Plus } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router'
import { toast } from 'sonner'
import {
  CheckOnboardingStepDocument,
  CreateWarehouseServiceProviderDocument,
  GetCompanyDocument,
  GetWarehouseServiceProvidersDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { useOnboardingStore } from '../../../../stores/useOnboardingStore'

type AddWarehouseServiceProviderFormInstance = {
  name: string
}

export const AddWarehouseServiceProviderModal = () => {
  const [open, setOpen] = useState(false)
  const warehouseRef = useRef(null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const setTourSteps = useOnboardingStore((state) => state.setTourSteps)
  const setTourOpen = useOnboardingStore((state) => state.setTourOpen)

  const { t } = useTranslation(['warehousing', 'company'])
  const [form] = Form.useForm<AddWarehouseServiceProviderFormInstance>()

  const [createWarehouseServiceProvider, { loading }] = useMutation(
    CreateWarehouseServiceProviderDocument
  )
  const [checkOnboardingStep] = useMutation(CheckOnboardingStepDocument)

  const handleSubmit = async (values: AddWarehouseServiceProviderFormInstance) => {
    try {
      if (!selectedCompany?.uuid) {
        throw new Error()
      }

      await createWarehouseServiceProvider({
        variables: {
          companyUuid: selectedCompany.uuid,
          name: values.name.trim(),
        },
        optimisticResponse: {
          createWarehouseServiceProvider: {
            uuid: 'optimistic',
            name: values.name.trim(),
            createdAt: dayjs().toISOString(),
          },
        },
        update: (cache, { data }) => {
          const createdWarehouseServiceProvider = data?.createWarehouseServiceProvider
          const cachedQuery = cache.readQuery({
            query: GetWarehouseServiceProvidersDocument,
            variables: { companyUuid: selectedCompany.uuid },
          })

          if (createdWarehouseServiceProvider && cachedQuery) {
            const warehouseServiceProviders = [
              ...cachedQuery.warehouseServiceProviders,
              { ...createdWarehouseServiceProvider, locations: [] },
            ]

            cache.writeQuery({
              query: GetWarehouseServiceProvidersDocument,
              variables: { companyUuid: selectedCompany.uuid },
              data: { warehouseServiceProviders },
            })
          }
        },
      })
      if (!selectedCompany.completedOnboarding) {
        setTourOpen(false)
        await checkOnboardingStep({
          variables: {
            input: {
              companyUuid: selectedCompany.uuid,
              eventName: 'add_warehouse_service_provider',
            },
          },
          refetchQueries: [
            {
              query: GetCompanyDocument,
              variables: { companyUuid: selectedCompany?.uuid },
            },
          ],
        })
        navigate('/products/add?tour=true')
      }
      toast.success(t('add.toast.success'))
    } catch (error) {
      toast.error(t('add.toast.error'))
    } finally {
      setOpen(false)
      form.resetFields()
    }
  }

  const steps: TourProps['steps'] = [
    {
      title: t('company:onboarding.addWarehouseServiceProvider.title'),
      description: t('company:onboarding.addWarehouseServiceProvider.description'),
      target: () => warehouseRef.current,
      nextButtonProps: {
        style: { display: 'none' },
      },
    },
  ]

  useEffect(() => {
    const tour = searchParams.get('tour')
    if (tour === 'true') {
      setTourSteps(steps)
      setTourOpen(true)
    }
  }, [searchParams])

  return (
    <>
      <Button
        ref={warehouseRef}
        icon={<Plus size={16} />}
        onClick={() => {
          setTourOpen(false)
          setOpen(true)
        }}
      >
        {t('add.button')}
      </Button>
      <Modal
        title={t('add.title')}
        open={open}
        okText={t('shared.button.save', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelButtonProps={{ disabled: loading }}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
      >
        <Form<AddWarehouseServiceProviderFormInstance>
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item<AddWarehouseServiceProviderFormInstance>
            name="name"
            label={t('add.form.name.label')}
            validateDebounce={1000}
            rules={[
              { required: true, message: t('add.form.name.rules.required') },
              { min: 3, message: t('add.form.name.rules.minLength') },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
