import { useMutation } from '@apollo/client'
import { Button, Form, Modal, Select } from 'antd'
import { Plus } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  CreateProductComplianceCheckItemsDocument,
  GetProductComplianceCheckByIdentifierDocument,
} from '../../../generated/graphql'
import { useCountryOptions } from '../../../hooks/useCountryOptions'
import { ProductComplianceCheckItem } from '../ComplianceDetailsView'

type CreateProductComplianceCheckItemsFormInstance = {
  countryCodes: string[]
}

type CreateProductComplianceCheckItemsModalProps = {
  items: ProductComplianceCheckItem[]
  productComplianceCheckIdentifier: string
}

export const CreateProductComplianceCheckItemsModal = ({
  items,
  productComplianceCheckIdentifier,
}: CreateProductComplianceCheckItemsModalProps) => {
  const { t } = useTranslation(['compliance'])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm<CreateProductComplianceCheckItemsFormInstance>()

  const countryOptions = useCountryOptions()

  const [createProductComplianceCheckItems] = useMutation(CreateProductComplianceCheckItemsDocument)

  const selectableCountryOptions = useMemo(() => {
    return countryOptions.reduce<{ value: string; label: string }[]>((acc, country) => {
      const isSelected = items.some(
        (item) =>
          item.countryCode === country.code || (country.code === 'GB' && item.countryCode === 'UK')
      )
      if (!isSelected) {
        acc.push({ value: country.code, label: country.name })
      }
      return acc
    }, [])
  }, [countryOptions, items])

  const handleOk = async (values: CreateProductComplianceCheckItemsFormInstance) => {
    setLoading(true)
    try {
      await createProductComplianceCheckItems({
        variables: {
          productComplianceCheckIdentifier: productComplianceCheckIdentifier,
          countryCodes: values.countryCodes,
        },
        refetchQueries: [
          {
            query: GetProductComplianceCheckByIdentifierDocument,
            variables: { identifier: productComplianceCheckIdentifier },
          },
        ],
      })

      toast.success(t('listCard.createModal.toast.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('listCard.createModal.toast.error'))
    } finally {
      setLoading(false)
      setOpen(false)
      form.resetFields()
    }
  }

  return (
    <>
      <Button
        icon={<Plus size={16} />}
        onClick={() => {
          setOpen(true)
        }}
      >
        {t('listCard.createModal.title')}
      </Button>
      <Modal
        title={t('listCard.createModal.title')}
        open={open}
        onOk={form.submit}
        okText={t('listCard.createModal.button.create')}
        cancelText={t('listCard.createModal.button.cancel')}
        onCancel={() => setOpen(false)}
        loading={loading}
      >
        <Form<CreateProductComplianceCheckItemsFormInstance> form={form} onFinish={handleOk}>
          <Form.Item
            name={'countryCodes'}
            label={t('listCard.createModal.form.countryCode.label')}
            rules={[
              {
                required: true,
                message: t('listCard.createModal.form.countryCode.validation.required'),
              },
            ]}
          >
            <Select
              mode="multiple"
              options={selectableCountryOptions}
              placeholder={t('listCard.createModal.form.countryCode.placeholder')}
              showSearch
              filterOption={true}
              optionFilterProp="label"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
