import { Alert, Collapse, List, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { ImportError } from './AmazonInboundShipmentPlanImportModal'

type AmazonInboundShipmentPlanImportErrorsAlertProps = {
  errors: ImportError[]
}

export const AmazonInboundShipmentPlanImportErrorsAlert = ({
  errors,
}: AmazonInboundShipmentPlanImportErrorsAlertProps) => {
  const { t } = useTranslation('inventory')

  if (errors.length === 0) {
    return null
  }

  const errorItems = errors.map((error) => ({
    key: error.sku,
    label: error.sku,
    children: (
      <List
        size="small"
        split={false}
        dataSource={error.errors}
        renderItem={(item) => (
          <List.Item key={`${error.sku}-${item}`}>
            <Typography.Text type="danger">- {item}</Typography.Text>
          </List.Item>
        )}
      />
    ),
  }))

  return (
    <Alert
      type="error"
      message={t('amazonInboundShipmentPlan.import.error.alert.title', {
        count: errors.length,
      })}
      description={<Collapse size="small" ghost items={errorItems} />}
    />
  )
}
