import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { SupportTicketStatus } from '../../generated/graphql'
import { getTicketStatusTagColor } from '../../views/ticket/helpers/getTicketStatusTagColor'

type TicketStatusBadgeProps = {
  status: SupportTicketStatus
}

const TicketStatusBadge = ({ status }: TicketStatusBadgeProps) => {
  const { t } = useTranslation(['ticket'], { keyPrefix: 'listTicketView.table.status' })
  return (
    <Tag
      style={{ padding: '5px 15px', borderRadius: 6, fontSize: 14, marginRight: 0 }}
      color={getTicketStatusTagColor(status)}
    >
      {t(status)}
    </Tag>
  )
}

export default TicketStatusBadge
