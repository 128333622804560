import { useMutation } from '@apollo/client'
import { Alert, Button, Flex, Modal, Radio, RadioChangeEvent, Typography } from 'antd'
import { SquarePen } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  ExternalAmazonListingStatus,
  InternalAmazonListingStatus,
  UpdateAmazonProductDocument,
} from '../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'

type EditAmazonListingSubscribeAndSaveModalProps = {
  subscribeAndSaveValue: number | null | undefined
  amazonProductUuid: string | null | undefined
  internalStatus: InternalAmazonListingStatus | null | undefined
  externalStatus: ExternalAmazonListingStatus | null | undefined
}

export const EditAmazonListingSubscribeAndSaveModal = ({
  subscribeAndSaveValue,
  amazonProductUuid,
  internalStatus,
  externalStatus,
}: EditAmazonListingSubscribeAndSaveModalProps) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(subscribeAndSaveValue ?? 0)
  const [submitting, setSubmitting] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation(['inventory', 'translation'])

  const [updateAmazonProduct] = useMutation(UpdateAmazonProductDocument)

  useEffect(() => {
    setValue(subscribeAndSaveValue ?? 0)
  }, [subscribeAndSaveValue, amazonProductUuid])

  const openModal = () => {
    setOpen(true)
  }

  const handleChange = (event: RadioChangeEvent) => {
    if (event.target.value || event.target.value === 0) {
      setValue(event.target.value)
    }
  }

  const handleSubmit = async () => {
    setSubmitting(true)

    try {
      if (!amazonProductUuid) {
        throw new Error()
      }

      await updateAmazonProduct({
        variables: {
          uuid: amazonProductUuid,
          companyUuid: selectedCompany.uuid,
          input: {
            subscribeAndSave: value,
          },
        },
      })

      toast.success(t('shared.notification.success', { ns: 'translation' }), {
        description: t('product.details.changeRequest.success'),
      })
    } catch (error) {
      console.error(error)
      toast.error(t('shared.notification.error', { ns: 'translation' }), {
        description: t('product.details.changeRequest.error'),
      })
    }

    setSubmitting(false)
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const options = [
    { label: t('product.details.changeRequest.subscribeAndSave.modal.radio.disable'), value: 0 },
    { label: '5%', value: 5 },
    { label: '10%', value: 10 },
  ]

  return (
    <>
      <Button
        type="text"
        icon={<SquarePen size={16} />}
        onClick={openModal}
        size="small"
        disabled={
          internalStatus === InternalAmazonListingStatus.REVIEW ||
          internalStatus === InternalAmazonListingStatus.DELETED ||
          externalStatus === ExternalAmazonListingStatus.DETAIL_PAGE_REMOVED
        }
      />
      <Modal
        title={t('product.details.changeRequest.subscribeAndSave.modal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        okButtonProps={{
          disabled: value === subscribeAndSaveValue,
        }}
        onOk={handleSubmit}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={handleCancel}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Alert
          description={t('product.details.changeRequest.subscribeAndSave.alert.description')}
          type="info"
        />
        <Flex justify="space-between" style={{ margin: '32px 0' }}>
          <Typography.Text>
            {t('product.details.changeRequest.subscribeAndSave.modal.label')}
          </Typography.Text>
          <Radio.Group
            options={options}
            value={value}
            onChange={handleChange}
            optionType="button"
          />
        </Flex>
      </Modal>
    </>
  )
}
