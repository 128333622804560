import { Col, List, Row, Space, Tooltip, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Flag } from '../../../../components/Flag'
import { formatMoney } from '../../../../helpers/formatMoney'
import { AmazonMarketplaceDomain } from '../../../../utils'
import { MarketplacePerformanceDetails } from '../helpers/preparePerformanceOverview'
import { StyledList, StyledSkeleton, StyledSubCard } from '../../styles'

type DashboardSubCardTopMarketplacesProps = {
  topMarketplaces: MarketplacePerformanceDetails[]
  rows?: number
  loading: boolean
}

export const DashboardSubCardTopMarketplaces = ({
  topMarketplaces,
  rows = 3,
  loading,
}: DashboardSubCardTopMarketplacesProps) => {
  const { t } = useTranslation(['dashboard', 'translation'])

  return (
    <StyledSubCard>
      <StyledSkeleton loading={loading} paragraph={{ rows: 4 }}>
        <StyledList<MarketplacePerformanceDetails>
          header={<Typography.Text>{t('performance.shared.topMarketplaces')}</Typography.Text>}
          dataSource={topMarketplaces.slice(0, rows)}
          renderItem={(item) => (
            <List.Item style={{ padding: '0.5rem 0' }}>
              <Row gutter={2} style={{ width: '100%' }}>
                <Col span={12}>
                  <Space>
                    <Flag code={item.marketplace} />
                    <Typography.Text>{AmazonMarketplaceDomain[item.marketplace]}</Typography.Text>
                  </Space>
                </Col>
                <Col span={12}>
                  <Tooltip
                    title={`${item.totalShipped} / ${item.totalPending} / ${item.totalCanceled}`}
                  >
                    <Row gutter={[4, 4]}>
                      <Col>
                        <Typography.Text>{formatMoney(item.totalRevenue, 'EUR')}</Typography.Text>
                      </Col>
                      <Col flex="auto">
                        <Typography.Text style={{ opacity: 0.4 }}>
                          {formatMoney(item.pendingRevenue, 'EUR')}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Tooltip>
                </Col>
              </Row>
            </List.Item>
          )}
          locale={{ emptyText: t('shared.noData', { ns: 'translation' }) }}
          split={false}
        />
      </StyledSkeleton>
    </StyledSubCard>
  )
}
