import { css } from '@emotion/react'
import styled from '@emotion/styled'

type ViewContainerProps = {
  centered?: boolean
  padding?: string
}

export const ViewContainer = styled.section<ViewContainerProps>(
  ({ centered, padding }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: ${centered ? 'center' : 'stretch'};
    gap: 1rem;
    padding: ${padding ? padding : '1rem 1rem 3rem 1rem'};
    overflow: hidden auto;
  `
)

export const FullViewContainer = styled(ViewContainer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.background};
`
