import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import {
  GetAllAmazonProductsDocument,
  InternalAmazonListingStatus,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { AmazonProductTable } from './components/AmazonProductTable/AmazonProductTable'
import { prepareAmazonProductTableData } from './components/AmazonProductTable/helpers'
import { CreateAmazonProductModal } from './components/CreateAmazonProductModal'

export const AmazonProductsView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('inventory')

  const { error, data } = useQuery(GetAllAmazonProductsDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    skip: !selectedCompany.uuid,
    variables: {
      uuid: selectedCompany.uuid,
      internalStatuses: [
        InternalAmazonListingStatus.ACTIVE,
        InternalAmazonListingStatus.REVIEW,
        InternalAmazonListingStatus.PROCESSING,
        InternalAmazonListingStatus.DELETED,
      ],
    },
  })

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const dataSource = data ? prepareAmazonProductTableData(data) : undefined

  const productVariants = data?.products.flatMap((product) =>
    product.productVariants?.flatMap((productVariant) => {
      if (productVariant.amazonProducts?.length) {
        return []
      }

      return {
        name: product.name,
        ean: productVariant.ean,
        internalSku: productVariant.internalSku,
        productVariantUuid: productVariant.uuid,
      }
    })
  )

  return (
    <>
      <SubHeader
        heading={t('amazonProduct.heading', { ns: 'inventory' })}
        rightContent={<CreateAmazonProductModal productVariants={productVariants} />}
      />
      <ViewContainer>
        <AmazonProductTable dataSource={dataSource} />
      </ViewContainer>
    </>
  )
}
