import { useQuery } from '@apollo/client'
import { Col, Divider, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { GetAmazonListingDocument } from '../../../generated/graphql'
import { getUserRole } from '../../../helpers/getUserRole'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { AmazonListingContent } from './components/AmazonListingContent'
import { AmazonListingDocumentsCard } from './components/AmazonListingDocumentsCard'
import { AmazonListingImages } from './components/AmazonListingImages'
import { AmazonListingInformation } from './components/AmazonListingInformation'
import { AmazonListingInventoryCard } from './components/AmazonListingInventoryCard'
import { AmazonListingMarketplaceSelect } from './components/AmazonListingMarketplaceSelect'
import { AmazonListingOrdersCard } from './components/AmazonListingOrdersCard'
import { AmazonListingReturnsCard } from './components/AmazonListingReturnsCard'

export const AmazonListingView = () => {
  const user = useGlobalStore((state) => state.user)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const params = useParams<{
    productUuid: string
    productVariantUuid: string
    amazonListingUuid: string
  }>()
  const { t } = useTranslation('inventory')

  const { loading, error, data } = useQuery(GetAmazonListingDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    skip: !params.amazonListingUuid,
    variables: {
      uuid: params.amazonListingUuid!,
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', {
          ns: 'translation',
        })}
      />
    )
  }

  const amazonListing = data?.amazonListing
  const userRole = getUserRole(user, selectedCompany)
  const hasFullAccess = userRole === 'company:admin' || user?.isAdmin

  return (
    <>
      <SubHeader
        heading={amazonListing?.sku}
        withBackButton
        onBackUrl={`/products/${params.productUuid}/variants/${params.productVariantUuid}`}
        rightContent={<AmazonListingMarketplaceSelect amazonListing={amazonListing} />}
      />
      <ViewContainer>
        {amazonListing && (
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Row gutter={[32, 16]}>
                <Col xs={24} sm={12} md={8} xxl={6}>
                  <AmazonListingImages amazonListing={amazonListing} />
                </Col>
                <Col xs={24} md={0}>
                  <Divider style={{ margin: '0.5rem 0' }} />
                </Col>
                <Col xs={24} md={16} xxl={12}>
                  <AmazonListingContent amazonListing={amazonListing} />
                </Col>
                <Col xs={24} md={0}>
                  <Divider style={{ margin: '0.5rem 0' }} />
                </Col>
                <Col xs={24} xxl={6}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <AmazonListingInformation amazonListing={amazonListing} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Divider style={{ margin: '0.5rem 0' }} />
            </Col>
            {hasFullAccess && (
              <Col xs={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} xxl={12}>
                    <AmazonListingOrdersCard orderLineItems={amazonListing?.orderLineItems ?? []} />
                  </Col>
                  <Col xs={24} xxl={12}>
                    <AmazonListingReturnsCard returns={amazonListing?.returns ?? []} />
                  </Col>
                  <Col xs={24}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} xxl={12}>
                        <AmazonListingInventoryCard inventory={amazonListing?.inventory} />
                      </Col>
                      <Col xs={24} xxl={12}>
                        <AmazonListingDocumentsCard
                          amazonListing={amazonListing}
                          loading={loading}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        )}
      </ViewContainer>
    </>
  )
}
