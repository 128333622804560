import { ColDef, ColGroupDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Flex, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components/FormattedDate'
import { Table } from '../../../../components/Table/Table'
import { AmazonOrderStatus, Marketplace } from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { TablePersistKey } from '../../../../stores/useTableStore'

export type AmazonOrderTableRecord = {
  name: string
  asin: string
  sku: string
  internalSku: string | null
  marketplace: Marketplace
  quantity: number
  amount: number
  currency?: string | null
  status: AmazonOrderStatus
  purchaseDate: string
  searchIdentifiers: string
}

type AmazonOrderTableProps = {
  orders: AmazonOrderTableRecord[]
  gridRef: React.MutableRefObject<AgGridReact<AmazonOrderTableRecord> | null>
}

export const AmazonOrderTable = ({ orders, gridRef }: AmazonOrderTableProps) => {
  const { t } = useTranslation(['inventory', 'translation'])

  const [columnDefs] = useState<
    (ColDef<AmazonOrderTableRecord> | ColGroupDef<AmazonOrderTableRecord>)[]
  >([
    {
      field: 'name',
      headerName: t('returns.table.column.name'),
      getQuickFilterText: (params) => {
        return params.data?.searchIdentifiers
      },
    },
    {
      field: 'asin',
      headerName: t('returns.table.column.asin'),
    },
    {
      field: 'internalSku',
      headerName: t('returns.table.column.internalSku'),
    },
    {
      field: 'sku',
      headerName: t('returns.table.column.sku'),
    },
    {
      headerName: t('returns.table.column.marketplace'),
      valueGetter: (params: ValueGetterParams<AmazonOrderTableRecord>) => {
        return params.data ? `Amazon.${getMarketplaceDomain(params.data.marketplace)}` : null
      },
    },
    {
      field: 'quantity',
      headerName: t('product.details.orders.table.headers.quantity'),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'amount',
      headerName: t('product.details.orders.table.headers.price'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<AmazonOrderTableRecord, AmazonOrderTableRecord['amount']>
      ) => {
        if (!params.data?.amount || !params.data?.currency) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Typography.Text>{formatMoney(params.data.amount, params.data.currency)}</Typography.Text>
        )
      },
    },
    {
      field: 'status',
      headerName: t('product.details.orders.table.headers.status'),
      cellRenderer: (
        params: ICellRendererParams<AmazonOrderTableRecord, AmazonOrderTableRecord['status']>
      ) => {
        if (
          params.data?.status === AmazonOrderStatus.UNSHIPPED ||
          params.data?.status === AmazonOrderStatus.PENDING
        ) {
          return <Tag color="warning">{t('shared.order.pending', { ns: 'translation' })}</Tag>
        } else if (params.data?.status === AmazonOrderStatus.SHIPPED) {
          return <Tag color="success">{t('shared.order.shipped', { ns: 'translation' })}</Tag>
        } else if (params.data?.status === AmazonOrderStatus.CANCELED) {
          return <Tag color="error">{t('shared.order.canceled', { ns: 'translation' })}</Tag>
        } else {
          return t('shared.notAvailable', { ns: 'translation' })
        }
      },
    },
    {
      field: 'purchaseDate',
      headerName: t('product.details.orders.table.headers.purchaseDate'),
      filter: 'agDateColumnFilter',
      initialSort: 'desc',
      filterValueGetter: (params) => {
        if (params.data) {
          return dayjs(params.data.purchaseDate).startOf('day').toDate()
        }

        return null
      },
      cellRenderer: (params: ICellRendererParams<AmazonOrderTableRecord['purchaseDate']>) => {
        return (
          <Flex align="center" justify="center">
            <FormattedDate date={dayjs(params.value)} />
          </Flex>
        )
      },
    },
  ])

  return (
    <Table<AmazonOrderTableRecord>
      columnDefs={columnDefs}
      persist={{
        key: TablePersistKey.AMAZON_ORDER,
        storage: localStorage,
      }}
      ref={gridRef}
      rowData={orders}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
      suppressContextMenu
    />
  )
}
