import { Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { CreateBrandModal } from '../../../products/components/CreateBrandModal'

export const BrandManagementTableHeader = () => {
  const { t } = useTranslation()

  return (
    <Flex justify="space-between" align="middle">
      <Typography.Text strong style={{ fontSize: '1rem' }}>
        {t('settings.brand.table.heading')}
      </Typography.Text>
      <CreateBrandModal />
    </Flex>
  )
}
