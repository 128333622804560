import Papa from 'papaparse'

export const useExportToTsv = () => {
  const exportToTsv = async <T>(data: T[], filename: string): Promise<void> => {
    try {
      const headerRows = [
        'Default prep owner\tSeller',
        'Default labeling owner\tSeller',
        '', // Empty row
      ].join('\n')

      const dataRows = Papa.unparse(data, {
        delimiter: '\t',
      })

      const fullContent = headerRows + '\n' + dataRows

      const blob = new Blob([fullContent], { type: 'text/plain;charset=utf-8;' })
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${filename}.txt`)
      link.style.visibility = 'hidden'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error(error)
      throw new Error()
    }
  }

  return { exportToTsv }
}
