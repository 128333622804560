import { Button, ButtonProps } from 'antd'
import { ReactNode, Ref } from 'react'
import { Link, To } from 'react-router'

type LinkButtonProps = ButtonProps & {
  to: To
  state?: unknown
  children?: ReactNode
  ref?: Ref<HTMLAnchorElement>
}

export const LinkButton = ({ to, state, children, ref, ...buttonProps }: LinkButtonProps) => {
  return (
    <Link to={to} state={state} ref={ref} style={{ display: 'flex' }}>
      <Button {...buttonProps}>{children}</Button>
    </Link>
  )
}
