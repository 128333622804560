import styled from '@emotion/styled'
import { Card, Collapse } from 'antd'

export const TicketMainCollapse = styled(Collapse)`
  background-color: transparent;

  > .ant-collapse-item {
    > .ant-collapse-header {
      border-radius: 8px !important;
      background-color: ${({ theme }) => theme.colors.primary}80;

      :hover,
      :focus {
        background-color: ${({ theme }) => theme.colors.primary}bf;
      }
    }

    > .ant-collapse-content {
      margin-top: 0.5rem;

      > .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
`

export const TicketSubCollapse = styled(Collapse)`
  background-color: ${({ theme }) => theme.colors.base[3]};

  li {
    margin-bottom: 0.5rem;
  }
`

export const TicketMainPanel = styled(Collapse.Panel)``

export const TicketSubPanel = styled(Collapse.Panel)``

export const TicketCreationCard = styled(Card)`
  width: 100%;
  max-width: 840px;
`
