import { useState } from 'react'
import { AttachmentProps } from '../ticket.interface'
import { useLocation, useNavigate } from 'react-router'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { useTranslation } from 'react-i18next'
import { Button, Form, Row, Typography } from 'antd'
import { useMutation } from '@apollo/client'
import { SendSupportTicketDocument } from '../../../generated/graphql'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { createZendeskTicketOptions } from '../helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../ticket.enum'
import { toast } from 'sonner'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { TicketCreationCard } from '../styles/TicketCreateViewStyles'
import QuillTextarea from '../../../components/Ticket/QuillTextarea'
import { PersonalNoteInput } from '../components/form/PersonalNoteInput'
import { UploadZendeskFile } from '../../../components/Ticket/UploadFile'

type FormInstance = {
  messageInput: string
  personalNotes: string
}
export const ShopifyShopSetupTicketView = () => {
  const [messageInputContainsText, setMessageInputContainsText] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const location = useLocation()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()

  const { t, ready, i18n } = useTranslation(['ticket'], {
    keyPrefix: 'shopify.shopifyShopSetupTicketView',
  })
  const { t: generalTranslation } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [form] = Form.useForm()

  const [sendSupportTicketRequest, { loading }] = useMutation(SendSupportTicketDocument)

  if (!ready) {
    return <LoadingSpinner />
  }

  const onFinish = async (values: FormInstance) => {
    const tokens = attachments.map((attachment) => {
      return attachment.token
    })

    const options = createZendeskTicketOptions({
      categoryTitle: location.state.categoryTitleTag,
      comment: values.messageInput,
      language: i18n.language,
      mainCategory: TicketMainCategory.SHOPIFY,
      subject: t('viewTitle'),
      ticketCategory: TicketCategory.SHOPIFY_SHOP_SETUP,
      sellerId: selectedCompany?.sellerId,
      personalNotes: values.personalNotes,
      attachments: tokens,
    })

    try {
      if (selectedCompany !== null && selectedCompany.uuid) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })
        toast.success(generalTranslation('notification.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      console.error(error)
      toast.error(generalTranslation('notification.error'), { description: error.message })
    }
  }

  const handleQuillOnChange = (value: string) => {
    const valueWithoutMarkup = value.replace(/<{1}[^<>]{1,}>{1}/g, ' ').trim()

    setMessageInputContainsText(!!valueWithoutMarkup?.length)
  }

  return (
    <>
      <SubHeader withBackButton />
      <ViewContainer centered>
        <TicketCreationCard title={t('ticketDescriptionTitle')}>
          <Typography.Paragraph>{t('ticketDescriptionSubtitle')}</Typography.Paragraph>
          <Typography.Paragraph>{t('spacegoatsSignature')}</Typography.Paragraph>
          <Form<FormInstance>
            name="shopify-shop-setup-ticket"
            form={form}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item name="messageInput">
              <QuillTextarea onChange={handleQuillOnChange} />
            </Form.Item>
            <PersonalNoteInput />
            <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!messageInputContainsText}
                loading={loading}
              >
                {t('sendButtonText')}
              </Button>
            </Row>
          </Form>
        </TicketCreationCard>
      </ViewContainer>
    </>
  )
}
