import { CreditNoteRecord, InvoiceRecord } from '../components/BillingDocumentsTable'

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const download = async (url: string, filename: string) => {
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const downloadMultiple = async (documents: (InvoiceRecord | CreditNoteRecord)[]) => {
  for (const document of documents.values()) {
    if (!document.pdfUrl) continue

    await download(document.pdfUrl, document.documentNumber)

    await delay(1000)
  }
}
