import { Auth, Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { AmazonListingImages } from '../../../../generated/graphql'
import { AmazonListingImagesUpload } from '../components/EditAmazonListingImagesModal/EditAmazonListingImagesModal'

export async function uploadImagesAndGetImageUrls(
  images: AmazonListingImagesUpload,
  companyUuid: string
): Promise<AmazonListingImages | undefined> {
  if (typeof images === 'undefined') {
    return undefined
  }

  const timestamp = dayjs().unix()
  const formattedImageUrls: Record<string, string> = {}

  try {
    await Promise.all(
      Object.values(images).map(async (image, index) => {
        if (!image) {
          return Promise.resolve()
        }

        const fileName = `${companyUuid}_${Object.keys(images)[index]}_${timestamp}_${index}`

        try {
          await Storage.put(fileName, image.originFileObj, {
            contentType: image.type,
            level: 'protected',
            customPrefix: {
              protected: 'images/',
            },
          })

          const formattedImageUrlIndex = Object.keys(images)[index]

          if (!formattedImageUrlIndex) {
            return Promise.resolve()
          }

          formattedImageUrls[formattedImageUrlIndex] = await formatImageUrl(fileName)
        } catch (error) {
          return Promise.reject(error)
        }
      })
    )

    return formattedImageUrls
  } catch (error) {
    console.error(error)
    return undefined
  }
}

async function formatImageUrl(fileName: string): Promise<string> {
  const bucketName = import.meta.env.VITE_AWS_S3_BUCKET_NAME
  const region = import.meta.env.VITE_AWS_REGION
  const { identityId } = await Auth.currentUserCredentials()

  return `https://${bucketName}.s3-${region}.amazonaws.com/images/${identityId}/${fileName}`
}
