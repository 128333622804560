import { ExternalAmazonListingStatus } from '../../../../generated/graphql'
import { getAmazonListingExternalStatusText } from '../helpers/getAmazonListingExternalStatusText'
import { useTranslation } from 'react-i18next'
import { Tag } from 'antd'
import { getAmazonListingExternalStatusColor } from '../helpers/getAmazonListingExternalStatusColor'

type AmazonListingExternalStatusTagProps = {
  externalStatus: ExternalAmazonListingStatus
}

export const AmazonListingExternalStatusTag = ({
  externalStatus,
}: AmazonListingExternalStatusTagProps) => {
  const { t } = useTranslation()
  const externalStatusColor = getAmazonListingExternalStatusColor(externalStatus)
  const externalStatusText = getAmazonListingExternalStatusText(externalStatus, t)

  if (externalStatusText && externalStatusColor) {
    return <Tag color={externalStatusColor}>{externalStatusText}</Tag>
  }

  return null
}
