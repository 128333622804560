import { Auth } from 'aws-amplify'
import mixpanel from 'mixpanel-browser'
import { AuthStatus, useGlobalStore } from '../stores/useGlobalStore'

export function useLogout() {
  const setAuthStatus = useGlobalStore((state) => state.setAuthStatus)
  const setCognitoUser = useGlobalStore((state) => state.setCognitoUser)
  const setUser = useGlobalStore((state) => state.setUser)
  const setSelectedCompany = useGlobalStore((state) => state.setSelectedCompany)

  mixpanel.reset()

  return async () => {
    await Auth.signOut()
    setAuthStatus(AuthStatus.UNAUTHENTICATED)
    setCognitoUser(null)
    setUser(null)
    setSelectedCompany(null)
  }
}
