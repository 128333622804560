import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components/FormattedDate'
import { Table } from '../../../../components/Table/Table'
import {
  AmazonListingFieldsFragment,
  AmazonReturnFieldsFragment,
} from '../../../../generated/graphql'
import { Flex } from 'antd'

type AmazonListingReturnTableProps = {
  returns: AmazonListingFieldsFragment['returns']
}

export const AmazonListingReturnTable = ({ returns }: AmazonListingReturnTableProps) => {
  const { t } = useTranslation(['inventory', 'translation'])

  const [columnDefs] = useState<
    (ColDef<AmazonReturnFieldsFragment> | ColGroupDef<AmazonReturnFieldsFragment>)[]
  >([
    {
      field: 'quantity',
      headerName: t('returns.table.column.quantity'),
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: t('returns.table.column.reason'),
      valueGetter: (params) => {
        return params.data
          ? t(`returns.reason.${params.data.reason}`)
          : t('translation:shared.notAvailable')
      },
    },
    {
      field: 'customerComment',
      headerName: t('returns.table.column.comment'),
      filter: false,
    },
    {
      field: 'returnDate',
      headerName: t('returns.table.column.returnDate'),
      filter: false,
      initialSort: 'desc',
      cellRenderer: (
        params: ICellRendererParams<
          AmazonReturnFieldsFragment,
          AmazonReturnFieldsFragment['returnDate']
        >
      ) => {
        return (
          <Flex align="center" justify="center">
            <FormattedDate date={dayjs(params.value)} />
          </Flex>
        )
      },
    },
  ])

  return (
    <Table<AmazonReturnFieldsFragment> columnDefs={columnDefs} maxTableRows={5} rowData={returns} />
  )
}
