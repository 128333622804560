import { Button } from 'antd'
import { CircleHelp, Plus, X } from 'lucide-react'
import { GetAllAmazonListingsByCompanyAndMarketplaceQuery } from '../../../../../../generated/graphql'
import {
  MissingImagePlaceholder,
  ProductImageThumbnailContainer,
  ProductListing,
  ProductSelectActionContainer,
  ProductSelectDescriptionContainer,
  ProductSku,
  ProductTitle,
} from './styles'

type AmazonListing = GetAllAmazonListingsByCompanyAndMarketplaceQuery['amazonListings'][number]

type AmazonListingTileProps = {
  amazonListing: AmazonListing
  selected: boolean
  handleOnClick: Function
}

const AmazonListingTile = ({ amazonListing, selected, handleOnClick }: AmazonListingTileProps) => {
  const imageUrl = amazonListing?.images?.mainImage ?? ''

  const content = imageUrl ? (
    <img src={imageUrl} alt={amazonListing.title!} />
  ) : (
    <MissingImagePlaceholder>
      <CircleHelp size={16} data-testid="missing-image-icon" />
    </MissingImagePlaceholder>
  )

  const buttonIcon = selected ? (
    <X size={16} data-testid="remove-product-btn" />
  ) : (
    <Plus size={16} data-testid="add-product-btn" />
  )

  return (
    <ProductListing>
      <ProductImageThumbnailContainer>{content}</ProductImageThumbnailContainer>
      <ProductSelectDescriptionContainer>
        <ProductTitle>{amazonListing.title}</ProductTitle>
        <ProductSku>{amazonListing.sku}</ProductSku>
      </ProductSelectDescriptionContainer>
      <ProductSelectActionContainer>
        <Button type="text" icon={buttonIcon} onClick={() => handleOnClick(amazonListing)} />
      </ProductSelectActionContainer>
    </ProductListing>
  )
}

export default AmazonListingTile
