import * as Sentry from '@sentry/react'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { Suspense } from 'react'
import { RouterProvider } from 'react-router'
import './App.css'
import { Providers } from './Providers'
import { router } from './router/root'
import { AuthStatus, useGlobalStore } from './stores/useGlobalStore'

Auth.currentAuthenticatedUser()
  .then((cognitoUser: CognitoUser) => {
    useGlobalStore.setState({ cognitoUser })
  })
  .catch(() => {
    useGlobalStore.setState({
      authStatus: AuthStatus.UNAUTHENTICATED,
      cognitoUser: null,
      user: null,
      selectedCompany: null,
    })
  })

const App = () => (
  <Suspense>
    <Providers>
      <RouterProvider router={router} />
    </Providers>
  </Suspense>
)

export default Sentry.withProfiler(App)
