import { Route, Routes } from 'react-router'
import TicketCreateView from './TicketCreateView'
import TicketListView from './TicketListView'
import TicketReadView from './TicketReadView'
import {
  ChangeCompanyNameTicketView,
  EditProfileDataTicketView,
  GeneralAccountTicketView,
  OnboardingTicketView,
  ProductSafetyAndComplianceTicketView,
} from './account'
import { DisputeProductReviewTicketView, RequestToAmazonTicketView } from './amazon'
import {
  BillingGalaxyAreaTicketView,
  BillingGeneralTicketView,
  BillingPaymentsTicketView,
  BillingProcessQuestionsTicketView,
  BillingTaxTicketView,
} from './billing'
import { BrandAuthorisationTicketView, BrandStoreTicketView, GeneralBrandTicketView } from './brand'
import {
  EuComplianceCheckTicketView,
  GeneralComplianceTicketView,
  UkComplianceCheckTicketView,
  UsComplianceCheckTicketView,
} from './compliance'
import { GeneralSpacegoatsTicketView } from './general'
import {
  ChangeReturnAddressTicketView,
  CustomerRefundTicketView,
  GeneralInventoryTicketView,
  ReturnOrderTicketView,
  UnsellableStockTicketView,
} from './inventory'
import {
  EbcaContentTicketView,
  EditProductInformationTicketView,
  ErrorReportTicketView,
  GeneralListingTicketView,
  PictureUpdateTicketView,
  PriceloopTicketView,
} from './listing'
import {
  CreatePpcTicketView,
  EditPpcTicketView,
  GeneralPpcTicketView,
  PausePpcTicketView,
  TransferPpcTicketView,
} from './ppc'
import {
  CreateCouponTicketView,
  DiscountTicketView,
  GeneralPromotionTicketView,
  LightningDealsTicketView,
  PrimeDealsTicketView,
} from './promotion'
import {
  CreateShippingOrderTicketView,
  GeneralShipmentFromCnToUsTicketView,
  GeneralShipmentFromDeToUsTicketView,
  GeneralShipmentTicketView,
  PackageDeliveryTicketView,
  PreFbaTicketView,
  ReimbursementStatusTicketView,
} from './shipment'
import {
  GeneralShopifyTicketView,
  ShopifyEndCustomerTicketView,
  ShopifyShopSetupTicketView,
} from './shopify'
import {
  EbookMailingOrEvaluationRequestTicketView,
  GeneralThirdPartyTicketView,
  ReportAnErrorOrBugTicketView,
} from './thirdParty'

const TicketRoutes = () => (
  <Routes>
    {/* General */}
    <Route index element={<TicketListView />} />
    <Route path="new" element={<TicketCreateView />} />
    <Route path=":id" element={<TicketReadView />} />
    {/* Advertising */}
    <Route path="ppc/create" element={<CreatePpcTicketView />} />
    <Route path="ppc/edit" element={<EditPpcTicketView />} />
    <Route path="ppc/pause" element={<PausePpcTicketView />} />
    <Route path="ppc/transfer" element={<TransferPpcTicketView />} />
    <Route path="ppc/general" element={<GeneralPpcTicketView />} />
    {/* Shipping */}
    <Route path="shipments/package-delivery/create" element={<PackageDeliveryTicketView />} />
    <Route path="shipments/reimbursement/status" element={<ReimbursementStatusTicketView />} />
    <Route path="shipments/fba/create" element={<PreFbaTicketView />} />
    <Route path="shipments/cn-to-us" element={<GeneralShipmentFromCnToUsTicketView />} />
    <Route path="shipments/de-to-us" element={<GeneralShipmentFromDeToUsTicketView />} />
    <Route path="shipments/general" element={<GeneralShipmentTicketView />} />
    {/* Inventory */}
    <Route path="inventory/shipping-order/create" element={<CreateShippingOrderTicketView />} />
    <Route
      path="inventory/unsellable-stock-preference/edit"
      element={<UnsellableStockTicketView />}
    />
    <Route path="inventory/return-order/create" element={<ReturnOrderTicketView />} />
    <Route path="inventory/product/refund/create" element={<CustomerRefundTicketView />} />
    <Route path="inventory/return/create" element={<ChangeReturnAddressTicketView />} />
    <Route path="inventory/general" element={<GeneralInventoryTicketView />} />
    {/* Listing */}
    <Route path="listings/product/picture/edit" element={<PictureUpdateTicketView />} />
    <Route path="listings/ebca-content/create" element={<EbcaContentTicketView />} />
    <Route path="listings/error-report/create" element={<ErrorReportTicketView />} />
    <Route path="listings/priceloop" element={<PriceloopTicketView />} />
    <Route path="listings/product/edit" element={<EditProductInformationTicketView />} />
    <Route path="listings/general" element={<GeneralListingTicketView />} />
    {/* Promotion */}
    <Route path="promo/coupon/create" element={<CreateCouponTicketView />} />
    <Route path="promo/deals/lightning/create" element={<LightningDealsTicketView />} />
    <Route path="promo/deals/prime/create" element={<PrimeDealsTicketView />} />
    <Route path="promo/discount/create" element={<DiscountTicketView />} />
    <Route path="promo/general" element={<GeneralPromotionTicketView />} />
    {/* Brand Rights */}
    <Route path="brand/store/create" element={<BrandStoreTicketView />} />
    <Route path="brand/authorize/create" element={<BrandAuthorisationTicketView />} />
    <Route path="brand/general" element={<GeneralBrandTicketView />} />
    {/* Compliance */}
    <Route path="compliance/create" element={<UkComplianceCheckTicketView />} />
    <Route path="compliance/eu/product" element={<EuComplianceCheckTicketView />} />
    <Route path="compliance/us/product" element={<UsComplianceCheckTicketView />} />
    <Route path="compliance/general" element={<GeneralComplianceTicketView />} />
    {/* Billing */}
    <Route path="galaxy/billing/process" element={<BillingProcessQuestionsTicketView />} />
    <Route path="galaxy/billing/payment" element={<BillingPaymentsTicketView />} />
    <Route path="galaxy/billing/tax" element={<BillingTaxTicketView />} />
    <Route path="galaxy/billing/galaxy" element={<BillingGalaxyAreaTicketView />} />
    <Route path="galaxy/billing/general" element={<BillingGeneralTicketView />} />
    {/* Account */}
    <Route path="galaxy/company/name/update" element={<ChangeCompanyNameTicketView />} />
    <Route path="galaxy/profile/edit" element={<EditProfileDataTicketView />} />
    <Route path="galaxy/onboarding" element={<OnboardingTicketView />} />
    <Route path="galaxy/compliance/create" element={<ProductSafetyAndComplianceTicketView />} />
    <Route path="galaxy/general" element={<GeneralAccountTicketView />} />
    {/* 3rd Party */}
    <Route path="galaxy/third-party/create" element={<ReportAnErrorOrBugTicketView />} />
    <Route
      path="galaxy/third-party/ebook/create"
      element={<EbookMailingOrEvaluationRequestTicketView />}
    />
    <Route path="galaxy/third-party/general" element={<GeneralThirdPartyTicketView />} />
    {/* Galaxy General */}
    <Route path="galaxy/general/create" element={<GeneralSpacegoatsTicketView />} />
    {/* Amazon Support - Product */}
    <Route path="amazon/product/dispute/create" element={<DisputeProductReviewTicketView />} />
    <Route path="amazon/general" element={<RequestToAmazonTicketView />} />
    {/* Shopify */}
    <Route path="shopify/general" element={<GeneralShopifyTicketView />} />
    <Route path="shopify/shop-setup" element={<ShopifyShopSetupTicketView />} />
    <Route path="shopify/end-customer" element={<ShopifyEndCustomerTicketView />} />
  </Routes>
)

export default TicketRoutes
