import { Table } from 'antd'
import { formatCurrency } from '../../../../../../views/ppc/helpers/formatCurrency'
import { useCurrencySymbol } from '../../../../../../views/ppc/helpers/useCurrencySymbol'
import { PPCBulkEditTargetBid } from '../../../../../../views/ppc/helpers/interfaces'

type BulkEditTargetBidTableProps = {
  targets: PPCBulkEditTargetBid[]
  loading: boolean
}

const BulkEditTargetBidTable = ({ targets, loading }: BulkEditTargetBidTableProps) => {
  const currencySymbol = useCurrencySymbol()

  const columns = [
    {
      title: 'Target',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Bid',
      children: [
        {
          title: 'Current',
          dataIndex: 'bid',
          key: 'bid',
          render: (bid: number) => formatCurrency(currencySymbol, bid),
        },
        {
          title: 'New',
          dataIndex: 'newBid',
          key: 'newBid',
          render: (bid: number) => formatCurrency(currencySymbol, bid),
        },
      ],
    },
  ]

  return (
    <Table
      dataSource={targets}
      columns={columns}
      pagination={false}
      bordered={true}
      rowKey="targetId"
      loading={loading}
      scroll={{
        y: 300,
      }}
    />
  )
}

export default BulkEditTargetBidTable
