import { Button, Descriptions, Flex, FormInstance, Modal, Tag } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../../../../components/FormattedDate'
import {
  ActionType,
  S3BucketActionButton,
} from '../../../../../../../components/S3BucketActionButton'
import { WholesaleOrderBillingDocumentType } from '../../../../../../../generated/graphql'
import { descriptionsStyle } from '../../../../../../products/components/styles'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'
import { WholesaleOrderBillingDocumentCreateFormSummaryTable } from './WholesaleOrderBillingDocumentCreateFormSummaryTable'

type WholesaleOrderBillingDocumentCreateFormSummaryModalProps = {
  form: FormInstance<CreateWholesaleOrderBillingDocumentFormValues>
  submitting: boolean
}

export const WholesaleOrderBillingDocumentCreateFormSummaryModal = ({
  form,
  submitting,
}: WholesaleOrderBillingDocumentCreateFormSummaryModalProps) => {
  const [open, setOpen] = useState(false)

  const { t } = useTranslation(['wholesale-order-billing-document', 'translation'])

  const type = useWatch('type', form)
  const issueDate = useWatch('issueDate', form)
  const dueDate = useWatch('dueDate', form)
  const documentNumber = useWatch('documentNumber', form)
  const bucketKey = useWatch('bucketKey', form)
  const lineItems = useWatch('lineItems', form)

  return (
    <>
      <Button
        type="primary"
        loading={submitting}
        onClick={async () => {
          await form.validateFields()
          setOpen(true)
        }}
      >
        {t('wholesaleOrderBillingDocumentCreateView.button.submit')}
      </Button>
      <Modal
        title={t('wholesaleOrderBillingDocumentCreateView.summary.title')}
        open={open}
        okText={t('wholesaleOrderBillingDocumentCreateView.button.confirm')}
        okButtonProps={{ loading: submitting }}
        onOk={() => form.submit()}
        cancelText={t('shared.button.back', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        width="90%"
      >
        <Flex vertical gap={16}>
          <Descriptions {...descriptionsStyle}>
            <Descriptions.Item label={t('billingDocumentTypeSelect.label')}>
              <WholesaleOrderBillingDocumentTypeTag type={type} />
            </Descriptions.Item>
            <Descriptions.Item label={t('issueDate.label')}>
              <FormattedDate date={issueDate} format="DD.MM.YYYY" />
            </Descriptions.Item>
            <Descriptions.Item label={t('dueDate.label')}>
              <FormattedDate date={dueDate} format="DD.MM.YYYY" />
            </Descriptions.Item>
            <Descriptions.Item label={t('documentNumber.label')}>
              {documentNumber}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('wholesaleOrderBillingDocumentGeneralDetailsCard.bucketKey.label')}
            >
              <S3BucketActionButton
                actionType={ActionType.VIEW}
                bucketName={import.meta.env.VITE_AWS_S3_BUCKET_NAME_WHOLESALE_ORDERS}
                objectKey={bucketKey}
                type="primary"
                size="small"
              >
                {t('wholesaleOrderBillingDocumentGeneralDetailsCard.bucketKey.button.view')}
              </S3BucketActionButton>
            </Descriptions.Item>
          </Descriptions>
          <WholesaleOrderBillingDocumentCreateFormSummaryTable selectedLineItems={lineItems} />
        </Flex>
      </Modal>
    </>
  )
}

const WholesaleOrderBillingDocumentTypeTag = ({
  type,
}: {
  type: WholesaleOrderBillingDocumentType
}) => {
  const { t } = useTranslation('wholesale-order-billing-document')

  switch (type) {
    case WholesaleOrderBillingDocumentType.INVOICE:
      return <Tag color="error">{t('billingDocumentTypeSelect.options.invoice')}</Tag>
    case WholesaleOrderBillingDocumentType.CREDIT_NOTE:
      return <Tag color="success">{t('billingDocumentTypeSelect.options.creditNote')}</Tag>
    case WholesaleOrderBillingDocumentType.INVOICE_CORRECTION:
      return <Tag color="warning">{t('billingDocumentTypeSelect.options.invoiceCorrection')}</Tag>
    default:
      return <Tag>{type}</Tag>
  }
}
