import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { GetAllWholesaleOrdersDocument } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { prepareWholesaleOrders } from './WholesaleOrdersTable/helpers/prepareWholesaleOrders'
import { WholesaleOrdersTable } from './WholesaleOrdersTable/WholesaleOrdersTable'

export const WholesaleOrdersContainer = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('wholesale-order')

  const { loading, error, data } = useQuery(GetAllWholesaleOrdersDocument, {
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  if (error) {
    return (
      <StatusResult status="500" title="500" subTitle={t('wholesaleOrdersView.error.general')} />
    )
  }

  const wholesaleOrders = data ? prepareWholesaleOrders(data.wholesaleOrders) : null

  return <WholesaleOrdersTable wholesaleOrders={wholesaleOrders} loading={loading} />
}
