import { useMutation, useQuery } from '@apollo/client'
import { Alert, Button, Form, Modal, Select, Space } from 'antd'
import { SquarePen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import {
  GetAllSalesChannelCategoriesDocument,
  GetProductDocument,
  UpdateProductSalesChannelCategoryDocument,
} from '../../../generated/graphql'

type EditProductSalesChannelCategoryModalFormInstance = {
  salesChannelCategory: string
}

type EditProductSalesChannelCategoryModalProps = {
  productUuid: string
  salesChannelCategory: string
}

export const EditProductSalesChannelCategoryModal = ({
  productUuid,
  salesChannelCategory,
}: EditProductSalesChannelCategoryModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { t } = useTranslation(['inventory', 'translation'])
  const [form] = Form.useForm<EditProductSalesChannelCategoryModalFormInstance>()

  const { data, loading, error } = useQuery(GetAllSalesChannelCategoriesDocument)

  const [updateProductSalesChannelCategory] = useMutation(UpdateProductSalesChannelCategoryDocument)

  if (error) {
    return (
      <StatusResult status="500" title="500" subTitle={t('translation:shared.error.message')} />
    )
  }

  const handleSubmit = async (values: EditProductSalesChannelCategoryModalFormInstance) => {
    setSubmitting(true)

    try {
      await updateProductSalesChannelCategory({
        variables: {
          uuid: productUuid,
          product: {
            salesChannelCategory: values.salesChannelCategory,
          },
        },
        update: (cache, { data }) => {
          const updatedProduct = data?.updateProduct
          const cachedQuery = cache.readQuery({
            query: GetProductDocument,
            variables: { uuid: productUuid },
          })

          if (updatedProduct && cachedQuery && updatedProduct.salesChannelCategory?.name) {
            cache.writeQuery({
              query: GetProductDocument,
              variables: { uuid: productUuid },
              data: {
                product: {
                  ...cachedQuery.product,
                  salesChannelCategory: {
                    name: updatedProduct.salesChannelCategory.name,
                    value: updatedProduct.salesChannelCategory.value,
                    __typename: 'SalesChannelCategory',
                  },
                },
              },
            })
          }
        },
      })

      toast.success(t('product.details.product.editTaxCategoryModal.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setOpen(false)
      setSubmitting(false)
    }
  }

  const options = data?.salesChannelCategories.map((salesChannelCategory) => ({
    value: salesChannelCategory.value,
    label: salesChannelCategory.name,
  }))

  return (
    <>
      <Button
        type="text"
        icon={<SquarePen size={16} />}
        size="small"
        onClick={() => setOpen(true)}
      />
      <Modal
        title={t('product.details.product.editSalesChannelCategoryModal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Space direction="vertical">
          <Alert
            type="info"
            message={t('product.details.product.editSalesChannelCategoryModal.note')}
          />
          <Form<EditProductSalesChannelCategoryModalFormInstance>
            form={form}
            initialValues={{ salesChannelCategory }}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="salesChannelCategory"
              label={t('product.details.product.editSalesChannelCategoryModal.label')}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder={t('product.details.product.editSalesChannelCategoryModal.placeholder')}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (!option) {
                    return false
                  }

                  return option.label.toLowerCase().includes(input.toLowerCase())
                }}
                options={options}
                defaultValue={salesChannelCategory}
                loading={loading}
              />
            </Form.Item>
          </Form>
        </Space>
      </Modal>
    </>
  )
}
