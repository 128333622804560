import { Flex } from 'antd'
import { User } from 'lucide-react'
import { StyledMainCard } from '../../views/dashboard/styles'

type UserMenuTitleProps = {
  firstName: string
  lastName: string
}

export const UserMenuTitle = ({ firstName, lastName }: UserMenuTitleProps) => {
  return (
    <StyledMainCard styles={{ body: { paddingLeft: '1.25rem', paddingRight: '1.25rem' } }}>
      <Flex align="center" gap={8}>
        <User size={16} />
        <span>
          {firstName} {lastName}
        </span>
      </Flex>
    </StyledMainCard>
  )
}
