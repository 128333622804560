import { Button, Col, Form, Input, Row, Select } from 'antd'
import { Plus, Trash } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Marketplace } from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'

export const SkuWithMarketplacesAndDescriptionOptionalDynamicInput = () => {
  const { t } = useTranslation(['ticket'], {
    keyPrefix: 'listing.editProductInformationTicketView.form',
  })

  const marketplaceOptions = useMarketplaceOptions()

  return (
    <Form.Item label={t('label')}>
      <Form.List name="issues">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key}>
                <Col span={2} style={{ marginBottom: 24 }}>
                  <Button
                    type="primary"
                    icon={<Trash size={16} />}
                    danger
                    onClick={() => remove(name)}
                    style={{ height: '100%' }}
                  />
                </Col>
                <Col span={22}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'sku']}
                        rules={[{ required: true, message: t('skuInput.validation.required') }]}
                      >
                        <Input placeholder={t('skuInput.placeholder')} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'marketplaces']}
                        rules={[
                          { required: true, message: t('marketplacesInput.validation.required') },
                        ]}
                      >
                        <Select<Marketplace>
                          mode="multiple"
                          options={marketplaceOptions.map((marketplace) => ({
                            value: marketplace,
                            label: `Amazon.${getMarketplaceDomain(marketplace)}`,
                          }))}
                          placeholder={t('marketplacesInput.placeholder')}
                          showSearch
                          filterOption={true}
                          optionFilterProp="label"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'description']}
                        rules={[
                          { required: true, message: t('descriptionInput.validation.required') },
                        ]}
                      >
                        <Input.TextArea placeholder={t('descriptionInput.placeholder')} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed" block icon={<Plus size={16} />} onClick={() => add()}>
                {t('addMoreButton')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  )
}
