import { ColDef, GetContextMenuItems, GridOptions } from 'ag-grid-community'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { TablePersistKey } from '../../../../stores/useTableStore'
import { BankTransaction } from '../BankTransactionsView'
import { useBankTransactionTableColumnTypes } from './hooks/useBankTransactionTableColumnTypes'
import { useBankTransactionTableColumns } from './hooks/useBankTransactionTableColumns'
import { useBankTransactionTableContextMenuItems } from './hooks/useBankTransactionTableContextMenuItems'

type BankTransactionListProps = {
  bankTransactions: BankTransaction[] | null | undefined
}

export const BankTransactionList = ({ bankTransactions }: BankTransactionListProps) => {
  const { t } = useTranslation(['ag-grid'])
  const translation: Record<string, string> = t('tableDefaults', { returnObjects: true })
  const columnTypes = useBankTransactionTableColumnTypes()
  const columnDefs = useBankTransactionTableColumns()
  const getContextMenuItems: GetContextMenuItems<BankTransaction> =
    useBankTransactionTableContextMenuItems()

  const [defaultColDef] = useState<ColDef<BankTransaction>>({
    wrapText: false,
    wrapHeaderText: true,
    filter: true,
  })

  const rowData = useMemo(() => {
    return bankTransactions
  }, [bankTransactions])

  const gridOptionsDef = useMemo(
    (): GridOptions => ({
      columnDefs,
      defaultColDef,
      enableRangeSelection: true,
      defaultExcelExportParams: {
        fileName: `bank-transactions-${dayjs().format('DD-MM-YYYY')}`,
      },
    }),
    []
  )

  return (
    <Table<BankTransaction>
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      defaultColDef={defaultColDef}
      getContextMenuItems={getContextMenuItems}
      gridOptions={gridOptionsDef}
      localeText={translation}
      persist={{
        key: TablePersistKey.BANK_TRANSACTION,
        storage: localStorage,
      }}
      rowData={rowData}
      showFilterReset
      showUniversalSearch
    />
  )
}
