import { Button } from 'antd'
import { ButtonProps } from 'antd/es/button/button'
import { Storage } from 'aws-amplify'

export enum ActionType {
  VIEW = 'view',
  DOWNLOAD = 'download',
}

type S3BucketActionButtonProps = {
  bucketName: string
  objectKey: string
  actionType?: ActionType
} & ButtonProps

export const S3BucketActionButton = ({
  bucketName,
  objectKey,
  actionType = ActionType.VIEW,
  ...props
}: S3BucketActionButtonProps) => {
  const handleClick = async () => {
    try {
      const fileUrl = await Storage.get(objectKey, {
        bucket: bucketName,
      })

      if (actionType === ActionType.VIEW) {
        window.open(fileUrl, '_blank')
      } else {
        const response = await fetch(fileUrl)
        const blob = await response.blob()
        const blobUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = objectKey
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Button onClick={handleClick} {...props}>
      {props.children}
    </Button>
  )
}
