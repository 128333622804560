import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Button, Flex } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { toast } from 'sonner'
import { HTMLContentDiv } from '../../../components/HTMLContentDiv'
import {
  CompanyType,
  UpdateProductComplianceCheckContentDocument,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { StyledMainCard } from '../../dashboard/styles'

type ProductComplianceCheckContentCardProps = {
  productComplianceCheckIdentifier: string
  content: string
}

export const ProductComplianceCheckContentCard = ({
  productComplianceCheckIdentifier,
  content,
}: ProductComplianceCheckContentCardProps) => {
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [message, setMessage] = useState(content)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation(['compliance'])

  const isRetailer = selectedCompany.type === CompanyType.RETAILER

  const [updateProductComplianceCheckContent] = useMutation(
    UpdateProductComplianceCheckContentDocument
  )

  const handleQuillOnChange = (value: string) => {
    setMessage(value)
  }

  const handleEdit = async () => {
    if (!editing) {
      setEditing(true)
    } else {
      setLoading(true)
      try {
        await updateProductComplianceCheckContent({
          variables: {
            productComplianceCheckIdentifier: productComplianceCheckIdentifier,
            content: message,
          },
        })
      } catch (error) {
        console.error(error)
        toast.error(t('compliance:content.toast.error'))
      } finally {
        setLoading(false)
        setEditing(false)
        toast.success(t('compliance:content.toast.success'))
      }
    }
  }

  const handleCancel = () => {
    setEditing(false)
    setMessage(content)
  }

  return (
    <StyledMainCard
      title={t('compliance:content.title')}
      extra={
        isRetailer && (
          <Flex gap={8}>
            {editing && <Button onClick={handleCancel}>Cancel</Button>}
            <Button loading={loading} type={editing ? 'primary' : 'default'} onClick={handleEdit}>
              {editing ? 'Save' : 'Edit'}
            </Button>
          </Flex>
        )
      }
      styles={{ body: { height: '100%' } }}
    >
      {isRetailer && editing ? (
        <StyledQuillContainer style={{ height: '100%', paddingBottom: '1rem' }}>
          <ReactQuill
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, false] }],
                ['underline'],
                ['blockquote', 'link'],
                [{ list: 'ordered' }, { list: 'bullet' }],
              ],
            }}
            placeholder={t('compliance:content.placeholder')}
            onChange={handleQuillOnChange}
            value={message}
          />
        </StyledQuillContainer>
      ) : (
        <HTMLContentDiv dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </StyledMainCard>
  )
}

const StyledQuillContainer = styled.div`
  .quill {
    height: calc(100% - 34px);
  }

  .ql-toolbar {
    border: 1px solid ${({ theme }) => theme.colors.base[2]};
    border-radius: 6px 6px 0 0;
    background-color: ${({ theme }) => theme.colors.base[5]};
    height: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .ql-formats {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .ql-header {
        color: ${({ theme }) => theme.colors.text};

        .ql-picker-label {
          border: none;
        }

        .ql-picker-label:hover {
          color: ${({ theme }) => theme.colors.primary};

          svg {
            .ql-stroke {
              stroke: ${({ theme }) => theme.colors.primary};
            }
          }
        }

        .ql-picker-options {
          border: 1px solid ${({ theme }) => theme.colors.base[7]} !important;
          border-radius: 8px;
          background-color: ${({ theme }) => theme.colors.base[5]};
          color: ${({ theme }) => theme.colors.text};
          box-shadow: none;

          .ql-picker-item:hover {
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }

      svg {
        .ql-fill {
          fill: ${({ theme }) => theme.colors.text};
        }

        .ql-stroke {
          stroke: ${({ theme }) => theme.colors.text};
        }
      }

      .ql-active {
        svg {
          .ql-fill {
            fill: ${({ theme }) => theme.colors.primary};
          }

          .ql-stroke {
            stroke: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }

  .ql-container {
    border: 1px solid ${({ theme }) => theme.colors.base[6]};
    border-radius: 0 0 8px 8px;
    background-color: ${({ theme }) => theme.colors.base[3]};

    .ql-blank::before {
      color: ${({ theme }) => theme.colors.text}80;
      font-style: normal;
    }

    .ql-tooltip {
      border: 1px solid ${({ theme }) => theme.colors.base[6]};
      border-radius: 8px;
      background-color: ${({ theme }) => theme.colors.base[5]};
      color: ${({ theme }) => theme.colors.text};
      box-shadow: none;

      input {
        border: 1px solid ${({ theme }) => theme.colors.base[7]};
        background-color: ${({ theme }) => theme.colors.base[5]};
        color: ${({ theme }) => theme.colors.text};
      }

      a {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`
