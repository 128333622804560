import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { CompanyType } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { getWholesaleOrderStatus } from '../helpers/getWholesaleOrderStatus'

type WholesaleOrderStatusTagProps = {
  vendorApprovedAt?: string | null
  retailerApprovedAt?: string | null
}

export const WholesaleOrderStatusTag = ({
  vendorApprovedAt,
  retailerApprovedAt,
}: WholesaleOrderStatusTagProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const isVendor = selectedCompany.type === CompanyType.WHOLESALER
  const { t } = useTranslation('wholesale-order', {
    keyPrefix: 'wholesaleOrdersTable.wholesaleOrderStatusTag',
  })

  const status = getWholesaleOrderStatus({
    vendorApprovedAt,
    retailerApprovedAt,
    isVendor,
  })

  switch (status) {
    case 'done':
      return <Tag color="green">{t('done')}</Tag>

    case 'waitingForOtherParty':
      return <Tag color="blue">{t('waitingForOtherParty')}</Tag>

    case 'waitingForApproval':
      return <Tag color="yellow">{t('waitingForApproval')}</Tag>

    default:
      return null
  }
}
