import styled from '@emotion/styled'
import { Collapse, Divider, Flex, Tooltip, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { GetPerformanceMetricsQuery } from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { StyledMainCard } from '../../styles'
import { calculateAmazonProfit } from '../helpers/calculateAmazonProfit'
import { DashboardFinanceSummarySection } from './DashboardFinanceSummarySection'

type DashboardFinanceSummaryCardProps = {
  data?: GetPerformanceMetricsQuery
  timeFrame: string
  loading: boolean
}

export const DashboardFinanceSummaryCard = ({
  data,
  timeFrame,
  loading,
}: DashboardFinanceSummaryCardProps) => {
  const { t } = useTranslation('dashboard')

  const summaryData = prepareFinanceSummaryData(data)

  return (
    <StyledMainCard
      title={t('finances.summary.title')}
      extra={<Typography.Text>{timeFrame}</Typography.Text>}
      loading={loading}
    >
      <Flex vertical gap={16}>
        <DashboardFinanceSummarySection
          label={t('finances.shared.sales')}
          tooltip={t('finances.summary.tooltip.sales')}
          value={summaryData.sales}
          decimal
        />
        <DashboardFinanceSummarySection
          label={t('finances.shared.shipped')}
          value={summaryData.unitsSold}
        />
        <DashboardFinanceSummarySection
          label={t('finances.shared.refunds')}
          value={summaryData.refunds}
          decimal
        />
        <DashboardFinanceSummarySection
          label={t('finances.shared.fees')}
          value={summaryData.fees}
          decimal
        />
        <DashboardFinanceSummarySection
          label={t('finances.shared.promotions')}
          value={summaryData.promotions}
          decimal
        />
        <StyledFinanceSummaryCollapse
          ghost
          items={[
            {
              key: '1',
              label: (
                <Flex justify="space-between" gap={8}>
                  <Tooltip title={t('finances.summary.tooltip.advertising')}>
                    <Typography.Text>{t('finances.shared.advertising')}</Typography.Text>
                  </Tooltip>
                  <Typography.Text ellipsis>
                    {formatMoney(summaryData.advertisingCosts, 'EUR')}
                  </Typography.Text>
                </Flex>
              ),
              children: (
                <Flex vertical gap={16}>
                  <DashboardFinanceSummarySection
                    label={t('finances.shared.sponsoredProducts')}
                    value={summaryData.sponsoredProductsCosts}
                    decimal
                  />
                  <DashboardFinanceSummarySection
                    label={t('finances.shared.sponsoredDisplay')}
                    value={summaryData.sponsoredDisplayCosts}
                    decimal
                  />
                </Flex>
              ),
            },
          ]}
        />
        <Divider style={{ margin: 0 }} />
        <DashboardFinanceSummarySection
          label={t('finances.shared.amazonProfit')}
          tooltip={t('finances.summary.tooltip.amazonProfit')}
          value={summaryData.amazonProfit}
          decimal
        />
      </Flex>
    </StyledMainCard>
  )
}

function prepareFinanceSummaryData(data: GetPerformanceMetricsQuery | undefined) {
  const initialValues = {
    unitsSold: 0,
    sales: 0,
    refunds: 0,
    fees: 0,
    promotions: 0,
    advertisingCosts: 0,
    sponsoredProductsCosts: 0,
    sponsoredDisplayCosts: 0,
    amazonProfit: 0,
  }

  if (!data) {
    return initialValues
  }

  const summaryData = data.performanceMetrics.reduce(
    (previousValue, currentValue) => ({
      sales: previousValue.sales + (currentValue?.sales ?? 0),
      unitsSold: previousValue.unitsSold + (currentValue?.unitsSold ?? 0),
      refunds: previousValue.refunds + (currentValue?.refunds ?? 0),
      fees: previousValue.fees + (currentValue?.fees ?? 0),
      promotions: previousValue.promotions + (currentValue?.promotions ?? 0),
      advertisingCosts:
        previousValue.advertisingCosts +
        ((currentValue?.sponsoredProductsCosts ?? 0) + (currentValue?.sponsoredDisplayCosts ?? 0)),
      sponsoredProductsCosts:
        previousValue.sponsoredProductsCosts + (currentValue?.sponsoredProductsCosts ?? 0),
      sponsoredDisplayCosts:
        previousValue.sponsoredDisplayCosts + (currentValue?.sponsoredDisplayCosts ?? 0),
      amazonProfit:
        previousValue.amazonProfit +
        calculateAmazonProfit({
          sales: currentValue?.sales ?? 0,
          refunds: currentValue?.refunds ?? 0,
          fees: currentValue?.fees ?? 0,
          promotions: currentValue?.promotions ?? 0,
          sponsoredProductsCosts: currentValue?.sponsoredProductsCosts ?? 0,
          sponsoredDisplayCosts: currentValue?.sponsoredDisplayCosts ?? 0,
        }),
    }),
    initialValues
  )

  return {
    sales: summaryData.sales,
    unitsSold: summaryData.unitsSold,
    refunds: summaryData.refunds,
    fees: summaryData.fees,
    promotions: summaryData.promotions,
    advertisingCosts: summaryData.advertisingCosts ? summaryData.advertisingCosts * -1 : 0,
    sponsoredProductsCosts: summaryData.sponsoredProductsCosts
      ? summaryData.sponsoredProductsCosts * -1
      : 0,
    sponsoredDisplayCosts: summaryData.sponsoredDisplayCosts
      ? summaryData.sponsoredDisplayCosts * -1
      : 0,
    amazonProfit: summaryData.amazonProfit,
  }
}

const StyledFinanceSummaryCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    margin-left: 1.5rem;
    padding: 1rem 0 0 !important;
  }
`
