import { useTheme } from '@emotion/react'
import { ColDef, ColGroupDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community'
import { Flex, Tag, Tooltip, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { MoreItemsIndicator } from '../../../../../../components/MoreItemsIndicator'
import { formatMoney } from '../../../../../../helpers/formatMoney'
import { WholesaleOrderLineItemTableRecord } from '../WholesaleOrderLineItemTable'

export const useWholesaleOrderLineItemTableColumnDefs = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const { t } = useTranslation('wholesale-order', {
    keyPrefix: 'wholesaleOrderLineItemsTable.columnDefs',
  })

  const [columnDefs] = useState<
    (ColDef<WholesaleOrderLineItemTableRecord> | ColGroupDef<WholesaleOrderLineItemTableRecord>)[]
  >([
    {
      field: 'identifier',
      headerName: t('identifier'),
      filter: 'agTextColumnFilter',
      sort: 'asc',
    },
    {
      field: 'product.name',
      headerName: t('productName'),
      filter: 'agTextColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['product']['name']
        >
      ) => {
        if (!params.data) {
          return null
        }

        const productUuid = params.data.product.uuid

        return (
          <Typography.Link onClick={() => navigate(`/products/${productUuid}`)}>
            {params.data.product.name}
          </Typography.Link>
        )
      },
    },
    {
      field: 'product.tags',
      headerName: t('tags'),
      hide: true,
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['product']['tags']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }

        return params.value
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['product']['tags']
        >
      ) => {
        const tags = params.value

        if (!tags) {
          return null
        }

        const totalQuantity = tags.length

        if (totalQuantity === 0) {
          return null
        }

        return tags.map((item) => {
          return <Tag key={item}>{item}</Tag>
        })
      },
    },
    {
      field: 'productVariant.attributes',
      headerName: t('attributes'),
      hide: true,
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['productVariant']['attributes']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }

        return params.value
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['productVariant']['attributes']
        >
      ) => {
        const attributes = params.value

        if (!attributes) {
          return null
        }

        const totalQuantity = attributes.length

        if (totalQuantity === 0) {
          return null
        }

        return attributes.map((item) => {
          return <Tag key={item}>{item}</Tag>
        })
      },
    },
    {
      field: 'productVariant.ean',
      headerName: t('ean'),
      hide: true,
    },
    {
      field: 'productVariant.internalSku',
      headerName: t('internalSku'),
    },
    {
      field: 'asins',
      headerName: t('asins'),
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['asins']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }

        return params.value
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['asins']
        >
      ) => {
        const asins = params.value

        if (!asins) {
          return null
        }

        const totalQuantity = asins.length

        if (totalQuantity === 0) {
          return null
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{asins[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={asins} />}
          </Flex>
        )
      },
    },
    {
      field: 'skus',
      headerName: t('skus'),
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['skus']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }

        return params.value
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['skus']
        >
      ) => {
        const skus = params.value

        if (!skus) {
          return null
        }

        const totalQuantity = skus.length

        if (totalQuantity === 0) {
          return null
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{skus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={skus} />}
          </Flex>
        )
      },
    },
    {
      field: 'packagingUnit.quantity',
      headerName: t('packagingUnit.quantity'),
    },
    {
      field: 'quantityOfPackagingUnits',
      headerName: t('quantityOfPackagingUnits'),
    },
    {
      field: 'totalQuantity',
      headerName: t('totalQuantity'),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'netPurchasePrice',
      headerName: t('netPurchasePrice.title'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['netPurchasePrice']
        >
      ) => {
        const value = params.value
        const currency = params.data?.currency

        if (value === null || value === undefined || !currency) {
          return null
        }

        const priceDifference = params.data?.priceDifference

        return (
          <Flex gap={8}>
            <Typography.Text>{formatMoney(value, currency)}</Typography.Text>
            {priceDifference ? (
              <Tooltip title={t('netPurchasePrice.tooltip')}>
                <Typography.Text type="secondary">
                  {formatMoney(priceDifference, currency, true)}
                </Typography.Text>
              </Tooltip>
            ) : priceDifference === 0 ? null : (
              <Tooltip title={t('netPurchasePrice.newTooltip')}>
                <Tag color="success">{t('netPurchasePrice.new')}</Tag>
              </Tooltip>
            )}
          </Flex>
        )
      },
    },
    {
      field: 'netTotal',
      headerName: t('netTotal'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemTableRecord,
          WholesaleOrderLineItemTableRecord['netTotal']
        >
      ) => {
        const value = params.value
        const currency = params.data?.currency
        const aggData = params.node.aggData as Partial<WholesaleOrderLineItemTableRecord>

        if (aggData?.netTotal) {
          return (
            <Typography.Text style={{ color: theme.colors.primary }}>
              {formatMoney(aggData.netTotal, 'EUR')}
            </Typography.Text>
          )
        }

        if (value === null || value === undefined || !currency) {
          return null
        }

        return <Typography.Text>{formatMoney(value, currency)}</Typography.Text>
      },
    },
  ])

  return columnDefs
}
