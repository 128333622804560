import { useTheme } from '@emotion/react'
import { Button, Tooltip } from 'antd'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Package, PackageOpen } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { CompanyType } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { getWholesaleOrderStatus } from '../helpers/getWholesaleOrderStatus'

dayjs.extend(relativeTime)

type WholesaleOrderStatusIconProps = {
  vendorApprovedAt?: string | null
  retailerApprovedAt?: string | null
}

export const WholesaleOrderStatusIcon = ({
  vendorApprovedAt,
  retailerApprovedAt,
}: WholesaleOrderStatusIconProps) => {
  const theme = useTheme()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { t } = useTranslation('wholesale-order', {
    keyPrefix: 'wholesaleOrdersTable.wholesaleOrderStatusTag',
  })

  const status = getWholesaleOrderStatus({
    vendorApprovedAt,
    retailerApprovedAt,
    isVendor: selectedCompany.type === CompanyType.WHOLESALER,
  })

  switch (status) {
    case 'done':
      return (
        <Tooltip title={t('done')}>
          <Button
            style={{ cursor: 'default' }}
            icon={<Package size={16} color={theme.colors.success} />}
          />
        </Tooltip>
      )

    case 'waitingForOtherParty':
      return (
        <Tooltip title={t('waitingForOtherParty')}>
          <Button
            style={{ cursor: 'default' }}
            icon={<PackageOpen size={16} color={theme.colors.primary} />}
          />
        </Tooltip>
      )

    case 'waitingForApproval':
      return (
        <Tooltip title={t('waitingForApproval')}>
          <Button
            style={{ cursor: 'default' }}
            icon={<PackageOpen size={16} color={theme.colors.warning} />}
          />
        </Tooltip>
      )
    default:
      return null
  }
}
