import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popover,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { CircleHelp } from 'lucide-react'
import {
  SponsoredProductsCampaignBiddingStrategy,
  SponsoredProductsCampaignTargetingType,
} from '../../../../../generated/graphql'

type CreateSponsoredProductsCampaignFormFieldsProps = {
  setTargetingType: Function
  targetingType: SponsoredProductsCampaignTargetingType
  campaignNamePrefix: string
  currencySymbol: string
}

const CreateSponsoredProductsCampaignFormFields = ({
  setTargetingType,
  targetingType,
  campaignNamePrefix,
  currencySymbol,
}: CreateSponsoredProductsCampaignFormFieldsProps) => {
  const subFormName = 'campaign'

  const disabledDate = (current: Dayjs) => {
    return current < dayjs()
  }

  const handleTargetingTypeChanged = (e: any) => {
    setTargetingType(e.target.value)
  }

  const adjustmentsPopoverContent = (
    <div style={{ maxWidth: '500px' }}>
      <p>In addition to your bidding strategy, you can increase bids by up to 900%.</p>
      <p>For example:</p>
      <p>
        "Top of search: 25%" would mean that a {currencySymbol}0.40 bid will be {currencySymbol}0.50
        for this placement. Dynamic bidding could increase it up to {currencySymbol}1.00
      </p>
      <p>
        "Products pages: 0%" would mean that a {currencySymbol}0.40 bid will remain {currencySymbol}
        0.40 for this placement. Dynamic bidding could increase it up to {currencySymbol}0.60
      </p>
    </div>
  )

  return (
    <>
      <Form.Item name={[subFormName, 'name']} label="Name" rules={[{ required: true }]}>
        <Input
          placeholder="Campaign name..."
          addonBefore={campaignNamePrefix}
          data-testid="campaign-name-input"
        />
      </Form.Item>
      <Form.Item
        name={[subFormName, 'startDate']}
        label="Start Date"
        rules={[{ required: true }]}
        initialValue={dayjs()}
      >
        <DatePicker
          disabledDate={disabledDate}
          picker="date"
          format="DD MMM YYYY"
          key={'startDate'}
          allowClear={false}
        />
      </Form.Item>
      <Form.Item name={[subFormName, 'endDate']} label="End Date" initialValue={undefined}>
        <DatePicker
          disabledDate={disabledDate}
          picker="date"
          format="DD MMM YYYY"
          key={'endDate'}
          allowClear={true}
        />
      </Form.Item>
      <Form.Item
        name={[subFormName, 'dailyBudget']}
        rules={[{ required: true }]}
        label={`Daily Budget (${currencySymbol})`}
        initialValue={1}
      >
        <InputNumber min={1} max={10000} data-testid="daily-budget-input" />
      </Form.Item>
      <Form.Item
        name={[subFormName, 'targetingType']}
        rules={[
          {
            required: true,
            enum: Object.values(SponsoredProductsCampaignTargetingType),
            type: 'enum',
          },
        ]}
        initialValue={SponsoredProductsCampaignTargetingType.MANUAL}
        label="Targeting Type"
      >
        <Radio.Group onChange={handleTargetingTypeChanged} style={{ marginTop: '6px' }}>
          <Space direction="vertical">
            <Radio value={SponsoredProductsCampaignTargetingType.AUTO}>Automatic targeting</Radio>
            <Radio value={SponsoredProductsCampaignTargetingType.MANUAL}>Manual targeting</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name={[subFormName, 'biddingStrategy']}
        rules={[
          {
            required: true,
            enum: Object.values(SponsoredProductsCampaignBiddingStrategy),
            type: 'enum',
          },
        ]}
        label="Bidding Strategy"
        initialValue={
          targetingType === SponsoredProductsCampaignTargetingType.MANUAL
            ? SponsoredProductsCampaignBiddingStrategy.LEGACYFORSALES
            : SponsoredProductsCampaignBiddingStrategy.AUTOFORSALES
        }
      >
        <Radio.Group style={{ marginTop: '6px' }}>
          <Space direction="vertical">
            <Radio value={SponsoredProductsCampaignBiddingStrategy.LEGACYFORSALES}>
              Dynamic bids - down only
            </Radio>
            <Radio value={SponsoredProductsCampaignBiddingStrategy.AUTOFORSALES}>
              Dynamic bids - up and down
            </Radio>
            <Radio value={SponsoredProductsCampaignBiddingStrategy.MANUAL}>Fixed bids</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Row style={{ marginBottom: '1rem' }}>
        <Col span={6} style={{ textAlign: 'right', paddingRight: '8px' }}>
          <Space>
            <Typography.Text>Adjustments</Typography.Text>
            <Popover content={adjustmentsPopoverContent} title="Adjustments" trigger="click">
              <Button icon={<CircleHelp size={16} />} />
            </Popover>
          </Space>
        </Col>
      </Row>
      <Form.Item
        name={[subFormName, 'biddingAdjustmentPlacementTop']}
        label="Top of search (first page)"
        rules={[{ type: 'integer', message: 'Value must be a whole number' }]}
      >
        <InputNumber min={1} max={900} step={1} addonAfter="%" />
      </Form.Item>
      <Form.Item
        name={[subFormName, 'biddingAdjustmentPercentage']}
        label="Product pages"
        rules={[{ type: 'integer', message: 'Value must be a whole number' }]}
      >
        <InputNumber min={1} max={900} step={1} addonAfter="%" />
      </Form.Item>
    </>
  )
}

export default CreateSponsoredProductsCampaignFormFields
