import { Input, List, Skeleton, TourProps } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router'
import { GetProductQuery } from '../../../generated/graphql'
import { useDebounce } from '../../../hooks/useDebounce'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { useOnboardingStore } from '../../../stores/useOnboardingStore'
import { StyledMainCard } from '../../dashboard/styles'
import { CreateProductVariantModal } from './CreateProductVariantModal'
import { ProductVariantCard } from './ProductVariantCard'

type ProductVariantsCardProps = {
  product: GetProductQuery['product'] | undefined
  loading: boolean
}

export const ProductVariantsCard = ({ product, loading }: ProductVariantsCardProps) => {
  const [searchTerm, setSearchTerm] = useState('')

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const setTourSteps = useOnboardingStore((state) => state.setTourSteps)
  const setTourOpen = useOnboardingStore((state) => state.setTourOpen)

  const { t } = useTranslation(['inventory', 'company'])
  const [searchParams] = useSearchParams()
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const variantsRef = useRef(null)

  const steps: TourProps['steps'] = [
    {
      title: t('company:onboarding.addProducts.title'),
      description: t('company:onboarding.addProducts.description'),
      target: () => variantsRef.current,
      nextButtonProps: {
        style: { display: 'none' },
      },
    },
  ]

  useEffect(() => {
    const showTour = searchParams.get('tour') === 'true'
    if (showTour) {
      setTourSteps(steps)
      setTourOpen(true)
    }
  }, [searchParams])

  const productVariants =
    [...(product?.productVariants ?? [])].sort((a, b) => a.sequence - b.sequence) ?? []

  const filteredProductVariants = productVariants.filter((variant) => {
    const searchTermLower = debouncedSearchTerm.toLowerCase()

    const gtin = variant.packagingUnits?.[0]?.gtin?.toLowerCase() ?? ''
    const internalSku = variant.internalSku?.toLowerCase() ?? ''
    const asins =
      variant.amazonProducts?.flatMap((amazonProduct) =>
        amazonProduct.amazonListings?.map((l) => l.sku?.toLowerCase() ?? '')
      ) ?? []
    const skus =
      variant.amazonProducts?.flatMap((amazonProduct) =>
        amazonProduct.amazonListings?.map((amazonListing) => amazonListing.sku?.toLowerCase() ?? '')
      ) ?? []

    return [gtin, internalSku, ...asins, ...skus].some((value) => value?.includes(searchTermLower))
  })

  return (
    <StyledMainCard
      title={t('product.details.productVariant.title')}
      extra={product && <CreateProductVariantModal productUuid={product?.uuid} />}
      style={{ height: 'auto' }}
    >
      {loading ? (
        <Skeleton loading active title={false} paragraph={{ rows: 3, width: '100%' }} />
      ) : (
        <div ref={variantsRef}>
          <List
            dataSource={filteredProductVariants}
            header={
              productVariants.length > 4 ? (
                <Input
                  value={searchTerm}
                  placeholder={t('shared.button.universalSearch', { ns: 'translation' })}
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
              ) : null
            }
            renderItem={(productVariant) => (
              <List.Item>
                <Link
                  to={`variants/${productVariant.uuid}${
                    !selectedCompany.completedOnboarding ? '?tour=true' : ''
                  }`}
                >
                  <ProductVariantCard productVariant={productVariant} />
                </Link>
              </List.Item>
            )}
            grid={{ gutter: 16, column: 2, xs: 1 }}
            locale={{ emptyText: t('shared.noData', { ns: 'translation' }) }}
            split={false}
            style={{ marginBottom: productVariants.length ? -16 : 0 }}
          />
        </div>
      )}
    </StyledMainCard>
  )
}
