import {
  ApolloClient,
  defaultDataIdFromObject,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Amplify, Auth } from 'aws-amplify'
import { createClient } from 'graphql-ws'
import { AuthStatus, useGlobalStore } from '../stores/useGlobalStore'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    identityPoolId: import.meta.env.VITE_AWS_IDENTITY_POOL_ID,
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_AWS_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_APP_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      region: import.meta.env.VITE_AWS_REGION,
      bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME,
    },
  },
})

const apiEndpoint = import.meta.env.VITE_API_ENDPOINT ?? ''
const websocketEndpoint = import.meta.env.VITE_WEBSOCKET_ENDPOINT ?? ''

const authLink = setContext(async (_, { headers }) => {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser()
    const token = user.getSignInUserSession()?.getAccessToken().getJwtToken()
    const selectedCompany = useGlobalStore.getState().selectedCompany

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'x-company-uuid': selectedCompany?.uuid ?? '',
      },
    }
  } catch (error) {
    console.error(error)
    useGlobalStore.getState().setAuthStatus(AuthStatus.UNAUTHENTICATED)
  }
})

const httpLink = new HttpLink({
  uri: `${apiEndpoint}${apiEndpoint.endsWith('/') ? 'graphql' : '/graphql'}`,
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${websocketEndpoint}${
      websocketEndpoint.endsWith('/') ? 'subscriptions' : '/subscriptions'
    }`,
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

export const client = new ApolloClient({
  link: from([authLink, splitLink]),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      const uuid = object.uuid as string
      switch (object.__typename) {
        default:
          // The GraphQL API uses UUIDs as primary keys.
          return uuid ? `${object.__typename}:${uuid}` : defaultDataIdFromObject(object)
      }
    },
    typePolicies: {
      WholesaleOrder: {
        keyFields: ['identifier'],
      },
      WholesaleOrderLineItem: {
        keyFields: ['identifier'],
      },
      ProductComplianceCheck: {
        keyFields: ['identifier'],
      },
    },
  }),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
