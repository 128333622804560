import { Button, Space, Table, Tag } from 'antd'
import { Key, useState } from 'react'
import { AmazonPpcState } from '../../../../../generated/graphql'
import { exportCsvFile } from '../../../../../helpers/exportFile'
import { getColorFromState } from '../../../../../views/ppc/helpers'
import { formatCurrency } from '../../../../../views/ppc/helpers/formatCurrency'
import { SponsoredBrandsAggregatedKeyword } from '../../../../../views/ppc/helpers/interfaces'
import { getTableColumnFilterProps } from '../../../../../views/ppc/helpers/tableColumnFilterProps'
import { getTableColumnSorterProps } from '../../../../../views/ppc/helpers/tableColumnSorterProps'
import { getColumnSearchProps } from '../../../../../views/ppc/helpers/tableSearchProps'
import { useCurrencySymbol } from '../../../../../views/ppc/helpers/useCurrencySymbol'

type SponsoredBrandsKeywordsTableProps = {
  keywords: SponsoredBrandsAggregatedKeyword[]
}

const SponsoredBrandsKeywordsTable = ({ keywords }: SponsoredBrandsKeywordsTableProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>()
  const [searchText, setSearchText] = useState<string>('')

  const currencySymbol = useCurrencySymbol()

  const handleSearch = (selectedKeys: any[], confirm: Function) => {
    confirm()
    setSearchText(selectedKeys[0])
  }

  const handleReset = (clearFilters: Function) => {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'keywordText',
      key: 'keywordText',
      fixed: true,
      ...getColumnSearchProps(handleSearch, handleReset, searchText, 'keywordText'),
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      filters: [
        { text: 'Enabled', value: AmazonPpcState.ENABLED },
        { text: 'Paused', value: AmazonPpcState.PAUSED },
        { text: 'Archived', value: AmazonPpcState.ARCHIVED },
      ],
      render: (state: AmazonPpcState) => {
        const stateString = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()
        return <Tag color={getColorFromState(state)}>{stateString}</Tag>
      },
      ...getTableColumnFilterProps('state'),
    },
    {
      title: 'Match type',
      dataIndex: 'matchType',
      key: 'matchType',
    },
    {
      title: 'Bid',
      dataIndex: 'bid',
      key: 'bid',
      render: (bid: number) => formatCurrency(currencySymbol, bid),
      ...getTableColumnSorterProps('bid'),
    },
    {
      title: 'ACoS',
      dataIndex: 'acos',
      key: 'acos',
      render: (acos: number) => {
        if (isFinite(acos)) {
          return acos + '%'
        } else {
          return '-'
        }
      },
      ...getTableColumnSorterProps('acos'),
    },
    {
      title: 'Cost per Click',
      dataIndex: 'costPerClick',
      key: 'costPerClick',
      render: (costPerClick: number) => formatCurrency(currencySymbol, costPerClick),
      ...getTableColumnSorterProps('costPerClick'),
    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      key: 'spend',
      render: (spend: number) => formatCurrency(currencySymbol, spend),
      ...getTableColumnSorterProps('spend'),
    },
    {
      title: 'Sales',
      dataIndex: 'sales',
      key: 'sales',
      render: (sales: number) => formatCurrency(currencySymbol, sales),
      ...getTableColumnSorterProps('sales'),
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      ...getTableColumnSorterProps('impressions'),
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      ...getTableColumnSorterProps('clicks'),
    },
  ]

  const handleDownloadCSV = () => {
    const data = keywords.filter((keyword: SponsoredBrandsAggregatedKeyword) =>
      selectedRowKeys?.includes(keyword.keywordId)
    )
    exportCsvFile(data, 'keywords')
  }

  const onBulkSelectChanged = (selectedRowKeys: Key[]) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  return (
    <Table
      bordered
      columns={columns}
      dataSource={keywords}
      pagination={{
        defaultPageSize: 20,
        pageSizeOptions: ['20', '50', '100', '500', '1000'],
        showSizeChanger: true,
      }}
      rowKey="keywordId"
      rowSelection={{ selectedRowKeys, onChange: onBulkSelectChanged }}
      scroll={{ x: true }}
      footer={() => {
        const bulkActionsDisabled = !selectedRowKeys?.length
        const textOpacity = bulkActionsDisabled ? 0.5 : 1

        return (
          <Space>
            <span style={{ opacity: textOpacity }}>With selection:</span>
            <Button onClick={() => handleDownloadCSV()} disabled={bulkActionsDisabled}>
              Export .csv
            </Button>
          </Space>
        )
      }}
    />
  )
}

export default SponsoredBrandsKeywordsTable
