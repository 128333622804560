import { Form, Select } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useTranslation } from 'react-i18next'
import { GetAllWholesaleOrdersDetailsQuery } from '../../../../../../../generated/graphql'
import { UserCompany } from '../../../../../../../hooks/useAuthentication'
import { useGlobalStore } from '../../../../../../../stores/useGlobalStore'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'

type RetailerCompany =
  GetAllWholesaleOrdersDetailsQuery['wholesaleOrders'][number]['retailerCompany']

type WholesaleOrderBillingDocumentDebtorCompanySelectProps = {
  wholesaleOrders: GetAllWholesaleOrdersDetailsQuery['wholesaleOrders']
}

export const WholesaleOrderBillingDocumentDebtorCompanySelect = ({
  wholesaleOrders,
}: WholesaleOrderBillingDocumentDebtorCompanySelectProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('wholesale-order-billing-document', {
    keyPrefix: 'debtorCompanySellerId',
  })

  const form = useFormInstance<CreateWholesaleOrderBillingDocumentFormValues>()

  const buyerCompanies = getUniqueBuyerCompanies(wholesaleOrders, selectedCompany)

  return (
    <Form.Item<CreateWholesaleOrderBillingDocumentFormValues>
      name="debtorCompanySellerId"
      label={t('label')}
      rules={[{ required: true, message: t('error.required') }]}
    >
      <Select
        placeholder={t('placeholder')}
        disabled={!buyerCompanies?.length}
        options={buyerCompanies?.map((company) => ({
          label: `${company.sellerId} - ${company.name}`,
          value: company.sellerId,
        }))}
        onChange={() => {
          form.setFieldValue('lineItems', [])
        }}
      />
    </Form.Item>
  )
}

function getUniqueBuyerCompanies(
  wholesaleOrders: GetAllWholesaleOrdersDetailsQuery['wholesaleOrders'],
  selectedCompany: UserCompany
) {
  return Array.from(
    wholesaleOrders
      .filter((wholesaleOrder) => {
        return wholesaleOrder.retailerCompany?.sellerId !== selectedCompany.sellerId
      })
      .reduce((accumulator, wholesaleOrder) => {
        if (wholesaleOrder.retailerCompany) {
          accumulator.set(wholesaleOrder.retailerCompany.sellerId, wholesaleOrder.retailerCompany)
        }

        return accumulator
      }, new Map<string, RetailerCompany>())
      .values()
  )
}
