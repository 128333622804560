import { useQuery } from '@apollo/client'
import { Button, Checkbox, Col, DatePicker, Divider, Popover, Row, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { Filter } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AmazonOrderStatus,
  GetDashboardPerformanceOverviewDocument,
  InternalAmazonListingStatus,
  Marketplace,
} from '../../../../../generated/graphql'
import { useBreakpoints } from '../../../../../hooks/useBreakpoints'
import { useDateRangePresets } from '../../../../../hooks/useDateRangePresets'
import { useMarketplaceOptions } from '../../../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { AmazonMarketplaceDomain } from '../../../../../utils'
import { StyledMainCard } from '../../../styles'
import { accumulatePerformanceOverview } from '../../helpers/accumulatePerformanceOverview'
import { preparePerformanceOverview } from '../../helpers/preparePerformanceOverview'
import { DashboardSubCardOrders } from '../DashboardSubCardOrders'
import { DashboardSubCardRevenue } from '../DashboardSubCardRevenue'
import { DashboardSubCardTopMarketplaces } from '../DashboardSubCardTopMarketplaces'
import { DashboardSubCardTopProducts } from '../DashboardSubCardTopProducts'
import { DashboardChart } from './DashboardChart'
import { OrdersHeatMap } from './OrdersHeatMap'
import { addMissingDays } from './helpers/addMissingDays'

const { RangePicker } = DatePicker

const orderStatus = [
  AmazonOrderStatus.SHIPPED,
  AmazonOrderStatus.PENDING,
  AmazonOrderStatus.CANCELED,
]

const fulfillmentChannels = ['AFN', 'MFN']

export enum DataType {
  ORDERS = 'Orders',
  REVENUE = 'Revenue',
}

const defaultDateRange: [Dayjs, Dayjs] = [
  dayjs().subtract(6, 'days').startOf('day'),
  dayjs().endOf('day'),
]

export const DashboardCardPerformanceChart = () => {
  const presets = useDateRangePresets()
  const marketplaceOptions = useMarketplaceOptions()

  const [dateRange, setDateRange] = useState(defaultDateRange)

  const [filter, setFilter] = useState({
    marketplaces: marketplaceOptions,
    orderStatus,
    fulfillmentChannels,
  })

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const { t } = useTranslation(['dashboard', 'translation'])
  const breakpoints = useBreakpoints()

  const { loading, data } = useQuery(GetDashboardPerformanceOverviewDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    pollInterval: 1000 * 60 * 15,
    skip: !selectedCompany?.uuid,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
      from: dateRange[0].format('YYYY-MM-DD'),
      to: dateRange[1].format('YYYY-MM-DD'),
      internalStatuses: Object.values(InternalAmazonListingStatus),
    },
  })

  const chartPerformanceOverview = preparePerformanceOverview(
    data,
    filter.marketplaces,
    filter.orderStatus,
    filter.fulfillmentChannels
  )
  const chartData = addMissingDays(chartPerformanceOverview, dateRange[0], dateRange[1])
  const dataSource = accumulatePerformanceOverview(chartPerformanceOverview)

  const handleMarketplaceChange = (checkedValues: Marketplace[]) => {
    setFilter({
      ...filter,
      marketplaces: checkedValues as Marketplace[],
    })
  }

  const handleOrderStatusChange = (checkedValues: AmazonOrderStatus[]) => {
    setFilter({
      ...filter,
      orderStatus: checkedValues as AmazonOrderStatus[],
    })
  }

  const handleFulfillmentChannelChange = (checkedValues: string[]) => {
    setFilter({
      ...filter,
      fulfillmentChannels: checkedValues as string[],
    })
  }

  return (
    <StyledMainCard
      title={t('performance.performanceChart.title')}
      extra={
        <Space>
          <RangePicker
            value={dateRange}
            onCalendarChange={(values) => {
              if (values?.[0] && values?.[1]) {
                setDateRange([values[0].startOf('day'), values[1].endOf('day')])
              }
            }}
            allowClear={false}
            disabledDate={(date) =>
              date.isAfter(dayjs()) || date.isBefore(dayjs().subtract(6, 'month'))
            }
            format="DD.MM.YY"
            placement="bottomRight"
            presets={presets}
          />
          <Popover
            content={
              <Row>
                <Col span={24}>
                  <Divider plain orientation="left">
                    {t('shared.term.marketplaces', { ns: 'translation' })}
                  </Divider>
                </Col>
                <Col span={24}>
                  <Checkbox.Group value={filter.marketplaces} onChange={handleMarketplaceChange}>
                    <Row gutter={[8, 8]}>
                      {marketplaceOptions.map((marketplace) => (
                        <Col span={12} key={marketplace}>
                          <Checkbox value={marketplace}>
                            {AmazonMarketplaceDomain[marketplace]}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Col>
                <Col span={24}>
                  <Divider plain orientation="left">
                    {t('shared.term.status', { ns: 'translation' })}
                  </Divider>
                </Col>
                <Col span={24}>
                  <Checkbox.Group
                    value={filter.orderStatus}
                    onChange={handleOrderStatusChange}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={[8, 8]}>
                      {[
                        AmazonOrderStatus.SHIPPED,
                        AmazonOrderStatus.PENDING,
                        AmazonOrderStatus.CANCELED,
                      ].map((status) => (
                        <Col span={8} key={status}>
                          <Checkbox value={status}>
                            {status === AmazonOrderStatus.SHIPPED
                              ? t('shared.order.shipped', { ns: 'translation' })
                              : status === AmazonOrderStatus.PENDING
                                ? t('shared.order.pending', { ns: 'translation' })
                                : t('shared.order.canceled', { ns: 'translation' })}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Col>
                <Col span={24}>
                  <Divider plain orientation="left">
                    {t('shared.term.fulfillmentChannel', { ns: 'translation' })}
                  </Divider>
                </Col>
                <Col span={24}>
                  <Checkbox.Group
                    value={filter.fulfillmentChannels}
                    onChange={handleFulfillmentChannelChange}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={[8, 8]}>
                      {fulfillmentChannels.map((fulfillmentChannel) => (
                        <Col span={8} key={fulfillmentChannel}>
                          <Checkbox value={fulfillmentChannel}>{fulfillmentChannel}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Col>
              </Row>
            }
            arrow={!breakpoints.md}
            overlayInnerStyle={{ width: '350px' }}
            placement="bottomRight"
            trigger="click"
          >
            <Button icon={<Filter size={16} />}>
              {t('shared.button.filter', { ns: 'translation' })}
            </Button>
          </Popover>
        </Space>
      }
    >
      <DashboardChart chartData={chartData ?? []} loading={loading} />
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={12}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <DashboardSubCardOrders
                totalShipped={dataSource?.totalShipped ?? 0}
                totalPending={dataSource?.totalPending ?? 0}
                totalCanceled={dataSource?.totalCanceled ?? 0}
                loading={loading}
              />
            </Col>
            <Col xs={24} md={12}>
              <DashboardSubCardRevenue
                totalRevenue={dataSource?.totalRevenue ?? 0}
                pendingRevenue={dataSource?.pendingRevenue ?? 0}
                loading={loading}
              />
            </Col>
            <Col xs={24} md={12}>
              <DashboardSubCardTopProducts
                topProducts={dataSource?.performance.products ?? []}
                rows={5}
                loading={loading}
              />
            </Col>
            <Col xs={24} md={12}>
              <DashboardSubCardTopMarketplaces
                topMarketplaces={dataSource?.performance.marketplaces ?? []}
                rows={5}
                loading={loading}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} xl={12}>
          <OrdersHeatMap orders={dataSource?.orders ?? []} loading={loading} />
        </Col>
      </Row>
    </StyledMainCard>
  )
}
