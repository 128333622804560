import { useMutation } from '@apollo/client'
import { Button, Col, Divider, Flex, Form, Input, Modal, Radio, Row, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { Plus } from 'lucide-react'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  CreateAmazonListingDocument,
  CreateAmazonProductDocument,
  LabelType,
  Marketplace,
} from '../../../../generated/graphql'
import { formatPercentage } from '../../../../helpers/formatPercentage'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'
import { asinRegExp } from '../../../../utils/regExp'
import {
  ProductVariantSalesChannelAmazonFormCard,
  ProductVariantSalesChannels,
} from '../../../products/components/ProductCreateForm'

type CreateSalesChannelModalProps = {
  productVariantUuid: string
}

type CreateSalesChannelFormInstance = {
  salesChannels: ProductVariantSalesChannels
}

export const CreateSalesChannelModal = ({ productVariantUuid }: CreateSalesChannelModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const marketplaceOptions = useMarketplaceOptions()
  const { t } = useTranslation('inventory')
  const [form] = useForm<CreateSalesChannelFormInstance>()

  const [createAmazonProduct] = useMutation(CreateAmazonProductDocument)
  const [createAmazonListing] = useMutation(CreateAmazonListingDocument)

  const handleFinish = async (values: CreateSalesChannelFormInstance) => {
    try {
      setSubmitting(true)

      const salesChannels = Object.entries(values.salesChannels.amazon.marketplaces ?? {})
        .filter(([, value]) => value.shouldCreate)
        .map(([marketplace, { price }]) => ({
          marketplace: marketplace as Marketplace,
          price,
        }))

      const marketplaces = salesChannels.map(({ marketplace }) => marketplace)

      const sku = `MISSING-SKU-${nanoid(10)}`
      const asin = values.salesChannels.amazon.asin ?? `MISSING-ASIN-${nanoid(10)}`

      const createdAmazonProduct = await createAmazonProduct({
        variables: {
          productVariantUuid,
          amazonProduct: {
            asin,
            amazonAccount: 'Varento',
            subscribeAndSave: values.salesChannels.amazon.subscribeAndSave,
          },
        },
      })

      const amazonProductUuid = createdAmazonProduct.data?.createAmazonProduct.uuid

      if (!amazonProductUuid) {
        throw new Error()
      }

      for (const marketplace of marketplaces) {
        const recommendedRetailPrice = values.salesChannels.amazon.marketplaces[marketplace]?.price

        await createAmazonListing({
          variables: {
            amazonProductUuid,
            amazonListing: {
              sku,
              marketplace,
              recommendedRetailPrice,
              labelType: values.salesChannels.amazon.labelType,
            },
          },
        })
      }

      toast.success(t('product.create.variant.salesChannels.create.toast.success'))
      form.resetFields()
      setOpen(false)
    } catch (error) {
      console.error(error)
      toast.error(t('product.create.variant.salesChannels.create.toast.error'))
    } finally {
      setTimeout(() => setSubmitting(false), 1000)
    }
  }

  return (
    <>
      <Button icon={<Plus size={16} />} onClick={() => setOpen(true)}>
        {t('product.create.variant.salesChannels.create.modal.button')}
      </Button>
      <Modal
        title={t('product.create.variant.salesChannels.create.modal.title')}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => form.submit()}
        confirmLoading={submitting}
        okText={t('shared.button.submit', { ns: 'translation' })}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
      >
        <Form<CreateSalesChannelFormInstance>
          name="product-form"
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          style={{ width: '100%' }}
        >
          <Flex vertical>
            <Row gutter={16}>
              <Col xs={24}>
                <Divider orientation="left" orientationMargin={0}>
                  Amazon
                </Divider>
              </Col>
              <Col xs={12}>
                <Form.Item<CreateSalesChannelFormInstance>
                  name={['salesChannels', 'amazon', 'asin']}
                  label={t('product.create.variant.salesChannels.asin')}
                  rules={[{ pattern: asinRegExp }]}
                  validateDebounce={1000}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item<CreateSalesChannelFormInstance>
                  name={['salesChannels', 'amazon', 'labelType']}
                  label={t('product.create.variant.salesChannels.labeling')}
                  rules={[{ required: true }]}
                >
                  <Select<string>
                    options={Object.values(LabelType).map((type) => ({
                      value: type,
                      label: type,
                    }))}
                    placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item<CreateSalesChannelFormInstance>
                  name={['salesChannels', 'amazon', 'subscribeAndSave']}
                  label={t('product.create.variant.salesChannels.subscribeAndSave')}
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value={0}>
                      {t('product.create.variant.salesChannels.radioButton.no')}
                    </Radio>
                    <Radio value={5}>{formatPercentage(0.05)}</Radio>
                    <Radio value={10}>{formatPercentage(0.1)}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              {marketplaceOptions.map((marketplace) => (
                <Col xs={24} sm={12} key={marketplace}>
                  <ProductVariantSalesChannelAmazonFormCard marketplace={marketplace} />
                </Col>
              ))}
            </Row>
          </Flex>
        </Form>
      </Modal>
    </>
  )
}
