import { useLazyQuery } from '@apollo/client'
import { Button, DatePicker, Flex, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { Download } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import { GetCompanyPaymentsReportDocument, GetPaymentsQuery } from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import {
  PaymentsQueryAdjustment,
  PaymentsQueryAmazonInboundShipment,
  PaymentsQueryAmazonPpcCosts,
  PaymentsQueryRefund,
  PaymentsQueryShipment,
  PaymentsQueryStorageFee,
} from '../PaymentsView'
import {
  exportPaymentsOverview,
  getFullDateRange,
  prepareCurrentMonthData as prepareCurrentPaymentsData,
  preparePastMonthData as preparePastPaymentsData,
  preparePaymentsAmazonRemovalOrderRecords,
  preparePaymentsLongTermStorageFeeRecords,
  preparePaymentsOverviewTableData,
  preparePaymentsServiceFeeRecords,
} from '../helpers'
import { PaymentsDetails } from './PaymentsDetails'
import { PaymentsLongTermStorageFeeTableRecord } from './PaymentsLongTermStorageFeeTable'
import { PaymentsOverview } from './PaymentsOverview'
import { PaymentsOverviewTableRecord } from './PaymentsOverviewTable'
import { PaymentsRemissionTableRecord } from './PaymentsRemissionTable'
import { PaymentsServiceFeeTableRecord } from './PaymentsServiceFeeTable'

const { RangePicker } = DatePicker

export type FinancialData = {
  overview: PaymentsOverviewTableRecord[]
  shipments: PaymentsQueryShipment[]
  refunds: PaymentsQueryRefund[]
  adjustments: PaymentsQueryAdjustment[]
  remissions: PaymentsRemissionTableRecord[]
  storageFees: PaymentsQueryStorageFee[]
  longTermStorageFees: PaymentsLongTermStorageFeeTableRecord[]
  amazonInboundShipments: PaymentsQueryAmazonInboundShipment[]
  advertisingFees: PaymentsQueryAmazonPpcCosts
  serviceFees: PaymentsServiceFeeTableRecord[]
}

type PaymentsProps = {
  dataCurrent: GetPaymentsQuery
}

export const Payments = ({ dataCurrent }: PaymentsProps) => {
  const formattedFinancialData = useMemo(
    () => ({
      ...dataCurrent,
      remissions: preparePaymentsAmazonRemovalOrderRecords(dataCurrent.amazonRemovalOrderItems),
      overview: preparePaymentsOverviewTableData(dataCurrent),
      longTermStorageFees: preparePaymentsLongTermStorageFeeRecords(
        dataCurrent.amazonLongTermStorageFees
      ),
      amazonInboundShipments: dataCurrent.amazonInboundShipments,
      advertisingFees: dataCurrent.amazonPpcCosts,
      serviceFees: preparePaymentsServiceFeeRecords(dataCurrent.invoiceItems.invoiceItems),
    }),
    [dataCurrent]
  )

  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(dayjs().startOf('month'))
  const [endDate, setEndDate] = useState(dayjs().endOf('month'))
  const [financialData, setFinancialData] = useState<FinancialData | undefined>(
    formattedFinancialData
  )

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation(['billing', 'translation'])

  const [downloadPastPayments] = useLazyQuery(GetCompanyPaymentsReportDocument)

  const handleOnCalendarChange = async (firstDate: Dayjs, secondDate: Dayjs) => {
    setLoading(true)

    const yearMonths = getFullDateRange(firstDate, secondDate)

    let accumulatedFinancialData: FinancialData = {
      overview: [],
      shipments: [],
      refunds: [],
      adjustments: [],
      remissions: [],
      storageFees: [],
      longTermStorageFees: [],
      amazonInboundShipments: [],
      advertisingFees: {
        sponsoredBrandsCosts: 0,
        sponsoredDisplayCosts: 0,
        sponsoredProductsCosts: 0,
      },
      serviceFees: [],
    }

    try {
      for (const yearMonth of yearMonths) {
        if (!selectedCompany?.uuid) {
          throw new Error()
        }

        if (dayjs().isSame(dayjs(yearMonth), 'month')) {
          accumulatedFinancialData = prepareCurrentPaymentsData(
            dataCurrent,
            accumulatedFinancialData
          )
        } else {
          const { data } = await downloadPastPayments({
            variables: {
              companyUuid: selectedCompany.uuid,
              yearMonth,
            },
          })

          if (data && data.companyAmazonPaymentReport) {
            accumulatedFinancialData = preparePastPaymentsData(data, accumulatedFinancialData)
          }
        }
      }

      setFinancialData(accumulatedFinancialData)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleExport = () => {
    if (!financialData) {
      return
    }
    exportPaymentsOverview(
      financialData.overview,
      financialData.shipments,
      financialData.refunds,
      financialData.adjustments,
      financialData.remissions,
      financialData.storageFees,
      financialData.longTermStorageFees,
      financialData.amazonInboundShipments,
      financialData.serviceFees,
      `payments-overview-${startDate.format('MMYYYY')}-${endDate.format('MMYYYY')}.xlsx`
    )
  }

  const rightContent = (
    <Space>
      <RangePicker
        value={[startDate, endDate]}
        onCalendarChange={async (values) => {
          if (values?.[0] && values?.[0] !== startDate) {
            setStartDate((previousStartDate: Dayjs) => {
              if (values[0] && previousStartDate !== values[0].startOf('month')) {
                return values[0].startOf('month')
              } else {
                return previousStartDate
              }
            })
          }

          if (values?.[0] && values?.[1] && values?.[1] !== endDate) {
            setEndDate((previousStartDate: Dayjs) => {
              if (values[1] && previousStartDate !== values[1].endOf('month')) {
                return values[1].endOf('month')
              } else {
                return previousStartDate
              }
            })

            await handleOnCalendarChange(values?.[0], values?.[1])
          }
        }}
        picker="month"
        format="MMM YYYY"
        allowClear={false}
        disabled={loading}
        disabledDate={(current) => current > dayjs() || current.isBefore(dayjs('2019-09-01'))}
        presets={[
          {
            label: t('payments.filter.dateRange.thisMonth'),
            value: [dayjs().startOf('month'), dayjs().endOf('month')],
          },
          {
            label: t('payments.filter.dateRange.lastMonth'),
            value: [
              dayjs().subtract(1, 'month').startOf('month'),
              dayjs().subtract(1, 'month').endOf('month'),
            ],
          },
          {
            label: t('payments.filter.dateRange.last6Months'),
            value: [dayjs().subtract(5, 'month').startOf('month'), dayjs().endOf('month')],
          },
          {
            label: t('payments.filter.dateRange.firstQuarter'),
            value: [dayjs().startOf('year'), dayjs().startOf('year').add(2, 'month')],
          },
          {
            label: t('payments.filter.dateRange.secondQuarter'),
            value: [
              dayjs().startOf('year').add(3, 'month'),
              dayjs().startOf('year').add(5, 'month'),
            ],
          },
          {
            label: t('payments.filter.dateRange.thirdQuarter'),
            value: [
              dayjs().startOf('year').add(6, 'month'),
              dayjs().startOf('year').add(8, 'month'),
            ],
          },
          {
            label: t('payments.filter.dateRange.lastQuarter'),
            value: [
              dayjs().startOf('year').add(9, 'month'),
              dayjs().startOf('year').add(11, 'month'),
            ],
          },
          {
            label: t('payments.filter.dateRange.yearToDate'),
            value: [dayjs().startOf('year'), dayjs().endOf('month')],
          },
          {
            label: t('payments.filter.dateRange.lastYear'),
            value: [
              dayjs().subtract(1, 'year').startOf('year'),
              dayjs().subtract(1, 'year').endOf('year'),
            ],
          },
        ]}
      />
      <Button
        icon={<Download size={16} />}
        disabled={loading}
        onClick={handleExport}
        data-testid="download-xlsx-btn"
      >
        {t('shared.button.export', { ns: 'translation' })}
      </Button>
    </Space>
  )

  return (
    <>
      <SubHeader heading={t('payments.heading')} rightContent={rightContent} />
      <ViewContainer>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Flex vertical gap={32}>
            <PaymentsOverview overview={financialData?.overview || []} />
            <PaymentsDetails financialData={financialData} />
          </Flex>
        )}
      </ViewContainer>
    </>
  )
}
