import { CustomTooltipProps } from 'ag-grid-react'
import { StyledSubCard } from '../../views/dashboard/styles'

export const CustomTooltip = (props: CustomTooltipProps) => {
  const headerName = props.colDef?.headerName
  const value = props.value === 'X' ? null : props.value

  if (!headerName && !value) {
    return
  }

  return (
    <StyledSubCard title={headerName} hideSeparator={!value} style={{ maxWidth: '320px' }}>
      {value}
    </StyledSubCard>
  )
}
