import { useMutation, useQuery } from '@apollo/client'
import { Button, Form, Modal, Popconfirm, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'sonner'
import {
  CreateSponsoredProductsProductAdInput,
  CreateSponsoredProductsProductAdsDocument,
  GetAllAmazonListingsByCompanyAndMarketplaceDocument,
  Marketplace,
} from '../../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../../stores/useGlobalStore'
import SelectSponsoredProductsProductAdsFormFields from '../CreateSponsoredProductsProductAdsWidget/SelectSponsoredProductsProductAdsFormFields'
type CreateSponsoredProductsProductAdsModal = {
  campaignId: string
  adGroupId: string
  disabled: boolean
  onProductAdsAdded: Function
}

const CreateSponsoredProductsProductAdsModal = ({
  campaignId,
  adGroupId,
  disabled,
  onProductAdsAdded,
}: CreateSponsoredProductsProductAdsModal) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [loadingCreateProductAds, setLoadingCreateProductAds] = useState<boolean>(false)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const [createSponsoredProductsProductAds] = useMutation(CreateSponsoredProductsProductAdsDocument)
  const params = useParams<{ marketplace: string }>()
  const [modalKey, setModalKey] = useState(Math.floor(Math.random() * 99999999).toString())

  useEffect(() => {
    setModalKey(Math.floor(Math.random() * 99999999).toString())
  }, [modalVisible])

  const { loading, error, data } = useQuery(GetAllAmazonListingsByCompanyAndMarketplaceDocument, {
    variables: {
      companyUuid: selectedCompany?.uuid!,
      marketplace: params.marketplace?.toUpperCase()! as Marketplace,
    },
  })

  if (error || !data?.amazonListings) {
    return null
  }

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleCreateProductAds = async (productAds: CreateSponsoredProductsProductAdInput[]) => {
    const request = await createSponsoredProductsProductAds({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: params.marketplace?.toUpperCase()! as Marketplace,
        productAds: productAds,
      },
    })

    return request.data?.createSponsoredProductsProductAds
  }

  const handleOnFinish = async (values: any) => {
    const productAdSkus = values.productAdSkus

    setLoadingCreateProductAds(true)

    try {
      if (!productAdSkus.length) {
        throw new Error('No product ads to create')
      }
      const formattedProductAds: CreateSponsoredProductsProductAdInput[] = productAdSkus.map(
        (sku: string) => {
          return {
            campaignId: campaignId,
            adGroupId: adGroupId,
            sku: sku,
          }
        }
      )

      const createdProductAds = await handleCreateProductAds(formattedProductAds)

      toast.success('Product Ads successfully created')

      onProductAdsAdded(createdProductAds)
    } catch (error: any) {
      console.error(error)
      toast.error('Error adding Product Ads', { description: error.message })
    } finally {
      setLoadingCreateProductAds(false)
      setModalVisible(false)
    }
  }

  return (
    <>
      <Button key="209783" onClick={showModal} disabled={disabled} data-testid="add-product-btn">
        Add products
      </Button>

      <Modal
        title="Add Products"
        open={modalVisible}
        onCancel={handleCancel}
        width={800}
        key={modalKey}
        footer={
          <Space>
            <Button onClick={handleCancel} key="cancel">
              Cancel
            </Button>
            <Popconfirm
              key="u8f8f"
              placement="top"
              title={
                'New Products will be added immediately on Amazon. Please confirm that you would like to continue.'
              }
              onConfirm={form.submit}
              okText="Continue"
              cancelText="Go back"
              disabled={loadingCreateProductAds || loading}
              overlayInnerStyle={{ maxWidth: '400px' }}
            >
              <Button key="savel" disabled={loadingCreateProductAds || loading} type={'primary'}>
                Save
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <Form form={form} onFinish={handleOnFinish}>
          <SelectSponsoredProductsProductAdsFormFields
            amazonListings={data?.amazonListings}
            form={form}
            handleSetSelectedAmazonListings={() => {}}
          />
        </Form>
      </Modal>
    </>
  )
}

export default CreateSponsoredProductsProductAdsModal
