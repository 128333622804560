import { useMutation } from '@apollo/client'
import { Alert, Button, Form, Row, Switch, Typography } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'sonner'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import QuillTextarea from '../../../components/Ticket/QuillTextarea'
import { UploadZendeskFile } from '../../../components/Ticket/UploadFile'
import { SendSupportTicketDocument } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { CompanyNameChangeInputRequired } from '../components/form/CompanyNameChangeInputRequired'
import { PersonalNoteInput } from '../components/form/PersonalNoteInput'
import { createZendeskTicketOptions } from '../helpers/Zendesk.helper'
import { TicketCreationCard } from '../styles/TicketCreateViewStyles'
import { TicketCategory, TicketMainCategory } from '../ticket.enum'
import { AttachmentProps } from '../ticket.interface'

type FormInstance = {
  messageInput: string
  confirmation_switch: boolean
  companyNames: string
  vatApplicationDate: string
  lastDeliveryAccount1: string
  firstDeliveryAccount2: string
  personalNotes: string
}

export const ChangeCompanyNameTicketView = () => {
  const [confirmationSwitchValue, setConfirmationSwitchValue] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const location = useLocation()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()

  const { t, ready, i18n } = useTranslation(['ticket'], {
    keyPrefix: 'account.changeCompanyNameTicketView',
  })
  const { t: generalTranslation } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [form] = Form.useForm()

  const [sendSupportTicketRequest, { loading }] = useMutation(SendSupportTicketDocument)

  if (!ready) {
    return <LoadingSpinner />
  }

  const initialValues: FormInstance = {
    confirmation_switch: false,
    messageInput: '',
    companyNames: '',
    vatApplicationDate: '',
    lastDeliveryAccount1: '',
    firstDeliveryAccount2: '',
    personalNotes: '',
  }

  const onFinish = async (values: FormInstance) => {
    const tokens = attachments.map((attachment) => {
      return attachment.token
    })

    const createCommentSection = (messageInput?: string) => {
      if (messageInput) {
        return `
          <h3>${t('form.comments.label')}</h3>
          <p>${messageInput}</p>
        `
      } else {
        return ''
      }
    }

    const message = `
      <h1>${t('ticketDescriptionTitle')}</h1>
      &nbsp;
      <h3>${t('form.companyNames.label')}</h3>
      <p>${values.companyNames}</p>
      &nbsp;
      <h3>${t('form.vatApplicationDate.label')}</h3>
      <p>${dayjs(values.vatApplicationDate).format('DD, MMMM YYYY')}</p>
      &nbsp;
      <h3>${t('form.lastDeliveryAccount1.label')}</h3>
      <p>${dayjs(values.lastDeliveryAccount1).locale(i18n.language).format('MMMM, YYYY')}</p>
      &nbsp;
      <h3>${t('form.firstDeliveryAccount2.label')}</h3>
      <p>${dayjs(values.firstDeliveryAccount2).locale(i18n.language).format('MMMM, YYYY')}</p>
      &nbsp;
      ${createCommentSection(values.messageInput)}
    `

    const options = createZendeskTicketOptions({
      categoryTitle: location.state.categoryTitleTag,
      comment: message,
      language: i18n.language,
      mainCategory: TicketMainCategory.ACCOUNT,
      subject: t('viewTitle'),
      ticketCategory: TicketCategory.ACCOUNT_COMPANY_NAME_CHANGE,
      sellerId: selectedCompany?.sellerId,
      personalNotes: values.personalNotes,
      attachments: tokens,
    })

    try {
      if (selectedCompany !== null && selectedCompany.uuid) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })
        toast.success(generalTranslation('notification.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      console.error(error)
      toast.error(generalTranslation('notification.error'), { description: error.message })
    }
  }

  const getNoteDescription = () => {
    return (
      <ul>
        <li>{t('notes.description1')}</li>
        <li>{t('notes.description2')}</li>
        <li>
          {t('notes.description3.text')}{' '}
          <Typography.Link
            href={t('notes.description3.url')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('notes.description3.linkText')}
          </Typography.Link>
        </li>
      </ul>
    )
  }

  return (
    <>
      <SubHeader withBackButton />
      <ViewContainer centered>
        <TicketCreationCard title={t('ticketDescriptionTitle')}>
          <Alert
            style={{ marginBottom: '1rem' }}
            type="info"
            message={t('notes.title')}
            description={getNoteDescription()}
          />
          <Typography.Paragraph>{t('ticketDescriptionSubtitle')}</Typography.Paragraph>
          <Form
            name="change-company-name-ticket"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            layout="vertical"
          >
            <CompanyNameChangeInputRequired />
            <Form.Item name="messageInput" label={t('form.comments.label')}>
              <QuillTextarea placeholder={t('form.comments.placeholder')} />
            </Form.Item>
            <PersonalNoteInput />
            <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
            <Form.Item
              name="confirmation_switch"
              label={t('confirmationText')}
              valuePropName="checked"
            >
              <Switch onChange={setConfirmationSwitchValue} />
            </Form.Item>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!confirmationSwitchValue}
                loading={loading}
              >
                {t('sendButtonText')}
              </Button>
            </Row>
          </Form>
        </TicketCreationCard>
      </ViewContainer>
    </>
  )
}
