import { useQuery } from '@apollo/client'
import { Col, DatePicker, Row, Select, Space, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { ViewContainer } from '../../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import { PPCPerformanceCard } from '../../../../components/PPC/layout/PPCPerformanceCard/PPCPerformanceCard'
import SponsoredBrandsAdGroupsTable from '../../../../components/PPC/sponsoredBrands/adGroup/SponsoredBrandsAdGroupsTable'
import {
  AmazonPpcCampaignType,
  GetSponsoredBrandsCampaignWithAllContentDocument,
  GetSponsoredBrandsCampaignWithAllContentQuery,
  Marketplace,
} from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useDateRangePresets } from '../../../../hooks/useDateRangePresets'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import {
  combineSponsoredBrandsAdGroupLiveDataAndReports,
  getColorFromState,
  getTagByCampaignType,
} from '../../helpers'
import { SponsoredBrandsAggregatedAdGroup } from '../../helpers/interfaces'
import { useCurrencySymbol } from '../../helpers/useCurrencySymbol'
import ppcStateStore from '../../ppcStateStore'

export type GetSponsoredBrandsAdGroupLiveDataModel =
  GetSponsoredBrandsCampaignWithAllContentQuery['sponsoredBrandsAdGroupLiveData'][number]
export type GetSponsoredBrandsAdGroupReportsModel =
  GetSponsoredBrandsCampaignWithAllContentQuery['sponsoredBrandsAdGroupReports'][number]

const { RangePicker } = DatePicker

export const PPCSponsoredBrandsCampaignView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { setMarketplace, ppcStartDate, setPpcStartDate, ppcEndDate, setPpcEndDate } =
    ppcStateStore()

  const { t } = useTranslation()

  const navigate = useNavigate()
  const params = useParams<{ id: string; marketplace: string; campaignid: string }>()
  const marketplace = params.marketplace?.toUpperCase() as Marketplace

  const marketplaceOptions = useMarketplaceOptions()
  const currencySymbol = useCurrencySymbol()
  const presets = useDateRangePresets()

  const { loading, error, data } = useQuery(GetSponsoredBrandsCampaignWithAllContentDocument, {
    skip: !selectedCompany.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
      marketplace,
      campaignId: params.campaignid!,
      startDate: ppcStartDate,
      endDate: ppcEndDate,
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (
    error ||
    !data ||
    !data.sponsoredBrandsAdGroupReports ||
    !data.sponsoredBrandsAdGroupLiveData ||
    !data.sponsoredBrandsCampaignLiveData
  ) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  const campaign = data.sponsoredBrandsCampaignLiveData[0]

  const adGroups: SponsoredBrandsAggregatedAdGroup[] =
    combineSponsoredBrandsAdGroupLiveDataAndReports(
      data.sponsoredBrandsAdGroupLiveData,
      data.sponsoredBrandsAdGroupReports
    )

  const handleMarketplaceChange = (value: Marketplace) => {
    navigate(`/amazon/ppc/${value.toLowerCase()}`)
    setMarketplace(value.toLowerCase())
  }

  const rightContent = (
    <Space>
      <Select<Marketplace>
        value={marketplace}
        onChange={handleMarketplaceChange}
        popupMatchSelectWidth={false}
        placement="bottomRight"
        options={marketplaceOptions.map((marketplace) => ({
          value: marketplace,
          label: `Amazon.${getMarketplaceDomain(marketplace as unknown as Marketplace)}`,
        }))}
      />
      <RangePicker
        value={[dayjs(ppcStartDate), dayjs(ppcEndDate)]}
        onCalendarChange={(values) => {
          if (values[0]) {
            setPpcStartDate(values[0].format('YYYY-MM-DD'))
          }

          if (values[1]) {
            setPpcEndDate(values[1].format('YYYY-MM-DD'))
          }
        }}
        allowClear={false}
        disabled={loading}
        disabledDate={(date) =>
          date.isAfter(dayjs()) || date.isBefore(dayjs().subtract(6, 'month'))
        }
        format="DD.MM.YY"
        placement="bottomRight"
        presets={presets}
      />
    </Space>
  )

  return (
    <>
      <SubHeader withBackButton rightContent={rightContent} />
      <ViewContainer>
        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <Typography.Text strong ellipsis>
              Campaign: {campaign?.name}
            </Typography.Text>
          </Col>
          <Col>
            <Space>
              <Tag>
                {currencySymbol}
                {campaign?.budget} - {campaign?.budgetType}
              </Tag>
              <Tag color={campaign?.state ? getColorFromState(campaign?.state) : undefined}>
                {campaign?.state}
              </Tag>
              {getTagByCampaignType(AmazonPpcCampaignType.SPONSOREDBRANDS)}
            </Space>
          </Col>
        </Row>
        <SponsoredBrandsAdGroupsTable adGroups={adGroups} />
      </ViewContainer>
      <PPCPerformanceCard data={adGroups} />
    </>
  )
}
