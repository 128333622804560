export const getFileTypeFromContentType = (contentType: string | null) => {
  if (!contentType) return ''

  const isImage = contentType?.startsWith('image/')
  const isPdf = contentType === 'application/pdf'
  const isExcel =
    contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    contentType === 'application/vnd.ms-excel'
  const isWord =
    contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    contentType === 'application/msword' ||
    contentType === 'application/vnd.ms-word'
  const isText =
    contentType?.startsWith('text/') ||
    contentType === 'application/json' ||
    contentType === 'application/xml'

  if (isImage) {
    return 'image'
  } else if (isPdf) {
    return 'pdf'
  } else if (isExcel) {
    return 'excel'
  } else if (isWord) {
    return 'word'
  } else if (isText) {
    return 'text'
  } else {
    return 'unknown'
  }
}
