import { DatePicker, Select, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Marketplace } from '../../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../../helpers/getMarketplaceDomain'
import { useDateRangePresets } from '../../../../../hooks/useDateRangePresets'
import { useMarketplaceOptions } from '../../../../../hooks/useMarketplaceOptions'

const { RangePicker } = DatePicker

type AmazonAdsPerformanceCardFiltersProps = {
  dateRange: [Dayjs, Dayjs]
  setDateRange: Dispatch<SetStateAction<[Dayjs, Dayjs]>>
  marketplace: Marketplace
  setMarketplace: Dispatch<SetStateAction<Marketplace>>
  loading: boolean
}

export const AmazonAdsPerformanceCardFilters = ({
  dateRange,
  setDateRange,
  marketplace,
  setMarketplace,
  loading,
}: AmazonAdsPerformanceCardFiltersProps) => {
  const marketplaceOptions = useMarketplaceOptions()
  const presets = useDateRangePresets()

  const { t } = useTranslation('translation')

  return (
    <Space>
      <RangePicker
        value={dateRange}
        onCalendarChange={(values) => {
          if (values?.[0] && values?.[1]) {
            setDateRange([values[0].startOf('day'), values[1].endOf('day')])
          }
        }}
        allowClear={false}
        disabledDate={(date) =>
          date.isAfter(dayjs()) || date.isBefore(dayjs().subtract(6, 'month'))
        }
        format="DD.MM.YY"
        placement="bottomRight"
        presets={presets}
      />
      <Select<Marketplace>
        value={marketplace}
        onChange={setMarketplace}
        options={marketplaceOptions.map((marketplace) => ({
          value: marketplace,
          label: `Amazon.${getMarketplaceDomain(marketplace)}`,
        }))}
        disabled={loading}
        popupMatchSelectWidth={150}
        placeholder={t('shared.form.placeholder.select')}
        placement="bottomRight"
      />
    </Space>
  )
}
