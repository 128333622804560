import { ICellEditorParams } from 'ag-grid-community'
import { CustomCellEditorProps } from 'ag-grid-react'
import { Button, Divider, Flex, Tag } from 'antd'
import Select from 'antd/es/select'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getProductVariantPackagingUnitTypeColor,
  getProductVariantPackagingUnitTypeText,
} from '../helpers/useWholesaleOrderFormTableColumnDefs'
import { PackagingUnit, WholesaleOrderFormTableRecord } from '../WholesaleOrderFormTable'
import { WholesaleOrderTablePackagingUnitCreateModal } from './WholesaleOrderTablePackagingUnitCreateModal'

export const WholesaleOrderTablePackagingUnitSelect = (
  props: ICellEditorParams<WholesaleOrderFormTableRecord> &
    CustomCellEditorProps<WholesaleOrderFormTableRecord>
) => {
  const { data, api, node, column } = props
  const { t } = useTranslation('inventory')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSelectOpen, setIsSelectOpen] = useState(true)
  const [localPackagingUnits, setLocalPackagingUnits] = useState<
    WholesaleOrderFormTableRecord['packagingUnits']
  >(data?.packagingUnits ?? [])

  const selectOptions = localPackagingUnits.map((packagingUnit) => ({
    label: (
      <Flex justify={'space-between'} align={'center'}>
        {packagingUnit.quantity}
        <Tag color={getProductVariantPackagingUnitTypeColor(packagingUnit.type)}>
          {getProductVariantPackagingUnitTypeText(packagingUnit.type, t, true)}
        </Tag>
      </Flex>
    ),
    value: packagingUnit.quantity,
  }))

  const handleSelect = (selectedQuantity: number) => {
    if (api && node && column) {
      const selectedPackagingUnit = localPackagingUnits.find(
        (unit) => unit.quantity === selectedQuantity
      )

      if (!selectedPackagingUnit) {
        return
      }

      if (props.onValueChange) {
        props.onValueChange(selectedPackagingUnit)
      }

      api.stopEditing()
    }
  }

  const handleAddClick = () => {
    setIsSelectOpen(false)
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setIsSelectOpen(true)
  }

  const handleModalSuccess = (newPackagingUnit: PackagingUnit) => {
    const updatedPackagingUnits = [...localPackagingUnits, newPackagingUnit].sort(
      (a, b) => a.quantity - b.quantity
    )

    setLocalPackagingUnits(updatedPackagingUnits)

    api?.applyTransaction({
      update: [
        {
          ...data,
          packagingUnits: updatedPackagingUnits,
        },
      ],
    })

    handleModalClose()
  }

  const existingQuantities = new Set<number>(localPackagingUnits.map((unit) => unit.quantity))

  return (
    <>
      <Select<number>
        open={isSelectOpen}
        options={selectOptions}
        defaultValue={data?.selectedPackagingUnit?.quantity}
        onSelect={handleSelect}
        onDropdownVisibleChange={setIsSelectOpen}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Button icon={<Plus size={16} />} block onClick={handleAddClick}>
              {t('product.details.productVariant.packagingUnitModal.createButton')}
            </Button>
          </>
        )}
        style={{ width: '100%', height: '100%' }}
      />
      <WholesaleOrderTablePackagingUnitCreateModal
        open={isModalOpen}
        onClose={handleModalClose}
        productVariantUuid={data.productVariantUuid}
        onSuccess={handleModalSuccess}
        existingQuantities={existingQuantities}
      />
    </>
  )
}
