import { Marketplace } from '../../generated/graphql'

export enum FileFormat {
  CSV = 'csv',
  XLSX = 'xlsx',
}

export type AmazonOrderReport = {
  amazonOrderId: string
  fulfillmentChannel: string
  name: string
  asin: string
  sku: string
  marketplace: Marketplace
  quantity: number
  price: string
  status: string
  purchaseDate: string
  lastUpdateDate: string
}

export type InventoryReportData = {
  asin: string
  sku: string
  name: string
  marketplace: Marketplace
  salesShippedLast7Days: number | null
  salesShippedLast30Days: number | null
  salesShippedLast60Days: number | null
  salesShippedLast90Days: number | null
  unitsShippedLast7Days: number | null
  unitsShippedLast30Days: number | null
  unitsShippedLast60Days: number | null
  unitsShippedLast90Days: number | null
  sellThrough: number | null
  daysOfSupply: number | null
  inventoryAge0To90: number | null
  inventoryAge91To180: number | null
  inventoryAge181To330: number | null
  inventoryAge331To365: number | null
  inventoryAge365Plus: number | null
  healthyInventoryLevel: number | null
  recommendedRemovalQuantity: number | null
  pendingRemovalQuantity: number | null
  estimatedExcessQuantity: number | null
  weeksOfCoverLast30Days: number | null
  weeksOfCoverLast90Days: number | null
  ltsfQuantity11Months: number | null
  ltsfEstimated11Months: number | null
  ltsfQuantity12Months: number | null
  ltsfEstimated12Months: number | null
  estimatedStorageCostNextMonth: number | null
  storageVolume: number | null
  storageVolumeMeasurement: string | null
  storageType: string | null
  fulfillableQuantity: number | null
  inboundWorkingQuantity: number | null
  inboundShippedQuantity: number | null
  inboundReceivingQuantity: number | null
  pendingCustomerOrderQuantity: number | null
  pendingTransshipmentQuantity: number | null
  fcProcessingQuantity: number | null
  customerDamagedQuantity: number | null
  warehouseDamagedQuantity: number | null
  distributorDamagedQuantity: number | null
  carrierDamagedQuantity: number | null
  defectiveQuantity: number | null
  expiredQuantity: number | null
  researchingQuantity: number | null
}

export type InventoryHealthReportData = {
  name: string
  asin: string
  sku: string
  marketplace: string
  mainCategory: string | null
  salesRank: number | null
  salesShippedLast7Days: number | string | null
  salesShippedLast30Days: number | string | null
  salesShippedLast60Days: number | string | null
  salesShippedLast90Days: number | string | null
  unitsShippedLast7Days: number | null
  unitsShippedLast30Days: number | null
  unitsShippedLast60Days: number | null
  unitsShippedLast90Days: number | null
  sellThrough: number | null
  fulfillableQuantity: number | null
  inbound: number | null
  reserved: number | null
  unfulfillable: number | null
  daysOfSupply: number | null
  inventoryAge0To90: number | null
  inventoryAge91To180: number | null
  inventoryAge181To330: number | null
  inventoryAge331To365: number | null
  inventoryAge365Plus: number | null
  healthyInventoryLevel: number | null
  recommendedRemovalQuantity: number | null
  pendingRemovalQuantity: number | null
  estimatedExcessQuantity: number | null
  weeksOfCoverLast30Days: number | null
  weeksOfCoverLast90Days: number | null
  ltsfQuantity11Months: number | null
  ltsfEstimated11Months: number | null
  ltsfQuantity12Months: number | null
  ltsfEstimated12Months: number | null
  estimatedStorageCostNextMonth: number | string | null
  storageVolume: number | string | null
  alert: string | null
  recommendedAction: string | null
}
