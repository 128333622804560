import styled from '@emotion/styled'
import { Descriptions, Tag, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { StyledMainCard } from '../../dashboard/styles'
import { descriptionsStyle } from '../../products/components/styles'
import { ProductComplianceCheckDetailsRecord } from '../ComplianceDetailsView'

type ProductComplianceCheckDataCardProps = {
  data: ProductComplianceCheckDetailsRecord
}

export const ProductComplianceCheckDataCard = ({ data }: ProductComplianceCheckDataCardProps) => {
  const { t } = useTranslation(['compliance'])
  const navigate = useNavigate()

  return (
    <StyledMainCard title={t('compliance:details.detailsCard.title')}>
      <Descriptions {...descriptionsStyle}>
        <Descriptions.Item label={t('compliance:details.detailsCard.identifier')}>
          {data.identifier}
        </Descriptions.Item>
        <Descriptions.Item label={t('compliance:details.detailsCard.productName')}>
          <Typography.Link onClick={() => navigate(`/products/${data.productUuid}`)}>
            {data.productName}
          </Typography.Link>
        </Descriptions.Item>
        <Descriptions.Item label={t('compliance:details.detailsCard.eans')}>
          {data.productVariants?.map((productVariant) => {
            if (!productVariant.ean) {
              return null
            }

            return (
              <Typography.Link key={productVariant.ean}>
                <LinkTag
                  onClick={() =>
                    navigate(`/products/${data.productUuid}/variants/${productVariant.uuid}`)
                  }
                >
                  {productVariant.ean}
                </LinkTag>
              </Typography.Link>
            )
          })}
        </Descriptions.Item>
        <Descriptions.Item label={t('compliance:details.detailsCard.serviceProvider')}>
          {data.serviceProvider}
        </Descriptions.Item>
        <Descriptions.Item label={t('compliance:details.detailsCard.createdAt')}>
          {data.createdAt.format('DD.MM.YYYY')}
        </Descriptions.Item>
      </Descriptions>
    </StyledMainCard>
  )
}

const LinkTag = styled(Tag)`
  cursor: pointer;
  color: inherit;

  :hover {
    color: inherit;
  }
`
