import { useMutation, useQuery } from '@apollo/client'
import { Button, Form, Modal, Popconfirm, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'sonner'
import {
  CreateSponsoredProductsTargetInput,
  CreateSponsoredProductsTargetsDocument,
  CreateSponsoredProductsTargetsMutation,
  GetAllAmazonProductsByAmazonListingSkusDocument,
  GetAllAmazonProductsByAmazonListingSkusQuery,
  Marketplace,
} from '../../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../../stores/useGlobalStore'
import CreateSponsoredProductsCampaignProductTargetsFormFields, {
  UserDefinedTarget,
} from '../CreateSponsoredProductsManualTargetsWidget/CreateSponsoredProductsManualTargetsFormFields'
type CreateSponsoredProductsManualTargetsModalProps = {
  campaignId: string
  adGroupId: string
  productAdSkus: string[]
  disabled: boolean
  onTargetsAdded: Function
}

const CreateSponsoredProductsManualTargetsModal = ({
  campaignId,
  adGroupId,
  productAdSkus,
  disabled,
  onTargetsAdded,
}: CreateSponsoredProductsManualTargetsModalProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [loadingCreateTargets, setLoadingCreateTargets] = useState<boolean>(false)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const [createSponsoredProductsTargets] = useMutation<CreateSponsoredProductsTargetsMutation>(
    CreateSponsoredProductsTargetsDocument
  )
  const params = useParams<{ marketplace: Marketplace }>()
  const [modalKey, setModalKey] = useState(Math.floor(Math.random() * 99999999).toString())

  const { loading, error, data } = useQuery<GetAllAmazonProductsByAmazonListingSkusQuery>(
    GetAllAmazonProductsByAmazonListingSkusDocument,
    {
      variables: {
        amazonListingSkus: productAdSkus,
      },
    }
  )

  if (error) {
    return null
  }

  useEffect(() => {
    setModalKey(Math.floor(Math.random() * 99999999).toString())
  }, [modalVisible])

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleCreateTargets = async (targets: CreateSponsoredProductsTargetInput[]) => {
    const request = await createSponsoredProductsTargets({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: params.marketplace?.toUpperCase()! as Marketplace,
        targets: targets,
      },
    })

    return request.data?.createSponsoredProductsTargets
  }

  const handleOnFinish = async (values: any) => {
    const targets = values.productTargets

    setLoadingCreateTargets(true)

    try {
      if (!targets.length) {
        throw new Error('No targets to create')
      }
      const formattedTargets: CreateSponsoredProductsTargetInput[] = targets.map(
        (target: UserDefinedTarget) => {
          return {
            campaignId: campaignId,
            adGroupId: adGroupId,
            expression: [
              {
                type: target.expressionType,
                value: target.expressionValue,
              },
            ],
            resolvedExpression: [
              {
                type: target.expressionType,
                value: target.resolvedExpressionValue,
              },
            ],
            bid: target.bid,
            expressionType: 'manual',
          }
        }
      )

      const createdTargets = await handleCreateTargets(formattedTargets)

      toast.success('Targets successfully created')

      onTargetsAdded(createdTargets)
    } catch (error: any) {
      console.error(error)
      toast.error('Error adding targets', { description: error.message })
    } finally {
      setLoadingCreateTargets(false)
      setModalVisible(false)
    }
  }

  return (
    <>
      <Button key="huihif" onClick={showModal} disabled={disabled} data-testid="add-keyword-btn">
        Add targets
      </Button>

      <Modal
        title="Create Product Targets"
        open={modalVisible}
        onCancel={handleCancel}
        width={800}
        key={modalKey}
        footer={
          <Space>
            <Button onClick={handleCancel} key="cancel">
              Cancel
            </Button>
            <Popconfirm
              key="u8f8f"
              placement="top"
              title={
                'New Targets will be created immediately on Amazon. Please confirm that you would like to continue.'
              }
              onConfirm={form.submit}
              okText="Continue"
              cancelText="Go back"
              disabled={loadingCreateTargets || loading}
              overlayInnerStyle={{ maxWidth: '400px' }}
            >
              <Button key="savel" disabled={loadingCreateTargets || loading} type={'primary'}>
                Save
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <Form form={form} onFinish={handleOnFinish}>
          <CreateSponsoredProductsCampaignProductTargetsFormFields
            form={form}
            marketplace={params.marketplace?.toUpperCase()! as Marketplace}
            selectedProductAsins={
              data?.amazonProducts
                ? data?.amazonProducts.map((amazonProduct) => amazonProduct.asin)
                : []
            }
          />
        </Form>
      </Modal>
    </>
  )
}

export default CreateSponsoredProductsManualTargetsModal
