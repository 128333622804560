import { useQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Flex,
  Form,
  FormListFieldData,
  Image,
  Input,
  InputNumber,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Tag,
  theme,
  Typography,
  Upload,
  UploadFile,
} from 'antd'
import { FormInstance, useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { NamePath, StoreValue } from 'antd/es/form/interface'
import { TFunction } from 'i18next'
import { CircleHelp, Pen, Plus, Trash, Undo, UploadIcon } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'remeda'
import { toast } from 'sonner'
import { Flag } from '../../../components/Flag'
import {
  CompanyType,
  GetAllEansAndInternalSkusFromCompanyDocument,
  GetAllSalesChannelCategoriesDocument,
  GetCompanyBrandsDocument,
  GetProductVariantOptionsDocument,
  LabelType,
  Marketplace,
} from '../../../generated/graphql'
import { formatPercentage } from '../../../helpers/formatPercentage'
import { getMarketplaceCurrency } from '../../../helpers/getMarketplaceCurrency'
import { getMarketplaceDomain } from '../../../helpers/getMarketplaceDomain'
import { useCountryOptions, useCountryOptionsForCompliance } from '../../../hooks/useCountryOptions'
import { useMarketplaceOptions } from '../../../hooks/useMarketplaceOptions'
import { usePreventNavigation } from '../../../hooks/usePreventNavigation'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { asinRegExp, taricRegExp } from '../../../utils/regExp'
import { StyledMainCard, StyledSubCard } from '../../dashboard/styles'
import { generateProductVariants } from '../helpers/generateProductVariants'
import { CreateBrandModal } from './CreateBrandModal'

// Improvements:
// - Store form state in global state.
// - Improve validation messages.
// - Button for resetting the form.
// - After adding a new option, focus on the option input field.
// - After selecting an option, focus on the first option value input field.

const { useForm } = Form

export type ProductCreateFormInstance = {
  name: string
  brand: string
  manufacturer: string
  countryOfOrigin: string
  taric: string
  complianceImages?: ProductComplianceImages
  hasVariants: boolean
  ean: string
  internalSku?: string
  packageLength?: number
  packageWidth?: number
  packageHeight?: number
  packageWeight?: number
  salesChannels?: ProductVariantSalesChannels
  options?: ProductVariantOptionFormValues[]
  variants?: ProductVariantFormInstance[]
  note?: string
  salesChannelCategory: string
  complianceCheckCountryCodes: string[]
}

type ProductComplianceImages = {
  package?: {
    frontView?: UploadFile
    backView?: UploadFile
    topView?: UploadFile
    bottomView?: UploadFile
    leftView?: UploadFile
    rightView?: UploadFile
  }
  product?: {
    frontView?: UploadFile
    backView?: UploadFile
    topView?: UploadFile
    bottomView?: UploadFile
    leftView?: UploadFile
    rightView?: UploadFile
    insideView?: UploadFile
  }
  label?: {
    frontView?: UploadFile
    backView?: UploadFile
  }
  document?: {
    frontView?: UploadFile
    backView?: UploadFile
  }
}

export type ProductVariantOptionFormValues = {
  option: string
  values: (string | undefined)[]
  isEditing: boolean
}

export type ProductVariantFormInstance = {
  id?: string
  ean: string
  internalSku?: string
  packageLength?: number
  packageWidth?: number
  packageHeight?: number
  packageWeight?: number
  attributes?: ProductAttribute[]
  salesChannels?: ProductVariantSalesChannels
  shouldCreate?: boolean
}

export type ProductVariantSalesChannels = {
  amazon: {
    asin?: string
    labelType?: LabelType
    subscribeAndSave?: number
    marketplaces: Partial<
      Record<
        Marketplace,
        {
          price: number
          shouldCreate: boolean
        }
      >
    >
  }
}

export type ProductAttribute = {
  option: string
  value: string
}

type ProductCreateFormProps = {
  form: FormInstance<ProductCreateFormInstance>
  submitForm: (values: ProductCreateFormInstance) => void
}

export const ProductCreateForm = ({ form, submitForm }: ProductCreateFormProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('inventory')
  const companyMarketplaces = useMarketplaceOptions()

  const salesChannelsRequired = selectedCompany.type !== CompanyType.COMPLIANCE
  const showRRP = selectedCompany.type !== CompanyType.WHOLESALER

  usePreventNavigation(form)

  const handleFinish = async () => {
    try {
      const validatedForm = await form.validateFields()

      const variants = validateVariants(validatedForm, t, salesChannelsRequired, showRRP)

      submitForm({ ...validatedForm, variants })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Form<ProductCreateFormInstance>
      name="product-form"
      form={form}
      layout="vertical"
      initialValues={{
        hasVariants: false,
        complianceCheckCountryCodes: companyMarketplaces,
      }}
      onFinish={handleFinish}
      style={{ width: '100%' }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={12}>
          <Flex vertical gap={16}>
            <ProductInformationFormCard />
            <ProductVariantFormCard />
          </Flex>
        </Col>
        <Col xs={24} xl={12}>
          <ProductComplianceFormCard />
        </Col>
      </Row>
    </Form>
  )
}

function validateVariants(
  form: ProductCreateFormInstance,
  t: TFunction<'inventory'>,
  salesChannelsRequired: boolean,
  showRRP: boolean
) {
  if (form.hasVariants) {
    const variantsToCreate = form.variants?.filter((variant) => variant.shouldCreate) ?? []

    if (!variantsToCreate.length) {
      toast.error(t('product.create.variant.validation.required'))
      throw new Error(t('product.create.variant.validation.required'))
    }

    for (const variant of variantsToCreate) {
      const variantLabel = variant.attributes?.map(({ value }) => value).join(' / ') ?? ''

      if (
        !variant.packageHeight ||
        !variant.packageLength ||
        !variant.packageWidth ||
        !variant.packageWeight
      ) {
        toast.error(
          t('product.create.variant.packageDimensions.validation.required.title', {
            label: variantLabel,
          }),
          {
            description: t(
              'product.create.variant.packageDimensions.validation.required.description'
            ),
          }
        )
        throw new Error(
          t('product.create.variant.packageDimensions.validation.required.description')
        )
      }

      if (
        !Object.values(variant.salesChannels?.amazon.marketplaces ?? {}).some((marketplace) => {
          return marketplace.shouldCreate
        })
      ) {
        toast.error(
          t('product.create.variant.salesChannels.validation.required.title', {
            label: variantLabel,
          }),
          {
            description: t('product.create.variant.salesChannels.validation.required.description'),
          }
        )
        throw new Error(t('product.create.variant.salesChannels.validation.required.description'))
      }

      for (const marketplace of Object.keys(variant.salesChannels?.amazon.marketplaces ?? {})) {
        if (
          variant.salesChannels?.amazon.marketplaces[marketplace as Marketplace]?.shouldCreate &&
          !variant.salesChannels?.amazon.marketplaces[marketplace as Marketplace]?.price &&
          showRRP
        ) {
          toast.error(
            t('product.create.variant.salesChannels.validation.price.title', {
              label: variantLabel,
            }),
            {
              description: t('product.create.variant.salesChannels.validation.price.description', {
                marketplace,
              }),
            }
          )
          throw new Error(
            t('product.create.variant.salesChannels.validation.price.description', { marketplace })
          )
        }
      }
    }

    return variantsToCreate
  } else {
    if (
      salesChannelsRequired &&
      !Object.values(form.salesChannels?.amazon.marketplaces ?? {}).some((marketplace) => {
        return marketplace.shouldCreate
      })
    ) {
      toast.error(t('product.create.variant.salesChannels.validation.required.description'))
      throw new Error(t('product.create.variant.salesChannels.validation.required.description'))
    }

    for (const marketplace of Object.keys(form.salesChannels?.amazon.marketplaces ?? {})) {
      if (
        form.salesChannels?.amazon.marketplaces[marketplace as Marketplace]?.shouldCreate &&
        !form.salesChannels?.amazon.marketplaces[marketplace as Marketplace]?.price &&
        showRRP
      ) {
        toast.error(
          t('product.create.variant.salesChannels.validation.price.description', {
            marketplace,
          })
        )
        throw new Error(
          t('product.create.variant.salesChannels.validation.price.description', { marketplace })
        )
      }
    }

    return [
      {
        ean: form.ean,
        internalSku: form.internalSku,
        packageLength: form.packageLength,
        packageWidth: form.packageWidth,
        packageHeight: form.packageHeight,
        packageWeight: form.packageWeight,
        attributes: [],
        salesChannels: form.salesChannels,
      },
    ]
  }
}

const ProductInformationFormCard = () => {
  const { t } = useTranslation('inventory')
  const countryOptions = useCountryOptions()

  const { data, loading } = useQuery(GetAllSalesChannelCategoriesDocument)

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const options = data?.salesChannelCategories.map((salesChannelCategory) => ({
    value: salesChannelCategory.value,
    label: salesChannelCategory.name,
  }))

  return (
    <StyledMainCard title={t('product.create.general.title')}>
      <Form.Item<ProductCreateFormInstance>
        name="name"
        label={t('product.create.general.name')}
        rules={[{ max: 100, required: true, whitespace: true }]}
      >
        <Input autoFocus />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Alert
            description={
              <Flex vertical gap={8}>
                {t('product.create.general.alert.brand.description')}
                <Flex justify="end">
                  <CreateBrandModal />
                </Flex>
              </Flex>
            }
          />
        </Col>
        <Col span={12}>
          <ProductBrandFormItem />
        </Col>
        <Col span={12}>
          <Form.Item<ProductCreateFormInstance>
            name="manufacturer"
            label={t('product.create.general.manufacturer')}
            rules={[{ max: 255, required: true, whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item<ProductCreateFormInstance>
            name="countryOfOrigin"
            label={t('product.create.general.countryOfOrigin')}
            rules={[{ required: true }]}
          >
            <Select<string>
              options={countryOptions.map((country) => ({
                value: country.code,
                label: country.name,
              }))}
              placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
              showSearch
              filterOption={true}
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item<ProductCreateFormInstance>
            name="taric"
            label={t('product.create.general.taric')}
            rules={[{ max: 255, pattern: taricRegExp, required: true }]}
            tooltip={t('product.create.general.tooltip.taric')}
            validateDebounce={1000}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="salesChannelCategory"
            label={t('product.details.product.editSalesChannelCategoryModal.label')}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder={t('product.details.product.editSalesChannelCategoryModal.placeholder')}
              optionFilterProp="children"
              filterOption={filterOption}
              options={options}
              style={{ width: '100%' }}
              loading={loading}
            />
          </Form.Item>
        </Col>
      </Row>
    </StyledMainCard>
  )
}

const ProductBrandFormItem = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation('inventory')

  const { loading, data } = useQuery(GetCompanyBrandsDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    skip: !selectedCompany?.uuid,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
    },
    onError: () => toast.error(t('shared.error.message', { ns: 'translation' })),
  })

  return (
    <Form.Item<ProductCreateFormInstance>
      name="brand"
      label={t('product.create.general.brand')}
      rules={[{ required: true }]}
    >
      <Select<string>
        options={data?.brands.map((brand) => ({ value: brand.name, label: brand.name }))}
        placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
        loading={loading}
        notFoundContent={
          <div style={{ padding: '0.5rem' }}>
            <Typography.Text type="secondary">
              {t('product.create.general.fallback.brand')}
            </Typography.Text>
          </div>
        }
      />
    </Form.Item>
  )
}

const ProductVariantFormCard = () => {
  const { t } = useTranslation('inventory')
  const hasVariants = useWatch<ProductCreateFormInstance['hasVariants']>('hasVariants')

  return (
    <StyledMainCard
      title={
        <Space>
          {t('product.create.variant.title')}
          <Popover
            arrow={{ pointAtCenter: true }}
            title={t('product.create.variant.popover.title')}
            content={t('product.create.variant.popover.content')}
            overlayStyle={{ maxWidth: 400 }}
          >
            <CircleHelp size={16} />
          </Popover>
        </Space>
      }
      extra={
        <Form.Item<ProductCreateFormInstance> name="hasVariants" valuePropName="checked" noStyle>
          <Switch />
        </Form.Item>
      }
      style={{ height: 'auto' }}
    >
      {hasVariants ? (
        <>
          <ProductVariantOptionFormList />
          <ProductVariantFormList />
        </>
      ) : (
        <ProductVariantTabs />
      )}
    </StyledMainCard>
  )
}

const ProductVariantOptionFormList = () => {
  const { t } = useTranslation('inventory')

  return (
    <Form.List name="options">
      {(optionFields, { add: addOption, remove: removeOption }) => (
        <Flex vertical gap={16}>
          <Flex vertical gap={8}>
            {optionFields.map((optionField) => (
              <ProductVariantOptionFormCard
                key={optionField.key}
                optionField={optionField}
                removeOption={removeOption}
              />
            ))}
          </Flex>
          {optionFields.length < 3 && (
            <Button
              type="link"
              icon={<Plus size={16} />}
              onClick={() => addOption({ option: undefined, values: [''], isEditing: true })}
              style={{ alignSelf: 'flex-start' }}
            >
              {t('product.create.variant.attribute.add')}
            </Button>
          )}
        </Flex>
      )}
    </Form.List>
  )
}

const ProductVariantOptionFormCard = ({
  optionField,
  removeOption,
}: {
  optionField: FormListFieldData
  removeOption: (index: number | number[]) => void
}) => {
  const form = useFormInstance<ProductVariantFormInstance>()

  return (
    <Form.Item<ProductCreateFormInstance>
      key={optionField.key}
      shouldUpdate={(prevValues, nextValues) => {
        return !R.isDeepEqual(
          prevValues.options?.[optionField.name]?.isEditing,
          nextValues.options?.[optionField.name]?.isEditing
        )
      }}
      noStyle
    >
      {() => {
        const isEditing: ProductVariantOptionFormValues['isEditing'] | undefined =
          form.getFieldValue(['options', optionField.name, 'isEditing'])

        if (isEditing === undefined) {
          return null
        }

        return isEditing ? (
          <ProductVariantOptionFormEditCard optionField={optionField} removeOption={removeOption} />
        ) : (
          <ProductVariantOptionFormViewCard optionField={optionField} />
        )
      }}
    </Form.Item>
  )
}

const ProductVariantOptionFormEditCard = ({
  optionField,
  removeOption,
}: {
  optionField: FormListFieldData
  removeOption: (index: number | number[]) => void
}) => {
  const form = useFormInstance<ProductVariantFormInstance>()
  const options = useWatch<ProductCreateFormInstance['options']>('options', form)

  const { t } = useTranslation('inventory')

  const { loading, data } = useQuery(GetProductVariantOptionsDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    onError: () => toast.error(t('shared.error.message', { ns: 'translation' })),
  })

  const handleDoneClick = async () => {
    await form.validateFields([
      ['options', optionField.name, 'option'],
      ['options', optionField.name, 'values', 0],
    ])

    const values: ProductVariantOptionFormValues['values'] =
      form.getFieldValue(['options', optionField.name, 'values']) ?? []

    form.setFieldValue(
      ['options', optionField.name, 'values'],
      values.map((value) => value?.trim())
    )

    form.setFieldValue(['options', optionField.name, 'isEditing'], false)
  }

  return (
    <StyledSubCard>
      <Form.Item<ProductCreateFormInstance>
        label={t('product.create.variant.attribute.label')}
        style={{ marginBottom: 0 }}
      >
        <Flex vertical gap={16}>
          <Flex gap={8}>
            <Form.Item<ProductCreateFormInstance['options']>
              name={[optionField.name, 'option']}
              rules={[
                {
                  validateTrigger: ['onBlur', 'onChange'],
                  validator: () => {
                    const uniqueOptions = new Set(
                      options?.map((option) => option.option).filter(Boolean)
                    )

                    if (
                      uniqueOptions.size !==
                      options?.map((option) => option.option).filter(Boolean).length
                    ) {
                      return Promise.reject(
                        t('product.create.variant.attribute.validation.duplicate')
                      )
                    }

                    return Promise.resolve()
                  },
                },
                {
                  message: t('product.create.variant.attribute.validation.required'),
                  required: true,
                  validateTrigger: ['onBlur'],
                },
              ]}
              validateTrigger={['onBlur', 'onChange']}
              noStyle
            >
              <Select<ProductVariantOptionFormValues['option']>
                showSearch
                options={data?.productVariantOptions?.map((option) => ({
                  value: option,
                  label: option,
                }))}
                placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
                loading={loading}
              />
            </Form.Item>
            <Button
              type="text"
              icon={<Trash size={16} />}
              onClick={() => removeOption(optionField.name)}
              style={{ flexShrink: 0 }}
            />
          </Flex>
          <Form.Item<ProductCreateFormInstance>
            label={t('product.create.variant.value.label')}
            style={{ marginBottom: 0 }}
          >
            <Form.List name={[optionField.name, 'values']}>
              {(valueFields, { add: addValue, remove: removeValue }) => (
                <Flex vertical gap={8}>
                  {valueFields.map((valueField) => (
                    <Form.Item<ProductCreateFormInstance>
                      key={valueField.key}
                      shouldUpdate={(prevValues, nextValues) => {
                        return !R.isDeepEqual(
                          prevValues.options?.[optionField.name]?.values?.[valueField.name],
                          nextValues.options?.[optionField.name]?.values?.[valueField.name]
                        )
                      }}
                      noStyle
                    >
                      {() => (
                        <ProductVariantOptionValueFormItem
                          optionField={optionField}
                          valueFields={valueFields}
                          valueField={valueField}
                          addValue={addValue}
                          removeValue={removeValue}
                        />
                      )}
                    </Form.Item>
                  ))}
                </Flex>
              )}
            </Form.List>
          </Form.Item>
          <Flex justify="end">
            <Button onClick={handleDoneClick}>{t('product.create.variant.done')}</Button>
          </Flex>
        </Flex>
      </Form.Item>
    </StyledSubCard>
  )
}

const ProductVariantOptionFormViewCard = ({ optionField }: { optionField: FormListFieldData }) => {
  const form = useFormInstance<ProductVariantFormInstance>()

  const option: ProductVariantOptionFormValues | undefined = form.getFieldValue([
    'options',
    optionField.name,
  ])

  if (!option) {
    return null
  }

  const handleEditClick = () => {
    form.setFieldValue(['options', optionField.name, 'isEditing'], true)
  }

  return (
    <StyledSubCard>
      <Flex justify="space-between">
        <Flex vertical gap={8}>
          <Typography.Text strong>{option.option}</Typography.Text>
          <div>
            {option.values.filter(Boolean).map((value, index) => (
              <Tag key={index}>{value}</Tag>
            ))}
          </div>
        </Flex>
        <Button type="text" icon={<Pen size={16} />} onClick={handleEditClick} />
      </Flex>
    </StyledSubCard>
  )
}

const ProductVariantOptionValueFormItem = ({
  optionField,
  valueFields,
  valueField,
  addValue,
  removeValue,
}: {
  optionField: FormListFieldData
  valueFields: FormListFieldData[]
  valueField: FormListFieldData
  addValue: (defaultValue?: StoreValue, insertIndex?: number) => void
  removeValue: (index: number | number[]) => void
}) => {
  const form = useFormInstance<ProductVariantFormInstance>()

  const { t } = useTranslation('inventory')

  const values: ProductVariantOptionFormValues['values'] =
    form.getFieldValue(['options', optionField.name, 'values']) ?? []

  const showDeleteButton =
    values.map((value) => value?.trim()).filter(Boolean).length > 1 &&
    values[valueField.name]?.trim()

  return (
    <Row gutter={8} wrap={false}>
      <Col xs={16}>
        <Form.Item<ProductVariantOptionFormValues['values'][number]>
          name={valueField.name}
          rules={[
            {
              validateTrigger: ['onChange'],
              validator: () => {
                const uniqueValues = new Set(values.filter(Boolean))

                if (uniqueValues.size !== values.filter(Boolean).length) {
                  return Promise.reject(t('product.create.variant.value.validation.duplicate'))
                }

                return Promise.resolve()
              },
            },
            {
              validateTrigger: ['onBlur'],
              validator: () => {
                const uniqueValues = new Set(values.filter(Boolean))

                if (uniqueValues.size !== values.filter(Boolean).length) {
                  removeValue(valueField.name)
                }

                return Promise.resolve()
              },
            },
            {
              validateTrigger: ['onBlur'],
              validator: (rule, value: ProductVariantOptionFormValues['values'][number]) => {
                if (values.length === 1 && !value?.trim()) {
                  return Promise.reject(t('product.create.variant.value.validation.required'))
                }

                return Promise.resolve()
              },
              transform: (value: ProductVariantOptionFormValues['values'][number]) => value?.trim(),
            },
          ]}
          validateTrigger={['onBlur', 'onChange']}
          noStyle
        >
          <Input
            placeholder={t('product.create.variant.value.placeholder')}
            onChange={(event) => {
              const value = event.target.value.trim()

              if (
                value &&
                valueFields.length < 25 &&
                valueFields.indexOf(valueField) === valueFields.length - 1
              ) {
                addValue()
              }
            }}
            onBlur={(event) => {
              const value = event.target.value.trim()

              if (
                !value &&
                valueFields.length > 1 &&
                valueFields.indexOf(valueField) !== valueFields.length - 1
              ) {
                removeValue(valueField.name)
                return
              }

              form.setFieldValue(['options', optionField.name, 'values', valueField.name], value)
            }}
          />
        </Form.Item>
      </Col>
      <Col hidden={!showDeleteButton}>
        <Button
          type="text"
          icon={<Trash size={16} />}
          onClick={() => {
            removeValue(valueField.name)

            if (
              values[valueField.name] === values[values.length - 1] ||
              values[values.length - 1]?.trim()
            ) {
              addValue()
            }
          }}
          style={{ flexShrink: 0 }}
        />
      </Col>
    </Row>
  )
}

const ProductVariantFormList = () => {
  const form = useFormInstance<ProductCreateFormInstance>()
  const options = useWatch<ProductCreateFormInstance['options']>('options', form)

  useEffect(() => {
    if (!options) {
      return
    }
    form.setFieldValue('variants', generateProductVariants(form.getFieldValue('variants'), options))
  }, [options])

  return (
    <Form.List name="variants">
      {(variantFields) => (
        <>
          {!!variantFields.length && <Divider />}
          <Flex vertical gap={8}>
            {variantFields.map((variantField) => (
              <Form.Item<ProductCreateFormInstance>
                key={variantField.key}
                shouldUpdate={(prevValues, nextValues) => {
                  return !R.isDeepEqual(
                    prevValues.variants?.[variantField.name],
                    nextValues.variants?.[variantField.name]
                  )
                }}
                noStyle
              >
                {() => <ProductVariantFormItem variantField={variantField} />}
              </Form.Item>
            ))}
          </Flex>
        </>
      )}
    </Form.List>
  )
}

const ProductVariantFormItem = ({ variantField }: { variantField: FormListFieldData }) => {
  const form = useFormInstance<ProductVariantFormInstance>()

  const variant: ProductVariantFormInstance | undefined = form.getFieldValue([
    'variants',
    variantField.name,
  ])

  if (!variant) {
    return null
  }

  const variantLabel = variant.attributes?.map(({ value }) => value).join(' / ') ?? ''

  const isComplete =
    variant.packageHeight &&
    variant.packageLength &&
    variant.packageWidth &&
    variant.packageWeight &&
    Object.values(variant.salesChannels?.amazon.marketplaces ?? {}).some((marketplace) => {
      return marketplace.shouldCreate
    })

  const handleShouldCreate = () => {
    form.setFieldValue(['variants', variantField.name, 'shouldCreate'], !variant.shouldCreate)
  }

  return (
    <StyledSubCard style={{ backgroundColor: variant.shouldCreate ? undefined : 'transparent' }}>
      <Form.Item<ProductCreateFormInstance['variants']> noStyle>
        <Flex gap={16} justify="space-between" align="center">
          <Badge
            text={
              <Typography.Text
                type={variant.shouldCreate ? undefined : 'secondary'}
                delete={!variant.shouldCreate}
                strong
              >
                {variantLabel}
              </Typography.Text>
            }
            color={isComplete ? 'green' : 'orange'}
          />
          <Space>
            <ProductVariantFormModal
              key={variant.id}
              variantField={variantField}
              label={variantLabel}
            />
            <Button
              type="text"
              icon={variant.shouldCreate ? <Trash size={16} /> : <Undo size={16} />}
              onClick={handleShouldCreate}
            />
          </Space>
        </Flex>
      </Form.Item>
    </StyledSubCard>
  )
}

const ProductVariantFormModal = ({
  variantField,
  label,
}: {
  variantField: FormListFieldData
  label: string
}) => {
  const [open, setOpen] = useState(false)

  const { t } = useTranslation('inventory')
  const mainForm = useFormInstance<ProductCreateFormInstance>()
  const [variantForm] = useForm<ProductVariantFormInstance>()

  const variant: ProductVariantFormInstance | undefined = mainForm.getFieldValue([
    'variants',
    variantField.name,
  ])

  if (!variant) {
    return null
  }

  return (
    <>
      <Button type="text" icon={<Pen size={16} />} onClick={() => setOpen(true)} />
      <Modal
        title={t('product.create.variant.modal.title', { label })}
        open={open}
        width={800}
        onCancel={() => {
          setOpen(false)
          variantForm.resetFields()
        }}
        onOk={async () => {
          try {
            const formValues = await variantForm.validateFields()
            mainForm.setFieldValue(['variants', variantField.name], { ...variant, ...formValues })
            setOpen(false)
          } catch (error) {
            console.error(error)
          }
        }}
      >
        <Form
          name={`product-variant-form-${variant.id}`}
          form={variantForm}
          layout="vertical"
          initialValues={variant}
        >
          <ProductVariantTabs />
        </Form>
      </Modal>
    </>
  )
}

const ProductVariantTabs = () => {
  const { t } = useTranslation('inventory')
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const form = useFormInstance<ProductCreateFormInstance>()
  const theme = useTheme()

  const eanValue = useWatch<ProductCreateFormInstance['ean']>('ean')
  const packageLengthValue = useWatch<ProductCreateFormInstance['packageLength']>('packageLength')
  const packageWidthValue = useWatch<ProductCreateFormInstance['packageWidth']>('packageWidth')
  const packageHeightValue = useWatch<ProductCreateFormInstance['packageHeight']>('packageHeight')
  const packageWeightValue = useWatch<ProductCreateFormInstance['packageWeight']>('packageWeight')
  const salesChannelsValue = useWatch<ProductCreateFormInstance['salesChannels']>('salesChannels')

  const [activeTabKey, setActiveTabKey] = useState('general')

  const [isGeneralTabValid, setIsGeneralTabValid] = useState(false)
  const [isPackageDimensionsTabValid, setIsPackageDimensionsTabValid] = useState(false)
  const [isSalesChannelsTabValid, setIsSalesChannelsTabValid] = useState(false)

  useEffect(() => {
    const validateFields = async () => {
      try {
        if (activeTabKey === 'general') {
          await form.validateFields(['ean'], { validateOnly: true })
          setIsGeneralTabValid(true)
        } else if (activeTabKey === 'dimensions') {
          await form.validateFields(
            ['packageLength', 'packageWidth', 'packageHeight', 'packageWeight'],
            { validateOnly: true }
          )
          setIsPackageDimensionsTabValid(true)
        } else if (activeTabKey === 'salesChannels') {
          await form.validateFields(
            [
              ['salesChannels', 'amazon', 'asin'],
              ['salesChannels', 'amazon', 'labelType'],
              ['salesChannels', 'amazon', 'subscribeAndSave'],
            ],
            { validateOnly: true }
          )
          setIsSalesChannelsTabValid(true)
        }
      } catch {
        if (activeTabKey === 'general') {
          setIsGeneralTabValid(false)
        } else if (activeTabKey === 'dimensions') {
          setIsPackageDimensionsTabValid(false)
        } else if (activeTabKey === 'salesChannels') {
          setIsSalesChannelsTabValid(false)
        }
      }
    }

    validateFields()
  }, [
    activeTabKey,
    eanValue,
    packageLengthValue,
    packageWidthValue,
    packageHeightValue,
    packageWeightValue,
    salesChannelsValue,
    form,
  ])

  const hasSalesChannels = [
    CompanyType.RETAILER,
    CompanyType.SUPPLIER,
    CompanyType.WHOLESALER,
  ].includes(selectedCompany.type)

  const tabItems = [
    {
      key: 'general',
      label: t('product.create.variant.general.title'),
      forceRender: true,
      children: <ProductVariantGeneralFormFields />,
      icon: <Badge color={isGeneralTabValid ? theme.colors.success : theme.colors.error} />,
    },
    {
      key: 'dimensions',
      label: t('product.create.variant.packageDimensions.title'),
      forceRender: true,
      children: <ProductVariantPackageDimensionsFormFields />,
      icon: (
        <Badge color={isPackageDimensionsTabValid ? theme.colors.success : theme.colors.error} />
      ),
    },
  ]

  if (hasSalesChannels) {
    tabItems.push({
      key: 'salesChannels',
      label: t('product.create.variant.salesChannels.title'),
      forceRender: true,
      children: <ProductVariantSalesChannelFormFields />,
      icon: <Badge color={isSalesChannelsTabValid ? theme.colors.success : theme.colors.error} />,
    })
  }

  return <Tabs activeKey={activeTabKey} onChange={setActiveTabKey} items={tabItems} />
}

export const ProductVariantGeneralFormFields = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { t } = useTranslation('inventory')

  const { data, error } = useQuery(GetAllEansAndInternalSkusFromCompanyDocument, {
    fetchPolicy: 'cache-first',
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  const eans = useMemo(() => {
    if (!data?.products) {
      return new Set<string>()
    }

    return new Set(
      data.products.flatMap(
        (product) => product.productVariants?.map((variant) => variant.ean).filter(Boolean) ?? []
      )
    )
  }, [data])

  const internalSkus = useMemo(() => {
    if (!data?.products) {
      return new Set<string>()
    }

    return new Set(
      data.products.flatMap(
        (product) =>
          product.productVariants?.map((variant) => variant.internalSku).filter(Boolean) ?? []
      )
    )
  }, [data])

  if (error) {
    toast.error(t('shared.error.message', { ns: 'translation' }))
  }

  return (
    <Row gutter={16}>
      <Col xs={12}>
        <Form.Item<ProductVariantFormInstance>
          name="ean"
          label={t('product.create.variant.general.ean')}
          rules={[
            {
              required: true,
              message: t('product.create.variant.general.validation.ean.required'),
            },
            {
              validator: async (_, value) => {
                if (eans.has(value)) {
                  return Promise.reject(
                    t('product.create.variant.general.validation.ean.duplicate')
                  )
                } else {
                  return Promise.resolve()
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={12}>
        <Form.Item<ProductVariantFormInstance>
          name="internalSku"
          label={t('product.create.variant.general.internalSku')}
          rules={[
            {
              validator: async (_, value) => {
                if (internalSkus.has(value)) {
                  return Promise.reject(
                    t('product.create.variant.general.validation.internalSku.duplicate')
                  )
                } else {
                  return Promise.resolve()
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  )
}

export const ProductVariantPackageDimensionsFormFields = () => {
  const { t, i18n } = useTranslation('inventory')

  const decimalSeparator = new Intl.NumberFormat(i18n.language).format(1.1)[1]
  const packageDimensionUpperLimit = 175

  return (
    <Row gutter={16}>
      <Col xs={12}>
        <Form.Item<ProductVariantFormInstance>
          name="packageLength"
          label={t('product.create.variant.packageDimensions.length')}
          rules={[{ required: true, type: 'number' }]}
          style={{ flex: 1 }}
        >
          <InputNumber
            addonAfter="cm"
            decimalSeparator={decimalSeparator}
            placeholder="0.00"
            min={0.1}
            max={packageDimensionUpperLimit}
            step={0.1}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      <Col xs={12}>
        <Form.Item<ProductVariantFormInstance>
          name="packageWidth"
          label={t('product.create.variant.packageDimensions.width')}
          rules={[{ required: true, type: 'number' }]}
          style={{ flex: 1 }}
        >
          <InputNumber
            addonAfter="cm"
            decimalSeparator={decimalSeparator}
            placeholder="0.00"
            min={0.1}
            max={packageDimensionUpperLimit}
            step={0.1}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      <Col xs={12}>
        <Form.Item<ProductVariantFormInstance>
          name="packageHeight"
          label={t('product.create.variant.packageDimensions.height')}
          rules={[{ required: true, type: 'number' }]}
          style={{ flex: 1 }}
        >
          <InputNumber
            addonAfter="cm"
            decimalSeparator={decimalSeparator}
            placeholder="0.00"
            min={0.1}
            max={packageDimensionUpperLimit}
            step={0.1}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      <Col xs={12}>
        <Form.Item<ProductVariantFormInstance>
          name="packageWeight"
          label={t('product.create.variant.packageDimensions.weight')}
          rules={[{ required: true, type: 'number' }]}
          style={{ flex: 1 }}
        >
          <InputNumber
            addonAfter="g"
            decimalSeparator={decimalSeparator}
            placeholder="0.00"
            min={1}
            step={0.1}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

const ProductVariantSalesChannelFormFields = () => {
  const { t } = useTranslation('inventory')
  const marketplaceOptions = useMarketplaceOptions()

  return (
    <Flex vertical>
      <Row gutter={16}>
        <Col xs={24}>
          <Divider orientation="left" orientationMargin={0}>
            Amazon
          </Divider>
        </Col>
        <Col xs={12}>
          <Form.Item<ProductVariantFormInstance>
            name={['salesChannels', 'amazon', 'asin']}
            label={t('product.create.variant.salesChannels.asin')}
            rules={[{ pattern: asinRegExp }]}
            validateDebounce={1000}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item<ProductVariantFormInstance>
            name={['salesChannels', 'amazon', 'labelType']}
            label={t('product.create.variant.salesChannels.labeling')}
            rules={[{ required: true }]}
          >
            <Select<string>
              options={Object.values(LabelType).map((type) => ({
                value: type,
                label: type,
              }))}
              placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item<ProductVariantFormInstance>
            name={['salesChannels', 'amazon', 'subscribeAndSave']}
            label={t('product.create.variant.salesChannels.subscribeAndSave')}
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={0}>{t('product.create.variant.salesChannels.radioButton.no')}</Radio>
              <Radio value={5}>{formatPercentage(0.05)}</Radio>
              <Radio value={10}>{formatPercentage(0.1)}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        {marketplaceOptions.map((marketplace) => (
          <Col xs={24} sm={12} key={marketplace}>
            <ProductVariantSalesChannelAmazonFormCard marketplace={marketplace} />
          </Col>
        ))}
      </Row>
    </Flex>
  )
}

export const ProductVariantSalesChannelAmazonFormCard = ({
  marketplace,
}: {
  marketplace: Marketplace
}) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const showRRP = selectedCompany.type !== CompanyType.WHOLESALER
  const shouldCreate: boolean | undefined = useWatch([
    'salesChannels',
    'amazon',
    'marketplaces',
    marketplace,
    'shouldCreate',
  ])

  return (
    <StyledSubCard
      title={
        <Space>
          <Flag code={marketplace} />
          <Typography.Text>{`Amazon.${getMarketplaceDomain(marketplace)}`}</Typography.Text>
        </Space>
      }
      extra={
        <Form.Item<ProductVariantFormInstance>
          name={['salesChannels', 'amazon', 'marketplaces', marketplace, 'shouldCreate']}
          valuePropName="checked"
          noStyle
        >
          <Checkbox />
        </Form.Item>
      }
      hideSeparator={!showRRP}
    >
      {showRRP && (
        <Form.Item<ProductVariantFormInstance>
          name={['salesChannels', 'amazon', 'marketplaces', marketplace, 'price']}
          noStyle
        >
          <InputNumber
            addonBefore={getMarketplaceCurrency(marketplace)}
            disabled={!shouldCreate}
            min={0}
            max={999999}
            placeholder="0.00"
            precision={2}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
    </StyledSubCard>
  )
}

const ProductComplianceFormCard = () => {
  const { t } = useTranslation(['inventory'])
  return (
    <StyledMainCard title={t('product.create.compliance.title')}>
      <Flex vertical gap={16}>
        <ProductComplianceCheckCountriesFormCard />
        <ProductComplianceImageFormCard />
      </Flex>
    </StyledMainCard>
  )
}

const ProductComplianceCheckCountriesFormCard = () => {
  const { t } = useTranslation(['inventory'])
  const countryOptions = useCountryOptionsForCompliance()
  const companyMarketplaces = useMarketplaceOptions()

  const countrySelectOptions = useMemo(() => {
    const companyMarketplaceOptions = []
    const otherCountryOptions = []

    for (const country of countryOptions) {
      const option = {
        value: country.code,
        label: country.name,
      }

      if (companyMarketplaces.includes(country.code as Marketplace)) {
        companyMarketplaceOptions.push(option)
      } else {
        otherCountryOptions.push(option)
      }
    }

    return [
      {
        label: 'Company Marketplaces',
        options: companyMarketplaceOptions,
      },
      {
        label: 'Other Countries',
        options: otherCountryOptions,
      },
    ]
  }, [companyMarketplaces, countryOptions])

  return (
    <StyledSubCard
      title={
        <Space>
          {t('product.create.compliance.countries.title')}
          <Popover
            arrow={{ pointAtCenter: true }}
            title={t('product.create.compliance.countries.popover.title')}
            content={t('product.create.compliance.countries.popover.description')}
            overlayStyle={{ maxWidth: 400 }}
          >
            <CircleHelp size={16} />
          </Popover>
        </Space>
      }
      style={{ height: 'auto' }}
    >
      <Flex gap={16} vertical>
        <Form.Item<ProductCreateFormInstance>
          name={'complianceCheckCountryCodes'}
          rules={[
            {
              required: true,
              message: t('product.create.compliance.countries.validation.required'),
            },
          ]}
        >
          <Select
            mode="multiple"
            optionLabelProp={'label'}
            options={countrySelectOptions}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
          />
        </Form.Item>
      </Flex>
    </StyledSubCard>
  )
}

const ProductComplianceImageFormCard = () => {
  const { t } = useTranslation('inventory')
  const { token } = theme.useToken()
  const themeValues = useTheme()

  const complianceImages: ProductComplianceImages | undefined = useWatch('complianceImages')

  const isCompletePackageImages =
    complianceImages?.package?.frontView &&
    complianceImages?.package?.backView &&
    complianceImages?.package?.topView &&
    complianceImages?.package?.bottomView &&
    complianceImages?.package?.leftView &&
    complianceImages?.package?.rightView

  const isCompleteProductImages =
    complianceImages?.product?.frontView &&
    complianceImages?.product?.backView &&
    complianceImages?.product?.topView &&
    complianceImages?.product?.bottomView &&
    complianceImages?.product?.leftView &&
    complianceImages?.product?.rightView &&
    complianceImages?.product?.insideView

  const isCompleteLabelImages =
    complianceImages?.label?.frontView && complianceImages?.label?.backView

  const isCompleteDocumentImages =
    complianceImages?.document?.frontView && complianceImages?.document?.backView

  const collapseItemStyle = useMemo(
    () => ({
      marginBottom: 16,
      borderRadius: token.borderRadiusLG,
      background: token.colorFillAlter,
    }),
    [token]
  )

  return (
    <StyledSubCard
      title={
        <Space>
          {t('product.create.compliance.images.title')}
          <Popover
            arrow={{ pointAtCenter: true }}
            title={t('product.create.compliance.images.why')}
            content={t('product.create.compliance.images.description')}
            overlayStyle={{ maxWidth: 400 }}
          >
            <CircleHelp size={16} />
          </Popover>
        </Space>
      }
      style={{ height: 'auto' }}
    >
      <Flex vertical gap={16}>
        <Alert
          type="warning"
          message={
            <Flex vertical gap={16}>
              <Typography.Text>{t('product.create.compliance.images.info1')}</Typography.Text>
              <Typography.Text>{t('product.create.compliance.images.info2')}</Typography.Text>
              <Typography.Text>{t('product.create.compliance.images.info3')}</Typography.Text>
            </Flex>
          }
        />
        <Collapse
          items={[
            {
              key: 'package',
              label: (
                <Badge
                  text={
                    <Typography.Text strong>
                      {t('product.create.compliance.images.package.title')}
                    </Typography.Text>
                  }
                  color={isCompletePackageImages ? 'green' : 'orange'}
                />
              ),
              children: <ProductComplianceImagePackageFormFields />,
              forceRender: true,
              style: collapseItemStyle,
            },
            {
              key: 'product',
              label: (
                <Badge
                  text={
                    <Typography.Text strong>
                      {t('product.create.compliance.images.product.title')}
                    </Typography.Text>
                  }
                  color={isCompleteProductImages ? 'green' : 'orange'}
                />
              ),
              children: <ProductComplianceImageProductFormFields />,
              forceRender: true,
              style: collapseItemStyle,
            },
            {
              key: 'label',
              label: (
                <Badge
                  text={
                    <Typography.Text strong>
                      {t('product.create.compliance.images.label.title')}
                    </Typography.Text>
                  }
                  color={isCompleteLabelImages ? 'green' : 'orange'}
                />
              ),
              children: <ProductComplianceImageLabelFormFields />,
              forceRender: true,
              style: collapseItemStyle,
            },
            {
              key: 'document',
              label: (
                <Badge
                  text={
                    <Typography.Text strong>
                      {t('product.create.compliance.images.document.title')}
                    </Typography.Text>
                  }
                  color={isCompleteDocumentImages ? 'green' : 'orange'}
                />
              ),
              children: <ProductComplianceImageDocumentFormFields />,
              forceRender: true,
              style: { ...collapseItemStyle, marginBottom: 0 },
            },
          ]}
          bordered={false}
          expandIconPosition="end"
          style={{ backgroundColor: themeValues.colors.base[5] }}
        />
      </Flex>
    </StyledSubCard>
  )
}

const ProductComplianceImagePackageFormFields = () => {
  const { t } = useTranslation('inventory')

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'package', 'frontView']}
            label={t('product.create.compliance.images.package.frontView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/package-front-view.jpeg"
          />
        </Col>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'package', 'backView']}
            label={t('product.create.compliance.images.package.backView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/package-back-view.jpeg"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'package', 'topView']}
            label={t('product.create.compliance.images.package.topView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/package-top-view.jpeg"
          />
        </Col>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'package', 'bottomView']}
            label={t('product.create.compliance.images.package.bottomView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/package-bottom-view.jpeg"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'package', 'leftView']}
            label={t('product.create.compliance.images.package.leftView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/package-left-view.jpeg"
          />
        </Col>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'package', 'rightView']}
            label={t('product.create.compliance.images.package.rightView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/package-right-view.jpeg"
          />
        </Col>
      </Row>
    </>
  )
}

const ProductComplianceImageProductFormFields = () => {
  const { t } = useTranslation('inventory')

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'product', 'frontView']}
            label={t('product.create.compliance.images.product.frontView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/product-front-view.jpeg"
          />
        </Col>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'product', 'backView']}
            label={t('product.create.compliance.images.product.backView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/product-back-view.jpeg"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'product', 'topView']}
            label={t('product.create.compliance.images.product.topView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/product-top-view.jpeg"
          />
        </Col>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'product', 'bottomView']}
            label={t('product.create.compliance.images.product.bottomView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/product-bottom-view.jpeg"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'product', 'leftView']}
            label={t('product.create.compliance.images.product.leftView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/product-left-view.jpeg"
          />
        </Col>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'product', 'rightView']}
            label={t('product.create.compliance.images.product.rightView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/product-right-view.jpeg"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'product', 'insideView']}
            label={t('product.create.compliance.images.product.insideView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/product-inside-view.jpeg"
          />
        </Col>
      </Row>
    </>
  )
}

const ProductComplianceImageLabelFormFields = () => {
  const { t } = useTranslation('inventory')

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'label', 'frontView']}
            label={t('product.create.compliance.images.label.frontView')}
          />
        </Col>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'label', 'backView']}
            label={t('product.create.compliance.images.label.backView')}
          />
        </Col>
      </Row>
    </>
  )
}

const ProductComplianceImageDocumentFormFields = () => {
  const { t } = useTranslation('inventory')

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'document', 'frontView']}
            label={t('product.create.compliance.images.document.frontView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/document-front-view.jpeg"
          />
        </Col>
        <Col span={12}>
          <ProductComplianceImageUploadFormItem
            name={['complianceImages', 'document', 'backView']}
            label={t('product.create.compliance.images.document.backView')}
            exampleImageSrc="https://sg-app-public.s3.eu-west-1.amazonaws.com/compliance/document-back-view.jpeg"
          />
        </Col>
      </Row>
    </>
  )
}

const ProductComplianceImageUploadFormItem = ({
  name,
  label,
  exampleImageSrc,
}: {
  name: NamePath<ProductCreateFormInstance>
  label: string
  exampleImageSrc?: string
}) => {
  const [visible, setVisible] = useState(false)

  const { t } = useTranslation('inventory')

  return (
    <Form.Item<ProductCreateFormInstance>
      name={name}
      label={
        exampleImageSrc ? (
          <Space>
            <Typography.Text>{label}</Typography.Text>
            <Typography.Link
              onClick={(event) => {
                event.preventDefault()
                setVisible(true)
              }}
            >
              {t('product.create.compliance.images.example')}
            </Typography.Link>
            <Image
              src={exampleImageSrc}
              preview={{ visible, onVisibleChange: setVisible }}
              style={{ display: 'none' }}
            />
          </Space>
        ) : (
          <Typography.Text>{label}</Typography.Text>
        )
      }
      valuePropName="file"
      getValueFromEvent={({ file }: { file: UploadFile }) => file}
      rules={[
        { required: true, message: t('product.create.compliance.images.validation.required') },
      ]}
    >
      <Upload
        accept=".jpg, .jpeg, .pdf"
        showUploadList={{ showPreviewIcon: false }}
        customRequest={({ onSuccess }) => {
          setTimeout(() => {
            if (onSuccess) {
              onSuccess('ok')
            }
          }, 0)
        }}
        maxCount={1}
      >
        <Button type="dashed" icon={<UploadIcon size={16} />}>
          {t('product.create.compliance.images.button.upload')}
        </Button>
      </Upload>
    </Form.Item>
  )
}
