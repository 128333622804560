import { CognitoUser } from '@aws-amplify/auth'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { Auth } from 'aws-amplify'
import { Lock, User } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router'
import { toast } from 'sonner'
import '../../App.css'
import { AuthStatus, useGlobalStore } from '../../stores/useGlobalStore'
type LoginFormInterface = {
  email: string
  password: string
}

export const LoginView = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const setAuthStatus = useGlobalStore((state) => state.setAuthStatus)
  const setCognitoUser = useGlobalStore((state) => state.setCognitoUser)

  const { t } = useTranslation('login', { keyPrefix: 'login' })
  const navigate = useNavigate()

  const handleSubmit = async (values: LoginFormInterface) => {
    setLoading(true)
    try {
      const sanitizedEmail = values.email.toLowerCase().trim()
      const cognitoUser: CognitoUser = await Auth.signIn(sanitizedEmail, values.password)
      setCognitoUser(cognitoUser)
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate('/login/new-password-required')
        return
      }
      setAuthStatus(AuthStatus.AUTHENTICATED)
      navigate('/')
    } catch (error) {
      console.error(error)
      toast.error(t('form.error.message'), { description: t('form.error.description') })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Row justify="center">
        <Col flex="0 1 480px">
          <Typography.Title level={3}>{t('heading')}</Typography.Title>
          <Form<LoginFormInterface> name="login" onFinish={handleSubmit} size="large">
            <Form.Item
              name="email"
              rules={[{ required: true, message: t('form.email.validation.required') }]}
            >
              <Input
                aria-label="email-input"
                autoFocus
                prefix={<User size={16} />}
                placeholder={t('form.email.placeholder')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: t('form.password.validation.required') }]}
            >
              <Input.Password
                aria-label="password-input"
                prefix={<Lock size={16} />}
                placeholder={t('form.password.placeholder')}
              />
            </Form.Item>
            <Form.Item>
              <Row gutter={[16, 16]} justify="space-between" align="middle">
                <Col>
                  <Link to="forgotten-password/step-1">{t('forgotPassword')}</Link>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    aria-label="submit-button"
                    loading={loading}
                  >
                    {t('form.submit')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}
