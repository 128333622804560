import { GetAllAmazonInboundShipmentDiscrepanciesQuery } from '../../../../generated/graphql'
import { AmazonInboundShipmentPlanDiscrepanciesTableRecord } from '../components/AmazonInboundShipmentPlanDiscrepanciesTable'

export function prepareAmazonInboundShipmentPlanDiscrepanciesData(
  data: GetAllAmazonInboundShipmentDiscrepanciesQuery
): AmazonInboundShipmentPlanDiscrepanciesTableRecord[] {
  return data.amazonInboundShipmentDiscrepancies.map((amazonInboundShipment) => {
    const skus = amazonInboundShipment.content.map((item) => {
      return item.amazonListing.sku
    })

    const totalUnitsExpected = amazonInboundShipment.content.reduce(
      (previousValue, currentValue) => {
        if (!currentValue.quantityShipped) {
          return previousValue
        }

        return previousValue + currentValue.quantityShipped
      },
      0
    )

    const totalUnitsLocated = amazonInboundShipment.content.reduce(
      (previousValue, currentValue) => {
        if (!currentValue.quantityReceived) {
          return previousValue
        }

        return previousValue + currentValue.quantityReceived
      },
      0
    )

    return {
      shipmentId: amazonInboundShipment.shipmentId,
      shipmentName: amazonInboundShipment.shipmentName,
      skus,
      totalUnitsExpected: totalUnitsExpected,
      totalUnitsLocated: totalUnitsLocated,
      shipmentStatus: amazonInboundShipment.shipmentStatus,
      proofOfDeliveryUrl: amazonInboundShipment.proofOfDeliveryBucketKey ?? null,
      createdAt: amazonInboundShipment.createdAt,
    }
  })
}
