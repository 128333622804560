import { TagProps } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import * as XLSX from 'xlsx'
import {
  GetCompanyPaymentsReportQuery,
  GetPaymentsQuery,
  Marketplace,
} from '../../../generated/graphql'
import { excelDateToFormattedDateString } from '../../../helpers/excelDateToFormattedDateString'
import {
  PaymentsQueryAdjustment,
  PaymentsQueryAmazonInboundShipment,
  PaymentsQueryAmazonLongTermStorageFee,
  PaymentsQueryAmazonPpcCosts,
  PaymentsQueryAmazonRemovalOrderItem,
  PaymentsQueryRefund,
  PaymentsQueryShipment,
  PaymentsQueryStorageFee,
} from './PaymentsView'
import { FinancialData } from './components/Payments'
import { PaymentsAdjustmentTableRecord } from './components/PaymentsAdjustmentTable'
import { PaymentsLongTermStorageFeeTableRecord } from './components/PaymentsLongTermStorageFeeTable'
import { PaymentsOverviewTableRecord } from './components/PaymentsOverviewTable'
import { PaymentsRefundTableRecord } from './components/PaymentsRefundTable'
import { PaymentsRemissionTableRecord } from './components/PaymentsRemissionTable'
import { PaymentsServiceFeeTableRecord } from './components/PaymentsServiceFeeTable'
import { PaymentsShipmentTableRecord } from './components/PaymentsShipmentTable'

type OverviewSheetRecord = {
  name: string
  asin: string
  sku: string
  qty: number
  shipments: number
  refunds: number
  adjustments: number
  remissions: number
  storageFees: number
  payout: number
  salesMargin: number
  remunerationAmount: number
  netPurchasePrice: number
  partialPayment: number
}

const getKeyValue = <T, K extends keyof T>(obj: T, key: K): T[K] => obj[key]

export const preparePaymentsOverviewTableData = (
  data: GetPaymentsQuery
): PaymentsOverviewTableRecord[] => {
  const flattenedProductData = flattenProductData(data.products)

  return flattenedProductData.map((product) => {
    const totalQuantity = getTotalQuantityBySku(product.sku, data)
    const totalShipments = getTotalShipmentsBySku(product.sku, data.shipments, 'profit')
    const totalRefunds = getTotalRefundsBySku(product.sku, data.refunds, 'profit')
    const totalAdjustments = getTotalAdjustmentsBySku(product.sku, data.adjustments, 'amount')
    const totalRemissions = getTotalAmazonRemovalOrdersBySku(
      product.sku,
      data.amazonRemovalOrderItems,
      'removalFee'
    )
    const totalStorageFees = getTotalStorageFeesByAsin(product.asin, data.storageFees)
    const totalLongTermStorageFees = getTotalLongTermStorageFeesBySku(
      product.sku,
      data.amazonLongTermStorageFees
    )
    const totalSalesMargin = getTotalShipmentsBySku(product.sku, data.shipments, 'sgSalesMargin')
    const totalRemunerationAmount = getTotalRemunerationAmountBySku(product.asin, product.sku, data)

    return {
      ...product,
      quantity: totalQuantity,
      shipments: totalShipments,
      refunds: totalRefunds,
      adjustments: totalAdjustments,
      remissions: totalRemissions,
      storageFees: totalStorageFees,
      longTermStorageFees: totalLongTermStorageFees,
      payout: totalShipments + totalRefunds + totalAdjustments + totalRemissions,
      salesMargin: totalSalesMargin,
      remunerationAmount: totalRemunerationAmount,
      partialPayment: totalQuantity * product.netPurchasePrice,
      retailPayout: totalQuantity * product.netPurchasePrice,
    }
  })
}

export function preparePaymentsShipmentRecords(
  shipments: PaymentsQueryShipment[]
): PaymentsShipmentTableRecord[] {
  return shipments.map((shipment) => {
    return {
      name: shipment.name,
      asin: shipment.asin,
      sku: shipment.sku,
      // TODO: Fix this in the backend.
      marketplace: (shipment.marketplace === 'OM' ? 'US' : shipment.marketplace) as Marketplace,
      date: shipment.date,
      quantity: shipment.qty,
      revenue: shipment.revenue,
      vat: shipment.revenueVat,
      shippingFees: shipment.shipmentCosts,
      amazonFees: shipment.expenses,
      salesMargin: shipment.sgSalesMargin,
      profit: shipment.profit,
      purchasePrice: shipment.netPurchasePrice,
      remunerationAmount: shipment.remunerationAmount,
    }
  })
}

export function preparePaymentsRefundRecords(
  refunds: PaymentsQueryRefund[]
): PaymentsRefundTableRecord[] {
  return refunds.map((refund) => {
    return {
      name: refund.name,
      asin: refund.asin,
      sku: refund.sku,
      // TODO: Fix this in the backend.
      marketplace: (refund.marketplace === 'OM' ? 'US' : refund.marketplace) as Marketplace,
      date: refund.date,
      quantity: refund.qty,
      revenue: refund.revenue,
      vat: refund.revenueVat,
      refundedFees: refund.otherRevenues,
      profit: refund.profit,
      purchasePrice: refund.netPurchasePrice,
      remunerationAmount: refund.remunerationAmount,
    }
  })
}

export function preparePaymentsAdjustmentRecords(
  adjustments: PaymentsQueryAdjustment[]
): PaymentsAdjustmentTableRecord[] {
  return adjustments.map((adjustment) => {
    return {
      name: adjustment.name,
      asin: adjustment.asin,
      sku: adjustment.sku,
      date: adjustment.date,
      quantity: adjustment.qty,
      amount: +adjustment.amount,
      type: adjustment.type,
      purchasePrice: adjustment.netPurchasePrice,
      remunerationAmount: adjustment.remunerationAmount,
    }
  })
}

export function preparePaymentsAmazonRemovalOrderRecords(
  amazonRemovalOrderItems: PaymentsQueryAmazonRemovalOrderItem[]
): PaymentsRemissionTableRecord[] {
  return amazonRemovalOrderItems.map((amazonRemovalOrderItem) => {
    return {
      name: amazonRemovalOrderItem.amazonProduct.productVariant.product.name,
      asin: amazonRemovalOrderItem.amazonProduct.asin,
      sku: amazonRemovalOrderItem.sku,
      date: amazonRemovalOrderItem.amazonRemovalOrder.amazonLastUpdatedDate,
      type: amazonRemovalOrderItem.amazonRemovalOrder.type,
      disposition: amazonRemovalOrderItem.disposition,
      removalFee: amazonRemovalOrderItem.removalFee.amount ?? 0,
      requestedQty: amazonRemovalOrderItem.requestedQuantity,
      shippedQty: amazonRemovalOrderItem.shippedQuantity,
      disposedQty: amazonRemovalOrderItem.disposedQuantity,
      cancelledQty: amazonRemovalOrderItem.cancelledQuantity,
    }
  })
}

export function preparePaymentsStorageFeeRecords(storageFees: PaymentsQueryStorageFee[]) {
  return storageFees.map((storageFee) => {
    return {
      name: storageFee.name,
      asin: storageFee.asin,
      country: storageFee.countryCode,
      fulfillmentCenter: storageFee.fulfillmentCenter,
      averageQuantity: storageFee.avgQty,
      fee: storageFee.fee,
    }
  })
}

export function preparePaymentsLongTermStorageFeeRecords(
  storageFees: PaymentsQueryAmazonLongTermStorageFee[]
) {
  return storageFees.map((longTermStorageFee) => {
    return {
      name: longTermStorageFee.amazonListing.title,
      asin: longTermStorageFee.amazonListing.amazonProduct.asin,
      sku: longTermStorageFee.amazonListing.sku,
      country: longTermStorageFee.amazonListing.marketplace.name,
      quantity: longTermStorageFee.quantity,
      fee: longTermStorageFee.fee,
      currency: longTermStorageFee.currency,
      surchargeAgeTier: longTermStorageFee.surchargeAgeTier,
      surchargeRate: longTermStorageFee.surchargeRate,
      perUnitVolume: longTermStorageFee.perUnitVolume,
      yearMonth: longTermStorageFee.yearMonth,
    }
  })
}

export function preparePaymentsShipmentPlanFeeRecords(
  amazonInboundShipments: PaymentsQueryAmazonInboundShipment[]
) {
  return amazonInboundShipments.map((amazonInboundShipment) => {
    return {
      shipmentName: amazonInboundShipment.shipmentName,
      shipmentId: amazonInboundShipment.shipmentId,
      date: amazonInboundShipment.createdAt,
      fee: {
        amount: amazonInboundShipment.deliveryCost ? amazonInboundShipment.deliveryCost * -1 : 0,
        currency: 'EUR',
      },
    }
  })
}

// TODO: Move the negation to the backend
export function preparePaymentsServiceFeeRecords(
  serviceFees: GetPaymentsQuery['invoiceItems']['invoiceItems']
): PaymentsServiceFeeTableRecord[] {
  return serviceFees.map((serviceFee) => ({
    name: serviceFee.title ?? null,
    quantity: serviceFee.amount,
    fee: serviceFee.unitCost * -1,
    comment: serviceFee.comment ?? null,
  }))
}

const flattenProductData = (products: GetPaymentsQuery['products']) => {
  return products.flatMap((product) => {
    if (!product.productVariants) {
      return []
    }

    return product.productVariants.flatMap((productVariant) => {
      if (!productVariant.amazonProducts) {
        return []
      }

      return productVariant.amazonProducts.filter(Boolean).flatMap((amazonProduct) => {
        if (!amazonProduct.amazonListings) {
          return []
        }

        const uniqueAmazonListings = amazonProduct.amazonListings.filter(
          (amazonListing, index, self) => {
            return index === self.findIndex(({ sku }) => sku === amazonListing.sku)
          }
        )

        return uniqueAmazonListings.map((amazonListing) => {
          return {
            name: product.name,
            asin: amazonProduct.asin,
            sku: amazonListing.sku,
            netPurchasePrice: productVariant.netPurchasePrice ?? 0,
          }
        })
      })
    })
  })
}

const getTotalQuantityBySku = (sku: string, data: GetPaymentsQuery): number => {
  const totalShipmentsQuantity = getTotalShipmentsBySku(sku, data.shipments, 'qty')
  const totalRefundsQuantity = getTotalRefundsBySku(sku, data.refunds, 'qty')
  const totalAdjustmentsQuantity = getTotalAdjustmentsBySku(sku, data.adjustments, 'qty')
  const totalShippedQuantity = getTotalAmazonRemovalOrdersBySku(
    sku,
    data.amazonRemovalOrderItems,
    'shippedQuantity'
  )
  const totalDisposedQuantity = getTotalAmazonRemovalOrdersBySku(
    sku,
    data.amazonRemovalOrderItems,
    'disposedQuantity'
  )

  return (
    totalShipmentsQuantity -
    totalRefundsQuantity +
    totalAdjustmentsQuantity +
    totalShippedQuantity +
    totalDisposedQuantity
  )
}

export const getTotalShipments = (shipments: PaymentsQueryShipment[]) => {
  return shipments.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.profit
  }, 0)
}

const getTotalShipmentsBySku = (
  sku: string,
  shipments: GetPaymentsQuery['shipments'],
  type: 'qty' | 'profit' | 'sgSalesMargin' | 'remunerationAmount'
) => {
  return shipments
    .filter((shipment) => shipment.sku === sku)
    .reduce((previousValue, currentValue) => {
      return previousValue + getKeyValue(currentValue, type)
    }, 0)
}

export const getTotalRefunds = (refunds: PaymentsQueryRefund[]) => {
  return refunds.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.profit
  }, 0)
}

const getTotalRefundsBySku = (
  sku: string,
  refunds: GetPaymentsQuery['refunds'],
  type: 'qty' | 'profit' | 'remunerationAmount'
) => {
  return refunds
    .filter((refund) => refund.sku === sku)
    .reduce((previousValue, currentValue) => {
      return previousValue + getKeyValue(currentValue, type)
    }, 0)
}

export const getTotalAdjustments = (adjustments: PaymentsQueryAdjustment[]) => {
  return adjustments.reduce((previousValue, currentValue) => {
    return previousValue + +currentValue.amount
  }, 0)
}

const getTotalAdjustmentsBySku = (
  sku: string,
  adjustments: PaymentsQueryAdjustment[],
  type: 'qty' | 'amount' | 'remunerationAmount'
) => {
  return adjustments
    .filter((adjustment) => adjustment.sku === sku)
    .reduce((previousValue, currentValue) => {
      return previousValue + +getKeyValue(currentValue, type)
    }, 0)
}

export const getTotalRemissions = (amazonRemovalOrders: PaymentsRemissionTableRecord[]) => {
  return amazonRemovalOrders.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.removalFee
  }, 0)
}

const getTotalAmazonRemovalOrdersBySku = (
  sku: string,
  amazonRemovalOrderItems: PaymentsQueryAmazonRemovalOrderItem[],
  type: 'removalFee' | 'shippedQuantity' | 'disposedQuantity'
) => {
  return amazonRemovalOrderItems
    .filter((amazonRemovalOrderItem) => amazonRemovalOrderItem.sku === sku)
    .reduce((previousValue, currentValue) => {
      if (type === 'removalFee') {
        return previousValue + (currentValue.removalFee.amount ?? 0)
      }
      return previousValue + getKeyValue(currentValue, type)
    }, 0)
}

// TODO: Move the negation to the backend
export const getTotalStorageFees = (storageFees: PaymentsQueryStorageFee[]) => {
  return storageFees.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.fee * -1
  }, 0)
}

const getTotalStorageFeesByAsin = (asin: string, storageFees: PaymentsQueryStorageFee[]) => {
  return storageFees
    .filter((storageFee) => storageFee.asin === asin)
    .reduce((previousValue, currentValue) => {
      return previousValue + currentValue.fee
    }, 0)
}

// TODO: Move the negation to the backend
export const getTotalLongTermStorageFees = (
  longTermStorageFees: PaymentsLongTermStorageFeeTableRecord[]
) => {
  return longTermStorageFees.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.fee * -1
  }, 0)
}

const getTotalLongTermStorageFeesBySku = (
  sku: string,
  longTermStorageFees: PaymentsQueryAmazonLongTermStorageFee[]
) => {
  return longTermStorageFees
    .filter((longTermStorageFee) => longTermStorageFee.amazonListing.sku === sku)
    .reduce((previousValue, currentValue) => {
      return previousValue + currentValue.fee
    }, 0)
}

export const getTotalShipmentPlanFees = (
  amazonInboundShipments: PaymentsQueryAmazonInboundShipment[]
) => {
  return amazonInboundShipments.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.deliveryCost * -1
  }, 0)
}

const getTotalRemunerationAmountBySku = (asin: string, sku: string, data: GetPaymentsQuery) => {
  const totalShipmentsRemunerationAmount = getTotalShipmentsBySku(
    sku,
    data.shipments,
    'remunerationAmount'
  )
  const totalRefundsRemunerationAmount = getTotalRefundsBySku(
    sku,
    data.refunds,
    'remunerationAmount'
  )
  const totalAdjustmentsRemunerationAmount = getTotalAdjustmentsBySku(
    sku,
    data.adjustments,
    'remunerationAmount'
  )
  const totalStorageFees = getTotalStorageFeesByAsin(asin, data.storageFees)
  const totalLongTermStorageFees = getTotalLongTermStorageFeesBySku(
    sku,
    data.amazonLongTermStorageFees
  )

  return (
    totalAdjustmentsRemunerationAmount +
    totalShipmentsRemunerationAmount +
    totalRefundsRemunerationAmount -
    totalStorageFees -
    totalLongTermStorageFees
  )
}

// TODO: Move the negation to the backend
export const getTotalAdvertisingFees = (advertisingFees: PaymentsQueryAmazonPpcCosts) => {
  return (
    advertisingFees.sponsoredProductsCosts * -1 +
    advertisingFees.sponsoredBrandsCosts * -1 +
    advertisingFees.sponsoredDisplayCosts * -1
  )
}

export const getTotalServiceFees = (serviceFees: PaymentsServiceFeeTableRecord[]) => {
  return serviceFees.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.fee
  }, 0)
}

export const getFullDateRange = (firstDate: Dayjs, secondDate: Dayjs): string[] => {
  const yearMonths: string[] = []

  if (dayjs(firstDate).isSame(dayjs(secondDate), 'month')) {
    yearMonths.push(dayjs(firstDate).format('YYYYMM'))
  } else {
    for (let i = 0; i < secondDate.diff(firstDate, 'month') + 1; ++i) {
      yearMonths.push(dayjs(secondDate).subtract(i, 'month').format('YYYYMM'))
    }
  }

  return yearMonths
}

export const prepareCurrentMonthData = (data: GetPaymentsQuery, financialData: FinancialData) => {
  const overview = preparePaymentsOverviewTableData(data)
  const longTermStorageFees = preparePaymentsLongTermStorageFeeRecords(
    data.amazonLongTermStorageFees
  )
  const serviceFees = preparePaymentsServiceFeeRecords(data.invoiceItems.invoiceItems)
  const remissions = preparePaymentsAmazonRemovalOrderRecords(data.amazonRemovalOrderItems)

  return mergeFinancialData(
    financialData,
    overview,
    data.shipments,
    data.refunds,
    data.adjustments,
    remissions,
    data.storageFees,
    longTermStorageFees,
    data.amazonInboundShipments,
    data.amazonPpcCosts,
    serviceFees
  )
}

export const preparePastMonthData = (
  data: GetCompanyPaymentsReportQuery,
  financialData: FinancialData
): FinancialData => {
  const workbook = XLSX.read(
    data.companyAmazonPaymentReport?.base64.replace(/_/g, '/').replace(/-/g, '+'),
    { type: 'base64' }
  )

  if (
    !workbook.Sheets['Overview'] ||
    !workbook.Sheets['Shipments'] ||
    !workbook.Sheets['Refunds'] ||
    !workbook.Sheets['Adjustments'] ||
    !workbook.Sheets['Remissions'] ||
    !workbook.Sheets['StorageFees']
  ) {
    return financialData
  }

  const overview = XLSX.utils.sheet_to_json<OverviewSheetRecord>(workbook.Sheets['Overview'])
  const shipments = XLSX.utils.sheet_to_json<PaymentsQueryShipment>(workbook.Sheets['Shipments'])
  const refunds = XLSX.utils.sheet_to_json<PaymentsQueryRefund>(workbook.Sheets['Refunds'])
  const adjustments = XLSX.utils.sheet_to_json<PaymentsQueryAdjustment>(
    workbook.Sheets['Adjustments']
  )
  const remissions = XLSX.utils.sheet_to_json<PaymentsRemissionTableRecord>(
    workbook.Sheets['Remissions']
  )
  const storageFees = XLSX.utils.sheet_to_json<PaymentsQueryStorageFee>(
    workbook.Sheets['StorageFees']
  )
  const longTermStorageFees = preparePaymentsLongTermStorageFeeRecords(
    data.amazonLongTermStorageFees
  )
  const serviceFees: PaymentsServiceFeeTableRecord[] = []

  const normalizedOverview: PaymentsOverviewTableRecord[] = overview.map((record) => ({
    name: record.name,
    asin: record.asin,
    sku: record.sku,
    quantity: record.qty,
    shipments: record.shipments,
    refunds: record.refunds,
    adjustments: record.adjustments,
    remissions: record.remissions,
    storageFees: record.storageFees,
    longTermStorageFees: getTotalLongTermStorageFeesBySku(
      record.sku,
      data.amazonLongTermStorageFees
    ),
    payout: record.payout,
    salesMargin: record.salesMargin,
    remunerationAmount:
      record.remunerationAmount -
      getTotalLongTermStorageFeesBySku(record.sku, data.amazonLongTermStorageFees),
    partialPayment: record.partialPayment,
    retailPayout: record.partialPayment,
  }))

  return mergeFinancialData(
    financialData,
    normalizedOverview,
    shipments,
    refunds,
    adjustments,
    remissions,
    storageFees,
    longTermStorageFees,
    data.amazonInboundShipments,
    data.amazonPpcCosts,
    serviceFees
  )
}

const mergeFinancialData = (
  financialData: FinancialData,
  overview: PaymentsOverviewTableRecord[],
  shipments: PaymentsQueryShipment[],
  refunds: PaymentsQueryRefund[],
  adjustments: PaymentsQueryAdjustment[],
  remissions: PaymentsRemissionTableRecord[],
  storageFees: PaymentsQueryStorageFee[],
  longTermStorageFees: PaymentsLongTermStorageFeeTableRecord[],
  amazonInboundShipments: PaymentsQueryAmazonInboundShipment[],
  advertisingFees: PaymentsQueryAmazonPpcCosts,
  serviceFees: PaymentsServiceFeeTableRecord[]
) => {
  const cleanedOverview = overview.reduce<PaymentsOverviewTableRecord[]>(
    (previousValue, currentValue) => {
      const existingRecord = financialData.overview.find(({ sku }) => currentValue.sku === sku)

      if (existingRecord) {
        currentValue = {
          ...currentValue,
          quantity: existingRecord.quantity + currentValue.quantity,
          shipments: existingRecord.shipments + currentValue.shipments,
          refunds: existingRecord.refunds + currentValue.refunds,
          adjustments: existingRecord.adjustments + currentValue.adjustments,
          remissions: existingRecord.remissions + currentValue.remissions,
          storageFees: existingRecord.storageFees + currentValue.storageFees,
          longTermStorageFees:
            existingRecord.longTermStorageFees + currentValue.longTermStorageFees,
          payout: existingRecord.payout + currentValue.payout,
          remunerationAmount: existingRecord.remunerationAmount + currentValue.remunerationAmount,
          partialPayment: existingRecord.partialPayment + currentValue.partialPayment,
        }
      }

      return [...previousValue, { ...currentValue }]
    },
    []
  )

  financialData.overview = financialData.overview.filter((record) => {
    return !cleanedOverview.filter(({ sku }) => record.sku === sku).length
  })

  financialData.overview = [...financialData.overview, ...cleanedOverview]
  financialData.shipments = [...financialData.shipments, ...shipments]
  financialData.refunds = [...financialData.refunds, ...refunds]
  financialData.adjustments = [...financialData.adjustments, ...adjustments]
  financialData.remissions = [...financialData.remissions, ...remissions]
  financialData.storageFees = [...financialData.storageFees, ...storageFees]
  financialData.longTermStorageFees = [...financialData.longTermStorageFees, ...longTermStorageFees]
  financialData.amazonInboundShipments = [
    ...financialData.amazonInboundShipments,
    ...amazonInboundShipments,
  ]
  financialData.advertisingFees = {
    sponsoredBrandsCosts:
      financialData.advertisingFees.sponsoredBrandsCosts + advertisingFees.sponsoredBrandsCosts,
    sponsoredDisplayCosts:
      financialData.advertisingFees.sponsoredDisplayCosts + advertisingFees.sponsoredDisplayCosts,
    sponsoredProductsCosts:
      financialData.advertisingFees.sponsoredProductsCosts + advertisingFees.sponsoredProductsCosts,
  }
  financialData.serviceFees = [...financialData.serviceFees, ...serviceFees]

  return financialData
}

export const exportPaymentsOverview = (
  overview: PaymentsOverviewTableRecord[],
  shipments: PaymentsQueryShipment[],
  refunds: PaymentsQueryRefund[],
  adjustments: PaymentsQueryAdjustment[],
  remissions: PaymentsRemissionTableRecord[],
  storageFees: PaymentsQueryStorageFee[],
  longTermStorageFees: PaymentsLongTermStorageFeeTableRecord[],
  shipmentPlanFees: PaymentsQueryAmazonInboundShipment[],
  serviceFees: PaymentsServiceFeeTableRecord[],
  title: string
) => {
  const data = {
    ['Overview']: overview.map((record) => ({
      ['Name']: record.name,
      ['ASIN']: record.asin,
      ['SKU']: record.sku,
      ['Quantity']: record.quantity,
      ['Shipments']: record.shipments,
      ['Refunds']: record.refunds,
      ['Adjustments']: record.adjustments,
      ['Remissions']: record.remissions,
      // TODO: Move the negation to the backend
      ['Storage Fees']: record.storageFees * -1,
      // TODO: Move the negation to the backend
      ['Long-term Storage Fees']: record.longTermStorageFees * -1,
      ['Total']: record.payout,
      ['Remuneration Amount']: record.remunerationAmount,
      ['Partial Payment']: record.partialPayment,
    })),
    ['Shipments']: preparePaymentsShipmentRecords(shipments).map((record) => ({
      ['Name']: record.name,
      ['ASIN']: record.asin,
      ['SKU']: record.sku,
      ['Marketplace']: record.marketplace,
      ['Date']: record.date,
      ['Quantity']: record.quantity,
      ['Revenue']: record.revenue,
      ['VAT']: record.vat,
      ['Shipping Fees']: record.shippingFees,
      ['Amazon Fees']: record.amazonFees,
      ['Sales Margin']: record.salesMargin,
      ['Profit']: record.profit,
      ['Purchase Price']: record.purchasePrice,
      ['Remuneration Amount']: record.remunerationAmount,
    })),
    ['Refunds']: preparePaymentsRefundRecords(refunds).map((record) => ({
      ['Name']: record.name,
      ['ASIN']: record.asin,
      ['SKU']: record.sku,
      ['Marketplace']: record.marketplace,
      ['Date']: record.date,
      ['Quantity']: record.quantity,
      ['Revenue']: record.revenue,
      ['VAT']: record.vat,
      ['Refunded Fees']: record.refundedFees,
      ['Profit']: record.profit,
      ['Purchase Price']: record.purchasePrice,
      ['Remuneration Amount']: record.remunerationAmount,
    })),
    ['Adjustments']: preparePaymentsAdjustmentRecords(adjustments).map((record) => ({
      ['Name']: record.name,
      ['ASIN']: record.asin,
      ['SKU']: record.sku,
      ['Date']: record.date,
      ['Quantity']: record.quantity,
      ['Amount']: record.amount,
      ['Type']: record.type,
      ['Purchase Price']: record.purchasePrice,
      ['Remuneration Amount']: record.remunerationAmount,
    })),
    ['Remissions']: remissions.map((record) => ({
      ['Name']: record.name,
      ['ASIN']: record.asin,
      ['SKU']: record.sku,
      ['Date']: excelDateToFormattedDateString(record.date),
      ['Type']: record.type,
      ['Disposition']: record.disposition,
      ['Fee']: record.removalFee,
      ['Requested Quantity']: record.requestedQty,
      ['Shipped Quantity']: record.shippedQty,
      ['Disposed Quantity']: record.disposedQty,
      ['Cancelled Quantity']: record.cancelledQty,
    })),
    ['Storage Fees']: preparePaymentsStorageFeeRecords(storageFees).map((record) => ({
      ['Name']: record.name,
      ['ASIN']: record.asin,
      ['Country']: record.country,
      ['Fulfillment Center']: record.fulfillmentCenter,
      ['Average Quantity']: record.averageQuantity,
      // TODO: Move the negation to the backend
      ['Fee']: record.fee * -1,
    })),
    ['Long-term Storage Fees']: longTermStorageFees.map((record) => ({
      ['Name']: record.name,
      ['ASIN']: record.asin,
      ['SKU']: record.sku,
      ['Country']: record.country,
      ['Quantity']: record.quantity,
      // TODO: Move the negation to the backend
      ['Fee']: record.fee * -1,
      ['Surcharge Age Tier']: record.surchargeAgeTier,
      ['Surcharge Rate']: +record.surchargeRate.toFixed(4),
      ['Per Unit Volume']: record.perUnitVolume,
    })),
    ['Shipment Plan Fees']: preparePaymentsShipmentPlanFeeRecords(shipmentPlanFees).map(
      (record) => ({
        ['Shipment Name']: record.shipmentName,
        ['Shipment ID']: record.shipmentId,
        ['Date']: record.date,
        ['Fee']: record.fee.amount,
      })
    ),
    ['Service Fees']: serviceFees.map((record) => ({
      ['Name']: record.name,
      ['Quantity']: record.quantity,
      ['Fee']: record.fee,
      ['Comment']: record.comment,
    })),
  }

  try {
    const workbook: XLSX.WorkBook = XLSX.utils.book_new()

    for (const [key, value] of Object.entries(data)) {
      const worksheet = XLSX.utils.json_to_sheet(value)
      XLSX.utils.book_append_sheet(workbook, worksheet, key)
    }

    XLSX.writeFile(workbook, title)
  } catch (error) {
    console.error(error)
  }
}

export function getTagColor(value: number): TagProps['color'] {
  if (value > 0) {
    return 'green'
  } else if (value < 0) {
    return 'red'
  } else {
    return 'default'
  }
}
