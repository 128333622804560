import dayjs from 'dayjs'
import { GetAllAmazonOrdersQuery } from '../../../../generated/graphql'
import { getSearchIdentifiers } from '../../../../helpers/getSearchIdentifiers'
import { AmazonOrderTableRecord } from '../components/AmazonOrderTable'

export function prepareAmazonOrderData(
  data: GetAllAmazonOrdersQuery | undefined
): AmazonOrderTableRecord[] {
  if (!data) {
    return []
  }

  const amazonOrders = data.products.flatMap((product) => {
    const amazonProducts =
      product.productVariants?.flatMap((productVariant) => {
        return productVariant.amazonProducts ?? []
      }) ?? []

    return amazonProducts?.filter(Boolean).flatMap((amazonProduct) => {
      if (!amazonProduct.amazonListings) {
        return []
      }

      const productVariant = product.productVariants?.find((variant) =>
        variant.amazonProducts?.some((ap) => ap.uuid === amazonProduct.uuid)
      )

      return amazonProduct.amazonListings?.filter(Boolean).flatMap((amazonListing) => {
        return amazonListing.orderLineItems.flatMap((item) => {
          const amazonOrder = item.amazonOrder

          return {
            name: product.name,
            asin: amazonProduct.asin,
            sku: amazonListing.sku,
            internalSku: productVariant?.internalSku || null,
            marketplace: amazonListing.marketplace.name,
            quantity: item.quantity,
            amount: +(item.itemPrice?.amount || 0),
            currency: item.itemPrice?.currency || 'EUR',
            status: amazonOrder.status,
            purchaseDate: amazonOrder.purchaseDate,
            searchIdentifiers: getSearchIdentifiers({ productVariant }),
          }
        })
      })
    })
  })

  return amazonOrders.sort((a, b) => dayjs(b.purchaseDate).diff(dayjs(a.purchaseDate)))
}
