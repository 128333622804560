import { Col, Descriptions, Divider, Flex, Row, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import { GetProductQuery } from '../../../generated/graphql'
import { StyledMainCard } from '../../dashboard/styles'
import { CreateProductVariantModal } from './CreateProductVariantModal'
import { EditProductBrandModal } from './EditProductBrandModal'
import { EditProductNameModal } from './EditProductNameModal'
import { EditProductSalesChannelCategoryModal } from './EditProductSalesChannelCategoryModal'
import { EditProductTaxCategoryModal } from './EditProductTaxCategoryModal'
import { ProductTags } from './ProductTags'
import { ProductVariantDescriptions } from './ProductVariantDescriptions/ProductVariantDescriptions'
import { TransferProductVariantModal } from './TransferProductVariantModal'
import { descriptionsStyle } from './styles'

type ProductDataCardProps = {
  product: GetProductQuery['product'] | undefined
  loading: boolean
}

export const ProductDataCard = ({ product, loading }: ProductDataCardProps) => {
  const { t } = useTranslation('inventory')

  const productVariant = product?.productVariants?.[0]

  return (
    <StyledMainCard title={t('product.details.product.title')}>
      <Skeleton loading={loading} active title={false} paragraph={{ rows: 3, width: '100%' }}>
        {!!product && (
          <>
            <Descriptions {...descriptionsStyle}>
              <Descriptions.Item label={t('product.details.product.name')}>
                <Row gutter={8} align="top" wrap={false}>
                  <Col flex="auto">{product.name}</Col>
                  <Col>
                    <EditProductNameModal uuid={product.uuid} name={product.name} />
                  </Col>
                </Row>
              </Descriptions.Item>
              <Descriptions.Item label={t('product.details.product.brand')}>
                <Row gutter={8} align="top" wrap={false}>
                  <Col flex="auto">{product.brand?.name}</Col>
                  <Col>
                    <EditProductBrandModal
                      uuid={product.uuid}
                      brand={product.brand?.name ?? null}
                    />
                  </Col>
                </Row>
              </Descriptions.Item>
              <Descriptions.Item label={t('product.details.product.manufacturer')}>
                {product.manufacturer ?? '-'}
              </Descriptions.Item>
              <Descriptions.Item label={t('product.details.product.taric')}>
                {product.taric?.[0]?.code ?? '-'}
              </Descriptions.Item>
              <Descriptions.Item label={t('product.details.product.tags')}>
                <ProductTags product={product} />
              </Descriptions.Item>
              <Descriptions.Item label={t('product.details.product.taxCategory')}>
                <Row gutter={8} align="top" wrap={false}>
                  <Col flex="auto">{product.taxCategory?.name ?? '-'}</Col>
                  <Col>
                    <EditProductTaxCategoryModal
                      uuid={product.uuid}
                      taxCategory={product.taxCategory?.name ?? null}
                    />
                  </Col>
                </Row>
              </Descriptions.Item>
              <Descriptions.Item label={t('product.details.product.salesChannelCategory')}>
                <Row gutter={8} align="top" wrap={false}>
                  <Col flex="auto">{product.salesChannelCategory?.name ?? '-'}</Col>
                  <Col>
                    <EditProductSalesChannelCategoryModal
                      productUuid={product.uuid}
                      salesChannelCategory={product.salesChannelCategory.value}
                    />
                  </Col>
                </Row>
              </Descriptions.Item>
            </Descriptions>
            {product.productVariants?.length === 1 && !!productVariant && (
              <>
                <Divider />
                <Flex gap={8} justify="flex-end" wrap style={{ marginBottom: 8 }}>
                  <TransferProductVariantModal productVariant={productVariant} />
                  <CreateProductVariantModal productUuid={product?.uuid} />
                </Flex>
                <ProductVariantDescriptions productVariant={productVariant} />
              </>
            )}
          </>
        )}
      </Skeleton>
    </StyledMainCard>
  )
}
