import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { ComplianceTable } from './components/ComplianceTable'

export const ComplianceListView = () => {
  const { t } = useTranslation('compliance')

  return (
    <>
      <SubHeader heading={t('heading')} beta />
      <ViewContainer>
        <ComplianceTable />
      </ViewContainer>
    </>
  )
}
