export enum TicketCustomFieldId {
  MAIN_CATEGORY = '5388356552733',
  TICKET_CATEGORY = '5384563984669',
  SELLER_ID = '5438242195101',
  PUBLIC = '5472136948125',
  LANGUAGE = '5795857841309',
  PERSONAL_NOTES = '6082567317533',
}

export enum LanguageOptions {
  ENGLISH = 'english',
  GERMAN = 'german',
}

export enum TicketMainCategory {
  ADMINISTRATION = 'administration',
  ADVERTISING = 'advertising',
  SHIPMENTS = 'shipments',
  LISTINGS = 'listings',
  INVENTORY = 'inventory',
  PROMOTIONS = 'promotions',
  BRAND_RIGHTS = 'brand_rights',
  COMPLIANCE = 'compliance',
  PRODUCT_DISPUTE = 'product_dispute',
  BILLING = 'billing',
  ACCOUNT = 'account',
  THIRD_PARTY_SERVICES = 'third_party_services',
  SPACEGOATS = 'spacegoats',
  AMAZON = 'amazon_general',
  SHOPIFY = 'shopify',
}

export enum TicketCategory {
  ADVERTISING_PPC_CREATE = 'advertising_ppc_create',
  ADVERTISING_PPC_EDIT = 'advertising_ppc_edit',
  ADVERTISING_PPC_PAUSE = 'advertising_ppc_pause',
  ADVERTISING_PPC_TRANSFER = 'advertising_ppc_transfer',
  ADVERTISING_PPC_GENERAL = 'advertising_ppc_general',
  SHIPMENTS_PACKAGE_DELIVERY_CREATE = 'shipments_package_delivery_create',
  SHIPMENTS_REIMBURSEMENT_STATUS = 'shipments_reimbursement_status',
  SHIPMENTS_FBA_INVENTORY_DATA_SUBMIT = 'shipments_fba_inventory_data_submit',
  SHIPMENTS_SHIPPING_ORDER_CREATE = 'shipments_shipping_order_create',
  SHIPMENTS_GENERAL = 'shipments_general',
  LISTINGS_PICTURE_UPDATE = 'listings_picture_update',
  LISTINGS_EBCA_CONTENT_CREATE = 'listings_ebca_content_create',
  LISTINGS_ERROR_REPORT = 'listings_error_report',
  LISTINGS_PRODUCT_INFO_EDIT = 'listings_product_info_edit',
  LISTINGS_PRICELOOP = 'listings_priceloop',
  LISTINGS_GENERAL = 'listings_general',
  INVENTORY_UNSELLABLE_STOCK_PREFERENCE_EDIT = 'inventory_unsellable_stock_preference_edit',
  INVENTORY_REQUEST_RETURN_ORDER = 'inventory_request_return_order',
  INVENTORY_CUSTOMER_REFUND_ORDER = 'inventory_customer_refund_order',
  INVENTORY_RETURN_ADDRESS_CREATE = 'inventory_return_address_create',
  INVENTORY_GENERAL = 'inventory_general',
  INVENTORY_ADD_NEW_PRODUCT = 'inventory_add_new_product',
  INVENTORY_DELETE_PRODUCT = 'inventory_delete_product',
  INVENTORY_ADD_NEW_LISTING = 'inventory_add_new_listing',
  INVENTORY_DELETE_LISTING = 'inventory_delete_listing',
  INVENTORY_OPTIMIZE_LISTING = 'inventory_optimize_listing',
  PROMOTIONS_COUPON_CREATE = 'promotions_coupon_create',
  PROMOTIONS_LIGHTNING_CREATE = 'promotions_lightning_create',
  PROMOTIONS_PRIME_DAY_CREATE = 'promotions_prime_day_create',
  PROMOTIONS_DISCOUNT_CREATE = 'promotions_discount_create',
  PROMOTIONS_GENERAL = 'promotions_general',
  BRAND_RIGHTS_BRAND_AUTHORISATION = 'brand_rights_brand_authorisation',
  BRAND_RIGHTS_BRAND_STORE_CREATE = 'brand_rights_brand_store_create',
  BRAND_RIGHTS_GENERAL = 'brand_rights_general',
  UK_COMPLIANCE_CHECK = 'uk_compliance_check',
  EU_COMPLIANCE_CHECK = 'eu_compliance_check',
  US_COMPLIANCE_CHECK = 'us_compliance_check',
  COMPLIANCE_GENERAL = 'compliance_general',
  PRODUCT_REVIEW_DISPUTE = 'product_review_dispute',
  AMAZON_SUPPORT = 'amazon_support',
  BILLING_GENERAL = 'billing_general',
  BILLING_PAYMENT = 'billing_payment',
  BILLING_TAX = 'billing_tax',
  BILLING_GALAXY = 'billing_galaxy',
  BILLING_PROCESS = 'billing_process',
  ACCOUNT_COMPANY_NAME_CHANGE = 'account_company_name_change',
  ACCOUNT_PROFILE_DATA_EDIT = 'account_profile_data_edit',
  ACCOUNT_ONBOARDING = 'account_onboarding',
  ACCOUNT_SAFETY_AND_COMPLIANCE = 'account_safety_and_compliance',
  ACCOUNT_GENERAL = 'account_general',
  THIRD_PARTY_SERVICES_BUG_REPORT = 'third_party_services_bug_report',
  THIRD_PARTY_SERVICES_EBOOK = 'third_party_services_ebook',
  THIRD_PARTY_SERVICES_GENERAL = 'third_party_services_general',
  SPACEGOATS_GENERAL = 'spacegoats_general',
  SHOPIFY_GENERAL = 'shopify_general',
  SHOPIFY_SHOP_SETUP = 'shopify_shop_setup',
  SHOPIFY_END_CUSTOMER = 'shopify_end_customer',
}
