import { Button, Form, FormInstance, Input, Row, Table } from 'antd'
import { Plus, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import { asinRegExp } from '../../../../../utils/regExp'
import {
  FormWidget,
  ListTitle,
  ScrollableList,
  SelectContainer,
  SelectedItemsContainer,
} from '../../../styles'

const { TextArea } = Input

type CreateSponsoredProductsNegativeProductTargetingFormFieldsProps = {
  form: FormInstance
}

const CreateSponsoredProductsNegativeProductTargetingFormFields = ({
  form,
}: CreateSponsoredProductsNegativeProductTargetingFormFieldsProps) => {
  const [textareaValue, setTextareaValue] = useState<string>('')
  const [asinInput, setAsinInput] = useState<string[]>([])
  const [negativeTargetAsins, setNegativeTargetAsins] = useState<string[]>([])

  useEffect(() => {
    form.setFieldsValue({ negativeProductTargetingAsins: negativeTargetAsins })
  }, [negativeTargetAsins, form])

  const handleRemoveNegativeTarget = (index: number) => {
    const negativeTargetsAsinsCopy = [...negativeTargetAsins]
    negativeTargetsAsinsCopy.splice(index, 1)
    setNegativeTargetAsins(negativeTargetsAsinsCopy)
  }

  const columns = [
    {
      title: 'ASIN',
      dataIndex: 'asin',
      key: 'asin',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '50px',
      render: (value: any, record: any, index: number) => (
        <Button
          type="text"
          icon={<X size={16} />}
          onClick={() => handleRemoveNegativeTarget(index)}
          data-testid="remove-negative-asin-btn"
        />
      ),
    },
  ]

  const handleAddTargets = () => {
    const newAsins: string[] = []
    asinInput.forEach((asin: string) => {
      const asinMatchedRegex = asin.match(asinRegExp)
      const asinAlreadyExists = negativeTargetAsins.includes(asin) || newAsins.includes(asin)
      if (!asinMatchedRegex) {
        toast.error(`Asin does not match expected pattern`, { description: asin })
      } else if (asinAlreadyExists) {
        toast.error(`Duplicate asin cannot be added`, { description: asin })
      } else {
        newAsins.push(asin)
      }
    })

    setNegativeTargetAsins([...negativeTargetAsins, ...newAsins])
    setTextareaValue('')
    setAsinInput([])
  }

  const handleTextareaOnChange = (e: any) => {
    const userInput = e.target.value
    setTextareaValue(userInput)
    if (userInput) {
      // split on new line and remove any empty lines
      const targets = userInput.split('\n').filter((asin: string) => asin?.trim())
      setAsinInput(targets)
    } else {
      setAsinInput([])
    }
  }

  return (
    <>
      <Form.Item name={'negativeProductTargetingAsins'} hidden>
        <Input></Input>
      </Form.Item>
      <FormWidget>
        <SelectContainer>
          <Row style={{ padding: '6px 12px' }}>
            <TextArea
              rows={15}
              placeholder="Enter ASINs of products to be excluded seperated by a new line"
              onChange={handleTextareaOnChange}
              value={textareaValue}
              data-testid="negative-asin-textarea"
            />
          </Row>
          <Row style={{ padding: '6px 12px' }} justify="end">
            <Button
              type="primary"
              onClick={handleAddTargets}
              icon={<Plus size={16} />}
              disabled={!asinInput.length}
              data-testid="add-negative-asin-btn"
            >
              Add ASINs
            </Button>
          </Row>
        </SelectContainer>
        <SelectedItemsContainer>
          <ListTitle>ASINs of products to exclude ({negativeTargetAsins.length})</ListTitle>
          <ScrollableList>
            <Table
              dataSource={negativeTargetAsins.map((asin) => {
                return { asin: asin }
              })}
              columns={columns}
              pagination={false}
              bordered={true}
              rowKey="asin"
              size="small"
            />
          </ScrollableList>
        </SelectedItemsContainer>
      </FormWidget>
    </>
  )
}

export default CreateSponsoredProductsNegativeProductTargetingFormFields
