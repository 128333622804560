import { TFunction } from 'i18next'

export const convertTicketMainCategory = (
  category: string,
  t: TFunction<['ticket'], 'listTicketView'>
) => {
  switch (category) {
    case 'amazon':
      return t('table.category.amazonSupport')
    case 'advertising':
      return t('table.category.advertising')
    case 'shipments':
      return t('table.category.shipments')
    case 'listings':
      return t('table.category.listings')
    case 'inventory':
      return t('table.category.inventory')
    case 'promotions':
      return t('table.category.promotions')
    case 'brand_rights':
      return t('table.category.brandRights')
    case 'compliance':
      return t('table.category.compliance')
    case 'product_dispute':
      return t('table.category.productDispute')
    case 'billing':
      return t('table.category.billing')
    case 'account':
      return t('table.category.account')
    case 'third_party_services':
      return t('table.category.thirdPartyServices')
    case 'spacegoats':
      return t('table.category.spacegoats')
    case 'shopify':
      return t('table.category.shopify')
    default:
      return ''
  }
}
