import { Divider, Flex } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { useMarketplaceOptions } from '../../../hooks/useMarketplaceOptions'
import { DashboardFinanceChartSummaryContainer } from './components/DashboardFinanceChartSummaryContainer'
import { DashboardFinanceHeader } from './components/DashboardFinanceHeader'
import { DashboardFinancePerformanceCardsContainer } from './components/DashboardFinancePerformanceCardsContainer'
import { DashboardFinanceProductTableContainer } from './components/DashboardFinanceProductTableContainer'

export const dashboardDateRanges = [1, 7, 30, 90]

const defaultDateRange: [Dayjs, Dayjs] = [
  dayjs().subtract(dashboardDateRanges[1]!, 'day').startOf('day'),
  dayjs().subtract(1, 'day').endOf('day'),
]

export const DashboardFinanceView = () => {
  const marketplaceOptions = useMarketplaceOptions()

  const [dateRange, setDateRange] = useState(defaultDateRange)
  const [selectedMarketplaces, setSelectedMarketplaces] = useState(marketplaceOptions)

  return (
    <Flex vertical gap={16}>
      <DashboardFinanceHeader
        filter={{
          dateRange,
          setDateRange,
          selectedMarketplaces,
          setSelectedMarketplaces,
        }}
      />
      <DashboardFinancePerformanceCardsContainer marketplaces={selectedMarketplaces} />
      <DashboardFinanceChartSummaryContainer
        marketplaces={selectedMarketplaces}
        dateRange={dateRange}
      />
      <Divider style={{ margin: 0 }} />
      <DashboardFinanceProductTableContainer
        marketplaces={selectedMarketplaces}
        dateRange={dateRange}
      />
    </Flex>
  )
}
