import styled from '@emotion/styled'
import { Tabs } from 'antd'

export const StyledTabs = styled(Tabs)`
  ${({ type, theme }) => {
    const baseStyles = `
      flex: 1;
    
      .ant-tabs-tab {
        margin-bottom: 0.5rem !important;
        border-radius: 0.25rem;
        transition: all 0.2s ease;
      }

      .ant-tabs-content-holder {
        display: flex;
      }

      [role='tabpanel'] {
        height: 100%;
      }
    `

    const lineStyles = `
      margin-top: -0.75rem;

      .ant-tabs-tab {
        padding: 0.5rem 0.75rem;
        &:hover {
          color: inherit;
          background-color: ${theme.colors.primary}12;
        }
      }
    `

    const cardStyles = `
      width: 100%;

      .ant-tabs-nav {
        margin-bottom: 0.5rem;

        &::before {
          border-bottom: none;
        }
      }


      .ant-tabs-nav-list {
        display: flex;
        gap: 0.5rem;

        .ant-tabs-tab {
          padding: 0.25rem 1rem;
          border-radius: 0.5rem !important;

          &.ant-tabs-tab-active {
            border: 1px solid ${theme.colors.base[6]};
          }
        }
      }
    `

    return `
      ${baseStyles}
      ${type === 'card' ? cardStyles : lineStyles}
    `
  }}
` as unknown as typeof Tabs
