import { Table, Tag } from 'antd'
import { useParams } from 'react-router'
import { AmazonPpcState, Marketplace } from '../../../../../generated/graphql'
import { getColorFromState } from '../../../../../views/ppc/helpers'
import { formatCurrency } from '../../../../../views/ppc/helpers/formatCurrency'
import { AggregatedProductAd } from '../../../../../views/ppc/helpers/interfaces'
import { getTableColumnFilterProps } from '../../../../../views/ppc/helpers/tableColumnFilterProps'
import { getTableColumnSorterProps } from '../../../../../views/ppc/helpers/tableColumnSorterProps'
import { useCurrencySymbol } from '../../../../../views/ppc/helpers/useCurrencySymbol'
import UpdateSponsoredProductsProductAdModal from '../UpdateSponsoredProductsProductAdModal/UpdateSponsoredProductsProductAdModal'

type SponsoredProductsProductAdsTableProps = {
  productAds: AggregatedProductAd[]
  onProductAdUpdated: Function
}

const SponsoredProductsProductAdsTable = ({
  productAds,
  onProductAdUpdated,
}: SponsoredProductsProductAdsTableProps) => {
  const params = useParams<{ marketplace: string; campaignid: string }>()

  const currencySymbol = useCurrencySymbol()

  const columns = [
    {
      title: 'Product SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      filters: [
        { text: 'Enabled', value: 'enabled' },
        { text: 'Paused', value: 'paused' },
      ],
      render: (state: AmazonPpcState) => {
        const stateString = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()
        return <Tag color={getColorFromState(state)}>{stateString}</Tag>
      },
      ...getTableColumnFilterProps('state'),
    },
    {
      title: 'ACoS',
      dataIndex: 'acos',
      key: 'acos',
      render: (acos: number) => {
        if (isFinite(acos)) {
          return acos + '%'
        } else {
          return '-'
        }
      },
      ...getTableColumnSorterProps('acos'),
    },
    {
      title: 'Cost per Click',
      dataIndex: 'costPerClick',
      key: 'costPerClick',
      render: (costPerClick: number) => formatCurrency(currencySymbol, costPerClick),
      ...getTableColumnSorterProps('costPerClick'),
    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      key: 'spend',
      render: (spend: number) => formatCurrency(currencySymbol, spend),
      ...getTableColumnSorterProps('spend'),
    },
    {
      title: 'Sales',
      dataIndex: 'sales',
      key: 'sales',
      render: (sales: number) => formatCurrency(currencySymbol, sales),
      ...getTableColumnSorterProps('sales'),
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      ...getTableColumnSorterProps('impressions'),
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      ...getTableColumnSorterProps('clicks'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, row: any) => {
        const bid = row.bid ? row.bid : 0
        return (
          <UpdateSponsoredProductsProductAdModal
            campaignId={params.campaignid!}
            adId={row.adId}
            stateIn={row.state}
            onProductAdUpdated={onProductAdUpdated}
            marketplace={params.marketplace?.toUpperCase()! as Marketplace}
          />
        )
      },
    },
  ]

  return (
    <Table
      bordered
      columns={columns}
      dataSource={productAds}
      pagination={{
        defaultPageSize: 20,
        pageSizeOptions: ['20', '50', '100', '500', '1000'],
        showSizeChanger: true,
      }}
      rowKey="adId"
      scroll={{ x: true }}
    />
  )
}

export default SponsoredProductsProductAdsTable
