import saveAs from 'file-saver'
import * as XLSX from 'xlsx'

export function exportCsvFile(data: any, fileName: string) {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const csvData = XLSX.utils.sheet_to_csv(worksheet)
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
  saveAs(blob, `${fileName}.csv`)
}

export function exportXlsxFile(data: any, fileName: string) {
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.json_to_sheet(data)

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, `${fileName}.xlsx`, { bookSST: true })
}
