import { Button, Divider, List, Popover, Select, Space, Switch, Typography } from 'antd'
import { Cog } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '../../../../../hooks/useBreakpoints'
import {
  DashboardConfigurationKey,
  useDashboardConfigurationStore,
} from '../../../../../stores/useDashboardConfigurationStore'

type DashboardConfigurationItem = {
  key: DashboardConfigurationKey
  active: boolean
  order: number
}

export const DashboardConfiguration = () => {
  const { dashboardConfiguration, setDashboardConfiguration } = useDashboardConfigurationStore()
  const { t } = useTranslation(['dashboard'])
  const breakpoints = useBreakpoints()

  const dataSource = Object.entries(dashboardConfiguration.cards).map(([key, value]) => ({
    key: key as DashboardConfigurationKey,
    ...value,
  }))

  const toggleCard = (item: DashboardConfigurationItem) => {
    setDashboardConfiguration({
      ...dashboardConfiguration,
      cards: {
        ...dashboardConfiguration.cards,
        [item.key]: {
          ...dashboardConfiguration.cards[item.key],
          active: !item.active,
        },
      },
    })
  }

  return (
    <Popover
      content={
        <Space direction="vertical" style={{ width: '100%', padding: '0 0.5rem' }}>
          <Typography.Text>
            {t('performance.configuration.productIdentifier.label')}
          </Typography.Text>
          <Select
            value={dashboardConfiguration.productIdentifier}
            options={[
              {
                label: t('performance.configuration.productIdentifier.options.productName'),
                value: 'productName',
              },
              {
                label: t('performance.configuration.productIdentifier.options.internalSku'),
                value: 'internalSku',
              },
              {
                label: t('performance.configuration.productIdentifier.options.amazonSku'),
                value: 'amazonSku',
              },
            ]}
            onChange={(value) =>
              setDashboardConfiguration({
                ...dashboardConfiguration,
                productIdentifier: value,
              })
            }
            style={{ width: '100%' }}
          />
          <Divider style={{ margin: '0.5rem 0' }} />
          <List<DashboardConfigurationItem>
            dataSource={dataSource}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Switch key="switch" checked={item.active} onChange={() => toggleCard(item)} />,
                ]}
                style={{ padding: '0.5rem 0' }}
              >
                {t(`performance.configuration.tiles.options.${item.key}`)}
              </List.Item>
            )}
            split={false}
          />
        </Space>
      }
      arrow={breakpoints.md ? false : true}
      placement="bottomRight"
      trigger="click"
    >
      <Button icon={<Cog size={16} />}>
        {breakpoints.md ? t('performance.configuration.button') : null}
      </Button>
    </Popover>
  )
}
