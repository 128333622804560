import {
  AmazonPpcCampaignType,
  AmazonPpcState,
  Marketplace,
  SponsoredBrandsKeywordMatchType,
  SponsoredProductsCampaignTargetingType,
  TargetingExpressionType,
  UpdateSponsoredProductsKeywordInput,
} from '../../../generated/graphql'

export enum FormStrategy {
  VALUE = 'value',
  INCR_PERCENTAGE = 'incrPercentage',
  DECR_PERCENTAGE = 'decrPercentage',
  INCR_AMOUNT = 'incrAmount',
  DECR_AMOUNT = 'decrAmount',
}

export type AggregatedCampaign = {
  campaignId: string
  name: string
  campaignType: AmazonPpcCampaignType
  targetingType?: SponsoredProductsCampaignTargetingType
  state: AmazonPpcState
  marketplace: Marketplace
  budget?: number
  dailyBudget?: number
  acos: number
  clicks: number
  impressions: number
  spend: number
  sales: number
  costPerClick: number
}

export type AggregatedAdGroup = {
  adGroupId: string
  name: string
  campaignId: string
  defaultBid: number | null
  state: AmazonPpcState
  marketplace: string
  acos: number
  clicks: number
  impressions: number
  spend: number
  sales: number
  costPerClick: number
}
export type AggregatedKeyword = {
  acos: number
  adGroupId: string
  bid?: number | null
  clicks: number
  impressions: number
  keywordId: string
  marketplace: string
  matchType: string
  keywordText: string
  sales: number
  spend: number
  state: AmazonPpcState
  costPerClick: number
}
export type AggregatedTarget = {
  name?: string | undefined | null
  acos: number
  adGroupId: string
  bid: number
  clicks: number
  expressionType: TargetingExpressionType
  impressions: number
  marketplace: string
  sales: number
  spend: number
  state: AmazonPpcState
  targetId: string
  expression: any[]
  resolvedExpression: any[]
  costPerClick: number
}

export type AggregatedProductAd = {
  adId: string
  campaignId: string
  adGroupId: string
  state: AmazonPpcState
  sku: string
  acos: number
  clicks: number
  impressions: number
  sales: number
  spend: number
  costPerClick: number
}

export type SponsoredBrandsAggregatedAdGroup = {
  adGroupId: string
  name: string
  campaignId: string
  marketplace: string
  acos: number
  clicks: number
  impressions: number
  spend: number
  sales: number
  costPerClick: number
}

export type SponsoredBrandsAggregatedKeyword = {
  acos: number
  adGroupId: string
  bid: number
  clicks: number
  impressions: number
  keywordId: string
  marketplace: string
  keywordText: string
  matchType: SponsoredBrandsKeywordMatchType
  sales: number
  spend: number
  state: string
  costPerClick: number
}

export type UpdateSponsoredProductsKeywordInputExtended = {
  keywordText?: string
  newBid?: number
} & UpdateSponsoredProductsKeywordInput

export type PPCBulkEditTargetBid = {
  newBid?: number
} & AggregatedTarget
