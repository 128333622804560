import { Button, Checkbox, Flex, Form, List, Modal, Space, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledMainCard } from '../../../dashboard/styles'
import { AmazonInboundShipmentPlanCreateFormInstance } from './AmazonInboundShipmentPlanCreateForm'

type AmazonInboundShipmentPlanToSModalProps = {
  submitting: boolean
}

export const AmazonInboundShipmentPlanToSModal = ({
  submitting,
}: AmazonInboundShipmentPlanToSModalProps) => {
  const [open, setOpen] = useState(false)
  const [checkbox, setCheckbox] = useState(false)

  const { t } = useTranslation('inventory')
  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  const handleSubmit = async () => form.submit()

  const terms = [
    t('amazonInboundShipmentPlan.form.termsAndConditions.object1'),
    t('amazonInboundShipmentPlan.form.termsAndConditions.object2'),
    t('amazonInboundShipmentPlan.form.termsAndConditions.object3'),
    t('amazonInboundShipmentPlan.form.termsAndConditions.object4'),
    t('amazonInboundShipmentPlan.form.termsAndConditions.object5'),
  ]

  return (
    <>
      <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
        <Button type="primary" onClick={() => setOpen(true)}>
          {t('shared.button.next', { ns: 'translation' })}
        </Button>
      </Form.Item>
      <Modal
        title={t('amazonInboundShipmentPlan.form.termsAndConditions.title')}
        open={open}
        width={800}
        onCancel={() => setOpen(false)}
        footer={
          <Space>
            <Button onClick={() => setOpen(false)}>
              {t('shared.button.back', { ns: 'translation' })}
            </Button>
            <Button type="primary" disabled={!checkbox} loading={submitting} onClick={handleSubmit}>
              {t('shared.button.submit', { ns: 'translation' })}
            </Button>
          </Space>
        }
      >
        <Flex vertical gap={16}>
          <StyledMainCard>
            <Typography.Paragraph strong>
              {t('amazonInboundShipmentPlan.form.termsAndConditions.subtitle')}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t('amazonInboundShipmentPlan.form.termsAndConditions.message')}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t('amazonInboundShipmentPlan.form.termsAndConditions.request')}
            </Typography.Paragraph>
            <List
              dataSource={terms}
              renderItem={(item) => {
                return <List.Item>• {item}</List.Item>
              }}
              size="small"
              split={false}
            />
          </StyledMainCard>
          <Checkbox onChange={(event) => setCheckbox(event.target.checked)}>
            <Typography.Text underline>
              {t('amazonInboundShipmentPlan.form.termsAndConditions.confirm')}
            </Typography.Text>
          </Checkbox>
        </Flex>
      </Modal>
    </>
  )
}
