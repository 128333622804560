import { Form, FormInstance, Input, Select } from 'antd'
import { useEffect, useState } from 'react'
import { GetAllAmazonListingsByCompanyAndMarketplaceQuery } from '../../../../../../generated/graphql'
import {
  FormWidget,
  ListTitle,
  RequiredFieldIndicator,
  ScrollableList,
  SearchBarContainer,
  SelectContainer,
  SelectedItemsContainer,
} from '../../../../styles'
import AmazonListingTile from './ProductAdAmazonListingTile'

type AmazonListing = GetAllAmazonListingsByCompanyAndMarketplaceQuery['amazonListings'][number]

type SelectSponsoredProductsProductAdsFormFieldsProps = {
  amazonListings: GetAllAmazonListingsByCompanyAndMarketplaceQuery['amazonListings']
  form: FormInstance
  handleSetSelectedAmazonListings: Function
}

type SelectableAmazonListing =
  GetAllAmazonListingsByCompanyAndMarketplaceQuery['amazonListings'][number] & {
    selected?: boolean
    hidden?: boolean
  }

const { Search } = Input

const SelectSponsoredProductsProductAdsFormFields = ({
  amazonListings,
  form,
  handleSetSelectedAmazonListings,
}: SelectSponsoredProductsProductAdsFormFieldsProps) => {
  const [listings, setListings] = useState<SelectableAmazonListing[]>(amazonListings)
  const [selectedListings, setSelectedListings] = useState<SelectableAmazonListing[]>([])
  const [unSelectedListings, setUnSelectedListings] = useState<SelectableAmazonListing[]>([])

  const handleAddProduct = (listing: AmazonListing) => {
    const listingsCopy = [...listings]
    const index = listings.map((data) => data.sku).indexOf(listing.sku)
    listings[index].selected = true
    setListings(listingsCopy)
  }

  const handleRemoveProduct = (listing: AmazonListing) => {
    const listingsCopy = [...listings]
    const index = listings.map((data) => data.sku).indexOf(listing.sku)
    listings[index].selected = false
    setListings(listingsCopy)
  }

  useEffect(() => {
    const selectedListings = listings.filter((listing) => listing.selected)
    setSelectedListings(selectedListings)
    const unSelectedListings = listings.filter((listing) => !listing.selected && !listing.hidden)
    setUnSelectedListings(unSelectedListings)
  }, [listings])

  useEffect(() => {
    handleSetSelectedAmazonListings(selectedListings)
    const selectedProductSkus = selectedListings.map((selectedListing) => selectedListing.sku)
    form.setFieldsValue({ productAdSkus: selectedProductSkus })
  }, [selectedListings, form, handleSetSelectedAmazonListings])

  const handleOnSearch = (value: string) => {
    const needle = value.toLowerCase()
    const listingsCopy = [...listings]
    listingsCopy.forEach((listing) => {
      const skuHaystack = listing.sku.toLowerCase()
      const titleHaystack = listing.title?.toLowerCase()
      listing.hidden = !skuHaystack.includes(needle) && !titleHaystack?.includes(needle)
    })
    setListings(listingsCopy)
  }

  const handleOnChange = (e: any) => {
    handleOnSearch(e.target.value)
  }

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleOnSearch(e.target.value)
    }
  }

  return (
    <>
      <Form.Item name="productAdSkus" hidden>
        <Select mode="multiple" />
      </Form.Item>
      <FormWidget>
        <SelectContainer>
          <SearchBarContainer>
            <Search
              placeholder="Search by SKU or name"
              onSearch={handleOnSearch}
              onKeyDown={handleKeyDown}
              allowClear
              onChange={handleOnChange}
              data-testid="sku-search-input"
            />
          </SearchBarContainer>
          <ScrollableList>
            {unSelectedListings.map((amazonListing) => (
              <AmazonListingTile
                key={amazonListing.sku}
                amazonListing={amazonListing}
                selected={false}
                handleOnClick={() => handleAddProduct(amazonListing)}
              />
            ))}
          </ScrollableList>
        </SelectContainer>
        <SelectedItemsContainer>
          <ListTitle>
            <RequiredFieldIndicator>*</RequiredFieldIndicator> Selected Products (
            {selectedListings.length})
          </ListTitle>
          <ScrollableList>
            {selectedListings.map((amazonListing) => (
              <AmazonListingTile
                key={amazonListing.sku}
                amazonListing={amazonListing}
                selected={true}
                handleOnClick={() => handleRemoveProduct(amazonListing)}
              />
            ))}
          </ScrollableList>
        </SelectedItemsContainer>
      </FormWidget>
    </>
  )
}

export default SelectSponsoredProductsProductAdsFormFields
