import styled from '@emotion/styled'
import { Card, CardProps, List, Modal, ModalProps, Skeleton, SkeletonProps } from 'antd'
import { ComponentType } from 'react'

type StyledSubCardProps = CardProps & {
  hideSeparator?: boolean
}

export const StyledMainCard = styled((props: CardProps) => (
  <Card
    {...props}
    style={{
      ...props.style,
    }}
    styles={{
      header: { padding: '0 1rem', zIndex: 1, ...props.styles?.header },
      body: { padding: '1rem', overflowY: 'auto', ...props.styles?.body },
    }}
  />
))`
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-card-head-wrapper {
    gap: 0.5rem;
  }

  .ant-card-head-title {
    font-size: 0.875rem;
  }

  .ant-card-actions {
    width: 100%;
  }
`

export const StyledSubCard = styled((props: StyledSubCardProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { hideSeparator, ...rest } = props

  return <Card {...rest} size="small" />
})`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.base[5]};

  ${(props) =>
    props.hideSeparator &&
    `
    .ant-card-head {
      border-bottom: none;
    }
    .ant-card-body {
      padding: 0;
    }
  `}
` as unknown as ComponentType<StyledSubCardProps>

export const StyledList = styled(List)`
  .ant-list-header {
    padding-top: 0;
  }

  .ant-list-items > li:first-of-type {
    padding-top: 0;
  }

  .ant-list-items > li:last-of-type {
    padding-bottom: 0;
  }

  .ant-list-item {
    padding: 0.5rem 0;
  }
` as unknown as typeof List

export const StyledModal = styled((props: ModalProps) => <Modal {...props} />)`
  .ant-modal-content {
    padding: 1rem;

    .ant-modal-title {
      padding-left: 0.75rem;
    }
  }
` as unknown as typeof Modal

export const StyledSkeleton = styled((props: SkeletonProps) => (
  <Skeleton {...props} active round />
))`
  .ant-skeleton-content {
    height: 5rem;
  }

  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }
`

export const StyledOrdersHeatMap = styled.div`
  min-height: 500px;
  border: 1px solid ${({ theme }) => theme.colors.base[6]};
  border-radius: 0.5rem;

  .mapboxgl-popup-content {
    border-radius: 0.5rem;
    padding: 1rem;
    font-size: 0.875rem;
    background-color: ${({ theme }) => theme.colors.base[5]};
    opacity: 0.95;
  }

  .mapboxgl-popup-tip {
    border-top-color: ${({ theme }) => theme.colors.base[5]};
  }
`
