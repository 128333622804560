import { useMutation, useQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import { Button, Flex } from 'antd'
import ButtonGroup from 'antd/es/button/button-group'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { LinkButton } from '../../../../components/LinkButton'
import {
  CompanyType,
  GetSalesChannelRegionDealDocument,
  GetWholesaleOrderDetailsDocument,
  UpdateWholesaleOrderDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { getWholesaleOrderStatus } from '../../helpers/getWholesaleOrderStatus'
import { WholesaleOrder } from '../../WholesaleOrderDetailsView'
import { WholesaleOrderTermsModal } from '../WholesaleOrderForm/WholesaleOrderTermsModal'
import { WholesaleOrderLineItemTableRecord } from './WholesaleOrderLineItemTable/WholesaleOrderLineItemTable'

type WholesaleOrderDetailsHeaderButtonsProps = {
  wholesaleOrder: WholesaleOrder
  lineItems: WholesaleOrderLineItemTableRecord[]
}

export const WholesaleOrderDetailsHeaderButtons = ({
  wholesaleOrder,
  lineItems,
}: WholesaleOrderDetailsHeaderButtonsProps) => {
  const user = useGlobalStore((state) => state.user)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const theme = useTheme()
  const { t } = useTranslation(['wholesale-order'], {
    keyPrefix: 'wholesaleOrderDetailsHeaderButtons',
  })
  const [loading, setLoading] = useState<boolean>(false)

  const isAdminUser = user?.isAdmin || false
  const isVendor = selectedCompany.type === CompanyType.WHOLESALER

  const { data: salesChannelRegionData } = useQuery(GetSalesChannelRegionDealDocument, {
    skip: !wholesaleOrder?.shippedToWarehouseLocation?.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
      warehouseServiceProviderLocationUuid: wholesaleOrder?.shippedToWarehouseLocation?.uuid || '',
    },
  })

  const [updateWholesaleOrder] = useMutation(UpdateWholesaleOrderDocument)

  const handleApproval = async () => {
    setLoading(true)
    const updatedAt = new Date().toISOString()

    let vendorApprovedAt = wholesaleOrder.vendorApprovedAt
    let retailerApprovedAt = wholesaleOrder.retailerApprovedAt

    if (isAdminUser) {
      if (!vendorApprovedAt && retailerApprovedAt) {
        vendorApprovedAt = updatedAt
      } else if (vendorApprovedAt && !retailerApprovedAt) {
        retailerApprovedAt = updatedAt
      }
    } else {
      vendorApprovedAt =
        selectedCompany.type === CompanyType.WHOLESALER
          ? updatedAt
          : wholesaleOrder.vendorApprovedAt
      retailerApprovedAt =
        selectedCompany.type === CompanyType.RETAILER
          ? updatedAt
          : wholesaleOrder.retailerApprovedAt
    }

    try {
      if (wholesaleOrder?.identifier) {
        await updateWholesaleOrder({
          variables: {
            identifier: wholesaleOrder.identifier,
            input: {
              vendorApprovedAt,
              retailerApprovedAt,
            },
          },
          optimisticResponse: {
            updateWholesaleOrder: {
              identifier: wholesaleOrder.identifier,
              updatedAt,
              vendorApprovedAt,
              retailerApprovedAt,
              lineItems: wholesaleOrder.lineItems,
            },
          },
          update: (cache, { data }) => {
            const updatedWholesaleOrder = data?.updateWholesaleOrder
            const cachedQuery = cache.readQuery({
              query: GetWholesaleOrderDetailsDocument,
              variables: {
                wholesaleOrderIdentifier: wholesaleOrder.identifier,
              },
            })

            if (updatedWholesaleOrder && cachedQuery) {
              cache.writeQuery({
                query: GetWholesaleOrderDetailsDocument,
                variables: {
                  wholesaleOrderIdentifier: wholesaleOrder.identifier,
                },
                data: {
                  wholesaleOrder: {
                    ...cachedQuery.wholesaleOrder,
                    updatedAt: updatedWholesaleOrder.updatedAt,
                    vendorApprovedAt: updatedWholesaleOrder.vendorApprovedAt,
                    retailerApprovedAt: updatedWholesaleOrder.retailerApprovedAt,
                  },
                },
              })
            }
          },
        })
      }
      toast.success(t('notification.statusChanged'))
    } catch (error) {
      console.error(error)
      toast.error(t('notification.statusChangeError'))
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return null
  }

  const wholeSaleOrderStatus = getWholesaleOrderStatus({
    vendorApprovedAt: wholesaleOrder.vendorApprovedAt,
    retailerApprovedAt: wholesaleOrder.retailerApprovedAt,
    isVendor,
  })

  switch (wholeSaleOrderStatus) {
    case 'waitingForApproval': {
      return (
        <Flex gap={8}>
          <WholesaleOrderTermsModal
            salesChannelRegionDeal={salesChannelRegionData?.salesChannelRegionDeal ?? null}
          />
          <ButtonGroup>
            <Button style={{ color: theme.colors.success }} onClick={() => handleApproval()}>
              {t('button.approve')}
            </Button>
          </ButtonGroup>
          <LinkButton
            type={'primary'}
            to={`/wholesale/orders/${wholesaleOrder.identifier}/edit`}
            state={{
              wholesaleOrder,
              lineItems,
            }}
          >
            {t('button.edit')}
          </LinkButton>
        </Flex>
      )
    }
    case 'waitingForOtherParty': {
      return (
        <Flex gap={8}>
          <WholesaleOrderTermsModal
            salesChannelRegionDeal={salesChannelRegionData?.salesChannelRegionDeal ?? null}
          />
          {isAdminUser && (
            <ButtonGroup>
              <Button style={{ color: theme.colors.success }} onClick={() => handleApproval()}>
                {t('button.approve')}
              </Button>
            </ButtonGroup>
          )}
          <LinkButton
            type={'primary'}
            to={`/wholesale/orders/${wholesaleOrder.identifier}/edit`}
            state={{
              wholesaleOrder,
              lineItems,
            }}
          >
            {t('button.edit')}
          </LinkButton>
        </Flex>
      )
    }
    default:
      return (
        <WholesaleOrderTermsModal
          salesChannelRegionDeal={salesChannelRegionData?.salesChannelRegionDeal ?? null}
        />
      )
  }
}
