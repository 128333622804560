import { Button, Space, Tag, Typography } from 'antd'
import { ArrowLeft } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router'
import { SubHeaderContainer, SubHeaderLeftContent, SubHeaderRightContent } from './SubHeader.style'

type SubHeaderProps = {
  heading?: string | null
  withBackButton?: boolean
  onBackUrl?: string
  rightContent?: React.ReactNode
  beta?: boolean
}

export const SubHeader = ({
  heading,
  withBackButton,
  onBackUrl,
  rightContent,
  beta,
}: SubHeaderProps) => {
  const navigate = useNavigate()

  const onBack = () => (onBackUrl ? navigate(onBackUrl) : navigate(-1))

  const backButtonMarkup = withBackButton ? (
    <Button
      type="text"
      icon={<ArrowLeft size={16} />}
      onClick={onBack}
      style={{ flex: '1 0 auto' }}
    />
  ) : null

  const headingMarkup = heading ? (
    <Space>
      <Typography.Text strong>{heading}</Typography.Text>
      {beta && (
        <Tag color="yellow" style={{ margin: 0 }}>
          Beta
        </Tag>
      )}
    </Space>
  ) : null

  const leftContentMarkup =
    heading || withBackButton ? (
      <SubHeaderLeftContent>
        {backButtonMarkup}
        {headingMarkup}
      </SubHeaderLeftContent>
    ) : null

  const rightContentMarkup = rightContent ? (
    <SubHeaderRightContent>{rightContent}</SubHeaderRightContent>
  ) : null

  return (
    <SubHeaderContainer>
      {leftContentMarkup}
      {rightContentMarkup}
    </SubHeaderContainer>
  )
}
