import { AmazonOutlined } from '@ant-design/icons'
import { useTheme } from '@emotion/react'
import { Flex, Tag, Typography } from 'antd'
import { useFeatureFlag } from 'configcat-react'
import {
  Boxes,
  CircleHelp,
  ClipboardList,
  Gauge,
  Package,
  ReceiptText,
  ScrollText,
  ShieldCheck,
  Tickets,
  Warehouse,
} from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router'
import SpacegoatsLogo from '../../../assets/icons/spacegoats-logo-minimal.svg?react'
import { CompanyType } from '../../../generated/graphql'
import { getUserRole } from '../../../helpers/getUserRole'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { StyledMainCard } from '../../../views/dashboard/styles'
import { CompanySelection } from '../../User/CompanySelection'
import { LogoContainer, StyledSidebarMenu } from './SidebarMenu.style'

export const SidebarMenu = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const user = useGlobalStore((state) => state.user)

  const { value: invoiceArticlesFeatureFlag } = useFeatureFlag('invoiceArticles', false, {
    identifier: user?.cognitoUsername ?? '',
    email: user?.email ?? '',
    custom: {},
  })

  const { i18n, t } = useTranslation()
  const location = useLocation()
  const theme = useTheme()

  const userRole = getUserRole(user, selectedCompany)
  const hasFullAccess = userRole === 'company:admin' || user?.isAdmin
  const companies = user?.companies ?? []
  const userHasMultipleCompanies = companies?.length && companies?.length > 1

  const faqLink =
    i18n.language === 'de'
      ? 'https://spacegoats.notion.site/wissensdatenbank'
      : 'https://spacegoats.notion.site/knowledge-base'

  const items = [
    {
      key: '/',
      icon: <Gauge size={16} />,
      label: <Link to=".">{t('sidebar.dashboard')}</Link>,
      disabled: !hasFullAccess,
      style: {
        display: shouldDisplayForCompanyTypes(
          selectedCompany.type,
          [CompanyType.SUPPLIER, CompanyType.WHOLESALER],
          'flex'
        ),
      },
    },
    {
      key: '/products',
      icon: <Package size={16} />,
      label: <Link to="products">{t('sidebar.products')}</Link>,
      style: {
        display: shouldDisplayForCompanyTypes(
          selectedCompany.type,
          [CompanyType.SUPPLIER, CompanyType.WHOLESALER, CompanyType.COMPLIANCE],
          'flex'
        ),
      },
    },
    {
      key: '/stock',
      icon: <Boxes size={16} />,
      label: <Link to="stock">{t('sidebar.stock')}</Link>,
      style: {
        display: shouldDisplayForCompanyTypes(
          selectedCompany.type,
          [CompanyType.SUPPLIER, CompanyType.WHOLESALER],
          'flex'
        ),
      },
    },
    {
      key: '/wholesale',
      icon: <Warehouse size={16} />,
      label: t('sidebar.wholesaleOrder'),
      children: [
        ...([CompanyType.WHOLESALER, CompanyType.RETAILER].includes(selectedCompany.type)
          ? [
              {
                key: '/wholesale/orders',
                label: <Link to="wholesale/orders">{t('sidebar.wholesaleOrders')}</Link>,
              },
            ]
          : []),
        ...([CompanyType.WHOLESALER, CompanyType.RETAILER].includes(selectedCompany.type)
          ? [
              {
                key: '/wholesale/billing-documents',
                label: (
                  <Link to="wholesale/billing-documents">
                    {t('sidebar.wholesaleOrderBillingDocuments')}
                  </Link>
                ),
              },
            ]
          : []),
      ],
      style: {
        display: shouldDisplayForCompanyTypes(selectedCompany.type, [
          CompanyType.WHOLESALER,
          CompanyType.RETAILER,
        ]),
      },
    },
    {
      key: '/amazon',
      icon: <AmazonOutlined />,
      label: t('sidebar.amazon'),
      children: [
        {
          key: '/amazon/products',
          label: <Link to="amazon/products">{t('sidebar.products')}</Link>,
        },
        {
          key: '/amazon/orders',
          label: <Link to="amazon/orders">{t('sidebar.orders')}</Link>,
          disabled: !hasFullAccess,
        },
        {
          key: '/amazon/returns',
          label: <Link to="amazon/returns">{t('sidebar.returns')}</Link>,
          disabled: !hasFullAccess,
        },
        {
          key: '/amazon/shipment-plans',
          label: <Link to="amazon/shipment-plans">{t('sidebar.shipmentPlans')}</Link>,
        },
        {
          key: '/amazon/ppc',
          label: <Link to="amazon/ppc">{t('sidebar.ppc')}</Link>,
          disabled: !hasFullAccess,
        },
      ],
      style: {
        display: shouldDisplayForCompanyTypes(selectedCompany.type, [
          CompanyType.SUPPLIER,
          CompanyType.WHOLESALER,
        ]),
      },
    },
    {
      key: '/compliance',
      icon: <ShieldCheck size={16} />,
      label: (
        <Link to="compliance">
          <Flex align="center" justify="space-between" gap={8}>
            {t('sidebar.compliance')}
            <Tag color="yellow" style={{ margin: 0 }}>
              Beta
            </Tag>
          </Flex>
        </Link>
      ),
      style: {
        display: shouldDisplayForCompanyTypes(
          selectedCompany.type,
          [
            CompanyType.SUPPLIER,
            CompanyType.WHOLESALER,
            CompanyType.RETAILER,
            CompanyType.COMPLIANCE,
          ],
          'flex'
        ),
      },
    },
    {
      key: '/reports',
      icon: <ClipboardList size={16} />,
      label: <Link to="reports">{t('sidebar.reports')}</Link>,
      disabled: !hasFullAccess,
      style: {
        display: shouldDisplayForCompanyTypes(
          selectedCompany.type,
          [CompanyType.SUPPLIER, CompanyType.WHOLESALER],
          'flex'
        ),
      },
    },
    {
      key: '/billing',
      icon: <ScrollText size={16} />,
      label: t('sidebar.billing'),
      disabled: !hasFullAccess,
      children: [
        {
          key: '/billing/payments',
          label: <Link to="billing/payments">{t('sidebar.payments')}</Link>,
        },
        {
          key: '/billing/documents',
          label: <Link to="billing/documents">{t('sidebar.documents')}</Link>,
        },
        {
          key: '/billing/bank-transactions',
          label: <Link to="billing/bank-transactions">{t('sidebar.bankTransactions')}</Link>,
        },
      ],
      style: {
        display: shouldDisplayForCompanyTypes(selectedCompany.type, [
          CompanyType.SUPPLIER,
          CompanyType.WHOLESALER,
        ]),
      },
    },
    {
      key: '/invoices',
      icon: <ReceiptText size={16} />,
      label: t('sidebar.serviceInvoices'),
      disabled: !hasFullAccess,
      children: [
        {
          key: '/invoices/view',
          label: (
            <Link to="invoices">
              <Flex align="center" justify="space-between" gap={8}>
                {t('sidebar.invoices')}
                <Tag color="yellow" style={{ margin: 0 }}>
                  Beta
                </Tag>
              </Flex>
            </Link>
          ),
        },
        {
          key: '/invoices/items',
          label: (
            <Link to="invoices/items">
              <Flex align="center" justify="space-between" gap={8}>
                {t('sidebar.invoiceLineItems')}
                <Tag color="yellow" style={{ margin: 0 }}>
                  Beta
                </Tag>
              </Flex>
            </Link>
          ),
        },
        {
          key: '/invoices/articles',
          label: (
            <Link to="invoices/articles">
              <Flex align="center" justify="space-between" gap={8}>
                {t('sidebar.invoiceArticles')}
                <Tag color="yellow" style={{ margin: 0 }}>
                  Beta
                </Tag>
              </Flex>
            </Link>
          ),
        },
        {
          key: '/invoices/subscriptions',
          label: (
            <Link to="invoices/subscriptions">
              <Flex align="center" justify="space-between" gap={8}>
                {t('sidebar.invoiceArticleSubscriptions')}
                <Tag color="yellow" style={{ margin: 0 }}>
                  Beta
                </Tag>
              </Flex>
            </Link>
          ),
        },
      ],
      style: {
        display: invoiceArticlesFeatureFlag
          ? shouldDisplayForCompanyTypes(selectedCompany.type, [CompanyType.RETAILER])
          : 'none',
      },
    },
    {
      key: '/tickets',
      icon: <Tickets size={16} />,
      label: <Link to="tickets">{t('sidebar.tickets')}</Link>,
      style: {
        display: shouldDisplayForCompanyTypes(
          selectedCompany.type,
          [CompanyType.SUPPLIER, CompanyType.WHOLESALER],
          'flex'
        ),
      },
    },
    {
      key: 'faq',
      icon: <CircleHelp size={16} />,
      label: (
        <Typography.Link
          href={faqLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'inherit' }}
        >
          FAQ
        </Typography.Link>
      ),
    },
  ]

  const { defaultSelectedKeys, defaultOpenKeys } = items.reduce<{
    defaultSelectedKeys: string[]
    defaultOpenKeys: string[]
  }>(
    (previousValue, currentValue) => {
      if (currentValue.children) {
        const matchingChild = currentValue.children.find((child) =>
          location.pathname.startsWith(child.key)
        )

        if (matchingChild) {
          previousValue.defaultSelectedKeys = [matchingChild.key]
          previousValue.defaultOpenKeys.push(currentValue.key)
        }
      } else if (location.pathname === currentValue.key) {
        previousValue.defaultSelectedKeys = [currentValue.key]
      }

      return previousValue
    },
    { defaultSelectedKeys: [], defaultOpenKeys: [] }
  )

  return (
    <>
      <LogoContainer>
        <SpacegoatsLogo width="40" height="40" color={theme.colors.primary} />
      </LogoContainer>
      <StyledMainCard
        styles={{ body: { padding: 8 } }}
        style={{ height: 'auto', margin: '8px 4px 4px' }}
      >
        <Flex vertical>
          {userHasMultipleCompanies ? (
            <CompanySelection companies={companies} />
          ) : (
            <Flex
              align="center"
              gap={8}
              title={`${selectedCompany.sellerId} - ${selectedCompany.name}`}
              style={{ height: '2rem', cursor: 'default' }}
            >
              <Typography.Text strong style={{ flexShrink: 0, fontFamily: 'monospace' }}>
                {selectedCompany.sellerId}
              </Typography.Text>
              <Typography.Text ellipsis style={{ flexGrow: 1, minWidth: 0 }}>
                {selectedCompany.name}
              </Typography.Text>
            </Flex>
          )}
        </Flex>
      </StyledMainCard>
      <StyledSidebarMenu
        mode="inline"
        items={items}
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        inlineIndent={16}
      />
    </>
  )
}

function shouldDisplayForCompanyTypes(
  companyType: CompanyType,
  allowedTypes: CompanyType[],
  displayStyle: 'block' | 'flex' = 'block'
): 'block' | 'flex' | 'none' {
  return allowedTypes.includes(companyType) ? displayStyle : 'none'
}
