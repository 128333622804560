import { AgGridReact } from 'ag-grid-react'
import { Button, Col, Flex, Form, Row } from 'antd'
import { SetStateAction, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'remeda'
import { WarehouseServiceProviderLocationFieldsFragment } from '../../../../generated/graphql'
import {
  AmazonInboundShipmentPlanCreateFormInstance,
  SelectedInventory,
} from './AmazonInboundShipmentPlanCreateForm'
import { AmazonInboundShipmentPlanStep } from './AmazonInboundShipmentPlanCreateFormCollapse'
import { AmazonInboundShipmentPlanImportModal } from './AmazonInboundShipmentPlanImportModal'
import {
  AmazonInboundShipmentPlanInventoryRecord,
  AmazonInboundShipmentPlanInventoryTable,
} from './AmazonInboundShipmentPlanInventoryTable'
import { AmazonShipmentPlanShipFromLocationFormField } from './AmazonShipmentPlanShipFromLocationFormField'

type AmazonInboundShipmentPlanInventorySectionProps = {
  locations: WarehouseServiceProviderLocationFieldsFragment[]
  setCurrentStep: (currentStep: SetStateAction<AmazonInboundShipmentPlanStep>) => void
}

export const AmazonInboundShipmentPlanInventorySection = ({
  locations,
  setCurrentStep,
}: AmazonInboundShipmentPlanInventorySectionProps) => {
  const { t } = useTranslation('inventory')
  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  const gridRef = useRef<AgGridReact<AmazonInboundShipmentPlanInventoryRecord> | null>(null)

  const handleNextClick = async () => {
    try {
      await form.validateFields(['shipFromLocationUuid'])
      setCurrentStep(AmazonInboundShipmentPlanStep.QUANTITY)
    } catch (error) {
      console.error(error)
    }
  }

  const handleImport = ({
    shipFromWarehouseLocationUuid,
    importedInventory,
    wholesaleOrderIdentifier,
  }: {
    shipFromWarehouseLocationUuid?: string
    importedInventory: SelectedInventory[]
    wholesaleOrderIdentifier?: string
  }) => {
    if (importedInventory.length === 0) {
      return
    }

    const cartons = importedInventory.map((item) => ({
      cartonType: item.uuid,
      numberOfCartons: item.numberOfPackagingUnits,
      cartonLength: item.cartonLength,
      cartonWidth: item.cartonWidth,
      cartonHeight: item.cartonHeight,
      cartonGrossWeight: item.cartonGrossWeight,
      items: [
        {
          amazonListingUuid: item.uuid,
          image: item.image,
          name: item.name,
          ean: item.ean,
          asin: item.asin,
          fnsku: item.fnsku,
          sku: item.sku,
          internalSku: item.internalSku,
          labelType: item.labelType,
          labeledByAmazon: false,
          bestBeforeDate: item.bestBeforeDate,
        },
      ],
    }))

    form.setFields([
      { name: 'shipFromLocationUuid', value: shipFromWarehouseLocationUuid },
      { name: 'selectedInventory', value: importedInventory },
      { name: 'cartons', value: cartons },
      {
        name: 'note',
        value: wholesaleOrderIdentifier || '',
      },
    ])

    const nodes = importedInventory.flatMap((item) => {
      return gridRef.current?.api.getRowNode(item.uuid) ?? []
    })

    gridRef.current?.api.deselectAll()
    gridRef.current?.api.setNodesSelected({ nodes, newValue: true })
  }

  return (
    <Flex vertical gap={8}>
      <Row gutter={16}>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
          <AmazonShipmentPlanShipFromLocationFormField locations={locations} />
        </Col>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
          <Flex justify="end">
            <AmazonInboundShipmentPlanImportModal onImport={handleImport} />
          </Flex>
        </Col>
      </Row>
      <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> style={{ marginBottom: 8 }}>
        <AmazonInboundShipmentPlanInventoryTable ref={gridRef} />
      </Form.Item>
      <Flex justify="end">
        <Form.Item<AmazonInboundShipmentPlanCreateFormInstance>
          shouldUpdate={(prevValues, nextValues) => {
            return !R.isDeepEqual(prevValues.selectedInventory, nextValues.selectedInventory)
          }}
          noStyle
        >
          {() => {
            const selectedInventory: AmazonInboundShipmentPlanCreateFormInstance['selectedInventory'] =
              form.getFieldValue('selectedInventory')

            return (
              <Button type="primary" disabled={!selectedInventory.length} onClick={handleNextClick}>
                {t('shared.button.next', { ns: 'translation' })}
              </Button>
            )
          }}
        </Form.Item>
      </Flex>
    </Flex>
  )
}
