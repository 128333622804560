import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { Typography } from 'antd'
import { Suspense, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { formatMoney } from '../../../../helpers/formatMoney'
import { PaymentsQueryStorageFee } from '../PaymentsView'
import { preparePaymentsStorageFeeRecords } from '../helpers'

type PaymentsStorageFeeTableRecord = {
  name: string
  asin: string
  country: string
  fulfillmentCenter: string
  averageQuantity: number
  fee: number
}

type PaymentsStorageFeeTableProps = {
  storageFees: PaymentsQueryStorageFee[]
}

export const PaymentsStorageFeeTable = ({ storageFees }: PaymentsStorageFeeTableProps) => {
  const rowData = preparePaymentsStorageFeeRecords(storageFees)

  const { t } = useTranslation(['billing'], { keyPrefix: 'payments.details.storageFees' })

  const [columnDefs] = useState<
    (ColDef<PaymentsStorageFeeTableRecord> | ColGroupDef<PaymentsStorageFeeTableRecord>)[]
  >([
    {
      field: 'name',
      headerName: t('table.name'),
    },
    {
      field: 'asin',
      headerName: t('table.asin'),
    },
    {
      field: 'country',
      headerName: t('table.country'),
      filter: 'agSetColumnFilter',
    },
    {
      field: 'fulfillmentCenter',
      headerName: t('table.fulfillmentCenter'),
      filter: 'agSetColumnFilter',
    },
    {
      field: 'averageQuantity',
      headerName: t('table.averageQuantity'),
    },
    {
      field: 'fee',
      headerName: t('table.fee'),
      initialSort: 'desc',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsStorageFeeTableRecord,
          PaymentsStorageFeeTableRecord['fee']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value ? params.value * -1 : 0, 'EUR')
          : t('shared.notAvailable')
      },
    },
  ])

  const defaultColDef: ColDef<PaymentsStorageFeeTableRecord> = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<PaymentsStorageFeeTableRecord>) => {
        if (params.value === null || params.value === undefined || params.value === '') {
          return t('shared.notAvailable', { ns: 'translation' })
        } else {
          return params.value
        }
      },
    }),
    []
  )

  return (
    <Suspense>
      {!rowData?.length ? (
        <Typography.Text type="secondary">{t('empty')}</Typography.Text>
      ) : (
        <Table<PaymentsStorageFeeTableRecord>
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          suppressContextMenu
        />
      )}
    </Suspense>
  )
}
