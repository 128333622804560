import { CarrierName } from '../../../../generated/graphql'

export const getTransportName = (deliveryService: string) => {
  switch (deliveryService) {
    case CarrierName.DHL_STANDARD:
      return 'DHL'

    case CarrierName.UNITED_PARCEL_SERVICE_INC:
      return 'UPS'

    default:
      return deliveryService
  }
}
