import { InternalAmazonListingStatus } from '../../../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { Tag } from 'antd'
import { getAmazonListingInternalStatusColor } from '../helpers/getAmazonListingInternalStatusColor'
import { getAmazonListingInternalStatusText } from '../helpers/getAmazonListingInternalStatusText'

type AmazonListingInternalStatusTagProps = {
  internalStatus: InternalAmazonListingStatus
}

export const AmazonListingInternalStatusTag = ({
  internalStatus,
}: AmazonListingInternalStatusTagProps) => {
  const { t } = useTranslation()
  const internalStatusColor = getAmazonListingInternalStatusColor(internalStatus)
  const internalStatusText = getAmazonListingInternalStatusText(internalStatus, t)

  if (internalStatusText && internalStatusColor) {
    return <Tag color={internalStatusColor}>{internalStatusText}</Tag>
  }

  return null
}
