import { Alert, Collapse, CollapseProps } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useTranslation } from 'react-i18next'
import { ShipmentType } from '../../../../generated/graphql'
import { AmazonInboundShipmentPlanCreateFormInstance } from './AmazonInboundShipmentPlanCreateForm'
import { MAX_ALLOWED_PACKAGE_WEIGHT_IN_KG } from './AmazonInboundShipmentPlanQuantityTable'

export const AmazonInboundShipmentPlanQuantitySectionFaq = () => {
  const { t } = useTranslation('inventory')

  const form = useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  const shippingMode: AmazonInboundShipmentPlanCreateFormInstance['shippingMode'] =
    form.getFieldValue('shippingMode')

  const items: CollapseProps['items'] = [
    ...(shippingMode !== ShipmentType.LTL
      ? [
          {
            key: 'total-package-weight',
            label: t('amazonInboundShipmentPlan.create.form.quantity.faq.packageWeight.question', {
              weight: MAX_ALLOWED_PACKAGE_WEIGHT_IN_KG,
            }),
            children: t('amazonInboundShipmentPlan.create.form.quantity.faq.packageWeight.answer', {
              weight: MAX_ALLOWED_PACKAGE_WEIGHT_IN_KG,
            }),
          },
        ]
      : []),
    {
      key: 'best-before-date',
      label: t('amazonInboundShipmentPlan.create.form.quantity.faq.bestBeforeDate.question'),
      children: t('amazonInboundShipmentPlan.create.form.quantity.faq.bestBeforeDate.answer'),
    },
  ]

  return <Alert type="warning" message={<Collapse items={items} ghost />} style={{ padding: 0 }} />
}
