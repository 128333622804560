import { useTheme } from '@emotion/react'
import { Alert, List, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { StyledListItem } from './styles'

export const ReportsView = () => {
  const { t } = useTranslation('report')
  const theme = useTheme()

  return (
    <>
      <SubHeader heading={t('reports.heading')} />
      <ViewContainer>
        <Alert message={t('reports.feedback')} showIcon />
        <List
          bordered
          style={{ width: '100%', backgroundColor: theme.colors.base[3], overflow: 'hidden' }}
          dataSource={[
            {
              value: t('reports.reports.orders.title'),
              description: t('reports.reports.orders.description'),
              path: 'orders',
            },
            {
              value: t('reports.reports.inventory.title'),
              description: t('reports.reports.inventory.description'),
              path: 'inventory',
            },
            {
              value: t('reports.reports.businessReports.title'),
              description: t('reports.reports.businessReports.description'),
              path: 'business',
            },
            {
              value: t('reports.reports.inventoryHealth.title'),
              description: t('reports.reports.inventoryHealth.description'),
              path: 'inventory-health',
            },
          ]}
          renderItem={(item) => {
            return (
              <StyledListItem>
                <Link to={item.path}>
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Typography.Text strong>{item.value}</Typography.Text>
                    <Typography.Text>{item.description}</Typography.Text>
                  </Space>
                </Link>
              </StyledListItem>
            )
          }}
        />
      </ViewContainer>
    </>
  )
}
