import { useMutation } from '@apollo/client'
import { Alert, Button, Form, Row, Switch, Typography } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { toast } from 'sonner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import QuillTextarea from '../../../components/Ticket/QuillTextarea'
import { UploadZendeskFile } from '../../../components/Ticket/UploadFile'
import { Marketplace } from '../../../generated/graphql'
import { SendSupportTicketDocument } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { DealsRequiredDynamicInput } from '../components/form/DealsRequiredDynamicInput'
import { PersonalNoteInput } from '../components/form/PersonalNoteInput'
import { createZendeskTicketOptions } from '../helpers/Zendesk.helper'
import { TicketCreationCard } from '../styles/TicketCreateViewStyles'
import { TicketCategory, TicketMainCategory } from '../ticket.enum'
import { AttachmentProps } from '../ticket.interface'

type FormInstance = {
  dealDetails: DealDetail[]
  messageInput: string
  confirmation_switch: boolean
  personalNotes: string
}

type DealDetail = {
  sku: string
  asin: string
  marketplaces: Marketplace[]
  includeChildVariants?: string
  originalPrice?: number
  discountPrice?: number
  week?: Dayjs
}

export const PrimeDealsTicketView = () => {
  const [confirmationSwitchValue, setConfirmationSwitchValue] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const location = useLocation()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()

  const { t, ready, i18n } = useTranslation(['ticket'], { keyPrefix: 'promotion.primeDeals' })
  const { t: generalTranslation } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [form] = Form.useForm()

  const [sendSupportTicketRequest, { loading }] = useMutation(SendSupportTicketDocument)

  if (!ready) {
    return <LoadingSpinner />
  }

  const initialValues: FormInstance = {
    confirmation_switch: false,
    messageInput: '',
    personalNotes: '',
    dealDetails: [
      {
        sku: '',
        asin: '',
        marketplaces: [],
      },
    ],
  }

  const onFinish = async (values: FormInstance) => {
    const tokens = attachments.map((attachment) => {
      return attachment.token
    })

    const createDealListSection = (issues: DealDetail[]) => {
      if (issues.length === 0) {
        return []
      }

      return issues.map((issue) => {
        return `
          <li>
            <p><b>SKU:</b> ${issue.sku}</p>
            <p><b>ASIN:</b> ${issue.asin}</p>
            <p><b>${t('form.marketplacesInput.label')}</b> ${issue.marketplaces
              .toString()
              .replaceAll(',', ', ')}</p>
            <p><b>${t('form.originalPriceInput.label')}</b> ${issue.originalPrice}</p>
            <p><b>${t('form.discountInput.label')}</b> ${issue.discountPrice}</p>
            <p><b>${t('form.weekInput.label')}</b> ${dayjs(issue.week)
              .locale(i18n.language)
              .startOf('week')
              .format('ddd, MMM DD')} - ${dayjs(issue.week)
              .locale(i18n.language)
              .endOf('week')
              .format('ddd, MMM DD')}</p>
            <p><b>${t('form.childSelector.label')}</b> ${issue.includeChildVariants}</p>
            &nbsp;
          </li>
        `
      })
    }

    const createCommentSection = (messageInput: string | undefined) => {
      if (messageInput) {
        return `
          <div>
            <h3>${t('form.comments.label')}</h3>
            <p>${messageInput}</p>
          </div>
        `
      } else {
        return ''
      }
    }

    const message = `
      <h1>${t('ticketDescriptionTitle')}</h1>
      <ul style={{listStyleType: 'none'}}>${createDealListSection(values.dealDetails).join('')}</ul>
      ${createCommentSection(values.messageInput)}
    `

    const options = createZendeskTicketOptions({
      categoryTitle: location.state.categoryTitleTag,
      comment: message,
      language: i18n.language,
      mainCategory: TicketMainCategory.PROMOTIONS,
      subject: t('viewTitle'),
      ticketCategory: TicketCategory.PROMOTIONS_PRIME_DAY_CREATE,
      sellerId: selectedCompany?.sellerId,
      personalNotes: values.personalNotes,
      attachments: tokens,
    })

    try {
      if (selectedCompany !== null && selectedCompany.uuid) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })
        toast.success(generalTranslation('notification.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      console.error(error)
      toast.error(generalTranslation('notification.error'), { description: error.message })
    }
  }

  const renderNoteComponent = () => {
    return (
      <ul>
        <li>{t('notes.list.eligibility.description')}</li>
        <li>{t('notes.list.eligibility.dealTypes')}</li>
        <li>{t('notes.list.price.flash')}</li>
        <li>{t('notes.list.price.sevenDay')}</li>
        <li>{t('notes.list.price.minItems')}</li>
      </ul>
    )
  }

  return (
    <>
      <SubHeader withBackButton />
      <ViewContainer centered>
        <TicketCreationCard title={t('ticketDescriptionTitle')}>
          <Alert
            type="info"
            message={t('notes.title')}
            description={renderNoteComponent()}
            style={{ marginBottom: '1rem' }}
          />
          <Typography.Paragraph>{t('ticketDescriptionSubtitle')}</Typography.Paragraph>
          <Form
            name="prime-deals-ticket"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            layout="vertical"
          >
            <DealsRequiredDynamicInput
              translation={useTranslation(['ticket'], { keyPrefix: 'promotion.primeDeals.form' })}
            />
            <Form.Item name="messageInput" label={t('form.comments.label')}>
              <QuillTextarea placeholder={t('form.comments.placeholder')} />
            </Form.Item>
            <PersonalNoteInput />
            <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
            <Form.Item
              name="confirmation_switch"
              label={t('confirmationText')}
              valuePropName="checked"
            >
              <Switch onChange={setConfirmationSwitchValue} />
            </Form.Item>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!confirmationSwitchValue}
                loading={loading}
              >
                {t('sendButtonText')}
              </Button>
            </Row>
          </Form>
        </TicketCreationCard>
      </ViewContainer>
    </>
  )
}
