import styled from '@emotion/styled'

export const HTMLContentDiv = styled.div`
  img,
  svg {
    max-width: 100%;
  }
  a {
    text-decoration: underline;
    :hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`
