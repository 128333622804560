import { getNames } from 'i18n-iso-countries'
import i18n from '../config/i18n'
import { useGlobalStore } from '../stores/useGlobalStore'
import { CompanyType } from '../generated/graphql'

export function useCountryOptions(): { code: string; name: string }[] {
  const countries = getNames(i18n.language)

  return Object.entries(countries).map(([code, name]) => ({ code, name }))
}

const PAN_EU_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]

const ALLOWED_COUNTRIES = ['US', 'GB', 'CA', ...PAN_EU_COUNTRIES]

export function useCountryOptionsForCompliance(): { code: string; name: string }[] {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const countryOptions = useCountryOptions()

  const processedOptions = countryOptions.map((country) => ({
    code: country.code === 'GB' ? 'UK' : country.code,
    name: country.name,
  }))

  if (selectedCompany.type !== CompanyType.COMPLIANCE) {
    return processedOptions
  }

  return processedOptions.filter((country) =>
    ALLOWED_COUNTRIES.includes(country.code === 'UK' ? 'GB' : country.code)
  )
}
