import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { Typography } from 'antd'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components/FormattedDate'
import { Table } from '../../../../components/Table/Table'
import { AmazonInboundShipmentPlan } from '../AmazonInboundShipmentPlanView'

type AmazonInboundShipmentPlanContentInCasesTableRecord = {
  shipmentId: string | null
  sku: string
  unitsPerCarton: number
  numberOfCartons: number
  unitsTotal: number
  quantityShipped: number
  quantityReceived: number
  labeledByAmazon: boolean
  bestBeforeDate: string | null
  length: number
  width: number
  height: number
  grossWeight: number
  netWeight: number | null
}

type AmazonInboundShipmentPlanContentTableProps = {
  amazonInboundShipmentPlan: AmazonInboundShipmentPlan
}

export const AmazonInboundShipmentPlanContentInCasesTable = ({
  amazonInboundShipmentPlan,
}: AmazonInboundShipmentPlanContentTableProps) => {
  const { t } = useTranslation('inventory')

  const rowData = amazonInboundShipmentPlan.cartons.flatMap((carton) => {
    const item = carton.items[0]

    if (!item) {
      return []
    }

    return {
      shipmentId: item.shipmentId ?? null,
      sku: item.amazonListing.sku,
      unitsPerCarton: item.unitsPerCarton,
      numberOfCartons: carton.numberOfCartons,
      labeledByAmazon: item.labeledByAmazon,
      quantityShipped: item.quantityShipped,
      quantityReceived: item.quantityReceived ?? 0,
      bestBeforeDate: item.bestBeforeDate ?? null,
      unitsTotal: carton.unitsTotal,
      length: carton.length,
      width: carton.width,
      height: carton.height,
      grossWeight: carton.grossWeight,
      netWeight: carton.netWeight ?? null,
    }
  })

  const [columnDefs] = useState<
    (
      | ColDef<AmazonInboundShipmentPlanContentInCasesTableRecord>
      | ColGroupDef<AmazonInboundShipmentPlanContentInCasesTableRecord>
    )[]
  >([
    {
      field: 'shipmentId',
      headerName: 'Shipment ID',
    },
    {
      field: 'sku',
      headerName: 'SKU',
      initialSort: 'asc',
    },
    {
      field: 'unitsPerCarton',
      headerName: t('amazonInboundShipmentPlan.carton.table.unitsPerCarton'),
    },
    {
      field: 'numberOfCartons',
      headerName: t('amazonInboundShipmentPlan.carton.table.noOfCartons'),
    },
    {
      field: 'unitsTotal',
      headerName: t('amazonInboundShipmentPlan.carton.table.unitsTotal'),
    },
    {
      field: 'quantityShipped',
      headerName: t('amazonInboundShipmentPlan.carton.table.quantityShipped'),
    },
    {
      field: 'quantityReceived',
      headerName: t('amazonInboundShipmentPlan.carton.table.quantityReceived'),
    },
    {
      field: 'labeledByAmazon',
      headerName: t('amazonInboundShipmentPlan.carton.table.labeledByAmazon'),
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanContentInCasesTableRecord,
          AmazonInboundShipmentPlanContentInCasesTableRecord['labeledByAmazon']
        >
      ) => {
        return (
          <Typography.Text>
            {params.value
              ? t('amazonInboundShipmentPlan.table.dropShipment.yes')
              : t('amazonInboundShipmentPlan.table.dropShipment.no')}
          </Typography.Text>
        )
      },
    },
    {
      field: 'bestBeforeDate',
      headerName: t('amazonInboundShipmentPlan.form.bestBeforeDate'),
      minWidth: 110,
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanContentInCasesTableRecord,
          AmazonInboundShipmentPlanContentInCasesTableRecord['bestBeforeDate']
        >
      ) => {
        return <FormattedDate date={params.value} format="DD.MM.YYYY" layout="horizontal" />
      },
    },
    {
      field: 'length',
      headerName: t('amazonInboundShipmentPlan.carton.table.lengthCarton'),
      hide: true,
    },
    {
      field: 'width',
      headerName: t('amazonInboundShipmentPlan.carton.table.widthCarton'),
      hide: true,
    },
    {
      field: 'height',
      headerName: t('amazonInboundShipmentPlan.carton.table.heightCarton'),
      hide: true,
    },
    {
      field: 'grossWeight',
      headerName: t('amazonInboundShipmentPlan.carton.table.grossWeight'),
      hide: true,
    },
    {
      field: 'netWeight',
      headerName: t('amazonInboundShipmentPlan.carton.table.netWeight'),
      hide: true,
    },
  ])

  const defaultColDef: ColDef<AmazonInboundShipmentPlanContentInCasesTableRecord> = useMemo(
    () => ({ filter: false }),
    []
  )

  return (
    <Table<AmazonInboundShipmentPlanContentInCasesTableRecord>
      fullHeight
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      rowData={rowData}
      showColumnChooser
      showFilterReset
      showUniversalSearch
    />
  )
}
