import { Button, Col, Form, Input, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { i18n, TFunction } from 'i18next'
import { Plus, Trash } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type RequestToAmazonDynamicInputProps = {
  translation?: [TFunction, i18n, boolean] & { t: TFunction; i18n: i18n; ready: boolean }
}

export const RequestToAmazonDynamicInput = ({ translation }: RequestToAmazonDynamicInputProps) => {
  const { t } =
    translation ??
    useTranslation(['ticket'], { keyPrefix: 'amazonSupport.requestToAmazonTicketView.form' })

  return (
    <Form.Item required={true}>
      <Form.List name="formDetails">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...rest }) => (
              <Row gutter={16} key={key}>
                {fields.length > 1 ? (
                  <Col span={2} style={{ marginBottom: 24 }}>
                    <Button
                      type="primary"
                      danger
                      icon={<Trash size={16} />}
                      onClick={() => remove(name)}
                      style={{ height: '100%' }}
                    />
                  </Col>
                ) : null}
                <Col span={fields.length > 1 ? 22 : 24}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        {...rest}
                        name={[name, 'skus']}
                        label={t('skuInput.label')}
                        rules={[{ required: true, message: t('skuInput.validation.required') }]}
                      >
                        <Input placeholder={t('skuInput.placeholder')} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...rest}
                        name={[name, 'description']}
                        label={t('description.label')}
                        rules={[{ required: true, message: t('description.validation.required') }]}
                      >
                        <TextArea placeholder={t('description.placeholder')} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item {...rest} name={[name, 'url']} label={t('url.label')}>
                        <TextArea placeholder={t('url.placeholder')} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed" block icon={<Plus size={16} />} onClick={() => add()}>
                {t('addMoreButton')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  )
}
