import dayjs from 'dayjs'
import { Table } from '../../../../components/Table/Table'
import { TablePersistKey } from '../../../../stores/useTableStore'
import { useWholesaleOrderTableColumnDefs } from './hooks/useWholesaleOrderTableColumnDefs'

export type WholesaleOrderRecord = {
  identifier: string
  vendorCompany: string
  retailerCompany: string
  shippedFromWarehouseLocation: string
  shippedToWarehouseLocation: string
  vendorApprovedAt: string | null
  retailerApprovedAt: string | null
  totalValue: {
    amount: number
    currency: string
  }
  searchIdentifiers?: string
  createdAt: string
}

type WholesaleOrdersTableProps = {
  wholesaleOrders: WholesaleOrderRecord[] | null
  loading: boolean
}

export const WholesaleOrdersTable = ({ wholesaleOrders, loading }: WholesaleOrdersTableProps) => {
  const columnDefs = useWholesaleOrderTableColumnDefs()

  return (
    <Table<WholesaleOrderRecord>
      columnDefs={columnDefs}
      defaultExcelExportParams={{
        fileName: `wholesale-orders-${dayjs().format('DD-MM-YYYY')}`,
      }}
      fullHeight
      grandTotalRow="bottom"
      loading={loading}
      persist={{
        key: TablePersistKey.WHOLESALE_ORDER,
        storage: localStorage,
      }}
      rowData={wholesaleOrders}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
      storeSearchInSearchParams
    />
  )
}
