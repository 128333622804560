import { GetAllAmazonProductsQuery } from '../../../../../generated/graphql'
import { getSearchIdentifiers } from '../../../../../helpers/getSearchIdentifiers'
import { AmazonProductTableRecord } from './AmazonProductTable'

export const prepareAmazonProductTableData = (
  data: GetAllAmazonProductsQuery
): AmazonProductTableRecord[] => {
  return data.products.flatMap((product) => {
    if (!product.productVariants) {
      return []
    }

    return product.productVariants.flatMap((productVariant) => {
      if (!productVariant.amazonProducts) {
        return []
      }

      return productVariant.amazonProducts.flatMap((amazonProduct) => {
        if (!amazonProduct.amazonListings) {
          return []
        }

        return amazonProduct.amazonListings.flatMap((amazonListing) => {
          return {
            productUuid: product.uuid,
            productVariantUuid: productVariant.uuid,
            amazonProductUuid: amazonProduct.uuid,
            amazonListingUuid: amazonListing.uuid,
            internalSku: productVariant.internalSku ?? null,
            ean: productVariant.ean ?? null,
            asin: amazonProduct.asin,
            sku: amazonListing.sku,
            fnsku: amazonListing.fnsku ?? null,
            marketplace: amazonListing.marketplace,
            mainImage: amazonListing.images?.mainImage ?? null,
            title: amazonListing.title ?? null,
            recommendedRetailPrice: amazonListing.recommendedRetailPrice ?? null,
            salePrice: amazonListing.salePrice ?? null,
            saleStartDate: amazonListing.saleStartDate ?? null,
            saleEndDate: amazonListing.saleEndDate ?? null,
            subscribeAndSave: amazonProduct.subscribeAndSave ?? null,
            changeRequests: amazonListing.changeRequests ?? [],
            mainCategory: amazonListing.mainCategory ?? null,
            subCategory: amazonListing.subCategory ?? null,
            internalStatus: amazonListing.internalStatus,
            externalStatus: amazonListing.externalStatus,
            isBuyBoxWinner: amazonListing.isBuyBoxWinner,
            panEUOfferStatus: amazonListing.panEUOfferStatus,
            labelType: amazonListing.labelType,
            isEligibleForInbound: amazonListing.isEligibleForInbound,
            isFbm: amazonListing.isFbm ?? false,
            inventory: amazonListing.inventory,
            searchIdentifiers: getSearchIdentifiers({ productVariant }),
            mostRecentShipmentDestination: amazonListing.mostRecentShipmentDestination ?? null,
          }
        })
      })
    })
  })
}

export const getIsOutOfStock = (data: AmazonProductTableRecord | undefined) => {
  if (!data) {
    return false
  }

  const fulfillableQuantity = data.inventory?.fulfillable?.fulfillableQuantity ?? 0
  const inboundQuantity =
    (data.inventory?.inbound?.inboundWorkingQuantity ?? 0) +
    (data.inventory?.inbound?.inboundShippedQuantity ?? 0) +
    (data.inventory?.inbound?.inboundReceivingQuantity ?? 0)

  const isOutOfStock = fulfillableQuantity + inboundQuantity === 0

  return isOutOfStock
}
