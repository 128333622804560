import { Badge, Col, List, Row, Tooltip, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { formatMoney } from '../../../../helpers/formatMoney'
import { ProductPerformanceDetails } from '../helpers/preparePerformanceOverview'
import { DashboardProductLabel } from './DashboardProductLabel'
import { StyledList, StyledSkeleton, StyledSubCard } from '../../styles'

type DashboardSubCardTopProductsProps = {
  topProducts: ProductPerformanceDetails[]
  rows?: number
  loading: boolean
}

export const DashboardSubCardTopProducts = ({
  topProducts,
  rows = 3,
  loading,
}: DashboardSubCardTopProductsProps) => {
  const { t } = useTranslation(['dashboard', 'translation'])

  return (
    <StyledSubCard>
      <StyledSkeleton loading={loading} paragraph={{ rows: 4 }}>
        <StyledList<ProductPerformanceDetails>
          header={<Typography.Text>{t('performance.shared.topProducts')}</Typography.Text>}
          dataSource={topProducts.slice(0, rows)}
          renderItem={(item) => (
            <List.Item style={{ padding: '0.5rem 0' }}>
              <Row gutter={8} style={{ width: '100%' }}>
                <Col span={12}>
                  <Badge
                    color={item.color}
                    text={<DashboardProductLabel product={item} />}
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Tooltip
                    title={`${item.totalShipped} / ${item.totalPending} / ${item.totalCanceled}`}
                  >
                    <Row gutter={[4, 4]}>
                      <Col>
                        <Typography.Text>{formatMoney(item.totalRevenue, 'EUR')}</Typography.Text>
                      </Col>
                      <Col flex="auto">
                        <Typography.Text style={{ opacity: 0.4 }}>
                          {formatMoney(item.pendingRevenue, 'EUR')}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Tooltip>
                </Col>
              </Row>
            </List.Item>
          )}
          locale={{ emptyText: t('shared.noData', { ns: 'translation' }) }}
          split={false}
        />
      </StyledSkeleton>
    </StyledSubCard>
  )
}
