import dayjs from 'dayjs'
import { GetBillingDocumentsQuery } from '../../../generated/graphql'
import { CreditNoteRecord, InvoiceRecord } from '../components/BillingDocumentsTable'

export const prepareBillingTableData = (data: GetBillingDocumentsQuery) => {
  const invoices: InvoiceRecord[] = data.invoices.map((invoice) => ({
    yearMonth: dayjs(invoice.date).format('YYYYMM'),
    invoiceId: invoice.invoiceId,
    documentNumber: invoice.invoiceNumber,
    date: invoice.date,
    totalAmount: invoice.totalGross,
    pdfUrl: invoice.pdfUrl,
  }))

  const creditNotes: CreditNoteRecord[] = data.creditNotes.map((invoice) => ({
    yearMonth: dayjs(invoice.date).format('YYYYMM'),
    creditNoteId: invoice.creditNoteId,
    documentNumber: invoice.creditNoteNumber,
    date: invoice.date,
    totalAmount: invoice.totalGross,
    pdfUrl: invoice.pdfUrl,
  }))

  return [...invoices, ...creditNotes].sort((a, b) => {
    return dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
  })
}
