import { useMutation, useQuery } from '@apollo/client'
import { AgGridReact } from 'ag-grid-react'
import { Button, Space } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import { Save } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'sonner'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import {
  CompanyType,
  GetSalesChannelRegionDealDocument,
  GetWarehousesAndWholesaleOrderDocument,
  GetWarehousesAndWholesaleOrderQuery,
  GetWholesaleOrderDetailsDocument,
  UpdateWholesaleOrderDocument,
  UpdateWholesaleOrderInput,
} from '../../generated/graphql'
import { usePreventNavigation } from '../../hooks/usePreventNavigation'
import { useGlobalStore } from '../../stores/useGlobalStore'
import {
  WholesaleOrderForm,
  WholesaleOrderFormInstance,
} from './components/WholesaleOrderForm/WholesaleOrderForm'
import { WholesaleOrderFormTableRecord } from './components/WholesaleOrderForm/WholesaleOrderFormTable'
import { WholesaleOrderTermsModal } from './components/WholesaleOrderForm/WholesaleOrderTermsModal'
import { validateForm } from './WholesaleOrderCreateView'

type WholesaleOrderEditViewParams = {
  wholesaleOrderIdentifier: string
}

export const WholesaleOrderEditView = () => {
  const [rowData, setRowData] = useState<WholesaleOrderFormTableRecord[]>([])
  const [submitting, setSubmitting] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const gridRef = useRef<AgGridReact<WholesaleOrderFormTableRecord> | null>(null)

  const navigate = useNavigate()
  const { t } = useTranslation(['wholesale-order', 'translation'], {
    keyPrefix: 'wholesaleOrderEditView',
  })
  const [form] = useForm<WholesaleOrderFormInstance>()

  const shipToWarehouseLocationUuid = useWatch(['shipToWarehouseLocationUuid'], form)

  usePreventNavigation(form)

  const params = useParams<WholesaleOrderEditViewParams>()
  const identifier = params.wholesaleOrderIdentifier ?? ''

  const { data, error, loading } = useQuery(GetWarehousesAndWholesaleOrderDocument, {
    errorPolicy: 'none',
    skip: !identifier,
    variables: {
      wholesaleOrderIdentifier: identifier,
      companyUuid: selectedCompany.uuid,
    },
  })

  const { data: salesChannelRegionData } = useQuery(GetSalesChannelRegionDealDocument, {
    skip: !shipToWarehouseLocationUuid,
    variables: {
      companyUuid: selectedCompany.uuid,
      warehouseServiceProviderLocationUuid: shipToWarehouseLocationUuid!,
    },
  })

  const [updateWholesaleOrder] = useMutation(UpdateWholesaleOrderDocument)

  useEffect(() => {
    if (data?.wholesaleOrder) {
      const { vendorApprovedAt, retailerApprovedAt } = data.wholesaleOrder

      if (vendorApprovedAt && retailerApprovedAt) {
        navigate(`/wholesale/orders/${identifier}`)
        toast.error(t('notification.cannotEditApprovedOrder'))
      }
    }
  }, [data, identifier, navigate])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error || !data) {
    return (
      <StatusResult status="500" title="500" subTitle={t('translation:shared.error.message')} />
    )
  }

  const submitForm = async (values: WholesaleOrderFormInstance) => {
    try {
      setSubmitting(true)

      if (!values.selectedRows?.length) {
        throw new Error('No rows selected.')
      }

      await updateWholesaleOrder({
        variables: {
          identifier,
          input: formatEditWholesaleOrderInput(values, data.company),
        },
        update: (cache, { data }) => {
          const updatedWholesaleOrder = data?.updateWholesaleOrder

          if (updatedWholesaleOrder) {
            const cachedQuery = cache.readQuery({
              query: GetWholesaleOrderDetailsDocument,
              variables: {
                wholesaleOrderIdentifier: updatedWholesaleOrder.identifier,
              },
            })

            if (cachedQuery) {
              cache.writeQuery({
                query: GetWholesaleOrderDetailsDocument,
                variables: {
                  wholesaleOrderIdentifier: updatedWholesaleOrder.identifier,
                },
                data: {
                  wholesaleOrder: {
                    ...cachedQuery.wholesaleOrder,
                    lineItems: updatedWholesaleOrder.lineItems,
                  },
                },
              })
            }
          }
        },
        onCompleted: (data) => {
          toast.success(t('notification.wholesaleOrderUpdated'))
          navigate(`/wholesale/orders/${data.updateWholesaleOrder.identifier}`)
        },
      })
    } catch (error) {
      console.error(error)
      toast.error(t('error.updateError'))
    } finally {
      setSubmitting(false)
    }
  }

  const selectedRows = gridRef.current
    ? (gridRef.current.api.getSelectedRows() as WholesaleOrderFormTableRecord[])
    : []

  const isValid = validateForm(selectedRows)

  return (
    <>
      <SubHeader
        heading={t('heading', { identifier })}
        withBackButton
        onBackUrl={`/wholesale/orders/${identifier}`}
        rightContent={
          <Space>
            <WholesaleOrderTermsModal
              salesChannelRegionDeal={salesChannelRegionData?.salesChannelRegionDeal ?? null}
            />
            <Button
              type="primary"
              icon={<Save size={16} />}
              onClick={() => form.submit()}
              disabled={!isValid}
              loading={submitting}
            >
              {t('button.save')}
            </Button>
          </Space>
        }
      />
      <ViewContainer>
        <WholesaleOrderForm
          form={form}
          submitForm={submitForm}
          gridRef={gridRef}
          company={data.company}
          rowData={rowData}
          setRowData={setRowData}
          wholesaleOrder={data.wholesaleOrder}
        />
      </ViewContainer>
    </>
  )
}

function formatEditWholesaleOrderInput(
  values: WholesaleOrderFormInstance,
  company: GetWarehousesAndWholesaleOrderQuery['company']
): UpdateWholesaleOrderInput {
  if (!values.selectedRows || values.selectedRows.length === 0) {
    throw new Error('Form is not valid.')
  }

  return {
    vendorApprovedAt: company.type === CompanyType.WHOLESALER ? dayjs().toISOString() : null,
    retailerApprovedAt: company.type === CompanyType.RETAILER ? dayjs().toISOString() : null,
    lineItems: values.selectedRows.map((row) => {
      const totalQuantity =
        row.quantityOfPackagingUnits && row.selectedPackagingUnit?.quantity
          ? row.quantityOfPackagingUnits * row.selectedPackagingUnit.quantity
          : undefined

      if (!totalQuantity) {
        throw new Error('Total quantity is missing.')
      }

      if (!row.netPurchasePrice) {
        throw new Error('Net purchase price is missing.')
      }

      if (!row.selectedPackagingUnit) {
        throw new Error('Selected packaging unit is missing.')
      }

      if (!row.quantityOfPackagingUnits) {
        throw new Error('Quantity of packaging units is missing.')
      }

      return {
        identifier: row.lineItemIdentifier,
        productVariantUuid: row.productVariantUuid,
        totalQuantity,
        netPurchasePrice: row.netPurchasePrice,
        currency: 'EUR',
        quantityOfPackagingUnits: row.quantityOfPackagingUnits,
        packagingUnitUuid: row.selectedPackagingUnit.uuid,
      }
    }),
  }
}
