import { Marketplace } from '../generated/graphql'

export function getMarketplaceCurrency(marketplace: Marketplace) {
  const marketplaceCurrencyMap = {
    DE: 'EUR',
    FR: 'EUR',
    ES: 'EUR',
    IT: 'EUR',
    NL: 'EUR',
    UK: 'GBP',
    SE: 'SEK',
    PL: 'PLN',
    BE: 'EUR',
    US: 'USD',
    CA: 'CAD',
    MX: 'MXN',
  } as const

  return marketplaceCurrencyMap[marketplace]
}
