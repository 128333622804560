import { useQuery } from '@apollo/client'
import { Dropdown, TabsProps } from 'antd'
import { ChevronDown } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { StyledTabs } from '../../components/Tabs'
import { GetAllProductsDocument, GetAllProductsQuery } from '../../generated/graphql'
import { getSearchIdentifiers } from '../../helpers/getSearchIdentifiers'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { PackagingUnitsImportModal } from './components/PackagingUnitsImportModal'
import { ProductsImportModalModal } from './components/ProductsImportModal'
import { ProductTable, ProductTableRecord } from './components/ProductTable/ProductTable'
import {
  ProductVariantPackagingUnitsTable,
  ProductVariantPackagingUnitsTableRecord,
} from './components/ProductVariantPackagingUnitsTable'

const ProductViewTabs = {
  PRODUCTS: 'products',
  PACKAGING_UNITS: 'packaging-units',
} as const

export const ProductsView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const user = useGlobalStore((state) => state.user)

  const [searchParams, setSearchParams] = useSearchParams()
  const tabParam = searchParams.get('tab')
  const tab =
    tabParam && Object.values(ProductViewTabs).includes(tabParam)
      ? tabParam
      : ProductViewTabs.PRODUCTS

  const { t } = useTranslation('inventory')

  const { error, data } = useQuery(GetAllProductsDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    skip: !selectedCompany?.uuid,
    variables: {
      uuid: selectedCompany?.uuid,
    },
  })

  const { dataSource, brands, productVariantPackagingUnits } = useMemo(() => {
    if (!data) {
      return {
        dataSource: undefined,
        brands: [],
        productVariantPackagingUnits: undefined,
      }
    }

    return {
      dataSource: prepareProductTableData(data),
      brands: data.brands.map((brand) => brand.name),
      productVariantPackagingUnits: prepareProductVariantPackagingUnitsTableData(data),
    }
  }, [data])

  if (!tabParam || !Object.values(ProductViewTabs).includes(tabParam)) {
    const newSearchParams = new URLSearchParams(window.location.search)
    newSearchParams.set('tab', ProductViewTabs.PRODUCTS)
    return <Navigate to={`?${newSearchParams}`} replace />
  }

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const handleTabChange: TabsProps['onChange'] = (activeKey) => {
    setSearchParams(() => {
      const newSearchParams = new URLSearchParams()
      newSearchParams.set('tab', activeKey)
      return newSearchParams
    })
  }

  const items: TabsProps['items'] = [
    {
      label: t('product.heading'),
      key: ProductViewTabs.PRODUCTS,
      children: <ProductTable dataSource={dataSource} brands={brands} />,
    },
    {
      label: t('packagingUnit.heading'),
      key: ProductViewTabs.PACKAGING_UNITS,
      children: (
        <ProductVariantPackagingUnitsTable
          productVariantPackagingUnits={productVariantPackagingUnits}
        />
      ),
    },
  ]

  return (
    <>
      <SubHeader
        heading={
          tab === ProductViewTabs.PRODUCTS ? t('product.heading') : t('packagingUnit.heading')
        }
        rightContent={
          tab === ProductViewTabs.PRODUCTS ? (
            <ProductsDropdownButton />
          ) : tab === ProductViewTabs.PACKAGING_UNITS && user?.isAdmin ? (
            <PackagingUnitsImportModal />
          ) : null
        }
      />
      <ViewContainer>
        <StyledTabs
          activeKey={tab}
          animated={{ inkBar: true, tabPane: true }}
          onChange={handleTabChange}
          items={items}
          tabBarGutter={0}
        />
      </ViewContainer>
    </>
  )
}

const ProductsDropdownButton = () => {
  const [open, setOpen] = useState(false)

  const user = useGlobalStore((state) => state.user)

  const navigate = useNavigate()
  const { t } = useTranslation('inventory')

  return (
    <>
      <ProductsImportModalModal open={open} setOpen={setOpen} />
      <Dropdown.Button
        type="primary"
        icon={<ChevronDown size={16} />}
        onClick={() => navigate('add')}
        trigger={['click']}
        menu={{
          items: [
            {
              key: 'bulk-add',
              label: t('product.button.bulkAdd'),
              onClick: () => setOpen(true),
              disabled: !user?.isAdmin,
            },
          ],
        }}
      >
        {t('product.button.add')}
      </Dropdown.Button>
    </>
  )
}
export function prepareProductTableData(data: GetAllProductsQuery): ProductTableRecord[] {
  return data.products.flatMap((product) => {
    if (!product.productVariants) {
      return []
    }

    return product.productVariants.flatMap((productVariant) => {
      return {
        productUuid: product.uuid,
        name: product.name,
        brand: product.brand?.name ?? null,
        ean: productVariant.ean ?? null,
        tags: product.tags ?? [],
        internalSku: productVariant.internalSku ?? null,
        variantUuid: productVariant.uuid,
        sequence: `${product.sequence}`.padStart(4, '0'),
        attributes: productVariant.attributes ?? [],
        manufacturer: product.manufacturer ?? null,
        countryOfOrigin: product.countryOfOrigin ?? null,
        taric: product.taric?.[0]?.code ?? null,
        taxCategory: product.taxCategory?.name ?? null,
        status: product.status,
        smallestSalesUnit: productVariant.smallestSalesUnit,
        salesChannelCategory: product.salesChannelCategory.name,
        searchIdentifiers: getSearchIdentifiers({ productVariant }),
      }
    })
  })
}

export function prepareProductVariantPackagingUnitsTableData(
  data: GetAllProductsQuery
): ProductVariantPackagingUnitsTableRecord[] {
  return data.products.flatMap((product) => {
    if (!product.productVariants) {
      return []
    }

    return product.productVariants.flatMap((productVariant) => {
      return productVariant.packagingUnits.map((packagingUnit) => ({
        uuid: packagingUnit.uuid,
        productUuid: product.uuid,
        productVariantUuid: productVariant.uuid,
        gtin: packagingUnit.gtin ?? null,
        gtinType: packagingUnit.gtinType ?? null,
        type: packagingUnit.type,
        internalSku: packagingUnit.internalSku ?? null,
        quantity: packagingUnit.quantity,
        lengthInCm: packagingUnit.lengthInCm ?? null,
        widthInCm: packagingUnit.widthInCm ?? null,
        heightInCm: packagingUnit.heightInCm ?? null,
        weightInGram: packagingUnit.weightInGram ?? null,
        searchIdentifiers: getSearchIdentifiers({ productVariant }),
      }))
    })
  })
}
