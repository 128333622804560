import { Col, Descriptions, Row, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { GetWholesaleOrderTemplateQuery } from '../../../../generated/graphql'
import { StyledMainCard } from '../../../dashboard/styles'
import { descriptionsStyle } from '../../../products/components/styles'
import { FormattedDate } from '../../../../components/FormattedDate'
import { EditWholesaleTemplateNameModal } from './EditWholesaleTemplateNameModal'
import { EditWholesaleTemplateEmailsModal } from './EditWholesaleTemplateEmailsModal'

type WholesaleOrderGeneralDetailsCardProps = {
  wholesaleOrderTemplateDetails: GetWholesaleOrderTemplateQuery['wholesaleOrderTemplate']
}
export const WholesaleOrderTemplateGeneralDetailsCard = ({
  wholesaleOrderTemplateDetails,
}: WholesaleOrderGeneralDetailsCardProps) => {
  const { t } = useTranslation('wholesale-order')

  return (
    <StyledMainCard title={t('wholesaleOrderGeneralDetailsCard.title')}>
      <Descriptions {...descriptionsStyle}>
        <Descriptions.Item label={t('wholesaleOrderGeneralDetailsCard.label.name')}>
          <Row gutter={8} align="top" wrap={false}>
            <Col flex="auto">{wholesaleOrderTemplateDetails.name}</Col>
            <Col>
              <EditWholesaleTemplateNameModal
                identifier={wholesaleOrderTemplateDetails.identifier ?? ''}
                name={wholesaleOrderTemplateDetails.name ?? ''}
              />
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label={t('wholesaleOrderGeneralDetailsCard.label.status')}>
          <Tag>{wholesaleOrderTemplateDetails.status}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('wholesaleOrderGeneralDetailsCard.label.interval')}>
          {wholesaleOrderTemplateDetails.interval} {t('wholesaleOrdersTemplatesList.lineItem.days')}
        </Descriptions.Item>

        <Descriptions.Item label={t('wholesaleOrderGeneralDetailsCard.label.startDate')}>
          <FormattedDate
            date={wholesaleOrderTemplateDetails.startDate}
            layout="horizontal"
            withRelativeTime
          />
        </Descriptions.Item>

        <Descriptions.Item label={t('wholesaleOrderGeneralDetailsCard.label.createdAt')}>
          <FormattedDate
            date={wholesaleOrderTemplateDetails.createdAt}
            layout="horizontal"
            withRelativeTime
          />
        </Descriptions.Item>
        <Descriptions.Item label={t('wholesaleOrderGeneralDetailsCard.label.updatedAt')}>
          <FormattedDate
            date={wholesaleOrderTemplateDetails.updatedAt}
            layout="horizontal"
            withRelativeTime
          />
        </Descriptions.Item>
        {wholesaleOrderTemplateDetails.notificationEmails && (
          <Descriptions.Item label={t('wholesaleOrderGeneralDetailsCard.label.notificationEmails')}>
            <Row gutter={8} align="top" wrap={false}>
              <Col flex="auto">
                {wholesaleOrderTemplateDetails.notificationEmails?.map((email) => (
                  <Tag key={email}>{email}</Tag>
                ))}
              </Col>
              <Col>
                <EditWholesaleTemplateEmailsModal
                  identifier={wholesaleOrderTemplateDetails.identifier ?? ''}
                  notificationEmails={wholesaleOrderTemplateDetails.notificationEmails ?? []}
                />
              </Col>
            </Row>
          </Descriptions.Item>
        )}
      </Descriptions>
    </StyledMainCard>
  )
}
