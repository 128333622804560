import { useMutation } from '@apollo/client'
import { useTheme } from '@emotion/react'
import {
  CellEditRequestEvent,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { Button, Flex, Popconfirm, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { PenLine, Trash } from 'lucide-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { FormattedDate } from '../../../components/FormattedDate'
import { Table } from '../../../components/Table/Table'
import {
  CompanyType,
  DeleteProductComplianceCheckItemDocument,
  GetProductComplianceCheckByIdentifierDocument,
  UpdateProductComplianceCheckItemNoteDocument,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { StyledMainCard } from '../../dashboard/styles'
import { ProductComplianceCheckItem } from '../ComplianceDetailsView'
import { getStatusDetails } from './ComplianceTable'
import { CreateProductComplianceCheckItemsModal } from './CreateProductComplianceCheckItemsModal'
import { EditProductComplianceCheckItemStatusModal } from './EditProductComplianceCheckItemStatusModal'

type ProductComplianceCheckItemListCardProps = {
  items: ProductComplianceCheckItem[]
  productComplianceCheckIdentifier: string
}

export const ProductComplianceCheckItemListCard = ({
  items,
  productComplianceCheckIdentifier,
}: ProductComplianceCheckItemListCardProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { t } = useTranslation(['compliance'])
  const theme = useTheme()

  const isRetailer = selectedCompany.type === CompanyType.RETAILER

  const [updateProductComplianceCheckItemNote] = useMutation(
    UpdateProductComplianceCheckItemNoteDocument
  )

  const [deleteProductComplianceCheckItem] = useMutation(DeleteProductComplianceCheckItemDocument)

  const handleDelete = async (countryCode: string) => {
    try {
      await deleteProductComplianceCheckItem({
        variables: {
          productComplianceCheckIdentifier,
          countryCode: countryCode,
        },
        refetchQueries: [
          {
            query: GetProductComplianceCheckByIdentifierDocument,
            variables: { identifier: productComplianceCheckIdentifier },
          },
        ],
      })
      toast.success(t('compliance:table.popConfirm.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('compliance:table.popConfirm.error'))
    }
  }

  const [columnDefs] = useState<
    (ColDef<ProductComplianceCheckItem> | ColGroupDef<ProductComplianceCheckItem>)[]
  >([
    {
      lockPosition: 'left',
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      hide: !isRetailer,
      minWidth: 56,
      maxWidth: 56,
      cellRenderer: ({ data }: ICellRendererParams<ProductComplianceCheckItem>) => {
        if (!data?.countryCode) {
          return null
        }

        return (
          <Popconfirm
            title={t('compliance:table.popConfirm.title')}
            okText={t('compliance:table.popConfirm.confirmButton')}
            cancelText={t('compliance:table.popConfirm.cancelButton')}
            onConfirm={async () => await handleDelete(data.countryCode)}
            okButtonProps={{ danger: true }}
          >
            <Button
              danger
              icon={<Trash size={16} />}
              disabled={
                data?.complianceStatus === 'COMPLIANT' ||
                data?.complianceStatus === 'APPROVED_WITH_RESERVATION'
              }
            />
          </Popconfirm>
        )
      },
    },
    {
      field: 'countryCode',
      headerName: t('compliance:table.countryCodes'),
    },
    {
      field: 'complianceStatus',
      headerName: t('compliance:table.status.header'),
      cellRenderer: (
        params: ICellRendererParams<
          ProductComplianceCheckItem,
          ProductComplianceCheckItem['complianceStatus']
        >
      ) => {
        if (!params?.data?.complianceStatus) {
          return null
        }

        const statusDetails = getStatusDetails(params.data.complianceStatus, t)

        return (
          <Flex gap={8} align="center">
            {isRetailer && (
              <EditProductComplianceCheckItemStatusModal
                productComplianceCheckIdentifier={productComplianceCheckIdentifier}
                countryCode={params.data.countryCode}
                complianceStatus={params.data.complianceStatus}
              />
            )}
            <Tooltip
              title={
                <Flex vertical gap={8}>
                  <Typography.Text strong>{statusDetails.statusText}</Typography.Text>
                  <Typography.Text>{statusDetails.tooltip}</Typography.Text>
                </Flex>
              }
              color={theme.colors.base[5]}
              mouseEnterDelay={0.5}
            >
              <Tag color={statusDetails.color}>{statusDetails.statusText}</Tag>
            </Tooltip>
          </Flex>
        )
      },
    },
    {
      field: 'note',
      headerName: t('compliance:table.note.label'),
      editable: isRetailer,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      suppressPaste: true,
      cellRenderer: (
        params: ICellRendererParams<ProductComplianceCheckItem, ProductComplianceCheckItem['note']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (isRetailer) {
          return (
            <Flex align="center" gap={8}>
              <PenLine size={16} />
              {params.value ? (
                <Typography.Text>{params.value}</Typography.Text>
              ) : (
                <Typography.Text type="secondary">
                  {t('shared.button.edit', { ns: 'translation' })}
                </Typography.Text>
              )}
            </Flex>
          )
        }

        return params.value
      },
    },
    {
      field: 'markedCompliantDate',
      headerName: t('compliance:table.date'),
      filter: 'agDateColumnFilter',
      filterValueGetter: (params: ValueGetterParams<ProductComplianceCheckItem>) => {
        if (!params.data?.markedCompliantDate) {
          return null
        }

        return dayjs(params.data.markedCompliantDate).startOf('day').toDate()
      },
      cellRenderer: ({ value }: ICellRendererParams<ProductComplianceCheckItem>) => {
        if (!value) {
          return null
        }

        return <FormattedDate date={value} withRelativeTime layout="horizontal" />
      },
    },
  ])

  const onCellEditRequest = useCallback(
    async (event: CellEditRequestEvent<ProductComplianceCheckItem>) => {
      const field = event.colDef.field

      if (!field) {
        return false
      }

      if (event.newValue === event.oldValue) {
        return false
      }

      switch (field) {
        case 'note': {
          try {
            const value = event.newValue?.trim() || null

            event.api.applyTransaction({
              update: [{ ...event.data, [field]: value }],
            })

            await updateProductComplianceCheckItemNote({
              variables: {
                productComplianceCheckIdentifier,
                countryCode: event.data.countryCode,
                note: value,
              },
              optimisticResponse: {
                updateProductComplianceCheckItemNote: {
                  note: value,
                },
              },
              update: (cache, { data }) => {
                const updatedProductComplianceCheckItem = data?.updateProductComplianceCheckItemNote
                const cachedQuery = cache.readQuery({
                  query: GetProductComplianceCheckByIdentifierDocument,
                  variables: { identifier: productComplianceCheckIdentifier },
                })

                if (updatedProductComplianceCheckItem && cachedQuery) {
                  cache.writeQuery({
                    query: GetProductComplianceCheckByIdentifierDocument,
                    variables: { identifier: productComplianceCheckIdentifier },
                    data: {
                      productComplianceCheck: {
                        ...cachedQuery.productComplianceCheck,
                        items: cachedQuery.productComplianceCheck.items.map((item) =>
                          item.countryCode === event.data.countryCode
                            ? { ...item, note: updatedProductComplianceCheckItem.note }
                            : item
                        ),
                      },
                    },
                  })
                }
              },
              refetchQueries: [
                {
                  query: GetProductComplianceCheckByIdentifierDocument,
                  variables: { identifier: productComplianceCheckIdentifier },
                },
              ],
            })
            toast.success(t('compliance:table.note.edit.success'))
          } catch (error) {
            event.api.applyTransaction({
              update: [{ ...event.data, [field]: event.oldValue }],
            })
            console.error(error)
            toast.error(t('shared.error.message', { ns: 'translation' }))
          } finally {
            event.api.refreshCells({
              force: true,
              columns: [field],
              rowNodes: [event.node],
            })
          }

          break
        }

        default:
          break
      }
    },
    []
  )

  return (
    <StyledMainCard
      title={t('compliance:listCard.title')}
      extra={
        isRetailer && (
          <CreateProductComplianceCheckItemsModal
            items={items}
            productComplianceCheckIdentifier={productComplianceCheckIdentifier}
          />
        )
      }
      styles={{ body: { height: '100%' } }}
    >
      <Table<ProductComplianceCheckItem>
        columnDefs={columnDefs}
        fullHeight
        onCellEditRequest={onCellEditRequest}
        readOnlyEdit
        rowData={items}
      />
    </StyledMainCard>
  )
}
