import { useMutation } from '@apollo/client'
import { Button, Col, Form, Modal, Popconfirm, Row, Space } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'sonner'
import {
  AmazonPpcCampaignType,
  Marketplace,
  UpdateSponsoredProductsKeywordsDocument,
} from '../../../../../../generated/graphql'
import { roundNumber } from '../../../../../../helpers/roundNumber'
import { useGlobalStore } from '../../../../../../stores/useGlobalStore'
import {
  FormStrategy,
  UpdateSponsoredProductsKeywordInputExtended,
} from '../../../../../../views/ppc/helpers/interfaces'
import { useDefaultMaximumBid } from '../../../../hooks/useDefaultMaximumBid'
import { useDefaultMinimumBid } from '../../../../hooks/useDefaultMinimumBid'
import BulkEditKeywordBidForm from './BulkEditKeywordBidForm'
import BulkEditKeywordBidTable from './BulkEditKeywordBidTable'

type BulkEditSponsoredProductsKeywordsBidModalProps = {
  campaignId: string
  keywordsIn: UpdateSponsoredProductsKeywordInputExtended[]
  updateKeywordList: Function
  marketplace: Marketplace
}

const BulkEditSponsoredProductsKeywordsBidModal = ({
  campaignId,
  keywordsIn,
  updateKeywordList,
  marketplace,
}: BulkEditSponsoredProductsKeywordsBidModalProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [formStrategy, setFormStrategy] = useState<FormStrategy>(FormStrategy.VALUE)
  const [keywordsOut, setKeywordsOut] = useState(keywordsIn)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const defaultMinimumBid = useDefaultMinimumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const defaultMaximumBid = useDefaultMaximumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const [updateSponsoredProductsKeywords, { loading: loadingUpdate }] = useMutation(
    UpdateSponsoredProductsKeywordsDocument
  )

  const calculateBidFromStrategy = useCallback(
    (currentBid: number) => {
      const strategy = form.getFieldValue('strategy')

      switch (strategy) {
        case FormStrategy.VALUE:
          const value = form.getFieldValue('bid')
          return value
        case FormStrategy.INCR_PERCENTAGE:
          const incrPercentage = form.getFieldValue('incrPercentage')
          return roundNumber(currentBid * ((100 + incrPercentage) / 100))
        case FormStrategy.DECR_PERCENTAGE:
          const decrPercentage = form.getFieldValue('decrPercentage')
          return roundNumber(currentBid * ((100 - decrPercentage) / 100))
        case FormStrategy.INCR_AMOUNT:
          const incrAmount = form.getFieldValue('incrAmount')
          return roundNumber(currentBid + incrAmount)
        case FormStrategy.DECR_AMOUNT:
          const decrAmount = form.getFieldValue('decrAmount')
          return roundNumber(currentBid - decrAmount)
        default:
          return currentBid
      }
    },
    [form]
  )

  const normaliseBid = (bid: number): number => {
    return bid < defaultMinimumBid
      ? defaultMinimumBid
      : bid > defaultMaximumBid
        ? defaultMaximumBid
        : bid
  }

  const updateSponsoredProductsKeywordsOut = useCallback(() => {
    if (!loadingUpdate) {
      const keywords = keywordsIn.map((keyword: UpdateSponsoredProductsKeywordInputExtended) => {
        let bid = calculateBidFromStrategy(keyword.bid)
        bid = normaliseBid(bid)
        return {
          ...keyword,
          newBid: bid,
        }
      })

      setKeywordsOut(keywords)
    }
  }, [keywordsIn, calculateBidFromStrategy, loadingUpdate])

  useEffect(() => {
    if (modalVisible && !loadingUpdate) {
      const initialBid =
        keywordsIn[0]?.bid > defaultMinimumBid ? keywordsIn[0].bid : defaultMinimumBid

      setFormStrategy(FormStrategy.VALUE)

      form.setFieldsValue({
        strategy: 'value',
        bid: initialBid,
        incrPercentage: 10,
        decrPercentage: 10,
        incrAmount: 0.05,
        decrAmount: 0.05,
      })

      updateSponsoredProductsKeywordsOut()
    }
  }, [modalVisible, keywordsIn, form, updateSponsoredProductsKeywordsOut, loadingUpdate])

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleOnFinish = async () => {
    const keywords = keywordsIn.map((keyword) => {
      let bid = calculateBidFromStrategy(keyword.bid)
      bid = normaliseBid(bid)

      return {
        keywordId: keyword.keywordId,
        state: keyword.state,
        bid: bid,
      }
    })

    updateSponsoredProductsKeywords({
      variables: { companyUuid: selectedCompany?.uuid || '', campaignId, marketplace, keywords },
    })
      .then(({ data }) => {
        const updatedKeywords = data?.updateSponsoredProductsKeywords
        if (!updatedKeywords) {
          throw new Error('No keywords were updated')
        }
        updatedKeywords.forEach((keyword) => {
          updateKeywordList(keyword)
        })
        toast.success('Keyword(s) updated.')
        setModalVisible(false)
      })
      .catch((e) => {
        console.error(e)
        if (e.networkError?.statusCode === 429) {
          toast.error("Amazon's servers are currently busy, please try again in a few minutes.")
        } else {
          toast.error('Error updating keyword(s).')
        }
      })
  }

  const handleFormStrategyChanged = (value: FormStrategy) => {
    setFormStrategy(value)
  }

  const handleFormChanged = () => {
    updateSponsoredProductsKeywordsOut()
  }

  return (
    <>
      <Button key="huihif" onClick={showModal} disabled={!keywordsIn?.length}>
        Change bid
      </Button>

      <div
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}
        key="u90u90u"
      >
        <Modal
          title={`Change bid of (${keywordsIn.length}) selected keywords`}
          open={modalVisible}
          onCancel={handleCancel}
          width={960}
          footer={
            <Space>
              <Button onClick={handleCancel} key="cancel">
                Cancel
              </Button>
              <Popconfirm
                key="u8f8f"
                placement="top"
                title={'Please confirm changes to all selected keywords'}
                onConfirm={form.submit}
                okText="Confirm"
                cancelText="Go back"
              >
                <Button key="savel" type={'primary'} loading={loadingUpdate}>
                  Save
                </Button>
              </Popconfirm>
            </Space>
          }
        >
          <Row gutter={36} align="middle">
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <BulkEditKeywordBidForm
                defaultMinimumBid={defaultMinimumBid}
                defaultMaximumBid={defaultMaximumBid}
                form={form}
                handleOnFinish={handleOnFinish}
                handleFormChanged={handleFormChanged}
                handleFormStrategyChanged={handleFormStrategyChanged}
                formStrategy={formStrategy}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <BulkEditKeywordBidTable keywords={keywordsOut} loading={loadingUpdate} />
            </Col>
          </Row>
        </Modal>
      </div>
    </>
  )
}

export default BulkEditSponsoredProductsKeywordsBidModal
