import { useLazyQuery } from '@apollo/client'
import { Form, FormInstance, Input, Menu } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import {
  GetAllSponsoredProductsTargetableCategoriesDocument,
  GetAllSponsoredProductsTargetableCategoriesQuery,
  GetSponsoredProductsTargetCategoryRecommendationsDocument,
  GetSponsoredProductsTargetCategoryRecommendationsQuery,
  Marketplace,
  TargetingExpressionPredicateType,
} from '../../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../../stores/useGlobalStore'
import { LoadingSpinner } from '../../../../../LoadingSpinner'
import {
  FormWidget,
  ListTitle,
  RequiredFieldIndicator,
  SelectContainer,
  SelectedItemsContainer,
} from '../../../../styles'
import BulkAsinInput from './BulkAsinInput'
import SelectSponsoredProductsCategories from './SelectSponsoredProductsCategories'
import UserDefinedTargetList from './UserDefinedTargetList'

type CreateSponsoredProductsManualTargetsFormFieldsProps = {
  form: FormInstance
  marketplace: Marketplace
  selectedProductAsins: string[]
}

enum TargetingSubType {
  PRODUCTS = 'products',
  CATEGORIES = 'categories',
}

export type UserDefinedTarget = {
  id: string
  expressionType: TargetingExpressionPredicateType
  expressionValue: string
  resolvedExpressionValue: string
  name: string
  description: string
  bid: number
}

export type AmazonCategory = {
  id: number
  na: string
  ta: boolean
  ch: [AmazonCategory]
}

const CreateSponsoredProductsManualTargetsFormFields = ({
  form,
  marketplace,
  selectedProductAsins,
}: CreateSponsoredProductsManualTargetsFormFieldsProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const [selectedTargetingSubtype, setSelectedTargetingSubtype] = useState<TargetingSubType>(
    TargetingSubType.CATEGORIES
  )
  const [userDefinedTargets, setUserDefinedTargets] = useState<UserDefinedTarget[]>([])
  const [categoryTree, setCategoryTree] = useState<AmazonCategory[]>([])
  const [recommendedCategories, setRecommendedCategories] = useState<
    GetSponsoredProductsTargetCategoryRecommendationsQuery['sponsoredProductsTargetCategoryRecommendations']['categories']
  >([])
  const [loadingRecommendedCategories, setLoadingRecommendedCategories] = useState<boolean>(false)
  const [loadingAllCategories, setLoadingAllCategories] = useState<boolean>(false)

  const handleAddTarget = (target: UserDefinedTarget) => {
    setUserDefinedTargets([...userDefinedTargets, target])
  }

  const handleAddTargets = (targets: UserDefinedTarget[]) => {
    setUserDefinedTargets([...userDefinedTargets, ...targets])
  }

  const handleRemoveTarget = (target: UserDefinedTarget) => {
    setUserDefinedTargets(
      userDefinedTargets.filter((userDefinedTarget) => userDefinedTarget.id !== target.id)
    )
  }

  const handleUpdateTarget = (updatedTarget: UserDefinedTarget) => {
    setUserDefinedTargets(
      userDefinedTargets.map((userDefinedTarget) => {
        if (updatedTarget.id === userDefinedTarget.id) {
          return updatedTarget
        } else {
          return userDefinedTarget
        }
      })
    )
  }

  useEffect(() => {
    form.setFieldsValue({ productTargets: userDefinedTargets })
  }, [userDefinedTargets, form])

  const handleSelectSubview = (e: any) => {
    setSelectedTargetingSubtype(e.key)
  }

  const onGetSponsoredProductsTargetCategoryRecommendationsCompleted = (
    data: GetSponsoredProductsTargetCategoryRecommendationsQuery
  ) => {
    const categories = data?.sponsoredProductsTargetCategoryRecommendations?.categories || []
    setRecommendedCategories(categories)
    setLoadingRecommendedCategories(false)
  }

  const onGetSponsoredProductsTargetCategoryRecommendationsError = (error: any) => {
    console.error(error)
    toast.error('Error fetching recommended categories')
    setLoadingRecommendedCategories(false)
  }

  const [getSponsoredProductsTargetCategoryRecommendations] = useLazyQuery(
    GetSponsoredProductsTargetCategoryRecommendationsDocument,
    {
      onCompleted: (data) => onGetSponsoredProductsTargetCategoryRecommendationsCompleted(data),
      onError: (error) => onGetSponsoredProductsTargetCategoryRecommendationsError(error),
    }
  )

  const onGetAllSponsoredProductsTargetableCategoriesCompleted = (
    data: GetAllSponsoredProductsTargetableCategoriesQuery
  ) => {
    const categories = JSON.parse(data?.sponsoredProductsTargetableCategories?.CategoryTree)
    setCategoryTree(categories as AmazonCategory[])
    setLoadingAllCategories(false)
  }

  const onGetAllSponsoredProductsTargetableCategoriesError = (error: any) => {
    console.error(error)
    toast.error('Error fetching recommended categories')
    setLoadingAllCategories(false)
  }

  const [getAllSponsoredProductsTargetableCategories] = useLazyQuery(
    GetAllSponsoredProductsTargetableCategoriesDocument,
    {
      onCompleted: (data) => onGetAllSponsoredProductsTargetableCategoriesCompleted(data),
      onError: (error) => onGetAllSponsoredProductsTargetableCategoriesError(error),
    }
  )

  useEffect(() => {
    if (selectedCompany?.uuid && selectedProductAsins.length) {
      setLoadingRecommendedCategories(true)
      getSponsoredProductsTargetCategoryRecommendations({
        variables: { companyUuid: selectedCompany.uuid, marketplace, asins: selectedProductAsins },
      })
    } else {
      setRecommendedCategories([])
    }
  }, [
    getSponsoredProductsTargetCategoryRecommendations,
    selectedProductAsins,
    selectedCompany,
    marketplace,
  ])

  useEffect(() => {
    if (selectedCompany?.uuid) {
      setLoadingAllCategories(true)
      getAllSponsoredProductsTargetableCategories({
        variables: { companyUuid: selectedCompany.uuid, marketplace },
      })
    }
  }, [getAllSponsoredProductsTargetableCategories, selectedCompany, marketplace])

  if (loadingRecommendedCategories || loadingAllCategories) {
    return (
      <SelectContainer>
        <LoadingSpinner />
      </SelectContainer>
    )
  }

  return (
    <>
      <Form.Item name={'productTargets'} hidden>
        <Input></Input>
      </Form.Item>
      <FormWidget>
        <SelectContainer style={{ paddingBottom: 0 }}>
          <Menu
            onClick={handleSelectSubview}
            selectedKeys={[selectedTargetingSubtype]}
            mode="horizontal"
            items={[
              { label: 'Categories', key: TargetingSubType.CATEGORIES },
              { label: 'Products', key: TargetingSubType.PRODUCTS },
            ]}
          />
          {selectedTargetingSubtype === TargetingSubType.PRODUCTS ? (
            <BulkAsinInput
              userDefinedAsinTargets={userDefinedTargets.filter(
                (target) => target.expressionType === TargetingExpressionPredicateType.ASINSAMEAS
              )}
              handleAddTargets={handleAddTargets}
            />
          ) : (
            <SelectSponsoredProductsCategories
              userDefinedCategoryTargets={userDefinedTargets.filter(
                (target) =>
                  target.expressionType === TargetingExpressionPredicateType.ASINCATEGORYSAMEAS
              )}
              handleAddTarget={handleAddTarget}
              recommendedCategories={recommendedCategories}
              categoryTree={categoryTree}
            />
          )}
        </SelectContainer>
        <SelectedItemsContainer>
          <ListTitle>
            <RequiredFieldIndicator>*</RequiredFieldIndicator> Categories and Products (
            {userDefinedTargets.length})
          </ListTitle>
          <UserDefinedTargetList
            userDefinedTargets={userDefinedTargets}
            handleRemoveTarget={handleRemoveTarget}
            handleUpdateTarget={handleUpdateTarget}
          />
        </SelectedItemsContainer>
      </FormWidget>
    </>
  )
}

export default CreateSponsoredProductsManualTargetsFormFields
