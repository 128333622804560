import { Suspense } from 'react'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import { BrandManagementContainer } from './BrandManagementContainer'

export const BrandManagement = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <BrandManagementContainer />
    </Suspense>
  )
}
