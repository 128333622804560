import { AutoComplete, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { TicketMainCategory } from './TicketsCollapse'

type AutocompleteOptions = {
  label: string
  options: OptionsItem[]
}
type OptionsItem = {
  value: any
  label: string
  key: string
}

const CategorySearch = () => {
  const { t, ready } = useTranslation(['ticket'])

  const navigate = useNavigate()
  const ticketMainCategories: TicketMainCategory[] = t('ticketMainCategories', {
    returnObjects: true,
  })

  if (!ready) {
    return <LoadingSpinner />
  }

  const renderOptions = (): AutocompleteOptions[] => {
    return ticketMainCategories.flatMap((ticketMainCategory, mainIndex) => {
      return ticketMainCategory.subCategories.flatMap((subcategory, subIndex) => {
        return {
          label: `${ticketMainCategory.categoryTitle} - ${subcategory.subCategoryTitle}`,
          options: subcategory.subCategoryTopics.flatMap((topic, topicIndex) => {
            return {
              key: `${mainIndex}${subIndex}${topicIndex}`,
              value: [ticketMainCategory.categoryTitleTag, topic.url],
              label: topic.title,
            }
          }),
        }
      })
    })
  }

  const handleFilter = (inputValue: string, option: any) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase())
  }

  const handleSelect = (value: string) => {
    navigate(value[1], { state: { categoryTitleTag: value[0] } })
  }

  return (
    <AutoComplete
      options={renderOptions()}
      onSelect={handleSelect}
      defaultActiveFirstOption
      filterOption={handleFilter}
      notFoundContent={t('searchBar.notFoundContent')}
      style={{ width: '100%' }}
    >
      <Input.Search size="large" placeholder={t('searchBar.placeholder')} />
    </AutoComplete>
  )
}

export default CategorySearch
