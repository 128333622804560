import { useMutation } from '@apollo/client'
import { Button, Form, InputNumber, Modal, Popconfirm, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { toast } from 'sonner'
import {
  AmazonPpcCampaignType,
  Marketplace,
  UpdateSponsoredProductsKeywordInput,
  UpdateSponsoredProductsKeywordsDocument,
  UpdateSponsoredProductsKeywordsMutation,
} from '../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { useCurrencySymbol } from '../../../../../views/ppc/helpers/useCurrencySymbol'
import PPCStateFormFields from '../../../PPCStateFormFields/PPCStateFormFields'
import { useDefaultMaximumBid } from '../../../hooks/useDefaultMaximumBid'
import { useDefaultMinimumBid } from '../../../hooks/useDefaultMinimumBid'
type UpdateSponsoredProductsKeywordModalProps = {
  campaignId: string
  keywordId: string
  bidIn: number
  stateIn: string
  updateKeywordList: Function
  marketplace: Marketplace
}

const UpdateSponsoredProductsKeywordModal = ({
  campaignId,
  keywordId,
  bidIn,
  stateIn,
  updateKeywordList,
  marketplace,
}: UpdateSponsoredProductsKeywordModalProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const currencySymbol = useCurrencySymbol()
  const defaultMinimumBid = useDefaultMinimumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const defaultMaximumBid = useDefaultMaximumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const [updateSponsoredProductsKeywords, { loading: loadingUpdate }] =
    useMutation<UpdateSponsoredProductsKeywordsMutation>(UpdateSponsoredProductsKeywordsDocument)

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({ bid: bidIn, state: stateIn })
    }
  }, [modalVisible, form, bidIn, stateIn])

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleOnFinish = async (values: any) => {
    const keywordToUpdate: UpdateSponsoredProductsKeywordInput = {
      keywordId: keywordId,
      state: values.state,
      bid: values.bid,
    }

    const keywords = [keywordToUpdate]

    updateSponsoredProductsKeywords({
      variables: {
        companyUuid: selectedCompany?.uuid,
        campaignId,
        marketplace,
        keywords: keywords,
      },
    })
      .then(({ data }) => {
        const updatedKeywords = data?.updateSponsoredProductsKeywords
        if (!updatedKeywords) {
          throw new Error('No keyword was updated')
        }
        toast.success('Keyword updated.')
        updatedKeywords.forEach((keyword) => {
          updateKeywordList(keyword)
        })
        setModalVisible(false)
      })
      .catch((e) => {
        console.error(e)
        if (e.networkError?.statusCode === 429) {
          toast.error("Amazon's servers are currently busy, please try again in a few minutes.")
        } else {
          toast.error('Error updating keyword.')
        }
      })
  }

  const handleFormValuesChange = () => {
    const formBid = form.getFieldValue('bid')
    const formState = form.getFieldValue('state')
    setSaveButtonDisabled(formState == stateIn && formBid == bidIn)
  }

  return (
    <>
      <Button key="huihif" onClick={showModal} data-testid="edit-keyword-btn">
        Edit
      </Button>

      <div
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}
        key="u90u90u"
      >
        <Modal
          title="Keyword Settings"
          open={modalVisible}
          onCancel={handleCancel}
          footer={
            <Space>
              <Button onClick={handleCancel} key="cancel">
                Cancel
              </Button>
              <Popconfirm
                key="u8f8f"
                placement="top"
                title={'Please confirm these changes'}
                onConfirm={form.submit}
                okText="Confirm"
                cancelText="Go back"
                disabled={saveButtonDisabled}
              >
                <Button
                  key="savel"
                  disabled={saveButtonDisabled}
                  type={'primary'}
                  loading={loadingUpdate}
                >
                  Save
                </Button>
              </Popconfirm>
            </Space>
          }
        >
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            initialValues={{ bid: bidIn, state: stateIn }}
            onFinish={handleOnFinish}
            onValuesChange={handleFormValuesChange}
          >
            <PPCStateFormFields />
            <Form.Item label={`Bid (${currencySymbol})`} name="bid">
              <InputNumber
                precision={2}
                step={0.01}
                min={defaultMinimumBid}
                max={defaultMaximumBid}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default UpdateSponsoredProductsKeywordModal
