import { useMutation } from '@apollo/client'
import { Button, Form, Modal, Popconfirm, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'sonner'
import {
  CreateSponsoredProductsAdGroupDocument,
  CreateSponsoredProductsAdGroupInput,
  Marketplace,
} from '../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import CreateSponsoredProductsAdGroupFormFields from '../create/CreateSponsoredProductsAdGroupFormFields'
type CreateSponsoredProductsAdGroupModal = {
  campaignId: string
  disabled: boolean
  onAdGroupAdded: Function
}

const CreateSponsoredProductsAdGroupModal = ({
  campaignId,
  disabled,
  onAdGroupAdded,
}: CreateSponsoredProductsAdGroupModal) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [loadingCreateAdGroup, setLoadingCreateAdGroup] = useState<boolean>(false)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const [createSponsoredProductsAdGroup] = useMutation(CreateSponsoredProductsAdGroupDocument)
  const params = useParams<{ marketplace: string }>()
  const marketplace: Marketplace = params.marketplace?.toUpperCase()! as Marketplace
  const [modalKey, setModalKey] = useState(Math.floor(Math.random() * 99999999).toString())
  const sellerId = selectedCompany?.sellerId ? selectedCompany?.sellerId : ''
  const adGroupNamePrefix = marketplace + '-' + sellerId + '-'

  useEffect(() => {
    setModalKey(Math.floor(Math.random() * 99999999).toString())
  }, [modalVisible])

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleCreateAdGroup = async (adGroup: CreateSponsoredProductsAdGroupInput) => {
    const request = await createSponsoredProductsAdGroup({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: marketplace,
        adGroup: adGroup,
      },
    })

    return request.data?.createSponsoredProductsAdGroup
  }

  const handleOnFinish = async (values: { adGroup: { name: string } }) => {
    setLoadingCreateAdGroup(true)

    const adGroupPayload: CreateSponsoredProductsAdGroupInput = {
      name: adGroupNamePrefix + values.adGroup.name,
      campaignId: campaignId,
    }

    try {
      const createdAdGroup = await handleCreateAdGroup(adGroupPayload)

      toast.success('Ad Group successfully created')

      onAdGroupAdded(createdAdGroup)
    } catch (error: any) {
      console.error(error)
      toast.error('Error creating Ad Group', { description: error.message })
    } finally {
      setLoadingCreateAdGroup(false)
      setModalVisible(false)
    }
  }

  return (
    <>
      <Button key="209783" onClick={showModal} disabled={disabled} data-testid="add-product-btn">
        Add Ad Group
      </Button>

      <Modal
        title="Create new Ad Group"
        open={modalVisible}
        onCancel={handleCancel}
        width={500}
        key={modalKey}
        footer={
          <Space>
            <Button onClick={handleCancel} key="cancel">
              Cancel
            </Button>
            <Popconfirm
              key="u8f8f"
              placement="top"
              title={
                'New Ad Group will be added immediately on Amazon. Please confirm that you would like to continue.'
              }
              onConfirm={form.submit}
              okText="Continue"
              cancelText="Go back"
              disabled={loadingCreateAdGroup}
              overlayInnerStyle={{ maxWidth: '400px' }}
            >
              <Button key="savel" disabled={loadingCreateAdGroup} type={'primary'}>
                Save
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <Form form={form} onFinish={handleOnFinish}>
          <CreateSponsoredProductsAdGroupFormFields adGroupNamePrefix={adGroupNamePrefix} />
        </Form>
      </Modal>
    </>
  )
}

export default CreateSponsoredProductsAdGroupModal
