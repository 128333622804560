import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client'
import { Alert, Upload as AntUpload, Button, Flex, List, Modal, Result, Space, Tag } from 'antd'
import { RcFile } from 'antd/es/upload'
import { Upload } from 'lucide-react'
import Papa from 'papaparse'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import * as XLSX from 'xlsx'
import { z } from 'zod'
import {
  CreateProductVariantPackagingUnitsDocument,
  GetAllProductsDocument,
  GetAllProductVariantGtinsDocument,
  ProductVariantPackagingUnitType,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'

const BATCH_SIZE = 10

type PackagingUnitImportData = {
  productVariantGtin: string
  packagingUnit: {
    type: ProductVariantPackagingUnitType
    quantity: number
    length: number
    width: number
    height: number
    weight: number
  }
}

type ValidationError = {
  rowIndex: number | undefined
  field: string | undefined
  message: string
}

export const PackagingUnitsImportModal = () => {
  const [open, setOpen] = useState(false)
  const [importedPackagingUnits, setImportedPackagingUnits] = useState<PackagingUnitImportData[]>(
    []
  )
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [submitting, setSubmitting] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const client = useApolloClient()
  const { t } = useTranslation('inventory')

  const [getProductVariantGtins] = useLazyQuery(GetAllProductVariantGtinsDocument)
  const [createPackagingUnits] = useMutation(CreateProductVariantPackagingUnitsDocument)

  const packagingUnitsImportRowSchema = usePackagingUnitsImportRowSchema()

  const handleTemplateDownload = async () => {
    try {
      const { data } = await getProductVariantGtins({
        variables: {
          companyUuid: selectedCompany?.uuid,
        },
      })

      const gtins = data
        ? data.productVariants
            .map((productVariant) => productVariant.ean)
            .filter(Boolean)
            .sort()
        : []

      const headers = [
        'Product variant GTIN',
        'Type',
        'Quantity in package',
        'Package length (cm)',
        'Package width (cm)',
        'Package height (cm)',
        'Package weight (g)',
      ]

      const rows = gtins.map((gtin) => [gtin, '', '', '', '', '', ''])

      const wb = XLSX.utils.book_new()

      const ws = XLSX.utils.aoa_to_sheet([headers, ...rows])
      XLSX.utils.book_append_sheet(wb, ws, 'Template')

      const referenceWs = XLSX.utils.aoa_to_sheet([['Valid Types'], ['SMALL_PARCEL'], ['PALLET']])
      XLSX.utils.book_append_sheet(wb, referenceWs, 'Reference')

      XLSX.writeFile(wb, 'packaging-units-import-template.xlsx')
    } catch (error) {
      console.error(error)
      toast.error(t('packagingUnit.bulk.import.template.error.toast.message'))
    }
  }

  const handleUpload = (file: RcFile) => {
    const fileReader = new FileReader()

    fileReader.onload = (event) => {
      try {
        const file = event.target?.result

        if (typeof file !== 'string') {
          throw new Error('File could not be read.')
        }

        Papa.parse<z.infer<typeof packagingUnitsImportRowSchema>>(file, {
          header: true,
          skipEmptyLines: 'greedy',
          transform: (value, field) => {
            const trimmedValue = value.trim()

            if (trimmedValue === '') {
              return undefined
            }

            if (typeof field === 'string') {
              if (
                [
                  'Quantity in package',
                  'Package length (cm)',
                  'Package width (cm)',
                  'Package height (cm)',
                  'Package weight (g)',
                ].includes(field)
              ) {
                if (/^-?\d+\.\d+$/.test(trimmedValue)) {
                  return Number(trimmedValue)
                }

                if (/^-?\d+,\d+$/.test(trimmedValue)) {
                  return Number(trimmedValue.replace(',', '.'))
                }

                const hasEnglishFormat = /^\d{1,3}(?:,\d{3})*(?:\.\d+)?$/.test(trimmedValue)
                const hasGermanFormat = /^\d{1,3}(?:\.\d{3})*(?:,\d+)?$/.test(trimmedValue)

                if (hasEnglishFormat) {
                  return Number(trimmedValue.replace(/,/g, ''))
                }

                if (hasGermanFormat) {
                  return Number(trimmedValue.replace(/\./g, '').replace(',', '.'))
                }

                return Number(trimmedValue)
              }
            }

            return trimmedValue
          },
          complete: (results) => {
            const data = results.data

            const filteredData = data.filter((row) => {
              return !!row['Quantity in package' as keyof typeof row]
            })
            const parsedRows = packagingUnitsImportRowSchema.parse(filteredData)

            const formattedRows: PackagingUnitImportData[] = parsedRows.map((row) => ({
              productVariantGtin: row['Product variant GTIN'],
              packagingUnit: {
                type: row['Type'],
                quantity: row['Quantity in package'],
                length: row['Package length (cm)'],
                width: row['Package width (cm)'],
                height: row['Package height (cm)'],
                weight: row['Package weight (g)'],
              },
            }))

            setImportedPackagingUnits(formattedRows)
            setValidationErrors([])
          },
        })
      } catch (error) {
        console.error(error)
        toast.error(t('product.bulk.import.error.toast.message'))

        if (error instanceof z.ZodError) {
          setValidationErrors(formatZodError(error))
          setImportedPackagingUnits([])
        }
      }
    }

    fileReader.readAsText(file)

    return false
  }

  const handleImport = async () => {
    try {
      setSubmitting(true)
      const batches: PackagingUnitImportData[][] = []

      for (let i = 0; i < importedPackagingUnits.length; i += BATCH_SIZE) {
        batches.push(importedPackagingUnits.slice(i, i + BATCH_SIZE))
      }

      for (const batch of batches) {
        await createPackagingUnits({
          variables: {
            input: batch.map((packagingUnit) => ({
              productVariantGtin: packagingUnit.productVariantGtin,
              packagingUnit: {
                type: packagingUnit.packagingUnit.type,
                quantity: packagingUnit.packagingUnit.quantity,
                lengthInCm: packagingUnit.packagingUnit.length,
                widthInCm: packagingUnit.packagingUnit.width,
                heightInCm: packagingUnit.packagingUnit.height,
                weightInGram: packagingUnit.packagingUnit.weight,
              },
            })),
          },
        })
      }

      await client.refetchQueries({
        include: [GetAllProductsDocument],
      })

      toast.success(
        t('packagingUnit.bulk.create.success.toast.message', {
          packagingUnitCount: importedPackagingUnits.length,
        })
      )
      setOpen(false)
      setImportedPackagingUnits([])
      setValidationErrors([])
    } catch (error) {
      console.error(error)
      toast.error(t('packagingUnit.bulk.create.error.toast.message'))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        {t('packagingUnit.button.bulkAdd')}
      </Button>
      <Modal
        title={
          <Space>
            {t('packagingUnit.bulk.heading')}
            <Tag color="yellow" style={{ margin: 0 }}>
              Beta
            </Tag>
          </Space>
        }
        open={open}
        okText={t('shared.button.import', { ns: 'translation' })}
        okButtonProps={{
          disabled: !importedPackagingUnits.length || !!validationErrors.length,
          loading: submitting,
        }}
        onOk={handleImport}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        destroyOnClose
      >
        <Flex vertical gap={16}>
          <Result
            title={t('packagingUnit.bulk.title')}
            icon={<Upload size={16} />}
            extra={
              <Space direction="vertical">
                <AntUpload.Dragger
                  accept=".csv"
                  beforeUpload={handleUpload}
                  customRequest={customRequest}
                  maxCount={1}
                  showUploadList={false}
                >
                  {t('packagingUnit.bulk.import.hint')}
                </AntUpload.Dragger>
                <Button type="link" onClick={handleTemplateDownload}>
                  {t('packagingUnit.bulk.import.template.download')}
                </Button>
              </Space>
            }
          />
          <Alert message={t('packagingUnit.bulk.import.template.description')} />
          {validationErrors.length ? (
            <Alert
              type="error"
              message={t('packagingUnit.bulk.import.error.alert.message', {
                count: validationErrors.length,
              })}
              description={
                <List
                  dataSource={validationErrors}
                  renderItem={(item) => (
                    <List.Item style={{ paddingLeft: 0 }}>
                      <List.Item.Meta
                        title={`Row ${item.rowIndex}: ${item.field}`}
                        description={item.message}
                      />
                    </List.Item>
                  )}
                  size="small"
                  split={false}
                  style={{ maxHeight: '320px', overflow: 'auto' }}
                />
              }
              showIcon
            />
          ) : importedPackagingUnits.length ? (
            <Alert
              type="success"
              message={t('packagingUnit.bulk.import.success.alert.message')}
              description={t('packagingUnit.bulk.import.success.alert.description', {
                packagingUnitCount: importedPackagingUnits.length,
              })}
              showIcon
            />
          ) : null}
        </Flex>
      </Modal>
    </>
  )
}

function usePackagingUnitsImportRowSchema() {
  return z
    .array(
      z.object({
        'Product variant GTIN': z.string(),
        Type: z.nativeEnum(ProductVariantPackagingUnitType),
        'Quantity in package': z.coerce.number(),
        'Package length (cm)': z.coerce.number(),
        'Package width (cm)': z.coerce.number(),
        'Package height (cm)': z.coerce.number(),
        'Package weight (g)': z.coerce.number(),
      })
    )
    .nonempty({
      message: 'File must contain at least one row.',
    })
    .superRefine((data, ctx) => {
      // Check for duplicate product variant GTIN + quantity combinations.
      const packagingUnitCombos = new Set<string>()

      for (const [index, row] of data.entries()) {
        const productVariantGtin = row['Product variant GTIN']
        const quantity = row['Quantity in package']
        const combo = `${productVariantGtin}__${quantity}`

        if (packagingUnitCombos.has(combo)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Duplicate product variant GTIN and quantity combination found: "${productVariantGtin}" with quantity "${quantity}"`,
            path: [index, 'Product variant GTIN'],
          })
        }

        packagingUnitCombos.add(combo)
      }
    })
}

function formatZodError(error?: z.ZodError) {
  if (!error) {
    return []
  }

  const issues = error.issues

  return issues.map((issue) => {
    const rowIndex = issue.path[0] !== undefined ? +issue.path[0] + 1 : undefined
    const field = issue.path[1] !== undefined ? issue.path[1].toString() : undefined

    return {
      rowIndex,
      field,
      message: issue.message,
    }
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customRequest = ({ onSuccess }: any) => {
  setTimeout(() => onSuccess('ok'), 0)
}
