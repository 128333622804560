import { Empty, Flex, Result, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { StyledMainCard } from '../../../dashboard/styles'
import { AddWarehouseServiceProviderLocationModal } from './AddWarehouseServiceProviderLocationModal'
import { DeleteWarehouseServiceProviderNameModal } from './DeleteWarehouseServiceProviderNameModal'
import { EditWarehouseServiceProviderNameModal } from './EditWarehouseServiceProviderNameModal'
import { WarehouseServiceProviderLocationCard } from './WarehouseServiceProviderLocationCard'
import { WarehouseServiceProvider } from './WarehousingContainer'

type WarehouseServiceProviderCardProps = {
  warehouseServiceProvider: WarehouseServiceProvider
}

export const WarehouseServiceProviderCard = ({
  warehouseServiceProvider,
}: WarehouseServiceProviderCardProps) => {
  const { t } = useTranslation('warehousing')

  return (
    <StyledMainCard
      title={warehouseServiceProvider.name}
      extra={
        <Space>
          <EditWarehouseServiceProviderNameModal
            warehouseServiceProvider={warehouseServiceProvider}
          />
          <DeleteWarehouseServiceProviderNameModal
            warehouseServiceProvider={warehouseServiceProvider}
          />
        </Space>
      }
      style={{ height: 'auto' }}
    >
      <Flex vertical gap={16}>
        {warehouseServiceProvider.locations?.length ? (
          <>
            {warehouseServiceProvider.locations.map((location) => (
              <WarehouseServiceProviderLocationCard key={location.uuid} location={location} />
            ))}
            <Flex justify="end">
              <AddWarehouseServiceProviderLocationModal
                warehouseServiceProviderUuid={warehouseServiceProvider.uuid}
              />
            </Flex>
          </>
        ) : (
          <Result
            icon={Empty.PRESENTED_IMAGE_SIMPLE}
            subTitle={t('location.empty')}
            extra={
              <AddWarehouseServiceProviderLocationModal
                warehouseServiceProviderUuid={warehouseServiceProvider.uuid}
              />
            }
          />
        )}
      </Flex>
    </StyledMainCard>
  )
}
