import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { Auth } from 'aws-amplify'
import { User } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'

type ForgottenPasswordStep1FormInstance = {
  email: string
}

export const ForgotPasswordStep1View = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation('login', { keyPrefix: 'forgotPassword.step1' })
  const navigate = useNavigate()

  const handleSubmit = async (values: ForgottenPasswordStep1FormInstance) => {
    setLoading(true)
    const sanitizedEmail = values.email.toLowerCase().trim()
    try {
      await Auth.forgotPassword(sanitizedEmail)
      setTimeout(() => navigate(`/login/forgotten-password/step-2?email=${sanitizedEmail}`), 1000)
    } catch (error) {
      console.error(error)
      toast.error(t('form.error.message'), { description: t('form.error.description') })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Row justify="center">
        <Col flex="0 1 480px">
          <Typography.Title level={3}>{t('heading')}</Typography.Title>
          <Typography.Paragraph>{t('description')}</Typography.Paragraph>
          <Form<ForgottenPasswordStep1FormInstance>
            name="forgotten-password-step-1"
            onFinish={handleSubmit}
            size="large"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: t('form.email.validation.required') }]}
            >
              <Input
                aria-label="email-input"
                autoFocus
                prefix={<User size={16} />}
                placeholder={t('form.email.placeholder')}
              />
            </Form.Item>
            <Form.Item>
              <Row justify="end">
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    aria-label="submit-button"
                    loading={loading}
                  >
                    {t('form.submit')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}
