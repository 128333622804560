import { Suspense } from 'react'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import { UserSettingsForm } from './UserSettingsForm'

export const UserSettings = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <UserSettingsForm />
    </Suspense>
  )
}
