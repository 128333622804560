import { Reference, useMutation } from '@apollo/client'
import { Typography } from 'antd'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  S3DocumentListCard,
  S3DocumentListCardPropsData,
} from '../../../../components/S3/S3DocumentListCard'
import { DeleteAmazonInboundShipmentPlanDocumentDocument } from '../../../../generated/graphql'
import { AmazonInboundShipmentPlan } from '../AmazonInboundShipmentPlanView'
import { AmazonInboundShipmentPlanDocumentUploadModal } from './AmazonInboundShipmentPlanDocumentUploadModal'

const bucketName = import.meta.env.VITE_AWS_S3_BUCKET_NAME_SHIPMENT_PLAN_DOCUMENTS

type AmazonInboundShipmentPlanDocumentsCardProps = {
  amazonInboundShipmentPlan: AmazonInboundShipmentPlan
  loading: boolean
}

export const AmazonInboundShipmentPlanDocumentsCard = ({
  amazonInboundShipmentPlan,
  loading,
}: AmazonInboundShipmentPlanDocumentsCardProps) => {
  const { t } = useTranslation('inventory')

  const [deleteAmazonInboundShipmentPlanDocument] = useMutation(
    DeleteAmazonInboundShipmentPlanDocumentDocument
  )

  const handleDeleteDocument = async (bucketKey: string) => {
    try {
      await Storage.remove(bucketKey, {
        bucket: bucketName,
      })

      await deleteAmazonInboundShipmentPlanDocument({
        variables: {
          amazonInboundShipmentPlanUuid: amazonInboundShipmentPlan.uuid,
          key: bucketKey,
        },
        update: (cache, { data }) => {
          const deleteAmazonInboundShipmentPlanDocument =
            data?.deleteAmazonInboundShipmentPlanDocument
          if (deleteAmazonInboundShipmentPlanDocument) {
            cache.modify({
              id: cache.identify({
                __typename: 'AmazonInboundShipmentPlan',
                uuid: amazonInboundShipmentPlan.uuid,
              }),
              fields: {
                documents(existingDocumentsRefs, { readField }) {
                  return existingDocumentsRefs.filter(
                    (documentRef: Reference) =>
                      deleteAmazonInboundShipmentPlanDocument.key !== readField('key', documentRef)
                  )
                },
              },
            })
          }
        },
      })

      toast.success(t('amazonInboundShipmentPlan.documents.delete.success'))
    } catch (error: unknown) {
      toast.error(t('amazonInboundShipmentPlan.documents.delete.error'))
      console.error(error)
    }
  }

  const documents: S3DocumentListCardPropsData =
    amazonInboundShipmentPlan?.documents?.map((document) => ({
      key: document.key,
      description: (
        <Typography.Text type={'secondary'}>
          {dayjs(document.created_at).format('DD.MM.YYYY')}
        </Typography.Text>
      ),
    })) || []

  return (
    <S3DocumentListCard
      bucketName={bucketName}
      title={t('amazonInboundShipmentPlan.documents.title')}
      rightHeaderContent={
        amazonInboundShipmentPlan && (
          <AmazonInboundShipmentPlanDocumentUploadModal
            amazonInboundShipmentPlan={amazonInboundShipmentPlan}
          />
        )
      }
      data={documents}
      loading={loading}
      showPreview
      showViewButton
      showDownloadButton
      deleteFunction={handleDeleteDocument}
      customGetFilenameFromKey={getFilenameFromKey}
    />
  )
}

function getFilenameFromKey(key: string) {
  const indexOfSecondUnderscore = key.indexOf('_', key.indexOf('_') + 1)

  if (indexOfSecondUnderscore !== -1) {
    return key.substring(indexOfSecondUnderscore + 1)
  }

  return key
}
