import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import { Suspense, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { formatMoney } from '../../../../helpers/formatMoney'
import { preparePaymentsAdjustmentRecords } from '../helpers'
import { PaymentsQueryAdjustment } from '../PaymentsView'

export type PaymentsAdjustmentTableRecord = {
  name: string
  asin: string
  sku: string
  date: string
  quantity: number
  amount: number
  type: string
  purchasePrice: number
  remunerationAmount: number
}

type PaymentsAdjustmentTableProps = {
  adjustments: PaymentsQueryAdjustment[]
}

export const PaymentsAdjustmentTable = ({ adjustments }: PaymentsAdjustmentTableProps) => {
  const rowData = preparePaymentsAdjustmentRecords(adjustments)

  const { t } = useTranslation(['billing'], { keyPrefix: 'payments.details.adjustments' })

  const [columnDefs] = useState<
    (ColDef<PaymentsAdjustmentTableRecord> | ColGroupDef<PaymentsAdjustmentTableRecord>)[]
  >([
    {
      field: 'name',
      headerName: t('table.name'),
    },
    {
      field: 'asin',
      headerName: t('table.asin'),
    },
    {
      field: 'sku',
      headerName: t('table.sku'),
    },
    {
      field: 'date',
      headerName: t('table.date'),
      initialSort: 'desc',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsAdjustmentTableRecord,
          PaymentsAdjustmentTableRecord['date']
        >
      ) => {
        return dayjs(params.value).format('DD.MM.YYYY HH:mm:ss')
      },
    },
    {
      field: 'type',
      headerName: t('table.type'),
      filter: 'agSetColumnFilter',
    },
    {
      field: 'quantity',
      headerName: t('table.quantity'),
    },
    {
      field: 'amount',
      headerName: t('table.amount'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsAdjustmentTableRecord,
          PaymentsAdjustmentTableRecord['amount']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'purchasePrice',
      headerName: t('table.purchasePrice'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsAdjustmentTableRecord,
          PaymentsAdjustmentTableRecord['purchasePrice']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'remunerationAmount',
      headerName: t('table.remunerationAmount'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsAdjustmentTableRecord,
          PaymentsAdjustmentTableRecord['remunerationAmount']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
  ])

  const defaultColDef: ColDef<PaymentsAdjustmentTableRecord> = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<PaymentsAdjustmentTableRecord>) => {
        if (params.value === null || params.value === undefined || params.value === '') {
          return t('shared.notAvailable', { ns: 'translation' })
        } else {
          return params.value
        }
      },
    }),
    []
  )

  return (
    <Suspense>
      {!rowData?.length ? (
        <Typography.Text type="secondary">{t('empty')}</Typography.Text>
      ) : (
        <Table<PaymentsAdjustmentTableRecord>
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          suppressContextMenu
        />
      )}
    </Suspense>
  )
}
