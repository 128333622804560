import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Skeleton } from 'antd'
import dayjs from 'dayjs'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { GetPerformanceMetricsQuery } from '../../../../generated/graphql'
import { StyledMainCard } from '../../styles'
import { calculateAmazonProfit } from '../helpers/calculateAmazonProfit'
import { calculateExpenses } from '../helpers/calculateExpenses'
import { DashboardFinanceChartTooltip } from './DashboardFinanceChartTooltip'

export type DashboardFinanceChartData = {
  date: string
  formattedDate: string
  unitsSold: number
  sales: number
  refunds: number
  fees: number
  promotions: number
  sponsoredProductCosts: number
  sponsoredDisplayCosts: number
  amazonGrossProfit: number
  expenses: number
  expensesReversed: number
}

type DashboardFinanceChartProps = {
  data: GetPerformanceMetricsQuery
  loading: boolean
}

export const DashboardFinanceChart = ({ data, loading }: DashboardFinanceChartProps) => {
  const theme = useTheme()

  const dataSource = prepareFinanceChartData(data)

  return (
    <StyledMainCard>
      <FinanceChartSkeleton title={false} paragraph={{ rows: 30 }} loading={loading}>
        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart data={dataSource} margin={{ top: 10, right: 10, bottom: 10, left: 20 }}>
            <Tooltip
              content={<DashboardFinanceChartTooltip />}
              wrapperStyle={{ outline: 'none' }}
            />
            <CartesianGrid
              stroke={theme.colors.primary}
              strokeDasharray="8"
              strokeWidth={0.5}
              opacity={0.2}
            />
            <Bar dataKey="expensesReversed" stackId="main" fill={theme.colors.error} />
            <Bar dataKey="expenses" stackId="main" fill={theme.colors.error} />
            <Bar dataKey="sales" stackId="main" fill={theme.colors.primary} />
            <Line
              type="monotone"
              dataKey="amazonGrossProfit"
              stroke={theme.colors.text}
              strokeWidth={2}
            />
            <XAxis dataKey="date" tickMargin={4} stroke={theme.colors.text} opacity={0.6} />
            <YAxis
              domain={['dataMin - 50', 'dataMax + 100']}
              scale="linear"
              width={30}
              allowDecimals={false}
              tickMargin={4}
              stroke={theme.colors.text}
              opacity={0.6}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </FinanceChartSkeleton>
    </StyledMainCard>
  )
}

function prepareFinanceChartData(
  data: GetPerformanceMetricsQuery | undefined
): DashboardFinanceChartData[] {
  if (!data) {
    return []
  }

  return data.performanceMetrics.flatMap((metric) => {
    if (!metric) {
      return []
    }

    const date = dayjs(metric.date).format('DD.MM')
    const tooltipDate = dayjs(metric.date).format('DD.MM.YYYY')
    const sales = metric.sales ?? 0
    const unitsSold = metric.unitsSold ?? 0
    const refunds = metric.refunds ?? 0
    const fees = metric.fees ?? 0
    const promotions = metric.promotions ?? 0
    const sponsoredProductsCosts = metric.sponsoredProductsCosts ?? 0
    const sponsoredDisplayCosts = metric.sponsoredDisplayCosts ?? 0

    const amazonProfit = calculateAmazonProfit({
      sales,
      refunds,
      fees,
      promotions,
      sponsoredProductsCosts,
      sponsoredDisplayCosts,
    })
    const expenses = calculateExpenses({
      refunds,
      fees,
      promotions,
      sponsoredProductsCosts,
      sponsoredDisplayCosts,
    })
    const expensesReversed = expenses < 0 ? Math.abs(2 * expenses) : -1 * expenses

    return {
      date,
      formattedDate: tooltipDate,
      unitsSold: unitsSold,
      sales: sales,
      refunds: refunds,
      fees: fees,
      promotions: promotions,
      sponsoredProductCosts: sponsoredProductsCosts ? sponsoredProductsCosts * -1 : 0,
      sponsoredDisplayCosts: sponsoredDisplayCosts ? sponsoredDisplayCosts * -1 : 0,
      amazonGrossProfit: amazonProfit,
      expenses: expenses,
      expensesReversed: expensesReversed,
    }
  })
}

const FinanceChartSkeleton = styled(Skeleton)`
  ul {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0;
  }

  li {
    width: 1% !important;
    margin: 0 !important;
  }

  li:nth-of-type(even) {
    height: 400px !important;
  }

  li:nth-of-type(odd) {
    height: 300px !important;
  }
`
