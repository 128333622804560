import { useMutation } from '@apollo/client'
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import { BaseType } from 'antd/es/typography/Base'
import dayjs, { Dayjs } from 'dayjs'
import { SquarePen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  AmazonListingChangeRequestStatus,
  AmazonListingChangeRequestType,
  AmazonListingChangeRequestValue,
  AmazonSalePrice,
  ExternalAmazonListingStatus,
  GetAmazonListingDocument,
  GetAmazonListingQuery,
  InternalAmazonListingStatus,
  RequestAmazonListingChangeDocument,
} from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { roundNumber } from '../../../../helpers/roundNumber'

const { RangePicker } = DatePicker

type EditAmazonListingSalePriceFormInstance = {
  rrp: number
  salePrice: number
  salePeriod: [Dayjs, Dayjs]
}

type EditAmazonListingSalePriceModalProps = {
  amazonListing: {
    uuid: string
    recommendedRetailPrice: number | null
    currency: string
    internalStatus: InternalAmazonListingStatus | null
    externalStatus: ExternalAmazonListingStatus | null
    changeRequests: {
      type: AmazonListingChangeRequestType
      status: AmazonListingChangeRequestStatus
      newValues: AmazonListingChangeRequestValue
      updated_at: string
    }[]
    saleEndDate: string | null
  }
}

export const EditAmazonListingSalePriceModal = ({
  amazonListing,
}: EditAmazonListingSalePriceModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [difference, setDifference] = useState(0)

  const { t } = useTranslation('inventory')
  const [form] = Form.useForm<EditAmazonListingSalePriceFormInstance>()

  const [requestAmazonListingChange] = useMutation(RequestAmazonListingChangeDocument)

  const pendingChangeRequest = amazonListing.changeRequests?.find(
    (changeRequest) =>
      changeRequest?.type === AmazonListingChangeRequestType.SALE_PRICE &&
      changeRequest?.status === AmazonListingChangeRequestStatus.PENDING
  )

  const handleChange = (value: number | null) => {
    if (value) {
      const oldValue = amazonListing.recommendedRetailPrice

      if (!oldValue) {
        return
      }

      setDifference(roundNumber(((value - oldValue) / oldValue) * 100))
    } else {
      setDifference(-100)
    }
  }

  const handleSubmit = async (values: EditAmazonListingSalePriceFormInstance) => {
    setSubmitting(true)

    const newValues = {
      salePrice: values.salePrice,
      saleStartDate: values.salePeriod[0].format('YYYY-MM-DD'),
      saleEndDate: values.salePeriod[1].format('YYYY-MM-DD'),
    }

    try {
      if (!amazonListing.uuid) {
        throw new Error()
      }

      await requestAmazonListingChange({
        variables: {
          amazonListingUuid: amazonListing.uuid,
          input: {
            type: AmazonListingChangeRequestType.SALE_PRICE,
            newSalePrice: newValues,
          },
        },
        optimisticResponse: {
          requestAmazonListingChange: {
            uuid: 'optimistic',
            status: AmazonListingChangeRequestStatus.PENDING,
            type: AmazonListingChangeRequestType.SALE_PRICE,
            newValues,
            updated_at: new Date().toISOString(),
          },
        },
        update: (cache, { data }) => {
          if (!data) {
            return
          }

          const newChangeRequest = data.requestAmazonListingChange

          const cachedQuery = cache.readQuery<GetAmazonListingQuery>({
            query: GetAmazonListingDocument,
            variables: { uuid: amazonListing.uuid },
          })

          if (newChangeRequest && cachedQuery) {
            cache.writeQuery<GetAmazonListingQuery>({
              query: GetAmazonListingDocument,
              data: {
                amazonListing: {
                  ...cachedQuery.amazonListing,
                  changeRequests: [
                    ...(cachedQuery.amazonListing.changeRequests || []),
                    newChangeRequest,
                  ],
                },
              },
            })
          }
        },
      })

      toast.success(t('shared.notification.success', { ns: 'translation' }), {
        description: t('product.details.changeRequest.success'),
      })
    } catch (error) {
      console.error(error)
      toast.error(t('shared.notification.error', { ns: 'translation' }), {
        description: t('product.details.changeRequest.error'),
      })
    }

    setSubmitting(false)
    setOpen(false)
  }

  const handleOk = () => {
    form.submit()
  }

  const newValues = pendingChangeRequest?.newValues as AmazonSalePrice

  return (
    <>
      <Flex align="center" gap={8}>
        <Button
          type="text"
          icon={<SquarePen size={16} />}
          disabled={
            !!pendingChangeRequest ||
            amazonListing.internalStatus === InternalAmazonListingStatus.REVIEW ||
            amazonListing.internalStatus === InternalAmazonListingStatus.DELETED ||
            amazonListing.externalStatus === ExternalAmazonListingStatus.DETAIL_PAGE_REMOVED ||
            (!!amazonListing.saleEndDate &&
              dayjs(amazonListing.saleEndDate).endOf('day').isAfter(dayjs()))
          }
          onClick={() => setOpen(true)}
          size="small"
        />
        {pendingChangeRequest && (
          <Tooltip
            title={t('product.details.changeRequest.salePrice.tag.tooltip', {
              price: formatMoney(
                newValues?.salePrice ? +newValues.salePrice : 0,
                amazonListing.currency
              ),
              startDate: dayjs(newValues?.saleStartDate).format('DD.MM.YYYY'),
              endDate: dayjs(newValues?.saleEndDate).format('DD.MM.YYYY'),
              date: dayjs(pendingChangeRequest.updated_at).format('DD.MM.YYYY'),
            })}
            arrow
          >
            <Tag color="gold">{t('product.details.changeRequest.salePrice.tag.pending')}</Tag>
          </Tooltip>
        )}
      </Flex>
      <Modal
        title={t('product.details.changeRequest.salePrice.modal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={handleOk}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Flex vertical gap={8}>
          <Form<EditAmazonListingSalePriceFormInstance>
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
              rrp: amazonListing.recommendedRetailPrice,
              salePrice: amazonListing.recommendedRetailPrice,
            }}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Form.Item<EditAmazonListingSalePriceFormInstance>
                  name="rrp"
                  label={t('product.table.rrp')}
                >
                  <InputNumber
                    addonBefore={amazonListing.currency}
                    disabled
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item<EditAmazonListingSalePriceFormInstance>
                  name="salePrice"
                  label={
                    <Space>
                      <Typography.Text>
                        {t('product.details.changeRequest.salePrice.modal.form.salePrice.title')}
                      </Typography.Text>
                    </Space>
                  }
                  tooltip={t(
                    'product.details.changeRequest.salePrice.modal.form.salePrice.tooltip'
                  )}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'product.details.changeRequest.salePrice.modal.form.salePrice.validation.required'
                      ),
                    },
                    {
                      validator: (_rule, value) => {
                        if (value === null || value === undefined) {
                          return Promise.resolve()
                        } else if (value === 0) {
                          return Promise.reject(
                            t(
                              'product.details.changeRequest.salePrice.modal.form.salePrice.validation.zero'
                            )
                          )
                        } else if (difference >= 0) {
                          return Promise.reject(
                            t(
                              'product.details.changeRequest.salePrice.modal.form.salePrice.validation.tooHigh'
                            )
                          )
                        } else if (difference <= -90) {
                          return Promise.reject(
                            t(
                              'product.details.changeRequest.salePrice.modal.form.salePrice.validation.tooLow'
                            )
                          )
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    status={difference < -50 && difference > -90 ? 'warning' : undefined}
                    addonBefore={amazonListing.currency}
                    addonAfter={
                      <Typography.Text type={getPercentageColor(difference)}>
                        {difference > 0 ? '+' : null}
                        {difference.toFixed(2)}%
                      </Typography.Text>
                    }
                    min={0}
                    max={999999}
                    step={0.1}
                    precision={2}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item<EditAmazonListingSalePriceFormInstance>
                  name="salePeriod"
                  label={
                    <Space>
                      <Typography.Text>
                        {t('product.details.changeRequest.salePrice.modal.form.salePeriod.title')}
                      </Typography.Text>
                    </Space>
                  }
                  tooltip={t(
                    'product.details.changeRequest.salePrice.modal.form.salePeriod.tooltip'
                  )}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'product.details.changeRequest.salePrice.modal.form.salePeriod.validation.required'
                      ),
                    },
                    {
                      validator: (_rule, value) => {
                        if (!value) {
                          return Promise.resolve()
                        }
                        if (value[1].diff(value[0], 'day') < 1) {
                          return Promise.reject(
                            t(
                              'product.details.changeRequest.salePrice.modal.form.salePeriod.validation.tooShort'
                            )
                          )
                        }

                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <RangePicker
                    key="salePeriod"
                    picker="date"
                    format="DD MMM YYYY"
                    allowClear={false}
                    disabledDate={(date) => date < dayjs().subtract(1, 'day')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Alert type="warning" message={t('product.details.changeRequest.warning')} />
        </Flex>
      </Modal>
    </>
  )
}

function getPercentageColor(difference: number): BaseType {
  if (difference < -50 && difference > -90) {
    return 'warning'
  } else if (difference >= 0 || difference === -100 || difference <= -90) {
    return 'danger'
  } else {
    return 'success'
  }
}
