import { ColDef, ColGroupDef, GetDetailRowDataParams, ICellRendererParams } from 'ag-grid-community'
import { Typography } from 'antd'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components/FormattedDate'
import { Table } from '../../../../components/Table/Table'
import { Marketplace } from '../../../../generated/graphql'
import { AmazonInboundShipmentPlan } from '../AmazonInboundShipmentPlanView'

type AmazonInboundShipmentPlanContentTableRecord = {
  unitsTotal: number
  length: number
  width: number
  height: number
  grossWeight: number
  netWeight: number | null
  items: AmazonInboundShipmentPlanContentItem[]
}

type AmazonInboundShipmentPlanContentItem = {
  shipmentId: string | null
  sku: string
  unitsPerCarton: number
  quantityShipped: number
  quantityReceived: number
  labeledByAmazon: boolean
  bestBeforeDate: string | null
}

type AmazonInboundShipmentPlanContentTableProps = {
  amazonInboundShipmentPlan: AmazonInboundShipmentPlan
}

export const AmazonInboundShipmentPlanContentTable = ({
  amazonInboundShipmentPlan,
}: AmazonInboundShipmentPlanContentTableProps) => {
  const { t } = useTranslation('inventory')

  const rowData = amazonInboundShipmentPlan.cartons.map((carton) => {
    const items = carton.items.map((item) => {
      return {
        shipmentId: item.shipmentId ?? null,
        sku: item.amazonListing.sku,
        unitsPerCarton: item.unitsPerCarton,
        quantityShipped: item.quantityShipped,
        quantityReceived: item.quantityReceived ?? 0,
        labeledByAmazon: item.labeledByAmazon,
        bestBeforeDate: item.bestBeforeDate ?? null,
      }
    })

    return {
      unitsTotal: carton.unitsTotal,
      length: carton.length,
      width: carton.width,
      height: carton.height,
      grossWeight: carton.grossWeight,
      netWeight: carton.netWeight ?? null,
      items,
    }
  })

  const [columnDefs] = useState<
    (
      | ColDef<AmazonInboundShipmentPlanContentTableRecord>
      | ColGroupDef<AmazonInboundShipmentPlanContentTableRecord>
    )[]
  >([
    {
      lockPosition: 'left',
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      minWidth: 40,
      maxWidth: 40,
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      field: 'unitsTotal',
      headerName: t('amazonInboundShipmentPlan.carton.table.unitsTotal'),
    },
    {
      field: 'length',
      headerName: t('amazonInboundShipmentPlan.carton.table.lengthCarton'),
    },
    {
      field: 'width',
      headerName: t('amazonInboundShipmentPlan.carton.table.widthCarton'),
    },
    {
      field: 'height',
      headerName: t('amazonInboundShipmentPlan.carton.table.heightCarton'),
    },
    {
      field: 'grossWeight',
      headerName: t('amazonInboundShipmentPlan.carton.table.grossWeight'),
    },
    {
      field: 'netWeight',
      headerName: t('amazonInboundShipmentPlan.carton.table.netWeight'),
      hide: amazonInboundShipmentPlan.marketplace === Marketplace.UK,
    },
  ])

  const detailCellRendererParams = useMemo(() => {
    const columnDefs: (
      | ColDef<AmazonInboundShipmentPlanContentTableRecord['items'][number]>
      | ColGroupDef<AmazonInboundShipmentPlanContentTableRecord['items'][number]>
    )[] = [
      {
        field: 'shipmentId',
        headerName: 'Shipment ID',
      },
      {
        field: 'sku',
        headerName: 'SKU',
        initialSort: 'asc',
      },
      {
        field: 'unitsPerCarton',
        headerName: t('amazonInboundShipmentPlan.carton.table.unitsPerCarton'),
      },
      {
        field: 'quantityShipped',
        headerName: t('amazonInboundShipmentPlan.carton.table.quantityShipped'),
        cellRenderer: (
          params: ICellRendererParams<
            AmazonInboundShipmentPlanContentItem,
            AmazonInboundShipmentPlanContentItem['quantityShipped']
          >
        ) => {
          if (!params.value) {
            return <Typography.Text type="secondary">-</Typography.Text>
          }

          return <Typography.Text>{params.value}</Typography.Text>
        },
      },
      {
        field: 'quantityReceived',
        headerName: t('amazonInboundShipmentPlan.carton.table.quantityReceived'),
      },
      {
        field: 'labeledByAmazon',
        headerName: t('amazonInboundShipmentPlan.carton.table.labeledByAmazon'),
        cellRenderer: (
          params: ICellRendererParams<
            AmazonInboundShipmentPlanContentItem,
            AmazonInboundShipmentPlanContentItem['labeledByAmazon']
          >
        ) => {
          return (
            <Typography.Text>
              {params.value
                ? t('amazonInboundShipmentPlan.table.dropShipment.yes')
                : t('amazonInboundShipmentPlan.table.dropShipment.no')}
            </Typography.Text>
          )
        },
      },
      {
        field: 'bestBeforeDate',
        headerName: t('amazonInboundShipmentPlan.form.bestBeforeDate'),
        minWidth: 110,
        cellRenderer: (
          params: ICellRendererParams<
            AmazonInboundShipmentPlanContentItem,
            AmazonInboundShipmentPlanContentItem['bestBeforeDate']
          >
        ) => {
          return <FormattedDate date={params.value} format="YYYY-MM-DD" layout="horizontal" />
        },
      },
    ]

    const defaultColDef: ColDef<AmazonInboundShipmentPlanContentItem> = {
      filter: false,
    }

    return {
      detailGridOptions: {
        columnDefs,
        columnMenu: 'new',
        defaultColDef,
      },
      getDetailRowData: (
        params: GetDetailRowDataParams<AmazonInboundShipmentPlanContentTableRecord>
      ) => {
        params.successCallback(params.data?.items)
      },
    }
  }, [])

  const defaultColDef: ColDef<AmazonInboundShipmentPlanContentTableRecord> = useMemo(
    () => ({ filter: false }),
    []
  )

  return (
    <Table<AmazonInboundShipmentPlanContentTableRecord>
      fullHeight
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      detailCellRendererParams={detailCellRendererParams}
      masterDetail
      rowData={rowData}
    />
  )
}
