import { Col, Row } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import {
  GetAllWholesaleOrdersDetailsQuery,
  WholesaleOrderBillingDocumentType,
} from '../../../../../../generated/graphql'
import { CreateWholesaleOrderBillingDocumentFormValues } from './WholesaleOrderBillingDocumentCreateForm'
import { WholesaleOrderBillingDocumentBillingDocumentSelect } from './WholesaleOrderBillingDocumentCreateFormFields/WholesaleOrderBillingDocumentBillingDocumentSelect'
import { WholesaleOrderBillingDocumentDebtorCompanySelect } from './WholesaleOrderBillingDocumentCreateFormFields/WholesaleOrderBillingDocumentDebtorCompanySelect'
import { WholesaleOrderBillingDocumentDocumentNumberInput } from './WholesaleOrderBillingDocumentCreateFormFields/WholesaleOrderBillingDocumentDocumentNumberInput'
import { WholesaleOrderBillingDocumentDueDatePicker } from './WholesaleOrderBillingDocumentCreateFormFields/WholesaleOrderBillingDocumentDueDatePicker'
import { WholesaleOrderBillingDocumentIssueDatePicker } from './WholesaleOrderBillingDocumentCreateFormFields/WholesaleOrderBillingDocumentIssueDatePicker'
import { WholesaleOrderBillingDocumentTypeSelect } from './WholesaleOrderBillingDocumentCreateFormFields/WholesaleOrderBillingDocumentTypeSelect'
import { WholesaleOrderBillingDocumentUpload } from './WholesaleOrderBillingDocumentCreateFormFields/WholesaleOrderBillingDocumentUpload'
import { WholesaleOrderBillingDocumentExistingLineItemsTable } from './WholesaleOrderBillingDocumentLineItemsSelectorTable/WholesaleOrderBillingDocumentExistingLineItemsTable'
import { WholesaleOrderLineItemsTable } from './WholesaleOrderLineItemsTable/WholesaleOrderLineItemsTable'

type WholesaleOrderBillingDocumentCreateFormFieldsProps = {
  data: GetAllWholesaleOrdersDetailsQuery | undefined
}

export const WholesaleOrderBillingDocumentCreateFormFields = ({
  data,
}: WholesaleOrderBillingDocumentCreateFormFieldsProps) => {
  const form = useFormInstance<CreateWholesaleOrderBillingDocumentFormValues>()
  const billingDocumentType = useWatch('type', form) || WholesaleOrderBillingDocumentType.INVOICE

  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <WholesaleOrderBillingDocumentTypeSelect />
      </Col>
      <Col xs={24} md={12}>
        {billingDocumentType === WholesaleOrderBillingDocumentType.INVOICE ? (
          <WholesaleOrderBillingDocumentDebtorCompanySelect
            wholesaleOrders={data?.wholesaleOrders ?? []}
          />
        ) : (
          <WholesaleOrderBillingDocumentBillingDocumentSelect
            billingDocuments={data?.wholesaleOrderBillingDocuments ?? []}
          />
        )}
      </Col>
      <Col xs={24} md={12}>
        <WholesaleOrderBillingDocumentDocumentNumberInput />
      </Col>
      <Col xs={24} md={12}>
        <WholesaleOrderBillingDocumentUpload />
      </Col>
      <Col xs={24} md={12}>
        <Row gutter={16}>
          <Col xs={12}>
            <WholesaleOrderBillingDocumentIssueDatePicker />
          </Col>
          <Col xs={12}>
            <WholesaleOrderBillingDocumentDueDatePicker />
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        {billingDocumentType === WholesaleOrderBillingDocumentType.INVOICE ? (
          <WholesaleOrderLineItemsTable wholesaleOrders={data?.wholesaleOrders ?? []} />
        ) : (
          <WholesaleOrderBillingDocumentExistingLineItemsTable
            billingDocuments={data?.wholesaleOrderBillingDocuments ?? []}
          />
        )}
      </Col>
    </Row>
  )
}
