import { useTranslation } from 'react-i18next'
import { AmazonListingFieldsFragment } from '../../../../generated/graphql'
import { StyledMainCard } from '../../../dashboard/styles'
import { prepareAmazonListingOrderData } from '../helpers/prepareAmazonListingOrderData'
import { AmazonListingOrderTable } from './AmazonListingOrderTable'

type AmazonListingOrdersCardProps = {
  orderLineItems: AmazonListingFieldsFragment['orderLineItems']
}

export const AmazonListingOrdersCard = ({ orderLineItems }: AmazonListingOrdersCardProps) => {
  const { t } = useTranslation('inventory')

  const preparedOrders = prepareAmazonListingOrderData(orderLineItems)

  return (
    <StyledMainCard title={t('product.details.orders.heading')}>
      <AmazonListingOrderTable orders={preparedOrders} />
    </StyledMainCard>
  )
}
