import { useMutation } from '@apollo/client'
import { Button, Flex, Form, Input, Modal } from 'antd'
import { Minus, Pen, Plus } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { UpdateWholesaleOrderTemplateEmailsDocument } from '../../../../generated/graphql'

type EditWholesaleTemplateEmailsFormInstance = {
  notificationEmails: string[]
}

type EditWholesaleTemplateEmailsModalProps = {
  identifier: string
  notificationEmails: string[]
}

export const EditWholesaleTemplateEmailsModal = ({
  identifier,
  notificationEmails,
}: EditWholesaleTemplateEmailsModalProps) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation('wholesale-order')
  const [form] = Form.useForm<EditWholesaleTemplateEmailsFormInstance>()

  const [updateWholesaleOrderTemplateEmails] = useMutation(
    UpdateWholesaleOrderTemplateEmailsDocument
  )

  const handleSubmit = async (values: EditWholesaleTemplateEmailsFormInstance) => {
    setLoading(true)
    try {
      await updateWholesaleOrderTemplateEmails({
        variables: {
          identifier,
          notificationEmails: values.notificationEmails,
        },
        refetchQueries: ['GetWholesaleOrderTemplate'],
      })

      toast.success(t('wholesaleOrderGeneralDetailsCard.editEmailsModal.success'))
      setOpen(false)
    } catch (error) {
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button type="text" icon={<Pen size={16} />} size="small" onClick={() => setOpen(true)} />
      <Modal
        title={t('wholesaleOrderGeneralDetailsCard.editEmailsModal.title')}
        open={open}
        onOk={() => form.submit()}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form<EditWholesaleTemplateEmailsFormInstance>
          form={form}
          initialValues={{ notificationEmails }}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.List name="notificationEmails">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field) => (
                  <Form.Item style={{ marginBottom: '6px' }} required={false} key={field.key}>
                    <Flex key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            type: 'email',
                            message: t('wholesaleOrderGeneralDetailsCard.editEmailsModal.required'),
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder={t(
                            'wholesaleOrderGeneralDetailsCard.editEmailsModal.placeholder'
                          )}
                          style={{ width: 'calc(100% - 32px)' }}
                        />
                      </Form.Item>

                      <Button onClick={() => remove(field.name)} style={{ marginLeft: '8px' }}>
                        <Minus size={16} />
                      </Button>
                    </Flex>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '100%' }}
                    icon={<Plus size={16} />}
                  >
                    {t('wholesaleOrderGeneralDetailsCard.editEmailsModal.addButton')}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}
