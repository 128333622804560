import { CognitoUser } from '@aws-amplify/auth'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { Auth } from 'aws-amplify'
import { Lock } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import { AuthStatus, useGlobalStore } from '../../stores/useGlobalStore'
import PasswordPolicyPopover from './components/PasswordPolicyPopover'

type NewPasswordRequiredFormInstance = {
  password: string
}

export const NewPasswordRequiredView = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const setAuthStatus = useGlobalStore((state) => state.setAuthStatus)
  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const setCognitoUser = useGlobalStore((state) => state.setCognitoUser)

  const { t } = useTranslation('login', { keyPrefix: 'newPassword' })
  const navigate = useNavigate()

  if (!cognitoUser) {
    navigate('/login')
  }

  const handleSubmit = async (values: NewPasswordRequiredFormInstance) => {
    setLoading(true)
    try {
      const user: CognitoUser = await Auth.completeNewPassword(cognitoUser, values.password)
      setCognitoUser(user)
      setAuthStatus(AuthStatus.AUTHENTICATED)
      navigate('/')
    } catch (error) {
      console.error(error)
      toast.error(t('form.error.message'), { description: t('form.error.description') })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Row justify="center" style={{ marginBottom: '1rem' }}>
        <Col flex="0 1 480px">
          <Typography.Title level={3}>{t('heading')}</Typography.Title>
          <Typography.Paragraph>{t('description')}</Typography.Paragraph>
          <Typography.Paragraph>
            <PasswordPolicyPopover />.
          </Typography.Paragraph>
          <Form<NewPasswordRequiredFormInstance>
            name="new-password-required"
            onFinish={handleSubmit}
            size="large"
          >
            <Form.Item
              name="password"
              rules={[{ required: true, message: t('form.password.validation.required') }]}
            >
              <Input.Password
                aria-label="password-input"
                autoFocus
                prefix={<Lock size={16} />}
                placeholder={t('form.password.placeholder')}
              />
            </Form.Item>
            <Form.Item>
              <Row justify="end">
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    aria-label="submit-button"
                    loading={loading}
                  >
                    {t('form.submit')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}
