import { useMutation } from '@apollo/client'
import { Button, Form, Modal, Popconfirm, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'sonner'
import {
  CreateSponsoredProductsKeywordInput,
  CreateSponsoredProductsKeywordsDocument,
  CreateSponsoredProductsKeywordsMutation,
  Marketplace,
} from '../../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../../stores/useGlobalStore'
import { useCurrencySymbol } from '../../../../../../views/ppc/helpers/useCurrencySymbol'
import CreateSponsoredProductsCampaignKeywordsFormFields from '../CreateSponsoredProductsKeywordsWidget/CreateSponsoredProductsKeywordsFormFields'
type CreateSponsoredProductsKeywordsModalProps = {
  campaignId: string
  adGroupId: string
  disabled: boolean
  onKeywordsAdded: Function
}

const CreateSponsoredProductsKeywordsModal = ({
  campaignId,
  adGroupId,
  disabled,
  onKeywordsAdded,
}: CreateSponsoredProductsKeywordsModalProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const [createSponsoredProductsKeywords] = useMutation<CreateSponsoredProductsKeywordsMutation>(
    CreateSponsoredProductsKeywordsDocument
  )
  const currencySymbol = useCurrencySymbol()
  const params = useParams<{ marketplace: string }>()
  const [modalKey, setModalKey] = useState(Math.floor(Math.random() * 99999999).toString())

  useEffect(() => {
    setModalKey(Math.floor(Math.random() * 99999999).toString())
  }, [modalVisible])

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleCreateKeywords = async (keywords: CreateSponsoredProductsKeywordInput[]) => {
    const request = await createSponsoredProductsKeywords({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: params.marketplace?.toUpperCase()! as Marketplace,
        keywords: keywords,
      },
    })

    return request.data?.createSponsoredProductsKeywords
  }

  const handleOnFinish = async (values: any) => {
    const keywords = values.keywords

    setLoading(true)

    try {
      if (!keywords.length) {
        throw new Error('No keywords to create')
      }
      for (const keyword of keywords) {
        keyword.campaignId = campaignId
        keyword.adGroupId = adGroupId
      }

      const createdKeywords = await handleCreateKeywords(keywords)

      toast.success('Keywords successfully added')

      onKeywordsAdded(createdKeywords)
    } catch (e: any) {
      console.error(e)
      toast.error('Error adding keywords', { description: e.message })
    } finally {
      setLoading(false)
      setModalVisible(false)
    }
  }

  return (
    <>
      <Button key="huihif" onClick={showModal} disabled={disabled} data-testid="add-keyword-btn">
        Add keywords
      </Button>

      <Modal
        title="Create Keywords"
        open={modalVisible}
        onCancel={handleCancel}
        width={800}
        key={modalKey}
        footer={
          <Space>
            <Button onClick={handleCancel} key="cancel">
              Cancel
            </Button>
            <Popconfirm
              key="u8f8f"
              placement="top"
              title={
                'New Keywords are created immediately on Amazon. Please confirm that you would like to continue.'
              }
              onConfirm={form.submit}
              okText="Continue"
              cancelText="Go back"
              disabled={loading}
              overlayInnerStyle={{ maxWidth: '400px' }}
            >
              <Button key="savel" disabled={loading} type={'primary'}>
                Save
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <Form form={form} onFinish={handleOnFinish}>
          <CreateSponsoredProductsCampaignKeywordsFormFields
            form={form}
            currencySymbol={currencySymbol}
          />
        </Form>
      </Modal>
    </>
  )
}

export default CreateSponsoredProductsKeywordsModal
