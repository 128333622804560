import { Button, Col, Flex, Form, Modal, Row, Select, Typography, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import { UploadIcon } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import { ImportWarnings } from '../../../components/ImportWarnings'
import { WholesaleOrderFormTableRecord } from './WholesaleOrderForm/WholesaleOrderFormTable'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

type WholesaleOrderCreateImportFormValues = {
  productIdentifier: string | undefined
  unitsPerPackagingUnit: string | undefined
  numberOfPackagingUnits: string | undefined
  netPurchasePrice: string | undefined
}

export type WholesaleOrderCreateImportValues = {
  productIdentifier: string
  unitsPerPackagingUnit: number | null
  numberOfPackagingUnits: number | null
  netPurchasePrice: number | null
}

type WholesaleOrderCreateImportModalProps = {
  rowData: WholesaleOrderFormTableRecord[]
  onImport: (data: WholesaleOrderFormTableRecord[]) => void
}

export const WholesaleOrderCreateImportModal = ({
  rowData,
  onImport,
}: WholesaleOrderCreateImportModalProps) => {
  const [open, setOpen] = useState(false)
  const [headers, setHeaders] = useState<string[]>([])
  const [worksheetData, setWorksheetData] = useState<Record<string, unknown>[]>([])
  const [importWarnings, setImportWarnings] = useState<string[]>([])

  const { t } = useTranslation(['wholesale-order', 'translation'])

  const [form] = Form.useForm<WholesaleOrderCreateImportFormValues>()

  const handleChange = (info: UploadChangeParam<UploadFile<unknown>>) => {
    const { file } = info

    if (file.originFileObj) {
      const reader = new FileReader()

      reader.onload = (event) => {
        const result = event.target?.result

        if (!result) {
          return
        }

        const data = new Uint8Array(result as ArrayBuffer)
        const workbook = XLSX.read(data, { type: 'array' })

        const sheetNames = workbook.SheetNames
        const firstSheetName = sheetNames[0]

        if (!firstSheetName) {
          return
        }

        const firstSheet = workbook.Sheets[firstSheetName]

        if (!firstSheet) {
          return
        }

        const range = XLSX.utils.decode_range(firstSheet['!ref'] || 'A1')

        const headers = Array.from({ length: range.e.c - range.s.c + 1 }, (_, i) => {
          const cell = firstSheet[XLSX.utils.encode_cell({ r: 0, c: range.s.c + i })]
          return cell?.v || ''
        }).filter((header) => header.trim().length)

        const worksheetData = XLSX.utils.sheet_to_json(firstSheet) as Record<string, unknown>[]

        setHeaders(headers)
        setWorksheetData(worksheetData)
      }

      reader.readAsArrayBuffer(file.originFileObj)
    }
  }

  const checkDataAndGenerateWarnings = (values: WholesaleOrderCreateImportFormValues) => {
    if (!worksheetData.length) {
      return
    }

    const mappedData = worksheetData.flatMap((row) => {
      const productIdentifier = values.productIdentifier
        ? row[values.productIdentifier]?.toString().trim()
        : null

      if (!productIdentifier) {
        return []
      }

      const unitsPerPackagingUnit = values.unitsPerPackagingUnit
        ? Number(row[values.unitsPerPackagingUnit])
        : null

      const numberOfPackagingUnits = values.numberOfPackagingUnits
        ? Number(row[values.numberOfPackagingUnits])
        : null

      const netPurchasePrice = values.netPurchasePrice ? Number(row[values.netPurchasePrice]) : null

      return {
        productIdentifier,
        unitsPerPackagingUnit,
        numberOfPackagingUnits,
        netPurchasePrice,
      }
    })

    const warnings: string[] = []

    for (const item of mappedData) {
      const matchingRow = rowData.find((row) => {
        return (
          row.internalSku === item.productIdentifier ||
          row.ean === item.productIdentifier ||
          row.asins.includes(item.productIdentifier) ||
          row.skus.includes(item.productIdentifier)
        )
      })

      if (!matchingRow) {
        warnings.push(
          t('wholesaleOrderCreateView.import.warning.productIdentifierNotFound', {
            productIdentifier: item.productIdentifier,
          })
        )
        continue
      }

      if (item.unitsPerPackagingUnit) {
        const packagingUnit = matchingRow.packagingUnits.find((unit) => {
          return unit.quantity === item.unitsPerPackagingUnit
        })

        if (!packagingUnit) {
          warnings.push(
            t('wholesaleOrderCreateView.import.warning.packagingUnitNotFound', {
              productIdentifier: item.productIdentifier,
              packagingUnit: item.unitsPerPackagingUnit,
            })
          )
        }
      }
    }

    setImportWarnings(warnings)
  }

  const handleFinish = (values: WholesaleOrderCreateImportFormValues) => {
    const mappedData = worksheetData.flatMap((row) => {
      const productIdentifier = values.productIdentifier
        ? row[values.productIdentifier]?.toString().trim()
        : null

      if (!productIdentifier) {
        return []
      }

      const unitsPerPackagingUnit = values.unitsPerPackagingUnit
        ? Number(row[values.unitsPerPackagingUnit])
        : null

      const numberOfPackagingUnits = values.numberOfPackagingUnits
        ? Number(row[values.numberOfPackagingUnits])
        : null

      const netPurchasePrice = values.netPurchasePrice ? Number(row[values.netPurchasePrice]) : null

      return {
        productIdentifier,
        unitsPerPackagingUnit,
        numberOfPackagingUnits,
        netPurchasePrice,
      }
    })

    const matchedData: WholesaleOrderFormTableRecord[] = []

    for (const item of mappedData) {
      const matchingRow = rowData.find((row) => {
        return (
          row.internalSku === item.productIdentifier ||
          row.ean === item.productIdentifier ||
          row.asins.includes(item.productIdentifier) ||
          row.skus.includes(item.productIdentifier)
        )
      })

      if (!matchingRow) {
        continue
      }

      const packagingUnit = matchingRow.packagingUnits.find((unit) => {
        return unit.quantity === item.unitsPerPackagingUnit
      })

      matchedData.push({
        ...matchingRow,
        selectedPackagingUnit: packagingUnit ?? matchingRow.selectedPackagingUnit,
        quantityOfPackagingUnits:
          item.numberOfPackagingUnits ?? matchingRow.quantityOfPackagingUnits,
        netPurchasePrice: item.netPurchasePrice ?? matchingRow.netPurchasePrice,
      })
    }

    onImport(matchedData)
    setOpen(false)
    setImportWarnings([])
    setWorksheetData([])
    setHeaders([])
    form.resetFields()
  }

  const getFilteredOptions = (currentField: keyof WholesaleOrderCreateImportFormValues) => {
    const values = form.getFieldsValue()
    const selectedHeaders = Object.entries(values)
      .filter(([key, value]) => key !== currentField && value !== undefined)
      .map(([, value]) => value)

    return headers
      .filter((header) => !selectedHeaders.includes(header))
      .map((header) => ({ label: header, value: header }))
  }

  const SelectWithDependencies = ({
    field,
    ...props
  }: {
    field: keyof WholesaleOrderCreateImportFormValues
  }) => (
    <Form.Item
      noStyle
      dependencies={[
        'productIdentifier',
        'unitsPerPackagingUnit',
        'numberOfPackagingUnits',
        'netPurchasePrice',
      ]}
    >
      {() => (
        <Select
          {...props}
          options={getFilteredOptions(field)}
          allowClear
          placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
          showSearch
        />
      )}
    </Form.Item>
  )

  return (
    <>
      <Button
        icon={<UploadIcon size={16} />}
        disabled={!rowData.length}
        onClick={() => setOpen(true)}
      >
        {t('shared.button.import', { ns: 'translation' })}
      </Button>
      <Modal
        title={t('wholesaleOrderCreateView.import.title')}
        open={open}
        okText={t('shared.button.import', { ns: 'translation' })}
        okButtonProps={{ disabled: !headers.length }}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
      >
        <Flex vertical gap={16}>
          <Upload.Dragger
            accept=".xlsx"
            customRequest={customRequest}
            maxCount={1}
            onChange={handleChange}
            showUploadList={false}
          >
            <Typography.Text>{t('wholesaleOrderCreateView.import.description')}</Typography.Text>
          </Upload.Dragger>
          <Form<WholesaleOrderCreateImportFormValues>
            form={form}
            disabled={!headers.length}
            layout="vertical"
            onFinish={handleFinish}
            onValuesChange={(changedValues, allValues) => checkDataAndGenerateWarnings(allValues)}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item<WholesaleOrderCreateImportFormValues>
                  name="productIdentifier"
                  label={t('wholesaleOrderCreateView.import.productIdentifier.label')}
                >
                  <SelectWithDependencies field="productIdentifier" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item<WholesaleOrderCreateImportFormValues>
                  name="unitsPerPackagingUnit"
                  label={t('wholesaleOrderCreateView.import.unitsPerPackagingUnit.label')}
                >
                  <SelectWithDependencies field="unitsPerPackagingUnit" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item<WholesaleOrderCreateImportFormValues>
                  name="numberOfPackagingUnits"
                  label={t('wholesaleOrderCreateView.import.numberOfPackagingUnits.label')}
                >
                  <SelectWithDependencies field="numberOfPackagingUnits" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item<WholesaleOrderCreateImportFormValues>
                  name="netPurchasePrice"
                  label={t('wholesaleOrderCreateView.import.netPurchasePrice.label')}
                >
                  <SelectWithDependencies field="netPurchasePrice" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <ImportWarnings
            warnings={importWarnings}
            heading={t('wholesaleOrderCreateView.import.warning.heading', {
              count: importWarnings.length,
            })}
          />
        </Flex>
      </Modal>
    </>
  )
}
