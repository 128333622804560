import { useMutation } from '@apollo/client'
import { Alert, Button, Flex, Form, InputNumber, Modal, Select, Switch, TourProps } from 'antd'
import { Plus } from 'lucide-react'
import { nanoid } from 'nanoid'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router'
import { toast } from 'sonner'
import {
  AmazonProductFieldsFragment,
  CheckOnboardingStepDocument,
  CreateAmazonListingDocument,
  GetCompanyDocument,
  GetProductDocument,
  GetProductVariantDocument,
  Marketplace,
  SendSupportTicketDocument,
} from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { getMarketplaceCurrency } from '../../../../helpers/getMarketplaceCurrency'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { useOnboardingStore } from '../../../../stores/useOnboardingStore'
import { createZendeskTicketOptions } from '../../../ticket/helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../../../ticket/ticket.enum'

type CreateAmazonListingFormInstance = {
  marketplace: Marketplace
  recommendedRetailPrice: number
  isFbm: boolean
}

type CreateAmazonListingModalProps = {
  amazonProduct: AmazonProductFieldsFragment
}

export const CreateAmazonListingModal = ({ amazonProduct }: CreateAmazonListingModalProps) => {
  const [open, setOpen] = useState(false)
  const [currency, setCurrency] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)
  const addListingRef = useRef(null)
  const [searchParams] = useSearchParams()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const setTourSteps = useOnboardingStore((state) => state.setTourSteps)
  const setTourOpen = useOnboardingStore((state) => state.setTourOpen)

  const { t, i18n } = useTranslation(['inventory', 'ticket', 'translation', 'company'])
  const [form] = Form.useForm<CreateAmazonListingFormInstance>()

  const amazonListings = amazonProduct.amazonListings ?? []

  const marketplaceOptions = useMarketplaceOptions()
  const allowedMarketplaces = marketplaceOptions
    // We don't support creating listings for the US marketplace yet.
    .filter((marketplace) => marketplace !== Marketplace.US)
    // Filter out marketplaces that already have a listing.
    .filter(
      (marketplace) =>
        !amazonListings?.some((amazonListing) => amazonListing.marketplace.name === marketplace)
    )

  const [createAmazonListing] = useMutation(CreateAmazonListingDocument)
  const [sendSupportTicket] = useMutation(SendSupportTicketDocument)
  const [checkOnboardingStep] = useMutation(CheckOnboardingStepDocument)

  const handleOk = () => {
    form.submit()
  }

  const handleValuesChange = (changedValues: Partial<CreateAmazonListingFormInstance>) => {
    if (changedValues.marketplace) {
      setCurrency(getMarketplaceCurrency(changedValues.marketplace))
    }
  }

  const handleSubmit = async (values: CreateAmazonListingFormInstance) => {
    setLoading(true)

    const sku = amazonListings[0]?.sku
      ? constructSku(amazonListings[0].sku, values.marketplace)
      : `MISSING-SKU-${nanoid(10)}`

    const comment = `
      <h3>${t('listingAddView.creationText.createdNote', { ns: 'ticket' })}</h3>
      &nbsp;
      &nbsp;
      <h3>${t('listingAddView.creationText.productDetailsList.title', { ns: 'ticket' })}</h3>
      <ul>
      <li>
          <b>ASIN: </b>${amazonProduct.asin}
        </li>
        <li>
          <b>SKU: </b>${sku}
        </li>
        <li>
          <b>${t('listingAddView.creationText.productDetailsList.marketplace', {
            ns: 'ticket',
          })}</b>
          ${values.marketplace}
        </li>
        <li>
          <b>${t('listingAddView.creationText.productDetailsList.recommendedRetailPrice', {
            ns: 'ticket',
          })}</b>
          ${formatMoney(+values.recommendedRetailPrice, getMarketplaceCurrency(values.marketplace))}
        </li>
      </ul>
      <p>${t('listingAddView.creationText.processNote', { ns: 'ticket' })}</p>
      <p>${t('listingAddView.creationText.contactNote', { ns: 'ticket' })}</p>
    `

    const options = createZendeskTicketOptions({
      subject: `${t('listingAddView.subject', { ns: 'ticket' })} (${values.marketplace})`,
      comment,
      categoryTitle: 'amazon',
      mainCategory: TicketMainCategory.INVENTORY,
      ticketCategory: TicketCategory.INVENTORY_ADD_NEW_LISTING,
      sellerId: selectedCompany?.sellerId,
      language: i18n.language,
    })

    try {
      if (!cognitoUser || !selectedCompany) {
        throw new Error()
      }

      await createAmazonListing({
        variables: {
          // Since we only support european marketplaces for now, we can be sure that the first
          // Amazon product is the one we want to create a listing for.
          amazonProductUuid: amazonProduct.uuid,
          amazonListing: {
            sku,
            marketplace: values.marketplace,
            recommendedRetailPrice: values.recommendedRetailPrice,
            isFbm: values.isFbm,
          },
        },
        refetchQueries: [GetProductDocument, GetProductVariantDocument],
      })

      await sendSupportTicket({
        variables: {
          cognitoUsername: cognitoUser.getUsername(),
          companyUuid: selectedCompany.uuid,
          input: options,
        },
      })

      if (!selectedCompany.completedOnboarding) {
        await checkOnboardingStep({
          variables: {
            input: {
              companyUuid: selectedCompany.uuid,
              eventName: 'add_listing',
            },
          },
          refetchQueries: [
            {
              query: GetCompanyDocument,
              variables: { companyUuid: selectedCompany?.uuid },
            },
          ],
        })
      }

      toast.success(t('product.details.amazonListings.modal.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('product.details.amazonListings.modal.error'))
    } finally {
      setOpen(false)
      setLoading(false)
    }
  }

  const steps: TourProps['steps'] = [
    {
      title: t('company:onboarding.addListing.title'),
      description: t('company:onboarding.addListing.description'),
      target: () => addListingRef.current,
      nextButtonProps: {
        style: { display: 'none' },
      },
    },
  ]

  useEffect(() => {
    const tour = searchParams.get('tour') === 'true'
    if (tour) {
      setTourSteps(steps)
      setTourOpen(true)
    }
  }, [searchParams])

  return (
    <>
      <div ref={addListingRef}>
        <Button
          icon={<Plus size={16} />}
          disabled={!allowedMarketplaces.length}
          onClick={() => {
            setOpen(true)
            setTourOpen(false)
          }}
        >
          {t('product.details.amazonListings.addListing')}
        </Button>
      </div>
      <Modal
        title={t('product.details.amazonListings.modal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={handleOk}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        destroyOnClose
      >
        <Flex vertical gap={16}>
          <Form<CreateAmazonListingFormInstance>
            form={form}
            onValuesChange={handleValuesChange}
            onFinish={handleSubmit}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
          >
            <Form.Item
              name="marketplace"
              label={t('product.details.amazonListings.modal.marketplace')}
              rules={[{ required: true, type: 'enum', enum: allowedMarketplaces }]}
            >
              <Select<Marketplace>
                options={allowedMarketplaces.map((marketplace) => ({
                  value: marketplace,
                  label: `Amazon.${getMarketplaceDomain(marketplace)}`,
                }))}
                placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
                showSearch
                filterOption={true}
                optionFilterProp="label"
              />
            </Form.Item>
            {selectedCompany?.isFbmEligible && (
              <Form.Item
                name="isFbm"
                label={t('product.details.amazonListings.modal.isFbm.title')}
                tooltip={t('product.details.amazonListings.modal.isFbm.tooltip')}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            )}

            <Form.Item
              name="recommendedRetailPrice"
              label={t('product.details.amazonListings.modal.recommendedRetailPrice')}
              dependencies={['marketplace']}
              rules={[{ required: true, min: 0, type: 'number' }]}
            >
              <InputNumber addonBefore={currency} min={0} precision={2} style={{ width: '100%' }} />
            </Form.Item>
          </Form>
          <Alert type="info" message={t('product.details.amazonListings.modal.note')} />
        </Flex>
      </Modal>
    </>
  )
}

function constructSku(sku: string, marketplace: Marketplace) {
  const parts = sku.split('-')
  const lastPart = parts[parts.length - 1] ?? ''
  const baseSku = parts.slice(0, -1).join('-') + '-' + lastPart.substring(0, 4)

  return marketplace === Marketplace.UK ? baseSku.concat('UK') : baseSku
}
