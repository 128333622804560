import { useTheme } from '@emotion/react'
import { Col, Divider, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { GetAmazonListingQuery } from '../../../../generated/graphql'
import { EditAmazonListingContentModal } from './EditAmazonListingContentModal'

type AmazonListingContentProps = {
  amazonListing: GetAmazonListingQuery['amazonListing']
}

export const AmazonListingContent = ({ amazonListing }: AmazonListingContentProps) => {
  const { t } = useTranslation(['inventory', 'translation'])
  const theme = useTheme()

  return (
    <>
      <Row gutter={16} justify="space-between" wrap={false}>
        <Col flex="auto">
          <Typography.Text strong>{amazonListing.title ?? '-'}</Typography.Text>
        </Col>
        <Col>
          <EditAmazonListingContentModal amazonListing={amazonListing} />
        </Col>
      </Row>
      <Divider plain orientation="left" orientationMargin={0} style={{ marginBottom: 8 }}>
        {t('product.details.amazonListings.description')}
      </Divider>
      {amazonListing.description ? (
        <Typography.Text>{amazonListing.description}</Typography.Text>
      ) : (
        <Typography.Text type="secondary">
          {t('product.details.amazonListings.noDescription')}
        </Typography.Text>
      )}
      <Divider plain orientation="left" orientationMargin={0} style={{ marginBottom: 8 }}>
        {t('product.details.amazonListings.bulletPoints')}
      </Divider>
      {amazonListing.bulletPoints?.length ? (
        <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
          {amazonListing.bulletPoints.map((bulletPoint, index) => (
            <li key={index} style={{ color: theme.colors.text }}>
              <Typography.Text>{bulletPoint}</Typography.Text>
            </li>
          ))}
        </ul>
      ) : (
        <Typography.Text type="secondary">
          {t('product.details.amazonListings.noBulletPoints')}
        </Typography.Text>
      )}
      <Divider plain orientation="left" orientationMargin={0} style={{ marginBottom: 8 }}>
        {t('product.details.amazonListings.searchTerms')}
      </Divider>
      {amazonListing.searchTerms ? (
        <Typography.Text>{amazonListing.searchTerms}</Typography.Text>
      ) : (
        <Typography.Text type="secondary">
          {t('product.details.amazonListings.noSearchTerms')}
        </Typography.Text>
      )}
    </>
  )
}
