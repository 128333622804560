import {
  CompanyType,
  GetAmazonListingsAndWarehousesQuery,
  GtinType,
  LabelType,
  PrepInstruction,
} from '../../../../generated/graphql'
import { UserCompany } from '../../../../hooks/useAuthentication'

export type AvailableInventory = {
  uuid: string
  image: string | null
  name: string
  ean: string | null
  asin: string
  fnsku: string | null
  sku: string
  internalSku: string | null
  labelType: LabelType
  prepInstructions: PrepInstruction[]
  recommendedQuantity: number | null
  isEligibleForInbound: boolean
  hasTaxCategory: boolean
  packagingUnits: NonNullable<
    GetAmazonListingsAndWarehousesQuery['products'][number]['productVariants']
  >[number]['packagingUnits']
  hasApprovedWholesaleOrder: boolean
}

export const prepareAvailableInventory = (
  products: GetAmazonListingsAndWarehousesQuery['products'] | undefined,
  company: UserCompany
): AvailableInventory[] => {
  if (!products) {
    return []
  }

  return products
    .flatMap((product) => {
      if (!product.productVariants) {
        return []
      }

      return product.productVariants.flatMap((productVariant) => {
        if (!productVariant.amazonProducts) {
          return []
        }

        const hasApprovedWholesaleOrder =
          company.type !== CompanyType.WHOLESALER
            ? true
            : // VARO2 is our own company, where we don't have any wholesale orders.
              company.sellerId === 'VARO2'
              ? true
              : (productVariant.wholesaleOrders?.some(
                  (order) => Boolean(order.retailerApprovedAt) || Boolean(order.vendorApprovedAt)
                ) ?? false)

        return productVariant.amazonProducts.flatMap((amazonProduct) => {
          if (!amazonProduct.amazonListings) {
            return []
          }

          return amazonProduct.amazonListings.flatMap((amazonListing) => {
            const stock = productVariant.stock?.find((stock) => {
              if (!stock?.warehouseServiceProviderLocation.active) {
                return false
              }

              if (
                stock.warehouseServiceProviderLocation.amazonAccount?.name !==
                amazonProduct.amazonAccount.name
              ) {
                return false
              }

              if (
                !stock.warehouseServiceProviderLocation.marketplaces?.includes(
                  amazonListing.marketplace.name
                )
              ) {
                return false
              }

              return true
            })

            const packagingUnits = productVariant.packagingUnits.sort((a, b) => {
              return a.quantity - b.quantity
            })

            const ean = packagingUnits.find((unit) => unit.gtinType === GtinType.EAN)?.gtin ?? null
            const internalSku = packagingUnits.find((unit) => unit.internalSku)?.internalSku ?? null

            return {
              uuid: amazonListing.uuid,
              image: amazonListing.images?.mainImage ?? null,
              name: product.name,
              ean,
              asin: amazonProduct.asin,
              fnsku: amazonListing.fnsku ?? null,
              sku: amazonListing.sku,
              internalSku,
              labelType: amazonListing.labelType ?? LabelType.EAN,
              prepInstructions: amazonListing.prepInstructions ?? [],
              recommendedQuantity: stock?.recommendedReorderQuantity ?? 0,
              isEligibleForInbound: amazonListing.isEligibleForInbound,
              hasTaxCategory: !!product.taxCategory?.name,
              packagingUnits,
              hasApprovedWholesaleOrder,
            }
          })
        })
      })
    })
    .filter((item, index, self) => index === self.findIndex((i) => i.sku === item.sku))
}
