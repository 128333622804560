import { Collapse, List, Space, Typography } from 'antd'
import { TriangleAlert } from 'lucide-react'

type ImportWarningsProps = {
  warnings: string[]
  heading: string
}

export const ImportWarnings = ({ warnings, heading }: ImportWarningsProps) => {
  if (!warnings.length) return null

  return (
    <Collapse
      items={[
        {
          key: 'warnings',
          label: (
            <Space>
              <TriangleAlert size={16} />
              <Typography.Text>{heading}</Typography.Text>
            </Space>
          ),
          children: (
            <List
              dataSource={warnings}
              renderItem={(warning, index) => <List.Item key={index}>{warning}</List.Item>}
              split={false}
            />
          ),
        },
      ]}
    />
  )
}
