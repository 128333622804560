import { useQuery } from '@apollo/client'
import { Dayjs } from 'dayjs'
import { ErrorWithRetry } from '../../../../components/ErrorWithRetry'
import {
  GetDashboardFinanceTableDataDocument,
  GetDashboardFinanceTableDataQuery,
  InternalAmazonListingStatus,
  Marketplace,
} from '../../../../generated/graphql'
import { getSearchIdentifiers } from '../../../../helpers/getSearchIdentifiers'
import { roundNumber } from '../../../../helpers/roundNumber'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import {
  DashboardFinanceProductTable,
  DashboardFinanceProductTableRecord,
} from './DashboardFinanceProductTable'

type DashboardFinanceProductTableContainerProps = {
  marketplaces: Marketplace[]
  dateRange: [Dayjs, Dayjs]
}

export const DashboardFinanceProductTableContainer = ({
  marketplaces,
  dateRange,
}: DashboardFinanceProductTableContainerProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { error, data, refetch } = useQuery(GetDashboardFinanceTableDataDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
      marketplaces,
      dateRange: {
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
      },
      internalStatuses: Object.values(InternalAmazonListingStatus),
    },
  })

  if (error) {
    return <ErrorWithRetry retry={refetch} />
  }

  const rowData = prepareProductTableData(data)

  return <DashboardFinanceProductTable rowData={rowData} dateRange={dateRange} />
}

function prepareProductTableData(
  data: GetDashboardFinanceTableDataQuery | undefined
): DashboardFinanceProductTableRecord[] | undefined {
  if (!data) {
    return undefined
  }

  return data.products.flatMap((product) => {
    if (!product.productVariants) {
      return []
    }

    return product.productVariants.flatMap((productVariant) => {
      if (!productVariant.amazonProducts) {
        return []
      }

      return productVariant.amazonProducts.flatMap((amazonProduct) => {
        if (!amazonProduct.amazonListings) {
          return []
        }

        return amazonProduct.amazonListings.flatMap((amazonListing) => {
          const sales = amazonListing.shipments
            ? amazonListing.shipments.reduce((previousValue, currentValue) => {
                if (!currentValue?.totalCharge) {
                  return previousValue
                }

                return previousValue + currentValue.totalCharge
              }, 0)
            : 0

          const unitsSold = amazonListing.shipments
            ? amazonListing.shipments.reduce((previousValue, currentValue) => {
                if (!currentValue?.totalQuantity) {
                  return previousValue
                }

                return previousValue + currentValue.totalQuantity
              }, 0)
            : 0

          const refundAmount = amazonListing.refunds
            ? amazonListing.refunds.reduce((previousValue, currentValue) => {
                if (!currentValue?.totalCharge) {
                  return previousValue
                }

                return previousValue + currentValue.totalCharge
              }, 0)
            : 0

          const refundQuantity = amazonListing.refunds
            ? amazonListing.refunds.reduce((previousValue, currentValue) => {
                if (!currentValue?.totalQuantity) {
                  return previousValue
                }

                return previousValue + currentValue.totalQuantity
              }, 0)
            : 0

          const refundRatio = unitsSold > 0 ? refundQuantity / unitsSold : refundQuantity / 1

          const shipmentFees = amazonListing.shipments
            ? amazonListing.shipments.reduce((previousValue, currentValue) => {
                if (!currentValue?.totalFee) {
                  return previousValue
                }

                return previousValue + currentValue.totalFee
              }, 0)
            : 0

          const shipmentPromotions = amazonListing.shipments
            ? amazonListing.shipments.reduce((previousValue, currentValue) => {
                if (!currentValue?.totalPromotionalDiscount) {
                  return previousValue
                }

                return previousValue + currentValue.totalPromotionalDiscount
              }, 0)
            : 0

          const refundFees = amazonListing.refunds
            ? amazonListing.refunds.reduce((previousValue, currentValue) => {
                if (!currentValue?.totalFee) {
                  return previousValue
                }

                return previousValue + currentValue.totalFee
              }, 0)
            : 0

          const refundPromotions = amazonListing.refunds
            ? amazonListing.refunds.reduce((previousValue, currentValue) => {
                if (!currentValue?.totalPromotionalDiscount) {
                  return previousValue
                }

                return previousValue + currentValue.totalPromotionalDiscount
              }, 0)
            : 0

          const fees = shipmentFees + refundFees
          const promotions = shipmentPromotions + refundPromotions

          const advertising = amazonListing.ppcCosts
            ? amazonListing.ppcCosts.reduce((previousValue, currentValue) => {
                if (!currentValue) {
                  return previousValue
                }

                return (
                  previousValue +
                  (currentValue.sponsoredProductsCosts ?? 0) +
                  (currentValue.sponsoredDisplayCosts ?? 0)
                )
              }, 0)
            : 0

          const profit = sales + refundAmount + fees + promotions + advertising

          return {
            key: amazonListing.uuid,
            image: amazonListing.images?.mainImage ?? null,
            name: product.name,
            internalSku: productVariant.internalSku ?? null,
            asin: amazonProduct.asin,
            sku: amazonListing.sku,
            marketplace: amazonListing.marketplace.name,
            sales: roundNumber(sales),
            unitsSold,
            refundAmount: roundNumber(refundAmount),
            refundQuantity,
            refundRatio,
            fees: roundNumber(fees),
            promotions: roundNumber(promotions),
            advertising: roundNumber(advertising),
            profit: roundNumber(profit),
            shipmentFees: roundNumber(shipmentFees),
            refundFees: roundNumber(refundFees),
            searchIdentifiers: getSearchIdentifiers({
              productVariant,
            }),
          }
        })
      })
    })
  })
}
