import { useQuery } from '@apollo/client'
import { Segmented } from 'antd'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  GetInvoiceArticleSubscriptionsDocument,
  GetInvoiceArticleSubscriptionsQuery,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import {
  InvoiceArticleSubscriptionRecord,
  InvoiceArticleSubscriptionsTable,
} from './InvoiceArticleSubscriptionsTable'

const InvoiceArticleSubscriptionsViewTabs = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
}

export const InvoiceArticleSubscriptionsView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation(['invoices', 'translation'])
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')

  const { error, data, loading } = useQuery(GetInvoiceArticleSubscriptionsDocument, {
    skip: !selectedCompany.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  if (!tab || !Object.values(InvoiceArticleSubscriptionsViewTabs).includes(tab)) {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('tab', InvoiceArticleSubscriptionsViewTabs.ACTIVE)
    return <Navigate to={`?${searchParams}`} replace />
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult status="500" title="500" subTitle={t('translation:shared.error.message')} />
    )
  }

  const invoiceArticleSubscriptions = prepareInvoiceArticleSubscriptions(data)
  const activeInvoiceArticleSubscriptions = invoiceArticleSubscriptions.filter(
    (article) => article.endDate === null || new Date(article.endDate) > new Date()
  )
  const archivedInvoiceArticleSubscriptions = invoiceArticleSubscriptions.filter(
    (article) => article.endDate !== null && new Date(article.endDate) <= new Date()
  )

  return (
    <>
      <SubHeader heading={t('invoices:subscriptions.heading')} />
      <ViewContainer>
        <Segmented
          value={tab}
          onChange={(value) => navigate(`/invoices/subscriptions?tab=${value}`)}
          options={[
            {
              label: t('invoices:subscriptions.status.active'),
              value: InvoiceArticleSubscriptionsViewTabs.ACTIVE,
            },
            {
              label: t('invoices:subscriptions.status.archived'),
              value: InvoiceArticleSubscriptionsViewTabs.ARCHIVED,
            },
          ]}
        />

        <InvoiceArticleSubscriptionsTable
          invoiceArticleSubscriptions={
            tab === InvoiceArticleSubscriptionsViewTabs.ACTIVE
              ? activeInvoiceArticleSubscriptions
              : archivedInvoiceArticleSubscriptions
          }
        />
      </ViewContainer>
    </>
  )
}

export const prepareInvoiceArticleSubscriptions = (
  data: GetInvoiceArticleSubscriptionsQuery | undefined
): InvoiceArticleSubscriptionRecord[] => {
  if (!data) {
    return []
  }

  return data.invoiceArticleSubscriptions.map((invoiceArticleSubscription) => {
    return {
      uuid: invoiceArticleSubscription.uuid,
      articleName: invoiceArticleSubscription.invoiceArticle.name,
      description: invoiceArticleSubscription.invoiceArticle.description ?? '',
      buyerCompany: invoiceArticleSubscription.company.sellerId,
      netAmount: invoiceArticleSubscription.netAmount,
      currency: invoiceArticleSubscription.currency,
      commissionInPercent: invoiceArticleSubscription.commissionInPercent,
      frequency: invoiceArticleSubscription.frequency,
      startDate: invoiceArticleSubscription.startDate,
      endDate: invoiceArticleSubscription.endDate ?? null,
      createdAt: invoiceArticleSubscription.createdAt,
      updatedAt: invoiceArticleSubscription.updatedAt,
    }
  })
}
