import { useMutation } from '@apollo/client'
import { Button, Form, Upload } from 'antd'
import { RcFile, UploadChangeParam } from 'antd/es/upload'
import { UploadFile } from 'antd/es/upload/interface'
import { Plus } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  DeleteAttachmentFromZendeskDocument,
  UploadAttachmentToZendeskDocument,
} from '../../generated/graphql'
import { convertFileToBase64 } from '../../helpers/convertFileToBase64'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { AttachmentProps } from '../../views/ticket/ticket.interface'

type UploadZendeskFileProps = {
  attachments: AttachmentProps[]
  setAttachments: React.Dispatch<React.SetStateAction<AttachmentProps[]>>
  jpegOnly?: boolean
}

export const UploadZendeskFile = ({
  attachments,
  setAttachments,
  jpegOnly,
}: UploadZendeskFileProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const { t } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [uploadAttachmentToZendesk] = useMutation(UploadAttachmentToZendeskDocument)
  const [deleteAttachmentFromZendesk] = useMutation(DeleteAttachmentFromZendeskDocument)

  const handleChange = async (info: UploadChangeParam<UploadFile>) => {
    try {
      if (!cognitoUser || !selectedCompany) {
        return
      }

      if (info.file.status === 'removed') {
        const [filteredAttachment] = attachments.filter((attachment) => {
          return attachment.uid === info.file.uid
        })

        if (!filteredAttachment) {
          return
        }

        await deleteAttachmentFromZendesk({
          variables: {
            companyUuid: selectedCompany?.uuid,
            cognitoUsername: cognitoUser.getUsername()!,
            token: filteredAttachment.token,
          },
        })
        setFileList(info.fileList)
        setAttachments((prevAttachments) =>
          prevAttachments.filter((attachment) => attachment.uid !== info.file.uid)
        )
      } else if (info.file.status === 'error') {
        toast.error(t('attachments.error.somethingWentWrong.title'), {
          description: t('attachments.error.somethingWentWrong.description'),
        })
      } else {
        const base64File = await convertFileToBase64(info.file)
        const response = await uploadAttachmentToZendesk({
          variables: {
            companyUuid: selectedCompany?.uuid,
            cognitoUsername: cognitoUser.getUsername()!,
            input: {
              fileName: info.file.name,
              base64: base64File as string,
            },
          },
        })
        setFileList(info.fileList)
        setAttachments((prevAttachments) => {
          if (
            response.data?.uploadAttachmentToZendesk.upload?.token &&
            response.data.uploadAttachmentToZendesk.upload.attachment.id
          ) {
            return [
              ...prevAttachments,
              {
                token: response.data?.uploadAttachmentToZendesk.upload?.token,
                id: response.data.uploadAttachmentToZendesk.upload.attachment.id,
                uid: info.file.uid,
              },
            ]
          } else {
            return prevAttachments
          }
        })
      }
    } catch (error) {
      toast.error(t('attachments.error.somethingWentWrong.title'), {
        description: t('attachments.error.somethingWentWrong.description'),
      })
    }
  }

  const handleBeforeUpload = (file: RcFile) => {
    const allowedFileTypes = jpegOnly
      ? ['image/jpeg']
      : [
          'image/jpeg',
          'image/png',
          'image/bmp',
          'application/pdf',
          'text/plain',
          'text/csv',
          'text/tab-separated-values',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
          'application/vnd.oasis.opendocument.spreadsheet',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]

    if (!allowedFileTypes.includes(file.type)) {
      toast.error(t('attachments.error.fileNotSupported.title'), {
        description: t('attachments.error.fileNotSupported.description', {
          formats: jpegOnly ? '.jpeg' : '.jpeg, .png, .csv, .txt, .pdf, .docx, .doc, .xlsx, .xls',
        }),
      })
    }

    return allowedFileTypes.includes(file.type) ? false : Upload.LIST_IGNORE
  }

  return (
    <Form.Item name="upload" label={t('attachments.label')}>
      <Upload
        accept="*"
        fileList={fileList}
        listType="picture"
        multiple
        onChange={handleChange}
        beforeUpload={handleBeforeUpload}
      >
        <Button icon={<Plus size={16} />}>{t('attachments.buttonTitle')}</Button>
      </Upload>
    </Form.Item>
  )
}
