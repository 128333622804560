import { useTheme } from '@emotion/react'
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GetRowIdParams,
  ICellRendererParams,
  IRowNode,
  IsRowSelectable,
  RowSelectionOptions,
  SelectionChangedEvent,
  SelectionColumnDef,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Form } from 'antd'
import { ImageOff } from 'lucide-react'
import { forwardRef, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridImage, GridImageFallback } from '../../../../components/Table/GridImage'
import { Table } from '../../../../components/Table/Table'
import { AvailableInventory } from '../helpers/prepareAvailableInventory'
import {
  AmazonInboundShipmentPlanCreateFormInstance,
  SelectedInventory,
} from './AmazonInboundShipmentPlanCreateForm'

export type AmazonInboundShipmentPlanInventoryRecord = AvailableInventory

export const AmazonInboundShipmentPlanInventoryTable = forwardRef<
  AgGridReact<AmazonInboundShipmentPlanInventoryRecord>
>((props, gridRef) => {
  const { t } = useTranslation('inventory')
  const theme = useTheme()

  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  const availableInventory: AvailableInventory[] = form.getFieldValue('availableInventory')
  const rowData = availableInventory

  const [columnDefs] = useState<
    (
      | ColDef<AmazonInboundShipmentPlanInventoryRecord>
      | ColGroupDef<AmazonInboundShipmentPlanInventoryRecord>
    )[]
  >([
    {
      field: 'image',
      headerName: '',
      minWidth: 64,
      maxWidth: 64,
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanInventoryRecord,
          AmazonInboundShipmentPlanInventoryRecord['image']
        >
      ) => {
        const src = params.value ?? null

        return src ? (
          <GridImage src={src} width={40} height={40} preview={false} />
        ) : (
          <GridImageFallback>
            <ImageOff />
          </GridImageFallback>
        )
      },
    },
    {
      field: 'name',
      headerName: t('amazonInboundShipmentPlan.create.form.inventory.table.name'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'ean',
      headerName: t('amazonInboundShipmentPlan.create.form.inventory.table.ean'),
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      field: 'asin',
      headerName: t('amazonInboundShipmentPlan.create.form.inventory.table.asin'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'fnsku',
      headerName: t('amazonInboundShipmentPlan.create.form.inventory.table.fnsku'),
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      field: 'sku',
      headerName: t('amazonInboundShipmentPlan.create.form.inventory.table.sku'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'internalSku',
      headerName: t('amazonInboundShipmentPlan.create.form.inventory.table.internalSku'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'labelType',
      headerName: t('amazonInboundShipmentPlan.create.form.inventory.table.labelType'),
    },
    {
      field: 'recommendedQuantity',
      headerName: t('amazonInboundShipmentPlan.create.form.inventory.table.recommendedQuantity'),
      filter: false,
      sort: 'desc',
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanInventoryRecord,
          AmazonInboundShipmentPlanInventoryRecord['recommendedQuantity']
        >
      ) => {
        if (params.value) {
          return { backgroundColor: `${theme.colors.warning}40` }
        }
      },
    },
  ])

  const getRowId = useMemo(
    () => (params: GetRowIdParams<AmazonInboundShipmentPlanInventoryRecord>) => {
      return params.data.uuid
    },
    []
  )

  const isRowSelectable = useCallback<IsRowSelectable<AmazonInboundShipmentPlanInventoryRecord>>(
    (params: IRowNode<AvailableInventory>) => {
      return (
        (params?.data?.isEligibleForInbound && params?.data?.hasApprovedWholesaleOrder) ?? false
      )
    },
    []
  )

  const rowSelection = useMemo<
    RowSelectionOptions<AmazonInboundShipmentPlanInventoryRecord>
  >(() => {
    return {
      mode: 'multiRow',
      isRowSelectable,
      selectAll: 'filtered',
    }
  }, [isRowSelectable])

  const selectionColumnDef = useMemo<SelectionColumnDef>(() => {
    return {
      minWidth: 40,
      maxWidth: 40,
      pinned: 'left',
      tooltipValueGetter: (params) => {
        const missingItems: string[] = []

        if (!params.data?.hasApprovedWholesaleOrder) {
          missingItems.push(
            t('amazonInboundShipmentPlan.create.form.inventory.table.tooltip.noWholesaleOrder')
          )
        }

        if (!params.data?.isEligibleForInbound) {
          missingItems.push(
            t('amazonInboundShipmentPlan.create.form.inventory.table.tooltip.ineligible')
          )
        }

        if (!params.data?.hasTaxCategory) {
          missingItems.push(
            t('amazonInboundShipmentPlan.create.form.inventory.table.tooltip.missingTaxCategory')
          )
        }

        if (missingItems.length > 0) {
          const title = t('amazonInboundShipmentPlan.create.form.inventory.table.tooltip.title')
          const listItems = missingItems.map((item) => `• ${item}`).join(' ')
          return `${title} ${listItems}`
        } else {
          return undefined
        }
      },
    }
  }, [])

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent<AmazonInboundShipmentPlanInventoryRecord>) => {
      const selectedRows = event.api.getSelectedRows()
      const availableInventory: AvailableInventory[] = form.getFieldValue('availableInventory')
      const selectedInventory: SelectedInventory[] = form.getFieldValue('selectedInventory')

      const currentSelectedInventory = selectedInventory.filter((item) => {
        return selectedRows.some((row) => row.uuid === item.uuid)
      })

      const newSelectedRows = selectedRows.filter((row) => {
        return !selectedInventory.some((item) => item.uuid === row.uuid)
      })

      const newSelectedInventory = newSelectedRows.map((row) => {
        const smallestPackagingUnit = row.packagingUnits.find((unit) => {
          return unit.quantity === 1
        })

        return {
          uuid: row.uuid,
          image: row.image,
          name: row.name,
          ean: row.ean,
          asin: row.asin,
          fnsku: row.fnsku,
          sku: row.sku,
          internalSku: row.internalSku,
          labelType: row.labelType,
          labeledByAmazon: false,
          prepInstructions: row.prepInstructions,
          recommendedQuantity: row.recommendedQuantity,
          smallestPackagingUnit,
          packagingUnits: row.packagingUnits,
        }
      })

      const selectedUuids = new Set(selectedRows.map((row) => row.uuid))

      const sortedAvailableInventory = [...availableInventory].sort((a, b) => {
        const aSelected = selectedUuids.has(a.uuid)
        const bSelected = selectedUuids.has(b.uuid)

        return bSelected ? 1 : aSelected ? -1 : 0
      })

      form.setFieldsValue({
        selectedInventory: [...currentSelectedInventory, ...newSelectedInventory],
        availableInventory: sortedAvailableInventory,
      })

      event.api.applyTransaction({
        update: sortedAvailableInventory,
      })
    },
    []
  )

  return (
    <Table<AmazonInboundShipmentPlanInventoryRecord>
      ref={gridRef}
      columnDefs={columnDefs}
      getRowId={getRowId}
      maintainColumnOrder
      maxTableRows={10}
      onSelectionChanged={handleSelectionChanged}
      rowData={rowData}
      rowSelection={rowSelection}
      selectionColumnDef={selectionColumnDef}
      showColumnChooser
      showUniversalSearch
    />
  )
})
