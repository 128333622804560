import { Button, Col, Descriptions, Divider, Flex, Form, Input, Row, Space, Typography } from 'antd'
import { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { WarehouseServiceProviderLocationFieldsFragment } from '../../../../generated/graphql'
import { descriptionsStyle } from '../../../products/components/styles'
import { ShipmentKind } from '../types'
import { AmazonInboundShipmentPlanCartonCaseTable } from './AmazonInboundShipmentPlanCartonCaseTable'
import { AmazonInboundShipmentPlanCartonMixedTable } from './AmazonInboundShipmentPlanCartonMixedTable'
import { AmazonInboundShipmentPlanCreateFormInstance } from './AmazonInboundShipmentPlanCreateForm'
import { AmazonInboundShipmentPlanStep } from './AmazonInboundShipmentPlanCreateFormCollapse'
import { AmazonInboundShipmentPlanToSModal } from './AmazonInboundShipmentPlanToSModal'

type AmazonInboundShipmentPlanSummarySectionProps = {
  locations: WarehouseServiceProviderLocationFieldsFragment[]
  setCurrentStep: (currentStep: SetStateAction<AmazonInboundShipmentPlanStep>) => void
  submitting: boolean
}

export const AmazonInboundShipmentPlanSummarySection = ({
  locations,
  setCurrentStep,
  submitting,
}: AmazonInboundShipmentPlanSummarySectionProps) => {
  const { t } = useTranslation('inventory')

  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()
  const shipmentKind: AmazonInboundShipmentPlanCreateFormInstance['shipmentKind'] =
    form.getFieldValue('shipmentKind')

  const handleBackClick = () => {
    setCurrentStep(AmazonInboundShipmentPlanStep.TRANSPORT)
  }

  return (
    <Flex vertical gap={8}>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <Flex vertical gap={8}>
            <AmazonInboundShipmentPlanSummaryDetails locations={locations} />
          </Flex>
        </Col>
        <Col xs={24} lg={12}>
          <AmazonInboundShipmentPlanNoteFormField />
        </Col>
        <Col xs={24}>
          <Divider orientation="left">Contents</Divider>
          {shipmentKind === ShipmentKind.CASE ? (
            <AmazonInboundShipmentPlanCartonCaseTable />
          ) : (
            <AmazonInboundShipmentPlanCartonMixedTable />
          )}
        </Col>
      </Row>
      <Flex justify="end">
        <Space>
          <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
            <Button onClick={handleBackClick}>
              {t('shared.button.back', { ns: 'translation' })}
            </Button>
          </Form.Item>
          <AmazonInboundShipmentPlanToSModal submitting={submitting} />
        </Space>
      </Flex>
    </Flex>
  )
}

const AmazonInboundShipmentPlanSummaryDetails = ({
  locations,
}: {
  locations: WarehouseServiceProviderLocationFieldsFragment[]
}) => {
  const { t } = useTranslation('inventory')

  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()
  const values: AmazonInboundShipmentPlanCreateFormInstance = form.getFieldsValue(true)

  const selectedLocation = locations.find(
    (location) => location.uuid === values.shipFromLocationUuid
  )

  return (
    <Descriptions {...descriptionsStyle}>
      <Descriptions.Item label={t('amazonInboundShipmentPlan.create.form.summary.shipFrom')}>
        {selectedLocation ? (
          <Flex vertical gap={4}>
            <Typography.Text>{selectedLocation.name}</Typography.Text>
            <Typography.Text>{selectedLocation.addressLine1}</Typography.Text>
            <Typography.Text>
              {selectedLocation.postalCode} {selectedLocation.city}, {selectedLocation.countryCode}
            </Typography.Text>
          </Flex>
        ) : (
          '-'
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('amazonInboundShipmentPlan.create.form.summary.shippingMode.label')}
      >
        <Typography.Text>
          {t(`amazonInboundShipmentPlan.create.form.summary.shippingMode.${values.shippingMode}`)}
        </Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item label={t('amazonInboundShipmentPlan.create.form.summary.shippingCarrier')}>
        <Typography.Text>
          {t(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            `amazonInboundShipmentPlan.create.form.transport.shippingCarrier.${values.shippingCarrier}`
          )}
        </Typography.Text>
      </Descriptions.Item>
    </Descriptions>
  )
}

const AmazonInboundShipmentPlanNoteFormField = () => {
  const { t } = useTranslation('inventory')

  return (
    <Form.Item<AmazonInboundShipmentPlanCreateFormInstance>
      name="note"
      label={t('amazonInboundShipmentPlan.create.form.summary.note.label')}
      style={{ marginBottom: 0 }}
    >
      <Input.TextArea
        placeholder={t('amazonInboundShipmentPlan.create.form.summary.note.placeholder')}
        rows={5}
      />
    </Form.Item>
  )
}
