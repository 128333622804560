import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import CategorySearch from './components/CategorySearch'
import { TicketsCollapse } from './components/TicketsCollapse'

const TicketCreateView = () => {
  const { t, ready } = useTranslation(['ticket'])

  if (!ready) {
    return <LoadingSpinner />
  }

  return (
    <>
      <SubHeader heading={t('ticketCreateView.heading')} withBackButton onBackUrl="/tickets" />
      <ViewContainer centered>
        <Space direction="vertical" size="large" style={{ width: '100%', maxWidth: '840px' }}>
          <CategorySearch />
          <TicketsCollapse />
        </Space>
      </ViewContainer>
    </>
  )
}

export default TicketCreateView
