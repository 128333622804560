import { Badge, Space } from 'antd'
import { MapPin } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { LinkButton } from '../../../components/LinkButton'
import { StyledTabs } from '../../../components/Tabs'
import {
  AmazonInboundShipmentPlansQuery,
  ShipmentPlanLifecycleStatus,
} from '../../../generated/graphql'
import { useBreakpoints } from '../../../hooks/useBreakpoints'
import { AmazonInboundShipmentPlanCreationModal } from './components/AmazonInboundShipmentPlanCreationModal'
import { AmazonInboundShipmentPlanDetailedTable } from './components/AmazonInboundShipmentPlanDetailedTable'
import { AmazonInboundShipmentPlanDiscrepancies } from './components/AmazonInboundShipmentPlanDiscrepancies'
import { AmazonInboundShipmentPlanSimpleTable } from './components/AmazonInboundShipmentPlanSimpleTable'

const AmazonInboundShipmentPlanTableStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  FINISHED: 'finished',
  CANCELLED: 'cancelled',
  DISCREPANCIES: 'discrepancies',
} as const

export type AmazonInboundShipmentPlan =
  AmazonInboundShipmentPlansQuery['amazonInboundShipmentPlans'][number]

export const AmazonInboundShipmentPlansView = () => {
  const { t } = useTranslation('inventory')
  const navigate = useNavigate()
  const breakpoints = useBreakpoints()
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')

  if (!status || !Object.values(AmazonInboundShipmentPlanTableStatus).includes(status)) {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('status', AmazonInboundShipmentPlanTableStatus.PENDING)
    return <Navigate to={`?${searchParams}`} replace />
  }

  return (
    <>
      <SubHeader
        heading={t('amazonInboundShipmentPlan.heading')}
        rightContent={
          <Space>
            <LinkButton icon={<MapPin size={16} />} to={'/settings/warehousing'}>
              {breakpoints.md ? t('amazonInboundShipmentPlan.button.warehouses') : null}
            </LinkButton>
            <AmazonInboundShipmentPlanCreationModal />
          </Space>
        }
      />
      <ViewContainer>
        <StyledTabs
          type="card"
          activeKey={status}
          onChange={(path) => navigate(`/amazon/shipment-plans?status=${path}`)}
          items={[
            {
              label: <Badge color="yellow" text={t('amazonInboundShipmentPlan.list.pending')} />,
              key: AmazonInboundShipmentPlanTableStatus.PENDING,
              children: (
                <AmazonInboundShipmentPlanSimpleTable
                  lifecycleStatus={ShipmentPlanLifecycleStatus.CREATED}
                />
              ),
            },
            {
              label: <Badge color="green" text={t('amazonInboundShipmentPlan.list.approved')} />,
              key: AmazonInboundShipmentPlanTableStatus.APPROVED,
              children: (
                <AmazonInboundShipmentPlanDetailedTable
                  lifecycleStatus={ShipmentPlanLifecycleStatus.IN_PROGRESS}
                />
              ),
            },
            {
              label: <Badge color="blue" text={t('amazonInboundShipmentPlan.list.closed')} />,
              key: AmazonInboundShipmentPlanTableStatus.FINISHED,
              children: (
                <AmazonInboundShipmentPlanDetailedTable
                  lifecycleStatus={ShipmentPlanLifecycleStatus.FINISHED}
                />
              ),
            },
            {
              label: <Badge color="grey" text={t('amazonInboundShipmentPlan.list.cancelled')} />,
              key: AmazonInboundShipmentPlanTableStatus.CANCELLED,
              children: (
                <AmazonInboundShipmentPlanSimpleTable
                  lifecycleStatus={ShipmentPlanLifecycleStatus.CANCELLED}
                />
              ),
            },
            {
              label: <Badge color="red" text={t('amazonInboundShipmentPlan.list.discrepancies')} />,
              key: AmazonInboundShipmentPlanTableStatus.DISCREPANCIES,
              children: <AmazonInboundShipmentPlanDiscrepancies />,
            },
          ]}
        />
      </ViewContainer>
    </>
  )
}
