import { useMutation } from '@apollo/client'
import {
  CellEditRequestEvent,
  ColDef,
  ColGroupDef,
  GetContextMenuItems,
  GetRowIdFunc,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { Badge, Button, Flex, Popconfirm, Space, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { Eye, ImageOff, PenLine, Trash } from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import { Flag } from '../../../../../components/Flag'
import { LinkButton } from '../../../../../components/LinkButton'
import { GridImage, GridImageFallback } from '../../../../../components/Table/GridImage'
import { Table } from '../../../../../components/Table/Table'
import {
  AmazonListingChangeRequestFieldsFragment,
  CreateAmazonListingDocument,
  DeleteSalePriceDocument,
  ExternalAmazonListingStatus,
  GetAllAmazonProductsDocument,
  GetAllAmazonProductsQuery,
  GetProductVariantDocument,
  InternalAmazonListingStatus,
  Marketplace,
  PanEuOfferStatus,
  UpdateInternalSkuDocument,
} from '../../../../../generated/graphql'
import { formatMoney } from '../../../../../helpers/formatMoney'
import { formatPercentage } from '../../../../../helpers/formatPercentage'
import { getMarketplaceDomain } from '../../../../../helpers/getMarketplaceDomain'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { TablePersistKey } from '../../../../../stores/useTableStore'
import { AmazonListingExternalStatusTag } from '../AmazonListingExternalStatusTag'
import { AmazonListingInternalStatusTag } from '../AmazonListingInternalStatusTag'
import { DownloadFnskuLabelsModal } from '../DownloadFnskuLabelsModal'
import { EditAmazonListingPriceModal } from '../EditAmazonListingPriceModal'
import { EditAmazonListingSalePriceModal } from '../EditAmazonListingSalePriceModal'
import { EditAmazonListingSubscribeAndSaveModal } from '../EditAmazonListingSubscribeAndSaveModal/EditAmazonListingSubscribeAndSaveModal'
import { getIsOutOfStock } from './helpers'

type Product = GetAllAmazonProductsQuery['products'][number]
type ProductVariant = NonNullable<Product['productVariants']>[number]
type AmazonProduct = NonNullable<ProductVariant['amazonProducts']>[number]
type AmazonListing = NonNullable<AmazonProduct['amazonListings']>[number]

export type AmazonProductTableRecord = {
  productUuid: string
  productVariantUuid: string
  amazonProductUuid: string
  amazonListingUuid: string
  internalSku: string | null
  ean: string | null
  asin: string
  sku: string
  fnsku: string | null
  marketplace: AmazonListing['marketplace']
  mainImage: string | null
  title: string | null
  recommendedRetailPrice: number | null
  salePrice: number | null
  saleStartDate: string | null
  saleEndDate: string | null
  subscribeAndSave: number | null
  changeRequests: AmazonListingChangeRequestFieldsFragment[]
  mainCategory: string | null
  subCategory: string | null
  internalStatus: AmazonListing['internalStatus']
  externalStatus: AmazonListing['externalStatus']
  isBuyBoxWinner: boolean
  panEUOfferStatus: AmazonListing['panEUOfferStatus']
  labelType: AmazonListing['labelType']
  isEligibleForInbound: boolean
  isFbm: boolean
  inventory: AmazonListing['inventory']
  searchIdentifiers: string
  mostRecentShipmentDestination: string | null
}

type AmazonProductTableProps = {
  dataSource: AmazonProductTableRecord[] | undefined
}

export const AmazonProductTable = ({ dataSource }: AmazonProductTableProps) => {
  const [loading, setLoading] = useState(false)

  const user = useGlobalStore((state) => state.user)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation('inventory')
  const navigate = useNavigate()

  const [createAmazonListing] = useMutation(CreateAmazonListingDocument)
  const [updateInternalSku] = useMutation(UpdateInternalSkuDocument)
  const [deleteSalePrice] = useMutation(DeleteSalePriceDocument)

  const handleDeleteSalePrice = async (uuid: string) => {
    setLoading(true)

    try {
      await deleteSalePrice({
        variables: { uuid: uuid },
        optimisticResponse: {
          deleteSalePrice: { uuid: uuid },
        },
        update: (cache, { data }) => {
          const deletedUuid = data?.deleteSalePrice?.uuid

          if (!deletedUuid) {
            return
          }

          const cacheId = cache.identify({ __typename: 'AmazonListing', uuid: deletedUuid })

          cache.modify({
            id: cacheId,
            fields: {
              salePrice: () => null,
              saleStartDate: () => null,
              saleEndDate: () => null,
            },
          })
        },
      })

      toast.success(t('product.details.amazonListings.salePriceDeletion.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setLoading(false)
    }
  }

  const [columnDefs] = useState<
    (ColDef<AmazonProductTableRecord> | ColGroupDef<AmazonProductTableRecord>)[]
  >([
    {
      headerName: t('product.table.actions'),
      filter: false,
      sortable: false,
      suppressNavigable: true,
      suppressSizeToFit: true,
      minWidth: 96,
      maxWidth: 96,
      cellRenderer: (params: ICellRendererParams<AmazonProductTableRecord>) => {
        if (params.node.group) {
          return null
        }

        return (
          <Space style={{ marginTop: 6 }}>
            {params.data?.internalStatus !== InternalAmazonListingStatus.DELETED && (
              <Tooltip title={t('amazonInboundShipmentPlan.tooltip.details')}>
                <LinkButton
                  to={`/products/${params.node?.data?.productUuid}/variants/${params.node?.data?.productVariantUuid}/amazon/${params.node?.data?.amazonListingUuid}`}
                  icon={<Eye size={16} />}
                />
              </Tooltip>
            )}
            {params.data?.labelType === 'FNSKU' && (
              <DownloadFnskuLabelsModal
                sku={params.data?.sku}
                marketplace={params.data?.marketplace.name}
                withButton={true}
              />
            )}
          </Space>
        )
      },
    },
    {
      field: 'mainImage',
      headerName: '',
      maxWidth: 64,
      sortable: false,
      resizable: false,
      filter: false,
      suppressColumnsToolPanel: true,
      suppressMovable: true,
      cellRenderer: (
        params: ICellRendererParams<AmazonProductTableRecord, AmazonProductTableRecord['mainImage']>
      ) => {
        if (params.node.group) {
          return null
        }

        const src = params.value ?? null

        return src ? (
          <GridImage src={src} width={40} height={40} preview={false} />
        ) : (
          <GridImageFallback>
            <ImageOff />
          </GridImageFallback>
        )
      },
    },
    {
      field: 'internalSku',
      headerName: t('product.table.internalSku'),
      headerTooltip: t('product.table.tooltip.internalSku'),
      filter: 'agTextColumnFilter',
      editable: true,
      suppressPaste: true,
      cellRenderer: (
        params: ICellRendererParams<
          AmazonProductTableRecord,
          AmazonProductTableRecord['internalSku']
        >
      ) => {
        if (params.node.group) {
          return null
        }

        return (
          <Flex align="center" gap={8}>
            <PenLine size={16} />
            {params.value ?? t('shared.button.edit', { ns: 'translation' })}
          </Flex>
        )
      },
    },
    {
      field: 'ean',
      headerName: t('product.table.ean'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'asin',
      headerName: t('product.table.asin'),
      filter: 'agTextColumnFilter',
      enableRowGroup: true,
      getQuickFilterText: (params) => {
        return params.data?.searchIdentifiers
      },
    },
    {
      field: 'title',
      headerName: t('product.table.title'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'sku',
      headerName: t('product.table.sku'),
      sort: 'asc',
      filter: 'agTextColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<AmazonProductTableRecord, AmazonProductTableRecord['sku']>
      ) => {
        const marketplace = params.data?.marketplace.name

        if (!marketplace) {
          return null
        }

        const amazonHyperlink = `https://amazon.${getMarketplaceDomain(marketplace)}/dp/${
          params.data?.asin
        }`

        return (
          <Tooltip
            title={t('product.card.sku.tooltip', {
              domain: getMarketplaceDomain(marketplace),
            })}
            mouseEnterDelay={0.5}
          >
            <Typography.Link href={amazonHyperlink} target="_blank" rel="noopener noreferrer">
              {params.value}
            </Typography.Link>
          </Tooltip>
        )
      },
    },
    {
      field: 'fnsku',
      headerName: t('product.table.fnsku'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'marketplace.name',
      headerName: t('product.table.marketplace'),
      enableRowGroup: true,
      cellRenderer: (
        params: ICellRendererParams<
          AmazonProductTableRecord,
          AmazonProductTableRecord['marketplace']['name']
        >
      ) => {
        if (params.node.group && params.node.field !== 'marketplace.name') {
          return null
        }

        const countryCode = params.value as Marketplace

        return (
          <Space>
            <Flag code={countryCode} />
            <Typography.Text>Amazon.{getMarketplaceDomain(countryCode)}</Typography.Text>
          </Space>
        )
      },
    },
    {
      field: 'recommendedRetailPrice',
      headerName: t('product.table.rrp'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          AmazonProductTableRecord,
          AmazonProductTableRecord['recommendedRetailPrice']
        >
      ) => {
        if (params.node.group) {
          return null
        }

        if (!params.value) {
          return (
            <Flex align="center" gap={8}>
              <EditAmazonListingPriceModal
                amazonListing={{ ...params.data!, uuid: params.data?.amazonListingUuid }}
              />
              <Typography.Text type="secondary">
                {t('shared.button.edit', { ns: 'translation' })}
              </Typography.Text>
            </Flex>
          )
        } else {
          return (
            <Flex align="center" gap={8}>
              <EditAmazonListingPriceModal
                amazonListing={{ ...params.data!, uuid: params.data?.amazonListingUuid }}
              />
              <Typography.Text>
                {params.data?.marketplace.currency}{' '}
                {params.data?.recommendedRetailPrice?.toFixed(2) ?? '-'}
              </Typography.Text>
            </Flex>
          )
        }
      },
    },
    {
      field: 'salePrice',
      headerName: t('product.table.salePrice'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<AmazonProductTableRecord, AmazonProductTableRecord['salePrice']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (!params.data) {
          return null
        }

        if (!params.data?.salePrice) {
          return (
            <Flex align="center" gap={8}>
              <EditAmazonListingSalePriceModal
                amazonListing={{
                  uuid: params.data.amazonListingUuid,
                  recommendedRetailPrice: params.data.recommendedRetailPrice,
                  currency: params.data.marketplace.currency,
                  internalStatus: params.data.internalStatus,
                  externalStatus: params.data.externalStatus,
                  changeRequests: params.data.changeRequests,
                  saleEndDate: params.data.saleEndDate,
                }}
              />
              <Typography.Text type="secondary">
                {t('shared.button.edit', { ns: 'translation' })}
              </Typography.Text>
            </Flex>
          )
        } else {
          if (
            dayjs().isBetween(params.data?.saleStartDate, params.data?.saleEndDate) ||
            dayjs().isBefore(params.data?.saleStartDate)
          ) {
            return (
              <Flex align="center" gap={8}>
                <Popconfirm
                  placement="top"
                  title={t('product.details.amazonListings.popConfirm.title')}
                  onConfirm={() => handleDeleteSalePrice(params.data?.amazonListingUuid ?? '')}
                  okText={t('product.details.amazonListings.popConfirm.confirmButton')}
                  cancelText={t('product.details.amazonListings.popConfirm.cancelButton')}
                  okButtonProps={{ danger: true }}
                  cancelButtonProps={{ type: 'text' }}
                >
                  <Tooltip title={t('product.details.amazonListings.popConfirm.tooltip')}>
                    <Button
                      type="text"
                      danger
                      icon={<Trash size={16} />}
                      disabled={loading}
                      size="small"
                    />
                  </Tooltip>
                </Popconfirm>
                <Tooltip
                  title={`${dayjs(params.data?.saleStartDate).format('DD.MM.YYYY')} - ${dayjs(params.data?.saleEndDate).format('DD.MM.YYYY')}`}
                >
                  <Typography.Text>
                    {formatMoney(params.data?.salePrice, params.data?.marketplace.currency)}
                  </Typography.Text>
                </Tooltip>
              </Flex>
            )
          } else {
            return (
              <Flex align="center" gap={8}>
                <EditAmazonListingSalePriceModal
                  amazonListing={{
                    uuid: params.data.amazonListingUuid,
                    recommendedRetailPrice: params.data.recommendedRetailPrice,
                    currency: params.data.marketplace.currency,
                    internalStatus: params.data.internalStatus,
                    externalStatus: params.data.externalStatus,
                    changeRequests: params.data.changeRequests,
                    saleEndDate: params.data.saleEndDate,
                  }}
                />
                <Typography.Text type="secondary">
                  {t('shared.button.edit', { ns: 'translation' })}
                </Typography.Text>
              </Flex>
            )
          }
        }
      },
    },
    {
      field: 'internalStatus',
      headerName: t('product.table.internalStatus'),
      enableRowGroup: true,
      filterParams: {
        values: [
          InternalAmazonListingStatus.ACTIVE,
          InternalAmazonListingStatus.REVIEW,
          InternalAmazonListingStatus.PROCESSING,
          InternalAmazonListingStatus.DELETED,
        ],
        cellRenderer: (params: { value: InternalAmazonListingStatus }) => {
          switch (params.value) {
            case InternalAmazonListingStatus.ACTIVE:
              return t('shared.status.active', { ns: 'translation' })
            case InternalAmazonListingStatus.REVIEW:
              return t('shared.status.review', { ns: 'translation' })
            case InternalAmazonListingStatus.PROCESSING:
              return t('shared.status.processing', { ns: 'translation' })
            case InternalAmazonListingStatus.DELETED:
              return t('shared.status.deleted', { ns: 'translation' })
            default:
              return null
          }
        },
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonProductTableRecord,
          AmazonProductTableRecord['internalStatus']
        >
      ) => {
        if (params.value) {
          return <AmazonListingInternalStatusTag internalStatus={params.value} />
        }
      },
    },
    {
      field: 'externalStatus',
      headerName: t('product.table.externalStatus'),
      enableRowGroup: true,
      filterParams: {
        values: [
          ExternalAmazonListingStatus.ACTIVE,
          ExternalAmazonListingStatus.INACTIVE,
          ExternalAmazonListingStatus.SEARCH_SUPPRESSED,
          ExternalAmazonListingStatus.DETAIL_PAGE_REMOVED,
        ],
        cellRenderer: (params: { value: ExternalAmazonListingStatus }) => {
          switch (params.value) {
            case ExternalAmazonListingStatus.ACTIVE:
              return t('shared.status.active', { ns: 'translation' })
            case ExternalAmazonListingStatus.INACTIVE:
              return t('shared.status.inactive', { ns: 'translation' })
            case ExternalAmazonListingStatus.SEARCH_SUPPRESSED:
              return t('shared.status.searchSuppressed', { ns: 'translation' })
            case ExternalAmazonListingStatus.DETAIL_PAGE_REMOVED:
              return t('shared.status.detailPageRemoved', { ns: 'translation' })
            default:
              return null
          }
        },
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonProductTableRecord,
          AmazonProductTableRecord['externalStatus']
        >
      ) => {
        if (params.value) {
          return <AmazonListingExternalStatusTag externalStatus={params.value} />
        }
      },
    },
    {
      headerName: t('product.table.outOfStock'),
      filterParams: {
        values: [true, false],
        cellRenderer: (params: ICellRendererParams<AmazonProductTableRecord, boolean>) => {
          if (params.value === true) {
            return <Tag color="red">{t('shared.inventory.outOfStock', { ns: 'translation' })}</Tag>
          } else if (params.value === false) {
            return <Tag color="green">{t('shared.inventory.inStock', { ns: 'translation' })}</Tag>
          } else {
            return null
          }
        },
      },
      valueGetter: (params) => {
        return getIsOutOfStock(params.data)
      },
      valueFormatter: (params: ValueFormatterParams<AmazonProductTableRecord, boolean>) => {
        return params.value
          ? t('shared.inventory.outOfStock', { ns: 'translation' })
          : t('shared.inventory.inStock', { ns: 'translation' })
      },
      cellRenderer: (params: ICellRendererParams<AmazonProductTableRecord, boolean>) => {
        if (params.node.group) {
          return null
        }

        if (params.value === true) {
          return <Tag color="red">{t('shared.inventory.outOfStock', { ns: 'translation' })}</Tag>
        } else if (params.value === false) {
          return <Tag color="green">{t('shared.inventory.inStock', { ns: 'translation' })}</Tag>
        } else {
          return null
        }
      },
    },
    {
      field: 'panEUOfferStatus',
      headerName: t('product.table.panEUOfferStatus'),
      filterValueGetter: (
        params: ValueGetterParams<
          AmazonProductTableRecord,
          AmazonProductTableRecord['panEUOfferStatus']
        >
      ) => {
        if (!params.data) {
          return null
        }

        return params.data.panEUOfferStatus === PanEuOfferStatus.ACTIVE
          ? t('product.details.amazonListings.panEUOffer.active')
          : t('product.details.amazonListings.panEUOffer.inactive')
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonProductTableRecord,
          AmazonProductTableRecord['panEUOfferStatus']
        >
      ) => {
        if (params.node.group) {
          return null
        }

        if (!params.data?.panEUOfferStatus) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return params.value === PanEuOfferStatus.ACTIVE ? (
          <Space>
            <Badge color="green" />
            <Typography.Text>
              {t('product.details.amazonListings.panEUOffer.active')}
            </Typography.Text>
          </Space>
        ) : (
          <Space>
            <Badge color="red" />
            <Typography.Text>
              {t('product.details.amazonListings.panEUOffer.inactive')}
            </Typography.Text>
          </Space>
        )
      },
    },
    {
      field: 'mainCategory',
      headerName: t('product.table.mainCategory'),
    },
    {
      field: 'subCategory',
      headerName: t('product.table.subCategory'),
    },
    {
      field: 'subscribeAndSave',
      headerName: t('product.table.subscribeAndSave'),
      cellRenderer: (
        params: ICellRendererParams<
          AmazonProductTableRecord,
          AmazonProductTableRecord['subscribeAndSave']
        >
      ) => {
        const subscribeAndSave = params.value
        if (params.node.group) {
          return null
        }

        return (
          <Flex align="center" gap={8}>
            <EditAmazonListingSubscribeAndSaveModal
              subscribeAndSaveValue={subscribeAndSave}
              amazonProductUuid={params.data?.amazonProductUuid}
              internalStatus={params.data?.internalStatus}
              externalStatus={params.data?.externalStatus}
            />
            <Typography.Text>
              {!subscribeAndSave
                ? t('product.details.amazonProduct.subscribeAndSave.disabled')
                : formatPercentage(subscribeAndSave / 100)}
            </Typography.Text>
          </Flex>
        )
      },
    },
    {
      field: 'labelType',
      headerName: t('product.table.labelType'),
    },
    {
      valueGetter: (
        params: ValueGetterParams<
          AmazonProductTableRecord,
          AmazonProductTableRecord['isBuyBoxWinner']
        >
      ) => {
        if (!params.data) {
          return null
        }

        return params.data.isBuyBoxWinner
          ? t('product.details.amazonListings.isBuyBoxWinner.yes')
          : t('product.details.amazonListings.isBuyBoxWinner.no')
      },
      headerName: t('product.table.isBuyBoxWinner'),
    },
    {
      valueGetter: (
        params: ValueGetterParams<
          AmazonProductTableRecord,
          AmazonProductTableRecord['isEligibleForInbound']
        >
      ) => {
        if (!params.data) {
          return null
        }

        return params.data?.isEligibleForInbound
          ? t('product.details.amazonListings.isBuyBoxWinner.yes')
          : t('product.details.amazonListings.isBuyBoxWinner.no')
      },
      field: 'isEligibleForInbound',
      headerName: t('product.table.isEligibleForInbound'),
    },
    {
      field: 'mostRecentShipmentDestination',
      headerName: t('product.table.mostRecentShipmentDestination'),
    },
  ])

  const onCellEditRequest = useCallback(
    async (event: CellEditRequestEvent<AmazonProductTableRecord>) => {
      const field = event.colDef.field

      if (!field) {
        return false
      }

      if (event.newValue === event.oldValue) {
        return false
      }

      switch (field) {
        case 'internalSku': {
          try {
            const value = event.newValue?.trim() || null

            event.api.applyTransaction({
              update: [{ ...event.data, [field]: value }],
            })

            await updateInternalSku({
              variables: {
                uuid: event.data.productVariantUuid,
                internalSku: value,
              },
              optimisticResponse: {
                updateProductVariant: {
                  internalSku: value,
                },
              },
              update: (cache, { data }) => {
                const updatedProductVariant = data?.updateProductVariant
                const cachedQuery = cache.readQuery({
                  query: GetProductVariantDocument,
                  variables: { uuid: event.data.productVariantUuid },
                })

                if (updatedProductVariant && cachedQuery) {
                  cache.writeQuery({
                    query: GetProductVariantDocument,
                    variables: { uuid: event.data.productVariantUuid },
                    data: {
                      productVariant: {
                        ...cachedQuery.productVariant,
                        internalSku: updatedProductVariant.internalSku,
                      },
                    },
                  })
                }
              },
              refetchQueries: [
                {
                  query: GetAllAmazonProductsDocument,
                  variables: {
                    uuid: selectedCompany?.uuid,
                  },
                },
              ],
            })
            toast.success(t('product.details.productVariant.internalSkuModal.success'))
          } catch (error) {
            event.api.applyTransaction({
              update: [{ ...event.data, [field]: event.oldValue }],
            })
            console.error(error)
            toast.error(t('shared.error.message', { ns: 'translation' }))
          }

          break
        }

        default:
          break
      }
    },
    []
  )

  const getContextMenuItems: GetContextMenuItems<AmazonProductTableRecord> = useCallback(
    (params) => {
      return [
        {
          name: t('product.table.contextMenu.showDetails'),
          disabled:
            !params.node?.data?.productUuid ||
            !params.node?.data?.productVariantUuid ||
            !params.node?.data?.amazonListingUuid,
          action: () => {
            return navigate(
              `/products/${params.node?.data?.productUuid}/variants/${params.node?.data?.productVariantUuid}/amazon/${params.node?.data?.amazonListingUuid}`
            )
          },
        },
        ...(user?.isAdmin
          ? [
              {
                name: t('product.table.contextMenu.createFbmListing'),
                disabled:
                  params.node?.data?.isFbm ||
                  params.node?.data?.marketplace.name !== Marketplace.DE,
                action: async () => {
                  const rowData = params.node?.data

                  if (!rowData) {
                    return
                  }

                  await createAmazonListing({
                    variables: {
                      amazonProductUuid: rowData.amazonProductUuid,
                      amazonListing: {
                        sku: rowData.sku + 'FBM',
                        marketplace: rowData.marketplace.name,
                        labelType: null,
                        isFbm: true,
                      },
                    },
                    onCompleted: (data) => {
                      toast.success(t('product.table.contextMenu.createFbmListingSuccess'))
                      navigate(
                        `/products/${rowData.productUuid}/variants/${rowData.productVariantUuid}/amazon/${data.createAmazonListing.uuid}`
                      )
                    },
                    onError: () => {
                      toast.error(t('product.table.contextMenu.createFbmListingError'))
                    },
                  })
                },
              },
            ]
          : []),
        'separator',
        'copy',
      ]
    },
    []
  )

  const getRowId = useMemo<GetRowIdFunc<AmazonProductTableRecord>>(
    () => (params) => params.data?.amazonListingUuid,
    []
  )

  return (
    <Table<AmazonProductTableRecord>
      columnDefs={columnDefs}
      fullHeight
      getContextMenuItems={getContextMenuItems}
      getRowId={getRowId}
      groupDefaultExpanded={-1}
      onCellEditRequest={onCellEditRequest}
      persist={{
        key: TablePersistKey.AMAZON_PRODUCT,
        storage: localStorage,
      }}
      readOnlyEdit
      rowData={dataSource}
      rowGroupPanelShow="always"
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
      storeSearchInSearchParams
    />
  )
}
