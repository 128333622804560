import { useQuery } from '@apollo/client'
import {
  ColDef,
  ColGroupDef,
  GetContextMenuItems,
  GetRowIdParams,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { Flex, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { Eye } from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components/FormattedDate'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { LinkButton } from '../../../../components/LinkButton'
import { MoreItemsIndicator } from '../../../../components/MoreItemsIndicator'
import { Table } from '../../../../components/Table/Table'
import {
  AmazonInboundShipmentPlansDocument,
  ShipmentPlanLifecycleStatus,
} from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { AmazonInboundShipmentPlan } from '../AmazonInboundShipmentPlansView'
import { getAmazonInboundShipmentPlanStatuses } from '../helpers/getAmazonInboundShipmentPlanStatuses'
import { getTransportName } from '../helpers/getTransportName'

const columnKeys = [
  '1',
  'marketplace',
  'areCasesRequired',
  'carrierName',
  'note',
  'createdAt',
  'updatedAt',
]

type ShipmentPlanWithoutLabelListProps = {
  lifecycleStatus: ShipmentPlanLifecycleStatus
}

export const AmazonInboundShipmentPlanSimpleTable = ({
  lifecycleStatus: lifecycleStatus,
}: ShipmentPlanWithoutLabelListProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation(['inventory', 'translation'])

  const { loading, error, data } = useQuery(AmazonInboundShipmentPlansDocument, {
    variables: {
      filter: {
        companyUuid: selectedCompany?.uuid,
        lifecycleStatus,
      },
    },
  })

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const amazonInboundShipmentPlans = data?.amazonInboundShipmentPlans
  const statuses = getAmazonInboundShipmentPlanStatuses(lifecycleStatus)

  const dataSource = amazonInboundShipmentPlans
    ? lifecycleStatus === ShipmentPlanLifecycleStatus.CREATED
      ? amazonInboundShipmentPlans
      : amazonInboundShipmentPlans.map((amazonInboundShipmentPlan) => ({
          ...amazonInboundShipmentPlan,
          shipments:
            amazonInboundShipmentPlan.inboundShipments?.filter((inboundShipment) =>
              statuses.includes(inboundShipment.shipmentStatus)
            ) ?? [],
        }))
    : undefined

  const [columnDefs] = useState<
    (ColDef<AmazonInboundShipmentPlan> | ColGroupDef<AmazonInboundShipmentPlan>)[]
  >([
    {
      lockPosition: 'left',
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      minWidth: 56,
      maxWidth: 56,
      cellRenderer: (params: ICellRendererParams<AmazonInboundShipmentPlan>) => (
        <Tooltip title={t('amazonInboundShipmentPlan.tooltip.details')}>
          <LinkButton icon={<Eye size={16} />} to={`/amazon/shipment-plans/${params.data?.uuid}`} />
        </Tooltip>
      ),
    },
    {
      headerName: t('amazonInboundShipmentPlan.table.internalSkus'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlan>) => {
        if (!params.data) {
          return null
        }

        return params.data.cartons
          .flatMap((carton) =>
            carton.items.map((item) => item.amazonListing.amazonProduct.productVariant.internalSku)
          )
          .filter(Boolean)
      },
      cellRenderer: (params: ICellRendererParams<AmazonInboundShipmentPlan, string[] | null>) => {
        const internalSkus = params.value

        if (!internalSkus) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = internalSkus.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{internalSkus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={internalSkus} />}
          </Flex>
        )
      },
    },
    {
      headerName: t('amazonInboundShipmentPlan.table.skus'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlan>) => {
        if (!params.data) {
          return null
        }

        return params.data.cartons
          .flatMap((carton) => carton.items.map((item) => item.amazonListing.sku))
          .filter(Boolean)
      },
      cellRenderer: (params: ICellRendererParams<AmazonInboundShipmentPlan, string[] | null>) => {
        const skus = params.value

        if (!skus) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = skus.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{skus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={skus} />}
          </Flex>
        )
      },
    },
    {
      headerName: t('amazonInboundShipmentPlan.table.marketplace'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlan>) => {
        if (!params.data) {
          return null
        }

        return `Amazon.${getMarketplaceDomain(params.data.marketplace)}`
      },
    },
    {
      headerName: t('amazonInboundShipmentPlan.table.shipmentKind'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlan>) => {
        return params.data?.areCasesRequired ? 'CASE' : 'MIXED'
      },
    },
    {
      headerName: t('amazonInboundShipmentPlan.table.deliveryService'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlan>) => {
        return params.data?.carrierName ? getTransportName(params.data.carrierName) : null
      },
    },
    {
      field: 'note',
      headerName: t('amazonInboundShipmentPlan.table.note.value'),
      filter: 'agTextColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<AmazonInboundShipmentPlan, AmazonInboundShipmentPlan['note']>
      ) => {
        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Tooltip title={params.value}>
            <Typography.Text ellipsis>{params.value}</Typography.Text>
          </Tooltip>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: t('amazonInboundShipmentPlan.table.creationDate'),
      filter: false,
      initialSort: 'desc',
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlan,
          AmazonInboundShipmentPlan['createdAt']
        >
      ) => {
        return <FormattedDate date={params.value} withRelativeTime layout="horizontal" />
      },
    },
    {
      field: 'updatedAt',
      headerName: t('amazonInboundShipmentPlan.table.lastUpdate'),
      filter: false,
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlan,
          AmazonInboundShipmentPlan['updatedAt']
        >
      ) => {
        return <FormattedDate date={params.value} withRelativeTime layout="horizontal" />
      },
    },
  ])

  const getContextMenuItems: GetContextMenuItems<AmazonInboundShipmentPlan> = useCallback(
    (params) => [
      {
        name: t('amazonInboundShipmentPlan.tooltip.details'),
        action: () => window.open(`/amazon/shipment-plans/${params.node?.data?.uuid}`),
      },
      'separator',
      'export',
    ],
    []
  )

  const getRowId = useMemo(
    () => (params: GetRowIdParams<AmazonInboundShipmentPlan>) => params.data.uuid,
    []
  )

  return (
    <Table<AmazonInboundShipmentPlan>
      columnDefs={columnDefs}
      context={{ dataSource }}
      defaultExcelExportParams={{
        fileName: `${lifecycleStatus.toLowerCase()}-amazon-shipment-plans-${dayjs().format(
          'YYYY-MM-DD'
        )}`,
        columnKeys,
      }}
      getContextMenuItems={getContextMenuItems}
      getRowId={getRowId}
      loading={loading}
      rowData={dataSource}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
    />
  )
}
