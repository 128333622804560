import { DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useDateRangePresets } from '../../../../hooks/useDateRangePresets'

const { RangePicker } = DatePicker

const dashboardDateRanges = [1, 7, 30, 90]

type DashboardFinanceHeaderDatePickerProps = {
  dateRange: [Dayjs, Dayjs]
  setDateRange: React.Dispatch<React.SetStateAction<[Dayjs, Dayjs]>>
}

export const DashboardFinanceHeaderDatePicker = ({
  dateRange,
  setDateRange,
}: DashboardFinanceHeaderDatePickerProps) => {
  const presets = useDateRangePresets()

  const disabledDate = (current: Dayjs) => {
    const tooEarly =
      dateRange[1].diff(current, 'days') >= dashboardDateRanges[dashboardDateRanges.length - 1]!
    const tooLate = current > dayjs().subtract(1, 'day')

    return tooEarly || tooLate
  }

  return (
    <RangePicker
      value={dateRange}
      onCalendarChange={(values) => {
        if (values?.[0] && values?.[1]) {
          setDateRange([values[0].startOf('day'), values[1].endOf('day')])
        }
      }}
      allowClear={false}
      disabledDate={disabledDate}
      format="DD.MM.YY"
      placement="bottomRight"
      presets={presets}
    />
  )
}
