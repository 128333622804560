import { InternalAmazonListingStatus } from '../../../../generated/graphql'

export const getAmazonListingInternalStatusColor = (
  internalStatus: InternalAmazonListingStatus
) => {
  switch (internalStatus) {
    case InternalAmazonListingStatus.ACTIVE:
      return 'green'
    case InternalAmazonListingStatus.REVIEW:
      return 'orange'
    case InternalAmazonListingStatus.PROCESSING:
      return 'yellow'
    case InternalAmazonListingStatus.DELETED:
      return 'red'
  }
}
