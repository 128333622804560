type ImageFormat = 'JPG' | 'PNG'

export function isValidImage(file: File, allowedFormats: ImageFormat[]): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result instanceof ArrayBuffer) {
        const buffer = new Uint8Array(event.target.result)

        for (const format of allowedFormats) {
          if (isValidFormat(buffer, format)) {
            return resolve(true)
          }
        }

        return resolve(false)
      } else {
        return reject(new Error('Error reading file data.'))
      }
    }

    reader.onerror = () => reject(new Error('An error occurred while reading the file.'))

    reader.readAsArrayBuffer(file)
  })
}

function isValidFormat(buffer: Uint8Array, format: ImageFormat): boolean {
  if (format === 'JPG') {
    const magicNumberStart = (buffer[0] << 8) | buffer[1]
    const magicNumberEnd = (buffer[buffer.length - 2] << 8) | buffer[buffer.length - 1]
    return magicNumberStart === 0xffd8 && magicNumberEnd === 0xffd9
  }

  if (format === 'PNG') {
    const magicNumberPNG = buffer.slice(0, 4)
    return (
      magicNumberPNG[0] === 0x89 &&
      magicNumberPNG[1] === 0x50 &&
      magicNumberPNG[2] === 0x4e &&
      magicNumberPNG[3] === 0x47
    )
  }

  return false
}
