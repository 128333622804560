import { DatePicker, Form } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'
import { useGlobalStore } from '../../../../../../../stores/useGlobalStore'

export const WholesaleOrderBillingDocumentDueDatePicker = () => {
  const user = useGlobalStore((state) => state.user)

  const { t } = useTranslation('wholesale-order-billing-document', {
    keyPrefix: 'dueDate',
  })

  return (
    <Form.Item<CreateWholesaleOrderBillingDocumentFormValues>
      name="dueDate"
      label={t('label')}
      tooltip={t('description')}
      rules={[{ required: true, message: t('error.required') }]}
    >
      <DatePicker
        format="DD.MM.YYYY"
        disabledDate={(current) => {
          if (user?.isAdmin) {
            return false
          }

          return current && current < dayjs().startOf('day')
        }}
        style={{ width: '100%' }}
      />
    </Form.Item>
  )
}
