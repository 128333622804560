import dayjs, { Dayjs } from 'dayjs'
import { GetPerformanceMetricsQuery } from '../../../../generated/graphql'
import { getAllDates } from '../../../../helpers/dateCalculations'

export function addMissingDatesToData(
  dateRange: [Dayjs, Dayjs],
  data: GetPerformanceMetricsQuery | undefined
) {
  if (!data || !dateRange) {
    return []
  }

  const allDates = getAllDates(
    dateRange[0].startOf('day') as unknown as Date,
    dateRange[1].endOf('day') as unknown as Date
  )

  return allDates.flatMap((date) => {
    const foundData = data.performanceMetrics.find((metric) => {
      if (dayjs(metric?.date).isSame(dayjs(date), 'day')) {
        return metric
      }
    })

    if (foundData) {
      return foundData
    } else {
      return {
        date,
        sales: 0,
        refunds: 0,
        sponsoredDisplayCosts: 0,
        sponsoredProductsCosts: 0,
      }
    }
  })
}
