import {
  ColoredAmazonOrder,
  DatedPerformanceOverview,
  MarketplacePerformanceDetails,
  ProductPerformanceDetails,
} from './preparePerformanceOverview'

type PerformanceOverview = {
  orders: ColoredAmazonOrder[]
  totalShipped: number
  totalPending: number
  totalCanceled: number
  totalRevenue: number
  pendingRevenue: number
  performance: {
    products: ProductPerformanceDetails[]
    marketplaces: MarketplacePerformanceDetails[]
  }
}

export function accumulatePerformanceOverview(
  data: DatedPerformanceOverview[]
): PerformanceOverview {
  const dataClone = structuredClone(data)

  const accumulatedPerformanceOverview = dataClone.reduce<PerformanceOverview>(
    (previousValue, currentValue) => {
      return {
        orders: [...previousValue.orders, ...currentValue.orders],
        totalShipped: previousValue.totalShipped + currentValue.totalShipped,
        totalPending: previousValue.totalPending + currentValue.totalPending,
        totalCanceled: previousValue.totalCanceled + currentValue.totalCanceled,
        totalRevenue: previousValue.totalRevenue + currentValue.totalRevenue,
        pendingRevenue: previousValue.pendingRevenue + currentValue.pendingRevenue,
        performance: {
          products: previousValue.performance.products.reduce<ProductPerformanceDetails[]>(
            (skuPreviousValue, skuCurrentValue) => {
              const skuIndex = skuPreviousValue.findIndex(
                (item) => item.amazonSku === skuCurrentValue.amazonSku
              )
              if (skuIndex === -1) {
                skuPreviousValue.push(skuCurrentValue)
              } else {
                if (!skuPreviousValue[skuIndex]) {
                  skuPreviousValue[skuIndex] = skuCurrentValue
                }

                skuPreviousValue[skuIndex].totalShipped += skuCurrentValue.totalShipped
                skuPreviousValue[skuIndex].totalPending += skuCurrentValue.totalPending
                skuPreviousValue[skuIndex].totalCanceled += skuCurrentValue.totalCanceled
                skuPreviousValue[skuIndex].totalRevenue += skuCurrentValue.totalRevenue
                skuPreviousValue[skuIndex].pendingRevenue += skuCurrentValue.pendingRevenue
              }
              return skuPreviousValue
            },
            currentValue.performance.products
          ),
          marketplaces: previousValue.performance.marketplaces.reduce<
            MarketplacePerformanceDetails[]
          >((marketplacePreviousValue, marketplaceCurrentValue) => {
            const marketplaceIndex = marketplacePreviousValue.findIndex(
              (item) => item.marketplace === marketplaceCurrentValue.marketplace
            )
            if (marketplaceIndex === -1) {
              marketplacePreviousValue.push(marketplaceCurrentValue)
            } else {
              if (!marketplacePreviousValue[marketplaceIndex]) {
                marketplacePreviousValue[marketplaceIndex] = marketplaceCurrentValue
              }

              marketplacePreviousValue[marketplaceIndex].totalShipped +=
                marketplaceCurrentValue.totalShipped
              marketplacePreviousValue[marketplaceIndex].totalPending +=
                marketplaceCurrentValue.totalPending
              marketplacePreviousValue[marketplaceIndex].totalCanceled +=
                marketplaceCurrentValue.totalCanceled
              marketplacePreviousValue[marketplaceIndex].totalRevenue +=
                marketplaceCurrentValue.totalRevenue
              marketplacePreviousValue[marketplaceIndex].pendingRevenue +=
                marketplaceCurrentValue.pendingRevenue
            }
            return marketplacePreviousValue
          }, currentValue.performance.marketplaces),
        },
      }
    },
    {
      orders: [],
      totalShipped: 0,
      totalPending: 0,
      totalCanceled: 0,
      totalRevenue: 0,
      pendingRevenue: 0,
      performance: {
        products: [],
        marketplaces: [],
      },
    }
  )

  accumulatedPerformanceOverview.performance.products.sort(
    (a, b) => b.totalRevenue - a.totalRevenue
  )
  accumulatedPerformanceOverview.performance.marketplaces.sort(
    (a, b) => b.totalRevenue - a.totalRevenue
  )

  return accumulatedPerformanceOverview
}
