import { Popover, Space, Table, Typography } from 'antd'
import { CircleHelp } from 'lucide-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type AmazonListingInventoryReservedTableProps = {
  reservedInventory: ReservedInventory
}

type ReservedInventory = {
  pendingCustomerOrderQuantity: number
  pendingTransshipmentQuantity: number
  fcProcessingQuantity: number
  totalQuantity: number
}

export const AmazonListingInventoryReservedTable = ({
  reservedInventory,
}: AmazonListingInventoryReservedTableProps) => {
  const {
    pendingCustomerOrderQuantity,
    pendingTransshipmentQuantity,
    fcProcessingQuantity,
    totalQuantity,
  } = reservedInventory

  const { t } = useTranslation('inventory')

  const dataSource = useMemo(() => {
    return [
      {
        type: 'pendingCustomerOrder',
        reason: Tooltip(
          t('product.details.inventory.reservedPopover.pendingCustomerOrder', { ns: 'inventory' }),
          t('product.details.inventory.reservedPopover.pendingCustomerOrderPopover', {
            ns: 'inventory',
          })
        ),
        amount: pendingCustomerOrderQuantity,
      },
      {
        type: 'pendingTransshipment',
        reason: Tooltip(
          t('product.details.inventory.reservedPopover.pendingTransshipment', { ns: 'inventory' }),
          t('product.details.inventory.reservedPopover.pendingTransshipmentPopover', {
            ns: 'inventory',
          })
        ),
        amount: pendingTransshipmentQuantity,
      },
      {
        type: 'fcProcessing',
        reason: Tooltip(
          t('product.details.inventory.reservedPopover.fcProcessing', { ns: 'inventory' }),
          t('product.details.inventory.reservedPopover.fcProcessingPopover', { ns: 'inventory' })
        ),
        amount: fcProcessingQuantity,
      },
      {
        type: 'total',
        reason: t('product.details.inventory.reservedPopover.total', { ns: 'inventory' }),
        amount: totalQuantity,
      },
    ]
  }, [
    pendingCustomerOrderQuantity,
    pendingTransshipmentQuantity,
    fcProcessingQuantity,
    totalQuantity,
  ])

  const columns = useMemo(() => {
    return [
      {
        title: t('product.details.inventory.reservedPopover.reasonColumn', { ns: 'inventory' }),
        dataIndex: 'reason',
      },
      {
        title: t('product.details.inventory.reservedPopover.amountColumn', { ns: 'inventory' }),
        dataIndex: 'amount',
      },
    ]
  }, [])

  return (
    <>
      <Typography.Paragraph>
        {t('product.details.inventory.reservedPopover.description', { ns: 'inventory' })}
      </Typography.Paragraph>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey="type"
        size="small"
      />
    </>
  )
}

const Tooltip = (reason: string, content: string) => (
  <Space>
    <Typography.Text>{reason}</Typography.Text>
    <Popover content={content} trigger="hover" placement="top">
      <CircleHelp size={16} />
    </Popover>
  </Space>
)
