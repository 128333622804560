import { useTheme } from '@emotion/react'
import { Button, Input, Space } from 'antd'
import { Search } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export const getColumnSearchProps = (
  handleSearch: Function,
  handleReset: Function,
  searchText: string,
  key: string
) => {
  let searchInput: any = null

  const { t } = useTranslation()
  const theme = useTheme()

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node
            }}
            placeholder={t('shared.button.search')}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 228, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<Search size={16} />}
              size="small"
              style={{ width: 110 }}
            >
              {t('shared.button.search')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 110 }}>
              {t('shared.button.reset')}
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered: any) => <Search color={filtered ? theme.colors.primary : undefined} />,
    onFilter: (value: any, record: any) => {
      return record[key] && record[key].toString().toLowerCase().includes(value.toLowerCase())
    },
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.select())
      }
    },
    render: (text: string) => {
      if (searchText && text.toString().toLowerCase().includes(searchText.toLowerCase())) {
        return (
          <span style={{ backgroundColor: theme.colors.warning, padding: '2px 6px' }}>
            <b>{text}</b>
          </span>
        )
      } else {
        return text
      }
    },
  }
}
