import { Col, Row, Typography } from 'antd'
import { Link } from 'react-router'

export const SessionExpiredView = () => (
  <Row justify="center">
    <Col flex="0 1 480px" style={{ textAlign: 'center', fontSize: '1rem' }}>
      <Typography.Title level={3}>Your session has expired</Typography.Title>
      <Typography.Paragraph>
        Return to <Link to="..">login</Link>
      </Typography.Paragraph>
    </Col>
  </Row>
)
