import { useTranslation } from 'react-i18next'
import { Table } from '../../../../../../components/Table/Table'
import { TablePersistKey } from '../../../../../../stores/useTableStore'
import { StyledMainCard } from '../../../../../dashboard/styles'
import { useWholesaleOrderBillingDocumentLineItemsTableColumnDefs } from './hooks/useWholesaleOrderBillingDocumentLineItemsTableColumnDefs'

export type WholesaleOrderBillingDocumentLineItemTableRecord = {
  position: number
  wholesaleOrderBillingDocumentLineItemIdentifier: string
  wholesaleOrderLineItemIdentifier: string
  product: {
    uuid: string
    name: string
    tags: string[]
  }
  productVariant: {
    // uuid: string
    ean: string | null
    internalSku: string | null
    attributes: string[]
  }
  asins: string[]
  skus: string[]
  quantity: number
  netPurchasePrice: number
  currency: string
  netTotal: number
  taxRate: number
  tax: number
  grossTotal: number
}

type WholesaleOrderBillingDocumentLineItemsTableProps = {
  wholesaleOrderBillingDocumentLineItems:
    | WholesaleOrderBillingDocumentLineItemTableRecord[]
    | undefined
}

export const WholesaleOrderBillingDocumentLineItemsTable = ({
  wholesaleOrderBillingDocumentLineItems,
}: WholesaleOrderBillingDocumentLineItemsTableProps) => {
  const { t } = useTranslation('wholesale-order-billing-document', {
    keyPrefix: 'wholesaleOrderBillingDocumentLineItemsTable',
  })

  const columnDefs = useWholesaleOrderBillingDocumentLineItemsTableColumnDefs()

  return (
    <StyledMainCard title={t('title')}>
      <Table<WholesaleOrderBillingDocumentLineItemTableRecord>
        columnDefs={columnDefs}
        grandTotalRow="bottom"
        maxTableRows={10}
        persist={{
          key: TablePersistKey.WHOLESALE_ORDER_BILLING_DOCUMENT_DETAILS,
          storage: localStorage,
        }}
        rowData={wholesaleOrderBillingDocumentLineItems}
        showColumnChooser
        showExport
        showFilterReset
        showUniversalSearch
      />
    </StyledMainCard>
  )
}
