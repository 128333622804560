import { ColDef, ICellRendererParams, RowClickedEvent, ValueGetterParams } from 'ag-grid-community'
import { Tag } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { SupportTicketsQuery, ZendeskTicket } from '../../generated/graphql'
import { TablePersistKey } from '../../stores/useTableStore'
import { convertTicketMainCategory } from '../../views/ticket/helpers/convertTicketCategory.helper'
import { getTicketStatusTagColor } from '../../views/ticket/helpers/getTicketStatusTagColor'
import { TicketCustomFieldId } from '../../views/ticket/ticket.enum'
import { FormattedDate } from '../FormattedDate'
import { Table } from '../Table/Table'

type TicketListProps = {
  rowData?: NonNullable<SupportTicketsQuery['supportTickets']> | null
}

const TicketList = ({ rowData }: TicketListProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['ticket'], { keyPrefix: 'listTicketView' })

  const ticketIds = rowData?.map((ticket) => ticket.id)

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'id',
      headerName: t('table.id'),
      filter: 'agTextColumnFilter',
    },
    {
      headerName: t('table.status.title'),
      valueGetter: (params: ValueGetterParams<ZendeskTicket, ZendeskTicket['status']>) => {
        if (!params.data?.status) {
          return ''
        }

        return t(`table.status.${params.data.status}`)
      },
      cellRenderer: (params: ICellRendererParams<ZendeskTicket, string>) => {
        if (!params.data?.status) {
          return null
        }

        return <Tag color={getTicketStatusTagColor(params.data.status)}>{params.value}</Tag>
      },
    },
    {
      headerName: t('table.category.title'),
      valueGetter: (params: ValueGetterParams<ZendeskTicket, ZendeskTicket['custom_fields']>) => {
        if (!params.data?.custom_fields) {
          return ''
        }

        const { value } = params.data.custom_fields.find((customField) => {
          return customField.id === Number(TicketCustomFieldId.MAIN_CATEGORY)
        })

        return convertTicketMainCategory(value, t)
      },
    },
    {
      field: 'subject',
      headerName: t('table.subject'),
      filter: 'agTextColumnFilter',
    },
    {
      headerName: t('table.personalNotes'),
      filter: 'agTextColumnFilter',
      valueGetter: (params: ValueGetterParams<ZendeskTicket, ZendeskTicket['custom_fields']>) => {
        if (!params.data?.custom_fields) {
          return ''
        }

        const { value } = params.data.custom_fields.find((customField) => {
          return customField.id === Number(TicketCustomFieldId.PERSONAL_NOTES)
        })

        return value
      },
    },
    {
      field: 'created_at',
      headerName: t('table.createdAt'),
      filter: 'agDateColumnFilter',
      filterParams: { comparator: compareDates },
      cellRenderer: (params: ICellRendererParams<ZendeskTicket, ZendeskTicket['created_at']>) => {
        return <FormattedDate date={params.value} withRelativeTime layout="horizontal" />
      },
    },
    {
      field: 'updated_at',
      headerName: t('table.updatedAt'),
      filter: 'agDateColumnFilter',
      filterParams: { comparator: compareDates },
      cellRenderer: (params: ICellRendererParams<ZendeskTicket, ZendeskTicket['updated_at']>) => {
        return <FormattedDate date={params.value} withRelativeTime layout="horizontal" />
      },
    },
  ])

  const rowClickHandler = (event: RowClickedEvent) => {
    navigate(`/tickets/${event.data.id}`, { state: { ticketIds } })
  }

  return (
    <Table
      columnDefs={columnDefs}
      fullHeight
      onRowClicked={rowClickHandler}
      persist={{
        key: TablePersistKey.SUPPORT_TICKET,
        storage: localStorage,
      }}
      rowData={rowData}
      rowStyle={{ cursor: 'pointer' }}
      showColumnChooser
      showFilterReset
      showUniversalSearch
    />
  )
}

export default TicketList

function compareDates(filterLocalDateAtMidnight: Date, cellValue: string): number {
  if (!cellValue) {
    return -1
  }

  const dayJsFilterValue = dayjs(filterLocalDateAtMidnight).startOf('day')
  const dayJsCellValue = dayjs(cellValue).startOf('day')

  if (dayJsCellValue.isBefore(dayJsFilterValue)) {
    return -1
  } else if (dayJsCellValue.isAfter(dayJsFilterValue)) {
    return 1
  }

  return 0
}
