import { Button, Form, Input, InputNumber, Space } from 'antd'
import { i18n, TFunction } from 'i18next'
import { Plus, Trash } from 'lucide-react'

type SkuWithQuantityDynamicInputProps = {
  translation: [TFunction, i18n, boolean] & { t: TFunction; i18n: i18n; ready: boolean }
}

export const SkuWithQuantityDynamicInput = ({ translation }: SkuWithQuantityDynamicInputProps) => {
  const { t } = translation

  return (
    <Form.Item label={t('label') as string} required={true}>
      <Form.List name="skuWithQuantity">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...rest }) => (
              <Space key={key}>
                <Form.Item
                  {...rest}
                  name={[name, 'sku']}
                  rules={[{ required: true, message: t('skuInput.validation.required') }]}
                >
                  <Input placeholder={t('skuInput.placeholder')} />
                </Form.Item>
                <Form.Item
                  {...rest}
                  name={[name, 'quantity']}
                  rules={[{ required: true, message: t('quantityInput.validation.required') }]}
                >
                  <InputNumber
                    min={1}
                    placeholder={t('quantityInput.placeholder')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <Button
                    type="primary"
                    danger
                    icon={<Trash size={16} />}
                    onClick={() => remove(name)}
                    style={{ marginBottom: '24px' }}
                  />
                ) : null}
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" block icon={<Plus size={16} />} onClick={() => add()}>
                {t('addMoreButton') as string}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  )
}
