import { Form, Select } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useTranslation } from 'react-i18next'
import { WholesaleOrderBillingDocumentType } from '../../../../../../../generated/graphql'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'

export const WholesaleOrderBillingDocumentTypeSelect = () => {
  const { t } = useTranslation('wholesale-order-billing-document')

  const form = useFormInstance<CreateWholesaleOrderBillingDocumentFormValues>()

  return (
    <Form.Item<CreateWholesaleOrderBillingDocumentFormValues>
      name="type"
      label={t('billingDocumentTypeSelect.label')}
      rules={[
        {
          required: true,
          message: t('billingDocumentTypeSelect.error.required'),
        },
      ]}
    >
      <Select
        placeholder={t('billingDocumentTypeSelect.placeholder')}
        options={[
          {
            value: WholesaleOrderBillingDocumentType.INVOICE,
            label: t('billingDocumentTypeSelect.options.invoice'),
          },
          {
            value: WholesaleOrderBillingDocumentType.CREDIT_NOTE,
            label: t('billingDocumentTypeSelect.options.creditNote'),
          },
          {
            value: WholesaleOrderBillingDocumentType.INVOICE_CORRECTION,
            label: t('billingDocumentTypeSelect.options.invoiceCorrection'),
          },
        ]}
        onChange={() => {
          form.resetFields(['isProForma', 'lineItems'])
        }}
      />
    </Form.Item>
  )
}
