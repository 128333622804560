import { useTheme } from '@emotion/react'
import {
  CellClassParams,
  CellEditRequestEvent,
  ColDef,
  ColGroupDef,
  GetDetailRowDataParams,
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  ValueGetterParams,
  ValueParserParams,
} from 'ag-grid-community'
import { Form, Space } from 'antd'
import { ImageOff, Pen } from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridImage, GridImageFallback } from '../../../../components/Table/GridImage'
import { Table } from '../../../../components/Table/Table'
import { Marketplace } from '../../../../generated/graphql'
import { formatDecimal } from '../../../../helpers/formatDecimal'
import {
  AmazonInboundShipmentPlanCarton,
  AmazonInboundShipmentPlanCartonItem,
  AmazonInboundShipmentPlanCreateFormInstance,
} from './AmazonInboundShipmentPlanCreateForm'

type AmazonInboundShipmentPlanCartonMixedTableRecord = AmazonInboundShipmentPlanCarton

type AmazonInboundShipmentPlanCartonMixedTableProps = {
  editable?: boolean
}

export const AmazonInboundShipmentPlanCartonMixedTable = ({
  editable,
}: AmazonInboundShipmentPlanCartonMixedTableProps) => {
  const { t } = useTranslation('inventory')
  const theme = useTheme()
  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  const marketplace: AmazonInboundShipmentPlanCreateFormInstance['marketplace'] =
    form.getFieldValue('marketplace')
  const cartons: AmazonInboundShipmentPlanCreateFormInstance['cartons'] =
    form.getFieldValue('cartons')
  const rowData = cartons

  const [columnDefs] = useState<
    (
      | ColDef<AmazonInboundShipmentPlanCartonMixedTableRecord>
      | ColGroupDef<AmazonInboundShipmentPlanCartonMixedTableRecord>
    )[]
  >([
    {
      field: 'cartonType',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.carton'),
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      colId: 'totalUnits',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.totalUnits'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlanCartonMixedTableRecord>) => {
        if (!params.data) {
          return null
        }

        const totalUnits = params.data.items.reduce((previousValue, currentValue) => {
          return (
            previousValue +
            currentValue.quantityInPackagingUnit * currentValue.numberOfPackagingUnits
          )
        }, 0)

        return totalUnits
      },
    },
    {
      field: 'cartonLength',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.cartonLength'),
      editable,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonLength']
        >
      ) => {
        return isNaN(+params.newValue) ? params.oldValue : +params.newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonLength']
        >
      ) => {
        const isValueDefined = params.value !== null && params.value !== undefined

        return (
          <Space>
            {editable && <Pen size={16} />}
            {isValueDefined
              ? `${params.value} cm`
              : `${t('shared.button.edit', { ns: 'translation' })} (cm)`}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonLength']
        >
      ) => {
        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      field: 'cartonWidth',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.cartonWidth'),
      editable,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonWidth']
        >
      ) => {
        return isNaN(+params.newValue) ? params.oldValue : +params.newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonWidth']
        >
      ) => {
        const isValueDefined = params.value !== null && params.value !== undefined

        return (
          <Space>
            {editable && <Pen size={16} />}
            {isValueDefined
              ? `${params.value} cm`
              : `${t('shared.button.edit', { ns: 'translation' })} (cm)`}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonWidth']
        >
      ) => {
        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      field: 'cartonHeight',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.cartonHeight'),
      editable,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonHeight']
        >
      ) => {
        return isNaN(+params.newValue) ? params.oldValue : +params.newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonHeight']
        >
      ) => {
        const isValueDefined = params.value !== null && params.value !== undefined

        return (
          <Space>
            {editable && <Pen size={16} />}
            {isValueDefined
              ? `${params.value} cm`
              : `${t('shared.button.edit', { ns: 'translation' })} (cm)`}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonHeight']
        >
      ) => {
        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      field: 'cartonGrossWeight',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.cartonGrossWeight'),
      editable,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonGrossWeight']
        >
      ) => {
        return isNaN(+params.newValue) ? params.oldValue : +params.newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonGrossWeight']
        >
      ) => {
        const isValueDefined = params.value !== null && params.value !== undefined

        return (
          <Space>
            {editable && <Pen size={16} />}
            {isValueDefined
              ? `${params.value} kg`
              : `${t('shared.button.edit', { ns: 'translation' })} (kg)`}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonGrossWeight']
        >
      ) => {
        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      field: 'cartonNetWeight',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.cartonNetWeight'),
      hide: marketplace !== Marketplace.UK,
      editable,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonNetWeight']
        >
      ) => {
        return isNaN(+params.newValue) ? params.oldValue : +params.newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonNetWeight']
        >
      ) => {
        const isValueDefined = params.value !== null && params.value !== undefined

        return (
          <Space>
            {editable && <Pen size={16} />}
            {isValueDefined
              ? `${params.value} kg`
              : `${t('shared.button.edit', { ns: 'translation' })} (kg)`}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanCartonMixedTableRecord,
          AmazonInboundShipmentPlanCartonMixedTableRecord['cartonNetWeight']
        >
      ) => {
        return !params.value && marketplace === Marketplace.UK
          ? { backgroundColor: `${theme.colors.error}40` }
          : { backgroundColor: 'initial' }
      },
    },
    {
      colId: 'totalCartonWeight',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.totalCartonWeight'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlanCartonMixedTableRecord>) => {
        const totalPackageWeight = params.data?.items.reduce((previousValue, currentValue) => {
          if (
            !currentValue.quantityInPackagingUnit ||
            !currentValue.smallestPackagingUnit.weightInGram
          ) {
            return previousValue
          }

          const kilogram = currentValue.smallestPackagingUnit.weightInGram / 1000

          return previousValue + currentValue.quantityInPackagingUnit * kilogram
        }, 0)

        if (totalPackageWeight === 0) {
          return null
        }

        return totalPackageWeight
      },
      cellRenderer: (
        params: ICellRendererParams<AmazonInboundShipmentPlanCartonMixedTableRecord, number>
      ) => {
        if (params.value === null || params.value === undefined) {
          return t('shared.notAvailable', { ns: 'translation' })
        }

        const formattedWeight = formatDecimal(params.value)

        return `${formattedWeight} kg`
      },
    },
  ])

  const defaultColDef = useMemo<ColDef<AmazonInboundShipmentPlanCartonMixedTableRecord>>(() => {
    return {
      filter: false,
    }
  }, [])

  const getRowId = useMemo(
    () => (params: GetRowIdParams<AmazonInboundShipmentPlanCartonMixedTableRecord>) => {
      return params.data.cartonType!
    },
    []
  )

  const detailCellRendererParams = useMemo(() => {
    const detailGridOptions: GridOptions<AmazonInboundShipmentPlanCartonItem> = {
      columnDefs: [
        {
          field: 'image',
          headerName: '',
          minWidth: 64,
          maxWidth: 64,
          filter: false,
          resizable: false,
          sortable: false,
          suppressColumnsToolPanel: true,
          cellRenderer: (
            params: ICellRendererParams<
              AmazonInboundShipmentPlanCartonItem,
              AmazonInboundShipmentPlanCartonItem['image']
            >
          ) => {
            const src = params.value ?? null

            return src ? (
              <GridImage src={src} width={40} height={40} preview={false} />
            ) : (
              <GridImageFallback>
                <ImageOff />
              </GridImageFallback>
            )
          },
        },
        {
          field: 'name',
          headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.name'),
        },
        {
          field: 'ean',
          headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.ean'),
        },
        {
          field: 'asin',
          headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.asin'),
        },
        {
          field: 'fnsku',
          headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.fnsku'),
        },
        {
          field: 'sku',
          headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.sku'),
        },
        {
          field: 'internalSku',
          headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.internalSku'),
        },
        {
          field: 'quantityInPackagingUnit',
          headerName: t(
            'amazonInboundShipmentPlan.create.form.quantity.table.packagingUnitQuantity'
          ),
        },
        {
          field: 'numberOfPackagingUnits',
          headerName: t(
            'amazonInboundShipmentPlan.create.form.quantity.table.numberOfPackagingUnits'
          ),
        },
        {
          colId: 'totalUnits',
          headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.totalUnits'),
          valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlanCartonItem>) => {
            const packagingUnitQuantity = params.data?.quantityInPackagingUnit
            const numberOfPackagingUnits = params.data?.numberOfPackagingUnits

            if (!packagingUnitQuantity || !numberOfPackagingUnits) {
              return 0
            }

            return packagingUnitQuantity * numberOfPackagingUnits
          },
        },
      ],
      defaultColDef: {
        flex: 1,
        cellClassRules: {
          'null-cell': (params) => {
            return params.value === null || params.value === undefined || params.value === ''
          },
        },
        filter: false,
        minWidth: 100,
        sortable: false,
        suppressHeaderMenuButton: true,
        width: 150,
      },
      rowHeight: 48,
    }

    return {
      detailGridOptions,
      getDetailRowData: (
        params: GetDetailRowDataParams<AmazonInboundShipmentPlanCartonMixedTableRecord>
      ) => {
        params.successCallback(params.data?.items)
      },
    }
  }, [])

  const handleCellEditRequest = useCallback(
    async (event: CellEditRequestEvent<AmazonInboundShipmentPlanCartonMixedTableRecord>) => {
      const field = event.colDef.field

      if (!field) {
        return false
      }

      if (event.newValue === event.oldValue) {
        return false
      }

      try {
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: event.newValue }],
        })

        const currentCartons: AmazonInboundShipmentPlanCreateFormInstance['cartons'] =
          form.getFieldValue('cartons')

        form.setFieldsValue({
          cartons: currentCartons.map((carton) => {
            if (carton.cartonType === event.data.cartonType) {
              return { ...carton, [field]: event.newValue }
            }

            return carton
          }),
        })
      } catch (error) {
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: event.oldValue }],
        })
        console.error(error)
      }
    },
    []
  )

  return (
    <Table<AmazonInboundShipmentPlanCartonMixedTableRecord>
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      detailCellRendererParams={detailCellRendererParams}
      getRowId={getRowId}
      maintainColumnOrder
      masterDetail
      minTableRows={10}
      maxTableRows={10}
      onCellEditRequest={handleCellEditRequest}
      readOnlyEdit
      rowData={rowData}
      showColumnChooser
      showUniversalSearch
    />
  )
}
