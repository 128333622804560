import { Button, Flex, Form, Switch, Tooltip, Upload, UploadProps } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { UploadFile } from 'antd/es/upload/interface'
import { Storage } from 'aws-amplify'
import { Upload as UploadIcon } from 'lucide-react'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { WholesaleOrderBillingDocumentType } from '../../../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../../../stores/useGlobalStore'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'

const BUCKET_KEY = import.meta.env.VITE_AWS_S3_BUCKET_NAME_WHOLESALE_ORDERS
const BUCKET_FOLDER = 'billing'

export const WholesaleOrderBillingDocumentUpload = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [uploading, setUploading] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('wholesale-order-billing-document')

  const form = useFormInstance<CreateWholesaleOrderBillingDocumentFormValues>()
  const type = useWatch('type', form)
  const documentNumber = useWatch('documentNumber', form)

  const handleChange: UploadProps['onChange'] = async (info) => {
    const fileName = `${documentNumber}_${nanoid(5)}.pdf`

    try {
      setUploading(true)
      setFileList(info.fileList)

      const key = `${BUCKET_FOLDER}/${selectedCompany.sellerId}/${fileName}`

      const status = info.file.status

      if (status === 'done') {
        const response = await Storage.put(key, info.file.originFileObj, {
          bucket: BUCKET_KEY,
          contentType: info.file.type,
        })

        form.setFieldValue('bucketKey', response.key)
      } else if (status === 'removed') {
        setFileList([])
        form.setFieldValue('bucketKey', '')
      } else if (status === 'error') {
        toast.error(t('uploadBillingDocument.error.general'))
      }
    } catch (error) {
      setFileList([])
      form.setFieldValue('bucketKey', '')
      console.error(error)
      toast.error(t('uploadBillingDocument.error.general'))
    } finally {
      setUploading(false)
    }
  }

  return (
    <Flex align="center" gap={16}>
      <Form.Item<CreateWholesaleOrderBillingDocumentFormValues>
        name="bucketKey"
        label={t('uploadBillingDocument.label')}
        rules={[{ required: true, message: t('uploadBillingDocument.error.required') }]}
      >
        <Upload
          accept="application/pdf"
          customRequest={customRequest}
          defaultFileList={fileList}
          disabled={!documentNumber}
          fileList={fileList}
          listType="text"
          maxCount={1}
          multiple={false}
          onChange={handleChange}
        >
          <Tooltip title={!documentNumber ? t('uploadBillingDocument.tooltip.title') : null}>
            <Button icon={<UploadIcon size={16} />} loading={uploading} disabled={!documentNumber}>
              {t('uploadBillingDocument.button.upload')}
            </Button>
          </Tooltip>
        </Upload>
      </Form.Item>
      {type === WholesaleOrderBillingDocumentType.INVOICE && (
        <Form.Item<CreateWholesaleOrderBillingDocumentFormValues>
          name="isProForma"
          label={t('isProForma.label')}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      )}
    </Flex>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}
