import { Col, DatePicker, Form, Row } from 'antd'
import { i18n, TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import QuillTextarea from '../../../../components/Ticket/QuillTextarea'

const { MonthPicker } = DatePicker

type DealsRequiredDynamicInputInputProps = {
  translation?: [TFunction, i18n, boolean] & { t: TFunction; i18n: i18n; ready: boolean }
}

export const CompanyNameChangeInputRequired = ({
  translation,
}: DealsRequiredDynamicInputInputProps) => {
  const { t } =
    translation ??
    useTranslation(['ticket'], { keyPrefix: 'account.changeCompanyNameTicketView.form' })

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          name={'companyNames'}
          label={t('companyNames.label')}
          rules={[{ required: true, message: t('companyNames.validation.required') }]}
        >
          <QuillTextarea placeholder={t('companyNames.placeholder')} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={'vatApplicationDate'}
          label={t('vatApplicationDate.label')}
          rules={[{ required: true, message: t('vatApplicationDate.validation.required') }]}
        >
          <DatePicker format="DD.MM.YY" placeholder={t('vatApplicationDate.placeholder')} />
        </Form.Item>
        <Form.Item
          name={'lastDeliveryAccount1'}
          label={t('lastDeliveryAccount1.label')}
          rules={[{ required: true, message: t('lastDeliveryAccount1.validation.required') }]}
        >
          <MonthPicker format="MMMM YYYY" placeholder={t('lastDeliveryAccount1.placeholder')} />
        </Form.Item>
        <Form.Item
          name={'firstDeliveryAccount2'}
          label={t('firstDeliveryAccount2.label')}
          rules={[{ required: true, message: t('firstDeliveryAccount2.validation.required') }]}
        >
          <MonthPicker format="MMMM YYYY" placeholder={t('firstDeliveryAccount2.placeholder')} />
        </Form.Item>
      </Col>
    </Row>
  )
}
