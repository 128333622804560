import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { StyledTabs } from '../../components/Tabs'
import { DashboardFinanceView } from './finance'
import { DashboardPerformanceView } from './performance'

const DashboardTab = {
  GENERAL: 'general',
  INVENTORY: 'inventory',
  FINANCE: 'finance',
} as const

export const DashboardView = () => {
  const { t } = useTranslation('dashboard')

  return (
    <>
      <SubHeader heading={t('heading')} />
      <ViewContainer>
        <StyledTabs
          items={[
            {
              label: t('tab.performance'),
              key: DashboardTab.GENERAL,
              children: <DashboardPerformanceView />,
            },
            {
              label: t('tab.finance'),
              key: DashboardTab.FINANCE,
              children: <DashboardFinanceView />,
            },
          ]}
          defaultActiveKey={DashboardTab.GENERAL}
          tabBarGutter={0}
        />
      </ViewContainer>
    </>
  )
}
