import { useQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import {
  CellClassParams,
  CellEditRequestEvent,
  ColDef,
  ColGroupDef,
  GetRowIdParams,
  ICellRendererParams,
  ValueGetterParams,
  ValueParserParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Form, Space } from 'antd'
import { ImageOff, Pen } from 'lucide-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridImage, GridImageFallback } from '../../../../components/Table/GridImage'
import { Table } from '../../../../components/Table/Table'
import {
  Marketplace,
  RecentCartonDimensionsDocument,
  RecentCartonDimensionsQuery,
} from '../../../../generated/graphql'
import { formatDecimal } from '../../../../helpers/formatDecimal'
import { ShipmentKind } from '../types'
import {
  AmazonInboundShipmentPlanCarton,
  AmazonInboundShipmentPlanCreateFormInstance,
} from './AmazonInboundShipmentPlanCreateForm'

type AmazonInboundShipmentPlanCartonCaseTableRecord = AmazonInboundShipmentPlanCarton

type AmazonInboundShipmentPlanCartonCaseTableProps = {
  editable?: boolean
}

export const AmazonInboundShipmentPlanCartonCaseTable = ({
  editable,
}: AmazonInboundShipmentPlanCartonCaseTableProps) => {
  const { t } = useTranslation('inventory')
  const theme = useTheme()
  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()
  const gridRef = useRef<AgGridReact<AmazonInboundShipmentPlanCartonCaseTableRecord>>(null)

  const marketplace: AmazonInboundShipmentPlanCreateFormInstance['marketplace'] =
    form.getFieldValue('marketplace')
  const shipmentKind: AmazonInboundShipmentPlanCreateFormInstance['shipmentKind'] =
    form.getFieldValue('shipmentKind')
  const rowData: AmazonInboundShipmentPlanCreateFormInstance['cartons'] =
    form.getFieldValue('cartons')

  const areCasesRequired = shipmentKind === ShipmentKind.CASE

  const { data } = useQuery<RecentCartonDimensionsQuery>(RecentCartonDimensionsDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    skip: !areCasesRequired,
    variables: {
      areCasesRequired,
      amazonListingUuids: rowData.flatMap((carton) =>
        carton.items.map((item) => item.amazonListingUuid)
      ),
    },
  })

  useEffect(() => {
    if (data && areCasesRequired) {
      const updatedRowData = getUpdatedRowData(rowData, data.recentCartonDimensions)
      form.setFieldsValue({ cartons: updatedRowData })

      if (gridRef.current) {
        gridRef.current?.api?.setGridOption('rowData', updatedRowData)
      }
    }
  }, [data, areCasesRequired, rowData, form])

  const [columnDefs] = useState<
    (
      | ColDef<AmazonInboundShipmentPlanCartonCaseTableRecord>
      | ColGroupDef<AmazonInboundShipmentPlanCartonCaseTableRecord>
    )[]
  >([
    {
      valueGetter: (params) => {
        return params.data?.items[0]?.image ?? null
      },
      headerName: '',
      minWidth: 64,
      maxWidth: 64,
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['items'][number]['image']
        >
      ) => {
        const src = params.value ?? null

        return src ? (
          <GridImage src={src} width={40} height={40} preview={false} />
        ) : (
          <GridImageFallback>
            <ImageOff />
          </GridImageFallback>
        )
      },
    },
    {
      valueGetter: (params) => {
        return params.data?.items[0]?.name ?? null
      },
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.name'),
    },
    {
      valueGetter: (params) => {
        return params.data?.items[0]?.ean ?? null
      },
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.ean'),
      hide: true,
    },
    {
      valueGetter: (params) => {
        return params.data?.items[0]?.asin ?? null
      },
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.asin'),
    },
    {
      valueGetter: (params) => {
        return params.data?.items[0]?.fnsku ?? null
      },
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.fnsku'),
      hide: true,
    },
    {
      valueGetter: (params) => {
        return params.data?.items[0]?.sku ?? null
      },
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.sku'),
    },
    {
      valueGetter: (params) => {
        return params.data?.items[0]?.internalSku ?? null
      },
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.internalSku'),
    },
    {
      valueGetter: (params) => {
        return params.data?.items[0]?.quantityInPackagingUnit ?? null
      },
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.packagingUnitQuantity'),
    },
    {
      valueGetter: (params) => {
        return params.data?.numberOfCartons ?? null
      },
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.numberOfPackagingUnits'),
    },
    {
      colId: 'totalUnits',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.totalUnits'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlanCartonCaseTableRecord>) => {
        const packagingUnitQuantity = params.data?.items[0]?.quantityInPackagingUnit
        const numberOfPackagingUnits = params.data?.numberOfCartons

        if (!packagingUnitQuantity || !numberOfPackagingUnits) {
          return 0
        }

        return packagingUnitQuantity * numberOfPackagingUnits
      },
    },
    {
      field: 'cartonLength',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.cartonLength'),
      editable,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonLength']
        >
      ) => {
        return isNaN(+params.newValue) ? params.oldValue : +params.newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonLength']
        >
      ) => {
        const isValueDefined = params.value !== null && params.value !== undefined

        return (
          <Space>
            {editable && <Pen size={16} />}
            {isValueDefined
              ? `${params.value} cm`
              : `${t('shared.button.edit', { ns: 'translation' })} (cm)`}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonLength']
        >
      ) => {
        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      field: 'cartonWidth',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.cartonWidth'),
      editable,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonWidth']
        >
      ) => {
        return isNaN(+params.newValue) ? params.oldValue : +params.newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonWidth']
        >
      ) => {
        const isValueDefined = params.value !== null && params.value !== undefined

        return (
          <Space>
            {editable && <Pen size={16} />}
            {isValueDefined
              ? `${params.value} cm`
              : `${t('shared.button.edit', { ns: 'translation' })} (cm)`}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonWidth']
        >
      ) => {
        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      field: 'cartonHeight',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.cartonHeight'),
      editable,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonHeight']
        >
      ) => {
        return isNaN(+params.newValue) ? params.oldValue : +params.newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonHeight']
        >
      ) => {
        const isValueDefined = params.value !== null && params.value !== undefined

        return (
          <Space>
            {editable && <Pen size={16} />}
            {isValueDefined
              ? `${params.value} cm`
              : `${t('shared.button.edit', { ns: 'translation' })} (cm)`}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonHeight']
        >
      ) => {
        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      field: 'cartonGrossWeight',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.cartonGrossWeight'),
      editable,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonGrossWeight']
        >
      ) => {
        return isNaN(+params.newValue) ? params.oldValue : +params.newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonGrossWeight']
        >
      ) => {
        const isValueDefined = params.value !== null && params.value !== undefined

        return (
          <Space>
            {editable && <Pen size={16} />}
            {isValueDefined
              ? `${params.value} kg`
              : `${t('shared.button.edit', { ns: 'translation' })} (kg)`}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonGrossWeight']
        >
      ) => {
        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      field: 'cartonNetWeight',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.cartonNetWeight'),
      hide: marketplace !== Marketplace.UK,
      editable,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonNetWeight']
        >
      ) => {
        return isNaN(+params.newValue) ? params.oldValue : +params.newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonNetWeight']
        >
      ) => {
        const isValueDefined = params.value !== null && params.value !== undefined

        return (
          <Space>
            {editable && <Pen size={16} />}
            {isValueDefined
              ? `${params.value} kg`
              : `${t('shared.button.edit', { ns: 'translation' })} (kg)`}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanCartonCaseTableRecord,
          AmazonInboundShipmentPlanCartonCaseTableRecord['cartonNetWeight']
        >
      ) => {
        return !params.value && marketplace === Marketplace.UK
          ? { backgroundColor: `${theme.colors.error}40` }
          : { backgroundColor: 'initial' }
      },
    },
    {
      colId: 'totalCartonWeight',
      headerName: t('amazonInboundShipmentPlan.create.form.carton.table.totalCartonWeight'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlanCartonCaseTableRecord>) => {
        const totalPackageWeight = params.data?.items.reduce((previousValue, currentValue) => {
          if (
            !currentValue.quantityInPackagingUnit ||
            !currentValue.smallestPackagingUnit.weightInGram
          ) {
            return previousValue
          }

          const kilogram = currentValue.smallestPackagingUnit.weightInGram / 1000

          return previousValue + currentValue.quantityInPackagingUnit * kilogram
        }, 0)

        return totalPackageWeight
      },
      cellRenderer: (
        params: ICellRendererParams<AmazonInboundShipmentPlanCartonCaseTableRecord, number>
      ) => {
        if (params.value === null || params.value === undefined) {
          return t('shared.notAvailable', { ns: 'translation' })
        }

        const formattedWeight = formatDecimal(params.value)

        return `${formattedWeight} kg`
      },
    },
  ])

  const defaultColDef = useMemo<ColDef<AmazonInboundShipmentPlanCartonCaseTableRecord>>(() => {
    return {
      filter: false,
    }
  }, [])

  const getRowId = useMemo(
    () => (params: GetRowIdParams<AmazonInboundShipmentPlanCartonCaseTableRecord>) => {
      return params.data.cartonType!
    },
    []
  )

  const handleCellEditRequest = useCallback(
    async (event: CellEditRequestEvent<AmazonInboundShipmentPlanCartonCaseTableRecord>) => {
      const field = event.colDef.field

      if (!field) {
        return false
      }

      if (event.newValue === event.oldValue) {
        return false
      }

      try {
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: event.newValue }],
        })

        const currentCartons: AmazonInboundShipmentPlanCreateFormInstance['cartons'] =
          form.getFieldValue('cartons')

        form.setFieldsValue({
          cartons: currentCartons.map((carton) => {
            if (carton.cartonType === event.data.cartonType) {
              return { ...carton, [field]: event.newValue }
            }

            return carton
          }),
        })
      } catch (error) {
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: event.oldValue }],
        })
        console.error(error)
      }
    },
    [form]
  )

  return (
    <Table<AmazonInboundShipmentPlanCartonCaseTableRecord>
      ref={gridRef}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      getRowId={getRowId}
      maintainColumnOrder
      maxTableRows={10}
      onCellEditRequest={handleCellEditRequest}
      readOnlyEdit
      rowData={rowData}
      showColumnChooser
      showUniversalSearch
    />
  )
}

const getUpdatedRowData = (
  rowData: AmazonInboundShipmentPlanCartonCaseTableRecord[],
  recentData: RecentCartonDimensionsQuery['recentCartonDimensions']
) => {
  const recentDataMap = new Map<string, (typeof recentData)[0]>(
    recentData.map((item) => [item.sku, item])
  )

  return rowData.map((carton) => {
    const recentData = recentDataMap.get(carton.items[0]!.sku)

    if (!recentData) {
      return carton
    }

    return {
      ...carton,
      cartonLength: carton.cartonLength ?? recentData.length,
      cartonWidth: carton.cartonWidth ?? recentData.width,
      cartonHeight: carton.cartonHeight ?? recentData.height,
      cartonGrossWeight: carton.cartonGrossWeight ?? recentData.grossWeight,
      cartonNetWeight: carton.cartonNetWeight ?? recentData.netWeight,
    }
  })
}
