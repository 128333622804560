import { useQuery } from '@apollo/client'
import { Plus } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { LinkButton } from '../../../components/LinkButton'
import {
  CompanyType,
  GetAllWholesaleOrderBillingDocumentsDocument,
  GetAllWholesaleOrderBillingDocumentsQuery,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import {
  WholesaleOrderBillingDocumentTable,
  WholesaleOrderBillingDocumentTableRecord,
} from './components/WholesaleOrderBillingDocumentTable/WholesaleOrderBillingDocumentTable'

export type WholesaleOrderBillingDocument =
  GetAllWholesaleOrderBillingDocumentsQuery['wholesaleOrderBillingDocuments'][number]

export const WholesaleOrderBillingDocumentsView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('wholesale-order-billing-document', {
    keyPrefix: 'wholesaleOrderBillingDocumentsView',
  })

  const { loading, error, data } = useQuery(GetAllWholesaleOrderBillingDocumentsDocument, {
    errorPolicy: 'none',
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  if (error) {
    return <StatusResult status="500" title="500" subTitle={t('error.general')} />
  }

  const isWholesaler = selectedCompany.type === CompanyType.WHOLESALER

  const rowData = data
    ? prepareWholesaleOrderBillingDocumentTableData(data.wholesaleOrderBillingDocuments)
    : []

  return (
    <>
      <SubHeader
        heading={t('heading')}
        rightContent={
          <LinkButton type="primary" icon={<Plus size={16} />} to="create" disabled={!isWholesaler}>
            {t('button.createWholesaleOrderBillingDocument')}
          </LinkButton>
        }
      />
      <ViewContainer>
        <WholesaleOrderBillingDocumentTable rowData={rowData} loading={loading} />
      </ViewContainer>
    </>
  )
}

function prepareWholesaleOrderBillingDocumentTableData(
  wholesaleOrderBillingDocuments: WholesaleOrderBillingDocument[]
): WholesaleOrderBillingDocumentTableRecord[] {
  return wholesaleOrderBillingDocuments.map((wholesaleOrderBillingDocument) => {
    return {
      identifier: wholesaleOrderBillingDocument.identifier,
      documentNumber: wholesaleOrderBillingDocument.documentNumber,
      creditorCompany: wholesaleOrderBillingDocument.creditorCompany?.sellerId,
      debtorCompany: wholesaleOrderBillingDocument.debtorCompany?.sellerId,
      type: wholesaleOrderBillingDocument.type,
      issueDate: wholesaleOrderBillingDocument.issueDate,
      lineItems: wholesaleOrderBillingDocument.lineItems,
    }
  })
}
