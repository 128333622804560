import { useLazyQuery, useMutation } from '@apollo/client'
import { Alert, Button, Form, Input, Modal, Space } from 'antd'
import { Pen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  GetAllEansAndInternalSkusFromCompanyDocument,
  GetProductVariantDocument,
  UpdateProductVariantEanDocument,
} from '../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'

type EditEANFormInstance = {
  ean: string
}

type EditEANModalProps = {
  productVariantUuid: string
}
export const EditEanModal = ({ productVariantUuid }: EditEANModalProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [existingEans, setExistingEans] = useState<Set<string>>(new Set())

  const { t } = useTranslation(['inventory', 'translation'])
  const [form] = Form.useForm<EditEANFormInstance>()

  const [getAllEansForCompany] = useLazyQuery(GetAllEansAndInternalSkusFromCompanyDocument, {
    fetchPolicy: 'cache-first',
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  const [updateProductVariantEan] = useMutation(UpdateProductVariantEanDocument)

  const handleSubmit = async (values: EditEANFormInstance) => {
    setLoading(true)

    try {
      await updateProductVariantEan({
        variables: {
          uuid: productVariantUuid,
          ean: values.ean,
        },
        optimisticResponse: {
          updateProductVariant: {
            ean: values.ean,
            uuid: productVariantUuid,
          },
        },
        update: (cache, { data }) => {
          const ean = data?.updateProductVariant?.ean
          if (ean) {
            cache.modify({
              id: cache.identify({
                __typename: 'ProductVariant',
                uuid: productVariantUuid,
              }),
              fields: {
                ean: () => ean,
              },
            })
          } else {
            throw new Error()
          }
        },
        refetchQueries: [
          {
            query: GetProductVariantDocument,
            variables: { uuid: productVariantUuid },
          },
        ],
      })

      toast.success(t('inventory:product.details.productVariant.eanModal.success'))
    } catch (error) {
      console.error({ error })
      toast.error(t('translation:shared.error.message'))
    } finally {
      setOpen(false)
      setLoading(false)
      form.resetFields()
    }
  }

  const handleAfterOpenChange = async () => {
    const { data } = await getAllEansForCompany()
    setExistingEans(
      new Set(
        data?.products.flatMap(
          (product) => product.productVariants?.map((variant) => variant.ean).filter(Boolean) ?? []
        )
      )
    )
  }

  return (
    <>
      <Button type="text" icon={<Pen size={16} />} size="small" onClick={() => setOpen(true)} />
      <Modal
        title={t('inventory:product.details.productVariant.eanModal.title')}
        open={open}
        okText={t('translation:shared.button.submit')}
        onOk={() => form.submit()}
        cancelText={t('translation:shared.button.cancel')}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        destroyOnClose
        afterOpenChange={handleAfterOpenChange}
      >
        <Space direction="vertical">
          <Alert
            type="info"
            message={t('inventory:product.details.productVariant.eanModal.note')}
          />
          <Form<EditEANFormInstance> form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name="ean"
              label={t('inventory:product.details.productVariant.eanModal.label')}
              rules={[
                {
                  required: true,
                  message: t(
                    'inventory:product.details.productVariant.eanModal.validation.required'
                  ),
                },
                {
                  validator: async (_, value) => {
                    if (value && existingEans && existingEans.has(value)) {
                      return Promise.reject(
                        t('inventory:product.details.productVariant.eanModal.validation.duplicate')
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Space>
      </Modal>
    </>
  )
}
