import { Button, Checkbox, Col, Flex, Popover, Row } from 'antd'
import { Funnel } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Marketplace } from '../../../../generated/graphql'
import { useBreakpoints } from '../../../../hooks/useBreakpoints'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'
import { AmazonMarketplaceDomain } from '../../../../utils'

type DashboardFinanceHeaderFilterProps = {
  selectedMarketplaces: Marketplace[]
  setSelectedMarketplaces: React.Dispatch<React.SetStateAction<Marketplace[]>>
}

export const DashboardFinanceHeaderFilter = ({
  selectedMarketplaces,
  setSelectedMarketplaces,
}: DashboardFinanceHeaderFilterProps) => {
  const { t } = useTranslation()
  const marketplaceOptions = useMarketplaceOptions()
  const breakpoints = useBreakpoints()

  const handleMarketplaceChange = (value: Marketplace[]) => setSelectedMarketplaces(value)

  return (
    <Popover
      content={
        <Flex vertical gap={8}>
          <Checkbox.Group value={selectedMarketplaces} onChange={handleMarketplaceChange}>
            <Row gutter={[8, 8]}>
              {marketplaceOptions.map((marketplace) => (
                <Col span={12} key={marketplace}>
                  <Checkbox value={marketplace}>{AmazonMarketplaceDomain[marketplace]}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Flex>
      }
      arrow={false}
      overlayInnerStyle={{ width: '320px' }}
      placement="bottomRight"
      trigger="click"
    >
      <Button icon={<Funnel size={16} />}>
        {breakpoints.md && t('shared.term.marketplaces', { ns: 'translation' })}
      </Button>
    </Popover>
  )
}
