const CENTIMETERS_PER_INCH = 2.54
const GRAMS_PER_POUND = 453.59237

export function centimetresToInches(value: number): number {
  return value / CENTIMETERS_PER_INCH
}

export function gramsToPounds(value: number): number {
  return value / GRAMS_PER_POUND
}
