import { useMutation, useQuery } from '@apollo/client'
import { Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  CreateProductVariantPackagingUnitDocument,
  GetAllProductVariantsForCompanyDocument,
  GetAllProductVariantsForCompanyQuery,
  GtinType,
  ProductVariantPackagingUnitType,
  UpdateProductVariantPackagingUnitDocument,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { PackagingUnit } from './ProductVariantPackagingUnitCard'
import { getProductVariantPackagingUnitTypeText } from './ProductVariantPackagingUnitTable'

type ProductVariantPackagingUnitModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  productVariantUuid: string
  selectedPackagingUnit: PackagingUnit | null
  setSelectedPackagingUnit: Dispatch<SetStateAction<PackagingUnit | null>>
}

type ProductVariantPackagingUnitFormValues = {
  gtin: string | null
  gtinType: GtinType
  type: ProductVariantPackagingUnitType
  internalSku: string | null
  quantity: number
  lengthInCm: number
  widthInCm: number
  heightInCm: number
  weightInGram: number
}

export const ProductVariantPackagingUnitModal = ({
  open,
  setOpen,
  productVariantUuid,
  selectedPackagingUnit,
  setSelectedPackagingUnit,
}: ProductVariantPackagingUnitModalProps) => {
  const [loading, setLoading] = useState(false)

  const user = useGlobalStore((state) => state.user)!
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('inventory')
  const [form] = Form.useForm<ProductVariantPackagingUnitFormValues>()
  const gtinType = useWatch('gtinType', form)

  const { data, loading: loadingProductVariants } = useQuery(
    GetAllProductVariantsForCompanyDocument,
    {
      fetchPolicy: 'cache-first',
      variables: {
        companyUuid: selectedCompany.uuid,
      },
    }
  )

  const [createProductVariantPackagingUnit] = useMutation(CreateProductVariantPackagingUnitDocument)
  const [updateProductVariantPackagingUnit] = useMutation(UpdateProductVariantPackagingUnitDocument)

  const { existingGtins, existingInternalSkus, existingQuantities } =
    getExistingGtinsInternalSkusQuantities(
      data?.productVariants,
      selectedPackagingUnit,
      productVariantUuid
    )

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        gtin: selectedPackagingUnit?.gtin || '',
        gtinType: selectedPackagingUnit?.gtinType || GtinType.EAN,
        type: selectedPackagingUnit?.type || ProductVariantPackagingUnitType.SMALL_PARCEL,
        internalSku: selectedPackagingUnit?.internalSku || '',
        quantity: selectedPackagingUnit?.quantity,
        lengthInCm: selectedPackagingUnit?.lengthInCm ?? undefined,
        widthInCm: selectedPackagingUnit?.widthInCm ?? undefined,
        heightInCm: selectedPackagingUnit?.heightInCm ?? undefined,
        weightInGram: selectedPackagingUnit?.weightInGram ?? undefined,
      })
    }
  }, [selectedPackagingUnit])

  const handleSubmit = async (values: ProductVariantPackagingUnitFormValues) => {
    setLoading(true)

    try {
      const input = {
        ...values,
        gtin: values.gtin?.trim() || null,
      }

      if (productVariantUuid) {
        if (!selectedPackagingUnit?.uuid) {
          await createProductVariantPackagingUnit({
            variables: {
              productVariantUuid: productVariantUuid,
              input,
            },
          })
        } else {
          await updateProductVariantPackagingUnit({
            variables: {
              input: { ...input, uuid: selectedPackagingUnit.uuid },
            },
          })
        }
      }

      toast.success(t('shared.notification.success', { ns: 'translation' }))
      setOpen(false)
      setSelectedPackagingUnit(null)
      form.resetFields()
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Modal
      title={t('product.details.productVariant.packagingUnitModal.title')}
      open={open}
      okText={t('shared.button.submit', { ns: 'translation' })}
      onOk={form.submit}
      cancelText={t('shared.button.cancel', { ns: 'translation' })}
      onCancel={handleClose}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          gtinType: GtinType.EAN,
          type: ProductVariantPackagingUnitType.SMALL_PARCEL,
        }}
        layout="vertical"
      >
        <Row gutter={16} style={{ width: '100%' }}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="gtinType"
              label={t('product.details.productVariant.packagingUnitModal.form.gtinType.label')}
            >
              <Select<GtinType>
                options={Object.values(GtinType).map((type) => ({
                  value: type,
                  label: type,
                }))}
                placeholder={t(
                  'product.details.productVariant.packagingUnitModal.form.gtinType.placeholder'
                )}
                disabled={
                  !user.isAdmin &&
                  !!selectedPackagingUnit?.gtin &&
                  selectedPackagingUnit?.quantity === 1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="gtin"
              label={t('product.details.productVariant.packagingUnitModal.form.gtin.label')}
              tooltip={t('product.details.productVariant.packagingUnitModal.form.gtin.tooltip')}
              rules={[
                {
                  type: 'string',
                  whitespace: true,
                },
                {
                  validator: (_, value) => {
                    if (value && existingGtins.has(value.trim())) {
                      return Promise.reject(
                        t(
                          'product.details.productVariant.packagingUnitModal.form.gtin.validation.duplicate'
                        )
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <Input
                addonAfter={gtinType}
                disabled={
                  !user.isAdmin &&
                  !!selectedPackagingUnit?.gtin &&
                  selectedPackagingUnit?.quantity === 1
                }
                placeholder={t(
                  'product.details.productVariant.packagingUnitModal.form.gtin.placeholder'
                )}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="internalSku"
              label={t('product.details.productVariant.packagingUnitModal.form.internalSku.label')}
              rules={[
                {
                  whitespace: true,
                  message: t(
                    'product.details.productVariant.packagingUnitModal.form.internalSku.validation.whitespace'
                  ),
                  validator: (_, value) => {
                    if (existingInternalSkus.has(value)) {
                      return Promise.reject(
                        t(
                          'product.details.productVariant.packagingUnitModal.form.internalSku.validation.duplicate'
                        )
                      )
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input
                disabled={
                  !user.isAdmin &&
                  (loadingProductVariants ||
                    (!!selectedPackagingUnit?.internalSku && selectedPackagingUnit?.quantity === 1))
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="type"
              label={t('product.details.productVariant.packagingUnitModal.form.type.label')}
              rules={[{ type: 'string', whitespace: true }]}
            >
              <Select<ProductVariantPackagingUnitType>
                options={Object.values(ProductVariantPackagingUnitType).map((type) => ({
                  value: type,
                  label: getProductVariantPackagingUnitTypeText(type, t),
                }))}
                placeholder={t(
                  'product.details.productVariant.packagingUnitModal.form.type.placeholder'
                )}
                disabled={
                  !user.isAdmin &&
                  !!selectedPackagingUnit?.type &&
                  selectedPackagingUnit?.quantity === 1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="quantity"
              label={t('product.details.productVariant.packagingUnitModal.form.quantity.label')}
              rules={[
                {
                  required: true,
                  message: t(
                    'product.details.productVariant.packagingUnitModal.form.quantity.validation.required'
                  ),
                },
                {
                  validator: (_, value) => {
                    if (existingQuantities.has(value)) {
                      return Promise.reject(
                        t(
                          'product.details.productVariant.packagingUnitModal.form.quantity.validation.duplicate'
                        )
                      )
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                step={1}
                precision={0}
                min={1}
                disabled={!!selectedPackagingUnit}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="lengthInCm"
              label={t('product.details.productVariant.packagingUnitModal.form.lengthInCm.label')}
              rules={[
                {
                  required: true,
                  message: t(
                    'product.details.productVariant.packagingUnitModal.form.lengthInCm.validation.required'
                  ),
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                addonAfter="cm"
                step={1}
                precision={2}
                min={0.1}
                disabled={
                  !user.isAdmin &&
                  !!selectedPackagingUnit?.lengthInCm &&
                  selectedPackagingUnit?.quantity === 1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="widthInCm"
              label={t('product.details.productVariant.packagingUnitModal.form.widthInCm.label')}
              rules={[
                {
                  required: true,
                  message: t(
                    'product.details.productVariant.packagingUnitModal.form.widthInCm.validation.required'
                  ),
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                addonAfter="cm"
                step={1}
                precision={2}
                min={0.1}
                disabled={
                  !user.isAdmin &&
                  !!selectedPackagingUnit?.widthInCm &&
                  selectedPackagingUnit?.quantity === 1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="heightInCm"
              label={t('product.details.productVariant.packagingUnitModal.form.heightInCm.label')}
              rules={[
                {
                  required: true,
                  message: t(
                    'product.details.productVariant.packagingUnitModal.form.heightInCm.validation.required'
                  ),
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                addonAfter="cm"
                step={1}
                precision={2}
                min={0.1}
                disabled={
                  !user.isAdmin &&
                  !!selectedPackagingUnit?.heightInCm &&
                  selectedPackagingUnit?.quantity === 1
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="weightInGram"
              label={t('product.details.productVariant.packagingUnitModal.form.weightInGram.label')}
              rules={[
                {
                  required: true,
                  message: t(
                    'product.details.productVariant.packagingUnitModal.form.weightInGram.validation.required'
                  ),
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                addonAfter="g"
                step={1}
                precision={2}
                min={1}
                disabled={
                  !user.isAdmin &&
                  !!selectedPackagingUnit?.weightInGram &&
                  selectedPackagingUnit?.quantity === 1
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

type ExistingValues = {
  existingGtins: Set<string>
  existingInternalSkus: Set<string>
  existingQuantities: Set<number>
}

const getExistingGtinsInternalSkusQuantities = (
  productVariants: GetAllProductVariantsForCompanyQuery['productVariants'] | undefined,
  selectedPackagingUnit: PackagingUnit | null,
  currentProductVariantUuid: string | null | undefined
): ExistingValues => {
  const existingGtins = new Set<string>()
  const existingInternalSkus = new Set<string>()
  const existingQuantities = new Set<number>()

  productVariants?.forEach((productVariant) => {
    productVariant.packagingUnits?.forEach((packagingUnit) => {
      if (
        packagingUnit.gtin &&
        selectedPackagingUnit?.gtin !== packagingUnit.gtin &&
        packagingUnit.gtinType === GtinType.EAN
      ) {
        existingGtins.add(packagingUnit.gtin)
      }

      if (
        packagingUnit.quantity &&
        currentProductVariantUuid === productVariant.uuid &&
        packagingUnit.quantity !== selectedPackagingUnit?.quantity
      ) {
        existingQuantities.add(packagingUnit.quantity)
      }

      if (
        packagingUnit.internalSku &&
        selectedPackagingUnit?.internalSku !== packagingUnit.internalSku
      ) {
        existingInternalSkus.add(packagingUnit.internalSku)
      }
    })
  })

  return { existingGtins, existingInternalSkus, existingQuantities }
}
