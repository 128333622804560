import { AgGridReact } from 'ag-grid-react'
import { Input, InputRef } from 'antd'
import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SetURLSearchParams } from 'react-router'
import * as R from 'remeda'

type BaseUniversalSearchProps = {
  gridRef: RefObject<AgGridReact>
  searchValue: string
}

type LocalUniversalSearchProps = BaseUniversalSearchProps & {
  setSearchValue: Dispatch<SetStateAction<string>>
  storeSearchInSearchParams: false
}

type URLSearchParamsUniversalSearchProps = BaseUniversalSearchProps & {
  setSearchValue: SetURLSearchParams
  storeSearchInSearchParams: true
}

type UniversalSearchProps = LocalUniversalSearchProps | URLSearchParamsUniversalSearchProps

export const UniversalSearch = (props: UniversalSearchProps) => {
  const { t } = useTranslation()
  const inputRef = useRef<InputRef>(null)
  const selectionRef = useRef<{ start: number | null; end: number | null }>({
    start: null,
    end: null,
  })

  const setQuickFilterText = useCallback(
    (value: string) => {
      props.gridRef.current?.api.setGridOption('quickFilterText', value)
    },
    [props.gridRef]
  )

  const debouncedSetQuickFilterText = useMemo(
    () => R.debounce(setQuickFilterText, { waitMs: 300 }),
    [setQuickFilterText]
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target

      selectionRef.current = {
        start: target.selectionStart,
        end: target.selectionEnd,
      }

      const value = target.value

      debouncedSetQuickFilterText.call(value)

      if (props.storeSearchInSearchParams) {
        props.setSearchValue((previousSearchParams) => {
          const newSearchParams = new URLSearchParams(previousSearchParams)
          if (value) {
            newSearchParams.set('search', value)
          } else {
            newSearchParams.delete('search')
          }
          return newSearchParams
        })
      } else {
        props.setSearchValue(value)
      }
    },
    [debouncedSetQuickFilterText]
  )

  useEffect(() => {
    const input = inputRef.current
    const { start, end } = selectionRef.current

    if (input && start !== null && end !== null) {
      input.setSelectionRange(start, end)
    }
  }, [props.searchValue])

  useEffect(() => {
    debouncedSetQuickFilterText.call(props.searchValue)
  }, [debouncedSetQuickFilterText, props.searchValue])

  return (
    <Input
      ref={inputRef}
      value={props.searchValue}
      onChange={handleChange}
      placeholder={t('shared.button.universalSearch')}
      allowClear
      autoFocus
    />
  )
}
