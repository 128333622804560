import { useLazyQuery } from '@apollo/client'
import { Button, DatePicker, Form, Modal, Select, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import { Download } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GetDashboardPerformanceExportDocument,
  InternalAmazonListingStatus,
  Marketplace,
} from '../../../../../generated/graphql'
import { exportCsvFile } from '../../../../../helpers/exportFile'
import { getMarketplaceDomain } from '../../../../../helpers/getMarketplaceDomain'
import { useBreakpoints } from '../../../../../hooks/useBreakpoints'
import { useDateRangePresets } from '../../../../../hooks/useDateRangePresets'
import { useMarketplaceOptions } from '../../../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { prepareDashboardPerformanceExportData } from './helpers/prepareDashboardPerformanceExportData'

const { RangePicker } = DatePicker

type DashboardPerformanceExportFormInstance = {
  marketplaces: Marketplace[]
  dateRange: [Dayjs, Dayjs]
}

export const DashboardPerformanceExportModal = () => {
  const [open, setOpen] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation(['dashboard', 'translation'])
  const breakpoints = useBreakpoints()
  const [form] = useForm<DashboardPerformanceExportFormInstance>()
  const marketplaceOptions = useMarketplaceOptions()
  const presets = useDateRangePresets()

  const [getDashboardPerformance, { loading }] = useLazyQuery(GetDashboardPerformanceExportDocument)

  const exportCsv = async (values: DashboardPerformanceExportFormInstance) => {
    try {
      const { data } = await getDashboardPerformance({
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          companyUuid: selectedCompany?.uuid!,
          marketplaces: values.marketplaces,
          from: values.dateRange[0].format('YYYY-MM-DD'),
          to: values.dateRange[1].format('YYYY-MM-DD'),
          internalStatuses: Object.values(InternalAmazonListingStatus),
        },
      })

      if (!data) {
        throw new Error(t('shared.error.message', { ns: 'translation' }))
      }

      const dashboardPerformanceExportData = prepareDashboardPerformanceExportData(data)

      exportCsvFile(
        dashboardPerformanceExportData,
        `galaxy-dashboard-performance-${dayjs().format()}`
      )
    } catch (error) {
      console.error(error)
    } finally {
      setOpen(false)
    }
  }

  const initialValues = {
    marketplaces: marketplaceOptions,
    dateRange: [dayjs().subtract(6, 'days').startOf('day'), dayjs().endOf('day')],
  }

  return (
    <>
      <Button icon={<Download size={16} />} onClick={() => setOpen(true)}>
        {breakpoints.md ? t('performance.export.button') : null}
      </Button>
      <Modal
        title={t('performance.export.modal.title')}
        open={open}
        okText={t('shared.button.export', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
      >
        <Typography.Paragraph>
          {t('performance.export.modal.body.description')}
        </Typography.Paragraph>
        <Form<DashboardPerformanceExportFormInstance>
          name="dashboard-performance-export"
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={exportCsv}
        >
          <Form.Item
            label={t('performance.export.modal.body.form.marketplaces')}
            name="marketplaces"
          >
            <Select<Marketplace[]>
              mode="multiple"
              options={marketplaceOptions.map((marketplace) => ({
                value: marketplace,
                label: `Amazon.${getMarketplaceDomain(marketplace)}`,
              }))}
              placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
              allowClear
              showSearch
              filterOption={true}
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item label={t('performance.export.modal.body.form.dateRange')} name="dateRange">
            <RangePicker
              allowClear={false}
              disabledDate={(date) =>
                date.isAfter(dayjs()) || date.isBefore(dayjs().subtract(3, 'month'))
              }
              format="DD.MM.YY"
              presets={presets}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
