import { Flex, Typography } from 'antd'
import { EditEanModal } from './EditEanModal'

type EditEanFieldProps = {
  productVariantUuid: string
  ean: string | null | undefined
}

export const EditEanField = ({ productVariantUuid, ean }: EditEanFieldProps) => (
  <Flex justify={'space-between'}>
    <Typography.Text>{ean ?? '-'}</Typography.Text>
    {!ean && <EditEanModal productVariantUuid={productVariantUuid} />}
  </Flex>
)
