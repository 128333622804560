import { Card, Col, Image, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { GetAmazonListingQuery } from '../../../../../generated/graphql'

type CurrentAmazonListingImagesProps = {
  images: GetAmazonListingQuery['amazonListing']['images']
}

export const CurrentAmazonListingImages = (props: CurrentAmazonListingImagesProps) => {
  const { t } = useTranslation(['inventory', 'translation'])
  const fallbackImage = '/images/fallbackImage.webp'

  const images = [
    props.images?.mainImage,
    props.images?.imagePosition1,
    props.images?.imagePosition2,
    props.images?.imagePosition3,
    props.images?.imagePosition4,
    props.images?.imagePosition5,
    props.images?.imagePosition6,
    props.images?.imagePosition7,
    props.images?.imagePosition8,
  ]

  return (
    <>
      <Typography.Title level={5}>
        {t('product.details.changeRequest.images.modal.content.title')}
      </Typography.Title>
      <Typography.Paragraph>
        {t('product.details.changeRequest.images.modal.content.subTitle')}
      </Typography.Paragraph>
      <Row gutter={[8, 8]}>
        {images.map((image, index) => {
          return (
            <Col xs={12} sm={6} md={4} xl={2} key={index}>
              <Card styles={{ body: { padding: '0.5rem', aspectRatio: '1' } }}>
                <Image
                  src={image ?? ''}
                  width="100%"
                  height="100%"
                  preview={false}
                  fallback={fallbackImage}
                  style={{ borderRadius: '0.25rem', objectFit: 'contain' }}
                />
              </Card>
            </Col>
          )
        })}
      </Row>
    </>
  )
}
