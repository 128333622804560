import { Flex, Space, Tag, Typography } from 'antd'
import { GetProductVariantQuery } from '../../../../../generated/graphql'
import { EditProductVariantAttributesModal } from './EditProductVariantAttributesModal'

type ProductVariantAttributesFieldProps = {
  productVariant: GetProductVariantQuery['productVariant']
  isEditable?: boolean
}

export const ProductVariantAttributesField = ({
  productVariant,
  isEditable,
}: ProductVariantAttributesFieldProps) => {
  return (
    <Flex justify="space-between" wrap="wrap">
      <Space size={2} wrap>
        {productVariant.attributes?.length ? (
          productVariant.attributes.map((attribute) => (
            <Tag key={attribute.option}>{attribute.value}</Tag>
          ))
        ) : (
          <Typography.Text type="secondary">-</Typography.Text>
        )}
      </Space>
      {isEditable && <EditProductVariantAttributesModal productVariant={productVariant} />}
    </Flex>
  )
}
