import { css, Global, useTheme } from '@emotion/react'
import { darkTheme, lightTheme } from './config/theme'
import { AppTheme, useAppThemeStore } from './stores/useAppThemeStore'

export const GlobalStyles = () => {
  const theme = useTheme()
  const appTheme = useAppThemeStore((state) => state.appTheme)

  return (
    <Global
      styles={css`
        body {
          background-color: ${appTheme === AppTheme.DARK
            ? darkTheme.colors.background
            : lightTheme.colors.background};
        }

        .ag-cell {
          display: inline-flex;
          align-items: center;
        }

        .ag-cell-muted {
          color: ${theme.colors.text + '40'};
        }

        .ag-row-footer,
        .ag-row-pinned {
          background-color: var(--ag-chrome-background-color);
        }

        .ag-status-bar {
          align-items: center;
          min-height: 48px;
          padding: 0 calc(var(--ag-cell-horizontal-padding) - 1px) !important;

          .ag-status-bar-left,
          .ag-status-bar-center,
          .ag-status-bar-right {
            display: flex;
            align-items: center;
            gap: 1rem;

            .ag-status-panel {
              margin: 0;
            }
          }
        }

        #sentry-feedback {
          --accent-background: ${theme.colors.primary};
          --success-color: ${theme.colors.success};
          --error-color: ${theme.colors.error};
        }
      `}
    />
  )
}
