import {
  CellEditRequestEvent,
  GetRowIdFunc,
  RowSelectionOptions,
  SelectionChangedEvent,
  SelectionColumnDef,
} from 'ag-grid-community'
import { Form } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../../../../components/Table/Table'
import { GetAllWholesaleOrdersDetailsQuery } from '../../../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../../../stores/useGlobalStore'
import { TablePersistKey } from '../../../../../../../stores/useTableStore'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'
import { useWholesaleOrderLineItemsTableColumnDefs } from './hooks/useWholesaleOrderLineItemsTableColumnDefs'

type WholesaleOrderLineItem =
  GetAllWholesaleOrdersDetailsQuery['wholesaleOrders'][number]['lineItems'][number]

export type WholesaleOrderLineItemsTableRecord = {
  identifier: string
  productName: string
  internalSku: string | null
  ean: string | null
  attributes: string[]
  asins: string[]
  skus: string[]
  position: number | null
  quantity: number
  netPurchasePrice: number
  currency: string
  taxRate: number | null
}

type WholesaleOrderLineItemsTableProps = {
  wholesaleOrders: GetAllWholesaleOrdersDetailsQuery['wholesaleOrders']
}

export const WholesaleOrderLineItemsTable = ({
  wholesaleOrders,
}: WholesaleOrderLineItemsTableProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('wholesale-order-billing-document')

  const columnDefs = useWholesaleOrderLineItemsTableColumnDefs()

  const form = useFormInstance<CreateWholesaleOrderBillingDocumentFormValues>()
  const debtorCompanySellerId = useWatch('debtorCompanySellerId', form)

  const filteredWholesaleOrderLineItems = wholesaleOrders.reduce<WholesaleOrderLineItem[]>(
    (wholesaleOrderLineItems, wholesaleOrder) => {
      if (
        selectedCompany.sellerId !== wholesaleOrder.retailerCompany.sellerId &&
        debtorCompanySellerId === wholesaleOrder.retailerCompany.sellerId
      ) {
        const lineItemsForWholesaleOrder = wholesaleOrder.lineItems
        wholesaleOrderLineItems.push(...lineItemsForWholesaleOrder)
      }
      return wholesaleOrderLineItems
    },
    []
  )

  const wholesaleOrderLineItems = flattenWholesaleOrderLineItems(filteredWholesaleOrderLineItems)

  const getRowId = useMemo<GetRowIdFunc<WholesaleOrderLineItemsTableRecord>>(
    () => (params) => params.data.identifier,
    []
  )

  const rowSelection = useMemo<RowSelectionOptions<WholesaleOrderLineItemsTableRecord>>(() => {
    return {
      mode: 'multiRow',
      selectAll: 'filtered',
    }
  }, [])

  const selectionColumnDef = useMemo<SelectionColumnDef>(() => {
    return {
      minWidth: 40,
      maxWidth: 40,
      pinned: 'left',
    }
  }, [])

  const handleCellEditRequest = useCallback(
    async (event: CellEditRequestEvent<WholesaleOrderLineItemsTableRecord>) => {
      const field = event.colDef.field

      if (!field || event.newValue === event.oldValue) {
        return false
      }

      try {
        if (field === 'position') {
          event.api.forEachNode((node) => {
            if (node.data?.position === event.newValue && node.id !== event.node.id) {
              throw new Error('Position already exists. Please enter a unique position.')
            }
          })

          const lineItems: CreateWholesaleOrderBillingDocumentFormValues['lineItems'] =
            form.getFieldValue('lineItems')

          const updatedLineItems = lineItems
            .map((lineItem) => {
              if (lineItem.identifier === event.data.identifier) {
                return {
                  ...lineItem,
                  position: event.newValue,
                }
              }

              return lineItem
            })
            .filter(Boolean)

          form.setFieldValue('lineItems', updatedLineItems)

          event.api.applyTransaction({
            update: [{ ...event.data, position: event.newValue }],
          })
        }

        if (field === 'taxRate') {
          const lineItems: CreateWholesaleOrderBillingDocumentFormValues['lineItems'] =
            form.getFieldValue('lineItems')

          const updatedLineItems = lineItems
            .map((lineItem) => {
              if (lineItem.identifier === event.data.identifier) {
                return {
                  ...lineItem,
                  taxRate: event.newValue,
                }
              }

              return lineItem
            })
            .filter(Boolean)

          form.setFieldValue('lineItems', updatedLineItems)

          event.api.applyTransaction({
            update: [{ ...event.data, taxRate: event.newValue }],
          })
        }
      } catch (error) {
        console.error(error)
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: event.oldValue }],
        })
      }
    },
    []
  )

  const handleSelectionChanged = (
    event: SelectionChangedEvent<WholesaleOrderLineItemsTableRecord>
  ) => {
    const selectedRows: WholesaleOrderLineItemsTableRecord[] = event.api.getSelectedRows()

    form.setFieldValue(
      'lineItems',
      selectedRows.map((selectedRow) => ({
        position: selectedRow.position,
        identifier: selectedRow.identifier,
        productName: selectedRow.productName,
        internalSku: selectedRow.internalSku,
        ean: selectedRow.ean,
        attributes: selectedRow.attributes,
        asins: selectedRow.asins,
        skus: selectedRow.skus,
        netPurchasePrice: selectedRow.netPurchasePrice,
        currency: selectedRow.currency,
        taxRate: selectedRow.taxRate,
        quantity: selectedRow.quantity,
      }))
    )

    const selectedIdentifiers = new Set(selectedRows.map((row) => row.identifier))
    const deselectedRows = wholesaleOrderLineItems.filter((row) => {
      return !selectedIdentifiers.has(row.identifier)
    })

    event.api.applyTransaction({ update: [...selectedRows, ...deselectedRows] })
    event.api.refreshCells({ force: true })
  }

  return (
    <Form.Item<CreateWholesaleOrderBillingDocumentFormValues>
      name="lineItems"
      rules={[
        {
          required: true,
          message: t('wholesaleOrderLineItemsTable.validation.error.required'),
        },
        {
          validator: (_, values: CreateWholesaleOrderBillingDocumentFormValues['lineItems']) => {
            const isPositionDefined = values.every((lineItem) => {
              return lineItem.position !== null && lineItem.position !== undefined
            })
            const isTaxRateDefined = values.every((lineItem) => {
              return lineItem.taxRate !== null && lineItem.taxRate !== undefined
            })

            if (!isPositionDefined && !isTaxRateDefined) {
              return Promise.reject(
                new Error(t('wholesaleOrderLineItemsTable.validation.error.noPositionAndTax'))
              )
            }

            if (!isPositionDefined) {
              return Promise.reject(
                new Error(t('wholesaleOrderLineItemsTable.validation.error.noPosition'))
              )
            }

            if (!isTaxRateDefined) {
              return Promise.reject(
                new Error(t('wholesaleOrderLineItemsTable.validation.error.noTax'))
              )
            }

            return Promise.resolve()
          },
        },
      ]}
    >
      <Table<WholesaleOrderLineItemsTableRecord>
        columnDefs={columnDefs}
        getRowId={getRowId}
        onCellEditRequest={handleCellEditRequest}
        onSelectionChanged={handleSelectionChanged}
        persist={{
          key: TablePersistKey.WHOLESALE_ORDER_BILLING_DOCUMENT_CREATION,
          storage: localStorage,
        }}
        grandTotalRow="bottom"
        readOnlyEdit
        rowData={wholesaleOrderLineItems}
        rowSelection={rowSelection}
        selectionColumnDef={selectionColumnDef}
        showColumnChooser
        showFilterReset
        showUniversalSearch
      />
    </Form.Item>
  )
}

function flattenWholesaleOrderLineItems(
  lineItems: WholesaleOrderLineItem[]
): WholesaleOrderLineItemsTableRecord[] {
  return lineItems.map((lineItem) => {
    const attributes = lineItem.productVariant.attributes
      ? formatAttributes(lineItem.productVariant.attributes)
      : []
    const asins = lineItem.productVariant.amazonProducts?.map(({ asin }) => asin) ?? []
    const skus = extractUniqueSkus(lineItem)

    return {
      identifier: lineItem.identifier,
      productName: lineItem.productVariant.product.name,
      internalSku: lineItem.productVariant.internalSku ?? null,
      ean: lineItem.productVariant.ean ?? null,
      attributes,
      asins,
      skus,
      position: null,
      quantity: lineItem.totalQuantity,
      netPurchasePrice: lineItem.netPurchasePrice,
      currency: lineItem.currency,
      // TODO: Prefill tax rate from last billing document line item for this product variant.
      taxRate: null,
    }
  })
}

function formatAttributes(attributes: { option: string; value: string }[]) {
  return attributes.map((attr) => `${attr.option}: ${attr.value}`)
}

function extractUniqueSkus(lineItem: WholesaleOrderLineItem) {
  const uniqueSkus = new Set<string>()

  if (lineItem.productVariant.amazonProducts) {
    for (const amazonProduct of lineItem.productVariant.amazonProducts) {
      if (amazonProduct.amazonListings) {
        for (const listing of amazonProduct.amazonListings) {
          if (listing.sku) {
            uniqueSkus.add(listing.sku)
          }
        }
      }
    }
  }

  return Array.from(uniqueSkus)
}
