import { Button, Space, Table, Tag } from 'antd'
import { Key, useState } from 'react'
import { useParams } from 'react-router'
import {
  AmazonPpcState,
  Marketplace,
  TargetingExpressionPredicate,
} from '../../../../../generated/graphql'
import { exportCsvFile } from '../../../../../helpers/exportFile'
import { getColorFromState } from '../../../../../views/ppc/helpers'
import { formatCurrency } from '../../../../../views/ppc/helpers/formatCurrency'
import { AggregatedTarget } from '../../../../../views/ppc/helpers/interfaces'
import { getTableColumnFilterProps } from '../../../../../views/ppc/helpers/tableColumnFilterProps'
import { getTableColumnSorterProps } from '../../../../../views/ppc/helpers/tableColumnSorterProps'
import { useCurrencySymbol } from '../../../../../views/ppc/helpers/useCurrencySymbol'
import { getReadableTargetingExpressionTypePredicate } from '../../../helpers'
import BulkEditTargetBidModal from '../bulkUpdate/BulkEditSponsoredProductsTargetsBidModal/BulkEditSponsoredProductsTargetsBidModal'
import BulkEditTargetStateModal from '../bulkUpdate/BulkEditSponsoredProductsTargetsStateModal/BulkEditSponsoredProductsTargetsStateModal'
import UpdateSponsoredProductsTargetsModal from '../UpdateSponsoredProductsTargetModal/UpdateSponsoredProductsTargetModal'

type SponsoredProductsTargetsTableProps = {
  targets: AggregatedTarget[]
  updateTargetList: Function
}

const SponsoredProductsTargetsTable = ({
  targets,
  updateTargetList,
}: SponsoredProductsTargetsTableProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>()

  const params = useParams<{ marketplace: string; campaignid: string; adgroupid: string }>()

  const currencySymbol = useCurrencySymbol()

  const columns = [
    {
      title: 'Target',
      dataIndex: 'resolvedExpression',
      key: 'resolvedExpression',
      fixed: true,
      render: (resolvedExpression: TargetingExpressionPredicate[], row: any) => {
        const output: any[] = []
        if (resolvedExpression?.length) {
          resolvedExpression.forEach(
            (resolvedExpression: TargetingExpressionPredicate, index: number) => {
              const resolvedExpressionValue: string = resolvedExpression.value
                ? resolvedExpression.value
                : ''
              const resolvedExpressionType = getReadableTargetingExpressionTypePredicate(
                resolvedExpression.type
              )
              output.push(<div key={index}>{resolvedExpressionType + resolvedExpressionValue}</div>)
            }
          )
        } else if (row.expression?.length) {
          row.expression.forEach((expressionObject: any, index: number) => {
            const expressionValue: string = expressionObject.value ? expressionObject.value : ''
            const expressionType = getReadableTargetingExpressionTypePredicate(
              expressionObject.type
            )
            output.push(<div key={index}>{expressionType + expressionValue}</div>)
          })
        }
        return output
      },
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      filters: [
        { text: 'Enabled', value: 'enabled' },
        { text: 'Paused', value: 'paused' },
      ],
      render: (state: AmazonPpcState) => {
        const stateString = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()
        return <Tag color={getColorFromState(state)}>{stateString}</Tag>
      },
      ...getTableColumnFilterProps('state'),
    },
    {
      title: 'Bid',
      dataIndex: 'bid',
      key: 'bid',
      render: (bid: number) => formatCurrency(currencySymbol, bid),
      ...getTableColumnSorterProps('bid'),
    },
    {
      title: 'ACoS',
      dataIndex: 'acos',
      key: 'acos',
      render: (acos: number) => {
        if (isFinite(acos)) {
          return acos + '%'
        } else {
          return '-'
        }
      },
      ...getTableColumnSorterProps('acos'),
    },
    {
      title: 'Cost per Click',
      dataIndex: 'costPerClick',
      key: 'costPerClick',
      render: (costPerClick: number) => formatCurrency(currencySymbol, costPerClick),
      ...getTableColumnSorterProps('costPerClick'),
    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      key: 'spend',
      render: (spend: number) => formatCurrency(currencySymbol, spend),
      ...getTableColumnSorterProps('spend'),
    },
    {
      title: 'Sales',
      dataIndex: 'sales',
      key: 'sales',
      render: (sales: number) => formatCurrency(currencySymbol, sales),
      ...getTableColumnSorterProps('sales'),
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      ...getTableColumnSorterProps('impressions'),
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      ...getTableColumnSorterProps('clicks'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, row: any) => {
        const bid = row.bid ? row.bid : 0
        return (
          <UpdateSponsoredProductsTargetsModal
            marketplace={params.marketplace?.toUpperCase()! as Marketplace}
            campaignId={params.campaignid!}
            adGroupId={params.adgroupid!}
            targetId={row.targetId}
            bidIn={bid}
            stateIn={row.state}
            updateTargetList={updateTargetList}
          />
        )
      },
    },
  ]

  const handleDownloadCSV = () => {
    const data = targets.filter((target: any) => selectedRowKeys?.includes(target.targetId))
    exportCsvFile(data, 'targets')
  }

  const onBulkSelectChanged = (selectedRowKeys: Key[]) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  return (
    <Table
      bordered
      columns={columns}
      dataSource={targets}
      pagination={{
        defaultPageSize: 20,
        pageSizeOptions: ['20', '50', '100', '500', '1000'],
        showSizeChanger: true,
      }}
      rowKey="targetId"
      rowSelection={{ selectedRowKeys, onChange: onBulkSelectChanged }}
      scroll={{ x: true }}
      footer={(data: any) => {
        const bulkActionsDisabled = !selectedRowKeys?.length
        const textOpacity = bulkActionsDisabled ? 0.5 : 1

        const selectedTargets = data.filter((target: AggregatedTarget) => {
          return selectedRowKeys?.includes(target.targetId)
        })

        return (
          <Space>
            <span style={{ opacity: textOpacity }}>With selection:</span>
            <Button onClick={() => handleDownloadCSV()} disabled={bulkActionsDisabled}>
              Export .csv
            </Button>
            <BulkEditTargetStateModal
              marketplace={params.marketplace?.toUpperCase()! as Marketplace}
              campaignId={params.campaignid!}
              targetsIn={selectedTargets}
              updateTargetList={updateTargetList}
            />
            <BulkEditTargetBidModal
              marketplace={params.marketplace?.toUpperCase()! as Marketplace}
              campaignId={params.campaignid!}
              targetsIn={selectedTargets}
              updateTargetList={updateTargetList}
            />
          </Space>
        )
      }}
    />
  )
}

export default SponsoredProductsTargetsTable
