import { useTheme } from '@emotion/react'
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Tag } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { StyledMainCard } from '../../../dashboard/styles'
import { FormattedWholesaleOrderTemplateLineItem } from '../../WholesaleOrderTemplateDetailsView'

type WholesaleOrderTemplateLineItemsTableProps = {
  lineItems: FormattedWholesaleOrderTemplateLineItem[]
  editable: boolean
  setModifiedRows: React.Dispatch<React.SetStateAction<FormattedWholesaleOrderTemplateLineItem[]>>
}

export const WholesaleOrderTemplateLineItemsTable = ({
  lineItems,
  editable,
  setModifiedRows,
}: WholesaleOrderTemplateLineItemsTableProps) => {
  const { t } = useTranslation('wholesale-order')
  const theme = useTheme()
  const gridRef = useRef<AgGridReact<FormattedWholesaleOrderTemplateLineItem>>(null)

  const defaultExcelExportParams = {
    fileName: `wholesale-order-template-line-items-${dayjs().format('DD-MM-YYYY')}`,
  }

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent<FormattedWholesaleOrderTemplateLineItem>) => {
      const field = event.colDef.field

      if (!field || event.newValue === event.oldValue) {
        return false
      }

      const modifiedRow = { ...event.data, [field]: event.newValue }

      setModifiedRows((prev) => {
        const index = prev.findIndex((row) => row.identifier === modifiedRow.identifier)
        if (index === -1) {
          return [...prev, modifiedRow]
        } else {
          const newModifiedRows = [...prev]
          newModifiedRows[index] = modifiedRow
          return newModifiedRows
        }
      })
    },
    []
  )

  const defaultColDef = useWholesaleOrderTemplateLineItemsTableDefaultColumnDefs()
  const columnDefs = useWholesaleOrderTemplateLineItemsTableColumnDefs()
  const columnTypes = useWholesaleOrderTemplateLineItemsTableColumnTypes()

  useEffect(() => {
    if (editable) {
      const columnDefs = gridRef.current?.api?.getColumnDefs()

      const newColumnDefs = columnDefs?.map((colDef) => {
        if ('field' in colDef && colDef.field && colDef.field === 'defaultOrderQuantity') {
          return {
            ...colDef,
            editable: true,
            cellStyle: { backgroundColor: `${theme.colors.warning}20` },
          }
        } else {
          return colDef
        }
      })
      gridRef.current?.api?.setGridOption('columnDefs', newColumnDefs)
    } else {
      const columnDefs = gridRef.current?.api?.getColumnDefs()

      const newColumnDefs = columnDefs?.map((colDef) => {
        if ('field' in colDef && colDef.field && colDef.field === 'defaultOrderQuantity') {
          return {
            ...colDef,
            editable: false,
            cellStyle: { backgroundColor: 'transparent' },
          }
        } else {
          return colDef
        }
      })
      gridRef.current?.api?.setGridOption('columnDefs', newColumnDefs)
    }
  }, [editable])

  return (
    <StyledMainCard title={t('wholesaleOrderLineItemsTable.title')}>
      <Table<FormattedWholesaleOrderTemplateLineItem>
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        defaultColDef={defaultColDef}
        defaultExcelExportParams={defaultExcelExportParams}
        onCellValueChanged={onCellValueChanged}
        showExport
        ref={gridRef}
        rowData={lineItems}
        showFilterReset
        showUniversalSearch
      />
    </StyledMainCard>
  )
}

const useWholesaleOrderTemplateLineItemsTableDefaultColumnDefs =
  (): ColDef<FormattedWholesaleOrderTemplateLineItem> => {
    return {
      filter: true,
      wrapText: false,
      wrapHeaderText: true,
    }
  }

const useWholesaleOrderTemplateLineItemsTableColumnDefs = () => {
  const { t } = useTranslation('wholesale-order')

  const [columnDefs] = useState<
    (
      | ColDef<FormattedWholesaleOrderTemplateLineItem>
      | ColGroupDef<FormattedWholesaleOrderTemplateLineItem>
    )[]
  >([
    {
      field: 'productVariant.product.name',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.productName'),
      filter: 'agTextColumnFilter',
      flex: 1,
    },
    {
      field: 'productVariant.attributes',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.attributes'),
      type: 'tagsColumn',
    },
    {
      field: 'identifier',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.identifier'),
      filter: 'agTextColumnFilter',
      sort: 'asc',
      minWidth: 220,
    },
    {
      field: 'defaultOrderQuantity',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.defaultOrderQuantity.title'),
      headerTooltip: t('wholesaleOrderLineItemsTable.columnDefs.defaultOrderQuantity.tooltip'),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'productVariant.skus',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.skus'),
      type: 'tagsColumn',
    },
    {
      field: 'productVariant.ean',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.ean'),
      type: 'tagColumn',
    },
    {
      field: 'productVariant.internalSku',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.internalSku'),
      type: 'tagColumn',
    },
    {
      field: 'productVariant.asins',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.asins'),
      type: 'tagsColumn',
    },
    {
      field: 'productVariant.fnSkus',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.fnskus'),
      type: 'tagsColumn',
    },
  ])

  return columnDefs
}

const useWholesaleOrderTemplateLineItemsTableColumnTypes = () => {
  return {
    tagColumn: {
      filter: 'agTextColumnFilter',
      cellRenderer: ({ value }: ICellRendererParams) => {
        if (!value) {
          return null
        }

        return <Tag key={value}>{value}</Tag>
      },
    },
    tagsColumn: {
      filter: 'agTextColumnFilter',
      valueFormatter: ({ value }: ValueFormatterParams) => {
        if (!value) {
          return ''
        }

        return value.join(', ')
      },
      cellRenderer: ({ value }: ICellRendererParams) => {
        if (!value) {
          return null
        }
        return value.map((item: string, index: number) => {
          return <Tag key={`${item}${index}`}>{item}</Tag>
        })
      },
    },
  }
}
