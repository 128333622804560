import { IRowNode } from 'ag-grid-community'
import dayjs from 'dayjs'
import { GridApi } from 'ag-grid-community'

export const dateComparator = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueA: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueB: any,
  _nodeA: IRowNode<T>,
  _nodeB: IRowNode<T>,
  isDescending: boolean
) => {
  if (valueA === null) {
    return isDescending ? -1 : 1
  }

  if (valueB === null) {
    return isDescending ? 1 : -1
  }

  return dayjs(valueA, 'DD-MM-YYYY').unix() < dayjs(valueB, 'DD-MM-YYYY').unix() ? -1 : 1
}

export const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    const dateAsString = dayjs(cellValue, 'DD-MM-YYYY')
    if (dateAsString == null) {
      return 0
    }
    if (dateAsString < dayjs(filterLocalDateAtMidnight)) {
      return -1
    } else if (dateAsString > dayjs(filterLocalDateAtMidnight)) {
      return 1
    }
    return 0
  },
}

export const cellGetters = {
  getDateValue: (date: Date, dateFormat = 'DD-MM-YYYY') => {
    return date ? dayjs(date).format(dateFormat) : ''
  },
}

export const findInsertionIndex = <T extends { uuid: string }>(api: GridApi<T>): number => {
  let lastTempRowIndex = -1
  api.forEachNode((node, index) => {
    if (node.data?.uuid.startsWith('temp-')) {
      lastTempRowIndex = index
    }
  })
  return lastTempRowIndex + 1
}
