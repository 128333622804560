import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@emotion/react'
import { ConfigProvider } from 'antd'
import deDE from 'antd/locale/de_DE'
import enGB from 'antd/locale/en_GB'
import { ConfigCatProvider } from 'configcat-react'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { Toaster } from 'sonner'
import { GlobalStyles } from './GlobalStyles'
import { client } from './config/apollo'
import i18n from './config/i18n'
import { darkTheme, darkThemeAntd, lightTheme, lightThemeAntd } from './config/theme'
import { AppTheme, useAppThemeStore } from './stores/useAppThemeStore'

dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  weekStart: 1,
})

export const Providers = ({ children }: { children: React.ReactNode }) => {
  const appTheme = useAppThemeStore((state) => state.appTheme)

  return (
    <ApolloProvider client={client}>
      <ConfigCatProvider sdkKey={import.meta.env.VITE_CONFIG_CAT_API_KEY}>
        <ThemeProvider theme={appTheme === AppTheme.DARK ? darkTheme : lightTheme}>
          <ConfigProvider
            theme={appTheme === AppTheme.DARK ? darkThemeAntd : lightThemeAntd}
            locale={i18n.language === 'de' ? deDE : enGB}
          >
            <GlobalStyles />
            <Toaster
              className="sonner"
              closeButton
              richColors
              offset={16}
              theme={appTheme}
              toastOptions={{ duration: 5000 }}
            />
            {children}
          </ConfigProvider>
        </ThemeProvider>
      </ConfigCatProvider>
    </ApolloProvider>
  )
}
