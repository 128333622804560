import { Button, Flex, Form, Radio, Space, Tooltip } from 'antd'
import { CircleHelp } from 'lucide-react'
import { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'remeda'
import { CarrierName, Marketplace, ShipmentType } from '../../../../generated/graphql'
import { AmazonInboundShipmentPlanCreateFormInstance } from './AmazonInboundShipmentPlanCreateForm'
import { AmazonInboundShipmentPlanStep } from './AmazonInboundShipmentPlanCreateFormCollapse'

type AmazonInboundShipmentPlanTransportSectionProps = {
  setCurrentStep: (currentStep: SetStateAction<AmazonInboundShipmentPlanStep>) => void
}

export const AmazonInboundShipmentPlanTransportSection = ({
  setCurrentStep,
}: AmazonInboundShipmentPlanTransportSectionProps) => {
  const { t } = useTranslation('inventory')

  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  const handleBackClick = () => {
    setCurrentStep(AmazonInboundShipmentPlanStep.CARTON)
  }

  const handleNextClick = async () => {
    try {
      await form.validateFields(['shippingCarrier'])
      setCurrentStep(AmazonInboundShipmentPlanStep.SUMMARY)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Flex vertical gap={8}>
      <AmazonShipmentPlanShippingCarrierFormField />
      <Flex justify="end">
        <Space>
          <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
            <Button onClick={handleBackClick}>
              {t('shared.button.back', { ns: 'translation' })}
            </Button>
          </Form.Item>
          <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
            <Button type="primary" onClick={handleNextClick}>
              {t('shared.button.next', { ns: 'translation' })}
            </Button>
          </Form.Item>
        </Space>
      </Flex>
    </Flex>
  )
}

const AmazonShipmentPlanShippingCarrierFormField = () => {
  const { t } = useTranslation('inventory')
  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  return (
    <Form.Item<AmazonInboundShipmentPlanCreateFormInstance>
      shouldUpdate={(prevValues, nextValues) => {
        return !R.isDeepEqual(prevValues.shippingMode, nextValues.shippingMode)
      }}
      noStyle
    >
      {() => {
        const marketplace: AmazonInboundShipmentPlanCreateFormInstance['marketplace'] =
          form.getFieldValue('marketplace')
        const shippingMode: AmazonInboundShipmentPlanCreateFormInstance['shippingMode'] =
          form.getFieldValue('shippingMode')

        if (shippingMode === ShipmentType.LTL || marketplace === Marketplace.US) {
          form.setFieldsValue({ shippingCarrier: CarrierName.OTHER })
        }

        return (
          <Form.Item<AmazonInboundShipmentPlanCreateFormInstance>
            name="shippingCarrier"
            label={
              <Space>
                {t('amazonInboundShipmentPlan.create.form.transport.shippingCarrier.label')}
                <Tooltip
                  title={t(
                    'amazonInboundShipmentPlan.create.form.transport.shippingCarrier.amazonPartnered'
                  )}
                >
                  <CircleHelp size={16} />
                </Tooltip>
              </Space>
            }
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio
                  value={CarrierName.UNITED_PARCEL_SERVICE_INC}
                  disabled={shippingMode === ShipmentType.LTL}
                >
                  {t(
                    `amazonInboundShipmentPlan.create.form.transport.shippingCarrier.${CarrierName.UNITED_PARCEL_SERVICE_INC}`
                  )}
                </Radio>
                <Radio value={CarrierName.DHL_STANDARD} disabled={marketplace === Marketplace.US}>
                  {t(
                    `amazonInboundShipmentPlan.create.form.transport.shippingCarrier.${CarrierName.DHL_STANDARD}`
                  )}
                </Radio>
                <Radio value={CarrierName.OTHER}>
                  {t(
                    `amazonInboundShipmentPlan.create.form.transport.shippingCarrier.${CarrierName.OTHER}`
                  )}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}
