import { useMutation } from '@apollo/client'
import { Col, Row, Tag } from 'antd'
import {
  GetCompanyProductTagsDocument,
  GetProductDocument,
  GetProductQuery,
  RemoveProductTagDocument,
} from '../../../generated/graphql'
import { NewProductTagButton } from './NewProductTagButton'

const MAX_TAG_COUNT = 10

type ProductTagsProps = {
  product: GetProductQuery['product']
}

export const ProductTags = ({ product }: ProductTagsProps) => {
  const [removeProductTag] = useMutation(RemoveProductTagDocument)

  const handleRemove = async (tag: string) => {
    try {
      await removeProductTag({
        variables: {
          productUuid: product.uuid,
          name: tag,
        },
        optimisticResponse: {
          removeProductTag: tag,
        },
        update: (cache, { data }) => {
          const removedTag = data?.removeProductTag
          const cachedQuery = cache.readQuery({
            query: GetProductDocument,
            variables: { uuid: product.uuid },
          })

          if (removedTag && cachedQuery) {
            cache.writeQuery({
              query: GetProductDocument,
              variables: { uuid: product.uuid },
              data: {
                product: {
                  ...cachedQuery.product,
                  tags: cachedQuery.product.tags?.filter((tag) => tag !== removedTag),
                },
              },
            })
          }
        },
        refetchQueries: [GetCompanyProductTagsDocument],
      })
    } catch (error) {
      console.error(error)
    }
  }

  const tags = product.tags ?? []

  return (
    <Row gutter={[0, 8]}>
      {tags.map((tag) => (
        <Col key={tag}>
          <Tag
            closable
            onClose={(event) => {
              event.preventDefault()
              handleRemove(tag)
            }}
          >
            {tag}
          </Tag>
        </Col>
      ))}
      {tags.length < MAX_TAG_COUNT ? (
        <Col>
          <NewProductTagButton product={product} />
        </Col>
      ) : null}
    </Row>
  )
}
