import { Statistic } from 'antd'
import { useCurrencySymbol } from '../../../../views/ppc/helpers/useCurrencySymbol'
import { getAcos, getTotalSales } from '../../helpers'
import { StyledPPCPerformanceCard } from './PPCPerformanceCard.styles'

type PPCPerformanceCardProps = {
  // data:
  //   | AggregatedCampaign[]
  //   | AggregatedProductAd[]
  //   | AggregatedTarget[]
  //   | AggregatedKeyword[]
  //   | SponsoredBrandsAggregatedAdGroup[]
  //   | SponsoredBrandsAggregatedKeyword[]
  data: any[]
}

export const PPCPerformanceCard = ({ data }: PPCPerformanceCardProps) => {
  const avgAcos = getAcos(data)
  const totalSales = getTotalSales(data)
  const currencySymbol = useCurrencySymbol()

  return (
    <StyledPPCPerformanceCard>
      <Statistic
        title="ACoS"
        value={avgAcos === Infinity ? '-' : avgAcos}
        precision={2}
        suffix={avgAcos === Infinity ? null : '%'}
        style={{ flex: 1 }}
      />
      <Statistic
        title="Sales"
        value={totalSales}
        prefix={currencySymbol}
        precision={2}
        style={{ flex: 1 }}
      />
    </StyledPPCPerformanceCard>
  )
}
