import { Popover, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const PasswordPolicyPopover = () => {
  const { t } = useTranslation('login', { keyPrefix: 'general.passwordPolicy' })

  const content = (
    <ul style={{ margin: '0 0 0 1rem', padding: 0 }}>
      <li>
        <Typography.Text>{t('list.characters')}</Typography.Text>
      </li>
      <li>
        <Typography.Text>{t('list.lowercase')}</Typography.Text>
      </li>
      <li>
        <Typography.Text>{t('list.uppercase')}</Typography.Text>
      </li>
      <li>
        <Typography.Text>{t('list.numbers')}</Typography.Text>
      </li>
      <li>
        <Typography.Text>{t('list.symbols')}</Typography.Text>
      </li>
    </ul>
  )

  return (
    <Popover title={t('list.title')} content={content}>
      <Typography.Text underline style={{ cursor: 'help' }}>
        {t('label')}
      </Typography.Text>
    </Popover>
  )
}

export default PasswordPolicyPopover
