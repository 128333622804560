import { useMutation } from '@apollo/client'
import { Checkbox, Space, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  UpdateWarehouseServiceProviderLocationDocument,
  WarehouseServiceProviderLocationFieldsFragmentDoc,
} from '../../../../generated/graphql'
import { WarehouseServiceProviderLocation } from './WarehousingContainer'

type WarehouseServiceProviderLocationStatusCheckboxProps = {
  location: WarehouseServiceProviderLocation
}

export const WarehouseServiceProviderLocationStatusCheckbox = ({
  location,
}: WarehouseServiceProviderLocationStatusCheckboxProps) => {
  const { t } = useTranslation('warehousing')

  const [updateWarehouseServiceProviderLocation] = useMutation(
    UpdateWarehouseServiceProviderLocationDocument
  )

  const handleUpdate = async (event: CheckboxChangeEvent) => {
    try {
      await updateWarehouseServiceProviderLocation({
        variables: {
          uuid: location.uuid,
          input: { active: event.target.checked },
        },
        optimisticResponse: {
          updateWarehouseServiceProviderLocation: {
            ...location,
            active: event.target.checked,
          },
        },
        update: (cache, { data }) => {
          const updatedWarehouseServiceProviderLocation =
            data?.updateWarehouseServiceProviderLocation

          if (updatedWarehouseServiceProviderLocation) {
            const cachedFragment = cache.readFragment({
              id: cache.identify(updatedWarehouseServiceProviderLocation),
              fragment: WarehouseServiceProviderLocationFieldsFragmentDoc,
            })

            if (cachedFragment) {
              cache.writeFragment({
                id: cache.identify(updatedWarehouseServiceProviderLocation),
                fragment: WarehouseServiceProviderLocationFieldsFragmentDoc,
                data: updatedWarehouseServiceProviderLocation,
              })
            }
          }
        },
      })

      toast.success(t('location.edit.toast.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('location.edit.toast.error'))
    }
  }

  return (
    <Space>
      <Typography.Text>{t('location.active')}</Typography.Text>
      <Checkbox checked={location.active} onChange={handleUpdate} />
    </Space>
  )
}
