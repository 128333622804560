import styled from '@emotion/styled'
import { Layout } from 'antd'

export const StyledSider = styled(Layout.Sider)`
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.base[2]};
  }
` as unknown as typeof Layout.Sider

export const StyledHeader = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
` as unknown as typeof Layout.Header

export const StyledContent = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
` as unknown as typeof Layout.Content
