import { List, Skeleton } from 'antd'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { StyledMainCard } from '../../views/dashboard/styles'
import { S3DocumentListCardItem } from './components/S3DocumentListCardItem'

export type S3DocumentListCardData = { key: string; description?: string | ReactNode }
export type S3DocumentListCardPropsData = S3DocumentListCardData[]

type S3DocumentListCardProps = {
  data: S3DocumentListCardPropsData
  bucketName: string
  title?: string | ReactNode
  showPreview?: boolean
  showViewButton?: boolean
  showDownloadButton?: boolean
  rightHeaderContent?: ReactNode
  loading?: boolean
  customGetFilenameFromKey?: (key: string) => string
  deleteFunction?: (key: string) => Promise<void> | undefined
}

export function S3DocumentListCard({
  title,
  data,
  bucketName,
  rightHeaderContent,
  showPreview,
  showViewButton,
  showDownloadButton,
  loading,
  customGetFilenameFromKey,
  deleteFunction,
}: S3DocumentListCardProps) {
  const { t } = useTranslation(['translation'])
  const user = useGlobalStore((state) => state.user)

  const content = (
    <Skeleton loading={loading} active title={false} paragraph={{ rows: 2, width: '100%' }} avatar>
      {!!data && (
        <List<S3DocumentListCardData>
          dataSource={data}
          renderItem={(item) => (
            <S3DocumentListCardItem
              bucketKey={item.key}
              description={item.description}
              bucketName={bucketName}
              showPreview={showPreview}
              showViewButton={showViewButton}
              showDownloadButton={showDownloadButton}
              customGetFilenameFromKey={customGetFilenameFromKey}
              deleteFunction={user?.isAdmin ? deleteFunction : undefined}
            />
          )}
          locale={{ emptyText: t('shared.noData') }}
        />
      )}
    </Skeleton>
  )

  return title || rightHeaderContent ? (
    <StyledMainCard title={title} extra={rightHeaderContent && rightHeaderContent}>
      {content}
    </StyledMainCard>
  ) : (
    content
  )
}
