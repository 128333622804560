import { Descriptions, Divider, Flex } from 'antd'
import * as i18nIsoCountries from 'i18n-iso-countries'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../config/i18n'
import { formatMoney } from '../../../../helpers/formatMoney'
import { StyledMainCard } from '../../../dashboard/styles'
import { descriptionsStyle } from '../../../products/components/styles'
import { AmazonInboundShipmentPlan } from '../AmazonInboundShipmentPlanView'
import { getAmazonInboundShipmentStatusText } from '../helpers/getAmazonInboundShipmentStatusText'
import { DownloadAmazonInboundShipmentPlanLabelModal } from './DownloadAmazonInboundShipmentPlanLabelModal'

type AmazonInboundShipmentPlanLabelDetailsProps = {
  amazonInboundShipmentPlan: AmazonInboundShipmentPlan
}

export const AmazonInboundShipmentPlanLabelDetails = ({
  amazonInboundShipmentPlan,
}: AmazonInboundShipmentPlanLabelDetailsProps) => {
  const { t } = useTranslation('inventory')

  const totalCost = amazonInboundShipmentPlan.inboundShipments
    ? amazonInboundShipmentPlan.inboundShipments.reduce((accumulator, inboundShipment) => {
        return accumulator + inboundShipment.deliveryCost
      }, 0)
    : 0

  return (
    <StyledMainCard
      title={t('amazonInboundShipmentPlan.table.labels.title')}
      extra={
        amazonInboundShipmentPlan.inboundShipments && (
          <DownloadAmazonInboundShipmentPlanLabelModal
            inboundShipments={amazonInboundShipmentPlan.inboundShipments}
            withLabel
            pageType={amazonInboundShipmentPlan.pageType}
          />
        )
      }
      style={{ height: 'auto' }}
    >
      <Flex vertical gap={16}>
        {amazonInboundShipmentPlan.inboundShipments?.map((inboundShipment, index) => (
          <Flex key={index} vertical gap={16}>
            <Descriptions key={index} {...descriptionsStyle}>
              <Descriptions.Item
                label={t('amazonInboundShipmentPlan.table.amazonInboundShipmentId.title')}
              >
                {inboundShipment.shipmentId}
              </Descriptions.Item>
              <Descriptions.Item label={t('amazonInboundShipmentPlan.table.status')}>
                {getAmazonInboundShipmentStatusText(inboundShipment.shipmentStatus, t)}
              </Descriptions.Item>
              <Descriptions.Item
                label={t('amazonInboundShipmentPlan.table.shipToFulfillmentCenterCountry')}
              >
                {i18nIsoCountries.getName(
                  inboundShipment.destinationFulfillmentCenterId,
                  i18n.language
                ) ?? inboundShipment.destinationFulfillmentCenterId}
              </Descriptions.Item>
              <Descriptions.Item label={t('amazonInboundShipmentPlan.table.deliveryCost')}>
                {formatMoney(inboundShipment.deliveryCost ?? 0, 'EUR')}
              </Descriptions.Item>
            </Descriptions>
          </Flex>
        ))}
      </Flex>
      <Divider />
      <Descriptions {...descriptionsStyle}>
        <Descriptions.Item label={t('amazonInboundShipmentPlan.table.totalCost')}>
          {formatMoney(totalCost, 'EUR')}
        </Descriptions.Item>
      </Descriptions>
    </StyledMainCard>
  )
}
