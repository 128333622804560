import {
  CellEditRequestEvent,
  GetRowIdParams,
  RowSelectionOptions,
  SelectionChangedEvent,
  SelectionColumnDef,
} from 'ag-grid-community'
import { Form } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../../../../components/Table/Table'
import { GetAllWholesaleOrdersDetailsQuery } from '../../../../../../../generated/graphql'
import { TablePersistKey } from '../../../../../../../stores/useTableStore'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'
import { useWholesaleOrderBillingDocumentExistingLineItemsTableColumnDefs } from './hooks/useWholesaleOrderBillingDocumentExistingLineItemsTableColumnDefs'

type WholeOrderLineItem =
  GetAllWholesaleOrdersDetailsQuery['wholesaleOrderBillingDocuments'][number]['lineItems'][number]['wholesaleOrderLineItem']

export type WholesaleOrderBillingDocumentExistingLineItemsTableRecord = {
  position: number
  wholesaleOrderBillingDocumentLineItemIdentifier: string
  wholesaleOrderLineItemIdentifier: string
  wholesaleOrderBillingDocumentLineItemQuantity: number
  wholesaleOrderLineItemQuantity: number
  productName: string
  internalSku: string | null
  ean: string | null
  attributes: string[]
  asins: string[]
  skus: string[]
  quantity: number | null
  netPurchasePrice: number
  currency: string
  taxRate: number
}

type WholesaleOrderBillingDocumentExistingLineItemsTableProps = {
  billingDocuments: GetAllWholesaleOrdersDetailsQuery['wholesaleOrderBillingDocuments']
}

export const WholesaleOrderBillingDocumentExistingLineItemsTable = ({
  billingDocuments,
}: WholesaleOrderBillingDocumentExistingLineItemsTableProps) => {
  const { t } = useTranslation('wholesale-order-billing-document')

  const form = useFormInstance<CreateWholesaleOrderBillingDocumentFormValues>()
  const billingDocumentUuid = useWatch('billingDocumentUuid', form)

  const columnDefs = useWholesaleOrderBillingDocumentExistingLineItemsTableColumnDefs()

  const wholesaleOrderBillingDocument = billingDocuments.find((billingDocument) => {
    return billingDocument.uuid === billingDocumentUuid
  })

  const wholesaleOrderBillingDocumentLineItems = formatWholesaleOrderBillingDocumentLineItems(
    wholesaleOrderBillingDocument
  )

  const getRowId = useMemo(
    () => (params: GetRowIdParams<WholesaleOrderBillingDocumentExistingLineItemsTableRecord>) =>
      params.data.wholesaleOrderBillingDocumentLineItemIdentifier,
    []
  )

  const rowSelection = useMemo<
    RowSelectionOptions<WholesaleOrderBillingDocumentExistingLineItemsTableRecord>
  >(() => {
    return {
      mode: 'multiRow',
      selectAll: 'filtered',
    }
  }, [])

  const selectionColumnDef = useMemo<SelectionColumnDef>(() => {
    return {
      minWidth: 40,
      maxWidth: 40,
      pinned: 'left',
    }
  }, [])

  const handleCellEditRequest = useCallback(
    async (
      event: CellEditRequestEvent<WholesaleOrderBillingDocumentExistingLineItemsTableRecord>
    ) => {
      const field = event.colDef.field

      if (!field || event.newValue === event.oldValue) {
        return false
      }

      try {
        if (field === 'quantity') {
          const lineItems: CreateWholesaleOrderBillingDocumentFormValues['lineItems'] =
            form.getFieldValue('lineItems')

          const updatedLineItems = lineItems
            .map((lineItem) => {
              if (lineItem.identifier === event.data.wholesaleOrderLineItemIdentifier) {
                return {
                  ...lineItem,
                  quantity: event.newValue,
                }
              }
            })
            .filter(Boolean)

          form.setFieldValue('lineItems', updatedLineItems)

          event.api.applyTransaction({
            update: [{ ...event.data, quantity: event.newValue }],
          })
        }
      } catch (error) {
        console.error(error)
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: event.oldValue }],
        })
      }
    },
    []
  )

  const handleSelectionChanged = (
    event: SelectionChangedEvent<WholesaleOrderBillingDocumentExistingLineItemsTableRecord>
  ) => {
    const selectedRows: WholesaleOrderBillingDocumentExistingLineItemsTableRecord[] =
      event.api.getSelectedRows()

    form.setFieldValue(
      'lineItems',
      selectedRows.map((selectedRow) => ({
        position: selectedRow.position,
        identifier: selectedRow.wholesaleOrderLineItemIdentifier,
        productName: selectedRow.productName,
        internalSku: selectedRow.internalSku,
        ean: selectedRow.ean,
        attributes: selectedRow.attributes,
        asins: selectedRow.asins,
        skus: selectedRow.skus,
        netPurchasePrice: selectedRow.netPurchasePrice,
        currency: selectedRow.currency,
        taxRate: selectedRow.taxRate,
        quantity: selectedRow.quantity,
      }))
    )

    const selectedIdentifiers = new Set(
      selectedRows.map((row) => row.wholesaleOrderLineItemIdentifier)
    )
    const deselectedRows = wholesaleOrderBillingDocumentLineItems.filter((row) => {
      return !selectedIdentifiers.has(row.wholesaleOrderLineItemIdentifier)
    })

    event.api.applyTransaction({ update: [...selectedRows, ...deselectedRows] })
    event.api.refreshCells({ force: true })
  }

  return (
    <Form.Item<CreateWholesaleOrderBillingDocumentFormValues>
      name="lineItems"
      rules={[
        {
          required: true,
          message: t(
            'wholesaleOrderBillingDocumentLineItemsSelectorTable.validation.error.required'
          ),
        },
        {
          validator: (_, values: CreateWholesaleOrderBillingDocumentFormValues['lineItems']) => {
            const isQuantityDefined = values.every((lineItem) => {
              return lineItem.quantity !== null && lineItem.quantity !== undefined
            })

            if (!isQuantityDefined) {
              return Promise.reject(
                new Error(
                  t(
                    'wholesaleOrderBillingDocumentLineItemsSelectorTable.validation.error.noQuantity'
                  )
                )
              )
            }

            return Promise.resolve()
          },
        },
      ]}
    >
      <Table<WholesaleOrderBillingDocumentExistingLineItemsTableRecord>
        columnDefs={columnDefs}
        getRowId={getRowId}
        onCellEditRequest={handleCellEditRequest}
        onSelectionChanged={handleSelectionChanged}
        persist={{
          key: TablePersistKey.WHOLESALE_ORDER_BILLING_DOCUMENT_SELECT,
          storage: localStorage,
        }}
        grandTotalRow="bottom"
        readOnlyEdit
        rowData={wholesaleOrderBillingDocumentLineItems}
        rowSelection={rowSelection}
        selectionColumnDef={selectionColumnDef}
        showColumnChooser
        showFilterReset
        showUniversalSearch
        suppressContextMenu
      />
    </Form.Item>
  )
}

function formatWholesaleOrderBillingDocumentLineItems(
  wholesaleOrderBillingDocument:
    | GetAllWholesaleOrdersDetailsQuery['wholesaleOrderBillingDocuments'][number]
    | undefined
): WholesaleOrderBillingDocumentExistingLineItemsTableRecord[] {
  if (!wholesaleOrderBillingDocument) {
    return []
  }

  return wholesaleOrderBillingDocument.lineItems.map((lineItem) => {
    const attributes = lineItem.wholesaleOrderLineItem.productVariant.attributes
      ? formatAttributes(lineItem.wholesaleOrderLineItem.productVariant.attributes)
      : []
    const asins =
      lineItem.wholesaleOrderLineItem.productVariant.amazonProducts?.map(({ asin }) => asin) ?? []
    const skus = extractUniqueSkus(lineItem.wholesaleOrderLineItem)

    return {
      position: lineItem.position,
      wholesaleOrderBillingDocumentLineItemIdentifier: lineItem.identifier,
      wholesaleOrderLineItemIdentifier: lineItem.wholesaleOrderLineItem.identifier,
      wholesaleOrderBillingDocumentLineItemQuantity: lineItem.quantity,
      wholesaleOrderLineItemQuantity: lineItem.wholesaleOrderLineItem.totalQuantity,
      productName: lineItem.wholesaleOrderLineItem.productVariant.product.name,
      internalSku: lineItem.wholesaleOrderLineItem.productVariant.internalSku ?? null,
      ean: lineItem.wholesaleOrderLineItem.productVariant.ean ?? null,
      attributes,
      asins,
      skus,
      quantity: null,
      netPurchasePrice: lineItem.netPurchasePrice,
      currency: lineItem.currency,
      taxRate: lineItem.taxRate,
    }
  })
}

function formatAttributes(attributes: { option: string; value: string }[]) {
  return attributes.map((attr) => `${attr.option}: ${attr.value}`)
}

function extractUniqueSkus(lineItem: WholeOrderLineItem) {
  const uniqueSkus = new Set<string>()

  if (lineItem.productVariant.amazonProducts) {
    for (const amazonProduct of lineItem.productVariant.amazonProducts) {
      if (amazonProduct.amazonListings) {
        for (const listing of amazonProduct.amazonListings) {
          if (listing.sku) {
            uniqueSkus.add(listing.sku)
          }
        }
      }
    }
  }

  return Array.from(uniqueSkus)
}
