import { theme } from 'antd'
import { ThemeConfig } from 'antd/es/config-provider/context'

export const lightTheme = {
  colors: {
    primary: '#2563eb',
    base: {
      1: '#f8fafc',
      2: '#eaf0f6',
      3: '#f8fafc',
      4: '#f8fafc',
      5: '#f3f5f8',
      6: '#e3ebf3',
      7: '#c2d4e5',
    },
    text: '#0f172a',
    background: '#f8fafc',
    success: '#22c55e',
    warning: '#eab308',
    error: '#ef4444',
  },
  fontFamily: 'Inter, sans-serif',
}

export const lightThemeAntd: ThemeConfig = {
  algorithm: theme.defaultAlgorithm,
  components: {
    Layout: {
      headerBg: 'transparent',
    },
  },
  token: {
    colorPrimary: lightTheme.colors.primary,
    colorInfo: lightTheme.colors.primary,
    colorSuccess: lightTheme.colors.success,
    colorWarning: lightTheme.colors.warning,
    colorError: lightTheme.colors.error,
    colorLink: lightTheme.colors.primary,
    colorTextBase: lightTheme.colors.text,
    colorBgBase: lightTheme.colors.background,
    fontFamily: lightTheme.fontFamily,
  },
}

export const darkTheme = {
  colors: {
    primary: '#60a5fa',
    base: {
      1: '#0f172a',
      2: '#0f172a',
      3: '#1a2748',
      4: '#1f3057',
      5: '#232f4f',
      6: '#283e71',
      7: '#324c8c',
    },
    text: '#f8fafc',
    background: '#0f172a',
    success: '#4ade80',
    warning: '#facc15',
    error: '#f87171',
  },
  fontFamily: 'Inter, sans-serif',
}

export const darkThemeAntd: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  components: {
    Layout: {
      headerBg: 'transparent',
    },
  },
  token: {
    colorPrimary: darkTheme.colors.primary,
    colorInfo: darkTheme.colors.primary,
    colorSuccess: darkTheme.colors.success,
    colorWarning: darkTheme.colors.warning,
    colorError: darkTheme.colors.error,
    colorLink: darkTheme.colors.primary,
    colorTextBase: darkTheme.colors.text,
    colorBgBase: darkTheme.colors.background,
    fontFamily: darkTheme.fontFamily,
  },
}
