import { useMutation } from '@apollo/client'
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  CreateWarehouseServiceProviderLocationDocument,
  GetWarehouseServiceProvidersDocument,
} from '../../../../generated/graphql'
import { useCountryOptions } from '../../../../hooks/useCountryOptions'
import { useGlobalStore } from '../../../../stores/useGlobalStore'

type CreateWarehouseServiceProviderLocationFormInstance = {
  name: string
  addressLine1: string
  addressLine2: string
  postalCode: string
  city: string
  provinceCode: string
  countryCode: string
  phone: string
  checkOutDurationInDays: number
}

type AddWarehouseServiceProviderLocationModalProps = {
  warehouseServiceProviderUuid: string
}

export const AddWarehouseServiceProviderLocationModal = ({
  warehouseServiceProviderUuid,
}: AddWarehouseServiceProviderLocationModalProps) => {
  const [open, setOpen] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation('warehousing')
  const [form] = Form.useForm<CreateWarehouseServiceProviderLocationFormInstance>()
  const countryOptions = useCountryOptions()

  const [createWarehouseServiceProviderLocation, { loading }] = useMutation(
    CreateWarehouseServiceProviderLocationDocument
  )

  const handleSubmit = async (values: CreateWarehouseServiceProviderLocationFormInstance) => {
    try {
      if (!selectedCompany?.uuid) {
        throw new Error()
      }

      await createWarehouseServiceProviderLocation({
        variables: {
          companyUuid: selectedCompany.uuid,
          input: {
            warehouseServiceProviderUuid,
            active: true,
            ...values,
          },
        },
        optimisticResponse: {
          createWarehouseServiceProviderLocation: {
            uuid: 'optimistic',
            active: true,
            ...values,
          },
        },
        update: (cache, { data }) => {
          const createdWarehouseServiceProviderLocation =
            data?.createWarehouseServiceProviderLocation
          const cachedQuery = cache.readQuery({
            query: GetWarehouseServiceProvidersDocument,
            variables: { companyUuid: selectedCompany.uuid },
          })

          if (createdWarehouseServiceProviderLocation && cachedQuery) {
            const warehouseServiceProviders = cachedQuery.warehouseServiceProviders.map(
              (warehouseServiceProvider) => {
                if (warehouseServiceProvider.uuid === warehouseServiceProviderUuid) {
                  return {
                    ...warehouseServiceProvider,
                    locations: [
                      ...(warehouseServiceProvider.locations ?? []),
                      createdWarehouseServiceProviderLocation,
                    ],
                  }
                }

                return warehouseServiceProvider
              }
            )

            cache.writeQuery({
              query: GetWarehouseServiceProvidersDocument,
              variables: { companyUuid: selectedCompany.uuid },
              data: { warehouseServiceProviders },
            })
          }
        },
      })

      toast.success(t('location.add.toast.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('location.add.toast.error'))
    } finally {
      setOpen(false)
      form.resetFields()
    }
  }

  return (
    <>
      <Button type="link" icon={<Plus size={16} />} onClick={() => setOpen(true)}>
        {t('location.add.button')}
      </Button>
      <Modal
        title={t('location.add.title')}
        open={open}
        okText={t('shared.button.save', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelButtonProps={{ disabled: loading }}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
      >
        <Form<CreateWarehouseServiceProviderLocationFormInstance>
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item<CreateWarehouseServiceProviderLocationFormInstance>
                name="name"
                label={t('location.add.form.name.label')}
                rules={[
                  { required: true, message: t('location.add.form.name.rules.required') },
                  { min: 3, message: t('location.add.form.name.rules.minLength') },
                ]}
                validateDebounce={1000}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item<CreateWarehouseServiceProviderLocationFormInstance>
                name="addressLine1"
                label={t('location.add.form.addressLine1.label')}
                rules={[
                  {
                    required: true,
                    message: t('location.add.form.addressLine1.rules.required'),
                  },
                ]}
                validateDebounce={1000}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item<CreateWarehouseServiceProviderLocationFormInstance>
                name="addressLine2"
                label={t('location.add.form.addressLine2.label')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item<CreateWarehouseServiceProviderLocationFormInstance>
                name="postalCode"
                label={t('location.add.form.postalCode.label')}
                rules={[
                  { required: true, message: t('location.add.form.postalCode.rules.required') },
                ]}
                validateDebounce={1000}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item<CreateWarehouseServiceProviderLocationFormInstance>
                name="city"
                label={t('location.add.form.city.label')}
                rules={[{ required: true, message: t('location.add.form.city.rules.required') }]}
                validateDebounce={1000}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item<CreateWarehouseServiceProviderLocationFormInstance>
                name="provinceCode"
                label={t('location.add.form.provinceCode.label')}
                rules={[
                  {
                    min: 2,
                    max: 2,
                    message: t('location.add.form.provinceCode.rules.exactLength'),
                  },
                ]}
                validateDebounce={1000}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item<CreateWarehouseServiceProviderLocationFormInstance>
                name="countryCode"
                label={t('location.add.form.countryCode.label')}
                rules={[
                  { required: true, message: t('location.add.form.countryCode.rules.required') },
                ]}
                validateDebounce={1000}
              >
                <Select<string>
                  options={countryOptions.map((country) => ({
                    value: country.code,
                    label: country.name,
                  }))}
                  placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item<CreateWarehouseServiceProviderLocationFormInstance>
                name="phone"
                label={t('location.add.form.phone.label')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item<CreateWarehouseServiceProviderLocationFormInstance>
                name="checkOutDurationInDays"
                label={t('location.add.form.checkOutDurationInDays.label')}
                rules={[
                  {
                    required: true,
                    message: t('location.add.form.checkOutDurationInDays.rules.required'),
                  },
                ]}
                validateDebounce={1000}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
