import { StockTableRecord } from '../components/StockTable'

export enum StockLevel {
  ALL_STOCK = 'ALL_STOCK',
  LOW_STOCK = 'LOW_STOCK',
  OVERSTOCK = 'OVERSTOCK',
}

export const filterDataByStockLevel = (
  data: StockTableRecord[],
  stockLevel: string | number
): StockTableRecord[] => {
  switch (stockLevel) {
    case StockLevel.ALL_STOCK:
      return data

    case StockLevel.LOW_STOCK:
      return data.filter((item) => {
        return item.recommendedOrderQuantity && item.recommendedOrderQuantity > 0
      })

    case StockLevel.OVERSTOCK:
      return data.filter((item) => {
        return item.overstock && item.overstock > 0
      })

    default:
      return data
  }
}
