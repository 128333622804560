import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export enum AppTheme {
  DARK = 'dark',
  LIGHT = 'light',
}

type AppThemeState = {
  appTheme: AppTheme
  changeAppTheme: (theme: AppTheme) => void
}

export const useAppThemeStore = create<AppThemeState>()(
  persist(
    (set) => ({
      appTheme: AppTheme.LIGHT,
      changeAppTheme: (appTheme: AppTheme) => set({ appTheme }),
    }),
    {
      name: 'galaxy-app-theme',
    }
  )
)
