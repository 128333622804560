import { Badge, Col, List, Row, Space, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { TooltipProps } from 'recharts'
import { formatMoney } from '../../../../../helpers/formatMoney'
import { StyledMainCard } from '../../../styles'
import { DatedPerformanceOverview } from '../../helpers/preparePerformanceOverview'
import { DashboardProductLabel } from '../DashboardProductLabel'

export const DashboardBarChartTooltip = (props: TooltipProps<number, string>) => {
  const payload: DatedPerformanceOverview | undefined = props.payload?.[0]?.payload

  const { t } = useTranslation(['dashboard', 'translation'])

  if (!payload) {
    return null
  }

  const weekday = dayjs(payload.date, 'DD.MM.YY').format('dddd')
  const title = `${weekday}, ${payload.date}`

  return (
    <StyledMainCard title={title} style={{ width: '400px', opacity: '0.98' }}>
      <Row gutter={[8, 16]}>
        <Col span={12}>
          <Space direction="vertical">
            <Typography.Text strong>{t('performance.shared.orders')}</Typography.Text>
            <Typography.Text>
              {payload.totalShipped + payload.totalPending + payload.totalCanceled} (
              {payload.totalShipped} / {payload.totalPending} / {payload.totalCanceled})
            </Typography.Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Typography.Text strong>{t('performance.shared.revenue')}</Typography.Text>
            <Row gutter={[4, 4]}>
              <Col>
                <Typography.Text>{formatMoney(payload.totalRevenue, 'EUR')}</Typography.Text>
              </Col>
              <Col flex="auto">
                <Typography.Text style={{ opacity: 0.4 }}>
                  {formatMoney(payload.pendingRevenue, 'EUR')}
                </Typography.Text>
              </Col>
            </Row>
          </Space>
        </Col>
        <Col span={24} hidden={!payload.performance.products.length}>
          <List
            header={<Typography.Text strong>{t('performance.shared.topProducts')}</Typography.Text>}
            dataSource={payload.performance.products.slice(0, 5)}
            renderItem={(item) => (
              <List.Item style={{ padding: '0.5rem 0' }}>
                <Row gutter={8} style={{ width: '100%' }}>
                  <Col span={12}>
                    <Badge
                      color={item.color}
                      text={<DashboardProductLabel product={item} />}
                      style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <Tooltip
                      title={`${item.totalShipped} / ${item.totalPending} / ${item.totalCanceled}`}
                    >
                      <Row gutter={[4, 4]}>
                        <Col>
                          <Typography.Text>{formatMoney(item.totalRevenue, 'EUR')}</Typography.Text>
                        </Col>
                        <Col flex="auto">
                          <Typography.Text style={{ opacity: 0.4 }}>
                            {formatMoney(item.pendingRevenue, 'EUR')}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
              </List.Item>
            )}
            locale={{ emptyText: t('shared.noData', { ns: 'translation' }) }}
            split={false}
          />
        </Col>
      </Row>
    </StyledMainCard>
  )
}
