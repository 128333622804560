import { Button } from 'antd'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetProductVariantQuery } from '../../../generated/graphql'
import { StyledMainCard } from '../../dashboard/styles'
import { ProductVariantPackagingUnitModal } from './ProductVariantPackagingUnitModal'
import { ProductVariantPackagingUnitTable } from './ProductVariantPackagingUnitTable'

export type PackagingUnit = NonNullable<
  GetProductVariantQuery['productVariant']['packagingUnits']
>[number]

type ProductVariantPackagingUnitCardProps = {
  productVariantUuid: string
  packagingUnits: PackagingUnit[]
  loading: boolean
}

export const ProductVariantPackagingUnitCard = ({
  productVariantUuid,
  packagingUnits,
  loading,
}: ProductVariantPackagingUnitCardProps) => {
  const [open, setOpen] = useState(false)
  const [selectedPackagingUnit, setSelectedPackagingUnit] = useState<PackagingUnit | null>(null)

  const { t } = useTranslation('inventory')

  const handleModalButtonClick = () => {
    setSelectedPackagingUnit(null)
    setOpen(true)
  }

  return (
    <StyledMainCard
      title={t('product.details.productVariant.packagingUnits')}
      extra={
        <Button icon={<Plus size={16} />} onClick={handleModalButtonClick}>
          {t('product.details.productVariant.packagingUnitModal.createTitle')}
        </Button>
      }
    >
      <ProductVariantPackagingUnitModal
        open={open}
        setOpen={setOpen}
        productVariantUuid={productVariantUuid}
        selectedPackagingUnit={selectedPackagingUnit}
        setSelectedPackagingUnit={setSelectedPackagingUnit}
      />
      <ProductVariantPackagingUnitTable
        packagingUnits={packagingUnits}
        setPackagingModalOpen={setOpen}
        setSelectedPackagingUnit={setSelectedPackagingUnit}
        loading={loading}
      />
    </StyledMainCard>
  )
}
