import { useQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import { ColDef, ColGroupDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { Flex, Tag, Tooltip, Typography } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { TFunction } from 'i18next'
import { Eye } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../components/FormattedDate'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { LinkButton } from '../../../components/LinkButton'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { Table } from '../../../components/Table/Table'
import {
  GetAllProductComplianceChecksForCompanyDocument,
  GetAllProductComplianceChecksForCompanyQuery,
  Marketplace,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { TablePersistKey } from '../../../stores/useTableStore'

type ProductComplianceCheck = NonNullable<
  GetAllProductComplianceChecksForCompanyQuery['productComplianceChecks']
>[number]
type ProductComplianceCheckRecord = {
  productName: string
  productUuid: string
  identifier: string
  lowestComplianceStatus: string
  countryCodes: { marketplace: Marketplace; status: string }[]
  eans: string[]
  internalSkus: string[]
  mostRecentComplianceDate: Dayjs | null
}

export const ComplianceTable = () => {
  const { t } = useTranslation(['compliance', 'translation'])
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const theme = useTheme()

  const { data, loading, error } = useQuery(GetAllProductComplianceChecksForCompanyDocument, {
    fetchPolicy: 'cache-first',
    skip: !selectedCompany?.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  const [columnDefs] = useState<
    (ColDef<ProductComplianceCheckRecord> | ColGroupDef<ProductComplianceCheckRecord>)[]
  >([
    {
      filter: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      minWidth: 56,
      maxWidth: 56,
      cellRenderer: ({ data }: ICellRendererParams<ProductComplianceCheckRecord>) => {
        if (!data?.identifier) {
          return null
        }

        return <LinkButton icon={<Eye size={16} />} to={`/compliance/${data.identifier}`} />
      },
    },
    {
      field: 'productName',
      headerName: t('compliance:table.productName'),
      filter: 'agTextColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          ProductComplianceCheckRecord,
          ProductComplianceCheckRecord['productName']
        >
      ) => {
        if (!params?.data) {
          return null
        }

        return (
          <LinkButton
            type="link"
            to={`/products/${params.data?.productUuid}`}
            style={{ padding: 0 }}
          >
            {params.data.productName}
          </LinkButton>
        )
      },
      getQuickFilterText: ({ data }) => {
        const eans = data.eans.join(', ')
        const internalSkus = data.internalSkus.join(', ')
        return `${data.productName} - ${eans} - ${internalSkus}`
      },
    },
    {
      field: 'identifier',
      headerName: t('compliance:table.identifier'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'lowestComplianceStatus',
      headerName: t('compliance:table.status.header'),
      filterValueGetter: (params) => {
        if (!params.data?.lowestComplianceStatus) {
          return null
        }

        const statusDetails = getStatusDetails(params.data.lowestComplianceStatus, t)

        return statusDetails.statusText
      },
      cellRenderer: (
        params: ICellRendererParams<
          ProductComplianceCheckRecord,
          ProductComplianceCheckRecord['lowestComplianceStatus']
        >
      ) => {
        if (!params.data?.lowestComplianceStatus) {
          return null
        }

        const statusDetails = getStatusDetails(params.data.lowestComplianceStatus, t)

        return (
          <Tooltip
            title={
              <Flex vertical gap={8}>
                <Typography.Text strong>{statusDetails.statusText}</Typography.Text>
                <Typography.Text>{statusDetails.tooltip}</Typography.Text>
              </Flex>
            }
            color={theme.colors.base[5]}
            mouseEnterDelay={0.5}
          >
            <Tag color={statusDetails.color}>{statusDetails.statusText}</Tag>
          </Tooltip>
        )
      },
    },
    {
      field: 'countryCodes',
      headerName: t('compliance:table.countryCodes'),
      filterValueGetter: (params) => {
        return params.data?.countryCodes.map((countryCode) => countryCode.marketplace)
      },
      valueGetter: (
        params: ValueGetterParams<
          ProductComplianceCheckRecord,
          ProductComplianceCheckRecord['countryCodes']
        >
      ) => params.data?.countryCodes.map((countryCode) => countryCode.marketplace).join(', '),
      cellRenderer: (params: ICellRendererParams<ProductComplianceCheckRecord>) => {
        return params.data?.countryCodes.map((countryCode) => {
          const statusDetails = getStatusDetails(countryCode.status, t)

          return (
            <Tooltip
              key={countryCode.marketplace}
              title={
                <Flex vertical gap={8}>
                  <Typography.Text strong>{statusDetails.statusText}</Typography.Text>
                  <Typography.Text>{statusDetails.tooltip}</Typography.Text>
                </Flex>
              }
              color={theme.colors.base[5]}
              mouseEnterDelay={0.5}
            >
              <Tag color={statusDetails.color}>{countryCode.marketplace}</Tag>
            </Tooltip>
          )
        })
      },
    },
    {
      field: 'mostRecentComplianceDate',
      headerName: t('compliance:table.date'),
      filter: 'agDateColumnFilter',
      filterValueGetter: (params) => {
        if (!params.data?.mostRecentComplianceDate) {
          return null
        }

        return params.data.mostRecentComplianceDate.startOf('day').toDate()
      },
      cellRenderer: ({ value }: ICellRendererParams<ProductComplianceCheckRecord>) => {
        if (!value) {
          return null
        }

        return (
          <FormattedDate date={value} format="DD.MM.YYYY" withRelativeTime layout="horizontal" />
        )
      },
    },
  ])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult status="500" title="500" subTitle={t('translation:shared.error.message')} />
    )
  }

  const complianceChecks = prepareComplianceChecks(data?.productComplianceChecks)

  return (
    <Table<ProductComplianceCheckRecord>
      columnDefs={columnDefs}
      fullHeight
      persist={{
        key: TablePersistKey.PRODUCT_COMPLIANCE,
        storage: localStorage,
      }}
      rowData={complianceChecks}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
      storeSearchInSearchParams
    />
  )
}

const prepareComplianceChecks = (
  data: ProductComplianceCheck[] | null | undefined
): ProductComplianceCheckRecord[] => {
  if (!data) {
    return []
  }

  return data.map((complianceCheck) => {
    const lowestComplianceStatus = getLeastCompliantStatus(complianceCheck.items)
    const latestComplianceItem = complianceCheck.items.reduce((previousValue, currentValue) => {
      if (!currentValue.markedCompliantDate) {
        return previousValue
      }
      if (
        dayjs(previousValue?.markedCompliantDate).isAfter(dayjs(currentValue.markedCompliantDate))
      ) {
        return previousValue
      }
      return currentValue
    }, complianceCheck.items[0])
    const eans: string[] = []
    const internalSkus: string[] = []

    complianceCheck.product.productVariants?.forEach((productVariant) => {
      if (productVariant.ean) {
        eans.push(productVariant.ean)
      }
      if (productVariant.internalSku) {
        internalSkus.push(productVariant.internalSku)
      }
    })

    return {
      productName: complianceCheck.product.name,
      productUuid: complianceCheck.product.uuid,
      identifier: complianceCheck.identifier,
      lowestComplianceStatus,
      eans,
      internalSkus,
      countryCodes: complianceCheck.items.map((item) => ({
        marketplace: item.countryCode as Marketplace,
        status: item.complianceStatus,
      })),
      mostRecentComplianceDate: latestComplianceItem?.markedCompliantDate
        ? dayjs(latestComplianceItem.markedCompliantDate)
        : null,
    }
  })
}

export const getLeastCompliantStatus = (items: { complianceStatus: string }[]) => {
  let hasInReview = false
  let hasApprovedWithReservation = false

  for (const item of items) {
    if (item.complianceStatus === 'IN_REVIEW') {
      hasInReview = true
      break
    } else if (item.complianceStatus === 'APPROVED_WITH_RESERVATION') {
      hasApprovedWithReservation = true
    }
  }

  if (hasInReview) {
    return 'IN_REVIEW'
  } else if (hasApprovedWithReservation) {
    return 'APPROVED_WITH_RESERVATION'
  } else {
    return 'COMPLIANT'
  }
}

export const getStatusDetails = (status: string, t: TFunction<'compliance'>) => {
  switch (status) {
    case 'COMPLIANT':
      return {
        color: 'success',
        tooltip: t('table.status.tooltip.COMPLIANT'),
        statusText: t('table.status.status.COMPLIANT'),
      }
    case 'IN_REVIEW':
      return {
        color: 'warning',
        tooltip: t('table.status.tooltip.IN_REVIEW'),
        statusText: t('table.status.status.IN_REVIEW'),
      }
    case 'APPROVED_WITH_RESERVATION':
      return {
        color: 'orange',
        tooltip: t('table.status.tooltip.APPROVED_WITH_RESERVATION'),
        statusText: t('table.status.status.APPROVED_WITH_RESERVATION'),
      }
    default:
      return {
        color: 'default',
        tooltip: '',
        statusText: '',
      }
  }
}
