import { useMutation } from '@apollo/client'
import {
  ColDef,
  ColGroupDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { Button, Flex, Popconfirm, Tag, Tooltip, Typography } from 'antd'
import { Eye, Trash } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { LinkButton } from '../../../components/LinkButton'
import { Table } from '../../../components/Table/Table'
import {
  GtinType,
  ProductVariantPackagingUnitType,
  UpdateProductVariantPackagingUnitDocument,
} from '../../../generated/graphql'
import { formatUnit } from '../../../helpers/formatUnit'
import { TablePersistKey } from '../../../stores/useTableStore'
import {
  getProductVariantPackagingUnitTypeColor,
  getProductVariantPackagingUnitTypeText,
} from './ProductVariantPackagingUnitTable'

export type ProductVariantPackagingUnitsTableRecord = {
  uuid: string
  productUuid: string
  productVariantUuid: string
  gtin: string | null
  gtinType: GtinType | null
  type: ProductVariantPackagingUnitType
  internalSku: string | null
  quantity: number
  widthInCm: number | null
  heightInCm: number | null
  lengthInCm: number | null
  weightInGram: number | null
  searchIdentifiers: string
}

type ProductVariantPackagingUnitsTableProps = {
  productVariantPackagingUnits: ProductVariantPackagingUnitsTableRecord[] | undefined
}

export const ProductVariantPackagingUnitsTable = ({
  productVariantPackagingUnits,
}: ProductVariantPackagingUnitsTableProps) => {
  const { t } = useTranslation('inventory')

  const [updateProductVariantPackagingUnit] = useMutation(UpdateProductVariantPackagingUnitDocument)

  const handleArchive = async (packagingUnitRecord: ProductVariantPackagingUnitsTableRecord) => {
    try {
      await updateProductVariantPackagingUnit({
        variables: {
          input: {
            uuid: packagingUnitRecord.uuid,
            archivedAt: new Date().toISOString(),
          },
        },
      })
      toast.success(t('shared.notification.success', { ns: 'translation' }))
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    }
  }

  const [columnDefs] = useState<
    (
      | ColDef<ProductVariantPackagingUnitsTableRecord>
      | ColGroupDef<ProductVariantPackagingUnitsTableRecord>
    )[]
  >([
    {
      lockPosition: 'left',
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      minWidth: 96,
      maxWidth: 96,
      cellRenderer: (params: ICellRendererParams<ProductVariantPackagingUnitsTableRecord>) => {
        if (params.node.group) {
          return null
        }

        return (
          <Flex gap={8} align="center">
            <Tooltip title={t('packagingUnit.table.tooltip.details')}>
              <LinkButton
                icon={<Eye size={16} />}
                to={`/products/${params.data?.productUuid}/variants/${params.data?.productVariantUuid}`}
              />
            </Tooltip>
            <Popconfirm
              placement="top"
              title={t('packagingUnit.table.archive.tooltip')}
              okText={t('packagingUnit.table.archive.confirmButton')}
              okButtonProps={{ danger: true }}
              onConfirm={() => handleArchive(params.data!)}
              cancelText={t('packagingUnit.table.archive.cancelButton')}
              cancelButtonProps={{ type: 'text' }}
            >
              <Tooltip title={t('packagingUnit.table.archive.tooltip')}>
                <Button danger icon={<Trash size={16} />} />
              </Tooltip>
            </Popconfirm>
          </Flex>
        )
      },
    },
    {
      field: 'gtin',
      headerName: t('product.details.productVariant.packagingUnit.table.gtin'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'gtinType',
      headerName: t('product.details.productVariant.packagingUnit.table.gtinType'),
    },
    {
      field: 'type',
      headerName: t('product.details.productVariant.packagingUnit.table.type.header'),
      filterValueGetter: (
        params: ValueGetterParams<
          ProductVariantPackagingUnitsTableRecord,
          ProductVariantPackagingUnitsTableRecord['type']
        >
      ) => {
        if (!params.data?.type) {
          return null
        }

        return getProductVariantPackagingUnitTypeText(params.data.type, t)
      },
      valueFormatter: (
        params: ValueFormatterParams<
          ProductVariantPackagingUnitsTableRecord,
          ProductVariantPackagingUnitsTableRecord['type']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        return getProductVariantPackagingUnitTypeText(params.value, t)
      },
      cellRenderer: (
        params: ICellRendererParams<
          ProductVariantPackagingUnitsTableRecord,
          ProductVariantPackagingUnitsTableRecord['type']
        >
      ) => {
        if (!params.value) {
          return null
        }

        return (
          <Tag color={getProductVariantPackagingUnitTypeColor(params.value)}>
            {params.valueFormatted}
          </Tag>
        )
      },
    },
    {
      field: 'internalSku',
      headerName: t('product.details.productVariant.packagingUnit.table.internalSku'),
      filter: 'agTextColumnFilter',
      getQuickFilterText: (
        params: GetQuickFilterTextParams<
          ProductVariantPackagingUnitsTableRecord,
          ProductVariantPackagingUnitsTableRecord['internalSku']
        >
      ) => {
        return params.data?.searchIdentifiers
      },
    },
    {
      field: 'quantity',
      headerName: t('product.details.productVariant.packagingUnit.table.quantity'),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'lengthInCm',
      headerName: t('product.details.productVariant.packagingUnit.table.lengthInCm'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams<ProductVariantPackagingUnitsTableRecord>) => {
        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatUnit(params.value, 'centimeter')}</Typography.Text>
      },
    },
    {
      field: 'widthInCm',
      headerName: t('product.details.productVariant.packagingUnit.table.widthInCm'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams<ProductVariantPackagingUnitsTableRecord>) => {
        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatUnit(params.value, 'centimeter')}</Typography.Text>
      },
    },
    {
      field: 'heightInCm',
      headerName: t('product.details.productVariant.packagingUnit.table.heightInCm'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams<ProductVariantPackagingUnitsTableRecord>) => {
        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatUnit(params.value, 'centimeter')}</Typography.Text>
      },
    },
    {
      field: 'weightInGram',
      headerName: t('product.details.productVariant.packagingUnit.table.weightInGram'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams<ProductVariantPackagingUnitsTableRecord>) => {
        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatUnit(params.value, 'gram')}</Typography.Text>
      },
    },
  ])

  return (
    <Table
      columnDefs={columnDefs}
      fullHeight
      persist={{
        key: TablePersistKey.PRODUCT_VARIANT_PACKAGING_UNITS,
        storage: localStorage,
      }}
      rowData={productVariantPackagingUnits}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
      storeSearchInSearchParams
    />
  )
}
