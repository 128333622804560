import { skipToken, useSuspenseQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { GetCompanyBrandsDocument } from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { BrandManagementCollapse } from './BrandManagementCollapse'
import { BrandManagementTable } from './BrandManagementTable'
import { BrandManagementTableHeader } from './BrandManagementTableHeader'

export const BrandManagementContainer = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation()

  const { error, data } = useSuspenseQuery(
    GetCompanyBrandsDocument,
    selectedCompany?.uuid
      ? {
          errorPolicy: 'none',
          fetchPolicy: 'cache-first',
          variables: {
            companyUuid: selectedCompany.uuid,
          },
        }
      : skipToken
  )

  if (error) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  const rowData =
    data?.brands.map((brand) => {
      const letterOfAuthorizations =
        brand.letterOfAuthorizations?.map(({ key, authorizedCompany }) => {
          return {
            key,
            authorizedCompany: {
              sellerId: authorizedCompany.sellerId,
            },
          }
        }) ?? []

      return {
        name: brand.name,
        letterOfAuthorizations,
      }
    }) ?? []

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <BrandManagementCollapse />
      </Col>
      <Col span={24}>
        <BrandManagementTableHeader />
      </Col>
      <Col span={24}>
        <BrandManagementTable rowData={rowData} />
      </Col>
    </Row>
  )
}
