import { Popover, Space, Table, Typography } from 'antd'
import { CircleHelp } from 'lucide-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type AmazonListingInventoryUnfulfillableTableProps = {
  unfulfillableInventory: UnfulfillableInventory
}

type UnfulfillableInventory = {
  carrierDamagedQuantity: number
  customerDamagedQuantity: number
  defectiveQuantity: number
  distributorDamagedQuantity: number
  expiredQuantity: number
  warehouseDamagedQuantity: number
  researchingQuantity?: number
  totalQuantity: number
}

export const AmazonListingInventoryUnfulfillableTable = ({
  unfulfillableInventory,
}: AmazonListingInventoryUnfulfillableTableProps) => {
  const {
    carrierDamagedQuantity,
    customerDamagedQuantity,
    defectiveQuantity,
    distributorDamagedQuantity,
    expiredQuantity,
    warehouseDamagedQuantity,
    researchingQuantity,
    totalQuantity,
  } = unfulfillableInventory

  const { t } = useTranslation('inventory')

  const dataSource = useMemo(() => {
    return [
      {
        type: 'carrierDamaged',
        reason: Tooltip(
          t('product.details.inventory.unfulfillablePopover.carrierDamaged', { ns: 'inventory' }),
          t('product.details.inventory.unfulfillablePopover.carrierDamagedPopover', {
            ns: 'inventory',
          })
        ),
        amount: carrierDamagedQuantity,
      },
      {
        type: 'customerDamaged',
        reason: Tooltip(
          t('product.details.inventory.unfulfillablePopover.customerDamaged', { ns: 'inventory' }),
          t('product.details.inventory.unfulfillablePopover.customerDamagedPopover', {
            ns: 'inventory',
          })
        ),
        amount: customerDamagedQuantity,
      },
      {
        type: 'defective',
        reason: Tooltip(
          t('product.details.inventory.unfulfillablePopover.defective', { ns: 'inventory' }),
          t('product.details.inventory.unfulfillablePopover.defectivePopover', { ns: 'inventory' })
        ),
        amount: defectiveQuantity,
      },
      {
        type: 'distributorDamaged',
        reason: Tooltip(
          t('product.details.inventory.unfulfillablePopover.distributorDamaged', {
            ns: 'inventory',
          }),
          t('product.details.inventory.unfulfillablePopover.distributorDamagedPopover', {
            ns: 'inventory',
          })
        ),
        amount: distributorDamagedQuantity,
      },
      {
        type: 'expired',
        reason: Tooltip(
          t('product.details.inventory.unfulfillablePopover.expired', { ns: 'inventory' }),
          t('product.details.inventory.unfulfillablePopover.expiredPopover', { ns: 'inventory' })
        ),
        amount: expiredQuantity,
      },
      {
        type: 'warehouseDamaged',
        reason: Tooltip(
          t('product.details.inventory.unfulfillablePopover.warehouseDamaged', { ns: 'inventory' }),
          t('product.details.inventory.unfulfillablePopover.warehouseDamagedPopover', {
            ns: 'inventory',
          })
        ),
        amount: warehouseDamagedQuantity,
      },
      ...(researchingQuantity !== undefined
        ? [
            {
              type: 'researching',
              reason: Tooltip(
                t('product.details.inventory.unfulfillablePopover.researching', {
                  ns: 'inventory',
                }),
                t('product.details.inventory.unfulfillablePopover.researchingPopover', {
                  ns: 'inventory',
                })
              ),
              amount: researchingQuantity,
            },
          ]
        : []),
      {
        type: 'total',
        reason: t('product.details.inventory.unfulfillablePopover.total', { ns: 'inventory' }),
        amount: totalQuantity,
      },
    ]
  }, [
    carrierDamagedQuantity,
    customerDamagedQuantity,
    defectiveQuantity,
    distributorDamagedQuantity,
    expiredQuantity,
    warehouseDamagedQuantity,
    researchingQuantity,
    totalQuantity,
  ])

  const columns = useMemo(() => {
    return [
      {
        title: t('product.details.inventory.unfulfillablePopover.reasonColumn', {
          ns: 'inventory',
        }),
        dataIndex: 'reason',
      },
      {
        title: t('product.details.inventory.unfulfillablePopover.amountColumn', {
          ns: 'inventory',
        }),
        dataIndex: 'amount',
      },
    ]
  }, [])

  return (
    <>
      <Typography.Paragraph>
        {t('product.details.inventory.unfulfillablePopover.description', { ns: 'inventory' })}
      </Typography.Paragraph>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey="type"
        size="small"
      />
    </>
  )
}

const Tooltip = (reason: string, content: string) => (
  <Space>
    <Typography.Text>{reason}</Typography.Text>
    <Popover content={content} trigger="hover" placement="top">
      <CircleHelp size={16} />
    </Popover>
  </Space>
)
