import styled from '@emotion/styled'
import { Button, List, Popconfirm } from 'antd'
import { Storage } from 'aws-amplify'
import { Download, Eye, Trash } from 'lucide-react'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { downloadFile } from '../helpers/downloadFile'
import { getFilenameFromKey } from '../helpers/getFilenameFromKey'
import { handleView } from '../helpers/handleView'
import { S3ImagePreview } from './S3ImagePreview'

type S3DocumentListCardItemProps = {
  bucketKey: string
  description?: string | ReactNode
  bucketName: string
  showPreview?: boolean
  showViewButton?: boolean
  showDownloadButton?: boolean
  customGetFilenameFromKey?: (key: string) => string
  deleteFunction?: (key: string) => Promise<void> | undefined
}

export const S3DocumentListCardItem = ({
  bucketKey,
  description,
  bucketName,
  showPreview,
  showViewButton,
  showDownloadButton,
  customGetFilenameFromKey,
  deleteFunction,
}: S3DocumentListCardItemProps) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null)
  const [contentType, setContentType] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!bucketKey) return

    const fetchFileUrlAndProperties = async () => {
      try {
        setLoading(true)
        const file = await Storage.get(bucketKey, {
          bucket: bucketName,
        })

        const fileProperties = await Storage.getProperties<any>(bucketKey, {
          bucket: bucketName,
        })

        setContentType(fileProperties.contentType)
        setFileUrl(file)
      } catch (err) {
        console.error('Error fetching file URL:', err)
        setContentType(null)
      } finally {
        setLoading(false)
      }
    }

    fetchFileUrlAndProperties()
  }, [bucketKey])

  return (
    <List.Item
      actions={getListItemActions({
        bucketKey,
        fileUrl,
        showViewButton,
        showDownloadButton,
        customGetFilenameFromKey,
        deleteFunction,
      })}
      style={{ gap: 8, alignItems: 'center' }}
    >
      <StyledMeta
        title={
          customGetFilenameFromKey
            ? customGetFilenameFromKey(bucketKey)
            : getFilenameFromKey(bucketKey)
        }
        description={description && description}
        avatar={
          showPreview && (
            <S3ImagePreview
              fileUrl={fileUrl}
              loading={loading}
              contentType={contentType}
              customGetFilenameFromKey={customGetFilenameFromKey}
            />
          )
        }
      />
    </List.Item>
  )
}

type GetListItemActionsProps = {
  bucketKey: string
  fileUrl: string | null
  showViewButton: boolean | undefined
  showDownloadButton: boolean | undefined
  customGetFilenameFromKey?: (key: string) => string
  deleteFunction?: (key: string) => Promise<void> | undefined
}

function getListItemActions({
  bucketKey,
  fileUrl,
  showViewButton,
  showDownloadButton,
  customGetFilenameFromKey,
  deleteFunction,
}: GetListItemActionsProps): ReactNode[] | undefined {
  const { t } = useTranslation(['translation'])
  const actions: ReactNode[] = []

  const buttonStyle = { padding: '0 6px' }

  if (fileUrl) {
    if (showViewButton) {
      actions.push(
        <Button type={'link'} style={buttonStyle} onClick={async () => await handleView(fileUrl)}>
          <Eye size={16} />
        </Button>
      )
    }

    if (showDownloadButton) {
      actions.push(
        <Button
          type={'link'}
          style={buttonStyle}
          onClick={async () =>
            await downloadFile(
              fileUrl,
              customGetFilenameFromKey
                ? customGetFilenameFromKey(bucketKey)
                : getFilenameFromKey(bucketKey)
            )
          }
        >
          <Download size={16} />
        </Button>
      )
    }

    if (deleteFunction) {
      actions.push(
        <Popconfirm
          title={t('translation:shared.button.delete')}
          okText={t('translation:shared.button.ok')}
          cancelText={t('translation:shared.button.cancel')}
          onConfirm={async () => await deleteFunction(bucketKey)}
        >
          <Button type={'link'} style={buttonStyle} danger>
            <Trash size={16} />
          </Button>
        </Popconfirm>
      )
    }
  }

  return actions
}

const StyledMeta = styled(List.Item.Meta)`
  .ant-list-item-meta-avatar {
    display: flex;
    width: 54px;
    height: 54px;
    justify-content: center;
    align-items: center;
  }

  .ant-image-mask {
    border-radius: 4px;
  }
`
