import { useMutation } from '@apollo/client'
import { CellValueChangedEvent, ColDef, GridApi, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Button, Space } from 'antd'
import { Pen, Plus, Save, Trash } from 'lucide-react'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { Table } from '../../../../components/Table/Table'
import {
  CreateInvoiceArticleCategoryDocument,
  GetInvoiceArticleCategoriesDocument,
  UpdateInvoiceArticleCategoryDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { TablePersistKey } from '../../../../stores/useTableStore'
import { BookingAccountRecord, BookingAccountsTable } from './BookingAccountsTable'

type InvoiceArticleCategoryRecord = {
  uuid: string
  name: string
  bookingAccounts: BookingAccountRecord[]
}

type InvoiceArticleCategoryTableProps = {
  categories: InvoiceArticleCategoryRecord[]
}

export const InvoiceArticleCategoryTable = ({
  categories: initialCategories,
}: InvoiceArticleCategoryTableProps) => {
  const { t } = useTranslation(['translation', 'invoices'])
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const [rowData, setRowData] = useState<InvoiceArticleCategoryRecord[]>(initialCategories)
  const gridRef = useRef<AgGridReact<InvoiceArticleCategoryRecord>>(null)

  const [updateCategory] = useMutation(UpdateInvoiceArticleCategoryDocument, {
    refetchQueries: [
      {
        query: GetInvoiceArticleCategoriesDocument,
        variables: { companyUuid: selectedCompany.uuid },
      },
    ],
  })

  const [createCategory] = useMutation(CreateInvoiceArticleCategoryDocument, {
    refetchQueries: [
      {
        query: GetInvoiceArticleCategoriesDocument,
        variables: { companyUuid: selectedCompany.uuid },
      },
    ],
  })

  const onCellValueChanged = useCallback(
    async (event: CellValueChangedEvent<InvoiceArticleCategoryRecord>) => {
      if (!event) return

      const field = event.colDef.field as keyof InvoiceArticleCategoryRecord
      if (!field) return

      const newValue = event.newValue
      const oldValue = event.oldValue

      if (newValue === oldValue) return

      try {
        let value = newValue
        if (typeof newValue === 'string') {
          value = newValue.trim().toUpperCase()
        }

        const updatedData = { ...event.data, [field]: value }
        event.api.applyTransaction({ update: [updatedData] })

        if (!event.data.uuid.startsWith('temp-')) {
          await updateCategory({
            variables: {
              uuid: event.data.uuid,
              input: {
                name: value,
              },
            },
          })
          toast.success(t('invoices:categories.table.update.success'))
        }
      } catch (error) {
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: oldValue }],
        })
        console.error(error)
        toast.error(t('invoices:categories.table.update.error'))
      }
    },
    [updateCategory, t]
  )

  const handleAddEmptyRow = useCallback(() => {
    const api = gridRef.current?.api
    if (!api) return

    const newEmptyRow: InvoiceArticleCategoryRecord = {
      uuid: `temp-${nanoid()}`,
      name: '',
      bookingAccounts: [],
    }
    api.applyTransaction({ add: [newEmptyRow] })
  }, [])

  const handleSaveNewRows = useCallback(
    async (gridApi: GridApi<InvoiceArticleCategoryRecord>) => {
      try {
        const rowData: InvoiceArticleCategoryRecord[] = []
        gridApi.forEachNodeAfterFilterAndSort((node) => {
          if (node.data) {
            rowData.push(node.data)
          }
        })

        const newRows = rowData.filter((row) => row.uuid.startsWith('temp-'))

        if (newRows.length === 0) {
          toast.info(t('invoices:categories.table.save.noNewRows'))
          return
        }

        const isValid = newRows.every((row) => row.name)

        if (!isValid) {
          toast.error(t('invoices:categories.table.save.invalidRows'))
          return
        }

        for (const newRow of newRows) {
          await createCategory({
            variables: {
              companyUuid: selectedCompany.uuid,
              input: {
                name: newRow.name.trim().toUpperCase(),
              },
            },
          })
        }

        gridApi.applyTransaction({
          remove: newRows,
        })

        toast.success(t('invoices:categories.table.save.success'))
      } catch (error) {
        console.error(error)
        toast.error(t('invoices:categories.table.save.error'))
      }
    },
    [createCategory, selectedCompany.uuid, t]
  )

  useEffect(() => {
    setRowData(initialCategories)
  }, [initialCategories])

  const BookingAccountDetailRenderer = useCallback(
    (params: ICellRendererParams<InvoiceArticleCategoryRecord>) => {
      const categoryUuid = params.data?.uuid ?? ''

      if (categoryUuid.startsWith('temp-')) {
        return (
          <div style={{ paddingTop: 16, paddingLeft: 98, textAlign: 'center' }}>
            {t('invoices:categories.bookingAccounts.saveFirst')}
          </div>
        )
      }

      return (
        <div style={{ paddingTop: 4, paddingLeft: 98 }}>
          <BookingAccountsTable categoryUuid={categoryUuid} />
        </div>
      )
    },
    [t]
  )

  const columnDefs = useMemo<ColDef<InvoiceArticleCategoryRecord>[]>(
    () => [
      {
        lockPosition: 'left',
        filter: false,
        resizable: false,
        sortable: false,
        suppressColumnsToolPanel: true,
        suppressNavigable: true,
        suppressSizeToFit: true,
        maxWidth: 40,
        minWidth: 40,
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        lockPosition: true,
        width: 96,
        resizable: false,
        sortable: false,
        filter: false,
        pinned: 'left',
        headerComponent: ActionHeader,
        headerComponentParams: {
          onAddEmptyRow: handleAddEmptyRow,
          onSaveNewRows: () => {
            const api = gridRef.current?.api
            if (api) handleSaveNewRows(api)
          },
          api: gridRef.current?.api,
        },
        cellRenderer: ({ data, api }: ICellRendererParams<InvoiceArticleCategoryRecord>) => {
          if (data?.uuid.startsWith('temp-')) {
            return (
              <Button
                icon={<Trash size={16} />}
                danger
                onClick={() => {
                  api?.applyTransaction({ remove: [data] })
                }}
              />
            )
          }
          return null
        },
      },
      {
        field: 'name',
        headerName: t('invoices:categories.table.name'),
        filter: 'agTextColumnFilter',
        editable: true,
        suppressPaste: true,
        cellRenderer: ({
          value,
        }: ICellRendererParams<
          InvoiceArticleCategoryRecord,
          InvoiceArticleCategoryRecord['name']
        >) => (
          <Space>
            <Pen size={16} />
            {value ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        ),
      },
    ],
    [handleAddEmptyRow, handleSaveNewRows]
  )

  return (
    <Table<InvoiceArticleCategoryRecord>
      ref={gridRef}
      columnDefs={columnDefs}
      rowData={rowData}
      onCellValueChanged={onCellValueChanged}
      persist={{
        key: TablePersistKey.INVOICE_ARTICLE_CATEGORY,
        storage: localStorage,
      }}
      masterDetail
      detailCellRenderer={BookingAccountDetailRenderer}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
    />
  )
}

const ActionHeader = ({
  onAddEmptyRow,
  onSaveNewRows,
  api,
}: {
  onAddEmptyRow: () => void
  onSaveNewRows: () => void
  api: GridApi<InvoiceArticleCategoryRecord> | undefined
}) => {
  const [hasNewRows, setHasNewRows] = useState(false)

  useEffect(() => {
    const checkForNewRows = () => {
      if (api) {
        const rowData: InvoiceArticleCategoryRecord[] = []
        api.forEachNode((node) => {
          if (node.data) {
            rowData.push(node.data)
          }
        })
        const newRows = rowData.some((row) => row?.uuid?.startsWith('temp-'))
        setHasNewRows(newRows)
      }
    }

    checkForNewRows()
    const intervalId = setInterval(checkForNewRows, 500)

    return () => clearInterval(intervalId)
  }, [api])

  const handleSaveNewRows = useCallback(() => {
    if (hasNewRows) {
      onSaveNewRows()
    }
  }, [hasNewRows, onSaveNewRows])

  return (
    <Space>
      <Button onClick={onAddEmptyRow} icon={<Plus size={16} />} type="primary" />
      <Button
        onClick={handleSaveNewRows}
        icon={<Save size={16} />}
        type="primary"
        disabled={!hasNewRows}
      />
    </Space>
  )
}
