type AnyObject = Record<string, any>

export function omitTypename(obj: AnyObject | undefined): AnyObject | undefined {
  if (obj === undefined) {
    return undefined
  }
  if (Array.isArray(obj)) {
    return obj.map(omitTypename)
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      if (key === '__typename') {
        return acc
      }
      acc[key] = omitTypename(obj[key])
      return acc
    }, {} as AnyObject)
  }
  return obj
}
