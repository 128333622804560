import { Space, Typography } from 'antd'

type PaymentsDetailsLabelProps = {
  title: string
  description: string
}

export const PaymentsDetailsLabel = ({ title, description }: PaymentsDetailsLabelProps) => (
  <Space direction="vertical">
    <Typography.Text strong>{title}</Typography.Text>
    <Typography.Text type="secondary">{description}</Typography.Text>
  </Space>
)
