import { useMutation } from '@apollo/client'
import { Button, Form, Row, Switch, Typography } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'sonner'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import QuillTextarea from '../../../components/Ticket/QuillTextarea'
import { UploadZendeskFile } from '../../../components/Ticket/UploadFile'
import { Marketplace, SendSupportTicketDocument } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { DisputeProductReviewInput } from '../components/form/DisputeProductReviewInput'
import { PersonalNoteInput } from '../components/form/PersonalNoteInput'
import { createZendeskTicketOptions } from '../helpers/Zendesk.helper'
import { TicketCreationCard } from '../styles/TicketCreateViewStyles'
import { TicketCategory, TicketMainCategory } from '../ticket.enum'
import { AttachmentProps } from '../ticket.interface'

type FormInstance = {
  messageInput: string
  confirmation_switch: boolean
  productNumber: string
  marketplace?: Marketplace
  productReviewTitle: string
  nameOfReviewer: string
  reviewDate: string
  reviewLink: string
  report: string
  personalNotes: string
}

export const DisputeProductReviewTicketView = () => {
  const [confirmationSwitchValue, setConfirmationSwitchValue] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const location = useLocation()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()

  const { t, ready, i18n } = useTranslation(['ticket'], {
    keyPrefix: 'amazonSupport.disputeProductReviewTicketView',
  })
  const { t: generalTranslation } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [form] = Form.useForm()

  const [sendSupportTicketRequest, { loading }] = useMutation(SendSupportTicketDocument)

  if (!ready) {
    return <LoadingSpinner />
  }

  const initialValues: FormInstance = {
    confirmation_switch: false,
    messageInput: '',
    productNumber: '',
    productReviewTitle: '',
    nameOfReviewer: '',
    reviewDate: '',
    reviewLink: '',
    report: '',
    personalNotes: '',
  }

  const onFinish = async (values: FormInstance) => {
    const tokens = attachments.map((attachment) => {
      return attachment.token
    })

    const createCommentSection = (messageInput?: string) => {
      if (messageInput) {
        return `
          <div>
            <h3>${t('form.comments.label')}</h3>
            <p>${messageInput}</p>
          </div>
        `
      } else {
        return ''
      }
    }

    const message = `
      <h1>${t('ticketDescriptionTitle')}</h1>
      <p><b>${t('form.productNumberInput.label')}</b></p>
      <p>${values.productNumber}</p>
      <p><b>${t('form.productReviewTitle.label')}</b></p>
      <p>${values.productReviewTitle}</p>
      <p><b>${t('form.nameOfReviewer.label')}</b></p>
      <p>${values.nameOfReviewer}</p>
      <p><b>${t('form.marketplace.label')}</b></p>
      <p>${values.marketplace}</p>
      <p><b>${t('form.reviewDate.label')}</b></p>
      <p>${dayjs(values.reviewDate).locale(i18n.language).format('D. MMMM YYYY')}</p>
      <p><b>${t('form.reviewLink.label')}</b></p>
      <p>${values.reviewLink}</p>
      <p><b>${t('form.report.label')}</b></p>
      <p>${values.report}</p>
      ${createCommentSection(values.messageInput)}
    `

    const options = createZendeskTicketOptions({
      categoryTitle: location.state.categoryTitleTag,
      comment: message,
      language: i18n.language,
      mainCategory: TicketMainCategory.PRODUCT_DISPUTE,
      subject: t('viewTitle'),
      ticketCategory: TicketCategory.PRODUCT_REVIEW_DISPUTE,
      sellerId: selectedCompany?.sellerId,
      personalNotes: values.personalNotes,
      attachments: tokens,
    })

    try {
      if (selectedCompany !== null && selectedCompany.uuid) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })
        toast.success(generalTranslation('notification.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      console.error(error)
      toast.error(generalTranslation('notification.error'), { description: error.message })
    }
  }

  return (
    <>
      <SubHeader withBackButton />
      <ViewContainer centered>
        <TicketCreationCard title={t('ticketDescriptionTitle')}>
          <Typography.Paragraph>{t('ticketDescriptionSubtitle')}</Typography.Paragraph>
          <Form
            name="dispute-product-review-ticket"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            layout="vertical"
          >
            <DisputeProductReviewInput />
            <Form.Item name="messageInput" label={t('form.comments.label')}>
              <QuillTextarea placeholder={t('form.comments.placeholder')} />
            </Form.Item>
            <PersonalNoteInput />
            <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
            <Form.Item
              name="confirmation_switch"
              label={t('confirmationText')}
              valuePropName="checked"
            >
              <Switch onChange={setConfirmationSwitchValue} />
            </Form.Item>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!confirmationSwitchValue}
                loading={loading}
              >
                {t('sendButtonText')}
              </Button>
            </Row>
          </Form>
        </TicketCreationCard>
      </ViewContainer>
    </>
  )
}
