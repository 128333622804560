import { Col, DatePicker, Form, Input, Row, Select } from 'antd'
import { i18n, TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import QuillTextarea from '../../../../components/Ticket/QuillTextarea'
import { Marketplace } from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'

type DisputeProductReviewInputProps = {
  translation?: [TFunction, i18n, boolean] & { t: TFunction; i18n: i18n; ready: boolean }
}

export const DisputeProductReviewInput = ({ translation }: DisputeProductReviewInputProps) => {
  const { t } =
    translation ??
    useTranslation(['ticket'], { keyPrefix: 'amazonSupport.disputeProductReviewTicketView.form' })

  const marketplaceOptions = useMarketplaceOptions()

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          name={'productNumber'}
          label={t('productNumberInput.label')}
          rules={[{ required: true, message: t('productNumberInput.validation.required') }]}
        >
          <Input placeholder={t('productNumberInput.placeholder')} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={'productReviewTitle'}
          label={t('productReviewTitle.label')}
          rules={[{ required: true, message: t('productReviewTitle.validation.required') }]}
        >
          <Input placeholder={t('productReviewTitle.placeholder')} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={'nameOfReviewer'}
          label={t('nameOfReviewer.label')}
          rules={[{ required: true, message: t('nameOfReviewer.validation.required') }]}
        >
          <Input placeholder={t('nameOfReviewer.placeholder')} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={'marketplace'}
          label={t('marketplace.label')}
          rules={[{ required: true, message: t('marketplace.validation.required') }]}
        >
          <Select<Marketplace>
            mode="multiple"
            options={marketplaceOptions.map((marketplace) => ({
              value: marketplace,
              label: `Amazon.${getMarketplaceDomain(marketplace)}`,
            }))}
            placeholder={t('marketplace.placeholder')}
            showSearch
            filterOption={true}
            optionFilterProp="label"
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={'reviewDate'}
          label={t('reviewDate.label')}
          rules={[{ required: true, message: t('reviewDate.validation.required') }]}
        >
          <DatePicker
            format="DD.MM.YYYY"
            placeholder={t('reviewDate.placeholder')}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={'reviewLink'}
          label={t('reviewLink.label')}
          rules={[{ required: true, message: t('reviewLink.validation.required') }]}
        >
          <Input placeholder={t('reviewLink.placeholder')} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={'report'}
          label={t('report.label')}
          rules={[{ required: true, message: t('report.validation.required') }]}
        >
          <QuillTextarea placeholder={t('report.placeholder')} />
        </Form.Item>
      </Col>
    </Row>
  )
}
