import { useTheme } from '@emotion/react'
import { Col, List, Row, Space, Statistic, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { StyledMainCard } from '../../../dashboard/styles'
import { prepareAmazonReturnOverview } from '../helpers/prepareAmazonReturnOverview'
import { AmazonReturnTableRecord } from './AmazonReturnTable'

type AmazonReturnOverviewProps = {
  amazonReturns: AmazonReturnTableRecord[]
}

export const AmazonReturnOverview = ({ amazonReturns }: AmazonReturnOverviewProps) => {
  const { t } = useTranslation(['inventory', 'translation'])
  const theme = useTheme()

  const overview = prepareAmazonReturnOverview(amazonReturns)

  return (
    <StyledMainCard style={{ maxHeight: '320px' }}>
      <Statistic title={t('returns.overview.statistic.title')} value={amazonReturns.length} />
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={12}>
          <List
            header={t('returns.overview.products.list.title')}
            dataSource={overview.topSkus}
            renderItem={(item) => (
              <List.Item style={{ position: 'relative', padding: 0 }}>
                <>
                  <div
                    style={{
                      position: 'absolute',
                      width: `${item.proportion}%`,
                      height: '90%',
                      borderRadius: '0.25rem',
                      backgroundColor: `${theme.colors.primary}20`,
                    }}
                  />
                  <Row justify="space-between" style={{ width: '100%', padding: '0.5rem' }}>
                    <Col>{item.sku}</Col>
                    <Col>
                      <Space>
                        <Typography.Text>{item.count}</Typography.Text>
                        <Typography.Text>({item.percentage})</Typography.Text>
                      </Space>
                    </Col>
                  </Row>
                </>
              </List.Item>
            )}
            locale={{ emptyText: t('shared.noData', { ns: 'translation' }) }}
            split={false}
          />
        </Col>
        <Col xs={24} sm={12}>
          <List
            header={t('returns.overview.reasons.list.title')}
            dataSource={overview.topReasons}
            renderItem={(item) => (
              <List.Item style={{ position: 'relative', padding: 0 }}>
                <>
                  <div
                    style={{
                      position: 'absolute',
                      width: `${item.proportion}%`,
                      height: '90%',
                      borderRadius: '0.25rem',
                      backgroundColor: `${theme.colors.primary}20`,
                    }}
                  />
                  <Row justify="space-between" style={{ width: '100%', padding: '0.5rem' }}>
                    <Col>{t(`returns.reason.${item.reason}`)}</Col>
                    <Col>
                      <Space>
                        <Typography.Text>{item.count}</Typography.Text>
                        <Typography.Text>({item.percentage})</Typography.Text>
                      </Space>
                    </Col>
                  </Row>
                </>
              </List.Item>
            )}
            locale={{ emptyText: t('shared.noData', { ns: 'translation' }) }}
            split={false}
          />
        </Col>
      </Row>
    </StyledMainCard>
  )
}
