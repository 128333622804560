import { Alert, Button, Flex, Typography, Upload, UploadFile } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { UploadChangeParam } from 'antd/es/upload'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import { getFileTypeFromContentType } from '../../../../components/S3/helpers/getFileTypeFromContentType'
import { AvailableInventory } from '../helpers/prepareAvailableInventory'
import { validateAndGetShipmentPlanInventory } from '../helpers/validateAndGetShipmentPlanInventory'
import {
  AmazonInboundShipmentPlanCreateFormInstance,
  SelectedInventory,
} from './AmazonInboundShipmentPlanCreateForm'
import { AmazonInboundShipmentPlanImportErrorsAlert } from './AmazonInboundShipmentPlanImportErrorsAlert'
import { ImportError } from './AmazonInboundShipmentPlanImportModal'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export type AmazonInboundShipmentPlanFileImportRecord = {
  'Merchant SKU': string
  Quantity: number
  'Prep owner'?: string
  'Labeling owner'?: string
  'Expiration date (MM/DD/YYYY)'?: string
  'Manufacturing lot code'?: string
  'Units per box'?: number
  'Number of boxes'?: number
  'Box length (cm)'?: number
  'Box width (cm)'?: number
  'Box height (cm)'?: number
  'Box weight (kg)'?: number
}

type AmazonInboundShipmentPlanFileImportProps = {
  importedInventory: SelectedInventory[]
  setImportedInventory: Dispatch<SetStateAction<SelectedInventory[]>>
  errors: ImportError[]
  setErrors: Dispatch<SetStateAction<ImportError[]>>
}

export const AmazonInboundShipmentPlanFileImport = ({
  importedInventory,
  setImportedInventory,
  errors,
  setErrors,
}: AmazonInboundShipmentPlanFileImportProps) => {
  const { t } = useTranslation('inventory')
  const { parseWorksheet } = useImportWorksheet()
  const form = useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()
  const availableInventory = form.getFieldValue('availableInventory')

  const handleChange = async (info: UploadChangeParam<UploadFile<unknown>>) => {
    setErrors([])
    const { file } = info

    if (!file.originFileObj) {
      return
    }

    try {
      const fileData = await parseWorksheet(file.originFileObj)
      const processedInventory = processFileData(fileData, availableInventory, setErrors, t)
      setImportedInventory(processedInventory)
    } catch (error) {
      console.error('Failed to parse worksheet:', error)
    }
  }

  const downloadShipmentPlanTemplate = async () => {
    try {
      const key = 'shipment-plan/ShipmentPlanTemplate.xlsx'
      const file = await Storage.get(key, {
        bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME_TEMPLATES,
      })
      window.open(file, '_blank')
    } catch (error) {
      console.error('Failed to download shipment plan template:', error)
    }
  }

  return (
    <Flex vertical gap={16}>
      <Typography.Text>{t('amazonInboundShipmentPlan.import.file.description')}</Typography.Text>
      <Upload.Dragger
        accept=".csv,.xlsx,.txt"
        customRequest={customRequest}
        maxCount={1}
        onChange={handleChange}
        showUploadList={false}
      >
        {t('amazonInboundShipmentPlan.import.file.upload')}
      </Upload.Dragger>
      {importedInventory && importedInventory.length > 0 && (
        <Alert
          type="success"
          message={t('amazonInboundShipmentPlan.import.success.alert.message')}
          description={t('amazonInboundShipmentPlan.import.success.alert.description', {
            count: importedInventory.length,
          })}
        />
      )}
      <AmazonInboundShipmentPlanImportErrorsAlert errors={errors} />
      <Flex>
        <Button key="template" variant="link" onClick={downloadShipmentPlanTemplate}>
          {t('amazonInboundShipmentPlan.import.file.downloadTemplate')}
        </Button>
      </Flex>
    </Flex>
  )
}

const useImportWorksheet = () => {
  const parseWorksheet = (file: File): Promise<AmazonInboundShipmentPlanFileImportRecord[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        try {
          const result = event.target?.result

          if (!result) {
            reject(new Error('Failed to read file.'))
            return
          }

          const data = new Uint8Array(result as ArrayBuffer)
          const workbook = XLSX.read(data, { type: 'array' })

          if (workbook.SheetNames.length === 0) {
            reject(new Error('No worksheets found in workbook.'))
            return
          }

          const fileType = getFileTypeFromContentType(file.type)
          const isXlsx = fileType === 'excel' || file.name.toLowerCase().endsWith('.xlsx')
          const sheetIndex =
            isXlsx && workbook.SheetNames.length > 1
              ? workbook.SheetNames.length - 2
              : workbook.SheetNames.length - 1

          const targetSheetName = workbook.SheetNames[sheetIndex]

          if (!targetSheetName) {
            reject(new Error('Target sheet not found.'))
            return
          }

          const targetSheet = workbook.Sheets[targetSheetName]

          if (!targetSheet) {
            reject(new Error('Target worksheet not found.'))
            return
          }

          // Find the row that starts with "Merchant SKU"
          const range = XLSX.utils.decode_range(targetSheet['!ref'] || 'A1')

          let headerRow = -1

          for (let row = range.s.r; row <= range.e.r; row++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: 0 })
            const cell = targetSheet[cellAddress]

            if (cell && cell.v === 'Merchant SKU') {
              headerRow = row
              break
            }
          }

          if (headerRow === -1) {
            reject(new Error('Could not find header row starting with "Merchant SKU"'))
            return
          }

          const worksheetData = XLSX.utils.sheet_to_json(targetSheet, {
            range: headerRow,
          }) as AmazonInboundShipmentPlanFileImportRecord[]

          resolve(worksheetData)
        } catch (error) {
          reject(error)
        }
      }

      reader.readAsArrayBuffer(file)
    })
  }

  return { parseWorksheet }
}

const processFileData = (
  fileData: AmazonInboundShipmentPlanFileImportRecord[] | undefined,
  availableInventory: AvailableInventory[],
  setErrors: Dispatch<SetStateAction<ImportError[]>>,
  t: TFunction<'inventory'>
) => {
  if (!fileData) {
    return []
  }

  const inventoryMap = new Map(availableInventory.map((inventory) => [inventory.sku, inventory]))
  const errors: ImportError[] = []
  const selectedInventory: SelectedInventory[] = []

  fileData.forEach((currentValue, currentIndex) => {
    const trimmedSku = currentValue['Merchant SKU']?.trim()
    const validation = validateAndGetShipmentPlanInventory(
      currentIndex,
      trimmedSku,
      inventoryMap,
      t
    )

    if (validation.error) {
      const existingError = errors.find((e) => e.sku === validation.error.sku)
      if (!existingError) {
        errors.push(validation.error)
      }
      return
    }

    if (!validation.result) {
      return
    }

    const { matchingInventory, smallestPackagingUnit } = validation.result

    selectedInventory.push({
      ...matchingInventory,
      labeledByAmazon: currentValue['Labeling owner']?.toLowerCase() === 'amazon',
      quantityInPackagingUnit: currentValue['Units per box']
        ? +currentValue['Units per box']
        : undefined,
      numberOfPackagingUnits: currentValue['Number of boxes']
        ? +currentValue['Number of boxes']
        : undefined,
      smallestPackagingUnit,
      bestBeforeDate: currentValue['Expiration date (MM/DD/YYYY)']
        ? dayjs(currentValue['Expiration date (MM/DD/YYYY)']).format('YYYY-MM-DD')
        : undefined,
      cartonLength: currentValue['Box length (cm)'] ? +currentValue['Box length (cm)'] : undefined,
      cartonWidth: currentValue['Box width (cm)'] ? +currentValue['Box width (cm)'] : undefined,
      cartonHeight: currentValue['Box height (cm)'] ? +currentValue['Box height (cm)'] : undefined,
      cartonGrossWeight: currentValue['Box weight (kg)']
        ? +currentValue['Box weight (kg)']
        : undefined,
      packagingUnits: matchingInventory.packagingUnits,
      prepInstructions: matchingInventory.prepInstructions,
      recommendedQuantity: matchingInventory.recommendedQuantity,
    })
  })

  setErrors(errors)
  return selectedInventory
}
