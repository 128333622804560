import { Button, Modal } from 'antd'
import { Upload } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTabs } from '../../../../components/Tabs'
import { CompanyType } from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { SelectedInventory } from './AmazonInboundShipmentPlanCreateForm'
import { AmazonInboundShipmentPlanFileImport } from './AmazonInboundShipmentPlanFileImport'
import {
  AmazonInboundShipmentPlanWholesaleOrderImport,
  AmazonInboundShipmentPlanWholesaleOrderImportRecord,
} from './AmazonInboundShipmentPlanWholesaleOrderImport'

type AmazonInboundShipmentPlanImportModalProps = {
  onImport: (data: {
    shipFromWarehouseLocationUuid?: string
    importedInventory: SelectedInventory[]
    wholesaleOrderIdentifier?: string
  }) => void
}

export type ImportError = {
  sku: string
  errors: string[]
}

export const AmazonInboundShipmentPlanImportModal = ({
  onImport,
}: AmazonInboundShipmentPlanImportModalProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const [open, setOpen] = useState(false)
  const [tab, setTab] = useState<'file' | 'wholesale-order'>(
    selectedCompany.type !== CompanyType.WHOLESALER ? 'file' : 'wholesale-order'
  )
  const [wholesaleOrderData, setWholesaleOrderData] = useState<{
    shipFromWarehouseLocationUuid: string
    lineItems: AmazonInboundShipmentPlanWholesaleOrderImportRecord[]
    wholesaleOrderIdentifier: string
  }>()
  const [importedInventory, setImportedInventory] = useState<SelectedInventory[]>([])
  const [errors, setErrors] = useState<ImportError[]>([])

  const { t } = useTranslation('inventory')

  const handleOk = () => {
    if (tab === 'file') {
      onImport({
        importedInventory,
      })
    }

    if (tab === 'wholesale-order' && wholesaleOrderData) {
      onImport({
        shipFromWarehouseLocationUuid: wholesaleOrderData.shipFromWarehouseLocationUuid,
        wholesaleOrderIdentifier: wholesaleOrderData.wholesaleOrderIdentifier,
        importedInventory,
      })
    }

    handleClose()
  }

  const handleTabChange = (activeKey: string) => {
    if (activeKey === 'file') {
      setTab('file')
      setImportedInventory([])
      setErrors([])
    }

    if (activeKey === 'wholesale-order') {
      setTab('wholesale-order')
      setImportedInventory([])
      setErrors([])
    }
  }

  const handleClose = () => {
    setOpen(false)
    setWholesaleOrderData(undefined)
    setImportedInventory([])
    setErrors([])
  }

  return (
    <>
      <Button icon={<Upload size={16} />} onClick={() => setOpen(true)}>
        {t('shared.button.import', { ns: 'translation' })}
      </Button>
      <Modal
        title={t('amazonInboundShipmentPlan.import.title')}
        open={open}
        okText={t('shared.button.import', { ns: 'translation' })}
        okButtonProps={{ disabled: importedInventory.length === 0 }}
        onOk={handleOk}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={handleClose}
        forceRender
        styles={{ body: { paddingTop: '1rem' } }}
      >
        <StyledTabs
          activeKey={tab}
          items={[
            {
              key: 'file',
              label: t('amazonInboundShipmentPlan.import.file.tab'),
              children: (
                <AmazonInboundShipmentPlanFileImport
                  importedInventory={importedInventory}
                  setImportedInventory={setImportedInventory}
                  errors={errors}
                  setErrors={setErrors}
                />
              ),
            },
            {
              key: 'wholesale-order',
              label: t('amazonInboundShipmentPlan.import.wholesale-order.tab'),
              disabled: selectedCompany.type !== CompanyType.WHOLESALER,
              children: (
                <AmazonInboundShipmentPlanWholesaleOrderImport
                  setWholesaleOrderData={setWholesaleOrderData}
                  errors={errors}
                  setErrors={setErrors}
                  importedInventory={importedInventory}
                  setImportedInventory={setImportedInventory}
                />
              ),
            },
          ]}
          onChange={handleTabChange}
          tabBarGutter={0}
        />
      </Modal>
    </>
  )
}
