import { GetAllBusinessReportsQuery } from '../../../../generated/graphql'
import { BusinessReportTableRecord } from '../components/BusinessReportTable/BusinessReportTable'

export function prepareBusinessReportTableData(
  data: GetAllBusinessReportsQuery | undefined
): BusinessReportTableRecord[] {
  if (!data) {
    return []
  }

  return data.businessReports.map((item, index) => ({
    title: item.amazonListing?.title ?? '-',
    rowIndex: index.toString(),
    parentAsin: item.parentAsin,
    childAsin: item.childAsin,
    sku: item.sku,
    sessions: +item.sessions,
    sessionsB2B: item.sessionsB2B ? +item.sessionsB2B : 0,
    browserSessions: +item.browserSessions,
    browserSessionsB2B: item.browserSessionsB2B ? +item.browserSessionsB2B : 0,
    mobileAppSessions: +item.mobileAppSessions,
    mobileAppSessionsB2B: item.mobileAppSessionsB2B ? +item.mobileAppSessionsB2B : 0,
    browserPageViews: +item.browserPageViews,
    browserPageViewsB2B: item.browserPageViewsB2B ? +item.browserPageViewsB2B : 0,
    mobileAppPageViews: +item.mobileAppPageViews,
    mobileAppPageViewsB2B: item.mobileAppPageViewsB2B ? +item.mobileAppPageViewsB2B : 0,
    pageViews: +item.pageViews,
    pageViewsB2B: item.pageViewsB2B ? +item.pageViewsB2B : 0,
    unitsOrdered: +item.unitsOrdered,
    unitsOrderedB2B: item.unitsOrderedB2B ? +item.unitsOrderedB2B : 0,
    totalOrderItems: +item.totalOrderItems,
    totalOrderItemsB2B: item.totalOrderItemsB2B ? +item.totalOrderItemsB2B : 0,
    orderedProductSales: +item.orderedProductSales,
    orderedProductSalesB2B: item.orderedProductSalesB2B ? +item.orderedProductSalesB2B : 0,
    unitSessionPercentage: item.unitSessionPercentage,
    unitSessionPercentageB2B: item.unitSessionPercentageB2B ?? '0%',
    orderPercentage: (
      Math.round(((+item.totalOrderItems / +item.sessions) * 100 + Number.EPSILON) * 100) / 100
    ).toFixed(2),
  }))
}
