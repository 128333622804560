import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

export const NoRowsOverlay = () => {
  const { t } = useTranslation()

  return (
    <Typography.Text type="secondary" style={{ fontSize: '0.875rem' }}>
      {t('shared.noData')}
    </Typography.Text>
  )
}
