import styled from '@emotion/styled'
import { Image } from 'antd'

export const GridImage = styled(Image)`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.base[6]};
  border-radius: 0.25rem;
  object-fit: cover;
  aspect-ratio: 1;
`

export const GridImageFallback = styled.div<{ bordered?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  border: ${({ bordered, theme }) => (bordered ? `1px solid ${theme.colors.base[6]}` : 'none')};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.base[6]};
  aspect-ratio: 1;
`
