import styled from '@emotion/styled'
import { Card } from 'antd'

export const StyledPPCPerformanceCard = styled(Card)`
  margin: 1rem;

  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    text-align: center;
  }
`
