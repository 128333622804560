import dayjs, { Dayjs } from 'dayjs'
import { DatedPerformanceOverview } from '../../../helpers/preparePerformanceOverview'

export function addMissingDays(
  data: DatedPerformanceOverview[],
  startDate: Dayjs,
  endDate: Dayjs
): DatedPerformanceOverview[] {
  const days = endDate.diff(startDate, 'day')
  const allDays = Array.from({ length: days + 1 }, (value, index) => startDate.add(index, 'day'))

  return allDays.map((day) => {
    const existingData = data.find((item) => dayjs(item.date).isSame(day, 'day'))

    if (existingData) {
      return {
        ...existingData,
        date: dayjs(day).format('DD.MM.YY'),
      }
    } else {
      return {
        date: dayjs(day).format('DD.MM.YY'),
        orders: [],
        totalShipped: 0,
        totalPending: 0,
        totalCanceled: 0,
        totalRevenue: 0,
        pendingRevenue: 0,
        performance: {
          products: [],
          marketplaces: [],
        },
      }
    }
  })
}
