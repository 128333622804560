import { ICellRendererParams } from 'ag-grid-community'
import i18n from 'i18next'
import { useMemo } from 'react'
import { BankTransaction } from '../../BankTransactionsView'
import { bankTransactionTableCellRenderers } from '../table/bankTransactionTableCellRenderers'
import { bankTransactionTableFilters } from '../table/bankTransactionTableFilters'
import { bankTransactionTableSorters } from '../table/bankTransactionTableSorters'
import { bankTransactionTableValueGetters } from '../table/bankTransactionTableValueGetters'

export const useBankTransactionTableColumnTypes = () => {
  return useMemo(
    () => ({
      dateColumn: {
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: bankTransactionTableFilters.date,
        },
        comparator: bankTransactionTableSorters.date,
      },
      textColumn: {
        filter: 'agTextColumnFilter',
        comparator: bankTransactionTableSorters.text,
      },
      amountColumn: {
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'right' },
        cellRenderer: (params: ICellRendererParams<BankTransaction>) =>
          bankTransactionTableCellRenderers.amount(params, i18n.language),
        comparator: bankTransactionTableSorters.amount,
        valueGetter: bankTransactionTableValueGetters.amount,
      },
    }),
    []
  )
}
