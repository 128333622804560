import { useTheme } from '@emotion/react'
import {
  GridReadyEvent,
  IAggFuncParams,
  ICellRendererParams,
  SelectionChangedEvent,
  StateUpdatedEvent,
  StatusPanelDef,
  themeQuartz,
  ValueGetterParams,
} from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { Flex, Typography } from 'antd'
import { t } from 'i18next'
import { Cog, Download, RotateCcw } from 'lucide-react'
import { ForwardedRef, forwardRef, RefObject, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router'
import { useBreakpoints } from '../../hooks/useBreakpoints'
import { useForwardedRef } from '../../hooks/useForwardedRef'
import { useAppThemeStore } from '../../stores/useAppThemeStore'
import { TablePersistConfig, useTableStore } from '../../stores/useTableStore'
import { EventButton } from '../EventButton'
import { CustomTooltip } from './CustomTooltip'
import { LoadingOverlay } from './LoadingOverlay'
import { NoRowsOverlay } from './NoRowsOverlay'
import { UniversalSearch } from './UniversalSearch'

type TableProps<T> = Omit<AgGridReactProps<T>, 'ref'> & {
  customHeaderHeight?: number
  customRowHeight?: number
  fullHeight?: boolean
  maxTableRows?: number
  minTableRows?: number
  persist?: TablePersistConfig
  showColumnChooser?: boolean
  showExport?: boolean
  showFilterReset?: boolean
  showRowCount?: boolean
  showUniversalSearch?: boolean
  storeSearchInSearchParams?: boolean
}

const DEFAULT_HEADER_HEIGHT = 48
const DEFAULT_ROW_HEIGHT = 48
const DEFAULT_STATUS_BAR_HEIGHT = 48
const DEFAULT_MAX_TABLE_ROWS = 15
const DEFAULT_MIN_TABLE_ROWS = 5

function LocalizedTable<T>(props: TableProps<T>, forwardedRef: ForwardedRef<AgGridReact<T>>) {
  const {
    fullHeight,
    customHeaderHeight,
    customRowHeight,
    maxTableRows,
    minTableRows,
    persist,
    showColumnChooser,
    showExport,
    showFilterReset,
    showRowCount = true,
    showUniversalSearch,
    storeSearchInSearchParams,
    ...agGridProps
  } = props

  const [localSearchValue, setLocalSearchValue] = useState('')

  const [searchParams, setSearchParams] = useSearchParams()

  const searchValue = storeSearchInSearchParams
    ? (searchParams.get('search') ?? '')
    : localSearchValue

  const tableStore = useTableStore(persist)()

  const initialState: AgGridReactProps<T>['initialState'] = useMemo(() => {
    if (!tableStore.gridState) {
      return
    }

    const {
      columnGroup,
      columnOrder,
      columnPinning,
      columnSizing,
      columnVisibility,
      filter,
      rowGroup,
      rowGroupExpansion,
      sort,
    } = tableStore.gridState

    return {
      columnGroup,
      columnOrder,
      columnPinning,
      columnSizing,
      columnVisibility,
      filter,
      rowGroup,
      rowGroupExpansion,
      sort,
      partialColumnState: true,
    }
  }, [tableStore.gridState])

  const innerRef = useForwardedRef(forwardedRef)
  const breakpoints = useBreakpoints()

  const theme = useTheme()
  const appTheme = useAppThemeStore((state) => state.appTheme)
  const gridTheme = themeQuartz.withParams({
    accentColor: theme.colors.primary,
    backgroundColor: theme.colors.base[3],
    borderColor: theme.colors.base[6],
    borderRadius: 6,
    browserColorScheme: appTheme,
    cellHorizontalPadding: 12,
    chromeBackgroundColor: theme.colors.base[5],
    columnBorder: true,
    dialogBorder: true,
    foregroundColor: theme.colors.text,
    headerHeight: customHeaderHeight ?? DEFAULT_HEADER_HEIGHT,
    menuBackgroundColor: {
      ref: 'chromeBackgroundColor',
    },
    menuBorder: true,
    panelBackgroundColor: {
      ref: 'chromeBackgroundColor',
    },
    rowHeight: customRowHeight ?? DEFAULT_ROW_HEIGHT,
    wrapperBorderRadius: {
      ref: 'borderRadius',
    },
  })

  const autoGroupColumnDef: AgGridReactProps<T>['autoGroupColumnDef'] = useMemo(() => {
    return {
      minWidth: 200,
      pinned: 'left',
      lockPinned: true,
    }
  }, [])

  const cellSelection: AgGridReactProps<T>['cellSelection'] = useMemo(() => {
    return {
      suppressMultiRanges: true,
    }
  }, [])

  const defaultColDef: AgGridReactProps<T>['defaultColDef'] = useMemo(
    () => ({
      cellClassRules: {
        'ag-cell-muted': (params) => {
          return params.value === null || params.value === undefined || params.value === ''
        },
      },
      cellStyle: (params) => {
        if (params.node.rowPinned === 'bottom') {
          return {
            color: theme.colors.primary,
          }
        }
      },
      cellRenderer: (params: ICellRendererParams<T>) => {
        if (params.value === null || params.value === undefined || params.value === '') {
          if (params.node.group || params.node.rowPinned === 'bottom') {
            return null
          }

          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{params.value}</Typography.Text>
      },
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (!nodeA && !nodeB) {
          return 0
        }

        if (nodeA.isSelected() && !nodeB.isSelected()) {
          return isDescending ? 1 : -1
        }

        if (!nodeA.isSelected() && nodeB.isSelected()) {
          return isDescending ? -1 : 1
        }

        if (valueA === null || valueA === undefined) {
          return isDescending ? -1 : 1
        }

        if (valueB === null || valueB === undefined) {
          return isDescending ? 1 : -1
        }

        return valueA < valueB ? -1 : 1
      },
      contextMenuItems: props.getContextMenuItems ? undefined : [],
      headerTooltip: 'X',
      filter: 'agSetColumnFilter',
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 1,
        ...props.defaultColDef?.filterParams,
      },
      minWidth: 100,
      suppressHeaderMenuButton: true,
      suppressKeyboardEvent: (params) => {
        // Using backspace should not start editing.
        return params.event.key === 'Backspace'
      },
      tooltipComponent: CustomTooltip,
      ...props.defaultColDef,
    }),
    [props.defaultColDef]
  )

  const shouldShowRowCount = useMemo(() => {
    if (!props.rowData?.length) {
      return false
    }

    return showRowCount
  }, [props.rowData, showRowCount])

  const statusRowCountBar = useMemo<{
    statusPanels: StatusPanelDef[]
  }>(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
      ],
    }
  }, [])

  const tableHeight = useMemo(() => {
    const headerRowHeight = customHeaderHeight ?? DEFAULT_HEADER_HEIGHT
    const bodyRowHeight = customRowHeight ?? DEFAULT_ROW_HEIGHT
    const hasStatusBar = shouldShowRowCount || props.statusBar
    const statusBarHeight = hasStatusBar ? DEFAULT_STATUS_BAR_HEIGHT : 0
    const rowLength = props.rowData?.length ?? 0
    const hasExtraBottomRow = !!props.pinnedBottomRowData || !!props.grandTotalRow
    const borderHeight = 3
    const clampedRowCount = Math.min(
      Math.max(rowLength, minTableRows ?? DEFAULT_MIN_TABLE_ROWS),
      maxTableRows ?? DEFAULT_MAX_TABLE_ROWS
    )
    const totalVisibleRowCount = hasExtraBottomRow ? clampedRowCount + 1 : clampedRowCount

    return headerRowHeight + totalVisibleRowCount * bodyRowHeight + statusBarHeight + borderHeight
  }, [customHeaderHeight, customRowHeight, props.rowData, props.statusBar])

  const handleGridReady = useCallback(
    (event: GridReadyEvent<T>) => {
      if (!tableStore.gridState) {
        event.api.sizeColumnsToFit()
      }

      event.api.setGridOption('quickFilterText', searchValue)
    },
    [searchValue]
  )

  const handleStateUpdated = useCallback((event: StateUpdatedEvent<T>) => {
    tableStore.setGridState(event.state)
  }, [])

  const handleStateReset = useCallback((ref: RefObject<AgGridReact<T>>) => {
    ref.current?.api?.resetColumnState()
    ref.current?.api?.sizeColumnsToFit()
    ref.current?.api?.setFilterModel(null)

    if (props.showUniversalSearch) {
      ref.current?.api.setGridOption('quickFilterText', '')
      if (props.storeSearchInSearchParams) {
        setSearchParams((previousSearchParams) => {
          const newSearchParams = new URLSearchParams(previousSearchParams)
          newSearchParams.delete('search')
          return newSearchParams
        })
      } else {
        setLocalSearchValue('')
      }
    }
  }, [])

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent<T>) => {
      event.api.refreshClientSideRowModel('aggregate')

      if (props.onSelectionChanged) {
        props.onSelectionChanged(event)
      }
    },
    [props.onSelectionChanged]
  )

  // Pass the correct props to the UniversalSearch component based on the storeSearchInSearchParams flag.
  const searchValueProps = useMemo(() => {
    return storeSearchInSearchParams
      ? { setSearchValue: setSearchParams, storeSearchInSearchParams: true as const }
      : { setSearchValue: setLocalSearchValue, storeSearchInSearchParams: false as const }
  }, [storeSearchInSearchParams])

  const aggFuncs = useMemo(
    () => ({
      sumSelected: (params: IAggFuncParams) => {
        const selectedNodes = params.api.getSelectedNodes()
        if (selectedNodes.length === 0) return null

        return selectedNodes.reduce((sum, node) => {
          const colDef = params.column.getColDef()
          let value

          if (typeof colDef.valueGetter === 'function') {
            value = colDef.valueGetter(node as unknown as ValueGetterParams)
          } else {
            value = node.data ? node.data[params.column.getColId()] : null
          }

          return value != null && !isNaN(value) ? sum + Number(value) : sum
        }, 0)
      },
    }),
    []
  )

  return (
    <Flex vertical gap={8} style={{ height: '100%' }}>
      <Flex gap={8} justify="end">
        {showUniversalSearch && (
          <UniversalSearch gridRef={innerRef} searchValue={searchValue} {...searchValueProps} />
        )}
        {showFilterReset && (
          <EventButton
            event="Reseted grid state"
            title={t('shared.button.reset', { ns: 'translation' })}
            icon={<RotateCcw size={16} />}
            onClick={() => handleStateReset(innerRef)}
            style={{ flexShrink: 0 }}
          >
            {breakpoints.md && t('shared.button.reset', { ns: 'translation' })}
          </EventButton>
        )}
        {showExport && (
          <EventButton
            event="Exported grid data"
            title={t('shared.button.export', { ns: 'translation' })}
            icon={<Download size={16} />}
            onClick={() => innerRef.current?.api.exportDataAsExcel()}
            style={{ flexShrink: 0 }}
          >
            {breakpoints.md && t('shared.button.export', { ns: 'translation' })}
          </EventButton>
        )}
        {showColumnChooser && (
          <EventButton
            event="Opened grid configuration"
            title={t('shared.button.configure', { ns: 'translation' })}
            icon={<Cog size={16} />}
            onClick={() => innerRef.current?.api.showColumnChooser()}
            style={{ flexShrink: 0 }}
          >
            {breakpoints.md && t('shared.button.configure', { ns: 'translation' })}
          </EventButton>
        )}
      </Flex>
      <div
        style={{
          height: fullHeight ? '100%' : tableHeight,
          minHeight: tableHeight,
        }}
      >
        <AgGridReact<T>
          {...agGridProps}
          autoGroupColumnDef={autoGroupColumnDef}
          cellSelection={cellSelection}
          defaultColDef={defaultColDef}
          groupDisplayType="multipleColumns"
          headerHeight={customHeaderHeight ?? DEFAULT_HEADER_HEIGHT}
          includeHiddenColumnsInQuickFilter
          initialState={initialState}
          loadingOverlayComponent={LoadingOverlay}
          maintainColumnOrder
          noRowsOverlayComponent={NoRowsOverlay}
          onGridReady={handleGridReady}
          onStateUpdated={handleStateUpdated}
          onSelectionChanged={handleSelectionChanged}
          ref={innerRef}
          rowBuffer={20}
          rowHeight={customRowHeight ?? DEFAULT_ROW_HEIGHT}
          statusBar={shouldShowRowCount ? statusRowCountBar : props.statusBar}
          suppressAggFuncInHeader
          suppressDragLeaveHidesColumns={
            props.showColumnChooser || props.showFilterReset
              ? props.suppressDragLeaveHidesColumns
              : true
          }
          suppressMultiSort
          theme={gridTheme}
          tooltipMouseTrack
          tooltipShowDelay={500}
          aggFuncs={aggFuncs}
        />
      </div>
    </Flex>
  )
}

export const Table = forwardRef(LocalizedTable) as <T>(
  props: TableProps<T> & { ref?: ForwardedRef<AgGridReact<T>> }
) => ReturnType<typeof LocalizedTable>
