import { useQuery } from '@apollo/client'
import { Col, DatePicker, Row, Select, Space, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { ViewContainer } from '../../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import { PPCPerformanceCard } from '../../../../components/PPC/layout/PPCPerformanceCard/PPCPerformanceCard'
import SponsoredProductsKeywordsTable from '../../../../components/PPC/sponsoredProducts/keyword/SponsoredProductsKeywordsTable/SponsoredProductsKeywordsTable'
import CreateSponsoredProductsKeywordsModal from '../../../../components/PPC/sponsoredProducts/keyword/create/CreateSponsoredProductsKeywordsModal/CreateSponsoredProductsKeywordsModal'
import SponsoredProductsProductAdsTable from '../../../../components/PPC/sponsoredProducts/productAds/SponsoredProductsProductAdsTable/SponsoredProductsProductAdsTable'
import CreateSponsoredProductsProductAdsModal from '../../../../components/PPC/sponsoredProducts/productAds/create/CreateSponsoredProductsProductAdsModal/CreateSponsoredProductsProductAdsModal'
import SponsoredProductsTargetsTable from '../../../../components/PPC/sponsoredProducts/target/SponsoredProductsTargetsTable/SponsoredProductsTargetsTable'
import CreateSponsoredProductsManualTargetsModal from '../../../../components/PPC/sponsoredProducts/target/create/CreateSponsoredProductsManualTargetsModal/CreateSponsoredProductsManualTargetsModal'
import {
  AmazonPpcSponsoredProductsProductAd,
  AmazonPpcState,
  GetSponsoredProductsAdGroupWithAllContentDocument,
  GetSponsoredProductsAdGroupWithAllContentQuery,
  Marketplace,
  SponsoredProductsCampaignTargetingType,
  SponsoredProductsKeywordLiveData,
  SponsoredProductsTargetLiveData,
  UpdateSponsoredProductsProductAdResponse,
} from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useDateRangePresets } from '../../../../hooks/useDateRangePresets'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import {
  getColorFromState,
  getCombinedKeywordLiveDataAndReports,
  getCombinedSponsoredProductsProductAdLiveDataAndReports,
  getCombinedTargetLiveDataAndReports,
} from '../../helpers'
import { AggregatedKeyword, AggregatedProductAd, AggregatedTarget } from '../../helpers/interfaces'
import ppcStateStore from '../../ppcStateStore'

const { RangePicker } = DatePicker

enum AdGroupSubviewType {
  PRODUCTS = 'Products',
  KEYWORDS = 'Keywords',
  TARGETS = 'Targets',
}

export type SponsoredProductsKeywordLiveDataQuery =
  GetSponsoredProductsAdGroupWithAllContentQuery['sponsoredProductsKeywordLiveData']
export type SponsoredProductsKeywordReportsQuery =
  GetSponsoredProductsAdGroupWithAllContentQuery['sponsoredProductsKeywordReports']
export type SponsoredProductsTargetLiveDataQuery =
  GetSponsoredProductsAdGroupWithAllContentQuery['sponsoredProductsTargetLiveData']
export type SponsoredProductsTargetReportsQuery =
  GetSponsoredProductsAdGroupWithAllContentQuery['sponsoredProductsTargetReports']
export type SponsoredProductsProductAdLiveDataQuery =
  GetSponsoredProductsAdGroupWithAllContentQuery['sponsoredProductsProductAdLiveData']
export type SponsoredProductsProductAdReportsQuery =
  GetSponsoredProductsAdGroupWithAllContentQuery['sponsoredProductsProductAdReports']

export const PPCSponsoredProductsAdGroupView = () => {
  const [selectedSubview, setSelectedSubview] = useState<AdGroupSubviewType>(
    AdGroupSubviewType.PRODUCTS
  )
  const [keywords, setKeywords] = useState<AggregatedKeyword[]>([])
  const [targets, setTargets] = useState<AggregatedTarget[]>([])
  const [productAds, setProductAds] = useState<AggregatedProductAd[]>([])

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { setMarketplace, ppcStartDate, setPpcStartDate, ppcEndDate, setPpcEndDate } =
    ppcStateStore()

  const { t } = useTranslation()

  const navigate = useNavigate()
  const params = useParams<{ marketplace: string; campaignid: string; adgroupid: string }>()
  const marketplace = params.marketplace?.toUpperCase() as Marketplace
  const campaignId = params.campaignid!
  const adGroupId = params.adgroupid!

  const marketplaceOptions = useMarketplaceOptions()
  const presets = useDateRangePresets()

  const { loading, error, data } = useQuery<GetSponsoredProductsAdGroupWithAllContentQuery>(
    GetSponsoredProductsAdGroupWithAllContentDocument,
    {
      skip: !selectedCompany.uuid,
      variables: {
        companyUuid: selectedCompany.uuid,
        marketplace,
        campaignId,
        adGroupId,
        startDate: ppcStartDate,
        endDate: ppcEndDate,
      },
    }
  )

  useEffect(() => {
    if (keywords.length) {
      setSelectedSubview(AdGroupSubviewType.KEYWORDS)
    } else if (targets.length) {
      setSelectedSubview(AdGroupSubviewType.TARGETS)
    }
  }, [keywords, targets])

  useEffect(() => {
    const keywordLiveData = data?.sponsoredProductsKeywordLiveData || []
    const keywordReports = data?.sponsoredProductsKeywordReports || []
    const aggregatedKeywords: AggregatedKeyword[] = getCombinedKeywordLiveDataAndReports(
      keywordLiveData,
      keywordReports
    )
    setKeywords(aggregatedKeywords)
  }, [data?.sponsoredProductsKeywordLiveData, data?.sponsoredProductsKeywordReports])

  useEffect(() => {
    const targetLiveData = data?.sponsoredProductsTargetLiveData || []
    const targetReports = data?.sponsoredProductsTargetReports || []
    const aggregatedTargets: AggregatedTarget[] = getCombinedTargetLiveDataAndReports(
      targetLiveData,
      targetReports
    )
    setTargets(aggregatedTargets)
  }, [data?.sponsoredProductsTargetLiveData, data?.sponsoredProductsTargetReports])

  useEffect(() => {
    const productAdsLiveData = data?.sponsoredProductsProductAdLiveData || []
    const productAdsReports = data?.sponsoredProductsProductAdReports || []
    const productAds: AggregatedProductAd[] =
      getCombinedSponsoredProductsProductAdLiveDataAndReports(productAdsLiveData, productAdsReports)
    setProductAds(productAds)
  }, [data?.sponsoredProductsProductAdLiveData, data?.sponsoredProductsProductAdReports])

  const onKeywordsAdded = (newKeywords: SponsoredProductsKeywordLiveData[]) => {
    const aggregatedKeywords = getCombinedKeywordLiveDataAndReports(newKeywords, [])
    setKeywords([...keywords, ...aggregatedKeywords])
  }

  const updateKeywordList = (updatedKeyword: any) => {
    keywords.forEach((keyword) => {
      if (keyword.keywordId === updatedKeyword.keywordId) {
        Object.keys(updatedKeyword).forEach((key) => {
          if (key !== 'keywordId') {
            ;(keyword as any)[key] = (updatedKeyword as any)[key]
          }
        })
      }
    })

    setKeywords([...keywords])
  }

  const onTargetsAdded = (newTargets: SponsoredProductsTargetLiveData[]) => {
    const aggregatedTargets = getCombinedTargetLiveDataAndReports(newTargets, [])
    setTargets([...targets, ...aggregatedTargets])
  }

  const updateTargetList = (updatedTarget: any) => {
    targets.forEach((target) => {
      if (target.targetId === updatedTarget.targetId) {
        Object.keys(updatedTarget).forEach((key) => {
          if (key !== 'targetId') {
            ;(target as any)[key] = updatedTarget[key]
          }
        })
      }
    })

    setTargets([...targets])
  }

  const onProductAdsAdded = (newProductAds: AmazonPpcSponsoredProductsProductAd[]) => {
    const aggregatedProductAds: AggregatedProductAd[] =
      getCombinedSponsoredProductsProductAdLiveDataAndReports(newProductAds, [])
    setProductAds([...productAds, ...aggregatedProductAds])
  }

  const updateProductAdsList = (updatedProductAd: UpdateSponsoredProductsProductAdResponse) => {
    productAds.forEach((productAd: AggregatedProductAd) => {
      if (productAd.adId === updatedProductAd.adId) {
        productAd.state = updatedProductAd.state
      }
    })

    setProductAds([...productAds])
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (
    error ||
    !data ||
    !data.sponsoredProductsKeywordLiveData ||
    !data.sponsoredProductsKeywordReports ||
    !data.sponsoredProductsTargetLiveData ||
    !data.sponsoredProductsTargetReports ||
    !data.sponsoredProductsProductAdLiveData ||
    !data.sponsoredProductsProductAdReports ||
    !data.sponsoredProductsAdGroupLiveData ||
    !data.sponsoredProductsCampaignLiveData
  ) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  const campaignTargetingType = data.sponsoredProductsCampaignLiveData[0]?.targetingType
  const adGroup = data.sponsoredProductsAdGroupLiveData[0]

  // We don't know the campaign's targeting subtype (targets or keywords) so we infer it if possible
  const adGroupSubviewOptions = Object.values(AdGroupSubviewType).filter((value) => {
    if (SponsoredProductsCampaignTargetingType.MANUAL && keywords?.length > 0) {
      return value !== AdGroupSubviewType.TARGETS
    } else if (SponsoredProductsCampaignTargetingType.MANUAL && targets?.length > 0) {
      return value !== AdGroupSubviewType.KEYWORDS
    } else {
      return true
    }
  })

  const handleMarketplaceChange = (value: Marketplace) => {
    navigate(`/amazon/ppc/${value.toLowerCase()}`)
    setMarketplace(value.toLowerCase())
  }

  const handleSelectedViewChanged = (value: AdGroupSubviewType) => {
    setSelectedSubview(value)
  }

  const rightContent = (
    <Space>
      <Select<Marketplace>
        value={marketplace}
        onChange={handleMarketplaceChange}
        popupMatchSelectWidth={false}
        placement="bottomRight"
        options={marketplaceOptions.map((marketplace) => ({
          value: marketplace,
          label: `Amazon.${getMarketplaceDomain(marketplace as unknown as Marketplace)}`,
        }))}
      />
      <RangePicker
        value={[dayjs(ppcStartDate), dayjs(ppcEndDate)]}
        onCalendarChange={(values) => {
          if (values[0]) {
            setPpcStartDate(values[0].format('YYYY-MM-DD'))
          }

          if (values[1]) {
            setPpcEndDate(values[1].format('YYYY-MM-DD'))
          }
        }}
        allowClear={false}
        disabled={loading}
        disabledDate={(date) =>
          date.isAfter(dayjs()) || date.isBefore(dayjs().subtract(6, 'month'))
        }
        format="DD.MM.YY"
        placement="bottomRight"
        presets={presets}
      />
    </Space>
  )

  return (
    <>
      <SubHeader withBackButton rightContent={rightContent} />
      <ViewContainer>
        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <Typography.Text strong ellipsis>
              Ad Group: {adGroup?.name ?? '?'}
            </Typography.Text>
          </Col>
          <Col>
            <Space>
              <Tag color={adGroup?.state ? getColorFromState(adGroup?.state) : undefined}>
                {adGroup?.state}
              </Tag>
              <Tag color="blue">Sponsored Products</Tag>
            </Space>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <Select
              value={selectedSubview}
              onChange={handleSelectedViewChanged}
              popupMatchSelectWidth={false}
              options={adGroupSubviewOptions.map((option) => ({
                value: option,
                label: option,
              }))}
            />
          </Col>
          <Col>
            {selectedSubview === AdGroupSubviewType.KEYWORDS &&
            campaignTargetingType === SponsoredProductsCampaignTargetingType.MANUAL ? (
              <CreateSponsoredProductsKeywordsModal
                campaignId={campaignId}
                adGroupId={adGroupId}
                disabled={adGroup?.state === AmazonPpcState.ARCHIVED}
                onKeywordsAdded={onKeywordsAdded}
              />
            ) : null}
            {selectedSubview === AdGroupSubviewType.TARGETS &&
            campaignTargetingType === SponsoredProductsCampaignTargetingType.MANUAL ? (
              <CreateSponsoredProductsManualTargetsModal
                campaignId={campaignId}
                adGroupId={adGroupId}
                productAdSkus={productAds.map((productAd) => productAd.sku)}
                disabled={adGroup?.state === AmazonPpcState.ARCHIVED}
                onTargetsAdded={onTargetsAdded}
              />
            ) : null}
            {selectedSubview === AdGroupSubviewType.PRODUCTS ? (
              <CreateSponsoredProductsProductAdsModal
                campaignId={campaignId}
                adGroupId={adGroupId}
                disabled={adGroup?.state === AmazonPpcState.ARCHIVED}
                onProductAdsAdded={onProductAdsAdded}
              />
            ) : null}
          </Col>
        </Row>
        {selectedSubview === AdGroupSubviewType.KEYWORDS ? (
          <SponsoredProductsKeywordsTable
            keywords={keywords}
            updateKeywordList={updateKeywordList}
          />
        ) : null}
        {selectedSubview === AdGroupSubviewType.TARGETS ? (
          <SponsoredProductsTargetsTable targets={targets} updateTargetList={updateTargetList} />
        ) : null}
        {selectedSubview === AdGroupSubviewType.PRODUCTS ? (
          <SponsoredProductsProductAdsTable
            productAds={productAds}
            onProductAdUpdated={updateProductAdsList}
          />
        ) : null}
      </ViewContainer>
      <PPCPerformanceCard
        data={
          selectedSubview === AdGroupSubviewType.KEYWORDS
            ? keywords
            : selectedSubview === AdGroupSubviewType.TARGETS
              ? targets
              : selectedSubview === AdGroupSubviewType.PRODUCTS
                ? productAds
                : []
        }
      />
    </>
  )
}
