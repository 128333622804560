import { useEffect, useState } from 'react'

export function useBreakpoints() {
  const [breakpoints, setBreakpoints] = useState(getBreakpoints(window.innerWidth))

  useEffect(() => {
    const handleResize = () => {
      setBreakpoints(getBreakpoints(window.innerWidth))
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return breakpoints
}

function getBreakpoints(width: number) {
  return {
    xs: width < 576,
    sm: width >= 576,
    md: width >= 768,
    lg: width >= 992,
    xl: width >= 1200,
    xxl: width >= 1600,
  }
}
