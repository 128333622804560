import { GetWarehousesQuery } from '../../../../../generated/graphql'

export type AllowedWarehouseLocation = NonNullable<
  GetWarehousesQuery['company']['allowedWarehouseLocations']
>[number]

export type CompanyWithWarehouses = {
  sellerId: string
  name: string
  warehouses: {
    uuid: string
    name: string
    location: {
      uuid: string
      name: string
      addressLine1: string
      city: string
      postalCode: string
      countryCode: string
    }
  }[]
}

export const prepareCompanyWarehouses = (
  allowedWarehouseLocations: AllowedWarehouseLocation[]
): CompanyWithWarehouses[] => {
  const transformWarehouseData = (location: AllowedWarehouseLocation) => ({
    name: location.warehouseServiceProvider.name,
    uuid: location.warehouseServiceProvider.uuid,
    location: {
      uuid: location.uuid,
      name: location.name,
      addressLine1: location.addressLine1,
      postalCode: location.postalCode,
      city: location.city,
      countryCode: location.countryCode,
    },
  })

  return allowedWarehouseLocations.reduce<CompanyWithWarehouses[]>((accumulator, currentValue) => {
    const { company } = currentValue.warehouseServiceProvider

    const warehouseData = transformWarehouseData(currentValue)

    const index = accumulator.findIndex((c) => c.sellerId === company.sellerId)

    if (index !== -1) {
      accumulator[index]?.warehouses.push(warehouseData)
    } else {
      accumulator.push({
        sellerId: company.sellerId,
        name: company.name,
        warehouses: [warehouseData],
      })
    }

    return accumulator
  }, [])
}
