import { ApolloQueryResult, useMutation } from '@apollo/client'
import { Button, Form, Input, Modal, Select, Upload, UploadFile, UploadProps } from 'antd'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { UploadIcon } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  CreateProductDocumentDocument,
  GetProductQuery,
  Marketplace,
  SendSupportTicketDocument,
} from '../../../generated/graphql'
import { useCountryOptions } from '../../../hooks/useCountryOptions'
import { useMarketplaceOptions } from '../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { createZendeskTicketOptions } from '../../ticket/helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../../ticket/ticket.enum'

const productDocumentTypes = [
  'PREMARKET_NOTIFICATION',
  'REACH_COMPLIANCE',
  'SUPPLEMENT_LABEL',
  'OTHER',
  'COMPLIANCE',
] as const

export type ProductDocumentType = (typeof productDocumentTypes)[number]

type ProductDocumentFormInstance = {
  file: UploadFile
  filename: string
  type: ProductDocumentType
  country?: Marketplace
}

type ProductDocumentUploadModalProps = {
  uuid: string
  productName: string
  refetch: () => Promise<ApolloQueryResult<GetProductQuery>>
}

export const ProductDocumentUploadModal = ({
  uuid,
  productName,
  refetch,
}: ProductDocumentUploadModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const { t, i18n } = useTranslation(['inventory'])
  const [form] = Form.useForm<ProductDocumentFormInstance>()

  const marketplaceOptions = useMarketplaceOptions()
  const countryOptions = useCountryOptions()

  const filteredCountryOptions = countryOptions.filter(
    (country) =>
      marketplaceOptions.includes(country.code) ||
      (country.code === 'GB' && marketplaceOptions.includes('UK'))
  )

  const [createProductDocument] = useMutation(CreateProductDocumentDocument)
  const [sendSupportTicketRequest] = useMutation(SendSupportTicketDocument)

  const handleFileChange: UploadProps['onChange'] = (info) => {
    const file = info.file.originFileObj
    const filename = file?.name.replace(/\.[^/.]+$/, '') ?? ''
    form.setFieldsValue({ filename })
  }

  const handleSubmit = async (values: ProductDocumentFormInstance) => {
    setSubmitting(true)

    try {
      const extension = values.file.name.split('.').pop()
      const unixTimestamp = dayjs().unix()
      const key = `${uuid}/${unixTimestamp}_${values.filename}.${extension}`

      const response = await Storage.put(key, values.file.originFileObj, {
        bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME_PRODUCT_DOCUMENTS,
        contentType: values.file.type,
        metadata: {
          type: values.type,
          ...(values.country && { marketplace: values.country }),
        },
      })

      await createProductDocument({
        variables: {
          productUuid: uuid,
          input: {
            key: response.key,
            type: values.type,
            country: values.country,
          },
        },
      })

      await refetch()

      const options = createZendeskTicketOptions({
        categoryTitle: TicketMainCategory.LISTINGS,
        comment: getMessage(uuid, productName, response.key, values.country),
        language: i18n.language,
        mainCategory: TicketMainCategory.LISTINGS,
        subject: t('product.details.documents.upload.success'),
        ticketCategory: TicketCategory.LISTINGS_PRODUCT_INFO_EDIT,
        sellerId: selectedCompany?.sellerId,
      })

      await sendSupportTicketRequest({
        variables: {
          companyUuid: selectedCompany?.uuid || '',
          input: options,
          isAdmin: true,
        },
      })

      toast.success(t('product.details.documents.upload.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('product.details.documents.upload.error'))
    } finally {
      setOpen(false)
      setSubmitting(false)
      form.resetFields()
    }
  }

  return (
    <>
      <Button icon={<UploadIcon size={16} />} onClick={() => setOpen(true)}>
        {t('product.details.documents.upload.button')}
      </Button>
      <Modal
        title={t('product.details.documents.upload.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Form<ProductDocumentFormInstance>
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            name="file"
            label={t('product.details.documents.upload.file')}
            rules={[{ required: true }]}
            getValueFromEvent={(event) => event.file}
            valuePropName="file"
          >
            <Upload
              accept=".pdf, .xlsx, .xls, .jpg, .jpeg, .png, .webp"
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  if (onSuccess) {
                    onSuccess('ok')
                  }
                }, 0)
              }}
              maxCount={1}
              onChange={handleFileChange}
              showUploadList={false}
            >
              <Button type="dashed" icon={<UploadIcon size={16} />}>
                {t('product.details.documents.upload.button')}
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="filename"
            label={t('product.details.documents.upload.filename')}
            rules={[{ required: true, type: 'string', whitespace: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label={t('product.details.documents.upload.type')}
            rules={[{ required: true, type: 'string' }]}
          >
            <Select<ProductDocumentType>
              options={productDocumentTypes.map((type) => ({
                value: type,
                label: getProductDocumentTypeLabel(type, t),
              }))}
              placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
            />
          </Form.Item>
          <Form.Item
            name="country"
            label={t('product.details.documents.upload.country')}
            rules={[{ type: 'enum', enum: marketplaceOptions }]}
          >
            <Select<Marketplace>
              options={filteredCountryOptions.map((country) => ({
                value: country.code === 'GB' ? Marketplace.UK : country.code,
                label: country.name,
              }))}
              allowClear
              placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export function getProductDocumentTypeLabel(type: ProductDocumentType, t: TFunction<'inventory'>) {
  switch (type) {
    case 'PREMARKET_NOTIFICATION':
      return t('product.details.documents.type.premarketNotification')
    case 'REACH_COMPLIANCE':
      return t('product.details.documents.type.reachCompliance')
    case 'SUPPLEMENT_LABEL':
      return t('product.details.documents.type.supplementLabel')
    case 'OTHER':
      return t('product.details.documents.type.other')
    case 'COMPLIANCE':
      return t('product.details.documents.type.compliance')
  }
}

const getMessage = (
  productUuid: string,
  productName: string,
  filename: string,
  country: Marketplace | undefined
) => {
  return `
  <h1>New Product Document Uploaded</h1>
  <p></p>
  <p><b>Product name:</b> ${productName}</p>
  <p><b>File name:</b> ${filename.split('_').slice(1).join('_')}</p>
  <p><b>Marketplace:</b> ${country ? country : 'Failed to determine country'}</p>
  <h3><a href="https://galaxy.spacegoats.io/products/${productUuid}">Open product details page in Galaxy</a></h3>
`
}
