import dayjs from 'dayjs'

export const bankTransactionTableSorters = {
  amount: (number1: any, number2: any) => {
    if (number1 === null && number2 === null) {
      return 0
    }
    if (isNaN(number1)) {
      return -1
    }
    if (isNaN(number2)) {
      return 1
    }
    if (number1 === null) {
      return -1
    }
    if (number2 === null) {
      return 1
    }
    return number1 - number2
  },
  date: (valueA: string, valueB: string) => {
    if (!valueA && !valueB) {
      return 0
    } else if (!valueA) {
      return -1
    } else if (!valueB) {
      return 1
    }

    const valueAFormatted = dayjs(valueA, 'DD.MM.YYYY')
    const valueBFormatted = dayjs(valueB, 'DD.MM.YYYY')

    if (valueAFormatted < valueBFormatted) {
      return -1
    } else if (valueAFormatted > valueBFormatted) {
      return 1
    }
    return 0
  },
  text: (valueA: string, valueB: string) => {
    const valueALowerCase = valueA.toLowerCase()
    const valueBLowerCase = valueB.toLowerCase()
    if (valueALowerCase == valueBLowerCase) return 0
    return valueALowerCase > valueBLowerCase ? 1 : -1
  },
}
