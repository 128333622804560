import { useMutation } from '@apollo/client'
import { Button, TourProps, Upload, UploadProps } from 'antd'
import { Storage } from 'aws-amplify'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router'
import { toast } from 'sonner'
import {
  CheckOnboardingStepDocument,
  CreateLetterOfAuthorizationDocument,
  GetCompanyBrandsDocument,
  GetCompanyDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { useOnboardingStore } from '../../../../stores/useOnboardingStore'

type LetterOfAuthorizationUploadButtonProps = {
  brand: string
  authorizedCompany: {
    sellerId: string
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export const LetterOfAuthorizationUploadButton = ({
  brand,
  authorizedCompany,
}: LetterOfAuthorizationUploadButtonProps) => {
  const [loading, setLoading] = useState(false)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const setTourSteps = useOnboardingStore((state) => state.setTourSteps)
  const setTourOpen = useOnboardingStore((state) => state.setTourOpen)
  const loaRef = useRef(null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { t } = useTranslation(['translation', 'company'])

  const [createLetterOfAuthorization] = useMutation(CreateLetterOfAuthorizationDocument)
  const [checkOnboardingStep] = useMutation(CheckOnboardingStepDocument)

  const handleChange: UploadProps['onChange'] = async (info) => {
    try {
      setLoading(true)
      setTourOpen(false)
      if (info.file.status === 'error') {
        throw new Error()
      }

      if (info.file.status === 'done') {
        const key = `${brand}/letter-of-authorizations/LOA_${brand}_${authorizedCompany.sellerId}.pdf`

        const response = await Storage.put(key, info.file.originFileObj, {
          bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME_BRANDS,
          contentType: info.file.type,
        })

        await createLetterOfAuthorization({
          variables: {
            input: {
              brand,
              authorizedCompanySellerId: authorizedCompany.sellerId,
              key: response.key,
            },
          },
          refetchQueries: [
            {
              query: GetCompanyBrandsDocument,
              variables: {
                companyUuid: selectedCompany.uuid,
              },
            },
          ],
        })

        if (!selectedCompany.completedOnboarding) {
          await checkOnboardingStep({
            variables: {
              input: {
                companyUuid: selectedCompany.uuid,
                eventName: 'add_loa',
              },
            },
            refetchQueries: [
              {
                query: GetCompanyDocument,
                variables: { companyUuid: selectedCompany.uuid },
              },
            ],
          })
          navigate('/settings/warehousing?tour=true')
        }
        toast.success(t('settings.brand.add.success'))
      }
    } catch (error) {
      console.error(error)
      toast.error(t('settings.brand.add.error'))
    } finally {
      setLoading(false)
    }
  }

  const steps: TourProps['steps'] = [
    {
      title: t('company:onboarding.addLoa.title'),
      description: t('company:onboarding.addLoa.description'),
      target: () => loaRef.current,
      nextButtonProps: {
        style: { display: 'none' },
      },
    },
  ]

  useEffect(() => {
    const tour = searchParams.get('tour-loa')
    if (tour === 'true') {
      setTourSteps(steps)
      setTourOpen(true)
    }
  }, [searchParams])

  return (
    <Upload
      accept=".pdf"
      customRequest={customRequest}
      maxCount={1}
      showUploadList={false}
      onChange={handleChange}
    >
      <Button
        disabled={loading}
        loading={loading}
        ref={loaRef}
        onClick={() => setTourOpen(false)}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {t('shared.button.upload')}
      </Button>
    </Upload>
  )
}
