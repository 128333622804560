import { Descriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { CompanyType, GetProductVariantQuery } from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { descriptionsStyle } from '../styles'
import { EditEanField } from './components/EditEanField'
import { ProductVariantAttributesField } from './components/ProductVariantAttributesField'
import { ProductVariantInternalSkuField } from './components/ProductVariantInternalSkuField'
import { ProductVariantSmallestSalesUnitField } from './components/ProductVariantSmallestSalesUnit'

type ProductVariantDescriptionsProps = {
  productVariant: GetProductVariantQuery['productVariant']
}

export const ProductVariantDescriptions = ({ productVariant }: ProductVariantDescriptionsProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('inventory', {
    keyPrefix: 'product.details.productVariant',
  })

  return (
    <Descriptions {...descriptionsStyle}>
      <Descriptions.Item label={t('attributes')}>
        <ProductVariantAttributesField productVariant={productVariant} isEditable />
      </Descriptions.Item>
      <Descriptions.Item label={t('ean')}>
        <EditEanField productVariantUuid={productVariant.uuid} ean={productVariant.ean} />
      </Descriptions.Item>
      <Descriptions.Item label={t('identifier')}>
        {productVariant.identifier ?? '-'}
      </Descriptions.Item>
      <Descriptions.Item label={t('internalSku')}>
        <ProductVariantInternalSkuField productVariant={productVariant} isEditable />
      </Descriptions.Item>
      {selectedCompany.type !== CompanyType.WHOLESALER ? (
        <Descriptions.Item label={t('netPurchasePrice')}>
          {productVariant.netPurchasePrice
            ? formatMoney(productVariant.netPurchasePrice, 'EUR')
            : '-'}
        </Descriptions.Item>
      ) : null}
      <Descriptions.Item label={t('smallestSalesUnit')}>
        <ProductVariantSmallestSalesUnitField productVariant={productVariant} isEditable />
      </Descriptions.Item>
    </Descriptions>
  )
}
