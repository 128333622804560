import i18n from 'i18next'

export function formatMoney(value: number, currency: string, withSign = false): string {
  return new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    signDisplay: withSign ? 'exceptZero' : 'auto',
  }).format(value)
}
