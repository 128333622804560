import { Button, Result } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { SubHeader } from '../components/Layout/SubHeader/SubHeader'

export const NotFound = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <>
      <SubHeader />
      <Result
        status="404"
        title={t('404.title')}
        subTitle={t('404.subtitle')}
        extra={
          <Button type="primary" onClick={goBack}>
            {t('404.button')}
          </Button>
        }
      />
    </>
  )
}
