import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

type QuillTextareaProps = {
  placeholder?: string
  onChange?: (value: string) => void
  /** Height of the editor, you can use any css values e.g.: px, %, vh, vw. Default value is 100px if not provided*/
  editorHeight?: string
}

const QuillTextarea = ({
  onChange = () => {},
  placeholder,
  editorHeight = '100px',
}: QuillTextareaProps) => {
  const { t } = useTranslation(['translation'], { keyPrefix: 'quill' })

  return (
    <StyledQuillContainer editorHeight={editorHeight}>
      <ReactQuill
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['underline'],
            ['blockquote', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        }}
        className="notranslate"
        placeholder={placeholder ?? t('placeholder')}
        onChange={onChange}
      />
    </StyledQuillContainer>
  )
}

const StyledQuillContainer = styled.div<{ editorHeight?: string }>`
  .ql-toolbar {
    border: 1px solid ${({ theme }) => theme.colors.base[7]};
    border-radius: 8px 8px 0 0;
    background-color: ${({ theme }) => theme.colors.base[3]};

    .ql-formats {
      .ql-header {
        color: ${({ theme }) => theme.colors.text};

        .ql-picker-label {
          border: none;
        }

        .ql-picker-label:hover {
          color: ${({ theme }) => theme.colors.primary};

          svg {
            .ql-stroke {
              stroke: ${({ theme }) => theme.colors.primary};
            }
          }
        }

        .ql-picker-options {
          border: 1px solid ${({ theme }) => theme.colors.base[7]} !important;
          border-radius: 8px;
          background-color: ${({ theme }) => theme.colors.base[5]};
          color: ${({ theme }) => theme.colors.text};
          box-shadow: none;

          .ql-picker-item:hover {
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }

      svg {
        .ql-fill {
          fill: ${({ theme }) => theme.colors.text};
        }

        .ql-stroke {
          stroke: ${({ theme }) => theme.colors.text};
        }
      }

      .ql-active {
        svg {
          .ql-fill {
            fill: ${({ theme }) => theme.colors.primary};
          }

          .ql-stroke {
            stroke: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }

  .ql-container {
    border: 1px solid ${({ theme }) => theme.colors.base[7]};
    border-radius: 0 0 8px 8px;
    background-color: ${({ theme }) => theme.colors.base[3]};

    .ql-blank::before {
      color: ${({ theme }) => theme.colors.text}80;
      font-style: normal;
    }

    .ql-tooltip {
      border: 1px solid ${({ theme }) => theme.colors.base[7]};
      border-radius: 8px;
      background-color: ${({ theme }) => theme.colors.base[5]};
      color: ${({ theme }) => theme.colors.text};
      box-shadow: none;

      input {
        border: 1px solid ${({ theme }) => theme.colors.base[7]};
        background-color: ${({ theme }) => theme.colors.base[5]};
        color: ${({ theme }) => theme.colors.text};
      }

      a {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .ql-editor {
    min-height: ${(props) => props.editorHeight};
  }
`

export default QuillTextarea
