import { Form, Select } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { GetAllWholesaleOrdersDetailsQuery } from '../../../../../../../generated/graphql'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'

type WholesaleOrderBillingDocumentProps = {
  billingDocuments: GetAllWholesaleOrdersDetailsQuery['wholesaleOrderBillingDocuments']
}

export const WholesaleOrderBillingDocumentBillingDocumentSelect = ({
  billingDocuments,
}: WholesaleOrderBillingDocumentProps) => {
  const { t } = useTranslation('wholesale-order-billing-document')

  const form = useFormInstance<CreateWholesaleOrderBillingDocumentFormValues>()

  const handleChange = (value: string) => {
    const foundBillingDocument = billingDocuments.find((billingDocument) => {
      return billingDocument.uuid === value
    })

    if (!foundBillingDocument) {
      toast.error('billingDocumentSelect.error.notFound')
    } else {
      form.setFieldValue('debtorCompanySellerId', foundBillingDocument.debtorCompany.sellerId)
    }

    form.setFieldValue('lineItems', [])
  }

  return (
    <Form.Item<CreateWholesaleOrderBillingDocumentFormValues>
      name="billingDocumentUuid"
      label={t('billingDocumentSelect.label')}
      rules={[{ required: true, message: t('billingDocumentSelect.error.required') }]}
    >
      <Select<string>
        options={billingDocuments.map((billingDocument) => ({
          label: `${billingDocument.identifier} - ${billingDocument.documentNumber}`,
          value: billingDocument.uuid,
        }))}
        placeholder={t('billingDocumentSelect.placeholder')}
        disabled={!billingDocuments.length}
        onChange={handleChange}
        showSearch
        filterOption
        optionFilterProp="label"
      />
    </Form.Item>
  )
}
