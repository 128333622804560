import { Trash } from 'lucide-react'
import { useMutation } from '@apollo/client'
import { Alert, Button, Modal } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  DeleteWarehouseServiceProviderDocument,
  GetWarehouseServiceProvidersDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { WarehouseServiceProvider } from './WarehousingContainer'

type DeleteWarehouseServiceProviderNameModalProps = {
  warehouseServiceProvider: WarehouseServiceProvider
}

export const DeleteWarehouseServiceProviderNameModal = ({
  warehouseServiceProvider,
}: DeleteWarehouseServiceProviderNameModalProps) => {
  const [open, setOpen] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation('warehousing')

  const [deleteWarehouseServiceProvider, { loading }] = useMutation(
    DeleteWarehouseServiceProviderDocument
  )

  const handleDelete = async () => {
    try {
      await deleteWarehouseServiceProvider({
        variables: {
          uuid: warehouseServiceProvider.uuid,
        },
        optimisticResponse: {
          deleteWarehouseServiceProvider: {
            uuid: warehouseServiceProvider.uuid,
          },
        },
        update: (cache, { data }) => {
          if (!selectedCompany?.uuid) {
            return
          }

          const deletedWarehouseServiceProvider = data?.deleteWarehouseServiceProvider
          const cachedQuery = cache.readQuery({
            query: GetWarehouseServiceProvidersDocument,
            variables: { companyUuid: selectedCompany.uuid },
          })

          if (deletedWarehouseServiceProvider && cachedQuery) {
            const warehouseServiceProviders = cachedQuery.warehouseServiceProviders.filter(
              (warehouseServiceProvider) => {
                return warehouseServiceProvider.uuid !== deletedWarehouseServiceProvider.uuid
              }
            )

            cache.writeQuery({
              query: GetWarehouseServiceProvidersDocument,
              variables: { companyUuid: selectedCompany.uuid },
              data: { warehouseServiceProviders },
            })
          }
        },
      })

      toast.success(t('delete.toast.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('delete.toast.error'))
    } finally {
      setOpen(false)
    }
  }

  return (
    <>
      <Button
        type="text"
        danger
        icon={<Trash size={16} />}
        disabled={!!warehouseServiceProvider.locations?.length}
        onClick={() => setOpen(true)}
      />
      <Modal
        title={t('delete.title')}
        open={open}
        okButtonProps={{ danger: true }}
        okText={t('shared.button.delete', { ns: 'translation' })}
        onOk={handleDelete}
        cancelButtonProps={{ disabled: loading }}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
      >
        <Alert
          type="error"
          description={t('delete.alert', { name: warehouseServiceProvider.name })}
        />
      </Modal>
    </>
  )
}
