import { CreateAmazonInboundShipmentPlanInput } from '../../../../generated/graphql'
import { AmazonInboundShipmentPlanCreateFormInstance } from '../components/AmazonInboundShipmentPlanCreateForm'
import { ShipmentKind } from '../types'

export function formatAmazonInboundShipmentPlan(
  values: AmazonInboundShipmentPlanCreateFormInstance
): CreateAmazonInboundShipmentPlanInput['amazonInboundShipmentPlan'] {
  const areCasesRequired = values.shipmentKind === ShipmentKind.CASE

  return {
    marketplace: values.marketplace,
    shipFromLocationUuid: values.shipFromLocationUuid,
    pageType: values.pageType,
    shippingMode: values.shippingMode,
    shippingCarrier: values.shippingCarrier,
    areCasesRequired,
    note: values.note,
    cartons: values.cartons.map((carton) => {
      const unitsTotal = carton.items.reduce((previousValue, currentValue) => {
        return (
          previousValue + currentValue.quantityInPackagingUnit * currentValue.numberOfPackagingUnits
        )
      }, 0)

      return {
        numberOfCartons: carton.numberOfCartons,
        length: carton.cartonLength!,
        width: carton.cartonWidth!,
        height: carton.cartonHeight!,
        grossWeight: carton.cartonGrossWeight!,
        netWeight: carton.cartonNetWeight,
        unitsTotal,
        items: carton.items.map((item) => {
          const unitsPerCarton = areCasesRequired
            ? item.quantityInPackagingUnit
            : item.quantityInPackagingUnit * item.numberOfPackagingUnits

          return {
            sku: item.sku,
            labelType: item.labelType,
            labeledByAmazon: item.labeledByAmazon,
            unitsPerCarton,
            quantityShipped: 0,
            bestBeforeDate: item.bestBeforeDate,
          }
        }),
      }
    }),
  }
}
