import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GetDashboardAmazonAdsPerformanceDocument,
  InternalAmazonListingStatus,
  Marketplace,
} from '../../../../../generated/graphql'
import { useMarketplaceOptions } from '../../../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { StyledMainCard } from '../../../styles'
import { AmazonAdsPerformanceCardFilters } from './AmazonAdsPerformanceCardFilters'
import {
  DashboardSubCardAmazonAds,
  DashboardSubCardAmazonAdsData,
} from './DashboardSubCardAmazonAds'
import { prepareAmazonAdsMetrics } from './prepareAmazonAdsMetrics'

const defaultDateRange: [Dayjs, Dayjs] = [
  dayjs().subtract(29, 'd').startOf('d'),
  dayjs().endOf('d'),
]

export const DashboardCardAmazonAds = () => {
  const marketplaceOptions = useMarketplaceOptions()

  const [marketplace, setMarketplace] = useState(marketplaceOptions[0] || Marketplace.DE)
  const [dateRange, setDateRange] = useState(defaultDateRange)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const { t } = useTranslation(['dashboard', 'translation'])

  const startDatePreviousPeriod = dateRange[0]
    .subtract(dateRange[1].diff(dateRange[0], 'd'), 'd')
    .startOf('d')
  const endDatePreviousPeriod = dateRange[0].subtract(1, 'd').endOf('d')

  const { loading, data } = useQuery(GetDashboardAmazonAdsPerformanceDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    skip: !selectedCompany?.uuid,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
      marketplace,
      from: startDatePreviousPeriod.format('YYYY-MM-DD'),
      to: dateRange[1].format('YYYY-MM-DD'),
      internalStatuses: Object.values(InternalAmazonListingStatus),
    },
  })

  const { impressions, clicks, spend, adSales, acos, totalSales, tacos } = prepareAmazonAdsMetrics(
    data,
    dateRange[0],
    dateRange[1],
    startDatePreviousPeriod,
    endDatePreviousPeriod
  )

  return (
    <StyledMainCard
      title={t('performance.amazonAds.title')}
      extra={
        <AmazonAdsPerformanceCardFilters
          dateRange={dateRange}
          setDateRange={setDateRange}
          marketplace={marketplace}
          setMarketplace={setMarketplace}
          loading={loading}
        />
      }
    >
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} lg={8}>
              <DashboardSubCardAmazonAds
                title={t('performance.amazonAds.impressions')}
                tooltip={t('performance.amazonAds.impressionsTooltip')}
                type="quantity"
                trendPreference="up"
                data={impressions as DashboardSubCardAmazonAdsData}
                loading={loading}
              />
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <DashboardSubCardAmazonAds
                title={t('performance.amazonAds.clicks')}
                tooltip={t('performance.amazonAds.clicksTooltip')}
                type="quantity"
                trendPreference="up"
                data={clicks as DashboardSubCardAmazonAdsData}
                loading={loading}
              />
            </Col>
            <Col xs={24} lg={8}>
              <DashboardSubCardAmazonAds
                title={t('performance.amazonAds.spend')}
                tooltip={t('performance.amazonAds.spendTooltip')}
                type="money"
                trendPreference="down"
                data={spend as DashboardSubCardAmazonAdsData}
                loading={loading}
              />
            </Col>
            {/* <Col xs={24} sm={12}>
              <DashboardSubCardAmazonAds
                title={t('performance.amazonAds.adOrders')}
                tooltip={t('performance.amazonAds.adOrdersTooltip')}
                type="quantity"
                trendPreference="up"
                data={adOrders}
                loading={loading}
              />
            </Col> */}
            {/* <Col xs={24} sm={12}>
              <DashboardSubCardAmazonAds
                title={t('performance.amazonAds.totalOrders')}
                tooltip={t('performance.amazonAds.totalOrdersTooltip')}
                type="quantity"
                trendPreference="up"
                data={totalOrders}
                loading={loading}
              />
            </Col> */}
            <Col xs={24} sm={12}>
              <DashboardSubCardAmazonAds
                title={t('performance.amazonAds.adSales')}
                tooltip={t('performance.amazonAds.adSalesTooltip')}
                type="money"
                trendPreference="up"
                data={adSales as DashboardSubCardAmazonAdsData}
                loading={loading}
              />
            </Col>
            <Col xs={24} sm={12}>
              <DashboardSubCardAmazonAds
                title={t('performance.amazonAds.totalSales')}
                tooltip={t('performance.amazonAds.totalSalesTooltip')}
                type="money"
                trendPreference="up"
                data={totalSales}
                loading={loading}
              />
            </Col>
            <Col xs={24} sm={12}>
              <DashboardSubCardAmazonAds
                title={t('performance.amazonAds.acos')}
                tooltip={t('performance.amazonAds.acosTooltip')}
                type="percentage"
                trendPreference="down"
                data={acos}
                loading={loading}
              />
            </Col>
            <Col xs={24} sm={12}>
              <DashboardSubCardAmazonAds
                title={t('performance.amazonAds.tacos')}
                tooltip={t('performance.amazonAds.tacosTooltip')}
                type="percentage"
                trendPreference="down"
                data={tacos}
                loading={loading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledMainCard>
  )
}
