import { Navigate, Outlet, RouteProps, useLocation } from 'react-router'
import { CompanyType } from '../generated/graphql'
import { getUserRole } from '../helpers/getUserRole'
import { UserRole, useGlobalStore } from '../stores/useGlobalStore'

type ProtectedRouteProps = RouteProps & {
  types?: CompanyType[]
  roles?: UserRole[]
  to?: string | ((companyType: CompanyType) => string)
}

export const ProtectedRoute = ({
  types,
  roles,
  to = '/forbidden',
  children,
}: ProtectedRouteProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const user = useGlobalStore((state) => state.user)

  const location = useLocation()

  if (!selectedCompany || !user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  const userRole = getUserRole(user, selectedCompany)

  if (types?.length && !types.includes(selectedCompany.type)) {
    const redirectPath = typeof to === 'function' ? to(selectedCompany.type) : to
    return <Navigate to={redirectPath} replace />
  }

  if (roles?.length && (!userRole || !roles.includes(userRole)) && !user.isAdmin) {
    const redirectPath = typeof to === 'function' ? to(selectedCompany.type) : to
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}
