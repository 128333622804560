import { useMutation } from '@apollo/client'
import { Form } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router'
import { toast } from 'sonner'
import i18n from '../../../../config/i18n'
import {
  CarrierName,
  CheckOnboardingStepDocument,
  CreateAmazonInboundShipmentPlanDocument,
  GetAmazonListingsAndWarehousesQuery,
  GetCompanyDocument,
  LabelType,
  Marketplace,
  PageType,
  PrepInstruction,
  SendSupportTicketDocument,
  ShipmentType,
  WarehouseServiceProviderLocationFieldsFragment,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { createZendeskTicketOptions } from '../../../ticket/helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../../../ticket/ticket.enum'
import { formatAmazonInboundShipmentPlan } from '../helpers/formatAmazonInboundShipmentPlan'
import { AvailableInventory } from '../helpers/prepareAvailableInventory'
import { ShipmentKind } from '../types'
import { AmazonInboundShipmentPlanCreateFormCollapse } from './AmazonInboundShipmentPlanCreateFormCollapse'

export type AmazonInboundShipmentPlanCreateFormInstance = {
  shipmentKind: ShipmentKind
  shippingMode: ShipmentType
  marketplace: Marketplace
  shipFromLocationUuid: string
  availableInventory: AvailableInventory[]
  selectedInventory: SelectedInventory[]
  cartons: AmazonInboundShipmentPlanCarton[]
  pageType: PageType
  shippingCarrier: CarrierName
  note: string
}

export type SelectedInventory = {
  uuid: string
  image: string | null
  name: string
  ean: string | null
  asin: string
  fnsku: string | null
  sku: string
  internalSku: string | null
  labelType: LabelType
  labeledByAmazon: boolean
  prepInstructions: PrepInstruction[]
  recommendedQuantity: number | null
  quantityInPackagingUnit?: number
  numberOfPackagingUnits?: number
  bestBeforeDate?: string
  cartonType?: string
  cartonLength?: number
  cartonWidth?: number
  cartonHeight?: number
  cartonGrossWeight?: number
  smallestPackagingUnit: NonNullable<
    GetAmazonListingsAndWarehousesQuery['products'][number]['productVariants']
  >[number]['packagingUnits'][number]
  packagingUnits: NonNullable<
    GetAmazonListingsAndWarehousesQuery['products'][number]['productVariants']
  >[number]['packagingUnits']
}

export type AmazonInboundShipmentPlanCarton = {
  cartonType?: string
  numberOfCartons: number
  cartonLength?: number
  cartonWidth?: number
  cartonHeight?: number
  cartonGrossWeight?: number
  cartonNetWeight?: number | null
  items: AmazonInboundShipmentPlanCartonItem[]
}

export type AmazonInboundShipmentPlanCartonItem = {
  amazonListingUuid: string
  image: string | null
  name: string
  ean: string | null
  asin: string
  fnsku: string | null
  sku: string
  internalSku: string | null
  labelType: LabelType
  labeledByAmazon: boolean
  quantityInPackagingUnit: number
  numberOfPackagingUnits: number
  bestBeforeDate?: string
  smallestPackagingUnit: NonNullable<
    GetAmazonListingsAndWarehousesQuery['products'][number]['productVariants']
  >[number]['packagingUnits'][number]
}

type AmazonInboundShipmentPlanCreateFormProps = {
  availableInventory: AvailableInventory[]
  locations: WarehouseServiceProviderLocationFieldsFragment[]
}

export const AmazonInboundShipmentPlanCreateForm = ({
  availableInventory,
  locations,
}: AmazonInboundShipmentPlanCreateFormProps) => {
  const [submitting, setSubmitting] = useState(false)

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)!
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('inventory')
  const [searchParams] = useSearchParams()
  const [form] = Form.useForm<AmazonInboundShipmentPlanCreateFormInstance>()
  const navigate = useNavigate()

  const shipmentKind = searchParams.get('type') as ShipmentKind
  const shippingMode = searchParams.get('mode') as ShipmentType
  const marketplace = searchParams.get('marketplace') as Marketplace

  const [createAmazonInboundShipmentPlan] = useMutation(CreateAmazonInboundShipmentPlanDocument)
  const [checkOnboardingStep] = useMutation(CheckOnboardingStepDocument)
  const [sendSupportTicket] = useMutation(SendSupportTicketDocument)

  const initialValues: Partial<AmazonInboundShipmentPlanCreateFormInstance> = {
    shipmentKind,
    shippingMode,
    marketplace,
    availableInventory,
    selectedInventory: [],
    cartons: [],
    pageType: PageType.PACKAGELABEL_THERMAL_NONPCP,
  }

  const handleFinish = async () => {
    try {
      setSubmitting(true)

      await form.validateFields()
      const values: AmazonInboundShipmentPlanCreateFormInstance = form.getFieldsValue(true)
      const amazonInboundShipmentPlan = formatAmazonInboundShipmentPlan(values)

      const { data } = await createAmazonInboundShipmentPlan({
        variables: {
          input: {
            companyUuid: selectedCompany.uuid,
            amazonInboundShipmentPlan,
          },
        },
      })

      if (!data?.createAmazonInboundShipmentPlan.uuid) {
        throw new Error()
      }

      toast.success(t('amazonInboundShipmentPlan.add.success.message'))

      if (!selectedCompany.completedOnboarding) {
        await checkOnboardingStep({
          variables: {
            input: {
              companyUuid: selectedCompany.uuid,
              eventName: 'add_shipment',
            },
          },
          refetchQueries: [
            {
              query: GetCompanyDocument,
              variables: { companyUuid: selectedCompany.uuid },
            },
          ],
        })
      }

      if (marketplace in [Marketplace.UK, Marketplace.US]) {
        const comment = `
          <p>
            A new shipment plan for the marketplace ${marketplace} has been created:
            <a href="https://galaxy.spacegoats.io/amazon/shipment-plans/${data?.createAmazonInboundShipmentPlan.uuid}">
              https://galaxy.spacegoats.io/amazon/shipment-plans/${data?.createAmazonInboundShipmentPlan.uuid}
            </a>
          </p>
        `

        const options = createZendeskTicketOptions({
          subject: `New shipment for ${marketplace} was created`,
          comment,
          categoryTitle: 'amazon',
          mainCategory: TicketMainCategory.SHIPMENTS,
          ticketCategory: TicketCategory.SHIPMENTS_GENERAL,
          sellerId: selectedCompany.sellerId,
          language: i18n.language,
        })

        await sendSupportTicket({
          variables: {
            cognitoUsername: cognitoUser.getUsername(),
            companyUuid: selectedCompany.uuid,
            input: options,
          },
        })
      }

      setTimeout(() => {
        navigate(`/amazon/shipment-plans/${data.createAmazonInboundShipmentPlan.uuid}`)
      }, 300)
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Form<AmazonInboundShipmentPlanCreateFormInstance>
      name="create-amazon-inbound-shipment-plan"
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleFinish}
    >
      <AmazonInboundShipmentPlanCreateFormCollapse locations={locations} submitting={submitting} />
    </Form>
  )
}
