import { Collapse, Divider, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  getTotalAdjustments,
  getTotalAdvertisingFees,
  getTotalLongTermStorageFees,
  getTotalRefunds,
  getTotalRemissions,
  getTotalServiceFees,
  getTotalShipmentPlanFees,
  getTotalShipments,
  getTotalStorageFees,
} from '../helpers'
import { FinancialData } from './Payments'
import { PaymentsAdjustmentTable } from './PaymentsAdjustmentTable'
import { PaymentsAdvertisingFeeDetails } from './PaymentsAdvertisingFeeDetails'
import { PaymentsDetailsLabel } from './PaymentsDetailsLabel'
import { PaymentsDetailsLabelExtra } from './PaymentsDetailsLabelExtra'
import { PaymentsLongTermStorageFeeTable } from './PaymentsLongTermStorageFeeTable'
import { PaymentsRefundTable } from './PaymentsRefundTable'
import { PaymentsRemissionTable } from './PaymentsRemissionTable'
import { PaymentsServiceFeeTable } from './PaymentsServiceFeeTable'
import { PaymentsShipmentPlanFeeTable } from './PaymentsShipmentPlanFeeTable'
import { PaymentsShipmentTable } from './PaymentsShipmentTable'
import { PaymentsStorageFeeTable } from './PaymentsStorageFeeTable'

type PaymentsDetailsProps = {
  financialData: FinancialData | undefined
}

export const PaymentsDetails = ({ financialData }: PaymentsDetailsProps) => {
  const { t } = useTranslation(['billing'], { keyPrefix: 'payments' })

  if (!financialData) {
    return null
  }

  const totalShipments = getTotalShipments(financialData.shipments)
  const totalRefunds = getTotalRefunds(financialData.refunds)
  const totalAdjustments = getTotalAdjustments(financialData.adjustments)
  const totalRemissions = getTotalRemissions(financialData.remissions)
  const totalStorageFees = getTotalStorageFees(financialData.storageFees)
  const totalLongTermStorageFees = getTotalLongTermStorageFees(financialData.longTermStorageFees)
  const totalShipmentPlanFees = getTotalShipmentPlanFees(financialData.amazonInboundShipments)
  const totalAdvertisingFees = getTotalAdvertisingFees(financialData.advertisingFees)
  const totalServiceFees = getTotalServiceFees(financialData.serviceFees)

  return (
    <Flex vertical gap={8}>
      <Divider orientation="left" style={{ margin: 0 }}>
        <Typography.Text strong style={{ fontSize: '1.125rem' }}>
          {t('details.title')}
        </Typography.Text>
      </Divider>
      <Collapse
        items={[
          {
            key: 'shipments',
            label: (
              <PaymentsDetailsLabel
                title={t('details.shipments.title')}
                description={t('details.shipments.description')}
              />
            ),
            extra: <PaymentsDetailsLabelExtra value={totalShipments} />,
            children: <PaymentsShipmentTable shipments={financialData.shipments} />,
          },
          {
            key: 'refunds',
            label: (
              <PaymentsDetailsLabel
                title={t('details.refunds.title')}
                description={t('details.refunds.description')}
              />
            ),
            extra: <PaymentsDetailsLabelExtra value={totalRefunds} />,
            children: <PaymentsRefundTable refunds={financialData.refunds} />,
          },
          {
            key: 'adjustments',
            label: (
              <PaymentsDetailsLabel
                title={t('details.adjustments.title')}
                description={t('details.adjustments.description')}
              />
            ),
            extra: <PaymentsDetailsLabelExtra value={totalAdjustments} />,
            children: <PaymentsAdjustmentTable adjustments={financialData.adjustments} />,
          },
          {
            key: 'amazon-removal-orders',
            label: (
              <PaymentsDetailsLabel
                title={t('details.remissions.title')}
                description={t('details.remissions.description')}
              />
            ),
            extra: <PaymentsDetailsLabelExtra value={totalRemissions} />,
            children: <PaymentsRemissionTable remissions={financialData.remissions} />,
          },
          {
            key: 'storage-fees',
            label: (
              <PaymentsDetailsLabel
                title={t('details.storageFees.title')}
                description={t('details.storageFees.description')}
              />
            ),
            extra: <PaymentsDetailsLabelExtra value={totalStorageFees} />,
            children: <PaymentsStorageFeeTable storageFees={financialData.storageFees} />,
          },
          {
            key: 'long-term-storage-fees',
            label: (
              <PaymentsDetailsLabel
                title={t('details.longTermStorageFees.title')}
                description={t('details.longTermStorageFees.description')}
              />
            ),
            extra: <PaymentsDetailsLabelExtra value={totalLongTermStorageFees} />,
            children: (
              <PaymentsLongTermStorageFeeTable
                longTermStorageFees={financialData.longTermStorageFees}
              />
            ),
          },
          {
            key: 'shipment-plan-fees',
            label: (
              <PaymentsDetailsLabel
                title={t('details.shipmentPlanFees.title')}
                description={t('details.shipmentPlanFees.description')}
              />
            ),
            extra: <PaymentsDetailsLabelExtra value={totalShipmentPlanFees} />,
            children: (
              <PaymentsShipmentPlanFeeTable
                shipmentPlanFees={financialData.amazonInboundShipments}
              />
            ),
          },
          {
            key: 'advertising-fees',
            label: (
              <PaymentsDetailsLabel
                title={t('details.advertisingFees.title')}
                description={t('details.advertisingFees.description')}
              />
            ),
            extra: <PaymentsDetailsLabelExtra value={totalAdvertisingFees} />,
            children: (
              <PaymentsAdvertisingFeeDetails advertisingFees={financialData.advertisingFees} />
            ),
          },
          {
            key: 'service-fees',
            label: (
              <PaymentsDetailsLabel
                title={t('details.serviceFees.title')}
                description={t('details.serviceFees.description')}
              />
            ),
            extra: <PaymentsDetailsLabelExtra value={totalServiceFees} />,
            children: <PaymentsServiceFeeTable serviceFees={financialData.serviceFees} />,
          },
        ]}
      />
    </Flex>
  )
}
