import dayjs from 'dayjs'

export function excelDateToFormattedDateString(
  excelDate: number | string | null | undefined,
  format?: string
): string {
  const dateFormat = format || 'DD.MM.YYYY'

  if (excelDate === null || excelDate === undefined) {
    return ''
  }

  if (typeof excelDate === 'string') {
    return dayjs(excelDate).format(dateFormat)
  }

  const excelEpoch = new Date(1899, 11, 30) // December 30, 1899
  const millisecondsPerDay = 24 * 60 * 60 * 1000
  const totalMilliseconds = excelDate * millisecondsPerDay

  return dayjs(excelEpoch.getTime() + totalMilliseconds).format(dateFormat)
}
