import {
  AmazonInboundShipmentStatus,
  ShipmentPlanLifecycleStatus,
} from '../../../../generated/graphql'

export const getAmazonInboundShipmentPlanStatuses = (status: ShipmentPlanLifecycleStatus) => {
  const statuses: AmazonInboundShipmentStatus[] = []

  if (status === ShipmentPlanLifecycleStatus.IN_PROGRESS) {
    statuses.push(
      AmazonInboundShipmentStatus.WORKING,
      AmazonInboundShipmentStatus.SHIPPED,
      AmazonInboundShipmentStatus.RECEIVING,
      AmazonInboundShipmentStatus.IN_TRANSIT,
      AmazonInboundShipmentStatus.DELIVERED,
      AmazonInboundShipmentStatus.CHECKED_IN,
      AmazonInboundShipmentStatus.READY_TO_SHIP
    )
  } else if (status === ShipmentPlanLifecycleStatus.FINISHED) {
    statuses.push(AmazonInboundShipmentStatus.CLOSED)
  } else if (status === ShipmentPlanLifecycleStatus.CANCELLED) {
    statuses.push(AmazonInboundShipmentStatus.CANCELLED, AmazonInboundShipmentStatus.DELETED)
  }

  return statuses
}
