import { useMutation } from '@apollo/client'
import { Alert, Button, Modal, Typography } from 'antd'
import { Trash } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import {
  DeleteProductDocument,
  GetProductQuery,
  InternalAmazonListingStatus,
} from '../../../../generated/graphql'
import { sortByMarketplace } from '../../../../helpers/sortByMarketplace'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { useTicketInput } from './useTicketInput'

type ArchiveProductModalProps = {
  product: GetProductQuery['product']
}

export const ArchiveProductModal = ({ product }: ArchiveProductModalProps) => {
  const [open, setOpen] = useState(false)

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()
  const { t } = useTranslation(['inventory', 'translation'])

  const [deleteProduct, { loading }] = useMutation(DeleteProductDocument)

  const productVariants = product.productVariants ?? []
  const amazonProducts = productVariants.flatMap((productVariant) => {
    if (!productVariant.amazonProducts) {
      return []
    }

    return productVariant.amazonProducts
  })
  const asins = [...new Set(amazonProducts.map((amazonProduct) => amazonProduct.asin))]
  const amazonListings =
    amazonProducts.flatMap((amazonProduct) => {
      if (!amazonProduct?.amazonListings) {
        return []
      }

      const activeAmazonListings = amazonProduct.amazonListings.filter(
        (amazonListing) => amazonListing.internalStatus === InternalAmazonListingStatus.ACTIVE
      )

      return activeAmazonListings.sort((a, b) => {
        return sortByMarketplace(a.marketplace.name, b.marketplace.name)
      })
    }) ?? []
  const skus = [...new Set(amazonListings.map((listing) => listing.sku))]

  const disabled = !!amazonListings.find((amazonListing) => {
    return (
      amazonListing.inventory?.fulfillable?.fulfillableQuantity &&
      amazonListing.inventory?.fulfillable.fulfillableQuantity > 0
    )
  })

  const ticketInput = useTicketInput({ asins, skus })

  const openModal = () => {
    setOpen(true)
  }

  const handleOk = async () => {
    try {
      if (!product.uuid || !cognitoUser || !selectedCompany) {
        throw new Error()
      }

      await deleteProduct({
        variables: {
          productUuid: product.uuid,
          companyUuid: selectedCompany.uuid,
          cognitoUsername: cognitoUser.getUsername(),
          input: ticketInput,
        },
      })

      toast.success(t('product.details.delete.success'))
      setTimeout(() => {
        navigate('/amazon/products')
      }, 300)
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setOpen(false)
    }
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        type="primary"
        danger
        icon={<Trash size={16} />}
        onClick={openModal}
        disabled={disabled}
      >
        {t('product.details.delete.button')}
      </Button>
      <Modal
        title={t('product.details.delete.modal.title')}
        open={open}
        okText={t('shared.button.delete', { ns: 'translation' })}
        onOk={handleOk}
        okButtonProps={{ danger: true }}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={closeModal}
        confirmLoading={loading}
      >
        <Typography.Paragraph>{t('product.details.delete.modal.paragraph1')}</Typography.Paragraph>
        <Typography.Paragraph>{t('product.details.delete.modal.paragraph2')}</Typography.Paragraph>
        <ul>
          {asins.map((asin) => (
            <li key={asin}>{asin}</li>
          ))}
        </ul>
        <Typography.Paragraph>{t('product.details.delete.modal.paragraph3')}</Typography.Paragraph>
        <ul>
          {skus.map((sku) => (
            <li key={sku}>{sku}</li>
          ))}
        </ul>
        <Alert type="error" message={t('product.details.delete.modal.alert')} />
      </Modal>
    </>
  )
}
