import { useMutation } from '@apollo/client'
import { Button, Form, Modal, Popconfirm, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { toast } from 'sonner'
import {
  AmazonPpcState,
  Marketplace,
  UpdateSponsoredProductsProductAdInput,
  UpdateSponsoredProductsProductAdsDocument,
  UpdateSponsoredProductsProductAdsMutation,
} from '../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import PPCStateFormFields from '../../../PPCStateFormFields/PPCStateFormFields'
type UpdateSponsoredProductsProductAdModalProps = {
  marketplace: Marketplace
  campaignId: string
  adId: string
  stateIn: AmazonPpcState
  onProductAdUpdated: Function
}

const UpdateSponsoredProductsProductAdModal = ({
  marketplace,
  campaignId,
  adId,
  stateIn,
  onProductAdUpdated,
}: UpdateSponsoredProductsProductAdModalProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const [updateSponsoredProductsProductAds, { loading: loadingUpdate }] =
    useMutation<UpdateSponsoredProductsProductAdsMutation>(
      UpdateSponsoredProductsProductAdsDocument
    )

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({ state: stateIn })
    }
  }, [modalVisible, form, stateIn])

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleOnFinish = async (values: any) => {
    const productAdsToUpdate: UpdateSponsoredProductsProductAdInput = {
      adId: adId,
      state: values.state,
    }

    const productAds = [productAdsToUpdate]

    updateSponsoredProductsProductAds({
      variables: { companyUuid: selectedCompany?.uuid, campaignId, marketplace, productAds },
    })
      .then(({ data }) => {
        const updatedProductAds = data?.updateSponsoredProductsProductAds
        toast.success('Product Ad updated.')
        updatedProductAds?.forEach((productAd) => {
          onProductAdUpdated(productAd)
        })
        setModalVisible(false)
      })
      .catch((e) => {
        console.error(e)
        if (e.networkError?.statusCode === 429) {
          toast.error("Amazon's servers are currently busy, please try again in a few minutes.")
        } else {
          toast.error('Error updating Product Ad.', e.message)
        }
      })
  }

  const handleFormValuesChange = () => {
    const formState = form.getFieldValue('state')
    setSaveButtonDisabled(formState == stateIn)
  }

  return (
    <>
      <Button key="huihif" onClick={showModal} data-testid="open-product-ad-settings-modal-btn">
        Edit
      </Button>

      <div
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}
        key="u90u90u"
      >
        <Modal
          title="Product Ad Settings"
          open={modalVisible}
          onCancel={handleCancel}
          footer={
            <Space>
              <Button onClick={handleCancel} key="cancel">
                Cancel
              </Button>
              <Popconfirm
                key="u8f8f"
                placement="top"
                title={'Please confirm these changes'}
                onConfirm={form.submit}
                okText="Confirm"
                cancelText="Go back"
                disabled={saveButtonDisabled}
              >
                <Button
                  key="89040"
                  disabled={saveButtonDisabled}
                  type={'primary'}
                  loading={loadingUpdate}
                >
                  Save
                </Button>
              </Popconfirm>
            </Space>
          }
        >
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            initialValues={{ state: stateIn }}
            onFinish={handleOnFinish}
            onValuesChange={handleFormValuesChange}
          >
            <PPCStateFormFields />
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default UpdateSponsoredProductsProductAdModal
