import { Button, Input, Row } from 'antd'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'sonner'
import {
  AmazonPpcCampaignType,
  TargetingExpressionPredicateType,
} from '../../../../../../generated/graphql'
import { asinRegExp } from '../../../../../../utils/regExp'
import { useDefaultMinimumBid } from '../../../../hooks/useDefaultMinimumBid'
import { UserDefinedTarget } from './CreateSponsoredProductsManualTargetsFormFields'

const { TextArea } = Input

type BulkAsinInputProps = {
  userDefinedAsinTargets: UserDefinedTarget[]
  handleAddTargets: Function
}

const BulkAsinInput = ({ userDefinedAsinTargets, handleAddTargets }: BulkAsinInputProps) => {
  const [textareaValue, setTextareaValue] = useState<string>('')
  const [asinInput, setAsinInput] = useState<string[]>([])
  const defaultMinimumBid = useDefaultMinimumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)

  const handleAddAsinTargets = () => {
    const newTargets: UserDefinedTarget[] = []
    asinInput.forEach((asin: string) => {
      const asinMatchedRegex = asin.match(asinRegExp)
      const asinAlreadyExists =
        userDefinedAsinTargets.filter((target) => target.expressionValue === asin).length > 0 ||
        newTargets.filter((target) => target.expressionValue === asin).length > 0
      if (!asinMatchedRegex) {
        toast.error(`Asin does not match expected pattern`, { description: asin })
      } else if (asinAlreadyExists) {
        toast.error(`Duplicate ASIN target cannot be added`, { description: asin })
      } else {
        newTargets.push({
          id: Math.floor(Math.random() * 99999999999999).toString(),
          expressionType: TargetingExpressionPredicateType.ASINSAMEAS,
          expressionValue: asin,
          resolvedExpressionValue: asin,
          description: '',
          name: asin,
          bid: defaultMinimumBid,
        })
      }
    })

    handleAddTargets(newTargets)

    setTextareaValue('')
    setAsinInput([])
  }

  const handleTextareaOnChange = (e: any) => {
    const userInput = e.target.value
    setTextareaValue(userInput)
    if (userInput) {
      // split on new line and remove any empty lines
      const targets = userInput.split('\n').filter((asin: string) => asin.trim())
      setAsinInput(targets)
    } else {
      setAsinInput([])
    }
  }

  return (
    <>
      <Row style={{ padding: '6px 12px' }}>
        <TextArea
          rows={15}
          placeholder="Enter ASINs seperated by new line"
          onChange={handleTextareaOnChange}
          value={textareaValue}
          data-testid="asin-textarea"
        />
      </Row>
      <Row style={{ padding: '6px 12px' }} justify="end">
        <Button
          type="primary"
          onClick={handleAddAsinTargets}
          icon={<Plus size={16} />}
          disabled={!asinInput.length}
          data-testid="add-asin-btn"
        >
          Add ASINs
        </Button>
      </Row>
    </>
  )
}

export default BulkAsinInput
