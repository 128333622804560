import { Col, Divider, Flex, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { AmazonProductFieldsFragment, GetProductVariantQuery } from '../../../../generated/graphql'
import { AmazonProductCard, AmazonProductCardSkeleton } from './AmazonProductCard'
import { CreateSalesChannelModal } from './CreateSalesChannelModal'

type AmazonProductCardsProps = {
  productVariant?: GetProductVariantQuery['productVariant'] | null
  amazonProducts: AmazonProductFieldsFragment[]
  loading: boolean
}

export const AmazonProductCards = ({
  productVariant,
  amazonProducts,
  loading,
}: AmazonProductCardsProps) => {
  const { t } = useTranslation('inventory')

  return (
    <>
      <Row align="middle" wrap={false} style={{ columnGap: '1rem' }}>
        {!loading && (
          <>
            <Col flex="auto">
              <Divider orientation="left" orientationMargin={16}>
                <Typography.Text strong>
                  {t('product.details.amazonProduct.divider')}
                </Typography.Text>
              </Divider>
            </Col>
            {!amazonProducts?.length && productVariant && (
              <Col flex="none">
                <CreateSalesChannelModal productVariantUuid={productVariant.uuid} />
              </Col>
            )}
          </>
        )}
      </Row>
      <Flex vertical gap={16}>
        {loading ? (
          <AmazonProductCardSkeleton />
        ) : amazonProducts.length ? (
          amazonProducts.map((amazonProduct) => (
            <AmazonProductCard key={amazonProduct.uuid} amazonProduct={amazonProduct} />
          ))
        ) : (
          <Typography.Text type="secondary">
            {t('product.details.amazonProduct.empty')}
          </Typography.Text>
        )}
      </Flex>
    </>
  )
}
