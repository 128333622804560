import { Button, Form, Modal, Tooltip, Typography } from 'antd'
import { File } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetAllAmazonProductsQuery, Marketplace } from '../../../../generated/graphql'
import { FnskuLabelForm } from '../../shipmentPlans/components/FNSKULabelForm'

const { useForm } = Form

enum LabelFormat {
  L = '27 labels, 63,5 x 29,6 mm A4',
  M = '40 labels, 52,5 x 29,7 mm A4',
  N = '44 labels, 48,5 x 25,4 mm A4',
}

type DownloadFnskuLabelsFormInstance = {
  sku: string
  marketplace: Marketplace
  labelFormat: LabelFormat
  total: number
}

type DownloadFnskuLabelsModalProps = {
  amazonProducts?: NonNullable<
    GetAllAmazonProductsQuery['products'][number]['productVariants']
  >[number]['amazonProducts']
  marketplace?: Marketplace
  sku?: string
  withButton?: boolean
}

export const DownloadFnskuLabelsModal = ({
  amazonProducts,
  marketplace,
  sku,
  withButton,
}: DownloadFnskuLabelsModalProps) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation(['inventory', 'translation'])
  const [form] = useForm<DownloadFnskuLabelsFormInstance>()

  const allowedMarketplaces: Marketplace[] = amazonProducts ? [] : [marketplace!]

  const uniqueSkus = amazonProducts
    ? Array.from(
        new Set(
          amazonProducts.flatMap((amazonProduct) => {
            if (!amazonProduct.amazonListings) {
              return []
            }

            return amazonProduct.amazonListings
              .filter((amazonListing) => amazonListing.fnsku)
              .flatMap((amazonListing) => {
                allowedMarketplaces.push(amazonListing.marketplace.name)
                return amazonListing.sku
              })
          })
        )
      )
    : sku
      ? [sku]
      : []

  if (marketplace) {
    allowedMarketplaces.sort((a, b) => {
      if (a === marketplace) {
        return -1
      }

      if (b === marketplace) {
        return 1
      }

      return 0
    })
  }

  return (
    <>
      {withButton ? (
        <Tooltip title={t('product.card.dropdown.downloadFnskuLabel')}>
          <Button icon={<File size={16} />} onClick={() => setOpen(true)} />
        </Tooltip>
      ) : (
        <Typography.Link onClick={() => setOpen(true)}>
          {t('product.card.dropdown.downloadFnskuLabel')}
        </Typography.Link>
      )}

      <Modal
        width={600}
        title={t('amazonInboundShipmentPlan.modal.labels.fnsku.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        destroyOnClose
      >
        <FnskuLabelForm
          skus={uniqueSkus}
          setModalVisible={setOpen}
          setLoading={setLoading}
          form={form}
          items={40}
          marketplaces={allowedMarketplaces}
        />
      </Modal>
    </>
  )
}
