import { useLazyQuery } from '@apollo/client'
import { Button, DatePicker, Form, Radio, Row, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { DefaultOptionType } from 'antd/es/select'
import dayjs, { Dayjs } from 'dayjs'
import mixpanel from 'mixpanel-browser'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { AmazonOrderStatus, GetOrderReportDocument, Marketplace } from '../../../generated/graphql'
import { exportCsvFile, exportXlsxFile } from '../../../helpers/exportFile'
import { getMarketplaceDomain } from '../../../helpers/getMarketplaceDomain'
import { useDateRangePresets } from '../../../hooks/useDateRangePresets'
import { useMarketplaceOptions } from '../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { getTranslatedKeys } from '../helpers'
import { FileFormat } from '../interfaces'
import { prepareAmazonOrderDataReport } from './prepareAmazonOrderDataReport'

const { RangePicker } = DatePicker

type OrderReportFormInstance = {
  dateRange: [Dayjs, Dayjs]
  marketplaces: Marketplace[]
  fileFormat: string
  statuses: AmazonOrderStatus[]
}

export const OrdersReportView = () => {
  const [loading, setLoading] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation(['report', 'translation'])
  const [form] = useForm<OrderReportFormInstance>()

  const presets = useDateRangePresets()
  const marketplaceOptions = useMarketplaceOptions()

  const [getOrders] = useLazyQuery(GetOrderReportDocument)

  const downloadReport = async (values: OrderReportFormInstance) => {
    mixpanel.track('Downloaded report', {
      type: 'orders-report',
    })

    setLoading(true)

    try {
      if (!selectedCompany?.uuid) {
        throw new Error(t('shared.error.message', { ns: 'translation' }))
      }

      const { data } = await getOrders({
        errorPolicy: 'none',
        fetchPolicy: 'no-cache',
        variables: {
          companyUuid: selectedCompany.uuid,
          from: values.dateRange[0].format('YYYY-MM-DD'),
          to: values.dateRange[1].format('YYYY-MM-DD'),
          marketplaces: values.marketplaces,
          statuses: values.statuses,
        },
      })

      if (!data) {
        throw new Error(t('shared.error.message', { ns: 'translation' }))
      }

      const amazonOrders = prepareAmazonOrderDataReport(data, t)

      if (!amazonOrders.length) {
        toast.success(t('shared.noData', { ns: 'translation' }))
      }

      const translatedOrders = getTranslatedKeys(amazonOrders, 'orders', t)

      const fileName = `${t('orders.form.fileName')}_${values.dateRange[0].format(
        'YYYY-MM-DD'
      )}-${values.dateRange[1].format('YYYY-MM-DD')}`

      if (values.fileFormat === FileFormat.CSV) {
        exportCsvFile(translatedOrders, fileName)
      } else {
        exportXlsxFile(translatedOrders, fileName)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const statusOptions: DefaultOptionType[] = [
    {
      value: AmazonOrderStatus.UNSHIPPED,
      label: t('orders.statuses.UNSHIPPED'),
    },
    {
      value: AmazonOrderStatus.SHIPPED,
      label: t('orders.statuses.SHIPPED'),
    },
    {
      value: AmazonOrderStatus.PENDING,
      label: t('orders.statuses.PENDING'),
    },
    {
      value: AmazonOrderStatus.CANCELED,
      label: t('orders.statuses.CANCELED'),
    },
  ]

  return (
    <>
      <SubHeader heading={t('orders.heading')} withBackButton />
      <Suspense>
        <ViewContainer centered>
          <Form<OrderReportFormInstance>
            name="order-report"
            form={form}
            layout="vertical"
            onFinish={downloadReport}
            style={{ width: '100%', maxWidth: '640px' }}
            initialValues={{
              dateRange: presets[3]?.value,
              marketplaces: [Marketplace.DE],
              statuses: [
                AmazonOrderStatus.UNSHIPPED,
                AmazonOrderStatus.SHIPPED,
                AmazonOrderStatus.PENDING,
                AmazonOrderStatus.CANCELED,
              ],
              fileFormat: FileFormat.CSV,
            }}
          >
            <Form.Item
              label={t('orders.form.dateRange')}
              name="dateRange"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <RangePicker
                allowClear={false}
                onCalendarChange={(values) => {
                  if (values?.[0] && values?.[1]) {
                    form.setFieldsValue({
                      dateRange: [values[0].startOf('day'), values[1].endOf('day')],
                    })
                  }
                }}
                disabledDate={(date) =>
                  date.isAfter(dayjs()) || date.isBefore(dayjs().subtract(3, 'month'))
                }
                format="DD.MM.YY"
                presets={presets}
              />
            </Form.Item>
            <Form.Item
              label={t('orders.form.marketplaces')}
              name="marketplaces"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select<Marketplace[]>
                mode="multiple"
                options={marketplaceOptions.map((marketplace) => ({
                  value: marketplace,
                  label: `Amazon.${getMarketplaceDomain(marketplace)}`,
                }))}
                placeholder={t('shared.form.placeholder.select', {
                  ns: 'translation',
                })}
                allowClear
                showSearch
                filterOption={true}
                optionFilterProp="label"
              />
            </Form.Item>
            <Form.Item
              label={t('orders.form.statuses')}
              name="statuses"
              rules={[{ required: true }]}
            >
              <Select<AmazonOrderStatus[]>
                mode="multiple"
                options={statusOptions}
                placeholder={t('shared.form.placeholder.select', {
                  ns: 'translation',
                })}
                allowClear
                showSearch
                filterOption={true}
                optionFilterProp="label"
              />
            </Form.Item>
            <Form.Item
              name="fileFormat"
              label={t('orders.form.fileFormat')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={FileFormat.XLSX}>Excel</Radio>
                  <Radio value={FileFormat.CSV}>CSV</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Row justify="end">
              <Button type="primary" htmlType="submit" loading={loading}>
                {t('orders.form.download')}
              </Button>
            </Row>
          </Form>
        </ViewContainer>
      </Suspense>
    </>
  )
}
