import { useMutation, useQuery } from '@apollo/client'
import { Button, Card, Col, Divider, Form, Row, Space } from 'antd'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { toast } from 'sonner'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import QuillTextarea from '../../components/Ticket/QuillTextarea'
import TicketComment from '../../components/Ticket/TicketComment'
import TicketStatusBadge from '../../components/Ticket/TicketStatusBadge'
import { UploadZendeskFile } from '../../components/Ticket/UploadFile'
import {
  AddSupportTicketCommentDocument,
  SupportTicketCommentsDocument,
  SupportTicketCommentsQuery,
  SupportTicketStatus,
  UpdateTicketDocument,
} from '../../generated/graphql'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { PersonalNoteInput } from './components/form/PersonalNoteInput'
import { TicketCustomFieldId } from './ticket.enum'
import { AttachmentProps } from './ticket.interface'

type CommentFormInstance = {
  comment: string
  personalNotes: string
}

const ReadView = () => {
  const [supportTicket, setSupportTicket] = useState<
    SupportTicketCommentsQuery['supportTicket'] | null
  >(null)
  const [comments, setComments] = useState<
    SupportTicketCommentsQuery['supportTicketComments']['comments']
  >([])
  const [users, setUsers] = useState<SupportTicketCommentsQuery['supportTicketComments']['users']>(
    []
  )
  const [messageInputContainsText, setMessageInputContainsText] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams<{ id: string }>()
  const ticketIds = location.state.ticketIds
  const ticketIndex = ticketIds.indexOf(Number(params.id))
  const { t } = useTranslation(['ticket'], { keyPrefix: 'ticketReadView' })

  const [form] = Form.useForm()
  const [form2] = Form.useForm()

  const [createNewComment, { loading: loadingCreateNewComment }] = useMutation(
    AddSupportTicketCommentDocument
  )

  const [updateTicket, { loading: loadingUpdateTicket }] = useMutation(UpdateTicketDocument)

  const { loading, error } = useQuery(SupportTicketCommentsDocument, {
    variables: {
      cognitoUsername: cognitoUser!.getUsername(),
      companyUuid: selectedCompany!.uuid,
      ticketId: params.id!,
    },
    onCompleted: (data) => {
      setSupportTicket(data.supportTicket)
      setComments(data.supportTicketComments.comments)
      setUsers(data.supportTicketComments.users)
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error || !supportTicket || !users) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  const onFinish = async ({ comment }: CommentFormInstance) => {
    try {
      const tokens = attachments.map((attachment) => {
        return attachment.token
      })

      if (selectedCompany !== null && selectedCompany.uuid && supportTicket.id) {
        await createNewComment({
          variables: {
            companyUuid: selectedCompany.uuid,
            cognitoUsername: cognitoUser!.getUsername(),
            input: {
              comment,
              attachments: tokens,
            },
            ticketId: supportTicket.id.toString(),
          },
        })
        toast.success(t('notifications.message.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      toast.error(t('notifications.message.fail'), { description: error.message })
    }
  }

  const onUpdate = async ({ personalNotes }: CommentFormInstance) => {
    try {
      if (selectedCompany !== null && selectedCompany.uuid && supportTicket.id) {
        await updateTicket({
          variables: {
            cognitoUsername: cognitoUser!.getUsername(),
            input: {
              customFields: [
                {
                  id: TicketCustomFieldId.PERSONAL_NOTES,
                  value: personalNotes,
                },
              ],
            },
            ticketId: supportTicket.id.toString(),
          },
        })
        toast.success(t('notifications.personalNotes.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      toast.error(t('notifications.personalNotes.fail'), { description: error.message })
    }
  }

  const handleQuillOnChange = (value: string) => {
    const valueWithoutMarkup = value.replace(/<{1}[^<>]{1,}>{1}/g, ' ').trim()
    setMessageInputContainsText(!!valueWithoutMarkup?.length)
  }

  const { value: personalNotes } = supportTicket.custom_fields.find(
    (customField: { id: number; value: string }) =>
      customField.id === Number(TicketCustomFieldId.PERSONAL_NOTES)
  )
  const renderPostCommentForm = () => {
    return (
      <>
        <Form<CommentFormInstance> form={form} onFinish={onFinish}>
          <Form.Item name="comment">
            <QuillTextarea onChange={handleQuillOnChange} editorHeight={'200px'} />
          </Form.Item>
          <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
          <Form.Item>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!messageInputContainsText}
                data-testid="submit-button"
                loading={loadingCreateNewComment}
              >
                {t('button.send')}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </>
    )
  }

  const renderCommentHistory = (comments: any) => {
    const commentMarkup = []

    for (let i = comments.length - 1; i >= 0; i--) {
      commentMarkup.push(<TicketComment comment={comments[i]} users={users} key={i} />)
    }

    return commentMarkup
  }

  const navigateToPreviousTicket = () => {
    navigate(`/tickets/${ticketIds[ticketIndex - 1]}`, { state: location.state })
  }

  const navigateToNextTicket = () => {
    navigate(`/tickets/${ticketIds[ticketIndex + 1]}`, { state: location.state })
  }

  const rightContent = (
    <Row justify="center" gutter={16}>
      <Col>
        {ticketIndex >= 1 ? (
          <Button onClick={navigateToPreviousTicket}>
            <ArrowLeft size={16} />
            {t('button.previousTicket')}
          </Button>
        ) : (
          <Space />
        )}
      </Col>
      <Col>
        <TicketStatusBadge status={supportTicket.status} />
      </Col>
      <Col>
        {ticketIndex !== location.state.ticketIds.length - 1 ? (
          <Button onClick={navigateToNextTicket}>
            {t('button.nextTicket')}
            <ArrowRight size={16} />
          </Button>
        ) : null}
      </Col>
    </Row>
  )

  return (
    <>
      <SubHeader
        heading={`Ticket #${supportTicket.id}`}
        withBackButton
        onBackUrl="/tickets"
        rightContent={rightContent}
      />
      <ViewContainer>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row gutter={16} align={'stretch'}>
            <Col xl={10} lg={10} md={24} sm={24} xs={24} flex={'auto'}>
              <Card style={{ position: 'sticky', top: 0, left: 0 }}>
                {supportTicket.status !== SupportTicketStatus.CLOSED
                  ? renderPostCommentForm()
                  : null}
                <Divider />
                <Form<CommentFormInstance>
                  form={form2}
                  onFinish={onUpdate}
                  initialValues={{ personalNotes }}
                >
                  <PersonalNoteInput />
                  <Form.Item>
                    <Row justify="end">
                      <Button
                        type="primary"
                        htmlType="submit"
                        data-testid="update-button"
                        loading={loadingUpdateTicket}
                      >
                        {t('button.update')}
                      </Button>
                    </Row>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            <Col xl={14} lg={14} md={24} sm={24} xs={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {comments?.length ? renderCommentHistory(comments) : null}
              </Space>
            </Col>
          </Row>
        </Space>
      </ViewContainer>
    </>
  )
}

export default ReadView
