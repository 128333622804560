import { useMutation } from '@apollo/client'
import { Alert, Button, Divider, Modal, Space, Tooltip, Typography, UploadFile } from 'antd'
import dayjs from 'dayjs'
import { SquarePen } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import i18n from '../../../../../config/i18n'
import {
  AmazonListingChangeRequestStatus,
  AmazonListingChangeRequestType,
  ExternalAmazonListingStatus,
  GetAmazonListingQuery,
  InternalAmazonListingStatus,
  RequestAmazonListingChangeDocument,
  SendSupportTicketDocument,
} from '../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { createZendeskTicketOptions } from '../../../../ticket/helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../../../../ticket/ticket.enum'
import { uploadImagesAndGetImageUrls } from '../../helpers/uploadImagesAndGetImageUrls'
import { AmazonListingImagesManagement } from './AmazonListingImagesManagement'
import { CurrentAmazonListingImages } from './CurrentAmazonListingImages'

export type AmazonListingImagesUpload = {
  mainImage: UploadFile
  imagePosition1?: UploadFile
  imagePosition2?: UploadFile
  imagePosition3?: UploadFile
  imagePosition4?: UploadFile
  imagePosition5?: UploadFile
  imagePosition6?: UploadFile
  imagePosition7?: UploadFile
  imagePosition8?: UploadFile
}

type EditAmazonListingImagesModalProps = {
  amazonListing: GetAmazonListingQuery['amazonListing']
}

export const EditAmazonListingImagesModal = ({
  amazonListing,
}: EditAmazonListingImagesModalProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [images, setImages] = useState<{ file: UploadFile; label: string }[]>()
  const [validImages, setValidImages] = useState<(string | null)[]>(
    Object.values(amazonListing.images ?? []).filter((image) => image !== 'AmazonListingImages')
  )
  const [pendingChangeRequest, setPendingChangeRequest] = useState(
    amazonListing.changeRequests?.find(
      (changeRequest) =>
        changeRequest?.type === AmazonListingChangeRequestType.IMAGES &&
        changeRequest?.status === AmazonListingChangeRequestStatus.PENDING
    )
  )

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const cognitoUser = useGlobalStore((state) => state.cognitoUser)

  const { t } = useTranslation(['inventory', 'translation'])

  const [requestAmazonListingChange] = useMutation(RequestAmazonListingChangeDocument)
  const [sendSupportTicketRequest] = useMutation(SendSupportTicketDocument)

  useEffect(() => {
    if (amazonListing.images) {
      const images = Object.values(amazonListing.images)
        .filter((image) => image !== 'AmazonListingImages')
        .map((image, index) => {
          if (image) {
            return {
              file: {
                uid: `-${index}`,
                name: image,
                status: 'done',
                url: image,
              } as UploadFile,
              label: index === 0 ? 'mainImage' : `imagePosition${index}`,
            }
          }
        })
        .filter((image) => image?.file) as { file: UploadFile; label: string }[]

      setImages(images)
    }
  }, [amazonListing])

  const handleOnFinish = async () => {
    if (!selectedCompany?.uuid) {
      return
    }

    if (images) {
      setLoading(true)
      const newImagesArray = await Promise.all(
        images?.map(async (image: { file: UploadFile; label: string }) => {
          if (image.file.originFileObj) {
            const imageUrl = await uploadImagesAndGetImageUrls(
              { [image.label]: image.file } as AmazonListingImagesUpload,
              selectedCompany?.uuid
            )

            if (imageUrl) {
              return { [image.label]: imageUrl[image.label] }
            }
          } else {
            return { [image.label]: image.file.name }
          }
        })
      )

      const emptyImages = validImages
        .map((image, index) => {
          if (!image) {
            return { [index === 0 ? 'mainImage' : `imagePosition${index}`]: image }
          }
        })
        .filter((image) => image)

      const finalArray = [...newImagesArray, ...emptyImages]

      const newImages = finalArray.reduce((acc, curr) => {
        if (curr && acc) {
          const key = Object.keys(curr)[0]
          acc[key] = curr[key]
        }
        return acc
      }, {})

      try {
        if (!amazonListing.uuid) {
          throw new Error()
        }

        await requestAmazonListingChange({
          variables: {
            amazonListingUuid: amazonListing.uuid,
            input: {
              type: AmazonListingChangeRequestType.IMAGES,
              newImages,
            },
          },
        }).then(({ data }) => {
          if (data) {
            setPendingChangeRequest(data.requestAmazonListingChange)
          }
        })

        const options = createZendeskTicketOptions({
          categoryTitle: TicketMainCategory.LISTINGS,
          comment: `
          <h1>Amazon Listing Images Change Request</h1>
          <p>SKU: ${amazonListing.sku}</p>
          <p><b>Listing title:</b> ${amazonListing.title}</p>         
          <p><b>Marketplace:</b> ${amazonListing.marketplace.name}</p>
          <h3><a href="https://galaxy.spacegoats.io${location}">Open amazon listing details page in Galaxy</a></h3>
          <h3><a href="https://groundcontrol.spacegoats.io/change-requests/images">Open change request in GC</a></h3>

        `,
          language: i18n.language,
          mainCategory: TicketMainCategory.LISTINGS,
          subject: 'Amazon Listing Images Change Request',
          ticketCategory: TicketCategory.LISTINGS_PICTURE_UPDATE,
          sellerId: selectedCompany?.sellerId,
        })

        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })

        toast.success(t('shared.notification.success', { ns: 'translation' }), {
          description: t('product.details.changeRequest.success'),
        })
      } catch (error) {
        console.error(error)
        toast.error(t('shared.notification.error', { ns: 'translation' }), {
          description: t('product.details.changeRequest.error'),
        })
      }

      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <>
      <Tooltip
        title={
          pendingChangeRequest
            ? t('product.details.changeRequest.images.tag.tooltip', {
                date: dayjs(pendingChangeRequest.updated_at).format('DD.MM.YYYY'),
              })
            : null
        }
        placement="bottomRight"
      >
        <Button
          shape="circle"
          size="large"
          icon={<SquarePen size={16} />}
          disabled={
            !!pendingChangeRequest ||
            amazonListing.internalStatus === InternalAmazonListingStatus.REVIEW ||
            amazonListing.internalStatus === InternalAmazonListingStatus.DELETED ||
            amazonListing.externalStatus === ExternalAmazonListingStatus.DETAIL_PAGE_REMOVED
          }
          onClick={() => setOpen(true)}
          style={{ position: 'absolute', bottom: 16, right: 24 }}
        />
      </Tooltip>
      <Modal
        title={t('product.details.changeRequest.images.modal.title')}
        open={open}
        width="100%"
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={handleOnFinish}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        destroyOnClose
        confirmLoading={loading}
        style={{ position: 'absolute', top: 0, left: 0, right: 0, padding: '1rem 0' }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <CurrentAmazonListingImages images={amazonListing.images} />
          <Divider />
          <Alert
            message={t('product.details.changeRequest.images.modal.content.guidelinesHeader')}
            description={
              <>
                <Typography.Text>
                  {t('product.details.changeRequest.images.modal.content.guidelines1')}
                  <br />
                  {t('product.details.changeRequest.images.modal.content.guidelines2')}
                  <br />
                  {t('product.details.changeRequest.images.modal.content.guidelines3')}
                </Typography.Text>
                <ul style={{ margin: 0 }}>
                  <li>{t('product.details.changeRequest.images.modal.content.guidelines4')}</li>
                  <li>{t('product.details.changeRequest.images.modal.content.guidelines5')}</li>
                  <li>{t('product.details.changeRequest.images.modal.content.guidelines6')}</li>
                  <li>{t('product.details.changeRequest.images.modal.content.guidelines7')}</li>
                  <li>{t('product.details.changeRequest.images.modal.content.guidelines8')}</li>
                </ul>
              </>
            }
            closable
          />
          <AmazonListingImagesManagement
            validImages={validImages}
            setValidImages={setValidImages}
            images={images}
            setImages={setImages}
          />
          <Alert type="warning" message={t('product.details.changeRequest.warning')} />
        </Space>
      </Modal>
    </>
  )
}
