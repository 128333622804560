import {
  AmazonInboundShipmentPlansQuery,
  AmazonInboundShipmentStatus,
  InternalShipmentPlanStatus,
  Marketplace,
  PageType,
  ShipmentType,
  ShippingLabelType,
} from '../../../../generated/graphql'
import { AmazonInboundShipmentPlan as AmazonInboundShipmentPlanDetails } from '../AmazonInboundShipmentPlanView'
import { AmazonInboundShipmentPlan } from '../AmazonInboundShipmentPlansView'
import { ShipmentKind } from '../types'

export type ShipmentPlanWithLabelData = {
  uuid: string
  note?: string | null
  marketplace: Marketplace
  internalStatus: InternalShipmentPlanStatus
  isPartnered: boolean
  shipmentType: ShipmentType
  shipmentKind: ShipmentKind
  amazonInboundShipmentId: string
  status: AmazonInboundShipmentStatus
  createdAt: string
  updatedAt: string
  deliveryCost?: number | null
  cartons: AmazonInboundShipmentPlan['cartons'] | AmazonInboundShipmentPlanDetails['cartons']
  noOfSkus: number
  internalSkus: string[]
  skus: string[]
  areCasesRequired: boolean
  totalReceived: number
  totalShipped: number
  pageType: PageType
  labelType: ShippingLabelType
}

export const prepareAmazonInboundShipmentPlanDetailedTableData = (
  amazonInboundShipmentPlans: AmazonInboundShipmentPlansQuery['amazonInboundShipmentPlans']
): ShipmentPlanWithLabelData[] => {
  return amazonInboundShipmentPlans.flatMap((amazonInboundShipmentPlan) =>
    amazonInboundShipmentPlan.inboundShipments?.flatMap((inboundShipment) => {
      const cartons = amazonInboundShipmentPlan.cartons
        .filter((carton) =>
          carton.items.some((item) => item.shipmentId === inboundShipment?.shipmentId)
        )
        .filter((carton) => carton.items.length > 0)

      const items = cartons.flatMap((carton) => carton.items)

      const internalSkus = items
        .map((item) => item.amazonListing.amazonProduct.productVariant.internalSku)
        .filter((sku) => sku !== null && sku !== undefined)
      const skus = items.flatMap((item) => item.amazonListing.sku)

      const totalShipped = items.reduce((acc, item) => acc + item.quantityShipped, 0)
      const totalReceived = items.reduce((acc, item) => acc + (item.quantityReceived ?? 0), 0)

      return {
        uuid: amazonInboundShipmentPlan.uuid,
        note: amazonInboundShipmentPlan.note,
        marketplace: amazonInboundShipmentPlan.marketplace,
        isPartnered: amazonInboundShipmentPlan.isPartnered,
        shipmentType: amazonInboundShipmentPlan.shipmentType,
        shipmentKind: amazonInboundShipmentPlan.areCasesRequired
          ? ShipmentKind.CASE
          : ShipmentKind.MIXED,
        amazonInboundShipmentId: inboundShipment?.shipmentId,
        status: inboundShipment?.shipmentStatus,
        createdAt: amazonInboundShipmentPlan.createdAt,
        updatedAt: inboundShipment?.updatedAt,
        deliveryCost: inboundShipment?.deliveryCost,
        cartons,
        internalSkus,
        noOfSkus: skus.length ?? 0,
        skus,
        areCasesRequired: amazonInboundShipmentPlan.areCasesRequired,
        totalReceived,
        totalShipped,
        pageType: amazonInboundShipmentPlan.pageType,
        labelType: amazonInboundShipmentPlan.labelType,
      }
    })
  ) as ShipmentPlanWithLabelData[]
}
