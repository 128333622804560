import { Form } from 'antd'
import { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { WarehouseServiceProviderLocationFieldsFragment } from '../../../../generated/graphql'
import { AmazonInboundShipmentPlanCartonSection } from '../components/AmazonInboundShipmentPlanCartonSection'
import { AmazonInboundShipmentPlanCreateFormInstance } from '../components/AmazonInboundShipmentPlanCreateForm'
import { AmazonInboundShipmentPlanStep } from '../components/AmazonInboundShipmentPlanCreateFormCollapse'
import { AmazonInboundShipmentPlanInventorySection } from '../components/AmazonInboundShipmentPlanInventorySection'
import { AmazonInboundShipmentPlanQuantitySection } from '../components/AmazonInboundShipmentPlanQuantitySection'
import { AmazonInboundShipmentPlanSummarySection } from '../components/AmazonInboundShipmentPlanSummarySection'
import { AmazonInboundShipmentPlanTransportSection } from '../components/AmazonInboundShipmentPlanTransportSection'

export const useCollapseItems = ({
  locations,
  setCurrentStep,
  submitting,
}: {
  locations: WarehouseServiceProviderLocationFieldsFragment[]
  setCurrentStep: (currentStep: SetStateAction<AmazonInboundShipmentPlanStep>) => void
  submitting: boolean
}) => {
  const { t } = useTranslation('inventory')

  const items = [
    {
      key: AmazonInboundShipmentPlanStep.INVENTORY,
      label: t('amazonInboundShipmentPlan.create.form.inventory.heading'),
      showArrow: false,
      children: (
        <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
          <AmazonInboundShipmentPlanInventorySection
            locations={locations}
            setCurrentStep={setCurrentStep}
          />
        </Form.Item>
      ),
    },
    {
      key: AmazonInboundShipmentPlanStep.QUANTITY,
      label: t('amazonInboundShipmentPlan.create.form.quantity.heading'),
      showArrow: false,
      children: (
        <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
          <AmazonInboundShipmentPlanQuantitySection setCurrentStep={setCurrentStep} />
        </Form.Item>
      ),
    },
    {
      key: AmazonInboundShipmentPlanStep.CARTON,
      label: t('amazonInboundShipmentPlan.create.form.carton.heading'),
      showArrow: false,
      children: (
        <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
          <AmazonInboundShipmentPlanCartonSection setCurrentStep={setCurrentStep} />
        </Form.Item>
      ),
    },
    {
      key: AmazonInboundShipmentPlanStep.TRANSPORT,
      label: t('amazonInboundShipmentPlan.create.form.transport.heading'),
      showArrow: false,
      children: (
        <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
          <AmazonInboundShipmentPlanTransportSection setCurrentStep={setCurrentStep} />
        </Form.Item>
      ),
    },
    {
      key: AmazonInboundShipmentPlanStep.SUMMARY,
      label: t('amazonInboundShipmentPlan.create.form.summary.heading'),
      showArrow: false,
      children: (
        <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
          <AmazonInboundShipmentPlanSummarySection
            locations={locations}
            setCurrentStep={setCurrentStep}
            submitting={submitting}
          />
        </Form.Item>
      ),
    },
  ]

  return items
}
