import { useTheme } from '@emotion/react'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import { Navigate, Outlet } from 'react-router'
import SpacegoatsLogo from '../assets/icons/spacegoats-logo.svg?react'
import { useAuthentication } from '../hooks/useAuthentication'
import {
  FooterContainer,
  FooterItem,
  HeaderContainer,
  MainContainer,
  StyledPublicLayout,
} from './PublicLayout.style'

export const PublicLayout = () => {
  const { loading, user } = useAuthentication()

  const theme = useTheme()

  if (loading) {
    return null
  }

  if (user) {
    return <Navigate to="/" replace />
  } else {
    return (
      <StyledPublicLayout>
        <HeaderContainer>
          <SpacegoatsLogo width="240" height="240" color={theme.colors.primary} />
        </HeaderContainer>
        <MainContainer>
          <Outlet />
        </MainContainer>
        <FooterContainer>
          <FooterItem>
            <Typography.Link
              href="https://spacegoats.io/privacy-statement"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Typography.Link>
          </FooterItem>
          <FooterItem>
            <Typography.Link
              href="https://spacegoats.io/legal-notice"
              target="_blank"
              rel="noopener noreferrer"
            >
              Legal Notice
            </Typography.Link>
          </FooterItem>
          <FooterItem>
            <Typography.Text>&#169; SPACEGOATS {dayjs().year()}</Typography.Text>
          </FooterItem>
        </FooterContainer>
      </StyledPublicLayout>
    )
  }
}
