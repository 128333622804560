import { Reference, useMutation } from '@apollo/client'
import { Flex, Tag, Typography } from 'antd'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  S3DocumentListCard,
  S3DocumentListCardPropsData,
} from '../../../../components/S3/S3DocumentListCard'
import {
  AmazonListingDocumentType,
  DeleteAmazonListingDocumentDocument,
  GetAmazonListingQuery,
} from '../../../../generated/graphql'
import { AmazonListingDocumentUploadModal } from './AmazonListingDocumentUploadModal'

const bucketName = import.meta.env.VITE_AWS_S3_BUCKET_NAME_AMAZON_LISTING_DOCUMENTS

type AmazonListing = NonNullable<GetAmazonListingQuery['amazonListing']>

type AmazonListingDocumentsCardProps = {
  amazonListing: AmazonListing
  loading: boolean
}

export const AmazonListingDocumentsCard = ({
  amazonListing,
  loading,
}: AmazonListingDocumentsCardProps) => {
  const { t } = useTranslation('inventory')

  const [deleteAmazonListingDocument] = useMutation(DeleteAmazonListingDocumentDocument)

  const handleDeleteDocument = async (bucketKey: string) => {
    try {
      await Storage.remove(bucketKey, {
        bucket: bucketName,
      })

      await deleteAmazonListingDocument({
        variables: {
          amazonListingUuid: amazonListing.uuid,
          key: bucketKey,
        },
        update: (cache, { data }) => {
          const deleteAmazonListingDocument = data?.deleteAmazonListingDocument
          if (deleteAmazonListingDocument) {
            cache.modify({
              id: cache.identify({
                __typename: 'AmazonListing',
                uuid: amazonListing.uuid,
              }),
              fields: {
                documents(existingDocumentsRefs, { readField }) {
                  return existingDocumentsRefs.filter(
                    (documentRef: Reference) =>
                      deleteAmazonListingDocument.key !== readField('key', documentRef)
                  )
                },
              },
            })
          }
        },
      })

      toast.success(t('product.details.documents.delete.success'))
    } catch (error) {
      toast.error(t('product.details.documents.delete.error'))
      console.error(error)
    }
  }

  const documents: S3DocumentListCardPropsData =
    amazonListing?.documents?.map((document) => ({
      key: document.key,
      description: (
        <Flex justify={'space-between'}>
          <Tag>
            {getAmazonListingDocumentTypeLabel(document.type as AmazonListingDocumentType, t)}
          </Tag>
          <Typography.Text type={'secondary'}>
            {dayjs(document.createdAt).format('DD.MM.YYYY')}
          </Typography.Text>
        </Flex>
      ),
    })) || []

  return (
    <S3DocumentListCard
      bucketName={bucketName}
      title={t('product.details.documents.title')}
      rightHeaderContent={
        amazonListing && <AmazonListingDocumentUploadModal amazonListing={amazonListing} />
      }
      data={documents}
      loading={loading}
      showPreview
      showViewButton
      showDownloadButton
      deleteFunction={handleDeleteDocument}
    />
  )
}

function getAmazonListingDocumentTypeLabel(
  type: AmazonListingDocumentType,
  t: TFunction<['inventory']>
) {
  switch (type) {
    case AmazonListingDocumentType.FLAT_FILE:
      return t('product.details.documents.type.flatFile')
    case AmazonListingDocumentType.OTHER:
      return t('product.details.documents.type.other')
  }
}
