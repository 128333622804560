import { ColDef, ColGroupDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BankTransaction } from '../../BankTransactionsView'
import { bankTransactionTableCellRenderers } from '../table/bankTransactionTableCellRenderers'
import { bankTransactionTableValueGetters } from '../table/bankTransactionTableValueGetters'

export enum BankTransactionEntities {
  CUSTOMER = 'Customer (SPACEGOATS)',
}

export enum BankTransactionPaymentStatuses {
  OPEN = 'Open',
  PAID = 'Paid',
  PART2 = 'Open Part 2',
  CLARIFICATION = 'In Clarification',
  CANCELLED = 'Cancelled',
}

export enum BankTransactionCategories {
  NONE = 'None',
  SETTLEMENT = 'Settlement',
  SETTLEMENT_TRANSACTION = 'Settlement Transaction',
}

export const useBankTransactionTableColumns = (): (
  | ColDef<BankTransaction>
  | ColGroupDef<BankTransaction>
)[] => {
  const { t } = useTranslation(['billing'], { keyPrefix: 'bankTransactions.table' })

  return useMemo(() => {
    return [
      {
        field: 'sender',
        headerName: t('header.sender'),
        valueGetter: (params: ValueGetterParams<BankTransaction, BankTransaction['sender']>) =>
          bankTransactionTableValueGetters.sender(params, t),
      },
      {
        field: 'recipient',
        headerName: t('header.recipient'),
        valueGetter: (params: ValueGetterParams<BankTransaction, BankTransaction['recipient']>) =>
          bankTransactionTableValueGetters.recipient(params, t),
      },
      {
        field: 'sellerId',
        headerName: 'Seller ID',
        filter: false,
        sortable: false,
      },
      {
        field: 'documentId',
        headerName: t('header.documentId'),
        type: 'textColumn',
      },
      {
        field: 'amount',
        headerName: t('header.amount'),
        type: 'amountColumn',
      },
      {
        field: 'paymentDate',
        headerName: t('header.paymentDate'),
        type: 'dateColumn',
      },
      {
        field: 'paymentStatus',
        headerName: t('header.paymentStatus'),
        valueGetter: (
          params: ValueGetterParams<BankTransaction, BankTransaction['paymentStatus']>
        ) => bankTransactionTableValueGetters.paymentStatus(params, t),
        cellRenderer: (params: ICellRendererParams<BankTransaction>) =>
          bankTransactionTableCellRenderers.paymentStatus(params, t),
      },
      {
        field: 'period',
        headerName: t('header.period'),
        initialSort: 'desc',
      },
      {
        field: 'category',
        headerName: t('header.category'),
        valueGetter: (params: ValueGetterParams<BankTransaction, BankTransaction['category']>) =>
          bankTransactionTableValueGetters.category(params, t),
        cellRenderer: (params: ICellRendererParams<BankTransaction>) =>
          bankTransactionTableCellRenderers.category(params, t),
      },
      {
        field: 'paymentReference',
        headerName: t('header.paymentReference'),
        filter: 'agTextColumnFilter',
      },
    ]
  }, [])
}
