import { useMutation, useQuery } from '@apollo/client'
import { Button, Flex, Input, Modal, Select, Typography } from 'antd'
import { ArrowDown, ArrowLeftRight } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import {
  GetAllAmazonProductsDocument,
  GetAllProductsDocument,
  GetProductDocument,
  GetProductVariantDocument,
  GetProductVariantQuery,
  InternalAmazonListingStatus,
  TransferProductVariantDocument,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'

type TransferProductVariantModalProps = {
  productVariant: GetProductVariantQuery['productVariant']
}

export const TransferProductVariantModal = ({
  productVariant,
}: TransferProductVariantModalProps) => {
  const [open, setOpen] = useState(false)
  const [selectedProductUuid, setSelectedProductUuid] = useState<string | undefined>(undefined)
  const [submitting, setSubmitting] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const navigate = useNavigate()
  const { t } = useTranslation(['inventory'])

  const { loading, data } = useQuery(GetAllAmazonProductsDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    skip: !selectedCompany.uuid,
    variables: {
      uuid: selectedCompany.uuid,
      internalStatuses: [InternalAmazonListingStatus.ACTIVE],
    },
  })

  const [transferProductVariant] = useMutation(TransferProductVariantDocument)

  const products = data?.products.filter((product) => product.uuid !== productVariant.product.uuid)

  const handleOk = async () => {
    setSubmitting(true)

    try {
      if (!selectedProductUuid) {
        return
      }

      await transferProductVariant({
        variables: {
          uuid: productVariant.uuid,
          newProductUuid: selectedProductUuid,
        },
        update: (cache, { data }) => {
          const updatedProductVariant = data?.transferProductVariant

          const cachedOldProductQuery = cache.readQuery({
            query: GetProductDocument,
            variables: { uuid: productVariant.product.uuid },
          })

          if (updatedProductVariant && cachedOldProductQuery) {
            cache.writeQuery({
              query: GetProductDocument,
              variables: { uuid: productVariant.product.uuid },
              data: {
                product: {
                  ...cachedOldProductQuery.product,
                  productVariants: cachedOldProductQuery.product.productVariants?.filter(
                    (productVariant) => productVariant.uuid !== updatedProductVariant.uuid
                  ),
                },
              },
            })
          }

          const cachedNewProductQuery = cache.readQuery({
            query: GetProductDocument,
            variables: { uuid: selectedProductUuid },
          })

          if (updatedProductVariant && cachedNewProductQuery) {
            cache.writeQuery({
              query: GetProductDocument,
              variables: { uuid: selectedProductUuid },
              data: {
                product: {
                  ...cachedNewProductQuery.product,
                  productVariants:
                    cachedNewProductQuery.product.productVariants?.concat(updatedProductVariant),
                },
              },
            })
          }

          const cachedProductVariantQuery = cache.readQuery({
            query: GetProductVariantDocument,
            variables: { uuid: productVariant.uuid },
          })

          if (updatedProductVariant && cachedProductVariantQuery) {
            cache.writeQuery({
              query: GetProductVariantDocument,
              variables: { uuid: productVariant.uuid },
              data: {
                productVariant: {
                  ...cachedProductVariantQuery.productVariant,
                  product: updatedProductVariant.product,
                },
              },
            })
          }
        },
        refetchQueries: [
          {
            query: GetAllProductsDocument,
            variables: {
              uuid: selectedCompany.uuid,
            },
          },
        ],
      })

      setTimeout(() => {
        toast.success(t('product.details.delete.success'))
        navigate(`/products/${selectedProductUuid}`)
      }, 1000)
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setTimeout(() => setSubmitting(false), 1000)
    }
  }

  return (
    <>
      <Button icon={<ArrowLeftRight size={16} />} onClick={() => setOpen(true)}>
        {t('product.details.productVariant.transfer.button')}
      </Button>
      <Modal
        title={t('product.details.productVariant.transfer.modal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={handleOk}
        okButtonProps={{ disabled: !selectedProductUuid }}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={submitting}
      >
        <Typography.Paragraph>
          {t('product.details.productVariant.transfer.modal.description')}
        </Typography.Paragraph>
        <Flex vertical gap={16} align="center">
          <Input defaultValue={productVariant.product.name} disabled />
          <ArrowDown size={16} />
          <Select
            value={selectedProductUuid}
            onChange={(value) => setSelectedProductUuid(value)}
            options={products?.map((product) => ({
              value: product.uuid,
              label: product.name,
            }))}
            placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
            disabled={!products}
            loading={loading}
            style={{ width: '100%' }}
          />
        </Flex>
      </Modal>
    </>
  )
}
