import { GetAllWholesaleOrdersQuery } from '../../../../../generated/graphql'
import { WholesaleOrderRecord } from '../WholesaleOrdersTable'

export function prepareWholesaleOrders(
  wholesaleOrders: GetAllWholesaleOrdersQuery['wholesaleOrders']
): WholesaleOrderRecord[] {
  return wholesaleOrders.map((wholesaleOrder) => {
    const searchIdentifiers = getSearchIdentifiers(wholesaleOrder).join(' ')

    return {
      identifier: wholesaleOrder.identifier,
      vendorCompany: wholesaleOrder.vendorCompany.sellerId,
      retailerCompany: wholesaleOrder.retailerCompany.sellerId,
      shippedFromWarehouseLocation: wholesaleOrder.shippedFromWarehouseLocation?.name,
      shippedToWarehouseLocation: wholesaleOrder.shippedToWarehouseLocation?.name,
      vendorApprovedAt: wholesaleOrder.vendorApprovedAt ?? null,
      retailerApprovedAt: wholesaleOrder.retailerApprovedAt ?? null,
      totalValue: {
        amount: wholesaleOrder.totalValue?.amount ?? 0,
        currency: wholesaleOrder.totalValue?.currency ?? 'EUR',
      },
      searchIdentifiers,
      createdAt: wholesaleOrder.createdAt,
    }
  })
}

function getSearchIdentifiers(
  wholesaleOrder: GetAllWholesaleOrdersQuery['wholesaleOrders'][number]
) {
  const searchIdentifiers = new Set<string>()

  for (const item of wholesaleOrder.lineItems) {
    if (item.identifier) {
      searchIdentifiers.add(item.identifier)
    }

    if (item.productVariant) {
      const { ean, internalSku, amazonProducts } = item.productVariant

      for (const id of [ean, internalSku].filter(Boolean)) {
        searchIdentifiers.add(id)
      }

      if (item.productVariant.product?.tags) {
        for (const tag of item.productVariant.product.tags) {
          searchIdentifiers.add(tag)
        }
      }

      if (amazonProducts) {
        for (const amazonProduct of amazonProducts) {
          if (amazonProduct.asin) {
            searchIdentifiers.add(amazonProduct.asin)
          }

          if (amazonProduct.amazonListings) {
            for (const amazonListing of amazonProduct.amazonListings) {
              if (amazonListing.sku) {
                searchIdentifiers.add(amazonListing.sku)
              }
            }
          }
        }
      }
    }
  }

  return Array.from(searchIdentifiers)
}
