import { useTranslation } from 'react-i18next'
import { AmazonListingFieldsFragment } from '../../../../generated/graphql'
import { StyledMainCard } from '../../../dashboard/styles'
import { AmazonListingReturnTable } from './AmazonListingReturnTable'

type AmazonListingReturnsCardProps = {
  returns: AmazonListingFieldsFragment['returns']
}

export const AmazonListingReturnsCard = ({ returns }: AmazonListingReturnsCardProps) => {
  const { t } = useTranslation('inventory')

  return (
    <StyledMainCard title={t('product.details.returns.heading')}>
      <AmazonListingReturnTable returns={returns} />
    </StyledMainCard>
  )
}
