type CalculateAmazonProfitProps = {
  sales: number
  refunds: number
  fees: number
  promotions: number
  sponsoredProductsCosts: number
  sponsoredDisplayCosts: number
}

export function calculateAmazonProfit({
  sales,
  refunds,
  fees,
  promotions,
  sponsoredProductsCosts,
  sponsoredDisplayCosts,
}: CalculateAmazonProfitProps): number {
  return sales + refunds + fees + promotions - sponsoredProductsCosts - sponsoredDisplayCosts
}
