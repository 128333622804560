import { useMutation } from '@apollo/client'
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { Button, Flex, Popconfirm, Tag, Tooltip, Typography } from 'antd'
import { TFunction } from 'i18next'
import { SquarePen, Trash } from 'lucide-react'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { Table } from '../../../components/Table/Table'
import {
  ProductVariantPackagingUnitType,
  UpdateProductVariantPackagingUnitDocument,
} from '../../../generated/graphql'
import { formatUnit } from '../../../helpers/formatUnit'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { PackagingUnit } from './ProductVariantPackagingUnitCard'

type ProductVariantPackagingUnitTableProps = {
  packagingUnits: PackagingUnit[]
  setSelectedPackagingUnit: Dispatch<SetStateAction<PackagingUnit | null>>
  setPackagingModalOpen: Dispatch<SetStateAction<boolean>>
  loading: boolean
}

export const ProductVariantPackagingUnitTable = ({
  packagingUnits,
  setPackagingModalOpen,
  setSelectedPackagingUnit,
  loading,
}: ProductVariantPackagingUnitTableProps) => {
  const user = useGlobalStore((state) => state.user)!

  const { t } = useTranslation('inventory')

  const [updateProductVariantPackagingUnit] = useMutation(UpdateProductVariantPackagingUnitDocument)

  const handleModalButtonClick = (packagingUnit: PackagingUnit | undefined) => {
    if (packagingUnit) {
      setSelectedPackagingUnit(packagingUnit)
      setPackagingModalOpen(true)
    }
  }

  const handleArchive = async (packagingUnit: PackagingUnit) => {
    try {
      await updateProductVariantPackagingUnit({
        variables: {
          input: {
            uuid: packagingUnit.uuid,
            archivedAt: new Date().toISOString(),
          },
        },
      })
      toast.success(t('shared.notification.success', { ns: 'translation' }))
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    }
  }

  const [columnDefs] = useState<(ColDef<PackagingUnit> | ColGroupDef<PackagingUnit>)[]>([
    {
      lockPosition: 'left',
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      minWidth: 96,
      maxWidth: 96,
      cellRenderer: (params: ICellRendererParams<PackagingUnit>) => {
        if (!params.data) {
          return null
        }

        const areAllValuesSetForQuantityOne =
          params.data.quantity === 1 && Object.values(params.data).every((value) => !!value)

        return (
          <Flex gap={8} align="center">
            <Tooltip
              title={
                !user.isAdmin &&
                areAllValuesSetForQuantityOne &&
                t('product.details.productVariant.packagingUnit.table.edit.tooltip')
              }
            >
              <Button
                onClick={() => handleModalButtonClick(params.data)}
                icon={<SquarePen size={16} />}
                disabled={!user.isAdmin && areAllValuesSetForQuantityOne}
              />
            </Tooltip>
            <Popconfirm
              placement="top"
              title={t('packagingUnit.table.archive.tooltip')}
              onConfirm={() => handleArchive(params.data!)}
              okText={t('packagingUnit.table.archive.confirmButton')}
              cancelText={t('packagingUnit.table.archive.cancelButton')}
              okButtonProps={{ danger: true }}
              cancelButtonProps={{ type: 'text' }}
            >
              <Tooltip title={t('packagingUnit.table.archive.tooltip')}>
                <Button danger icon={<Trash size={16} />} />
              </Tooltip>
            </Popconfirm>
          </Flex>
        )
      },
    },
    {
      field: 'gtin',
      headerName: t('product.details.productVariant.packagingUnit.table.gtin'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'gtinType',
      headerName: t('product.details.productVariant.packagingUnit.table.gtinType'),
    },
    {
      field: 'type',
      headerName: t('product.details.productVariant.packagingUnit.table.type.header'),
      filterValueGetter: (params: ValueGetterParams<PackagingUnit, PackagingUnit['type']>) => {
        if (!params.data?.type) {
          return null
        }

        return getProductVariantPackagingUnitTypeText(params.data.type, t)
      },
      valueFormatter: ({ value }: ValueFormatterParams<PackagingUnit, PackagingUnit['type']>) => {
        if (!value) {
          return ''
        }

        return getProductVariantPackagingUnitTypeText(value, t)
      },
      cellRenderer: (params: ICellRendererParams<PackagingUnit, PackagingUnit['type']>) => {
        if (!params.value) {
          return null
        }

        return (
          <Tag color={getProductVariantPackagingUnitTypeColor(params.value)}>
            {params.valueFormatted}
          </Tag>
        )
      },
    },
    {
      field: 'internalSku',
      headerName: t('product.details.productVariant.packagingUnit.table.internalSku'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'quantity',
      headerName: t('product.details.productVariant.packagingUnit.table.quantity'),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'lengthInCm',
      headerName: t('product.details.productVariant.packagingUnit.table.lengthInCm'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams<PackagingUnit, PackagingUnit['lengthInCm']>) => {
        if (!params.value) {
          return null
        }

        return <Typography.Text>{formatUnit(params.value, 'centimeter')}</Typography.Text>
      },
    },
    {
      field: 'widthInCm',
      headerName: t('product.details.productVariant.packagingUnit.table.widthInCm'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams<PackagingUnit, PackagingUnit['widthInCm']>) => {
        if (!params.value) {
          return null
        }

        return <Typography.Text>{formatUnit(params.value, 'centimeter')}</Typography.Text>
      },
    },
    {
      field: 'heightInCm',
      headerName: t('product.details.productVariant.packagingUnit.table.heightInCm'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams<PackagingUnit, PackagingUnit['heightInCm']>) => {
        if (!params.value) {
          return null
        }

        return <Typography.Text>{formatUnit(params.value, 'centimeter')}</Typography.Text>
      },
    },
    {
      field: 'weightInGram',
      headerName: t('product.details.productVariant.packagingUnit.table.weightInGram'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (params: ICellRendererParams<PackagingUnit, PackagingUnit['weightInGram']>) => {
        if (!params.value) {
          return null
        }

        return <Typography.Text>{formatUnit(params.value, 'gram')}</Typography.Text>
      },
    },
  ])

  return <Table<PackagingUnit> columnDefs={columnDefs} loading={loading} rowData={packagingUnits} />
}

export function getProductVariantPackagingUnitTypeText(
  type: ProductVariantPackagingUnitType,
  t: TFunction<'inventory'>
): string {
  switch (type) {
    case ProductVariantPackagingUnitType.SMALL_PARCEL:
      return t('product.details.productVariant.packagingUnit.table.type.smallParcel')
    case ProductVariantPackagingUnitType.PALLET:
      return t('product.details.productVariant.packagingUnit.table.type.pallet')
    default:
      return t('product.details.productVariant.packagingUnit.table.type.unknown')
  }
}

export function getProductVariantPackagingUnitTypeColor(
  type: ProductVariantPackagingUnitType
): string {
  switch (type) {
    case ProductVariantPackagingUnitType.SMALL_PARCEL:
      return 'green'
    case ProductVariantPackagingUnitType.PALLET:
      return 'blue'
    default:
      return 'default'
  }
}
