import { useMutation } from '@apollo/client'
import { Button, Form, Input, Modal } from 'antd'
import { Pen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { UpdateWholesaleOrderTemplateNameDocument } from '../../../../generated/graphql'

type EditWholesaleTemplateNameFormInstance = {
  name: string
}

type EditWholesaleTemplateNameModalProps = {
  identifier: string
  name: string
}

export const EditWholesaleTemplateNameModal = ({
  identifier,
  name,
}: EditWholesaleTemplateNameModalProps) => {
  const [open, setOpen] = useState(false)

  const { t } = useTranslation('wholesale-order')
  const [form] = Form.useForm<EditWholesaleTemplateNameFormInstance>()

  const [updateWholesaleOrderTemplateName, { loading }] = useMutation(
    UpdateWholesaleOrderTemplateNameDocument
  )

  const handleSubmit = async (values: EditWholesaleTemplateNameFormInstance) => {
    try {
      await updateWholesaleOrderTemplateName({
        variables: {
          identifier,
          name: values.name.trim(),
        },
        refetchQueries: ['GetWholesaleOrderTemplate'],
      })

      toast.success(t('wholesaleOrderGeneralDetailsCard.editNameModal.success'))
      setOpen(false)
    } catch (error) {
      toast.error(t('shared.error.message', { ns: 'translation' }))
    }
  }

  return (
    <>
      <Button type="text" icon={<Pen size={16} />} size="small" onClick={() => setOpen(true)} />
      <Modal
        title={t('wholesaleOrderGeneralDetailsCard.editNameModal.title')}
        open={open}
        onOk={() => form.submit()}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form<EditWholesaleTemplateNameFormInstance>
          form={form}
          initialValues={{ name }}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item name="name" rules={[{ required: true, type: 'string', whitespace: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
