import { useMutation } from '@apollo/client'
import { Button, Form, Row, Switch, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { toast } from 'sonner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import QuillTextarea from '../../../components/Ticket/QuillTextarea'
import { UploadZendeskFile } from '../../../components/Ticket/UploadFile'
import { Marketplace, SendSupportTicketDocument } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { EbookMailingDynamicInput } from '../components/form/EbookMailingDynamicInput'
import { PersonalNoteInput } from '../components/form/PersonalNoteInput'
import { createZendeskTicketOptions } from '../helpers/Zendesk.helper'
import { TicketCreationCard } from '../styles/TicketCreateViewStyles'
import { TicketCategory, TicketMainCategory } from '../ticket.enum'
import { AttachmentProps } from '../ticket.interface'

type FormInstance = {
  formDetails: DealDetail[]
  messageInput: string
  confirmation_switch: boolean
  personalNotes: string
}
type DealDetail = {
  sku: string
  marketplaces: Marketplace[]
  customHtmlTemplate?: string
  customerType: string
  excludeNegativeOrders: string
  excludeRefundedOrders: string
  standardOrCustomTemplate: string
  emailSendOption: string
}

export const EbookMailingOrEvaluationRequestTicketView = () => {
  const [confirmationSwitchValue, setConfirmationSwitchValue] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const location = useLocation()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()

  const { t, ready, i18n } = useTranslation(['ticket'], {
    keyPrefix: 'thirdPartyServices.ebookMailingOrEvaluationRequestTicketView',
  })
  const { t: generalTranslation } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [form] = Form.useForm()

  const [sendSupportTicketRequest, { loading }] = useMutation(SendSupportTicketDocument)

  if (!ready) {
    return <LoadingSpinner />
  }

  const initialValues: FormInstance = {
    confirmation_switch: false,
    messageInput: '',
    personalNotes: '',
    formDetails: [
      {
        sku: '',
        marketplaces: [],
        customerType: '',
        excludeNegativeOrders: '',
        excludeRefundedOrders: '',
        standardOrCustomTemplate: '',
        emailSendOption: '',
      },
    ],
  }

  const onFinish = async (values: FormInstance) => {
    const tokens = attachments.map((attachment) => {
      return attachment.token
    })

    const createStandardOrCustomTemplateSection = (dealDetail: DealDetail) => {
      if (
        dealDetail.standardOrCustomTemplate === 'Standard Template' ||
        dealDetail.standardOrCustomTemplate === 'Amazon-Template'
      ) {
        return ''
      } else if (
        dealDetail.standardOrCustomTemplate === 'Custom Template' ||
        dealDetail.standardOrCustomTemplate === 'Eigener Text'
      ) {
        return `
          <p><b>${t('form.customHtmlTemplate.label')}</b> ${dealDetail.customHtmlTemplate}</p>
        `
      }
    }

    const createCommentSection = (messageInput: string | undefined) => {
      if (messageInput) {
        return `
          <div>
            <h3>${t('form.comments.label')}</h3>
            <p>${messageInput}</p>
          </div>
        `
      } else {
        return ''
      }
    }

    const createDealListSection = (dealDetails: DealDetail[]) => {
      if (dealDetails.length === 0) {
        return []
      }

      return dealDetails.map((dealDetail) => {
        return `
          <li>
            <p><b>SKU:</b></p>
            <p>${dealDetail.sku}</p>
            <p><b>${t('form.customerTypeSelector.label')}</b></p>
            <p>${dealDetail.customerType}</p>
            <p><b>${t('form.excludeNegativeOrders.label')}</b></p>
            <p>${dealDetail.excludeNegativeOrders}</p>
            <p><b>${t('form.excludeRefundedOrders.label')}</b></p>
            <p>${dealDetail.excludeRefundedOrders}</p>
            <p><b>${t('form.marketplacesInput.label')}</b></p>
            <p>${dealDetail.marketplaces.toString().replaceAll(',', ', ')}</p>
            <p><b>${t('form.standardOrCustomTemplate.label')}</b></p>
            <p>${dealDetail.standardOrCustomTemplate}</p>
            ${createStandardOrCustomTemplateSection(dealDetail)}
            <p><b>${t('form.emailSendOption.label')}</b></p>
            <p>${dealDetail.emailSendOption}</p>
            &nbsp;
          </li>
        `
      })
    }

    const message = `
      <h1>${t('ticketDescriptionTitle')}</h1>
      <ul style={{listStyleType: 'none'}}>${createDealListSection(values.formDetails).join('')}</ul>
      ${createCommentSection(values.messageInput)}
    `

    const options = createZendeskTicketOptions({
      categoryTitle: location.state.categoryTitleTag,
      comment: message,
      language: i18n.language,
      mainCategory: TicketMainCategory.THIRD_PARTY_SERVICES,
      subject: t('viewTitle'),
      ticketCategory: TicketCategory.THIRD_PARTY_SERVICES_EBOOK,
      sellerId: selectedCompany?.sellerId,
      personalNotes: values.personalNotes,
      attachments: tokens,
    })

    try {
      if (selectedCompany !== null && selectedCompany.uuid) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })
        toast.success(generalTranslation('notification.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      console.error(error)
      toast.error(generalTranslation('notification.error'), { description: error.message })
    }
  }

  return (
    <>
      <SubHeader withBackButton />
      <ViewContainer centered>
        <TicketCreationCard title={t('ticketDescriptionTitle')}>
          <Typography.Paragraph>{t('ticketDescriptionSubtitle')}</Typography.Paragraph>
          <Form
            name="lightning-deals-ticket"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            layout="vertical"
          >
            <EbookMailingDynamicInput />
            <Form.Item name="messageInput" label={t('form.comments.label')}>
              <QuillTextarea placeholder={t('form.comments.placeholder')} />
            </Form.Item>
            <PersonalNoteInput />
            <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
            <Form.Item
              name="confirmation_switch"
              label={t('confirmationText')}
              valuePropName="checked"
            >
              <Switch onChange={setConfirmationSwitchValue} />
            </Form.Item>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!confirmationSwitchValue}
                loading={loading}
              >
                {t('sendButtonText')}
              </Button>
            </Row>
          </Form>
        </TicketCreationCard>
      </ViewContainer>
    </>
  )
}
