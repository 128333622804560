import { useMutation } from '@apollo/client'
import { Button, Form, Input, Modal, Upload, UploadFile, UploadProps } from 'antd'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { UploadIcon } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { CreateAmazonInboundShipmentPlanDocumentDocument } from '../../../../generated/graphql'
import { AmazonInboundShipmentPlan } from '../AmazonInboundShipmentPlanView'

type AmazonInboundShipmentPlanDocumentFormInstance = {
  file: UploadFile
  filename: string
}

type AmazonInboundShipmentPlanDocumentUploadModalProps = {
  amazonInboundShipmentPlan: AmazonInboundShipmentPlan
}

export const AmazonInboundShipmentPlanDocumentUploadModal = ({
  amazonInboundShipmentPlan,
}: AmazonInboundShipmentPlanDocumentUploadModalProps) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation(['inventory'])
  const [form] = Form.useForm<AmazonInboundShipmentPlanDocumentFormInstance>()

  const [createAmazonInboundShipmentPlanDocument] = useMutation(
    CreateAmazonInboundShipmentPlanDocumentDocument
  )

  const handleFileChange: UploadProps['onChange'] = (info) => {
    const file = info.file.originFileObj
    const filename = file?.name.replace(/\.[^/.]+$/, '') ?? ''
    form.setFieldsValue({ filename })
  }

  const handleSubmit = async (values: AmazonInboundShipmentPlanDocumentFormInstance) => {
    setLoading(true)
    try {
      const unixTimestamp = dayjs().unix()
      const fileExtension = values.file.name.split('.').pop()
      const key = `${amazonInboundShipmentPlan?.uuid}_${unixTimestamp}_${values.filename}.${fileExtension}`

      const response = await Storage.put(key, values.file.originFileObj, {
        bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME_SHIPMENT_PLAN_DOCUMENTS,
        contentType: values.file.type,
        level: 'public',
      })

      await createAmazonInboundShipmentPlanDocument({
        variables: {
          amazonInboundShipmentPlanUuid: amazonInboundShipmentPlan.uuid,
          input: {
            key: response.key,
          },
        },
        optimisticResponse: {
          createAmazonInboundShipmentPlanDocument: {
            key: response.key,
            created_at: new Date().toISOString(),
          },
        },
        update: (cache, { data }) => {
          const updatedAmazonInboundShipmentPlan = data?.createAmazonInboundShipmentPlanDocument
          if (updatedAmazonInboundShipmentPlan) {
            cache.modify({
              id: cache.identify({
                __typename: 'AmazonInboundShipmentPlan',
                uuid: amazonInboundShipmentPlan.uuid,
              }),
              fields: {
                documents: (documents = []) => [...documents, updatedAmazonInboundShipmentPlan],
              },
            })
          }
        },
      })

      toast.success(t('amazonInboundShipmentPlan.documents.upload.success'))
    } catch (error: unknown) {
      toast.error(t('amazonInboundShipmentPlan.documents.upload.error'))
      console.error(error)
    } finally {
      setOpen(false)
      setLoading(false)
      form.resetFields()
    }
  }

  return (
    <>
      <Button icon={<UploadIcon size={16} />} onClick={() => setOpen(true)}>
        {t('amazonInboundShipmentPlan.documents.upload.button')}
      </Button>
      <Modal
        title={t('amazonInboundShipmentPlan.documents.upload.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form<AmazonInboundShipmentPlanDocumentFormInstance>
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            name="file"
            label={t('amazonInboundShipmentPlan.documents.upload.file')}
            rules={[{ required: true }]}
            getValueFromEvent={(event) => event.file}
            valuePropName="file"
          >
            <Upload
              accept=".csv,.xlsx,.xls,.pdf,.gsheet"
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  if (onSuccess) {
                    onSuccess('ok')
                  }
                }, 0)
              }}
              maxCount={1}
              onChange={handleFileChange}
              showUploadList={false}
            >
              <Button type="dashed" icon={<UploadIcon size={16} />} loading={loading}>
                {t('amazonInboundShipmentPlan.documents.upload.button')}
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="filename"
            label={t('amazonInboundShipmentPlan.documents.upload.filename')}
            rules={[{ required: true, type: 'string', whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
