import { useQuery } from '@apollo/client'
import { AgGridReact } from 'ag-grid-react'
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Typography,
} from 'antd'
import Select from 'antd/es/select'
import dayjs from 'dayjs'
import { Minus, Plus } from 'lucide-react'
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Location, useLocation, useSearchParams } from 'react-router'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import {
  GetWarehousesAndWholesaleOrderQuery,
  GetWarehousesQuery,
  GetWholesaleOrderProductsDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { StyledSubCard } from '../../../dashboard/styles'
import { StockTableRecord } from '../../../stock/components/StockTable'
import {
  CompanyWithWarehouses,
  prepareCompanyWarehouses,
} from '../WholesaleOrdersTable/helpers/prepareCompanyWarehouses'
import { prepareRowData } from './helpers/prepareRowData'
import { WholesaleOrderFormTable, WholesaleOrderFormTableRecord } from './WholesaleOrderFormTable'

export type WholesaleOrderFormInstance = Partial<{
  shipFromWarehouseLocationUuid: string
  shipToWarehouseLocationUuid: string
  selectedRows: WholesaleOrderFormTableRecord[]
}> &
  WholesaleOrderTemplateFormValues

type WholesaleOrderTemplateFormValues = Partial<{
  name: string
  interval: string
  weekdays: string[]
  frequency: number
  startDate: string
  notificationEmails: string[]
}>

type WholesaleOrderFormProps = {
  form: FormInstance<WholesaleOrderFormInstance>
  submitForm: (values: WholesaleOrderFormInstance) => void
  gridRef: MutableRefObject<AgGridReact<WholesaleOrderFormTableRecord> | null>
  company: GetWarehousesQuery['company']
  rowData: WholesaleOrderFormTableRecord[]
  setRowData: Dispatch<SetStateAction<WholesaleOrderFormTableRecord[]>>
  wholesaleOrder?: GetWarehousesAndWholesaleOrderQuery['wholesaleOrder']
}

export const WholesaleOrderForm = ({
  form,
  submitForm,
  gridRef,
  company,
  rowData,
  setRowData,
  wholesaleOrder,
}: WholesaleOrderFormProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const isTemplate = searchParams.get('template') === 'true'

  const location = useLocation() as Location<
    | {
        shipToWarehouseLocationUuid: string
        selectedRows: StockTableRecord[]
      }
    | undefined
  >

  const incomingStock = location.state?.selectedRows ?? []

  const shipFromWarehouseLocationUuid: WholesaleOrderFormInstance['shipFromWarehouseLocationUuid'] =
    form.getFieldValue('shipFromWarehouseLocationUuid')
  const shipToWarehouseLocationUuid: WholesaleOrderFormInstance['shipToWarehouseLocationUuid'] =
    form.getFieldValue('shipToWarehouseLocationUuid')

  const shipFromWarehouses = company.warehouses ?? []
  const allowedWarehouseLocations = company.allowedWarehouseLocations ?? []
  const shipToCompanies = prepareCompanyWarehouses(allowedWarehouseLocations)

  const { loading, error, data } = useQuery(GetWholesaleOrderProductsDocument, {
    skip: !shipToWarehouseLocationUuid,
    variables: {
      companyUuid: selectedCompany.uuid,
      shipToLocationUuid: shipToWarehouseLocationUuid!,
    },
  })

  const products = useMemo(() => data?.products ?? [], [data?.products])

  const preparedRowData = useMemo(() => {
    if (!shipToWarehouseLocationUuid) {
      return []
    }

    const baseParams = {
      shipToWarehouseLocationUuid,
      allowedWarehouseLocations,
      products,
    }

    return wholesaleOrder
      ? prepareRowData({
          ...baseParams,
          wholesaleOrder,
        })
      : prepareRowData({
          ...baseParams,
          incomingStock,
        })
  }, [shipToWarehouseLocationUuid, allowedWarehouseLocations, products])

  useEffect(() => {
    if (!preparedRowData) {
      return
    }

    setRowData(preparedRowData)
  }, [preparedRowData])

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const handleFinish = async () => {
    try {
      const validatedFormValues = await form.validateFields()

      const selectedRows = gridRef.current
        ? (gridRef.current.api.getSelectedRows() as WholesaleOrderFormTableRecord[])
        : []

      submitForm({ ...validatedFormValues, selectedRows })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Form<WholesaleOrderFormInstance>
        form={form}
        initialValues={{
          shipFromWarehouseLocationUuid: wholesaleOrder?.shippedFromWarehouseLocation.uuid,
          shipToWarehouseLocationUuid: wholesaleOrder?.shippedToWarehouseLocation.uuid,
          frequency: isTemplate ? 1 : undefined,
        }}
        layout="vertical"
        onFinish={handleFinish}
      >
        <Row gutter={[16, 16]}>
          <WarehouseLocationFormFields
            shipFromWarehouseLocationUuid={shipFromWarehouseLocationUuid}
            shipFromWarehouses={shipFromWarehouses}
            shipToCompanies={shipToCompanies}
            loading={loading}
          />
          {!loading && rowData.length ? (
            <Col xs={24}>
              <WholesaleOrderFormTable
                gridRef={gridRef}
                rowData={rowData}
                setRowData={setRowData}
                wholesaleOrder={wholesaleOrder}
              />
            </Col>
          ) : null}
          {isTemplate && (
            <Col xs={24} xl={12}>
              <WholesaleOrderTemplateFormCard />
            </Col>
          )}
        </Row>
      </Form>
      {loading && <LoadingSpinner />}
    </>
  )
}

const WarehouseLocationFormFields = ({
  shipFromWarehouseLocationUuid,
  shipFromWarehouses,
  shipToCompanies,
  loading,
}: {
  shipFromWarehouseLocationUuid: string | undefined
  shipFromWarehouses: NonNullable<GetWarehousesQuery['company']['warehouses']>
  shipToCompanies: CompanyWithWarehouses[]
  loading: boolean
}) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('wholesale-order')
  const location = useLocation()
  const isEdit = location.pathname.endsWith('/edit')

  const warehousesWithValidLocations = shipFromWarehouses.filter(Boolean).flatMap((warehouse) => {
    if (!warehouse.locations) {
      return []
    }

    const validLocations = warehouse.locations.filter((location) => {
      return location.active || location.uuid === shipFromWarehouseLocationUuid
    })

    if (validLocations.length === 0) {
      return []
    }

    return {
      ...warehouse,
      locations: validLocations,
    }
  })

  // Filter out the selected company from the list of companies to ship to.
  const companyWarehouses =
    shipToCompanies.filter((company) => {
      return company.sellerId !== selectedCompany.sellerId
    }) ?? []

  return (
    <>
      <Col xs={24} md={12}>
        <Form.Item<WholesaleOrderFormInstance>
          name="shipFromWarehouseLocationUuid"
          label={t('shipFromWarehouseLocation.form.label')}
          required
        >
          {warehousesWithValidLocations.length ? (
            <Select<string>
              options={warehousesWithValidLocations.map((warehouse) => {
                return {
                  label: warehouse.name,
                  options: warehouse.locations.map((location) => ({
                    value: location.uuid,
                    label: `${location.name}, ${location.addressLine1}, ${location.postalCode}, ${location.city}, ${location.countryCode}`,
                  })),
                }
              })}
              placeholder={t('shipFromWarehouseLocation.form.placeholder')}
              disabled={loading}
              showSearch
              filterOption={true}
              optionFilterProp="label"
            />
          ) : (
            <Link to="/settings/warehousing">{t('shipFromWarehouseLocation.form.link')}</Link>
          )}
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item<WholesaleOrderFormInstance>
          name="shipToWarehouseLocationUuid"
          label={t('shipToWarehouseLocation.form.label')}
          required
        >
          <Select<string>
            options={companyWarehouses.map((company) => ({
              label: `${company.sellerId} - ${company.name}`,
              options: company.warehouses.map((warehouse) => {
                const location = warehouse.location

                return {
                  value: warehouse.location.uuid,
                  label: `${location.name}, ${location.addressLine1}, ${location.postalCode}, ${location.city}, ${location.countryCode}`,
                }
              }),
            }))}
            placeholder={t('shipToWarehouseLocation.form.placeholder')}
            showSearch
            filterOption={true}
            optionFilterProp="label"
            disabled={isEdit || loading}
          />
        </Form.Item>
      </Col>
    </>
  )
}

const WholesaleOrderTemplateFormCard = () => {
  const { t } = useTranslation('wholesale-order')

  const today = dayjs()

  return (
    <StyledSubCard>
      <Form.Item<WholesaleOrderFormInstance>
        name="name"
        label={t('wholesaleOrderTemplateCreateForm.name.label')}
        rules={[
          {
            required: true,
            message: t('wholesaleOrderTemplateCreateForm.name.required'),
          },
        ]}
      >
        <Input placeholder={t('wholesaleOrderTemplateCreateForm.name.placeholder')} />
      </Form.Item>
      <Flex gap={16}>
        <Form.Item<WholesaleOrderFormInstance>
          name="startDate"
          label={t('wholesaleOrderTemplateCreateForm.startDate.label')}
          style={{ width: '100%' }}
          rules={[
            {
              required: true,
              message: t('wholesaleOrderTemplateCreateForm.startDate.required'),
            },
          ]}
        >
          <DatePicker minDate={today} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item<WholesaleOrderFormInstance> name="frequency" label="Interval">
          <InputNumber
            min={1}
            addonBefore={t('wholesaleOrderTemplateCreateForm.repeat')}
            addonAfter={t('wholesaleOrderTemplateCreateForm.repeatOptions.day')}
          />
        </Form.Item>
      </Flex>
      <Form.List name="notificationEmails">
        {(fields, { add, remove }) => (
          <Flex vertical gap={8}>
            <Typography.Text>
              {t('wholesaleOrderTemplateCreateForm.notificationEmails.label')}
            </Typography.Text>
            {fields.map((field) => (
              <Flex gap={8} key={field.key}>
                <Form.Item<WholesaleOrderFormInstance['notificationEmails']>
                  name={field.name}
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      whitespace: true,
                      message: t('wholesaleOrderTemplateCreateForm.notificationEmails.required'),
                    },
                  ]}
                  validateTrigger={['onChange', 'onBlur']}
                  noStyle
                >
                  <Input
                    placeholder={t(
                      'wholesaleOrderTemplateCreateForm.notificationEmails.placeholder'
                    )}
                  />
                </Form.Item>
                <Button onClick={() => remove(field.name)}>
                  <Minus size={16} />
                </Button>
              </Flex>
            ))}
            <Form.Item<WholesaleOrderFormInstance>>
              <Button type="dashed" icon={<Plus size={16} />} onClick={() => add()}>
                {t('wholesaleOrderTemplateCreateForm.notificationEmails.addButton')}
              </Button>
            </Form.Item>
          </Flex>
        )}
      </Form.List>
    </StyledSubCard>
  )
}
