import { getFilenameFromKey } from './getFilenameFromKey'

export const downloadFile = async (fileUrl: string, filename?: string) => {
  try {
    const response = await fetch(fileUrl)
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename || getFilenameFromKey(fileUrl)

    document.body.appendChild(a)
    a.click()

    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}
