import { Flex, FlexProps, Typography } from 'antd'
import dayjs from 'dayjs'

const flexProps: Record<FormattedDateLayout, Partial<FlexProps>> = {
  horizontal: {
    align: 'center',
    gap: 8,
  },
  vertical: {
    vertical: true,
    gap: 0,
  },
}

type FormattedDateLayout = 'horizontal' | 'vertical'

type FormattedDateProps = {
  date: string | number | Date | dayjs.Dayjs | undefined | null
  format?: string
  withRelativeTime?: boolean
  layout?: FormattedDateLayout
}

export const FormattedDate = ({
  date,
  format = 'DD.MM.YYYY HH:mm',
  withRelativeTime = false,
  layout = 'vertical',
}: FormattedDateProps) => {
  if (!date) {
    return null
  }

  return (
    <Flex style={{ height: '100%' }} {...flexProps[layout]}>
      <Typography.Text>{dayjs(date).format(format)}</Typography.Text>
      {withRelativeTime && (
        <Typography.Text type="secondary" italic>
          ({dayjs(date).fromNow()})
        </Typography.Text>
      )}
    </Flex>
  )
}
