import { CompanyStockQuery, ProductStatus } from '../../../generated/graphql'
import { getSearchIdentifiers } from '../../../helpers/getSearchIdentifiers'
import { StockTableRecord } from '../components/StockTable'

const MAX_RUNTIME_IN_DAYS = 365

export function prepareStockData(data: CompanyStockQuery): StockTableRecord[] {
  return data.companyStock
    .filter((item) => {
      const hasQuantities =
        item.availableQuantity > 0 ||
        item.inboundQuantity > 0 ||
        item.reservedQuantity > 0 ||
        item.researchingQuantity > 0 ||
        item.unfulfillableQuantity > 0

      return item.productVariant.product.status === ProductStatus.ACTIVE || hasQuantities
    })
    .map((item) => ({
      warehouseName: `${item.warehouseServiceProviderLocation.warehouseServiceProvider.name} - ${item.warehouseServiceProviderLocation.name}`,
      warehouseLocationUuid: item.warehouseServiceProviderLocation.uuid,
      productUuid: item.productVariant.product.uuid,
      productVariantUuid: item.productVariant.uuid,
      productName: item.productVariant.product.name,
      productStatus: item.productVariant.product.status,
      tags: item.productVariant.product.tags ?? [],
      internalSku: item.productVariant.internalSku ?? null,
      asins: getUniqueAsins(item),
      skus: getUniqueSkus(item),
      netPurchasePrice: item.productVariant.netPurchasePrice ?? null,
      availableQuantity: item.availableQuantity,
      inboundQuantity: item.inboundQuantity,
      reservedQuantity: item.reservedQuantity,
      researchingQuantity: item.researchingQuantity,
      unfulfillableQuantity: item.unfulfillableQuantity,
      runtimeInDays: item.estimatedDaysToStockout
        ? item.estimatedDaysToStockout > MAX_RUNTIME_IN_DAYS
          ? MAX_RUNTIME_IN_DAYS
          : item.estimatedDaysToStockout
        : null,
      recommendedOrderQuantity: item.recommendedReorderQuantity ?? null,
      overstock: item.overstockQuantity ?? null,
      packagingUnits: item.productVariant.packagingUnits,
      mostRecentShipmentDestinations: getMostRecentShipmentDestinations(item),
      ordersLast14Days: item.performance?.ordersLast14Days ?? 0,
      ordersLast30Days: item.performance?.ordersLast30Days ?? 0,
      ordersLast90Days: item.performance?.ordersLast90Days ?? 0,
      firstOrderDate: item.health?.firstOrderDate ?? null,
      mostRecentOrderDate: item.health?.mostRecentOrderDate ?? null,
      deltaOrdersLast14Days: item.performance?.deltaOrdersLast14Days ?? 0,
      deltaOrdersLast30Days: item.performance?.deltaOrdersLast30Days ?? 0,
      deltaOrdersLast90Days: item.performance?.deltaOrdersLast90Days ?? 0,
      salesRevenueLast14Days: item.performance?.salesRevenueLast14Days ?? 0,
      salesRevenueLast30Days: item.performance?.salesRevenueLast30Days ?? 0,
      salesRevenueLast90Days: item.performance?.salesRevenueLast90Days ?? 0,
      returnsLast14Days: item.performance?.returnsLast14Days ?? 0,
      returnsLast30Days: item.performance?.returnsLast30Days ?? 0,
      returnsLast90Days: item.performance?.returnsLast90Days ?? 0,
      age0To90Days: item.age0To90Days ?? null,
      age91To180Days: item.age91To180Days ?? null,
      age181To330Days: item.age181To330Days ?? null,
      age331To365Days: item.age331To365Days ?? null,
      ageOver365Days: item.ageOver365Days ?? null,
      searchIdentifiers: getSearchIdentifiers({
        productVariant: item.productVariant,
      }),
    }))
}

function getUniqueAsins(item: CompanyStockQuery['companyStock'][number]) {
  if (!item.productVariant.amazonProducts?.length) {
    return []
  }

  const asins = item.productVariant.amazonProducts.flatMap((amazonProduct) => {
    if (
      amazonProduct.amazonAccount.name !== item.warehouseServiceProviderLocation.amazonAccount?.name
    ) {
      return []
    }

    return amazonProduct.asin
  })

  const uniqueAsins = [...new Set(asins)]

  return uniqueAsins
}

function getUniqueSkus(item: CompanyStockQuery['companyStock'][number]) {
  if (!item.productVariant.amazonProducts?.length) {
    return []
  }

  const skus = item.productVariant.amazonProducts.flatMap((amazonProduct) => {
    if (
      !amazonProduct.amazonListings?.length ||
      amazonProduct.amazonAccount.name !== item.warehouseServiceProviderLocation.amazonAccount?.name
    ) {
      return []
    }

    return amazonProduct.amazonListings.flatMap((amazonListing) => {
      if (
        item.warehouseServiceProviderLocation.marketplaces?.find(
          (marketplace) => marketplace === amazonListing.marketplace.name
        )
      ) {
        return amazonListing.sku
      }

      return []
    })
  })

  const uniqueSkus = [...new Set(skus)]

  return uniqueSkus
}

function getMostRecentShipmentDestinations(item: CompanyStockQuery['companyStock'][number]) {
  const locationMarketplaces = item.warehouseServiceProviderLocation.marketplaces ?? []
  const amazonProducts = item.productVariant.amazonProducts ?? []

  return amazonProducts.flatMap((amazonProduct) => {
    if (!amazonProduct.amazonListings) {
      return []
    }

    return amazonProduct.amazonListings.flatMap((amazonListing) => {
      if (
        !amazonListing.mostRecentShipmentDestination ||
        !locationMarketplaces.includes(amazonListing.marketplace.name)
      ) {
        return []
      }

      return {
        sku: amazonListing.sku,
        marketplace: amazonListing.marketplace.name,
        mostRecentShipmentDestination: amazonListing.mostRecentShipmentDestination,
      }
    })
  })
}
