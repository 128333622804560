import { create } from 'zustand'
import { TourProps } from 'antd'

type OnboardingState = {
  onboardingOpen: boolean
  tourOpen: boolean
  tourSteps: TourProps['steps']
}

type OnboardingActions = {
  setOnboardingOpen: (open: boolean) => void
  setTourOpen: (open: boolean) => void
  setTourSteps: (steps: TourProps['steps']) => void
}

const initialState: OnboardingState = {
  onboardingOpen: false,
  tourOpen: false,
  tourSteps: [],
}

export const useOnboardingStore = create<OnboardingState & OnboardingActions>((set) => ({
  ...initialState,
  setOnboardingOpen: (open) => set({ onboardingOpen: open }),
  setTourOpen: (open) => set({ tourOpen: open }),
  setTourSteps: (steps) => set({ tourSteps: steps }),
}))
