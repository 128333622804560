import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { Marketplace } from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { LetterOfAuthorizationUploadButton } from './LetterOfAuthorizationUploadButton'
import { LetterOfAuthorizationViewButton } from './LetterOfAuthorizationViewButton'

type BrandManagementRecord = {
  name: string
  letterOfAuthorizations: {
    key: string
    authorizedCompany: {
      sellerId: string
    }
  }[]
}

export const BrandManagementTable = ({ rowData }: { rowData: BrandManagementRecord[] }) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation()

  const [columnDefs] = useState<
    (ColDef<BrandManagementRecord> | ColGroupDef<BrandManagementRecord>)[]
  >([
    {
      field: 'name',
      headerName: t('settings.brand.table.columns.brand'),
    },
    {
      headerName: t('settings.brand.table.columns.letterOfAuthorization'),
      children: [
        {
          headerName: 'Varento',
          sortable: false,
          valueGetter: (params) => {
            if (!params.data) {
              return null
            }

            const letterOfAuthorization = params.data.letterOfAuthorizations.find(
              ({ authorizedCompany }) => {
                return authorizedCompany.sellerId === 'VARO1'
              }
            )

            return letterOfAuthorization?.key ? (
              <LetterOfAuthorizationViewButton objectKey={letterOfAuthorization.key} />
            ) : (
              <LetterOfAuthorizationUploadButton
                brand={params.data.name}
                authorizedCompany={{ sellerId: 'VARO1' }}
              />
            )
          },
        },
        {
          headerName: 'SPACEGOATS US',
          hide: !!selectedCompany?.marketplaces.find(
            (marketplace) => marketplace === Marketplace.US
          ),
          sortable: false,
          valueGetter: (params) => {
            if (!params.data) {
              return null
            }

            const letterOfAuthorization = params.data.letterOfAuthorizations.find(
              ({ authorizedCompany }) => {
                return authorizedCompany.sellerId === 'SGUS1'
              }
            )

            return letterOfAuthorization?.key ? (
              <LetterOfAuthorizationViewButton objectKey={letterOfAuthorization.key} />
            ) : (
              <LetterOfAuthorizationUploadButton
                brand={params.data.name}
                authorizedCompany={{
                  sellerId: 'SGUS1',
                }}
              />
            )
          },
        },
      ],
    },
  ])

  const defaultColDef: ColDef<BrandManagementRecord> = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      suppressMovable: true,
      cellRenderer: (params: ICellRendererParams<BrandManagementRecord>) => {
        if (params.value === null || params.value === undefined || params.value === '') {
          return t('shared.notAvailable', { ns: 'translation' })
        } else {
          return params.value
        }
      },
    }),
    []
  )

  return (
    <Table<BrandManagementRecord>
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      rowData={rowData}
      suppressContextMenu
    />
  )
}
