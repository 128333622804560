import { useTheme } from '@emotion/react'
import {
  CellEditRequestEvent,
  FirstDataRenderedEvent,
  GetRowIdParams,
  ICellRendererParams,
  IRowNode,
  RowSelectionOptions,
  SelectionChangedEvent,
  SelectionColumnDef,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Tooltip } from 'antd'
import { CircleAlert } from 'lucide-react'
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useLocation } from 'react-router'
import { Table } from '../../../../components/Table/Table'
import {
  CreateProductVariantPackagingUnitMutation,
  GetWarehousesAndWholesaleOrderQuery,
  GetWholesaleOrderProductsQuery,
} from '../../../../generated/graphql'
import { TablePersistKey } from '../../../../stores/useTableStore'
import { StockTableRecord } from '../../../../views/stock/components/StockTable'
import { useWholesaleOrderFormTableColumnDefs } from './helpers/useWholesaleOrderFormTableColumnDefs'

export type PackagingUnit = NonNullable<
  CreateProductVariantPackagingUnitMutation['createProductVariantPackagingUnit']
>

export type WholesaleOrderFormTableRecord = {
  lineItemIdentifier?: string
  productName: string
  productVariantUuid: string
  tags: string[]
  internalSku: string | null
  ean: string | null
  attributes: string[]
  asins: string[]
  skus: string[]
  fnskus: string[]
  netPurchasePrice: number | null
  packageLength: number | null
  packageWidth: number | null
  packageHeight: number | null
  packageWeight: number | null
  availableQuantity: number
  recommendedOrderQuantity: number | null
  selectedPackagingUnit?: NonNullable<
    GetWholesaleOrderProductsQuery['products'][number]['productVariants']
  >[number]['packagingUnits'][number]
  quantityOfPackagingUnits?: number
  packagingUnits: NonNullable<
    GetWholesaleOrderProductsQuery['products'][number]['productVariants']
  >[number]['packagingUnits']
  taxRate: number | null
  mostRecentShipmentDestinations: {
    sku: string
    marketplace: string
    mostRecentShipmentDestination: string
  }[]
}

type WholesaleOrderFormTableProps = {
  gridRef: MutableRefObject<AgGridReact<WholesaleOrderFormTableRecord> | null>
  rowData: WholesaleOrderFormTableRecord[]
  setRowData: Dispatch<SetStateAction<WholesaleOrderFormTableRecord[]>>
  wholesaleOrder?: GetWarehousesAndWholesaleOrderQuery['wholesaleOrder']
}

export const WholesaleOrderFormTable = ({
  gridRef,
  rowData,
  setRowData,
  wholesaleOrder,
}: WholesaleOrderFormTableProps) => {
  const { t } = useTranslation('wholesale-order')
  const theme = useTheme()

  const location = useLocation() as Location<
    | {
        selectedRows?: StockTableRecord[]
      }
    | undefined
  >

  const initialState = location.state

  const columnDefs = useWholesaleOrderFormTableColumnDefs()

  const getRowId = useMemo(() => {
    return (params: GetRowIdParams<WholesaleOrderFormTableRecord>) => {
      if (params.rowPinned === 'bottom') {
        return 'bottom'
      }

      return params.data.productVariantUuid
    }
  }, [])

  // A row is selectable if the following conditions are met:
  // - The product variant has package dimensions.
  // - The product variant has a tax rate, determined by the product variant tax category.
  // - The fulfillment fee is paid by the wholesaler or there is a fulfillment fee estimate.
  // - The storage fee is paid by the wholesaler or there is a storage fee estimate.
  const isRowSelectable = useCallback((params: IRowNode<WholesaleOrderFormTableRecord>) => {
    if (!params.data) {
      return false
    }

    const { packageLength, packageWidth, packageHeight, packageWeight, taxRate } = params.data

    if (!packageLength || !packageWidth || !packageHeight || !packageWeight) {
      return false
    }

    if (taxRate === null) {
      return false
    }

    return true
  }, [])

  const rowSelection = useMemo<RowSelectionOptions<WholesaleOrderFormTableRecord>>(() => {
    return {
      mode: 'multiRow',
      isRowSelectable,
      selectAll: 'filtered',
    }
  }, [isRowSelectable])

  const selectionColumnDef = useMemo<SelectionColumnDef>(() => {
    return {
      minWidth: 66,
      maxWidth: 66,
      pinned: 'left',
      cellRenderer: (params: ICellRendererParams<WholesaleOrderFormTableRecord>) => {
        if (params.data && !params.node.selectable && params.node.rowPinned !== 'bottom') {
          const { packageLength, packageWidth, packageHeight, packageWeight, taxRate } = params.data

          const reasons: string[] = []

          if (!packageLength || !packageWidth || !packageHeight || !packageWeight) {
            reasons.push(t('wholesaleOrderLineItemsSelectorTable.columnDefs.tooltip.dimensions'))
          }

          if (!taxRate) {
            reasons.push(t('wholesaleOrderLineItemsSelectorTable.columnDefs.tooltip.taxCategory'))
          }

          return (
            <Tooltip
              title={
                <>
                  {t('wholesaleOrderLineItemsSelectorTable.columnDefs.tooltip.title')}
                  {reasons.length > 0 ? (
                    <ul style={{ margin: 0, paddingLeft: '1rem' }}>
                      {reasons.map((reason, index) => (
                        <li key={index}>{reason}</li>
                      ))}
                    </ul>
                  ) : null}
                </>
              }
            >
              <CircleAlert size={16} color={theme.colors.warning} />
            </Tooltip>
          )
        }

        return null
      },
    }
  }, [])

  const handleFirstDataRendered = useCallback(
    (event: FirstDataRenderedEvent<WholesaleOrderFormTableRecord>) => {
      if (wholesaleOrder) {
        const nodesToSelect: IRowNode<WholesaleOrderFormTableRecord>[] = []

        event.api.forEachNode((node) => {
          const productVariantUuid = node.data?.productVariantUuid

          if (!productVariantUuid) {
            return
          }

          if (
            wholesaleOrder.lineItems.some(
              (lineItem) => lineItem.productVariant.uuid === productVariantUuid
            )
          ) {
            nodesToSelect.push(node)
          }
        })

        event.api.setNodesSelected({ nodes: nodesToSelect, newValue: true })
      } else if (initialState?.selectedRows) {
        const nodesToSelect: IRowNode<WholesaleOrderFormTableRecord>[] = []

        event.api.forEachNode((node) => {
          const productVariantUuid = node.data?.productVariantUuid

          if (!productVariantUuid) {
            return
          }

          if (
            initialState.selectedRows?.some((row) => row.productVariantUuid === productVariantUuid)
          ) {
            nodesToSelect.push(node)
          }
        })

        event.api.setNodesSelected({ nodes: nodesToSelect, newValue: true })
      }
    },
    [wholesaleOrder, initialState]
  )

  const applyRowUpdate = useCallback(
    async (
      event: CellEditRequestEvent<WholesaleOrderFormTableRecord>,
      updatedData: Partial<WholesaleOrderFormTableRecord>
    ) => {
      const newData = { ...event.data, ...updatedData }

      event.api.applyTransaction({ update: [newData] })

      setRowData((prevRowData) => {
        return prevRowData.map((row) => {
          if (row.productVariantUuid === event.data.productVariantUuid) {
            return newData as WholesaleOrderFormTableRecord
          }
          return row
        })
      })
    },
    []
  )

  const handleCellEditRequest = useCallback(
    async (event: CellEditRequestEvent<WholesaleOrderFormTableRecord>) => {
      const field = event.colDef.field

      if (!field || event.newValue === event.oldValue) {
        return false
      }

      try {
        if (field === 'selectedPackagingUnit') {
          const selectedPackagingUnitQuantity = event.newValue.quantity as number
          const selectedPackagingUnit = event.data.packagingUnits.find(
            (packagingUnit) => packagingUnit.quantity === selectedPackagingUnitQuantity
          )

          if (!selectedPackagingUnit) {
            return false
          }

          await applyRowUpdate(event, {
            selectedPackagingUnit: {
              ...selectedPackagingUnit,
              quantity: selectedPackagingUnitQuantity,
            },
          })
        }

        if (field === 'quantityOfPackagingUnits') {
          const quantityOfPackagingUnits = event.newValue as number

          await applyRowUpdate(event, { quantityOfPackagingUnits })
        }

        if (field === 'netPurchasePrice') {
          const netPurchasePrice = event.newValue as number

          await applyRowUpdate(event, { netPurchasePrice })
        }

        if (!event.node.isSelected()) {
          event.node.setSelected(true)
        }
      } catch (error) {
        console.error(error)
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: event.oldValue }],
        })
      }
    },
    []
  )

  const handleSelectionChanged = (event: SelectionChangedEvent<WholesaleOrderFormTableRecord>) => {
    const selectedRows = event.api.getSelectedRows() as WholesaleOrderFormTableRecord[]

    setRowData((prevRowData) => {
      const selectedRowData = prevRowData.filter((row) => {
        return selectedRows
          .map((selectedRow) => selectedRow.productVariantUuid)
          .includes(row.productVariantUuid)
      })

      const unselectedRowData = prevRowData.filter((row) => {
        return !selectedRows
          .map((selectedRow) => selectedRow.productVariantUuid)
          .includes(row.productVariantUuid)
      })

      return [...selectedRowData, ...unselectedRowData]
    })

    event.api.refreshCells({ force: true })
  }

  const pinnedBottomRowData = [['netTotal']]

  return (
    <Table<WholesaleOrderFormTableRecord>
      columnDefs={columnDefs}
      getRowId={getRowId}
      onCellEditRequest={handleCellEditRequest}
      onFirstDataRendered={handleFirstDataRendered}
      onSelectionChanged={handleSelectionChanged}
      persist={{
        key: TablePersistKey.WHOLESALE_ORDER_FORM,
        storage: localStorage,
      }}
      pinnedBottomRowData={pinnedBottomRowData}
      readOnlyEdit
      ref={gridRef}
      rowData={rowData}
      rowSelection={rowSelection}
      selectionColumnDef={selectionColumnDef}
      showColumnChooser
      showFilterReset
      showUniversalSearch
      suppressContextMenu
    />
  )
}
