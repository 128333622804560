import { useTheme } from '@emotion/react'
import { CellClassParams, ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import dayjs from 'dayjs'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { formatMoney } from '../../../../helpers/formatMoney'
import { formatPercentage } from '../../../../helpers/formatPercentage'
import { InvoiceLineItemRecord } from '../InvoiceTable'

type InvoiceLineItemsSubTableProps = {
  lineItems: InvoiceLineItemRecord[]
}

export const InvoiceLineItemsSubTable = ({ lineItems }: InvoiceLineItemsSubTableProps) => {
  const gridRef = useRef<AgGridReact<InvoiceLineItemRecord>>(null)
  const { t } = useTranslation(['invoices', 'translation'])
  const theme = useTheme()

  const columnDefs = useMemo<ColDef<InvoiceLineItemRecord>[]>(
    () => [
      {
        field: 'title',
        headerName: t('invoices:lineItems.table.title'),
        cellStyle: (params: CellClassParams<InvoiceLineItemRecord>) => ({
          backgroundColor: params.data?.uuid?.startsWith('temp-')
            ? `${theme.colors.warning}20`
            : 'initial',
        }),
      },
      {
        field: 'netAmount',
        headerName: t('invoices:lineItems.table.netAmount'),

        cellRenderer: (params: ICellRendererParams<InvoiceLineItemRecord>) =>
          formatMoney(params.value, params.data?.currency || 'EUR'),
      },
      {
        field: 'quantity',
        headerName: t('invoices:lineItems.table.quantity'),

        cellRenderer: (params: ICellRendererParams<InvoiceLineItemRecord>) =>
          params.value ?? t('shared.button.edit', { ns: 'translation' }),
      },
      {
        field: 'taxRate',
        headerName: t('invoices:lineItems.table.taxRate'),
        cellRenderer: (params: ICellRendererParams<InvoiceLineItemRecord>) =>
          formatPercentage(params.value / 100) ?? t('shared.button.edit', { ns: 'translation' }),
      },
      {
        field: 'comment',
        headerName: t('invoices:lineItems.table.comment'),
      },
    ],
    [t, theme.colors.warning]
  )

  return (
    <Table<InvoiceLineItemRecord>
      ref={gridRef}
      columnDefs={columnDefs}
      rowData={lineItems}
      defaultExcelExportParams={{
        fileName: `quantity-discounts-${dayjs().format('DD-MM-YYYY')}`,
      }}
      getRowId={(params) => params.data.uuid}
    />
  )
}
