import { ColDef, ColGroupDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { Suspense, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flag } from '../../../../components/Flag'
import { Table } from '../../../../components/Table/Table'
import { formatMoney } from '../../../../helpers/formatMoney'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { preparePaymentsRefundRecords } from '../helpers'
import { Marketplace } from '../../../../generated/graphql'
import { PaymentsQueryRefund } from '../PaymentsView'

export type PaymentsRefundTableRecord = {
  name: string
  asin: string
  sku: string
  marketplace: Marketplace
  date: string
  quantity: number
  revenue: number
  vat: number
  refundedFees: number
  profit: number
  purchasePrice: number
  remunerationAmount: number
}

type PaymentsRefundTableProps = {
  refunds: PaymentsQueryRefund[]
}

export const PaymentsRefundTable = ({ refunds }: PaymentsRefundTableProps) => {
  const rowData = preparePaymentsRefundRecords(refunds)

  const { t } = useTranslation(['billing'], { keyPrefix: 'payments.details.refunds' })

  const [columnDefs] = useState<
    (ColDef<PaymentsRefundTableRecord> | ColGroupDef<PaymentsRefundTableRecord>)[]
  >([
    {
      field: 'name',
      headerName: t('table.name'),
    },
    {
      field: 'asin',
      headerName: t('table.asin'),
    },
    {
      field: 'sku',
      headerName: t('table.sku'),
    },
    {
      headerName: t('table.marketplace'),
      filter: 'agSetColumnFilter',
      valueGetter: (
        params: ValueGetterParams<
          PaymentsRefundTableRecord,
          PaymentsRefundTableRecord['marketplace']
        >
      ) => {
        return params.data
          ? `Amazon.${getMarketplaceDomain(params.data.marketplace)}`
          : t('shared.notAvailable')
      },
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsRefundTableRecord,
          PaymentsRefundTableRecord['marketplace']
        >
      ) => {
        return params.data ? (
          <Space>
            <Flag code={params.data.marketplace} />
            <Typography.Text>
              Amazon.{getMarketplaceDomain(params.data.marketplace)}
            </Typography.Text>
          </Space>
        ) : (
          t('shared.notAvailable')
        )
      },
    },
    {
      field: 'date',
      headerName: t('table.date'),
      initialSort: 'desc',
      cellRenderer: (
        params: ICellRendererParams<PaymentsRefundTableRecord, PaymentsRefundTableRecord['date']>
      ) => {
        return dayjs(params.value).format('DD.MM.YYYY HH:mm:ss')
      },
    },
    {
      field: 'quantity',
      headerName: t('table.quantity'),
    },
    {
      field: 'revenue',
      headerName: t('table.revenue'),
      cellRenderer: (
        params: ICellRendererParams<PaymentsRefundTableRecord, PaymentsRefundTableRecord['revenue']>
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'vat',
      headerName: t('table.vat'),
      cellRenderer: (
        params: ICellRendererParams<PaymentsRefundTableRecord, PaymentsRefundTableRecord['vat']>
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'refundedFees',
      headerName: t('table.refundedFees'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsRefundTableRecord,
          PaymentsRefundTableRecord['refundedFees']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'profit',
      headerName: t('table.profit'),
      cellRenderer: (
        params: ICellRendererParams<PaymentsRefundTableRecord, PaymentsRefundTableRecord['profit']>
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },

    {
      field: 'purchasePrice',
      headerName: t('table.purchasePrice'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsRefundTableRecord,
          PaymentsRefundTableRecord['purchasePrice']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'remunerationAmount',
      headerName: t('table.remunerationAmount'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsRefundTableRecord,
          PaymentsRefundTableRecord['remunerationAmount']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
  ])

  const defaultColDef: ColDef<PaymentsRefundTableRecord> = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<PaymentsRefundTableRecord>) => {
        if (params.value === null || params.value === undefined || params.value === '') {
          return t('shared.notAvailable', { ns: 'translation' })
        } else {
          return params.value
        }
      },
    }),
    []
  )

  return (
    <Suspense>
      {!rowData?.length ? (
        <Typography.Text type="secondary">{t('empty')}</Typography.Text>
      ) : (
        <Table<PaymentsRefundTableRecord>
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          suppressContextMenu
        />
      )}
    </Suspense>
  )
}
