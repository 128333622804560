import { useMutation } from '@apollo/client'
import { Alert, Button, Form, Row, Switch, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { toast } from 'sonner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import QuillTextarea from '../../../components/Ticket/QuillTextarea'
import { UploadZendeskFile } from '../../../components/Ticket/UploadFile'
import { SendSupportTicketDocument } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { PersonalNoteInput } from '../components/form/PersonalNoteInput'
import { SkuWithMarketplacesAndDescriptionOptionalDynamicInput } from '../components/form/SkuWithMarketplacesAndDescriptionOptionalDynamicInput'
import { getCurrencySymbolOrCodeFromTicketMainCategory } from '../helpers/getCurrencySymbolFromTicketMainCategory'
import { createZendeskTicketOptions } from '../helpers/Zendesk.helper'
import { TicketCreationCard } from '../styles/TicketCreateViewStyles'
import { TicketCategory, TicketMainCategory } from '../ticket.enum'
import { AttachmentProps } from '../ticket.interface'

type FormInstance = {
  issues: Issue[]
  messageInput: string
  confirmation_switch: boolean
  personalNotes: string
}

type Issue = {
  sku: string
  description: string
  marketplaces: string[]
}

export const EditProductInformationTicketView = () => {
  const [confirmationSwitchValue, setConfirmationSwitchValue] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const location = useLocation()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()

  const { t, ready, i18n } = useTranslation(['ticket'], {
    keyPrefix: 'listing.editProductInformationTicketView',
  })
  const { t: generalTranslation } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [form] = Form.useForm()

  const [sendSupportTicketRequest, { loading }] = useMutation(SendSupportTicketDocument)

  if (!ready) {
    return <LoadingSpinner />
  }

  const onFinish = async (values: FormInstance) => {
    const tokens = attachments.map((attachment) => {
      return attachment.token
    })

    const createIssueList = (issues: Issue[]) => {
      if (issues.length === 0) {
        return []
      }

      return issues.map((issue) => {
        return `
          <li>
            <p><b>SKU:</b> ${issue.sku}</p>
            <p><b>${t(
              'form.marketplacesInput.placeholder'
            )}:</b> ${issue.marketplaces.toString()}</p>
            <p><b>${t('form.descriptionInput.placeholder')}:</b></p>
            <p>${issue.description}</p>
          </li>
        `
      })
    }

    const createIssuesSection = (issues: Issue[]) => {
      if (issues.length === 0) {
        return ''
      } else {
        return `
          <h3>${t('form.label')}</h3>
          <ul>
          ${createIssueList(issues).join('')}
          </ul>
        `
      }
    }

    const message = `
      <h1>${t('viewTitle')}</h1>
      ${values.issues ? createIssuesSection(values.issues) : ''}
      <h3><b>${t('form.comments.label')}</b></h3>
      ${values.messageInput}
    `

    const options = createZendeskTicketOptions({
      categoryTitle: location.state.categoryTitleTag,
      comment: message,
      language: i18n.language,
      mainCategory: TicketMainCategory.LISTINGS,
      subject: t('viewTitle'),
      ticketCategory: TicketCategory.LISTINGS_PRODUCT_INFO_EDIT,
      sellerId: selectedCompany?.sellerId,
      personalNotes: values.personalNotes,
      attachments: tokens,
    })

    try {
      if (selectedCompany !== null && selectedCompany.uuid) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })
        toast.success(generalTranslation('notification.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      console.error(error)
      toast.error(generalTranslation('notification.error'), { description: error.message })
    }
  }

  return (
    <>
      <SubHeader withBackButton />
      <ViewContainer centered>
        <TicketCreationCard title={t('ticketDescriptionTitle')}>
          <Typography.Paragraph>{t('ticketDescriptionSubtitle')}</Typography.Paragraph>
          <Typography.Paragraph>{t('shortEditList.title')}</Typography.Paragraph>
          <Form<FormInstance>
            name="edit-product-information-ticket"
            form={form}
            onFinish={onFinish}
            layout="vertical"
          >
            <SkuWithMarketplacesAndDescriptionOptionalDynamicInput />
            <Typography.Paragraph>{t('longEditList.title')}</Typography.Paragraph>
            <ul>
              <li>
                {t('longEditList.mainListItems.getFileSteps.title')}&nbsp;
                <Typography.Link href={t('longEditList.mainListItems.getFileSteps.link')}>
                  {t('longEditList.mainListItems.getFileSteps.linkTitle')}
                </Typography.Link>
              </li>
              <li>{`${t(
                'longEditList.mainListItems.fillTemplate1'
              )}${getCurrencySymbolOrCodeFromTicketMainCategory(
                location.state.categoryTitleTag
              )}${t('longEditList.mainListItems.fillTemplate2')}`}</li>
              <li>{t('longEditList.mainListItems.saveFile')}</li>
              <li>{t('longEditList.mainListItems.sendLink')}</li>
            </ul>
            <Form.Item>
              <Alert type="info" message={t('notes.title')} description={t('notes.description')} />
            </Form.Item>
            <Typography.Paragraph>{t('spacegoatsSignature')}</Typography.Paragraph>
            <Form.Item name="messageInput" label={t('form.comments.label')}>
              <QuillTextarea placeholder={t('form.comments.placeholder')} />
            </Form.Item>
            <PersonalNoteInput />
            <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
            <Form.Item
              name="confirmation_switch"
              label={t('confirmationText')}
              valuePropName="checked"
            >
              <Switch onChange={setConfirmationSwitchValue} />
            </Form.Item>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!confirmationSwitchValue}
                loading={loading}
              >
                {t('sendButtonText')}
              </Button>
            </Row>
          </Form>
        </TicketCreationCard>
      </ViewContainer>
    </>
  )
}
