import { useLazyQuery } from '@apollo/client'
import { Button, Form, Modal, Select, Tooltip } from 'antd'
import { File } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { GetAmazonLabelsDocument, PageType } from '../../../../generated/graphql'
import { downloadBase64File } from '../../../../helpers/downloadBase64File'
import { AmazonInboundShipment } from '../AmazonInboundShipmentPlanView'

type DownloadAmazonInboundShipmentPlanLabelFormInstance = {
  pageType: PageType
}

type DownloadAmazonInboundShipmentPlanLabelModalProps = {
  inboundShipments: Partial<AmazonInboundShipment>[]
  withLabel?: boolean
  pageType?: PageType
}

export const DownloadAmazonInboundShipmentPlanLabelModal = ({
  inboundShipments,
  withLabel = false,
  pageType = PageType.PACKAGELABEL_A4_2,
}: DownloadAmazonInboundShipmentPlanLabelModalProps) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation('inventory')
  const [form] = Form.useForm<DownloadAmazonInboundShipmentPlanLabelFormInstance>()

  const [amazonInboundShipmentLabelsBase64] = useLazyQuery(GetAmazonLabelsDocument)

  const handleSubmit = async (values: DownloadAmazonInboundShipmentPlanLabelFormInstance) => {
    setLoading(true)
    try {
      for (const label of inboundShipments) {
        if (!label.shipmentId) {
          throw new Error()
        }

        const { data } = await amazonInboundShipmentLabelsBase64({
          variables: {
            pageType: values.pageType,
            amazonInboundShipmentId: label.shipmentId,
          },
        })

        if (!data?.amazonInboundShipmentLabelsBase64) {
          throw new Error()
        }

        const pdf = data.amazonInboundShipmentLabelsBase64

        downloadBase64File(pdf, 'application/pdf', `${label.shipmentId}.pdf`)

        await new Promise((resolve) => setTimeout(resolve, 1000))
      }

      form.resetFields()
      toast.success(t('amazonInboundShipmentPlan.labels.success.message'))
    } catch (error) {
      console.error(error)
      toast.error(t('amazonInboundShipmentPlan.labels.error.message'))
    } finally {
      setOpen(false)
      setLoading(false)
    }
  }

  return (
    <>
      {withLabel ? (
        <Button type="primary" onClick={() => setOpen(true)}>
          {t('amazonInboundShipmentPlan.button.download')}
        </Button>
      ) : (
        <Tooltip title={t('amazonInboundShipmentPlan.table.labels.download')}>
          <Button icon={<File size={16} />} onClick={() => setOpen(true)} />
        </Tooltip>
      )}
      <Modal
        title={t('amazonInboundShipmentPlan.modal.labels.title')}
        open={open}
        okText={t('shared.button.download', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form<DownloadAmazonInboundShipmentPlanLabelFormInstance>
          form={form}
          initialValues={{ pageType }}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            name="pageType"
            label={t('amazonInboundShipmentPlan.modal.labels.form.pageType.title')}
            rules={[{ enum: Object.values(PageType), required: true, type: 'enum' }]}
          >
            <Select<PageType>
              options={[
                {
                  value: PageType.PACKAGELABEL_A4_2,
                  label: t('amazonInboundShipmentPlan.create.form.carton.shippingLabel.standard'),
                },
                {
                  value: PageType.PACKAGELABEL_THERMAL_NONPCP,
                  label: t('amazonInboundShipmentPlan.create.form.carton.shippingLabel.thermal'),
                },
              ]}
              placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
