import { Badge, Button, Descriptions, Modal, Space } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flag } from '../../../../components/Flag'
import {
  AmazonProductFieldsFragment,
  PanEuOfferStatus,
  PanEuStatus,
} from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { sortByMarketplace } from '../../../../helpers/sortByMarketplace'
import { descriptionsStyle } from '../../../products/components/styles'

type AmazonProductPanEuModalProps = {
  amazonProduct: AmazonProductFieldsFragment
}

export const AmazonProductPanEuModal = ({ amazonProduct }: AmazonProductPanEuModalProps) => {
  const [open, setOpen] = useState(false)

  const { t } = useTranslation('inventory')

  const panEUStatusToTagColor = {
    [PanEuStatus.ELIGIBLE]: 'blue',
    [PanEuStatus.NOT_ELIGIBLE]: 'red',
    [PanEuStatus.REGISTERED]: 'green',
    [PanEuStatus.EXPIRING_SOON]: 'orange',
    [PanEuStatus.EXPIRED]: 'red',
    [PanEuStatus.MISSING_DATA]: 'default',
  }

  const panEUStatusToTagText = {
    [PanEuStatus.ELIGIBLE]: t('product.details.amazonProduct.panEU.eligible'),
    [PanEuStatus.NOT_ELIGIBLE]: t('product.details.amazonProduct.panEU.notEligible'),
    [PanEuStatus.REGISTERED]: t('product.details.amazonProduct.panEU.registered'),
    [PanEuStatus.EXPIRING_SOON]: t('product.details.amazonProduct.panEU.expiringSoon'),
    [PanEuStatus.EXPIRED]: t('product.details.amazonProduct.panEU.expired'),
    [PanEuStatus.MISSING_DATA]: t('product.details.amazonProduct.panEU.missingData'),
  }

  const amazonListings = amazonProduct.amazonListings ?? []
  const sortedAmazonListings = [...amazonListings].sort((a, b) => {
    return sortByMarketplace(a.marketplace.name, b.marketplace.name)
  })

  if (amazonProduct.amazonAccount.name !== 'Varento' || !amazonProduct.panEUStatus) {
    return null
  }

  return (
    <>
      <Button type="text" onClick={() => setOpen(true)}>
        <Space>
          <Badge color={panEUStatusToTagColor[amazonProduct.panEUStatus]} />
          {t('product.details.amazonProduct.panEU.title')}
        </Space>
      </Button>
      <Modal title={amazonProduct.asin} open={open} onCancel={() => setOpen(false)} footer={null}>
        <Descriptions {...descriptionsStyle} title={t('product.details.amazonProduct.panEU.title')}>
          <Descriptions.Item label={t('product.details.amazonProduct.panEU.status')}>
            <Space align="start">
              <Badge color={panEUStatusToTagColor[amazonProduct.panEUStatus]} />
              {panEUStatusToTagText[amazonProduct.panEUStatus]}
            </Space>
          </Descriptions.Item>
          {sortedAmazonListings?.map((amazonListing) => (
            <Descriptions.Item
              key={amazonListing.uuid}
              label={
                <Space>
                  <Flag code={amazonListing.marketplace.name} />
                  {`Amazon.${getMarketplaceDomain(amazonListing.marketplace.name)}`}
                </Space>
              }
            >
              {amazonListing.panEUOfferStatus === PanEuOfferStatus.ACTIVE ? (
                <Space>
                  <Badge color="green" />
                  {t('product.details.amazonListings.panEUOffer.active')}
                </Space>
              ) : (
                <Space>
                  <Badge color="red" />
                  {t('product.details.amazonListings.panEUOffer.inactive')}
                </Space>
              )}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Modal>
    </>
  )
}
