import { Descriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components/FormattedDate'
import { GetWholesaleOrderDetailsQuery } from '../../../../generated/graphql'
import { useCountryOptions } from '../../../../hooks/useCountryOptions'
import { StyledMainCard } from '../../../dashboard/styles'
import { descriptionsStyle } from '../../../products/components/styles'
import { WholesaleOrderStatusTag } from '../WholesaleOrderStatusTag'

type WholesaleOrderGeneralDetailsCardProps = {
  wholesaleOrder: GetWholesaleOrderDetailsQuery['wholesaleOrder']
}

export const WholesaleOrderGeneralDetailsCard = ({
  wholesaleOrder,
}: WholesaleOrderGeneralDetailsCardProps) => {
  const { t } = useTranslation('wholesale-order', { keyPrefix: 'wholesaleOrderGeneralDetailsCard' })
  const countryOptions = useCountryOptions()

  return (
    <StyledMainCard title={t('title')}>
      <Descriptions {...descriptionsStyle}>
        <Descriptions.Item label={t('label.wholesaleOrderNumber')}>
          {wholesaleOrder?.identifier}
        </Descriptions.Item>
        <Descriptions.Item label={t('label.status')}>
          <WholesaleOrderStatusTag
            retailerApprovedAt={wholesaleOrder?.retailerApprovedAt}
            vendorApprovedAt={wholesaleOrder?.vendorApprovedAt}
          />
        </Descriptions.Item>
        <Descriptions.Item label={t('label.sellerCompany')}>
          {`${wholesaleOrder?.vendorCompany.sellerId} - ${wholesaleOrder?.vendorCompany.name}`}
        </Descriptions.Item>
        <Descriptions.Item label={t('label.buyerCompany')}>
          {`${wholesaleOrder?.retailerCompany.sellerId} - ${wholesaleOrder?.retailerCompany.name}`}
        </Descriptions.Item>
        <Descriptions.Item label={t('label.billedFromCountry')}>
          {
            countryOptions.find((country) => country.code === wholesaleOrder?.billedFromCountry)
              ?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label={t('label.billedToCountry')}>
          {countryOptions.find((country) => country.code === wholesaleOrder?.billedToCountry)?.name}
        </Descriptions.Item>
        {wholesaleOrder?.template && (
          <Descriptions.Item label={t('label.template')}>
            {wholesaleOrder.template.identifier}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={t('label.createdAt')}>
          <FormattedDate date={wholesaleOrder?.createdAt} layout="horizontal" withRelativeTime />
        </Descriptions.Item>
        <Descriptions.Item label={t('label.updatedAt')}>
          <FormattedDate date={wholesaleOrder?.updatedAt} layout="horizontal" withRelativeTime />
        </Descriptions.Item>
      </Descriptions>
    </StyledMainCard>
  )
}
