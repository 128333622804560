import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type LayoutState = {
  sidebarCollapsed: boolean
  setSidebarCollapsed: (sidebarCollapsed: boolean) => void
}

export const useLayoutStore = create<LayoutState>()(
  persist(
    (set) => ({
      sidebarCollapsed: false,
      setSidebarCollapsed: (sidebarCollapsed: boolean) => set({ sidebarCollapsed }),
    }),
    {
      name: 'galaxy-layout-state',
    }
  )
)
