import { Button, Col, Form, Input, Row } from 'antd'
import { i18n, TFunction } from 'i18next'
import { Plus, Trash } from 'lucide-react'

type DocumentNumberWithIssueDynamicInputProps = {
  translation: [TFunction, i18n, boolean] & { t: TFunction; i18n: i18n; ready: boolean }
}

export const DocumentNumberWithIssueDynamicInput = ({
  translation,
}: DocumentNumberWithIssueDynamicInputProps) => {
  const { t } = translation

  return (
    <Form.Item label={t('label') as string} required={true}>
      <Form.List name="data">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...rest }) => (
              <Row gutter={16} key={key}>
                <Col span={fields.length > 1 ? 22 : 24}>
                  <Form.Item
                    {...rest}
                    name={[name, 'documentNumber']}
                    rules={[
                      { required: true, message: t('documentNumberInput.validation.required') },
                    ]}
                  >
                    <Input placeholder={t('documentNumberInput.placeholder')} />
                  </Form.Item>
                  <Form.Item
                    {...rest}
                    name={[name, 'issue']}
                    rules={[{ required: true, message: t('issueInput.validation.required') }]}
                  >
                    <Input.TextArea placeholder={t('issueInput.placeholder')} />
                  </Form.Item>
                </Col>
                <Col span={2} style={{ marginBottom: 24 }}>
                  {fields.length > 1 ? (
                    <Button
                      type="primary"
                      danger
                      icon={<Trash size={16} />}
                      onClick={() => remove(name)}
                      style={{ height: '100%' }}
                    />
                  ) : null}
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed" block icon={<Plus size={16} />} onClick={() => add()}>
                {t('addMoreButton') as string}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  )
}
