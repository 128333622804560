import { useTheme } from '@emotion/react'
import { Badge, Col, List, Row, Tooltip, Typography } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Cell, Tooltip as ChartTooltip, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { formatMoney } from '../../../../../helpers/formatMoney'
import { useBreakpoints } from '../../../../../hooks/useBreakpoints'
import { StyledList, StyledModal, StyledSubCard } from '../../../styles'
import {
  DatedPerformanceOverview,
  ProductPerformanceDetails,
} from '../../helpers/preparePerformanceOverview'
import { DashboardProductLabel } from '../DashboardProductLabel'
import { DashboardSubCardOrders } from '../DashboardSubCardOrders'
import { DashboardSubCardRevenue } from '../DashboardSubCardRevenue'
import { DashboardPieChartTooltip } from './DashboardPieChartTooltip'

type PerformanceOverviewModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  payload?: DatedPerformanceOverview
}

export const PerformanceOverviewModal = ({
  open,
  setOpen,
  payload,
}: PerformanceOverviewModalProps) => {
  const { t } = useTranslation(['dashboard', 'translation'])
  const theme = useTheme()
  const breakpoints = useBreakpoints()

  const handleCancel = () => {
    setOpen(false)
  }

  if (!payload) {
    return null
  }

  return (
    <StyledModal
      open={open}
      title={<Typography.Text strong>{payload.date}</Typography.Text>}
      width={1000}
      footer={null}
      onCancel={handleCancel}
      style={{ top: breakpoints.sm ? undefined : 16 }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <DashboardSubCardOrders
            totalShipped={payload.totalShipped}
            totalPending={payload.totalPending}
            totalCanceled={payload.totalCanceled}
            loading={false}
          />
        </Col>
        <Col xs={24} md={12}>
          <DashboardSubCardRevenue
            totalRevenue={payload.totalRevenue}
            pendingRevenue={payload.pendingRevenue}
            loading={false}
          />
        </Col>
        <Col xs={24} lg={12} hidden={!payload.orders.length}>
          <StyledSubCard style={{ maxHeight: '442px', overflowY: 'auto' }}>
            <StyledList<ProductPerformanceDetails>
              header={
                <Typography.Text strong>{t('performance.shared.topProducts')}</Typography.Text>
              }
              dataSource={payload.performance.products}
              renderItem={(item) => {
                return (
                  <List.Item style={{ padding: '0.5rem 0' }}>
                    <Row gutter={8} style={{ width: '100%' }}>
                      <Col span={12}>
                        <Badge
                          color={item.color}
                          text={<DashboardProductLabel product={item} />}
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Tooltip
                          title={`${item.totalShipped} / ${item.totalPending} / ${item.totalCanceled}`}
                        >
                          <Row gutter={[4, 4]}>
                            <Col>
                              <Typography.Text>
                                {formatMoney(item.totalRevenue, 'EUR')}
                              </Typography.Text>
                            </Col>
                            <Col flex="auto">
                              <Typography.Text style={{ opacity: 0.4 }}>
                                {formatMoney(item.pendingRevenue, 'EUR')}
                              </Typography.Text>
                            </Col>
                          </Row>
                        </Tooltip>
                      </Col>
                    </Row>
                  </List.Item>
                )
              }}
              locale={{ emptyText: t('shared.noData', { ns: 'translation' }) }}
              split={false}
            />
          </StyledSubCard>
        </Col>
        <Col xs={24} lg={12}>
          {payload.totalRevenue ? (
            <StyledSubCard style={{ maxHeight: '442px' }}>
              <ResponsiveContainer width="100%" height={410}>
                <PieChart>
                  <ChartTooltip
                    content={<DashboardPieChartTooltip />}
                    wrapperStyle={{ outline: 'none' }}
                  />
                  <Pie
                    data={payload.performance.products}
                    dataKey="totalRevenue"
                    style={{ outline: 'none' }}
                  >
                    {payload.performance.products.map((item, index) => (
                      <Cell
                        key={index}
                        fill={item.color}
                        stroke={theme.colors.base[7]}
                        strokeWidth={2}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </StyledSubCard>
          ) : null}
        </Col>
      </Row>
    </StyledModal>
  )
}
