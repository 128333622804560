export const getWholesaleOrderStatus = ({
  vendorApprovedAt,
  retailerApprovedAt,
  isVendor,
}: {
  vendorApprovedAt: string | null | undefined
  retailerApprovedAt: string | null | undefined
  isVendor: boolean
}) => {
  if (vendorApprovedAt && retailerApprovedAt) {
    return 'done'
  }

  if (isVendor) {
    if (vendorApprovedAt && !retailerApprovedAt) {
      return 'waitingForOtherParty'
    }

    if (!vendorApprovedAt && retailerApprovedAt) {
      return 'waitingForApproval'
    }
  } else {
    if (retailerApprovedAt && !vendorApprovedAt) {
      return 'waitingForOtherParty'
    }

    if (!retailerApprovedAt && vendorApprovedAt) {
      return 'waitingForApproval'
    }
  }

  return null
}
