import {
  ColDef,
  ColGroupDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { Flex, Typography } from 'antd'
import dayjs from 'dayjs'
import { Eye } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../../components/FormattedDate'
import { LinkButton } from '../../../../../components/LinkButton'
import { CompanyType } from '../../../../../generated/graphql'
import { formatMoney } from '../../../../../helpers/formatMoney'
import { roundNumber } from '../../../../../helpers/roundNumber'
import { cellGetters, dateComparator, dateFilterParams } from '../../../../../helpers/tableHelpers'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { getWholesaleOrderStatus } from '../../../helpers/getWholesaleOrderStatus'
import { WholesaleOrderBillingDocumentInvoiceIcon } from '../../../WholesaleOrderBillingDocuments/components/WholesaleOrderBillingDocumentInvoiceIcon'
import { WholesaleOrderBillingDocumentUnsoldItemsIcon } from '../../../WholesaleOrderBillingDocuments/components/WholesaleOrderBillingDocumentUnsoldItemsIcon'
import { WholesaleOrderStatusIcon } from '../../WholesaleOrderStatusIcon'
import { WholesaleOrderRecord } from '../WholesaleOrdersTable'

export const useWholesaleOrderTableColumnDefs = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('wholesale-order', { keyPrefix: 'wholesaleOrdersTable' })

  const isWholesaler = selectedCompany.type === CompanyType.WHOLESALER

  const [columnDefs] = useState<
    (ColDef<WholesaleOrderRecord> | ColGroupDef<WholesaleOrderRecord>)[]
  >([
    {
      lockPosition: 'left',
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      minWidth: 56,
      maxWidth: 56,
      cellRenderer: (params: ICellRendererParams<WholesaleOrderRecord>) => {
        if (params.node.group) {
          return null
        }

        const identifier = params.data?.identifier

        return <LinkButton icon={<Eye size={16} />} disabled={!identifier} to={identifier!} />
      },
    },
    {
      field: 'identifier',
      headerName: t('columnDefs.identifier'),
      filter: 'agTextColumnFilter',
      getQuickFilterText: (
        params: GetQuickFilterTextParams<WholesaleOrderRecord, WholesaleOrderRecord['identifier']>
      ) => {
        const { identifier, searchIdentifiers } = params.data
        return `${identifier} ${searchIdentifiers}`
      },
      sort: 'desc',
    },
    {
      field: 'vendorCompany',
      headerName: t('columnDefs.vendorCompany'),
      hide: isWholesaler,
    },
    {
      field: 'retailerCompany',
      headerName: t('columnDefs.retailerCompany'),
    },
    {
      field: 'shippedFromWarehouseLocation',
      headerName: t('columnDefs.shippedFromWarehouseLocation'),
    },
    {
      field: 'shippedToWarehouseLocation',
      headerName: t('columnDefs.shippedToWarehouseLocation'),
    },
    {
      headerName: t('columnDefs.status'),
      filterValueGetter: (params: ValueGetterParams<WholesaleOrderRecord>) => {
        if (!params.data) {
          return null
        }

        const { vendorApprovedAt, retailerApprovedAt } = params.data

        const status = getWholesaleOrderStatus({
          vendorApprovedAt,
          retailerApprovedAt,
          isVendor: selectedCompany.type === CompanyType.WHOLESALER,
        })

        if (!status) {
          return null
        }

        return t(`wholesaleOrderStatusTag.${status}`)
      },
      cellRenderer: (params: ICellRendererParams<WholesaleOrderRecord>) => {
        if (params.node.group) {
          return null
        }

        return (
          <Flex gap={8}>
            <WholesaleOrderStatusIcon
              vendorApprovedAt={params.data?.vendorApprovedAt}
              retailerApprovedAt={params.data?.retailerApprovedAt}
            />
            <WholesaleOrderBillingDocumentInvoiceIcon />
            <WholesaleOrderBillingDocumentUnsoldItemsIcon />
          </Flex>
        )
      },
    },
    {
      field: 'totalValue',
      headerName: t('columnDefs.totalValue'),
      aggFunc: 'sum',
      valueGetter: (
        params: ValueGetterParams<WholesaleOrderRecord, WholesaleOrderRecord['totalValue']>
      ) => {
        return roundNumber(params.data?.totalValue.amount ?? 0)
      },
      cellRenderer: (params: ICellRendererParams<WholesaleOrderRecord, number>) => {
        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    {
      headerName: t('columnDefs.createdAt'),
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
      comparator: dateComparator,
      valueGetter: (
        params: ValueGetterParams<WholesaleOrderRecord, WholesaleOrderRecord['createdAt']>
      ) => {
        if (!params.data?.createdAt) {
          return null
        }

        const value = dayjs(params.data.createdAt).toDate()

        return cellGetters.getDateValue(value, 'DD.MM.YYYY HH:mm')
      },
      cellRenderer: (params: ICellRendererParams<WholesaleOrderRecord>) => {
        if (!params.data) {
          return null
        }

        return <FormattedDate date={params.data.createdAt} withRelativeTime layout="horizontal" />
      },
    },
  ])

  return columnDefs
}
