import {
  GetWarehousesAndWholesaleOrderQuery,
  GetWholesaleOrderProductsQuery,
  ProductVariantPackagingUnitType,
} from '../../../../../generated/graphql'
import { StockTableRecord } from '../../../../stock/components/StockTable'
import { AllowedWarehouseLocation } from '../../WholesaleOrdersTable/helpers/prepareCompanyWarehouses'
import { WholesaleOrderFormTableRecord } from '../WholesaleOrderFormTable'

const PALLET_THRESHOLD = 0.7

type BaseRowDataParams = {
  shipToWarehouseLocationUuid: string
  allowedWarehouseLocations: AllowedWarehouseLocation[]
  products: GetWholesaleOrderProductsQuery['products']
}

type ExistingOrderParams = BaseRowDataParams & {
  wholesaleOrder: NonNullable<GetWarehousesAndWholesaleOrderQuery['wholesaleOrder']>
  incomingStock?: never
}

type NewOrderParams = BaseRowDataParams & {
  wholesaleOrder?: never
  incomingStock: StockTableRecord[]
}

type PrepareRowDataParams = ExistingOrderParams | NewOrderParams

export function prepareRowData(params: PrepareRowDataParams): WholesaleOrderFormTableRecord[] {
  try {
    const { shipToWarehouseLocationUuid, allowedWarehouseLocations, products } = params

    const selectedWarehouseLocation = allowedWarehouseLocations.find((location) => {
      return location.uuid === shipToWarehouseLocationUuid
    })
    const availableMarketplaces = selectedWarehouseLocation?.marketplaces ?? []

    // Determine if we're editing an existing order or creating a new one.
    const isExistingOrder = 'wholesaleOrder' in params && params.wholesaleOrder !== undefined

    // For new orders, determine the global packaging unit type.
    const usePackagingUnitType = isExistingOrder
      ? ProductVariantPackagingUnitType.SMALL_PARCEL
      : determineGlobalPackagingUnitType({
          incomingStock: params.incomingStock,
          products,
        })

    // Access these safely now based on the scenario.
    const wholesaleOrder = isExistingOrder ? params.wholesaleOrder : undefined
    const incomingStock = isExistingOrder ? [] : params.incomingStock

    return products.flatMap((product) => {
      if (!product.productVariants) {
        return []
      }

      return product.productVariants.flatMap((productVariant) => {
        return processProductVariant({
          product,
          productVariant,
          availableMarketplaces,
          shipToWarehouseLocationUuid,
          wholesaleOrder,
          incomingStock,
          usePackagingUnitType,
        })
      })
    })
  } catch (error) {
    console.error(error)
    return []
  }
}

function determineGlobalPackagingUnitType({
  incomingStock,
  products,
}: {
  incomingStock: NonNullable<NewOrderParams['incomingStock']>
  products: GetWholesaleOrderProductsQuery['products']
}): ProductVariantPackagingUnitType {
  // Only consider if we have at least one recommended order quantity.
  const hasRecommendedOrderQuantities = incomingStock.some(
    (stock) => stock.recommendedOrderQuantity !== null && stock.recommendedOrderQuantity > 0
  )

  // Default to small parcel unless we determine pallet is better.
  let usePackagingUnitType = ProductVariantPackagingUnitType.SMALL_PARCEL

  // If we have incoming stock with recommended quantities, determine the packaging unit type.
  if (hasRecommendedOrderQuantities) {
    // Check if all products with recommended quantities have pallet options.
    const productVariantUuids = new Set(
      incomingStock
        .filter((stock) => {
          return stock.recommendedOrderQuantity !== null && stock.recommendedOrderQuantity > 0
        })
        .map((stock) => {
          return stock.productVariantUuid
        })
    )

    // Only proceed with pallet check if we have at least one product with recommendation.
    if (productVariantUuids.size > 0) {
      let allProductsHaveEfficientPallets = true

      // Check all products with recommended quantities.
      for (const productVariantUuid of productVariantUuids) {
        if (!canUseEfficientPallets(productVariantUuid, products, incomingStock)) {
          allProductsHaveEfficientPallets = false
          break
        }
      }

      // If all products with recommended quantities can efficiently use pallets, use pallets.
      if (allProductsHaveEfficientPallets) {
        usePackagingUnitType = ProductVariantPackagingUnitType.PALLET
      }
    }
  }

  return usePackagingUnitType
}

function canUseEfficientPallets(
  productVariantUuid: string,
  products: GetWholesaleOrderProductsQuery['products'],
  incomingStock: NonNullable<NewOrderParams['incomingStock']>
): boolean {
  // Find the product variant.
  const product = products.find((product) => {
    return product.productVariants?.some((variant) => variant.uuid === productVariantUuid)
  })

  if (!product) {
    return false
  }

  const variant = product.productVariants?.find((variant) => variant.uuid === productVariantUuid)

  if (!variant) {
    return false
  }

  // Get recommended quantity.
  const stock = incomingStock.find((s) => s.productVariantUuid === productVariantUuid)
  const recommendedQuantity = stock?.recommendedOrderQuantity || 0

  // Check if this product has pallet packaging units.
  const palletPackagingUnits = variant.packagingUnits.filter(
    (unit) => unit.type === ProductVariantPackagingUnitType.PALLET
  )

  if (palletPackagingUnits.length === 0) {
    // No pallet option for this product.
    return false
  }

  // Check if pallets are efficient for this product's recommended quantity.
  // Find the smallest pallet packaging unit.
  let smallestPalletQuantity = Infinity
  for (const unit of palletPackagingUnits) {
    if (unit.quantity < smallestPalletQuantity) {
      smallestPalletQuantity = unit.quantity
    }
  }

  // Check if it's efficient to use pallets - use threshold of 70% capacity.
  const isEfficientForPallet = recommendedQuantity >= smallestPalletQuantity * PALLET_THRESHOLD

  return isEfficientForPallet
}

function processProductVariant({
  product,
  productVariant,
  availableMarketplaces,
  shipToWarehouseLocationUuid,
  wholesaleOrder,
  incomingStock,
  usePackagingUnitType,
}: {
  product: GetWholesaleOrderProductsQuery['products'][number]
  productVariant: NonNullable<
    GetWholesaleOrderProductsQuery['products'][number]['productVariants']
  >[number]
  availableMarketplaces: string[]
  shipToWarehouseLocationUuid: string
  wholesaleOrder?: ExistingOrderParams['wholesaleOrder']
  incomingStock: StockTableRecord[]
  usePackagingUnitType: ProductVariantPackagingUnitType
}): WholesaleOrderFormTableRecord {
  const { attributes, asins, skus, fnskus, mostRecentShipmentDestinations } = extractProductData({
    productVariant,
    availableMarketplaces,
  })

  const existingLineItem = wholesaleOrder?.lineItems.find((lineItem) => {
    return lineItem.productVariant.uuid === productVariant.uuid
  })

  // Determine the net purchase price with the following priority:
  // 1. The net purchase price from the existing line item.
  // 2. The net purchase price from the latest wholesale order.
  // 3. The net purchase price from the product variant.
  const netPurchasePrice =
    existingLineItem?.netPurchasePrice ??
    productVariant.mostRecentWholesaleNetPurchasePrice ??
    productVariant.netPurchasePrice ??
    null

  const matchingStock = productVariant.stock?.find(
    (stock) => stock?.warehouseServiceProviderLocation.uuid === shipToWarehouseLocationUuid
  )

  const sortedPackagingUnits = productVariant.packagingUnits.sort((a, b) => {
    return a.quantity - b.quantity
  })

  // Filter packaging units based on the determined type if we have incoming stock.
  const eligiblePackagingUnits =
    incomingStock.length > 0
      ? sortedPackagingUnits.filter((unit) => unit.type === usePackagingUnitType)
      : sortedPackagingUnits

  const smallestPackagingUnit = sortedPackagingUnits.find((packagingUnit) => {
    return packagingUnit.quantity === 1
  })

  const mainCountryTaxRate = productVariant.applicableTaxRates?.find((item) => {
    return item?.isMainCountry
  })

  // Find matching stock record from incomingStock.
  const matchingIncomingStock = incomingStock.find(
    (stock) => stock.productVariantUuid === productVariant.uuid
  )

  // Select packaging unit and determine quantity.
  const { selectedPackagingUnit, quantityOfPackagingUnits } = selectPackagingUnit({
    existingLineItem,
    eligiblePackagingUnits,
    sortedPackagingUnits,
    matchingIncomingStock,
  })

  return {
    lineItemIdentifier: existingLineItem?.identifier,
    productName: product.name,
    productVariantUuid: productVariant.uuid,
    tags: product.tags ?? [],
    internalSku: productVariant.internalSku ?? null,
    ean: productVariant.ean ?? null,
    attributes: Array.from(attributes),
    asins: Array.from(asins),
    skus: Array.from(skus),
    fnskus: Array.from(fnskus),
    netPurchasePrice,
    packageLength: smallestPackagingUnit?.lengthInCm ?? null,
    packageWidth: smallestPackagingUnit?.widthInCm ?? null,
    packageHeight: smallestPackagingUnit?.heightInCm ?? null,
    packageWeight: smallestPackagingUnit?.weightInGram ?? null,
    availableQuantity: matchingStock?.availableQuantity ?? 0,
    recommendedOrderQuantity: matchingStock?.recommendedReorderQuantity ?? null,
    selectedPackagingUnit,
    quantityOfPackagingUnits,
    packagingUnits: sortedPackagingUnits,
    taxRate: mainCountryTaxRate?.rate ?? null,
    mostRecentShipmentDestinations: Array.from(mostRecentShipmentDestinations),
  }
}

function extractProductData({
  productVariant,
  availableMarketplaces,
}: {
  productVariant: NonNullable<
    GetWholesaleOrderProductsQuery['products'][number]['productVariants']
  >[number]
  availableMarketplaces: string[]
}) {
  const attributes = new Set<string>()
  const asins = new Set<string>()
  const skus = new Set<string>()
  const fnskus = new Set<string>()
  const mostRecentShipmentDestinations = new Set<{
    sku: string
    marketplace: string
    mostRecentShipmentDestination: string
  }>()

  if (productVariant.attributes) {
    for (const attribute of productVariant.attributes) {
      attributes.add(attribute.value)
    }
  }

  if (productVariant.amazonProducts) {
    for (const amazonProduct of productVariant.amazonProducts) {
      asins.add(amazonProduct.asin)

      if (amazonProduct.amazonListings) {
        for (const amazonListing of amazonProduct.amazonListings) {
          // Only include Amazon listings from marketplaces that are available in the selected warehouse location.
          if (!availableMarketplaces.includes(amazonListing.marketplace.name)) {
            continue
          }

          skus.add(amazonListing.sku)

          if (amazonListing.fnsku) {
            fnskus.add(amazonListing.fnsku)
          }

          if (amazonListing.mostRecentShipmentDestination) {
            mostRecentShipmentDestinations.add({
              sku: amazonListing.sku,
              marketplace: amazonListing.marketplace.name,
              mostRecentShipmentDestination: amazonListing.mostRecentShipmentDestination,
            })
          }
        }
      }
    }
  }

  return { attributes, asins, skus, fnskus, mostRecentShipmentDestinations }
}

function selectPackagingUnit({
  existingLineItem,
  eligiblePackagingUnits,
  sortedPackagingUnits,
  matchingIncomingStock,
}: {
  existingLineItem?: NonNullable<ExistingOrderParams['wholesaleOrder']>['lineItems'][number]
  eligiblePackagingUnits: NonNullable<
    GetWholesaleOrderProductsQuery['products'][number]['productVariants']
  >[number]['packagingUnits']
  sortedPackagingUnits: NonNullable<
    GetWholesaleOrderProductsQuery['products'][number]['productVariants']
  >[number]['packagingUnits']
  matchingIncomingStock?: StockTableRecord
}): {
  selectedPackagingUnit?: NonNullable<
    GetWholesaleOrderProductsQuery['products'][number]['productVariants']
  >[number]['packagingUnits'][number]
  quantityOfPackagingUnits?: number
} {
  // Default packaging unit selection.
  let selectedPackagingUnit =
    existingLineItem?.packagingUnit ??
    (eligiblePackagingUnits.length > 0
      ? eligiblePackagingUnits[0]
      : sortedPackagingUnits.find((packagingUnit) => packagingUnit.quantity === 1))

  let quantityOfPackagingUnits = existingLineItem?.quantityOfPackagingUnits

  // If we have a recommended order quantity and no existing line item, find the optimal packaging unit.
  if (matchingIncomingStock?.recommendedOrderQuantity && !existingLineItem) {
    const recommendedQuantity = matchingIncomingStock.recommendedOrderQuantity

    // Find the most efficient packaging unit to minimize package count and excess.
    let bestPackagingUnit = selectedPackagingUnit
    let bestPackagingUnitCount = bestPackagingUnit
      ? Math.ceil(recommendedQuantity / bestPackagingUnit.quantity)
      : Infinity
    const bestTotalQuantity = bestPackagingUnit
      ? bestPackagingUnitCount * bestPackagingUnit.quantity
      : Infinity
    let bestExcess = bestTotalQuantity - recommendedQuantity

    // Try each packaging unit to find the optimal one (only from eligible types).
    for (const packagingUnit of eligiblePackagingUnits) {
      const unitsNeeded = Math.ceil(recommendedQuantity / packagingUnit.quantity)
      const totalQuantity = unitsNeeded * packagingUnit.quantity
      const excess = totalQuantity - recommendedQuantity

      // Prioritize fewer packaging units first.
      if (unitsNeeded < bestPackagingUnitCount) {
        bestPackagingUnitCount = unitsNeeded
        bestPackagingUnit = packagingUnit
        bestExcess = excess
      }
      // If same number of packages, prefer the one with less excess.
      else if (unitsNeeded === bestPackagingUnitCount && excess < bestExcess) {
        bestPackagingUnit = packagingUnit
        bestExcess = excess
      }
    }

    // Update with the best packaging unit found.
    if (bestPackagingUnit) {
      selectedPackagingUnit = bestPackagingUnit
      quantityOfPackagingUnits = bestPackagingUnitCount
    }
  }
  // If no recommended quantity or already have a line item, use the old calculation.
  else if (
    matchingIncomingStock?.recommendedOrderQuantity &&
    !quantityOfPackagingUnits &&
    selectedPackagingUnit
  ) {
    quantityOfPackagingUnits = Math.ceil(
      matchingIncomingStock.recommendedOrderQuantity / (selectedPackagingUnit.quantity || 1)
    )
  }

  return { selectedPackagingUnit, quantityOfPackagingUnits }
}
