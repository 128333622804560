import { Button, Flex, Form, FormInstance, Popover, Space } from 'antd'
import { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'remeda'
import { LabelType, ShipmentType } from '../../../../generated/graphql'
import { formatDecimal } from '../../../../helpers/formatDecimal'
import { ShipmentKind } from '../types'
import { AmazonInboundShipmentPlanCreateFormInstance } from './AmazonInboundShipmentPlanCreateForm'
import { AmazonInboundShipmentPlanStep } from './AmazonInboundShipmentPlanCreateFormCollapse'
import { AmazonInboundShipmentPlanQuantitySectionFaq } from './AmazonInboundShipmentPlanQuantitySectionFaq'
import {
  AmazonInboundShipmentPlanQuantityTable,
  MAX_ALLOWED_PACKAGE_WEIGHT_IN_KG,
} from './AmazonInboundShipmentPlanQuantityTable'

type AmazonInboundShipmentPlanQuantitySectionProps = {
  setCurrentStep: (currentStep: SetStateAction<AmazonInboundShipmentPlanStep>) => void
}

export const AmazonInboundShipmentPlanQuantitySection = ({
  setCurrentStep,
}: AmazonInboundShipmentPlanQuantitySectionProps) => {
  const { t } = useTranslation('inventory')
  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  const handleBackClick = () => {
    setCurrentStep(AmazonInboundShipmentPlanStep.INVENTORY)
  }

  const handleNextClick = async () => {
    try {
      const cartons = synchronizeCartons(form)
      form.setFieldsValue({ cartons })
      setCurrentStep(AmazonInboundShipmentPlanStep.CARTON)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Flex vertical gap={8}>
      <AmazonInboundShipmentPlanQuantitySectionFaq />
      <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
        <AmazonInboundShipmentPlanQuantityTable />
      </Form.Item>
      <Flex justify="end">
        {/* <AmazonInboundShipmentGroupPreviewModal /> */}
        <Space>
          <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
            <Button onClick={handleBackClick}>
              {t('shared.button.back', { ns: 'translation' })}
            </Button>
          </Form.Item>
          <Form.Item<AmazonInboundShipmentPlanCreateFormInstance>
            shouldUpdate={(prevValues, nextValues) => {
              return !R.isDeepEqual(prevValues.selectedInventory, nextValues.selectedInventory)
            }}
            noStyle
          >
            {() => {
              const { hasQuantities, hasCartonTypes, hasMixedLabelTypes, hasOverweightCartons } =
                validateSection(form)

              const disabled =
                !hasQuantities || !hasCartonTypes || hasMixedLabelTypes || hasOverweightCartons

              const popoverContent = !hasQuantities
                ? t('amazonInboundShipmentPlan.create.form.quantity.popover.missingQuantities')
                : !hasCartonTypes
                  ? t('amazonInboundShipmentPlan.create.form.quantity.popover.missingCartonTypes')
                  : hasMixedLabelTypes
                    ? t('amazonInboundShipmentPlan.create.form.quantity.popover.hasMixedLabelTypes')
                    : hasOverweightCartons
                      ? t(
                          'amazonInboundShipmentPlan.create.form.quantity.popover.hasOverweightCarton',
                          { weight: formatDecimal(MAX_ALLOWED_PACKAGE_WEIGHT_IN_KG) }
                        )
                      : undefined

              return (
                <Popover content={popoverContent} placement="topRight">
                  <Button type="primary" disabled={disabled} onClick={handleNextClick}>
                    {t('shared.button.next', { ns: 'translation' })}
                  </Button>
                </Popover>
              )
            }}
          </Form.Item>
        </Space>
      </Flex>
    </Flex>
  )
}

function synchronizeCartons(form: FormInstance<AmazonInboundShipmentPlanCreateFormInstance>) {
  const shipmentKind: AmazonInboundShipmentPlanCreateFormInstance['shipmentKind'] =
    form.getFieldValue('shipmentKind')
  const selectedInventory: AmazonInboundShipmentPlanCreateFormInstance['selectedInventory'] =
    form.getFieldValue('selectedInventory')
  const currentCartons: AmazonInboundShipmentPlanCreateFormInstance['cartons'] =
    form.getFieldValue('cartons')

  if (shipmentKind === ShipmentKind.CASE) {
    const newCartons = selectedInventory.map((item) => ({
      cartonType: item.uuid,
      cartonLength: item.cartonLength,
      cartonWidth: item.cartonWidth,
      cartonHeight: item.cartonHeight,
      cartonGrossWeight: item.cartonGrossWeight,
      numberOfCartons: item.numberOfPackagingUnits!,
      items: [
        {
          amazonListingUuid: item.uuid,
          image: item.image,
          name: item.name,
          ean: item.ean,
          asin: item.asin,
          fnsku: item.fnsku,
          sku: item.sku,
          internalSku: item.internalSku,
          labelType: item.labelType,
          labeledByAmazon: item.labeledByAmazon,
          quantityInPackagingUnit: item.quantityInPackagingUnit!,
          numberOfPackagingUnits: item.numberOfPackagingUnits!,
          bestBeforeDate: item.bestBeforeDate,
          smallestPackagingUnit: item.smallestPackagingUnit,
        },
      ],
    }))

    const updatedCartons = newCartons.map((newCarton) => {
      const currentCarton = currentCartons.find((currentCarton) => {
        return R.isDeepEqual(currentCarton.items, newCarton.items)
      })

      return currentCarton ?? newCarton
    })

    return updatedCartons
  } else {
    const groupedInventory = R.groupBy(selectedInventory, (item) => item.cartonType)

    const newCartons = Object.keys(groupedInventory).map((cartonType) => {
      const items = groupedInventory[cartonType] ?? []

      return {
        cartonType,
        numberOfCartons: 1,
        items: items.map((item) => ({
          amazonListingUuid: item.uuid,
          image: item.image,
          name: item.name,
          ean: item.ean,
          asin: item.asin,
          fnsku: item.fnsku,
          sku: item.sku,
          internalSku: item.internalSku,
          labelType: item.labelType,
          labeledByAmazon: item.labeledByAmazon,
          quantityInPackagingUnit: item.quantityInPackagingUnit!,
          numberOfPackagingUnits: item.numberOfPackagingUnits!,
          bestBeforeDate: item.bestBeforeDate,
          smallestPackagingUnit: item.smallestPackagingUnit,
        })),
      }
    })

    const updatedCartons = newCartons.map((newCarton) => {
      const currentCarton = currentCartons.find((currentCarton) => {
        return R.isDeepEqual(currentCarton.items, newCarton.items)
      })

      return currentCarton ?? newCarton
    })

    return updatedCartons
  }
}

function validateSection(form: FormInstance<AmazonInboundShipmentPlanCreateFormInstance>) {
  const shipmentKind: AmazonInboundShipmentPlanCreateFormInstance['shipmentKind'] =
    form.getFieldValue('shipmentKind')
  const shippingMode: AmazonInboundShipmentPlanCreateFormInstance['shippingMode'] =
    form.getFieldValue('shippingMode')
  const selectedInventory: AmazonInboundShipmentPlanCreateFormInstance['selectedInventory'] =
    form.getFieldValue('selectedInventory')

  const hasQuantities = selectedInventory.every((item) => {
    return item.quantityInPackagingUnit && item.numberOfPackagingUnits
  })

  const hasCartonTypes =
    shipmentKind === ShipmentKind.CASE ? true : selectedInventory.every((item) => item.cartonType)

  const cartons = synchronizeCartons(form)

  const hasMixedLabelTypes = cartons.some((carton) => {
    const hasEan = carton.items.some((item) => item.labelType === LabelType.EAN)
    const hasFnsku = carton.items.some((item) => item.labelType === LabelType.FNSKU)

    return hasEan && hasFnsku
  })

  const hasOverweightCartons =
    shippingMode === ShipmentType.LTL
      ? false
      : cartons.some((carton) => {
          const totalPackageWeight = carton.items.reduce((previousValue, currentValue) => {
            if (!currentValue.smallestPackagingUnit.weightInGram) {
              return previousValue
            }

            const kilograms = currentValue.smallestPackagingUnit.weightInGram / 1000

            return previousValue + currentValue.quantityInPackagingUnit * kilograms
          }, 0)

          const hasCartonItemExceedingMaxWeight = carton.items.some((item) => {
            return (
              item.smallestPackagingUnit.weightInGram &&
              item.smallestPackagingUnit.weightInGram > MAX_ALLOWED_PACKAGE_WEIGHT_IN_KG * 1000
            )
          })

          // If the product exceeds the maximum weight, the weight limit does not apply.
          if (hasCartonItemExceedingMaxWeight) {
            return false
          }

          return totalPackageWeight > MAX_ALLOWED_PACKAGE_WEIGHT_IN_KG
        })

  return { hasQuantities, hasCartonTypes, hasMixedLabelTypes, hasOverweightCartons }
}
