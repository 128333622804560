import { useQuery } from '@apollo/client'
import { Button, Col, DatePicker, Row, Select, Space } from 'antd'
import dayjs from 'dayjs'
import { Download, Plus } from 'lucide-react'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { LinkButton } from '../../components/LinkButton'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import PPCCampaignTable from '../../components/PPC/PPCCampaignTable/PPCCampaignTable'
import { PPCPerformanceCard } from '../../components/PPC/layout/PPCPerformanceCard/PPCPerformanceCard'
import {
  AmazonPpcCampaignType,
  GetAllPpcCampaignReportsAndLiveDataDocument,
  GetAllPpcCampaignReportsAndLiveDataQuery,
  Marketplace,
} from '../../generated/graphql'
import { exportCsvFile } from '../../helpers/exportFile'
import { getMarketplaceDomain } from '../../helpers/getMarketplaceDomain'
import { useDateRangePresets } from '../../hooks/useDateRangePresets'
import { useMarketplaceOptions } from '../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { combineCampaignLiveDataAndReports } from './helpers'
import { AggregatedCampaign } from './helpers/interfaces'
import ppcStateStore from './ppcStateStore'

const { RangePicker } = DatePicker

export type GetSponsoredProductsCampaignLiveDataModel =
  GetAllPpcCampaignReportsAndLiveDataQuery['sponsoredProductsCampaignLiveData'][number]
export type GetSponsoredProductsCampaignReportsModel =
  GetAllPpcCampaignReportsAndLiveDataQuery['sponsoredProductsCampaignReports'][number]
export type GetSponsoredBrandsCampaignLiveDataModel =
  GetAllPpcCampaignReportsAndLiveDataQuery['sponsoredBrandsCampaignLiveData'][number]
export type GetSponsoredBrandsCampaignReportsModel =
  GetAllPpcCampaignReportsAndLiveDataQuery['sponsoredBrandsCampaignReports'][number]
export type GetSponsoredDisplayCampaignLiveDataModel =
  GetAllPpcCampaignReportsAndLiveDataQuery['sponsoredDisplayCampaignLiveData'][number]
export type GetSponsoredDisplayCampaignReportsModel =
  GetAllPpcCampaignReportsAndLiveDataQuery['sponsoredDisplayCampaignReports'][number]

export const PPCCampaignListView = () => {
  const [aggregatedCampaigns, setAggregatedCampaigns] = useState<AggregatedCampaign[]>([])

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { setMarketplace, ppcStartDate, setPpcStartDate, ppcEndDate, setPpcEndDate } =
    ppcStateStore()

  const { t } = useTranslation()

  const navigate = useNavigate()
  const params = useParams<{ marketplace: string }>()
  const marketplace = params.marketplace?.toUpperCase() as Marketplace

  const marketplaceOptions = useMarketplaceOptions()
  const presets = useDateRangePresets()

  const { loading, error, data } = useQuery(GetAllPpcCampaignReportsAndLiveDataDocument, {
    skip: !selectedCompany.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
      marketplace,
      startDate: ppcStartDate,
      endDate: ppcEndDate,
    },
  })

  useEffect(() => {
    if (data) {
      const combinedSponsoredProductsLiveDataAndReports: AggregatedCampaign[] =
        combineCampaignLiveDataAndReports(
          AmazonPpcCampaignType.SPONSOREDPRODUCTS,
          data.sponsoredProductsCampaignLiveData || [],
          data.sponsoredProductsCampaignReports || []
        )

      const combinedSponsoredBrandsLiveDataAndReports: AggregatedCampaign[] =
        combineCampaignLiveDataAndReports(
          AmazonPpcCampaignType.SPONSOREDBRANDS,
          data.sponsoredBrandsCampaignLiveData || [],
          data.sponsoredBrandsCampaignReports || []
        )

      const combinedSponsoredDisplayLiveDataAndReports: AggregatedCampaign[] =
        combineCampaignLiveDataAndReports(
          AmazonPpcCampaignType.SPONSOREDDISPLAY,
          data.sponsoredDisplayCampaignLiveData || [],
          data.sponsoredDisplayCampaignReports || []
        )

      const combinedAggregatedCampaigns = [
        ...combinedSponsoredProductsLiveDataAndReports,
        ...combinedSponsoredBrandsLiveDataAndReports,
        ...combinedSponsoredDisplayLiveDataAndReports,
      ]

      setAggregatedCampaigns(combinedAggregatedCampaigns)
    }
  }, [data])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error || !data) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  const updateCampaignList = (updatedCampaign: any) => {
    const aggregatedCampaignsCopy = [...aggregatedCampaigns]

    const { campaignId, ...campaign } = updatedCampaign

    if (campaignId) {
      aggregatedCampaignsCopy?.forEach((existingCampaign: any) => {
        if (existingCampaign.campaignId === campaignId.toString()) {
          Object.keys(campaign).forEach((key) => {
            existingCampaign[key] = campaign[key]
          })
        }
      })
    }

    setAggregatedCampaigns(aggregatedCampaignsCopy)
  }

  const handleMarketplaceChange = (value: Marketplace) => {
    navigate(`/amazon/ppc/${value.toLowerCase()}`)
    setMarketplace(value.toLowerCase())
  }

  const rightContent = (
    <Space>
      <Button
        icon={<Download size={16} />}
        onClick={() =>
          exportCsvFile(
            aggregatedCampaigns,
            `Amazon PPC Campaigns (${marketplace}) (${ppcStartDate} to ${ppcEndDate})`
          )
        }
      />
      <Select<Marketplace>
        value={marketplace}
        onChange={handleMarketplaceChange}
        popupMatchSelectWidth={false}
        placement="bottomRight"
        options={marketplaceOptions.map((marketplace) => ({
          value: marketplace,
          label: `Amazon.${getMarketplaceDomain(marketplace as unknown as Marketplace)}`,
        }))}
      />
      <RangePicker
        value={[dayjs(ppcStartDate), dayjs(ppcEndDate)]}
        onCalendarChange={(values) => {
          if (values[0]) {
            setPpcStartDate(values[0].format('YYYY-MM-DD'))
          }

          if (values[1]) {
            setPpcEndDate(values[1].format('YYYY-MM-DD'))
          }
        }}
        allowClear={false}
        disabled={loading}
        disabledDate={(date) =>
          date.isAfter(dayjs()) || date.isBefore(dayjs().subtract(6, 'month'))
        }
        format="DD.MM.YY"
        placement="bottomRight"
        presets={presets}
      />
    </Space>
  )

  return (
    <>
      <SubHeader heading={t('ppc.heading')} rightContent={rightContent} />
      <ViewContainer>
        <Row justify="space-between" gutter={[8, 8]}>
          <Col>
            <Button
              type="primary"
              href="https://app.bidx.io/login"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => mixpanel.track('Navigated to BidX')}
            >
              {t('ppc.bidX')}
            </Button>
          </Col>
          <Col>
            <LinkButton
              icon={<Plus size={16} />}
              to={`/amazon/ppc/${params.marketplace}/sp/campaigns/create`}
            >
              Create SP campaign
            </LinkButton>
          </Col>
        </Row>
        <PPCCampaignTable
          aggregatedCampaigns={aggregatedCampaigns}
          updateCampaignList={updateCampaignList}
        />
      </ViewContainer>
      <PPCPerformanceCard data={aggregatedCampaigns} />
    </>
  )
}
