import { useMutation } from '@apollo/client'
import { Button, Descriptions, Modal, Typography } from 'antd'
import { Trash } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import i18n from '../../../../../config/i18n'
import {
  GetAmazonListingDocument,
  GetAmazonListingQuery,
  InternalAmazonListingStatus,
  SendSupportTicketDocument,
  UpdateAmazonListingDocument,
} from '../../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../../helpers/getMarketplaceDomain'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { createZendeskTicketOptions } from '../../../../ticket/helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../../../../ticket/ticket.enum'

type DeleteAmazonListingModalProps = {
  amazonListing: GetAmazonListingQuery['amazonListing']
}

export const DeleteAmazonListingModal = ({ amazonListing }: DeleteAmazonListingModalProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()
  const { t } = useTranslation(['inventory', 'translation'])

  const [updateAmazonListing, { loading: updateAmazonListingLoading }] = useMutation(
    UpdateAmazonListingDocument
  )
  const [sendSupportTicket, { loading: sendSupportTicketLoading }] =
    useMutation(SendSupportTicketDocument)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleOk = async () => {
    try {
      if (!cognitoUser || !selectedCompany) {
        throw new Error()
      }

      const { data } = await updateAmazonListing({
        variables: {
          uuid: amazonListing.uuid,
          amazonListing: { internalStatus: InternalAmazonListingStatus.DELETED },
        },
        refetchQueries: [
          {
            query: GetAmazonListingDocument,
            variables: {
              uuid: amazonListing.uuid,
            },
          },
        ],
      })

      if (data?.updateAmazonListing) {
        const comment = `
        <h3>${t('listingDeleteModal.creationText.createdNote', { ns: 'ticket' })}</h3>
        &nbsp;
        <ul>
          <li>
            <b>${t('listingDeleteModal.creationText.amazonListingDetails.title', {
              ns: 'ticket',
            })}</b>
            ${amazonListing.title ? amazonListing.title : 'N/A'}
          </li>
          <li>
            <b>${t('listingDeleteModal.creationText.amazonListingDetails.sku', {
              ns: 'ticket',
            })}</b>
            ${amazonListing.sku}
          </li>
          <li>
            <b>${t('listingDeleteModal.creationText.amazonListingDetails.marketplace', {
              ns: 'ticket',
            })}</b>
            ${amazonListing.marketplace.name}
          </li>      
        </ul>    
        `

        const options = createZendeskTicketOptions({
          subject: `${t('listingDeleteModal.subject', { ns: 'ticket' })}${amazonListing.sku}`,
          comment,
          categoryTitle: 'amazon',
          mainCategory: TicketMainCategory.INVENTORY,
          ticketCategory: TicketCategory.INVENTORY_DELETE_LISTING,
          sellerId: selectedCompany?.sellerId,
          language: i18n.language,
        })

        await sendSupportTicket({
          variables: {
            cognitoUsername: cognitoUser.getUsername(),
            companyUuid: selectedCompany.uuid,
            input: options,
          },
        })
      }

      toast.success(t('product.details.amazonListings.delete.modal.success'))
      setTimeout(() => {
        navigate('/amazon/products')
      }, 300)
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setOpen(false)
    }
  }

  return (
    <>
      <Button type="primary" danger icon={<Trash size={16} />} onClick={openModal}>
        {t('product.details.amazonListings.delete.button')}
      </Button>
      <Modal
        title={t('product.details.amazonListings.delete.modal.title')}
        open={open}
        okText={t('shared.button.delete', { ns: 'translation' })}
        onOk={handleOk}
        okButtonProps={{ danger: true }}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={closeModal}
        cancelButtonProps={{ disabled: updateAmazonListingLoading || sendSupportTicketLoading }}
        confirmLoading={updateAmazonListingLoading || sendSupportTicketLoading}
      >
        <Typography.Paragraph>
          {t('product.details.amazonListings.delete.modal.description')}
        </Typography.Paragraph>
        <Descriptions bordered column={1} size="small">
          <Descriptions.Item label={t('product.details.amazonProduct.asin')}>
            {amazonListing.amazonProduct.asin}
          </Descriptions.Item>
          <Descriptions.Item label={t('product.details.amazonListings.delete.modal.sku')}>
            {amazonListing.sku}
          </Descriptions.Item>
          <Descriptions.Item label={t('product.details.amazonListings.delete.modal.marketplace')}>
            Amazon.{getMarketplaceDomain(amazonListing.marketplace.name)}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  )
}
