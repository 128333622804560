import { useMutation } from '@apollo/client'
import { Alert, Button, Form, Row, Switch, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { toast } from 'sonner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import QuillTextarea from '../../../components/Ticket/QuillTextarea'
import { UploadZendeskFile } from '../../../components/Ticket/UploadFile'
import { SendSupportTicketDocument } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { DocumentNumberWithIssueDynamicInput } from '../components/form/DocumentNumberWithIssueDynamicInput'
import { PersonalNoteInput } from '../components/form/PersonalNoteInput'
import { createZendeskTicketOptions } from '../helpers/Zendesk.helper'
import { TicketCreationCard } from '../styles/TicketCreateViewStyles'
import { TicketCategory, TicketMainCategory } from '../ticket.enum'
import { AttachmentProps } from '../ticket.interface'

type FormInstance = {
  data: [DocumentNumberWithIssue]
  confirmation_switch: boolean
  messageInput: string
  personalNotes: string
}

type DocumentNumberWithIssue = {
  documentNumber: string
  issue: string
}

export const BillingPaymentsTicketView = () => {
  const [confirmationSwitchValue, setConfirmationSwitchValue] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const location = useLocation()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()

  const { t, ready, i18n } = useTranslation(['ticket'], {
    keyPrefix: 'billing.billingPaymentsTicketView',
  })
  const { t: generalTranslation } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [form] = Form.useForm()

  const [sendSupportTicketRequest, { loading }] = useMutation(SendSupportTicketDocument)

  if (!ready) {
    return <LoadingSpinner />
  }

  const initialValues: FormInstance = {
    data: [
      {
        documentNumber: location.state.bankTransactionId ? location.state.bankTransactionId : null,
        issue: '',
      },
    ],
    messageInput: '',
    confirmation_switch: false,
    personalNotes: '',
  }

  const onFinish = async (values: FormInstance) => {
    const tokens = attachments.map((attachment) => {
      return attachment.token
    })

    const createCommentSection = (messageInput: string | undefined) => {
      if (messageInput) {
        return `
          <div>
            <h3>${t('form.comments.label')}</h3>
            <p>${messageInput}</p>
          </div>
        `
      } else {
        return ''
      }
    }

    const createIssueListSection = (data: DocumentNumberWithIssue[]) => {
      if (data.length === 0) {
        return []
      }

      return data.map((item) => {
        return `
          <li>
            <p><b>${t('form.documentNumberInput.placeholder')}</b></p>
            <p>${item.documentNumber}</p>
            <p><b>${t('form.issueInput.placeholder')}</b></p>
            <p>${item.issue}</p>
            &nbsp;
          </li>
        `
      })
    }

    const message = `
      <h1>${t('ticketDescriptionTitle')}</h1>
      <ul style={{listStyleType: 'none'}}>${createIssueListSection(values.data).join('')}</ul>
      ${createCommentSection(values.messageInput)}
    `

    const options = createZendeskTicketOptions({
      categoryTitle: location.state.categoryTitleTag,
      comment: message,
      language: i18n.language,
      mainCategory: TicketMainCategory.BILLING,
      subject: t('viewTitle'),
      ticketCategory: TicketCategory.BILLING_PAYMENT,
      sellerId: selectedCompany?.sellerId,
      personalNotes: values.personalNotes,
      attachments: tokens,
    })

    try {
      if (selectedCompany !== null && selectedCompany.uuid) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })
        toast.success(generalTranslation('notification.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      console.error(error)
      toast.error(generalTranslation('notification.error'), { description: error.message })
    }
  }

  const Description = () => (
    <>
      <Typography.Paragraph>{t('ticketDescriptionSubtitle')}</Typography.Paragraph>
      <ul>
        <li>{t('mainListItems.payoutDate1.title')}</li>
        <li>{t('mainListItems.payoutDate2.title')}</li>
      </ul>
      <Alert type="warning" message={t('notes.warningNote')} style={{ margin: '1rem 0' }} />
      <Typography.Paragraph>{t('notes.sendInfo')}</Typography.Paragraph>
      <Typography.Paragraph>{t('spacegoatsSignature')}</Typography.Paragraph>
    </>
  )

  return (
    <>
      <SubHeader withBackButton />
      <ViewContainer centered>
        <TicketCreationCard title={t('ticketDescriptionTitle')}>
          <Description />
          <Form
            name="billing-payments-ticket"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            layout="vertical"
          >
            <DocumentNumberWithIssueDynamicInput
              translation={useTranslation(['ticket'], {
                keyPrefix: 'billing.billingPaymentsTicketView.form',
              })}
            />
            <Form.Item name="messageInput" label={t('form.comments.label')}>
              <QuillTextarea />
            </Form.Item>
            <PersonalNoteInput />
            <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
            <Form.Item
              name="confirmation_switch"
              label={t('confirmationText')}
              valuePropName="checked"
              rules={[{ required: true }]}
            >
              <Switch onChange={setConfirmationSwitchValue} />
            </Form.Item>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!confirmationSwitchValue}
                loading={loading}
              >
                {t('sendButtonText')}
              </Button>
            </Row>
          </Form>
        </TicketCreationCard>
      </ViewContainer>
    </>
  )
}
