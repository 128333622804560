import { useQuery } from '@apollo/client'
import { Alert, Flex, Form, Select, SelectProps, Typography } from 'antd'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router'
import {
  GetWholesaleOrdersForImportDocument,
  GetWholesaleOrdersForImportQuery,
  Marketplace,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { AvailableInventory } from '../helpers/prepareAvailableInventory'
import { validateAndGetShipmentPlanInventory } from '../helpers/validateAndGetShipmentPlanInventory'
import {
  AmazonInboundShipmentPlanCreateFormInstance,
  SelectedInventory,
} from './AmazonInboundShipmentPlanCreateForm'
import { AmazonInboundShipmentPlanImportErrorsAlert } from './AmazonInboundShipmentPlanImportErrorsAlert'
import { ImportError } from './AmazonInboundShipmentPlanImportModal'

export type AmazonInboundShipmentPlanWholesaleOrderImportRecord = {
  sku: string
  quantity: number
  numberOfPackagingUnits: number
  cartonLength: number | null
  cartonWidth: number | null
  cartonHeight: number | null
  cartonGrossWeight: number | null
}

type AmazonInboundShipmentPlanWholesaleOrderImportProps = {
  setWholesaleOrderData: Dispatch<
    SetStateAction<
      | {
          shipFromWarehouseLocationUuid: string
          lineItems: AmazonInboundShipmentPlanWholesaleOrderImportRecord[]
          wholesaleOrderIdentifier: string
        }
      | undefined
    >
  >
  importedInventory: SelectedInventory[]
  setImportedInventory: Dispatch<SetStateAction<SelectedInventory[]>>
  errors: ImportError[]
  setErrors: Dispatch<SetStateAction<ImportError[]>>
}

export const AmazonInboundShipmentPlanWholesaleOrderImport = ({
  setWholesaleOrderData,
  importedInventory,
  setImportedInventory,
  errors,
  setErrors,
}: AmazonInboundShipmentPlanWholesaleOrderImportProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  const { t } = useTranslation(['inventory', 'translation'])
  const [searchParams] = useSearchParams()
  const marketplace = searchParams.get('marketplace') as Marketplace

  const { loading, data } = useQuery(GetWholesaleOrdersForImportDocument, {
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  const wholesaleOrders = data
    ? prepareWholesaleOrdersForSelection(data.wholesaleOrders, marketplace)
    : []

  const handleSelect: SelectProps<string>['onSelect'] = (value) => {
    setErrors([])
    const selectedWholesaleOrder = wholesaleOrders.find(
      (wholesaleOrder) => wholesaleOrder.identifier === value
    )

    if (!selectedWholesaleOrder) {
      return
    }

    const shipFromWarehouseLocationUuid = selectedWholesaleOrder.shipFromWarehouseLocationUuid
    const lineItems = prepareLineItemsForImport(selectedWholesaleOrder.lineItems, marketplace)
    const wholesaleOrderIdentifier = selectedWholesaleOrder.identifier
    const availableInventory: AvailableInventory[] = form.getFieldValue('availableInventory')

    const processedInventory = processWholesaleOrderData(
      { lineItems },
      availableInventory,
      setErrors,
      t
    )

    setWholesaleOrderData({
      shipFromWarehouseLocationUuid,
      lineItems,
      wholesaleOrderIdentifier,
    })

    setImportedInventory(processedInventory)
  }

  return (
    <Flex vertical gap={16}>
      <Typography.Text>
        {t('amazonInboundShipmentPlan.import.wholesale-order.description')}
      </Typography.Text>
      <Select<string>
        options={wholesaleOrders.map((wholesaleOrder) => ({
          label: wholesaleOrder.identifier,
          value: wholesaleOrder.identifier,
        }))}
        placeholder={
          loading
            ? t('shared.form.placeholder.loading', { ns: 'translation' })
            : t('shared.form.placeholder.select', { ns: 'translation' })
        }
        disabled={loading}
        loading={loading}
        onSelect={handleSelect}
      />
      {importedInventory && importedInventory.length > 0 && (
        <Alert
          type="success"
          message={t('amazonInboundShipmentPlan.import.success.alert.message')}
          description={t('amazonInboundShipmentPlan.import.success.alert.description', {
            count: importedInventory.length,
          })}
        />
      )}
      <AmazonInboundShipmentPlanImportErrorsAlert errors={errors} />
    </Flex>
  )
}

function prepareWholesaleOrdersForSelection(
  wholesaleOrders: GetWholesaleOrdersForImportQuery['wholesaleOrders'],
  marketplace: Marketplace
) {
  return (
    wholesaleOrders
      .flatMap((wholesaleOrder) => {
        const salesChannelRegion = wholesaleOrder.shippedToWarehouseLocation.salesChannelRegion

        if (
          !salesChannelRegion ||
          salesChannelRegion.salesChannel.name !== 'Amazon' ||
          !marketplace ||
          !salesChannelRegion.countries.includes(marketplace)
        ) {
          return []
        }

        const hasAmazonListings = wholesaleOrder.lineItems.every((lineItem) => {
          const productVariant = lineItem.productVariant
          const amazonProducts = productVariant.amazonProducts ?? []

          if (amazonProducts.length === 0) {
            return false
          }

          return amazonProducts.some((amazonProduct) => {
            const amazonListings = amazonProduct.amazonListings ?? []

            return amazonListings.some((amazonListing) => {
              return amazonListing.marketplace.name === marketplace
            })
          })
        })

        if (!hasAmazonListings) {
          return []
        }

        return {
          identifier: wholesaleOrder.identifier,
          shipFromWarehouseLocationUuid: wholesaleOrder.shippedFromWarehouseLocation.uuid,
          lineItems: wholesaleOrder.lineItems,
          createdAt: wholesaleOrder.createdAt,
        }
      })
      .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
      .slice(0, 5) ?? []
  )
}

function prepareLineItemsForImport(
  lineItems: GetWholesaleOrdersForImportQuery['wholesaleOrders'][number]['lineItems'],
  marketplace: Marketplace
) {
  return lineItems.flatMap((lineItem) => {
    const productVariant = lineItem.productVariant
    const amazonProducts = productVariant.amazonProducts ?? []
    const amazonListings = amazonProducts.flatMap((amazonProduct) => {
      const amazonListings = amazonProduct.amazonListings ?? []

      if (amazonListings.length === 0) {
        return []
      }

      return amazonListings.filter((amazonListing) => {
        return amazonListing.marketplace.name === marketplace
      })
    })

    return amazonListings.map((amazonListing) => {
      return {
        sku: amazonListing.sku,
        quantity: lineItem.packagingUnit.quantity,
        numberOfPackagingUnits: lineItem.quantityOfPackagingUnits,
        cartonLength: lineItem.packagingUnit.lengthInCm ?? null,
        cartonWidth: lineItem.packagingUnit.widthInCm ?? null,
        cartonHeight: lineItem.packagingUnit.heightInCm ?? null,
        cartonGrossWeight: lineItem.packagingUnit.weightInGram
          ? lineItem.packagingUnit.weightInGram / 1000
          : null,
      }
    })
  })
}

const processWholesaleOrderData = (
  wholesaleOrderData:
    | {
        lineItems: AmazonInboundShipmentPlanWholesaleOrderImportRecord[]
      }
    | undefined,
  availableInventory: AvailableInventory[],
  setErrors: Dispatch<SetStateAction<ImportError[]>>,
  t: TFunction<'inventory'>
) => {
  if (!wholesaleOrderData) {
    return []
  }

  const inventoryMap = new Map(availableInventory.map((inventory) => [inventory.sku, inventory]))
  const errors: ImportError[] = []
  const selectedInventory: SelectedInventory[] = []

  wholesaleOrderData.lineItems.forEach((currentValue, currentIndex) => {
    const validation = validateAndGetShipmentPlanInventory(
      currentIndex,
      currentValue.sku,
      inventoryMap,
      t
    )

    if (validation.error) {
      const existingError = errors.find((e) => e.sku === validation.error.sku)
      if (!existingError) {
        errors.push(validation.error)
      }
      return
    }

    if (!validation.result) {
      return
    }

    const { matchingInventory, smallestPackagingUnit } = validation.result

    selectedInventory.push({
      ...matchingInventory,
      labeledByAmazon: false,
      quantityInPackagingUnit: currentValue.quantity,
      numberOfPackagingUnits: currentValue.numberOfPackagingUnits,
      smallestPackagingUnit,
      cartonLength: currentValue.cartonLength ?? undefined,
      cartonWidth: currentValue.cartonWidth ?? undefined,
      cartonHeight: currentValue.cartonHeight ?? undefined,
      cartonGrossWeight: currentValue.cartonGrossWeight ?? undefined,
      packagingUnits: matchingInventory.packagingUnits,
      prepInstructions: matchingInventory.prepInstructions,
      recommendedQuantity: matchingInventory.recommendedQuantity,
    })
  })

  setErrors(errors)
  return selectedInventory
}
