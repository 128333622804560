import { useMutation, useQuery } from '@apollo/client'
import { Button, Col, Flex, Row } from 'antd'
import dayjs from 'dayjs'
import { X } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'sonner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  GetAmazonInboundShipmentPlanDocument,
  GetAmazonInboundShipmentPlanQuery,
  InternalShipmentPlanStatus,
  UpdateAmazonInboundShipmentPlanInternalStatusDocument,
} from '../../../generated/graphql'
import { AmazonInboundShipmentPlanContentInCasesTable } from './components/AmazonInboundShipmentPlanContentInCasesTable'
import { AmazonInboundShipmentPlanContentTable } from './components/AmazonInboundShipmentPlanContentTable'
import { AmazonInboundShipmentPlanDeliveryDetails } from './components/AmazonInboundShipmentPlanDeliveryDetails'
import { AmazonInboundShipmentPlanDocumentsCard } from './components/AmazonInboundShipmentPlanDocumentsCard'
import { AmazonInboundShipmentPlanLabelDetails } from './components/AmazonInboundShipmentPlanLabelDetails'
import { AmazonInboundShipmentPlanTransportDetails } from './components/AmazonInboundShipmentPlanTransportDetails'

export type AmazonInboundShipmentPlan =
  GetAmazonInboundShipmentPlanQuery['amazonInboundShipmentPlan']
export type AmazonInboundShipment = NonNullable<
  AmazonInboundShipmentPlan['inboundShipments']
>[number]

export const AmazonInboundShipmentPlanView = () => {
  const [loadingCancel, setLoadingCancel] = useState(false)

  const params = useParams<{ uuid: string }>()

  const { loading, error, data } = useQuery(GetAmazonInboundShipmentPlanDocument, {
    fetchPolicy: 'cache-first',
    skip: !params.uuid,
    variables: {
      amazonInboundShipmentPlanUuid: params.uuid!,
    },
  })

  const [updateShipmentInternalStatus] = useMutation(
    UpdateAmazonInboundShipmentPlanInternalStatusDocument
  )

  const { t } = useTranslation('inventory')
  const navigate = useNavigate()

  const amazonInboundShipmentPlan = data?.amazonInboundShipmentPlan

  if (loading) {
    return <LoadingSpinner />
  }

  if (error || amazonInboundShipmentPlan === undefined) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const handleCancel = async () => {
    setLoadingCancel(true)
    try {
      if (
        amazonInboundShipmentPlan.inboundShipments?.length ||
        amazonInboundShipmentPlan.inboundShipments?.some((inboundShipment) =>
          dayjs().isAfter(dayjs(inboundShipment.createdAt).add(24, 'hour'))
        )
      ) {
        throw new Error()
      }

      await updateShipmentInternalStatus({
        variables: {
          amazonInboundShipmentPlanUuid: amazonInboundShipmentPlan.uuid,
          internalStatus: InternalShipmentPlanStatus.CANCELLED_BY_USER,
        },
      })

      toast.success(t('amazonInboundShipmentPlan.list.cancel.success'))
      setTimeout(() => navigate(`/amazon/shipment-plans`), 300)
    } catch (error) {
      console.error(error)
      toast.error(t('amazonInboundShipmentPlan.list.cancel.error'))
    } finally {
      setLoadingCancel(false)
    }
  }

  const showCancel =
    amazonInboundShipmentPlan.internalStatus === InternalShipmentPlanStatus.CREATED_BY_USER
  const hasLabels =
    (amazonInboundShipmentPlan.inboundShipments?.length ?? 0) > 0 &&
    amazonInboundShipmentPlan.confirmed

  return (
    <>
      <SubHeader
        withBackButton
        onBackUrl="/amazon/shipment-plans"
        rightContent={
          showCancel && (
            <Button
              type="primary"
              danger
              icon={<X size={16} />}
              loading={loadingCancel}
              onClick={() => handleCancel()}
            >
              {t('amazonInboundShipmentPlan.details.button.cancel')}
            </Button>
          )
        }
      />
      <ViewContainer>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={16}>
            {amazonInboundShipmentPlan.areCasesRequired ? (
              <AmazonInboundShipmentPlanContentInCasesTable
                amazonInboundShipmentPlan={amazonInboundShipmentPlan}
              />
            ) : (
              <AmazonInboundShipmentPlanContentTable
                amazonInboundShipmentPlan={amazonInboundShipmentPlan}
              />
            )}
          </Col>
          <Col xs={24} xl={8}>
            <Flex vertical gap={16}>
              <AmazonInboundShipmentPlanDeliveryDetails
                amazonInboundShipmentPlan={amazonInboundShipmentPlan}
              />
              <AmazonInboundShipmentPlanTransportDetails
                amazonInboundShipmentPlan={amazonInboundShipmentPlan}
              />
              {hasLabels && (
                <AmazonInboundShipmentPlanLabelDetails
                  amazonInboundShipmentPlan={amazonInboundShipmentPlan}
                />
              )}
              <AmazonInboundShipmentPlanDocumentsCard
                amazonInboundShipmentPlan={amazonInboundShipmentPlan}
                loading={loading}
              />
            </Flex>
          </Col>
        </Row>
      </ViewContainer>
    </>
  )
}
