import { useLazyQuery, useMutation } from '@apollo/client'
import { AutoComplete, Flex, Tag } from 'antd'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AddProductTagDocument,
  GetCompanyProductTagsDocument,
  GetProductDocument,
  GetProductQuery,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'

type NewProductTagButtonProps = {
  product: GetProductQuery['product']
}

export const NewProductTagButton = ({ product }: NewProductTagButtonProps) => {
  const [inputVisible, setInputVisible] = useState(false)
  const [options, setOptions] = useState<{ label: string; value: string }[]>([])

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation('inventory')

  const [getCompanyProductTags, { data }] = useLazyQuery(GetCompanyProductTagsDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
  })

  const [addProductTag] = useMutation(AddProductTagDocument)

  const usedTags = new Set(product.tags)

  const showInput = async () => {
    if (!selectedCompany?.uuid) {
      return
    }

    const { data } = await getCompanyProductTags({
      variables: {
        companyUuid: selectedCompany.uuid,
      },
    })

    const productTags = data?.productTags ?? []
    const filteredOptions = productTags
      .filter((tag) => !usedTags.has(tag))
      .map((tag) => ({ value: tag, label: tag }))
    setOptions(filteredOptions)

    setInputVisible(true)
  }

  const hideInput = () => {
    setInputVisible(false)
  }

  const handleSearch = (searchText: string) => {
    const productTags = data?.productTags ?? []
    const filteredOptions = productTags
      .filter((tag) => !usedTags.has(tag))
      .filter((tag) => tag.includes(searchText))
      .map((tag) => ({ value: tag, label: tag }))

    if (searchText) {
      if (
        !filteredOptions.some((option) => option.value === searchText) &&
        !usedTags.has(searchText)
      ) {
        filteredOptions.push({ value: searchText, label: searchText })
      }
    }

    setOptions(filteredOptions)
  }

  const handleSelect = async (tag: string) => {
    try {
      if (tag.trim()) {
        await addProductTag({
          variables: {
            productUuid: product.uuid,
            name: tag,
          },
          update: (cache, { data }) => {
            const addedTag = data?.addProductTag
            const cachedQuery = cache.readQuery({
              query: GetProductDocument,
              variables: { uuid: product.uuid },
            })

            if (addedTag && cachedQuery) {
              cache.writeQuery({
                query: GetProductDocument,
                variables: { uuid: product.uuid },
                data: {
                  product: {
                    ...cachedQuery.product,
                    tags: cachedQuery.product.tags
                      ? [...cachedQuery.product.tags, addedTag]
                      : [addedTag],
                  },
                },
              })
            }
          },
          refetchQueries: [GetCompanyProductTagsDocument],
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setInputVisible(false)
    }
  }

  return inputVisible ? (
    <AutoComplete<string>
      options={options}
      autoFocus
      backfill
      defaultOpen
      onSelect={handleSelect}
      onSearch={handleSearch}
      onBlur={hideInput}
      size="small"
      style={{ width: '100px', height: '22px' }}
    />
  ) : (
    <Tag onClick={showInput} style={{ borderStyle: 'dashed' }}>
      <Flex align="center" gap={4}>
        <Plus size={12} />
        {t('product.details.product.newTag')}
      </Flex>
    </Tag>
  )
}
