import { useParams } from 'react-router'
import {
  GetProductComplianceCheckByIdentifierDocument,
  GetProductComplianceCheckByIdentifierQuery,
} from '../../generated/graphql'
import { useQuery } from '@apollo/client'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { useTranslation } from 'react-i18next'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { Col, Row, Tag } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { ProductComplianceCheckDataCard } from './components/ProductComplianceCheckDataCard'
import { getLeastCompliantStatus } from './components/ComplianceTable'
import { ProductComplianceCheckItemListCard } from './components/ProductComplianceCheckItemListCard'
import { S3DocumentListCardData } from '../../components/S3/S3DocumentListCard'
import { ProductComplianceCheckDocumentsCard } from './components/ProductComplianceCheckDocumentsCard'
import { ProductComplianceCheckContentCard } from './components/ProductComplianceCheckContentCard'

type ProductComplianceCheck = GetProductComplianceCheckByIdentifierQuery['productComplianceCheck']
export type ProductComplianceCheckItem = NonNullable<ProductComplianceCheck>['items'][number]

export type ProductComplianceCheckDetailsRecord = {
  identifier: string
  productName: string
  productUuid: string
  productVariants: GetProductComplianceCheckByIdentifierQuery['productComplianceCheck']['product']['productVariants']
  serviceProvider: string
  status: string
  createdAt: Dayjs
}

export const ComplianceDetailsView = () => {
  const params = useParams<{ productComplianceIdentifier: string }>()
  const { t } = useTranslation(['compliance'])

  const { data, loading, error } = useQuery(GetProductComplianceCheckByIdentifierDocument, {
    fetchPolicy: 'cache-first',
    skip: !params.productComplianceIdentifier,
    variables: {
      identifier: params.productComplianceIdentifier!,
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error || !data) {
    return (
      <StatusResult status="500" title="500" subTitle={t('compliance:details.error.message')} />
    )
  }

  const productComplianceCheckRecord = transformProductComplianceCheckData(
    data.productComplianceCheck
  )
  const productComplianceCheckItems = data.productComplianceCheck.items
  const productComplianceCheckDocuments: S3DocumentListCardData[] =
    data.productComplianceCheck.documents?.map((document) => ({
      key: document.bucketKey,
      description: document.countryCodes?.map((countryCode) => (
        <Tag key={countryCode}>{countryCode}</Tag>
      )),
    })) || []

  return (
    <>
      <SubHeader
        heading={productComplianceCheckRecord.identifier}
        withBackButton
        onBackUrl="/compliance"
      />
      <ViewContainer>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <ProductComplianceCheckDataCard data={productComplianceCheckRecord} />
          </Col>
          <Col xs={24} xl={12}>
            <ProductComplianceCheckItemListCard
              items={productComplianceCheckItems}
              productComplianceCheckIdentifier={productComplianceCheckRecord.identifier}
            />
          </Col>
          <Col xs={24} xl={12}>
            <ProductComplianceCheckContentCard
              productComplianceCheckIdentifier={productComplianceCheckRecord.identifier}
              content={data.productComplianceCheck.content || ''}
            />
          </Col>
          <Col xs={24} xl={12}>
            <ProductComplianceCheckDocumentsCard
              documents={productComplianceCheckDocuments}
              productComplianceCheckIdentifier={productComplianceCheckRecord.identifier}
              productUuid={productComplianceCheckRecord.productUuid}
            />
          </Col>
        </Row>
      </ViewContainer>
    </>
  )
}

function transformProductComplianceCheckData(
  data: ProductComplianceCheck
): ProductComplianceCheckDetailsRecord {
  return {
    identifier: data.identifier,
    productName: data.product.name,
    productUuid: data.product.uuid,
    productVariants: data.product.productVariants,
    serviceProvider: data.serviceProviderCompany.name,
    status: getLeastCompliantStatus(data.items),
    createdAt: dayjs(data.createdAt),
  }
}
