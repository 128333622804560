import { useMutation } from '@apollo/client'
import { useTheme } from '@emotion/react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd'
import { Storage } from 'aws-amplify'
import dayjs, { Dayjs } from 'dayjs'
import { UploadIcon } from 'lucide-react'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  GetWholesaleOrderBillingDocumentDetailsDocument,
  UpdateWholesaleOrderBillingDocumentDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'

const BUCKET_KEY = import.meta.env.VITE_AWS_S3_BUCKET_NAME_WHOLESALE_ORDERS
const BUCKET_FOLDER = 'billing'

type WholesaleOrderBillingDocumentUploadModalFormValues = {
  documentNumber: string
  bucketKey: string
  issueDate: Dayjs
  dueDate: Dayjs
}

type WholesaleOrderBillingDocumentUploadModalProps = {
  wholesaleOrderBillingDocument: {
    identifier: string
    documentNumber: string
    issueDate: Dayjs
    dueDate: Dayjs
  }
}

export const WholesaleOrderBillingDocumentUploadModal = ({
  wholesaleOrderBillingDocument,
}: WholesaleOrderBillingDocumentUploadModalProps) => {
  const [open, setOpen] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [uploading, setUploading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const user = useGlobalStore((state) => state.user)!
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('wholesale-order-billing-document')
  const [form] = Form.useForm<WholesaleOrderBillingDocumentUploadModalFormValues>()
  const theme = useTheme()

  const documentNumber = Form.useWatch('documentNumber', form)
  const bucketKey = Form.useWatch('bucketKey', form)

  const [updateWholesaleOrderBillingDocument] = useMutation(
    UpdateWholesaleOrderBillingDocumentDocument
  )

  const handleChange: UploadProps['onChange'] = async (info) => {
    const fileName = `${documentNumber}_${nanoid(5)}.pdf`

    try {
      setUploading(true)
      setFileList(info.fileList)

      const key = `${BUCKET_FOLDER}/${selectedCompany.sellerId}/${fileName}`

      const status = info.file.status

      if (status === 'done') {
        const response = await Storage.put(key, info.file.originFileObj, {
          bucket: BUCKET_KEY,
          contentType: info.file.type,
        })

        form.setFieldValue('bucketKey', response.key)
      } else if (status === 'removed') {
        setFileList([])
        form.setFieldValue('bucketKey', '')
      } else if (status === 'error') {
        toast.error(t('uploadBillingDocument.error.general'))
      }
    } catch (error) {
      console.error(error)
      setFileList([])
      form.setFieldValue('bucketKey', '')
      toast.error(t('uploadBillingDocument.error.general'))
    } finally {
      setUploading(false)
    }
  }

  const handleSubmit = async (values: WholesaleOrderBillingDocumentUploadModalFormValues) => {
    setSubmitting(true)

    try {
      await updateWholesaleOrderBillingDocument({
        variables: {
          identifier: wholesaleOrderBillingDocument.identifier,
          input: {
            documentNumber: values.documentNumber,
            bucketKey: values.bucketKey,
            issueDate: values.issueDate.toISOString(),
            dueDate: values.dueDate.toISOString(),
          },
        },
        optimisticResponse: {
          updateWholesaleOrderBillingDocument: {
            identifier: wholesaleOrderBillingDocument.identifier,
            documentNumber: values.documentNumber,
            bucketKey: values.bucketKey,
            issueDate: values.issueDate.toISOString(),
            dueDate: values.dueDate.toISOString(),
          },
        },
        update: (cache, { data }) => {
          const updatedWholesaleOrderBillingDocument = data?.updateWholesaleOrderBillingDocument
          const cachedQuery = cache.readQuery({
            query: GetWholesaleOrderBillingDocumentDetailsDocument,
            variables: { identifier: wholesaleOrderBillingDocument.identifier },
          })

          if (updatedWholesaleOrderBillingDocument && cachedQuery) {
            cache.writeQuery({
              query: GetWholesaleOrderBillingDocumentDetailsDocument,
              variables: { identifier: wholesaleOrderBillingDocument.identifier },
              data: {
                wholesaleOrderBillingDocument: {
                  ...cachedQuery.wholesaleOrderBillingDocument,
                  documentNumber: updatedWholesaleOrderBillingDocument.documentNumber,
                  bucketKey: updatedWholesaleOrderBillingDocument.bucketKey,
                  issueDate: updatedWholesaleOrderBillingDocument.issueDate,
                  dueDate: updatedWholesaleOrderBillingDocument.dueDate,
                },
              },
            })
          }
        },
      })

      toast.success(
        t('wholesaleOrderBillingDocumentGeneralDetailsCard.bucketKey.modal.toast.success')
      )
    } catch (error) {
      console.error(error)
      toast.error(t('wholesaleOrderBillingDocumentGeneralDetailsCard.bucketKey.modal.toast.error'))
    } finally {
      setOpen(false)
      setSubmitting(false)
    }
  }

  return (
    <>
      <Button
        type="text"
        icon={<UploadIcon size={16} />}
        size="small"
        onClick={() => setOpen(true)}
        style={{ color: theme.colors.warning }}
      />
      <Modal
        title={t('wholesaleOrderBillingDocumentGeneralDetailsCard.bucketKey.modal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={submitting}
      >
        <Form
          form={form}
          initialValues={{
            documentNumber: wholesaleOrderBillingDocument.documentNumber,
            issueDate: wholesaleOrderBillingDocument.issueDate,
            dueDate: wholesaleOrderBillingDocument.dueDate,
          }}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item<WholesaleOrderBillingDocumentUploadModalFormValues>
                name="documentNumber"
                label={t(
                  'wholesaleOrderBillingDocumentGeneralDetailsCard.bucketKey.modal.form.documentNumber.label'
                )}
                rules={[{ required: true }]}
              >
                <Input disabled={!!bucketKey} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<WholesaleOrderBillingDocumentUploadModalFormValues>
                name="bucketKey"
                label={t(
                  'wholesaleOrderBillingDocumentGeneralDetailsCard.bucketKey.modal.form.document.label'
                )}
                rules={[
                  {
                    required: true,
                    message: t(
                      'wholesaleOrderBillingDocumentGeneralDetailsCard.bucketKey.modal.form.document.error.required'
                    ),
                  },
                ]}
              >
                <Upload
                  accept="application/pdf"
                  customRequest={customRequest}
                  defaultFileList={fileList}
                  disabled={!documentNumber}
                  fileList={fileList}
                  listType="text"
                  maxCount={1}
                  multiple={false}
                  onChange={handleChange}
                >
                  <Button
                    type="dashed"
                    icon={<UploadIcon size={16} />}
                    loading={uploading}
                    disabled={!documentNumber}
                  >
                    {t('shared.button.upload', { ns: 'translation' })}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<WholesaleOrderBillingDocumentUploadModalFormValues>
                name="issueDate"
                label={t(
                  'wholesaleOrderBillingDocumentGeneralDetailsCard.bucketKey.modal.form.issueDate.label'
                )}
                rules={[{ required: true }]}
              >
                <DatePicker
                  format="DD.MM.YYYY"
                  disabledDate={(current) => {
                    if (user?.isAdmin) {
                      return false
                    }

                    return (
                      current &&
                      (current > dayjs().endOf('day') || current < dayjs().subtract(4, 'days'))
                    )
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item<WholesaleOrderBillingDocumentUploadModalFormValues>
                name="dueDate"
                label={t(
                  'wholesaleOrderBillingDocumentGeneralDetailsCard.bucketKey.modal.form.dueDate.label'
                )}
                rules={[{ required: true }]}
              >
                <DatePicker
                  format="DD.MM.YYYY"
                  disabledDate={(current) => {
                    if (user?.isAdmin) {
                      return false
                    }

                    return current && current < dayjs().startOf('day')
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}
