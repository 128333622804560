import { Divider, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { PaymentsOverviewTable, PaymentsOverviewTableRecord } from './PaymentsOverviewTable'

type PaymentsOverviewProps = {
  overview: PaymentsOverviewTableRecord[]
}

export const PaymentsOverview = ({ overview }: PaymentsOverviewProps) => {
  const { t } = useTranslation(['billing'], { keyPrefix: 'payments.overview' })

  return (
    <Flex vertical gap={8}>
      <Divider orientation="left" style={{ margin: 0 }}>
        <Typography.Text strong style={{ fontSize: '1.125rem' }}>
          {t('title')}
        </Typography.Text>
      </Divider>
      <PaymentsOverviewTable overview={overview} />
    </Flex>
  )
}
