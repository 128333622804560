import {
  ColDef,
  ColGroupDef,
  GridOptions,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { Suspense, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flag } from '../../../../components/Flag'
import { Table } from '../../../../components/Table/Table'
import { CompanyType, Marketplace } from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { preparePaymentsShipmentRecords } from '../helpers'
import { PaymentsQueryShipment } from '../PaymentsView'

export type PaymentsShipmentTableRecord = {
  name: string
  asin: string
  sku: string
  marketplace: Marketplace
  date: string
  quantity: number
  revenue: number
  vat: number
  shippingFees: number
  amazonFees: number
  salesMargin: number
  profit: number
  purchasePrice: number
  remunerationAmount: number
}

type PaymentsShipmentTableProps = {
  shipments: PaymentsQueryShipment[]
}

export const PaymentsShipmentTable = ({ shipments }: PaymentsShipmentTableProps) => {
  const rowData = preparePaymentsShipmentRecords(shipments)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation(['billing'], { keyPrefix: 'payments.details.shipments' })

  const [columnDefs] = useState<
    (ColDef<PaymentsShipmentTableRecord> | ColGroupDef<PaymentsShipmentTableRecord>)[]
  >([
    {
      field: 'name',
      headerName: t('table.name'),
    },
    {
      field: 'asin',
      headerName: t('table.asin'),
    },
    {
      field: 'sku',
      headerName: t('table.sku'),
    },
    {
      headerName: t('table.marketplace'),
      filter: 'agSetColumnFilter',
      valueGetter: (
        params: ValueGetterParams<
          PaymentsShipmentTableRecord,
          PaymentsShipmentTableRecord['marketplace']
        >
      ) => {
        return params.data
          ? `Amazon.${getMarketplaceDomain(params.data.marketplace)}`
          : t('shared.notAvailable')
      },
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentTableRecord,
          PaymentsShipmentTableRecord['marketplace']
        >
      ) => {
        return params.data ? (
          <Space>
            <Flag code={params.data.marketplace} />
            <Typography.Text>
              Amazon.{getMarketplaceDomain(params.data.marketplace)}
            </Typography.Text>
          </Space>
        ) : (
          t('shared.notAvailable')
        )
      },
    },
    {
      field: 'date',
      headerName: t('table.date'),
      initialSort: 'desc',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentTableRecord,
          PaymentsShipmentTableRecord['date']
        >
      ) => {
        return dayjs(params.value).format('DD.MM.YYYY HH:mm:ss')
      },
    },
    {
      field: 'quantity',
      headerName: t('table.quantity'),
    },
    {
      field: 'revenue',
      headerName: t('table.revenue'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentTableRecord,
          PaymentsShipmentTableRecord['revenue']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'vat',
      headerName: t('table.vat'),
      cellRenderer: (
        params: ICellRendererParams<PaymentsShipmentTableRecord, PaymentsShipmentTableRecord['vat']>
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'shippingFees',
      headerName: t('table.shippingFees'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentTableRecord,
          PaymentsShipmentTableRecord['shippingFees']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'amazonFees',
      headerName: t('table.amazonFees'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentTableRecord,
          PaymentsShipmentTableRecord['amazonFees']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'salesMargin',
      headerName: t('table.salesMargin'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentTableRecord,
          PaymentsShipmentTableRecord['salesMargin']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'profit',
      headerName: t('table.profit'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentTableRecord,
          PaymentsShipmentTableRecord['profit']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'purchasePrice',
      headerName: t('table.purchasePrice'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentTableRecord,
          PaymentsShipmentTableRecord['purchasePrice']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'remunerationAmount',
      headerName: t('table.remunerationAmount'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentTableRecord,
          PaymentsShipmentTableRecord['remunerationAmount']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
  ])

  const defaultColDef: ColDef<PaymentsShipmentTableRecord> = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<PaymentsShipmentTableRecord>) => {
        if (params.value === null || params.value === undefined || params.value === '') {
          return t('shared.notAvailable', { ns: 'translation' })
        } else {
          return params.value
        }
      },
    }),
    []
  )

  const gridOptionsDef = useMemo(
    (): GridOptions => ({
      onGridReady: (event) => {
        if (selectedCompany.type === CompanyType.WHOLESALER) {
          event.api.setColumnsVisible(['profit'], false)
          event.api.setColumnsVisible(['remunerationAmount'], false)
        }
      },
    }),

    []
  )

  return (
    <Suspense>
      {!rowData?.length ? (
        <Typography.Text type="secondary">{t('empty')}</Typography.Text>
      ) : (
        <Table<PaymentsShipmentTableRecord>
          columnDefs={columnDefs}
          gridOptions={gridOptionsDef}
          defaultColDef={defaultColDef}
          rowData={rowData}
          suppressContextMenu
        />
      )}
    </Suspense>
  )
}
