import { Button, Col, Flex, Form, FormInstance, Popover, Row, Select, Space } from 'antd'
import { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'remeda'
import { Marketplace, PageType } from '../../../../generated/graphql'
import { ShipmentKind } from '../types'
import { AmazonInboundShipmentPlanCartonCaseTable } from './AmazonInboundShipmentPlanCartonCaseTable'
import { AmazonInboundShipmentPlanCartonMixedTable } from './AmazonInboundShipmentPlanCartonMixedTable'
import { AmazonInboundShipmentPlanCreateFormInstance } from './AmazonInboundShipmentPlanCreateForm'
import { AmazonInboundShipmentPlanStep } from './AmazonInboundShipmentPlanCreateFormCollapse'

const MAX_STANDARD_DIMENSION = 63.5
const MAX_OVERSIZED_DIMENSION = 175

type AmazonInboundShipmentPlanCartonSectionProps = {
  setCurrentStep: (currentStep: SetStateAction<AmazonInboundShipmentPlanStep>) => void
}

export const AmazonInboundShipmentPlanCartonSection = ({
  setCurrentStep,
}: AmazonInboundShipmentPlanCartonSectionProps) => {
  const { t } = useTranslation('inventory')
  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  const shipmentKind: AmazonInboundShipmentPlanCreateFormInstance['shipmentKind'] =
    form.getFieldValue('shipmentKind')

  const handleBackClick = () => {
    setCurrentStep(AmazonInboundShipmentPlanStep.QUANTITY)
  }

  const handleNextClick = async () => {
    try {
      await form.validateFields(['pageType'])
      setCurrentStep(AmazonInboundShipmentPlanStep.TRANSPORT)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Flex vertical gap={8}>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <AmazonShipmentPlanPageTypeFormField />
        </Col>
      </Row>
      <Form.Item<AmazonInboundShipmentPlanCreateFormInstance>>
        {shipmentKind === ShipmentKind.CASE ? (
          <AmazonInboundShipmentPlanCartonCaseTable editable />
        ) : (
          <AmazonInboundShipmentPlanCartonMixedTable editable />
        )}
      </Form.Item>
      <Flex justify="end">
        <Space>
          <Form.Item<AmazonInboundShipmentPlanCreateFormInstance> noStyle>
            <Button onClick={handleBackClick}>
              {t('shared.button.back', { ns: 'translation' })}
            </Button>
          </Form.Item>
          <Form.Item<AmazonInboundShipmentPlanCreateFormInstance>
            shouldUpdate={(prevValues, nextValues) => {
              return !R.isDeepEqual(prevValues.cartons, nextValues.cartons)
            }}
            noStyle
          >
            {() => {
              const { hasMissingDimensions, hasOversizedDimensions } = validateSection(form)

              const disabled = hasMissingDimensions || hasOversizedDimensions

              const popoverContent = hasMissingDimensions
                ? t('amazonInboundShipmentPlan.create.form.carton.popover.missingDimensions')
                : hasOversizedDimensions
                  ? t('amazonInboundShipmentPlan.create.form.carton.popover.dimensionsTooBig')
                  : undefined

              return (
                <Popover content={popoverContent} placement="topRight">
                  <Button type="primary" disabled={disabled} onClick={handleNextClick}>
                    {t('shared.button.next', { ns: 'translation' })}
                  </Button>
                </Popover>
              )
            }}
          </Form.Item>
        </Space>
      </Flex>
    </Flex>
  )
}

const AmazonShipmentPlanPageTypeFormField = () => {
  const { t } = useTranslation('inventory')

  return (
    <Form.Item<AmazonInboundShipmentPlanCreateFormInstance>
      name="pageType"
      label={t('amazonInboundShipmentPlan.create.form.carton.shippingLabel.label')}
      rules={[{ enum: Object.values(PageType), required: true, type: 'enum' }]}
    >
      <Select<PageType>
        options={[
          {
            value: PageType.PACKAGELABEL_A4_2,
            label: t('amazonInboundShipmentPlan.create.form.carton.shippingLabel.standard'),
          },
          {
            value: PageType.PACKAGELABEL_THERMAL_NONPCP,
            label: t('amazonInboundShipmentPlan.create.form.carton.shippingLabel.thermal'),
          },
        ]}
        placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
      />
    </Form.Item>
  )
}

function validateSection(form: FormInstance<AmazonInboundShipmentPlanCreateFormInstance>) {
  const marketplace: AmazonInboundShipmentPlanCreateFormInstance['marketplace'] =
    form.getFieldValue('marketplace')

  const cartons: AmazonInboundShipmentPlanCreateFormInstance['cartons'] =
    form.getFieldValue('cartons')

  const isUkMarketplace = marketplace === Marketplace.UK

  const hasMissingDimensions = cartons.some(
    (carton) =>
      !carton.cartonLength ||
      !carton.cartonWidth ||
      !carton.cartonHeight ||
      !carton.cartonGrossWeight ||
      (isUkMarketplace && !carton.cartonNetWeight)
  )

  const hasOversizedDimensions = cartons.some((carton) => {
    const largestItemDimension = Math.max(
      carton.items
        .map((item) => item.smallestPackagingUnit?.lengthInCm ?? 0)
        .reduce((a, b) => Math.max(a, b), 0),
      carton.items
        .map((item) => item.smallestPackagingUnit?.widthInCm ?? 0)
        .reduce((a, b) => Math.max(a, b), 0),
      carton.items
        .map((item) => item.smallestPackagingUnit?.heightInCm ?? 0)
        .reduce((a, b) => Math.max(a, b), 0)
    )

    const limit =
      largestItemDimension > MAX_STANDARD_DIMENSION
        ? MAX_OVERSIZED_DIMENSION
        : MAX_STANDARD_DIMENSION

    return (
      Math.max(carton.cartonLength ?? 0, carton.cartonWidth ?? 0, carton.cartonHeight ?? 0) > limit
    )
  })

  return { hasMissingDimensions, hasOversizedDimensions }
}
