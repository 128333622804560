import { useQuery } from '@apollo/client'
import { Col, DatePicker, Row, Select, Space, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { ViewContainer } from '../../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import { PPCPerformanceCard } from '../../../../components/PPC/layout/PPCPerformanceCard/PPCPerformanceCard'
import SponsoredBrandsKeywordTable from '../../../../components/PPC/sponsoredBrands/keyword/SponsoredBrandsKeywordsTable/SponsoredBrandsKeywordsTable'
import {
  GetSponsoredBrandsAdGroupWithAllContentDocument,
  GetSponsoredBrandsAdGroupWithAllContentQuery,
  Marketplace,
} from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useDateRangePresets } from '../../../../hooks/useDateRangePresets'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { getCombinedSponsoredBrandsKeywordLiveDataAndReports } from '../../helpers'
import { SponsoredBrandsAggregatedKeyword } from '../../helpers/interfaces'
import ppcStateStore from '../../ppcStateStore'

const { RangePicker } = DatePicker

export type SponsoredBrandsKeywordLiveDataQuery =
  GetSponsoredBrandsAdGroupWithAllContentQuery['sponsoredBrandsKeywordLiveData']
export type SponsoredBrandsKeywordReportsQuery =
  GetSponsoredBrandsAdGroupWithAllContentQuery['sponsoredBrandsKeywordReports']

export const PPCSponsoredBrandsAdGroupView = () => {
  const [keywords, setKeywords] = useState<SponsoredBrandsAggregatedKeyword[]>([])

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { setMarketplace, ppcStartDate, setPpcStartDate, ppcEndDate, setPpcEndDate } =
    ppcStateStore()

  const { t } = useTranslation()

  const navigate = useNavigate()
  const params = useParams<{ marketplace: string; campaignid: string; adgroupid: string }>()
  const marketplace = params.marketplace?.toUpperCase() as Marketplace
  const campaignId = params.campaignid!
  const adGroupId = params.adgroupid!

  const marketplaceOptions = useMarketplaceOptions()
  const presets = useDateRangePresets()

  const { loading, error, data } = useQuery<GetSponsoredBrandsAdGroupWithAllContentQuery>(
    GetSponsoredBrandsAdGroupWithAllContentDocument,
    {
      skip: !selectedCompany.uuid,
      variables: {
        companyUuid: selectedCompany.uuid,
        marketplace,
        campaignId,
        adGroupId,
        startDate: ppcStartDate,
        endDate: ppcEndDate,
      },
    }
  )

  useEffect(() => {
    const keywordLiveData = data?.sponsoredBrandsKeywordLiveData || []
    const keywordReports = data?.sponsoredBrandsKeywordReports || []
    const aggregatedKeywords = getCombinedSponsoredBrandsKeywordLiveDataAndReports(
      keywordLiveData,
      keywordReports
    )
    setKeywords(aggregatedKeywords)
  }, [data?.sponsoredBrandsKeywordLiveData, data?.sponsoredBrandsKeywordReports])

  if (loading) {
    return <LoadingSpinner />
  }

  if (
    error ||
    !data ||
    !data.sponsoredBrandsKeywordReports ||
    !data.sponsoredBrandsKeywordLiveData ||
    !data.sponsoredBrandsAdGroupLiveData ||
    !data.sponsoredBrandsCampaignLiveData
  ) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  const adGroup = data.sponsoredBrandsAdGroupLiveData[0]

  const handleMarketplaceChange = (value: Marketplace) => {
    navigate(`/amazon/ppc/${value.toLowerCase()}`)
    setMarketplace(value.toLowerCase())
  }

  const rightContent = (
    <Space>
      <Select<Marketplace>
        value={marketplace}
        onChange={handleMarketplaceChange}
        popupMatchSelectWidth={false}
        placement="bottomRight"
        options={marketplaceOptions.map((marketplace) => ({
          value: marketplace,
          label: `Amazon.${getMarketplaceDomain(marketplace as unknown as Marketplace)}`,
        }))}
      />
      <RangePicker
        value={[dayjs(ppcStartDate), dayjs(ppcEndDate)]}
        onCalendarChange={(values) => {
          if (values[0]) {
            setPpcStartDate(values[0].format('YYYY-MM-DD'))
          }

          if (values[1]) {
            setPpcEndDate(values[1].format('YYYY-MM-DD'))
          }
        }}
        allowClear={false}
        disabled={loading}
        disabledDate={(date) =>
          date.isAfter(dayjs()) || date.isBefore(dayjs().subtract(6, 'month'))
        }
        format="DD.MM.YY"
        placement="bottomRight"
        presets={presets}
      />
    </Space>
  )

  return (
    <>
      <SubHeader withBackButton rightContent={rightContent} />
      <ViewContainer>
        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <Typography.Text strong ellipsis>
              Ad Group: {adGroup?.name}
            </Typography.Text>
          </Col>
          <Col>
            <Tag color="cyan">Sponsored Brands</Tag>
          </Col>
        </Row>
        <SponsoredBrandsKeywordTable keywords={keywords} />
      </ViewContainer>
      <PPCPerformanceCard data={keywords} />
    </>
  )
}
