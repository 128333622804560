import { Flex, Typography } from 'antd'
import * as i18nIsoCountries from 'i18n-iso-countries'
import { MapPin, Phone, Timer } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { StyledSubCard } from '../../../dashboard/styles'
import { WarehouseServiceProviderLocationStatusCheckbox } from './WarehouseServiceProviderLocationStatusCheckbox'
import { WarehouseServiceProviderLocation } from './WarehousingContainer'

type WarehouseServiceProviderLocationCardProps = {
  location: WarehouseServiceProviderLocation
}

export const WarehouseServiceProviderLocationCard = ({
  location,
}: WarehouseServiceProviderLocationCardProps) => {
  const { t, i18n } = useTranslation('warehousing')

  return (
    <StyledSubCard
      title={location.name}
      extra={<WarehouseServiceProviderLocationStatusCheckbox location={location} />}
      style={{ opacity: location.active ? 1 : 0.5 }}
    >
      <Flex vertical gap={8}>
        <Flex align="start" gap={8}>
          <MapPin size={16} />
          <Flex vertical>
            <Typography.Text>{location.addressLine1}</Typography.Text>
            <Typography.Text>{location.addressLine2}</Typography.Text>
            <Typography.Text>{location.city}</Typography.Text>
            <Typography.Text>{location.provinceCode}</Typography.Text>
            <Typography.Text>
              {i18nIsoCountries.getName(location.countryCode, i18n.language)}
            </Typography.Text>
            <Typography.Text>{location.postalCode}</Typography.Text>
          </Flex>
        </Flex>
        {location.phone && (
          <Flex gap={8} align="center">
            <Phone size={16} />
            <Typography.Text>{location.phone}</Typography.Text>
          </Flex>
        )}
        <Flex gap={8} align="center">
          <Timer size={16} />
          <Typography.Text>
            {t('checkOutDuration', { count: location.checkOutDurationInDays })}
          </Typography.Text>
        </Flex>
      </Flex>
    </StyledSubCard>
  )
}
