import { ColDef, ColGroupDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community'
import { Flex, Tag, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MoreItemsIndicator } from '../../../../../../../components/MoreItemsIndicator'
import { Table } from '../../../../../../../components/Table/Table'
import { formatMoney } from '../../../../../../../helpers/formatMoney'
import { formatPercentage } from '../../../../../../../helpers/formatPercentage'
import { roundNumber } from '../../../../../../../helpers/roundNumber'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'

type WholesaleOrderBillingDocumentCreateFormSummaryTableRecord =
  CreateWholesaleOrderBillingDocumentFormValues['lineItems'][number]

type WholesaleOrderBillingDocumentCreateFormSummaryTableProps = {
  selectedLineItems: CreateWholesaleOrderBillingDocumentFormValues['lineItems']
}

export const WholesaleOrderBillingDocumentCreateFormSummaryTable = ({
  selectedLineItems,
}: WholesaleOrderBillingDocumentCreateFormSummaryTableProps) => {
  const { t } = useTranslation('wholesale-order-billing-document', {
    keyPrefix: 'wholesaleOrderLineItemsTable.columnDefs',
  })

  const [columnDefs] = useState<
    (
      | ColDef<WholesaleOrderBillingDocumentCreateFormSummaryTableRecord>
      | ColGroupDef<WholesaleOrderBillingDocumentCreateFormSummaryTableRecord>
    )[]
  >([
    {
      field: 'position',
      headerName: t('position'),
      sort: 'asc',
    },
    {
      field: 'identifier',
      headerName: t('identifier'),
    },
    {
      field: 'productName',
      headerName: t('productName'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'internalSku',
      headerName: t('internalSku'),
    },
    {
      field: 'ean',
      headerName: t('ean'),
    },
    {
      field: 'attributes',
      headerName: t('attribute'),
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord,
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord['attributes']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }

        return params.value
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord,
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord['attributes']
        >
      ) => {
        const attributes = params.value

        if (!attributes) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = attributes.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return attributes.map((item) => {
          return <Tag key={item}>{item}</Tag>
        })
      },
    },
    {
      field: 'asins',
      headerName: t('asins'),
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord,
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord['asins']
        >
      ) => {
        const asins = params.value

        if (!asins) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = asins.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{asins[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={asins} />}
          </Flex>
        )
      },
    },
    {
      field: 'skus',
      headerName: t('skus'),
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord,
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord['skus']
        >
      ) => {
        const skus = params.value

        if (!skus) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = skus.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{skus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={skus} />}
          </Flex>
        )
      },
    },
    {
      field: 'quantity',
      headerName: t('quantity'),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'netPurchasePrice',
      headerName: t('netPurchasePrice'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord,
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord['netPurchasePrice']
        >
      ) => {
        const currency = params.data?.currency ?? 'EUR'

        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, currency)}</Typography.Text>
      },
    },
    {
      field: 'taxRate',
      headerName: t('newTaxRate'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord,
          WholesaleOrderBillingDocumentCreateFormSummaryTableRecord['taxRate']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatPercentage(params.value / 100)}</Typography.Text>
      },
    },
    {
      headerName: t('netTotal'),
      filter: 'agNumberColumnFilter',
      valueGetter: (params) => {
        if (!params.data) {
          return null
        }

        if (params.data.quantity === null || params.data.quantity === undefined) {
          return null
        }

        const netPurchasePrice = params.data.netPurchasePrice
        const quantity = params.data.quantity

        return roundNumber(quantity * netPurchasePrice)
      },
      cellRenderer: (
        params: ICellRendererParams<WholesaleOrderBillingDocumentCreateFormSummaryTableRecord>
      ) => {
        const currency = params.data?.currency ?? 'EUR'

        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, currency)}</Typography.Text>
      },
    },
    {
      headerName: t('tax'),
      filter: 'agNumberColumnFilter',
      valueGetter: (params) => {
        if (!params.data) {
          return null
        }

        if (params.data.quantity === null || params.data.quantity === undefined) {
          return null
        }

        const netPurchasePrice = params.data.netPurchasePrice
        const taxRate = params.data.taxRate ?? 0
        const quantity = params.data.quantity

        return roundNumber(quantity * netPurchasePrice * (taxRate / 100))
      },
      cellRenderer: (
        params: ICellRendererParams<WholesaleOrderBillingDocumentCreateFormSummaryTableRecord>
      ) => {
        const currency = params.data?.currency ?? 'EUR'

        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, currency)}</Typography.Text>
      },
    },
    {
      headerName: t('grossTotal'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sumSelected',
      valueGetter: (params) => {
        if (!params.data) {
          return null
        }

        if (params.data.quantity === null || params.data.quantity === undefined) {
          return null
        }

        const quantity = params.data.quantity
        const netPurchasePrice = params.data.netPurchasePrice
        const taxRate = params.data.taxRate ?? 0

        return roundNumber(
          quantity * netPurchasePrice + quantity * netPurchasePrice * (taxRate / 100)
        )
      },
      cellRenderer: (
        params: ICellRendererParams<WholesaleOrderBillingDocumentCreateFormSummaryTableRecord>
      ) => {
        const currency = params.data?.currency ?? 'EUR'

        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, currency)}</Typography.Text>
      },
    },
  ])

  return (
    <Table<WholesaleOrderBillingDocumentCreateFormSummaryTableRecord>
      columnDefs={columnDefs}
      rowData={selectedLineItems}
      grandTotalRow="bottom"
      showExport
      showUniversalSearch
      suppressContextMenu
    />
  )
}
