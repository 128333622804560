import { Space, Table, Tag } from 'antd'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'sonner'
import { AmazonPpcCampaignType, AmazonPpcState } from '../../../generated/graphql'
import { roundNumber } from '../../../helpers/roundNumber'
import { getColorFromState, getTagByCampaignType } from '../../../views/ppc/helpers'
import { formatCurrency } from '../../../views/ppc/helpers/formatCurrency'
import { AggregatedCampaign } from '../../../views/ppc/helpers/interfaces'
import { getTableColumnFilterProps } from '../../../views/ppc/helpers/tableColumnFilterProps'
import { getTableColumnSorterProps } from '../../../views/ppc/helpers/tableColumnSorterProps'
import { getColumnSearchProps } from '../../../views/ppc/helpers/tableSearchProps'
import { useCurrencySymbol } from '../../../views/ppc/helpers/useCurrencySymbol'
import {
  getAverageAcos,
  getAverageBudget,
  getAverageClicks,
  getAverageCostPerClick,
  getAverageImpressions,
  getAverageSales,
  getAverageSpend,
  getTotalAcos,
  getTotalBudget,
  getTotalClicks,
  getTotalCostPerClick,
  getTotalImpressions,
  getTotalSales,
  getTotalSpend,
} from '../helpers'
import SponsoredProductsCampaignSettingsModal from '../sponsoredProducts/campaign/UpdateSponsoredProductsCampaignModal/UpdateSponsoredProductsCampaignModal'
import { TableWithClickableRows } from '../styles'

type PPCCampaignTableProps = {
  aggregatedCampaigns: AggregatedCampaign[]
  updateCampaignList: Function
}

const PPCCampaignTable = ({ aggregatedCampaigns, updateCampaignList }: PPCCampaignTableProps) => {
  const [searchText, setSearchText] = useState<string>('')

  const navigate = useNavigate()
  const params = useParams<{ marketplace: string }>()

  const currencySymbol = useCurrencySymbol()

  const handleSearch = (selectedKeys: any[], confirm: Function) => {
    confirm()
    setSearchText(selectedKeys[0])
  }

  const handleReset = (clearFilters: Function) => {
    clearFilters()
    setSearchText('')
  }

  const handleOnRowClick = (record: any) => {
    if (record.campaignType === AmazonPpcCampaignType.SPONSOREDPRODUCTS) {
      navigate(`/amazon/ppc/${params.marketplace}/sp/campaigns/${record.campaignId}`)
    } else if (record.campaignType === AmazonPpcCampaignType.SPONSOREDBRANDS) {
      navigate(`/amazon/ppc/${params.marketplace}/sb/campaigns/${record.campaignId}`)
    } else {
      toast('Sponsored Display campaigns are not visible at a deeper level.')
    }
  }

  const columns = [
    {
      title: 'Campaign',
      dataIndex: 'name',
      key: 'name',
      fixed: true,
      ...getColumnSearchProps(handleSearch, handleReset, searchText, 'name'),
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      filters: [
        { text: 'Enabled', value: AmazonPpcState.ENABLED },
        { text: 'Paused', value: AmazonPpcState.PAUSED },
        { text: 'Archived', value: AmazonPpcState.ARCHIVED },
      ],
      ...getTableColumnFilterProps('state'),
      render: (state: AmazonPpcState) => {
        const stateString = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()
        return <Tag color={getColorFromState(state)}>{stateString}</Tag>
      },
    },
    {
      title: 'Type',
      dataIndex: 'campaignType',
      key: 'campaignType',
      filters: [
        { text: 'Sponsored Products', value: AmazonPpcCampaignType.SPONSOREDPRODUCTS },
        { text: 'Sponsored Brands', value: AmazonPpcCampaignType.SPONSOREDBRANDS },
        { text: 'Sponsored Display', value: AmazonPpcCampaignType.SPONSOREDDISPLAY },
      ],
      ...getTableColumnFilterProps('campaignType'),
      render: (campaignType: AmazonPpcCampaignType) => {
        return getTagByCampaignType(campaignType)
      },
    },
    {
      title: 'Targeting',
      dataIndex: 'targetingType',
      key: 'targetingType',
      render: (targetingType: string) => {
        if (targetingType) {
          const targetingTypeString =
            targetingType.charAt(0).toUpperCase() + targetingType.slice(1).toLowerCase()
          return <Tag>{targetingTypeString}</Tag>
        } else {
          return '-'
        }
      },
    },
    {
      title: 'Daily Budget',
      dataIndex: 'dailyBudget',
      key: 'dailyBudget',
      render: (dailyBudget: number, row: any) => {
        if (dailyBudget) {
          return formatCurrency(currencySymbol, dailyBudget)
        } else if (row.budget) {
          return formatCurrency(currencySymbol, row.budget)
        }
      },
      sorter: (a: any, b: any) => {
        const aBudget = a.budget ? a.budget : a.dailyBudget
        const bBudget = b.budget ? b.budget : b.dailyBudget
        return aBudget - bBudget
      },
    },
    {
      title: 'ACoS',
      dataIndex: 'acos',
      key: 'acos',
      render: (acos: number) => {
        if (isFinite(acos)) {
          return acos + '%'
        } else {
          return '-'
        }
      },
      ...getTableColumnSorterProps('acos'),
    },
    {
      title: 'Cost per Click',
      dataIndex: 'costPerClick',
      key: 'costPerClick',
      render: (costPerClick: number) => formatCurrency(currencySymbol, costPerClick),
      ...getTableColumnSorterProps('costPerClick'),
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      ...getTableColumnSorterProps('impressions'),
    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      key: 'spend',
      render: (spend: number) => formatCurrency(currencySymbol, spend),
      ...getTableColumnSorterProps('spend'),
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      ...getTableColumnSorterProps('clicks'),
    },
    {
      title: 'Sales',
      dataIndex: 'sales',
      key: 'sales',
      render: (sales: number) => formatCurrency(currencySymbol, sales),
      ...getTableColumnSorterProps('sales'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, row: any) => {
        if (row.campaignType === AmazonPpcCampaignType.SPONSOREDPRODUCTS) {
          return (
            <Space onClick={(e) => e.stopPropagation()} size="small">
              <SponsoredProductsCampaignSettingsModal
                campaignId={row.campaignId}
                dailyBudgetIn={row.dailyBudget}
                stateIn={row.state}
                updateCampaignList={updateCampaignList}
              />
            </Space>
          )
        }
      },
    },
  ]

  return (
    <>
      <TableWithClickableRows
        bordered
        columns={columns}
        dataSource={aggregatedCampaigns}
        onRow={(record) => ({ onClick: () => handleOnRowClick(record) })}
        pagination={{ defaultPageSize: 20 }}
        rowKey="campaignId"
        scroll={{ x: true }}
        summary={(data) => {
          if (data.length) {
            const totalBudget = getTotalBudget(data as any[])
            const totalAcos = getTotalAcos(data as any[])
            const totalCostPerClick = getTotalCostPerClick(data as any[])
            const totalImpressions = getTotalImpressions(data as any[])
            const totalSpend = getTotalSpend(data as any[])
            const totalClicks = getTotalClicks(data as any[])
            const totalSales = getTotalSales(data as any[])

            const avgBudget = getAverageBudget(data as any[])
            const avgAcos = getAverageAcos(data as any[])
            const avgCostPerClick = getAverageCostPerClick(data as any[])
            const avgImpressions = getAverageImpressions(data as any[])
            const avgSpend = getAverageSpend(data as any[])
            const avgClicks = getAverageClicks(data as any[])
            const avgSales = getAverageSales(data as any[])

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total:</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>-</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>-</Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>-</Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    {formatCurrency(currencySymbol, totalBudget)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    {totalAcos !== Infinity ? totalAcos : '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    {formatCurrency(currencySymbol, totalCostPerClick)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>{totalImpressions}</Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    {formatCurrency(currencySymbol, totalSpend)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>{totalClicks}</Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    {formatCurrency(currencySymbol, totalSales)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}>-</Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Average:</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>-</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>-</Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>-</Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    {formatCurrency(currencySymbol, avgBudget)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    {avgAcos !== Infinity ? avgAcos : '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    {formatCurrency(currencySymbol, avgCostPerClick)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>{roundNumber(avgImpressions)}</Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    {formatCurrency(currencySymbol, avgSpend)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>{roundNumber(avgClicks)}</Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    {formatCurrency(currencySymbol, avgSales)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}>-</Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          }
        }}
      />
    </>
  )
}

export default PPCCampaignTable
