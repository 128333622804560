import { Typography } from 'antd'
import dayjs from 'dayjs'
import { TooltipProps } from 'recharts'
import { formatMoney } from '../../../../../helpers/formatMoney'
import { formatPercentage } from '../../../../../helpers/formatPercentage'
import { StyledMainCard } from '../../../styles'

type AmazonAdsMetricTooltipProps = TooltipProps<number, string> & {
  name: string
  type: 'quantity' | 'money' | 'percentage'
}

export const AmazonAdsMetricTooltip = (props: AmazonAdsMetricTooltipProps) => {
  const payload: { date: string; value: number } | undefined = props.payload?.[0]?.payload

  if (!payload) {
    return null
  }

  return (
    <StyledMainCard
      title={dayjs(payload.date).format('DD.MM.YYYY')}
      size="small"
      style={{ width: '200px', opacity: '0.98' }}
      styles={{ header: { border: 'none' }, body: { padding: '0 1rem 0.5rem' } }}
    >
      <Typography.Text>{props.name}</Typography.Text>:{' '}
      <Typography.Text>
        {props.type === 'money'
          ? formatMoney(payload.value, 'EUR')
          : props.type === 'percentage'
            ? formatPercentage(payload.value)
            : payload.value}
      </Typography.Text>
    </StyledMainCard>
  )
}
