import { useTheme } from '@emotion/react'
import { Col, List, Row, Space, Statistic, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { StyledMainCard } from '../../../dashboard/styles'
import { prepareAmazonOrderOverview } from '../helpers/prepareAmazonOrderOverview'
import { AmazonOrderTableRecord } from './AmazonOrderTable'

type AmazonOrderOverviewProps = {
  amazonOrders: AmazonOrderTableRecord[]
}

export const AmazonOrderOverview = ({ amazonOrders }: AmazonOrderOverviewProps) => {
  const { t } = useTranslation(['inventory', 'translation'])
  const theme = useTheme()

  const overview = prepareAmazonOrderOverview(amazonOrders)

  return (
    <StyledMainCard style={{ maxHeight: '336px' }}>
      <Statistic title={t('orders.overview.statistic.title')} value={amazonOrders.length} />
      <List
        header={t('orders.overview.products.list.title')}
        dataSource={overview.topSkus}
        renderItem={(item) => (
          <List.Item style={{ position: 'relative', padding: 0 }}>
            <>
              <div
                style={{
                  position: 'absolute',
                  width: `${item.proportion}%`,
                  height: '90%',
                  borderRadius: '0.25rem',
                  backgroundColor: `${theme.colors.primary}20`,
                }}
              />
              <Row justify="space-between" style={{ width: '100%', padding: '0.5rem' }}>
                <Col>{item.sku}</Col>
                <Col>
                  <Space>
                    <Typography.Text>{item.count}</Typography.Text>
                    <Typography.Text>({item.percentage})</Typography.Text>
                  </Space>
                </Col>
              </Row>
            </>
          </List.Item>
        )}
        locale={{ emptyText: t('shared.noData', { ns: 'translation' }) }}
        split={false}
      />
    </StyledMainCard>
  )
}
