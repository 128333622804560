import { Avatar, Divider, Menu, Popover, theme } from 'antd'
import { Cog, LogOut } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { AppThemeSelection } from './AppThemeSelection'
import { LanguageSelection } from './LanguageSelection'
import { UserMenuTitle } from './UserMenuTitle'

export const UserMenu = () => {
  const [open, setOpen] = useState(false)

  const user = useGlobalStore((state) => state.user)

  const { t } = useTranslation()

  const firstName = user?.firstName ?? ''
  const lastName = user?.lastName ?? ''

  const handleOpenChange = (visible: boolean) => {
    setOpen(visible)
  }

  const closePopover = () => {
    setOpen(false)
  }

  const content = (
    <>
      <LanguageSelection />
      <AppThemeSelection />
      <Divider style={{ margin: '0.5rem 0' }} />
      <Menu
        selectable={false}
        items={[
          {
            key: 'settings',
            icon: <Cog size={16} />,
            label: <Link to="settings">{t('menu.settings')}</Link>,
            onClick: closePopover,
          },
          {
            key: 'logout',
            icon: <LogOut size={16} />,
            label: <Link to="logout">{t('menu.logout')}</Link>,
            onClick: closePopover,
          },
        ]}
        style={{ border: 'none', backgroundColor: 'transparent' }}
      />
    </>
  )

  return (
    <Popover
      title={<UserMenuTitle firstName={firstName} lastName={lastName} />}
      content={content}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayInnerStyle={{ width: '320px' }}
    >
      <Avatar
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor">
            <path d="M370.7 96.1C346.1 39.5 289.7 0 224 0S101.9 39.5 77.3 96.1C60.9 97.5 48 111.2 48 128v64c0 16.8 12.9 30.5 29.3 31.9C101.9 280.5 158.3 320 224 320s122.1-39.5 146.7-96.1c16.4-1.4 29.3-15.1 29.3-31.9V128c0-16.8-12.9-30.5-29.3-31.9zM336 144v16c0 53-43 96-96 96H208c-53 0-96-43-96-96V144c0-26.5 21.5-48 48-48H288c26.5 0 48 21.5 48 48zM189.3 162.7l-6-21.2c-.9-3.3-3.9-5.5-7.3-5.5s-6.4 2.2-7.3 5.5l-6 21.2-21.2 6c-3.3 .9-5.5 3.9-5.5 7.3s2.2 6.4 5.5 7.3l21.2 6 6 21.2c.9 3.3 3.9 5.5 7.3 5.5s6.4-2.2 7.3-5.5l6-21.2 21.2-6c3.3-.9 5.5-3.9 5.5-7.3s-2.2-6.4-5.5-7.3l-21.2-6zM112.7 316.5C46.7 342.6 0 407 0 482.3C0 498.7 13.3 512 29.7 512H128V448c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64l98.3 0c16.4 0 29.7-13.3 29.7-29.7c0-75.3-46.7-139.7-112.7-165.8C303.9 338.8 265.5 352 224 352s-79.9-13.2-111.3-35.5zM176 448c-8.8 0-16 7.2-16 16v48h32V464c0-8.8-7.2-16-16-16zm96 32c8.8 0 16-7.2 16-16s-7.2-16-16-16s-16 7.2-16 16s7.2 16 16 16z" />
          </svg>
        }
        style={{ backgroundColor: theme.defaultSeed.colorPrimary, cursor: 'pointer' }}
      />
    </Popover>
  )
}
