import styled from '@emotion/styled'

export const MagicBellContainer = styled.div`
  margin-top: 4px;

  [role='button']:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  [data-floating-ui-focusable] {
    z-index: 9999 !important;
  }
`
