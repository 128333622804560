import { Button, Col, Form, FormInstance, Input, InputNumber, Row, Select, Table } from 'antd'
import { Plus, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import {
  AmazonPpcCampaignType,
  SponsoredProductsKeywordMatchType,
} from '../../../../../../generated/graphql'
import { useDefaultMaximumBid } from '../../../../hooks/useDefaultMaximumBid'
import { useDefaultMinimumBid } from '../../../../hooks/useDefaultMinimumBid'
import {
  FormWidget,
  ListTitle,
  RequiredFieldIndicator,
  ScrollableList,
  SelectContainer,
  SelectedItemsContainer,
} from '../../../../styles'

const { TextArea } = Input

type CreateSponsoredProductsKeywordsFormFieldsProps = {
  form: FormInstance
  currencySymbol: string
}

type KeywordData = {
  keywordText: string
  bid: number
  matchType: SponsoredProductsKeywordMatchType
}

const CreateSponsoredProductsKeywordsFormFields = ({
  form,
  currencySymbol,
}: CreateSponsoredProductsKeywordsFormFieldsProps) => {
  const matchTypeOptions = Object.values(SponsoredProductsKeywordMatchType)
  const defaultMatchType = SponsoredProductsKeywordMatchType.EXACT
  const [textareaValue, setTextareaValue] = useState<string>('')
  const [keywordInput, setKeywordInput] = useState<string[]>([])
  const defaultMinimumBid = useDefaultMinimumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const defaultMaximumBid = useDefaultMaximumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const [bid, setBid] = useState<number>(defaultMinimumBid)
  const [matchTypes, setMatchTypes] = useState<SponsoredProductsKeywordMatchType[]>([
    defaultMatchType,
  ])
  const [keywords, setKeywords] = useState<KeywordData[]>([])

  useEffect(() => {
    form.setFieldsValue({ keywords: keywords })
  }, [keywords, form])

  const handleRemoveKeyword = (index: number) => {
    const keywordCopy = [...keywords]
    keywordCopy.splice(index, 1)
    setKeywords(keywordCopy)
  }

  const columns = [
    {
      title: 'Text',
      dataIndex: 'keywordText',
      key: 'keywordText',
    },
    {
      title: 'Match Type',
      dataIndex: 'matchType',
      key: 'matchType',
    },
    {
      title: `Bid (${currencySymbol})`,
      dataIndex: 'bid',
      key: 'bid',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '50px',
      render: (value: any, record: any, index: number) => (
        <Button
          type="text"
          icon={<X size={16} />}
          onClick={() => handleRemoveKeyword(index)}
          data-testid="remove-keyword-btn"
        />
      ),
    },
  ]

  const handleAddKeywords = () => {
    const newKeywords: KeywordData[] = []
    keywordInput.forEach((keywordInput: string) => {
      matchTypes.forEach((matchType: SponsoredProductsKeywordMatchType) => {
        const newKeyword: KeywordData = {
          keywordText: keywordInput,
          matchType: matchType,
          bid: bid,
        }

        let keywordExists = false

        keywords.forEach((existingKeyword: KeywordData) => {
          if (
            existingKeyword.keywordText === newKeyword.keywordText &&
            existingKeyword.matchType === newKeyword.matchType
          ) {
            keywordExists = true
            return
          }
        })

        newKeywords.forEach((existingNewKeyword: KeywordData) => {
          if (
            existingNewKeyword.keywordText === newKeyword.keywordText &&
            existingNewKeyword.matchType === newKeyword.matchType
          ) {
            keywordExists = true
            return
          }
        })

        if (!keywordExists) {
          newKeywords.push(newKeyword)
        } else {
          toast.error(`Duplicate keyword cannot be added`, {
            description: `Keyword text: ${newKeyword.keywordText}, match type: ${newKeyword.matchType}`,
          })
        }
      })
    })

    setKeywords([...keywords, ...newKeywords])
    setTextareaValue('')
    setKeywordInput([])
  }

  const handleTextareaOnChange = (e: any) => {
    const userInput = e.target.value
    setTextareaValue(userInput)
    if (userInput) {
      // split on new line and remove any empty lines
      const keywords = userInput.split('\n').filter((keyword: string) => keyword?.trim())
      setKeywordInput(keywords)
    } else {
      setKeywordInput([])
    }
  }

  const handleMatchtypeChanged = (value: SponsoredProductsKeywordMatchType[]) => {
    setMatchTypes(value)
  }

  const handleBidChanged = (value: number | null) => {
    if (value) {
      setBid(value)
    }
  }

  return (
    <>
      <Form.Item name={'keywords'} hidden>
        <Input></Input>
      </Form.Item>
      <FormWidget>
        <SelectContainer>
          <Row align="middle" gutter={12} style={{ padding: '6px 12px' }}>
            <Col>Bid ({currencySymbol}): </Col>
            <Col>
              <InputNumber
                precision={2}
                step={0.01}
                min={defaultMinimumBid}
                max={defaultMaximumBid}
                defaultValue={defaultMinimumBid}
                onChange={handleBidChanged}
                data-testid="keyword-bid-input"
              />
            </Col>
          </Row>
          <Row style={{ padding: '6px 12px' }}>
            <Select
              mode="multiple"
              defaultValue={[defaultMatchType]}
              style={{ width: '100%' }}
              onChange={handleMatchtypeChanged}
            >
              {matchTypeOptions.map((matchTypeOption) => {
                return (
                  <Select.Option
                    value={matchTypeOption}
                    label={matchTypeOption}
                    key={matchTypeOption}
                  >
                    {matchTypeOption}
                  </Select.Option>
                )
              })}
            </Select>
          </Row>
          <Row style={{ padding: '6px 12px' }}>
            <TextArea
              rows={15}
              placeholder="Enter keywords seperated by new line"
              onChange={handleTextareaOnChange}
              value={textareaValue}
              data-testid="keyword-textarea"
            />
          </Row>
          <Row style={{ padding: '6px 12px' }} justify="end">
            <Button
              type="primary"
              onClick={handleAddKeywords}
              icon={<Plus size={16} />}
              disabled={!keywordInput.length || !matchTypes.length}
              data-testid="add-keywords-btn"
            >
              Add Keywords
            </Button>
          </Row>
        </SelectContainer>
        <SelectedItemsContainer>
          <ListTitle>
            <RequiredFieldIndicator>*</RequiredFieldIndicator> Keywords ({keywords.length})
          </ListTitle>
          <ScrollableList>
            <Table
              dataSource={keywords}
              columns={columns}
              pagination={false}
              bordered={true}
              rowKey={(row) => row.keywordText + '-' + row.matchType}
              size="small"
            />
          </ScrollableList>
        </SelectedItemsContainer>
      </FormWidget>
    </>
  )
}

export default CreateSponsoredProductsKeywordsFormFields
