import { TFunction } from 'i18next'
import { AmazonInboundShipmentStatus } from '../../../../generated/graphql'

export const getAmazonInboundShipmentStatusText = (
  shipmentStatus: AmazonInboundShipmentStatus,
  t: TFunction<'inventory'>
) => {
  switch (shipmentStatus) {
    case AmazonInboundShipmentStatus.WORKING:
      return t('amazonInboundShipmentPlan.table.shipmentStatus.working')
    case AmazonInboundShipmentStatus.RECEIVING:
      return t('amazonInboundShipmentPlan.table.shipmentStatus.receiving')
    case AmazonInboundShipmentStatus.CANCELLED:
      return t('amazonInboundShipmentPlan.table.shipmentStatus.cancelled')
    case AmazonInboundShipmentStatus.DELETED:
      return t('amazonInboundShipmentPlan.table.shipmentStatus.deleted')
    case AmazonInboundShipmentStatus.CLOSED:
      return t('amazonInboundShipmentPlan.table.shipmentStatus.closed')
    case AmazonInboundShipmentStatus.ERROR:
      return t('amazonInboundShipmentPlan.table.shipmentStatus.error')
    case AmazonInboundShipmentStatus.IN_TRANSIT:
      return t('amazonInboundShipmentPlan.table.shipmentStatus.inTransit')
    case AmazonInboundShipmentStatus.DELIVERED:
      return t('amazonInboundShipmentPlan.table.shipmentStatus.delivered')
    case AmazonInboundShipmentStatus.CHECKED_IN:
      return t('amazonInboundShipmentPlan.table.shipmentStatus.checkedIn')
    case AmazonInboundShipmentStatus.SHIPPED:
      return t('amazonInboundShipmentPlan.table.shipmentStatus.shipped')
    default:
      return shipmentStatus
  }
}
