import { Button, ButtonProps } from 'antd'
import mixpanel from 'mixpanel-browser'

type EventButtonProps = ButtonProps & {
  event: string
}

export const EventButton = ({ event, onClick, children, ...props }: EventButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    mixpanel.track(event)
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <Button onClick={handleClick} {...props}>
      {children}
    </Button>
  )
}
