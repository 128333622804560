import { Flex, Statistic, Tooltip, Typography } from 'antd'
import { CircleHelp } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { StyledSkeleton, StyledSubCard } from '../../styles'

type DashboardSubCardOrdersProps = {
  totalShipped: number
  totalPending: number
  totalCanceled: number
  loading: boolean
}

export const DashboardSubCardOrders = ({
  totalShipped,
  totalPending,
  totalCanceled,
  loading,
}: DashboardSubCardOrdersProps) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <StyledSubCard>
      <StyledSkeleton loading={loading} paragraph={{ rows: 1 }}>
        <Statistic
          title={
            <Flex align="center" gap={8}>
              <Typography.Text>{t('performance.shared.orders')}</Typography.Text>
              <Tooltip title={t('performance.shared.ordersTooltip')}>
                <CircleHelp size={16} />
              </Tooltip>
            </Flex>
          }
          value={totalShipped + totalPending + totalCanceled}
          suffix={
            <Typography.Text style={{ marginLeft: '0.5rem', fontSize: '1rem' }}>
              ({totalShipped} / {totalPending} / {totalCanceled})
            </Typography.Text>
          }
        />
      </StyledSkeleton>
    </StyledSubCard>
  )
}
