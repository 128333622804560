import { Button, Result, ResultProps } from 'antd'
import { SubHeader } from '../SubHeader/SubHeader'

export const StatusResult = (props: ResultProps) => {
  const reload = () => window.location.reload()

  return (
    <>
      <SubHeader />
      <Result
        extra={
          <Button type="primary" onClick={reload}>
            Reload
          </Button>
        }
        {...props}
      />
    </>
  )
}
