import { Form, FormInstance, InputNumber, Select } from 'antd'
import { FormEventHandler } from 'react'
import { useCurrencySymbol } from '../../../../../../views/ppc/helpers/useCurrencySymbol'
import { FormStrategy } from '../../../../../../views/ppc/helpers/interfaces'

type BulkEditTargetBidFormProps = {
  handleOnFinish: FormEventHandler
  handleFormChanged: FormEventHandler
  handleFormStrategyChanged: Function
  formStrategy: FormStrategy
  form: FormInstance
  minimumBid: number
  maximumBid: number
}

const BulkEditTargetBidForm = ({
  handleOnFinish,
  handleFormChanged,
  handleFormStrategyChanged,
  formStrategy,
  form,
  minimumBid,
  maximumBid,
}: BulkEditTargetBidFormProps) => {
  const currencySymbol = useCurrencySymbol()
  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      layout="horizontal"
      onFinish={handleOnFinish}
      onValuesChange={handleFormChanged}
      onChange={handleFormChanged}
    >
      <Form.Item name="strategy" label="Strategy">
        <Select onChange={(values) => handleFormStrategyChanged(values)}>
          <Select.Option value={FormStrategy.VALUE}>Change to value</Select.Option>
          <Select.Option value={FormStrategy.INCR_PERCENTAGE}>Increase by percentage</Select.Option>
          <Select.Option value={FormStrategy.DECR_PERCENTAGE}>Decrease by percentage</Select.Option>
          <Select.Option value={FormStrategy.INCR_AMOUNT}>Increase by amount</Select.Option>
          <Select.Option value={FormStrategy.DECR_AMOUNT}>Decrease by amount</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={`Value (${currencySymbol})`}
        name="bid"
        hidden={formStrategy !== FormStrategy.VALUE}
      >
        <InputNumber precision={2} step={0.01} min={minimumBid} max={maximumBid} />
      </Form.Item>
      <Form.Item
        label="Percentage"
        name="incrPercentage"
        hidden={formStrategy !== FormStrategy.INCR_PERCENTAGE}
      >
        <InputNumber
          data-testid="incrPercentage"
          step={1}
          min={0}
          max={1000}
          formatter={(value) => `${value}%`}
        />
      </Form.Item>
      <Form.Item
        label="Percentage"
        name="decrPercentage"
        hidden={formStrategy !== FormStrategy.DECR_PERCENTAGE}
      >
        <InputNumber
          data-testid="decrPercentage"
          step={1}
          min={0}
          max={100}
          formatter={(value) => `${value}%`}
        />
      </Form.Item>
      <Form.Item
        label={`Amount (${currencySymbol})`}
        name="incrAmount"
        hidden={formStrategy !== FormStrategy.INCR_AMOUNT}
      >
        <InputNumber data-testid="incrAmount" precision={2} min={0} step={0.01} />
      </Form.Item>
      <Form.Item
        label={`Amount (${currencySymbol})`}
        name="decrAmount"
        hidden={formStrategy !== FormStrategy.DECR_AMOUNT}
      >
        <InputNumber data-testid="decrAmount" precision={2} min={0} step={0.01} />
      </Form.Item>
    </Form>
  )
}

export default BulkEditTargetBidForm
