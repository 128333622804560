import { Button, Col, Form, Input, Radio, RadioChangeEvent, Row, Select } from 'antd'
import { FormListFieldData } from 'antd/es/form/FormList'
import { i18n, TFunction } from 'i18next'
import { Plus, Trash } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import QuillTextarea from '../../../../components/Ticket/QuillTextarea'
import { Marketplace } from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'

type DealsRequiredDynamicInputInputProps = {
  translation?: [TFunction, i18n, boolean] & { t: TFunction; i18n: i18n; ready: boolean }
}

export const EbookMailingDynamicInput = ({ translation }: DealsRequiredDynamicInputInputProps) => {
  const [templateVariables, setTemplateVariables] = useState<Record<number, string>>({})

  const { t } =
    translation ??
    useTranslation(['ticket'], {
      keyPrefix: 'thirdPartyServices.ebookMailingOrEvaluationRequestTicketView.form',
    })

  const marketplaceOptions = useMarketplaceOptions()

  const customerTypeSelectorOptions = [
    t('customerTypeSelector.new'),
    t('customerTypeSelector.existing'),
    t('customerTypeSelector.all'),
  ]
  const excludeNegativeOrdersOptions = [
    t('excludeNegativeOrders.yes'),
    t('excludeNegativeOrders.no'),
  ]
  const excludeRefundedOrdersOptions = [
    t('excludeRefundedOrders.yes'),
    t('excludeRefundedOrders.no'),
  ]
  const standardOrCustomTemplateOptions = [
    t('standardOrCustomTemplate.standard'),
    t('standardOrCustomTemplate.custom'),
  ]

  const handleTemplateOptionsChange = (event: RadioChangeEvent, field: FormListFieldData) => {
    setTemplateVariables({ ...templateVariables, [field.key]: event.target.value })
  }

  return (
    <Form.Item required={true}>
      <Form.List name="formDetails">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...rest }) => (
              <Row key={key}>
                {fields.length > 1 ? (
                  <Col span={2} style={{ marginBottom: 24 }}>
                    <Button
                      type="primary"
                      danger
                      icon={<Trash size={16} />}
                      onClick={() => remove(name)}
                      style={{ height: '100%' }}
                    />
                  </Col>
                ) : null}
                <Col span={fields.length > 1 ? 22 : 24}>
                  <Row key={key} gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        {...rest}
                        name={[name, 'sku']}
                        label={t('skuInput.label')}
                        rules={[{ required: true, message: t('skuInput.validation.required') }]}
                      >
                        <Input placeholder={t('skuInput.placeholder')} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...rest}
                        label={t('customerTypeSelector.label')}
                        name={[name, 'customerType']}
                        rules={[
                          {
                            required: true,
                            message: t('customerTypeSelector.validation.required'),
                          },
                        ]}
                      >
                        <Radio.Group options={customerTypeSelectorOptions} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...rest}
                        label={t('excludeNegativeOrders.label')}
                        name={[name, 'excludeNegativeOrders']}
                        rules={[
                          {
                            required: true,
                            message: t('excludeNegativeOrders.validation.required'),
                          },
                        ]}
                      >
                        <Radio.Group options={excludeNegativeOrdersOptions} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...rest}
                        label={t('excludeRefundedOrders.label')}
                        name={[name, 'excludeRefundedOrders']}
                        rules={[
                          {
                            required: true,
                            message: t('excludeRefundedOrders.validation.required'),
                          },
                        ]}
                      >
                        <Radio.Group options={excludeRefundedOrdersOptions} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...rest}
                        name={[name, 'marketplaces']}
                        label={t('marketplacesInput.label')}
                        rules={[
                          { required: true, message: t('marketplacesInput.validation.required') },
                        ]}
                      >
                        <Select<Marketplace>
                          mode="multiple"
                          options={marketplaceOptions.map((marketplace) => ({
                            value: marketplace,
                            label: `Amazon.${getMarketplaceDomain(marketplace)}`,
                          }))}
                          placeholder={t('marketplacesInput.placeholder')}
                          showSearch
                          filterOption={true}
                          optionFilterProp="label"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...rest}
                        name={[name, 'emailSendOption']}
                        label={t('emailSendOption.label')}
                        rules={[
                          { required: true, message: t('emailSendOption.validation.required') },
                        ]}
                      >
                        <Input.TextArea placeholder={t('emailSendOption.placeholder')} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...rest}
                        label={t('standardOrCustomTemplate.label')}
                        name={[name, 'standardOrCustomTemplate']}
                        rules={[
                          {
                            required: true,
                            message: t('standardOrCustomTemplate.validation.required'),
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={(event: RadioChangeEvent) =>
                            handleTemplateOptionsChange(event, fields[key])
                          }
                          options={standardOrCustomTemplateOptions}
                        />
                      </Form.Item>
                    </Col>
                    {templateVariables[key] === t('standardOrCustomTemplate.custom') ? (
                      <Col span={24}>
                        <Form.Item
                          {...rest}
                          name={[name, 'customHtmlTemplate']}
                          label={t('customHtmlTemplate.label')}
                          rules={[
                            {
                              required: true,
                              message: t('customHtmlTemplate.validation.required'),
                            },
                          ]}
                        >
                          <QuillTextarea placeholder={t('customHtmlTemplate.placeholder')} />
                        </Form.Item>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<Plus size={16} />}>
                {t('addMoreButton')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  )
}
