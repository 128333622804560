import { Col, InputNumber, Row, Switch } from 'antd'
import { useState } from 'react'
import { getReadableTargetingExpressionTypePredicate } from '../../../../helpers'
import { ISponsoredProductsAutoTarget } from './interfaces'

type EditSponsoredProductsAutomaticTargetRowProps = {
  targetIn: ISponsoredProductsAutoTarget
  handleTargetChanged: Function
  defaultMinimumBid: number
  defaultMaximumBid: number
}

const EditSponsoredProductsAutomaticTargetRow = ({
  targetIn,
  handleTargetChanged,
  defaultMinimumBid,
  defaultMaximumBid,
}: EditSponsoredProductsAutomaticTargetRowProps) => {
  const [target, setTarget] = useState(targetIn)

  const handleTargetBidChanged = (value: number | null) => {
    if (value) {
      const targetCopy = { ...target }
      targetCopy.bid = value
      setTarget(targetCopy)
      handleTargetChanged(targetCopy)
    }
  }

  const handleTargetActiveChanged = (checked: boolean) => {
    const targetCopy = { ...target }
    targetCopy.active = checked
    setTarget(targetCopy)
    handleTargetChanged(targetCopy)
  }

  return (
    <Row style={{ marginBottom: '1rem' }} align="middle">
      <Col span={6} style={{ textAlign: 'right', paddingRight: '0.5rem' }}>
        {getReadableTargetingExpressionTypePredicate(target.expressionTypePredicate)}
      </Col>
      <Col span={2}>
        <Switch
          defaultChecked={target.active}
          onChange={handleTargetActiveChanged}
          data-testid="target-active-switch"
        />
      </Col>
      <Col>
        <InputNumber
          min={defaultMinimumBid}
          max={defaultMaximumBid}
          step={0.1}
          value={target.bid}
          onChange={handleTargetBidChanged}
          disabled={!target.active}
          data-testid="target-bid-input"
        />
        €
      </Col>
    </Row>
  )
}

export default EditSponsoredProductsAutomaticTargetRow
