import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ViewContainer } from '../../../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../../../components/LoadingSpinner'
import {
  GetWholesaleOrderBillingDocumentDetailsDocument,
  GetWholesaleOrderBillingDocumentDetailsQuery,
} from '../../../../../generated/graphql'
import { WholesaleOrderBillingDocumentCompanyDetailsCard } from './WholesaleOrderBillingDocumentCompanyDetailsCard'
import { WholesaleOrderBillingDocumentGeneralDetailsCard } from './WholesaleOrderBillingDocumentGeneralDetailsCard'
import {
  WholesaleOrderBillingDocumentLineItemsTable,
  WholesaleOrderBillingDocumentLineItemTableRecord,
} from './WholesaleOrderBillingDocumentLineItemsTable/WholesaleOrderBillingDocumentLineItemsTable'

export type WholesaleOrderBillingDocument =
  GetWholesaleOrderBillingDocumentDetailsQuery['wholesaleOrderBillingDocument']
type WholesaleOrderBillingDocumentLineItem = WholesaleOrderBillingDocument['lineItems'][number]

export const WholesaleOrderBillingDocumentDetailsView = () => {
  const { t } = useTranslation()

  const params = useParams<{ identifier: string }>()

  const { data, loading, error } = useQuery(GetWholesaleOrderBillingDocumentDetailsDocument, {
    variables: {
      identifier: params.identifier!,
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const formattedLineItems = data
    ? prepareWholesaleOrderBillingDocumentLineItems(data.wholesaleOrderBillingDocument.lineItems)
    : []

  return (
    <>
      <SubHeader
        heading={data?.wholesaleOrderBillingDocument.identifier}
        withBackButton
        onBackUrl="/wholesale/billing-documents"
      />
      <ViewContainer>
        {data ? (
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12} style={{ display: 'grid', gridTemplateRows: '1fr' }}>
              <WholesaleOrderBillingDocumentGeneralDetailsCard
                wholesaleOrderBillingDocument={data.wholesaleOrderBillingDocument}
              />
            </Col>
            <Col xs={24} xl={12} style={{ display: 'grid', gridTemplateRows: '1fr' }}>
              <WholesaleOrderBillingDocumentCompanyDetailsCard
                wholesaleOrderBillingDocument={data.wholesaleOrderBillingDocument}
              />
            </Col>
            <Col xs={24}>
              <WholesaleOrderBillingDocumentLineItemsTable
                wholesaleOrderBillingDocumentLineItems={formattedLineItems}
              />
            </Col>
          </Row>
        ) : null}
      </ViewContainer>
    </>
  )
}

const prepareWholesaleOrderBillingDocumentLineItems = (
  lineItems: WholesaleOrderBillingDocumentLineItem[]
): WholesaleOrderBillingDocumentLineItemTableRecord[] => {
  if (!lineItems.length) {
    return []
  }

  return lineItems.flatMap((lineItem) => {
    const salesChannelRegion =
      lineItem.wholesaleOrderLineItem.wholesaleOrder.shippedToWarehouseLocation.salesChannelRegion

    const skus: string[] = []

    const amazonProducts = lineItem.wholesaleOrderLineItem.productVariant.amazonProducts ?? []

    for (const amazonProduct of amazonProducts) {
      const amazonListings = amazonProduct.amazonListings ?? []

      for (const amazonListing of amazonListings) {
        if (!salesChannelRegion) {
          continue
        }

        const marketplace = amazonListing.marketplace.name
        const sku = amazonListing.sku

        if (salesChannelRegion.countries.includes(marketplace)) {
          if (!skus.includes(sku)) {
            skus.push(sku)
          }
        }
      }
    }

    const attributes = lineItem.wholesaleOrderLineItem.productVariant.attributes
      ? lineItem.wholesaleOrderLineItem.productVariant.attributes.flatMap((attribute) => {
          return `${attribute.option}: ${attribute.value}`
        })
      : []

    const asins = amazonProducts.map((amazonProduct) => amazonProduct.asin)

    const netTotal = lineItem.quantity * lineItem.netPurchasePrice
    const tax = netTotal * (lineItem.taxRate / 100)
    const grossTotal = netTotal + tax

    return {
      position: lineItem.position,
      wholesaleOrderBillingDocumentLineItemIdentifier: lineItem.identifier,
      wholesaleOrderLineItemIdentifier: lineItem.wholesaleOrderLineItem.identifier,
      product: {
        uuid: lineItem.wholesaleOrderLineItem.productVariant.product.uuid,
        name: lineItem.wholesaleOrderLineItem.productVariant.product.name,
        tags: lineItem.wholesaleOrderLineItem.productVariant.product.tags ?? [],
      },
      productVariant: {
        // uuid: lineItem.wholesaleOrderLineItem.productVariant.uuid,
        ean: lineItem.wholesaleOrderLineItem.productVariant.ean ?? null,
        internalSku: lineItem.wholesaleOrderLineItem.productVariant.internalSku ?? null,
        attributes,
      },
      asins,
      skus,
      quantity: lineItem.quantity,
      netPurchasePrice: lineItem.netPurchasePrice,
      currency: lineItem.currency,
      netTotal,
      taxRate: lineItem.taxRate,
      tax,
      grossTotal,
    }
  })
}
