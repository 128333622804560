import { useMutation } from '@apollo/client'
import { Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  CreateProductVariantPackagingUnitDocument,
  GtinType,
  ProductVariantPackagingUnitType,
} from '../../../../../generated/graphql'
import { getProductVariantPackagingUnitTypeText } from '../helpers/useWholesaleOrderFormTableColumnDefs'
import { PackagingUnit } from '../WholesaleOrderFormTable'

type WholesaleOrderTablePackagingUnitCreateModalProps = {
  open: boolean
  onClose: () => void
  productVariantUuid: string
  onSuccess: (newPackagingUnit: PackagingUnit) => void
  existingQuantities?: Set<number>
}

type PackagingUnitFormValues = {
  gtin: string | null
  gtinType: GtinType
  type: ProductVariantPackagingUnitType
  internalSku: string | null
  quantity: number
  lengthInCm: number
  widthInCm: number
  heightInCm: number
  weightInGram: number
}

export const WholesaleOrderTablePackagingUnitCreateModal = ({
  open,
  onClose,
  productVariantUuid,
  onSuccess,
  existingQuantities = new Set(),
}: WholesaleOrderTablePackagingUnitCreateModalProps) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm<PackagingUnitFormValues>()
  const gtinType = useWatch('gtinType', form)
  const { t } = useTranslation(['wholesale-order'])
  const { t: tInventory } = useTranslation('inventory')

  const [createProductVariantPackagingUnit] = useMutation(CreateProductVariantPackagingUnitDocument)

  const handleSubmit = async (values: PackagingUnitFormValues) => {
    setLoading(true)

    try {
      const input = {
        ...values,
        gtin: values.gtin?.trim() || null,
      }

      const result = await createProductVariantPackagingUnit({
        variables: {
          productVariantUuid,
          input,
        },
      })

      const newPackagingUnit = result.data?.createProductVariantPackagingUnit

      if (!newPackagingUnit) {
        throw new Error('Failed to create packaging unit')
      }

      toast.success(t('shared.notification.success', { ns: 'translation' }))
      onSuccess(newPackagingUnit)
      onClose()
      form.resetFields()
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title={tInventory('product.details.productVariant.packagingUnitModal.title')}
      open={open}
      okText={t('shared.button.submit', { ns: 'translation' })}
      onOk={form.submit}
      cancelText={t('shared.button.cancel', { ns: 'translation' })}
      onCancel={onClose}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          gtinType: GtinType.EAN,
          type: ProductVariantPackagingUnitType.SMALL_PARCEL,
        }}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="gtinType"
              label={tInventory(
                'product.details.productVariant.packagingUnitModal.form.gtinType.label'
              )}
            >
              <Select<GtinType>
                options={Object.values(GtinType).map((type) => ({
                  value: type,
                  label: type,
                }))}
                placeholder={tInventory(
                  'product.details.productVariant.packagingUnitModal.form.gtinType.placeholder'
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="gtin"
              label={tInventory(
                'product.details.productVariant.packagingUnitModal.form.gtin.label'
              )}
              tooltip={tInventory(
                'product.details.productVariant.packagingUnitModal.form.gtin.tooltip'
              )}
            >
              <Input
                addonAfter={gtinType}
                placeholder={tInventory(
                  'product.details.productVariant.packagingUnitModal.form.gtin.placeholder'
                )}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="internalSku"
              label={tInventory(
                'product.details.productVariant.packagingUnitModal.form.internalSku.label'
              )}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="type"
              label={tInventory(
                'product.details.productVariant.packagingUnitModal.form.type.label'
              )}
            >
              <Select<ProductVariantPackagingUnitType>
                options={Object.values(ProductVariantPackagingUnitType).map((type) => ({
                  value: type,
                  label: getProductVariantPackagingUnitTypeText(type, tInventory, false),
                }))}
                placeholder={tInventory(
                  'product.details.productVariant.packagingUnitModal.form.type.placeholder'
                )}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="quantity"
              label={tInventory(
                'product.details.productVariant.packagingUnitModal.form.quantity.label'
              )}
              rules={[
                {
                  required: true,
                  message: tInventory(
                    'product.details.productVariant.packagingUnitModal.form.quantity.validation.required'
                  ),
                },
                {
                  validator: (_, value) => {
                    if (existingQuantities.has(value)) {
                      return Promise.reject(
                        tInventory(
                          'product.details.productVariant.packagingUnitModal.form.quantity.validation.duplicate'
                        )
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <InputNumber style={{ width: '100%' }} min={1} precision={0} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="lengthInCm"
              label={tInventory(
                'product.details.productVariant.packagingUnitModal.form.lengthInCm.label'
              )}
              rules={[
                {
                  required: true,
                  message: tInventory(
                    'product.details.productVariant.packagingUnitModal.form.lengthInCm.validation.required'
                  ),
                },
              ]}
            >
              <InputNumber style={{ width: '100%' }} addonAfter="cm" min={0.1} precision={2} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="widthInCm"
              label={tInventory(
                'product.details.productVariant.packagingUnitModal.form.widthInCm.label'
              )}
              rules={[
                {
                  required: true,
                  message: tInventory(
                    'product.details.productVariant.packagingUnitModal.form.widthInCm.validation.required'
                  ),
                },
              ]}
            >
              <InputNumber style={{ width: '100%' }} addonAfter="cm" min={0.1} precision={2} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="heightInCm"
              label={tInventory(
                'product.details.productVariant.packagingUnitModal.form.heightInCm.label'
              )}
              rules={[
                {
                  required: true,
                  message: tInventory(
                    'product.details.productVariant.packagingUnitModal.form.heightInCm.validation.required'
                  ),
                },
              ]}
            >
              <InputNumber style={{ width: '100%' }} addonAfter="cm" min={0.1} precision={2} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="weightInGram"
              label={tInventory(
                'product.details.productVariant.packagingUnitModal.form.weightInGram.label'
              )}
              rules={[
                {
                  required: true,
                  message: tInventory(
                    'product.details.productVariant.packagingUnitModal.form.weightInGram.validation.required'
                  ),
                },
              ]}
            >
              <InputNumber style={{ width: '100%' }} addonAfter="g" min={1} precision={2} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
