import { Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { StyledMainCard } from '../../styles'
import { calculateDifferenceInPercent } from '../helpers/calculateDifferenceInPercent'
import { DashboardFinancePerformanceCardSection } from './DashboardFinancePerformanceCardSection'
import { FinancePerformanceCardData } from './DashboardFinancePerformanceCardsContainer'

type DashboardFinancePerformanceCardProps = {
  title: string
  timeFrame: string
  dataSource: FinancePerformanceCardData
  loading: boolean
}

export const DashboardFinancePerformanceCard = ({
  title,
  timeFrame,
  dataSource,
  loading,
}: DashboardFinancePerformanceCardProps) => {
  const { t } = useTranslation('dashboard')

  return (
    <StyledMainCard title={title} extra={timeFrame} loading={loading}>
      <Flex vertical gap={8}>
        <DashboardFinancePerformanceCardSection
          title={t('finances.shared.sales')}
          value={dataSource?.sales}
          precision={2}
          delta={calculateDifferenceInPercent(dataSource?.sales, dataSource?.prevSales)}
        />
        <DashboardFinancePerformanceCardSection
          title={t('finances.shared.shipped')}
          value={dataSource?.unitsSold}
          precision={0}
          delta={calculateDifferenceInPercent(dataSource?.unitsSold, dataSource?.prevUnitsSold)}
        />
        <DashboardFinancePerformanceCardSection
          title={t('finances.shared.amazonProfit')}
          value={dataSource?.amazonProfit}
          precision={2}
          delta={calculateDifferenceInPercent(
            dataSource?.amazonProfit,
            dataSource?.prevAmazonProfit
          )}
        />
      </Flex>
    </StyledMainCard>
  )
}
