import { useLazyQuery } from '@apollo/client'
import { Form, FormInstance, FormProps, InputNumber, Select } from 'antd'
import * as i18nIsoCountries from 'i18n-iso-countries'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import i18n from '../../../../config/i18n'
import { GetFnskuLabelDocument, Marketplace } from '../../../../generated/graphql'
import { downloadBase64File } from '../../../../helpers/downloadBase64File'
import { useGlobalStore } from '../../../../stores/useGlobalStore'

const formProps: FormProps<FnskuLabelFormValues> = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
}

enum LabelFormat {
  L = '27 labels, 63,5 x 29,6 mm A4',
  M = '40 labels, 52,5 x 29,7 mm A4',
  N = '44 labels, 48,5 x 25,4 mm A4',
}

type FnskuLabelFormValues = {
  sku: string
  marketplace: Marketplace
  total: number
  labelFormat: LabelFormat
}

type FnskuLabelFormProps = {
  skus: string[]
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  form: FormInstance
  items: number
  marketplaces: Marketplace[]
}

export const FnskuLabelForm = ({
  skus,
  setModalVisible,
  setLoading,
  form,
  items,
  marketplaces,
}: FnskuLabelFormProps) => {
  const { t } = useTranslation('inventory')
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const [downloadLabel] = useLazyQuery(GetFnskuLabelDocument)

  const handleSubmit = async (values: FnskuLabelFormValues) => {
    setLoading(true)

    if (!selectedCompany?.uuid) {
      return
    }

    try {
      const response = await downloadLabel({
        variables: {
          sku: values.sku,
          marketplace: values.marketplace,
          labelsTotalNumber: values.total,
          labelFormat: values.labelFormat,
          companyUuid: selectedCompany?.uuid,
        },
      })

      if (response && response?.data) {
        const pdf = response?.data?.fnskuLabel
        downloadBase64File(pdf, 'application/pdf', `${values.sku}.pdf`)
      } else {
        setLoading(false)
        setModalVisible(false)
        toast.error(t('amazonInboundShipmentPlan.labels.error.message'))
        return
      }

      setLoading(false)
      setModalVisible(false)
      toast.success(t('amazonInboundShipmentPlan.labels.success.message'))
    } catch (error) {
      setLoading(false)
      setModalVisible(false)
      if (error instanceof Error) {
        toast.error(t('amazonInboundShipmentPlan.labels.error.message'), {
          description: error.message,
        })
      } else {
        toast.error(t('amazonInboundShipmentPlan.labels.error.message'))
      }
    }
  }

  return (
    <Form<FnskuLabelFormValues>
      form={form}
      initialValues={{ sku: skus[0], labelFormat: 'L', total: items, marketplace: marketplaces[0] }}
      onFinish={handleSubmit}
      {...formProps}
    >
      <Form.Item name="sku" label={'SKU'} rules={[{ enum: skus, required: true, type: 'enum' }]}>
        <Select
          placeholder={t('product.details.amazonListings.modal.marketplacePlaceholder')}
          popupMatchSelectWidth={false}
        >
          {skus.map((sku, index) => (
            <Select.Option value={sku} key={`sku_${index}`} data-testid={`sku_${sku}`}>
              {sku}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="labelFormat"
        label={t('amazonInboundShipmentPlan.modal.labels.fnsku.labelFormat')}
        rules={[{ enum: Object.keys(LabelFormat), required: true, type: 'enum' }]}
      >
        <Select
          placeholder={t('product.details.amazonListings.modal.marketplacePlaceholder')}
          popupMatchSelectWidth={false}
        >
          {Object.entries(LabelFormat).map(([key, value], index) => (
            <Select.Option
              value={key}
              key={`labelFormat_${index}`}
              data-testid={`labelFormat_${key}`}
            >
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="total"
        label={t('amazonInboundShipmentPlan.modal.labels.fnsku.total')}
        rules={[{ required: true }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item
        name="marketplace"
        label={t('product.details.amazonListings.modal.marketplace')}
        rules={[{ enum: marketplaces, required: true, type: 'enum' }]}
      >
        <Select placeholder={t('product.details.amazonListings.modal.marketplacePlaceholder')}>
          {marketplaces.map((marketplace, index) => (
            <Select.Option value={marketplace} key={`marketplace_${index}`}>
              {i18nIsoCountries.getName(marketplace, i18n.language)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}
