import { Flex, Statistic, Tooltip, Typography } from 'antd'
import { CircleHelp } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { formatMoney } from '../../../../helpers/formatMoney'
import { StyledSkeleton, StyledSubCard } from '../../styles'

type DashboardSubCardRevenueProps = {
  totalRevenue: number
  pendingRevenue: number
  loading: boolean
}

export const DashboardSubCardRevenue = ({
  totalRevenue,
  pendingRevenue,
  loading,
}: DashboardSubCardRevenueProps) => {
  const { t } = useTranslation('dashboard')

  return (
    <StyledSubCard>
      <StyledSkeleton loading={loading} paragraph={{ rows: 1 }}>
        <Statistic
          title={
            <Flex align="center" gap={8}>
              <Typography.Text>{t('performance.shared.revenue')}</Typography.Text>
              <Tooltip title={t('performance.shared.revenueTooltip')}>
                <CircleHelp size={16} />
              </Tooltip>
            </Flex>
          }
          value={formatMoney(totalRevenue, 'EUR')}
          suffix={
            <Typography.Text style={{ marginLeft: '0.5rem', fontSize: '1rem', opacity: 0.4 }}>
              {formatMoney(pendingRevenue, 'EUR')}
            </Typography.Text>
          }
        />
      </StyledSkeleton>
    </StyledSubCard>
  )
}
