import { useMutation } from '@apollo/client'
import { Alert, Button, Flex, Form, Modal, Select } from 'antd'
import { SquarePen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  GetProductComplianceCheckByIdentifierDocument,
  UpdateProductComplianceCheckItemStatusDocument,
} from '../../../generated/graphql'

type EditProductComplianceCheckItemStatusModalFormInstance = {
  complianceStatus: string
}

type EditProductComplianceCheckItemStatusModalProps = {
  productComplianceCheckIdentifier: string
  countryCode: string
  complianceStatus: string
}

export const EditProductComplianceCheckItemStatusModal = ({
  productComplianceCheckIdentifier,
  complianceStatus,
  countryCode,
}: EditProductComplianceCheckItemStatusModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { t } = useTranslation(['compliance'])
  const [form] = Form.useForm<EditProductComplianceCheckItemStatusModalFormInstance>()

  const [updateProductComplianceCheckItemStatus] = useMutation(
    UpdateProductComplianceCheckItemStatusDocument
  )

  const selectOptions = [
    {
      value: 'APPROVED_WITH_RESERVATION',
      label: t('compliance:table.status.status.APPROVED_WITH_RESERVATION'),
    },
    { value: 'COMPLIANT', label: t('compliance:table.status.status.COMPLIANT') },
  ]

  if (complianceStatus === 'APPROVED_WITH_RESERVATION') {
    selectOptions.splice(0, 1)
  }

  const handleSubmit = async ({
    complianceStatus,
  }: EditProductComplianceCheckItemStatusModalFormInstance) => {
    setSubmitting(true)

    try {
      await updateProductComplianceCheckItemStatus({
        variables: {
          productComplianceCheckIdentifier,
          countryCode,
          complianceStatus,
        },
        update: (cache, { data }) => {
          const updatedProductComplianceCheckItem = data?.updateProductComplianceCheckItemStatus
          if (!updatedProductComplianceCheckItem) return

          const cachedQuery = cache.readQuery({
            query: GetProductComplianceCheckByIdentifierDocument,
            variables: { identifier: productComplianceCheckIdentifier },
          })

          if (cachedQuery) {
            cache.writeQuery({
              query: GetProductComplianceCheckByIdentifierDocument,
              variables: { identifier: productComplianceCheckIdentifier },
              data: {
                productComplianceCheck: {
                  ...cachedQuery.productComplianceCheck,
                  items: cachedQuery.productComplianceCheck.items.map((item) => {
                    if (item.countryCode === countryCode) {
                      return {
                        ...item,
                        markedCompliantDate: updatedProductComplianceCheckItem.markedCompliantDate,
                        complianceStatus: complianceStatus,
                      }
                    }
                    return item
                  }),
                },
              },
            })
          }
        },
      })

      toast.success(t('compliance:table.status.editModal.toast.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('compliance:table.status.editModal.toast.error'))
    } finally {
      setSubmitting(false)
      setOpen(false)
    }
  }
  return (
    <>
      <Button
        type="text"
        icon={<SquarePen size={16} />}
        size="small"
        onClick={() => setOpen(true)}
      />
      <Modal
        title={t('compliance:table.status.editModal.title')}
        open={open}
        okText={t('compliance:table.status.editModal.button.edit')}
        cancelText={t('compliance:table.status.editModal.button.cancel')}
        onOk={() => form.submit()}
        okButtonProps={{ danger: true }}
        onCancel={() => setOpen(false)}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Form<EditProductComplianceCheckItemStatusModalFormInstance>
          form={form}
          onFinish={handleSubmit}
        >
          <Flex vertical gap={16}>
            <Alert
              description={t('compliance:table.status.editModal.alert')}
              type="warning"
              showIcon
            />
            <Form.Item
              name="complianceStatus"
              label={t('compliance:table.status.editModal.form.complianceStatus.label')}
              rules={[
                {
                  required: true,
                  message: t(
                    'compliance:table.status.editModal.form.complianceStatus.validation.required'
                  ),
                },
              ]}
            >
              <Select
                placeholder={t(
                  'compliance:table.status.editModal.form.complianceStatus.placeholder'
                )}
                options={selectOptions}
              />
            </Form.Item>
          </Flex>
        </Form>
      </Modal>
    </>
  )
}
