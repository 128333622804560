import { Flex, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import { GetProductVariantQuery } from '../../../generated/graphql'
import { StyledMainCard } from '../../dashboard/styles'
import { ProductVariantDescriptions } from './ProductVariantDescriptions/ProductVariantDescriptions'

type ProductVariantDataCardProps = {
  productVariant: GetProductVariantQuery['productVariant'] | null
  loading: boolean
}

export const ProductVariantDataCard = ({
  productVariant,
  loading,
}: ProductVariantDataCardProps) => {
  const { t } = useTranslation('inventory')

  return (
    <StyledMainCard
      title={t('product.details.productVariant.variant', {
        sequence: productVariant?.sequence ?? '',
      })}
      style={{ height: 'auto' }}
    >
      <Skeleton loading={loading} active title={false} paragraph={{ rows: 3, width: '100%' }}>
        {!!productVariant && (
          <Flex vertical gap={16}>
            <ProductVariantDescriptions productVariant={productVariant} />
          </Flex>
        )}
      </Skeleton>
    </StyledMainCard>
  )
}
