import { AmazonListingFieldsFragment, AmazonOrderStatus } from '../../../../generated/graphql'

export type AmazonListingOrderTableRecord = {
  orderItemId: string
  quantity: number
  itemPrice: {
    amount: number | null
    currency: string | null
  }
  amazonOrder: {
    amazonOrderId: string
    purchaseDate: string
    status: AmazonOrderStatus
    lastUpdatedDate: string
    fulfillmentChannel: string
  }
}

export function prepareAmazonListingOrderData(
  orderLineItems: AmazonListingFieldsFragment['orderLineItems']
): AmazonListingOrderTableRecord[] {
  if (!orderLineItems) {
    return []
  }

  return orderLineItems.map((item) => ({
    orderItemId: item.orderItemId,
    quantity: item.quantity,
    itemPrice: {
      amount: item.itemPrice.amount ?? null,
      currency: item.itemPrice.currency ?? null,
    },
    amazonOrder: {
      amazonOrderId: item.amazonOrder.amazonOrderId,
      purchaseDate: item.amazonOrder.purchaseDate,
      status: item.amazonOrder.status,
      lastUpdatedDate: item.amazonOrder.lastUpdatedDate,
      fulfillmentChannel: item.amazonOrder.fulfillmentChannel,
    },
  }))
}
