import { Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  TicketMainCollapse,
  TicketMainPanel,
  TicketSubCollapse,
  TicketSubPanel,
} from '../styles/TicketCreateViewStyles'
import TicketTopics from './TicketTopics'

type Topic = {
  title: string
  url: string
}

type SubCategory = {
  subCategoryTitle: string
  subCategoryDescription: string
  subCategoryTopics: Topic[]
}

export type TicketMainCategory = {
  categoryTitle: string
  categoryTitleTag: string
  categoryDescription: string
  subCategories: SubCategory[]
}

export const TicketsCollapse = () => {
  const renderTicketsView = () => {
    const { t, ready } = useTranslation(['ticket'])

    if (!ready) {
      return <LoadingSpinner />
    }

    const translatedTicket: TicketMainCategory[] = t('ticketMainCategories', {
      returnObjects: true,
    })

    return translatedTicket.map((mainCategory: TicketMainCategory, mainIndex: number) => {
      const renderSubCategories = () => {
        return mainCategory.subCategories.map((subcategory, subIndex) => {
          return (
            <TicketSubCollapse bordered={false} key={`${mainIndex}${subIndex}`}>
              <TicketSubPanel
                header={
                  <Space direction="vertical">
                    <Typography.Text strong>{subcategory.subCategoryTitle}</Typography.Text>
                    <Typography.Text>{subcategory.subCategoryDescription}</Typography.Text>
                  </Space>
                }
                key={subIndex}
              >
                <TicketTopics
                  ticketTopics={subcategory.subCategoryTopics}
                  categoryTitleTag={mainCategory.categoryTitleTag}
                />
              </TicketSubPanel>
            </TicketSubCollapse>
          )
        })
      }

      return (
        <TicketMainCollapse bordered={false} key={mainIndex}>
          <TicketMainPanel
            header={
              <Space direction="vertical">
                <Typography.Text strong>{mainCategory.categoryTitle}</Typography.Text>
                <Typography.Text>{mainCategory.categoryDescription}</Typography.Text>
              </Space>
            }
            key={mainIndex}
          >
            <Space direction="vertical" size="small" style={{ display: 'flex' }}>
              {renderSubCategories()}
            </Space>
          </TicketMainPanel>
        </TicketMainCollapse>
      )
    })
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      {renderTicketsView()}
    </Space>
  )
}
