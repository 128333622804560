import { useMutation, useQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  GridApi,
  ICellRendererParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Badge, Button, Flex, Space } from 'antd'
import dayjs from 'dayjs'
import { Pen, Plus, Save, Trash } from 'lucide-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { FormattedDate } from '../../../components/FormattedDate'
import { Table } from '../../../components/Table/Table'
import {
  CreateInvoiceArticleSubscriptionDocument,
  Currency,
  Frequency,
  GetAllActiveCompaniesDocument,
  GetInvoiceArticlesDocument,
  UpdateInvoiceArticleSubscriptionDocument,
} from '../../../generated/graphql'
import { formatMoney } from '../../../helpers/formatMoney'
import { dateComparator, dateFilterParams, findInsertionIndex } from '../../../helpers/tableHelpers'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { TablePersistKey } from '../../../stores/useTableStore'

export type InvoiceArticleSubscriptionRecord = {
  uuid: string
  buyerCompany: string
  articleName: string
  netAmount: number
  currency: string
  commissionInPercent: number
  frequency: Frequency
  startDate: string
  endDate: string | null
  createdAt: string
  updatedAt: string
}

type InvoiceArticleSubscriptionsTableProps = {
  invoiceArticleSubscriptions: InvoiceArticleSubscriptionRecord[]
}

const currencyOptions = Object.values(Currency).map((currencyValue) => ({
  value: currencyValue,
  label: currencyValue,
}))

const frequencyOptions = Object.values(Frequency).map((frequencyValue) => ({
  value: frequencyValue,
  label: frequencyValue,
}))

export const InvoiceArticleSubscriptionsTable = ({
  invoiceArticleSubscriptions: initialInvoiceArticleSubscriptions,
}: InvoiceArticleSubscriptionsTableProps) => {
  const { t } = useTranslation(['translation', 'invoices'])
  const gridRef = useRef<AgGridReact<InvoiceArticleSubscriptionRecord>>(null)
  const [rowData, setRowData] = useState<InvoiceArticleSubscriptionRecord[]>(
    initialInvoiceArticleSubscriptions
  )
  const theme = useTheme()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { data: invoiceArticlesData } = useQuery(GetInvoiceArticlesDocument, {
    skip: !selectedCompany?.uuid,
    variables: {
      companyUuid: selectedCompany?.uuid,
    },
  })

  const { data: companiesData } = useQuery(GetAllActiveCompaniesDocument, {
    skip: !selectedCompany?.uuid,
    variables: {},
  })

  const [updateInvoiceArticleSubscription] = useMutation(UpdateInvoiceArticleSubscriptionDocument)
  const [createInvoiceArticleSubscription] = useMutation(CreateInvoiceArticleSubscriptionDocument)

  const articleNameOptions = useMemo(
    () =>
      invoiceArticlesData?.invoiceArticles
        ?.filter((article) => article.isActive)
        ?.map((article) => ({
          value: article.name,
          uuid: article.uuid,
        })) ?? [],
    [invoiceArticlesData]
  )

  const companyOptions = useMemo(
    () => companiesData?.companies?.map((company) => ({ value: company.sellerId })) ?? [],
    [companiesData]
  )
  const onCellValueChanged = useCallback(
    async (event: CellValueChangedEvent<InvoiceArticleSubscriptionRecord>) => {
      if (!event) {
        console.error('Cell value changed event is null')
        return
      }

      const field = event.colDef.field as keyof InvoiceArticleSubscriptionRecord

      if (!field) {
        return false
      }

      const newValue = event.newValue
      const oldValue = event.oldValue

      if (newValue === oldValue) {
        return false
      }

      try {
        let value = newValue
        if (field === 'startDate' || field === 'endDate') {
          value = dayjs(newValue instanceof Date ? newValue : newValue).format('YYYY-MM-DD')
        } else if (typeof newValue === 'string') {
          value = newValue.trim()
        }

        const updatedData = { ...event.data, [field]: value }

        if (field === 'currency') {
          event.api.refreshCells({
            force: true,
            rowNodes: [event.node],
            columns: ['netAmount'],
          })
        }

        event.node.setData(updatedData)

        if (!event.data.uuid.startsWith('temp-')) {
          await updateInvoiceArticleSubscription({
            variables: {
              uuid: event.data.uuid,
              input: {
                [field]: value,
              },
            },
          })

          toast.success(t('invoices:subscriptions.table.update.success'))
        }
      } catch (error) {
        event.node.setData({ ...event.data, [field]: oldValue })
        console.error(error)
        toast.error(t('invoices:subscriptions.table.update.error'))
      } finally {
        event.api.refreshCells({
          force: true,
          rowNodes: [event.node],
          columns: [field],
        })
      }
    },
    [t, updateInvoiceArticleSubscription]
  )

  const handleAddEmptyRow = useCallback(() => {
    const api = gridRef.current?.api
    if (!api) return

    const newEmptyRow: InvoiceArticleSubscriptionRecord = {
      uuid: `temp-${Date.now()}`,
      articleName: '',
      netAmount: 0,
      currency: 'EUR',
      commissionInPercent: 0,
      frequency: Frequency.MONTHLY,
      startDate: new Date().toISOString(),
      endDate: null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      buyerCompany: '',
    }

    const insertIndex = findInsertionIndex(api)
    api.applyTransaction({ add: [newEmptyRow], addIndex: insertIndex })
  }, [])

  const handleSaveNewRows = useCallback(
    async (gridApi: GridApi<InvoiceArticleSubscriptionRecord>) => {
      try {
        const rowData: InvoiceArticleSubscriptionRecord[] = []
        gridApi.forEachNodeAfterFilterAndSort((node) => {
          if (node.data) {
            rowData.push(node.data)
          }
        })

        const newRows = rowData.filter((row) => row.uuid.startsWith('temp-'))

        if (newRows.length === 0) {
          toast.info(t('invoices:subscriptions.table.save.noNewRows'))
          return
        }

        const isValid = newRows.every(
          (row) => row.articleName && row.netAmount > 0 && row.buyerCompany
        )

        if (!isValid) {
          toast.error(t('invoices:subscriptions.table.save.invalidRows'))
          return
        }

        for (const newRow of newRows) {
          const articleOption = articleNameOptions.find(
            (option) => option.value === newRow.articleName
          )

          if (!articleOption) {
            toast.error(t('invoices:subscriptions.table.save.invalidArticle'))
            return
          }

          const { data } = await createInvoiceArticleSubscription({
            variables: {
              input: {
                buyerCompanySellerId: newRow.buyerCompany,
                netAmount: newRow.netAmount,
                currency: newRow.currency as Currency,
                commissionInPercent: newRow.commissionInPercent,
                frequency: newRow.frequency,
                startDate: newRow.startDate,
                endDate: newRow.endDate,
                invoiceArticleUuid: articleOption.uuid,
              },
            },
          })

          if (data?.createInvoiceArticleSubscription) {
            const createdSubscription = data.createInvoiceArticleSubscription
            gridApi.applyTransaction({
              remove: [newRow],
              add: [
                {
                  ...createdSubscription,
                  articleName: createdSubscription.invoiceArticle.name,
                  endDate: createdSubscription.endDate || null,
                  buyerCompany: newRow.buyerCompany,
                  uuid: '',
                },
              ],
            })
          }
        }

        toast.success(t('invoices:subscriptions.table.save.success'))
      } catch (error) {
        console.error(error)
        toast.error(t('invoices:subscriptions.table.save.error'))
      }
    },
    [t, createInvoiceArticleSubscription, articleNameOptions]
  )

  const columnDefs = useMemo<
    (ColDef<InvoiceArticleSubscriptionRecord> | ColGroupDef<InvoiceArticleSubscriptionRecord>)[]
  >(
    () => [
      {
        lockPosition: true,
        width: 96,
        resizable: false,
        sortable: false,
        filter: false,
        pinned: 'left',
        headerComponent: ActionHeader,
        headerComponentParams: {
          onAddEmptyRow: handleAddEmptyRow,
          onSaveNewRows: () => {
            const api = gridRef.current?.api
            if (api) handleSaveNewRows(api)
          },
          api: gridRef.current?.api,
        },
        cellRenderer: ({ data, api }: ICellRendererParams<InvoiceArticleSubscriptionRecord>) => {
          const uuid = data?.uuid
          const isNewRow = uuid?.startsWith('temp-')

          if (!uuid) {
            return null
          }

          if (isNewRow) {
            const newRows: InvoiceArticleSubscriptionRecord[] = []
            api?.forEachNode((rowNode) => {
              if (rowNode.data?.uuid?.startsWith('temp-')) {
                newRows.push(rowNode.data)
              }
            })
            newRows.sort((a, b) => {
              const timeA = parseInt(a.uuid?.split('-')[1] ?? '0')
              const timeB = parseInt(b.uuid?.split('-')[1] ?? '0')
              return timeA - timeB
            })
            const index = newRows.findIndex((row) => row.uuid === uuid) + 1

            return (
              <Flex gap={16} align="center" style={{ width: '100%' }}>
                <Button
                  icon={<Trash size={16} />}
                  danger
                  onClick={() => {
                    if (api) {
                      api.applyTransaction({ remove: [data] })
                      api.refreshCells({ force: true })
                    }
                  }}
                />
                <Badge count={index} />
              </Flex>
            )
          }

          return null
        },
      },
      {
        field: 'articleName',
        headerName: t('invoices:subscriptions.table.article'),
        headerTooltip: t('invoices:subscriptions.table.articleTooltip'),
        filter: 'agTextColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') ?? false,
        cellEditor: 'agRichSelectCellEditor',
        valueParser: (params) => {
          const value = params.newValue?.trim()
          return articleNameOptions.find((option) => option.value === value)?.value ?? null
        },
        cellEditorParams: {
          values: articleNameOptions.map((option) => option.value),
          cellRenderer: ({ value }: { value: string }) => value,
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
          searchType: 'matchAny',
        },
        cellRenderer: ({
          value,
          data,
        }: ICellRendererParams<
          InvoiceArticleSubscriptionRecord,
          InvoiceArticleSubscriptionRecord['articleName']
        >) => {
          return (
            <Space>
              {data?.uuid.startsWith('temp-') && <Pen size={16} />}
              {value ?? t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
        cellStyle: (params) =>
          params.data?.uuid?.startsWith('temp-')
            ? { backgroundColor: `${theme.colors.warning}20` }
            : undefined,
      },
      {
        field: 'buyerCompany',
        headerName: t('invoices:subscriptions.table.buyerCompany'),
        filter: 'agTextColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') ?? false,
        cellEditor: 'agRichSelectCellEditor',
        valueParser: (params) => {
          const value = params.newValue?.trim()
          return companyOptions.find((option) => option.value === value)?.value ?? null
        },
        cellEditorParams: {
          values: companyOptions.map((option) => option.value),
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
          searchType: 'matchAny',
        },
        cellRenderer: ({ value, data }: ICellRendererParams<InvoiceArticleSubscriptionRecord>) => {
          return (
            <Space>
              {data?.uuid.startsWith('temp-') && <Pen size={16} />}
              {value}
            </Space>
          )
        },
        cellStyle: (params) =>
          params.data?.uuid?.startsWith('temp-')
            ? { backgroundColor: `${theme.colors.warning}20` }
            : undefined,
      },
      {
        field: 'netAmount',
        headerName: t('invoices:subscriptions.table.netPrice'),
        filter: 'agNumberColumnFilter',
        editable: true,
        suppressPaste: true,
        cellRenderer: ({
          data,
          value,
        }: ICellRendererParams<
          InvoiceArticleSubscriptionRecord,
          InvoiceArticleSubscriptionRecord['netAmount']
        >) => {
          const currency = data?.currency ?? 'EUR'

          return (
            <Space>
              <Pen size={16} />
              {formatMoney(value ?? 0, currency) ?? t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
        cellStyle: (params) =>
          params.data?.uuid?.startsWith('temp-')
            ? { backgroundColor: `${theme.colors.warning}20` }
            : undefined,
      },
      {
        field: 'currency',
        headerName: t('invoices:subscriptions.table.currency'),
        filter: 'agTextColumnFilter',
        editable: (params) => params.data?.uuid.startsWith('temp-') ?? false,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: currencyOptions.map((option) => option.value),
        },
        cellRenderer: ({
          value,
          data,
        }: ICellRendererParams<
          InvoiceArticleSubscriptionRecord,
          InvoiceArticleSubscriptionRecord['currency']
        >) => {
          return (
            <Space>
              {data?.uuid.startsWith('temp-') && <Pen size={16} />}
              {value ?? t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
        cellStyle: (params) =>
          params.data?.uuid?.startsWith('temp-')
            ? { backgroundColor: `${theme.colors.warning}20` }
            : undefined,
      },
      {
        field: 'frequency',
        headerName: t('invoices:subscriptions.table.frequency'),
        filter: 'agTextColumnFilter',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: frequencyOptions.map((option) => option.value),
        },
        cellRenderer: ({
          value,
        }: ICellRendererParams<
          InvoiceArticleSubscriptionRecord,
          InvoiceArticleSubscriptionRecord['frequency']
        >) => {
          return (
            <Space>
              <Pen size={16} />
              {value
                ? t('invoices:subscriptions.frequencies.' + value.toLowerCase(), {
                    defaultValue: value,
                  })
                : t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
        cellStyle: (params) =>
          params.data?.uuid?.startsWith('temp-')
            ? { backgroundColor: `${theme.colors.warning}20` }
            : undefined,
      },
      {
        field: 'startDate',
        headerName: t('invoices:subscriptions.table.startDate'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        editable: true,
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: dayjs().format('YYYY-MM-DD'),
          max: dayjs().add(10, 'years').format('YYYY-MM-DD'),
        },
        valueParser: (params) => {
          if (!params.newValue) return params.oldValue
          return dayjs(params.newValue).format('YYYY-MM-DD')
        },
        valueFormatter: (params) => {
          if (!params.value) return ''
          return dayjs(params.value).format('DD.MM.YYYY')
        },
        cellRenderer: ({
          value,
        }: ICellRendererParams<
          InvoiceArticleSubscriptionRecord,
          InvoiceArticleSubscriptionRecord['startDate']
        >) => {
          return (
            <Space>
              <Pen size={16} />
              {value ? (
                <FormattedDate date={value} format={'DD.MM.YYYY'} />
              ) : (
                t('shared.button.edit', { ns: 'translation' })
              )}
            </Space>
          )
        },
        cellStyle: (params) =>
          params.data?.uuid?.startsWith('temp-')
            ? { backgroundColor: `${theme.colors.warning}20` }
            : undefined,
      },
      {
        field: 'endDate',
        headerName: t('invoices:subscriptions.table.endDate'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        editable: true,
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: dayjs().format('YYYY-MM-DD'),
          max: dayjs().add(10, 'years').format('YYYY-MM-DD'),
        },
        valueParser: (params) => {
          if (!params.newValue) return params.oldValue
          return dayjs(params.newValue).format('YYYY-MM-DD')
        },
        valueFormatter: (params) => {
          if (!params.value) return ''
          return dayjs(params.value).format('DD.MM.YYYY')
        },
        cellRenderer: ({
          value,
        }: ICellRendererParams<
          InvoiceArticleSubscriptionRecord,
          InvoiceArticleSubscriptionRecord['endDate']
        >) => {
          return (
            <Space>
              <Pen size={16} />
              {value ? (
                <FormattedDate date={value} format={'DD.MM.YYYY'} />
              ) : (
                t('shared.button.edit', { ns: 'translation' })
              )}
            </Space>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: t('invoices:subscriptions.table.createdAt'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        cellRenderer: ({
          value,
        }: ICellRendererParams<
          InvoiceArticleSubscriptionRecord,
          InvoiceArticleSubscriptionRecord['createdAt']
        >) => {
          if (value) {
            return <FormattedDate date={value} withRelativeTime={true} />
          }
        },
        hide: true,
      },
      {
        field: 'updatedAt',
        headerName: t('invoices:subscriptions.table.updatedAt'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        cellRenderer: ({
          value,
        }: ICellRendererParams<
          InvoiceArticleSubscriptionRecord,
          InvoiceArticleSubscriptionRecord['updatedAt']
        >) => {
          if (value) {
            return <FormattedDate date={value} withRelativeTime={true} />
          }
        },
      },
      {
        field: 'uuid',
        headerName: t('invoices:subscriptions.table.uuid'),
        filter: 'agTextColumnFilter',
        hide: true,
      },
    ],
    [articleNameOptions, companyOptions]
  )

  useEffect(() => {
    setRowData(initialInvoiceArticleSubscriptions)
  }, [initialInvoiceArticleSubscriptions, selectedCompany])

  useEffect(() => {
    if (companyOptions.length > 0 && gridRef.current?.api) {
      gridRef.current.api.redrawRows()
    }
  }, [companyOptions, rowData])

  return (
    <Table<InvoiceArticleSubscriptionRecord>
      ref={gridRef}
      columnDefs={columnDefs}
      defaultExcelExportParams={{
        fileName: `invoice-article-subscriptions-${dayjs().format('DD-MM-YYYY')}`,
      }}
      fullHeight
      onCellValueChanged={onCellValueChanged}
      persist={{
        key: TablePersistKey.INVOICE_ARTICLE_SUBSCRIPTION,
        storage: localStorage,
      }}
      rowData={rowData}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
    />
  )
}

const ActionHeader = ({
  onAddEmptyRow,
  onSaveNewRows,
  api,
}: {
  onAddEmptyRow: () => void
  onSaveNewRows: () => void
  api: GridApi<InvoiceArticleSubscriptionRecord> | undefined
}) => {
  const [hasNewRows, setHasNewRows] = useState(false)

  useEffect(() => {
    const checkForNewRows = () => {
      if (api) {
        const rowData: InvoiceArticleSubscriptionRecord[] = []
        api.forEachNode((node) => {
          if (node.data) {
            rowData.push(node.data)
          }
        })
        const newRows = rowData.some((row) => row?.uuid?.startsWith('temp-'))
        setHasNewRows(newRows)
      }
    }

    checkForNewRows()
    const intervalId = setInterval(checkForNewRows, 500)

    return () => clearInterval(intervalId)
  }, [api])

  const handleSaveNewRows = useCallback(() => {
    if (hasNewRows) {
      onSaveNewRows()
    }
  }, [hasNewRows, onSaveNewRows])

  return (
    <Space>
      <Button onClick={onAddEmptyRow} icon={<Plus size={16} />} type="primary" />
      <Button
        onClick={handleSaveNewRows}
        icon={<Save size={16} />}
        type="primary"
        disabled={!hasNewRows}
      />
    </Space>
  )
}
