import { useLazyQuery } from '@apollo/client'
import { Button, Col, DatePicker, Form, Radio, Row, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import mixpanel from 'mixpanel-browser'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { GetAllBusinessReportsDocument, Marketplace } from '../../../generated/graphql'
import { exportCsvFile, exportXlsxFile } from '../../../helpers/exportFile'
import { getMarketplaceDomain } from '../../../helpers/getMarketplaceDomain'
import { useDateRangePresets } from '../../../hooks/useDateRangePresets'
import { useMarketplaceOptions } from '../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { getTranslatedKeys } from '../helpers'
import { FileFormat } from '../interfaces'
import { prepareBusinessReportTableData } from './helpers/prepareBusinessReportTableData'

const { RangePicker } = DatePicker

type BusinessReportInput = {
  dateRange: [Dayjs, Dayjs]
  marketplaces: Marketplace[]
  fileFormat: string
}

export const BusinessReportView = () => {
  const [loading, setLoading] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const [form] = useForm<BusinessReportInput>()
  const presets = useDateRangePresets()
  const marketplaceOptions = useMarketplaceOptions()

  const { t } = useTranslation(['report', 'translation'])

  const [getBusinessReports] = useLazyQuery(GetAllBusinessReportsDocument)

  const downloadReport = async (values: BusinessReportInput) => {
    mixpanel.track('Downloaded report', {
      type: 'business-report',
    })
    setLoading(true)
    try {
      const { data, error } = await getBusinessReports({
        errorPolicy: 'none',
        fetchPolicy: 'cache-first',
        variables: {
          companyUuid: selectedCompany?.uuid,
          marketplaces: values.marketplaces,
          startDate: values.dateRange[0].format('YYYY-MM-DD'),
          endDate: values.dateRange[1].format('YYYY-MM-DD'),
        },
      })

      if (!data || error) {
        toast.error(t('shared.error.message', { ns: 'translation' }))
        throw new Error(t('shared.error.message', { ns: 'translation' }))
      }
      const businessReports = prepareBusinessReportTableData(data)

      if (!businessReports.length) {
        toast.success(t('shared.noData', { ns: 'translation' }))
      }

      const cleanedReports = businessReports.map((obj) => {
        const newObj = { ...obj }
        delete newObj.rowIndex
        return newObj
      })

      const translatedOrders = getTranslatedKeys(cleanedReports, 'businessReports', t)
      const fileName = `business-reports_${values.dateRange[0].format(
        'YYYY-MM-DD'
      )}-${values.dateRange[1].format('YYYY-MM-DD')}`

      if (values.fileFormat === FileFormat.CSV) {
        exportCsvFile(translatedOrders, fileName)
      } else {
        exportXlsxFile(translatedOrders, fileName)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <SubHeader heading={t('businessReports.heading')} withBackButton />
      <ViewContainer>
        <Row justify="center">
          <Col xs={24} sm={20} md={16} lg={12} xl={10}>
            <Form<BusinessReportInput>
              name="business-reports"
              form={form}
              layout="vertical"
              onFinish={downloadReport}
              style={{ width: '100%', maxWidth: '640px' }}
              initialValues={{
                dateRange: presets[3]?.value,
                marketplaces: [Marketplace.DE],
                fileFormat: FileFormat.CSV,
              }}
            >
              <Form.Item
                label={t('businessReports.form.dateRange')}
                name="dateRange"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <RangePicker
                  allowClear={false}
                  onCalendarChange={(values) => {
                    if (values?.[0] && values?.[1]) {
                      form.setFieldsValue({
                        dateRange: [values[0].startOf('day'), values[1].endOf('day')],
                      })
                    }
                  }}
                  disabledDate={(date) =>
                    date.isAfter(dayjs()) || date.isBefore(dayjs().subtract(365, 'day'))
                  }
                  format="DD.MM.YY"
                  presets={presets}
                />
              </Form.Item>
              <Form.Item
                label={t('businessReports.form.marketplaces')}
                name="marketplaces"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select<Marketplace[]>
                  mode="multiple"
                  options={marketplaceOptions.map((marketplace) => ({
                    value: marketplace,
                    label: `Amazon.${getMarketplaceDomain(marketplace)}`,
                  }))}
                  placeholder={t('shared.form.placeholder.select', {
                    ns: 'translation',
                  })}
                  allowClear
                  showSearch
                  filterOption={true}
                  optionFilterProp="label"
                />
              </Form.Item>
              <Form.Item
                name="fileFormat"
                label={t('businessReports.form.fileFormat')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={FileFormat.XLSX}>Excel</Radio>
                    <Radio value={FileFormat.CSV}>CSV</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Row justify="end">
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t('businessReports.form.download')}
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </ViewContainer>
    </>
  )
}
