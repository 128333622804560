import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { CompanyType } from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { useGlobalStore } from '../../../../stores/useGlobalStore'

export type PaymentsOverviewTableRecord = {
  name: string
  asin: string
  sku: string
  quantity: number
  shipments: number
  refunds: number
  adjustments: number
  remissions: number
  storageFees: number
  longTermStorageFees: number
  payout: number
  remunerationAmount: number
  partialPayment: number
  retailPayout: number
}

type PaymentsOverviewTableProps = {
  overview: PaymentsOverviewTableRecord[]
}

export const PaymentsOverviewTable = ({ overview }: PaymentsOverviewTableProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation(['billing'])

  const [columnDefs] = useState<
    (ColDef<PaymentsOverviewTableRecord> | ColGroupDef<PaymentsOverviewTableRecord>)[]
  >([
    {
      field: 'name',
      headerName: t('payments.overview.table.name'),
    },
    {
      field: 'asin',
      headerName: t('payments.overview.table.asin'),
    },
    {
      field: 'sku',
      headerName: t('payments.overview.table.sku'),
    },
    {
      field: 'quantity',
      headerName: t('payments.overview.table.quantity'),
      filter: 'agNumberColumnFilter',
      initialSort: 'desc',
      aggFunc: 'sum',
    },
    {
      field: 'shipments',
      headerName: t('payments.overview.table.shipments'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsOverviewTableRecord,
          PaymentsOverviewTableRecord['shipments']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    {
      field: 'refunds',
      headerName: t('payments.overview.table.refunds'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsOverviewTableRecord,
          PaymentsOverviewTableRecord['refunds']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    {
      field: 'adjustments',
      headerName: t('payments.overview.table.adjustments'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsOverviewTableRecord,
          PaymentsOverviewTableRecord['adjustments']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    {
      field: 'remissions',
      headerName: t('payments.overview.table.remissions'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsOverviewTableRecord,
          PaymentsOverviewTableRecord['remissions']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    {
      field: 'payout',
      headerName: t('payments.overview.table.payout'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsOverviewTableRecord,
          PaymentsOverviewTableRecord['payout']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    {
      field: 'storageFees',
      headerName: t('payments.overview.table.storageFees'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsOverviewTableRecord,
          PaymentsOverviewTableRecord['storageFees']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const value = params.value ? params.value * -1 : 0

        return <Typography.Text>{formatMoney(value, 'EUR')}</Typography.Text>
      },
    },
    {
      field: 'longTermStorageFees',
      headerName: t('payments.overview.table.longTermStorageFees'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsOverviewTableRecord,
          PaymentsOverviewTableRecord['longTermStorageFees']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const value = params.value ? params.value * -1 : 0

        return <Typography.Text>{formatMoney(value, 'EUR')}</Typography.Text>
      },
    },
    {
      field: 'retailPayout',
      headerName: t('payments.overview.table.totalPayout'),
      hide: selectedCompany.type !== CompanyType.WHOLESALER,
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsOverviewTableRecord,
          PaymentsOverviewTableRecord['partialPayment']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    {
      headerName: t('payments.overview.table.totalPayout'),
      children: [
        {
          field: 'remunerationAmount',
          headerName: t('payments.overview.table.remunerationAmount'),
          hide: selectedCompany.type === CompanyType.WHOLESALER,
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          cellRenderer: (
            params: ICellRendererParams<
              PaymentsOverviewTableRecord,
              PaymentsOverviewTableRecord['remunerationAmount']
            >
          ) => {
            if (params.value === null || params.value === undefined) {
              return <Typography.Text type="secondary">-</Typography.Text>
            }

            return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
          },
        },
        {
          field: 'partialPayment',
          headerName: t('payments.overview.table.partialPayment'),
          hide: selectedCompany.type === CompanyType.WHOLESALER,
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          cellRenderer: (
            params: ICellRendererParams<
              PaymentsOverviewTableRecord,
              PaymentsOverviewTableRecord['partialPayment']
            >
          ) => {
            if (params.value === null || params.value === undefined) {
              return <Typography.Text type="secondary">-</Typography.Text>
            }

            return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
          },
        },
      ],
    },
  ])

  return (
    <Table<PaymentsOverviewTableRecord>
      columnDefs={columnDefs}
      grandTotalRow="bottom"
      rowData={overview}
      suppressContextMenu
      showExport
      showFilterReset
      showUniversalSearch
    />
  )
}
