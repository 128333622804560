import { Form, FormInstance, Input, InputNumber, Radio, RadioChangeEvent, Space } from 'antd'
import { useEffect, useState } from 'react'
import {
  AmazonPpcCampaignType,
  TargetingExpressionPredicateType,
} from '../../../../../../generated/graphql'
import { useDefaultMaximumBid } from '../../../../hooks/useDefaultMaximumBid'
import { useDefaultMinimumBid } from '../../../../hooks/useDefaultMinimumBid'
import EditSponsoredProductsAutomaticTargetRow from './EditSponsoredProductsAutomaticTargetRow'
import { AutomaticTargetingBiddingType, ISponsoredProductsAutoTarget } from './interfaces'

type CreateSponsoredProductsCampaignAutomaticTargetsFormFieldsProps = {
  form: FormInstance
  currencySymbol: string
}

const generateAutoTargets = (defaultBid: number) => {
  return [
    {
      bid: defaultBid,
      expressionTypePredicate: TargetingExpressionPredicateType.QUERYHIGHRELMATCHES,
      active: true,
    },
    {
      bid: defaultBid,
      expressionTypePredicate: TargetingExpressionPredicateType.QUERYBROADRELMATCHES,
      active: true,
    },
    {
      bid: defaultBid,
      expressionTypePredicate: TargetingExpressionPredicateType.ASINSUBSTITUTERELATED,
      active: true,
    },
    {
      bid: defaultBid,
      expressionTypePredicate: TargetingExpressionPredicateType.ASINACCESSORYRELATED,
      active: true,
    },
  ]
}

const CreateSponsoredProductsCampaignAutomaticTargetsFormFields = ({
  form,
  currencySymbol,
}: CreateSponsoredProductsCampaignAutomaticTargetsFormFieldsProps) => {
  const defaultMinimumBid = useDefaultMinimumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const defaultMaximumBid = useDefaultMaximumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const [defaultBid, setDefaultBid] = useState<number>(0.4)
  const [automaticTargets, setAutomaticTargets] = useState<ISponsoredProductsAutoTarget[]>([])
  const [automaticTargetingBiddingType, setAutomaticTargetingBiddingType] =
    useState<AutomaticTargetingBiddingType>(AutomaticTargetingBiddingType.DEFAULT)

  useEffect(() => {
    form.setFieldsValue({
      automaticTargets: automaticTargets,
    })
  }, [automaticTargets, form])

  useEffect(() => {
    if (automaticTargetingBiddingType === AutomaticTargetingBiddingType.DEFAULT) {
      setAutomaticTargets([])
    } else {
      setAutomaticTargets(generateAutoTargets(defaultBid))
    }
  }, [automaticTargetingBiddingType, defaultBid])

  const handleChangeTargetingType = (e: RadioChangeEvent) => {
    setAutomaticTargetingBiddingType(e.target.value)
  }

  const handleDefaultBidChanged = (value: number | null) => {
    if (value) {
      setDefaultBid(value)
    }
  }

  const handleTargetChanged = (changedTarget: ISponsoredProductsAutoTarget) => {
    const automaticTargetsCopy = [...automaticTargets]
    automaticTargetsCopy.forEach((automaticTarget: ISponsoredProductsAutoTarget) => {
      if (automaticTarget.expressionTypePredicate === changedTarget.expressionTypePredicate) {
        automaticTarget.bid = changedTarget.bid
        automaticTarget.active = changedTarget.active
      }
    })
    setAutomaticTargets(automaticTargetsCopy)
  }

  return (
    <>
      <Form.Item name={'automaticTargets'} hidden>
        <Input></Input>
      </Form.Item>
      <Form.Item
        name={'automaticTargetingBiddingType'}
        label="Bidding type"
        initialValue={AutomaticTargetingBiddingType.DEFAULT}
        rules={[
          { required: true, enum: Object.values(AutomaticTargetingBiddingType), type: 'enum' },
        ]}
      >
        <Radio.Group
          onChange={handleChangeTargetingType}
          style={{ marginTop: '6px' }}
          value="biddingType"
        >
          <Space direction="vertical">
            <Radio value={AutomaticTargetingBiddingType.DEFAULT}>Set default bid</Radio>
            <Radio
              value={AutomaticTargetingBiddingType.INDIVIDUAL}
              data-testid="individual-auto-bids-radio"
            >
              Set bids by targeting group
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {automaticTargetingBiddingType === AutomaticTargetingBiddingType.DEFAULT ? (
        <Form.Item
          name={'automaticTargetingDefaultBid'}
          label={`Default bid (${currencySymbol})`}
          initialValue={defaultBid}
          rules={[{ required: true }]}
        >
          <InputNumber
            min={defaultMinimumBid}
            max={defaultMaximumBid}
            step={0.1}
            onChange={handleDefaultBidChanged}
          />
        </Form.Item>
      ) : (
        automaticTargets.map((automaticTarget) => (
          <EditSponsoredProductsAutomaticTargetRow
            targetIn={automaticTarget}
            handleTargetChanged={handleTargetChanged}
            defaultMinimumBid={defaultMinimumBid}
            defaultMaximumBid={defaultMaximumBid}
            key={automaticTarget.expressionTypePredicate}
          />
        ))
      )}
    </>
  )
}

export default CreateSponsoredProductsCampaignAutomaticTargetsFormFields
