import { useMutation } from '@apollo/client'
import { Button, Col, Form, Modal, Popconfirm, Row, Space } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'sonner'
import {
  AmazonPpcCampaignType,
  Marketplace,
  UpdateSponsoredProductsTargetInput,
  UpdateSponsoredProductsTargetResponse,
  UpdateSponsoredProductsTargetsDocument,
  UpdateSponsoredProductsTargetsMutation,
} from '../../../../../../generated/graphql'
import { roundNumber } from '../../../../../../helpers/roundNumber'
import { useGlobalStore } from '../../../../../../stores/useGlobalStore'
import {
  AggregatedTarget,
  FormStrategy,
  PPCBulkEditTargetBid,
} from '../../../../../../views/ppc/helpers/interfaces'
import { useDefaultMaximumBid } from '../../../../hooks/useDefaultMaximumBid'
import { useDefaultMinimumBid } from '../../../../hooks/useDefaultMinimumBid'
import BulkEditTargetBidForm from './BulkEditTargetBidForm'
import BulkEditTargetBidTable from './BulkEditTargetBidTable'

type BulkEditSponsoredProductsTargetsBidModalProps = {
  marketplace: Marketplace
  campaignId: string
  targetsIn: AggregatedTarget[]
  updateTargetList: Function
}

const BulkEditSponsoredProductsTargetsBidModal = ({
  marketplace,
  campaignId,
  targetsIn,
  updateTargetList,
}: BulkEditSponsoredProductsTargetsBidModalProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [formStrategy, setFormStrategy] = useState<FormStrategy>(FormStrategy.VALUE)
  const [targetsOut, setTargetsOut] = useState(targetsIn)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const defaultMinimumBid = useDefaultMinimumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const defaultMaximumBid = useDefaultMaximumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const [updateSponsoredProductsTargets, { loading: loadingUpdate }] =
    useMutation<UpdateSponsoredProductsTargetsMutation>(UpdateSponsoredProductsTargetsDocument)

  const calculateBidFromStrategy = useCallback(
    (currentBid: number) => {
      const strategy = form.getFieldValue('strategy')

      switch (strategy) {
        case FormStrategy.VALUE:
          const value = form.getFieldValue('bid')
          return value
        case FormStrategy.INCR_PERCENTAGE:
          const incrPercentage = form.getFieldValue('incrPercentage')
          return roundNumber(currentBid * ((100 + incrPercentage) / 100))
        case FormStrategy.DECR_PERCENTAGE:
          const decrPercentage = form.getFieldValue('decrPercentage')
          return roundNumber(currentBid * ((100 - decrPercentage) / 100))
        case FormStrategy.INCR_AMOUNT:
          const incrAmount = form.getFieldValue('incrAmount')
          return roundNumber(currentBid + incrAmount)
        case FormStrategy.DECR_AMOUNT:
          const decrAmount = form.getFieldValue('decrAmount')
          return roundNumber(currentBid - decrAmount)
        default:
          return currentBid
      }
    },
    [form]
  )

  const normaliseBid = (bid: number): number => {
    return bid < defaultMinimumBid
      ? defaultMinimumBid
      : bid > defaultMaximumBid
        ? defaultMaximumBid
        : bid
  }

  const updateSponsoredProductsTargetsOut = useCallback(() => {
    if (!loadingUpdate) {
      const targets: PPCBulkEditTargetBid[] = targetsIn.map((target: AggregatedTarget) => {
        let bid = calculateBidFromStrategy(target.bid)
        bid = normaliseBid(bid)
        return {
          ...target,
          newBid: bid,
        }
      })

      setTargetsOut(targets)
    }
  }, [targetsIn, calculateBidFromStrategy, loadingUpdate])

  useEffect(() => {
    if (modalVisible && !loadingUpdate) {
      const initialBid =
        targetsIn[0]?.bid > defaultMinimumBid ? targetsIn[0].bid : defaultMinimumBid

      setFormStrategy(FormStrategy.VALUE)

      form.setFieldsValue({
        strategy: 'value',
        bid: initialBid,
        incrPercentage: 10,
        decrPercentage: 10,
        incrAmount: 0.05,
        decrAmount: 0.05,
      })

      updateSponsoredProductsTargetsOut()
    }
  }, [modalVisible, targetsIn, form, updateSponsoredProductsTargetsOut, loadingUpdate])

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleOnFinish = async () => {
    const targets: UpdateSponsoredProductsTargetInput[] = targetsIn.map((target) => {
      let bid = calculateBidFromStrategy(target.bid)
      bid = normaliseBid(bid)

      return {
        campaignId: campaignId,
        adGroupId: target.adGroupId,
        targetId: target.targetId,
        state: target.state,
        bid: bid,
      }
    })

    updateSponsoredProductsTargets({
      variables: { companyUuid: selectedCompany?.uuid || '', marketplace, targets },
    })
      .then(({ data }) => {
        const updatedTargets = data?.updateSponsoredProductsTargets
        if (!updatedTargets) {
          throw new Error('No targets were updated')
        }
        updatedTargets.forEach((target: UpdateSponsoredProductsTargetResponse) => {
          updateTargetList(target)
        })
        toast.success('Target(s) updated.')
        setModalVisible(false)
      })
      .catch((e) => {
        console.error(e)
        if (e.networkError?.statusCode === 429) {
          toast.error("Amazon's servers are currently busy, please try again in a few minutes.")
        } else {
          toast.error('Error updating target(s).')
        }
      })
  }

  const handleFormStrategyChanged = (value: FormStrategy) => {
    setFormStrategy(value)
  }

  const handleFormChanged = () => {
    updateSponsoredProductsTargetsOut()
  }

  return (
    <>
      <Button key="huihif" onClick={showModal} disabled={!targetsIn?.length}>
        Change bid
      </Button>

      <div
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}
        key="u90u90u"
      >
        <Modal
          title={`Change bid of (${targetsIn.length}) selected targets`}
          open={modalVisible}
          onCancel={handleCancel}
          width={960}
          footer={
            <Space>
              <Button onClick={handleCancel} key="cancel">
                Cancel
              </Button>
              <Popconfirm
                key="u8f8f"
                placement="top"
                title={'Please confirm changes to all selected targets'}
                onConfirm={form.submit}
                okText="Confirm"
                cancelText="Go back"
              >
                <Button key="savel" type={'primary'} loading={loadingUpdate}>
                  Save
                </Button>
              </Popconfirm>
            </Space>
          }
        >
          <Row gutter={36} align="middle">
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <BulkEditTargetBidForm
                form={form}
                handleOnFinish={handleOnFinish}
                handleFormChanged={handleFormChanged}
                handleFormStrategyChanged={handleFormStrategyChanged}
                formStrategy={formStrategy}
                minimumBid={defaultMinimumBid}
                maximumBid={defaultMaximumBid}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <BulkEditTargetBidTable targets={targetsOut} loading={loadingUpdate} />
            </Col>
          </Row>
        </Modal>
      </div>
    </>
  )
}

export default BulkEditSponsoredProductsTargetsBidModal
