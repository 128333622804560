import { useMutation } from '@apollo/client'
import { Button, Form, InputNumber, Modal, Popconfirm, Select, Space } from 'antd'
import { Pen } from 'lucide-react'
import React, { useState } from 'react'
import { toast } from 'sonner'
import {
  AmazonPpcCampaignType,
  AmazonPpcState,
  UpdateSponsoredProductsCampaignDocument,
} from '../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { useCurrencySymbol } from '../../../../../views/ppc/helpers/useCurrencySymbol'
import { useDefaultMaximumBid } from '../../../hooks/useDefaultMaximumBid'
import { useDefaultMinimumBid } from '../../../hooks/useDefaultMinimumBid'

type UpdateSponsoredProductsCampaignModalProps = {
  campaignId: string
  dailyBudgetIn: number
  stateIn: string
  updateCampaignList: Function
}

const UpdateSponsoredProductsCampaignModal = ({
  campaignId,
  dailyBudgetIn,
  stateIn,
  updateCampaignList,
}: UpdateSponsoredProductsCampaignModalProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const currencySymbol = useCurrencySymbol()
  const defaultMinimumBid = useDefaultMinimumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const defaultMaximumBid = useDefaultMaximumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)

  const [updateSponsoredProductsCampaign, { loading: updateSponsoredProductsCampaignLoading }] =
    useMutation(UpdateSponsoredProductsCampaignDocument)

  const campaignStateOptionMarkup = [
    <Select.Option key={AmazonPpcState.ENABLED} value={AmazonPpcState.ENABLED}>
      {AmazonPpcState.ENABLED}
    </Select.Option>,
    <Select.Option key={AmazonPpcState.PAUSED} value={AmazonPpcState.PAUSED}>
      {AmazonPpcState.PAUSED}
    </Select.Option>,
  ]

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleOnFinish = async (values: any) => {
    values.campaignId = campaignId
    if (!selectedCompany?.uuid) {
      return
    }

    updateSponsoredProductsCampaign({
      variables: { companyUuid: selectedCompany?.uuid, campaign: values },
    })
      .then(({ data }) => {
        const updatedSponsoredProductsCampaign = data?.updateSponsoredProductsCampaign
        if (!updatedSponsoredProductsCampaign) {
          throw new Error('No campaign was updated')
        }
        toast.success('Campaign updated.')
        updateCampaignList(updatedSponsoredProductsCampaign)
        setModalVisible(false)
      })
      .catch((e) => {
        console.error(e)
        if (e.networkError?.statusCode === 429) {
          toast.error("Amazon's servers are currently busy, please try again in a few minutes.")
        } else {
          toast.error('Error updating campaign.')
        }
      })
  }

  const handleFormValuesChange = () => {
    const formDailyBudget = form.getFieldValue('dailyBudget')
    const formState = form.getFieldValue('state')

    setSaveButtonDisabled(formState == stateIn && formDailyBudget == dailyBudgetIn)
  }

  return (
    <>
      <Button
        type="text"
        icon={<Pen size={16} />}
        onClick={showModal}
        data-testid="edit-campaign-btn"
      />
      <div
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}
        key="u90u90u"
      >
        <Modal
          title="Sponsored Products Campaign Settings"
          open={modalVisible}
          onCancel={handleCancel}
          footer={
            <Space>
              <Button onClick={handleCancel} key="cancel">
                Cancel
              </Button>
              <Popconfirm
                key="u8f8f"
                placement="top"
                title={'Please confirm these changes'}
                onConfirm={form.submit}
                okText="Confirm"
                cancelText="Go back"
                disabled={saveButtonDisabled}
              >
                <Button
                  key="savel"
                  disabled={saveButtonDisabled}
                  type={'primary'}
                  loading={updateSponsoredProductsCampaignLoading}
                >
                  Save
                </Button>
              </Popconfirm>
            </Space>
          }
        >
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            initialValues={{ dailyBudget: dailyBudgetIn, state: stateIn }}
            onFinish={handleOnFinish}
            onValuesChange={handleFormValuesChange}
          >
            <Form.Item label="Status" name="state">
              <Select>{campaignStateOptionMarkup}</Select>
            </Form.Item>
            <Form.Item label={`Daily Budget (${currencySymbol})`} name="dailyBudget">
              <InputNumber min={defaultMinimumBid} max={defaultMaximumBid} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default UpdateSponsoredProductsCampaignModal
