import { nanoid } from 'nanoid'
import {
  ProductAttribute,
  ProductVariantFormInstance,
  ProductVariantOptionFormValues,
} from '../components/ProductCreateForm'

export function generateProductVariants(
  existingVariants: ProductVariantFormInstance[] = [],
  options: ProductVariantOptionFormValues[]
) {
  const optionValueMap = options.reduce<Map<string, string[]>>((accumulator, option) => {
    if (!option.option) {
      return accumulator
    }

    const cleanedValues = option.values.map((value) => value?.trim()).filter(Boolean)
    const uniqueValues = Array.from(new Set(cleanedValues))

    const attributeValues = accumulator.get(option.option) ?? []
    accumulator.set(option.option, [...attributeValues, ...uniqueValues])
    return accumulator
  }, new Map())

  const combinations = generateAttributeCombinations(Array.from(optionValueMap.entries()))

  if (combinations.flat().length === 0) {
    return []
  }

  return combinations.map((attributes) => {
    const existingVariant = existingVariants.find((variant) => {
      return areAttributesEqual(variant.attributes ?? [], attributes)
    })

    if (existingVariant) {
      return existingVariant
    } else {
      return {
        id: nanoid(),
        attributes,
        shouldCreate: true,
      }
    }
  })
}

function generateAttributeCombinations(attributePairs: [string, string[]][]) {
  return attributePairs.reduce<ProductAttribute[][]>(
    (accumulator, [option, values]) => {
      if (values.length === 0) {
        return accumulator
      }

      return accumulator.flatMap((attributes) => {
        return values.map((value) => {
          return [...attributes, { option, value }]
        })
      })
    },
    [[]]
  )
}

function areAttributesEqual(
  existingAttributes: ProductAttribute[],
  newAttributes: ProductAttribute[]
) {
  if (existingAttributes.length !== newAttributes.length) {
    return false
  }

  for (let i = 0; i < newAttributes.length; i++) {
    if (
      existingAttributes[i]?.option !== newAttributes[i]?.option ||
      existingAttributes[i]?.value !== newAttributes[i]?.value
    ) {
      return false
    }
  }

  return true
}
