import * as Sentry from '@sentry/react'
import { AllEnterpriseModule, LicenseManager, ModuleRegistry } from 'ag-grid-enterprise'
import mixpanel from 'mixpanel-browser'
import { createRoot } from 'react-dom/client'
import App from './App'
import './config/env'

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  api_host: 'https://api-eu.mixpanel.com',
  track_pageview: 'url-with-path',
})

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENCE_KEY)
ModuleRegistry.registerModules([AllEnterpriseModule])

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.feedbackIntegration({
      showName: false,
      showEmail: false,
      useSentryUser: {
        email: 'email',
        name: 'name',
      },
      // Text customization
      triggerLabel: 'Feedback',
      triggerAriaLabel: 'Open feedback form',
      formTitle: 'Share your thoughts',
      messageLabel: 'Your feedback',
      messagePlaceholder: 'Tell us what you think or suggest improvements...',
      submitButtonLabel: 'Submit feedback',
      successMessage: 'Thank you for your feedback!',
    }),
  ],
})

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
