import { AmazonOutlined } from '@ant-design/icons'
import { Col, Flex, List, Row, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { Barcode } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flag } from '../../../../components/Flag'
import {
  AmazonFulfillmentChannel,
  AmazonOrderStatus,
  Marketplace,
} from '../../../../generated/graphql'
import { convertToEuro } from '../../../../helpers/convertToEuro'
import { formatMoney } from '../../../../helpers/formatMoney'
import { useDashboardConfigurationStore } from '../../../../stores/useDashboardConfigurationStore'
import { AmazonMarketplaceDomain } from '../../../../utils'
import { StyledList, StyledSkeleton, StyledSubCard } from '../../styles'
import { DashboardOrderStatusTag } from './DashboardOrderStatusTag'

const ORDERS_LOAD_INCREMENT = 10

export type DashboardOrdersListItem = {
  internalSku: string | null
  recommendedRetailPrice: number
  marketplace: Marketplace
  asin: string
  sku: string
  quantity: number
  price: {
    amount: number
    currency: string
  }
  status: AmazonOrderStatus
  fulfillmentChannel: AmazonFulfillmentChannel
  purchaseDate: string
}

type DashboardOrdersListProps = {
  orders: DashboardOrdersListItem[]
  loading: boolean
  statusFilter: string | number
}

export const DashboardOrdersList = ({
  orders,
  loading,
  statusFilter,
}: DashboardOrdersListProps) => {
  const [items, setItems] = useState<DashboardOrdersListItem[]>([])

  const productIdentifierType = useDashboardConfigurationStore(
    (state) => state.dashboardConfiguration.productIdentifier
  )

  const { t } = useTranslation(['dashboard', 'translation'])

  const listTopRef = useRef<HTMLDivElement>(null)
  const observerTarget = useRef<HTMLDivElement>(null)

  const addItems = () => {
    setItems((previousItems) => [
      ...previousItems,
      ...orders.slice(previousItems.length, previousItems.length + ORDERS_LOAD_INCREMENT),
    ])
  }

  useEffect(() => {
    setItems(orders.slice(0, ORDERS_LOAD_INCREMENT))
    if (listTopRef.current?.scrollIntoView) {
      listTopRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [statusFilter, orders])

  useEffect(() => {
    if (orders.length > 0 && items.length === 0) {
      addItems()
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          addItems()
        }
      },
      { threshold: 0.1 }
    )

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current)
      }
    }
  }, [orders, observerTarget])

  return (
    <StyledSkeleton loading={loading} paragraph={{ rows: 10, width: '100%' }} title={false}>
      <div ref={listTopRef} />
      <StyledList<DashboardOrdersListItem>
        style={{ overflow: 'auto' }}
        dataSource={items}
        renderItem={(item) => (
          <List.Item>
            <StyledSubCard>
              <Row gutter={[8, 8]}>
                <Col xs={{ span: 8, order: 1 }} md={{ span: 4, order: 1 }}>
                  <Col xs={24}>
                    <Typography.Text strong ellipsis>
                      {dayjs(item.purchaseDate).fromNow()}
                    </Typography.Text>
                  </Col>
                  <Col xs={24}>
                    <Typography.Text ellipsis>
                      {dayjs(item.purchaseDate).format('DD.MM.YY')}
                    </Typography.Text>
                  </Col>
                  <Col xs={24}>
                    <Typography.Text ellipsis>
                      {dayjs(item.purchaseDate).format('HH:mm')}
                    </Typography.Text>
                  </Col>
                </Col>
                <Col xs={{ span: 16, order: 2 }} md={{ span: 8, order: 2 }}>
                  <Col xs={24}>
                    <Space>
                      <Flag code={item.marketplace} />
                      <Typography.Text strong ellipsis>
                        {AmazonMarketplaceDomain[item.marketplace]}
                      </Typography.Text>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Space>
                      <AmazonOutlined />
                      <Typography.Text ellipsis>{item.asin}</Typography.Text>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Flex align="center" gap={8}>
                      <Barcode size={16} />
                      <Typography.Text ellipsis>
                        {productIdentifierType === 'internalSku'
                          ? (item.internalSku ?? item.sku)
                          : item.sku}
                      </Typography.Text>
                    </Flex>
                  </Col>
                </Col>
                <Col xs={{ span: 16, order: 4 }} md={{ span: 8, order: 3 }}>
                  <Col xs={24}>
                    <Typography.Text ellipsis>
                      {t('performance.latestOrders.quantity')}: {item.quantity}
                    </Typography.Text>
                  </Col>
                  <Col xs={24}>
                    {item.status === AmazonOrderStatus.SHIPPED ? (
                      <Typography.Text ellipsis>
                        {t('performance.latestOrders.total')}:{' '}
                        {formatMoney(item.price.amount ?? 0, item.price?.currency ?? 'EUR')}
                      </Typography.Text>
                    ) : [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(
                        item.status
                      ) ? (
                      <Typography.Text ellipsis style={{ opacity: 0.4 }}>
                        {t('performance.latestOrders.total')}:{' '}
                        {formatMoney(
                          convertToEuro(item.recommendedRetailPrice, item.marketplace),
                          'EUR'
                        )}
                      </Typography.Text>
                    ) : null}
                  </Col>
                  <Col xs={24}>
                    <Typography.Text ellipsis>{item.fulfillmentChannel}</Typography.Text>
                  </Col>
                </Col>
                <Col xs={{ span: 8, order: 3 }} md={{ span: 4, order: 4 }}>
                  <DashboardOrderStatusTag status={item.status} />
                </Col>
              </Row>
            </StyledSubCard>
          </List.Item>
        )}
        split={false}
        locale={{ emptyText: t('shared.noData', { ns: 'translation' }) }}
      />
      <div ref={observerTarget} />
    </StyledSkeleton>
  )
}
