import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { GetOrderReportQuery } from '../../../generated/graphql'
import { AmazonOrderReport } from '../interfaces'

export function prepareAmazonOrderDataReport(
  data: GetOrderReportQuery | undefined,
  t: TFunction<'report', 'translation'>
): AmazonOrderReport[] {
  if (!data) {
    return []
  }

  const amazonOrders = data.products.flatMap((product) => {
    const amazonProducts =
      product.productVariants?.flatMap((productVariant) => {
        return productVariant.amazonProducts ?? []
      }) ?? []

    return amazonProducts?.filter(Boolean).flatMap((amazonProduct) => {
      if (!amazonProduct.amazonListings) {
        return []
      }

      return amazonProduct.amazonListings?.filter(Boolean).flatMap((amazonListing) => {
        return amazonListing.orderLineItems.map((item) => {
          const order = item.amazonOrder
          return {
            amazonOrderId: order.amazonOrderId,
            name: product.name,
            asin: amazonProduct.asin,
            sku: amazonListing.sku,
            marketplace: amazonListing.marketplace.name,
            fulfillmentChannel: order.fulfillmentChannel,
            quantity: item.quantity,
            price:
              +(item.itemPrice.amount ?? 0) > 0
                ? `${item.itemPrice.amount} ${item.itemPrice.currency}`
                : '-',
            status: t(`orders.statuses.${order.status}`),
            purchaseDate: dayjs(order.purchaseDate).format('DD.MM.YYYY HH:mm'),
            lastUpdateDate: dayjs(order.lastUpdatedDate).format('DD.MM.YYYY HH:mm'),
          }
        })
      })
    })
  })

  return amazonOrders.sort((a, b) => dayjs(b.purchaseDate).diff(dayjs(a.purchaseDate)))
}
