import { useTranslation } from 'react-i18next'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { createZendeskTicketOptions } from '../../../ticket/helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../../../ticket/ticket.enum'

export function useTicketInput({ asins, skus }: { asins: string[]; skus: string[] }) {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t, i18n } = useTranslation('inventory')

  const comment = `
    <p>${t('product.details.delete.ticket.paragraph1')}</p>
    <p>${t('product.details.delete.ticket.paragraph2')}</p>
    <ul>
      ${asins.map((asin) => `<li>${asin}</li>`).join('')}
    </ul>
    <p>${t('product.details.delete.ticket.paragraph3')}</p>
    <ul>
      ${skus.map((sku) => `<li>${sku}</li>`).join('')}
    </ul>
  `

  return createZendeskTicketOptions({
    subject: t('product.details.delete.ticket.subject'),
    comment,
    categoryTitle: 'amazon',
    mainCategory: TicketMainCategory.INVENTORY,
    ticketCategory: TicketCategory.INVENTORY_DELETE_PRODUCT,
    sellerId: selectedCompany?.sellerId,
    language: i18n.language,
  })
}
