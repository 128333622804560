import { Descriptions } from 'antd'
import * as i18nIsoCountries from 'i18n-iso-countries'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../config/i18n'
import { StyledMainCard } from '../../../dashboard/styles'
import { descriptionsStyle } from '../../../products/components/styles'
import { AmazonInboundShipmentPlan } from '../AmazonInboundShipmentPlanView'

type AmazonInboundShipmentPlanDeliveryDetailsProps = {
  amazonInboundShipmentPlan: AmazonInboundShipmentPlan
}

export const AmazonInboundShipmentPlanDeliveryDetails = ({
  amazonInboundShipmentPlan,
}: AmazonInboundShipmentPlanDeliveryDetailsProps) => {
  const { t } = useTranslation('inventory')

  return (
    <StyledMainCard
      title={t('amazonInboundShipmentPlan.table.deliveryDetails')}
      style={{ height: 'auto' }}
    >
      {/* <Flex vertical gap={2}>
        <Typography.Text strong>{amazonInboundShipmentPlan.shipFromAddress.name}</Typography.Text>
        <Typography.Text>{amazonInboundShipmentPlan.shipFromAddress.addressLine1}</Typography.Text>
        <Typography.Text>{amazonInboundShipmentPlan.shipFromAddress.addressLine2}</Typography.Text>
        <Typography.Text>
          {amazonInboundShipmentPlan.shipFromAddress.postalCode} {amazonInboundShipmentPlan.shipFromAddress.city}
        </Typography.Text>
        <Typography.Text>{amazonInboundShipmentPlan.shipFromAddress.provinceCode}</Typography.Text>
        <Typography.Text>
          {i18nIsoCountries.getName(amazonInboundShipmentPlan.shipFromAddress.countryCode, i18n.language)}
        </Typography.Text>
      </Flex> */}
      <Descriptions {...descriptionsStyle}>
        <Descriptions.Item label={t('amazonInboundShipmentPlan.table.dispatcherName')}>
          {amazonInboundShipmentPlan.shipFromAddress.name}
        </Descriptions.Item>
        <Descriptions.Item label={t('amazonInboundShipmentPlan.table.shippingAddressLine1')}>
          {amazonInboundShipmentPlan.shipFromAddress.addressLine1}
        </Descriptions.Item>
        <Descriptions.Item label={t('amazonInboundShipmentPlan.table.shippingAddressLine2')}>
          {amazonInboundShipmentPlan.shipFromAddress.addressLine2 ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('amazonInboundShipmentPlan.table.shippingCity')}>
          {amazonInboundShipmentPlan.shipFromAddress.city}
        </Descriptions.Item>
        <Descriptions.Item label={t('amazonInboundShipmentPlan.table.shippingZipCode')}>
          {amazonInboundShipmentPlan.shipFromAddress.postalCode}
        </Descriptions.Item>
        <Descriptions.Item label={t('amazonInboundShipmentPlan.table.shippingState')}>
          {amazonInboundShipmentPlan.shipFromAddress.provinceCode ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('amazonInboundShipmentPlan.table.shipFromCountry')}>
          {i18nIsoCountries.getName(
            amazonInboundShipmentPlan.shipFromAddress.countryCode,
            i18n.language
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t('amazonInboundShipmentPlan.table.phone')}>
          {amazonInboundShipmentPlan.shipFromAddress.phone ?? '-'}
        </Descriptions.Item>
      </Descriptions>
    </StyledMainCard>
  )
}
