import {
  AmazonOrderStatus,
  CurrencyCode,
  GetDashboardPerformanceExportQuery,
  Marketplace,
} from '../../../../../../generated/graphql'
import { formatMoney } from '../../../../../../helpers/formatMoney'
import { PerformanceDetails } from '../../../helpers/preparePerformanceOverview'

type DashboardPerformanceExportData = {
  Name: string
  ASIN: string
  SKU: string
  Marketplace: Marketplace
  'Total Shipped': number
  'Total Pending': number
  'Total Canceled': number
  'Total Revenue': string
  'Pending Revenue': string
  'Total Fulfillable': number
  'Total Inbound': number
  'Total Reserved': number
  'Total Unfulfillable': number
}

export function prepareDashboardPerformanceExportData(
  data: GetDashboardPerformanceExportQuery
): DashboardPerformanceExportData[] {
  return data.products.flatMap((product) => {
    const amazonProducts =
      product.productVariants?.flatMap((productVariant) => {
        return productVariant.amazonProducts ?? []
      }) ?? []

    return amazonProducts.flatMap((amazonProduct) => {
      if (!amazonProduct.amazonListings) {
        return []
      }

      return amazonProduct.amazonListings.flatMap((amazonListing) => {
        const { totalShipped, totalPending, totalCanceled, totalRevenue, pendingRevenue } =
          amazonListing.orderLineItems.reduce<PerformanceDetails>(
            (previousValue, currentValue) => {
              const totalAmount = +(currentValue.itemPrice.amount ?? 0)
              const price = {
                amount: totalAmount,
                currency: CurrencyCode.EUR,
              }

              if (currentValue.amazonOrder.status === AmazonOrderStatus.SHIPPED) {
                previousValue.totalShipped++
                previousValue.totalRevenue += price.amount
              } else if (
                [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(
                  currentValue.amazonOrder.status
                )
              ) {
                previousValue.totalPending++
                previousValue.pendingRevenue += amazonListing.recommendedRetailPrice ?? 0
              } else if (currentValue.amazonOrder.status === AmazonOrderStatus.CANCELED) {
                previousValue.totalCanceled++
              }

              return previousValue
            },
            {
              totalShipped: 0,
              totalPending: 0,
              totalCanceled: 0,
              totalRevenue: 0,
              pendingRevenue: 0,
            }
          )

        return {
          Name: product.name,
          ASIN: amazonProduct.asin,
          SKU: amazonListing.sku,
          Marketplace: amazonListing.marketplace.name,
          'Total Shipped': totalShipped,
          'Total Pending': totalPending,
          'Total Canceled': totalCanceled,
          'Total Revenue': formatMoney(totalRevenue, 'EUR'),
          'Pending Revenue': formatMoney(pendingRevenue, 'EUR'),
          'Total Fulfillable': amazonListing.inventory?.fulfillable?.fulfillableQuantity ?? 0,
          'Total Inbound':
            (amazonListing.inventory?.inbound?.inboundWorkingQuantity ?? 0) +
            (amazonListing.inventory?.inbound?.inboundShippedQuantity ?? 0) +
            (amazonListing.inventory?.inbound?.inboundReceivingQuantity ?? 0),
          'Total Reserved':
            (amazonListing.inventory?.reserved?.pendingCustomerOrderQuantity ?? 0) +
            (amazonListing.inventory?.reserved?.pendingTransshipmentQuantity ?? 0) +
            (amazonListing.inventory?.reserved?.fcProcessingQuantity ?? 0),
          'Total Unfulfillable':
            (amazonListing.inventory?.unfulfillable?.customerDamagedQuantity ?? 0) +
            (amazonListing.inventory?.unfulfillable?.warehouseDamagedQuantity ?? 0) +
            (amazonListing.inventory?.unfulfillable?.distributorDamagedQuantity ?? 0) +
            (amazonListing.inventory?.unfulfillable?.carrierDamagedQuantity ?? 0) +
            (amazonListing.inventory?.unfulfillable?.defectiveQuantity ?? 0) +
            (amazonListing.inventory?.unfulfillable?.expiredQuantity ?? 0) +
            (amazonListing.inventory?.researching?.researchingQuantity ?? 0),
        }
      })
    })
  })
}
