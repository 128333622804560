import { FormInstance } from 'antd'
import { useEffect } from 'react'

export function usePreventNavigation<T>(form: FormInstance<T>) {
  useEffect(() => {
    const handleNavigation = (event: BeforeUnloadEvent) => {
      if (form.isFieldsTouched()) {
        event.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleNavigation)

    return () => {
      window.removeEventListener('beforeunload', handleNavigation)
    }
  }, [form])
}
