import { Tree } from 'antd'
import Search from 'antd/es/input/Search'
import Fuse from 'fuse.js'
import { ChevronDown } from 'lucide-react'
import { useState } from 'react'
import {
  AmazonPpcCampaignType,
  AmazonPpcCategory,
  TargetingExpressionPredicateType,
} from '../../../../../../generated/graphql'
import { useDefaultMinimumBid } from '../../../../hooks/useDefaultMinimumBid'
import { ScrollableList, SearchBarContainer } from '../../../../styles'
import AddCategoryList, { DetailedAmazonCategory } from './AddCategoryList'
import { UserDefinedTarget } from './CreateSponsoredProductsManualTargetsFormFields'
import { TreeItem } from './SelectSponsoredProductsCategories'

type SearchCategoryTreeProps = {
  treeData: TreeItem[]
  allCategories: DetailedAmazonCategory[]
  selectedCategoryIds: string[]
  handleAddTarget: Function
}

const SearchCategoryTree = ({
  treeData,
  allCategories,
  handleAddTarget,
  selectedCategoryIds,
}: SearchCategoryTreeProps) => {
  const defaultMinimumBid = useDefaultMinimumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const [searchFilterActive, setSearchFilterActive] = useState<boolean>(false)
  const [filteredCategories, setFilteredCategories] = useState<DetailedAmazonCategory[]>([])

  const onTreeItemSelect = (selectedKeys: any, info: any) => {
    const selectedCategory = {
      id: selectedKeys[0],
      na: info.node.title,
      path: info.node.path,
    }

    const target: UserDefinedTarget = {
      id: selectedCategory.id?.toString(),
      expressionType: TargetingExpressionPredicateType.ASINCATEGORYSAMEAS,
      expressionValue: selectedCategory.id?.toString(),
      resolvedExpressionValue: selectedCategory.na,
      description: selectedCategory.path,
      name: selectedCategory.na,
      bid: defaultMinimumBid,
    }

    handleAddTarget(target)
  }

  const searcher = new Fuse(allCategories, {
    isCaseSensitive: false,
    shouldSort: true,
    keys: ['name'],
  })

  const handleOnSearch = (value: string) => {
    const searchTerm = value.toLowerCase().trim().toLowerCase()
    const searchResult = searcher.search(searchTerm)
    const filteredCategories: AmazonPpcCategory[] = searchResult.map((item) => item.item)
    // limit the reults to 50 to avoid longer browser rendering times
    filteredCategories.length = 50

    setFilteredCategories(filteredCategories)
  }

  const handleSearchOnChange = (e: any) => {
    handleOnSearch(e.target.value)
    setSearchFilterActive(e.target?.value?.trim()?.length > 0)
  }

  const handleSearchOnKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleOnSearch(e.target.value)
    }
  }

  return (
    <>
      <SearchBarContainer>
        <Search
          placeholder="Search by category"
          onSearch={handleOnSearch}
          onKeyDown={handleSearchOnKeyDown}
          allowClear
          onChange={handleSearchOnChange}
          data-testid="category-search-input"
        />
      </SearchBarContainer>
      {searchFilterActive ? (
        <AddCategoryList
          categories={filteredCategories}
          selectedCategoryIds={selectedCategoryIds}
          handleAddTarget={handleAddTarget}
        />
      ) : (
        <ScrollableList>
          <Tree
            treeData={treeData}
            onSelect={onTreeItemSelect}
            showLine
            switcherIcon={<ChevronDown size={16} />}
            style={{ marginTop: '1rem' }}
          />
        </ScrollableList>
      )}
    </>
  )
}

export default SearchCategoryTree
