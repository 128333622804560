import { TagProps } from 'antd'
import { SupportTicketStatus } from '../../../generated/graphql'

export const getTicketStatusTagColor = (status: string): TagProps['color'] => {
  switch (status) {
    case SupportTicketStatus.OPEN:
      return 'red'
    case SupportTicketStatus.NEW:
      return 'orange'
    case SupportTicketStatus.PENDING:
      return 'blue'
    case SupportTicketStatus.HOLD:
      return 'blue'
    case SupportTicketStatus.CLOSED:
      return 'green'
    case SupportTicketStatus.SOLVED:
      return 'green'
    default:
      return 'default'
  }
}
