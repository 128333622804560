import { AgGridReact } from 'ag-grid-react'
import { Button } from 'antd'
import dayjs from 'dayjs'
import { Download } from 'lucide-react'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'sonner'
import { Table } from '../../../../../components/Table/Table'
import { roundNumber } from '../../../../../helpers/roundNumber'
import { centimetresToInches, gramsToPounds } from '../../../../../helpers/unitConverters'
import { TablePersistKey } from '../../../../../stores/useTableStore'
import { StyledMainCard } from '../../../../dashboard/styles'
import { WholesaleOrder } from '../../../WholesaleOrderDetailsView'
import { useExportToTsv } from './hooks/useExportToTsv'
import { useWholesaleOrderLineItemTableColumnDefs } from './hooks/useWholesaleOrderLineItemTableColumnDefs'

export type WholesaleOrderLineItemTableRecord = {
  identifier: string
  product: {
    uuid: string
    name: string
    tags: string[]
  }
  productVariant: {
    uuid: string
    identifier: string
    ean: string | null
    internalSku: string | null
    attributes: string[]
  }
  asins: string[]
  skus: string[]
  packagingUnit: {
    uuid: string
    quantity: number
    lengthInCm: number | null
    widthInCm: number | null
    heightInCm: number | null
    weightInGram: number | null
  }
  quantityOfPackagingUnits: number
  totalQuantity: number
  netPurchasePrice: number
  currency: string
  priceDifference: number | null
  netTotal: number
}

type WholesaleOrderLineItemsTableProps = {
  wholesaleOrder: WholesaleOrder
  lineItems: WholesaleOrderLineItemTableRecord[]
}

export const WholesaleOrderLineItemTable = ({
  wholesaleOrder,
  lineItems,
}: WholesaleOrderLineItemsTableProps) => {
  const gridRef = useRef<AgGridReact<WholesaleOrderLineItemTableRecord>>(null)

  const { wholesaleOrderIdentifier } = useParams<{ wholesaleOrderIdentifier: string }>()
  const { t } = useTranslation('wholesale-order')

  const columnDefs = useWholesaleOrderLineItemTableColumnDefs()

  const { exportToTsv } = useExportToTsv()

  const defaultExcelExportParams = {
    fileName: `wholesale-orders-line-items-${dayjs().format('DD-MM-YYYY')}`,
  }

  const handleExport = useCallback(async () => {
    if (!lineItems.length) {
      return
    }

    const destinationCountry = wholesaleOrder.shippedToWarehouseLocation.countryCode
    const isImperialSystem = destinationCountry === 'US'

    try {
      const data = lineItems.map((item) => {
        const dimensions = isImperialSystem
          ? {
              'Box length (in)':
                item.packagingUnit.lengthInCm !== null &&
                item.packagingUnit.lengthInCm !== undefined
                  ? roundNumber(centimetresToInches(item.packagingUnit.lengthInCm))
                  : '',
              'Box width (in)':
                item.packagingUnit.widthInCm !== null && item.packagingUnit.widthInCm !== undefined
                  ? roundNumber(centimetresToInches(item.packagingUnit.widthInCm))
                  : '',
              'Box height (in)':
                item.packagingUnit.heightInCm !== null &&
                item.packagingUnit.heightInCm !== undefined
                  ? roundNumber(centimetresToInches(item.packagingUnit.heightInCm))
                  : '',
              'Box weight (lb)':
                item.packagingUnit.weightInGram !== null &&
                item.packagingUnit.weightInGram !== undefined
                  ? roundNumber(gramsToPounds(item.packagingUnit.weightInGram))
                  : '',
            }
          : {
              'Box length (cm)': item.packagingUnit.lengthInCm ?? '',
              'Box width (cm)': item.packagingUnit.widthInCm ?? '',
              'Box height (cm)': item.packagingUnit.heightInCm ?? '',
              'Box weight (kg)':
                item.packagingUnit.weightInGram !== null &&
                item.packagingUnit.weightInGram !== undefined
                  ? roundNumber(item.packagingUnit.weightInGram / 1000)
                  : '',
            }

        return {
          'Merchant SKU': item.skus?.[0] ?? '',
          Quantity: item.totalQuantity,
          'Prep owner': 'Seller',
          'Labeling owner': 'Seller',
          'Expiration date (MM/DD/YYYY)': dayjs().add(1, 'year').startOf('day').toISOString(),
          'Manufacturing lot code': '',
          'Units per box': item.packagingUnit.quantity,
          'Number of boxes': item.quantityOfPackagingUnits,
          ...dimensions,
        }
      })

      await exportToTsv(data, wholesaleOrderIdentifier ?? 'WO')
    } catch {
      toast.error(t('shared.error.message', { ns: 'translation' }))
    }
  }, [lineItems, wholesaleOrderIdentifier, exportToTsv])

  return (
    <StyledMainCard
      title={t('wholesaleOrderLineItemsTable.title')}
      extra={
        <Button icon={<Download size={16} />} onClick={handleExport}>
          {t('wholesaleOrderLineItemsTable.button.export')}
        </Button>
      }
    >
      <Table<WholesaleOrderLineItemTableRecord>
        columnDefs={columnDefs}
        defaultExcelExportParams={defaultExcelExportParams}
        grandTotalRow="bottom"
        maxTableRows={10}
        persist={{
          key: TablePersistKey.WHOLESALE_ORDER_LINE_ITEM,
          storage: localStorage,
        }}
        ref={gridRef}
        rowData={lineItems}
        showColumnChooser
        showExport
        showFilterReset
        showUniversalSearch
      />
    </StyledMainCard>
  )
}
