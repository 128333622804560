import { ColDef, ColGroupDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community'
import { Flex, Tag, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components/FormattedDate'
import { MoreItemsIndicator } from '../../../../components/MoreItemsIndicator'
import { S3BucketActionButton } from '../../../../components/S3BucketActionButton'
import { Table } from '../../../../components/Table/Table'
import { AmazonInboundShipmentStatus } from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { UploadProofOfDeliveryButton } from './UploadProofOfDeliveryButton'

export type AmazonInboundShipmentPlanDiscrepanciesTableRecord = {
  shipmentId: string
  shipmentName: string
  skus: string[]
  totalUnitsExpected: number | null
  totalUnitsLocated: number | null
  shipmentStatus: string
  proofOfDeliveryUrl: string | null
  createdAt: string
}

type AmazonInboundShipmentPlanDiscrepanciesTableProps = {
  dataSource: AmazonInboundShipmentPlanDiscrepanciesTableRecord[] | undefined
  loading: boolean
}

export const AmazonInboundShipmentPlanDiscrepanciesTable = ({
  dataSource,
  loading,
}: AmazonInboundShipmentPlanDiscrepanciesTableProps) => {
  const [rowData, setRowData] = useState<
    AmazonInboundShipmentPlanDiscrepanciesTableRecord[] | undefined
  >(dataSource)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('inventory')

  useEffect(() => {
    setRowData(dataSource)
  }, [dataSource])

  const [columnDefs] = useState<
    (
      | ColDef<AmazonInboundShipmentPlanDiscrepanciesTableRecord>
      | ColGroupDef<AmazonInboundShipmentPlanDiscrepanciesTableRecord>
    )[]
  >([
    {
      field: 'shipmentId',
      headerName: t('amazonInboundShipmentPlan.discrepancies.columns.shipmentId'),
    },
    {
      field: 'shipmentName',
      headerName: t('amazonInboundShipmentPlan.discrepancies.columns.shipmentName'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'skus',
      headerName: t('amazonInboundShipmentPlan.discrepancies.columns.skus'),
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanDiscrepanciesTableRecord,
          string[] | null
        >
      ) => {
        const skus = params.value

        if (!skus) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = skus.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{skus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={skus} />}
          </Flex>
        )
      },
    },
    {
      field: 'totalUnitsExpected',
      headerName: t('amazonInboundShipmentPlan.discrepancies.columns.unitsExpected'),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'totalUnitsLocated',
      headerName: t('amazonInboundShipmentPlan.discrepancies.columns.unitsLocated'),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'shipmentStatus',
      headerName: t('amazonInboundShipmentPlan.discrepancies.columns.shipmentStatus'),
      valueFormatter: (
        params: ValueFormatterParams<
          AmazonInboundShipmentPlanDiscrepanciesTableRecord,
          AmazonInboundShipmentPlanDiscrepanciesTableRecord['shipmentStatus']
        >
      ) => {
        if (params.value === AmazonInboundShipmentStatus.RECEIVING) {
          return t('amazonInboundShipmentPlan.discrepancies.status.RECEIVING')
        }

        if (params.value === AmazonInboundShipmentStatus.CLOSED) {
          return t('amazonInboundShipmentPlan.discrepancies.status.CLOSED')
        }

        return ''
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanDiscrepanciesTableRecord,
          AmazonInboundShipmentPlanDiscrepanciesTableRecord['shipmentStatus']
        >
      ) => {
        return <Tag>{params.valueFormatted}</Tag>
      },
    },
    {
      field: 'proofOfDeliveryUrl',
      headerName: t('amazonInboundShipmentPlan.discrepancies.columns.proofOfDelivery'),
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanDiscrepanciesTableRecord,
          AmazonInboundShipmentPlanDiscrepanciesTableRecord['proofOfDeliveryUrl']
        >
      ) => {
        if (!params.data?.shipmentId) {
          return null
        }

        if (!params.value) {
          return (
            <UploadProofOfDeliveryButton
              shipmentId={params.data.shipmentId}
              setRowData={setRowData}
            />
          )
        }

        return (
          <S3BucketActionButton
            bucketName={import.meta.env.VITE_AWS_S3_BUCKET_NAME_AMAZON_INBOUND_SHIPMENTS}
            objectKey={`${selectedCompany.sellerId}/${params.data.shipmentId}/proof-of-delivery/${params.value}`}
          >
            {t('shared.button.download', { ns: 'translation' })}
          </S3BucketActionButton>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: t('amazonInboundShipmentPlan.discrepancies.columns.createdAt'),
      filter: false,
      initialSort: 'desc',
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanDiscrepanciesTableRecord,
          AmazonInboundShipmentPlanDiscrepanciesTableRecord['createdAt']
        >
      ) => {
        return <FormattedDate date={params.value} withRelativeTime layout="horizontal" />
      },
    },
  ])

  return (
    <Table<AmazonInboundShipmentPlanDiscrepanciesTableRecord>
      columnDefs={columnDefs}
      loading={loading}
      rowData={rowData}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
    />
  )
}
