import { useMutation } from '@apollo/client'
import { Alert, Button, Form, Input, Modal, Space } from 'antd'
import { SquarePen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { GetProductDocument, UpdateProductNameDocument } from '../../../generated/graphql'

type EditProductNameFormInstance = {
  name: string
}

type EditProductNameModalProps = {
  uuid: string
  name: string
}

export const EditProductNameModal = ({ uuid, name }: EditProductNameModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { t } = useTranslation('inventory')
  const [form] = Form.useForm<EditProductNameFormInstance>()

  const [updateProductName] = useMutation(UpdateProductNameDocument)

  const handleSubmit = async (values: EditProductNameFormInstance) => {
    setSubmitting(true)

    try {
      await updateProductName({
        variables: {
          uuid,
          name: values.name.trim(),
        },
        optimisticResponse: {
          updateProduct: {
            name: values.name.trim(),
          },
        },
        update: (cache, { data }) => {
          const updatedProduct = data?.updateProduct
          const cachedQuery = cache.readQuery({
            query: GetProductDocument,
            variables: { uuid },
          })

          if (updatedProduct && cachedQuery) {
            cache.writeQuery({
              query: GetProductDocument,
              variables: { uuid },
              data: {
                product: {
                  ...cachedQuery.product,
                  name: updatedProduct.name,
                },
              },
            })
          }
        },
      })

      toast.success(t('product.details.product.editNameModal.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setOpen(false)
      setSubmitting(false)
    }
  }

  return (
    <>
      <Button
        type="text"
        icon={<SquarePen size={16} />}
        size="small"
        onClick={() => setOpen(true)}
      />
      <Modal
        title={t('product.details.product.editNameModal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Space direction="vertical">
          <Alert type="info" message={t('product.details.product.editNameModal.note')} />
          <Form<EditProductNameFormInstance>
            form={form}
            initialValues={{ name }}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="name"
              label={t('product.details.product.editNameModal.label')}
              rules={[{ required: true, type: 'string', whitespace: true }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Space>
      </Modal>
    </>
  )
}
