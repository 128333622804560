import { useMutation } from '@apollo/client'
import { Button, Form, Input, Modal } from 'antd'
import { SquarePen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  GetWarehouseServiceProvidersDocument,
  UpdateWarehouseServiceProviderDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { WarehouseServiceProvider } from './WarehousingContainer'

type EditWarehouseServiceProviderNameFormInstance = {
  name: string
}

type EditWarehouseServiceProviderNameModalProps = {
  warehouseServiceProvider: WarehouseServiceProvider
}

export const EditWarehouseServiceProviderNameModal = ({
  warehouseServiceProvider,
}: EditWarehouseServiceProviderNameModalProps) => {
  const [open, setOpen] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation('warehousing')
  const [form] = Form.useForm<EditWarehouseServiceProviderNameFormInstance>()

  const [updateWarehouseServiceProvider, { loading }] = useMutation(
    UpdateWarehouseServiceProviderDocument
  )

  const handleSubmit = async (values: EditWarehouseServiceProviderNameFormInstance) => {
    try {
      if (values.name.trim() === warehouseServiceProvider.name) {
        return
      }

      await updateWarehouseServiceProvider({
        variables: {
          uuid: warehouseServiceProvider.uuid,
          name: values.name.trim(),
        },
        optimisticResponse: {
          updateWarehouseServiceProvider: {
            uuid: warehouseServiceProvider.uuid,
            name: values.name.trim(),
          },
        },
        update: (cache, { data }) => {
          if (!selectedCompany?.uuid) {
            return
          }

          const updatedWarehouseServiceProvider = data?.updateWarehouseServiceProvider
          const cachedQuery = cache.readQuery({
            query: GetWarehouseServiceProvidersDocument,
            variables: { companyUuid: selectedCompany.uuid },
          })

          if (updatedWarehouseServiceProvider && cachedQuery) {
            const warehouseServiceProviders = cachedQuery.warehouseServiceProviders.map(
              (warehouseServiceProvider) => {
                if (warehouseServiceProvider.uuid === updatedWarehouseServiceProvider.uuid) {
                  return { ...warehouseServiceProvider, name: updatedWarehouseServiceProvider.name }
                }

                return warehouseServiceProvider
              }
            )

            cache.writeQuery({
              query: GetWarehouseServiceProvidersDocument,
              variables: { companyUuid: selectedCompany.uuid },
              data: { warehouseServiceProviders },
            })
          }
        },
      })

      toast.success(t('edit.toast.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('edit.toast.error'))
    } finally {
      setOpen(false)
      form.resetFields()
    }
  }

  return (
    <>
      <Button
        type="text"
        icon={<SquarePen size={16} />}
        onClick={() => setOpen(true)}
        title={t('edit.title')}
      />
      <Modal
        title={t('edit.title')}
        open={open}
        okText={t('shared.button.save', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelButtonProps={{ disabled: loading }}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
      >
        <Form<EditWarehouseServiceProviderNameFormInstance>
          form={form}
          initialValues={{ name: warehouseServiceProvider.name }}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item<EditWarehouseServiceProviderNameFormInstance>
            name="name"
            rules={[
              { required: true, message: t('form.name.rules.required') },
              { min: 3, message: t('form.name.rules.minLength') },
            ]}
            validateDebounce={1000}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
