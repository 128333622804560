import { CSSProperties } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Marketplace } from '../generated/graphql'

type FlagProps = {
  code: Marketplace
  style?: CSSProperties
}

export const Flag = ({ code, style = {} }: FlagProps) => {
  const countryCode = code === Marketplace.UK ? 'GB' : code

  return (
    <ReactCountryFlag
      countryCode={countryCode}
      svg
      style={{ height: 'auto', borderRadius: '1px', verticalAlign: 'baseline', ...style }}
    />
  )
}
