import { AuthenticatedUser, UserCompany } from '../hooks/useAuthentication'

export function getUserRole(
  user: AuthenticatedUser | undefined | null,
  selectedCompany: UserCompany | undefined | null
): string | undefined {
  if (!user?.roles || !selectedCompany) {
    return undefined
  }

  const role = user.roles.find((role) => role.companyUuid === selectedCompany.uuid)

  if (!role) {
    return undefined
  }

  return role.name
}
