import { useMutation } from '@apollo/client'
import { Button, Form, Input, Modal, TourProps } from 'antd'
import { Plus } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router'
import { toast } from 'sonner'
import {
  CheckOnboardingStepDocument,
  CreateBrandDocument,
  GetCompanyBrandsDocument,
  GetCompanyDocument,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { useOnboardingStore } from '../../../stores/useOnboardingStore'

type CreateBrandFormInstance = {
  name: string
}

export const CreateBrandModal = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const brandRef = useRef(null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const setTourSteps = useOnboardingStore((state) => state.setTourSteps)
  const setTourOpen = useOnboardingStore((state) => state.setTourOpen)
  const { t } = useTranslation(['inventory', 'company'])

  const [form] = Form.useForm<CreateBrandFormInstance>()

  const [createBrand] = useMutation(CreateBrandDocument)
  const [checkOnboardingStep] = useMutation(CheckOnboardingStepDocument)

  const handleSubmit = async (values: CreateBrandFormInstance) => {
    setLoading(true)
    try {
      if (!selectedCompany?.uuid) {
        throw new Error()
      }

      await createBrand({
        variables: {
          input: {
            companyUuid: selectedCompany?.uuid,
            name: values.name.trim(),
          },
        },
        optimisticResponse: {
          createBrand: {
            name: values.name.trim(),
          },
        },
        update: (cache, { data }) => {
          const createdBrand = data?.createBrand
          const cachedQuery = cache.readQuery({
            query: GetCompanyBrandsDocument,
            variables: { companyUuid: selectedCompany?.uuid },
          })

          if (createdBrand && cachedQuery) {
            cache.writeQuery({
              query: GetCompanyBrandsDocument,
              variables: { companyUuid: selectedCompany?.uuid },
              data: {
                brands: [...cachedQuery.brands, createdBrand],
              },
            })
          }
        },
      })

      if (!selectedCompany.completedOnboarding) {
        await checkOnboardingStep({
          variables: {
            input: {
              companyUuid: selectedCompany.uuid,
              eventName: 'add_brand',
            },
          },
          refetchQueries: [
            {
              query: GetCompanyDocument,
              variables: { companyUuid: selectedCompany?.uuid },
            },
            {
              query: GetCompanyBrandsDocument,
              variables: { companyUuid: selectedCompany?.uuid },
            },
          ],
        })
        setTourOpen(false)
        navigate('/settings/brands?tour-loa=true')
      }

      toast.success(t('product.create.general.modal.brand.success'))
    } catch (error) {
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setOpen(false)
      setLoading(false)
      form.resetFields()
    }
  }

  const steps: TourProps['steps'] = [
    {
      title: t('company:onboarding.addBrand.title'),
      description: t('company:onboarding.addBrand.description'),
      target: () => brandRef.current,
      nextButtonProps: {
        style: { display: 'none' },
      },
    },
  ]

  useEffect(() => {
    const tour = searchParams.get('tour-brand')
    if (tour === 'true') {
      setTourSteps(steps)
      setTourOpen(true)
    }
  }, [searchParams])

  return (
    <>
      <Button
        ref={brandRef}
        icon={<Plus size={16} />}
        onClick={() => {
          setTourOpen(false)
          setOpen(true)
        }}
      >
        {t('product.create.general.modal.brand.button')}
      </Button>
      <Modal
        title={t('product.create.general.modal.brand.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form<CreateBrandFormInstance> form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="name"
            label={t('product.create.general.modal.brand.label')}
            rules={[{ required: true, type: 'string', whitespace: true }]}
          >
            <Input placeholder={t('product.create.general.modal.brand.placeholder')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
