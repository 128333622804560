import styled from '@emotion/styled'

export const LanguageSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin: 0.25rem 0.5rem 0.25rem 0.25rem;
  padding-left: 1rem;
  line-height: 40px;
`
