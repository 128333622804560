import { Typography } from 'antd'
import { useDashboardConfigurationStore } from '../../../../stores/useDashboardConfigurationStore'
import { ProductPerformanceDetails } from '../helpers/preparePerformanceOverview'

export const DashboardProductLabel = ({ product }: { product: ProductPerformanceDetails }) => {
  const productIdentifierType = useDashboardConfigurationStore(
    (state) => state.dashboardConfiguration.productIdentifier
  )

  const identifierMap = {
    productName: product.productName,
    internalSku: product.internalSku,
    amazonSku: product.amazonSku,
  }

  return <Typography.Text>{identifierMap[productIdentifierType]}</Typography.Text>
}
