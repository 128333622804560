import { ApolloQueryResult } from '@apollo/client'
import { Button, Card, Result } from 'antd'
import { useTranslation } from 'react-i18next'

type ErrorWithButtonProps = {
  retry: () => Promise<ApolloQueryResult<unknown>>
}

export const ErrorWithRetry = ({ retry }: ErrorWithButtonProps) => {
  const { t } = useTranslation()

  const handleRetry = async () => retry()

  return (
    <Card>
      <Result
        status="warning"
        title={t('shared.error.message')}
        extra={
          <Button type="primary" onClick={handleRetry}>
            {t('shared.button.retry')}
          </Button>
        }
      />
    </Card>
  )
}
