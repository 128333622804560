import styled from '@emotion/styled'
import { Link } from 'react-router'

export const StyledTicketTopic = styled(Link)`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary}80;
  margin-bottom: 10px;
  margin-right: 50px;
  padding: 5px;
  transition: all 0.2 ease;

  :hover,
  :focus {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.primary}80;
    color: white;
  }

  & > li {
    padding-left: 10px;
    margin-bottom: 0;
    list-style: none;
  }
` as unknown as typeof Link
