import { Trash } from 'lucide-react'
import { Button, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select } from 'antd'
import dayjs from 'dayjs'
import { TFunction, i18n } from 'i18next'
import { Plus } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Marketplace } from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'

const { WeekPicker } = DatePicker

type DealsRequiredDynamicInputInputProps = {
  translation?: [TFunction, i18n, boolean] & { t: TFunction; i18n: i18n; ready: boolean }
}

export const DealsRequiredDynamicInput = ({ translation }: DealsRequiredDynamicInputInputProps) => {
  const { t } =
    translation ?? useTranslation(['ticket'], { keyPrefix: 'promotion.lightningDeals.form' })

  const marketplaceOptions = useMarketplaceOptions()

  const childVariantSelectionOptions = [t('childSelector.yes'), t('childSelector.no')]

  return (
    <Form.Item required={true}>
      <Form.List name="dealDetails">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...rest }) => (
              <Row key={key}>
                {fields.length > 1 ? (
                  <Col span={2} style={{ marginBottom: 24 }}>
                    <Button
                      type="primary"
                      danger
                      icon={<Trash size={16} />}
                      onClick={() => remove(name)}
                      style={{ height: '100%' }}
                    />
                  </Col>
                ) : null}
                <Col span={fields.length > 1 ? 22 : 24}>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        {...rest}
                        name={[name, 'sku']}
                        label={t('skuInput.label')}
                        rules={[{ required: true, message: t('skuInput.validation.required') }]}
                      >
                        <Input placeholder={t('skuInput.placeholder')} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...rest}
                        name={[name, 'asin']}
                        label={t('asinInput.label')}
                        rules={[{ required: true, message: t('asinInput.validation.required') }]}
                      >
                        <Input placeholder={t('asinInput.placeholder')} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...rest}
                        name={[name, 'marketplaces']}
                        label={t('marketplacesInput.label')}
                        rules={[
                          { required: true, message: t('marketplacesInput.validation.required') },
                        ]}
                      >
                        <Select<Marketplace>
                          mode="multiple"
                          options={marketplaceOptions.map((marketplace) => ({
                            value: marketplace,
                            label: `Amazon.${getMarketplaceDomain(marketplace)}`,
                          }))}
                          placeholder={t('marketplacesInput.placeholder')}
                          showSearch
                          filterOption={true}
                          optionFilterProp="label"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...rest}
                        name={[name, 'week']}
                        label={t('weekInput.label')}
                        rules={[{ required: true, message: t('weekInput.validation.required') }]}
                      >
                        <WeekPicker
                          placeholder={t('weekInput.placeholder')}
                          disabledDate={(current) =>
                            current.isBefore(dayjs().endOf('week').add(1, 'day'))
                          }
                          format={(value) => {
                            return `${dayjs(value).startOf('week').format('ddd, MMM DD')} - ${dayjs(
                              value
                            )
                              .endOf('week')
                              .format('ddd, MMM DD')}`
                          }}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...rest}
                        name={[name, 'originalPrice']}
                        label={t('originalPriceInput.label')}
                        rules={[
                          { required: true, message: t('originalPriceInput.validation.required') },
                        ]}
                      >
                        <InputNumber
                          placeholder={t('originalPriceInput.placeholder')}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...rest}
                        name={[name, 'discountPrice']}
                        label={t('discountInput.label')}
                        rules={[
                          { required: true, message: t('discountInput.validation.required') },
                        ]}
                      >
                        <InputNumber
                          addonAfter="%"
                          min={20}
                          max={100}
                          placeholder={t('discountInput.placeholder')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...rest}
                        label={t('childSelector.label')}
                        name={[name, 'includeChildVariants']}
                        rules={[
                          { required: true, message: t('childSelector.validation.required') },
                        ]}
                      >
                        <Radio.Group options={childVariantSelectionOptions} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button type="dashed" block icon={<Plus size={16} />} onClick={() => add()}>
                {t('addMoreButton')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  )
}
