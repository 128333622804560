import { useMutation, useQuery } from '@apollo/client'
import { Button, List, Space, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { Clock, Eye, Pause, Pen, Play, Trash } from 'lucide-react'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { LinkButton } from '../../../../components/LinkButton'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import {
  DeleteWholesaleOrderTemplateDocument,
  GetAllWholesaleOrderTemplatesDocument,
  UpdateWholesaleOrderTemplateDocument,
  WholesaleOrderTemplateStatus,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'

type InfoBadgeProps = {
  text: string
  tooltipText?: string
  icon?: ReactNode
}

const InfoBadge = ({ icon, text, tooltipText }: InfoBadgeProps) => {
  const content = (
    <Space>
      {icon}
      {text}
    </Space>
  )

  return tooltipText ? <Tooltip title={tooltipText}>{content}</Tooltip> : content
}

export const WholesaleOrdersTemplatesList = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { t } = useTranslation(['wholesale-order', 'translation'])
  const [loadingItemId, setLoadingItemId] = useState<string | null>(null)

  const { data, loading, error, refetch } = useQuery(GetAllWholesaleOrderTemplatesDocument, {
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  const [deleteWholesaleOrderTemplate, { loading: isDeleting }] = useMutation(
    DeleteWholesaleOrderTemplateDocument,
    {
      onCompleted: async () => {
        await refetch()
        setLoadingItemId(null)
      },
    }
  )

  const [updateWholesaleOrderTemplate, { loading: isUpdating }] = useMutation(
    UpdateWholesaleOrderTemplateDocument,
    {
      onCompleted: async () => {
        await refetch()
        setLoadingItemId(null)
      },
    }
  )

  if (loading) return <LoadingSpinner />
  if (error)
    return (
      <StatusResult status="500" title="500" subTitle={t('translation:shared.error.message')} />
    )

  const handleDelete = async (identifier: string) => {
    setLoadingItemId(identifier)
    try {
      await deleteWholesaleOrderTemplate({
        variables: { identifier },
        refetchQueries: [
          {
            query: GetAllWholesaleOrderTemplatesDocument,
            variables: { companyUuid: selectedCompany?.uuid },
          },
        ],
      })
      toast.success(t('wholesaleOrdersTemplatesList.notification.deleteSuccess'))
    } catch (error) {
      toast.error(t('wholesaleOrdersTemplatesList.notification.deleteError'))
      console.error(error)
    } finally {
      setLoadingItemId(null)
    }
  }

  const handleToggleStatus = async (identifier: string, status: WholesaleOrderTemplateStatus) => {
    setLoadingItemId(identifier)
    try {
      await updateWholesaleOrderTemplate({
        variables: {
          identifier,
          input: { status },
        },
        refetchQueries: [
          {
            query: GetAllWholesaleOrderTemplatesDocument,
            variables: { companyUuid: selectedCompany.uuid },
          },
        ],
      })
      toast.success(t('wholesaleOrdersTemplatesList.notification.updateSuccess'))
    } catch (error) {
      console.error(error)
      toast.error(t('wholesaleOrdersTemplatesList.notification.updateError'))
    } finally {
      setLoadingItemId(null)
    }
  }

  return (
    <List
      itemLayout="vertical"
      size="large"
      style={{ width: '100%' }}
      dataSource={data?.wholesaleOrderTemplates}
      renderItem={(item) => (
        <List.Item
          key={item.createdAt}
          style={{ opacity: item.status === WholesaleOrderTemplateStatus.INACTIVE ? 0.5 : 1 }}
          actions={[
            <Tag>{item.identifier}</Tag>,
            <Tag color={item.status === WholesaleOrderTemplateStatus.ACTIVE ? 'success' : 'error'}>
              {item.status}
            </Tag>,
            <InfoBadge
              text={`${item.shippedFromWarehouseLocation.name} --> ${item.shippedToWarehouseLocation.name}`}
            />,
            <InfoBadge
              text={dayjs(item.createdAt).fromNow()}
              tooltipText={`${t('wholesaleOrdersTemplatesList.lineItem.createdOn')} ${dayjs(item.createdAt).format('DD-MM-YYYY HH:mm')}`}
              icon={<Clock size={16} />}
            />,
            <InfoBadge
              text={dayjs(item.updatedAt).fromNow()}
              tooltipText={`${t('wholesaleOrdersTemplatesList.lineItem.updatedOn')} ${dayjs(item.updatedAt).format('DD-MM-YYYY HH:mm')}`}
              icon={<Pen size={16} />}
            />,
            <InfoBadge
              text={`${t('wholesaleOrderTemplateCreateForm.repeat')} ${item.interval} ${t('wholesaleOrdersTemplatesList.lineItem.days')}`}
              tooltipText={t('wholesaleOrdersTemplatesList.lineItem.interval')}
            />,
          ]}
          extra={
            <Space>
              <LinkButton
                to={`/wholesale/orders/templates/${item?.identifier}`}
                icon={<Eye size={16} />}
              />
              <Button
                loading={loadingItemId === item.identifier && isUpdating}
                onClick={() =>
                  handleToggleStatus(
                    item.identifier,
                    item.status === WholesaleOrderTemplateStatus.ACTIVE
                      ? WholesaleOrderTemplateStatus.INACTIVE
                      : WholesaleOrderTemplateStatus.ACTIVE
                  )
                }
                icon={
                  item.status === WholesaleOrderTemplateStatus.ACTIVE ? (
                    <Pause size={16} />
                  ) : (
                    <Play size={16} />
                  )
                }
              />
              <Button
                onClick={() => handleDelete(item.identifier)}
                loading={loadingItemId === item.identifier && isDeleting}
                danger
                icon={<Trash size={16} />}
              />
            </Space>
          }
        >
          <List.Item.Meta description={<Typography.Text strong>{item.name}</Typography.Text>} />
        </List.Item>
      )}
    />
  )
}
