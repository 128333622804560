import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import { Suspense, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { formatMoney } from '../../../../helpers/formatMoney'

export type PaymentsLongTermStorageFeeTableRecord = {
  name: string | null | undefined
  asin: string
  sku: string
  country: string
  quantity: number
  fee: number
  currency: string
  surchargeAgeTier: string
  surchargeRate: number
  perUnitVolume: number
  yearMonth: string
}

type PaymentsLongTermStorageFeeTableProps = {
  longTermStorageFees: PaymentsLongTermStorageFeeTableRecord[]
}

export const PaymentsLongTermStorageFeeTable = ({
  longTermStorageFees,
}: PaymentsLongTermStorageFeeTableProps) => {
  const { t } = useTranslation('billing')

  const [columnDefs] = useState<
    (
      | ColDef<PaymentsLongTermStorageFeeTableRecord>
      | ColGroupDef<PaymentsLongTermStorageFeeTableRecord>
    )[]
  >([
    {
      field: 'name',
      headerName: t('payments.details.longTermStorageFees.table.name'),
    },
    {
      field: 'asin',
      headerName: t('payments.details.longTermStorageFees.table.asin'),
    },
    {
      field: 'sku',
      headerName: t('payments.details.longTermStorageFees.table.sku'),
    },
    {
      field: 'country',
      headerName: t('payments.details.longTermStorageFees.table.country'),
      filter: 'agSetColumnFilter',
    },
    {
      field: 'quantity',
      headerName: t('payments.details.longTermStorageFees.table.quantity'),
    },
    {
      field: 'fee',
      headerName: t('payments.details.longTermStorageFees.table.fee'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsLongTermStorageFeeTableRecord,
          PaymentsLongTermStorageFeeTableRecord['fee']
        >
      ) => {
        const currency = params.data?.currency ?? 'EUR'
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value ? params.value * -1 : 0, currency)
          : t('shared.notAvailable', { ns: 'translation' })
      },
    },
    {
      field: 'surchargeAgeTier',
      headerName: t('payments.details.longTermStorageFees.table.surchargeAgeTier'),
      filter: 'agSetColumnFilter',
    },
    {
      field: 'surchargeRate',
      headerName: t('payments.details.longTermStorageFees.table.surchargeRate'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsLongTermStorageFeeTableRecord,
          PaymentsLongTermStorageFeeTableRecord['surchargeRate']
        >
      ) => {
        const value = params.value ?? 0
        return +value.toFixed(4)
      },
    },
    {
      field: 'perUnitVolume',
      headerName: t('payments.details.longTermStorageFees.table.perUnitVolume'),
    },
    {
      field: 'yearMonth',
      headerName: t('payments.details.longTermStorageFees.table.date'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsLongTermStorageFeeTableRecord,
          PaymentsLongTermStorageFeeTableRecord['yearMonth']
        >
      ) => {
        return dayjs(params.value, 'YYYYMM').format('MMM YYYY')
      },
    },
  ])

  const defaultColDef: ColDef<PaymentsLongTermStorageFeeTableRecord> = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<PaymentsLongTermStorageFeeTableRecord>) => {
        if (params.value === null || params.value === undefined || params.value === '') {
          return t('shared.notAvailable', { ns: 'translation' })
        } else {
          return params.value
        }
      },
    }),
    []
  )

  return (
    <Suspense>
      {!longTermStorageFees?.length ? (
        <Typography.Text type="secondary">
          {t('payments.details.longTermStorageFees.empty')}
        </Typography.Text>
      ) : (
        <Table<PaymentsLongTermStorageFeeTableRecord>
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={longTermStorageFees}
          suppressContextMenu
        />
      )}
    </Suspense>
  )
}
