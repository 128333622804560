import { createBrowserRouter, RouteObject } from 'react-router'
import { CompanyType } from '../generated/graphql'
import { ProtectedLayout } from '../layouts/ProtectedLayout'
import { PublicLayout } from '../layouts/PublicLayout'
import { Forbidden } from '../views/Forbidden'
import { NotFound } from '../views/NotFound'
import { AmazonOrdersView } from '../views/amazon/orders/AmazonOrdersView'
import { AmazonListingView, AmazonProductsView } from '../views/amazon/products'
import { AmazonReturnView } from '../views/amazon/returns/AmazonReturnView'
import { AmazonInboundShipmentPlanCreateView } from '../views/amazon/shipmentPlans/AmazonInboundShipmentPlanCreateView'
import { AmazonInboundShipmentPlanView } from '../views/amazon/shipmentPlans/AmazonInboundShipmentPlanView'
import { AmazonInboundShipmentPlansView } from '../views/amazon/shipmentPlans/AmazonInboundShipmentPlansView'
import {
  ForgotPasswordStep1View,
  ForgotPasswordStep2View,
  LoginView,
  NewPasswordRequiredView,
  SessionExpiredView,
} from '../views/auth'
import { BillingDocumentsView, PaymentsView } from '../views/billing'
import { BankTransactionsView } from '../views/billing/BankTransactionsView/BankTransactionsView'
import { ComplianceDetailsView } from '../views/compliance/ComplianceDetailsView'
import { ComplianceListView } from '../views/compliance/ComplianceListView'
import { DashboardView } from '../views/dashboard'
import { InvoiceArticleSubscriptionsView } from '../views/invoices/InvoiceArticleSubscriptionsView/InvoiceArticleSubscriptionsView'
import { InvoiceArticlesView } from '../views/invoices/InvoiceArticlesView/InvoiceArticlesView'
import { InvoiceLineItemsView } from '../views/invoices/InvoiceLineItemsView/InvoiceLineItemsView'
import { InvoiceView } from '../views/invoices/InvoiceView/InvoiceView'
import {
  MarketplaceNavigate,
  PPCCampaignListView,
  PPCSponsoredBrandsAdGroupView,
  PPCSponsoredBrandsCampaignView,
  PPCSponsoredProductsAdGroupView,
  PPCSponsoredProductsCampaignCreateView,
  PPCSponsoredProductsCampaignView,
} from '../views/ppc'
import { ProductCreateView, ProductsView, ProductView } from '../views/products'
import { ProductVariantView } from '../views/products/ProductVariantView'
import { ReportsView } from '../views/reports/ReportsView'
import { BusinessReportView } from '../views/reports/businessReports/BusinessReportView'
import { InventoryReportView } from '../views/reports/inventory/InventoryReportView'
import { InventoryHealthReportView } from '../views/reports/inventoryHealth/InventoryHealthReportView'
import { OrdersReportView } from '../views/reports/orders/OrdersReportView'
import {
  BrandManagement,
  CompanySettings,
  LogoutView,
  SettingsView,
  UserSettings,
} from '../views/settings'
import { CompanyContracts } from '../views/settings/components/CompanyContracts/CompanyContracts'
import { Warehousing } from '../views/settings/components/Warehousing/Warehousing'
import { StockView } from '../views/stock/StockView'
import TicketRoutes from '../views/ticket'
import { WholesaleOrderBillingDocumentsView } from '../views/wholesale/WholesaleOrderBillingDocuments/WholesaleOrderBillingDocumentsView'
import { WholesaleOrderBillingDocumentCreateView } from '../views/wholesale/WholesaleOrderBillingDocuments/components/WholesaleOrderBillingDocumentCreate/WholesaleOrderBillingDocumentCreateView'
import { WholesaleOrderBillingDocumentDetailsView } from '../views/wholesale/WholesaleOrderBillingDocuments/components/WholesaleOrderBillingDocumentDetails/WholesaleOrderBillingDocumentDetailsView'
import { WholesaleOrderCreateView } from '../views/wholesale/WholesaleOrderCreateView'
import { WholesaleOrderDetailsView } from '../views/wholesale/WholesaleOrderDetailsView'
import { WholesaleOrderEditView } from '../views/wholesale/WholesaleOrderEditView'
import { WholesaleOrderTemplateDetailsView } from '../views/wholesale/WholesaleOrderTemplateDetailsView'
import { WholesaleOrdersView } from '../views/wholesale/WholesaleOrdersView'
import { ProtectedRoute } from './ProtectedRoute'

const routes: RouteObject[] = [
  {
    path: 'login',
    element: <PublicLayout />,
    children: [
      { index: true, element: <LoginView /> },
      { path: 'new-password-required', element: <NewPasswordRequiredView /> },
      { path: 'forgotten-password/step-1', element: <ForgotPasswordStep1View /> },
      { path: 'forgotten-password/step-2', element: <ForgotPasswordStep2View /> },
      { path: 'session-expired', element: <SessionExpiredView /> },
    ],
  },
  {
    path: '/',
    element: <ProtectedLayout />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute
            roles={['company:admin']}
            types={[CompanyType.SUPPLIER, CompanyType.WHOLESALER]}
            to={getRedirectPath}
          >
            <DashboardView />
          </ProtectedRoute>
        ),
      },
      {
        path: 'products',
        element: (
          <ProtectedRoute
            types={[CompanyType.SUPPLIER, CompanyType.WHOLESALER, CompanyType.COMPLIANCE]}
          />
        ),
        children: [
          { index: true, element: <ProductsView /> },
          { path: 'add', element: <ProductCreateView /> },
          { path: ':productUuid', element: <ProductView /> },
          {
            path: ':productUuid/variants/:productVariantUuid',
            element: <ProductVariantView />,
          },
          {
            path: ':productUuid/variants/:productVariantUuid/amazon/:amazonListingUuid',
            element: <AmazonListingView />,
          },
        ],
      },
      {
        path: 'stock',
        element: (
          <ProtectedRoute types={[CompanyType.SUPPLIER, CompanyType.WHOLESALER]}>
            <StockView />
          </ProtectedRoute>
        ),
      },
      {
        path: 'amazon',
        element: <ProtectedRoute types={[CompanyType.SUPPLIER, CompanyType.WHOLESALER]} />,
        children: [
          {
            path: 'products',
            element: <AmazonProductsView />,
          },
          {
            path: 'orders',
            element: (
              <ProtectedRoute roles={['company:admin']}>
                <AmazonOrdersView />
              </ProtectedRoute>
            ),
          },
          {
            path: 'returns',
            element: (
              <ProtectedRoute roles={['company:admin']}>
                <AmazonReturnView />
              </ProtectedRoute>
            ),
          },
          {
            path: 'shipment-plans',
            children: [
              { index: true, element: <AmazonInboundShipmentPlansView /> },
              { path: ':uuid', element: <AmazonInboundShipmentPlanView /> },
              {
                path: 'create',
                element: <AmazonInboundShipmentPlanCreateView />,
              },
            ],
          },
          {
            path: 'ppc',
            element: <ProtectedRoute roles={['company:admin']} />,
            children: [
              { index: true, element: <MarketplaceNavigate /> },
              { path: ':marketplace', element: <PPCCampaignListView /> },
              {
                path: ':marketplace/sp/campaigns/:campaignid',
                element: <PPCSponsoredProductsCampaignView />,
              },
              {
                path: ':marketplace/sp/campaigns/create',
                element: <PPCSponsoredProductsCampaignCreateView />,
              },
              {
                path: ':marketplace/sp/campaigns/:campaignid/adgroups/:adgroupid',
                element: <PPCSponsoredProductsAdGroupView />,
              },
              {
                path: ':marketplace/sb/campaigns/:campaignid',
                element: <PPCSponsoredBrandsCampaignView />,
              },
              {
                path: ':marketplace/sb/campaigns/:campaignid/adgroups/:adgroupid',
                element: <PPCSponsoredBrandsAdGroupView />,
              },
            ],
          },
        ],
      },
      {
        path: 'wholesale',
        element: <ProtectedRoute types={[CompanyType.WHOLESALER, CompanyType.RETAILER]} />,
        children: [
          {
            path: 'orders',
            children: [
              { index: true, element: <WholesaleOrdersView /> },
              {
                path: 'create',
                element: (
                  <ProtectedRoute types={[CompanyType.WHOLESALER]}>
                    <WholesaleOrderCreateView />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':wholesaleOrderIdentifier',
                children: [
                  {
                    index: true,
                    element: <WholesaleOrderDetailsView />,
                  },
                  {
                    path: 'edit',
                    element: <WholesaleOrderEditView />,
                  },
                ],
              },
              {
                path: 'templates',
                children: [
                  {
                    path: ':identifier',
                    element: <WholesaleOrderTemplateDetailsView />,
                  },
                ],
              },
            ],
          },
          {
            path: 'billing-documents',
            children: [
              {
                index: true,
                element: <WholesaleOrderBillingDocumentsView />,
              },
              {
                path: 'create',
                element: (
                  <ProtectedRoute types={[CompanyType.WHOLESALER]}>
                    <WholesaleOrderBillingDocumentCreateView />
                  </ProtectedRoute>
                ),
              },
              {
                path: ':identifier',
                element: <WholesaleOrderBillingDocumentDetailsView />,
              },
            ],
          },
        ],
      },
      {
        path: 'compliance',
        element: (
          <ProtectedRoute
            types={[
              CompanyType.SUPPLIER,
              CompanyType.WHOLESALER,
              CompanyType.RETAILER,
              CompanyType.COMPLIANCE,
            ]}
          />
        ),
        children: [
          { index: true, element: <ComplianceListView /> },
          {
            path: ':productComplianceIdentifier',
            element: <ComplianceDetailsView />,
          },
        ],
      },
      {
        path: 'reports',
        element: (
          <ProtectedRoute
            roles={['company:admin']}
            types={[CompanyType.SUPPLIER, CompanyType.WHOLESALER]}
          />
        ),
        children: [
          { index: true, element: <ReportsView /> },
          { path: 'orders', element: <OrdersReportView /> },
          { path: 'inventory', element: <InventoryReportView /> },
          { path: 'business', element: <BusinessReportView /> },
          { path: 'inventory-health', element: <InventoryHealthReportView /> },
        ],
      },
      {
        path: 'billing',
        element: (
          <ProtectedRoute
            roles={['company:admin']}
            types={[CompanyType.SUPPLIER, CompanyType.WHOLESALER]}
          />
        ),
        children: [
          { path: 'payments', element: <PaymentsView /> },
          { path: 'documents', element: <BillingDocumentsView /> },
          { path: 'bank-transactions', element: <BankTransactionsView /> },
        ],
      },
      {
        path: 'invoices',
        element: <ProtectedRoute roles={['company:admin']} types={[CompanyType.RETAILER]} />,
        children: [
          { index: true, element: <InvoiceView /> },
          { path: 'articles', element: <InvoiceArticlesView /> },
          { path: 'subscriptions', element: <InvoiceArticleSubscriptionsView /> },
          { path: 'items', element: <InvoiceLineItemsView /> },
        ],
      },
      {
        path: 'tickets/*',
        element: (
          <ProtectedRoute types={[CompanyType.SUPPLIER, CompanyType.WHOLESALER]}>
            <TicketRoutes />
          </ProtectedRoute>
        ),
      },
      {
        path: 'settings',
        element: (
          <ProtectedRoute
            types={[
              CompanyType.RETAILER,
              CompanyType.SUPPLIER,
              CompanyType.WHOLESALER,
              CompanyType.COMPLIANCE,
            ]}
          >
            <SettingsView />
          </ProtectedRoute>
        ),
        children: [
          { path: 'user', element: <UserSettings /> },
          { path: 'company', element: <CompanySettings /> },
          {
            path: 'brands',
            element: (
              <ProtectedRoute
                types={[CompanyType.SUPPLIER, CompanyType.WHOLESALER, CompanyType.COMPLIANCE]}
              >
                <BrandManagement />
              </ProtectedRoute>
            ),
          },
          {
            path: 'warehousing',
            element: (
              <ProtectedRoute
                types={[CompanyType.SUPPLIER, CompanyType.WHOLESALER, CompanyType.RETAILER]}
              >
                <Warehousing />
              </ProtectedRoute>
            ),
          },
          {
            path: 'contracts',
            element: (
              <ProtectedRoute
                types={[CompanyType.SUPPLIER, CompanyType.WHOLESALER, CompanyType.COMPLIANCE]}
              >
                <CompanyContracts />
              </ProtectedRoute>
            ),
          },
        ],
      },
      { path: 'logout', element: <LogoutView /> },
      { path: 'forbidden', element: <Forbidden /> },
      { path: '*', element: <NotFound /> },
    ],
  },
]

export const router = createBrowserRouter(routes)

export function getRedirectPath(companyType: CompanyType) {
  switch (companyType) {
    case CompanyType.RETAILER:
      return '/wholesale/orders'
    case CompanyType.COMPLIANCE:
      return '/products'
    default:
      return '/'
  }
}
