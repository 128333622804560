import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { GetBillingDocumentsDocument } from '../../generated/graphql'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { BillingDocumentsTable } from './components/BillingDocumentsTable'
import { prepareBillingTableData } from './helpers/prepareBillingTableData'

export const BillingDocumentsView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation(['billing', 'translation'], { keyPrefix: 'document' })

  const { loading, error, data } = useQuery(GetBillingDocumentsDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-and-network',
    skip: !selectedCompany.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult status="500" title="500" subTitle={t('translation:shared.error.message')} />
    )
  }

  const documents = data ? prepareBillingTableData(data) : []

  return (
    <>
      <SubHeader heading={t('heading')} />
      <ViewContainer>
        <BillingDocumentsTable documents={documents} />
      </ViewContainer>
    </>
  )
}
