import { useMutation } from '@apollo/client'
import { Storage } from 'aws-amplify'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  S3DocumentListCard,
  S3DocumentListCardData,
} from '../../../components/S3/S3DocumentListCard'
import {
  CompanyType,
  DeleteProductComplianceCheckDocumentDocument,
} from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { ProductComplianceCheckDocumentUploadModal } from './ProductComplianceCheckDocumentUploadModal'

const bucketName = import.meta.env.VITE_AWS_S3_BUCKET_NAME_PRODUCT_DOCUMENTS

type ProductComplianceCheckDocumentsCardProps = {
  productComplianceCheckIdentifier: string
  productUuid: string
  documents: S3DocumentListCardData[]
}

export const ProductComplianceCheckDocumentsCard = ({
  documents,
  productComplianceCheckIdentifier,
  productUuid,
}: ProductComplianceCheckDocumentsCardProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { t } = useTranslation(['compliance'])

  const [deleteProductComplianceCheckDocument] = useMutation(
    DeleteProductComplianceCheckDocumentDocument
  )

  const isRetailer = selectedCompany.type === CompanyType.RETAILER

  const handleDeleteDocument = async (bucketKey: string) => {
    try {
      await Storage.remove(bucketKey, {
        bucket: bucketName,
      })

      await deleteProductComplianceCheckDocument({
        variables: {
          bucketKey: bucketKey,
        },
        update: (cache, { data }) => {
          const deletedDocument = data?.deleteProductComplianceCheckDocument
          if (!deletedDocument) return

          const cacheId = cache.identify({
            __typename: 'ProductComplianceCheck',
            identifier: productComplianceCheckIdentifier,
          })

          cache.modify({
            id: cacheId,
            fields: {
              documents: (documents = []) =>
                documents.filter((document) => document.bucketKey !== bucketKey),
            },
          })
        },
      })

      toast.success(t('documents.delete.toast.success'))
    } catch (error) {
      toast.error(t('documents.delete.toast.error'))
      console.error(error)
    }
  }

  return (
    <S3DocumentListCard
      title={t('compliance:documents.title')}
      data={documents}
      bucketName={bucketName}
      showPreview
      showViewButton
      showDownloadButton
      customGetFilenameFromKey={getFilenameFromKey}
      deleteFunction={isRetailer ? handleDeleteDocument : undefined}
      rightHeaderContent={
        isRetailer && (
          <ProductComplianceCheckDocumentUploadModal
            productComplianceCheckIdentifier={productComplianceCheckIdentifier}
            productUuid={productUuid}
          />
        )
      }
    />
  )
}

function getFilenameFromKey(key: string) {
  const fileName = key.split('/').slice(2).join('/')
  return fileName.split('_').slice(-1).join('_')
}
