import { useMutation } from '@apollo/client'
import { Alert, Button, Form, InputNumber, Modal, Space } from 'antd'
import { SquarePen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  GetProductVariantDocument,
  UpdateSmallestSalesUnitDocument,
} from '../../../../../generated/graphql'

type EditSmallestSalesUnitFormInstance = {
  smallestSalesUnit: number
}

type EditSmallestSalesUnitModalProps = {
  uuid: string
  smallestSalesUnit: number | null | undefined
}

export const EditSmallestSalesUnitModal = ({
  uuid,
  smallestSalesUnit,
}: EditSmallestSalesUnitModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { t } = useTranslation(['inventory', 'translation'])
  const [form] = Form.useForm<EditSmallestSalesUnitFormInstance>()

  const [updateSmallestSalesUnit] = useMutation(UpdateSmallestSalesUnitDocument)

  const handleSubmit = async (values: EditSmallestSalesUnitFormInstance) => {
    setSubmitting(true)

    try {
      await updateSmallestSalesUnit({
        variables: {
          uuid,
          smallestSalesUnit: values.smallestSalesUnit,
        },
        optimisticResponse: {
          updateProductVariant: {
            smallestSalesUnit: values.smallestSalesUnit,
          },
        },
        refetchQueries: [{ query: GetProductVariantDocument }],
      })

      toast.success(t('product.details.productVariant.smallestSalesUnitModal.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setOpen(false)
      setSubmitting(false)
    }
  }

  return (
    <>
      <Button
        type="text"
        icon={<SquarePen size={16} />}
        size="small"
        onClick={() => setOpen(true)}
      />
      <Modal
        title={t('product.details.productVariant.smallestSalesUnitModal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Space direction="vertical">
          <Alert
            type="info"
            message={t('product.details.productVariant.smallestSalesUnitModal.note')}
          />
          <Form<EditSmallestSalesUnitFormInstance>
            form={form}
            initialValues={{ smallestSalesUnit }}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="smallestSalesUnit"
              label={t('product.details.productVariant.smallestSalesUnitModal.label')}
              rules={[
                {
                  required: true,
                  type: 'integer',
                  min: 1,
                  transform: (value) => {
                    const parsedValue = parseInt(value, 10)
                    return isNaN(parsedValue) ? undefined : parsedValue
                  },
                },
              ]}
            >
              <InputNumber min={1} precision={0} />
            </Form.Item>
          </Form>
        </Space>
      </Modal>
    </>
  )
}
