import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { AmazonOrderStatus } from '../../../../generated/graphql'

type DashboardOrderStatusTagProps = {
  status: AmazonOrderStatus
}

export const DashboardOrderStatusTag = ({ status }: DashboardOrderStatusTagProps) => {
  const { t } = useTranslation('dashboard')

  return (
    <Tag
      color={
        status === AmazonOrderStatus.SHIPPED
          ? 'success'
          : [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(status)
            ? 'warning'
            : status === AmazonOrderStatus.CANCELED
              ? 'error'
              : 'default'
      }
    >
      {status === AmazonOrderStatus.SHIPPED
        ? t('performance.latestOrders.statusFilter.shipped')
        : [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(status)
          ? t('performance.latestOrders.statusFilter.pending')
          : status === AmazonOrderStatus.CANCELED
            ? t('performance.latestOrders.statusFilter.canceled')
            : null}
    </Tag>
  )
}
