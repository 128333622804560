import {
  Alert,
  Button,
  Flex,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
} from 'antd'
import * as i18nIsoCountries from 'i18n-iso-countries'
import { Plus, Trash } from 'lucide-react'
import { forwardRef, useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../config/i18n'
import {
  CompanyVatIdInput,
  CompanyVatIdStatus,
  CompanyVatIdValidationStatus,
} from '../../../../generated/graphql'
import { useCountryOptions } from '../../../../hooks/useCountryOptions'
import { useOnboardingStore } from '../../../../stores/useOnboardingStore'

type CompanyInformationVatDataFormFieldsProps = {
  form: FormInstance
  temporaryVatIds: Set<string>
  setTemporaryVatIds: React.Dispatch<React.SetStateAction<Set<string>>>
}

const defaultCountry = 'DE'

export const CompanyInformationVatDataFormFields = forwardRef<
  HTMLButtonElement,
  CompanyInformationVatDataFormFieldsProps
>(({ form, temporaryVatIds, setTemporaryVatIds }, ref) => {
  const { t } = useTranslation(['translation'])
  const countries = useCountryOptions()
  const [visible, setVisible] = useState(false)
  const [countryCode, setCountryCode] = useState(defaultCountry)
  const [duplicateIndex, setDuplicateIndex] = useState<number | null>(null)

  const setTourOpen = useOnboardingStore((state) => state.setTourOpen)

  const vatIds: CompanyVatIdInput[] = Form.useWatch('vatIds', { form, preserve: true }) || []

  const add = (country: string) => {
    const currentVatIds = form.getFieldValue('vatIds') || []

    const updatedVatIds = currentVatIds.map((vatId: CompanyVatIdInput) => {
      if (vatId.countryCode === country) {
        return {
          ...vatId,
          status: CompanyVatIdStatus.INACTIVE,
        }
      }
      return vatId
    })

    const newVatId = {
      countryCode: country,
      vatId: '',
      validationStatus: CompanyVatIdValidationStatus.UNKNOWN,
      status: CompanyVatIdStatus.ACTIVE,
    }

    updatedVatIds.push(newVatId)
    setTemporaryVatIds((prev) => new Set(prev).add(newVatId.vatId.toLowerCase()))
    form.setFieldsValue({ vatIds: updatedVatIds })
  }

  const remove = (index: number) => {
    const currentVatIds = form.getFieldValue('vatIds') || []
    const vatIdToRemove = currentVatIds[index]

    if (temporaryVatIds.has(vatIdToRemove.vatId.toLowerCase())) {
      setTemporaryVatIds((prev) => {
        const newSet = new Set(prev)
        newSet.delete(vatIdToRemove.vatId.toLowerCase())
        return newSet
      })
    }

    const updatedVatIds = currentVatIds.filter((_: CompanyVatIdInput, i: number) => i !== index)
    form.setFieldsValue({ vatIds: updatedVatIds })
  }

  const showDialog = () => {
    setVisible(true)
  }

  const hideDialog = () => {
    setVisible(false)
  }

  const handleOk = () => {
    add(countryCode)
    setVisible(false)
  }

  const handleSwitchChange = (checked: boolean, vatId: CompanyVatIdInput, index: number) => {
    if (vatId.validationStatus === CompanyVatIdValidationStatus.INVALID) return

    const updatedVatIds = vatIds.map((vId: CompanyVatIdInput, i: number) => {
      if (vId.countryCode === vatId.countryCode) {
        return {
          ...vId,
          status: i === index && checked ? CompanyVatIdStatus.ACTIVE : CompanyVatIdStatus.INACTIVE,
        }
      }
      return vId
    })
    form.setFieldsValue({ vatIds: updatedVatIds })
  }

  const handleInputChange = (value: string, vatId: CompanyVatIdInput, index: number) => {
    const lowerCaseValue = value.toLowerCase()
    const currentVatIds = form.getFieldValue('vatIds') || []
    const isDuplicate = currentVatIds.some(
      (v: CompanyVatIdInput, i: number) => v.vatId.toLowerCase() === lowerCaseValue && i !== index
    )

    if (isDuplicate) {
      form.setFields([
        {
          name: ['vatIds', index, 'vatId'],
          errors: [t('settings.company.vat.duplicate')],
        },
      ])

      const duplicateIndex = currentVatIds.findIndex(
        (v: CompanyVatIdInput, i: number) => v.vatId.toLowerCase() === lowerCaseValue && i !== index
      )
      form.setFieldsValue({
        vatIds: currentVatIds.map((v: CompanyVatIdInput, i: number) => {
          if (i === duplicateIndex) {
            return { ...v, vatId: '' }
          }
          return v
        }),
      })

      setTemporaryVatIds((prev) => {
        const newSet = new Set(prev)
        newSet.delete(value.toLowerCase())
        return newSet
      })
    } else {
      form.setFields([
        {
          name: ['vatIds', index, 'vatId'],
          errors: [],
        },
      ])

      setTemporaryVatIds((prev) => {
        const newSet = new Set(prev)
        newSet.delete(vatId.vatId.toLowerCase())
        newSet.add(value.toLowerCase())
        return newSet
      })
    }

    vatId.vatId = value
    form.setFieldsValue({ vatIds: currentVatIds })
  }

  useEffect(() => {
    if (temporaryVatIds.size === 0) {
      setDuplicateIndex(null)
    }
  }, [temporaryVatIds])

  useEffect(() => {
    visible && setTourOpen(false)
  }, [visible])

  return (
    <>
      <Modal open={visible} centered forceRender onOk={handleOk} onCancel={hideDialog}>
        <Form.Item
          label={t('settings.company.addressCountry')}
          rules={[{ required: false }]}
          style={{ marginTop: '32px' }}
        >
          <Select
            showSearch
            value={countryCode}
            onChange={(value) => setCountryCode(value)}
            filterOption={(input, option) =>
              option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) !== -1
            }
          >
            {countries.map((country) => (
              <Select.Option value={country.code} key={country.code}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Modal>

      <Form.Item name="activeKey" rules={[{ required: false }]}>
        <Flex align="center">
          <Typography.Text style={{ whiteSpace: 'nowrap', marginRight: '4px' }}>
            {t('settings.company.vat.title')}
          </Typography.Text>

          <Button
            type="dashed"
            ref={ref}
            onClick={showDialog}
            disabled={temporaryVatIds.size > 0}
            icon={<Plus size={16} />}
            style={{ marginLeft: 'auto' }}
          >
            {t('settings.company.vat.add')}
          </Button>
        </Flex>

        <Alert
          type="warning"
          showIcon
          style={{ margin: '8px 0' }}
          message={t('settings.company.vat.warning')}
        />
        {vatIds.length > 0 && (
          <div style={{ marginTop: '16px' }}>
            {Object.entries(
              vatIds.reduce(
                (acc: Record<string, CompanyVatIdInput[]>, vatId: CompanyVatIdInput) => {
                  if (!acc[vatId.countryCode]) acc[vatId.countryCode] = []
                  acc[vatId.countryCode]?.push(vatId)
                  return acc
                },
                {}
              )
            ).map(([countryCode, countryVatIds]) => (
              <div key={countryCode}>
                <Space>
                  <ReactCountryFlag
                    countryCode={countryCode}
                    svg
                    style={{ width: '2em', height: '2em' }}
                  />
                  <Typography.Text>
                    {i18nIsoCountries.getName(countryCode, i18n.language)}
                  </Typography.Text>
                </Space>
                {countryVatIds.map((vatId: CompanyVatIdInput, index: number) => (
                  <div key={index} style={{ marginBottom: '16px', marginTop: '16px' }}>
                    <Form.Item
                      name={[
                        'vatIds',
                        vatIds.findIndex((v: CompanyVatIdInput) => v === vatId),
                        'vatId',
                      ]}
                      rules={[
                        {
                          validator: (_, value) => {
                            const currentVatIds = form.getFieldValue('vatIds') || []
                            const isDuplicate = currentVatIds.some(
                              (v: CompanyVatIdInput, i: number) =>
                                v.vatId.toLowerCase() === value.toLowerCase() &&
                                i !== vatIds.findIndex((v: CompanyVatIdInput) => v === vatId)
                            )

                            if (!value) {
                              return Promise.reject(new Error(t('settings.company.vat.invalid')))
                            }

                            if (isDuplicate) {
                              setDuplicateIndex(index)
                              return Promise.reject(new Error(t('settings.company.vat.duplicate')))
                            }

                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <Input
                        disabled={
                          !temporaryVatIds.has(vatId.vatId.toLowerCase()) &&
                          duplicateIndex !== index
                        }
                        value={vatId.vatId}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            vatId,
                            vatIds.findIndex((v: CompanyVatIdInput) => v === vatId)
                          )
                        }
                        suffix={
                          vatId.validationStatus === CompanyVatIdValidationStatus.VALID ? (
                            <Tag color="success">Valid</Tag>
                          ) : vatId.validationStatus === CompanyVatIdValidationStatus.INVALID ? (
                            <Tag color="error">Invalid</Tag>
                          ) : (
                            ''
                          )
                        }
                        placeholder={t('settings.company.vat.placeholder')}
                        prefix={
                          <>
                            {temporaryVatIds.has(vatId.vatId.toLowerCase()) ? (
                              <Button
                                type="link"
                                icon={<Trash size={16} />}
                                onClick={() => {
                                  remove(vatIds.findIndex((v: CompanyVatIdInput) => v === vatId))

                                  setTemporaryVatIds((prev) => {
                                    const newSet = new Set(prev)
                                    newSet.delete(vatId.vatId.toLowerCase())
                                    return newSet
                                  })
                                }}
                              />
                            ) : null}
                          </>
                        }
                        addonAfter={
                          <>
                            <Switch
                              defaultChecked
                              checked={vatId.status === CompanyVatIdStatus.ACTIVE}
                              onChange={(checked) =>
                                handleSwitchChange(
                                  checked,
                                  vatId,
                                  vatIds.findIndex((v: CompanyVatIdInput) => v === vatId)
                                )
                              }
                              disabled={
                                vatId.validationStatus === CompanyVatIdValidationStatus.INVALID
                              }
                            />
                          </>
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name={[
                        'vatIds',
                        vatIds.findIndex((v: CompanyVatIdInput) => v === vatId),
                        'countryCode',
                      ]}
                      hidden
                    >
                      <Input value={vatId.countryCode} />
                    </Form.Item>
                    <Form.Item
                      name={[
                        'vatIds',
                        vatIds.findIndex((v: CompanyVatIdInput) => v === vatId),
                        'status',
                      ]}
                      hidden
                    >
                      <Input value={vatId.status || ''} />
                    </Form.Item>
                    <Form.Item
                      name={[
                        'vatIds',
                        vatIds.findIndex((v: CompanyVatIdInput) => v === vatId),
                        'validationStatus',
                      ]}
                      hidden
                    >
                      <Input value={vatId.validationStatus || ''} />
                    </Form.Item>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </Form.Item>
    </>
  )
})
