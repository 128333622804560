import { useMutation } from '@apollo/client'
import { Alert, Button, Form, Input, Modal, Space, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { SquarePen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import i18n from '../../../../../config/i18n'
import {
  AmazonListingChangeRequestStatus,
  AmazonListingChangeRequestType,
  AmazonListingFieldsFragment,
  ExternalAmazonListingStatus,
  InternalAmazonListingStatus,
  RequestAmazonListingChangeDocument,
  SendSupportTicketDocument,
} from '../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../stores/useGlobalStore'
import { bulletPointRegExp } from '../../../../../utils/regExp'
import { createZendeskTicketOptions } from '../../../../ticket/helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../../../../ticket/ticket.enum'

const { useForm } = Form

type EditAmazonListingContentFormInstance = {
  title: string
  description: string
  bulletPoint1: string
  bulletPoint2: string
  bulletPoint3: string
  bulletPoint4: string
  bulletPoint5: string
  searchTerms: string
}

type EditAmazonListingContentModalProps = {
  amazonListing: AmazonListingFieldsFragment
}

export const EditAmazonListingContentModal = ({
  amazonListing,
}: EditAmazonListingContentModalProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation(['inventory', 'translation'])
  const [form] = useForm<EditAmazonListingContentFormInstance>()

  const [requestAmazonListingChange, { loading }] = useMutation(RequestAmazonListingChangeDocument)
  const [sendSupportTicketRequest] = useMutation(SendSupportTicketDocument)

  const initialValues = {
    title: amazonListing.title ?? '',
    description: amazonListing.description ?? '',
    bulletPoint1: amazonListing.bulletPoints?.[0] ?? '',
    bulletPoint2: amazonListing.bulletPoints?.[1] ?? '',
    bulletPoint3: amazonListing.bulletPoints?.[2] ?? '',
    bulletPoint4: amazonListing.bulletPoints?.[3] ?? '',
    bulletPoint5: amazonListing.bulletPoints?.[4] ?? '',
    searchTerms: amazonListing.searchTerms ?? '',
  }

  const pendingChangeRequest = amazonListing.changeRequests?.find(
    (changeRequest) =>
      changeRequest?.type === AmazonListingChangeRequestType.DETAILS &&
      changeRequest?.status === AmazonListingChangeRequestStatus.PENDING
  )

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleOk = () => {
    form.submit()
  }

  const handleSubmit = async (values: EditAmazonListingContentFormInstance) => {
    try {
      await requestAmazonListingChange({
        variables: {
          amazonListingUuid: amazonListing.uuid,
          input: {
            type: AmazonListingChangeRequestType.DETAILS,
            newDetails: {
              title: values.title.trim(),
              description: values.description.trim(),
              bulletPoints: [
                values.bulletPoint1.trim(),
                values.bulletPoint2.trim(),
                values.bulletPoint3.trim(),
                values.bulletPoint4.trim(),
                values.bulletPoint5.trim(),
              ],
              searchTerms: values.searchTerms.trim(),
            },
          },
        },
      })

      const options = createZendeskTicketOptions({
        categoryTitle: TicketMainCategory.LISTINGS,
        comment: `
        <h1>Amazon Listing Details Change Request</h1>
        <p>SKU: ${amazonListing.sku}</p>
        <p><b>Listing title:</b> ${amazonListing.title}</p>         
        <p><b>Marketplace:</b> ${amazonListing.marketplace.name}</p>
        <h3><a href="https://galaxy.spacegoats.io${location}">Open amazon listing details page in Galaxy</a></h3>
        <h3><a href="https://groundcontrol.spacegoats.io/change-requests/details">Open change request in GC</a></h3>

      `,
        language: i18n.language,
        mainCategory: TicketMainCategory.LISTINGS,
        subject: 'Amazon Listing Details Change Request',
        ticketCategory: TicketCategory.LISTINGS_PRODUCT_INFO_EDIT,
        sellerId: selectedCompany?.sellerId,
      })

      await sendSupportTicketRequest({
        variables: {
          companyUuid: selectedCompany.uuid,
          input: options,
          cognitoUsername: cognitoUser!.getUsername(),
        },
      })

      toast.success(t('shared.notification.success', { ns: 'translation' }), {
        description: t('product.details.changeRequest.success'),
      })
    } catch (error) {
      console.error(error)
      toast.error(t('shared.notification.error', { ns: 'translation' }), {
        description: t('product.details.changeRequest.error'),
      })
    } finally {
      closeModal()
    }
  }

  return (
    <>
      <Tooltip
        title={
          pendingChangeRequest
            ? t('product.details.changeRequest.content.tag.tooltip', {
                date: dayjs(pendingChangeRequest.updated_at).format('DD.MM.YYYY'),
              })
            : null
        }
        placement="bottomRight"
      >
        <Button
          shape="circle"
          size="large"
          icon={<SquarePen size={16} />}
          disabled={
            !!pendingChangeRequest ||
            amazonListing.internalStatus === InternalAmazonListingStatus.REVIEW ||
            amazonListing.internalStatus === InternalAmazonListingStatus.DELETED ||
            amazonListing.externalStatus === ExternalAmazonListingStatus.DETAIL_PAGE_REMOVED
          }
          onClick={openModal}
        />
      </Tooltip>
      <Modal
        title={t('product.details.changeRequest.content.modal.title')}
        width={800}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={handleOk}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={closeModal}
        confirmLoading={loading}
        destroyOnClose
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Form<EditAmazonListingContentFormInstance>
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="title"
              label={t('product.details.changeRequest.content.modal.form.title')}
              rules={[{ required: true, max: 200 }]}
            >
              <Input.TextArea rows={2} />
            </Form.Item>
            <Form.Item
              name="description"
              label={t('product.details.changeRequest.content.modal.form.description')}
              rules={[{ required: true, max: 2000 }]}
            >
              <Input.TextArea rows={6} />
            </Form.Item>
            <Form.Item
              name="bulletPoint1"
              label={t('product.details.changeRequest.content.modal.form.bulletPoint', {
                number: 1,
              })}
              rules={[
                { required: true, max: 500 },
                {
                  pattern: bulletPointRegExp,
                  message: t('product.details.amazonListings.validation.bulletPoints.pattern'),
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="bulletPoint2"
              label={t('product.details.changeRequest.content.modal.form.bulletPoint', {
                number: 2,
              })}
              rules={[
                { required: true, max: 500 },
                {
                  pattern: bulletPointRegExp,
                  message: t('product.details.amazonListings.validation.bulletPoints.pattern'),
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="bulletPoint3"
              label={t('product.details.changeRequest.content.modal.form.bulletPoint', {
                number: 3,
              })}
              rules={[
                { required: true, max: 500 },
                {
                  pattern: bulletPointRegExp,
                  message: t('product.details.amazonListings.validation.bulletPoints.pattern'),
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="bulletPoint4"
              label={t('product.details.changeRequest.content.modal.form.bulletPoint', {
                number: 4,
              })}
              rules={[
                { required: true, max: 500 },
                {
                  pattern: bulletPointRegExp,
                  message: t('product.details.amazonListings.validation.bulletPoints.pattern'),
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="bulletPoint5"
              label={t('product.details.changeRequest.content.modal.form.bulletPoint', {
                number: 5,
              })}
              rules={[
                { required: true, max: 500 },
                {
                  pattern: bulletPointRegExp,
                  message: t('product.details.amazonListings.validation.bulletPoints.pattern'),
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="searchTerms"
              label={t('product.details.changeRequest.content.modal.form.searchTerms')}
              rules={[{ required: true, max: 490 }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Form>
          <Alert type="warning" message={t('product.details.changeRequest.warning')} />
        </Space>
      </Modal>
    </>
  )
}
