import { List, Skeleton, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router'
import { AmazonProductFieldsFragment } from '../../../../generated/graphql'
import { sortByMarketplace } from '../../../../helpers/sortByMarketplace'
import { StyledMainCard } from '../../../dashboard/styles'
import { AmazonListingCard } from './AmazonListingCard'
import { AmazonProductPanEuModal } from './AmazonProductPanEuModal'
import { CreateAmazonListingModal } from './CreateAmazonListingModal'

type AmazonProductCardProps = {
  amazonProduct: AmazonProductFieldsFragment
}

export const AmazonProductCard = ({ amazonProduct }: AmazonProductCardProps) => {
  const { t } = useTranslation()

  const amazonListings = amazonProduct.amazonListings ?? []

  const sortedAmazonListings = [...amazonListings].sort((a, b) => {
    return sortByMarketplace(a.marketplace.name, b.marketplace.name)
  })

  const productUuid = amazonProduct.productVariant.product.uuid
  const productVariantUuid = amazonProduct.productVariant.uuid

  return (
    <StyledMainCard
      title={amazonProduct.asin}
      extra={
        <Space>
          <AmazonProductPanEuModal amazonProduct={amazonProduct} />
          <CreateAmazonListingModal amazonProduct={amazonProduct} />
        </Space>
      }
    >
      <List
        dataSource={sortedAmazonListings}
        renderItem={(amazonListing) => {
          return (
            <List.Item>
              <Link
                to={`/products/${productUuid}/variants/${productVariantUuid}/amazon/${amazonListing.uuid}`}
              >
                <AmazonListingCard amazonListing={amazonListing} />
              </Link>
            </List.Item>
          )
        }}
        grid={{ gutter: 16, column: 3, xs: 2 }}
        locale={{ emptyText: t('shared.noData') }}
        style={{ marginBottom: sortedAmazonListings.length ? -16 : 0 }}
      />
    </StyledMainCard>
  )
}

export const AmazonProductCardSkeleton = () => {
  return (
    <StyledMainCard title={<Skeleton active title={false} paragraph={{ rows: 1, width: '25%' }} />}>
      <Skeleton active title={false} paragraph={{ rows: 3, width: '100%' }} />
    </StyledMainCard>
  )
}
