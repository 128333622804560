import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { GetAllAmazonInboundShipmentDiscrepanciesDocument } from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { prepareAmazonInboundShipmentPlanDiscrepanciesData } from '../helpers/prepareAmazonInboundShipmentPlanDiscrepanciesData'
import { AmazonInboundShipmentPlanDiscrepanciesTable } from './AmazonInboundShipmentPlanDiscrepanciesTable'

export const AmazonInboundShipmentPlanDiscrepancies = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation()

  const { loading, error, data } = useQuery(GetAllAmazonInboundShipmentDiscrepanciesDocument, {
    errorPolicy: 'none',
    skip: !selectedCompany.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  if (error) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  const dataSource = data ? prepareAmazonInboundShipmentPlanDiscrepanciesData(data) : []

  return <AmazonInboundShipmentPlanDiscrepanciesTable dataSource={dataSource} loading={loading} />
}
