import styled from '@emotion/styled'

export const ProductListing = styled.div`
  display: flex;
  align-items: stretch;
  height: 82px;
  margin: 8px 8px 0px 8px;
  padding: 8px;
`

export const ProductImageThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  overflow: hidden;

  img {
    width: auto;
    height: 64px;
  }
`

export const MissingImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: ${({ theme }) => theme.colors.base[5]};
  overflow: hidden;
`

export const ProductSelectDescriptionContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  overflow: hidden;
`

export const ProductTitle = styled.div`
  position: relative;
  overflow: hidden;
  font-size: 11px;
  line-height: 16px;
  max-height: 32px;

  ::after {
    content: '';
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    bottom: 0;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  }
`

export const ProductSku = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ProductSelectActionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
`
