import { useQuery } from '@apollo/client'
import { Col, Flex, List, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import {
  GetWholesaleOrderDetailsDocument,
  GetWholesaleOrderDetailsQuery,
} from '../../generated/graphql'
import { StyledMainCard, StyledSubCard } from '../dashboard/styles'
import { WholesaleOrderBillingDocumentTypeTag } from './components/WholesaleOrderBillingDocumentTypeTag'
import { WholesaleOrderDetailsHeaderButtons } from './components/WholesaleOrderDetails/WholesaleOrderDetailsHeaderButtons'
import { WholesaleOrderGeneralDetailsCard } from './components/WholesaleOrderDetails/WholesaleOrderGeneralDetailsCard'
import {
  WholesaleOrderLineItemTable,
  WholesaleOrderLineItemTableRecord,
} from './components/WholesaleOrderDetails/WholesaleOrderLineItemTable/WholesaleOrderLineItemTable'
import { WholesaleOrderWarehouseDetailsCard } from './components/WholesaleOrderDetails/WholesaleOrderWarehouseDetailsCard'

export type WholesaleOrder = GetWholesaleOrderDetailsQuery['wholesaleOrder']
type WholesaleOrderLineItem = WholesaleOrder['lineItems'][number]
type SalesChannelRegion = WholesaleOrder['shippedToWarehouseLocation']['salesChannelRegion']

export const WholesaleOrderDetailsView = () => {
  const params = useParams<{ wholesaleOrderIdentifier: string }>()
  const { t } = useTranslation('wholesale-order')

  const { loading, error, data } = useQuery(GetWholesaleOrderDetailsDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    skip: !params.wholesaleOrderIdentifier,
    variables: {
      wholesaleOrderIdentifier: params.wholesaleOrderIdentifier!,
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error || !data?.wholesaleOrder) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const lineItems = data
    ? prepareWholesaleOrderLineItems(
        data.wholesaleOrder.lineItems,
        data.wholesaleOrder.shippedToWarehouseLocation.salesChannelRegion
      )
    : []

  const billingDocuments = data.wholesaleOrder.billingDocuments ?? []

  return (
    <>
      <SubHeader
        heading={data?.wholesaleOrder?.identifier || ''}
        withBackButton
        onBackUrl="/wholesale/orders"
        rightContent={
          <WholesaleOrderDetailsHeaderButtons
            wholesaleOrder={data.wholesaleOrder}
            lineItems={lineItems}
          />
        }
      />
      <ViewContainer>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <WholesaleOrderGeneralDetailsCard wholesaleOrder={data.wholesaleOrder} />
          </Col>
          <Col xs={24} xl={12}>
            <WholesaleOrderWarehouseDetailsCard wholesaleOrder={data.wholesaleOrder} />
          </Col>
          <Col xs={24}>
            <WholesaleOrderLineItemTable
              wholesaleOrder={data.wholesaleOrder}
              lineItems={lineItems}
            />
          </Col>
          <Col xs={24}>
            <StyledMainCard title={t('billingDocuments.heading')}>
              <List
                dataSource={billingDocuments}
                renderItem={(document) => (
                  <List.Item>
                    <StyledSubCard
                      title={
                        <Link to={`/wholesale/billing-documents/${document.identifier}`}>
                          {document.identifier}
                        </Link>
                      }
                      extra={<WholesaleOrderBillingDocumentTypeTag type={document.type} />}
                    >
                      <Flex vertical align="flex-start" gap={8}>
                        <Typography.Text ellipsis>{document.documentNumber}</Typography.Text>
                        <Typography.Text ellipsis>
                          {dayjs(document.issueDate).format('DD.MM.YYYY')}
                        </Typography.Text>
                      </Flex>
                    </StyledSubCard>
                  </List.Item>
                )}
                grid={{ gutter: 16, column: 4, xs: 1, sm: 1, md: 2, lg: 3 }}
                locale={{ emptyText: t('billingDocuments.empty') }}
                style={{ marginBottom: billingDocuments.length ? -16 : 0 }}
              />
            </StyledMainCard>
          </Col>
        </Row>
      </ViewContainer>
    </>
  )
}

function prepareWholesaleOrderLineItems(
  lineItems: WholesaleOrderLineItem[],
  salesChannelRegion: SalesChannelRegion
): WholesaleOrderLineItemTableRecord[] {
  if (!lineItems.length) {
    return []
  }

  return lineItems.flatMap((lineItem) => {
    const skus = new Set<string>()
    const asins = new Set<string>()
    const attributes = new Set<string>()

    const amazonProducts = lineItem.productVariant.amazonProducts ?? []

    for (const amazonProduct of amazonProducts) {
      if (amazonProduct.amazonAccount.name !== 'Varento') {
        continue
      }

      asins.add(amazonProduct.asin)

      const amazonListings = amazonProduct.amazonListings ?? []

      for (const amazonListing of amazonListings) {
        if (!salesChannelRegion) {
          continue
        }

        const marketplace = amazonListing.marketplace.name
        const sku = amazonListing.sku

        if (salesChannelRegion.countries.includes(marketplace)) {
          skus.add(sku)
        }
      }
    }

    if (lineItem.productVariant.attributes) {
      for (const attribute of lineItem.productVariant.attributes) {
        attributes.add(`${attribute.option}: ${attribute.value}`)
      }
    }

    return {
      identifier: lineItem.identifier,
      product: {
        uuid: lineItem.productVariant.product.uuid,
        name: lineItem.productVariant.product.name,
        tags: lineItem.productVariant.product.tags ?? [],
      },
      productVariant: {
        uuid: lineItem.productVariant.uuid,
        identifier: lineItem.productVariant.identifier,
        ean: lineItem.productVariant.ean ?? null,
        internalSku: lineItem.productVariant.internalSku ?? null,
        attributes: Array.from(attributes),
      },
      asins: Array.from(asins),
      skus: Array.from(skus),
      packagingUnit: {
        uuid: lineItem.packagingUnit.uuid,
        quantity: lineItem.packagingUnit.quantity,
        lengthInCm: lineItem.packagingUnit.lengthInCm ?? null,
        widthInCm: lineItem.packagingUnit.widthInCm ?? null,
        heightInCm: lineItem.packagingUnit.heightInCm ?? null,
        weightInGram: lineItem.packagingUnit.weightInGram ?? null,
      },
      quantityOfPackagingUnits: lineItem.quantityOfPackagingUnits,
      totalQuantity: lineItem.totalQuantity,
      netPurchasePrice: lineItem.netPurchasePrice,
      currency: lineItem.currency,
      priceDifference: lineItem.priceDifference ?? null,
      netTotal: lineItem.totalQuantity * lineItem.netPurchasePrice,
    }
  })
}
