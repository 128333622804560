import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { CompanyStockDocument } from '../../generated/graphql'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { StockTable } from './components/StockTable'
import { prepareStockData } from './helpers/prepareStockData'

export const StockView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('inventory', { keyPrefix: 'stock' })

  const { data, error } = useQuery(CompanyStockDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    skip: !selectedCompany.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  if (error) {
    return <StatusResult status="500" title="500" subTitle={t('error.message')} />
  }

  const dataSource = data ? prepareStockData(data) : undefined

  return (
    <>
      <SubHeader heading={t('heading')} />
      <ViewContainer>
        <StockTable data={dataSource} />
      </ViewContainer>
    </>
  )
}
