import { useMutation } from '@apollo/client'
import { Button, Col, Descriptions, Flex, Popconfirm, Row, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { Trash } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  DeleteSalePriceDocument,
  GetAmazonListingQuery,
  InternalAmazonListingStatus,
} from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { formatPercentage } from '../../../../helpers/formatPercentage'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { descriptionsStyle } from '../../../products/components/styles'
import { AmazonListingExternalStatusTag } from './AmazonListingExternalStatusTag'
import { AmazonListingInternalStatusTag } from './AmazonListingInternalStatusTag'
import { DeleteAmazonListingModal } from './DeleteAmazonListingModal'
import { EditAmazonListingPriceModal } from './EditAmazonListingPriceModal'
import { EditAmazonListingSalePriceModal } from './EditAmazonListingSalePriceModal'
import { EditAmazonListingSubscribeAndSaveModal } from './EditAmazonListingSubscribeAndSaveModal/EditAmazonListingSubscribeAndSaveModal'

type AmazonListingInformationProps = {
  amazonListing: GetAmazonListingQuery['amazonListing']
}

export const AmazonListingInformation = ({ amazonListing }: AmazonListingInformationProps) => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation(['inventory', 'translation'])

  const [deleteSalePrice] = useMutation(DeleteSalePriceDocument)

  const onConfirm = async () => {
    setLoading(true)

    try {
      const { data } = await deleteSalePrice({
        variables: { uuid: amazonListing.uuid },
        optimisticResponse: {
          deleteSalePrice: { uuid: amazonListing.uuid },
        },
        update: (cache, { data }) => {
          const deletedUuid = data?.deleteSalePrice?.uuid
          if (!deletedUuid) return

          const cacheId = cache.identify({ __typename: 'AmazonListing', uuid: deletedUuid })

          cache.modify({
            id: cacheId,
            fields: {
              salePrice: () => null,
              saleStartDate: () => null,
              saleEndDate: () => null,
            },
          })
        },
      })

      if (!data?.deleteSalePrice) {
        toast.error(t('shared.error.message', { ns: 'translation' }))
      }

      toast.error(t('product.details.amazonListings.salePriceDeletion.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Flex vertical gap={16}>
      <Descriptions
        {...descriptionsStyle}
        title={t('product.details.amazonListings.information')}
        extra={
          <Button
            type="link"
            size="small"
            href={`https://amazon.${getMarketplaceDomain(amazonListing.marketplace.name)}/dp/${
              amazonListing.amazonProduct.asin
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('product.details.amazonListings.amazonButton')}
          </Button>
        }
      >
        <Descriptions.Item label={t('product.details.amazonListings.internalStatus')}>
          <AmazonListingInternalStatusTag internalStatus={amazonListing.internalStatus} />
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonListings.externalStatus')}>
          <AmazonListingExternalStatusTag externalStatus={amazonListing.externalStatus} />
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonProduct.asin')}>
          {amazonListing.amazonProduct.asin}
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonProduct.subscribeAndSave.title')}>
          <Flex justify={'space-between'} align={'center'} style={{ width: '100%' }}>
            <Typography.Text>
              {!amazonListing.amazonProduct.subscribeAndSave
                ? t('product.details.amazonProduct.subscribeAndSave.disabled')
                : formatPercentage(amazonListing.amazonProduct.subscribeAndSave / 100)}
            </Typography.Text>
            <EditAmazonListingSubscribeAndSaveModal
              subscribeAndSaveValue={amazonListing.amazonProduct.subscribeAndSave}
              amazonProductUuid={amazonListing.amazonProduct.uuid}
              internalStatus={amazonListing.internalStatus}
              externalStatus={amazonListing.externalStatus}
            />
          </Flex>
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonListings.sku')}>
          {amazonListing.sku}
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonListings.fnsku')}>
          {amazonListing.fnsku ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonListings.labelType')}>
          {amazonListing.labelType ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonListings.marketplace')}>
          Amazon.{getMarketplaceDomain(amazonListing.marketplace.name)}
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonListings.recommendedRetailPrice')}>
          <Row justify="space-between">
            <Col flex="auto">
              {formatMoney(
                amazonListing.recommendedRetailPrice ?? 0,
                amazonListing.marketplace.currency
              )}
            </Col>
            <Col>
              <EditAmazonListingPriceModal amazonListing={amazonListing} />
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonListings.salePrice')}>
          {amazonListing.recommendedRetailPrice &&
          amazonListing.salePrice &&
          amazonListing.saleEndDate &&
          dayjs(amazonListing.saleEndDate).endOf('day').isAfter(dayjs()) ? (
            <Flex vertical gap={8} wrap>
              <Flex align="center" justify="space-between" gap={8}>
                <Typography.Text>
                  {formatMoney(amazonListing.salePrice, amazonListing.marketplace.currency)}
                </Typography.Text>
                <Flex gap={4}>
                  <Popconfirm
                    placement="top"
                    title={t('product.details.amazonListings.popConfirm.title')}
                    onConfirm={onConfirm}
                    okText={t('product.details.amazonListings.popConfirm.confirmButton')}
                    cancelText={t('product.details.amazonListings.popConfirm.cancelButton')}
                    okButtonProps={{ danger: true }}
                    cancelButtonProps={{ type: 'text' }}
                  >
                    <Tooltip title={t('product.details.amazonListings.popConfirm.tooltip')}>
                      <Button
                        type="text"
                        danger
                        icon={<Trash size={16} />}
                        disabled={loading}
                        size="small"
                      />
                    </Tooltip>
                  </Popconfirm>
                  <EditAmazonListingSalePriceModal
                    amazonListing={{
                      uuid: amazonListing.uuid,
                      recommendedRetailPrice: amazonListing.recommendedRetailPrice,
                      currency: amazonListing.marketplace.currency,
                      internalStatus: amazonListing.internalStatus,
                      externalStatus: amazonListing.externalStatus,
                      changeRequests: amazonListing.changeRequests ?? [],
                      saleEndDate: amazonListing.saleEndDate ?? null,
                    }}
                  />
                </Flex>
              </Flex>
              <Typography.Text type="secondary">
                {`${dayjs(amazonListing.saleStartDate).format('DD.MM.YYYY')} - ${dayjs(
                  amazonListing.saleEndDate
                ).format('DD.MM.YYYY')}`}
              </Typography.Text>
            </Flex>
          ) : (
            <Flex justify="space-between" gap={8}>
              <Typography.Text>-</Typography.Text>
              {amazonListing.recommendedRetailPrice && (
                <EditAmazonListingSalePriceModal
                  amazonListing={{
                    uuid: amazonListing.uuid,
                    recommendedRetailPrice: amazonListing.recommendedRetailPrice,
                    currency: amazonListing.marketplace.currency,
                    internalStatus: amazonListing.internalStatus,
                    externalStatus: amazonListing.externalStatus,
                    changeRequests: amazonListing.changeRequests ?? [],
                    saleEndDate: amazonListing.saleEndDate ?? null,
                  }}
                />
              )}
            </Flex>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonListings.mainCategory')}>
          {amazonListing.mainCategory ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonListings.isBuyBoxWinner.title')}>
          {amazonListing.isBuyBoxWinner
            ? t('product.details.amazonListings.isBuyBoxWinner.yes')
            : t('product.details.amazonListings.isBuyBoxWinner.no')}
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.amazonListings.isFbm.title')}>
          {amazonListing.isFbm
            ? t('product.details.amazonListings.isFbm.yes')
            : t('product.details.amazonListings.isFbm.no')}
        </Descriptions.Item>
      </Descriptions>
      {amazonListing.internalStatus !== InternalAmazonListingStatus.DELETED && (
        <Flex justify="end">
          <DeleteAmazonListingModal amazonListing={amazonListing} />
        </Flex>
      )}
    </Flex>
  )
}
