import { Button, Modal, Alert } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GetInvoiceArticleCategoriesDocument } from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { InvoiceArticleCategoryTable } from './InvoiceArticleCategoryTable'

export const InvoiceArticleCategoriesModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(['invoices', 'translation'])
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { error, data, loading } = useQuery(GetInvoiceArticleCategoriesDocument, {
    skip: !selectedCompany.uuid || !isOpen,
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const modalContent = () => {
    if (loading) return <LoadingSpinner />
    if (error) {
      return (
        <StatusResult status="500" title="500" subTitle={t('translation:shared.error.message')} />
      )
    }
    return (
      <>
        <Alert
          message={t('invoices:categories.uppercaseNotice')}
          type="info"
          showIcon
          style={{ marginBottom: 16 }}
        />
        <InvoiceArticleCategoryTable categories={data?.invoiceArticleCategories ?? []} />
      </>
    )
  }

  return (
    <>
      <Button type="primary" onClick={handleOpen}>
        {t('invoices:categories.manage')}
      </Button>
      <Modal
        title={t('invoices:categories.heading')}
        open={isOpen}
        onCancel={handleClose}
        width={1000}
        footer={null}
      >
        {modalContent()}
      </Modal>
    </>
  )
}
