import { TFunction } from 'i18next'
import { BusinessReportTableRecord } from './businessReports/components/BusinessReportTable/BusinessReportTable'
import { AmazonOrderReport, InventoryHealthReportData, InventoryReportData } from './interfaces'

export const getTranslatedKeys = (
  data:
    | AmazonOrderReport[]
    | InventoryReportData[]
    | BusinessReportTableRecord[]
    | InventoryHealthReportData[],
  reportKey: string,
  t: TFunction<['report', 'translation']>
) => {
  const formattedValues = data.flatMap((value) => {
    const keys = Object.keys(value)

    return keys.reduce((translatedValue, key) => {
      translatedValue[`${t(`${reportKey}.columns.${key}`)}`] = value[key]
      return translatedValue
    }, {})
  })
  return formattedValues
}
