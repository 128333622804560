import { ICellRendererParams } from 'ag-grid-community'
import { Tag } from 'antd'
import { BankTransaction } from '../../BankTransactionsView'
import {
  BankTransactionCategories,
  BankTransactionPaymentStatuses,
} from '../hooks/useBankTransactionTableColumns'

export const bankTransactionTableCellRenderers = {
  amount: (params: ICellRendererParams<BankTransaction>, locale: string) => {
    if (params.value && (params.value === 0 || params.value !== 'NaN')) {
      return (
        <p style={{ justifySelf: 'right', width: '100%' }}>
          {Number(params.value).toLocaleString(locale, { minimumFractionDigits: 2 })} €
        </p>
      )
    } else {
      return <p style={{ justifySelf: 'right', width: '100%' }}>Error</p>
    }
  },
  paymentStatus: (params: ICellRendererParams<BankTransaction>, t: any) => {
    if (params?.data?.paymentStatus) {
      const { paymentStatus } = params.data
      return (
        <Tag color={getPaymentStatusTagColor(paymentStatus)}>
          {t(`paymentStatus.${getPaymentStatusTranslationKey(paymentStatus)}`)}
        </Tag>
      )
    }
    return null
  },
  category: (params: ICellRendererParams<BankTransaction>, t: any) => {
    if (params?.data?.category) {
      const { category } = params.data
      return (
        <Tag color={getCategoryTagColor(category)}>
          {t(`category.${getCategoryTranslationKey(category)}`)}
        </Tag>
      )
    }
    return null
  },
}

const getPaymentStatusTagColor = (status: string) => {
  switch (status) {
    case BankTransactionPaymentStatuses.OPEN:
      return '#d9534f'
    case BankTransactionPaymentStatuses.PART2:
      return '#f0ad4e'
    case BankTransactionPaymentStatuses.CANCELLED:
      return '#5bc0de'
    case BankTransactionPaymentStatuses.CLARIFICATION:
      return '#0275d8'
    case BankTransactionPaymentStatuses.PAID:
      return '#5cb85c'
  }
}

const getPaymentStatusTranslationKey = (status: string) => {
  switch (status) {
    case BankTransactionPaymentStatuses.OPEN:
      return 'open'
    case BankTransactionPaymentStatuses.PART2:
      return 'openPartTwo'
    case BankTransactionPaymentStatuses.CANCELLED:
      return 'cancelled'
    case BankTransactionPaymentStatuses.CLARIFICATION:
      return 'inClarification'
    case BankTransactionPaymentStatuses.PAID:
      return 'paid'
  }
}

const getCategoryTagColor = (status: string) => {
  switch (status) {
    case BankTransactionCategories.NONE:
      return 'grey'
    case BankTransactionCategories.SETTLEMENT:
      return '#5bc0de'
    case BankTransactionCategories.SETTLEMENT_TRANSACTION:
      return '#0275d8'
  }
}

const getCategoryTranslationKey = (status: string) => {
  switch (status) {
    case BankTransactionCategories.NONE:
      return 'none'
    case BankTransactionCategories.SETTLEMENT:
      return 'settlement'
    case BankTransactionCategories.SETTLEMENT_TRANSACTION:
      return 'settlementTransaction'
  }
}
