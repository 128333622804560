import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const APP_VERSION = import.meta.env.VITE_APP_VERSION
const STORE_VERSION = Number(APP_VERSION.split('.').join(''))

export type DashboardConfigurationKey =
  | 'performanceOverview'
  | 'latestOrders'
  | 'performanceChart'
  | 'amazonAds'

export type DashboardProductIdentifier = 'productName' | 'internalSku' | 'amazonSku'

type DashboardConfiguration = {
  cards: Record<DashboardConfigurationKey, { active: boolean; order: number }>
  productIdentifier: DashboardProductIdentifier
}

type LayoutState = {
  dashboardConfiguration: DashboardConfiguration
  setDashboardConfiguration: (dashboardConfiguration: DashboardConfiguration) => void
}

const initialDashboardConfiguration: DashboardConfiguration = {
  cards: {
    performanceOverview: { active: true, order: 1 },
    latestOrders: { active: true, order: 2 },
    performanceChart: { active: true, order: 3 },
    amazonAds: { active: true, order: 4 },
  },
  productIdentifier: 'amazonSku',
}

export const useDashboardConfigurationStore = create<LayoutState>()(
  persist(
    (set) => ({
      dashboardConfiguration: initialDashboardConfiguration,
      setDashboardConfiguration: (dashboardConfiguration: DashboardConfiguration) =>
        set({ dashboardConfiguration }),
    }),
    {
      name: 'galaxy-dashboard-configuration',
      version: STORE_VERSION,
      migrate: (persistedState, version) => {
        if (version !== STORE_VERSION) {
          return {
            dashboardConfiguration: initialDashboardConfiguration,
          }
        }

        return persistedState
      },
    }
  )
)
