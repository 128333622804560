import { Descriptions, Flex, Tag, Tooltip, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { MoreItemsIndicator } from '../../../components/MoreItemsIndicator'
import { ProductVariantFieldsFragment } from '../../../generated/graphql'
import { StyledSubCard } from '../../dashboard/styles'

type ProductVariantCardProps = {
  productVariant: ProductVariantFieldsFragment
}

export const ProductVariantCard = ({ productVariant }: ProductVariantCardProps) => {
  const { t } = useTranslation('inventory')

  const amazonProducts = productVariant.amazonProducts ?? []
  const amazonListings = amazonProducts.flatMap(
    (amazonProduct) => amazonProduct.amazonListings ?? []
  )

  const attributes = productVariant.attributes ?? []
  const packagingUnits = productVariant.packagingUnits ?? []
  const asins = [...new Set(amazonProducts.map((amazonProduct) => amazonProduct.asin))]
  const skus = [...new Set(amazonListings.map((amazonListing) => amazonListing.sku))]

  return (
    <StyledSubCard
      title={t('product.details.productVariant.variant', {
        sequence: productVariant.sequence,
      })}
      hoverable
    >
      <Descriptions colon={false} column={1} size="small">
        <Descriptions.Item label={t('product.details.productVariant.attributes')}>
          <Flex gap={8} wrap>
            {attributes.length ? (
              attributes.map((attribute) => {
                return (
                  <Tooltip key={attribute.option} title={attribute.value}>
                    <Tag style={{ margin: 0 }}>{attribute.value}</Tag>
                  </Tooltip>
                )
              })
            ) : (
              <Typography.Text type="secondary">-</Typography.Text>
            )}
          </Flex>
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.productVariant.gtin')}>
          {packagingUnits[0]?.gtin ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.productVariant.internalSku')}>
          {productVariant.internalSku ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.productVariant.asin')}>
          <Flex gap={8} align="center">
            <Typography.Text>{asins[0]}</Typography.Text>
            {asins.length > 1 && <MoreItemsIndicator items={asins} />}
          </Flex>
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.productVariant.sku')}>
          <Flex gap={8} align="center">
            <Typography.Text>{skus[0]}</Typography.Text>
            {skus.length > 1 && <MoreItemsIndicator items={skus} />}
          </Flex>
        </Descriptions.Item>
        <Descriptions.Item label={t('product.details.productVariant.salesChannels')}>
          <Flex justify="flex-start" gap={8}>
            {amazonProducts.length ? (
              <Tag color="orange">Amazon</Tag>
            ) : (
              <Typography.Text type="secondary">
                {t('product.details.productVariant.noSalesChannels')}
              </Typography.Text>
            )}
          </Flex>
        </Descriptions.Item>
      </Descriptions>
    </StyledSubCard>
  )
}
