import { useTheme } from '@emotion/react'
import { ColDef, ColGroupDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community'
import { Flex, Tag, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router'
import { MoreItemsIndicator } from '../../../../../../../components/MoreItemsIndicator'
import { formatMoney } from '../../../../../../../helpers/formatMoney'
import { formatPercentage } from '../../../../../../../helpers/formatPercentage'
import { WholesaleOrderBillingDocumentLineItemTableRecord } from '../WholesaleOrderBillingDocumentLineItemsTable'

export const useWholesaleOrderBillingDocumentLineItemsTableColumnDefs = () => {
  const theme = useTheme()

  const { t } = useTranslation('wholesale-order-billing-document', {
    keyPrefix: 'wholesaleOrderBillingDocumentLineItemsTable.columnDefs',
  })

  const [columnDefs] = useState<
    (
      | ColDef<WholesaleOrderBillingDocumentLineItemTableRecord>
      | ColGroupDef<WholesaleOrderBillingDocumentLineItemTableRecord>
    )[]
  >([
    {
      field: 'position',
      headerName: t('position'),
      filter: 'agNumberColumnFilter',
      sort: 'asc',
    },
    {
      field: 'wholesaleOrderBillingDocumentLineItemIdentifier',
      headerName: t('wholesaleOrderBillingDocumentLineItemIdentifier'),
    },
    {
      field: 'wholesaleOrderLineItemIdentifier',
      headerName: t('wholesaleOrderLineItemIdentifier'),
    },
    {
      field: 'product.name',
      headerName: t('productName'),
      filter: 'agTextColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['product']['name']
        >
      ) => {
        if (!params.data) {
          return null
        }

        const productUuid = params.data.product.uuid

        return (
          <Link to={`/products/${productUuid}`}>
            <Typography.Link>{params.data.product.name}</Typography.Link>
          </Link>
        )
      },
    },
    {
      field: 'product.tags',
      headerName: t('tags'),
      hide: true,
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['product']['tags']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }

        return params.value
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['product']['tags']
        >
      ) => {
        const tags = params.value

        if (!tags) {
          return null
        }

        const totalQuantity = tags.length

        if (totalQuantity === 0) {
          return null
        }

        return tags.map((item) => {
          return <Tag key={item}>{item}</Tag>
        })
      },
    },
    {
      field: 'productVariant.attributes',
      headerName: t('attribute'),
      hide: true,
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['productVariant']['attributes']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }

        return params.value
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['productVariant']['attributes']
        >
      ) => {
        const attributes = params.value

        if (!attributes) {
          return null
        }

        const totalQuantity = attributes.length

        if (totalQuantity === 0) {
          return null
        }

        return attributes.map((item) => {
          return <Tag key={item}>{item}</Tag>
        })
      },
    },
    {
      field: 'productVariant.ean',
      headerName: t('ean'),
      hide: true,
    },
    {
      field: 'productVariant.internalSku',
      headerName: t('internalSku'),
    },
    {
      field: 'asins',
      headerName: t('asins'),
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['asins']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }

        return params.value
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['asins']
        >
      ) => {
        const asins = params.value

        if (!asins) {
          return null
        }

        const totalQuantity = asins.length

        if (totalQuantity === 0) {
          return null
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{asins[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={asins} />}
          </Flex>
        )
      },
    },
    {
      field: 'skus',
      headerName: t('skus'),
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['skus']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }

        return params.value
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['skus']
        >
      ) => {
        const skus = params.value

        if (!skus) {
          return null
        }

        const totalQuantity = skus.length

        if (totalQuantity === 0) {
          return null
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{skus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={skus} />}
          </Flex>
        )
      },
    },
    {
      field: 'quantity',
      headerName: t('quantity'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['quantity']
        >
      ) => {
        const value = params.value

        if (value === null || value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Typography.Text
            style={{ color: params.node.aggData ? theme.colors.primary : undefined }}
          >
            {value}
          </Typography.Text>
        )
      },
    },
    {
      field: 'netPurchasePrice',
      headerName: t('netPurchasePrice'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['netPurchasePrice']
        >
      ) => {
        const value = params.value
        const currency = params.data?.currency

        if (value === null || value === undefined || !currency) {
          return null
        }

        return <Typography.Text>{formatMoney(value, currency)}</Typography.Text>
      },
    },
    {
      field: 'netTotal',
      headerName: t('netTotal'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['netTotal']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Typography.Text
            style={{ color: params.node.aggData ? theme.colors.primary : undefined }}
          >
            {formatMoney(params.value, 'EUR')}
          </Typography.Text>
        )
      },
    },
    {
      field: 'taxRate',
      headerName: t('taxRate'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['taxRate']
        >
      ) => {
        const value = params.value

        if (value === null || value === undefined) {
          return null
        }

        return <Typography.Text>{formatPercentage(value / 100)}</Typography.Text>
      },
    },
    {
      field: 'tax',
      headerName: t('tax'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['tax']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Typography.Text
            style={{ color: params.node.aggData ? theme.colors.primary : undefined }}
          >
            {formatMoney(params.value, 'EUR')}
          </Typography.Text>
        )
      },
    },
    {
      field: 'grossTotal',
      headerName: t('grossTotal'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderBillingDocumentLineItemTableRecord,
          WholesaleOrderBillingDocumentLineItemTableRecord['grossTotal']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Typography.Text
            style={{ color: params.node.aggData ? theme.colors.primary : undefined }}
          >
            {formatMoney(params.value, 'EUR')}
          </Typography.Text>
        )
      },
    },
  ])

  return columnDefs
}
