import { List, Popover, Typography } from 'antd'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type MoreItemsIndicatorProps<T> = {
  items: T[]
  renderItem?: (item: T, index: number) => ReactNode
}

export const MoreItemsIndicator = <T,>({ items, renderItem }: MoreItemsIndicatorProps<T>) => {
  const { t } = useTranslation()

  return (
    <Popover
      content={
        <List
          dataSource={items}
          renderItem={(item, index) => (
            <List.Item>
              <Typography.Text type={index === 0 ? 'secondary' : undefined}>
                {renderItem ? renderItem(item, index) : String(item)}
              </Typography.Text>
            </List.Item>
          )}
          bordered
          size="small"
          split={false}
        />
      }
      trigger="hover"
      placement="bottom"
      overlayInnerStyle={{ padding: 0 }}
    >
      <Typography.Text type="secondary">
        {t('shared.more', { count: items.length - 1 })}
      </Typography.Text>
    </Popover>
  )
}
