/**
 * Triggers the download of a file encoded in base64 format.
 * @param {string} base64 The base64 encoded content of the file.
 * @param {string} mimeType The MIME type of the file.
 * @param {string} fileName The name of the file to be downloaded.
 */
export function downloadBase64File(base64: string, mimeType: string, fileName: string) {
  try {
    // Convert base64 to Blob.
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const fileBlob = new Blob([byteArray], { type: mimeType })

    // Create a URL for the Blob.
    const blobUrl = URL.createObjectURL(fileBlob)

    // Create and trigger a download link.
    const element = document.createElement('a')
    element.setAttribute('href', blobUrl)
    element.setAttribute('download', fileName)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)

    // Clean up by revoking the Blob URL.
    URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error('Error downloading base64 file:', error)
  }
}
