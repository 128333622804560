import {
  FileExcelTwoTone,
  FilePdfTwoTone,
  FileTextTwoTone,
  FileUnknownTwoTone,
  FileWordTwoTone,
} from '@ant-design/icons'
import { Flex, Image, Spin } from 'antd'
import { Eye } from 'lucide-react'
import { downloadFile } from '../helpers/downloadFile'
import { getFilenameFromKey } from '../helpers/getFilenameFromKey'
import { getFileTypeFromContentType } from '../helpers/getFileTypeFromContentType'
import { handleView } from '../helpers/handleView'

type ImagePreviewProps = {
  fileUrl: string | null
  loading: boolean
  contentType: string | null
  customGetFilenameFromKey?: (key: string) => string
}

const imagePreviewSize = 54
const documentPreviewSize = 32

export const S3ImagePreview = ({
  fileUrl,
  loading,
  contentType,
  customGetFilenameFromKey,
}: ImagePreviewProps) => {
  if (loading) {
    return (
      <Flex
        style={{
          width: imagePreviewSize,
          height: imagePreviewSize,
        }}
        justify={'center'}
        align={'center'}
      >
        <Spin />
      </Flex>
    )
  }

  const fileType = getFileTypeFromContentType(contentType)

  if (fileUrl) {
    if (fileType === 'image') {
      return (
        <Image
          style={{ borderRadius: 4 }}
          src={fileUrl}
          height={imagePreviewSize}
          width={imagePreviewSize}
          preview={{ mask: <Eye size={16} /> }}
        />
      )
    } else if (fileType === 'pdf') {
      return (
        <FilePdfTwoTone
          style={{ fontSize: documentPreviewSize }}
          twoToneColor={'#f40f02'}
          onClick={() => handleView(fileUrl)}
        />
      )
    } else if (fileType === 'excel') {
      return (
        <FileExcelTwoTone
          style={{ fontSize: documentPreviewSize }}
          twoToneColor={'#63BE7B'}
          onClick={() =>
            downloadFile(
              fileUrl,
              customGetFilenameFromKey
                ? customGetFilenameFromKey(fileUrl)
                : getFilenameFromKey(fileUrl)
            )
          }
        />
      )
    } else if (fileType === 'word') {
      return (
        <FileWordTwoTone
          style={{ fontSize: documentPreviewSize }}
          twoToneColor={'#2b579a'}
          onClick={() =>
            downloadFile(
              fileUrl,
              customGetFilenameFromKey
                ? customGetFilenameFromKey(fileUrl)
                : getFilenameFromKey(fileUrl)
            )
          }
        />
      )
    } else if (fileType === 'text') {
      return (
        <FileTextTwoTone
          style={{ fontSize: documentPreviewSize }}
          twoToneColor={'#aaa'}
          onClick={() =>
            downloadFile(
              fileUrl,
              customGetFilenameFromKey
                ? customGetFilenameFromKey(fileUrl)
                : getFilenameFromKey(fileUrl)
            )
          }
        />
      )
    } else {
      return (
        <FileUnknownTwoTone
          style={{ fontSize: imagePreviewSize }}
          twoToneColor={'#aaa'}
          onClick={() =>
            downloadFile(
              fileUrl,
              customGetFilenameFromKey
                ? customGetFilenameFromKey(fileUrl)
                : getFilenameFromKey(fileUrl)
            )
          }
        />
      )
    }
  }
}
