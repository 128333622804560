import { AmazonReturnReason } from '../../../../generated/graphql'
import { formatPercentage } from '../../../../helpers/formatPercentage'
import { AmazonReturnTableRecord } from '../components/AmazonReturnTable'

export function prepareAmazonReturnOverview(amazonReturns: AmazonReturnTableRecord[]) {
  const totalAmazonReturns = amazonReturns.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0
  )

  const skus = amazonReturns.reduce<Partial<Record<string, number>>>(
    (previousValue, currentValue) => {
      previousValue[currentValue.sku] = (previousValue[currentValue.sku] ?? 0) + 1
      return previousValue
    },
    {}
  )

  const sortedSkus = Object.entries(skus)
    .sort((a, b) => (b[1] ?? 0) - (a[1] ?? 0))
    .slice(0, 3)

  const topSkusCount = sortedSkus.reduce(
    (previousValue, currentValue) => previousValue + (currentValue[1] ?? 0),
    0
  )

  const topSkus = sortedSkus.map(([sku, count]) => ({
    sku,
    count,
    proportion: Math.round(((count ?? 0) / topSkusCount) * 100),
    percentage: formatPercentage((count ?? 0) / totalAmazonReturns),
  }))

  const reasons = amazonReturns.reduce<Partial<Record<AmazonReturnReason, number>>>(
    (previousValue, currentValue) => {
      previousValue[currentValue.reason] = (previousValue[currentValue.reason] ?? 0) + 1
      return previousValue
    },
    {}
  )

  const sortedReasons = Object.entries(reasons)
    .filter(([reason]) => reason !== AmazonReturnReason.NO_REASON_GIVEN)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 3)

  const topReasonsCount = sortedReasons.reduce(
    (previousValue, currentValue) => previousValue + currentValue[1],
    0
  )

  const topReasons = sortedReasons.map(([reason, count]) => ({
    reason,
    count,
    proportion: Math.round((count / topReasonsCount) * 100),
    percentage: formatPercentage(count / totalAmazonReturns),
  }))

  return { topSkus, topReasons }
}
