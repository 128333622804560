import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { WarehouseServiceProviderLocationFieldsFragment } from '../../../../generated/graphql'
import { AmazonInboundShipmentPlanCreateFormInstance } from './AmazonInboundShipmentPlanCreateForm'

type AmazonShipmentPlanShipFromLocationFormFieldProps = {
  locations: WarehouseServiceProviderLocationFieldsFragment[]
}

export const AmazonShipmentPlanShipFromLocationFormField = ({
  locations,
}: AmazonShipmentPlanShipFromLocationFormFieldProps) => {
  const { t } = useTranslation('inventory')

  return (
    <Form.Item<AmazonInboundShipmentPlanCreateFormInstance>
      name="shipFromLocationUuid"
      label={t('amazonInboundShipmentPlan.create.form.inventory.shipFrom')}
      rules={[{ required: true }]}
    >
      <Select<string>
        options={locations.map((location) => ({
          value: location.uuid,
          label: `${location.name}, ${location.addressLine1}, ${location.postalCode}, ${location.city}, ${location.countryCode}`,
        }))}
        placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
        autoFocus
        showSearch
        filterOption={true}
        optionFilterProp="label"
      />
    </Form.Item>
  )
}
