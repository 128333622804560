import { Form } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../../../components/Layout/SubHeader/SubHeader'
import {
  CreateWholesaleOrderBillingDocumentFormValues,
  WholesaleOrderBillingDocumentCreateForm,
} from './WholesaleOrderBillingDocumentCreateForm/WholesaleOrderBillingDocumentCreateForm'
import { WholesaleOrderBillingDocumentCreateFormSummaryModal } from './WholesaleOrderBillingDocumentCreateForm/WholesaleOrderBillingDocumentCreateFormSummary/WholesaleOrderBillingDocumentCreateFormSummaryModal'

export const WholesaleOrderBillingDocumentCreateView = () => {
  const [submitting, setSubmitting] = useState(false)

  const { t } = useTranslation('wholesale-order-billing-document')
  const [form] = Form.useForm<CreateWholesaleOrderBillingDocumentFormValues>()

  return (
    <>
      <SubHeader
        heading={t('wholesaleOrderBillingDocumentCreateView.heading')}
        withBackButton
        rightContent={
          <WholesaleOrderBillingDocumentCreateFormSummaryModal
            form={form}
            submitting={submitting}
          />
        }
      />
      <ViewContainer>
        <WholesaleOrderBillingDocumentCreateForm form={form} setSubmitting={setSubmitting} />
      </ViewContainer>
    </>
  )
}
