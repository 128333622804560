import { Marketplace } from '../generated/graphql'
import { sortByMarketplace } from '../helpers/sortByMarketplace'
import { useGlobalStore } from '../stores/useGlobalStore'

export function useMarketplaceOptions(): Marketplace[] {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  if (!selectedCompany?.marketplaces) {
    return []
  }

  return selectedCompany.marketplaces.sort((a, b) => sortByMarketplace(a, b))
}
