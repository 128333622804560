import { useParams } from 'react-router'
import { Marketplace } from '../../../generated/graphql'

export function useCurrencySymbol() {
  const params = useParams<{ marketplace: string }>()
  const marketplace = params.marketplace?.toUpperCase()! as Marketplace

  return getCurrencySymbolFromMarketplace(marketplace)
}

function getCurrencySymbolFromMarketplace(marketplace: Marketplace) {
  switch (marketplace) {
    case Marketplace.DE:
      return '€'
    case Marketplace.ES:
      return '€'
    case Marketplace.FR:
      return '€'
    case Marketplace.IT:
      return '€'
    case Marketplace.NL:
      return '€'
    case Marketplace.PL:
      return 'zł '
    case Marketplace.SE:
      return 'kr '
    case Marketplace.UK:
      return '£'
    case Marketplace.BE:
      return '€'
    case Marketplace.US:
      return '$'
    case Marketplace.CA:
      return '$'
    case Marketplace.MX:
      return '$'
    default:
      throw new Error('Unexpected marketplace ' + marketplace)
  }
}
