import { Marketplace } from '../generated/graphql'

export const getMarketplaceDomain = (marketplace: Marketplace) => {
  switch (marketplace) {
    case Marketplace.DE:
      return 'de'
    case Marketplace.FR:
      return 'fr'
    case Marketplace.ES:
      return 'es'
    case Marketplace.IT:
      return 'it'
    case Marketplace.NL:
      return 'nl'
    case Marketplace.UK:
      return 'co.uk'
    case Marketplace.SE:
      return 'se'
    case Marketplace.PL:
      return 'pl'
    case Marketplace.BE:
      return 'com.be'
    case Marketplace.US:
      return 'com'
    case Marketplace.CA:
      return 'ca'
    case Marketplace.MX:
      return 'com.mx'
    default:
      return null
  }
}
