import { useMutation, useQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  GridApi,
  ICellRendererParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Button, Popconfirm, Space } from 'antd'
import dayjs from 'dayjs'
import { Pen, Plus, Save, Trash } from 'lucide-react'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { FormattedDate } from '../../../../components/FormattedDate'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import { Table } from '../../../../components/Table/Table'
import {
  CreateInvoiceArticleCategoryBookingAccountsDocument,
  DeleteInvoiceArticleCategoryBookingAccountDocument,
  GetInvoiceArticleCategoryBookingAccountsDocument,
  UpdateInvoiceArticleCategoryBookingAccountsDocument,
} from '../../../../generated/graphql'
import { formatPercentage } from '../../../../helpers/formatPercentage'
import { useCountryOptions } from '../../../../hooks/useCountryOptions'

export type BookingAccountRecord = {
  uuid: string
  bookingAccountNumber: number
  countries: string[]
  taxRate: number
  createdAt: string
  updatedAt: string
}

type BookingAccountsTableProps = {
  categoryUuid: string
  bookingAccounts?: BookingAccountRecord[]
}

export const BookingAccountsTable = ({ categoryUuid }: BookingAccountsTableProps) => {
  const { t } = useTranslation(['translation', 'invoices'])
  const theme = useTheme()
  const gridRef = useRef<AgGridReact<BookingAccountRecord>>(null)
  const countryOptions = useCountryOptions()

  const [rowData, setRowData] = useState<BookingAccountRecord[]>([])

  const { data, loading, error, refetch } = useQuery(
    GetInvoiceArticleCategoryBookingAccountsDocument,
    {
      variables: { categoryUuid },
      skip: !categoryUuid || categoryUuid.startsWith('temp-'),
    }
  )

  const [updateBookingAccount] = useMutation(UpdateInvoiceArticleCategoryBookingAccountsDocument, {
    onCompleted: () => {
      refetch()
      toast.success(t('invoices:categories.bookingAccounts.update.success'))
    },
    onError: (error) => {
      console.error(error)
      toast.error(t('invoices:categories.bookingAccounts.update.error'))
    },
  })

  const [createBookingAccount] = useMutation(CreateInvoiceArticleCategoryBookingAccountsDocument, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      console.error(error)
      toast.error(t('invoices:categories.bookingAccounts.save.error'))
    },
  })

  const [deleteBookingAccount] = useMutation(DeleteInvoiceArticleCategoryBookingAccountDocument, {
    onCompleted: () => {
      refetch()
      toast.success(t('invoices:categories.bookingAccounts.delete.success'))
    },
    onError: (error) => {
      console.error(error)
      toast.error(t('invoices:categories.bookingAccounts.delete.error'))
    },
  })

  const bookingAccounts = useMemo(() => {
    return data?.invoiceArticleCategoryBookingAccounts || []
  }, [data])

  useEffect(() => {
    if (bookingAccounts.length > 0 || !loading) {
      const mutableBookingAccounts = bookingAccounts.map((account) => ({
        ...account,
        countries: [...account.countries],
      }))

      setRowData(mutableBookingAccounts)
    }
  }, [bookingAccounts, loading])

  const onCellValueChanged = useCallback(
    async (event: CellValueChangedEvent<BookingAccountRecord>) => {
      if (!event) return

      const field = event.colDef.field as keyof BookingAccountRecord
      if (!field) return

      const newValue = event.newValue
      const oldValue = event.oldValue

      if (newValue === oldValue) return

      try {
        let value = newValue
        if (typeof newValue === 'string') {
          value = newValue.trim()
        }

        const updatedData = {
          ...event.data,
          countries: Array.isArray(event.data.countries) ? [...event.data.countries] : [],
          [field]: value,
        }

        event.api.applyTransaction({ update: [updatedData] })

        if (!event.data.uuid.startsWith('temp-')) {
          const mutationInput = {
            bookingAccountNumber:
              field === 'bookingAccountNumber' ? value : updatedData.bookingAccountNumber,
            countries: field === 'countries' ? value : updatedData.countries,
            taxRate: field === 'taxRate' ? value : updatedData.taxRate,
          }

          await updateBookingAccount({
            variables: {
              uuid: event.data.uuid,
              input: mutationInput,
            },
          })
        }
      } catch (error) {
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: oldValue }],
        })
        console.error(error)
      }
    },
    [updateBookingAccount]
  )

  const handleDelete = useCallback(
    async (uuid: string) => {
      try {
        await deleteBookingAccount({
          variables: { uuid },
        })
      } catch (error) {
        console.error(error)
      }
    },
    [deleteBookingAccount]
  )

  const createEmptyRow = useCallback(
    (): BookingAccountRecord => ({
      uuid: `temp-${nanoid()}`,
      bookingAccountNumber: 0,
      countries: [],
      taxRate: 0,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }),
    []
  )

  const handleAddEmptyRow = useCallback(() => {
    const api = gridRef.current?.api
    if (!api) return

    api.applyTransaction({ add: [createEmptyRow()] })
  }, [createEmptyRow])

  const handleSaveNewRows = useCallback(
    async (gridApi: GridApi<BookingAccountRecord>) => {
      try {
        const rowData: BookingAccountRecord[] = []
        gridApi.forEachNodeAfterFilterAndSort((node) => {
          if (node.data) {
            rowData.push(node.data)
          }
        })

        const newRows = rowData.filter((row) => row.uuid.startsWith('temp-'))

        if (newRows.length === 0) {
          toast.info(t('invoices:categories.bookingAccounts.save.noNewRows'))
          return
        }

        const isValid = newRows.every(
          (row) => row.bookingAccountNumber > 0 && row.countries.length > 0 && row.taxRate >= 0
        )

        if (!isValid) {
          toast.error(t('invoices:categories.bookingAccounts.save.invalidRows'))
          return
        }

        const createdAccounts = []
        for (const newRow of newRows) {
          const result = await createBookingAccount({
            variables: {
              invoiceArticleCategoryUuid: categoryUuid,
              input: {
                bookingAccountNumber: newRow.bookingAccountNumber,
                countries: newRow.countries,
                taxRate: newRow.taxRate,
              },
            },
          })

          if (result.data?.createInvoiceArticleBookingAccount) {
            createdAccounts.push(result.data.createInvoiceArticleBookingAccount)
          }
        }

        gridApi.applyTransaction({ remove: newRows })

        if (createdAccounts.length > 0) {
          gridApi.applyTransaction({ add: createdAccounts })
        }

        await refetch()

        toast.success(t('invoices:categories.bookingAccounts.save.success'))
      } catch (error) {
        console.error(error)
      }
    },
    [createBookingAccount, categoryUuid, t, refetch]
  )

  const getTempRowStyle = useCallback(
    (params: CellClassParams<BookingAccountRecord>) => {
      return params.data?.uuid?.startsWith('temp-')
        ? { backgroundColor: `${theme.colors.warning}20` }
        : { backgroundColor: 'initial' }
    },
    [theme.colors.warning]
  )

  const createEditCellRenderer = useCallback(
    (valueFormatter?: (value: number) => React.ReactNode) => {
      return (params: ICellRendererParams<BookingAccountRecord>) => {
        const displayValue = valueFormatter
          ? valueFormatter(params.value)
          : (params.value ?? t('shared.button.edit', { ns: 'translation' }))

        return (
          <Space>
            <Pen size={16} />
            {displayValue}
          </Space>
        )
      }
    },
    [t]
  )

  const columnDefs = useMemo<ColDef<BookingAccountRecord>[]>(
    () => [
      {
        lockPosition: 'left',
        filter: false,
        resizable: false,
        sortable: false,
        suppressColumnsToolPanel: true,
        suppressNavigable: true,
        suppressSizeToFit: true,
        minWidth: 96,
        maxWidth: 96,
        headerComponent: ActionHeader,
        headerComponentParams: {
          onAddEmptyRow: handleAddEmptyRow,
          onSaveNewRows: () => {
            const api = gridRef.current?.api
            if (api) handleSaveNewRows(api)
          },
          api: gridRef.current?.api,
        },
        cellRenderer: ({ data }: ICellRendererParams<BookingAccountRecord>) => {
          if (data?.uuid.startsWith('temp-')) {
            return (
              <Button
                icon={<Trash size={16} />}
                danger
                onClick={() => {
                  gridRef.current?.api?.applyTransaction({ remove: [data] })
                }}
              />
            )
          }

          return (
            <Popconfirm
              title={t('invoices:categories.bookingAccounts.delete.title')}
              description={t('invoices:categories.bookingAccounts.delete.description')}
              onConfirm={() => data?.uuid && handleDelete(data.uuid)}
              okText={t('invoices:categories.bookingAccounts.delete.okText')}
              cancelText={t('invoices:categories.bookingAccounts.delete.cancelText')}
            >
              <Button icon={<Trash size={16} />} disabled={!data?.uuid} />
            </Popconfirm>
          )
        },
      },
      {
        field: 'bookingAccountNumber',
        headerName: t('invoices:categories.bookingAccounts.table.bookingAccount'),
        filter: 'agNumberColumnFilter',
        editable: true,
        suppressPaste: true,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: { min: 1 },
        cellRenderer: createEditCellRenderer(),
        cellStyle: getTempRowStyle,
      },
      {
        field: 'taxRate',
        headerName: t('invoices:categories.bookingAccounts.table.taxRate'),
        filter: 'agNumberColumnFilter',
        editable: true,
        suppressPaste: true,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: { min: 0 },
        cellRenderer: createEditCellRenderer((value) => formatPercentage(value / 100)),
        cellStyle: getTempRowStyle,
      },
      {
        field: 'countries',
        headerName: t('invoices:categories.bookingAccounts.table.countries'),
        filter: 'agTextColumnFilter',
        editable: true,
        suppressPaste: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: countryOptions.map((country) => country.code),
          cellRenderer: (params: ICellRendererParams) => {
            const countryCode = params.value
            const countryName =
              countryOptions.find((country) => country.code === countryCode)?.name || countryCode
            return countryName
          },
          searchDebounceDelay: 500,
          multiSelect: true,
        },
        cellRenderer: (params: ICellRendererParams<BookingAccountRecord>) => {
          const countries = params.value || []

          if (countries.length === 0) {
            return (
              <Space>
                <Pen size={16} />
                {t('shared.button.edit', { ns: 'translation' })}
              </Space>
            )
          }

          return (
            <Space>
              <Pen size={16} />
              <Space size={[0, 4]} wrap>
                {countries.map((countryCode: string, index: number) => {
                  const countryName =
                    countryOptions.find((country) => country.code === countryCode)?.name ||
                    countryCode
                  return `${countryName}${index < countries.length - 1 ? ',' : ''}`
                })}
              </Space>
            </Space>
          )
        },
        cellStyle: getTempRowStyle,
      },
      {
        field: 'createdAt',
        hide: true,
        headerName: t('invoices:categories.bookingAccounts.table.createdAt'),
        filter: 'agDateColumnFilter',
        cellRenderer: (params: ICellRendererParams<BookingAccountRecord>) => (
          <FormattedDate date={params.value} withRelativeTime={true} />
        ),
      },
      {
        field: 'updatedAt',
        hide: true,
        headerName: t('invoices:categories.bookingAccounts.table.updatedAt'),
        filter: 'agDateColumnFilter',
        cellRenderer: (params: ICellRendererParams<BookingAccountRecord>) => (
          <FormattedDate date={params.value} withRelativeTime={true} />
        ),
      },
    ],
    [
      handleAddEmptyRow,
      handleSaveNewRows,
      handleDelete,
      t,
      getTempRowStyle,
      createEditCellRenderer,
      countryOptions,
    ]
  )

  if (loading && !rowData.length) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  return (
    <Table<BookingAccountRecord>
      ref={gridRef}
      columnDefs={columnDefs}
      rowData={rowData}
      onCellValueChanged={onCellValueChanged}
      defaultExcelExportParams={{
        fileName: `booking-accounts-${dayjs().format('DD-MM-YYYY')}`,
      }}
      getRowId={(params) => params.data.uuid}
      stopEditingWhenCellsLoseFocus={true}
    />
  )
}

const ActionHeader = ({
  onAddEmptyRow,
  onSaveNewRows,
  api,
}: {
  onAddEmptyRow: () => void
  onSaveNewRows: () => void
  api: GridApi<BookingAccountRecord> | undefined
}) => {
  const [hasNewRows, setHasNewRows] = useState(false)

  useEffect(() => {
    const checkForNewRows = () => {
      if (!api) return

      let newRowsExist = false
      api.forEachNode((node) => {
        if (node.data?.uuid?.startsWith('temp-')) {
          newRowsExist = true
        }
      })

      setHasNewRows(newRowsExist)
    }

    checkForNewRows()
    const intervalId = setInterval(checkForNewRows, 500)

    return () => clearInterval(intervalId)
  }, [api])

  return (
    <Space>
      <Button onClick={onAddEmptyRow} icon={<Plus size={16} />} type="primary" />
      <Button
        onClick={onSaveNewRows}
        icon={<Save size={16} />}
        type="primary"
        disabled={!hasNewRows}
      />
    </Space>
  )
}
