import { Popover, Space, Table, Typography } from 'antd'
import { CircleHelp } from 'lucide-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type AmazonListingInventoryInboundTableProps = {
  inboundInventory: InboundInventory
}

type InboundInventory = {
  inboundWorkingQuantity: number
  inboundShippedQuantity: number
  inboundReceivingQuantity: number
  totalQuantity: number
}

export const AmazonListingInventoryInboundTable = ({
  inboundInventory,
}: AmazonListingInventoryInboundTableProps) => {
  const {
    inboundWorkingQuantity,
    inboundShippedQuantity,
    inboundReceivingQuantity,
    totalQuantity,
  } = inboundInventory

  const { t } = useTranslation('inventory')

  const dataSource = useMemo(() => {
    return [
      {
        type: 'working',
        reason: Tooltip(
          t('product.details.inventory.inboundPopover.working', { ns: 'inventory' }),
          t('product.details.inventory.inboundPopover.workingPopover', { ns: 'inventory' })
        ),
        amount: inboundWorkingQuantity,
      },
      {
        type: 'shipped',
        reason: Tooltip(
          t('product.details.inventory.inboundPopover.shipped', { ns: 'inventory' }),
          t('product.details.inventory.inboundPopover.shippedPopover', { ns: 'inventory' })
        ),
        amount: inboundShippedQuantity,
      },
      {
        type: 'receiving',
        reason: Tooltip(
          t('product.details.inventory.inboundPopover.receiving', { ns: 'inventory' }),
          t('product.details.inventory.inboundPopover.receivingPopover', { ns: 'inventory' })
        ),
        amount: inboundReceivingQuantity,
      },
      {
        type: 'total',
        reason: t('product.details.inventory.inboundPopover.total', { ns: 'inventory' }),
        amount: totalQuantity,
      },
    ]
  }, [inboundWorkingQuantity, inboundShippedQuantity, inboundReceivingQuantity, totalQuantity])

  const columns = useMemo(() => {
    return [
      {
        title: t('product.details.inventory.inboundPopover.reasonColumn', { ns: 'inventory' }),
        dataIndex: 'reason',
      },
      {
        title: t('product.details.inventory.inboundPopover.amountColumn', { ns: 'inventory' }),
        dataIndex: 'amount',
      },
    ]
  }, [])

  return (
    <>
      <Typography.Paragraph>
        {t('product.details.inventory.inboundPopover.description', { ns: 'inventory' })}
      </Typography.Paragraph>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey="type"
        size="small"
      />
    </>
  )
}

const Tooltip = (reason: string, content: string) => (
  <Space>
    <Typography.Text>{reason}</Typography.Text>
    <Popover content={content} trigger="hover" placement="top">
      <CircleHelp size={16} />
    </Popover>
  </Space>
)
