import { useMutation } from '@apollo/client'
import { DeepPartial } from '@apollo/client/utilities'
import { Theme, useTheme } from '@emotion/react'
import {
  Alert,
  Button,
  Col,
  Flex,
  InputNumber,
  Modal,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import { SquarePen } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  AmazonListing,
  AmazonListingChangeRequestStatus,
  AmazonListingChangeRequestType,
  ExternalAmazonListingStatus,
  InternalAmazonListingStatus,
  Price,
  RequestAmazonListingChangeDocument,
} from '../../../../../generated/graphql'
import { formatMoney } from '../../../../../helpers/formatMoney'
import { roundNumber } from '../../../../../helpers/roundNumber'

const MAXIMUM_PRICE_INCREASE = 5

type EditAmazonListingPriceModalProps = {
  amazonListing: DeepPartial<AmazonListing>
}

export const EditAmazonListingPriceModal = ({
  amazonListing,
}: EditAmazonListingPriceModalProps) => {
  const [open, setOpen] = useState(false)
  const [newPrice, setNewPrice] = useState(amazonListing.recommendedRetailPrice ?? 0)
  const [difference, setDifference] = useState(0)
  const [submitting, setSubmitting] = useState(false)

  const { t } = useTranslation('inventory')
  const theme = useTheme()

  const [requestAmazonListingChange] = useMutation(RequestAmazonListingChangeDocument)

  useEffect(() => {
    setNewPrice(amazonListing.recommendedRetailPrice ?? 0)
    setDifference(0)
  }, [amazonListing])

  const pendingChangeRequest = amazonListing.changeRequests?.find(
    (changeRequest) =>
      changeRequest?.type === AmazonListingChangeRequestType.RECOMMENDED_RETAIL_PRICE &&
      changeRequest?.status === AmazonListingChangeRequestStatus.PENDING
  )

  const openModal = () => {
    setOpen(true)
  }

  const handleChange = (value: number | null) => {
    if (value) {
      setNewPrice(value)

      const oldValue = amazonListing.recommendedRetailPrice

      if (!oldValue) {
        return
      }

      setDifference(roundNumber(((value - oldValue) / oldValue) * 100))
    }
  }

  const handleSubmit = async () => {
    setSubmitting(true)

    try {
      if (!amazonListing.uuid) {
        throw new Error()
      }

      await requestAmazonListingChange({
        variables: {
          amazonListingUuid: amazonListing.uuid,
          input: {
            type: AmazonListingChangeRequestType.RECOMMENDED_RETAIL_PRICE,
            newRRP: { value: newPrice.toString() },
          },
        },
      })

      toast.success(t('shared.notification.success', { ns: 'translation' }), {
        description: t('product.details.changeRequest.success'),
      })
    } catch (error) {
      console.error(error)
      toast.error(t('shared.notification.error', { ns: 'translation' }), {
        description: t('product.details.changeRequest.error'),
      })
    }

    setSubmitting(false)
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const maximumPriceLimit = roundNumber(
    amazonListing.recommendedRetailPrice
      ? amazonListing.recommendedRetailPrice * (MAXIMUM_PRICE_INCREASE / 100 + 1)
      : 999999
  )
  const isValid = newPrice > 0 && newPrice <= maximumPriceLimit
  const newValues = pendingChangeRequest?.newValues as Price

  return (
    <>
      <Flex align="center" gap={8}>
        <Button
          type="text"
          icon={<SquarePen size={16} />}
          onClick={openModal}
          disabled={
            !!pendingChangeRequest ||
            amazonListing.internalStatus === InternalAmazonListingStatus.REVIEW ||
            amazonListing.internalStatus === InternalAmazonListingStatus.DELETED ||
            amazonListing.externalStatus === ExternalAmazonListingStatus.DETAIL_PAGE_REMOVED
          }
          size="small"
        />
        {pendingChangeRequest && (
          <Tooltip
            title={t('product.details.changeRequest.recommendedRetailPrice.tag.tooltip', {
              price: formatMoney(newValues.value ? +newValues.value : 0, 'EUR'),
              date: dayjs(pendingChangeRequest.updated_at).format('DD.MM.YYYY'),
            })}
            arrow
          >
            <Tag color="gold">
              {t('product.details.changeRequest.recommendedRetailPrice.tag.pending')}
            </Tag>
          </Tooltip>
        )}
      </Flex>
      <Modal
        title={t('product.details.changeRequest.recommendedRetailPrice.modal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        okButtonProps={{
          disabled: newPrice === amazonListing.recommendedRetailPrice || !isValid,
        }}
        onOk={handleSubmit}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={handleCancel}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row gutter={12} align="middle">
            <Col span={12}>
              <Typography.Text>
                {t('product.details.changeRequest.recommendedRetailPrice.modal.newValues')}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <InputNumber
                value={newPrice}
                onChange={handleChange}
                addonBefore={amazonListing.marketplace?.currency}
                autoFocus
                min={0}
                max={999999}
                precision={2}
                status={isValid ? undefined : 'error'}
                style={{ width: '100%' }}
              />

              {!isValid && (
                <Typography.Text style={{ color: 'red' }}>
                  {t(
                    'product.details.changeRequest.recommendedRetailPrice.modal.maximumLimitError'
                  )}
                </Typography.Text>
              )}
            </Col>
          </Row>
          {amazonListing.recommendedRetailPrice && (
            <>
              <Row gutter={12} align="middle">
                <Col span={12}>
                  <Typography.Text>
                    {t(
                      'product.details.changeRequest.recommendedRetailPrice.modal.maximumPriceLimit'
                    )}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Flex justify="space-between" align="center">
                    <Typography.Text style={{ color: getPercentageColor(difference, theme) }}>
                      {maximumPriceLimit.toFixed(2)}
                    </Typography.Text>
                    <Button
                      type="link"
                      onClick={() => {
                        setNewPrice(maximumPriceLimit)
                        setDifference(MAXIMUM_PRICE_INCREASE)
                      }}
                    >
                      {t('shared.button.apply', { ns: 'translation' })}
                    </Button>
                  </Flex>
                </Col>
              </Row>
              <Row gutter={12} align="middle">
                <Col span={12}>
                  <Typography.Text>
                    {t('product.details.changeRequest.recommendedRetailPrice.modal.difference')}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text style={{ color: getPercentageColor(difference, theme) }}>
                    {difference > 0 ? '+' : null}
                    {difference.toFixed(2)}%
                  </Typography.Text>
                </Col>
              </Row>
            </>
          )}
          <Alert
            type="info"
            message={t('product.details.changeRequest.recommendedRetailPrice.modal.alert')}
          />
          <Alert type="warning" message={t('product.details.changeRequest.warning')} />
        </Space>
      </Modal>
    </>
  )
}

function getPercentageColor(difference: number, theme: Theme) {
  if (difference <= MAXIMUM_PRICE_INCREASE) {
    return theme.colors.success
  } else if (difference < 0 || difference > MAXIMUM_PRICE_INCREASE) {
    return theme.colors.error
  } else {
    return undefined
  }
}
