import { Button, Tag } from 'antd'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { AmazonPpcState } from '../../../../../generated/graphql'
import { formatCurrency } from '../../../../../views/ppc/helpers/formatCurrency'
import { exportCsvFile } from '../../../../../helpers/exportFile'
import { getTableColumnFilterProps } from '../../../../../views/ppc/helpers/tableColumnFilterProps'
import { getTableColumnSorterProps } from '../../../../../views/ppc/helpers/tableColumnSorterProps'
import { getColumnSearchProps } from '../../../../../views/ppc/helpers/tableSearchProps'
import { useCurrencySymbol } from '../../../../../views/ppc/helpers/useCurrencySymbol'
import { getColorFromState } from '../../../../../views/ppc/helpers'
import { AggregatedAdGroup } from '../../../../../views/ppc/helpers/interfaces'
import { TableWithClickableRows } from '../../../styles'

type SponsoredProductsAdGroupsTableProps = {
  adGroups: AggregatedAdGroup[]
}

const SponsoredProductsAdGroupsTable = ({ adGroups }: SponsoredProductsAdGroupsTableProps) => {
  const [searchText, setSearchText] = useState<string>('')

  const navigate = useNavigate()
  const params = useParams<{ marketplace: string }>()

  const currencySymbol = useCurrencySymbol()

  const handleSearch = (selectedKeys: any[], confirm: Function) => {
    confirm()
    setSearchText(selectedKeys[0])
  }

  const handleReset = (clearFilters: Function) => {
    clearFilters()
    setSearchText('')
  }

  const columns = [
    {
      title: 'Ad group',
      dataIndex: 'name',
      key: 'name',
      fixed: true,
      ...getColumnSearchProps(handleSearch, handleReset, searchText, 'name'),
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      filters: [
        { text: 'Enabled', value: 'enabled' },
        { text: 'Paused', value: 'paused' },
      ],
      render: (state: AmazonPpcState) => {
        const stateString = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()
        return <Tag color={getColorFromState(state)}>{stateString}</Tag>
      },
      ...getTableColumnFilterProps('state'),
    },
    {
      title: 'Default Bid',
      dataIndex: 'defaultBid',
      key: 'defaultBid',
      render: (defaultBid: number) => formatCurrency(currencySymbol, defaultBid),
      ...getTableColumnSorterProps('defaultBid'),
    },
    {
      title: 'ACoS',
      dataIndex: 'acos',
      key: 'acos',
      render: (acos: number) => {
        if (isFinite(acos)) {
          return acos + '%'
        } else {
          return '-'
        }
      },
      ...getTableColumnSorterProps('acos'),
    },
    {
      title: 'Cost per Click',
      dataIndex: 'costPerClick',
      key: 'costPerClick',
      render: (costPerClick: number) => formatCurrency(currencySymbol, costPerClick),
      ...getTableColumnSorterProps('costPerClick'),
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      ...getTableColumnSorterProps('impressions'),
    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      key: 'spend',
      render: (spend: number) => formatCurrency(currencySymbol, spend),
      ...getTableColumnSorterProps('spend'),
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      ...getTableColumnSorterProps('clicks'),
    },
    {
      title: 'Sales',
      dataIndex: 'sales',
      key: 'sales',
      render: (sales: number) => formatCurrency(currencySymbol, sales),
      ...getTableColumnSorterProps('sales'),
    },
  ]

  const handleOnRowClick = (record: any) => {
    navigate(
      `/amazon/ppc/${params.marketplace}/sp/campaigns/${record.campaignId}/adgroups/${record.adGroupId}`
    )
  }

  return (
    <TableWithClickableRows
      bordered
      columns={columns}
      dataSource={adGroups}
      onRow={(record) => ({ onClick: () => handleOnRowClick(record) })}
      pagination={{ defaultPageSize: 20 }}
      rowKey="adGroupId"
      scroll={{ x: true }}
      footer={(data: any) => (
        <Button onClick={() => exportCsvFile(data, 'adgroups')}>Export .csv</Button>
      )}
    />
  )
}

export default SponsoredProductsAdGroupsTable
