import { Alert, Card, Col, Image, List, Row, Space, Typography, Upload, UploadFile } from 'antd'
import { RcFile, UploadChangeParam } from 'antd/es/upload'
import { ArrowLeftRight, Camera, ChevronLeft, ChevronRight, Trash } from 'lucide-react'
import { createRef, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { convertFileToBase64 } from '../../../../../helpers/convertFileToBase64'
import { isValidImage } from './isValidImage'

type AmazonListingImagesManagementProps = {
  validImages: (string | null)[]
  setValidImages: React.Dispatch<React.SetStateAction<(string | null)[]>>
  images: { file: UploadFile; label: string }[] | undefined
  setImages: React.Dispatch<React.SetStateAction<{ file: UploadFile; label: string }[] | undefined>>
}

export const AmazonListingImagesManagement = ({
  validImages,
  setValidImages,
  images,
  setImages,
}: AmazonListingImagesManagementProps) => {
  const [rejectedFilesList, setRejectedFilesList] = useState<string[]>([])

  const { t } = useTranslation(['inventory', 'translation'])

  const singleUploadRefs = useRef<any>(
    Array(9)
      .fill(null)
      .map(() => createRef())
  )

  const multipleUploadRef = useRef<React.ElementRef<'div'> | null>(null)

  const handleShiftPosition = async (oldIndex: number, newIndex: number) => {
    const newValidImages = [...validImages]
    const x = newValidImages[oldIndex]
    const y = newValidImages[newIndex]
    newValidImages[oldIndex] = y
    newValidImages[newIndex] = x
    setValidImages(newValidImages)
    const newImages = images
    if (newImages) {
      const foundOldIndex = newImages.findIndex(
        (image) => image.label === (oldIndex === 0 ? 'mainImage' : `imagePosition${oldIndex}`)
      )
      const foundNewIndex = newImages.findIndex(
        (image) => image.label === (newIndex === 0 ? 'mainImage' : `imagePosition${newIndex}`)
      )

      if (foundNewIndex >= 0 && newImages[foundNewIndex]) {
        newImages[foundNewIndex].label = oldIndex === 0 ? 'mainImage' : `imagePosition${oldIndex}`
      }
      if (foundOldIndex >= 0 && newImages[foundOldIndex]) {
        newImages[foundOldIndex].label = newIndex === 0 ? 'mainImage' : `imagePosition${newIndex}`
      }
      setImages(newImages)
    }
  }

  const handleChange = async (file: UploadFile<any>, index: number) => {
    const isValid = await isValidImage(file.originFileObj as RcFile, ['JPG', 'PNG'])

    if (!isValid) {
      return toast.error(t('product.details.changeRequest.images.modal.error.message'), {
        description: t('product.details.changeRequest.images.modal.error.description'),
      })
    }

    const newValidImages = [...validImages]
    newValidImages[index] = await convertFileToBase64(file.originFileObj!)
    setValidImages(newValidImages)
    const newImages = images
    if (newImages) {
      const foundIndex = newImages.findIndex((image) => image.label === `imagePosition${index}`)
      if (foundIndex > 0) {
        newImages[foundIndex].file = file
      } else {
        newImages.push({ file, label: index === 0 ? 'mainImage' : `imagePosition${index}` })
      }
      setImages(newImages)
    }
  }

  const handleDelete = async (index: number) => {
    const newValidImages = [...validImages]
    newValidImages[index] = null
    setValidImages(newValidImages)
    const newImages = images ? [...images] : []
    if (newImages) {
      const foundIndex = newImages.findIndex(
        (image) => image.label === (index === 0 ? 'mainImage' : `imagePosition${index}`)
      )
      newImages.splice(foundIndex, 1)
      setImages(newImages)
    }
  }

  const handleMultipleUploadChange = async ({ fileList }: UploadChangeParam<UploadFile>) => {
    const newValidImages = [...validImages]
    const rejectedFiles = []
    const newImages = images
    for (const file of fileList) {
      if (file.status !== 'done') {
        continue
      }

      const isValid = await isValidImage(file.originFileObj as RcFile, ['JPG', 'PNG'])

      if (!isValid) {
        return toast.error(t('product.details.changeRequest.images.modal.error.message'), {
          description: t('product.details.changeRequest.images.modal.error.description'),
        })
      }

      if (newValidImages.filter((image) => !image).length > 0) {
        const newFile = await convertFileToBase64(file.originFileObj!)
        const foundNullIndexes = newValidImages.findIndex((image) => !image)
        newValidImages[foundNullIndexes] = newFile
        const newLabel = foundNullIndexes === 0 ? 'mainImage' : `imagePosition${foundNullIndexes}`
        if (!newImages?.filter((image) => image.label === newLabel).length) {
          newImages?.push({
            file,
            label: newLabel,
          })
        }
      } else {
        rejectedFiles.push(file.name)
      }
    }
    setValidImages(newValidImages)
    if (rejectedFiles.length > 0) {
      setRejectedFilesList(rejectedFiles)
    }
  }

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Text>
          <Typography.Link onClick={() => multipleUploadRef?.current?.click()}>
            {t('product.details.changeRequest.images.modal.content.upload1')}
          </Typography.Link>{' '}
          {t('product.details.changeRequest.images.modal.content.upload2')}
        </Typography.Text>
        <Row gutter={[8, 8]}>
          {[...Array(9)].map((_, index) => {
            return (
              <Col xs={12} sm={6} md={4} xl={2} key={index}>
                {validImages[index] ? (
                  <Card
                    styles={{ body: { padding: '0.5rem', aspectRatio: '1' } }}
                    actions={[
                      <ChevronLeft
                        size={16}
                        onClick={() => handleShiftPosition(index, index - 1)}
                        style={{ pointerEvents: index === 0 ? 'none' : 'auto' }}
                      />,
                      <ChevronRight
                        size={16}
                        onClick={() => handleShiftPosition(index, index + 1)}
                        style={{ pointerEvents: index === 8 ? 'none' : 'auto' }}
                      />,
                      <ArrowLeftRight
                        size={16}
                        onClick={() => singleUploadRefs.current[index].current.click()}
                      />,
                      <Trash size={16} onClick={() => handleDelete(index)} />,
                    ]}
                  >
                    <Image
                      src={validImages[index] ?? ''}
                      width="100%"
                      height="100%"
                      preview={false}
                      style={{ borderRadius: '0.25rem', objectFit: 'contain' }}
                    />
                    <div style={{ display: 'none' }}>
                      <Upload
                        accept=".jpg, .jpeg, .png"
                        customRequest={customRequest}
                        listType="picture-card"
                        onChange={(info) => {
                          return info.file.status === 'done' && handleChange(info.file, index)
                        }}
                      >
                        <div ref={singleUploadRefs.current[index]} />
                      </Upload>
                    </div>
                  </Card>
                ) : (
                  <Card styles={{ body: { padding: '0.5rem', aspectRatio: '1' } }}>
                    <Upload.Dragger
                      accept=".jpg, .jpeg, .png"
                      customRequest={customRequest}
                      onChange={(info) => {
                        return info.file.status === 'done' && handleChange(info.file, index)
                      }}
                      showUploadList={false}
                    >
                      <Space direction="vertical">
                        <Camera />
                        <Typography.Text>Upload</Typography.Text>
                      </Space>
                    </Upload.Dragger>
                  </Card>
                )}
              </Col>
            )
          })}
        </Row>
        {rejectedFilesList.length ? (
          <Alert
            type="error"
            message="Attention: Maximum file uploads exceeded!"
            description={
              <List<string>
                header={t('product.details.changeRequest.images.modal.content.exceedLimit')}
                dataSource={rejectedFilesList}
                renderItem={(item) => <List.Item style={{ padding: '0.5rem' }}>{item}</List.Item>}
                split={false}
              />
            }
            closable
            onClose={() => setRejectedFilesList([])}
          />
        ) : null}
        <div style={{ display: 'none' }}>
          <Upload
            accept=".jpg, .jpeg, .png"
            customRequest={customRequest}
            listType="picture-card"
            maxCount={9}
            multiple
            onChange={handleMultipleUploadChange}
          >
            <div ref={multipleUploadRef} />
          </Upload>
        </div>
      </Space>
    </>
  )
}

const customRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}
