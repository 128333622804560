import { Col, Descriptions, Flex, Popover, Row, Tag, Typography } from 'antd'
import { CircleHelp } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { AmazonListingFieldsFragment } from '../../../../generated/graphql'
import { StyledMainCard } from '../../../dashboard/styles'
import { descriptionsStyle } from '../../../products/components/styles'
import { AmazonListingInventoryInboundTable } from './AmazonListingInventoryInboundTable'
import { AmazonListingInventoryReservedTable } from './AmazonListingInventoryReservedTable'
import { AmazonListingInventoryUnfulfillableTable } from './UnfulfillableInventoryTable'

type AmazonListingInventoryCardProps = {
  inventory: AmazonListingFieldsFragment['inventory']
}

const prepareInventoryData = (inventory: AmazonListingFieldsFragment['inventory']) => {
  const fulfilledInventory = {
    fulfilledQuantity: inventory?.fulfillable?.fulfillableQuantity ?? 0,
  }

  const inboundInventory = {
    inboundReceivingQuantity: inventory?.inbound?.inboundReceivingQuantity ?? 0,
    inboundShippedQuantity: inventory?.inbound?.inboundShippedQuantity ?? 0,
    inboundWorkingQuantity: inventory?.inbound?.inboundWorkingQuantity ?? 0,
    totalQuantity:
      (inventory?.inbound?.inboundReceivingQuantity ?? 0) +
      (inventory?.inbound?.inboundShippedQuantity ?? 0) +
      (inventory?.inbound?.inboundWorkingQuantity ?? 0),
  }

  const reservedInventory = {
    pendingCustomerOrderQuantity: inventory?.reserved?.pendingCustomerOrderQuantity ?? 0,
    pendingTransshipmentQuantity: inventory?.reserved?.pendingTransshipmentQuantity ?? 0,
    fcProcessingQuantity: inventory?.reserved?.fcProcessingQuantity ?? 0,
    totalQuantity:
      (inventory?.reserved?.pendingCustomerOrderQuantity ?? 0) +
      (inventory?.reserved?.pendingTransshipmentQuantity ?? 0) +
      (inventory?.reserved?.fcProcessingQuantity ?? 0),
  }

  const unfulfillableInventory = {
    carrierDamagedQuantity: inventory?.unfulfillable?.carrierDamagedQuantity ?? 0,
    customerDamagedQuantity: inventory?.unfulfillable?.customerDamagedQuantity ?? 0,
    defectiveQuantity: inventory?.unfulfillable?.defectiveQuantity ?? 0,
    distributorDamagedQuantity: inventory?.unfulfillable?.distributorDamagedQuantity ?? 0,
    expiredQuantity: inventory?.unfulfillable?.expiredQuantity ?? 0,
    warehouseDamagedQuantity: inventory?.unfulfillable?.warehouseDamagedQuantity ?? 0,
    totalQuantity:
      (inventory?.unfulfillable?.carrierDamagedQuantity ?? 0) +
      (inventory?.unfulfillable?.customerDamagedQuantity ?? 0) +
      (inventory?.unfulfillable?.defectiveQuantity ?? 0) +
      (inventory?.unfulfillable?.distributorDamagedQuantity ?? 0) +
      (inventory?.unfulfillable?.expiredQuantity ?? 0) +
      (inventory?.unfulfillable?.warehouseDamagedQuantity ?? 0),
  }

  return {
    fulfilledInventory,
    inboundInventory,
    reservedInventory,
    unfulfillableInventory,
  }
}

export const AmazonListingInventoryCard = ({ inventory }: AmazonListingInventoryCardProps) => {
  const { t } = useTranslation('inventory')

  const { fulfilledInventory, inboundInventory, reservedInventory, unfulfillableInventory } =
    prepareInventoryData(inventory)

  const isOutOfStock = fulfilledInventory.fulfilledQuantity === 0

  return (
    <StyledMainCard
      title={t('product.details.inventory.heading')}
      extra={
        isOutOfStock ? <Tag color="gold">{t('product.details.inventory.outOfStock')}</Tag> : null
      }
    >
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Descriptions {...descriptionsStyle}>
            <Descriptions.Item
              label={
                <Flex justify="space-between" align="center" gap={8}>
                  {t('product.details.inventory.fulfillable')}
                  <Popover
                    content={t('product.details.inventory.fulfillablePopover')}
                    trigger="hover"
                    arrow
                    placement="top"
                    overlayInnerStyle={{ width: '300px' }}
                  >
                    <CircleHelp size={16} />
                  </Popover>
                </Flex>
              }
            >
              <Typography.Text type="success">
                {fulfilledInventory.fulfilledQuantity}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Flex justify="space-between" align="center" gap={8}>
                  {t('product.details.inventory.inbound')}
                  <Popover
                    title={t('product.details.inventory.inboundPopover.title', {
                      ns: 'inventory',
                    })}
                    content={
                      <AmazonListingInventoryInboundTable inboundInventory={inboundInventory} />
                    }
                    trigger="hover"
                    placement="top"
                    overlayInnerStyle={{
                      width: '350px',
                      borderRadius: '0.5rem',
                      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <CircleHelp size={16} />
                  </Popover>
                </Flex>
              }
            >
              {inboundInventory.totalQuantity}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Flex justify="space-between" align="center" gap={8}>
                  {t('product.details.inventory.reserved')}
                  <Popover
                    title={t('product.details.inventory.reservedPopover.title', {
                      ns: 'inventory',
                    })}
                    content={
                      <AmazonListingInventoryReservedTable reservedInventory={reservedInventory} />
                    }
                    trigger="hover"
                    placement="top"
                    overlayInnerStyle={{
                      width: '350px',
                      borderRadius: '0.5rem',
                      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <CircleHelp size={16} />
                  </Popover>
                </Flex>
              }
            >
              {reservedInventory.totalQuantity}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Flex justify="space-between" align="center" gap={8}>
                  {t('product.details.inventory.unfulfillable')}
                  <Popover
                    title={t('product.details.inventory.unfulfillablePopover.title', {
                      ns: 'inventory',
                    })}
                    content={
                      <AmazonListingInventoryUnfulfillableTable
                        unfulfillableInventory={unfulfillableInventory}
                      />
                    }
                    trigger="hover"
                    placement="top"
                    overlayInnerStyle={{
                      width: '350px',
                      borderRadius: '0.5rem',
                      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <CircleHelp size={16} />
                  </Popover>
                </Flex>
              }
            >
              <Typography.Text type="danger">
                {unfulfillableInventory.totalQuantity}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </StyledMainCard>
  )
}
