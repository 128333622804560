import { useMutation } from '@apollo/client'
import { useTheme } from '@emotion/react'
import {
  CellClassParams,
  CellEditRequestEvent,
  ColDef,
  ColGroupDef,
  GetRowIdFunc,
  GetRowIdParams,
  ICellRendererParams,
  RowSelectionOptions,
  SelectionChangedEvent,
  SelectionColumnDef,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import { Button, Flex, Segmented, Tag, Tooltip, Typography } from 'antd'
import { ArrowDown, ArrowUp, PenLine, Plus } from 'lucide-react'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router'
import { toast } from 'sonner'
import { FormattedDate } from '../../../components/FormattedDate'
import { MoreItemsIndicator } from '../../../components/MoreItemsIndicator'
import { Table } from '../../../components/Table/Table'
import {
  CompanyStockQuery,
  CompanyType,
  GetProductVariantDocument,
  ProductStatus,
  ProductVariantPackagingUnitType,
  UpdateInternalSkuDocument,
} from '../../../generated/graphql'
import { formatMoney } from '../../../helpers/formatMoney'
import { formatPercentage } from '../../../helpers/formatPercentage'
import { getUserRole } from '../../../helpers/getUserRole'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { TablePersistKey } from '../../../stores/useTableStore'
import { StockLevel, filterDataByStockLevel } from '../helpers/filterDataByStockLevel'

export type StockTableRecord = {
  warehouseName: string
  warehouseLocationUuid: string
  productUuid: string
  productVariantUuid: string
  productName: string
  productStatus: ProductStatus
  tags: string[]
  internalSku: string | null
  asins: string[]
  skus: string[]
  netPurchasePrice: number | null
  availableQuantity: number
  inboundQuantity: number
  reservedQuantity: number
  researchingQuantity: number
  unfulfillableQuantity: number
  runtimeInDays: number | null
  recommendedOrderQuantity: number | null
  overstock: number | null
  packagingUnits: CompanyStockQuery['companyStock'][number]['productVariant']['packagingUnits']
  mostRecentShipmentDestinations: {
    sku: string
    marketplace: string
    mostRecentShipmentDestination: string
  }[]
  ordersLast14Days: number
  ordersLast30Days: number
  ordersLast90Days: number
  firstOrderDate: string | null
  mostRecentOrderDate: string | null
  deltaOrdersLast14Days: number
  deltaOrdersLast30Days: number
  deltaOrdersLast90Days: number
  salesRevenueLast14Days: number
  salesRevenueLast30Days: number
  salesRevenueLast90Days: number
  returnsLast14Days: number
  returnsLast30Days: number
  returnsLast90Days: number
  age0To90Days: number | null
  age91To180Days: number | null
  age181To330Days: number | null
  age331To365Days: number | null
  ageOver365Days: number | null
  searchIdentifiers: string
}

type StockTableProps = {
  data?: StockTableRecord[]
}

export const StockTable = ({ data }: StockTableProps) => {
  const [stockLevel, setStockLevel] = useState<string | number>(StockLevel.ALL_STOCK)
  const [selectedRows, setSelectedRows] = useState<StockTableRecord[]>([])

  const navigate = useNavigate()
  const user = useGlobalStore((state) => state.user)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation(['inventory', 'translation'])

  const gridRef = useRef<AgGridReact<StockTableRecord>>(null)
  const theme = useTheme()

  const [updateInternalSku] = useMutation(UpdateInternalSkuDocument)

  const userRole = getUserRole(user, selectedCompany)
  const hasFullAccess = userRole === 'company:admin' || user?.isAdmin
  const isVendor = selectedCompany.type === CompanyType.WHOLESALER

  const [columnDefs] = useState<(ColDef<StockTableRecord> | ColGroupDef<StockTableRecord>)[]>([
    {
      field: 'warehouseName',
      headerName: t('stock.table.warehouseName', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.warehouseName', { ns: 'inventory' }),
      enableRowGroup: true,
    },
    {
      field: 'productName',
      headerName: t('stock.table.productName', { ns: 'inventory' }),
      filter: 'agTextColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<StockTableRecord, StockTableRecord['productName']>
      ) => {
        if (params.node.group) {
          return params.value
        }

        return (
          <Link
            to={`/products/${params.data?.productUuid}/variants/${params.data?.productVariantUuid}`}
          >
            <Typography.Link>{params.value}</Typography.Link>
          </Link>
        )
      },
      getQuickFilterText: (params) => {
        return `${params.data?.productName} ${params.data?.searchIdentifiers}`
      },
    },
    {
      field: 'productStatus',
      headerName: t('stock.table.productStatus', { ns: 'inventory' }),
      filterParams: {
        values: [ProductStatus.ACTIVE, ProductStatus.ARCHIVED],
        cellRenderer: (params: { value: ProductStatus }) => {
          if (params.value === ProductStatus.ACTIVE) {
            return t('shared.status.active', { ns: 'translation' })
          } else if (params.value === ProductStatus.ARCHIVED) {
            return t('shared.status.archived', { ns: 'translation' })
          }
          return params.value
        },
      },
      cellRenderer: (
        params: ICellRendererParams<StockTableRecord, StockTableRecord['productStatus']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (params.data?.productStatus === ProductStatus.ACTIVE) {
          return <Tag color="success">{t('shared.status.active', { ns: 'translation' })}</Tag>
        } else if (params.data?.productStatus === ProductStatus.ARCHIVED) {
          return <Tag>{t('shared.status.archived', { ns: 'translation' })}</Tag>
        }
      },
    },
    {
      field: 'tags',
      headerName: t('stock.table.tags'),
      filter: false,
      cellRenderer: (params: ICellRendererParams<StockTableRecord, StockTableRecord['tags']>) => {
        if (params.node.group) {
          return null
        }

        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return params.value.map((tag) => <Tag key={tag}>{tag}</Tag>)
      },
    },
    {
      field: 'internalSku',
      headerName: t('stock.table.internalSku', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.internalSku', { ns: 'inventory' }),
      filter: 'agTextColumnFilter',
      editable: true,
      suppressPaste: true,
      cellRenderer: (
        params: ICellRendererParams<StockTableRecord, StockTableRecord['internalSku']>
      ) => {
        if (params.node.group) {
          return null
        }

        return (
          <Typography.Text type={!params.value ? 'secondary' : undefined}>
            <Flex align="center" gap={4}>
              {params.value ? params.value : t('shared.button.edit', { ns: 'translation' })}
              <PenLine size={16} />
            </Flex>
          </Typography.Text>
        )
      },
    },
    {
      field: 'asins',
      headerName: t('stock.table.asins', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.asins', { ns: 'inventory' }),
      cellRenderer: (params: ICellRendererParams<StockTableRecord, StockTableRecord['asins']>) => {
        if (params.node.group) {
          return null
        }

        const asins = params.value

        if (!asins) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = asins.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{asins[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={asins} />}
          </Flex>
        )
      },
    },
    {
      field: 'skus',
      headerName: t('stock.table.skus', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.skus', { ns: 'inventory' }),
      cellRenderer: (params: ICellRendererParams<StockTableRecord, StockTableRecord['skus']>) => {
        if (params.node.group) {
          return null
        }

        const skus = params.value

        if (!skus) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = skus.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{skus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={skus} />}
          </Flex>
        )
      },
    },
    {
      field: 'availableQuantity',
      headerName: t('stock.table.availableQuantity', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.availableQuantity', { ns: 'inventory' }),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
    },
    {
      field: 'inboundQuantity',
      headerName: t('stock.table.inboundQuantity', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.inboundQuantity', { ns: 'inventory' }),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
    },
    {
      field: 'reservedQuantity',
      headerName: t('stock.table.reservedQuantity', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.reservedQuantity', { ns: 'inventory' }),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
    },
    {
      field: 'researchingQuantity',
      headerName: t('stock.table.researchingQuantity', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.researchingQuantity', { ns: 'inventory' }),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
    },
    {
      field: 'unfulfillableQuantity',
      headerName: t('stock.table.unfulfillableQuantity', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.unfulfillableQuantity', { ns: 'inventory' }),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
    },
    {
      field: 'runtimeInDays',
      headerName: t('stock.table.runtimeInDays', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.runtimeInDays', { ns: 'inventory' }),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'recommendedOrderQuantity',
      headerName: t('stock.table.recommendedOrderQuantity', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.recommendedOrderQuantity', { ns: 'inventory' }),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellStyle: (
        params: CellClassParams<StockTableRecord, StockTableRecord['recommendedOrderQuantity']>
      ) => {
        if (params.value && !params.node.aggData) {
          return { backgroundColor: `${theme.colors.warning}40` }
        }
      },
    },
    {
      field: 'overstock',
      headerName: t('stock.table.overstock', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.overstock', { ns: 'inventory' }),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellStyle: (params: CellClassParams<StockTableRecord, StockTableRecord['overstock']>) => {
        if (params.value && !params.node.aggData) {
          return { backgroundColor: `${theme.colors.error}40` }
        }
      },
    },
    {
      field: 'packagingUnits',
      headerName: t('stock.table.packagingUnits'),
      filter: false,
      valueFormatter: (
        params: ValueFormatterParams<StockTableRecord, StockTableRecord['packagingUnits']>
      ) => {
        if (!params.value) {
          return ''
        }

        return params.value.map((packagingUnit) => packagingUnit.quantity).join(', ')
      },
      cellRenderer: (
        params: ICellRendererParams<StockTableRecord, StockTableRecord['packagingUnits']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return params.value.map((packagingUnit) => (
          <Tag
            key={packagingUnit.uuid}
            color={
              packagingUnit.type === ProductVariantPackagingUnitType.SMALL_PARCEL
                ? 'green'
                : packagingUnit.type === ProductVariantPackagingUnitType.PALLET
                  ? 'blue'
                  : 'default'
            }
          >
            {packagingUnit.quantity}
          </Tag>
        ))
      },
    },
    {
      field: 'mostRecentShipmentDestinations',
      headerName: t('stock.table.mostRecentShipmentDestinations', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.mostRecentShipmentDestinations', { ns: 'inventory' }),
      filterValueGetter: (
        params: ValueGetterParams<
          StockTableRecord,
          StockTableRecord['mostRecentShipmentDestinations']
        >
      ) => {
        if (!params.data) {
          return null
        }

        return params.data.mostRecentShipmentDestinations.map(
          (item) => item.mostRecentShipmentDestination
        )
      },
      valueFormatter: (
        params: ValueFormatterParams<
          StockTableRecord,
          StockTableRecord['mostRecentShipmentDestinations']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (!Array.isArray(params.value)) {
          return params.value
        }

        return params.value.map((item) => item.mostRecentShipmentDestination).join(', ')
      },
      cellRenderer: (
        params: ICellRendererParams<
          StockTableRecord,
          StockTableRecord['mostRecentShipmentDestinations']
        >
      ) => {
        if (params.node.group) {
          return null
        }

        const items = params.value

        if (!items) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = items.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{items[0]?.mostRecentShipmentDestination}</Typography.Text>
            {totalQuantity > 1 && (
              <MoreItemsIndicator
                items={items}
                renderItem={(item) =>
                  `${item.mostRecentShipmentDestination} (${item.sku} - ${item.marketplace})`
                }
              />
            )}
          </Flex>
        )
      },
    },
    {
      field: 'netPurchasePrice',
      headerName: t('stock.table.netPurchasePrice', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.netPurchasePrice', { ns: 'inventory' }),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<StockTableRecord, StockTableRecord['netPurchasePrice']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    {
      valueGetter: (params) => {
        if (!params.data) {
          return null
        }

        if (params.data.netPurchasePrice === null) {
          return null
        }

        const quantity =
          params.data.availableQuantity +
          params.data.inboundQuantity +
          params.data.reservedQuantity +
          params.data.researchingQuantity

        return params.data.netPurchasePrice * quantity
      },
      headerName: t('stock.table.totalValue', { ns: 'inventory' }),
      headerTooltip: t('stock.table.tooltip.totalValue', { ns: 'inventory' }),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (params: ICellRendererParams<StockTableRecord, number>) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    ...(hasFullAccess
      ? ([
          {
            field: 'ordersLast14Days',
            headerName: t('stock.table.ordersLast14Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.ordersLast14Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            hide: true,
          },
          {
            field: 'deltaOrdersLast14Days',
            headerName: t('stock.table.deltaOrdersLast14Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.deltaOrdersLast14Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'avg',
            hide: true,
            useValueFormatterForExport: true,
            cellRenderer: (
              params: ICellRendererParams<
                StockTableRecord,
                StockTableRecord['deltaOrdersLast14Days']
              >
            ) => {
              if (!params.value) {
                return formatPercentage(0)
              }

              if (params.value > 0) {
                return (
                  <Flex align="center" gap={4}>
                    <ArrowUp size={16} color={theme.colors.success} />
                    <Typography.Text>{formatPercentage(params.value / 100)}</Typography.Text>
                  </Flex>
                )
              } else if (params.value < 0) {
                return (
                  <Flex align="center" gap={4}>
                    <ArrowDown size={16} color={theme.colors.error} />
                    <Typography.Text>{formatPercentage(params.value / 100)}</Typography.Text>
                  </Flex>
                )
              }
            },
          },
          {
            field: 'ordersLast30Days',
            headerName: t('stock.table.ordersLast30Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.ordersLast30Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
          },
          {
            field: 'deltaOrdersLast30Days',
            headerName: t('stock.table.deltaOrdersLast30Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.deltaOrdersLast30Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'avg',
            useValueFormatterForExport: true,
            cellRenderer: (
              params: ICellRendererParams<
                StockTableRecord,
                StockTableRecord['deltaOrdersLast30Days']
              >
            ) => {
              if (!params.value) {
                return formatPercentage(0)
              }

              if (params.value > 0) {
                return (
                  <Flex align="center" gap={4}>
                    <ArrowUp size={16} color={theme.colors.success} />
                    <Typography.Text>{formatPercentage(params.value / 100)}</Typography.Text>
                  </Flex>
                )
              } else if (params.value < 0) {
                return (
                  <Flex align="center" gap={4}>
                    <ArrowDown size={16} color={theme.colors.error} />
                    <Typography.Text>{formatPercentage(params.value / 100)}</Typography.Text>
                  </Flex>
                )
              }
            },
          },
          {
            field: 'ordersLast90Days',
            headerName: t('stock.table.ordersLast90Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.ordersLast90Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            hide: true,
          },
          {
            field: 'deltaOrdersLast90Days',
            headerName: t('stock.table.deltaOrdersLast90Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.deltaOrdersLast90Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'avg',
            hide: true,
            useValueFormatterForExport: true,
            cellRenderer: (
              params: ICellRendererParams<
                StockTableRecord,
                StockTableRecord['deltaOrdersLast90Days']
              >
            ) => {
              if (!params.value) {
                return formatPercentage(0)
              }

              if (params.value > 0) {
                return (
                  <Flex align="center" gap={4}>
                    <ArrowUp size={16} color={theme.colors.success} />
                    <Typography.Text>{formatPercentage(params.value / 100)}</Typography.Text>
                  </Flex>
                )
              } else if (params.value < 0) {
                return (
                  <Flex align="center" gap={4}>
                    <ArrowDown size={16} color={theme.colors.error} />
                    <Typography.Text>{formatPercentage(params.value / 100)}</Typography.Text>
                  </Flex>
                )
              }
            },
          },
          {
            field: 'firstOrderDate',
            headerName: t('stock.table.firstOrderDate', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.firstOrderDate', { ns: 'inventory' }),
            filter: false,
            cellRenderer: (
              params: ICellRendererParams<StockTableRecord, StockTableRecord['firstOrderDate']>
            ) => {
              if (params.node.group) {
                return null
              }

              if (!params.value) {
                return <Typography.Text type="secondary">-</Typography.Text>
              }

              return <FormattedDate date={params.value} withRelativeTime layout="horizontal" />
            },
          },
          {
            field: 'mostRecentOrderDate',
            headerName: t('stock.table.mostRecentOrderDate', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.mostRecentOrderDate', { ns: 'inventory' }),
            filter: false,
            cellRenderer: (
              params: ICellRendererParams<StockTableRecord, StockTableRecord['mostRecentOrderDate']>
            ) => {
              if (params.node.group) {
                return null
              }

              if (!params.value) {
                return <Typography.Text type="secondary">-</Typography.Text>
              }

              return <FormattedDate date={params.value} withRelativeTime layout="horizontal" />
            },
          },
          {
            field: 'salesRevenueLast14Days',
            headerName: t('stock.table.salesRevenueLast14Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.salesRevenueLast14Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            hide: true,
            cellRenderer: (params: ICellRendererParams<StockTableRecord, number>) => {
              if (params.value === null || params.value === undefined) {
                return <Typography.Text type="secondary">-</Typography.Text>
              }

              return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
            },
          },
          {
            field: 'salesRevenueLast30Days',
            headerName: t('stock.table.salesRevenueLast30Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.salesRevenueLast30Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            cellRenderer: (params: ICellRendererParams<StockTableRecord, number>) => {
              if (params.value === null || params.value === undefined) {
                return <Typography.Text type="secondary">-</Typography.Text>
              }

              return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
            },
          },
          {
            field: 'salesRevenueLast90Days',
            headerName: t('stock.table.salesRevenueLast90Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.salesRevenueLast90Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            hide: true,
            cellRenderer: (params: ICellRendererParams<StockTableRecord, number>) => {
              if (params.value === null || params.value === undefined) {
                return <Typography.Text type="secondary">-</Typography.Text>
              }

              return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
            },
          },
          {
            field: 'returnsLast14Days',
            headerName: t('stock.table.returnsLast14Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.returnsLast14Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            hide: true,
          },
          {
            field: 'returnsLast30Days',
            headerName: t('stock.table.returnsLast30Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.returnsLast30Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
          },
          {
            field: 'returnsLast90Days',
            headerName: t('stock.table.returnsLast90Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.returnsLast90Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            hide: true,
          },
          {
            field: 'age0To90Days',
            headerName: t('stock.table.age0To90Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.age0To90Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            initialHide: true,
          },
          {
            field: 'age91To180Days',
            headerName: t('stock.table.age91To180Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.age91To180Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            initialHide: true,
          },
          {
            field: 'age181To330Days',
            headerName: t('stock.table.age181To330Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.age181To330Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            initialHide: true,
          },
          {
            field: 'age331To365Days',
            headerName: t('stock.table.age331To365Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.age331To365Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            initialHide: true,
          },
          {
            field: 'ageOver365Days',
            headerName: t('stock.table.ageOver365Days', { ns: 'inventory' }),
            headerTooltip: t('stock.table.tooltip.ageOver365Days', { ns: 'inventory' }),
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            initialHide: true,
          },
        ] as ColDef<StockTableRecord>[])
      : []),
  ])

  const defaultColDef = useMemo<ColDef<StockTableRecord>>(() => {
    return {
      contextMenuItems: [],
      marryChildren: true,
    }
  }, [])

  const selectionColumnDef = useMemo<SelectionColumnDef>(() => {
    return {
      pinned: 'left',
      minWidth: 41,
      maxWidth: 41,
    }
  }, [])

  const getRowId = useMemo<GetRowIdFunc<StockTableRecord>>(() => {
    return (params: GetRowIdParams<StockTableRecord>) =>
      `${params.data.productVariantUuid}_${params.data.warehouseName}`
  }, [])

  const rowSelection = useMemo<RowSelectionOptions<StockTableRecord>>(() => {
    return {
      checkboxLocation: 'autoGroupColumn',
      groupSelects: 'filteredDescendants',
      mode: 'multiRow',
      selectAll: 'filtered',
    }
  }, [])

  const onCellEditRequest = useCallback(async (event: CellEditRequestEvent<StockTableRecord>) => {
    const field = event.colDef.field

    if (!field) {
      return false
    }

    if (event.newValue === event.oldValue) {
      return false
    }

    switch (field) {
      case 'internalSku': {
        try {
          const value = event.newValue?.trim() || null

          event.api.applyTransaction({
            update: [{ ...event.data, [field]: value }],
          })

          await updateInternalSku({
            variables: {
              uuid: event.data.productVariantUuid,
              internalSku: value,
            },
            optimisticResponse: {
              updateProductVariant: {
                internalSku: value,
              },
            },
            update: (cache, { data }) => {
              const updatedProductVariant = data?.updateProductVariant
              const cachedQuery = cache.readQuery({
                query: GetProductVariantDocument,
                variables: { uuid: event.data.productVariantUuid },
              })

              if (updatedProductVariant && cachedQuery) {
                cache.writeQuery({
                  query: GetProductVariantDocument,
                  variables: { uuid: event.data.productVariantUuid },
                  data: {
                    productVariant: {
                      ...cachedQuery.productVariant,
                      internalSku: updatedProductVariant.internalSku,
                    },
                  },
                })
              }
            },
          })
        } catch (error) {
          event.api.applyTransaction({
            update: [{ ...event.data, [field]: event.oldValue }],
          })
          console.error(error)
          toast.error(t('shared.error.message', { ns: 'translation' }))
        }

        break
      }

      default:
        break
    }
  }, [])

  const onSelectionChanged = useCallback((event: SelectionChangedEvent<StockTableRecord>) => {
    const selectedNodes = event.api.getSelectedNodes()
    const selectedRows = selectedNodes.map((node) => node.data).filter(Boolean)
    setSelectedRows(selectedRows)
  }, [])

  const rowData = useMemo(() => {
    if (!data) {
      return undefined
    }

    return filterDataByStockLevel(data, stockLevel)
  }, [data, stockLevel])

  const isCreateDisabled = useMemo(() => {
    if (selectedRows.length === 0) {
      return true
    }

    const warehouseLocations = selectedRows.map((row) => row.warehouseLocationUuid)
    const uniqueWarehouseLocations = new Set(warehouseLocations)

    if (uniqueWarehouseLocations.size > 1) {
      return true
    }

    return false
  }, [selectedRows])

  const handleCreate = useCallback(() => {
    if (selectedRows.length === 0) {
      return
    }

    const warehouseLocationUuid = selectedRows[0]?.warehouseLocationUuid

    if (!warehouseLocationUuid) {
      return
    }

    navigate('/wholesale/orders/create', {
      state: {
        shipToWarehouseLocationUuid: warehouseLocationUuid,
        selectedRows,
      },
    })
  }, [selectedRows])

  return (
    <Flex vertical gap={8} style={{ height: '100%' }}>
      <Flex justify="space-between">
        <Segmented
          value={stockLevel}
          onChange={setStockLevel}
          options={[
            {
              value: StockLevel.ALL_STOCK,
              label: t('stock.filters.allStock', { ns: 'inventory' }),
            },
            {
              value: StockLevel.LOW_STOCK,
              label: t('stock.filters.lowStock', { ns: 'inventory' }),
            },
            {
              value: StockLevel.OVERSTOCK,
              label: t('stock.filters.overstock', { ns: 'inventory' }),
            },
          ]}
        />
        {isVendor ? (
          <Tooltip
            title={
              isCreateDisabled
                ? t('stock.createWholesaleOrder.tooltip', { ns: 'inventory' })
                : undefined
            }
            placement="bottomRight"
          >
            <Button
              type="primary"
              disabled={isCreateDisabled}
              onClick={handleCreate}
              icon={<Plus size={16} />}
            >
              {t('stock.createWholesaleOrder.button', { ns: 'inventory' })}
            </Button>
          </Tooltip>
        ) : null}
      </Flex>
      <Table<StockTableRecord>
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        fullHeight
        getRowId={getRowId}
        grandTotalRow="bottom"
        onCellEditRequest={onCellEditRequest}
        onSelectionChanged={onSelectionChanged}
        persist={{
          key: TablePersistKey.STOCK,
          storage: localStorage,
        }}
        ref={gridRef}
        readOnlyEdit
        rowData={rowData}
        rowGroupPanelShow="always"
        rowSelection={rowSelection}
        selectionColumnDef={selectionColumnDef}
        showColumnChooser
        showExport
        showFilterReset
        showUniversalSearch
        storeSearchInSearchParams
      />
    </Flex>
  )
}
