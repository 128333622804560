import { formatPercentage } from '../../../../helpers/formatPercentage'
import { AmazonOrderTableRecord } from '../components/AmazonOrderTable'

export function prepareAmazonOrderOverview(amazonOrders: AmazonOrderTableRecord[]) {
  const totalAmazonOrders = amazonOrders.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantity,
    0
  )

  const skus = amazonOrders.reduce<Partial<Record<string, number>>>(
    (previousValue, currentValue) => {
      previousValue[currentValue.sku] = (previousValue[currentValue.sku] ?? 0) + 1
      return previousValue
    },
    {}
  )

  const sortedSkus = Object.entries(skus)
    .sort((a, b) => (b[1] ?? 0) - (a[1] ?? 0))
    .slice(0, 5)

  const topSkusCount = sortedSkus.reduce(
    (previousValue, currentValue) => previousValue + (currentValue[1] ?? 0),
    0
  )

  const topSkus = sortedSkus.map(([sku, count]) => ({
    sku,
    count,
    proportion: Math.round(((count ?? 0) / topSkusCount) * 100),
    percentage: formatPercentage((count ?? 0) / totalAmazonOrders),
  }))

  return { topSkus }
}
