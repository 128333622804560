import { Button } from 'antd'
import { Storage } from 'aws-amplify'
import { useTranslation } from 'react-i18next'

type LetterOfAuthorizationViewButtonProps = {
  objectKey: string
}

export const LetterOfAuthorizationViewButton = ({
  objectKey,
}: LetterOfAuthorizationViewButtonProps) => {
  const { t } = useTranslation()

  const handleClick = async () => {
    try {
      const file = await Storage.get(objectKey, {
        bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME_BRANDS,
      })

      window.open(file, '_blank')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Button type="primary" onClick={handleClick}>
      {t('shared.button.view')}
    </Button>
  )
}
