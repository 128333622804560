import dayjs from 'dayjs'

export const getAllDates = (startDate: Date, endDate: Date, format?: string): string[] => {
  const dayJsStartDate = dayjs(startDate).startOf('day')
  const dayJsEndDate = dayjs(endDate).endOf('day')
  const daysInRange = dayJsEndDate.diff(dayJsStartDate, 'day')
  const allDates: string[] = []
  for (let i = 0; i <= daysInRange; i = i + 1) {
    allDates.push(dayJsStartDate.add(i === 0 ? 0 : i, 'day').format(format))
  }
  return allDates
}
