import { Typography } from 'antd'
import { StyledVideo } from '../styles/TicketCreateFormStyles'

const StyledVideoPlayer = ({ videoURL, title }: { videoURL: string; title: string }) => (
  <>
    <Typography.Paragraph>{title}</Typography.Paragraph>
    <StyledVideo width="100%" height="56.25%" preload="metadata" controls>
      <source src={videoURL} type="video/mp4" />
      Unsupported video. Please copy/paste this URL in you browser:
      {videoURL}
    </StyledVideo>
  </>
)

export default StyledVideoPlayer
