import styled from '@emotion/styled'

export const SubHeaderContainer = styled.section`
  flex: 0 1 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1.5rem;
  padding: 1rem;
`

export const SubHeaderLeftContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;

  .ant-typography {
    font-size: 1.25rem;
    line-height: 2rem;
  }
`

export const SubHeaderRightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
