import { Marketplace } from '../generated/graphql'
import { getMarketplaceCurrency } from './getMarketplaceCurrency'

export function convertToEuro(amount: number, marketplace: Marketplace) {
  const currency = getMarketplaceCurrency(marketplace)
  const rate = exchangeRates[currency]

  if (!rate) {
    throw new Error(`No exchange rate for currency ${currency}`)
  }

  return amount / rate
}

export const exchangeRates = {
  EUR: 1,
  GBP: 0.84,
  PLN: 4.18,
  SEK: 10.91,
  USD: 1.08,
  CAD: 1.55,
  MXN: 21.65,
} as const
