import {
  Button,
  Collapse,
  Form,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from 'antd'
import { Check } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import {
  GetAllAmazonListingsByCompanyAndMarketplaceQuery,
  Marketplace,
  SponsoredProductsCampaignTargetingType,
} from '../../../../../generated/graphql'
import { useCurrencySymbol } from '../../../../../views/ppc/helpers/useCurrencySymbol'
import CreateSponsoredProductsAdGroupFormFields from '../../adGroup/create/CreateSponsoredProductsAdGroupFormFields'
import CreateSponsoredProductsCampaignKeywordsFormFields from '../../keyword/create/CreateSponsoredProductsKeywordsWidget/CreateSponsoredProductsKeywordsFormFields'
import SelectSponsoredProductsProductAdsFormFields from '../../productAds/create/CreateSponsoredProductsProductAdsWidget/SelectSponsoredProductsProductAdsFormFields'
import CreateSponsoredProductsManualTargetsFormFields from '../../target/create/CreateSponsoredProductsManualTargetsWidget/CreateSponsoredProductsManualTargetsFormFields'
import CreateSponsoredProductsCampaignFormFields from './CreateSponsoredProductsCampaignFormFields'
import CreateSponsoredProductsCampaignNegativeKeywordsFormFields from './CreateSponsoredProductsCampaignNegativeKeywordsFormFields'
import CreateSponsoredProductsCampaignNegativeProductTargetingFormFields from './CreateSponsoredProductsCampaignNegativeProductTargetingFormFields'
import CreateSponsoredProductsCampaignAutomaticTargetsFormFields from './automaticTargets/CreateSponsoredProductsCampaignAutomaticTargetsFormFields'
import { AutomaticTargetingBiddingType } from './automaticTargets/interfaces'

type CreateSponsoredProductsCampaignWithChildrenFormProps = {
  onFinish: (values: any) => void
  amazonListings: GetAllAmazonListingsByCompanyAndMarketplaceQuery['amazonListings']
  campaignAndAdGroupNamePrefix: string
  marketplace: Marketplace
}

enum ManualTargetingSubType {
  KEYWORD = 'keyword',
  PRODUCT = 'product',
}

const CreateSponsoredProductsCampaignWithChildrenForm = ({
  onFinish,
  amazonListings,
  campaignAndAdGroupNamePrefix,
  marketplace,
}: CreateSponsoredProductsCampaignWithChildrenFormProps) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }
  const [targetingType, setTargetingType] = useState<SponsoredProductsCampaignTargetingType>(
    SponsoredProductsCampaignTargetingType.MANUAL
  )
  const [manualTargetingSubType, setManualTargetingSubType] = useState(
    ManualTargetingSubType.KEYWORD
  )
  const [selectedAmazonListings, setSelectedAmazonListings] = useState<
    GetAllAmazonListingsByCompanyAndMarketplaceQuery['amazonListings']
  >([])

  const handleChangeManualTargetingSubType = (e: RadioChangeEvent) => {
    setManualTargetingSubType(e.target.value)
  }
  const currencySymbol = useCurrencySymbol()

  const handleOnFinish = async (values: any) => {
    // Validate that there is at least one productAdSku
    if (!values.productAdSkus?.length) {
      toast.error('Cannot create the campaign', {
        description: 'At least one product must be added',
      })
      return
    }

    if (targetingType === SponsoredProductsCampaignTargetingType.MANUAL) {
      if (manualTargetingSubType === ManualTargetingSubType.KEYWORD) {
        if (!values.keywords?.length) {
          toast.error('Cannot create the campaign', {
            description: 'At least one keyword must be added',
          })
          return
        }

        const {
          automaticTargets,
          automaticTargetingBiddingType,
          automaticTargetingDefaultBid,
          negativeProductTargetingAsins,
          productTargets,
          ...payload
        } = values

        await onFinish(payload)
      } else {
        if (!values.productTargets?.length) {
          toast.error('Cannot create the campaign', {
            description: 'At least one product target must be added',
          })
          return
        }

        const {
          automaticTargets,
          automaticTargetingBiddingType,
          automaticTargetingDefaultBid,
          keywords,
          negativeKeywords,
          ...payload
        } = values

        await onFinish(payload)
      }
    } else {
      if (
        values.automaticTargetingBiddingType === AutomaticTargetingBiddingType.INDIVIDUAL &&
        !values.automaticTargets?.length
      ) {
        toast.error('Cannot create the campaign', {
          description:
            'At least one automatic target must be added when setting bids by targeting group',
        })
        return
      }

      const { keywords, productTargets, ...payload } = values

      await onFinish(payload)
    }

    form.resetFields()
    navigate('/amazon/ppc')
  }

  const handleSetSelectedAmazonListings = (
    listings: GetAllAmazonListingsByCompanyAndMarketplaceQuery['amazonListings']
  ) => {
    setSelectedAmazonListings(listings)
  }

  return (
    <>
      <Form
        name="create-sponsored-products-campaign"
        form={form}
        onFinish={handleOnFinish}
        scrollToFirstError
        style={{ width: '100%', maxWidth: '840px' }}
        {...formLayout}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Collapse defaultActiveKey={['1', '2', '3', '4', '5', '6', '7']}>
            <Collapse.Panel header="Campaign Settings" key="1">
              <CreateSponsoredProductsCampaignFormFields
                setTargetingType={setTargetingType}
                targetingType={targetingType}
                campaignNamePrefix={campaignAndAdGroupNamePrefix}
                currencySymbol={currencySymbol}
              />
            </Collapse.Panel>
            <Collapse.Panel header="Ad Group Settings" key="2">
              <CreateSponsoredProductsAdGroupFormFields
                adGroupNamePrefix={campaignAndAdGroupNamePrefix}
              />
            </Collapse.Panel>
            <Collapse.Panel header="Products" key="3">
              <SelectSponsoredProductsProductAdsFormFields
                amazonListings={amazonListings}
                form={form}
                handleSetSelectedAmazonListings={handleSetSelectedAmazonListings}
              />
            </Collapse.Panel>
            {targetingType === SponsoredProductsCampaignTargetingType.MANUAL ? (
              <>
                <Collapse.Panel header="Targeting" key="4">
                  <Form.Item name="targeting" initialValue={ManualTargetingSubType.KEYWORD}>
                    <Radio.Group onChange={handleChangeManualTargetingSubType}>
                      <Space direction="vertical">
                        <Radio value={ManualTargetingSubType.KEYWORD}>
                          <Typography.Text strong>Keyword targeting</Typography.Text> - Choose
                          keywords to help your products appear in shopper searches.
                        </Radio>
                        <Radio value={ManualTargetingSubType.PRODUCT}>
                          <Typography.Text strong>Product targeting</Typography.Text> - Choose
                          specific products or categories to target your ads.
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Collapse.Panel>
                {manualTargetingSubType === ManualTargetingSubType.KEYWORD ? (
                  <>
                    <Collapse.Panel header="Keyword targeting" key="5">
                      <CreateSponsoredProductsCampaignKeywordsFormFields
                        form={form}
                        currencySymbol={currencySymbol}
                      />
                    </Collapse.Panel>
                    <Collapse.Panel header="Negative keyword targeting (optional)" key="6">
                      <CreateSponsoredProductsCampaignNegativeKeywordsFormFields form={form} />
                    </Collapse.Panel>
                  </>
                ) : (
                  <>
                    <Collapse.Panel header="Product targeting" key="5">
                      <CreateSponsoredProductsManualTargetsFormFields
                        form={form}
                        marketplace={marketplace}
                        selectedProductAsins={selectedAmazonListings.map(
                          (listing) => listing.amazonProduct?.asin || ''
                        )}
                      />
                    </Collapse.Panel>
                    <Collapse.Panel header="Negative product targeting (optional)" key="6">
                      <CreateSponsoredProductsCampaignNegativeProductTargetingFormFields
                        form={form}
                      />
                    </Collapse.Panel>
                  </>
                )}
              </>
            ) : (
              <>
                <Collapse.Panel header="Automatic Targeting" key="5">
                  <CreateSponsoredProductsCampaignAutomaticTargetsFormFields
                    form={form}
                    currencySymbol={currencySymbol}
                  />
                </Collapse.Panel>
                <Collapse.Panel header="Negative keyword targeting (optional)" key="6">
                  <CreateSponsoredProductsCampaignNegativeKeywordsFormFields form={form} />
                </Collapse.Panel>
                <Collapse.Panel header="Negative product targeting (optional)" key="7">
                  <CreateSponsoredProductsCampaignNegativeProductTargetingFormFields form={form} />
                </Collapse.Panel>
              </>
            )}
          </Collapse>
          <Row justify="end">
            <Form.Item>
              <Popconfirm
                title="New Campaigns are created immediately on Amazon. Please confirm that you would like to continue."
                okText="Continue"
                onConfirm={form.submit}
                cancelText="Go back"
                placement="topRight"
                overlayInnerStyle={{ maxWidth: '400px' }}
              >
                <Button type="primary" icon={<Check size={16} />}>
                  Create campaign
                </Button>
              </Popconfirm>
            </Form.Item>
          </Row>
        </Space>
      </Form>
    </>
  )
}

export default CreateSponsoredProductsCampaignWithChildrenForm
