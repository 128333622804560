import { useMutation } from '@apollo/client'
import { Alert, Button, Form, Modal, Select, Space } from 'antd'
import { SquarePen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import {
  GetProductDocument,
  TaxCode,
  UpdateProductTaxCategoryDocument,
} from '../../../generated/graphql'

type EditProductTaxCategoryFormInstance = {
  taxCategory: TaxCode
}

type EditProductTaxCategoryModalProps = {
  uuid: string
  taxCategory: TaxCode | null
}

export const EditProductTaxCategoryModal = ({
  uuid,
  taxCategory,
}: EditProductTaxCategoryModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { t } = useTranslation('inventory')
  const [form] = Form.useForm<EditProductTaxCategoryFormInstance>()

  const [updateProductTaxCategory] = useMutation(UpdateProductTaxCategoryDocument)

  const handleSubmit = async (values: EditProductTaxCategoryFormInstance) => {
    setSubmitting(true)

    try {
      await updateProductTaxCategory({
        variables: {
          uuid,
          taxCategoryName: values.taxCategory,
        },
        optimisticResponse: {
          updateProduct: {
            taxCategory: {
              name: values.taxCategory,
            },
          },
        },
        update: (cache, { data }) => {
          const updatedProduct = data?.updateProduct
          const cachedQuery = cache.readQuery({
            query: GetProductDocument,
            variables: { uuid },
          })

          if (updatedProduct && cachedQuery && updatedProduct.taxCategory?.name) {
            cache.writeQuery({
              query: GetProductDocument,
              variables: { uuid },
              data: {
                product: {
                  ...cachedQuery.product,
                  taxCategory: {
                    name: updatedProduct.taxCategory.name,
                  },
                },
              },
            })
          }
        },
        refetchQueries: [{ query: GetProductDocument, variables: { uuid } }],
      })

      toast.success(t('product.details.product.editTaxCategoryModal.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('shared.error.message', { ns: 'translation' }))
    } finally {
      setOpen(false)
      setSubmitting(false)
    }
  }

  const taxCodes = Object.values(TaxCode)

  const options = taxCodes.map((taxCode) => ({
    value: taxCode,
    label: taxCode,
  }))

  return (
    <>
      <Button
        type="text"
        icon={<SquarePen size={16} />}
        size="small"
        onClick={() => setOpen(true)}
      />
      <Modal
        title={t('product.details.product.editTaxCategoryModal.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={submitting}
        destroyOnClose
      >
        <Space direction="vertical">
          <Alert type="info" message={t('product.details.product.editTaxCategoryModal.note')} />
          <Form<EditProductTaxCategoryFormInstance>
            form={form}
            initialValues={{ taxCategory }}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="taxCategory"
              label={t('product.details.product.editTaxCategoryModal.label')}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder={t('product.details.product.editTaxCategoryModal.placeholder')}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (!option) {
                    return false
                  }

                  return option.label.toLowerCase().includes(input.toLowerCase())
                }}
                options={options}
                defaultValue={taxCategory}
              />
            </Form.Item>
          </Form>
        </Space>
      </Modal>
    </>
  )
}
