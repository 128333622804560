import { TFunction } from 'i18next'
import { ImportError } from '../components/AmazonInboundShipmentPlanImportModal'
import { AvailableInventory } from './prepareAvailableInventory'

type ValidationSuccess = {
  error: undefined
  result: {
    matchingInventory: AvailableInventory
    smallestPackagingUnit: NonNullable<AvailableInventory['packagingUnits']>[number]
  }
}

type ValidationError = {
  error: ImportError
  result: undefined
}

type ValidationResult = ValidationSuccess | ValidationError

export const validateAndGetShipmentPlanInventory = (
  currentIndex: number,
  sku: string | undefined,
  inventoryMap: Map<string, AvailableInventory>,
  t: TFunction<['inventory']>
): ValidationResult => {
  if (!sku) {
    return {
      error: {
        sku: t('amazonInboundShipmentPlan.import.error.alert.row', { row: currentIndex + 1 }),
        errors: [t('amazonInboundShipmentPlan.import.error.alert.skuMissing')],
      },
      result: undefined,
    }
  }

  const matchingInventory = inventoryMap.get(sku)
  if (!matchingInventory) {
    return {
      error: {
        sku,
        errors: [t('amazonInboundShipmentPlan.import.error.alert.noMatchingInventory')],
      },
      result: undefined,
    }
  }

  // NOTE: For now we allow wholesale orders to be imported even if they are not approved yet
  // if (!matchingInventory.hasApprovedWholesaleOrder) {
  //   return undefined
  // }

  const smallestPackagingUnit = matchingInventory.packagingUnits.find((unit) => {
    return unit.quantity === 1
  })
  if (!smallestPackagingUnit) {
    return {
      error: {
        sku,
        errors: [t('amazonInboundShipmentPlan.import.error.alert.noSmallestPackagingUnit')],
      },
      result: undefined,
    }
  }

  return {
    error: undefined,
    result: {
      matchingInventory,
      smallestPackagingUnit,
    },
  }
}
