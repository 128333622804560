import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { Flex, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components/FormattedDate'
import { Table } from '../../../../components/Table/Table'
import { AmazonOrderStatus } from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { AmazonListingOrderTableRecord } from '../helpers/prepareAmazonListingOrderData'

type AmazonListingOrderTableProps = {
  orders: AmazonListingOrderTableRecord[]
}

export const AmazonListingOrderTable = ({ orders }: AmazonListingOrderTableProps) => {
  const { t } = useTranslation(['inventory', 'translation'])
  const [columnDefs] = useState<
    (ColDef<AmazonListingOrderTableRecord> | ColGroupDef<AmazonListingOrderTableRecord>)[]
  >([
    {
      field: 'quantity',
      headerName: t('product.details.orders.table.headers.quantity'),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'itemPrice',
      headerName: t('product.details.orders.table.headers.price'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          AmazonListingOrderTableRecord,
          AmazonListingOrderTableRecord['itemPrice']
        >
      ) => {
        if (!params.value?.amount || !params.value?.currency) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Typography.Text>
            {formatMoney(params.value.amount, params.value.currency)}
          </Typography.Text>
        )
      },
    },
    {
      field: 'amazonOrder.status',
      headerName: t('product.details.orders.table.headers.status'),
      cellRenderer: (params: ICellRendererParams<AmazonListingOrderTableRecord>) => {
        if (
          params.data?.amazonOrder.status === AmazonOrderStatus.UNSHIPPED ||
          params.data?.amazonOrder.status === AmazonOrderStatus.PENDING
        ) {
          return <Tag color="warning">{t('shared.order.pending', { ns: 'translation' })}</Tag>
        } else if (params.data?.amazonOrder.status === AmazonOrderStatus.SHIPPED) {
          return <Tag color="success">{t('shared.order.shipped', { ns: 'translation' })}</Tag>
        } else if (params.data?.amazonOrder.status === AmazonOrderStatus.CANCELED) {
          return <Tag color="error">{t('shared.order.canceled', { ns: 'translation' })}</Tag>
        } else {
          return <Typography.Text type="secondary">-</Typography.Text>
        }
      },
    },
    {
      field: 'amazonOrder.purchaseDate',
      headerName: t('product.details.orders.table.headers.purchaseDate'),
      filter: false,
      initialSort: 'desc',
      filterValueGetter: (params) => {
        if (params.data) {
          return dayjs(params.data.amazonOrder.purchaseDate).startOf('day').toDate()
        }
        return null
      },
      cellRenderer: (params: ICellRendererParams<AmazonListingOrderTableRecord>) => {
        return (
          <Flex align="center" justify="center">
            <FormattedDate date={dayjs(params.value)} />
          </Flex>
        )
      },
    },
  ])

  return (
    <Table<AmazonListingOrderTableRecord>
      columnDefs={columnDefs}
      maxTableRows={5}
      rowData={orders}
      suppressContextMenu
    />
  )
}
