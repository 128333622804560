/**
 * Converts a file to a base64 string.
 * @param {File} file The file to convert.
 */
export async function convertFileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()

      reader.onloadend = (event) => {
        const result = event.target?.result

        if (result) {
          resolve(result)
        } else {
          reject(new Error('Failed to convert file to base64.'))
        }
      }

      reader.onerror = (error) => {
        reject(error)
      }

      // Read the file and encode it as base64.
      reader.readAsDataURL(file)
    } catch (error) {
      console.error('Error converting file to base64:', error)
      reject(error)
    }
  })
}
