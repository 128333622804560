import { DatePicker, Form } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useGlobalStore } from '../../../../../../../stores/useGlobalStore'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'

export const WholesaleOrderBillingDocumentIssueDatePicker = () => {
  const user = useGlobalStore((state) => state.user)

  const { t } = useTranslation('wholesale-order-billing-document', {
    keyPrefix: 'issueDate',
  })

  return (
    <Form.Item<CreateWholesaleOrderBillingDocumentFormValues>
      name="issueDate"
      label={t('label')}
      tooltip={t('description')}
      rules={[{ required: true, message: t('error.required') }]}
    >
      <DatePicker
        format="DD.MM.YYYY"
        disabledDate={(current) => {
          if (user?.isAdmin) {
            return false
          }

          return (
            current && (current > dayjs().endOf('day') || current < dayjs().subtract(4, 'days'))
          )
        }}
        style={{ width: '100%' }}
      />
    </Form.Item>
  )
}
