import { useTheme } from '@emotion/react'
import MagicBell, { FloatingNotificationInbox } from '@magicbell/magicbell-react'
import { Bell } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { MagicBellContainer } from './NotificationBell.style'

export const NotificationBell = () => {
  const user = useGlobalStore((state) => state.user)

  const { t } = useTranslation()
  const theme = useTheme()

  if (!user) {
    return null
  }

  return (
    <MagicBellContainer>
      <MagicBell
        apiKey={import.meta.env.VITE_MAGICBELL_API_KEY}
        userEmail={user?.email || ''}
        theme={{
          header: {
            backgroundColor: theme.colors.base[3],
            borderColor: theme.colors.base[6],
            borderRadius: '0.5rem',
            fontSize: '1rem',
            padding: '1rem 1.5rem',
            textColor: theme.colors.text,
          },
          container: {
            backgroundColor: theme.colors.base[3],
            borderColor: theme.colors.base[6],
            textColor: theme.colors.text,
          },
          footer: {
            backgroundColor: theme.colors.base[3],
            borderColor: theme.colors.base[6],
            borderRadius: '0.5rem',
            padding: '1rem 1.5rem',
          },
          notification: {
            default: {
              backgroundColor: theme.colors.primary,
              title: {
                fontSize: '0.875rem',
              },
              textColor: theme.colors.text,
            },
            unseen: {
              state: {
                color: theme.colors.primary,
              },
            },
            unread: {
              state: {
                color: theme.colors.primary,
              },
            },
          },
        }}
        BellIcon={<Bell size={16} />}
        locale={t('shared.languageCode')}
      >
        {(props) => (
          <FloatingNotificationInbox
            {...props}
            closeOnNotificationClick={false}
            height={window ? window.innerHeight - 80 : 500}
            hideArrow
            placement="bottom-end"
          />
        )}
      </MagicBell>
    </MagicBellContainer>
  )
}
