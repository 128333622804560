import { useState } from 'react'
import { AttachmentProps } from '../ticket.interface'
import { useLocation, useNavigate } from 'react-router'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { useTranslation } from 'react-i18next'
import { Button, Form, Row, Typography } from 'antd'
import { useMutation } from '@apollo/client'
import { SendSupportTicketDocument } from '../../../generated/graphql'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { createZendeskTicketOptions } from '../helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../ticket.enum'
import { toast } from 'sonner'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { TicketCreationCard } from '../styles/TicketCreateViewStyles'
import QuillTextarea from '../../../components/Ticket/QuillTextarea'
import { PersonalNoteInput } from '../components/form/PersonalNoteInput'
import { UploadZendeskFile } from '../../../components/Ticket/UploadFile'
import { UploadProps } from 'antd/es/upload/interface'

type FormInstance = {
  messageInput: string | undefined
  personalNotes: string | undefined
  upload: UploadProps
}

export const PriceloopTicketView = () => {
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const location = useLocation()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()

  const { t, ready, i18n } = useTranslation(['ticket'], {
    keyPrefix: 'listing.priceloopTicketView',
  })
  const { t: generalTranslation } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [form] = Form.useForm()

  const [sendSupportTicketRequest, { loading }] = useMutation(SendSupportTicketDocument)

  if (!ready) {
    return <LoadingSpinner />
  }

  const onFinish = async (values: FormInstance) => {
    const tokens = attachments.map((attachment) => {
      return attachment.token
    })

    const options = createZendeskTicketOptions({
      categoryTitle: location.state.categoryTitleTag,
      comment: values.messageInput || 'Priceloop Ticket',
      language: i18n.language,
      mainCategory: TicketMainCategory.LISTINGS,
      subject: t('viewTitle'),
      ticketCategory: TicketCategory.LISTINGS_PRICELOOP,
      sellerId: selectedCompany?.sellerId,
      personalNotes: values.personalNotes,
      attachments: tokens,
    })

    try {
      if (selectedCompany !== null && selectedCompany.uuid) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })
        toast.success(generalTranslation('notification.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      console.error(error)
      toast.error(generalTranslation('notification.error'), { description: error.message })
    }
  }

  return (
    <>
      <SubHeader withBackButton />
      <ViewContainer centered>
        <TicketCreationCard title={t('ticketDescriptionTitle')}>
          <Form<FormInstance>
            form={form}
            onFinish={onFinish}
            name="priceloop-listing-ticket"
            layout="vertical"
          >
            <Typography.Paragraph>{t('ticketDescriptionSubtitle')}</Typography.Paragraph>
            <Typography.Paragraph>{t('ticketListDescription')}</Typography.Paragraph>
            <ul>
              <li>{t('ticketListItem1')}</li>
              <li>{t('ticketListItem2')}</li>
              <li>{t('ticketListItem3')}</li>
              <li>{t('ticketListItem4')}</li>
              <li>{t('ticketListItem5')}</li>
            </ul>
            <Typography.Paragraph>{t('spacegoatsSignature')}</Typography.Paragraph>
            <Form.Item name="messageInput">
              <QuillTextarea />
            </Form.Item>
            <PersonalNoteInput />
            <Form.Item
              name="upload"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || value.fileList.length < 1) {
                      return Promise.reject(t('fileUploadError'))
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
            </Form.Item>
            <Row justify="end">
              <Button type="primary" htmlType="submit" loading={loading}>
                {t('sendButtonText')}
              </Button>
            </Row>
          </Form>
        </TicketCreationCard>
      </ViewContainer>
    </>
  )
}
