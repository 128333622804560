import { useTheme } from '@emotion/react'
import { Col, Row, Segmented, Spin } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart'
import { DatedPerformanceOverview } from '../../helpers/preparePerformanceOverview'
import { DashboardBarChartTooltip } from './DashboardBarChartTooltip'
import { DataType } from './DashboardCardPerformanceChart'
import { PerformanceOverviewModal } from './PerformanceOverviewModal'

type DashboardChartProps = {
  chartData: DatedPerformanceOverview[]
  loading: boolean
}

export const DashboardChart = ({ chartData, loading }: DashboardChartProps) => {
  const [dataType, setDataType] = useState<string | number>(DataType.ORDERS)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalPayload, setModalPayload] = useState<DatedPerformanceOverview>()

  const { t } = useTranslation(['dashboard'])
  const theme = useTheme()

  const handleBarClick: CategoricalChartFunc = (nextState) => {
    const payload: DatedPerformanceOverview | undefined = nextState.activePayload?.[0]?.payload

    if (payload?.orders.length) {
      setModalPayload(payload)
      setModalOpen(true)
    }
  }

  return (
    <>
      <Row justify="center" style={{ marginBottom: '1.5rem' }}>
        <Col flex="300px">
          <Segmented
            block
            disabled={loading}
            value={dataType}
            onChange={setDataType}
            options={[
              { value: DataType.ORDERS, label: t('performance.shared.orders') },
              { value: DataType.REVENUE, label: t('performance.shared.revenue') },
            ]}
          />
        </Col>
      </Row>
      <Spin spinning={loading}>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={chartData}
            onClick={handleBarClick}
            margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
          >
            <Tooltip
              content={<DashboardBarChartTooltip />}
              cursor={false}
              wrapperStyle={{ outline: 'none' }}
            />
            <CartesianGrid
              stroke={theme.colors.text}
              strokeDasharray="8"
              strokeWidth={0.5}
              opacity={0.2}
            />
            {dataType === DataType.ORDERS ? (
              <Bar
                name={t('performance.performanceChart.legend.shipped')}
                key="totalShipped"
                dataKey="totalShipped"
                stackId="primary"
                fill={theme.colors.success}
                opacity={0.8}
                cursor="pointer"
              />
            ) : null}
            {dataType === DataType.ORDERS ? (
              <Bar
                name={t('performance.performanceChart.legend.pending')}
                key="totalPending"
                dataKey="totalPending"
                stackId="primary"
                fill={theme.colors.warning}
                opacity={0.8}
                cursor="pointer"
              />
            ) : null}
            {dataType === DataType.ORDERS ? (
              <Bar
                name={t('performance.performanceChart.legend.canceled')}
                key="totalCanceled"
                dataKey="totalCanceled"
                stackId="primary"
                fill={theme.colors.error}
                opacity={0.8}
                cursor="pointer"
              />
            ) : null}
            {dataType === DataType.REVENUE ? (
              <Bar
                key="totalRevenue"
                dataKey="totalRevenue"
                fill={theme.colors.primary}
                cursor="pointer"
              />
            ) : null}
            <XAxis dataKey="date" tickMargin={8} stroke={theme.colors.text} opacity={0.6} />
            <YAxis
              allowDecimals={false}
              min={0}
              tickMargin={8}
              stroke={theme.colors.text}
              opacity={0.6}
            />
            {dataType === DataType.ORDERS ? (
              <Legend layout="horizontal" verticalAlign="top" align="center" />
            ) : null}
          </BarChart>
        </ResponsiveContainer>
      </Spin>
      <PerformanceOverviewModal open={modalOpen} setOpen={setModalOpen} payload={modalPayload} />
    </>
  )
}
