import { useTheme } from '@emotion/react'
import {
  CellClassParams,
  CellEditRequestEvent,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GetRowIdParams,
  ICellRendererParams,
  ValueGetterParams,
  ValueParserParams,
} from 'ag-grid-community'
import { Form, Space, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { Check, CircleAlert, ImageOff, Pen, X } from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridImage, GridImageFallback } from '../../../../components/Table/GridImage'
import { Table } from '../../../../components/Table/Table'
import { LabelType, PrepInstruction, ShipmentType } from '../../../../generated/graphql'
import { formatDecimal } from '../../../../helpers/formatDecimal'
import { ShipmentKind } from '../types'
import {
  AmazonInboundShipmentPlanCreateFormInstance,
  SelectedInventory,
} from './AmazonInboundShipmentPlanCreateForm'

export const MAX_ALLOWED_PACKAGE_WEIGHT_IN_KG = 22

const cartonTypeValues = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

type AmazonInboundShipmentPlanQuantityTableRecord = SelectedInventory

export const AmazonInboundShipmentPlanQuantityTable = () => {
  const { t } = useTranslation('inventory')
  const theme = useTheme()
  const form = Form.useFormInstance<AmazonInboundShipmentPlanCreateFormInstance>()

  const shipmentKind: ShipmentKind = form.getFieldValue('shipmentKind')
  const selectedInventory: AmazonInboundShipmentPlanCreateFormInstance['selectedInventory'] =
    form.getFieldValue('selectedInventory')
  const rowData = selectedInventory

  const [columnDefs] = useState<
    (
      | ColDef<AmazonInboundShipmentPlanQuantityTableRecord>
      | ColGroupDef<AmazonInboundShipmentPlanQuantityTableRecord>
    )[]
  >([
    {
      field: 'image',
      headerName: '',
      minWidth: 64,
      maxWidth: 64,
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['image']
        >
      ) => {
        const src = params.value ?? null

        return src ? (
          <GridImage src={src} width={40} height={40} preview={false} />
        ) : (
          <GridImageFallback>
            <ImageOff />
          </GridImageFallback>
        )
      },
    },
    {
      field: 'name',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.name'),
    },
    {
      field: 'ean',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.ean'),
      hide: true,
    },
    {
      field: 'asin',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.asin'),
    },
    {
      field: 'fnsku',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.fnsku'),
      hide: true,
    },
    {
      field: 'sku',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.sku'),
    },
    {
      field: 'internalSku',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.internalSku'),
    },
    {
      colId: 'labelingRequired',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.labelingRequired'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlanQuantityTableRecord>) => {
        return params.data?.prepInstructions.includes(PrepInstruction.LABELING)
      },
      cellRenderer: (params: ICellRendererParams<AmazonInboundShipmentPlanQuantityTableRecord>) => {
        return params.value ? <Check size={16} /> : <X size={16} />
      },
    },
    {
      field: 'labeledByAmazon',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.labeledByAmazon'),
      editable: (
        params: EditableCallbackParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['labeledByAmazon']
        >
      ) => {
        return params.data?.labelType === LabelType.FNSKU
      },
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      field: 'quantityInPackagingUnit',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.packagingUnitQuantity'),
      editable: true,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 1,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['quantityInPackagingUnit']
        >
      ) => {
        const newValue = +params.newValue

        if (isNaN(newValue) || newValue < 1) {
          return params.oldValue
        }

        return newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['quantityInPackagingUnit']
        >
      ) => {
        return (
          <Space>
            <Pen size={16} />
            {params.value ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['quantityInPackagingUnit']
        >
      ) => {
        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      field: 'numberOfPackagingUnits',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.numberOfPackagingUnits'),
      editable: true,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 1,
        max: 200,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['numberOfPackagingUnits']
        >
      ) => {
        const newValue = +params.newValue

        if (isNaN(newValue) || newValue < 1) {
          return params.oldValue
        }

        return newValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['numberOfPackagingUnits']
        >
      ) => {
        return (
          <Space>
            <Pen size={16} />
            {params.value ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['numberOfPackagingUnits']
        >
      ) => {
        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      colId: 'totalUnits',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.totalUnits'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlanQuantityTableRecord>) => {
        if (
          !params.data ||
          !params.data.quantityInPackagingUnit ||
          !params.data.numberOfPackagingUnits
        ) {
          return 0
        }

        return params.data.quantityInPackagingUnit * params.data.numberOfPackagingUnits
      },
      cellStyle: (
        params: CellClassParams<AmazonInboundShipmentPlanQuantityTableRecord, number>
      ) => {
        const recommendedQuantity = params.data?.recommendedQuantity

        if (
          params.value === null ||
          params.value === undefined ||
          recommendedQuantity === null ||
          recommendedQuantity === undefined
        ) {
          return { backgroundColor: 'initial' }
        }

        if (recommendedQuantity === 0) {
          return { backgroundColor: `${theme.colors.error}40` }
        }

        const difference = params.value - recommendedQuantity
        const percentageDifference = (difference / recommendedQuantity) * 100

        if (percentageDifference < -20) {
          return { backgroundColor: `${theme.colors.warning}40` }
        }

        if (percentageDifference > 30) {
          return { backgroundColor: `${theme.colors.warning}40` }
        }

        return { backgroundColor: `${theme.colors.success}40` }
      },
    },
    {
      field: 'recommendedQuantity',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.recommendedQuantity'),
      filter: false,
      cellRenderer: (
        params: ICellRendererParams<AmazonInboundShipmentPlanQuantityTableRecord, number>
      ) => {
        if (params.value === null || params.value === undefined) {
          return t('shared.notAvailable', { ns: 'translation' })
        }

        return (
          <Space>
            {params.value}
            {params.value === 0 && (
              <Tooltip
                title={t(
                  'amazonInboundShipmentPlan.create.form.quantity.table.recommendedQuantityTooltip'
                )}
              >
                <CircleAlert size={16} color={theme.colors.warning} />
              </Tooltip>
            )}
          </Space>
        )
      },
    },
    {
      colId: 'totalPackageWeight',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.totalPackageWeight'),
      valueGetter: (params: ValueGetterParams<AmazonInboundShipmentPlanQuantityTableRecord>) => {
        if (
          !params.data ||
          !params.data.quantityInPackagingUnit ||
          !params.data.smallestPackagingUnit.weightInGram
        ) {
          return null
        }

        const kilograms = params.data.smallestPackagingUnit.weightInGram / 1000

        return params.data.quantityInPackagingUnit * kilograms
      },
      cellRenderer: (
        params: ICellRendererParams<AmazonInboundShipmentPlanQuantityTableRecord, number | null>
      ) => {
        if (params.value === null || params.value === undefined) {
          return t('shared.notAvailable', { ns: 'translation' })
        }

        const formattedWeight = formatDecimal(params.value)

        return `${formattedWeight} kg`
      },
      cellStyle: (
        params: CellClassParams<AmazonInboundShipmentPlanQuantityTableRecord, number>
      ) => {
        if (params.value === null || params.value === undefined) {
          return { backgroundColor: 'initial' }
        }

        const shippingMode: AmazonInboundShipmentPlanCreateFormInstance['shippingMode'] =
          form.getFieldValue('shippingMode')

        if (shippingMode === ShipmentType.LTL) {
          return { backgroundColor: 'initial' }
        }

        const isProductExceedingMaxWeight =
          params.data?.smallestPackagingUnit.weightInGram &&
          params.data.smallestPackagingUnit.weightInGram > MAX_ALLOWED_PACKAGE_WEIGHT_IN_KG * 1000

        if (isProductExceedingMaxWeight) {
          return { backgroundColor: 'initial' }
        }

        return params.value > MAX_ALLOWED_PACKAGE_WEIGHT_IN_KG
          ? { backgroundColor: `${theme.colors.error}40` }
          : { backgroundColor: 'initial' }
      },
    },
    {
      field: 'bestBeforeDate',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.bestBeforeDate'),
      headerTooltip: t(
        'amazonInboundShipmentPlan.create.form.quantity.table.bestBeforeDateTooltip'
      ),
      editable: true,
      cellEditor: 'agDateStringCellEditor',
      cellEditorParams: {
        min: dayjs().add(180, 'days').format('YYYY-MM-DD'),
        max: dayjs().add(10, 'years').format('YYYY-MM-DD'),
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['bestBeforeDate']
        >
      ) => {
        return (
          <Tooltip
            title={t('amazonInboundShipmentPlan.create.form.quantity.table.bestBeforeDateTooltip')}
          >
            <Space>
              <Pen size={16} />
              {params.value
                ? dayjs(params.value).format('DD.MM.YYYY')
                : t('shared.button.edit', { ns: 'translation' })}
            </Space>
          </Tooltip>
        )
      },
    },
    {
      field: 'cartonType',
      headerName: t('amazonInboundShipmentPlan.create.form.quantity.table.cartonType'),
      hide: form.getFieldValue('shipmentKind') !== ShipmentKind.MIXED,
      editable: form.getFieldValue('shipmentKind') === ShipmentKind.MIXED,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: cartonTypeValues,
      },
      valueParser: (
        params: ValueParserParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['cartonType']
        >
      ) => {
        const newValue = params.newValue?.toUpperCase()
        return cartonTypeValues.includes(newValue) ? newValue : params.oldValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['bestBeforeDate']
        >
      ) => {
        return shipmentKind === ShipmentKind.CASE ? (
          t('shared.notAvailable', { ns: 'translation' })
        ) : (
          <Space>
            <Pen size={16} />
            {params.value ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          AmazonInboundShipmentPlanQuantityTableRecord,
          AmazonInboundShipmentPlanQuantityTableRecord['cartonType']
        >
      ) => {
        if (shipmentKind === ShipmentKind.CASE) {
          return { backgroundColor: 'initial' }
        }

        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
  ])

  const defaultColDef = useMemo<ColDef<AmazonInboundShipmentPlanQuantityTableRecord>>(() => {
    return {
      filter: false,
    }
  }, [])

  const getRowId = useMemo(
    () => (params: GetRowIdParams<AmazonInboundShipmentPlanQuantityTableRecord>) =>
      params.data.uuid,
    []
  )

  const handleCellEditRequest = useCallback(
    async (event: CellEditRequestEvent<AmazonInboundShipmentPlanQuantityTableRecord>) => {
      const field = event.colDef.field

      if (!field) {
        return false
      }

      if (event.newValue === event.oldValue) {
        return false
      }

      try {
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: event.newValue }],
        })

        const currentSelectedInventory: AmazonInboundShipmentPlanCreateFormInstance['selectedInventory'] =
          form.getFieldValue('selectedInventory')

        form.setFieldsValue({
          selectedInventory: currentSelectedInventory.map((item) => {
            return item.uuid === event.data.uuid ? { ...item, [field]: event.newValue } : item
          }),
        })
      } catch (error) {
        console.error(error)
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: event.oldValue }],
        })
      }
    },
    []
  )

  return (
    <Table<AmazonInboundShipmentPlanQuantityTableRecord>
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      getRowId={getRowId}
      maintainColumnOrder
      maxTableRows={10}
      onCellEditRequest={handleCellEditRequest}
      readOnlyEdit
      rowData={rowData}
      showColumnChooser
      showUniversalSearch
    />
  )
}
