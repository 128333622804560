import { Dropdown, MenuProps } from 'antd'
import { useFeatureFlag } from 'configcat-react'
import { ChevronDown } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { LinkButton } from '../../components/LinkButton'
import { CompanyType } from '../../generated/graphql'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { WholesaleOrderTabs } from './components/WholesaleOrderTabs'

export const WholesaleOrdersView = () => {
  const user = useGlobalStore((state) => state.user)!
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const navigate = useNavigate()
  const { t } = useTranslation('wholesale-order')

  const { value: featureFlag } = useFeatureFlag('wholesaleOrderTemplates', false, {
    identifier: user.cognitoUsername,
    email: user.email,
    custom: {},
  })

  const isWholesaler = selectedCompany.type === CompanyType.WHOLESALER

  const menuProps: MenuProps = {
    items: [
      {
        key: 'template',
        label: t('wholesaleOrdersView.button.createWholesaleOrderTemplate'),
        onClick: () => navigate('/wholesale/orders/create?template=true'),
      },
    ],
  }

  return (
    <>
      <SubHeader
        heading={t('wholesaleOrdersView.heading')}
        rightContent={
          featureFlag ? (
            <Dropdown.Button
              type="primary"
              icon={<ChevronDown size={16} />}
              disabled={!isWholesaler}
              menu={menuProps}
              trigger={['click']}
              onClick={() => navigate('/wholesale/orders/create')}
            >
              {t('wholesaleOrdersView.button.createWholesaleOrder')}
            </Dropdown.Button>
          ) : (
            <LinkButton type="primary" to={'/wholesale/orders/create'} disabled={!isWholesaler}>
              {t('wholesaleOrdersView.button.createWholesaleOrder')}
            </LinkButton>
          )
        }
      />
      <ViewContainer>
        <WholesaleOrderTabs />
      </ViewContainer>
    </>
  )
}
