import { Button } from 'antd'
import { X } from 'lucide-react'
import {
  AmazonPpcCampaignType,
  TargetingExpressionPredicateType,
} from '../../../../../../generated/graphql'
import { useCurrencySymbol } from '../../../../../../views/ppc/helpers/useCurrencySymbol'
import { useDefaultMaximumBid } from '../../../../hooks/useDefaultMaximumBid'
import { useDefaultMinimumBid } from '../../../../hooks/useDefaultMinimumBid'
import { ScrollableList } from '../../../../styles'
import { UserDefinedTarget } from './CreateSponsoredProductsManualTargetsFormFields'
import { CategoryItem, CategoryItemDetails, CategoryName, CategoryPath, SmallInput } from './styles'

type UserDefinedTargetListProps = {
  userDefinedTargets: UserDefinedTarget[]
  handleRemoveTarget: Function
  handleUpdateTarget: Function
}

const UserDefinedTargetList = ({
  userDefinedTargets,
  handleRemoveTarget,
  handleUpdateTarget,
}: UserDefinedTargetListProps) => {
  const currencySymbol = useCurrencySymbol()
  const defaultMinimumBid = useDefaultMinimumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const defaultMaximumBid = useDefaultMaximumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)
  const handleBidChanged = (bid: number, target: UserDefinedTarget) => {
    target.bid = bid
    handleUpdateTarget(target)
  }

  return (
    <ScrollableList>
      {userDefinedTargets.map((target: UserDefinedTarget) => {
        const targetType =
          target.expressionType === TargetingExpressionPredicateType.ASINSAMEAS
            ? 'ASIN'
            : 'Category'
        return (
          <CategoryItem key={target.id}>
            <CategoryItemDetails>
              <CategoryPath>{target.description?.replace(/\//g, ' / ')}</CategoryPath>
              <CategoryName>
                {targetType}: <span style={{ fontWeight: 'bold' }}>{target.name}</span>
              </CategoryName>
            </CategoryItemDetails>
            <SmallInput
              min={defaultMinimumBid}
              max={defaultMaximumBid}
              defaultValue={target.bid}
              step={0.01}
              size="small"
              onChange={(value: any) => handleBidChanged(value, target)}
              data-testid="update-asin-input"
              prefix={currencySymbol}
            />
            <Button
              type="text"
              icon={<X size={16} />}
              onClick={() => handleRemoveTarget(target)}
              data-testid="remove-asin-btn"
            />
          </CategoryItem>
        )
      })}
    </ScrollableList>
  )
}

export default UserDefinedTargetList
