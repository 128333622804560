import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'sonner'
import { ViewContainer } from '../../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import CreateSponsoredProductsCampaignWithChildrenForm from '../../../../components/PPC/sponsoredProducts/campaign/create/CreateSponsoredProductsCampaignWithChildrenForm'
import {
  AutomaticTargetingBiddingType,
  ISponsoredProductsAutoTarget,
} from '../../../../components/PPC/sponsoredProducts/campaign/create/automaticTargets/interfaces'
import { UserDefinedTarget } from '../../../../components/PPC/sponsoredProducts/target/create/CreateSponsoredProductsManualTargetsWidget/CreateSponsoredProductsManualTargetsFormFields'
import {
  AmazonPpcSponsoredProductsTarget,
  AmazonPpcState,
  CreateSponsoredProductsAdGroupDocument,
  CreateSponsoredProductsAdGroupInput,
  CreateSponsoredProductsCampaignDocument,
  CreateSponsoredProductsCampaignInput,
  CreateSponsoredProductsKeywordInput,
  CreateSponsoredProductsKeywordsDocument,
  CreateSponsoredProductsNegativeKeywordInput,
  CreateSponsoredProductsNegativeKeywordsDocument,
  CreateSponsoredProductsNegativeTargetInput,
  CreateSponsoredProductsNegativeTargetsDocument,
  CreateSponsoredProductsProductAdInput,
  CreateSponsoredProductsProductAdsDocument,
  CreateSponsoredProductsTargetInput,
  CreateSponsoredProductsTargetsDocument,
  GetAllAmazonListingsByCompanyAndMarketplaceDocument,
  GetSponsoredProductsTargetsByAdGroupIdDocument,
  GetSponsoredProductsTargetsByAdGroupIdQuery,
  Marketplace,
  SponsoredProductsCampaignPredicate,
  TargetingExpressionPredicateType,
  UpdateSponsoredProductsTargetInput,
  UpdateSponsoredProductsTargetsDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'

export const PPCSponsoredProductsCampaignCreateView = () => {
  const [sendingData, setSendingData] = useState<boolean>(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const sellerId = selectedCompany?.sellerId ? selectedCompany?.sellerId : ''

  const { t } = useTranslation()
  const params = useParams<{ marketplace: string }>()
  const marketplace = params.marketplace?.toUpperCase()! as Marketplace

  const { loading, error, data } = useQuery(GetAllAmazonListingsByCompanyAndMarketplaceDocument, {
    skip: !selectedCompany?.uuid,
    variables: {
      companyUuid: selectedCompany?.uuid!,
      marketplace,
    },
  })

  const campaignAndAdGroupNamePrefix = marketplace + '-' + sellerId + '-'

  const [createSponsoredProductsCampaign, { error: createSponsoredProductsCampaignError }] =
    useMutation(CreateSponsoredProductsCampaignDocument)

  const [createSponsoredProductsAdGroup, { error: createSponsoredProductsAdGroupError }] =
    useMutation(CreateSponsoredProductsAdGroupDocument)

  const [createSponsoredProductsProductAds, { error: createSponsoredProductsProductAdsError }] =
    useMutation(CreateSponsoredProductsProductAdsDocument)

  const [createSponsoredProductsKeywords, { error: createSponsoredProductsKeywordsError }] =
    useMutation(CreateSponsoredProductsKeywordsDocument)

  const [createSponsoredProductsTargets, { error: createSponsoredProductsTargetsError }] =
    useMutation(CreateSponsoredProductsTargetsDocument)

  const [
    createSponsoredProductsNegativeTargets,
    { error: createSponsoredProductsNegativeTargetsError },
  ] = useMutation(CreateSponsoredProductsNegativeTargetsDocument)

  const [updateSponsoredProductsTargets, { error: updateSponsoredProductsTargetsError }] =
    useMutation(UpdateSponsoredProductsTargetsDocument)

  const [
    createSponsoredProductsNegativeKeywords,
    { error: createSponsoredProductsNegativeKeywordsError },
  ] = useMutation(CreateSponsoredProductsNegativeKeywordsDocument)

  const [
    getSponsoredProductsTargetsByAdGroupId,
    { error: getSponsoredProductsTargetsByAdGroupIdError },
  ] = useLazyQuery(GetSponsoredProductsTargetsByAdGroupIdDocument)

  if (loading || sendingData) {
    return <LoadingSpinner />
  }

  if (
    error ||
    createSponsoredProductsCampaignError ||
    createSponsoredProductsAdGroupError ||
    createSponsoredProductsProductAdsError ||
    createSponsoredProductsKeywordsError ||
    createSponsoredProductsNegativeKeywordsError ||
    createSponsoredProductsTargetsError ||
    createSponsoredProductsNegativeTargetsError ||
    updateSponsoredProductsTargetsError ||
    getSponsoredProductsTargetsByAdGroupIdError
  ) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  const handleFormOnFinish = async (values: any) => {
    if (!selectedCompany?.uuid) {
      return
    }

    setSendingData(true)

    try {
      const {
        campaign,
        adGroup,
        productAdSkus,
        keywords,
        negativeKeywords,
        automaticTargets,
        negativeProductTargetingAsins,
        productTargets,
        automaticTargetingBiddingType,
        automaticTargetingDefaultBid,
      } = values

      const campaignPayload = formatCampaignPayload(campaign)

      const campaignId = await handleCreateCampaign(campaignPayload)

      if (!campaignId) {
        throw new Error('No Campaign ID returned on creation')
      }

      adGroup.campaignId = campaignId
      adGroup.name = campaignAndAdGroupNamePrefix + adGroup.name

      if (
        automaticTargetingBiddingType &&
        automaticTargetingBiddingType === AutomaticTargetingBiddingType.DEFAULT
      ) {
        // if it is an auto campaign with default bidding the ad group needs a default bid value
        adGroup.defaultBid = automaticTargetingDefaultBid
      }

      const adGroupId = await handleCreateAdGroup(adGroup)

      if (!adGroupId) {
        throw new Error('No Ad Group ID returned on creation')
      }

      const productAds = []

      for (const productAdSku of productAdSkus) {
        const ad = {
          campaignId: campaignId,
          adGroupId: adGroupId,
          sku: productAdSku,
        }
        productAds.push(ad)
      }

      await handleCreateProductAds(productAds)

      if (keywords?.length) {
        for (const keyword of keywords) {
          keyword.campaignId = campaignId
          keyword.adGroupId = adGroupId
        }

        await handleCreateKeywords(keywords)
      }

      if (negativeKeywords?.length) {
        for (const keyword of negativeKeywords) {
          keyword.campaignId = campaignId
          keyword.adGroupId = adGroupId
        }

        await handleCreateNegativeKeywords(negativeKeywords)
      }

      if (
        automaticTargetingBiddingType &&
        automaticTargetingBiddingType === AutomaticTargetingBiddingType.INDIVIDUAL
      ) {
        if (automaticTargets?.length) {
          // On auto campaign creation automatic targets are created by default
          // We firstly get these automatically created targets
          // Then we update them to the user defined values using each generated targetId
          const response = await getSponsoredProductsTargetsByAdGroupId({
            variables: { companyUuid: selectedCompany.uuid, marketplace, adGroupId },
          })

          if (response.error) {
            console.error(response.error)
            toast.error('Error fetching automatic targets.', {
              description: response.error.message,
            })
            return
          }

          if (response.data?.sponsoredProductsTargets?.length) {
            // Format the user defined auto targets
            const userDefinedAutomaticTargets: AmazonPpcSponsoredProductsTarget[] =
              automaticTargets.map((automaticTarget: ISponsoredProductsAutoTarget) => {
                return {
                  campaignId: campaignId,
                  adGroupId: adGroupId,
                  expression: [
                    {
                      type: automaticTarget.expressionTypePredicate,
                    },
                  ],
                  expressionType: 'manual',
                  bid: automaticTarget.bid,
                  state: automaticTarget.active ? AmazonPpcState.ENABLED : AmazonPpcState.PAUSED,
                }
              })

            const existingAutomaticTargets: GetSponsoredProductsTargetsByAdGroupIdQuery['sponsoredProductsTargets'] =
              response.data?.sponsoredProductsTargets

            // Format the payload using the user defined target values and the targetId from existing ones
            const updateTargetsPayload: UpdateSponsoredProductsTargetInput[] = []
            userDefinedAutomaticTargets.forEach(
              (userDefinedAutomaticTarget: AmazonPpcSponsoredProductsTarget) => {
                const matchingExistingTarget = existingAutomaticTargets.find((obj) => {
                  return obj?.expression.find((expression) => {
                    return expression?.type === userDefinedAutomaticTarget.expression[0]?.type
                  })
                })
                if (matchingExistingTarget) {
                  updateTargetsPayload.push({
                    campaignId: userDefinedAutomaticTarget.campaignId,
                    adGroupId: userDefinedAutomaticTarget.adGroupId,
                    targetId: matchingExistingTarget.targetId,
                    bid: userDefinedAutomaticTarget.bid!,
                    state: userDefinedAutomaticTarget.state,
                  })
                }
              }
            )

            if (updateTargetsPayload?.length) {
              try {
                await updateSponsoredProductsTargets({
                  variables: {
                    companyUuid: selectedCompany?.uuid!,
                    marketplace: marketplace,
                    targets: updateTargetsPayload,
                  },
                })
              } catch (e: any) {
                console.error(e)
                toast.error('Error updating automatic targets.', { description: e.message })
              }
            }
          }
        }
      }

      if (negativeProductTargetingAsins?.length) {
        const formattedNegativeTargets: CreateSponsoredProductsNegativeTargetInput[] =
          negativeProductTargetingAsins.map((negativeProductTargetingAsin: string) => {
            return {
              campaignId: campaignId,
              adGroupId: adGroupId,
              expression: [
                {
                  type: TargetingExpressionPredicateType.ASINSAMEAS,
                  value: negativeProductTargetingAsin,
                },
              ],
              expressionType: 'manual',
            }
          })

        await handleCreateNegativeTargets(formattedNegativeTargets)
      }

      if (productTargets?.length) {
        const formattedTargets: CreateSponsoredProductsTargetInput[] = productTargets.map(
          (productTarget: UserDefinedTarget) => {
            return {
              campaignId: campaignId,
              adGroupId: adGroupId,
              expression: [
                {
                  type: productTarget.expressionType,
                  value: productTarget.expressionValue,
                },
              ],
              resolvedExpression: [
                {
                  type: productTarget.expressionType,
                  value: productTarget.resolvedExpressionValue,
                },
              ],
              bid: productTarget.bid,
              expressionType: 'manual',
            }
          }
        )

        await handleCreateTargets(formattedTargets)
      }

      toast.success('Campaign created.')
    } catch (e: any) {
      toast.error('Error during campaign creation.', { description: e.message })
      console.error(e)
    } finally {
      setSendingData(false)
    }
  }

  const formatCampaignPayload = (campaignValues: any) => {
    const biddingAdjustments = []
    if (campaignValues.biddingAdjustmentPlacementTop) {
      biddingAdjustments.push({
        predicate: SponsoredProductsCampaignPredicate.PLACEMENTTOP,
        percentage: campaignValues.biddingAdjustmentPlacementTop,
      })
    }
    if (campaignValues.biddingAdjustmentPercentage) {
      biddingAdjustments.push({
        predicate: SponsoredProductsCampaignPredicate.PLACEMENTPRODUCTPAGE,
        percentage: campaignValues.biddingAdjustmentPercentage,
      })
    }

    return {
      name: campaignAndAdGroupNamePrefix + campaignValues.name,
      dailyBudget: campaignValues.dailyBudget,
      startDate: campaignValues.startDate?.format('YYYYMMDD'),
      endDate: campaignValues.endDate?.format('YYYYMMDD'),
      targetingType: campaignValues.targetingType,
      bidding: {
        strategy: campaignValues.biddingStrategy,
        adjustments: biddingAdjustments,
      },
    }
  }

  const handleCreateCampaign = async (campaign: CreateSponsoredProductsCampaignInput) => {
    const request = await createSponsoredProductsCampaign({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: marketplace,
        campaign: campaign,
      },
    })

    return request.data?.createSponsoredProductsCampaign.campaignId
  }

  const handleCreateAdGroup = async (adGroup: CreateSponsoredProductsAdGroupInput) => {
    const request = await createSponsoredProductsAdGroup({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: marketplace,
        adGroup: adGroup,
      },
    })

    return request.data?.createSponsoredProductsAdGroup.adGroupId
  }

  const handleCreateProductAds = async (productAds: CreateSponsoredProductsProductAdInput[]) => {
    const request = await createSponsoredProductsProductAds({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: marketplace,
        productAds: productAds,
      },
    })

    return request.data?.createSponsoredProductsProductAds
  }

  const handleCreateKeywords = async (keywords: CreateSponsoredProductsKeywordInput[]) => {
    const request = await createSponsoredProductsKeywords({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: marketplace,
        keywords: keywords,
      },
    })

    return request.data?.createSponsoredProductsKeywords
  }

  const handleCreateNegativeKeywords = async (
    keywords: CreateSponsoredProductsNegativeKeywordInput[]
  ) => {
    const request = await createSponsoredProductsNegativeKeywords({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: marketplace,
        keywords: keywords,
      },
    })

    return request.data?.createSponsoredProductsNegativeKeywords
  }

  const handleCreateTargets = async (targets: CreateSponsoredProductsTargetInput[]) => {
    const request = await createSponsoredProductsTargets({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: marketplace,
        targets: targets,
      },
    })

    return request.data?.createSponsoredProductsTargets
  }

  const handleCreateNegativeTargets = async (
    negativeTargets: CreateSponsoredProductsNegativeTargetInput[]
  ) => {
    const request = await createSponsoredProductsNegativeTargets({
      variables: {
        companyUuid: selectedCompany?.uuid!,
        marketplace: marketplace,
        negativeTargets: negativeTargets,
      },
    })

    return request.data?.createSponsoredProductsNegativeTargets
  }

  return (
    <>
      {!data?.amazonListings?.length ? (
        <>
          <SubHeader withBackButton />
          <ViewContainer>
            <Typography.Text>
              You need to have products listed in this Amazon marketplace before you can launch a
              Sponsored Products campaign.
            </Typography.Text>
          </ViewContainer>
        </>
      ) : (
        <ViewContainer centered>
          <CreateSponsoredProductsCampaignWithChildrenForm
            onFinish={handleFormOnFinish}
            amazonListings={data?.amazonListings}
            campaignAndAdGroupNamePrefix={campaignAndAdGroupNamePrefix}
            marketplace={marketplace}
          />
        </ViewContainer>
      )}
    </>
  )
}
