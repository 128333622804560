import { useState } from 'react'
import { GetSponsoredProductsTargetCategoryRecommendationsQuery } from '../../../../../../generated/graphql'
import AddCategoryList, { DetailedAmazonCategory } from './AddCategoryList'
import { AmazonCategory, UserDefinedTarget } from './CreateSponsoredProductsManualTargetsFormFields'
import SearchCategoryTree from './SearchCategoryTree'
import { SmallSubMenu } from './styles'

type SelectSponsoredProductsCategoriesProps = {
  userDefinedCategoryTargets: UserDefinedTarget[]
  handleAddTarget: Function
  recommendedCategories: GetSponsoredProductsTargetCategoryRecommendationsQuery['sponsoredProductsTargetCategoryRecommendations']['categories']
  categoryTree: AmazonCategory[]
}

enum CategorySubview {
  RECOMMENDED = 'recommended',
  SEARCH = 'search',
}

export type TreeItem = {
  children: TreeItem[]
  disabled: boolean
  key: number
  title: string
}

const SelectSponsoredProductsCategories = ({
  userDefinedCategoryTargets,
  handleAddTarget,
  recommendedCategories,
  categoryTree,
}: SelectSponsoredProductsCategoriesProps) => {
  const [selectedSubview, setSelectedSubview] = useState<CategorySubview>(
    CategorySubview.RECOMMENDED
  )

  const handleSelectSubview = (e: any) => {
    setSelectedSubview(e.key)
  }

  const selectedCategoryIds = userDefinedCategoryTargets.map((target) => {
    return target.expressionValue
  })

  const allCategories: DetailedAmazonCategory[] = []

  const buildTreeRecursive = (
    categories: AmazonCategory[],
    depth = 0,
    pathArray: string[] = []
  ): TreeItem[] => {
    return categories?.map((category) => {
      const categoryAlreadySelected = selectedCategoryIds.includes(category.id?.toString())
      pathArray.splice(depth)
      pathArray.push(category.na)
      const path = pathArray.join('/')
      allCategories.push({
        id: category.id.toString(),
        name: category.na,
        path: path,
        canBeTargeted: category.ta,
      })
      const childDepth = depth + 1
      return {
        title: category.na,
        key: category.id,
        disabled: !category.ta || categoryAlreadySelected,
        children: buildTreeRecursive(category.ch, childDepth, pathArray),
        path: path,
      }
    })
  }

  const treeData = buildTreeRecursive(categoryTree)

  return (
    <>
      <SmallSubMenu
        onClick={handleSelectSubview}
        selectedKeys={[selectedSubview]}
        mode="horizontal"
        items={[
          { label: 'Recommended', key: CategorySubview.RECOMMENDED },
          { label: 'Search', key: CategorySubview.SEARCH },
        ]}
      />

      {selectedSubview === CategorySubview.RECOMMENDED ? (
        <AddCategoryList
          categories={recommendedCategories}
          selectedCategoryIds={selectedCategoryIds}
          handleAddTarget={handleAddTarget}
        />
      ) : (
        <SearchCategoryTree
          treeData={treeData}
          handleAddTarget={handleAddTarget}
          selectedCategoryIds={selectedCategoryIds}
          allCategories={allCategories}
        />
      )}
    </>
  )
}

export default SelectSponsoredProductsCategories
