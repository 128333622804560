import { useMutation } from '@apollo/client'
import { Button } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import {
  CheckOnboardingStepDocument,
  CompanyType,
  CreateAmazonListingDocument,
  CreateAmazonProductDocument,
  CreateProductComplianceCheckDocument,
  CreateProductComplianceCheckDocumentInput,
  CreateProductComplianceCheckDocumentsDocument,
  CreateProductDocument,
  CreateProductVariantDocument,
  GetCompanyDocument,
  Marketplace,
  SendSupportTicketDocument,
} from '../../generated/graphql'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { ProductCreateForm, ProductCreateFormInstance } from './components/ProductCreateForm'
import { calculateNetPurchasePrice } from './helpers/calculateNetPurchasePrice'
import { uploadComplianceImages } from './helpers/uploadComplianceImages'
import { getProductCreationTicketOptions } from './utils/tickets'

export const ProductCreateView = () => {
  const [submitting, setSubmitting] = useState(false)

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const navigate = useNavigate()
  const { t } = useTranslation(['inventory', 'ticket'])
  const [form] = useForm<ProductCreateFormInstance>()

  const [createProduct] = useMutation(CreateProductDocument)
  const [createProductVariant] = useMutation(CreateProductVariantDocument)
  const [createAmazonProduct] = useMutation(CreateAmazonProductDocument)
  const [createAmazonListing] = useMutation(CreateAmazonListingDocument)
  const [sendSupportTicketRequest] = useMutation(SendSupportTicketDocument)
  const [checkOnboardingStep] = useMutation(CheckOnboardingStepDocument)
  const [createProductComplianceCheck] = useMutation(CreateProductComplianceCheckDocument)
  const [createProductComplianceCheckDocuments] = useMutation(
    CreateProductComplianceCheckDocumentsDocument
  )

  const submitForm = async (values: ProductCreateFormInstance) => {
    setSubmitting(true)

    try {
      const companyUuid = selectedCompany?.uuid

      if (!companyUuid || !values.variants?.length) {
        throw new Error()
      }

      const hasSalesChannels = [
        CompanyType.RETAILER,
        CompanyType.SUPPLIER,
        CompanyType.WHOLESALER,
      ].includes(selectedCompany.type)

      const createdProduct = await createProduct({
        variables: {
          companyUuid,
          product: {
            name: values.name,
            brand: values.brand,
            manufacturer: values.manufacturer,
            countryOfOrigin: values.countryOfOrigin,
            taric: values.taric,
            salesChannelCategory: values.salesChannelCategory,
          },
        },
      })

      const productUuid = createdProduct.data?.createProduct.uuid

      if (!productUuid) {
        throw new Error()
      }

      if (hasSalesChannels) {
        for (const variant of values.variants) {
          if (!variant.salesChannels) {
            throw new Error()
          }

          const salesChannels = Object.entries(variant.salesChannels.amazon.marketplaces ?? {})
            .filter(([, value]) => value.shouldCreate)
            .map(([marketplace, { price }]) => ({
              marketplace: marketplace as Marketplace,
              price,
            }))

          const marketplaces = salesChannels.map(({ marketplace }) => marketplace)

          if (!marketplaces.length) {
            throw new Error()
          }

          const netPurchasePrice = calculateNetPurchasePrice(salesChannels[0]?.price ?? 0)

          const createdProductVariant = await createProductVariant({
            variables: {
              productUuid,
              input: {
                ean: variant.ean,
                internalSku: variant.internalSku,
                netPurchasePrice,
                packageHeight: variant.packageHeight ?? null,
                packageLength: variant.packageLength ?? null,
                packageWeight: variant.packageWeight ?? null,
                packageWidth: variant.packageWidth ?? null,
                attributes: variant.attributes,
              },
            },
          })

          const productVariantUuid = createdProductVariant.data?.createProductVariant.uuid

          if (!productVariantUuid) {
            throw new Error()
          }

          const sku = `MISSING-SKU-${nanoid(10)}`
          const asin = variant.salesChannels.amazon.asin ?? `MISSING-ASIN-${nanoid(10)}`

          const createdAmazonProduct = await createAmazonProduct({
            variables: {
              productVariantUuid,
              amazonProduct: {
                asin,
                amazonAccount: 'Varento',
                subscribeAndSave: variant.salesChannels.amazon.subscribeAndSave,
              },
            },
          })

          const amazonProductUuid = createdAmazonProduct.data?.createAmazonProduct.uuid

          if (!amazonProductUuid) {
            throw new Error()
          }

          for (const marketplace of marketplaces) {
            const recommendedRetailPrice =
              variant.salesChannels.amazon.marketplaces[marketplace]?.price

            await createAmazonListing({
              variables: {
                amazonProductUuid,
                amazonListing: {
                  sku,
                  marketplace,
                  recommendedRetailPrice,
                  labelType: variant.salesChannels.amazon.labelType,
                },
              },
            })
          }
        }
      } else {
        for (const variant of values.variants) {
          const createdProductVariant = await createProductVariant({
            variables: {
              productUuid,
              input: {
                ean: variant.ean,
                internalSku: variant.internalSku,
                packageHeight: variant.packageHeight ?? null,
                packageLength: variant.packageLength ?? null,
                packageWeight: variant.packageWeight ?? null,
                packageWidth: variant.packageWidth ?? null,
                attributes: variant.attributes,
              },
            },
          })

          const productVariantUuid = createdProductVariant.data?.createProductVariant.uuid

          if (!productVariantUuid) {
            throw new Error()
          }
        }
      }

      const createdProductComplianceCheck = await createProductComplianceCheck({
        variables: {
          productUuid,
          countryCodes: values.complianceCheckCountryCodes,
        },
      })

      if (!createdProductComplianceCheck.data?.createProductComplianceCheck) {
        console.error('Error creating product compliance check')
        throw new Error()
      }

      const uploadedComplianceImages = await uploadComplianceImages(
        values.complianceImages,
        productUuid
      )

      if (!uploadedComplianceImages) {
        console.error('Error uploading compliance images')
        throw new Error()
      }

      const input: CreateProductComplianceCheckDocumentInput[] = uploadedComplianceImages?.map(
        (document) => ({
          bucketKey: document.fileName,
          countryCodes: null,
        })
      )

      if (input) {
        await createProductComplianceCheckDocuments({
          variables: {
            productComplianceCheckIdentifier:
              createdProductComplianceCheck.data.createProductComplianceCheck.identifier,
            input,
          },
        })
      }

      const options = getProductCreationTicketOptions({
        sellerId: selectedCompany.sellerId,
        name: values.name,
        variants: values.variants,
        complianceCheckIdentifier:
          createdProductComplianceCheck.data.createProductComplianceCheck.identifier,
      })

      await sendSupportTicketRequest({
        variables: {
          cognitoUsername: cognitoUser?.getUsername() ?? '',
          companyUuid,
          input: options,
        },
      })

      setTimeout(async () => {
        toast.success(t('product.create.toast.success'))
        if (!selectedCompany.completedOnboarding) {
          await checkOnboardingStep({
            variables: {
              input: {
                companyUuid: selectedCompany.uuid,
                eventName: 'add_products',
              },
            },
            refetchQueries: [
              {
                query: GetCompanyDocument,
                variables: { companyUuid: selectedCompany?.uuid },
              },
            ],
          })
          navigate(`/products/${productUuid}?tour=true`)
        }
        navigate(`/products/${productUuid}`)
      }, 1000)
    } catch (error) {
      console.error(error)
      toast.error(t('product.create.toast.error'))
    } finally {
      setTimeout(() => setSubmitting(false), 1000)
    }
  }

  return (
    <>
      <SubHeader
        heading={t('product.create.heading')}
        withBackButton
        onBackUrl="/products"
        rightContent={
          <Button type="primary" loading={submitting} onClick={() => form.submit()}>
            {t('shared.button.submit', { ns: 'translation' })}
          </Button>
        }
      />
      <ViewContainer centered>
        <ProductCreateForm form={form} submitForm={submitForm} />
      </ViewContainer>
    </>
  )
}
