import { useQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import { Alert, Button, Flex, Modal, Select, TourProps, Typography } from 'antd'
import { Plus } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import {
  GetWarehouseServiceProvidersDocument,
  Marketplace,
  ShipmentType,
} from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useBreakpoints } from '../../../../hooks/useBreakpoints'
import { useMarketplaceOptions } from '../../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { useOnboardingStore } from '../../../../stores/useOnboardingStore'
import { StyledMainCard } from '../../../dashboard/styles'
import { ShipmentKind } from '../types'

const availableMarketplaces = [
  Marketplace.DE,
  Marketplace.FR,
  Marketplace.ES,
  Marketplace.IT,
  Marketplace.UK,
  Marketplace.US,
]

export const AmazonInboundShipmentPlanCreationModal = () => {
  const [open, setOpen] = useState(false)
  const [selectedShipmentType, setSelectedShipmentType] = useState<ShipmentKind>()
  const [selectedShippingMode, setSelectedShippingMode] = useState<ShipmentType>()
  const [selectedMarketplace, setSelectedMarketplace] = useState<Marketplace>()
  const shipmentPlanRef = useRef(null)
  const [searchParams] = useSearchParams()

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const setTourSteps = useOnboardingStore((state) => state.setTourSteps)
  const setTourOpen = useOnboardingStore((state) => state.setTourOpen)

  const { t } = useTranslation(['inventory', 'company'])
  const theme = useTheme()
  const navigate = useNavigate()
  const breakpoints = useBreakpoints()
  const allowedMarketplaces = useMarketplaceOptions()

  const filteredAllowedMarketplaces = allowedMarketplaces.filter((marketplace) =>
    availableMarketplaces.includes(marketplace)
  )

  const { data, error } = useQuery(GetWarehouseServiceProvidersDocument, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
    },
  })

  const steps: TourProps['steps'] = [
    {
      title: t('company:onboarding.addShipment.title'),
      description: t('company:onboarding.addShipment.description'),
      target: () => shipmentPlanRef.current,
      nextButtonProps: {
        style: { display: 'none' },
      },
    },
  ]

  useEffect(() => {
    const tour = searchParams.get('tour')

    if (tour === 'true') {
      setTourSteps(steps)
      setTourOpen(true)
    }
  }, [searchParams])

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const handleContinue = () => {
    const searchParams = new URLSearchParams()
    searchParams.set('type', selectedShipmentType!)
    searchParams.set('mode', selectedShippingMode!)
    searchParams.set('marketplace', selectedMarketplace!)
    navigate(`/amazon/shipment-plans/create?${searchParams}`)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const hasWarehouseLocation = data?.warehouseServiceProviders.some((warehouse) => {
    return warehouse.locations?.filter((location) => location.active).length
  })

  const isDisabled = !selectedShipmentType || !selectedShippingMode || !selectedMarketplace

  return (
    <>
      <Button
        ref={shipmentPlanRef}
        type="primary"
        icon={<Plus size={16} />}
        onClick={() => {
          setOpen((prevState) => !prevState)
          setTourOpen(false)
        }}
      >
        {breakpoints.md ? t('amazonInboundShipmentPlan.create.button') : null}
      </Button>
      <Modal
        title={t('amazonInboundShipmentPlan.create.modal.title')}
        open={open}
        okText={t('shared.button.continue', { ns: 'translation' })}
        okButtonProps={{ disabled: isDisabled }}
        onOk={handleContinue}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={handleCancel}
      >
        <Flex vertical gap={16}>
          {!hasWarehouseLocation && (
            <Alert
              type="warning"
              showIcon
              description={
                <Flex vertical align="baseline" gap={8}>
                  <Typography.Text>
                    {t('amazonInboundShipmentPlan.create.modal.alert.message')}
                  </Typography.Text>
                  <Link to="/settings/warehousing">
                    {t('amazonInboundShipmentPlan.create.modal.alert.link')}
                  </Link>
                </Flex>
              }
              style={{ padding: '1rem' }}
            />
          )}
          <Flex vertical gap={8}>
            <Typography.Text>
              {t('amazonInboundShipmentPlan.create.modal.shipmentType.label')}
            </Typography.Text>
            <StyledMainCard
              onClick={() => setSelectedShipmentType(ShipmentKind.CASE)}
              style={{
                borderColor:
                  selectedShipmentType === ShipmentKind.CASE ? theme.colors.primary : undefined,
                borderWidth: '2px',
                cursor: 'pointer',
                opacity: !hasWarehouseLocation ? 0.5 : 1,
                pointerEvents: !hasWarehouseLocation ? 'none' : 'auto',
              }}
            >
              <Flex vertical gap={8}>
                <Typography.Text strong>
                  {t('amazonInboundShipmentPlan.create.modal.shipmentType.case.title')}
                </Typography.Text>
                <Typography.Text>
                  {t('amazonInboundShipmentPlan.create.modal.shipmentType.case.description')}
                </Typography.Text>
              </Flex>
            </StyledMainCard>
            <StyledMainCard
              onClick={() => setSelectedShipmentType(ShipmentKind.MIXED)}
              style={{
                borderColor:
                  selectedShipmentType === ShipmentKind.MIXED ? theme.colors.primary : undefined,
                borderWidth: '2px',
                cursor: 'pointer',
                opacity: !hasWarehouseLocation ? 0.5 : 1,
                pointerEvents: !hasWarehouseLocation ? 'none' : 'auto',
              }}
            >
              <Flex vertical gap={8}>
                <Typography.Text strong>
                  {t('amazonInboundShipmentPlan.create.modal.shipmentType.mixed.title')}
                </Typography.Text>
                <Typography.Text>
                  {t('amazonInboundShipmentPlan.create.modal.shipmentType.mixed.description')}
                </Typography.Text>
              </Flex>
            </StyledMainCard>
          </Flex>
          <Flex vertical gap={8}>
            <Typography.Text>
              {t('amazonInboundShipmentPlan.create.modal.shippingMode.label')}
            </Typography.Text>
            <StyledMainCard
              onClick={() => setSelectedShippingMode(ShipmentType.SP)}
              style={{
                borderColor:
                  selectedShippingMode === ShipmentType.SP ? theme.colors.primary : undefined,
                borderWidth: '2px',
                cursor: 'pointer',
                opacity: !hasWarehouseLocation ? 0.5 : 1,
                pointerEvents: !hasWarehouseLocation ? 'none' : 'auto',
              }}
            >
              <Flex vertical gap={8}>
                <Typography.Text strong>
                  {t('amazonInboundShipmentPlan.create.modal.shippingMode.SP.title')}
                </Typography.Text>
                <Typography.Text>
                  {t('amazonInboundShipmentPlan.create.modal.shippingMode.SP.description')}
                </Typography.Text>
              </Flex>
            </StyledMainCard>
            <StyledMainCard
              onClick={() => setSelectedShippingMode(ShipmentType.LTL)}
              style={{
                borderColor:
                  selectedShippingMode === ShipmentType.LTL ? theme.colors.primary : undefined,
                borderWidth: '2px',
                cursor: 'pointer',
                opacity: !hasWarehouseLocation ? 0.5 : 1,
                pointerEvents: !hasWarehouseLocation ? 'none' : 'auto',
              }}
            >
              <Flex vertical gap={8}>
                <Typography.Text strong>
                  {t('amazonInboundShipmentPlan.create.modal.shippingMode.LTL.title')}
                </Typography.Text>
                <Typography.Text>
                  {t('amazonInboundShipmentPlan.create.modal.shippingMode.LTL.description')}
                </Typography.Text>
              </Flex>
            </StyledMainCard>
          </Flex>
          <Flex vertical gap={8}>
            <Typography.Text>
              {t('amazonInboundShipmentPlan.create.modal.marketplace.label')}
            </Typography.Text>
            <Select<Marketplace>
              value={selectedMarketplace}
              onChange={setSelectedMarketplace}
              options={filteredAllowedMarketplaces.map((marketplace) => ({
                value: marketplace,
                label: `Amazon.${getMarketplaceDomain(marketplace)}`,
              }))}
              placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
              disabled={!hasWarehouseLocation}
              showSearch
              filterOption={true}
              optionFilterProp="label"
            />
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
