import { useTranslation } from 'react-i18next'
import { Navigate, NavLink, Outlet, useLocation } from 'react-router'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { NavLinks } from '../../components/NavLinks'
import { CompanyType } from '../../generated/graphql'
import { useGlobalStore } from '../../stores/useGlobalStore'

export const SettingsView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation()
  const { pathname } = useLocation()

  return (
    <>
      <SubHeader heading={t('settings.heading')} />
      <ViewContainer>
        <NavLinks>
          <NavLink to="user">{t('settings.tab.user')}</NavLink>
          <NavLink to="company">{t('settings.tab.company')}</NavLink>
          <NavLink to="brands" hidden={selectedCompany.type === CompanyType.RETAILER}>
            {t('settings.tab.brands')}
          </NavLink>
          <NavLink to="warehousing" hidden={selectedCompany.type === CompanyType.COMPLIANCE}>
            {t('settings.tab.warehousing')}
          </NavLink>
          <NavLink to="contracts" hidden={selectedCompany.type === CompanyType.RETAILER}>
            {t('settings.tab.contracts')}
          </NavLink>
        </NavLinks>
        <Outlet />
        {pathname.match(/^\/settings\/?$/) && <Navigate to="/settings/user" replace />}
      </ViewContainer>
    </>
  )
}
