import { Suspense } from 'react'
import { LoadingSpinner } from '../../../../components/LoadingSpinner'
import { WarehousingContainer } from './WarehousingContainer'

export const Warehousing = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <WarehousingContainer />
    </Suspense>
  )
}
