import { t } from 'i18next'
import i18n from '../../../config/i18n'
import { createZendeskTicketOptions } from '../../ticket/helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../../ticket/ticket.enum'
import { ProductVariantFormInstance } from '../components/ProductCreateForm'

export function getProductCreationTicketOptions({
  sellerId,
  name,
  variants,
  complianceCheckIdentifier,
}: {
  sellerId: string
  name: string
  variants: ProductVariantFormInstance[]
  complianceCheckIdentifier: string
}) {
  const hasAmazonSalesChannel = variants.some(
    (variant) =>
      variant.salesChannels?.amazon?.marketplaces &&
      Object.values(variant.salesChannels.amazon.marketplaces).some(
        (marketplace) => marketplace.shouldCreate
      )
  )

  const identifiers = variants
    .map((variant) => {
      if (hasAmazonSalesChannel && variant.salesChannels?.amazon.asin) {
        return variant.salesChannels.amazon.asin
      } else {
        return variant.ean
      }
    })
    .join(', ')

  const subject = t('productCreateView.subject', {
    ns: 'ticket',
    count: variants.length,
    identifiers,
  })

  const comment = `
    <h3>${t('productCreateView.creationText.receivedNote', { ns: 'ticket' })}</h3>
    <p>${t('productCreateView.creationText.processNote', { ns: 'ticket' })}</p>
    <p><strong>${name}</strong></p>
    <p>${t('productCreateView.creationText.variantListTitle', { ns: 'ticket' })}</p>
    <ul>${variants
      .map((variant) => {
        let variantInfo = `<li>
            ${variant.attributes?.map(({ value }) => value).join(' / ') ?? ''}<br />
            ${variant.ean ? `${variant.ean}<br />` : ''}`

        if (hasAmazonSalesChannel) {
          variantInfo += `
              ${variant.salesChannels?.amazon.asin ? `${variant.salesChannels?.amazon.asin}<br />` : ''}
              ${Object.entries(variant.salesChannels?.amazon.marketplaces ?? {})
                .filter(([, value]) => value.shouldCreate)
                .map(([marketplace]) => marketplace)
                .join(', ')}`
        }

        variantInfo += `</li>`
        return variantInfo
      })
      .join('')}</ul>
    <p>${t('productCreateView.creationText.complianceImageListTitle', { ns: 'ticket' })}</p>
    <a href="https://galaxy.spacegoats.io/compliance/${complianceCheckIdentifier}" target="_blank" rel="noopener noreferrer">https://galaxy.spacegoats.io/compliance/${complianceCheckIdentifier}</a>
    <br />
    <p>${t('productCreateView.creationText.contactNote', { ns: 'ticket' })}</p>
  `

  return createZendeskTicketOptions({
    subject,
    comment,
    categoryTitle: hasAmazonSalesChannel ? 'amazon' : '',
    mainCategory: TicketMainCategory.INVENTORY,
    ticketCategory: TicketCategory.INVENTORY_ADD_NEW_PRODUCT,
    sellerId,
    language: i18n.language,
  })
}
