type CalculateExpensesProps = {
  refunds: number
  fees: number
  promotions: number
  sponsoredProductsCosts: number
  sponsoredDisplayCosts: number
}

export function calculateExpenses({
  refunds,
  fees,
  promotions,
  sponsoredProductsCosts,
  sponsoredDisplayCosts,
}: CalculateExpensesProps): number {
  return Math.abs(refunds + fees + promotions + sponsoredDisplayCosts + sponsoredProductsCosts)
}
