import { useTheme } from '@emotion/react'
import { Col, Image, Row } from 'antd'
import { useEffect, useState } from 'react'
import { GetAmazonListingQuery } from '../../../../generated/graphql'
import { EditAmazonListingImagesModal } from './EditAmazonListingImagesModal'

type AmazonListingImagesProps = {
  amazonListing: GetAmazonListingQuery['amazonListing']
}

export const AmazonListingImages = ({ amazonListing }: AmazonListingImagesProps) => {
  const theme = useTheme()

  const images = [
    amazonListing.images?.mainImage,
    amazonListing.images?.imagePosition1,
    amazonListing.images?.imagePosition2,
    amazonListing.images?.imagePosition3,
    amazonListing.images?.imagePosition4,
    amazonListing.images?.imagePosition5,
    amazonListing.images?.imagePosition6,
    amazonListing.images?.imagePosition7,
    amazonListing.images?.imagePosition8,
  ].filter(Boolean)

  const [highlightedImage, setHighlightedImage] = useState<string>(images[0])

  useEffect(() => {
    setHighlightedImage(images[0])
  }, [amazonListing])

  const highlightImage = (image: string) => {
    setHighlightedImage(image)
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Image
          src={highlightedImage}
          width="100%"
          height="100%"
          preview={false}
          style={{ borderRadius: '0.25rem', aspectRatio: '1', objectFit: 'contain' }}
        />
        <EditAmazonListingImagesModal amazonListing={amazonListing} />
      </Col>
      <Col xs={24}>
        <Row gutter={[8, 8]}>
          {Object.values(images).map((image, index) => {
            const isHighlighted = image === highlightedImage
            return (
              <Col key={index} xs={4} sm={6}>
                <Image
                  src={image}
                  width="100%"
                  preview={false}
                  onClick={() => highlightImage(image)}
                  style={{
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: isHighlighted ? theme.colors.primary : 'transparent',
                    borderRadius: '0.25rem',
                    aspectRatio: '1',
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                />
              </Col>
            )
          })}
        </Row>
      </Col>
    </Row>
  )
}
