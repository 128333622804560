import { useTheme } from '@emotion/react'
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueParserParams,
} from 'ag-grid-community'
import { Flex, Space, Tag, Typography } from 'antd'
import { Pen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MoreItemsIndicator } from '../../../../../../../../components/MoreItemsIndicator'
import { formatMoney } from '../../../../../../../../helpers/formatMoney'
import { formatPercentage } from '../../../../../../../../helpers/formatPercentage'
import { roundNumber } from '../../../../../../../../helpers/roundNumber'
import { WholesaleOrderLineItemsTableRecord } from '../WholesaleOrderLineItemsTable'

export const useWholesaleOrderLineItemsTableColumnDefs = () => {
  const { t } = useTranslation(['wholesale-order-billing-document', 'translation'])

  const theme = useTheme()

  const [columnDefs] = useState<
    (ColDef<WholesaleOrderLineItemsTableRecord> | ColGroupDef<WholesaleOrderLineItemsTableRecord>)[]
  >([
    {
      field: 'identifier',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.identifier'),
    },
    {
      field: 'productName',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.productName'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'internalSku',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.internalSku'),
    },
    {
      field: 'ean',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.ean'),
    },
    {
      field: 'attributes',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.attribute'),
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['attributes']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        if (Array.isArray(params.value)) {
          return params.value.join(', ')
        }

        return params.value
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['attributes']
        >
      ) => {
        const attributes = params.value

        if (!attributes) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = attributes.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return attributes.map((item) => {
          return <Tag key={item}>{item}</Tag>
        })
      },
    },
    {
      field: 'asins',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.asins'),
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['asins']
        >
      ) => {
        const asins = params.value

        if (!asins) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = asins.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{asins[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={asins} />}
          </Flex>
        )
      },
    },
    {
      field: 'skus',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.skus'),
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['skus']
        >
      ) => {
        const skus = params.value

        if (!skus) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = skus.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{skus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={skus} />}
          </Flex>
        )
      },
    },
    {
      field: 'position',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.position'),
      filter: 'agNumberColumnFilter',
      editable: (params) => !!params.node.isSelected(),
      cellEditor: 'agNumberCellEditor',
      lockVisible: true,
      cellEditorParams: {
        min: 1,
        step: 1,
      },
      valueParser: (
        params: ValueParserParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['position']
        >
      ) => {
        const parsedValue = Number(params.newValue)

        if (!isNaN(parsedValue) && isFinite(parsedValue) && parsedValue >= 1) {
          return parsedValue
        }

        return params.oldValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['position']
        >
      ) => {
        if (!params.node.isSelected()) {
          return null
        }

        return (
          <Space>
            <Pen size={16} />
            {params.value ? (
              <Typography.Text>{params.value}</Typography.Text>
            ) : (
              t('shared.button.edit', { ns: 'translation' })
            )}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['position']
        >
      ) => {
        if (!params.node.isSelected()) {
          return { backgroundColor: 'initial' }
        }

        if (params.value) {
          return { backgroundColor: `${theme.colors.success}40` }
        } else {
          return { backgroundColor: `${theme.colors.error}40` }
        }
      },
    },
    {
      field: 'quantity',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.quantity'),
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'netPurchasePrice',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.netPurchasePrice'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['netPurchasePrice']
        >
      ) => {
        const currency = params.data?.currency ?? 'EUR'

        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, currency)}</Typography.Text>
      },
    },
    {
      field: 'taxRate',
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.newTaxRate'),
      filter: 'agNumberColumnFilter',
      editable: (params) => !!params.node.isSelected(),
      cellEditor: 'agNumberCellEditor',
      lockVisible: true,
      cellEditorParams: {
        min: 0,
        step: 0.01,
        max: 100,
      },
      valueParser: (
        params: ValueParserParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['position']
        >
      ) => {
        const parsedValue = Number(params.newValue)

        if (!isNaN(parsedValue) && isFinite(parsedValue) && parsedValue >= 0) {
          return parsedValue
        }

        return params.oldValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['taxRate']
        >
      ) => {
        if (!params.node.isSelected()) {
          return null
        }

        return (
          <Space>
            <Pen size={16} />
            {params.value !== null && params.value !== undefined ? (
              <Typography.Text>{formatPercentage(params.value / 100)}</Typography.Text>
            ) : (
              t('shared.button.edit', { ns: 'translation' })
            )}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          WholesaleOrderLineItemsTableRecord,
          WholesaleOrderLineItemsTableRecord['taxRate']
        >
      ) => {
        if (!params.node.isSelected()) {
          return { backgroundColor: 'initial' }
        }

        if (params.value !== null && params.value !== undefined) {
          return { backgroundColor: `${theme.colors.success}40` }
        } else {
          return { backgroundColor: `${theme.colors.error}40` }
        }
      },
    },
    {
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.netTotal'),
      filter: 'agNumberColumnFilter',
      valueGetter: (params) => {
        if (!params.data) {
          return null
        }

        const quantity = params.data.quantity
        const netPurchasePrice = params.data.netPurchasePrice

        return roundNumber(quantity * netPurchasePrice)
      },
      cellRenderer: (params: ICellRendererParams<WholesaleOrderLineItemsTableRecord>) => {
        const currency = params.data?.currency ?? 'EUR'

        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, currency)}</Typography.Text>
      },
    },
    {
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.tax'),
      filter: 'agNumberColumnFilter',
      valueGetter: (params) => {
        if (!params.data) {
          return null
        }

        const netPurchasePrice = params.data.netPurchasePrice
        const taxRate = params.data.taxRate ?? 0
        const quantity = params.data.quantity

        return roundNumber(quantity * netPurchasePrice * (taxRate / 100))
      },
      cellRenderer: (params: ICellRendererParams<WholesaleOrderLineItemsTableRecord>) => {
        const currency = params.data?.currency ?? 'EUR'

        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, currency)}</Typography.Text>
      },
    },
    {
      headerName: t('wholesaleOrderLineItemsTable.columnDefs.grossTotal'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sumSelected',
      valueGetter: (params) => {
        if (!params.data) {
          return null
        }

        const quantity = params.data.quantity
        const netPurchasePrice = params.data.netPurchasePrice
        const taxRate = params.data.taxRate ?? 0

        return roundNumber(
          quantity * netPurchasePrice + quantity * netPurchasePrice * (taxRate / 100)
        )
      },
      cellRenderer: (params: ICellRendererParams<WholesaleOrderLineItemsTableRecord>) => {
        const currency = params.data?.currency ?? 'EUR'

        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, currency)}</Typography.Text>
      },
    },
  ])

  return columnDefs
}
