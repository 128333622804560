import { useMutation, useQuery } from '@apollo/client'
import { Form, FormInstance } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import i18n from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import { StatusResult } from '../../../../../../components/Layout/StatusResult/StatusResult'
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner'
import {
  CreateWholesaleOrderBillingDocumentDocument,
  CreateWholesaleOrderBillingDocumentMutation,
  GetAllWholesaleOrdersDetailsDocument,
  SendSupportTicketDocument,
  WholesaleOrderBillingDocumentType,
} from '../../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../../stores/useGlobalStore'
import { createZendeskTicketOptions } from '../../../../../ticket/helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../../../../../ticket/ticket.enum'
import { WholesaleOrderBillingDocumentCreateFormFields } from './WholesaleOrderBillingDocumentCreateFormFields'

export type CreateWholesaleOrderBillingDocumentFormValues = {
  type: WholesaleOrderBillingDocumentType
  debtorCompanySellerId: string
  billingDocumentUuid: string | null
  documentNumber: string
  bucketKey: string
  isProForma: boolean
  issueDate: Dayjs
  dueDate: Dayjs
  lineItems: {
    position: number | null
    identifier: string
    productName: string
    internalSku: string | null
    ean: string | null
    attributes: string[]
    asins: string[]
    skus: string[]
    netPurchasePrice: number
    currency: string
    taxRate: number | null
    quantity: number
  }[]
}

type WholesaleOrderBillingDocumentCreateFormProps = {
  form: FormInstance<CreateWholesaleOrderBillingDocumentFormValues>
  setSubmitting: Dispatch<SetStateAction<boolean>>
}

export const WholesaleOrderBillingDocumentCreateForm = ({
  form,
  setSubmitting,
}: WholesaleOrderBillingDocumentCreateFormProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('wholesale-order-billing-document')
  const navigate = useNavigate()

  const { loading, error, data } = useQuery(GetAllWholesaleOrdersDetailsDocument, {
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  const [createWholesaleOrderBillingDocument] = useMutation(
    CreateWholesaleOrderBillingDocumentDocument
  )
  const [sendSupportTicketRequest] = useMutation(SendSupportTicketDocument)

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const handleFinish = async () => {
    setSubmitting(true)

    try {
      // Retrieve the values from the form, to include hidden form fields.
      const values: CreateWholesaleOrderBillingDocumentFormValues = form.getFieldsValue(true)

      const areFieldsDefined = values.lineItems.every((lineItem) => {
        return lineItem.position !== null && lineItem.taxRate !== null
      })

      if (!areFieldsDefined) {
        toast.error(t('wholesaleOrderBillingDocumentCreateForm.toast.positionTaxRate'))
        return
      }

      const { data } = await createWholesaleOrderBillingDocument({
        variables: {
          input: {
            type: values.type,
            creditorCompanySellerId: selectedCompany.sellerId,
            debtorCompanySellerId: values.debtorCompanySellerId,
            documentNumber: values.documentNumber,
            bucketKey: values.isProForma ? null : values.bucketKey,
            proFormaBucketKey: values.isProForma ? values.bucketKey : null,
            issueDate: values.issueDate.format('YYYY-MM-DD'),
            dueDate: values.dueDate.format('YYYY-MM-DD'),
            lineItems: values.lineItems.map((lineItem) => ({
              position: lineItem.position!,
              identifier: lineItem.identifier,
              netPurchasePrice: lineItem.netPurchasePrice,
              currency: lineItem.currency,
              taxRate: lineItem.taxRate!,
              quantity: lineItem.quantity,
            })),
          },
        },
      })

      if (data) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: getZendeskTicketOptions({
              data,
              selectedCompanySellerId: selectedCompany.sellerId,
            }),
            isAdmin: true,
          },
        })

        const identifier = data.createWholesaleOrderBillingDocument.identifier

        toast.success(t('wholesaleOrderBillingDocumentCreateForm.toast.success'))
        navigate(`/wholesale/billing-documents/${identifier}`)

        form.resetFields()
      }
    } catch (error) {
      console.error(error)
      toast.error(t('wholesaleOrderBillingDocumentCreateForm.toast.error'))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Form<CreateWholesaleOrderBillingDocumentFormValues>
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      initialValues={{ type: WholesaleOrderBillingDocumentType.INVOICE, issueDate: dayjs() }}
    >
      <WholesaleOrderBillingDocumentCreateFormFields data={data} />
    </Form>
  )
}

const getZendeskTicketOptions = ({
  data,
  selectedCompanySellerId,
}: {
  data: CreateWholesaleOrderBillingDocumentMutation
  selectedCompanySellerId: string
}) => {
  const galaxyLink = `https://galaxy.spacegoats.io/wholesale/billing-documents/${data.createWholesaleOrderBillingDocument.identifier}`

  const comment = `
    <h1>Wholesale Order Billing Document created</h1>
    <br />
    <p>Identifier: ${data.createWholesaleOrderBillingDocument.identifier}</p>
    <p>Document number: ${data.createWholesaleOrderBillingDocument.documentNumber}</p>
    <p>Document type: ${data.createWholesaleOrderBillingDocument.type}</p>
    <p>Issue date: ${dayjs(data.createWholesaleOrderBillingDocument.issueDate).format('YYYY-MM-DD')}</p>
    <p>Due date: ${dayjs(data.createWholesaleOrderBillingDocument.dueDate).format('YYYY-MM-DD')}</p>
    <p>Galaxy Link to Document: <button><a href="${galaxyLink}">View</a></button></p>
  `

  const subject = `WOBD uploaded: ${data.createWholesaleOrderBillingDocument.identifier} - ${data.createWholesaleOrderBillingDocument.documentNumber} - ${selectedCompanySellerId}`

  return createZendeskTicketOptions({
    sellerId: selectedCompanySellerId,
    subject,
    language: i18n.language,
    comment,
    categoryTitle: 'Amazon',
    mainCategory: TicketMainCategory.ADMINISTRATION,
    ticketCategory: TicketCategory.BILLING_GALAXY,
  })
}
