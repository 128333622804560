import { Flex, Select, Typography } from 'antd'
import { RefSelectProps, SelectValue } from 'antd/es/select'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import { CompanyStatus } from '../../generated/graphql'
import { UserCompany } from '../../hooks/useAuthentication'
import { getRedirectPath } from '../../router/root'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { useLayoutStore } from '../../stores/useLayoutStore'

type CompanySwitcherProps = {
  companies: UserCompany[]
}

export const CompanySelection = ({ companies }: CompanySwitcherProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const setSelectedCompany = useGlobalStore((state) => state.setSelectedCompany)
  const setSelectedCompanyUuid = useGlobalStore((state) => state.setSelectedCompanyUuid)
  const sidebarCollapsed = useLayoutStore((state) => state.sidebarCollapsed)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const selectRef = useRef<RefSelectProps>(null)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
        if (dropdownOpen) {
          setDropdownOpen(false)
          selectRef.current?.blur()
        } else {
          selectRef.current?.focus()
          setDropdownOpen(true)
        }
      } else if (event.key === 'Escape' && dropdownOpen) {
        setDropdownOpen(false)
        selectRef.current?.blur()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [dropdownOpen, selectRef])

  const handleChange = (value: SelectValue) => {
    const company = companies.find((company) => company?.uuid === value)!
    setSelectedCompany(company)
    setSelectedCompanyUuid(company.uuid)
    navigate(getRedirectPath(company.type))
    toast.success(`Switched to ${company?.name}`)
  }

  const handleDropdownVisibleChange = (open: boolean) => {
    setDropdownOpen(open)
    if (!open) {
      selectRef.current?.blur()
    }
  }

  const sortedCompanies = useMemo(() => {
    const statusOrder = {
      [CompanyStatus.ACTIVE]: 0,
      [CompanyStatus.SIGNED]: 1,
      [CompanyStatus.CANCELLED]: 2,
      [CompanyStatus.INACTIVE]: 3,
    }

    return companies.sort((a, b) => {
      if (a.status !== b.status) {
        return statusOrder[a.status] - statusOrder[b.status]
      }

      return a.sellerId.localeCompare(b.sellerId)
    })
  }, [companies])

  return (
    <Select
      ref={selectRef}
      open={dropdownOpen}
      onChange={handleChange}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      onFocus={() => setDropdownOpen(true)}
      value={selectedCompany.uuid}
      variant="borderless"
      placeholder={t('shared.form.placeholder.select')}
      popupMatchSelectWidth={false}
      options={[
        ...sortedCompanies.map((company) => ({
          value: company.uuid,
          label: (
            <span key={`${company.sellerId} ${company.name}`}>
              {sidebarCollapsed ? (
                <strong style={{ fontFamily: 'monospace' }}>{company.sellerId}</strong>
              ) : (
                <>
                  <strong style={{ marginRight: 8, fontFamily: 'monospace' }}>
                    {company.sellerId}
                  </strong>
                  {company.name}
                </>
              )}
            </span>
          ),
        })),
      ]}
      showSearch
      optionFilterProp="label"
      filterOption={(input, option) => {
        if (!option?.label.key) {
          return false
        }

        return option.label.key.toLowerCase().includes(input.toLowerCase())
      }}
      notFoundContent={
        <Flex justify="center" align="center">
          <Typography.Text type="secondary">{t('menu.company.notFound')}</Typography.Text>
        </Flex>
      }
      suffixIcon={sidebarCollapsed ? null : undefined}
      style={{ margin: '0 -0.5rem' }}
    />
  )
}
