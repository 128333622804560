import { InternalAmazonListingStatus } from '../../../../generated/graphql'
import { TFunction } from 'i18next'

export const getAmazonListingInternalStatusText = (
  internalStatus: InternalAmazonListingStatus,
  t: TFunction<['translation']>
) => {
  if (internalStatus === InternalAmazonListingStatus.ACTIVE) {
    return t(`shared.status.active`)
  } else if (internalStatus === InternalAmazonListingStatus.REVIEW) {
    return t(`shared.status.review`)
  } else if (internalStatus === InternalAmazonListingStatus.PROCESSING) {
    return t(`shared.status.processing`)
  } else if (internalStatus === InternalAmazonListingStatus.DELETED) {
    return t(`shared.status.deleted`)
  }
}
