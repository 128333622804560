import { useTheme } from '@emotion/react'
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  IRichCellEditorParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
} from 'ag-grid-community'
import { Flex, Space, Tag, Typography } from 'antd'
import { TFunction } from 'i18next'
import { Pen } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router'
import { MoreItemsIndicator } from '../../../../../components/MoreItemsIndicator'
import { ProductVariantPackagingUnitType } from '../../../../../generated/graphql'
import { formatMoney } from '../../../../../helpers/formatMoney'
import { WholesaleOrderTablePackagingUnitSelect } from '../components/WholesaleOrderTablePackagingUnitSelect'
import { WholesaleOrderFormTableRecord } from '../WholesaleOrderFormTable'

export const useWholesaleOrderFormTableColumnDefs = () => {
  const { t } = useTranslation('wholesale-order')
  const { t: tInventory } = useTranslation('inventory')

  const theme = useTheme()

  const [searchParams] = useSearchParams()
  const isTemplate = searchParams.get('template') === 'true'

  const [columnDefs] = useState<
    (ColDef<WholesaleOrderFormTableRecord> | ColGroupDef<WholesaleOrderFormTableRecord>)[]
  >([
    {
      field: 'productName',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.productName'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'tags',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.tags'),
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['tags']
        >
      ) => {
        if (params.node.rowPinned === 'bottom') {
          return null
        }

        const tags = params.value

        if (!tags) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = tags.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return tags.map((item) => {
          return <Tag key={item}>{item}</Tag>
        })
      },
    },
    {
      field: 'internalSku',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.internalSku'),
    },
    {
      field: 'ean',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.ean'),
    },
    {
      field: 'attributes',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.attributes'),
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['attributes']
        >
      ) => {
        if (params.node.rowPinned === 'bottom') {
          return null
        }

        const attributes = params.value

        if (!attributes) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = attributes.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return attributes.map((item) => {
          return <Tag key={item}>{item}</Tag>
        })
      },
    },
    {
      field: 'asins',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.asins'),
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['asins']
        >
      ) => {
        if (params.node.rowPinned === 'bottom') {
          return null
        }

        const asins = params.value

        if (!asins) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = asins.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{asins[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={asins} />}
          </Flex>
        )
      },
    },
    {
      field: 'skus',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.skus'),
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['skus']
        >
      ) => {
        if (params.node.rowPinned === 'bottom') {
          return null
        }

        const skus = params.value

        if (!skus) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = skus.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{skus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={skus} />}
          </Flex>
        )
      },
    },
    {
      field: 'availableQuantity',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.currentStock'),
      filter: false,
    },
    {
      field: 'recommendedOrderQuantity',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.recommendedOrderQuantity'),
      filter: 'agNumberColumnFilter',
      cellStyle: (
        params: CellClassParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['recommendedOrderQuantity']
        >
      ) => {
        if (params.value) {
          return { backgroundColor: `${theme.colors.warning}40` }
        }
      },
    },
    {
      field: 'selectedPackagingUnit',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.packagingUnit.quantity'),
      filter: false,
      editable: (
        params: EditableCallbackParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['selectedPackagingUnit']
        >
      ) => {
        return params.node.selectable
      },
      cellEditor: WholesaleOrderTablePackagingUnitSelect,
      cellEditorParams: {
        values: (
          params: ICellEditorParams<
            WholesaleOrderFormTableRecord,
            WholesaleOrderFormTableRecord['selectedPackagingUnit']
          >
        ) => {
          return params.data.packagingUnits
        },
        cellHeight: 32,
        cellRenderer: (
          params: ICellRendererParams<
            WholesaleOrderFormTableRecord,
            WholesaleOrderFormTableRecord['selectedPackagingUnit']
          >
        ) => {
          if (!params.value) {
            return <Typography.Text type="secondary">-</Typography.Text>
          }

          return (
            <Space>
              {params.value.quantity}
              <Tag color={getProductVariantPackagingUnitTypeColor(params.value.type)}>
                {getProductVariantPackagingUnitTypeText(params.value.type, tInventory, true)}
              </Tag>
            </Space>
          )
        },
      } as IRichCellEditorParams<WholesaleOrderFormTableRecord>,
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['selectedPackagingUnit']
        >
      ) => {
        return params.value?.quantity.toString() ?? ''
      },
      valueParser: (
        params: ValueParserParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['selectedPackagingUnit']
        >
      ) => {
        return Number(params.newValue)
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['selectedPackagingUnit']
        >
      ) => {
        if (params.node.rowPinned === 'bottom') {
          return null
        }

        return (
          <Space>
            <Pen size={16} />
            {params.valueFormatted ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['selectedPackagingUnit']
        >
      ) => {
        if (params.node.rowPinned === 'bottom' || !params.node.isSelected()) {
          return { backgroundColor: 'initial' }
        }

        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      field: 'quantityOfPackagingUnits',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.quantityOfPackagingUnits'),
      filter: false,
      editable: (
        params: EditableCallbackParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['quantityOfPackagingUnits']
        >
      ) => {
        return params.node.selectable ? true : false
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 1,
        precision: 0,
      },
      valueParser: (
        params: ValueParserParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['quantityOfPackagingUnits']
        >
      ) => {
        const parsedValue = Math.round(Number(params.newValue))

        if (!isNaN(parsedValue) && isFinite(parsedValue) && parsedValue >= 1) {
          return parsedValue
        }

        return params.oldValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['quantityOfPackagingUnits']
        >
      ) => {
        if (params.node.rowPinned === 'bottom') {
          return null
        }

        return (
          <Space>
            <Pen size={16} />
            {params.value ?? t('shared.button.edit', { ns: 'translation' })}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['quantityOfPackagingUnits']
        >
      ) => {
        if (params.node.rowPinned === 'bottom' || !params.node.isSelected()) {
          return { backgroundColor: 'initial' }
        }

        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      valueGetter: (params: ValueGetterParams<WholesaleOrderFormTableRecord>) => {
        return params.data?.quantityOfPackagingUnits && params.data?.selectedPackagingUnit?.quantity
          ? params.data.quantityOfPackagingUnits * params.data.selectedPackagingUnit.quantity
          : null
      },
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.totalQuantity'),
      filter: false,
      cellRenderer: (params: ICellRendererParams<WholesaleOrderFormTableRecord, number | null>) => {
        if (params.node.rowPinned === 'bottom') {
          return null
        }

        if (!params.node.isSelected()) {
          return null
        }

        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return params.value
      },
    },
    {
      field: 'netPurchasePrice',
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.netPurchasePrice'),
      hide: isTemplate,
      filter: false,
      editable: (
        params: EditableCallbackParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['netPurchasePrice']
        >
      ) => {
        return params.node.selectable ? !isTemplate : false
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0.01,
      },
      valueParser: (
        params: ValueParserParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['netPurchasePrice']
        >
      ) => {
        const parsedValue = Number(params.newValue)

        if (!isNaN(parsedValue) && isFinite(parsedValue) && parsedValue >= 0.01) {
          return parsedValue
        }

        return params.oldValue
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['netPurchasePrice']
        >
      ) => {
        if (params.node.rowPinned === 'bottom') {
          return null
        }

        return (
          <Space>
            <Pen size={16} />
            {params.value
              ? formatMoney(params.value, 'EUR')
              : t('shared.button.edit', { ns: 'translation' })}
          </Space>
        )
      },
      cellStyle: (
        params: CellClassParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['netPurchasePrice']
        >
      ) => {
        if (params.node.rowPinned === 'bottom' || !params.node.isSelected()) {
          return { backgroundColor: 'initial' }
        }

        return params.value
          ? { backgroundColor: 'initial' }
          : { backgroundColor: `${theme.colors.error}40` }
      },
    },
    {
      valueGetter: (params: ValueGetterParams<WholesaleOrderFormTableRecord>) => {
        const totalQuantity =
          params.data?.quantityOfPackagingUnits && params.data?.selectedPackagingUnit?.quantity
            ? params.data.quantityOfPackagingUnits * params.data.selectedPackagingUnit.quantity
            : null

        const netPurchasePrice = params.data?.netPurchasePrice

        if (!totalQuantity || !netPurchasePrice) {
          return null
        }

        return totalQuantity * netPurchasePrice
      },
      headerName: t('wholesaleOrderLineItemsSelectorTable.columnDefs.netTotal'),
      hide: isTemplate,
      filter: false,
      cellRenderer: (params: ICellRendererParams<WholesaleOrderFormTableRecord>) => {
        if (!params.node.isSelected()) {
          return null
        }

        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
      cellRendererSelector: (params: ICellRendererParams<WholesaleOrderFormTableRecord>) => {
        if (params.node.rowPinned !== 'bottom') {
          return undefined
        }

        let sum = 0

        params.api.getSelectedRows().forEach((row) => {
          const totalQuantity =
            row.quantityOfPackagingUnits && row.selectedPackagingUnit?.quantity
              ? row.quantityOfPackagingUnits * row.selectedPackagingUnit.quantity
              : null

          const netPurchasePrice = row.netPurchasePrice

          if (totalQuantity && netPurchasePrice) {
            const value = totalQuantity * netPurchasePrice

            sum = sum + value
          }
        })

        return {
          component: () => formatMoney(sum, 'EUR'),
        }
      },
    },
    {
      field: 'mostRecentShipmentDestinations',
      headerName: t(
        'wholesaleOrderLineItemsSelectorTable.columnDefs.mostRecentShipmentDestinations'
      ),
      filter: false,
      valueFormatter: (
        params: ValueFormatterParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['mostRecentShipmentDestinations']
        >
      ) => {
        if (!params.value) {
          return ''
        }

        return params.value.map((item) => item.mostRecentShipmentDestination).join(', ')
      },
      cellRenderer: (
        params: ICellRendererParams<
          WholesaleOrderFormTableRecord,
          WholesaleOrderFormTableRecord['mostRecentShipmentDestinations']
        >
      ) => {
        if (params.node.rowPinned === 'bottom') {
          return null
        }

        const items = params.value

        if (!items) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = items.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{items[0]?.mostRecentShipmentDestination}</Typography.Text>
            {totalQuantity > 1 && (
              <MoreItemsIndicator
                items={items}
                renderItem={(item) =>
                  `${item.mostRecentShipmentDestination} (${item.sku} - ${item.marketplace})`
                }
              />
            )}
          </Flex>
        )
      },
    },
  ])

  return columnDefs
}

export function getProductVariantPackagingUnitTypeText(
  type: ProductVariantPackagingUnitType,
  t: TFunction<'inventory'>,
  shortFormat = true
) {
  switch (type) {
    case ProductVariantPackagingUnitType.SMALL_PARCEL:
      return shortFormat
        ? 'SP'
        : t('product.details.productVariant.packagingUnit.table.type.smallParcel')
    case ProductVariantPackagingUnitType.PALLET:
      return shortFormat ? 'P' : t('product.details.productVariant.packagingUnit.table.type.pallet')
    default:
      return shortFormat
        ? '?'
        : t('product.details.productVariant.packagingUnit.table.type.unknown')
  }
}

export function getProductVariantPackagingUnitTypeColor(type: ProductVariantPackagingUnitType) {
  switch (type) {
    case ProductVariantPackagingUnitType.SMALL_PARCEL:
      return 'green'
    case ProductVariantPackagingUnitType.PALLET:
      return 'blue'
    default:
      return 'default'
  }
}
