import { ExternalAmazonListingStatus } from '../../../../generated/graphql'

export const getAmazonListingExternalStatusColor = (
  externalStatus: ExternalAmazonListingStatus
) => {
  switch (externalStatus) {
    case ExternalAmazonListingStatus.ACTIVE:
      return 'green'
    case ExternalAmazonListingStatus.INACTIVE:
      return 'red'
    case ExternalAmazonListingStatus.SEARCH_SUPPRESSED:
      return 'yellow'
    case ExternalAmazonListingStatus.DETAIL_PAGE_REMOVED:
      return 'orange'
  }
}
