import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { useQuery } from '@apollo/client'
import { GetCompanyContractsDocument } from '../../../../generated/graphql'
import { useTranslation } from 'react-i18next'
import {
  S3DocumentListCard,
  S3DocumentListCardPropsData,
} from '../../../../components/S3/S3DocumentListCard'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import dayjs from 'dayjs'

const bucketName = import.meta.env.VITE_AWS_S3_BUCKET_NAME_COMPANY_CONTRACTS

export const CompanyContracts = () => {
  const { t } = useTranslation()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { data, loading, error } = useQuery(GetCompanyContractsDocument, {
    fetchPolicy: 'cache-first',
    skip: !selectedCompany.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  const contracts: S3DocumentListCardPropsData =
    data?.company?.contracts?.map((contract) => {
      return {
        key: contract.contractUrl,
        description: dayjs(contract.created_at).format('DD.MM.YYYY'),
      }
    }) ?? []

  if (error) {
    return <StatusResult status="500" title="500" subTitle={t('shared.error.message')} />
  }

  return (
    <S3DocumentListCard
      data={contracts}
      loading={loading}
      bucketName={bucketName}
      showPreview
      showViewButton
      showDownloadButton
      customGetFilenameFromKey={getFilenameFromKey}
    />
  )
}

function getFilenameFromKey(key: string) {
  // This check is needed because old upload structure were nesting files in folders and new one is not
  const doesKeyHaveMoreThanOneSlash = key.split('/').length > 2
  return doesKeyHaveMoreThanOneSlash
    ? key.split('/').slice(1).join('-')
    : key.split('_').slice(1).join('_')
}
