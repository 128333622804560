import { utils, WorkSheet, writeFile } from 'xlsx'

export const downloadXlsx = (data: {}, filename: string, autoResizeColumns = false) => {
  try {
    const wb = utils.book_new()

    for (const key in data) {
      ;(data as any)[key].forEach((dataObject: any) => {
        for (const dataKey in dataObject) {
          if (dataKey === '__typename' || dataKey === 'id') {
            delete dataObject[dataKey]
          }
        }
      })

      const ws_name = key
      const ws_data: any = (data as any)[key]
      const ws: WorkSheet = utils.json_to_sheet(ws_data)

      if (autoResizeColumns) {
        ws['!cols'] = fitToColumn(ws_data)
      }

      utils.book_append_sheet(wb, ws, ws_name)
    }

    writeFile(wb, filename)
  } catch (e) {
    console.error(e)
  }
}

function fitToColumn(array: any[]) {
  const keys = array.length > 0 ? Object.keys(array[0]) : []

  return keys.map((key, index) => {
    const maxRowLength = array.reduce((max: number, row: any) => {
      return Math.max(max, row[key] ? row[key].toString().length : key.length)
    }, 0)
    return { wch: maxRowLength > keys[index].length ? maxRowLength : keys[index].length }
  })
}
