import { Alert, Descriptions, Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { StyledMainCard } from '../../../dashboard/styles'
import { descriptionsStyle } from '../../../products/components/styles'
import { AmazonInboundShipmentPlan } from '../AmazonInboundShipmentPlanView'
import { getTransportName } from '../helpers/getTransportName'

type AmazonInboundShipmentPlanTransportDetailsProps = {
  amazonInboundShipmentPlan: AmazonInboundShipmentPlan
}

export const AmazonInboundShipmentPlanTransportDetails = ({
  amazonInboundShipmentPlan,
}: AmazonInboundShipmentPlanTransportDetailsProps) => {
  const { t } = useTranslation('inventory')

  return (
    <StyledMainCard
      title={t('amazonInboundShipmentPlan.table.transportDetails')}
      style={{ height: 'auto' }}
    >
      <Flex vertical gap={16}>
        {amazonInboundShipmentPlan.note && (
          <Alert
            type="warning"
            message={t('amazonInboundShipmentPlan.table.note.value')}
            description={amazonInboundShipmentPlan.note}
            showIcon
          />
        )}
        <Descriptions {...descriptionsStyle}>
          <Descriptions.Item label={t('amazonInboundShipmentPlan.table.deliveryService')}>
            {getTransportName(amazonInboundShipmentPlan.carrierName)}
          </Descriptions.Item>
          <Descriptions.Item label={t('amazonInboundShipmentPlan.transport.shipmentType')}>
            {amazonInboundShipmentPlan.shipmentType === 'SP'
              ? t('amazonInboundShipmentPlan.transport.SP')
              : t('amazonInboundShipmentPlan.transport.LTL')}
          </Descriptions.Item>
          <Descriptions.Item label={t('amazonInboundShipmentPlan.transport.isAmazonPartnered')}>
            {amazonInboundShipmentPlan.isPartnered
              ? t('amazonInboundShipmentPlan.table.dropShipment.yes')
              : t('amazonInboundShipmentPlan.table.dropShipment.no')}
          </Descriptions.Item>
        </Descriptions>
      </Flex>
    </StyledMainCard>
  )
}
