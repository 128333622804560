import { ExternalAmazonListingStatus } from '../../../../generated/graphql'
import { TFunction } from 'i18next'

export const getAmazonListingExternalStatusText = (
  externalStatus: ExternalAmazonListingStatus,
  t: TFunction<['translation']>
) => {
  if (externalStatus === ExternalAmazonListingStatus.ACTIVE) {
    return t(`shared.status.active`)
  } else if (externalStatus === ExternalAmazonListingStatus.INACTIVE) {
    return t(`shared.status.inactive`)
  } else if (externalStatus === ExternalAmazonListingStatus.SEARCH_SUPPRESSED) {
    return t(`shared.status.searchSuppressed`)
  } else if (externalStatus === ExternalAmazonListingStatus.DETAIL_PAGE_REMOVED) {
    return t(`shared.status.detailPageRemoved`)
  }
}
