import { useMutation } from '@apollo/client'
import {
  CellEditRequestEvent,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GetContextMenuItems,
  GetRowIdFunc,
  ICellRendererParams,
  ISelectCellEditorParams,
  ValueFormatterParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Flex, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import * as i18nIsoCountries from 'i18n-iso-countries'
import { Eye, PenLine } from 'lucide-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'
import { LinkButton } from '../../../../components/LinkButton'
import { Table } from '../../../../components/Table/Table'
import i18n from '../../../../config/i18n'
import {
  GetAllProductsDocument,
  GetProductDocument,
  GetProductVariantDocument,
  ProductStatus,
  ProductVariantAttribute,
  TaxCode,
  UpdateInternalSkuDocument,
  UpdateSmallestSalesUnitDocument,
  UpdateTaricDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { TablePersistKey } from '../../../../stores/useTableStore'
import { EditProductBrandModal } from '../EditProductBrandModal'
import { EditProductSalesChannelCategoryModal } from '../EditProductSalesChannelCategoryModal'
import { EditProductTaxCategoryModal } from '../EditProductTaxCategoryModal'
import { EditEanModal } from '../ProductVariantDescriptions/components/EditEanModal'

export type ProductTableRecord = {
  productUuid: string
  name: string
  brand: string | null
  ean: string | null
  internalSku: string | null
  tags: string[]
  variantUuid: string
  sequence: string
  attributes: ProductVariantAttribute[]
  manufacturer: string | null
  countryOfOrigin: string | null
  taric: string | null
  taxCategory: TaxCode | null
  status: ProductStatus
  smallestSalesUnit: number
  salesChannelCategory: string
  searchIdentifiers: string
}

type ProductTableProps = {
  dataSource: ProductTableRecord[] | undefined
  brands: string[]
}

export const ProductTable = ({ dataSource, brands }: ProductTableProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const gridRef = useRef<AgGridReact<ProductTableRecord>>(null)
  const { t } = useTranslation(['inventory', 'translation'])
  const navigate = useNavigate()

  const [updateInternalSku] = useMutation(UpdateInternalSkuDocument)
  const [updateTaric] = useMutation(UpdateTaricDocument)
  const [updateSmallestSalesUnit] = useMutation(UpdateSmallestSalesUnitDocument)

  useEffect(() => {
    if (brands.length > 0 && gridRef.current?.api) {
      const api = gridRef.current.api
      const currentColumnState = api.getColumnState()
      const columnDefs = api.getColumnDefs()

      const newColumnDefs = columnDefs?.map((colDef) => {
        if ('field' in colDef && colDef.field && colDef.field === 'brand') {
          return {
            ...colDef,
            editable: (
              params: EditableCallbackParams<ProductTableRecord, ProductTableRecord['brand']>
            ) => !params.data?.brand,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: brands,
            } as ISelectCellEditorParams,
          }
        } else {
          return colDef
        }
      })
      api.setGridOption('columnDefs', newColumnDefs)
      api.applyColumnState({
        state: currentColumnState,
        applyOrder: true,
      })
    }
  }, [brands, gridRef])

  const [columnDefs] = useState<(ColDef<ProductTableRecord> | ColGroupDef<ProductTableRecord>)[]>([
    {
      filter: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      minWidth: 56,
      maxWidth: 56,
      cellRenderer: (params: ICellRendererParams<ProductTableRecord>) => {
        if (params.node.group) {
          return null
        }

        return (
          <Tooltip title={t('amazonInboundShipmentPlan.tooltip.details')}>
            <LinkButton
              icon={<Eye size={16} />}
              to={`/products/${params.node?.data?.productUuid}`}
            />
          </Tooltip>
        )
      },
      getQuickFilterText: ({ data }) => {
        if (!data) {
          return ''
        }

        return data.searchIdentifiers
      },
    },
    {
      field: 'sequence',
      headerName: 'Product #',
      filter: 'agTextColumnFilter',
      sort: 'asc',
      enableRowGroup: true,
      hide: true,
      resizable: true,
    },
    {
      field: 'name',
      headerName: t('product.table.name'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'ean',
      headerName: t('product.table.ean'),
      filter: 'agTextColumnFilter',
      suppressPaste: true,
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['internalSku']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (!params.data) {
          return null
        }

        if (!params.value) {
          return (
            <Flex align="center" gap={8}>
              <EditEanModal productVariantUuid={params.data.variantUuid} />
              <Typography.Text type="secondary">
                {t('shared.button.edit', { ns: 'translation' })}
              </Typography.Text>
            </Flex>
          )
        }

        return <Typography.Text>{params.value}</Typography.Text>
      },
    },
    {
      field: 'internalSku',
      headerName: t('product.table.internalSku'),
      headerTooltip: t('product.table.tooltip.internalSku'),
      filter: 'agTextColumnFilter',
      editable: true,
      suppressPaste: true,
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['internalSku']>
      ) => {
        if (params.node.group) {
          return null
        }

        return (
          <Flex align="center" gap={8}>
            <PenLine size={16} />
            {params.value ? (
              <Typography.Text>{params.value}</Typography.Text>
            ) : (
              <Typography.Text type="secondary">
                {t('shared.button.edit', { ns: 'translation' })}
              </Typography.Text>
            )}
          </Flex>
        )
      },
    },
    {
      field: 'status',
      headerName: t('product.table.status'),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [ProductStatus.ACTIVE, ProductStatus.ARCHIVED],
        cellRenderer: (params: { value: ProductStatus }) => {
          if (params.value === ProductStatus.ACTIVE) {
            return t('translation:shared.status.active')
          } else if (params.value === ProductStatus.ARCHIVED) {
            return t('translation:shared.status.archived')
          }
          return params.value
        },
      },
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['status']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (params.data?.status === ProductStatus.ACTIVE) {
          return <Tag color="success">{t('translation:shared.status.active')}</Tag>
        } else if (params.data?.status === ProductStatus.ARCHIVED) {
          return <Tag>{t('translation:shared.status.archived')}</Tag>
        }
      },
    },
    {
      field: 'brand',
      headerName: t('product.table.brand'),
      filter: 'agTextColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['brand']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (!params.data) {
          return null
        }

        return (
          <Flex align="center" gap={8}>
            <EditProductBrandModal uuid={params.data.productUuid} brand={params.value ?? null} />
            {params.value ? (
              <Typography.Text>{params.value}</Typography.Text>
            ) : (
              <Typography.Text type="secondary">
                {t('shared.button.edit', { ns: 'translation' })}
              </Typography.Text>
            )}
          </Flex>
        )
      },
    },
    {
      field: 'salesChannelCategory',
      headerName: t('product.table.salesChannelCategory'),
      filter: 'agTextColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['brand']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (!params.data) {
          return null
        }

        return (
          <Flex align="center" gap={8}>
            <EditProductSalesChannelCategoryModal
              productUuid={params.data.productUuid}
              salesChannelCategory={params.data.salesChannelCategory}
            />
            {params.value ? (
              <Typography.Text>{params.value}</Typography.Text>
            ) : (
              <Typography.Text type="secondary">
                {t('shared.button.edit', { ns: 'translation' })}
              </Typography.Text>
            )}
          </Flex>
        )
      },
    },
    {
      field: 'attributes',
      headerName: t('product.table.attributes'),
      filter: 'agSetColumnFilter',
      filterValueGetter: (params) => {
        return params.data?.attributes?.map(
          (attribute) => `${attribute.option}: ${attribute.value}`
        )
      },
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['attributes']>
      ) => {
        if (params.node.group) {
          return null
        }

        const attributes = params.data?.attributes

        if (!attributes?.length) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return attributes.map((attribute) => {
          return <Tag key={attribute.option}>{attribute.value}</Tag>
        })
      },
      valueFormatter: ({
        value,
      }: ValueFormatterParams<ProductTableRecord, ProductTableRecord['attributes']>) =>
        value?.toString() ?? t('translation:shared.notAvailable'),
    },
    {
      field: 'taric',
      headerName: t('product.table.taric'),
      headerTooltip: t('product.table.tooltip.taric'),
      filter: 'agTextColumnFilter',
      editable: true,
      suppressPaste: true,
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['taric']>
      ) => {
        if (params.node.group) {
          return null
        }

        return (
          <Flex align="center" gap={8}>
            <PenLine size={16} />
            {params.value ? (
              <Typography.Text>{params.value}</Typography.Text>
            ) : (
              <Typography.Text type="secondary">
                {t('shared.button.edit', { ns: 'translation' })}
              </Typography.Text>
            )}
          </Flex>
        )
      },
    },
    {
      field: 'manufacturer',
      headerName: t('product.table.manufacturer'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'countryOfOrigin',
      headerName: t('product.table.countryOfOrigin'),
      filter: 'agTextColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['countryOfOrigin']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Tooltip title={i18nIsoCountries.getName(params.value, i18n.language)}>
            <Typography.Text>{params.value}</Typography.Text>
          </Tooltip>
        )
      },
    },
    {
      field: 'taxCategory',
      headerName: t('product.table.taxCategory'),
      headerTooltip: t('product.table.tooltip.taxCategory'),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: Object.values(TaxCode),
      },
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['taxCategory']>
      ) => {
        if (params.node.group) {
          return null
        }

        if (!params.data) {
          return null
        }

        return (
          <Flex align="center" gap={8}>
            <EditProductTaxCategoryModal
              uuid={params.data.productUuid}
              taxCategory={params.value ?? null}
            />
            {params.value ? (
              <Typography.Text>{params.value}</Typography.Text>
            ) : (
              <Typography.Text type="secondary">
                {t('shared.button.edit', { ns: 'translation' })}
              </Typography.Text>
            )}
          </Flex>
        )
      },
    },
    {
      field: 'tags',
      headerName: t('product.table.tags'),
      filter: 'agSetColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['tags']>
      ) => {
        if (params.node.group) {
          return null
        }

        const tags = params.data?.tags ?? []

        if (!tags.length) {
          return <Typography.Text type="secondary">{t('product.card.noTags')}</Typography.Text>
        }

        return tags.map((tag) => {
          return <Tag key={tag}>{tag}</Tag>
        })
      },
    },
    {
      field: 'smallestSalesUnit',
      headerName: t('product.table.smallestSalesUnit'),
      headerTooltip: t('product.table.tooltip.smallestSalesUnit'),
      filter: 'agNumberColumnFilter',
      editable: true,
      suppressPaste: true,
      cellRenderer: (
        params: ICellRendererParams<ProductTableRecord, ProductTableRecord['smallestSalesUnit']>
      ) => {
        if (params.node.group) {
          return null
        }

        return (
          <Flex align="center" gap={8}>
            <PenLine size={16} />
            {params.value ? (
              <Typography.Text>{params.value}</Typography.Text>
            ) : (
              <Typography.Text type="secondary">
                {t('shared.button.edit', { ns: 'translation' })}
              </Typography.Text>
            )}
          </Flex>
        )
      },
    },
  ])

  const getRowId = useMemo<GetRowIdFunc<ProductTableRecord>>(
    () => (params) => params.data.variantUuid,
    []
  )

  const onCellEditRequest = useCallback(async (event: CellEditRequestEvent<ProductTableRecord>) => {
    const field = event.colDef.field

    if (!field) {
      return false
    }

    if (event.newValue === event.oldValue) {
      return false
    }

    switch (field) {
      case 'internalSku': {
        try {
          const value = event.newValue?.trim() || null

          event.api.applyTransaction({
            update: [{ ...event.data, [field]: value }],
          })

          await updateInternalSku({
            variables: {
              uuid: event.data.variantUuid,
              internalSku: value,
            },
            optimisticResponse: {
              updateProductVariant: {
                internalSku: value,
              },
            },
            update: (cache, { data }) => {
              const updatedProductVariant = data?.updateProductVariant
              const cachedQuery = cache.readQuery({
                query: GetProductVariantDocument,
                variables: { uuid: event.data.variantUuid },
              })

              if (updatedProductVariant && cachedQuery) {
                cache.writeQuery({
                  query: GetProductVariantDocument,
                  variables: { uuid: event.data.variantUuid },
                  data: {
                    productVariant: {
                      ...cachedQuery.productVariant,
                      internalSku: updatedProductVariant.internalSku,
                    },
                  },
                })
              }
            },
            refetchQueries: [
              {
                query: GetAllProductsDocument,
                variables: {
                  uuid: selectedCompany?.uuid,
                },
              },
            ],
          })
          toast.success(t('product.details.productVariant.internalSkuModal.success'))
        } catch (error) {
          event.api.applyTransaction({
            update: [{ ...event.data, [field]: event.oldValue }],
          })
          console.error(error)
          toast.error(t('shared.error.message', { ns: 'translation' }))
        } finally {
          event.api.refreshCells({
            force: true,
            columns: [field],
            rowNodes: [event.node],
          })
        }

        break
      }

      case 'taric': {
        try {
          const value = event.newValue?.trim() || null

          event.api.applyTransaction({
            update: [{ ...event.data, [field]: value }],
          })

          await updateTaric({
            variables: {
              productUuid: event.data.productUuid,
              taric: { code: value, year: dayjs().format('YYYY') },
            },
            optimisticResponse: {
              updateTaric: {
                code: value,
              },
            },
            update: (cache, { data }) => {
              const updatedTaric = data?.updateTaric
              const cachedQuery = cache.readQuery({
                query: GetProductDocument,
                variables: { uuid: event.data.productUuid },
              })

              if (updatedTaric && cachedQuery && updatedTaric?.code) {
                cache.writeQuery({
                  query: GetProductDocument,
                  variables: { uuid: event.data.productUuid },
                  data: {
                    product: {
                      ...cachedQuery.product,
                      taric: [
                        {
                          code: updatedTaric.code,
                          year: updatedTaric.year,
                        },
                      ],
                    },
                  },
                })
              }
            },
            refetchQueries: [
              {
                query: GetAllProductsDocument,
                variables: {
                  uuid: selectedCompany?.uuid,
                },
              },
            ],
          })
          toast.success(t('product.details.product.editTaric.success'))
        } catch (error) {
          event.api.applyTransaction({
            update: [{ ...event.data, [field]: event.oldValue }],
          })
          console.error(error)
          toast.error(t('shared.error.message', { ns: 'translation' }))
        } finally {
          event.api.refreshCells({
            force: true,
            columns: [field],
            rowNodes: [event.node],
          })
        }
        break
      }

      case 'smallestSalesUnit': {
        try {
          const value = +event.newValue

          if (value >= 1) {
            event.api.applyTransaction({
              update: [{ ...event.data, [field]: value }],
            })

            await updateSmallestSalesUnit({
              variables: {
                uuid: event.data.variantUuid,
                smallestSalesUnit: value,
              },
              optimisticResponse: {
                updateProductVariant: {
                  smallestSalesUnit: value,
                },
              },
              update: (cache, { data }) => {
                const updatedProductVariant = data?.updateProductVariant
                const cachedQuery = cache.readQuery({
                  query: GetProductVariantDocument,
                  variables: { uuid: event.data.variantUuid },
                })

                if (updatedProductVariant && cachedQuery) {
                  cache.writeQuery({
                    query: GetProductVariantDocument,
                    variables: { uuid: event.data.variantUuid },
                    data: {
                      productVariant: {
                        ...cachedQuery.productVariant,
                        smallestSalesUnit: updatedProductVariant.smallestSalesUnit,
                      },
                    },
                  })
                }
              },
              refetchQueries: [
                {
                  query: GetAllProductsDocument,
                },
              ],
            })
            toast.success(t('product.details.productVariant.smallestSalesUnitModal.success'))
          }
        } catch (error) {
          event.api.applyTransaction({
            update: [{ ...event.data, [field]: event.oldValue }],
          })
          console.error(error)
          toast.error(t('shared.error.message', { ns: 'translation' }))
        } finally {
          event.api.refreshCells({
            force: true,
            columns: [field],
            rowNodes: [event.node],
          })
        }

        break
      }

      default:
        break
    }
  }, [])

  const getContextMenuItems: GetContextMenuItems<ProductTableRecord> = useCallback(
    (params) => [
      {
        name: t('product.table.contextMenu.showDetails'),
        disabled: !params.node?.data?.productUuid,
        action: () => {
          return navigate(`${params.node?.data?.productUuid}`)
        },
      },
    ],
    []
  )

  return (
    <Table<ProductTableRecord>
      columnDefs={columnDefs}
      fullHeight
      getContextMenuItems={getContextMenuItems}
      getRowId={getRowId}
      groupDefaultExpanded={-1}
      onCellEditRequest={onCellEditRequest}
      persist={{
        key: TablePersistKey.PRODUCT,
        storage: localStorage,
      }}
      readOnlyEdit
      ref={gridRef}
      rowData={dataSource}
      rowGroupPanelShow="always"
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
      storeSearchInSearchParams
    />
  )
}
