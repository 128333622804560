import { Collapse } from 'antd'
import { useState } from 'react'
import { WarehouseServiceProviderLocationFieldsFragment } from '../../../../generated/graphql'
import { useCollapseItems } from '../helpers/useCollapseItems'

export enum AmazonInboundShipmentPlanStep {
  INVENTORY,
  QUANTITY,
  CARTON,
  TRANSPORT,
  SUMMARY,
}

type AmazonInboundShipmentPlanCreateFormCollapseProps = {
  locations: WarehouseServiceProviderLocationFieldsFragment[]
  submitting: boolean
}

export const AmazonInboundShipmentPlanCreateFormCollapse = ({
  locations,
  submitting,
}: AmazonInboundShipmentPlanCreateFormCollapseProps) => {
  const [currentStep, setCurrentStep] = useState(AmazonInboundShipmentPlanStep.INVENTORY)

  const items = useCollapseItems({
    locations,
    setCurrentStep,
    submitting,
  })

  return <Collapse activeKey={currentStep} items={items} />
}
