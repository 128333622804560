import { useMutation, useQuery } from '@apollo/client'
import { AgGridReact } from 'ag-grid-react'
import { Button, Space } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import { Plus } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, useLocation, useNavigate, useSearchParams } from 'react-router'
import { toast } from 'sonner'
import { ViewContainer } from '../../components/Layout/ContentWrapper'
import { StatusResult } from '../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import {
  CreateWholesaleOrderDocument,
  CreateWholesaleOrderInput,
  CreateWholesaleOrderTemplateDocument,
  CreateWholesaleOrderTemplateInput,
  GetSalesChannelRegionDealDocument,
  GetWarehousesDocument,
} from '../../generated/graphql'
import { usePreventNavigation } from '../../hooks/usePreventNavigation'
import { useGlobalStore } from '../../stores/useGlobalStore'
import { StockTableRecord } from '../../views/stock/components/StockTable'
import { WholesaleOrderCreateImportModal } from './components/WholesaleOrderCreateImportModal'
import {
  WholesaleOrderForm,
  WholesaleOrderFormInstance,
} from './components/WholesaleOrderForm/WholesaleOrderForm'
import { WholesaleOrderFormTableRecord } from './components/WholesaleOrderForm/WholesaleOrderFormTable'
import { WholesaleOrderTermsModal } from './components/WholesaleOrderForm/WholesaleOrderTermsModal'

export const WholesaleOrderCreateView = () => {
  const [rowData, setRowData] = useState<WholesaleOrderFormTableRecord[]>([])
  const [submitting, setSubmitting] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const gridRef = useRef<AgGridReact<WholesaleOrderFormTableRecord> | null>(null)

  const navigate = useNavigate()
  const { t } = useTranslation('wholesale-order')
  const [form] = useForm<WholesaleOrderFormInstance>()

  const shipToWarehouseLocationUuid = useWatch(['shipToWarehouseLocationUuid'], form)

  const location = useLocation() as Location<
    | {
        shipToWarehouseLocationUuid: string
        selectedRows: StockTableRecord[]
      }
    | undefined
  >

  useEffect(() => {
    if (!location.state) {
      return
    }

    if (location.state.shipToWarehouseLocationUuid) {
      form.setFieldValue('shipToWarehouseLocationUuid', location.state.shipToWarehouseLocationUuid)
    }
  }, [location.state])

  usePreventNavigation(form)

  const [searchParams] = useSearchParams()
  const isTemplateCreation = searchParams.get('template') === 'true'

  const { loading, error, data } = useQuery(GetWarehousesDocument, {
    errorPolicy: 'none',
    variables: { companyUuid: selectedCompany.uuid },
  })

  const { data: salesChannelRegionData } = useQuery(GetSalesChannelRegionDealDocument, {
    skip: !shipToWarehouseLocationUuid,
    variables: {
      companyUuid: selectedCompany.uuid,
      warehouseServiceProviderLocationUuid: shipToWarehouseLocationUuid!,
    },
  })

  const [createWholesaleOrder] = useMutation(CreateWholesaleOrderDocument)
  const [createWholesaleOrderTemplate] = useMutation(CreateWholesaleOrderTemplateDocument)

  if (loading) {
    return <LoadingSpinner />
  }

  if (error || !data) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const handleImport = (importedData: WholesaleOrderFormTableRecord[]) => {
    if (importedData.length === 0) {
      return
    }

    setRowData((prevRowData) => {
      const selectedRowData = prevRowData.filter((row) => {
        return importedData.map((item) => item.productVariantUuid).includes(row.productVariantUuid)
      })

      const updatedSelectedRowData = selectedRowData.map((row) => {
        const matchingImportData = importedData.find((item) => {
          return item.productVariantUuid === row.productVariantUuid
        })

        if (!matchingImportData) {
          return row
        }

        return {
          ...row,
          ...matchingImportData,
        }
      })

      const unselectedRowData = prevRowData.filter((row) => {
        return !importedData.map((item) => item.productVariantUuid).includes(row.productVariantUuid)
      })

      return [...updatedSelectedRowData, ...unselectedRowData]
    })

    gridRef.current?.api.forEachNode((node) => {
      const productVariantUuid = node.data?.productVariantUuid

      if (
        productVariantUuid &&
        importedData.some((item) => item.productVariantUuid === productVariantUuid)
      ) {
        node.setSelected(true)
      }
    })
  }

  const submitForm = async (values: WholesaleOrderFormInstance) => {
    try {
      setSubmitting(true)

      if (!values.selectedRows?.length) {
        throw new Error('No rows selected.')
      }

      if (isTemplateCreation) {
        const input = formatCreateWholesaleOrderTemplateInput(values, selectedCompany.uuid)

        await createWholesaleOrderTemplate({
          variables: {
            input,
          },
          onCompleted: (data) => {
            toast.success(t('wholesaleOrderCreateView.notification.wholesaleOrderTemplateCreated'))
            navigate(`/wholesale/orders/templates/${data.createWholesaleOrderTemplate.identifier}`)
          },
        })
      } else {
        await createWholesaleOrder({
          variables: {
            input: formatCreateWholesaleOrderInput(values, selectedCompany.uuid),
          },
          onCompleted: (data) => {
            toast.success(t('wholesaleOrderCreateView.notification.wholesaleOrderCreated'))
            navigate(`/wholesale/orders/${data.createWholesaleOrder.identifier}`)
          },
        })
      }
    } catch (error) {
      console.error(error)
      toast.error(t('wholesaleOrderCreateView.error.creationError'))
    } finally {
      setSubmitting(false)
    }
  }

  const selectedRows = gridRef.current?.api?.getSelectedRows() ?? []
  const isValid = validateForm(selectedRows)

  return (
    <>
      <SubHeader
        heading={
          isTemplateCreation
            ? t('wholesaleOrderCreateView.headingTemplate')
            : t('wholesaleOrderCreateView.headingOrder')
        }
        withBackButton
        onBackUrl="/wholesale/orders"
        rightContent={
          <Space>
            <WholesaleOrderTermsModal
              salesChannelRegionDeal={salesChannelRegionData?.salesChannelRegionDeal ?? null}
            />
            <WholesaleOrderCreateImportModal rowData={rowData} onImport={handleImport} />
            <Button
              type="primary"
              icon={<Plus size={16} />}
              onClick={() => form.submit()}
              disabled={!isValid}
              loading={submitting}
            >
              {t('wholesaleOrderCreateView.button.create')}
            </Button>
          </Space>
        }
      />
      <ViewContainer>
        <WholesaleOrderForm
          form={form}
          submitForm={submitForm}
          gridRef={gridRef}
          company={data.company}
          rowData={rowData}
          setRowData={setRowData}
        />
      </ViewContainer>
    </>
  )
}

function formatCreateWholesaleOrderInput(
  values: WholesaleOrderFormInstance,
  selectedCompanyUuid: string
): CreateWholesaleOrderInput {
  if (
    !values.shipFromWarehouseLocationUuid ||
    !values.shipToWarehouseLocationUuid ||
    !values.selectedRows ||
    values.selectedRows.length === 0
  ) {
    throw new Error('Form is not valid.')
  }

  return {
    vendorCompanyUuid: selectedCompanyUuid,
    vendorApprovedAt: dayjs().toISOString(),
    shippedFromWarehouseLocationUuid: values.shipFromWarehouseLocationUuid,
    shippedToWarehouseLocationUuid: values.shipToWarehouseLocationUuid,
    lineItems: values.selectedRows.map((row) => {
      const totalQuantity =
        row.quantityOfPackagingUnits && row.selectedPackagingUnit?.quantity
          ? row.quantityOfPackagingUnits * row.selectedPackagingUnit.quantity
          : undefined

      if (!totalQuantity) {
        throw new Error('Total quantity is missing.')
      }

      if (!row.netPurchasePrice) {
        throw new Error('Net purchase price is missing.')
      }

      if (!row.selectedPackagingUnit) {
        throw new Error('Packaging unit is missing.')
      }

      if (!row.quantityOfPackagingUnits) {
        throw new Error('Quantity of packaging units is missing.')
      }

      return {
        productVariantUuid: row.productVariantUuid,
        totalQuantity,
        quantityOfPackagingUnits: row.quantityOfPackagingUnits,
        packagingUnitUuid: row.selectedPackagingUnit.uuid,
        netPurchasePrice: row.netPurchasePrice,
        currency: 'EUR',
      }
    }),
  }
}

function formatCreateWholesaleOrderTemplateInput(
  values: WholesaleOrderFormInstance,
  selectedCompanyUuid: string
): CreateWholesaleOrderTemplateInput {
  if (
    !values.name ||
    !values.shipFromWarehouseLocationUuid ||
    !values.shipToWarehouseLocationUuid ||
    !values.selectedRows ||
    values.selectedRows.length === 0
  ) {
    throw new Error('Form is not valid.')
  }

  return {
    name: values.name,
    sellerCompanyUuid: selectedCompanyUuid,
    shippedFromWarehouseLocationUuid: values.shipFromWarehouseLocationUuid,
    shippedToWarehouseLocationUuid: values.shipToWarehouseLocationUuid,
    lineItems: values.selectedRows.map((row) => {
      const totalQuantity =
        row.quantityOfPackagingUnits && row.selectedPackagingUnit?.quantity
          ? row.quantityOfPackagingUnits * row.selectedPackagingUnit.quantity
          : undefined

      if (!totalQuantity) {
        throw new Error('Total quantity is missing.')
      }

      return {
        productVariantUuid: row.productVariantUuid,
        defaultOrderQuantity: totalQuantity,
      }
    }),
    interval: convertIntervalToDays({ interval: values.interval, frequency: values.frequency }),
    weekdays: values.weekdays,
    startDate: dayjs(values.startDate).format('YYYY-MM-DD'),
    notificationEmails: values.notificationEmails,
  }
}

function convertIntervalToDays({
  interval,
  frequency = 1,
}: {
  interval?: string
  frequency?: number
}) {
  switch (interval) {
    case 'day':
      return frequency
    case 'week':
      return frequency * 7
    case 'month':
      return frequency * 30
    default:
      return frequency
  }
}

export function validateForm(selectedRows: WholesaleOrderFormTableRecord[]) {
  if (selectedRows.length === 0) {
    return false
  }

  if (selectedRows.some((row) => !row.selectedPackagingUnit)) {
    return false
  }

  if (selectedRows.some((row) => !row.quantityOfPackagingUnits)) {
    return false
  }

  if (selectedRows.some((row) => !row.netPurchasePrice)) {
    return false
  }

  return true
}
