import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { ProductCreateFormInstance } from '../components/ProductCreateForm'

export async function uploadComplianceImages(
  complianceImages: ProductCreateFormInstance['complianceImages'],
  productUuid: string
): Promise<{ key: string; fileName: string }[] | undefined> {
  const images = {
    'package-front-view': complianceImages?.package?.['frontView'],
    'package-back-view': complianceImages?.package?.['backView'],
    'package-top-view': complianceImages?.package?.['topView'],
    'package-bottom-view': complianceImages?.package?.['bottomView'],
    'package-left-view': complianceImages?.package?.['leftView'],
    'package-right-view': complianceImages?.package?.['rightView'],
    'product-front-view': complianceImages?.product?.['frontView'],
    'product-back-view': complianceImages?.product?.['backView'],
    'product-top-view': complianceImages?.product?.['topView'],
    'product-bottom-view': complianceImages?.product?.['bottomView'],
    'product-left-view': complianceImages?.product?.['leftView'],
    'product-right-view': complianceImages?.product?.['rightView'],
    'product-inside-view': complianceImages?.product?.['insideView'],
    'label-front-view': complianceImages?.label?.['frontView'],
    'label-back-view': complianceImages?.label?.['backView'],
    'document-front-view': complianceImages?.document?.['frontView'],
    'document-back-view': complianceImages?.document?.['backView'],
  }

  try {
    const uploadedComplianceImages = await Promise.all(
      Object.entries(images).map(async ([key, value]) => {
        const unixTimestamp = dayjs().unix()
        const extension = value?.name.split('.').pop()
        const fileName = `${productUuid}/compliance/${unixTimestamp}_${key}.${extension}`

        try {
          await Storage.put(fileName, value?.originFileObj, {
            bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME_PRODUCT_DOCUMENTS,
            contentType: value?.type,
          })

          return { key, fileName }
        } catch (error) {
          return Promise.reject(error)
        }
      })
    )

    return uploadedComplianceImages
  } catch (error) {
    console.error(error)
    return undefined
  }
}
