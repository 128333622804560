import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

export const PersonalNoteInput = () => {
  const { t } = useTranslation(['ticket'], {
    keyPrefix: 'ticketCreateView',
  })

  return (
    <Form.Item name="personalNotes" label={t('personalNotes.label')}>
      <Input.TextArea rows={3} placeholder={t('personalNotes.placeholder')} />
    </Form.Item>
  )
}
