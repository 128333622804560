import { useMutation } from '@apollo/client'
import { Button, Upload, UploadProps } from 'antd'
import { Storage } from 'aws-amplify'
import { nanoid } from 'nanoid'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { UploadProofOfDeliveryDocument } from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { AmazonInboundShipmentPlanDiscrepanciesTableRecord } from './AmazonInboundShipmentPlanDiscrepanciesTable'

type UploadProofOfDeliveryButtonProps = {
  shipmentId: string
  setRowData: Dispatch<
    SetStateAction<AmazonInboundShipmentPlanDiscrepanciesTableRecord[] | undefined>
  >
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export const UploadProofOfDeliveryButton = ({
  shipmentId,
  setRowData,
}: UploadProofOfDeliveryButtonProps) => {
  const [loading, setLoading] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation(['inventory'])

  const [uploadProofOfDelivery] = useMutation(UploadProofOfDeliveryDocument)

  const handleChange: UploadProps['onChange'] = async (info) => {
    try {
      setLoading(true)

      if (info.file.status === 'error') {
        throw new Error()
      }

      if (info.file.status === 'done') {
        const key = `${nanoid(10)}.pdf`

        const response = await Storage.put(key, info.file.originFileObj, {
          bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME_AMAZON_INBOUND_SHIPMENTS,
          contentType: info.file.type,
          customPrefix: {
            public: `${selectedCompany.sellerId}/${shipmentId}/proof-of-delivery/`,
          },
        })

        const { data } = await uploadProofOfDelivery({
          variables: {
            input: { shipmentId, key: response.key },
          },
        })

        if (!data?.uploadProofOfDelivery) {
          throw new Error()
        }

        setRowData((previousRowData) => {
          return previousRowData?.map((rowData) => {
            if (rowData.shipmentId === shipmentId) {
              return { ...rowData, proofOfDeliveryUrl: data.uploadProofOfDelivery }
            }

            return rowData
          })
        })
      }

      toast.success(t('amazonInboundShipmentPlan.discrepancies.notification.success'))
    } catch (error) {
      console.error(error)
      toast.error(`${t('amazonInboundShipmentPlan.discrepancies.notification.error')} ${error}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Upload
      accept=".pdf"
      customRequest={customRequest}
      maxCount={1}
      showUploadList={false}
      onChange={handleChange}
    >
      <Button
        disabled={loading}
        loading={loading}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {t('shared.button.upload', { ns: 'translation' })}
      </Button>
    </Upload>
  )
}
