import { Col, Empty, Row, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDashboardConfigurationStore } from '../../../stores/useDashboardConfigurationStore'
import { DashboardCardAmazonAds } from './components/DashboardCardAmazonAds'
import { DashboardCardLatestOrders } from './components/DashboardCardLatestOrders'
import { DashboardCardPerformanceChart } from './components/DashboardCardPerformanceChart'
import { DashboardCardPerformanceOverview } from './components/DashboardCardPerformanceOverview'
import { DashboardConfiguration } from './components/DashboardConfiguration'
import { DashboardPerformanceExportModal } from './components/DashboardPerformanceExportModal'

export const DashboardPerformanceView = () => {
  const dashboardConfiguration = useDashboardConfigurationStore(
    (state) => state.dashboardConfiguration
  )

  const { t } = useTranslation('dashboard')

  const isEveryCardHidden = Object.values(dashboardConfiguration.cards).every(
    (card) => !card.active
  )

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row justify="end">
          <Col>
            <Space>
              <DashboardPerformanceExportModal />
              <DashboardConfiguration />
            </Space>
          </Col>
        </Row>
      </Col>
      {dashboardConfiguration.cards['performanceOverview'].active && (
        <Col xs={24} xl={12} order={dashboardConfiguration.cards['performanceOverview'].order}>
          <DashboardCardPerformanceOverview />
        </Col>
      )}
      {dashboardConfiguration.cards['latestOrders'].active && (
        <Col xs={24} xl={12} order={dashboardConfiguration.cards['latestOrders'].order}>
          <DashboardCardLatestOrders />
        </Col>
      )}
      {dashboardConfiguration.cards['performanceChart'].active && (
        <Col xs={24} order={dashboardConfiguration.cards['performanceChart'].order}>
          <DashboardCardPerformanceChart />
        </Col>
      )}
      {dashboardConfiguration.cards['amazonAds'].active && (
        <Col xs={24} order={dashboardConfiguration.cards['amazonAds'].order}>
          <DashboardCardAmazonAds />
        </Col>
      )}
      {isEveryCardHidden && (
        <Col xs={24}>
          <Empty
            description={t('performance.configuration.tiles.empty')}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ maxWidth: '400px', margin: '0 auto' }}
          />
        </Col>
      )}
    </Row>
  )
}
