import { Table } from 'antd'
import { formatCurrency } from '../../../../../../views/ppc/helpers/formatCurrency'
import { useCurrencySymbol } from '../../../../../../views/ppc/helpers/useCurrencySymbol'
import { UpdateSponsoredProductsKeywordInputExtended } from '../../../../../../views/ppc/helpers/interfaces'

type BulkEditKeywordBidTableProps = {
  keywords: UpdateSponsoredProductsKeywordInputExtended[]
  loading: boolean
}

const BulkEditKeywordBidTable = ({ keywords, loading }: BulkEditKeywordBidTableProps) => {
  const currencySymbol = useCurrencySymbol()

  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'keywordText',
      key: 'keywordText',
    },
    {
      title: 'Bid',
      children: [
        {
          title: 'Current',
          dataIndex: 'bid',
          key: 'bid',
          render: (bid: number) => formatCurrency(currencySymbol, bid),
        },
        {
          title: 'New',
          dataIndex: 'newBid',
          key: 'newBid',
          render: (bid: number) => formatCurrency(currencySymbol, bid),
        },
      ],
    },
  ]

  return (
    <Table
      dataSource={keywords}
      columns={columns}
      pagination={false}
      bordered={true}
      rowKey="keywordId"
      loading={loading}
      scroll={{
        y: 300,
      }}
    />
  )
}

export default BulkEditKeywordBidTable
