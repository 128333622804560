import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import { Dayjs } from 'dayjs'
import { ErrorWithRetry } from '../../../../components/ErrorWithRetry'
import { GetPerformanceMetricsDocument, Marketplace } from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { addMissingDatesToData } from '../helpers/addMissingDatesToData'
import { DashboardFinanceChart } from './DashboardFinanceChart'
import { DashboardFinanceSummaryCard } from './DashboardFinanceSummaryCard'

type DashboardFinanceChartSummaryContainerProps = {
  marketplaces: Marketplace[]
  dateRange: [Dayjs, Dayjs]
}

export const DashboardFinanceChartSummaryContainer = ({
  marketplaces,
  dateRange,
}: DashboardFinanceChartSummaryContainerProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { loading, error, data, refetch } = useQuery(GetPerformanceMetricsDocument, {
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyUuid: selectedCompany?.uuid!,
      marketplaces,
      dateRange: {
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
      },
    },
  })

  if (error) {
    return <ErrorWithRetry retry={refetch} />
  }

  const completeData = {
    ...data,
    performanceMetrics: addMissingDatesToData(dateRange, data),
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} xl={18}>
        <DashboardFinanceChart data={completeData} loading={loading} />
      </Col>
      <Col xs={24} xl={6}>
        <DashboardFinanceSummaryCard
          data={completeData}
          timeFrame={`${dateRange[0].format('DD.MM.YYYY')} - ${dateRange[1].format('DD.MM.YYYY')}`}
          loading={loading}
        />
      </Col>
    </Row>
  )
}
