export const getCurrencySymbolOrCodeFromTicketMainCategory = (
  categoryTitleTag: string,
  symbol = true
) => {
  if (symbol) {
    switch (categoryTitleTag) {
      case 'amazon_eu':
        return '€'
      case 'amazon_uk':
        return '£'
      case 'amazon_us':
        return '$'
    }
  } else {
    switch (categoryTitleTag) {
      case 'amazon_eu':
        return 'EUR'
      case 'amazon_uk':
        return 'GBP'
      case 'amazon_us':
        return 'USD'
    }
  }
}
