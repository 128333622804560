import { ApolloError, useLazyQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import { ColDef, ColGroupDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community'
import { Button, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import { FileSpreadsheet, FileText } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { FormattedDate } from '../../../components/FormattedDate'
import { Table } from '../../../components/Table/Table'
import {
  GetAllCreditNoteItemsDocumentsDocument,
  GetAllCreditNoteItemsDocumentsQuery,
  GetAllInvoiceItemsDocumentsDocument,
  GetAllInvoiceItemsDocumentsQuery,
} from '../../../generated/graphql'
import { formatMoney } from '../../../helpers/formatMoney'
import { downloadMultiple } from '../helpers/downloadMultiple'
import { downloadXlsx } from '../helpers/downloadXlsx'
import { prepareXlsxCreditNoteData, prepareXlsxInvoiceData } from '../helpers/prepareXlsxData'

export type InvoiceRecord = {
  yearMonth: string
  invoiceId: string
  documentNumber: string
  date: string
  totalAmount: number
  pdfUrl: string | null | undefined
}

export type CreditNoteRecord = {
  yearMonth: string
  creditNoteId: string
  documentNumber: string
  date: string
  totalAmount: number
  pdfUrl: string | null | undefined
}

type BillingDocumentsTableProps = {
  documents: (InvoiceRecord | CreditNoteRecord)[]
}

export const BillingDocumentsTable = ({ documents }: BillingDocumentsTableProps) => {
  const [fileTitle, setFileTitle] = useState('')

  const { t } = useTranslation(['billing'], { keyPrefix: 'document' })
  const theme = useTheme()

  const [columnDefs] = useState<
    (ColDef<InvoiceRecord | CreditNoteRecord> | ColGroupDef<InvoiceRecord | CreditNoteRecord>)[]
  >([
    {
      field: 'yearMonth',
      headerName: t('table.header.period'),
      headerTooltip: t('popover'),
      enableRowGroup: true,
      initialHide: true,
      initialRowGroup: true,
      initialSort: 'desc',
      valueFormatter: ({ value }: ValueFormatterParams) => {
        return dayjs(value).format('MMM YYYY')
      },
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      field: 'date',
      headerName: t('table.header.date'),
      filter: 'agDateColumnFilter',
      filterValueGetter: (params) => {
        if (params.data) {
          return dayjs(params.data.date).startOf('day').toDate()
        }

        return null
      },
      cellRenderer: (
        params: ICellRendererParams<
          InvoiceRecord | CreditNoteRecord,
          InvoiceRecord['date'] | CreditNoteRecord['date']
        >
      ) => {
        if (params.node.group && params.node.field !== 'date') {
          return null
        }

        return <FormattedDate date={params.value} format="DD.MM.YYYY" layout="horizontal" />
      },
    },
    {
      field: 'documentNumber',
      headerName: t('table.header.documentNumber'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'totalAmount',
      headerName: t('table.header.total'),
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      cellRenderer: (
        params: ICellRendererParams<
          InvoiceRecord | CreditNoteRecord,
          InvoiceRecord['totalAmount'] | CreditNoteRecord['totalAmount']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    {
      headerName: t('table.header.action'),
      filter: false,
      sortable: false,
      cellRenderer: (
        params: ICellRendererParams<
          InvoiceRecord | CreditNoteRecord,
          InvoiceRecord['pdfUrl'] | CreditNoteRecord['pdfUrl']
        >
      ) => {
        if (params.node.group && params.node.field !== 'pdfUrl') {
          const children = params.node.allLeafChildren
          const documents = children?.map((child) => child.data).filter(Boolean)

          if (!documents) {
            return null
          }

          return (
            <Button
              icon={<FileText size={16} style={{ color: theme.colors.error }} />}
              onClick={async () => {
                await downloadMultiple(documents)
              }}
              disabled={!documents.length}
            >
              PDF
            </Button>
          )
        }

        const record = params.data

        if (!record) {
          return null
        }

        return (
          <Space>
            <Button
              icon={<FileText size={16} style={{ color: theme.colors.error }} />}
              href={record.pdfUrl ?? undefined}
              download={record.documentNumber}
              disabled={!record.pdfUrl}
            >
              PDF
            </Button>
            <Button
              icon={<FileSpreadsheet size={16} style={{ color: theme.colors.success }} />}
              onClick={async () => {
                if ('invoiceId' in record) {
                  await downloadInvoiceItems(record.invoiceId, record.documentNumber)
                } else {
                  await downloadCreditNoteItems(record.creditNoteId, record.documentNumber)
                }
              }}
            >
              XLSX
            </Button>
          </Space>
        )
      },
    },
  ])

  const handleOnCompleted = async (
    data: GetAllInvoiceItemsDocumentsQuery | GetAllCreditNoteItemsDocumentsQuery
  ) => {
    let transformedData

    if ('externalInvoiceItems' in data) {
      if (data.externalInvoiceItems.length === 0) {
        return
      }

      transformedData = prepareXlsxInvoiceData(data)
    } else if ('externalCreditNoteItems' in data) {
      if (data.externalCreditNoteItems.length === 0) {
        return
      }

      transformedData = prepareXlsxCreditNoteData(data)
    }

    if (!fileTitle) {
      return
    }

    if (transformedData) {
      downloadXlsx(transformedData, `${fileTitle}.xlsx`)
      setFileTitle('')
    }
  }

  const handleOnError = (error: ApolloError) => {
    toast.error(t('download.error.message'), { description: error.message })
  }

  const [getInvoiceItems] = useLazyQuery(GetAllInvoiceItemsDocumentsDocument, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  })

  const [getCreditNoteItems] = useLazyQuery(GetAllCreditNoteItemsDocumentsDocument, {
    onCompleted: handleOnCompleted,
    onError: handleOnError,
  })

  const downloadInvoiceItems = async (invoiceId: string, documentTitle: string) => {
    setFileTitle(documentTitle)
    await getInvoiceItems({ variables: { invoiceId } })
  }

  const downloadCreditNoteItems = async (creditNoteId: string, documentTitle: string) => {
    setFileTitle(documentTitle)
    await getCreditNoteItems({ variables: { creditNoteId } })
  }

  return (
    <Table<InvoiceRecord | CreditNoteRecord>
      columnDefs={columnDefs}
      fullHeight
      rowData={documents}
      showUniversalSearch
      showFilterReset
      storeSearchInSearchParams
      suppressContextMenu
    />
  )
}
