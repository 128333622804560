import {
  GetAllCreditNoteItemsDocumentsQuery,
  GetAllInvoiceItemsDocumentsQuery,
} from '../../../generated/graphql'

export const prepareXlsxInvoiceData = (data: GetAllInvoiceItemsDocumentsQuery) => {
  const sheetTitle = data.externalInvoiceItems[0]?.invoiceId

  if (!sheetTitle) {
    return null
  }

  return {
    [sheetTitle]: data.externalInvoiceItems.map((document) => {
      return {
        Quantity: document.quantity,
        'Unit Price': document.unitPrice,
        Unit: document.unit,
        Title: document.title,
        Description: document.description,
        'Tax Name': document.taxName,
        'Tax Rate': document.taxRate,
        'Total Net': document.totalNet,
        'Total Gross': document.totalGross,
      }
    }),
  }
}

export const prepareXlsxCreditNoteData = (data: GetAllCreditNoteItemsDocumentsQuery) => {
  const sheetTitle = data.externalCreditNoteItems[0]?.creditNoteId

  if (!sheetTitle) {
    return null
  }

  return {
    [sheetTitle]: data.externalCreditNoteItems.map((document) => {
      return {
        Quantity: document.quantity,
        'Unit Price': document.unitPrice,
        Unit: document.unit,
        Title: document.title,
        Description: document.description,
        'Tax Name': document.taxName,
        'Tax Rate': document.taxRate,
        'Total Net': document.totalNet,
        'Total Gross': document.totalGross,
      }
    }),
  }
}
