import { Form, Input } from 'antd'

type SponsoredProductsCampaignAdGroupCreateFormProps = {
  adGroupNamePrefix: string
}

const SponsoredProductsCampaignAdGroupCreateForm = ({
  adGroupNamePrefix,
}: SponsoredProductsCampaignAdGroupCreateFormProps) => {
  const subFormName = 'adGroup'
  return (
    <>
      <Form.Item name={[subFormName, 'name']} label="Name" rules={[{ required: true }]}>
        <Input
          placeholder="Ad Group Name..."
          data-testid="adgroup-name-input"
          addonBefore={adGroupNamePrefix}
        />
      </Form.Item>
    </>
  )
}

export default SponsoredProductsCampaignAdGroupCreateForm
