import styled from '@emotion/styled'
import { Menu } from 'antd'

export const StyledSidebarMenu = styled(Menu)`
  flex: 1;
  border: none !important;
  background-color: ${({ theme }) => theme.colors.base[2]};
  overflow-y: auto;

  .ant-menu-submenu {
    .ant-menu-sub {
      background-color: transparent !important;
    }
  }

  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled {
    pointer-events: none;
  }

  [role='menuitem'] {
    display: flex;
    align-items: center;

    .ant-menu-item-icon {
      flex-shrink: 0;
    }
  }
`

export const LogoContainer = styled.div`
  flex: 0 0 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
`
