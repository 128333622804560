import { Avatar, Card, Divider, Space } from 'antd'
import dayjs from 'dayjs'
import { User } from 'lucide-react'
import 'react-quill/dist/quill.snow.css'
import { ZendeskComment, ZendeskUser } from '../../generated/graphql'
import { HTMLContentDiv } from '../HTMLContentDiv'

type TicketCommentProps = {
  comment: ZendeskComment
  users: ZendeskUser[]
}

const showAttachments = (comment: ZendeskComment) => {
  if (comment.attachments?.length === 0) {
    return
  }

  return (
    <>
      <Divider orientation="left">Attachments</Divider>
      <ul>
        {comment.attachments?.map((attachment) => {
          return (
            <li key={attachment.content_url}>
              <a href={attachment.content_url ?? ''}>{attachment.file_name}</a>
            </li>
          )
        })}
      </ul>
    </>
  )
}

const TicketComment = ({ comment, users }: TicketCommentProps) => {
  let authorName
  let authorPhotoContentUrl

  users?.forEach((user) => {
    if (user.id === comment.author_id) {
      authorName = user.name

      if (user.photo) {
        authorPhotoContentUrl = user.photo.content_url
      }

      if (user.photo?.thumbnails) {
        user.photo.thumbnails.forEach((thumbnail) => {
          if (thumbnail.width === '32') {
            authorPhotoContentUrl = thumbnail.content_url
          }
        })
      }

      return
    }
  })

  return (
    <Card
      title={
        <Space>
          {authorPhotoContentUrl ? (
            <Avatar src={authorPhotoContentUrl} />
          ) : (
            <Avatar icon={<User size={16} />} />
          )}
          {authorName ? authorName : 'Unknown user'}
        </Space>
      }
      extra={dayjs(comment.created_at).fromNow()}
    >
      <HTMLContentDiv dangerouslySetInnerHTML={{ __html: comment.html_body }} />
      {showAttachments(comment)}
    </Card>
  )
}

export default TicketComment
