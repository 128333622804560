export function formatCurrency(currencySymbol: string, price: any) {
  if (typeof price === 'string') {
    return currencySymbol + price
  }

  price = Number(price)

  if (isNaN(price)) {
    return '-'
  }

  if (!Number.isInteger(price)) {
    price = price.toFixed(2)
  }

  price = price.toString()

  return currencySymbol + price
}
