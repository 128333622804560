import { useMutation } from '@apollo/client'
import { Button, Form, Modal, Popconfirm, Space } from 'antd'
import React, { useState } from 'react'
import { toast } from 'sonner'
import {
  Marketplace,
  UpdateSponsoredProductsTargetInput,
  UpdateSponsoredProductsTargetResponse,
  UpdateSponsoredProductsTargetsDocument,
  UpdateSponsoredProductsTargetsMutation,
} from '../../../../../../generated/graphql'
import { useGlobalStore } from '../../../../../../stores/useGlobalStore'
import { AggregatedTarget } from '../../../../../../views/ppc/helpers/interfaces'
import PPCStateFormFields from '../../../../PPCStateFormFields/PPCStateFormFields'
type BulkEditSponsoredProductsTargetsStateModalProps = {
  marketplace: Marketplace
  campaignId: string
  targetsIn: AggregatedTarget[]
  updateTargetList: Function
}

const BulkEditSponsoredProductsTargetsStateModal = ({
  marketplace,
  campaignId,
  targetsIn,
  updateTargetList,
}: BulkEditSponsoredProductsTargetsStateModalProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true)
  const [form] = Form.useForm()
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)
  const [updateSponsoredProductsTargets, { loading: loadingUpdate }] =
    useMutation<UpdateSponsoredProductsTargetsMutation>(UpdateSponsoredProductsTargetsDocument)

  const showModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const handleOnFinish = async (values: any) => {
    const payload: UpdateSponsoredProductsTargetInput[] = targetsIn.map((target) => {
      return {
        campaignId: campaignId,
        adGroupId: target.adGroupId,
        targetId: target.targetId,
        state: values.state,
        bid: target.bid,
      }
    })

    updateSponsoredProductsTargets({
      variables: { companyUuid: selectedCompany?.uuid, marketplace, targets: payload },
    })
      .then(({ data }) => {
        const updatedTargets = data?.updateSponsoredProductsTargets
        if (!updatedTargets) {
          throw new Error('No targets were updated')
        }
        updatedTargets.forEach((updatedTarget: UpdateSponsoredProductsTargetResponse) => {
          updateTargetList(updatedTarget)
        })
        toast.success('Target(s) updated.')
        setModalVisible(false)
      })
      .catch((e) => {
        console.error(e)
        if (e.networkError?.statusCode === 429) {
          toast.error("Amazon's servers are currently busy, please try again in a few minutes.")
        } else {
          toast.error('Error updating target(s).')
        }
      })
  }

  const handleFormValuesChange = () => {
    const formState = form.getFieldValue('state')

    setSaveButtonDisabled(formState === undefined || formState === null)
  }

  return (
    <>
      <Button key="huihif" onClick={showModal} disabled={targetsIn.length === 0}>
        Change state
      </Button>

      <div
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}
        key="u90u90u"
      >
        <Modal
          title={`Change state of (${targetsIn.length}) selected targets`}
          open={modalVisible}
          onCancel={handleCancel}
          footer={
            <Space>
              <Button onClick={handleCancel} key="cancel">
                Cancel
              </Button>
              <Popconfirm
                key="u8f8f"
                placement="top"
                title={'Please confirm changes to all selected targets'}
                onConfirm={form.submit}
                okText="Confirm"
                cancelText="Go back"
                disabled={saveButtonDisabled}
              >
                <Button
                  key="savel"
                  disabled={saveButtonDisabled}
                  type={'primary'}
                  loading={loadingUpdate}
                >
                  Save
                </Button>
              </Popconfirm>
            </Space>
          }
        >
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            onFinish={handleOnFinish}
            onValuesChange={handleFormValuesChange}
          >
            <PPCStateFormFields />
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default BulkEditSponsoredProductsTargetsStateModal
