import { useTheme } from '@emotion/react'
import { Spin } from 'antd'
import dayjs from 'dayjs'
import { FullscreenControl, LngLat, Map, MapOptions, NavigationControl, Popup } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useEffect, useRef, useState } from 'react'
import { AppTheme, useAppThemeStore } from '../../../../../stores/useAppThemeStore'
import { AmazonMarketplaceDomain } from '../../../../../utils'
import { StyledOrdersHeatMap } from '../../../styles'
import { ColoredAmazonOrder } from '../../helpers/preparePerformanceOverview'

const DEFAULT_LNG = 9.18
const DEFAULT_LAT = 48.78

type OrdersHeatMapProps = {
  orders: ColoredAmazonOrder[]
  loading: boolean
}

export const OrdersHeatMap = ({ orders, loading }: OrdersHeatMapProps) => {
  const [lng, setLng] = useState<LngLat['lng']>(DEFAULT_LNG)
  const [lat, setLat] = useState<LngLat['lat']>(DEFAULT_LAT)
  const [zoom, setZoom] = useState<number>(4)

  const appTheme = useAppThemeStore((state) => state.appTheme)

  const mapContainer = useRef<MapOptions['container']>(null)
  const map = useRef<Map | null>(null)

  const theme = useTheme()

  const mapStyle = appTheme === AppTheme.DARK ? 'dark-v11' : 'light-v11'

  const latestOrder = orders
    .filter((order) => order.shippingAddress.latitude && order.shippingAddress.longitude)
    .sort((a, b) => dayjs(b.purchaseDate).unix() - dayjs(a.purchaseDate).unix())
    .at(0)

  useEffect(() => {
    map.current = new Map({
      accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
      container: mapContainer.current!,
      style: `mapbox://styles/mapbox/${mapStyle}`,
      center: latestOrder
        ? [latestOrder.shippingAddress.longitude!, latestOrder.shippingAddress.latitude!]
        : [lng, lat],
      zoom,
      maxZoom: 10,
    })

    map.current.on('load', () => {
      if (!map.current) {
        return
      }

      map.current.addControl(new NavigationControl({ showCompass: false }), 'bottom-right')
      map.current.addControl(new FullscreenControl(), 'bottom-right')

      const features: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] = orders.flatMap(
        (order) => {
          if (!order.shippingAddress.latitude || !order.shippingAddress.longitude) {
            return []
          }

          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [order.shippingAddress.longitude, order.shippingAddress.latitude],
            },
            properties: order,
          }
        }
      )

      map.current.addSource('orders', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
      })

      map.current.addLayer({
        id: 'orders-heatmap',
        type: 'heatmap',
        source: 'orders',
        maxzoom: 10,
        paint: {
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'transparent',
            0.2,
            'royalblue',
            0.4,
            'cyan',
            0.6,
            'lime',
            0.8,
            'yellow',
            1,
            'red',
          ],
          'heatmap-intensity': {
            type: 'exponential',
            stops: [
              [0, 0.5],
              [1, 0.6],
              [2, 0.7],
              [3, 0.8],
              [4, 0.9],
              [5, 1],
              [6, 0.9],
              [7, 0.8],
              [8, 0.7],
              [9, 0.6],
              [10, 0.5],
            ],
          },
          'heatmap-opacity': {
            type: 'exponential',
            stops: [
              [9, 0.75],
              [10, 0],
            ],
          },
          'heatmap-radius': {
            stops: [
              [0, 1],
              [1, 2],
              [2, 3],
              [3, 4],
              [4, 5],
              [5, 6],
              [6, 7],
              [7, 8],
              [8, 9],
              [9, 10],
            ],
          },
          'heatmap-weight': {
            stops: [
              [5, 1],
              [6, 1.1],
              [7, 1.2],
              [8, 1.3],
              [9, 1.4],
              [10, 1.5],
            ],
          },
        },
      })

      map.current.addLayer({
        id: 'orders-circle',
        type: 'circle',
        source: 'orders',
        minzoom: 9,
        paint: {
          'circle-color': {
            type: 'categorical',
            property: 'status',
            stops: [
              ['Shipped', theme.colors.success],
              ['Pending', theme.colors.warning],
              ['Canceled', theme.colors.error],
            ],
          },
          'circle-opacity': {
            stops: [
              [9, 0],
              [10, 1],
            ],
          },
          'circle-radius': {
            stops: [
              [9, 0],
              [10, 10],
            ],
          },
        },
      })

      map.current.on('click', 'orders-circle', (event) => {
        if (!map.current) {
          return
        }

        const properties = event.features?.[0]?.properties as ColoredAmazonOrder

        new Popup({ closeButton: false })
          .setLngLat(event.lngLat)
          .setHTML(
            `<div><strong>${AmazonMarketplaceDomain[properties.marketplace]}</strong></div><hr><div>${properties.internalSku}</div><div>${
              properties.asin
            }</div><div>${properties.sku}</div><div>${dayjs(properties.purchaseDate).format(
              'DD.MM.YY HH:mm'
            )}</div>`
          )
          .addTo(map.current)
      })
    })

    return () => map.current?.remove()
  }, [orders, mapStyle])

  useEffect(() => {
    if (!map.current) {
      return
    }

    map.current.on('move', () => {
      if (!map.current) {
        return
      }

      const center = map.current.getCenter()
      const zoom = map.current.getZoom()

      setLng(center.lng)
      setLat(center.lat)
      setZoom(zoom)
    })
  })

  return (
    <Spin spinning={loading}>
      {/* @ts-expect-error - Ignore Mapbox types */}
      <StyledOrdersHeatMap ref={mapContainer} />
    </Spin>
  )
}
