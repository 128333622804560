import { StyledTicketTopic } from '../styles/TicketTopicsStyles'

type TicketTopicProps = {
  ticketTopics: TicketTopic[]
  categoryTitleTag: string
}

type TicketTopic = {
  title: string
  url: string
}

const TicketTopics = ({ ticketTopics, categoryTitleTag }: TicketTopicProps) => (
  <ul>
    {ticketTopics.map((topic, index) => {
      return (
        <StyledTicketTopic to={topic.url} state={{ categoryTitleTag }}>
          <li key={index}>{topic.title}</li>
        </StyledTicketTopic>
      )
    })}
  </ul>
)

export default TicketTopics
