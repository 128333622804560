import styled from '@emotion/styled'
import { List } from 'antd'

export const StyledListItem = styled(List.Item)`
  padding: 0 !important;
  transition: all 0.2s ease;

  a {
    flex: 1;
    padding: 1rem 1.5rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary}80;
  }
`
