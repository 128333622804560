export function calculateNetPurchasePrice(recommendedRetailPrice: number) {
  let netPurchasePrice = recommendedRetailPrice * 0.3

  if (netPurchasePrice < 2) {
    netPurchasePrice = 2
  }

  netPurchasePrice = Math.round(Number((Math.round(netPurchasePrice * 100) / 100).toFixed(2)))

  return netPurchasePrice
}
