import { GetInventoryHealthQuery } from '../../../generated/graphql'
import { formatMoney } from '../../../helpers/formatMoney'
import { InventoryHealthReportData } from '../interfaces'

export function prepareInventoryHealthReportData(
  data?: GetInventoryHealthQuery
): InventoryHealthReportData[] {
  if (!data) {
    return []
  }

  const tableData = data.products.flatMap((product) => {
    const amazonProducts =
      product.productVariants?.flatMap((productVariant) => {
        return productVariant.amazonProducts ?? []
      }) ?? []

    return amazonProducts.flatMap((amazonProduct) => {
      if (!amazonProduct.amazonListings) {
        return []
      }

      return amazonProduct.amazonListings.flatMap((amazonListing) => {
        if (!amazonListing) {
          return []
        }

        return {
          name: product.name,
          asin: amazonProduct.asin,
          sku: amazonListing.sku,
          marketplace: amazonListing.marketplace.name,
          mainCategory: amazonListing.mainCategory ?? null,
          salesRank: amazonListing.salesRanks?.[0]?.rank ?? null,
          salesShippedLast7Days:
            amazonListing.inventoryHealth?.salesShippedLast7Days !== undefined
              ? formatMoney(amazonListing.inventoryHealth.salesShippedLast7Days!, 'EUR')
              : null,
          salesShippedLast30Days:
            amazonListing.inventoryHealth?.salesShippedLast30Days !== undefined
              ? formatMoney(amazonListing.inventoryHealth.salesShippedLast30Days!, 'EUR')
              : null,
          salesShippedLast60Days:
            amazonListing.inventoryHealth?.salesShippedLast60Days !== undefined
              ? formatMoney(amazonListing.inventoryHealth.salesShippedLast60Days!, 'EUR')
              : null,
          salesShippedLast90Days:
            amazonListing.inventoryHealth?.salesShippedLast90Days !== undefined
              ? formatMoney(amazonListing.inventoryHealth.salesShippedLast90Days!, 'EUR')
              : null,
          unitsShippedLast7Days: amazonListing.inventoryHealth?.unitsShippedLast7Days ?? null,
          unitsShippedLast30Days: amazonListing.inventoryHealth?.unitsShippedLast30Days ?? null,
          unitsShippedLast60Days: amazonListing.inventoryHealth?.unitsShippedLast60Days ?? null,
          unitsShippedLast90Days: amazonListing.inventoryHealth?.unitsShippedLast90Days ?? null,
          sellThrough: amazonListing.inventoryHealth?.sellThrough ?? null,
          fulfillableQuantity: amazonListing.inventory?.fulfillable?.fulfillableQuantity ?? null,
          inbound:
            (amazonListing.inventory?.inbound?.inboundWorkingQuantity ?? 0) +
            (amazonListing.inventory?.inbound?.inboundShippedQuantity ?? 0) +
            (amazonListing.inventory?.inbound?.inboundReceivingQuantity ?? 0),
          reserved:
            (amazonListing.inventory?.reserved?.pendingCustomerOrderQuantity ?? 0) +
            (amazonListing.inventory?.reserved?.pendingTransshipmentQuantity ?? 0) +
            (amazonListing.inventory?.reserved?.fcProcessingQuantity ?? 0),
          unfulfillable:
            (amazonListing.inventory?.unfulfillable?.customerDamagedQuantity ?? 0) +
            (amazonListing.inventory?.unfulfillable?.warehouseDamagedQuantity ?? 0) +
            (amazonListing.inventory?.unfulfillable?.distributorDamagedQuantity ?? 0) +
            (amazonListing.inventory?.unfulfillable?.carrierDamagedQuantity ?? 0) +
            (amazonListing.inventory?.unfulfillable?.defectiveQuantity ?? 0) +
            (amazonListing.inventory?.unfulfillable?.expiredQuantity ?? 0) +
            (amazonListing.inventory?.researching?.researchingQuantity ?? 0),
          daysOfSupply: amazonListing.inventoryHealth?.daysOfSupply ?? null,
          inventoryAge0To90: amazonListing.inventoryHealth?.inventoryAge0To90 ?? null,
          inventoryAge91To180: amazonListing.inventoryHealth?.inventoryAge91To180 ?? null,
          inventoryAge181To330: amazonListing.inventoryHealth?.inventoryAge181To330 ?? null,
          inventoryAge331To365: amazonListing.inventoryHealth?.inventoryAge331To365 ?? null,
          inventoryAge365Plus: amazonListing.inventoryHealth?.inventoryAge365Plus ?? null,
          healthyInventoryLevel: amazonListing.inventoryHealth?.healthyInventoryLevel ?? null,
          recommendedRemovalQuantity:
            amazonListing.inventoryHealth?.recommendedRemovalQuantity ?? null,
          pendingRemovalQuantity: amazonListing.inventoryHealth?.pendingRemovalQuantity ?? null,
          estimatedExcessQuantity: amazonListing.inventoryHealth?.estimatedExcessQuantity ?? null,
          weeksOfCoverLast30Days: amazonListing.inventoryHealth?.weeksOfCoverLast30Days ?? null,
          weeksOfCoverLast90Days: amazonListing.inventoryHealth?.weeksOfCoverLast90Days ?? null,
          ltsfQuantity11Months: amazonListing.inventoryHealth?.ltsfQuantity11Months ?? null,
          ltsfEstimated11Months: amazonListing.inventoryHealth?.ltsfEstimated11Months ?? null,
          ltsfQuantity12Months: amazonListing.inventoryHealth?.ltsfQuantity12Months ?? null,
          ltsfEstimated12Months: amazonListing.inventoryHealth?.ltsfEstimated12Months ?? null,
          estimatedStorageCostNextMonth:
            amazonListing.inventoryHealth?.estimatedStorageCostNextMonth !== undefined
              ? formatMoney(amazonListing.inventoryHealth.estimatedStorageCostNextMonth!, 'EUR')
              : null,
          storageVolume:
            amazonListing.inventoryHealth?.storageVolume &&
            amazonListing.inventoryHealth?.storageVolumeMeasurement
              ? `${amazonListing.inventoryHealth.storageVolume} (${amazonListing.inventoryHealth.storageVolumeMeasurement})`
              : null,
          alert: amazonListing.inventoryHealth?.alert ?? null,
          recommendedAction: amazonListing.inventoryHealth?.recommendedAction ?? null,
        }
      })
    })
  })

  return tableData.sort((a, b) => a.marketplace.localeCompare(b.marketplace))
}
