import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Navigate, useSearchParams } from 'react-router'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  GetAmazonListingsAndWarehousesDocument,
  Marketplace,
  ShipmentType,
} from '../../../generated/graphql'
import { getMarketplaceDomain } from '../../../helpers/getMarketplaceDomain'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { AmazonInboundShipmentPlanCreateForm } from './components/AmazonInboundShipmentPlanCreateForm'
import { getActiveWarehouseLocations } from './helpers/getActiveWarehouseLocations'
import { prepareAvailableInventory } from './helpers/prepareAvailableInventory'
import { ShipmentKind } from './types'

export const AmazonInboundShipmentPlanCreateView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('inventory')
  const [searchParams] = useSearchParams()
  const shipmentType = searchParams.get('type') as ShipmentKind | null
  const shipmentMode = searchParams.get('mode') as ShipmentType | null
  const marketplace = searchParams.get('marketplace') as Marketplace | null

  if (!shipmentType || !shipmentMode || !marketplace) {
    return <Navigate to="/amazon/shipment-plans" />
  }

  const { loading, error, data } = useQuery(GetAmazonListingsAndWarehousesDocument, {
    skip: !selectedCompany,
    variables: {
      companyUuid: selectedCompany.uuid,
      marketplaces: [marketplace],
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const availableInventory = prepareAvailableInventory(data?.products, selectedCompany)
  const activeWarehouseLocations = getActiveWarehouseLocations(data?.warehouseServiceProviders)

  const heading =
    t('amazonInboundShipmentPlan.create.heading') +
    ' - ' +
    t(`amazonInboundShipmentPlan.create.shipmentType.${shipmentType}`) +
    ' - ' +
    t(`amazonInboundShipmentPlan.create.shipmentMode.${shipmentMode}`) +
    ' - Amazon.' +
    getMarketplaceDomain(marketplace)

  return (
    <>
      <SubHeader heading={heading} withBackButton onBackUrl="/amazon/shipment-plans" />
      <ViewContainer>
        <AmazonInboundShipmentPlanCreateForm
          availableInventory={availableInventory}
          locations={activeWarehouseLocations}
        />
      </ViewContainer>
    </>
  )
}
