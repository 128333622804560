import { useMutation } from '@apollo/client'
import { useTheme } from '@emotion/react'
import {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Button, Popconfirm, Space } from 'antd'
import dayjs from 'dayjs'
import { Pen, Plus, Save, Trash } from 'lucide-react'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { FormattedDate } from '../../../../components/FormattedDate'
import { Table } from '../../../../components/Table/Table'
import { UpdateInvoiceArticleDocument } from '../../../../generated/graphql'
import { formatPercentage } from '../../../../helpers/formatPercentage'

export type InvoiceArticleDiscountRecord = {
  uuid: string
  quantity: number
  discountInPercent: number
  createdAt: string
  updatedAt: string
}

type QuantityDiscountsTableProps = {
  invoiceArticleUuid: string
  discounts: InvoiceArticleDiscountRecord[]
}

const formatDiscountsForMutation = (discounts: InvoiceArticleDiscountRecord[]) => {
  return discounts.map(({ quantity, discountInPercent }) => ({
    quantity,
    discountInPercent,
  }))
}

export const QuantityDiscountsTable = ({
  invoiceArticleUuid,
  discounts: initialDiscounts,
}: QuantityDiscountsTableProps) => {
  const [rowData, setRowData] = useState<InvoiceArticleDiscountRecord[]>(initialDiscounts)
  const gridRef = useRef<AgGridReact<InvoiceArticleDiscountRecord>>(null)
  const { t } = useTranslation(['translation', 'invoices'])
  const theme = useTheme()

  const [updateInvoiceArticle] = useMutation(UpdateInvoiceArticleDocument)

  const onCellValueChanged = useCallback(
    async (event: CellValueChangedEvent<InvoiceArticleDiscountRecord>) => {
      if (!event) {
        console.error('Cell value changed event is null')
        return
      }

      const field = event.colDef.field as keyof InvoiceArticleDiscountRecord
      if (!field) return false

      const newValue = event.newValue
      const oldValue = event.oldValue

      if (newValue === oldValue) return false

      if (event.data.uuid.startsWith('temp-')) {
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: newValue }],
        })
        return
      }

      try {
        let value = newValue
        if (typeof newValue === 'string') {
          value = newValue.trim()
        }

        event.api.applyTransaction({
          update: [{ ...event.data, [field]: value }],
        })

        const updatedDiscounts: InvoiceArticleDiscountRecord[] = []
        event.api.forEachNode((node) => {
          if (node.data) {
            updatedDiscounts.push(node.data)
          }
        })

        setRowData(updatedDiscounts)

        const discountsForMutation = formatDiscountsForMutation(updatedDiscounts)

        await updateInvoiceArticle({
          variables: {
            uuid: invoiceArticleUuid,
            input: {
              quantityDiscounts: discountsForMutation,
            },
          },
        })

        toast.success(t('invoices:articles.quantityDiscounts.update.success'))
      } catch (error) {
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: oldValue }],
        })
        console.error(error)
        toast.error(t('invoices:articles.quantityDiscounts.update.error'))
      } finally {
        event.api.refreshCells({
          force: true,
          columns: [field],
          rowNodes: [event.node],
        })
      }
    },
    [updateInvoiceArticle, t, invoiceArticleUuid]
  )

  const onGridReady = useCallback(
    (params: GridReadyEvent<InvoiceArticleDiscountRecord>) => {
      params.api.applyTransaction({ add: rowData })
      params.api.sizeColumnsToFit()
    },
    [rowData]
  )

  const handleAddEmptyRow = useCallback(() => {
    const api = gridRef.current?.api
    if (!api) return

    const newEmptyRow: InvoiceArticleDiscountRecord = {
      uuid: `temp-${nanoid()}`,
      quantity: 0,
      discountInPercent: 0,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }
    api.applyTransaction({ add: [newEmptyRow] })
  }, [])

  const handleSaveNewRows = useCallback(
    async (gridApi: GridApi<InvoiceArticleDiscountRecord>) => {
      try {
        const rowData: InvoiceArticleDiscountRecord[] = []
        gridApi.forEachNodeAfterFilterAndSort((node) => {
          if (node.data) {
            rowData.push(node.data)
          }
        })

        const newRows = rowData.filter((row) => row.uuid?.startsWith('temp-'))

        if (newRows.length === 0) {
          toast.info(t('invoices:articles.quantityDiscounts.save.noNewRows'))
          return
        }

        const isValid = newRows.every((row) => row.quantity > 0 && row.discountInPercent > 0)

        if (!isValid) {
          toast.error(t('invoices:articles.quantityDiscounts.save.invalidRows'))
          return
        }

        const discountsForMutation = formatDiscountsForMutation(rowData)

        const { data } = await updateInvoiceArticle({
          variables: {
            uuid: invoiceArticleUuid,
            input: {
              quantityDiscounts: discountsForMutation,
            },
          },
        })

        if (data?.updateInvoiceArticle.quantityDiscounts) {
          const updatedDiscounts = data.updateInvoiceArticle.quantityDiscounts.map(
            (discount, index) => {
              const existingDiscount = rowData[index]
              const isNewOrChanged =
                !existingDiscount ||
                existingDiscount.quantity !== discount.quantity ||
                existingDiscount.discountInPercent !== discount.discountInPercent

              return {
                ...discount,
                uuid: existingDiscount?.uuid || `new-${nanoid()}`,
                createdAt: existingDiscount ? existingDiscount.createdAt : new Date().toISOString(),
                updatedAt: isNewOrChanged
                  ? new Date().toISOString()
                  : existingDiscount?.updatedAt || new Date().toISOString(),
              }
            }
          )

          setRowData(updatedDiscounts)
          gridApi.applyTransaction({ update: updatedDiscounts })
        }

        toast.success(t('invoices:articles.quantityDiscounts.save.success'))
      } catch (error) {
        console.error(error)
        toast.error(t('invoices:articles.quantityDiscounts.save.error'))
      }
    },
    [updateInvoiceArticle, invoiceArticleUuid, t]
  )

  const handleDelete = useCallback(
    async (uuid: string) => {
      try {
        const api = gridRef.current?.api
        if (!api) return

        const rowToDelete = api.getRowNode(uuid)
        if (!rowToDelete) {
          console.error(`Row with uuid ${uuid} not found`)
          return
        }

        api.applyTransaction({ remove: [rowToDelete.data as InvoiceArticleDiscountRecord] })

        const updatedDiscounts: InvoiceArticleDiscountRecord[] = []
        api.forEachNode((node) => {
          if (node.data) {
            updatedDiscounts.push(node.data)
          }
        })

        const discountsForMutation = formatDiscountsForMutation(updatedDiscounts)

        await updateInvoiceArticle({
          variables: {
            uuid: invoiceArticleUuid,
            input: {
              quantityDiscounts: discountsForMutation,
            },
          },
        })

        setRowData(updatedDiscounts)

        toast.success(t('invoices:articles.quantityDiscounts.delete.success'))
      } catch (error) {
        console.error(error)
        toast.error(t('invoices:articles.quantityDiscounts.delete.error'))
      }
    },
    [updateInvoiceArticle, invoiceArticleUuid, t]
  )

  useEffect(() => {
    setRowData(initialDiscounts)
  }, [initialDiscounts])

  const columnDefs = useMemo<ColDef<InvoiceArticleDiscountRecord>[]>(
    () => [
      {
        filter: false,
        resizable: false,
        sortable: false,
        suppressColumnsToolPanel: true,
        suppressNavigable: true,
        suppressSizeToFit: true,
        minWidth: 96,
        maxWidth: 96,
        headerComponent: ActionHeader,
        headerComponentParams: {
          onAddEmptyRow: handleAddEmptyRow,
          onSaveNewRows: () => {
            const api = gridRef.current?.api
            if (api) handleSaveNewRows(api)
          },
          api: gridRef.current?.api,
        },
        cellRenderer: ({ data }: ICellRendererParams<InvoiceArticleDiscountRecord>) => {
          const uuid = data?.uuid
          return (
            <Popconfirm
              title={t('invoices:articles.quantityDiscounts.delete.title')}
              description={t('invoices:articles.quantityDiscounts.delete.description')}
              onConfirm={() => handleDelete(uuid!)}
              okText={t('invoices:articles.quantityDiscounts.delete.okText')}
              cancelText={t('invoices:articles.quantityDiscounts.delete.cancelText')}
            >
              <Button icon={<Trash size={16} />} disabled={!uuid} />
            </Popconfirm>
          )
        },
      },
      {
        field: 'quantity',
        headerName: t('invoices:articles.quantityDiscounts.table.quantity'),
        filter: 'agNumberColumnFilter',
        editable: true,
        suppressPaste: true,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 1,
        },
        cellRenderer: (params: ICellRendererParams<InvoiceArticleDiscountRecord>) => {
          if (params.node.group) {
            return null
          }
          return (
            <Space>
              <Pen size={16} />
              {params.value ?? t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
        cellStyle: (params: CellClassParams<InvoiceArticleDiscountRecord>) => {
          if (params.data?.uuid?.startsWith('temp')) {
            return { backgroundColor: `${theme.colors.warning}20` }
          } else {
            return { backgroundColor: 'initial' }
          }
        },
      },
      {
        field: 'discountInPercent',
        headerName: t('invoices:articles.quantityDiscounts.table.discountInPercent'),
        filter: 'agNumberColumnFilter',
        editable: true,
        suppressPaste: true,
        cellEditorParams: {
          min: 0,
        },
        cellRenderer: (params: ICellRendererParams<InvoiceArticleDiscountRecord>) => {
          return (
            <Space>
              <Pen size={16} />
              {formatPercentage(params.value / 100) ??
                t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
        cellStyle: (params: CellClassParams<InvoiceArticleDiscountRecord>) => {
          if (params.data?.uuid?.startsWith('temp')) {
            return { backgroundColor: `${theme.colors.warning}20` }
          } else {
            return { backgroundColor: 'initial' }
          }
        },
      },
      {
        field: 'createdAt',
        headerName: t('invoices:articles.quantityDiscounts.table.createdAt'),
        filter: 'agDateColumnFilter',
        cellRenderer: (params: ICellRendererParams<InvoiceArticleDiscountRecord>) => (
          <FormattedDate date={params.value} withRelativeTime={true} />
        ),
      },
      {
        field: 'updatedAt',
        headerName: t('invoices:articles.quantityDiscounts.table.updatedAt'),
        filter: 'agDateColumnFilter',
        cellRenderer: (params: ICellRendererParams<InvoiceArticleDiscountRecord>) => (
          <FormattedDate date={params.value} withRelativeTime={true} />
        ),
      },
    ],
    [handleAddEmptyRow, handleSaveNewRows, handleDelete, t, theme.colors.warning]
  )

  return (
    <Table<InvoiceArticleDiscountRecord>
      ref={gridRef}
      columnDefs={columnDefs}
      rowData={rowData}
      onGridReady={onGridReady}
      onCellValueChanged={onCellValueChanged}
      defaultExcelExportParams={{
        fileName: `quantity-discounts-${dayjs().format('DD-MM-YYYY')}`,
      }}
      getRowId={(params) => params.data.uuid}
    />
  )
}

const ActionHeader = ({
  onAddEmptyRow,
  onSaveNewRows,
  api,
}: {
  onAddEmptyRow: () => void
  onSaveNewRows: () => void
  api: GridApi<InvoiceArticleDiscountRecord> | undefined
}) => {
  const [hasNewRows, setHasNewRows] = useState(false)

  useEffect(() => {
    const checkForNewRows = () => {
      if (api) {
        const rowData: InvoiceArticleDiscountRecord[] = []
        api.forEachNode((node) => {
          if (node.data) {
            rowData.push(node.data)
          }
        })
        const newRows = rowData.some((row) => row.uuid?.startsWith('temp-'))
        setHasNewRows(newRows)
      }
    }

    checkForNewRows()
    const intervalId = setInterval(checkForNewRows, 500)

    return () => clearInterval(intervalId)
  }, [api])

  const handleSaveNewRows = useCallback(() => {
    if (hasNewRows) {
      onSaveNewRows()
    }
  }, [hasNewRows, onSaveNewRows])

  return (
    <Space>
      <Button onClick={onAddEmptyRow} icon={<Plus size={16} />} type="primary" />
      <Button
        onClick={handleSaveNewRows}
        icon={<Save size={16} />}
        type="primary"
        disabled={!hasNewRows}
      />
    </Space>
  )
}
