import { Marketplace } from '../generated/graphql'

const marketplaceOrder = [
  Marketplace.DE,
  Marketplace.FR,
  Marketplace.ES,
  Marketplace.IT,
  Marketplace.NL,
  Marketplace.UK,
  Marketplace.SE,
  Marketplace.PL,
  Marketplace.BE,
  Marketplace.US,
  Marketplace.CA,
  Marketplace.MX,
]

export function sortByMarketplace(a: Marketplace, b: Marketplace) {
  return marketplaceOrder.indexOf(a) - marketplaceOrder.indexOf(b)
}
