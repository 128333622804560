import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { Typography } from 'antd'
import { Suspense, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { formatMoney } from '../../../../helpers/formatMoney'

export type PaymentsServiceFeeTableRecord = {
  name: string | null
  quantity: number
  fee: number
  comment: string | null
}

type PaymentsServiceFeeTableProps = {
  serviceFees: PaymentsServiceFeeTableRecord[]
}

export const PaymentsServiceFeeTable = ({ serviceFees }: PaymentsServiceFeeTableProps) => {
  const { t } = useTranslation('billing')

  const [columnDefs] = useState<
    (ColDef<PaymentsServiceFeeTableRecord> | ColGroupDef<PaymentsServiceFeeTableRecord>)[]
  >([
    {
      field: 'name',
      headerName: t('payments.details.serviceFees.table.name'),
    },
    {
      field: 'quantity',
      headerName: t('payments.details.serviceFees.table.quantity'),
    },
    {
      field: 'fee',
      headerName: t('payments.details.serviceFees.table.fee'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsServiceFeeTableRecord,
          PaymentsServiceFeeTableRecord['fee']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable', { ns: 'translation' })
      },
    },
    {
      field: 'comment',
      headerName: t('payments.details.serviceFees.table.comment'),
    },
  ])

  const defaultColDef: ColDef<PaymentsServiceFeeTableRecord> = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<PaymentsServiceFeeTableRecord>) => {
        if (params.value === null || params.value === undefined || params.value === '') {
          return t('shared.notAvailable', { ns: 'translation' })
        } else {
          return params.value
        }
      },
    }),
    []
  )

  return (
    <Suspense>
      {!serviceFees?.length ? (
        <Typography.Text type="secondary">
          {t('payments.details.serviceFees.empty')}
        </Typography.Text>
      ) : (
        <Table<PaymentsServiceFeeTableRecord>
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={serviceFees}
          suppressContextMenu
        />
      )}
    </Suspense>
  )
}
