import { useMutation } from '@apollo/client'
import { Button, Form, Row, Switch, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { toast } from 'sonner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import QuillTextarea from '../../../components/Ticket/QuillTextarea'
import { UploadZendeskFile } from '../../../components/Ticket/UploadFile'
import { SendSupportTicketDocument } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { PersonalNoteInput } from '../components/form/PersonalNoteInput'
import { RequestToAmazonDynamicInput } from '../components/form/RequestToAmazonDynamicInput'
import { createZendeskTicketOptions } from '../helpers/Zendesk.helper'
import { TicketCreationCard } from '../styles/TicketCreateViewStyles'
import { TicketCategory, TicketMainCategory } from '../ticket.enum'
import { AttachmentProps } from '../ticket.interface'

type FormInstance = {
  formDetails: FormDetail[]
  messageInput: string
  confirmation_switch: boolean
  personalNotes: string
}

type FormDetail = {
  skus: string
  description: string
  url: string
}

export const RequestToAmazonTicketView = () => {
  const [confirmationSwitchValue, setConfirmationSwitchValue] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const location = useLocation()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()

  const { t, ready, i18n } = useTranslation(['ticket'], {
    keyPrefix: 'amazonSupport.requestToAmazonTicketView',
  })
  const { t: generalTranslation } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [form] = Form.useForm()

  const [sendSupportTicketRequest, { loading }] = useMutation(SendSupportTicketDocument)

  if (!ready) {
    return <LoadingSpinner />
  }

  const initialValues: FormInstance = {
    confirmation_switch: false,
    messageInput: '',
    personalNotes: '',
    formDetails: [
      {
        skus: '',
        description: '',
        url: '',
      },
    ],
  }

  const onFinish = async (values: FormInstance) => {
    const tokens = attachments.map((attachment) => {
      return attachment.token
    })

    const createRequestsListSection = (requestDetails: FormDetail[]) => {
      if (requestDetails.length === 0) {
        return []
      }

      return requestDetails.map((requestDetail) => {
        return `
          <li>
            <p><b>${t('form.skuInput.label')}</b></p>
            <p>${requestDetail.skus}</p>
            <p><b>${t('form.description.label')}</b></p>
            <p>${requestDetail.description}</p>
            <p><b>${t('form.url.label')}</b></p>
            <p>${requestDetail.url}</p>
          </li>
        `
      })
    }

    const createCommentSection = (messageInput: string | undefined) => {
      if (messageInput) {
        return `
          <div>
            <h3>${t('form.comments.label')}</h3>
            <p>${messageInput}</p>
          </div>
        `
      } else {
        return ''
      }
    }

    const message = `
      <h1>${t('ticketDescriptionTitle')}</h1>
      <ul style={{listStyleType: 'none'}}>${createRequestsListSection(values.formDetails).join(
        ''
      )}</ul>
      ${createCommentSection(values.messageInput)}
    `

    const options = createZendeskTicketOptions({
      categoryTitle: location.state.categoryTitleTag,
      comment: message,
      language: i18n.language,
      mainCategory: TicketMainCategory.AMAZON,
      subject: t('viewTitle'),
      ticketCategory: TicketCategory.AMAZON_SUPPORT,
      sellerId: selectedCompany?.sellerId,
      personalNotes: values.personalNotes,
      attachments: tokens,
    })

    try {
      if (selectedCompany !== null && selectedCompany.uuid) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })
        toast.success(generalTranslation('notification.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      console.error(error)
      toast.error(generalTranslation('notification.error'), { description: error.message })
    }
  }

  return (
    <>
      <SubHeader withBackButton />
      <ViewContainer centered>
        <TicketCreationCard title={t('ticketDescriptionTitle')}>
          <Typography.Paragraph>{t('ticketDescriptionSubtitle')}</Typography.Paragraph>
          <Form
            name="request-t-amazon-ticket"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            layout="vertical"
          >
            <RequestToAmazonDynamicInput />
            <Form.Item name="messageInput" label={t('form.comments.label')}>
              <QuillTextarea placeholder={t('form.comments.placeholder')} />
            </Form.Item>
            <PersonalNoteInput />
            <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
            <Form.Item
              name="confirmation_switch"
              label={t('confirmationText')}
              valuePropName="checked"
            >
              <Switch onChange={setConfirmationSwitchValue} />
            </Form.Item>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!confirmationSwitchValue}
                loading={loading}
              >
                {t('sendButtonText')}
              </Button>
            </Row>
          </Form>
        </TicketCreationCard>
      </ViewContainer>
    </>
  )
}
