import { TargetingExpressionPredicateType } from '../../../../../../generated/graphql'

export type ISponsoredProductsAutoTarget = {
  bid: number
  expressionTypePredicate: TargetingExpressionPredicateType
  active: boolean
}

export enum AutomaticTargetingBiddingType {
  DEFAULT = 'default',
  INDIVIDUAL = 'individual',
}
