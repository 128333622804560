import styled from '@emotion/styled'
import { Layout } from 'antd'

export const StyledPublicLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  gap: 1rem;
`

export const HeaderContainer = styled(Layout.Header)`
  flex: 1 0 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  margin: 1rem;
`

export const MainContainer = styled(Layout.Content)`
  flex: 2 1 0 !important;
  margin: 1rem;
`

export const FooterContainer = styled(Layout.Footer)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.base[3]}80 !important;
`

export const FooterItem = styled.div`
  flex: 1 1 200px;
`
