import { Col, Row, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { TooltipProps } from 'recharts'
import { formatMoney } from '../../../../../helpers/formatMoney'
import { ProductPerformanceDetails } from '../../helpers/preparePerformanceOverview'
import { DashboardProductLabel } from '../DashboardProductLabel'
import { StyledMainCard } from '../../../styles'

export const DashboardPieChartTooltip = (props: TooltipProps<number, string>) => {
  const payload: ProductPerformanceDetails | undefined = props.payload?.[0]?.payload

  const { t } = useTranslation('dashboard')

  if (!payload) {
    return null
  }

  return (
    <StyledMainCard
      title={<DashboardProductLabel product={payload} />}
      style={{ width: '300px', opacity: '0.99' }}
    >
      <Row gutter={[8, 16]}>
        <Col span={12}>
          <Space direction="vertical">
            <Typography.Text strong>{t('performance.shared.orders')}</Typography.Text>
            <Typography.Text>
              {payload.totalShipped + payload.totalPending + payload.totalCanceled} (
              {payload.totalShipped} / {payload.totalPending} / {payload.totalCanceled})
            </Typography.Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Typography.Text strong>{t('performance.shared.revenue')}</Typography.Text>
            <Typography.Text>{formatMoney(payload.totalRevenue, 'EUR')}</Typography.Text>
          </Space>
        </Col>
      </Row>
    </StyledMainCard>
  )
}
