import { useFeatureFlag } from 'configcat-react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router'
import { StyledTabs } from '../../../components/Tabs'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { WholesaleOrdersContainer } from './WholesaleOrdersContainer'
import { WholesaleOrdersTemplatesList } from './WholesaleOrdersTemplates/WholesaleOrdersTemplateList'
import { Space, Tag } from 'antd'

const WholesaleOrdersTableTabs = {
  ORDERS: 'orders',
  TEMPLATES: 'templates',
} as const

export const WholesaleOrderTabs = () => {
  const user = useGlobalStore((state) => state.user)!

  const { t } = useTranslation('wholesale-order')
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const view = searchParams.get('view')

  const { value: featureFlag } = useFeatureFlag('wholesaleOrderTemplates', false, {
    identifier: user.cognitoUsername,
    email: user.email,
    custom: {},
  })

  if (!view || !Object.values(WholesaleOrdersTableTabs).includes(view)) {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('view', 'orders')
    return <Navigate to={`?${searchParams}`} replace />
  }

  return (
    <StyledTabs
      activeKey={view}
      onChange={(path) => navigate(`/wholesale/orders?view=${path}`)}
      items={[
        {
          label: t('wholesaleOrdersView.tabs.orders'),
          key: WholesaleOrdersTableTabs.ORDERS,
          children: <WholesaleOrdersContainer />,
        },
        ...(featureFlag
          ? [
              {
                label: (
                  <Space>
                    {t('wholesaleOrdersView.tabs.templates')}
                    <Tag color="yellow" style={{ margin: 0 }}>
                      Beta
                    </Tag>
                  </Space>
                ),
                key: WholesaleOrdersTableTabs.TEMPLATES,
                children: <WholesaleOrdersTemplatesList />,
              },
            ]
          : []),
      ]}
      tabBarGutter={0}
    />
  )
}
