import { DescriptionsProps } from 'antd'

export const descriptionsStyle: DescriptionsProps = {
  bordered: true,
  column: 1,
  labelStyle: {
    width: '50%',
    verticalAlign: 'top',
    lineHeight: '1.5rem',
  },
  contentStyle: {
    width: '50%',
    verticalAlign: 'top',
    lineHeight: '1.5rem',
  },
  size: 'small',
}
