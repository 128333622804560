import { useMutation } from '@apollo/client'
import { Button, Form, Modal, Tabs } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { CreateProductVariantDocument, GetProductDocument } from '../../../generated/graphql'
import {
  ProductVariantGeneralFormFields,
  ProductVariantPackageDimensionsFormFields,
} from './ProductCreateForm'

type CreateProductVariantModalProps = {
  productUuid: string
}

type CreateProductVariantFormInstance = {
  ean: string
  internalSku?: string
  packageLength?: number
  packageWidth?: number
  packageHeight?: number
  packageWeight?: number
}

export const CreateProductVariantModal = ({ productUuid }: CreateProductVariantModalProps) => {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { t } = useTranslation('inventory')
  const [form] = useForm<CreateProductVariantFormInstance>()

  const [createProductVariant] = useMutation(CreateProductVariantDocument)

  const handleFinish = async (values: CreateProductVariantFormInstance) => {
    try {
      setSubmitting(true)

      const productVariant = {
        ean: values.ean,
        internalSku: values.internalSku,
        packageLength: values.packageLength,
        packageWidth: values.packageWidth,
        packageHeight: values.packageHeight,
        packageWeight: values.packageWeight,
        attributes: [],
      }

      await createProductVariant({
        variables: {
          productUuid,
          input: {
            ean: productVariant.ean,
            internalSku: productVariant.internalSku,
            packageHeight: productVariant.packageHeight ?? null,
            packageLength: productVariant.packageLength ?? null,
            packageWeight: productVariant.packageWeight ?? null,
            packageWidth: productVariant.packageWidth ?? null,
          },
        },
        update: (cache, { data }) => {
          if (data?.createProductVariant) {
            const cachedProductQuery = cache.readQuery({
              query: GetProductDocument,
              variables: { uuid: productUuid },
            })

            if (cachedProductQuery && cachedProductQuery.product) {
              const existingProductVariants = cachedProductQuery.product.productVariants || []

              const updatedProductVariants = [...existingProductVariants, data.createProductVariant]

              cache.writeQuery({
                query: GetProductDocument,
                variables: { uuid: productUuid },
                data: {
                  product: {
                    ...cachedProductQuery.product,
                    productVariants: updatedProductVariants,
                  },
                },
              })
            }
          }
        },
      })

      toast.success(t('product.create.toast.success'))
      form.resetFields()
      setOpen(false)
    } catch (error) {
      console.error(error)
      toast.error(t('product.create.toast.error'))
    } finally {
      setTimeout(() => setSubmitting(false), 1000)
    }
  }

  return (
    <>
      <Button icon={<Plus size={16} />} onClick={() => setOpen(true)}>
        {t('product.details.productVariant.createProductVariantModal.button')}
      </Button>
      <Modal
        title={t('product.details.productVariant.createProductVariantModal.modal.title')}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => form.submit()}
        confirmLoading={submitting}
        okText={t('shared.button.submit', { ns: 'translation' })}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
      >
        <Form<CreateProductVariantFormInstance>
          name="product-form"
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          style={{ width: '100%' }}
        >
          <Tabs
            items={[
              {
                key: 'general',
                label: t('product.create.variant.general.title'),
                forceRender: true,
                children: <ProductVariantGeneralFormFields />,
              },
              {
                key: 'dimensions',
                label: t('product.create.variant.packageDimensions.title'),
                forceRender: true,
                children: <ProductVariantPackageDimensionsFormFields />,
              },
            ]}
          />
        </Form>
      </Modal>
    </>
  )
}
