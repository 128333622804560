export enum AmazonMarketplaceDomain {
  DE = 'Amazon.de',
  FR = 'Amazon.fr',
  ES = 'Amazon.es',
  IT = 'Amazon.it',
  NL = 'Amazon.nl',
  UK = 'Amazon.co.uk',
  SE = 'Amazon.se',
  PL = 'Amazon.pl',
  BE = 'Amazon.com.be',
  US = 'Amazon.com',
  CA = 'Amazon.ca',
  MX = 'Amazon.com.mx',
}
