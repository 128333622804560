import { useQuery } from '@apollo/client'
import {
  ColDef,
  ColGroupDef,
  GetContextMenuItems,
  GetRowIdParams,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { Flex, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import { Eye } from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components/FormattedDate'
import { StatusResult } from '../../../../components/Layout/StatusResult/StatusResult'
import { LinkButton } from '../../../../components/LinkButton'
import { MoreItemsIndicator } from '../../../../components/MoreItemsIndicator'
import { Table } from '../../../../components/Table/Table'
import {
  AmazonInboundShipmentPlansDocument,
  ShipmentPlanLifecycleStatus,
} from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { AmazonInboundShipmentPlan } from '../AmazonInboundShipmentPlansView'
import { getAmazonInboundShipmentPlanStatuses } from '../helpers/getAmazonInboundShipmentPlanStatuses'
import { getAmazonInboundShipmentStatusText } from '../helpers/getAmazonInboundShipmentStatusText'
import {
  prepareAmazonInboundShipmentPlanDetailedTableData,
  ShipmentPlanWithLabelData,
} from '../helpers/prepareAmazonInboundShipmentPlanDetailedTableData'
import { DownloadAmazonInboundShipmentPlanLabelModal } from './DownloadAmazonInboundShipmentPlanLabelModal'

type AmazonInboundShipmentPlanWithLabelTableProps = {
  lifecycleStatus: ShipmentPlanLifecycleStatus
}

export const AmazonInboundShipmentPlanDetailedTable = ({
  lifecycleStatus,
}: AmazonInboundShipmentPlanWithLabelTableProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const { t } = useTranslation(['inventory', 'translation'])

  const { error, data } = useQuery(AmazonInboundShipmentPlansDocument, {
    variables: {
      filter: {
        companyUuid: selectedCompany?.uuid,
        lifecycleStatus,
      },
    },
  })

  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const amazonInboundShipmentPlans = data?.amazonInboundShipmentPlans
  const statuses = getAmazonInboundShipmentPlanStatuses(lifecycleStatus)

  const dataSource = amazonInboundShipmentPlans
    ? lifecycleStatus === ShipmentPlanLifecycleStatus.CREATED
      ? prepareAmazonInboundShipmentPlanDetailedTableData(amazonInboundShipmentPlans)
      : prepareAmazonInboundShipmentPlanDetailedTableData(
          amazonInboundShipmentPlans.map((amazonInboundShipmentPlan) => ({
            ...amazonInboundShipmentPlan,
            shipments:
              amazonInboundShipmentPlan.inboundShipments?.filter((inboundShipment) =>
                statuses.includes(inboundShipment.shipmentStatus)
              ) ?? [],
          }))
        )
    : undefined

  const [columnDefs] = useState<
    (ColDef<ShipmentPlanWithLabelData> | ColGroupDef<ShipmentPlanWithLabelData>)[]
  >([
    {
      lockPosition: 'left',
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      minWidth: 96,
      maxWidth: 96,
      cellRenderer: (params: ICellRendererParams<ShipmentPlanWithLabelData>) => (
        <Flex gap={8} align="center">
          <Tooltip title={t('amazonInboundShipmentPlan.tooltip.details')}>
            <LinkButton
              icon={<Eye size={16} />}
              to={`/amazon/shipment-plans/${params.data?.uuid}`}
            />
          </Tooltip>
          <DownloadAmazonInboundShipmentPlanLabelModal
            inboundShipments={[{ shipmentId: params.data?.amazonInboundShipmentId }]}
            pageType={params.data?.pageType}
          />
        </Flex>
      ),
    },
    {
      field: 'amazonInboundShipmentId',
      headerName: t('amazonInboundShipmentPlan.table.amazonInboundShipmentId.title'),
    },
    {
      field: 'internalSkus',
      headerName: t('amazonInboundShipmentPlan.table.internalSkus'),
      cellRenderer: (params: ICellRendererParams<ShipmentPlanWithLabelData, string[] | null>) => {
        const internalSkus = params.value

        if (!internalSkus) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const totalQuantity = internalSkus.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{internalSkus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={internalSkus} />}
          </Flex>
        )
      },
    },
    {
      field: 'skus',
      headerName: 'SKUs',
      cellRenderer: (params: ICellRendererParams<ShipmentPlanWithLabelData, string[] | null>) => {
        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        const skus = params.value
        const totalQuantity = skus.length

        if (totalQuantity === 0) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Flex gap={8} align="center" style={{ height: '100%' }}>
            <Typography.Text>{skus[0]}</Typography.Text>
            {totalQuantity > 1 && <MoreItemsIndicator items={skus} />}
          </Flex>
        )
      },
    },
    {
      headerName: t('amazonInboundShipmentPlan.table.marketplace'),
      valueGetter: (params: ValueGetterParams<ShipmentPlanWithLabelData>) => {
        if (!params.data) {
          return null
        }

        return `Amazon.${getMarketplaceDomain(params.data.marketplace)}`
      },
    },
    {
      field: 'totalReceived',
      headerName: 'Quantity shipped',
      filter: 'agNumberColumnFilter',
      hide: lifecycleStatus !== ShipmentPlanLifecycleStatus.FINISHED,
    },
    {
      field: 'totalShipped',
      headerName: 'Quantity received',
      filter: 'agNumberColumnFilter',
      hide: lifecycleStatus !== ShipmentPlanLifecycleStatus.FINISHED,
    },
    {
      field: 'deliveryCost',
      headerName: t('amazonInboundShipmentPlan.table.deliveryCost'),
      filter: 'agNumberColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<
          ShipmentPlanWithLabelData,
          ShipmentPlanWithLabelData['deliveryCost']
        >
      ) => {
        if (params.value === null || params.value === undefined) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return <Typography.Text>{formatMoney(params.value, 'EUR')}</Typography.Text>
      },
    },
    {
      headerName: t('amazonInboundShipmentPlan.table.status'),
      valueGetter: (params: ValueGetterParams<ShipmentPlanWithLabelData>) => {
        if (!params.data) {
          return null
        }

        return getAmazonInboundShipmentStatusText(params.data.status, t)
      },
    },
    {
      field: 'shipmentKind',
      headerName: t('amazonInboundShipmentPlan.table.shipmentKind'),
      valueGetter: (params: ValueGetterParams<ShipmentPlanWithLabelData>) => {
        return params.data?.areCasesRequired ? 'CASE' : 'MIXED'
      },
    },
    {
      field: 'note',
      headerName: t('amazonInboundShipmentPlan.table.note.value'),
      filter: 'agTextColumnFilter',
      cellRenderer: (
        params: ICellRendererParams<AmazonInboundShipmentPlan, AmazonInboundShipmentPlan['note']>
      ) => {
        if (!params.value) {
          return <Typography.Text type="secondary">-</Typography.Text>
        }

        return (
          <Tooltip title={params.value}>
            <Typography.Text ellipsis>{params.value}</Typography.Text>
          </Tooltip>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: t('amazonInboundShipmentPlan.table.creationDate'),
      filter: false,
      initialSort: 'desc',
      cellRenderer: (
        params: ICellRendererParams<
          ShipmentPlanWithLabelData,
          ShipmentPlanWithLabelData['createdAt']
        >
      ) => {
        return <FormattedDate date={params.value} withRelativeTime layout="horizontal" />
      },
    },
    {
      field: 'updatedAt',
      headerName: t('amazonInboundShipmentPlan.table.lastUpdate'),
      filter: false,
      cellRenderer: (
        params: ICellRendererParams<
          ShipmentPlanWithLabelData,
          ShipmentPlanWithLabelData['updatedAt']
        >
      ) => {
        return <FormattedDate date={params.value} withRelativeTime layout="horizontal" />
      },
    },
  ])

  const columnKeys =
    lifecycleStatus === ShipmentPlanLifecycleStatus.FINISHED
      ? [
          'amazonInboundShipmentId',
          'noOfSkus',
          'status',
          'marketplace',
          'totalShipped',
          'totalReceived',
          'deliveryCost',
          'shipmentKind',
          'createdAt',
          'updatedAt',
        ]
      : [
          'amazonInboundShipmentId',
          'noOfSkus',
          'marketplace',
          'deliveryCost',
          'status',
          'shipmentKind',
          'createdAt',
          'updatedAt',
        ]

  const getContextMenuItems: GetContextMenuItems<ShipmentPlanWithLabelData> = useCallback(
    (params) => [
      {
        name: t('amazonInboundShipmentPlan.tooltip.details'),
        action: () => window.open(`/amazon/shipment-plans/${params.node?.data?.uuid}`),
      },
      'separator',
      'export',
    ],
    []
  )

  const getRowId = useMemo(
    () => (params: GetRowIdParams<ShipmentPlanWithLabelData>) =>
      `${params.data.uuid}_${params.data.amazonInboundShipmentId}`,
    []
  )

  return (
    <Table<ShipmentPlanWithLabelData>
      columnDefs={columnDefs}
      context={{ dataSource }}
      defaultExcelExportParams={{
        fileName: `${lifecycleStatus.toLowerCase()}-amazon-shipment-plans-${dayjs().format(
          'YYYY-MM-DD'
        )}`,
        columnKeys,
      }}
      getContextMenuItems={getContextMenuItems}
      getRowId={getRowId}
      rowData={dataSource}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
      storeSearchInSearchParams
    />
  )
}
