import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Flex } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components/FormattedDate'
import { Table } from '../../../../components/Table/Table'
import {
  AmazonReturnDisposition,
  AmazonReturnReason,
  Marketplace,
} from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { TablePersistKey } from '../../../../stores/useTableStore'

export type AmazonReturnTableRecord = {
  name: string
  asin: string
  sku: string
  internalSku: string | null
  marketplace: Marketplace
  amazonOrderId: string
  quantity: number
  reason: AmazonReturnReason
  comment: string | null
  disposition: AmazonReturnDisposition
  returnDate: string
  searchIdentifiers: string
}

type AmazonReturnTableProps = {
  amazonReturns: AmazonReturnTableRecord[]
  gridRef: React.MutableRefObject<AgGridReact<AmazonReturnTableRecord> | null>
}

export const AmazonReturnTable = ({ amazonReturns, gridRef }: AmazonReturnTableProps) => {
  const { t } = useTranslation(['inventory', 'translation'])

  const [columnDefs] = useState<
    (ColDef<AmazonReturnTableRecord> | ColGroupDef<AmazonReturnTableRecord>)[]
  >([
    {
      field: 'name',
      headerName: t('returns.table.column.name'),
      getQuickFilterText: (params) => {
        return params.data?.searchIdentifiers
      },
    },
    {
      field: 'asin',
      headerName: t('returns.table.column.asin'),
    },
    {
      field: 'sku',
      headerName: t('returns.table.column.sku'),
    },
    {
      field: 'internalSku',
      headerName: t('returns.table.column.internalSku'),
    },
    {
      headerName: t('returns.table.column.marketplace'),
      valueGetter: (params) => {
        return params.data ? `Amazon.${getMarketplaceDomain(params.data.marketplace)}` : null
      },
    },
    {
      field: 'amazonOrderId',
      headerName: t('returns.table.column.amazonOrderId'),
      filter: false,
      hide: true,
    },
    {
      field: 'quantity',
      headerName: t('returns.table.column.quantity'),
      filter: false,
    },
    {
      headerName: t('returns.table.column.reason'),
      valueGetter: (params) => {
        return params.data ? t(`returns.reason.${params.data.reason}`) : null
      },
    },
    {
      field: 'comment',
      headerName: t('returns.table.column.comment'),
      filter: false,
    },
    {
      headerName: t('returns.table.column.disposition'),
      valueGetter: (params) => {
        return params.data ? t(`returns.disposition.${params.data.disposition}`) : null
      },
    },
    {
      field: 'returnDate',
      headerName: t('returns.table.column.returnDate'),
      filter: 'agDateColumnFilter',
      initialSort: 'desc',
      filterValueGetter: (params) => {
        if (params.data) {
          return dayjs(params.data.returnDate).startOf('day').toDate()
        }

        return null
      },
      cellRenderer: (params: ICellRendererParams<AmazonReturnTableRecord>) => {
        return (
          <Flex align="center" justify="center">
            <FormattedDate date={params.value} />
          </Flex>
        )
      },
    },
  ])

  return (
    <Table<AmazonReturnTableRecord>
      columnDefs={columnDefs}
      persist={{
        key: TablePersistKey.AMAZON_RETURN,
        storage: localStorage,
      }}
      ref={gridRef}
      rowData={amazonReturns}
      showUniversalSearch
      showColumnChooser
      showExport
      showFilterReset
      suppressContextMenu
    />
  )
}
