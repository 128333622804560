import { useLazyQuery } from '@apollo/client'
import { Button, Form, Radio, Row, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import mixpanel from 'mixpanel-browser'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import {
  GetInventoryHealthReportDocument,
  Marketplace,
  ProductStatus,
} from '../../../generated/graphql'
import { exportCsvFile, exportXlsxFile } from '../../../helpers/exportFile'
import { getMarketplaceDomain } from '../../../helpers/getMarketplaceDomain'
import { useMarketplaceOptions } from '../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { getTranslatedKeys } from '../helpers'
import { FileFormat } from '../interfaces'
import { prepareInventoryHealthReportData } from './prepareInventoryHealthTableData'

type InventoryHealthReportInput = {
  marketplaces: Marketplace[]
  fileFormat: string
}

export const InventoryHealthReportView = () => {
  const [loading, setLoading] = useState(false)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const [form] = useForm<InventoryHealthReportInput>()
  const marketplaceOptions = useMarketplaceOptions()

  const { t } = useTranslation(['report', 'translation'])

  const [getInventoryHealth] = useLazyQuery(GetInventoryHealthReportDocument)

  const downloadReport = async (values: InventoryHealthReportInput) => {
    mixpanel.track('Downloaded report', {
      type: 'inventory-health-report',
    })
    setLoading(true)

    try {
      if (!selectedCompany?.uuid) {
        throw new Error(t('shared.error.message', { ns: 'translation' }))
      }

      const { data } = await getInventoryHealth({
        errorPolicy: 'none',
        fetchPolicy: 'cache-first',
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          companyUuid: selectedCompany?.uuid!,
          marketplaces: values.marketplaces,
          status: ProductStatus.ACTIVE,
        },
      })

      if (!data) {
        throw new Error(t('shared.error.message', { ns: 'translation' }))
      }

      const inventoryHealth = prepareInventoryHealthReportData(data)

      const translatedOrders = getTranslatedKeys(inventoryHealth, 'inventoryHealth', t)

      if (!translatedOrders.length) {
        toast.error(t('shared.noData', { ns: 'translation' }))
      }

      const cleanedReports = translatedOrders.map((obj) => {
        const newObj = { ...obj }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        delete newObj.rowIndex

        return newObj
      })

      const fileName = t('inventoryHealth.form.fileName')

      if (values.fileFormat === FileFormat.CSV) {
        exportCsvFile(cleanedReports, fileName)
      } else {
        exportXlsxFile(cleanedReports, fileName)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <SubHeader heading={t('inventoryHealth.heading')} withBackButton />
      <Suspense>
        <ViewContainer centered>
          <Form<InventoryHealthReportInput>
            name="inventory-health-report"
            form={form}
            layout="vertical"
            onFinish={downloadReport}
            style={{ width: '100%', maxWidth: '640px' }}
            initialValues={{
              marketplaces: [Marketplace.DE],
              fileFormat: FileFormat.CSV,
            }}
          >
            <Form.Item
              label={t('inventoryHealth.form.marketplaces')}
              name="marketplaces"
              rules={[
                {
                  required: true,
                  message: t('shared.form.error.required', {
                    ns: 'translation',
                  }),
                },
              ]}
            >
              <Select<Marketplace[]>
                mode="multiple"
                options={marketplaceOptions.map((marketplace) => ({
                  value: marketplace,
                  label: `Amazon.${getMarketplaceDomain(marketplace)}`,
                }))}
                placeholder={t('shared.form.placeholder.select', {
                  ns: 'translation',
                })}
                allowClear
                showSearch
                filterOption={true}
                optionFilterProp="label"
              />
            </Form.Item>

            <Form.Item
              name="fileFormat"
              label={t('inventoryHealth.form.fileFormat')}
              rules={[
                {
                  required: true,
                  message: t('shared.form.error.required', {
                    ns: 'translation',
                  }),
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={FileFormat.XLSX}>Excel</Radio>
                  <Radio value={FileFormat.CSV}>CSV</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Row justify="end">
              <Button type="primary" htmlType="submit" loading={loading}>
                {t('inventoryHealth.form.download')}
              </Button>
            </Row>
          </Form>
        </ViewContainer>
      </Suspense>
    </>
  )
}
