import { Layout, Space } from 'antd'
import { Suspense } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router'
import { NotificationBell } from '../components/Layout/NotificationBell/NotificationBell'
import { SidebarMenu } from '../components/Layout/Sidebar/SidebarMenu'
import { SidebarToggle } from '../components/Layout/SidebarToggle/SidebarToggle'
import { UserMenu } from '../components/User/UserMenu'
import { useAuthentication } from '../hooks/useAuthentication'
import { useBreakpoints } from '../hooks/useBreakpoints'
import { useLayoutStore } from '../stores/useLayoutStore'
import { StyledContent, StyledHeader, StyledSider } from './ProtectedLayout.style'

export const ProtectedLayout = () => {
  const sidebarCollapsed = useLayoutStore((state) => state.sidebarCollapsed)

  const location = useLocation()
  const breakpoints = useBreakpoints()

  const collapsedWidth = breakpoints.md ? 80 : 0

  const { loading, user } = useAuthentication()

  if (loading) {
    return null
  }

  if (!user) {
    return <Navigate to="login" state={{ from: location }} replace />
  }

  return (
    <Layout style={{ flexDirection: 'row', justifyContent: 'center', height: '100vh' }}>
      <StyledSider
        collapsible
        collapsed={sidebarCollapsed}
        collapsedWidth={collapsedWidth}
        trigger={null}
        width={240}
      >
        <SidebarMenu />
      </StyledSider>
      <Layout style={{ maxWidth: '2280px' }}>
        <StyledHeader>
          <SidebarToggle />
          <Space size="middle">
            <NotificationBell />
            <UserMenu />
          </Space>
        </StyledHeader>
        <StyledContent>
          <Suspense>
            <Outlet />
          </Suspense>
        </StyledContent>
      </Layout>
    </Layout>
  )
}
