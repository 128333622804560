import { Select } from 'antd'
import { SunMoon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { AppTheme, useAppThemeStore } from '../../stores/useAppThemeStore'
import { AppThemeSelectionContainer } from './AppThemeSelection.style'

const themes = [
  { key: AppTheme.LIGHT, displayName: 'Light' },
  { key: AppTheme.DARK, displayName: 'Dark' },
]

export const AppThemeSelection = () => {
  const appTheme = useAppThemeStore((state) => state.appTheme)
  const changeAppTheme = useAppThemeStore((state) => state.changeAppTheme)
  const { t } = useTranslation()

  const handleOnChange = (value: AppTheme) => {
    changeAppTheme(value)
  }

  return (
    <AppThemeSelectionContainer>
      <SunMoon size={16} />
      <Select<AppTheme>
        value={appTheme}
        onChange={handleOnChange}
        placeholder={t('shared.form.placeholder.select')}
        placement="bottomRight"
        variant={'borderless'}
        size="small"
        options={[
          ...themes.map((theme) => ({
            value: theme.key,
            label: theme.displayName,
          })),
        ]}
        style={{ width: '100%' }}
      />
    </AppThemeSelectionContainer>
  )
}
