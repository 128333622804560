import { useTheme } from '@emotion/react'
import { Flex, Statistic, Typography } from 'antd'
import { ArrowDown, ArrowUp } from 'lucide-react'
import { formatMoney } from '../../../../helpers/formatMoney'
import { formatPercentage } from '../../../../helpers/formatPercentage'

type DashboardFinancePerformanceCardSectionProps = {
  title: string
  value: number
  precision: number
  delta: number
}

export const DashboardFinancePerformanceCardSection = ({
  title,
  value,
  precision,
  delta,
}: DashboardFinancePerformanceCardSectionProps) => {
  const theme = useTheme()

  const color = delta > 0 ? theme.colors.success : delta < 0 ? theme.colors.error : undefined
  const arrow = delta > 0 ? <ArrowUp size={16} /> : delta < 0 ? <ArrowDown size={16} /> : null

  return (
    <Flex justify="space-between" align="end">
      <Statistic
        title={title}
        value={precision ? formatMoney(value, 'EUR') : value}
        precision={precision}
        valueStyle={{ fontSize: '1rem' }}
      />
      {delta !== Infinity && (
        <Typography.Text ellipsis style={{ fontSize: '1rem', color }}>
          {arrow} {formatPercentage(delta)}
        </Typography.Text>
      )}
    </Flex>
  )
}
