import { Descriptions, Flex, Tag } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../../components/FormattedDate'
import { ActionType, S3BucketActionButton } from '../../../../../components/S3BucketActionButton'
import { WholesaleOrderBillingDocumentType } from '../../../../../generated/graphql'
import { StyledMainCard } from '../../../../dashboard/styles'
import { descriptionsStyle } from '../../../../products/components/styles'
import { WholesaleOrderBillingDocumentUploadModal } from '../WholesaleOrderBillingDocumentUploadModal'
import { WholesaleOrderBillingDocument } from './WholesaleOrderBillingDocumentDetailsView'

type WholesaleOrderBillingDocumentGeneralDetailsCardProps = {
  wholesaleOrderBillingDocument: WholesaleOrderBillingDocument
}

export const WholesaleOrderBillingDocumentGeneralDetailsCard = ({
  wholesaleOrderBillingDocument,
}: WholesaleOrderBillingDocumentGeneralDetailsCardProps) => {
  const { t } = useTranslation('wholesale-order-billing-document', {
    keyPrefix: 'wholesaleOrderBillingDocumentGeneralDetailsCard',
  })

  return (
    <StyledMainCard title={t('heading')}>
      <Descriptions {...descriptionsStyle}>
        <Descriptions.Item label={t('documentNumber.label')}>
          {wholesaleOrderBillingDocument.documentNumber}
        </Descriptions.Item>
        <Descriptions.Item label={t('documentType.label')}>
          <Tag>
            {t(
              `documentType.${
                wholesaleOrderBillingDocument.type === WholesaleOrderBillingDocumentType.INVOICE
                  ? 'invoice'
                  : 'creditNote'
              }`
            )}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('bucketKey.label')}>
          {wholesaleOrderBillingDocument.bucketKey ? (
            <S3BucketActionButton
              actionType={ActionType.VIEW}
              bucketName={import.meta.env.VITE_AWS_S3_BUCKET_NAME_WHOLESALE_ORDERS}
              objectKey={`${wholesaleOrderBillingDocument.bucketKey}`}
              type="primary"
              size="small"
            >
              {t('bucketKey.button.view')}
            </S3BucketActionButton>
          ) : wholesaleOrderBillingDocument.proFormaBucketKey ? (
            <Flex gap={8} justify="space-between">
              <S3BucketActionButton
                actionType={ActionType.VIEW}
                bucketName={import.meta.env.VITE_AWS_S3_BUCKET_NAME_WHOLESALE_ORDERS}
                objectKey={`${wholesaleOrderBillingDocument.proFormaBucketKey}`}
                type="primary"
                size="small"
              >
                {t('bucketKey.button.view')}
              </S3BucketActionButton>
              <WholesaleOrderBillingDocumentUploadModal
                wholesaleOrderBillingDocument={{
                  identifier: wholesaleOrderBillingDocument.identifier,
                  documentNumber: wholesaleOrderBillingDocument.documentNumber,
                  issueDate: dayjs(wholesaleOrderBillingDocument.issueDate),
                  dueDate: dayjs(wholesaleOrderBillingDocument.dueDate),
                }}
              />
            </Flex>
          ) : (
            <WholesaleOrderBillingDocumentUploadModal
              wholesaleOrderBillingDocument={{
                identifier: wholesaleOrderBillingDocument.identifier,
                documentNumber: wholesaleOrderBillingDocument.documentNumber,
                issueDate: dayjs(wholesaleOrderBillingDocument.issueDate),
                dueDate: dayjs(wholesaleOrderBillingDocument.dueDate),
              }}
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t('issueDate.label')}>
          <FormattedDate date={wholesaleOrderBillingDocument.issueDate} format="DD.MM.YYYY" />
        </Descriptions.Item>
        <Descriptions.Item label={t('dueDate.label')}>
          <FormattedDate date={wholesaleOrderBillingDocument.dueDate} format="DD.MM.YYYY" />
        </Descriptions.Item>
      </Descriptions>
    </StyledMainCard>
  )
}
