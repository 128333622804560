import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import { Suspense, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { formatMoney } from '../../../../helpers/formatMoney'
import { PaymentsQueryAmazonInboundShipment } from '../PaymentsView'
import { preparePaymentsShipmentPlanFeeRecords } from '../helpers'

type PaymentsShipmentPlanFeeTableRecord = {
  shipmentName: string
  shipmentId: string
  date: string
  fee: {
    amount: number
    currency: string
  }
}

type PaymentsShipmentPlanFeeTableProps = {
  shipmentPlanFees: PaymentsQueryAmazonInboundShipment[]
}

export const PaymentsShipmentPlanFeeTable = ({
  shipmentPlanFees: shipmentPlanFees,
}: PaymentsShipmentPlanFeeTableProps) => {
  const rowData = preparePaymentsShipmentPlanFeeRecords(shipmentPlanFees)

  const { t } = useTranslation(['billing'])

  const [columnDefs] = useState<
    (ColDef<PaymentsShipmentPlanFeeTableRecord> | ColGroupDef<PaymentsShipmentPlanFeeTableRecord>)[]
  >([
    {
      field: 'shipmentName',
      headerName: t('payments.details.shipmentPlanFees.table.shipmentName'),
    },
    {
      field: 'shipmentId',
      headerName: t('payments.details.shipmentPlanFees.table.shipmentId'),
    },
    {
      field: 'date',
      headerName: t('payments.details.shipmentPlanFees.table.date'),
      initialSort: 'desc',
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentPlanFeeTableRecord,
          PaymentsShipmentPlanFeeTableRecord['date']
        >
      ) => {
        return dayjs(params.value).format('DD.MM.YYYY HH:mm:ss')
      },
    },
    {
      field: 'fee.amount',
      headerName: t('payments.details.shipmentPlanFees.table.fee'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsShipmentPlanFeeTableRecord,
          PaymentsShipmentPlanFeeTableRecord['fee']
        >
      ) => {
        return params.data
          ? formatMoney(params.data.fee.amount, params.data.fee.currency)
          : t('shared.notAvailable', { ns: 'translation' })
      },
    },
  ])

  const defaultColDef: ColDef<PaymentsShipmentPlanFeeTableRecord> = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<PaymentsShipmentPlanFeeTableRecord>) => {
        if (params.value === null || params.value === undefined || params.value === '') {
          return t('shared.notAvailable', { ns: 'translation' })
        } else {
          return params.value
        }
      },
    }),
    []
  )

  return (
    <Suspense>
      {!rowData?.length ? (
        <Typography.Text type="secondary">
          {t('payments.details.shipmentPlanFees.empty')}
        </Typography.Text>
      ) : (
        <Table<PaymentsShipmentPlanFeeTableRecord>
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          suppressContextMenu
        />
      )}
    </Suspense>
  )
}
