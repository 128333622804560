import { Plus } from 'lucide-react'
import { Button, Form, FormInstance, Input, Row, Select, Table } from 'antd'
import { X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import { SponsoredProductsNegativeKeywordMatchType } from '../../../../../generated/graphql'
import {
  FormWidget,
  ListTitle,
  ScrollableList,
  SelectContainer,
  SelectedItemsContainer,
} from '../../../styles'

const { TextArea } = Input

type CreateSponsoredProductsNegativeKeywordsWidgetProps = {
  form: FormInstance
}

type NegativeKeywordData = {
  keywordText: string
  matchType: SponsoredProductsNegativeKeywordMatchType
}

const CreateSponsoredProductsNegativeKeywordsWidget = ({
  form,
}: CreateSponsoredProductsNegativeKeywordsWidgetProps) => {
  const matchTypeOptions = Object.values(SponsoredProductsNegativeKeywordMatchType)
  const defaultMatchType = SponsoredProductsNegativeKeywordMatchType.NEGATIVEEXACT
  const [textareaValue, setTextareaValue] = useState<string>('')
  const [keywordInput, setKeywordInput] = useState<string[]>([])
  const [matchType, setMatchType] =
    useState<SponsoredProductsNegativeKeywordMatchType>(defaultMatchType)
  const [negativeKeywords, setNegativeKeywords] = useState<NegativeKeywordData[]>([])

  useEffect(() => {
    form.setFieldsValue({ negativeKeywords: negativeKeywords })
  }, [negativeKeywords, form])

  const handleRemoveNegativeKeyword = (index: number) => {
    const negativeKeywordsCopy = [...negativeKeywords]
    negativeKeywordsCopy.splice(index, 1)
    setNegativeKeywords(negativeKeywordsCopy)
  }

  const columns = [
    {
      title: 'Text',
      dataIndex: 'keywordText',
      key: 'keywordText',
    },
    {
      title: 'Match Type',
      dataIndex: 'matchType',
      key: 'matchType',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '50px',
      render: (value: any, record: any, index: number) => (
        <Button
          type="text"
          icon={<X size={16} />}
          onClick={() => handleRemoveNegativeKeyword(index)}
          data-testid="remove-negative-keyword-btn"
        />
      ),
    },
  ]

  const handleAddKeywords = () => {
    const newKeywords: NegativeKeywordData[] = []
    keywordInput.forEach((keywordInput: string) => {
      const newKeyword: NegativeKeywordData = {
        keywordText: keywordInput,
        matchType: matchType,
      }

      let keywordExists = false

      negativeKeywords.forEach((existingKeyword: NegativeKeywordData) => {
        if (
          existingKeyword.keywordText === newKeyword.keywordText &&
          existingKeyword.matchType === newKeyword.matchType
        ) {
          keywordExists = true
          return
        }
      })

      newKeywords.forEach((existingNewKeyword: NegativeKeywordData) => {
        if (
          existingNewKeyword.keywordText === newKeyword.keywordText &&
          existingNewKeyword.matchType === newKeyword.matchType
        ) {
          keywordExists = true
          return
        }
      })

      if (!keywordExists) {
        newKeywords.push(newKeyword)
      } else {
        toast.error(`Duplicate keyword cannot be added`, {
          description: `Keyword text: ${newKeyword.keywordText}, match type: ${newKeyword.matchType}`,
        })
      }
    })

    setNegativeKeywords([...negativeKeywords, ...newKeywords])
    setTextareaValue('')
    setKeywordInput([])
  }

  const handleTextareaOnChange = (e: any) => {
    const userInput = e.target.value
    setTextareaValue(userInput)
    if (userInput) {
      // split on new line and remove any empty lines
      const keywords = userInput.split('\n').filter((keyword: string) => keyword?.trim())
      setKeywordInput(keywords)
    } else {
      setKeywordInput([])
    }
  }

  const handleMatchTypeChanged = (value: SponsoredProductsNegativeKeywordMatchType) => {
    setMatchType(value)
  }

  return (
    <>
      <Form.Item name={'negativeKeywords'} hidden>
        <Input></Input>
      </Form.Item>
      <FormWidget>
        <SelectContainer>
          <Row style={{ padding: '6px 12px' }}>
            <Select
              defaultValue={defaultMatchType}
              style={{ width: '100%' }}
              onChange={handleMatchTypeChanged}
            >
              {matchTypeOptions.map((matchTypeOption) => {
                return (
                  <Select.Option
                    value={matchTypeOption}
                    label={matchTypeOption}
                    key={matchTypeOption}
                  >
                    {matchTypeOption}
                  </Select.Option>
                )
              })}
            </Select>
          </Row>
          <Row style={{ padding: '6px 12px' }}>
            <TextArea
              rows={15}
              placeholder="Enter keywords seperated by new line"
              onChange={handleTextareaOnChange}
              value={textareaValue}
              data-testid="negative-keyword-textarea"
            />
          </Row>
          <Row style={{ padding: '6px 12px' }} justify="end">
            <Button
              type="primary"
              onClick={handleAddKeywords}
              icon={<Plus size={16} />}
              disabled={!keywordInput.length}
              data-testid="add-negative-keywords-btn"
            >
              Add Keywords
            </Button>
          </Row>
        </SelectContainer>
        <SelectedItemsContainer>
          <ListTitle>Negative keywords ({negativeKeywords.length})</ListTitle>
          <ScrollableList>
            <Table
              dataSource={negativeKeywords}
              columns={columns}
              pagination={false}
              bordered={true}
              rowKey={(row) => row.keywordText + '-' + row.matchType}
              size="small"
            />
          </ScrollableList>
        </SelectedItemsContainer>
      </FormWidget>
    </>
  )
}

export default CreateSponsoredProductsNegativeKeywordsWidget
