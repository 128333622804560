import dayjs from 'dayjs'
import { GetAllAmazonReturnsQuery } from '../../../../generated/graphql'
import { getSearchIdentifiers } from '../../../../helpers/getSearchIdentifiers'
import { AmazonReturnTableRecord } from '../components/AmazonReturnTable'

export function prepareAmazonReturnData(
  data: GetAllAmazonReturnsQuery | undefined
): AmazonReturnTableRecord[] {
  if (!data) {
    return []
  }

  const amazonReturns = data.products.flatMap((product) => {
    const amazonProducts =
      product.productVariants?.flatMap((productVariant) => {
        return productVariant.amazonProducts ?? []
      }) ?? []

    return amazonProducts?.filter(Boolean).flatMap((amazonProduct) => {
      if (!amazonProduct.amazonListings) {
        return []
      }

      return amazonProduct.amazonListings?.filter(Boolean).flatMap((amazonListing) => {
        const productVariant = product.productVariants?.find((pv) =>
          pv.amazonProducts?.some((ap) => ap.uuid === amazonProduct.uuid)
        )

        return amazonListing.returns.flatMap((amazonReturn) => {
          return {
            name: product.name,
            asin: amazonProduct.asin,
            internalSku: productVariant?.internalSku || null,
            sku: amazonListing.sku,
            marketplace: amazonListing.marketplace.name,
            amazonOrderId: amazonReturn.amazonOrderLineItem.amazonOrder.amazonOrderId,
            quantity: amazonReturn.quantity,
            reason: amazonReturn.reason,
            comment: amazonReturn.customerComment ?? null,
            disposition: amazonReturn.disposition,
            returnDate: amazonReturn.returnDate,
            searchIdentifiers: getSearchIdentifiers({ productVariant }),
          }
        })
      })
    })
  })

  return amazonReturns.sort((a, b) => dayjs(b.returnDate).diff(dayjs(a.returnDate)))
}
