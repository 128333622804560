import { Select } from 'antd'
import { Globe } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import i18n from '../../config/i18n'
import { LanguageSelectionContainer } from './LanguageSelection.style'

const languages = [
  { key: 'en', displayName: 'English' },
  { key: 'de', displayName: 'Deutsch' },
]

export const LanguageSelection = () => {
  const { t } = useTranslation()

  const handleOnChange = (value: string) => {
    i18n.changeLanguage(value)
  }

  return (
    <LanguageSelectionContainer>
      <Globe size={16} />
      <Select
        value={i18n.language}
        onChange={handleOnChange}
        placeholder={t('shared.form.placeholder.select')}
        variant={'borderless'}
        placement="bottomRight"
        size="small"
        options={[
          ...languages.map((language) => ({
            value: language.key,
            label: language.displayName,
          })),
        ]}
        style={{ width: '100%' }}
      />
    </LanguageSelectionContainer>
  )
}
