import { GridState } from 'ag-grid-community'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type TableStoreProps = TablePersistConfig | undefined

export type TablePersistConfig = {
  key: (typeof TablePersistKey)[keyof typeof TablePersistKey]
  storage: Storage
}

export const TablePersistKey = {
  AMAZON_ORDER: 'amazon-order',
  AMAZON_PRODUCT: 'amazon-product',
  AMAZON_RETURN: 'amazon-return',
  BANK_TRANSACTION: 'bank-transaction',
  INVOICE_ARTICLE: 'invoice-article',
  INVOICE_ARTICLE_CATEGORY: 'invoice-article-category',
  INVOICE_ARTICLE_SUBSCRIPTION: 'invoice-article-subscription',
  INVOICE_LINE_ITEMS: 'invoice-line-items',
  PRODUCT: 'product',
  PRODUCT_COMPLIANCE: 'product-compliance',
  PRODUCT_VARIANT_PACKAGING_UNITS: 'product-variant-packaging-units',
  SERVICE_INVOICE: 'service-invoice',
  STOCK: 'stock',
  SUPPORT_TICKET: 'support-ticket',
  WHOLESALE_ORDER: 'wholesale-order',
  WHOLESALE_ORDER_BILLING_DOCUMENT: 'wholesale-order-billing-document',
  WHOLESALE_ORDER_BILLING_DOCUMENT_CREATION: 'wholesale-order-billing-document-creation',
  WHOLESALE_ORDER_BILLING_DOCUMENT_DETAILS: 'wholesale-order-billing-document-details',
  WHOLESALE_ORDER_BILLING_DOCUMENT_SELECT: 'wholesale-order-billing-document-select',
  WHOLESALE_ORDER_FORM: 'wholesale-order-form',
  WHOLESALE_ORDER_LINE_ITEM: 'wholesale-order-line-item',
  WHOLESALE_ORDER_SHIPMENT: 'wholesale-order-shipment',
  WHOLESALE_ORDER_SHIPMENT_CREATION: 'wholesale-order-shipment-creation',
  WHOLESALE_ORDER_SHIPMENT_DETAILS: 'wholesale-order-shipment-details',
} as const

type TableState = {
  gridState?: GridState
  quickFilterText: string
}

type TableActions = {
  setGridState: (gridState?: GridState) => void
  setQuickFilterText: (quickFilterText: string) => void
}

const initialState: TableState = {
  quickFilterText: '',
}

export const useTableStore = (props: TableStoreProps) => {
  return props
    ? create<TableState & TableActions>()(
        persist(
          (set) => ({
            ...initialState,
            setGridState: (gridState) => set({ gridState }),
            setQuickFilterText: (quickFilterText) => set({ quickFilterText }),
          }),
          {
            name: `galaxy-${props.key}-table`,
            storage: createJSONStorage(() => props.storage),
          }
        )
      )
    : create<TableState & TableActions>((set) => ({
        ...initialState,
        setGridState: (gridState) => set({ gridState }),
        setQuickFilterText: (quickFilterText) => set({ quickFilterText }),
      }))
}
