import dayjs from 'dayjs'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type PPCState = {
  ppcStartDate: string
  setPpcStartDate: (ppcStartDate: string) => void
  ppcEndDate: string
  setPpcEndDate: (ppcEndDate: string) => void
  marketplace: string
  setMarketplace: (marketplace: string) => void
}

const ppcStateStore = create(
  persist<PPCState>(
    (set) => ({
      ppcStartDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      setPpcStartDate: (ppcStartDate: string) => set(() => ({ ppcStartDate })),
      ppcEndDate: dayjs().format('YYYY-MM-DD'),
      setPpcEndDate: (ppcEndDate: string) => set(() => ({ ppcEndDate })),
      marketplace: 'de',
      setMarketplace: (marketplace: string) => set(() => ({ marketplace })),
    }),
    { name: 'galaxy.ppcStateStore', storage: createJSONStorage(() => sessionStorage) }
  )
)

export default ppcStateStore
