import { Tag } from 'antd'
import {
  AmazonPpcCampaignType,
  AmazonPpcState,
  SponsoredBrandsCampaignLiveData,
  SponsoredDisplayCampaignLiveData,
  SponsoredProductsCampaignLiveData,
} from '../../../generated/graphql'
import { roundNumber } from '../../../helpers/roundNumber'

import {
  GetSponsoredBrandsCampaignLiveDataModel,
  GetSponsoredBrandsCampaignReportsModel,
  GetSponsoredDisplayCampaignLiveDataModel,
  GetSponsoredDisplayCampaignReportsModel,
  GetSponsoredProductsCampaignLiveDataModel,
  GetSponsoredProductsCampaignReportsModel,
} from '../PPCCampaignListView'
import {
  SponsoredBrandsKeywordLiveDataQuery,
  SponsoredBrandsKeywordReportsQuery,
} from '../sponsoredBrands/adGroup/PPCSponsoredBrandsAdGroupView'
import {
  GetSponsoredBrandsAdGroupLiveDataModel,
  GetSponsoredBrandsAdGroupReportsModel,
} from '../sponsoredBrands/campaign/PPCSponsoredBrandsCampaignView'
import {
  SponsoredProductsKeywordLiveDataQuery,
  SponsoredProductsKeywordReportsQuery,
  SponsoredProductsProductAdLiveDataQuery,
  SponsoredProductsProductAdReportsQuery,
  SponsoredProductsTargetLiveDataQuery,
  SponsoredProductsTargetReportsQuery,
} from '../sponsoredProducts/adGroup/PPCSponsoredProductsAdGroupView'
import {
  GetSponsoredProductsAdGroupLiveDataModel,
  GetSponsoredProductsAdGroupReportsModel,
} from '../sponsoredProducts/campaign/PPCSponsoredProductsCampaignView'
import {
  AggregatedAdGroup,
  AggregatedCampaign,
  AggregatedKeyword,
  AggregatedProductAd,
  AggregatedTarget,
  SponsoredBrandsAggregatedAdGroup,
  SponsoredBrandsAggregatedKeyword,
} from './interfaces'

type GenericPpcCampaignLiveData =
  | GetSponsoredProductsCampaignLiveDataModel
  | GetSponsoredBrandsCampaignLiveDataModel
  | GetSponsoredDisplayCampaignLiveDataModel

type GenericPpcCampaignReport =
  | GetSponsoredProductsCampaignReportsModel
  | GetSponsoredBrandsCampaignReportsModel
  | GetSponsoredDisplayCampaignReportsModel

export function combineCampaignLiveDataAndReports(
  campaignType: AmazonPpcCampaignType,
  campaignLiveData: GenericPpcCampaignLiveData[],
  campaignReports: GenericPpcCampaignReport[]
): AggregatedCampaign[] {
  const aggregatedCampaigns: AggregatedCampaign[] = []
  campaignLiveData?.forEach((campaign: GenericPpcCampaignLiveData) => {
    if (!campaign) {
      return null
    }
    const foundCampaignReport: GenericPpcCampaignReport = campaignReports?.filter(
      (campaignReport: any) => campaign.campaignId === campaignReport.campaignId
    )[0]

    const budget =
      campaignType !== AmazonPpcCampaignType.SPONSOREDPRODUCTS
        ? (campaign as SponsoredDisplayCampaignLiveData | SponsoredBrandsCampaignLiveData).budget
        : undefined

    const dailyBudget =
      campaignType === AmazonPpcCampaignType.SPONSOREDPRODUCTS
        ? (campaign as SponsoredProductsCampaignLiveData).dailyBudget
        : undefined

    const targetingType =
      campaignType === AmazonPpcCampaignType.SPONSOREDPRODUCTS
        ? (campaign as SponsoredProductsCampaignLiveData).targetingType
        : undefined

    const acos =
      foundCampaignReport && foundCampaignReport.spend / foundCampaignReport.sales
        ? roundNumber((foundCampaignReport.spend / foundCampaignReport.sales) * 100)
        : 0
    const clicks = foundCampaignReport ? foundCampaignReport.clicks : 0
    const impressions = foundCampaignReport ? foundCampaignReport.impressions : 0
    const spend = foundCampaignReport ? roundNumber(foundCampaignReport.spend) : 0
    const sales = foundCampaignReport ? roundNumber(foundCampaignReport.sales) : 0
    const costPerClick =
      foundCampaignReport && foundCampaignReport.spend / foundCampaignReport.clicks
        ? roundNumber(foundCampaignReport.spend / foundCampaignReport.clicks)
        : 0

    aggregatedCampaigns.push({
      campaignId: campaign.campaignId,
      name: campaign.name,
      campaignType: campaignType,
      state: campaign.state as AmazonPpcState,
      budget: budget,
      dailyBudget: dailyBudget,
      marketplace: campaign.marketplace,
      targetingType: targetingType,
      acos: acos,
      clicks: clicks,
      impressions: impressions,
      spend: spend,
      sales: sales,
      costPerClick: costPerClick,
    })
  })

  return aggregatedCampaigns
}

export const combineSponsoredProductsAdGroupLiveDataAndReports = (
  adGroupLiveData: GetSponsoredProductsAdGroupLiveDataModel[],
  adGroupReports: GetSponsoredProductsAdGroupReportsModel[]
): AggregatedAdGroup[] => {
  const aggregatedAdGroups: AggregatedAdGroup[] = []
  adGroupLiveData.forEach((adGroup: GetSponsoredProductsAdGroupLiveDataModel) => {
    if (!adGroup) {
      return
    }
    const foundAdGroupReport = adGroupReports?.filter(
      (adgroupReport: GetSponsoredProductsAdGroupReportsModel) =>
        adGroup.adGroupId === adgroupReport?.adGroupId
    )[0]

    const acos =
      foundAdGroupReport && foundAdGroupReport.spend / foundAdGroupReport.sales
        ? roundNumber((foundAdGroupReport.spend / foundAdGroupReport.sales) * 100)
        : 0

    const clicks = foundAdGroupReport && foundAdGroupReport.clicks ? foundAdGroupReport.clicks : 0
    const impressions =
      foundAdGroupReport && foundAdGroupReport.impressions ? foundAdGroupReport.impressions : 0
    const spend =
      foundAdGroupReport && foundAdGroupReport.spend ? roundNumber(foundAdGroupReport.spend) : 0
    const sales =
      foundAdGroupReport && foundAdGroupReport.sales ? roundNumber(foundAdGroupReport.sales) : 0
    const costPerClick =
      foundAdGroupReport && foundAdGroupReport.spend / foundAdGroupReport.clicks
        ? roundNumber(foundAdGroupReport.spend / foundAdGroupReport.clicks)
        : 0

    aggregatedAdGroups.push({
      campaignId: adGroup.campaignId,
      adGroupId: adGroup.adGroupId,
      name: adGroup.name,
      marketplace: adGroup.marketplace,
      state: adGroup.state,
      defaultBid: adGroup.defaultBid,
      acos: acos,
      clicks: clicks,
      impressions: impressions,
      spend: spend,
      sales: sales,
      costPerClick: costPerClick,
    })
  })

  return aggregatedAdGroups
}

export const combineSponsoredBrandsAdGroupLiveDataAndReports = (
  adGroupLiveData: GetSponsoredBrandsAdGroupLiveDataModel[],
  adGroupReports: GetSponsoredBrandsAdGroupReportsModel[]
): SponsoredBrandsAggregatedAdGroup[] => {
  const aggregatedAdGroups: SponsoredBrandsAggregatedAdGroup[] = []
  adGroupLiveData.forEach((adGroup: GetSponsoredBrandsAdGroupLiveDataModel) => {
    if (!adGroup) {
      return
    }
    const foundAdGroupReport = adGroupReports?.filter(
      (adgroupReport: GetSponsoredBrandsAdGroupReportsModel) =>
        adGroup.adGroupId === adgroupReport?.adGroupId
    )[0]

    const acos =
      foundAdGroupReport && foundAdGroupReport.spend / foundAdGroupReport.sales
        ? roundNumber((foundAdGroupReport.spend / foundAdGroupReport.sales) * 100)
        : 0

    const clicks = foundAdGroupReport && foundAdGroupReport.clicks ? foundAdGroupReport.clicks : 0
    const impressions =
      foundAdGroupReport && foundAdGroupReport.impressions ? foundAdGroupReport.impressions : 0
    const spend =
      foundAdGroupReport && foundAdGroupReport.spend ? roundNumber(foundAdGroupReport.spend) : 0
    const sales =
      foundAdGroupReport && foundAdGroupReport.sales ? roundNumber(foundAdGroupReport.sales) : 0
    const costPerClick =
      foundAdGroupReport && foundAdGroupReport.spend / foundAdGroupReport.clicks
        ? roundNumber(foundAdGroupReport.spend / foundAdGroupReport.clicks)
        : 0

    aggregatedAdGroups.push({
      campaignId: adGroup.campaignId,
      adGroupId: adGroup.adGroupId,
      name: adGroup.name,
      marketplace: adGroup.marketplace,
      acos: acos,
      clicks: clicks,
      impressions: impressions,
      spend: spend,
      sales: sales,
      costPerClick: costPerClick,
    })
  })

  return aggregatedAdGroups
}

export const getCombinedKeywordLiveDataAndReports = (
  keywordLiveData: SponsoredProductsKeywordLiveDataQuery,
  keywordReports: SponsoredProductsKeywordReportsQuery
): AggregatedKeyword[] => {
  const aggregatedKeywords: AggregatedKeyword[] = []
  keywordLiveData.map((keyword: SponsoredProductsKeywordLiveDataQuery[number]) => {
    if (!keyword) {
      return
    }
    const foundKeywordReport = keywordReports.filter(
      (keywordReport: SponsoredProductsKeywordReportsQuery[number]) =>
        keyword.keywordId === keywordReport?.keywordId
    )[0]
    if (foundKeywordReport) {
      aggregatedKeywords.push({
        ...keyword,
        acos:
          foundKeywordReport.spend / foundKeywordReport.sales
            ? roundNumber((foundKeywordReport.spend / foundKeywordReport.sales) * 100)
            : 0,
        clicks: foundKeywordReport.clicks,
        impressions: foundKeywordReport.impressions,
        spend: roundNumber(foundKeywordReport.spend),
        sales: roundNumber(foundKeywordReport.sales),
        costPerClick:
          foundKeywordReport.spend / foundKeywordReport.clicks
            ? roundNumber(foundKeywordReport.spend / foundKeywordReport.clicks)
            : 0,
      })
    } else {
      aggregatedKeywords.push({
        ...keyword,
        acos: 0,
        spend: 0,
        sales: 0,
        clicks: 0,
        impressions: 0,
        costPerClick: 0,
      })
    }
  })

  return aggregatedKeywords
}

export const getCombinedTargetLiveDataAndReports = (
  targetLiveData: SponsoredProductsTargetLiveDataQuery,
  targetReports: SponsoredProductsTargetReportsQuery
): AggregatedTarget[] => {
  const aggregatedTargets: AggregatedTarget[] = []

  targetLiveData.forEach((target: SponsoredProductsTargetLiveDataQuery[number]) => {
    if (!target) {
      return
    }
    const foundTargetReport = targetReports.filter(
      (targetReport: SponsoredProductsTargetReportsQuery[number]) =>
        target?.targetId === targetReport?.targetId
    )[0]

    let targetName = ''
    if (target.resolvedExpression?.length) {
      const resolvedExpressionValue: string = target.resolvedExpression[0]?.value
        ? ': ' + target.resolvedExpression[0].value
        : ''
      targetName = target.resolvedExpression[0]?.type + resolvedExpressionValue
    } else if (target.expression?.length) {
      const expressionValue: string = target.expression[0]?.value
        ? ': ' + target.expression[0].value
        : ''
      targetName = target.expression[0]?.type + expressionValue
    }

    const acos =
      foundTargetReport && foundTargetReport.spend / foundTargetReport.sales
        ? roundNumber((foundTargetReport.spend / foundTargetReport.sales) * 100)
        : 0

    const clicks = foundTargetReport && foundTargetReport.clicks ? foundTargetReport.clicks : 0
    const impressions =
      foundTargetReport && foundTargetReport.impressions ? foundTargetReport.impressions : 0
    const spend =
      foundTargetReport && foundTargetReport.spend ? roundNumber(foundTargetReport.spend) : 0
    const sales =
      foundTargetReport && foundTargetReport.sales ? roundNumber(foundTargetReport.sales) : 0
    const costPerClick =
      foundTargetReport && foundTargetReport.spend / foundTargetReport.clicks
        ? roundNumber(foundTargetReport.spend / foundTargetReport.clicks)
        : 0

    aggregatedTargets.push({
      ...target,
      bid: target.bid || 0,
      name: targetName,
      acos: acos,
      clicks: clicks,
      impressions: impressions,
      spend: spend,
      sales: sales,
      costPerClick: costPerClick,
    })
  })

  return aggregatedTargets
}

export const getCombinedSponsoredProductsProductAdLiveDataAndReports = (
  productAdLiveData: SponsoredProductsProductAdLiveDataQuery,
  productAdReports: SponsoredProductsProductAdReportsQuery
): AggregatedProductAd[] => {
  const aggregatedProductAds: AggregatedProductAd[] = []
  productAdLiveData.forEach((productAd: SponsoredProductsProductAdLiveDataQuery[number]) => {
    if (!productAd) {
      return
    }
    const foundReport = productAdReports.filter(
      (productAdReport: SponsoredProductsProductAdReportsQuery[number]) =>
        productAdReport?.adId === productAd?.adId
    )[0]

    const acos =
      foundReport && foundReport.spend / foundReport.sales
        ? roundNumber((foundReport.spend / foundReport.sales) * 100)
        : 0
    const clicks = foundReport && foundReport.clicks ? foundReport.clicks : 0
    const impressions = foundReport && foundReport.impressions ? foundReport.impressions : 0
    const spend = foundReport && foundReport.spend ? roundNumber(foundReport.spend) : 0
    const sales = foundReport && foundReport.sales ? roundNumber(foundReport.sales) : 0
    const costPerClick =
      foundReport && foundReport.spend / foundReport.clicks
        ? roundNumber(foundReport.spend / foundReport.clicks)
        : 0

    aggregatedProductAds.push({
      ...productAd,
      acos: acos,
      spend: spend,
      sales: sales,
      clicks: clicks,
      impressions: impressions,
      costPerClick: costPerClick,
    })
  })

  return aggregatedProductAds
}

export const getCombinedSponsoredBrandsKeywordLiveDataAndReports = (
  keywordLiveData: SponsoredBrandsKeywordLiveDataQuery,
  keywordReports: SponsoredBrandsKeywordReportsQuery
): SponsoredBrandsAggregatedKeyword[] => {
  const aggregatedKeywords: SponsoredBrandsAggregatedKeyword[] = []

  keywordLiveData.forEach((keyword: SponsoredBrandsKeywordLiveDataQuery[number]) => {
    if (!keyword) {
      return
    }
    const foundKeywordReport = keywordReports.filter(
      (keywordReport: SponsoredBrandsKeywordReportsQuery[number]) =>
        keyword.keywordId === keywordReport?.keywordId
    )[0]

    const acos =
      foundKeywordReport && foundKeywordReport.spend / foundKeywordReport.sales
        ? roundNumber((foundKeywordReport.spend / foundKeywordReport.sales) * 100)
        : 0
    const clicks = foundKeywordReport && foundKeywordReport.clicks ? foundKeywordReport.clicks : 0
    const impressions =
      foundKeywordReport && foundKeywordReport.impressions ? foundKeywordReport.impressions : 0
    const spend =
      foundKeywordReport && foundKeywordReport.spend ? roundNumber(foundKeywordReport.spend) : 0
    const sales =
      foundKeywordReport && foundKeywordReport.sales ? roundNumber(foundKeywordReport.sales) : 0
    const costPerClick =
      foundKeywordReport && foundKeywordReport.spend / foundKeywordReport.clicks
        ? roundNumber(foundKeywordReport.spend / foundKeywordReport.clicks)
        : 0

    aggregatedKeywords.push({
      keywordId: keyword.keywordId,
      adGroupId: keyword.adGroupId,
      marketplace: keyword.marketplace,
      keywordText: keyword.keywordText,
      bid: keyword.bid,
      state: keyword.state,
      matchType: keyword.matchType,
      acos: acos,
      spend: spend,
      sales: sales,
      clicks: clicks,
      impressions: impressions,
      costPerClick: costPerClick,
    })
  })

  return aggregatedKeywords
}

export const getColorFromState = (state: AmazonPpcState) => {
  switch (state) {
    case AmazonPpcState.ENABLED:
      return 'green'
    case AmazonPpcState.PAUSED:
      return 'blue'
    case AmazonPpcState.ARCHIVED:
    default:
      return 'default'
  }
}

export const getColorFromTargetingType = (type: string) => {
  if (type === 'manual') {
    return 'default'
  } else {
    return 'default'
  }
}

export const getTagByCampaignType = (campaignType: AmazonPpcCampaignType) => {
  switch (campaignType) {
    case AmazonPpcCampaignType.SPONSOREDPRODUCTS:
      return <Tag color="orange">Sponsored Products</Tag>
    case AmazonPpcCampaignType.SPONSOREDBRANDS:
      return <Tag color="cyan">Sponsored Brands</Tag>
    case AmazonPpcCampaignType.SPONSOREDDISPLAY:
      return <Tag color="magenta">Sponsored Display</Tag>
    default:
      return null
  }
}
