import { useMutation } from '@apollo/client'
import { Button, Form, Input, Modal, Select, Upload, UploadFile, UploadProps } from 'antd'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { UploadIcon } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { toast } from 'sonner'
import {
  AmazonListingDocumentType,
  CreateAmazonListingDocumentDocument,
  GetAmazonListingQuery,
  Marketplace,
  SendSupportTicketDocument,
} from '../../../../generated/graphql'
import { useGlobalStore } from '../../../../stores/useGlobalStore'
import { createZendeskTicketOptions } from '../../../ticket/helpers/Zendesk.helper'
import { TicketCategory, TicketMainCategory } from '../../../ticket/ticket.enum'

type AmazonListingDocumentFormInstance = {
  file: UploadFile
  filename: string
  type: AmazonListingDocumentType
}

type AmazonListingDocumentUploadModalProps = {
  amazonListing: GetAmazonListingQuery['amazonListing']
}

const amazonListingDocumentTypeOptions = Object.values(AmazonListingDocumentType).map((value) => ({
  label: value,
  value,
}))

export const AmazonListingDocumentUploadModal = ({
  amazonListing,
}: AmazonListingDocumentUploadModalProps) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { t, i18n } = useTranslation(['inventory'])
  const [form] = Form.useForm<AmazonListingDocumentFormInstance>()

  const [createAmazonListingDocument] = useMutation(CreateAmazonListingDocumentDocument)
  const [sendSupportTicketRequest] = useMutation(SendSupportTicketDocument)

  const handleFileChange: UploadProps['onChange'] = (info) => {
    const file = info.file.originFileObj
    const filename = file?.name.replace(/\.[^/.]+$/, '') ?? ''
    form.setFieldsValue({ filename })
  }

  const handleSubmit = async (values: AmazonListingDocumentFormInstance) => {
    setLoading(true)
    try {
      const unixTimestamp = dayjs().unix()
      const fileExtension = values.file.name.split('.').pop()
      const key = `${amazonListing?.uuid}/${unixTimestamp}_${values.filename}.${fileExtension}`
      const response = await Storage.put(key, values.file.originFileObj, {
        bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME_AMAZON_LISTING_DOCUMENTS,
        contentType: values.file.type,
        metadata: {
          type: values.type,
        },
      })

      await createAmazonListingDocument({
        variables: {
          amazonListingUuid: amazonListing.uuid,
          input: {
            key: response.key,
            type: values.type,
          },
        },
        optimisticResponse: {
          createAmazonListingDocument: {
            key: response.key,
            type: values.type,
            createdAt: new Date().toISOString(),
          },
        },
        update: (cache, { data }) => {
          const updatedAmazonListing = data?.createAmazonListingDocument
          if (updatedAmazonListing) {
            cache.modify({
              id: cache.identify({
                __typename: 'AmazonListing',
                uuid: amazonListing.uuid,
              }),
              fields: {
                documents: (documents = []) => [...documents, updatedAmazonListing],
              },
            })
          }
        },
      })

      const options = createZendeskTicketOptions({
        categoryTitle: TicketMainCategory.LISTINGS,
        comment: getMessage(
          location.pathname,
          amazonListing.title ?? '',
          amazonListing.sku,
          amazonListing.amazonProduct.asin,
          response.key,
          amazonListing.marketplace.name
        ),
        language: i18n.language,
        mainCategory: TicketMainCategory.LISTINGS,
        subject: t('product.details.documents.upload.success'),
        ticketCategory: TicketCategory.LISTINGS_PRODUCT_INFO_EDIT,
        sellerId: selectedCompany?.sellerId,
      })

      await sendSupportTicketRequest({
        variables: {
          companyUuid: selectedCompany.uuid,
          input: options,
          isAdmin: true,
        },
      })

      toast.success(t('product.details.documents.upload.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('product.details.documents.upload.error'))
    } finally {
      setOpen(false)
      setLoading(false)
      form.resetFields()
    }
  }

  return (
    <>
      <Button icon={<UploadIcon size={16} />} onClick={() => setOpen(true)}>
        {t('product.details.documents.upload.button')}
      </Button>
      <Modal
        title={t('product.details.documents.upload.title')}
        open={open}
        okText={t('shared.button.submit', { ns: 'translation' })}
        onOk={() => form.submit()}
        cancelText={t('shared.button.cancel', { ns: 'translation' })}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form<AmazonListingDocumentFormInstance>
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            name="file"
            label={t('product.details.documents.upload.file')}
            rules={[{ required: true }]}
            getValueFromEvent={(event) => event.file}
            valuePropName="file"
          >
            <Upload
              accept=".csv,.xlsx,.xls,.pdf,.gsheet"
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  if (onSuccess) {
                    onSuccess('ok')
                  }
                }, 0)
              }}
              maxCount={1}
              onChange={handleFileChange}
              showUploadList={false}
            >
              <Button type="dashed" icon={<UploadIcon size={16} />} loading={loading}>
                {t('product.details.documents.upload.button')}
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="filename"
            label={t('product.details.documents.upload.filename')}
            rules={[{ required: true, type: 'string', whitespace: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label={t('product.details.documents.upload.type')}
            rules={[{ required: true, type: 'string' }]}
          >
            <Select<AmazonListingDocumentType>
              options={amazonListingDocumentTypeOptions}
              placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const getMessage = (
  location: string,
  amazonListingTitle: string,
  sku: string,
  asin: string,
  filename: string,
  marketplace: Marketplace | undefined
) => {
  return `
  <h1>New Amazon Listing Document Uploaded</h1>
  <p>ASIN: ${asin}</p>
  <p>SKU: ${sku}</p>
  <p><b>Listing title:</b> ${amazonListingTitle}</p>
  <p><b>File name:</b> ${filename.split('_').slice(1).join('_')}</p>
  <p><b>Marketplace:</b> ${marketplace ? marketplace : 'Failed to determine marketplace'}</p>
  <h3><a href="https://galaxy.spacegoats.io${location}">Open amazon listing details page in Galaxy</a></h3>
`
}
