import { Form, Select } from 'antd'
import { AmazonPpcState } from '../../../generated/graphql'

const PPCStateFormFields = () => {
  return (
    <Form.Item label="Status" name="state">
      <Select>
        <Select.Option key={AmazonPpcState.ENABLED} value={AmazonPpcState.ENABLED}>
          {AmazonPpcState.ENABLED}
        </Select.Option>
        <Select.Option key={AmazonPpcState.PAUSED} value={AmazonPpcState.PAUSED}>
          {AmazonPpcState.PAUSED}
        </Select.Option>
        <Select.Option key={AmazonPpcState.ARCHIVED} value={AmazonPpcState.ARCHIVED}>
          {AmazonPpcState.ARCHIVED}
        </Select.Option>
      </Select>
    </Form.Item>
  )
}

export default PPCStateFormFields
