import { Spin, Typography } from 'antd'

type LoadingSpinnerProps = {
  title?: string
  message?: string
}

export const LoadingSpinner = ({ title, message }: LoadingSpinnerProps) => (
  <div
    style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
      height: '100%',
    }}
  >
    <Spin size="large" />
    {title ? (
      <div style={{ width: '400px' }}>
        <Typography.Title level={4}>{title}</Typography.Title>
      </div>
    ) : null}
    {message ? (
      <div style={{ width: '400px' }}>
        <Typography.Text>{message}</Typography.Text>
      </div>
    ) : null}
  </div>
)
