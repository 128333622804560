import { GetContextMenuItems } from 'ag-grid-community'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { BankTransaction } from '../../BankTransactionsView'

export const useBankTransactionTableContextMenuItems = () => {
  const { t } = useTranslation(['billing'], { keyPrefix: 'bankTransactions.table.contextMenu' })

  const navigate = useNavigate()

  const getContextMenuItems: GetContextMenuItems<BankTransaction> = useCallback(
    (params) => [
      {
        name: t('createTicket'),
        disabled: !params.node?.data?.id,
        action: () => {
          navigate('/tickets/galaxy/billing/payment', {
            state: {
              categoryTitleTag: 'spacegoats',
              bankTransactionId: params.node?.data?.id,
            },
          })
        },
      },
      'separator',
      'copy',
      'copyWithHeaders',
      'export',
    ],
    []
  )

  return getContextMenuItems
}
