import styled from '@emotion/styled'
import { InputNumber, Menu } from 'antd'

const CategoryItem = styled.div`
  margin: 8px 8px 0px 8px;
  padding: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
`

const CategoryItemDetails = styled.div`
  flex: 1;
`

const CategoryPath = styled.div`
  font-size: 0.7rem;
  line-height: 1rem;
  color: grey;
`

const CategoryName = styled.div`
  margin-top: 4px;
  font-size: 0.8rem;
`

const CategoryItemActions = styled.div`
  padding: 8px;
`

const SmallSubMenu = styled(Menu)`
  &.ant-menu-horizontal {
    font-size: 13px;
    line-height: 40px;
  }
`

const SmallInput = styled(InputNumber)`
  font-size: 13px;
  &.ant-input-number-affix-wrapper {
    padding-left: 8px;
    margin: 8px;
    width: 76px;
  }
  .ant-input-number {
    line-height: 28px;
  }
`

export {
  CategoryItem,
  CategoryItemActions,
  CategoryItemDetails,
  CategoryName,
  CategoryPath,
  SmallInput,
  SmallSubMenu,
}
