import { Badge, Flex, Space, Tooltip, Typography } from 'antd'
import { Flag } from '../../../../components/Flag'
import { AmazonListingFieldsFragment } from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { StyledSubCard } from '../../../dashboard/styles'
import { getAmazonListingInternalStatusColor } from '../helpers/getAmazonListingInternalStatusColor'
import { AmazonListingExternalStatusTag } from './AmazonListingExternalStatusTag'
import { getAmazonListingInternalStatusText } from '../helpers/getAmazonListingInternalStatusText'
import { useTranslation } from 'react-i18next'

type AmazonListingCardProps = {
  amazonListing: AmazonListingFieldsFragment
}

export const AmazonListingCard = ({ amazonListing }: AmazonListingCardProps) => {
  const { t } = useTranslation()
  const internalStatusColor = getAmazonListingInternalStatusColor(amazonListing.internalStatus)
  const internalStatusText = getAmazonListingInternalStatusText(amazonListing.internalStatus, t)

  return (
    <StyledSubCard
      title={
        <Tooltip title={internalStatusText}>
          <Space>
            <Badge color={internalStatusColor} />
            {`Amazon.${getMarketplaceDomain(amazonListing.marketplace.name)}`}
          </Space>
        </Tooltip>
      }
      extra={
        <Flag
          code={amazonListing.marketplace.name}
          style={{ fontSize: '1.5em', verticalAlign: 'text-bottom' }}
        />
      }
      hoverable
    >
      <Flex vertical align="flex-start" gap={8}>
        <Typography.Text ellipsis>{amazonListing.sku}</Typography.Text>
        <AmazonListingExternalStatusTag externalStatus={amazonListing.externalStatus} />
      </Flex>
    </StyledSubCard>
  )
}
