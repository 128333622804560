import { Button, Empty } from 'antd'
import { Plus } from 'lucide-react'
import {
  AmazonPpcCampaignType,
  GetSponsoredProductsTargetCategoryRecommendationsQuery,
  TargetingExpressionPredicateType,
} from '../../../../../../generated/graphql'
import { ViewContainer } from '../../../../../Layout/ContentWrapper'
import { useDefaultMinimumBid } from '../../../../hooks/useDefaultMinimumBid'
import { ScrollableList } from '../../../../styles'
import { UserDefinedTarget } from './CreateSponsoredProductsManualTargetsFormFields'
import {
  CategoryItem,
  CategoryItemActions,
  CategoryItemDetails,
  CategoryName,
  CategoryPath,
} from './styles'

export type DetailedAmazonCategory =
  GetSponsoredProductsTargetCategoryRecommendationsQuery['sponsoredProductsTargetCategoryRecommendations']['categories'][number]

type AddCategoryListProps = {
  categories: DetailedAmazonCategory[]
  selectedCategoryIds: string[]
  handleAddTarget: Function
}

const AddCategoryList = ({
  categories,
  handleAddTarget,
  selectedCategoryIds,
}: AddCategoryListProps) => {
  const defaultMinimumBid = useDefaultMinimumBid(AmazonPpcCampaignType.SPONSOREDPRODUCTS)

  const handleAddCategory = (category: DetailedAmazonCategory) => {
    const target: UserDefinedTarget = {
      id: category.id,
      expressionType: TargetingExpressionPredicateType.ASINCATEGORYSAMEAS,
      expressionValue: category.id,
      resolvedExpressionValue: category.name,
      description: category.path,
      name: category.name,
      bid: defaultMinimumBid,
    }
    handleAddTarget(target)
  }

  if (!categories.length) {
    return (
      <ViewContainer centered>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </ViewContainer>
    )
  }

  return (
    <ScrollableList>
      {categories.map((category) => {
        const categoryDisabled = selectedCategoryIds.includes(category.id)
        return (
          <CategoryItem key={category.id} style={{ opacity: categoryDisabled ? 0.5 : 1 }}>
            <CategoryItemDetails>
              <CategoryPath>{category.path?.replace(/\//g, ' / ')}</CategoryPath>
              <CategoryName>
                Category: <span style={{ fontWeight: 'bold' }}>{category.name}</span>
              </CategoryName>
            </CategoryItemDetails>
            <CategoryItemActions>
              <Button
                type="text"
                icon={<Plus size={16} />}
                disabled={categoryDisabled}
                onClick={() => handleAddCategory(category)}
                data-testid="add-category-btn"
              />
            </CategoryItemActions>
          </CategoryItem>
        )
      })}
    </ScrollableList>
  )
}

export default AddCategoryList
