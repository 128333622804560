import { useMutation } from '@apollo/client'
import { Button, Form, Input, Modal, Select, Upload, UploadFile, UploadProps } from 'antd'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { CreateProductComplianceCheckDocumentDocument } from '../../../generated/graphql'
import { useCountryOptions } from '../../../hooks/useCountryOptions'
import { UploadIcon } from 'lucide-react'

dayjs.extend(utc)

type ProductComplianceCheckDocumentUploadModalFormInstance = {
  file: UploadFile
  filename: string
  countryCodes: string[]
}

type ProductComplianceCheckDocumentUploadModalProps = {
  productComplianceCheckIdentifier: string
  productUuid: string
}

const bucketName = import.meta.env.VITE_AWS_S3_BUCKET_NAME_PRODUCT_DOCUMENTS

export const ProductComplianceCheckDocumentUploadModal = ({
  productComplianceCheckIdentifier,
  productUuid,
}: ProductComplianceCheckDocumentUploadModalProps) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const countryOptions = useCountryOptions()

  const { t } = useTranslation(['compliance'])
  const [form] = Form.useForm<ProductComplianceCheckDocumentUploadModalFormInstance>()

  const [createProductComplianceCheckDocument] = useMutation(
    CreateProductComplianceCheckDocumentDocument
  )

  const handleFileChange: UploadProps['onChange'] = (info) => {
    const file = info.file.originFileObj
    const filename = file?.name.replace(/\.[^/.]+$/, '') ?? ''
    form.setFieldsValue({ filename })
  }

  const handleSubmit = async (values: ProductComplianceCheckDocumentUploadModalFormInstance) => {
    setLoading(true)
    try {
      const unixTimestamp = dayjs.utc().unix()
      const fileExtension = values.file.name.split('.').pop() ?? ''
      const key = `${productUuid}/compliance/${unixTimestamp}_${values.filename}.${fileExtension}`

      const response = await Storage.put(key, values.file.originFileObj, {
        bucket: bucketName,
        contentType: values.file.type,
      })

      await createProductComplianceCheckDocument({
        variables: {
          productComplianceCheckIdentifier,
          bucketKey: response.key,
          countryCodes: values.countryCodes,
        },
        update: (cache, { data }) => {
          const createdProductComplianceCheckDocument = data?.createProductComplianceCheckDocument
          if (createdProductComplianceCheckDocument) {
            cache.modify({
              id: cache.identify({
                __typename: 'ProductComplianceCheck',
                identifier: productComplianceCheckIdentifier,
              }),
              fields: {
                documents: (documents = []) => [
                  ...documents,
                  createdProductComplianceCheckDocument,
                ],
              },
            })
          }
        },
      })

      toast.success(t('documents.uploadModal.toast.success'))
    } catch (error) {
      console.error(error)
      toast.error(t('documents.uploadModal.toast.error'))
    } finally {
      setOpen(false)
      setLoading(false)
      form.resetFields()
    }
  }

  return (
    <>
      <Button icon={<UploadIcon size={16} />} onClick={() => setOpen(true)}>
        {t('documents.uploadModal.button.upload')}
      </Button>
      <Modal
        title={t('documents.uploadModal.title')}
        open={open}
        okText={t('documents.uploadModal.button.upload')}
        onOk={() => form.submit()}
        cancelText={t('documents.uploadModal.button.cancel')}
        onCancel={() => setOpen(false)}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form<ProductComplianceCheckDocumentUploadModalFormInstance>
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            name="file"
            label={t('documents.uploadModal.form.file.label')}
            rules={[
              { required: true, message: t('documents.uploadModal.form.file.validation.required') },
            ]}
            getValueFromEvent={(event) => event.file}
            valuePropName="file"
          >
            <Upload
              accept=".csv,.xlsx,.xls,.pdf,.gsheet.,.jpg,.jpeg,.png,.webp,.doc,.docx"
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  if (onSuccess) {
                    onSuccess('ok')
                  }
                }, 0)
              }}
              maxCount={1}
              onChange={handleFileChange}
            >
              <Button icon={<UploadIcon size={16} />} loading={loading}>
                {t('documents.uploadModal.form.file.button')}
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="filename"
            label={t('documents.uploadModal.form.filename.label')}
            rules={[
              {
                required: true,
                type: 'string',
                whitespace: true,
                message: t('documents.uploadModal.form.filename.validation.required'),
              },
            ]}
          >
            <Input placeholder={t('documents.uploadModal.form.filename.placeholder')} />
          </Form.Item>
          <Form.Item name="countryCodes" label={t('documents.uploadModal.form.countryCodes.label')}>
            <Select
              mode="multiple"
              options={countryOptions.map((country) => ({
                value: country.code,
                label: country.name,
              }))}
              placeholder={t('shared.form.placeholder.select', { ns: 'translation' })}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
