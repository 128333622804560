import { Descriptions, Flex, Typography } from 'antd'
import { ArrowDown } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useCountryOptions } from '../../../../hooks/useCountryOptions'
import { StyledMainCard } from '../../../dashboard/styles'
import { descriptionsStyle } from '../../../products/components/styles'
import { WholesaleOrder } from '../../WholesaleOrderDetailsView'
import { WholesaleOrderTemplate } from '../../WholesaleOrderTemplateDetailsView'

type WholesaleOrderWarehouseDetailsCardProps = {
  wholesaleOrder: WholesaleOrder | WholesaleOrderTemplate
}

export const WholesaleOrderWarehouseDetailsCard = ({
  wholesaleOrder,
}: WholesaleOrderWarehouseDetailsCardProps) => {
  const { t } = useTranslation('wholesale-order', {
    keyPrefix: 'wholesaleOrderShipmentDetailsCard',
  })

  return (
    <StyledMainCard title={t('title')}>
      <Flex vertical gap={16}>
        <ShippedFromWarehouseDetails
          warehouseLocation={wholesaleOrder?.shippedFromWarehouseLocation}
        />
        <Flex justify="center">
          <ArrowDown size={24} />
        </Flex>
        <ShippedToWarehouseDetails warehouseLocation={wholesaleOrder?.shippedToWarehouseLocation} />
      </Flex>
    </StyledMainCard>
  )
}

const ShippedFromWarehouseDetails = ({
  warehouseLocation,
}: {
  warehouseLocation: WholesaleOrderWarehouseDetailsCardProps['wholesaleOrder']['shippedFromWarehouseLocation']
}) => {
  const { t } = useTranslation('wholesale-order', {
    keyPrefix: 'wholesaleOrderShipmentDetailsCard',
  })
  const countryOptions = useCountryOptions()

  return (
    <Descriptions {...descriptionsStyle}>
      <Descriptions.Item label={t('label.shippedFromWarehouse')}>
        {warehouseLocation.name}
      </Descriptions.Item>
      <Descriptions.Item label={t('label.shippedFromAddress')}>
        <Flex vertical>
          <Typography.Text>{warehouseLocation.addressLine1}</Typography.Text>
          <Typography.Text>{warehouseLocation.addressLine2}</Typography.Text>
          <Typography.Text>{warehouseLocation.city}</Typography.Text>
          <Typography.Text>{warehouseLocation.provinceCode}</Typography.Text>
          <Typography.Text>{warehouseLocation.postalCode}</Typography.Text>
          <Typography.Text>
            {
              countryOptions.find((country) => country.code === warehouseLocation?.countryCode)
                ?.name
            }
          </Typography.Text>
        </Flex>
      </Descriptions.Item>
      <Descriptions.Item label={t('label.checkOutDurationInDays')}>
        {warehouseLocation.checkOutDurationInDays}{' '}
        {warehouseLocation.checkOutDurationInDays === 1 ? t('day') : t('days')}
      </Descriptions.Item>
    </Descriptions>
  )
}

const ShippedToWarehouseDetails = ({
  warehouseLocation,
}: {
  warehouseLocation: WholesaleOrderWarehouseDetailsCardProps['wholesaleOrder']['shippedToWarehouseLocation']
}) => {
  const { t } = useTranslation('wholesale-order', {
    keyPrefix: 'wholesaleOrderShipmentDetailsCard',
  })
  const countryOptions = useCountryOptions()

  return (
    <Descriptions {...descriptionsStyle}>
      <Descriptions.Item label={t('label.shippedToWarehouse')}>
        {warehouseLocation.name}
      </Descriptions.Item>
      <Descriptions.Item label={t('label.shippedToAddress')}>
        <Flex vertical>
          <Typography.Text>{warehouseLocation.addressLine1}</Typography.Text>
          <Typography.Text>{warehouseLocation.addressLine2}</Typography.Text>
          <Typography.Text>{warehouseLocation.city}</Typography.Text>
          <Typography.Text>{warehouseLocation.provinceCode}</Typography.Text>
          <Typography.Text>{warehouseLocation.postalCode}</Typography.Text>
          <Typography.Text>
            {
              countryOptions.find((country) => country.code === warehouseLocation?.countryCode)
                ?.name
            }
          </Typography.Text>
        </Flex>
      </Descriptions.Item>
      <Descriptions.Item label={t('label.checkInDurationInDays')}>
        {warehouseLocation.checkInDurationInDays}{' '}
        {warehouseLocation.checkInDurationInDays === 1 ? t('day') : t('days')}
      </Descriptions.Item>
    </Descriptions>
  )
}
