import { ColDef, ColGroupDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'
import { Eye } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../../../components/FormattedDate'
import { LinkButton } from '../../../../../../components/LinkButton'
import { CompanyType } from '../../../../../../generated/graphql'
import { getSearchIdentifiers } from '../../../../../../helpers/getSearchIdentifiers'
import {
  cellGetters,
  dateComparator,
  dateFilterParams,
} from '../../../../../../helpers/tableHelpers'
import { useGlobalStore } from '../../../../../../stores/useGlobalStore'
import { WholesaleOrderBillingDocumentTypeTag } from '../../../../components/WholesaleOrderBillingDocumentTypeTag'
import { WholesaleOrderBillingDocumentTableRecord } from '../WholesaleOrderBillingDocumentTable'

export const useWholesaleOrderBillingDocumentColumnDefs = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation('wholesale-order-billing-document', {
    keyPrefix: 'wholesaleOrderBillingDocumentTable.columnDefs',
  })

  const isWholesaler = selectedCompany.type === CompanyType.WHOLESALER

  const [columnDefs] = useState<
    (
      | ColDef<WholesaleOrderBillingDocumentTableRecord>
      | ColGroupDef<WholesaleOrderBillingDocumentTableRecord>
    )[]
  >([
    {
      lockPosition: 'left',
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
      minWidth: 56,
      maxWidth: 56,
      cellRenderer: (params: ICellRendererParams<WholesaleOrderBillingDocumentTableRecord>) => {
        const identifier = params.data?.identifier

        return (
          <Tooltip title={t('actions.view.tooltip')}>
            <LinkButton icon={<Eye size={16} />} disabled={!identifier} to={identifier!} />
          </Tooltip>
        )
      },
    },
    {
      field: 'identifier',
      headerName: t('identifier'),
      filter: 'agTextColumnFilter',
      minWidth: 160,
      getQuickFilterText: (params) => {
        const searchItems = params.data.lineItems.map((item) => ({
          identifier: [params.data.identifier, item.identifier].join(' '),
          productVariant: item.wholesaleOrderLineItem.productVariant,
        }))
        return getSearchIdentifiers(searchItems)
      },
    },
    {
      field: 'documentNumber',
      headerName: t('documentNumber'),
    },
    {
      field: 'creditorCompany',
      headerName: t('creditorCompany'),
      hide: isWholesaler,
    },
    {
      field: 'debtorCompany',
      headerName: t('debtorCompany'),
    },
    {
      field: 'type',
      headerName: t('documentType.header'),
      cellRenderer: ({
        data,
      }: ICellRendererParams<
        WholesaleOrderBillingDocumentTableRecord,
        WholesaleOrderBillingDocumentTableRecord['type']
      >) => {
        if (!data) {
          return null
        }

        return <WholesaleOrderBillingDocumentTypeTag type={data.type} />
      },
    },
    {
      field: 'issueDate',
      headerName: t('issueDate'),
      filterParams: dateFilterParams,
      comparator: dateComparator,
      sort: 'desc',
      valueGetter: (
        params: ValueGetterParams<
          WholesaleOrderBillingDocumentTableRecord,
          WholesaleOrderBillingDocumentTableRecord['issueDate']
        >
      ) => {
        if (!params.data) {
          return null
        }

        const value = dayjs(params.data.issueDate).toDate()

        return cellGetters.getDateValue(value, 'DD.MM.YYYY')
      },
      cellRenderer: (params: ICellRendererParams<WholesaleOrderBillingDocumentTableRecord>) => {
        if (!params.data) {
          return null
        }

        return (
          <FormattedDate
            date={params.data.issueDate}
            format="DD.MM.YYYY"
            withRelativeTime
            layout="horizontal"
          />
        )
      },
    },
  ])

  return columnDefs
}
