import i18n from 'i18next'

type Unit = 'gram' | 'kilogram' | 'centimeter'

export function formatUnit(value: number, unit: Unit) {
  return new Intl.NumberFormat(i18n.language, {
    style: 'unit',
    unit,
    unitDisplay: 'short',
  }).format(value)
}
