import { FullViewContainer } from '../../../components/Layout/ContentWrapper'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { useLogout } from '../../../hooks/useLogout'

export const LogoutView = () => {
  const logout = useLogout()

  setTimeout(async () => {
    logout()
  }, 3000)

  const randomQuote = getRandomQuote()

  return (
    <FullViewContainer>
      <LoadingSpinner title={randomQuote.quote} message={randomQuote.author} />
    </FullViewContainer>
  )
}

function getRandomQuote() {
  const quotes = [
    {
      author: '- Anthony Burgess',
      quote: 'In a dead white field an untethered goat gave them sardonic greeting.',
    },
    {
      author: '- Edna Ferber',
      quote: "The goat's business is none of the sheep's concern.",
    },
    {
      author: '- Gary Larson',
      quote: 'What is that mountain goat doing way up here in the clouds?',
    },
    {
      author: '- James Patterson',
      quote: '"I love you more than I love goats, and you know how I feel about goats", Gaby said.',
    },
    {
      author: '- Jhonen Vasquez',
      quote: 'One day I will rule the world with a goat by my side!',
    },
    {
      author: '- Julia Roberts',
      quote: 'Love is not love, without a violin playing goat.',
    },
    {
      author: '- Mary Jane Hathaway',
      quote: '"I saw goats. A party can\'t be all bad when you have goats," Lucy said.',
    },
  ]

  const quote = quotes[Math.floor(Math.random() * quotes.length)]

  if (!quote) {
    return {
      author: undefined,
      quote: undefined,
    }
  }

  return quote
}
