import { Button, Descriptions, Modal, Space, Tooltip, Typography } from 'antd'
import { CircleHelp, FileText } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TranslationKeys } from '../../../../config/resources'
import { formatPercentage } from '../../../../helpers/formatPercentage'
import { descriptionsStyle } from '../../../products/components/styles'

type SalesChannelRegionDealTerms = {
  margin: number
  securityMargin: number
  marketingPaidByWholesaler: boolean
  removalOrderInMargin: boolean
  shippingFeePaidByWholesaler: boolean
  fulfillmentFeePaidByWholesaler: boolean
  storageFeePaidByWholeSaler: boolean
  daysUntilDiscountApplied?: number | null
  extendDeadlineByDays?: number | null
  discountRate?: number | null
  resaleOverdueProducts: boolean
}

type WholesaleOrderTermsModalProps = {
  salesChannelRegionDeal: SalesChannelRegionDealTerms | null
}

export const WholesaleOrderTermsModal = ({
  salesChannelRegionDeal,
}: WholesaleOrderTermsModalProps) => {
  const [open, setOpen] = useState(false)

  const { t } = useTranslation('wholesale-order')

  const renderLabel = (
    labelKey: TranslationKeys<'wholesale-order'>,
    tooltipKey: TranslationKeys<'wholesale-order'>
  ) => (
    <Space>
      {t(labelKey)}
      <Tooltip title={t(tooltipKey)}>
        <CircleHelp size={16} />
      </Tooltip>
    </Space>
  )

  return (
    <>
      <Button
        icon={<FileText size={16} />}
        disabled={!salesChannelRegionDeal}
        onClick={() => setOpen(true)}
      >
        {t('wholesaleOrderTerms.button.label')}
      </Button>
      <Modal
        title={t('wholesaleOrderTerms.modal.title')}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={800}
      >
        {salesChannelRegionDeal && (
          <Descriptions {...descriptionsStyle}>
            <Descriptions.Item
              label={renderLabel('wholesaleOrderTerms.margin', 'wholesaleOrderTerms.marginTooltip')}
            >
              {formatPercentage(salesChannelRegionDeal.margin / 100)}
            </Descriptions.Item>
            <Descriptions.Item
              label={renderLabel(
                'wholesaleOrderTerms.securityMargin',
                'wholesaleOrderTerms.securityMarginTooltip'
              )}
            >
              {formatPercentage(salesChannelRegionDeal.securityMargin / 100)}
            </Descriptions.Item>
            <Descriptions.Item
              label={renderLabel(
                'wholesaleOrderTerms.marketingPaidByWholesaler',
                'wholesaleOrderTerms.marketingPaidByWholesalerTooltip'
              )}
            >
              <Typography.Text>
                {salesChannelRegionDeal.marketingPaidByWholesaler
                  ? t('wholesaleOrderTerms.yes')
                  : t('wholesaleOrderTerms.no')}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={renderLabel(
                'wholesaleOrderTerms.removalOrdersInMargin',
                'wholesaleOrderTerms.removalOrdersInMarginTooltip'
              )}
            >
              <Typography.Text>
                {salesChannelRegionDeal.removalOrderInMargin
                  ? t('wholesaleOrderTerms.yes')
                  : t('wholesaleOrderTerms.no')}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={renderLabel(
                'wholesaleOrderTerms.shippingFeePaidByWholesaler',
                'wholesaleOrderTerms.shippingFeePaidByWholesalerTooltip'
              )}
            >
              <Typography.Text>
                {salesChannelRegionDeal.shippingFeePaidByWholesaler
                  ? t('wholesaleOrderTerms.yes')
                  : t('wholesaleOrderTerms.no')}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={renderLabel(
                'wholesaleOrderTerms.storageFeePaidByWholesaler',
                'wholesaleOrderTerms.storageFeePaidByWholesalerTooltip'
              )}
            >
              <Typography.Text>
                {salesChannelRegionDeal.storageFeePaidByWholeSaler
                  ? t('wholesaleOrderTerms.yes')
                  : t('wholesaleOrderTerms.no')}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={renderLabel(
                'wholesaleOrderTerms.fulfillmentFeePaidByWholesaler',
                'wholesaleOrderTerms.fulfillmentFeePaidByWholesalerTooltip'
              )}
            >
              <Typography.Text>
                {salesChannelRegionDeal.fulfillmentFeePaidByWholesaler
                  ? t('wholesaleOrderTerms.yes')
                  : t('wholesaleOrderTerms.no')}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={renderLabel(
                'wholesaleOrderTerms.daysUntilDiscountApplied',
                'wholesaleOrderTerms.daysUntilDiscountAppliedTooltip'
              )}
            >
              {salesChannelRegionDeal.daysUntilDiscountApplied}
            </Descriptions.Item>
            <Descriptions.Item
              label={renderLabel(
                'wholesaleOrderTerms.extendDeadlineByDays',
                'wholesaleOrderTerms.extendDeadlineByDaysTooltip'
              )}
            >
              {salesChannelRegionDeal.extendDeadlineByDays}
            </Descriptions.Item>
            <Descriptions.Item
              label={renderLabel(
                'wholesaleOrderTerms.discountRate',
                'wholesaleOrderTerms.discountRateTooltip'
              )}
            >
              {formatPercentage((salesChannelRegionDeal.discountRate ?? 0) / 100)}
            </Descriptions.Item>
            <Descriptions.Item
              label={renderLabel(
                'wholesaleOrderTerms.resaleOverdueProducts',
                'wholesaleOrderTerms.resaleOverdueProductsTooltip'
              )}
            >
              <Typography.Text>
                {salesChannelRegionDeal.resaleOverdueProducts
                  ? t('wholesaleOrderTerms.yes')
                  : t('wholesaleOrderTerms.no')}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
    </>
  )
}
