import styled from '@emotion/styled'
import { Table } from 'antd'

const FormWidget = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  flex-flow: row nowrap;
  max-height: 500px;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    flex-flow: column nowrap;
  }
`

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex-basis: 50%;
  max-width: 50%;
`

const SelectedItemsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-basis: 50%;
  max-width: 50%;

  @media only screen and (max-width: 768px) {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
`

const ScrollableList = styled.div`
  flex: 1;
  padding-bottom: 0.5rem;
  overflow-y: auto;
`

const ListTitle = styled.div`
  height: 48px;
  padding: 12px;
`

const RequiredFieldIndicator = styled.span`
  color: red;
`

const TableWithClickableRows = styled(Table)`
  tbody tr {
    cursor: pointer;
  }
`

const SearchBarContainer = styled.div`
  padding: 0.5rem;
`

export {
  FormWidget,
  ListTitle,
  RequiredFieldIndicator,
  ScrollableList,
  SearchBarContainer,
  SelectContainer,
  SelectedItemsContainer,
  TableWithClickableRows,
}
