import { Flex, Typography } from 'antd'
import { GetProductVariantQuery } from '../../../../../generated/graphql'
import { EditSmallestSalesUnitModal } from './EditSmallestSalesUnit'

type ProductVariantSmallestSalesUnitFieldProps = {
  productVariant: GetProductVariantQuery['productVariant']
  isEditable?: boolean
}
export const ProductVariantSmallestSalesUnitField = ({
  productVariant,
  isEditable,
}: ProductVariantSmallestSalesUnitFieldProps) => {
  return (
    <Flex justify="space-between">
      <Typography.Text>{productVariant.smallestSalesUnit ?? '-'}</Typography.Text>
      {isEditable && (
        <EditSmallestSalesUnitModal
          uuid={productVariant.uuid}
          smallestSalesUnit={productVariant.smallestSalesUnit}
        />
      )}
    </Flex>
  )
}
