import {
  SupportTicketPriority,
  SupportTicketStatus,
  SupportTicketType,
} from '../../../generated/graphql'
import {
  LanguageOptions,
  TicketCategory,
  TicketCustomFieldId,
  TicketMainCategory,
} from '../ticket.enum'
import { ZendeskMessageOptions } from '../ticket.interface'

type CreateZendeskTicketOptionsParams = {
  comment: string
  categoryTitle: string
  mainCategory: TicketMainCategory
  ticketCategory: TicketCategory
  language: string
  subject: string
  sellerId: string | undefined
  isPublic?: boolean
  type?: SupportTicketType
  priority?: SupportTicketPriority
  status?: SupportTicketStatus
  tags?: string[]
  personalNotes?: string
  attachments?: string[]
}

export const createZendeskTicketOptions = ({
  sellerId,
  comment,
  categoryTitle,
  mainCategory,
  ticketCategory,
  language,
  subject,
  isPublic = false,
  type = SupportTicketType.TASK,
  priority = SupportTicketPriority.NORMAL,
  status = SupportTicketStatus.NEW,
  tags = [],
  personalNotes = '',
  attachments = [],
}: CreateZendeskTicketOptionsParams): ZendeskMessageOptions => {
  sellerId = sellerId ? sellerId : ''
  return {
    comment: comment,
    tags: [
      categoryTitle,
      mainCategory,
      ticketCategory,
      `${categoryTitle}_${ticketCategory}`,
      language,
      ...tags,
    ],
    subject: subject,
    type,
    priority,
    status,
    customFields: {
      [TicketCustomFieldId.MAIN_CATEGORY]: mainCategory,
      [TicketCustomFieldId.TICKET_CATEGORY]: ticketCategory,
      [TicketCustomFieldId.SELLER_ID]: sellerId,
      [TicketCustomFieldId.PUBLIC]: isPublic,
      [TicketCustomFieldId.LANGUAGE]:
        language === 'de' ? LanguageOptions.GERMAN : LanguageOptions.ENGLISH,
      [TicketCustomFieldId.PERSONAL_NOTES]: personalNotes,
    },
    sellerId,
    attachments,
  }
}
