import { useApolloClient, useMutation } from '@apollo/client'
import { Alert, Upload as AntUpload, Button, List, Modal, Result, Space } from 'antd'
import { RcFile } from 'antd/es/upload'
import { Upload } from 'lucide-react'
import { nanoid } from 'nanoid'
import Papa from 'papaparse'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { z } from 'zod'
import {
  CreateProductsDocument,
  GetAllProductsDocument,
  GtinType,
  Marketplace,
  ProductVariantPackagingUnitType,
  TaxCode,
} from '../../../generated/graphql'
import { useCountryOptions } from '../../../hooks/useCountryOptions'
import { useMarketplaceOptions } from '../../../hooks/useMarketplaceOptions'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { asinRegExp, taricRegExp } from '../../../utils/regExp'

const BATCH_SIZE = 10

const marketplaceByRrpField = {
  'RRP DE': Marketplace.DE,
  'RRP FR': Marketplace.FR,
  'RRP ES': Marketplace.ES,
  'RRP IT': Marketplace.IT,
  'RRP NL': Marketplace.NL,
  'RRP UK': Marketplace.UK,
  'RRP SE': Marketplace.SE,
  'RRP PL': Marketplace.PL,
  'RRP BE': Marketplace.BE,
  'RRP US': Marketplace.US,
  'RRP CA': Marketplace.CA,
  'RRP MX': Marketplace.MX,
} as const

type GroupedProductImportData = {
  products: Record<string, ProductImportData>
  lastProductName: string | undefined
}

type ProductImportData = {
  name: string
  brand: string
  manufacturer: string
  countryOfOrigin: string
  taricCode: string
  taxCategory: string
  productCategory: string
  variants: {
    name: string | undefined
    gtin: string
    gtinType: GtinType
    internalSku: string | undefined
    netPurchasePrice: number | undefined
    smallestSalesUnit: number
    package: {
      length: number
      width: number
      height: number
      weight: number
    }
    parcel?: {
      gtin: string | undefined
      gtinType: GtinType | undefined
      internalSku: string | undefined
      quantityInPackage: number
      dimensions: {
        length: number | undefined
        width: number | undefined
        height: number | undefined
        weight: number | undefined
      }
    }
    pallet?: {
      gtin: string | undefined
      gtinType: GtinType | undefined
      internalSku: string | undefined
      quantityPerPallet: number
      dimensions: {
        length: number | undefined
        width: number | undefined
        height: number | undefined
        weight: number | undefined
      }
    }
    amazonProducts?: AmazonProductImportData[]
  }[]
}

type AmazonProductImportData = {
  asin: string | undefined
  amazonListings: AmazonListingImportData[]
}

type AmazonListingImportData = {
  sku: string
  recommendedRetailPrice: number
  marketplace: Marketplace
}

type ValidationError = {
  rowIndex: number | undefined
  field: string | undefined
  message: string
}

type ProductsImportModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const ProductsImportModalModal = ({ open, setOpen }: ProductsImportModalProps) => {
  const [products, setProducts] = useState<ProductImportData[]>([])
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [submitting, setSubmitting] = useState(false)

  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const client = useApolloClient()
  const { t } = useTranslation('inventory')

  const [createProducts] = useMutation(CreateProductsDocument)

  const productsImportRowSchema = useProductsImportRowSchema()

  const handleUpload = (file: RcFile) => {
    const fileReader = new FileReader()

    fileReader.onload = (event) => {
      try {
        const file = event.target?.result

        if (typeof file !== 'string') {
          throw new Error('File could not be read.')
        }

        // Remove the first and third row of the file.
        const cleanedFile = file
          .split('\n')
          .filter((_, index) => index !== 0 && index !== 2)
          .join('\n')

        Papa.parse<z.infer<typeof productsImportRowSchema>>(cleanedFile, {
          header: true,
          skipEmptyLines: 'greedy',
          transform: (value, field) => {
            const trimmedValue = value.trim()

            if (trimmedValue === '') {
              return undefined
            }

            if (typeof field === 'string') {
              if (
                [
                  'Net purchase price',
                  'Smalles sales unit',
                  'Package length (cm)',
                  'Package width (cm)',
                  'Package height (cm)',
                  'Package weight (g)',
                  'Quantity in package (Parcel)',
                  'Package length (cm) (Parcel)',
                  'Package width (cm) (Parcel)',
                  'Package height (cm) (Parcel)',
                  'Package weight (g) (Parcel)',
                  'Quantity per pallet (Pallet)',
                  'Package length (cm) (Pallet)',
                  'Package width (cm) (Pallet)',
                  'Package height (cm) (Pallet)',
                  'Package weight (g) (Pallet)',
                  'RRP DE',
                  'RRP FR',
                  'RRP ES',
                  'RRP IT',
                  'RRP NL',
                  'RRP UK',
                  'RRP SE',
                  'RRP PL',
                  'RRP BE',
                  'RRP US',
                  'RRP CA',
                  'RRP MX',
                ].includes(field)
              ) {
                if (/^-?\d+\.\d+$/.test(trimmedValue)) {
                  return Number(trimmedValue)
                }

                if (/^-?\d+,\d+$/.test(trimmedValue)) {
                  return Number(trimmedValue.replace(',', '.'))
                }

                const hasEnglishFormat = /^\d{1,3}(?:,\d{3})*(?:\.\d+)?$/.test(trimmedValue)
                const hasGermanFormat = /^\d{1,3}(?:\.\d{3})*(?:,\d+)?$/.test(trimmedValue)

                if (hasEnglishFormat) {
                  return Number(trimmedValue.replace(/,/g, ''))
                }

                if (hasGermanFormat) {
                  return Number(trimmedValue.replace(/\./g, '').replace(',', '.'))
                }

                return Number(trimmedValue)
              }
            }

            return trimmedValue
          },
          complete: (results) => {
            const data = results.data

            const parsedRows = productsImportRowSchema.parse(data)

            const groupedProducts = parsedRows.reduce<GroupedProductImportData>(
              (accumulator, row) => {
                const productName = row['Product parent name'] || accumulator.lastProductName || ''

                if (!accumulator.products[productName]) {
                  accumulator.products[productName] = {
                    name: productName,
                    brand: row.Brand,
                    manufacturer: row.Manufacturer,
                    countryOfOrigin: row['Country of origin'],
                    taricCode: row['TARIC code'],
                    taxCategory: row['Tax category'],
                    productCategory: row['Product category'],
                    variants: [],
                  }
                }

                const variant: GroupedProductImportData['products'][string]['variants'][number] = {
                  name: row['Variant name'],
                  gtin: row.GTIN,
                  gtinType: row['GTIN Type'],
                  internalSku: row['Internal SKU'] || undefined,
                  netPurchasePrice: row['Net purchase price'] || undefined,
                  smallestSalesUnit: +row['Smalles sales unit'],
                  package: {
                    length: +row['Package length (cm)'],
                    width: +row['Package width (cm)'],
                    height: +row['Package height (cm)'],
                    weight: +row['Package weight (g)'],
                  },
                }

                if (row['Quantity in package (Parcel)']) {
                  variant.parcel = {
                    gtin: row['GTIN (Parcel)'] || undefined,
                    gtinType: row['GTIN Type (Parcel)'] || undefined,
                    internalSku: row['Internal SKU (Parcel)'] || undefined,
                    quantityInPackage: +row['Quantity in package (Parcel)'],
                    dimensions: {
                      length: row['Package length (cm) (Parcel)']
                        ? +row['Package length (cm) (Parcel)']
                        : undefined,
                      width: row['Package width (cm) (Parcel)']
                        ? +row['Package width (cm) (Parcel)']
                        : undefined,
                      height: row['Package height (cm) (Parcel)']
                        ? +row['Package height (cm) (Parcel)']
                        : undefined,
                      weight: row['Package weight (g) (Parcel)']
                        ? +row['Package weight (g) (Parcel)']
                        : undefined,
                    },
                  }
                }

                if (row['Quantity per pallet (Pallet)']) {
                  variant.pallet = {
                    gtin: row['GTIN (Pallet)'] || undefined,
                    gtinType: row['GTIN Type (Pallet)'] || undefined,
                    internalSku: row['Internal SKU (Pallet)'] || undefined,
                    quantityPerPallet: +row['Quantity per pallet (Pallet)'],
                    dimensions: {
                      length: row['Package length (cm) (Pallet)']
                        ? +row['Package length (cm) (Pallet)']
                        : undefined,
                      width: row['Package width (cm) (Pallet)']
                        ? +row['Package width (cm) (Pallet)']
                        : undefined,
                      height: row['Package height (cm) (Pallet)']
                        ? +row['Package height (cm) (Pallet)']
                        : undefined,
                      weight: row['Package weight (g) (Pallet)']
                        ? +row['Package weight (g) (Pallet)']
                        : undefined,
                    },
                  }
                }

                if (row['ASIN']) {
                  const amazonListings: AmazonListingImportData[] = []

                  if (row['SKU']) {
                    const marketplaces = Object.entries(marketplaceByRrpField)
                      .filter(([field]) => row[field as keyof typeof marketplaceByRrpField])
                      .map(([, marketplace]) => marketplace)

                    for (const marketplace of marketplaces) {
                      const recommendedRetailPrice =
                        row[`RRP ${marketplace}` as keyof typeof marketplaceByRrpField]

                      if (!recommendedRetailPrice) {
                        continue
                      }

                      let sku = row['SKU']

                      if (marketplace === Marketplace.UK) {
                        sku = sku + 'UK'
                      }

                      if (marketplace === Marketplace.US) {
                        sku = sku + 'US'
                      }

                      amazonListings.push({ sku, recommendedRetailPrice, marketplace })
                    }
                  }

                  variant.amazonProducts = [{ asin: row['ASIN'], amazonListings }]
                }

                accumulator.products[productName].variants.push(variant)
                accumulator.lastProductName = productName

                return accumulator
              },
              {
                products: {},
                lastProductName: undefined,
              }
            )

            const products = Object.values(groupedProducts.products)

            setProducts(products)
            setValidationErrors([])
          },
        })
      } catch (error) {
        console.error(error)
        toast.error(t('product.bulk.import.error.toast.message'))

        if (error instanceof z.ZodError) {
          setValidationErrors(formatZodError(error))
          setProducts([])
        }
      }
    }

    fileReader.readAsText(file)

    return false
  }

  const handleImport = async () => {
    try {
      setSubmitting(true)
      const batches: ProductImportData[][] = []

      for (let i = 0; i < products.length; i += BATCH_SIZE) {
        batches.push(products.slice(i, i + BATCH_SIZE))
      }

      for (const batch of batches) {
        await createProducts({
          variables: {
            companyUuid: selectedCompany.uuid,
            products: batch.map((product) => ({
              name: product.name,
              brand: product.brand,
              manufacturer: product.manufacturer,
              countryOfOrigin: product.countryOfOrigin,
              taric: product.taricCode,
              taxCategory: product.taxCategory,
              salesChannelCategory: product.productCategory,
              salesCountries: selectedCompany.marketplaces,
              variants: product.variants.map((variant) => ({
                variantName: variant.name,
                ean: variant.gtin,
                internalSku: variant.internalSku,
                netPurchasePrice: variant.netPurchasePrice,
                packageLength: variant.package.length,
                packageWidth: variant.package.width,
                packageHeight: variant.package.height,
                packageWeight: variant.package.weight,
                smallestSalesUnit: variant.smallestSalesUnit,
                packagingUnits: [
                  {
                    type: ProductVariantPackagingUnitType.SMALL_PARCEL,
                    gtin: variant.gtin,
                    gtinType: variant.gtinType,
                    internalSku: variant.internalSku,
                    quantity: 1,
                    lengthInCm: variant.package.length,
                    widthInCm: variant.package.width,
                    heightInCm: variant.package.height,
                    weightInGram: variant.package.weight,
                  },
                  ...(variant.parcel
                    ? [
                        {
                          type: ProductVariantPackagingUnitType.SMALL_PARCEL,
                          gtin: variant.parcel.gtin,
                          gtinType: variant.parcel.gtinType,
                          internalSku: variant.parcel.internalSku,
                          quantity: variant.parcel.quantityInPackage,
                          lengthInCm: variant.parcel.dimensions.length,
                          widthInCm: variant.parcel.dimensions.width,
                          heightInCm: variant.parcel.dimensions.height,
                          weightInGram: variant.parcel.dimensions.weight,
                        },
                      ]
                    : []),
                  ...(variant.pallet
                    ? [
                        {
                          type: ProductVariantPackagingUnitType.PALLET,
                          gtin: variant.pallet.gtin,
                          gtinType: variant.pallet.gtinType,
                          internalSku: variant.pallet.internalSku,
                          quantity: variant.pallet.quantityPerPallet,
                          lengthInCm: variant.pallet.dimensions.length,
                          widthInCm: variant.pallet.dimensions.width,
                          heightInCm: variant.pallet.dimensions.height,
                          weightInGram: variant.pallet.dimensions.weight,
                        },
                      ]
                    : []),
                ],
                amazonProducts: variant.amazonProducts
                  ? variant.amazonProducts.map((amazonProduct) => ({
                      asin: amazonProduct.asin ?? `MISSING-ASIN-${nanoid(10)}`,
                      amazonAccount: 'Varento',
                      amazonListings: amazonProduct.amazonListings.map((amazonListing) => ({
                        sku: amazonListing.sku,
                        recommendedRetailPrice: amazonListing.recommendedRetailPrice,
                        marketplace: amazonListing.marketplace,
                      })),
                    }))
                  : [],
              })),
            })),
          },
        })
      }

      await client.refetchQueries({
        include: [GetAllProductsDocument],
      })

      toast.success(
        t('product.bulk.create.success.toast.message', {
          productCount: products.length,
          variantCount: products.reduce((sum, product) => sum + product.variants.length, 0),
        })
      )
      setOpen(false)
      setProducts([])
      setValidationErrors([])
    } catch (error) {
      console.error(error)
      toast.error(t('product.bulk.create.error.toast.message'))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal
      title={t('product.bulk.heading')}
      open={open}
      okText={t('shared.button.import', { ns: 'translation' })}
      okButtonProps={{
        disabled: !products.length || !!validationErrors.length,
        loading: submitting,
      }}
      onOk={handleImport}
      cancelText={t('shared.button.cancel', { ns: 'translation' })}
      onCancel={() => setOpen(false)}
      destroyOnClose
    >
      <Result
        title={t('product.bulk.title')}
        icon={<Upload size={16} />}
        extra={
          <Space direction="vertical">
            <AntUpload.Dragger
              accept=".csv"
              beforeUpload={handleUpload}
              customRequest={customRequest}
              maxCount={1}
              showUploadList={false}
            >
              {t('product.bulk.import.hint')}
            </AntUpload.Dragger>
            <Button
              type="link"
              href="https://docs.google.com/spreadsheets/d/1ScRT6nPqAWvJ1P1xnW4H2Dq4_ilG3-jAybP1iOjbidE?gid=54613214"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('product.bulk.import.template')}
            </Button>
          </Space>
        }
      />
      {validationErrors.length ? (
        <Alert
          type="error"
          message={t('product.bulk.import.error.alert.message', {
            count: validationErrors.length,
          })}
          description={
            <List
              dataSource={validationErrors}
              renderItem={(item) => (
                <List.Item style={{ paddingLeft: 0 }}>
                  <List.Item.Meta
                    title={`Row ${item.rowIndex}: ${item.field}`}
                    description={item.message}
                  />
                </List.Item>
              )}
              size="small"
              split={false}
              style={{ maxHeight: '320px', overflow: 'auto' }}
            />
          }
          showIcon
        />
      ) : products.length ? (
        <Alert
          type="success"
          message={t('product.bulk.import.success.alert.message')}
          description={t('product.bulk.import.success.alert.description', {
            productCount: products.length,
            variantCount: products.reduce((sum, product) => sum + product.variants.length, 0),
          })}
          showIcon
        />
      ) : null}
    </Modal>
  )
}

function useProductsImportRowSchema() {
  const countryOptions = useCountryOptions()
  const countries = countryOptions.map((country) => country.code)

  const marketplaceOptions = useMarketplaceOptions()
  const marketplaces = marketplaceOptions.map((marketplace) => marketplace)

  return z
    .array(
      z
        .object({
          GTIN: z.string(),
          'GTIN Type': z.nativeEnum(GtinType),
          'Internal SKU': z.string().optional(),
          'Product parent name': z.string().max(255).optional(),
          'Variant name': z.string().optional(),
          Brand: z.string(),
          Manufacturer: z.string(),
          'Country of origin': z.string().refine((country) => countries.includes(country), {
            message: 'Country of origin must be a valid ISO 3166-1 alpha-2 country code.',
          }),
          'TARIC code': z.string().regex(taricRegExp, {
            message: 'TARIC code must be 11 digits long.',
          }),
          'Tax category': z.nativeEnum(TaxCode),
          'Product category': z.string(),
          'Net purchase price': z.coerce.number().optional(),
          'Smalles sales unit': z.coerce.number().positive(),
          'Package length (cm)': z.coerce.number().positive(),
          'Package width (cm)': z.coerce.number().positive(),
          'Package height (cm)': z.coerce.number().positive(),
          'Package weight (g)': z.coerce.number().positive(),
          'GTIN (Parcel)': z.string().optional(),
          'GTIN Type (Parcel)': z.nativeEnum(GtinType).optional(),
          'Internal SKU (Parcel)': z.string().optional(),
          'Quantity in package (Parcel)': z.coerce.number().optional(),
          'Package length (cm) (Parcel)': z.coerce.number().optional(),
          'Package width (cm) (Parcel)': z.coerce.number().optional(),
          'Package height (cm) (Parcel)': z.coerce.number().optional(),
          'Package weight (g) (Parcel)': z.coerce.number().optional(),
          'GTIN (Pallet)': z.string().optional(),
          'GTIN Type (Pallet)': z.nativeEnum(GtinType).optional(),
          'Internal SKU (Pallet)': z.string().optional(),
          'Quantity per pallet (Pallet)': z.coerce.number().optional(),
          'Package length (cm) (Pallet)': z.coerce.number().optional(),
          'Package width (cm) (Pallet)': z.coerce.number().optional(),
          'Package height (cm) (Pallet)': z.coerce.number().optional(),
          'Package weight (g) (Pallet)': z.coerce.number().optional(),
          ASIN: z
            .string()
            .regex(asinRegExp, {
              message: 'ASIN must be 10 characters long.',
            })
            .optional(),
          SKU: z.string().optional(),
          'RRP DE': z.coerce.number().optional(),
          'RRP FR': z.coerce.number().optional(),
          'RRP ES': z.coerce.number().optional(),
          'RRP IT': z.coerce.number().optional(),
          'RRP NL': z.coerce.number().optional(),
          'RRP UK': z.coerce.number().optional(),
          'RRP SE': z.coerce.number().optional(),
          'RRP PL': z.coerce.number().optional(),
          'RRP BE': z.coerce.number().optional(),
          'RRP US': z.coerce.number().optional(),
          'RRP CA': z.coerce.number().optional(),
          'RRP MX': z.coerce.number().optional(),
        })
        .superRefine((data, ctx) => {
          // Check if any parcel packaging unit field is defined.
          const parcelFields = [
            'GTIN (Parcel)',
            'GTIN Type (Parcel)',
            'Internal SKU (Parcel)',
            'Quantity in package (Parcel)',
            'Package length (cm) (Parcel)',
            'Package width (cm) (Parcel)',
            'Package height (cm) (Parcel)',
            'Package weight (g) (Parcel)',
          ] as const

          const hasAnyParcelField = parcelFields.some((field) => data[field])

          if (hasAnyParcelField) {
            const requiredParcelFields = [
              'Quantity in package (Parcel)',
              'Package length (cm) (Parcel)',
              'Package width (cm) (Parcel)',
              'Package height (cm) (Parcel)',
              'Package weight (g) (Parcel)',
            ] as const

            for (const field of requiredParcelFields) {
              if (!data[field]) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `${field} is required when any parcel packaging unit field is defined.`,
                  path: [field],
                })
              }
            }
          }

          // Check if any pallet packaging unit field is defined.
          const palletFields = [
            'GTIN (Pallet)',
            'GTIN Type (Pallet)',
            'Internal SKU (Pallet)',
            'Quantity per pallet (Pallet)',
            'Package length (cm) (Pallet)',
            'Package width (cm) (Pallet)',
            'Package height (cm) (Pallet)',
            'Package weight (g) (Pallet)',
          ] as const

          const hasAnyPalletField = palletFields.some((field) => data[field])

          if (hasAnyPalletField) {
            const requiredPalletFields = ['Quantity per pallet (Pallet)'] as const

            for (const field of requiredPalletFields) {
              if (!data[field]) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `${field} is required when any pallet packaging unit field is defined.`,
                  path: [field],
                })
              }
            }
          }

          // Check if any recommended retail price field is defined.
          const recommendedRetailPriceFields = [
            'RRP DE',
            'RRP FR',
            'RRP ES',
            'RRP IT',
            'RRP NL',
            'RRP UK',
            'RRP SE',
            'RRP PL',
            'RRP BE',
            'RRP US',
            'RRP CA',
            'RRP MX',
          ] as const

          const hasAnyRecommendedRetailPriceField = recommendedRetailPriceFields.some(
            (field) => data[field]
          )

          if (hasAnyRecommendedRetailPriceField) {
            const requiredRecommendedRetailPriceFields = ['SKU'] as const

            for (const field of requiredRecommendedRetailPriceFields) {
              if (!data[field]) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `${field} is required when any recommended retail price field is defined.`,
                  path: [field],
                })
              }
            }

            for (const [rrpField, marketplace] of Object.entries(marketplaceByRrpField)) {
              if (
                data[rrpField as keyof typeof marketplaceByRrpField] &&
                !marketplaces.includes(marketplace)
              ) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `${rrpField} cannot be set because marketplace ${marketplace} is not available for this company.`,
                  path: [rrpField],
                })
              }
            }
          }
        })
    )
    .nonempty({
      message: 'File must contain at least one row.',
    })
    .superRefine((data, ctx) => {
      // Check for product names that are duplicates but not consecutive.
      const productNamesByIndex = new Map<string, number[]>()

      // First collect all indices for each product name.
      for (const [index, row] of data.entries()) {
        const productName = row['Product parent name'] ?? ''

        if (!productNamesByIndex.has(productName)) {
          productNamesByIndex.set(productName, [])
        }

        const indices = productNamesByIndex.get(productName)

        if (!indices) {
          continue
        }

        indices.push(index)
      }

      // Then check each product name's indices for non-consecutive duplicates.
      for (const [productName, indices] of productNamesByIndex) {
        if (indices.length > 1) {
          // Check if indices are consecutive.
          for (let i = 1; i < indices.length; i++) {
            const currentIndex = indices[i]
            const previousIndex = indices[i - 1]

            if (!currentIndex || !previousIndex) {
              continue
            }

            if (currentIndex !== previousIndex + 1) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Product name "${productName}" can only be duplicate if it appears in consecutive rows.`,
                path: [currentIndex, 'Product parent name'],
              })
            }
          }
        }
      }

      // Check for duplicate product name + variant name combinations.
      const productVariantCombos = new Set<string>()
      let lastProductName = ''

      for (const [index, row] of data.entries()) {
        const productName = row['Product parent name'] || lastProductName
        const variantName = row['Variant name']
        const combo = `${productName}__${variantName}`

        if (productVariantCombos.has(combo)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Duplicate product name and variant name combination found: "${productName}" with variant "${variantName}"`,
            path: [index, 'Variant name'],
          })
        }

        productVariantCombos.add(combo)
        lastProductName = productName
      }

      // Check for duplicate GTINs.
      const gtins = new Set<string>()

      for (const [index, row] of data.entries()) {
        const gtin = row['GTIN']

        if (gtins.has(gtin)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Duplicate GTIN found: "${gtin}"`,
            path: [index, 'GTIN'],
          })
        }

        gtins.add(gtin)
      }

      // Check for duplicate internal SKUs.
      const internalSkus = new Set<string>()

      for (const [index, row] of data.entries()) {
        const internalSku = row['Internal SKU']

        if (internalSku && internalSkus.has(internalSku)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Duplicate Internal SKU found: "${internalSku}"`,
            path: [index, 'Internal SKU'],
          })
        }

        if (internalSku) {
          internalSkus.add(internalSku)
        }
      }

      // Check for duplicate ASINs.
      const asins = new Set<string>()

      for (const [index, row] of data.entries()) {
        const asin = row['ASIN']

        if (asin && asins.has(asin)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Duplicate ASIN found: "${asin}"`,
            path: [index, 'ASIN'],
          })
        }

        if (asin) {
          asins.add(asin)
        }
      }

      // Check for duplicate SKUs.
      const skus = new Set<string>()

      for (const [index, row] of data.entries()) {
        const sku = row['SKU']

        if (sku && skus.has(sku)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Duplicate SKU found: "${sku}"`,
            path: [index, 'SKU'],
          })
        }

        if (sku) {
          skus.add(sku)
        }
      }
    })
}

function formatZodError(error?: z.ZodError) {
  if (!error) {
    return []
  }

  const issues = error.issues

  return issues.map((issue) => {
    const rowIndex = issue.path[0] !== undefined ? +issue.path[0] + 1 : undefined
    const field = issue.path[1] !== undefined ? issue.path[1].toString() : undefined

    return {
      rowIndex,
      field,
      message: issue.message,
    }
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customRequest = ({ onSuccess }: any) => {
  setTimeout(() => onSuccess('ok'), 0)
}
