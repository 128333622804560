import { TFunction } from 'i18next'
import { GetInventoryReportQuery } from '../../../generated/graphql'
import { InventoryReportData } from '../interfaces'

export function prepareInventoryDataReport(
  t: TFunction<['report', 'translation']>,
  data?: GetInventoryReportQuery
): InventoryReportData[] {
  if (!data) {
    return []
  }

  const tableData = data.products.flatMap((product) => {
    const amazonProducts =
      product.productVariants?.flatMap((productVariant) => {
        return productVariant.amazonProducts ?? []
      }) ?? []

    return amazonProducts.flatMap((amazonProduct) => {
      if (!amazonProduct.amazonListings) {
        return []
      }

      return amazonProduct.amazonListings.flatMap((amazonListing) => {
        if (!amazonListing) {
          return []
        }

        return {
          asin: amazonProduct.asin,
          sku: amazonListing.sku,
          name: product.name,
          status: `${t(`inventory.statuses.${amazonListing.internalStatus}`)}`,
          marketplace: amazonListing.marketplace.name,
          salesShippedLast7Days: amazonListing.inventoryHealth?.salesShippedLast7Days ?? null,
          salesShippedLast30Days: amazonListing.inventoryHealth?.salesShippedLast30Days ?? null,
          salesShippedLast60Days: amazonListing.inventoryHealth?.salesShippedLast60Days ?? null,
          salesShippedLast90Days: amazonListing.inventoryHealth?.salesShippedLast90Days ?? null,
          unitsShippedLast7Days: amazonListing.inventoryHealth?.unitsShippedLast7Days ?? null,
          unitsShippedLast30Days: amazonListing.inventoryHealth?.unitsShippedLast30Days ?? null,
          unitsShippedLast60Days: amazonListing.inventoryHealth?.unitsShippedLast60Days ?? null,
          unitsShippedLast90Days: amazonListing.inventoryHealth?.unitsShippedLast90Days ?? null,
          sellThrough: amazonListing.inventoryHealth?.sellThrough ?? null,
          daysOfSupply: amazonListing.inventoryHealth?.daysOfSupply ?? null,
          healthyInventoryLevel: amazonListing.inventoryHealth?.healthyInventoryLevel ?? null,
          recommendedRemovalQuantity:
            amazonListing.inventoryHealth?.recommendedRemovalQuantity ?? null,
          pendingRemovalQuantity: amazonListing.inventoryHealth?.pendingRemovalQuantity ?? null,
          estimatedExcessQuantity: amazonListing.inventoryHealth?.estimatedExcessQuantity ?? null,
          weeksOfCoverLast30Days: amazonListing.inventoryHealth?.weeksOfCoverLast30Days ?? null,
          weeksOfCoverLast90Days: amazonListing.inventoryHealth?.weeksOfCoverLast90Days ?? null,
          inventoryAge0To90: amazonListing.inventoryHealth?.inventoryAge0To90 ?? null,
          inventoryAge91To180: amazonListing.inventoryHealth?.inventoryAge91To180 ?? null,
          inventoryAge181To330: amazonListing.inventoryHealth?.inventoryAge181To330 ?? null,
          inventoryAge331To365: amazonListing.inventoryHealth?.inventoryAge331To365 ?? null,
          inventoryAge365Plus: amazonListing.inventoryHealth?.inventoryAge365Plus ?? null,
          ltsfQuantity11Months: amazonListing.inventoryHealth?.ltsfQuantity11Months ?? null,
          ltsfEstimated11Months: amazonListing.inventoryHealth?.ltsfEstimated11Months ?? null,
          ltsfQuantity12Months: amazonListing.inventoryHealth?.ltsfQuantity12Months ?? null,
          ltsfEstimated12Months: amazonListing.inventoryHealth?.ltsfEstimated12Months ?? null,
          estimatedStorageCostNextMonth:
            amazonListing.inventoryHealth?.estimatedStorageCostNextMonth ?? null,
          storageVolume: amazonListing.inventoryHealth?.storageVolume ?? null,
          storageVolumeMeasurement: amazonListing.inventoryHealth?.storageVolumeMeasurement ?? null,
          storageType: amazonListing.inventoryHealth?.storageType ?? null,
          fulfillableQuantity: amazonListing.inventory?.fulfillable?.fulfillableQuantity ?? null,
          inboundWorkingQuantity: amazonListing.inventory?.inbound?.inboundWorkingQuantity ?? null,
          inboundShippedQuantity: amazonListing.inventory?.inbound?.inboundShippedQuantity ?? null,
          inboundReceivingQuantity:
            amazonListing.inventory?.inbound?.inboundReceivingQuantity ?? null,
          pendingCustomerOrderQuantity:
            amazonListing.inventory?.reserved?.pendingCustomerOrderQuantity ?? null,
          pendingTransshipmentQuantity:
            amazonListing.inventory?.reserved?.pendingTransshipmentQuantity ?? null,
          fcProcessingQuantity: amazonListing.inventory?.reserved?.fcProcessingQuantity ?? null,
          customerDamagedQuantity:
            amazonListing.inventory?.unfulfillable?.customerDamagedQuantity ?? null,
          warehouseDamagedQuantity:
            amazonListing.inventory?.unfulfillable?.warehouseDamagedQuantity ?? null,
          distributorDamagedQuantity:
            amazonListing.inventory?.unfulfillable?.distributorDamagedQuantity ?? null,
          carrierDamagedQuantity:
            amazonListing.inventory?.unfulfillable?.carrierDamagedQuantity ?? null,
          defectiveQuantity: amazonListing.inventory?.unfulfillable?.defectiveQuantity ?? null,
          expiredQuantity: amazonListing.inventory?.unfulfillable?.expiredQuantity ?? null,
          researchingQuantity: amazonListing.inventory?.researching?.researchingQuantity ?? null,
        }
      })
    })
  })

  return tableData
}
