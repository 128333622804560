import { useQuery } from '@apollo/client'
import { Segmented } from 'antd'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { GetInvoiceArticlesDocument, GetInvoiceArticlesQuery } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { InvoiceArticleRecord, InvoiceArticlesTable } from './InvoiceArticlesTable'
import { InvoiceArticleCategoriesModal } from './components/InvoiceArticleCategoriesModal'

const InvoiceArticlesViewTabs = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
}

export const InvoiceArticlesView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { t } = useTranslation(['invoices', 'translation'])
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')

  const { error, data, loading } = useQuery(GetInvoiceArticlesDocument, {
    errorPolicy: 'none',
    skip: !selectedCompany.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
    },
  })

  if (!tab || !Object.values(InvoiceArticlesViewTabs).includes(tab)) {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('tab', InvoiceArticlesViewTabs.ACTIVE)
    return <Navigate to={`?${searchParams}`} replace />
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult status="500" title="500" subTitle={t('translation:shared.error.message')} />
    )
  }

  const invoiceArticles = prepareInvoiceArticles(data)
  const activeInvoiceArticles = invoiceArticles.filter((article) => article.isActive)
  const archivedInvoiceArticles = invoiceArticles.filter((article) => !article.isActive)

  return (
    <>
      <SubHeader
        heading={t('invoices:articles.heading')}
        rightContent={<InvoiceArticleCategoriesModal />}
        beta
      />
      <ViewContainer>
        <Segmented
          value={tab}
          onChange={(value) => navigate(`/invoices/articles?tab=${value}`)}
          options={[
            {
              label: t('invoices:articles.status.active'),
              value: InvoiceArticlesViewTabs.ACTIVE,
            },
            {
              label: t('invoices:articles.status.archived'),
              value: InvoiceArticlesViewTabs.ARCHIVED,
            },
          ]}
        />

        <InvoiceArticlesTable
          invoiceArticles={
            tab === InvoiceArticlesViewTabs.ACTIVE ? activeInvoiceArticles : archivedInvoiceArticles
          }
        />
      </ViewContainer>
    </>
  )
}

export const prepareInvoiceArticles = (
  data: GetInvoiceArticlesQuery | undefined
): InvoiceArticleRecord[] => {
  if (!data) {
    return []
  }

  return data.invoiceArticles.map((invoiceArticle) => {
    return {
      uuid: invoiceArticle.uuid,
      name: invoiceArticle.name,
      description: invoiceArticle.description ?? '',
      category: invoiceArticle.invoiceArticleCategory?.name,
      type: invoiceArticle.type,
      netAmount: invoiceArticle.netAmount,
      currency: invoiceArticle.currency,
      commissionInPercent: invoiceArticle.commissionInPercent,
      isActive: invoiceArticle.isActive,
      discounts: invoiceArticle.quantityDiscounts
        ? invoiceArticle.quantityDiscounts.map((discount) => ({
            uuid: discount.uuid,
            quantity: discount.quantity,
            discountInPercent: discount.discountInPercent,
            createdAt: discount.createdAt,
            updatedAt: discount.updatedAt,
          }))
        : [],
      createdAt: invoiceArticle.createdAt,
      updatedAt: invoiceArticle.updatedAt,
    }
  })
}
