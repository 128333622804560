import dayjs from 'dayjs'

export const bankTransactionTableFilters = {
  date: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    if (!cellValue) return -1

    const dayJsFilterValue = dayjs(filterLocalDateAtMidnight)
    const dayJsCellValue = dayjs(cellValue, 'DD.MM.YYYY')
    if (dayJsCellValue < dayJsFilterValue) {
      return -1
    } else if (dayJsCellValue > dayJsFilterValue) {
      return 1
    }
    return 0
  },
}
