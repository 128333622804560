import { CognitoUser } from 'amazon-cognito-identity-js'
import { create } from 'zustand'
import { AuthenticatedUser, UserCompany } from '../hooks/useAuthentication'

export enum AuthStatus {
  PENDING = 'PENDING',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export type UserRole = 'company:admin' | 'company:user'

type GlobalState = {
  authStatus: AuthStatus
  cognitoUser: CognitoUser | null
  user: AuthenticatedUser | null
  selectedCompany: UserCompany | null
  selectedCompanyUuid: string | null
}

type GlobalStateActions = {
  setAuthStatus: (authStatus: AuthStatus) => void
  setCognitoUser: (cognitoUser: CognitoUser | null) => void
  setUser: (user: AuthenticatedUser | null) => void
  setSelectedCompany: (selectedCompany: UserCompany | null) => void
  setSelectedCompanyUuid: (selectedCompanyUuid: string) => void
}

const initialState: GlobalState = {
  authStatus: AuthStatus.PENDING,
  cognitoUser: null,
  user: null,
  selectedCompany: null,
  selectedCompanyUuid: localStorage.getItem('galaxy-selected-company-uuid'),
}

export const useGlobalStore = create<GlobalState & GlobalStateActions>()((set) => ({
  ...initialState,
  setAuthStatus: (authStatus) => set({ authStatus }),
  setCognitoUser: (cognitoUser) => set({ cognitoUser }),
  setUser: (user) => set({ user }),
  setSelectedCompany: (selectedCompany) => set({ selectedCompany }),
  setSelectedCompanyUuid: (selectedCompanyUuid) => {
    localStorage.setItem('galaxy-selected-company-uuid', selectedCompanyUuid)
    set({ selectedCompanyUuid })
  },
}))
