import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { WholesaleOrderBillingDocumentType } from '../../../generated/graphql'

type WholesaleOrderBillingDocumentTypeTagProps = {
  type: WholesaleOrderBillingDocumentType
}

export const WholesaleOrderBillingDocumentTypeTag = ({
  type,
}: WholesaleOrderBillingDocumentTypeTagProps) => {
  const { t } = useTranslation('wholesale-order-billing-document')

  switch (type) {
    case WholesaleOrderBillingDocumentType.INVOICE:
      return (
        <Tag color="error" style={{ margin: 0 }}>
          {t('shared.type.invoice')}
        </Tag>
      )
    case WholesaleOrderBillingDocumentType.CREDIT_NOTE:
      return (
        <Tag color="success" style={{ margin: 0 }}>
          {t('shared.type.creditNote')}
        </Tag>
      )
    case WholesaleOrderBillingDocumentType.INVOICE_CORRECTION:
      return (
        <Tag color="warning" style={{ margin: 0 }}>
          {t('shared.type.invoiceCorrection')}
        </Tag>
      )
    default:
      return null
  }
}
