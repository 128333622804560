import { Collapse, Divider, Flex, Modal, Space, Typography } from 'antd'
import { Dayjs } from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flag } from '../../../../components/Flag'
import { formatMoney } from '../../../../helpers/formatMoney'
import { AmazonMarketplaceDomain } from '../../../../utils'
import { DashboardFinanceProductTableRecord } from './DashboardFinanceProductTable'
import styled from '@emotion/styled'

type DashboardFinanceProductTableDetailsModalProps = {
  data: DashboardFinanceProductTableRecord
  dateRange: [Dayjs, Dayjs]
}

const FinanceRow = ({
  label,
  value,
  currency = 'EUR',
}: {
  label: string
  value: number | string
  currency?: string
}) => (
  <Flex justify="space-between" gap={8}>
    <Typography.Text>{label}</Typography.Text>
    <Typography.Text>
      {typeof value === 'number' ? formatMoney(value, currency) : value}
    </Typography.Text>
  </Flex>
)

export const DashboardFinanceProductTableDetailsModal = ({
  data,
  dateRange,
}: DashboardFinanceProductTableDetailsModalProps) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('dashboard')

  const financeSummaryItems = [
    { label: t('finances.shared.sales'), value: data.sales },
    { label: t('finances.shared.shipped'), value: data.unitsSold },
    { label: t('finances.shared.refunds'), value: data.refundAmount },
    { label: t('finances.shared.promotions'), value: data.promotions },
    { label: t('finances.shared.advertising'), value: data.advertising },
  ]

  const feesItems = [
    {
      key: '1',
      label: <FinanceRow label={t('finances.shared.fees')} value={data.fees} />,
      children: (
        <Flex vertical gap={8}>
          <FinanceRow label={t('finances.shared.shipmentFees')} value={data.shipmentFees} />
          <FinanceRow label={t('finances.shared.refundFees')} value={data.refundFees} />
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Typography.Link onClick={() => setOpen(true)}>Details</Typography.Link>
      <Modal title={data.name} open={open} footer={null} onCancel={() => setOpen(false)}>
        <Flex vertical gap={32}>
          <Flex vertical gap={8}>
            {data.internalSku && <Typography.Text>{data.internalSku}</Typography.Text>}
            <Typography.Text>{data.asin}</Typography.Text>
            <Flex justify="space-between" gap={8}>
              <Typography.Text>{data.sku}</Typography.Text>
              <Typography.Text>
                <Space>
                  <Flag code={data.marketplace} />
                  <Typography.Text>{AmazonMarketplaceDomain[data.marketplace]}</Typography.Text>
                </Space>
              </Typography.Text>
            </Flex>
          </Flex>
          <Flex vertical gap={8}>
            <Flex justify="end">
              <Typography.Text>
                {dateRange[0].format('DD.MM.YYYY')} - {dateRange[1].format('DD.MM.YYYY')}
              </Typography.Text>
            </Flex>
            {financeSummaryItems.map((item, index) => (
              <FinanceRow key={index} label={item.label} value={item.value} />
            ))}
            <StyledFinanceSummaryCollapse ghost items={feesItems} />
            <Divider style={{ margin: 0 }} />
            <FinanceRow label={t('finances.shared.amazonProfit')} value={data.profit} />
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}

const StyledFinanceSummaryCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    margin-left: 1.5rem;
    padding: 0.5rem 0 0 !important;
  }
`
