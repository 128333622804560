import styled from '@emotion/styled'

export const NavLinks = ({ children }: { children: React.ReactNode }) => {
  return <StyledNavLinks>{children}</StyledNavLinks>
}

const StyledNavLinks = styled.nav`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding-bottom: 0.5rem;

  a {
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    color: ${({ theme }) => theme.colors.text}aa;
    font-weight: bold;
    transition: all 0.2s ease;

    :hover {
      color: ${({ theme }) => theme.colors.text};
      background-color: ${({ theme }) => theme.colors.primary}20;
    }

    :focus-visible {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary};
    }

    &.active {
      position: relative;
      color: ${({ theme }) => theme.colors.primary};

      ::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.5rem;
        height: 0;
        border-bottom: 2px solid;
      }
    }
  }
`
