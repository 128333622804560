import { Descriptions } from 'antd'
import { formatMoney } from '../../../../helpers/formatMoney'
import { PaymentsQueryAmazonPpcCosts } from '../PaymentsView'

type PaymentsAdvertisingFeeDetailsProps = {
  advertisingFees: PaymentsQueryAmazonPpcCosts
}

export const PaymentsAdvertisingFeeDetails = ({
  advertisingFees,
}: PaymentsAdvertisingFeeDetailsProps) => {
  return (
    <Descriptions
      bordered
      column={1}
      contentStyle={{ width: '50%' }}
      labelStyle={{ width: '50%' }}
      items={[
        {
          label: 'Sponsored Products',
          children: formatMoney(advertisingFees.sponsoredProductsCosts * -1, 'EUR'),
        },
        {
          label: 'Sponsored Brands',
          children: formatMoney(advertisingFees.sponsoredBrandsCosts * -1, 'EUR'),
        },
        {
          label: 'Sponsored Display',
          children: formatMoney(advertisingFees.sponsoredDisplayCosts * -1, 'EUR'),
        },
      ]}
    />
  )
}
