import { RangePickerProps } from 'antd/es/date-picker'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

export function useDateRangePresets() {
  const { t } = useTranslation()

  const today = dayjs()
  const dayOfWeek = today.day()

  type Preset = {
    label: string
    value: [Dayjs, Dayjs]
    condition: boolean
  }

  const allPresets: Preset[] = [
    {
      label: t('shared.timeFrame.today'),
      value: [dayjs(), dayjs()],
      condition: true,
    },
    {
      label: t('shared.timeFrame.yesterday'),
      value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
      condition: true,
    },
    {
      label: t('shared.timeFrame.thisWeek'),
      value: [dayjs().startOf('week'), dayjs().endOf('week')],
      condition: dayOfWeek !== 1, // Show only if today is not Monday
    },
    {
      label: t('shared.timeFrame.lastWeek'),
      value: [
        dayjs().subtract(1, 'week').startOf('week'),
        dayjs().subtract(1, 'week').endOf('week'),
      ],
      condition: true,
    },
    {
      label: t('shared.timeFrame.last7Days'),
      value: [dayjs().subtract(6, 'days'), dayjs()],
      condition: true,
    },
    {
      label: t('shared.timeFrame.thisMonth'),
      value: [dayjs().startOf('month'), dayjs().endOf('month')],
      condition: today.date() !== 1, // Show only if today is not the first day of the month
    },
    {
      label: t('shared.timeFrame.lastMonth'),
      value: [
        dayjs().subtract(1, 'month').startOf('month'),
        dayjs().subtract(1, 'month').endOf('month'),
      ],
      condition: true,
    },
    {
      label: t('shared.timeFrame.last30Days'),
      value: [dayjs().subtract(29, 'days'), dayjs()],
      condition: true,
    },
    {
      label: t('shared.timeFrame.last60Days'),
      value: [dayjs().subtract(59, 'days'), dayjs()],
      condition: true,
    },
    {
      label: t('shared.timeFrame.last90Days'),
      value: [dayjs().subtract(89, 'days'), dayjs()],
      condition: true,
    },
    {
      label: t('shared.timeFrame.last180Days'),
      value: [dayjs().subtract(179, 'days'), dayjs()],
      condition: true,
    },
    {
      label: t('shared.timeFrame.last365Days'),
      value: [dayjs().subtract(364, 'days'), dayjs()],
      condition: true,
    },
  ]

  const filteredPresets = allPresets.filter((preset) => preset.condition)

  const presets: RangePickerProps['presets'] = filteredPresets.map(({ label, value }) => ({
    label,
    value,
  }))

  return presets
}
