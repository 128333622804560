import { GetAmazonListingsAndWarehousesQuery } from '../../../../generated/graphql'

export function getActiveWarehouseLocations(
  providers: GetAmazonListingsAndWarehousesQuery['warehouseServiceProviders'] | undefined
) {
  if (!providers) {
    return []
  }

  return providers.flatMap((warehouse) => {
    if (!warehouse?.locations) {
      return []
    }

    return warehouse.locations
      .filter((location) => location.active)
      .map((location) => ({
        ...location,
        warehouseName: warehouse.name,
      }))
  })
}
