import { useTheme } from '@emotion/react'
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { Space, Typography } from 'antd'
import { Dayjs } from 'dayjs'
import { ImageOff } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flag } from '../../../../components/Flag'
import { GridImage, GridImageFallback } from '../../../../components/Table/GridImage'
import { Table } from '../../../../components/Table/Table'
import { Marketplace } from '../../../../generated/graphql'
import { formatMoney } from '../../../../helpers/formatMoney'
import { formatPercentage } from '../../../../helpers/formatPercentage'
import { AmazonMarketplaceDomain } from '../../../../utils'
import { DashboardFinanceProductTableDetailsModal } from './DashboardFinanceProductTableDetailsModal'

export type DashboardFinanceProductTableRecord = {
  key: string
  image: string | null
  name: string
  internalSku: string | null
  asin: string
  sku: string
  marketplace: Marketplace
  sales: number
  unitsSold: number
  refundAmount: number
  refundQuantity: number
  refundRatio: number
  promotions: number
  fees: number
  advertising: number
  profit: number
  shipmentFees: number
  refundFees: number
  searchIdentifiers: string
}

type DashboardFinanceProductTableProps = {
  rowData?: DashboardFinanceProductTableRecord[]
  dateRange: [Dayjs, Dayjs]
}

export const DashboardFinanceProductTable = ({
  rowData,
  dateRange,
}: DashboardFinanceProductTableProps) => {
  const { t } = useTranslation('dashboard')
  const theme = useTheme()

  const [columnDefs] = useState<
    (ColDef<DashboardFinanceProductTableRecord> | ColGroupDef<DashboardFinanceProductTableRecord>)[]
  >([
    {
      filter: false,
      resizable: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressMovable: true,
      suppressNavigable: true,
      minWidth: 64,
      maxWidth: 64,
      cellRenderer: (
        params: ICellRendererParams<
          DashboardFinanceProductTableRecord,
          DashboardFinanceProductTableRecord['image']
        >
      ) => {
        if (params.node.group) {
          return null
        }

        const src = params.data?.image ?? null

        return src ? (
          <GridImage src={src} width={40} height={40} preview={false} />
        ) : (
          <GridImageFallback>
            <ImageOff />
          </GridImageFallback>
        )
      },
    },
    {
      field: 'name',
      headerName: t('finances.table.header.name'),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'internalSku',
      headerName: t('finances.table.header.internalSku'),
      enableRowGroup: true,
      cellRenderer: (
        params: ICellRendererParams<
          DashboardFinanceProductTableRecord,
          DashboardFinanceProductTableRecord['internalSku']
        >
      ) => {
        if (params.node.group) {
          return params.value
        }

        if (!params.value) {
          return t('shared.notAvailable', { ns: 'translation' })
        }

        return params.value
      },
      getQuickFilterText: (params) => {
        return params.data?.searchIdentifiers
      },
    },
    {
      field: 'asin',
      headerName: t('finances.table.header.asin'),
      enableRowGroup: true,
    },
    {
      field: 'sku',
      headerName: t('finances.table.header.sku'),
    },
    {
      colId: 'marketplace',
      headerName: t('finances.table.header.marketplace'),
      enableRowGroup: true,
      valueGetter: (
        params: ValueGetterParams<
          DashboardFinanceProductTableRecord,
          DashboardFinanceProductTableRecord['marketplace']
        >
      ) => {
        if (!params.data) {
          return null
        }

        return AmazonMarketplaceDomain[params.data.marketplace]
      },
      cellRenderer: (
        params: ICellRendererParams<
          DashboardFinanceProductTableRecord,
          DashboardFinanceProductTableRecord['marketplace']
        >
      ) => {
        if (params.node.group) {
          return params.value
        }

        const marketplace = params.data?.marketplace

        if (!marketplace) {
          return t('shared.notAvailable', { ns: 'translation' })
        }

        return (
          <Space>
            <Flag code={marketplace} />
            <Typography.Text>{AmazonMarketplaceDomain[marketplace]}</Typography.Text>
          </Space>
        )
      },
    },
    {
      field: 'sales',
      headerName: t('finances.table.header.sales'),
      type: 'currencyColumn',
      aggFunc: 'sum',
    },
    {
      field: 'unitsSold',
      headerName: t('finances.table.header.unitsSold'),
      filter: false,
      aggFunc: 'sum',
    },
    {
      field: 'refundAmount',
      headerName: t('finances.table.header.refunds'),
      type: 'currencyColumn',
      aggFunc: 'sum',
    },
    {
      field: 'refundQuantity',
      headerName: t('finances.table.header.unitsRefunded'),
      filter: false,
      aggFunc: 'sum',
    },
    {
      field: 'refundRatio',
      headerName: t('finances.table.header.refundRatio'),
      filter: false,
      useValueFormatterForExport: true,
      valueFormatter: (
        params: ValueFormatterParams<
          DashboardFinanceProductTableRecord,
          DashboardFinanceProductTableRecord['refundRatio']
        >
      ) => {
        return formatPercentage((params.value ?? 0) / 100)
      },
      cellRenderer: (
        params: ICellRendererParams<
          DashboardFinanceProductTableRecord,
          DashboardFinanceProductTableRecord['refundRatio']
        >
      ) => {
        if (params.node.group) {
          return params.value
        }

        if (params.value === null || params.value === undefined) {
          return t('shared.notAvailable', { ns: 'translation' })
        }

        const color =
          params.value * 100 >= 66
            ? theme.colors.error
            : params.value >= 33
              ? theme.colors.warning
              : undefined

        return <span style={{ color }}>{params.valueFormatted}</span>
      },
    },
    {
      field: 'fees',
      headerName: t('finances.table.header.amazonFees'),
      type: 'currencyColumn',
      aggFunc: 'sum',
    },
    {
      field: 'promotions',
      headerName: t('finances.table.header.promotions'),
      type: 'currencyColumn',
      aggFunc: 'sum',
    },
    {
      field: 'advertising',
      headerName: t('finances.table.header.advertising'),
      type: 'currencyColumn',
      aggFunc: 'sum',
    },
    {
      field: 'profit',
      headerName: t('finances.table.header.amazonProfit'),
      type: 'currencyColumn',
      initialSort: 'desc',
      aggFunc: 'sum',
    },
    {
      headerName: t('finances.table.header.details'),
      filter: false,
      sortable: false,
      suppressNavigable: true,
      cellRenderer: (params: ICellRendererParams<DashboardFinanceProductTableRecord>) => {
        if (!params.data) {
          return null
        }

        return <DashboardFinanceProductTableDetailsModal data={params.data} dateRange={dateRange} />
      },
    },
  ])

  const columnTypes = useMemo<
    Record<
      string,
      ColDef<DashboardFinanceProductTableRecord> | ColGroupDef<DashboardFinanceProductTableRecord>
    >
  >(
    () => ({
      currencyColumn: {
        filter: false,
        cellRenderer: (params: ICellRendererParams<DashboardFinanceProductTableRecord>) => {
          if (params.value === null || params.value === undefined) {
            return t('shared.notAvailable', { ns: 'translation' })
          }

          return formatMoney(params.value, 'EUR')
        },
      },
    }),
    []
  )

  return (
    <Table<DashboardFinanceProductTableRecord>
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      rowData={rowData}
      rowGroupPanelShow="always"
      rowHeight={70}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
    />
  )
}
