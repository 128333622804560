import { Form, Input } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useTranslation } from 'react-i18next'
import { CreateWholesaleOrderBillingDocumentFormValues } from '../WholesaleOrderBillingDocumentCreateForm'

export const WholesaleOrderBillingDocumentDocumentNumberInput = () => {
  const { t } = useTranslation('wholesale-order-billing-document')

  const form = useFormInstance<CreateWholesaleOrderBillingDocumentFormValues>()

  const bucketKey = useWatch<CreateWholesaleOrderBillingDocumentFormValues['bucketKey']>(
    'bucketKey',
    form
  )

  return (
    <Form.Item<CreateWholesaleOrderBillingDocumentFormValues>
      name="documentNumber"
      label={t('documentNumber.label')}
      rules={[{ required: true, message: t('documentNumber.error.required') }]}
    >
      <Input disabled={!!bucketKey} />
    </Form.Item>
  )
}
