import { Select } from 'antd'
import { useNavigate, useParams } from 'react-router'
import { AmazonListingFieldsFragment, GetAmazonListingQuery } from '../../../../generated/graphql'
import { getMarketplaceDomain } from '../../../../helpers/getMarketplaceDomain'
import { sortByMarketplace } from '../../../../helpers/sortByMarketplace'

type AmazonListingMarketplaceSelectProps = {
  amazonListing: GetAmazonListingQuery['amazonListing'] | undefined
}

export const AmazonListingMarketplaceSelect = ({
  amazonListing,
}: AmazonListingMarketplaceSelectProps) => {
  const params = useParams<{
    productUuid: string
    productVariantUuid: string
    amazonListingUuid: string
  }>()
  const navigate = useNavigate()

  const amazonListings = amazonListing?.amazonProduct.amazonListings ?? []
  const sortedAmazonListings = [...amazonListings].sort((a, b) =>
    sortByMarketplace(a.marketplace.name, b.marketplace.name)
  )

  const options = sortedAmazonListings.reduce<
    { label: string; options: { label: string; value: string }[] }[]
  >((previousValue, currentValue) => {
    const existingSku = previousValue.find((option) => option.label === currentValue.sku)

    if (existingSku) {
      existingSku.options.push({
        label: `Amazon.${getMarketplaceDomain(currentValue.marketplace.name)}`,
        value: currentValue.uuid,
      })
    } else {
      previousValue.push({
        label: currentValue.sku,
        options: [
          {
            label: `Amazon.${getMarketplaceDomain(currentValue.marketplace.name)}`,
            value: currentValue.uuid,
          },
        ],
      })
    }

    return previousValue
  }, [])

  const handleAmazonListingChange = (uuid: AmazonListingFieldsFragment['uuid']) => {
    navigate(`/products/${params.productUuid}/variants/${params.productVariantUuid}/amazon/${uuid}`)
  }

  return (
    <Select<AmazonListingFieldsFragment['uuid']>
      options={options}
      value={amazonListing?.uuid}
      onChange={handleAmazonListingChange}
      popupMatchSelectWidth={false}
      placement="bottomRight"
    />
  )
}
