import {
  AmazonPpcCampaignType,
  Marketplace,
  SponsoredBrandsCampaignAdFormat,
} from '../../../generated/graphql'
import { AggregatedCampaign } from '../../../views/ppc/helpers/interfaces'

export function getAcos(rows: any[]): number {
  const spendSum = rows.reduce((prev, curr) => prev + curr.spend, 0)
  const salesSum = rows.reduce((prev, curr) => prev + curr.sales, 0)
  const avg = (spendSum / salesSum) * 100
  return avg ? avg : 0
}

export function getTotalAcos(rows: AggregatedCampaign[]): number {
  return rows.reduce((prev, curr) => prev + curr.acos, 0)
}

export function getAverageAcos(rows: AggregatedCampaign[]): number {
  const totalAcos = getTotalAcos(rows)
  return totalAcos / rows.length
}

export function getTotalCostPerClick(rows: AggregatedCampaign[]): number {
  return rows.reduce((prev, curr) => prev + curr.costPerClick, 0)
}

export function getAverageCostPerClick(rows: AggregatedCampaign[]): number {
  const totalCostPerClick = getTotalCostPerClick(rows)
  return totalCostPerClick / rows.length
}

export function getTotalSales(rows: AggregatedCampaign[]): number {
  return rows.reduce((prev, curr) => prev + curr.sales, 0)
}

export function getAverageSales(rows: AggregatedCampaign[]) {
  const totalSales = getTotalSales(rows)
  return totalSales / rows.length
}

export function getTotalBudget(rows: AggregatedCampaign[]): number {
  return rows.reduce(
    (prev, curr) => prev + (curr.dailyBudget ? curr.dailyBudget : curr.budget ? curr.budget : 0),
    0
  )
}

export function getAverageBudget(rows: AggregatedCampaign[]): number {
  const totalBudget = getTotalBudget(rows)
  return totalBudget / rows.length
}

export function getTotalImpressions(rows: AggregatedCampaign[]): number {
  return rows.reduce((prev, curr) => prev + curr.impressions, 0)
}

export function getAverageImpressions(rows: AggregatedCampaign[]): number {
  const totalImpressions = getTotalImpressions(rows)
  return totalImpressions / rows.length
}

export function getTotalSpend(rows: AggregatedCampaign[]): number {
  return rows.reduce((prev, curr) => prev + curr.spend, 0)
}

export function getAverageSpend(rows: AggregatedCampaign[]): number {
  const totalSpend = getTotalSpend(rows)
  return totalSpend / rows.length
}

export function getTotalClicks(rows: AggregatedCampaign[]): number {
  return rows.reduce((prev, curr) => prev + curr.clicks, 0)
}

export function getAverageClicks(rows: AggregatedCampaign[]): number {
  const totalClicks = getTotalClicks(rows)
  return totalClicks / rows.length
}

export const getReadableTargetingExpressionTypePredicate = (expressionTypePredicate: string) => {
  switch (expressionTypePredicate) {
    case 'queryBroadRelMatches':
      return 'Loose match'
    case 'asinSubstituteRelated':
      return 'Substitutes'
    case 'asinAccessoryRelated':
      return 'Complements'
    case 'queryHighRelMatches':
      return 'Close match'
    case 'asinSameAs':
      return 'asin='
    case 'asinCategorySameAs':
      return 'category='
    default:
      return expressionTypePredicate + ': '
  }
}

export function getMinimumBid(
  marketplace: Marketplace,
  campaignType: AmazonPpcCampaignType,
  adFormat?: SponsoredBrandsCampaignAdFormat | undefined
): number {
  return getMinimumAndMaximumBid(marketplace, campaignType, adFormat)[0]
}

export function getMaximumBid(
  marketplace: Marketplace,
  campaignType: AmazonPpcCampaignType,
  adFormat?: SponsoredBrandsCampaignAdFormat | undefined
): number {
  return getMinimumAndMaximumBid(marketplace, campaignType, adFormat)[1]
}

function getMinimumAndMaximumBid(
  marketplace: Marketplace,
  campaignType: AmazonPpcCampaignType,
  adFormat?: SponsoredBrandsCampaignAdFormat | undefined
): [number, number] {
  switch (marketplace) {
    case Marketplace.DE:
      switch (campaignType) {
        case AmazonPpcCampaignType.SPONSOREDPRODUCTS:
          return [0.02, 1000]
        case AmazonPpcCampaignType.SPONSOREDBRANDS:
          if (adFormat === SponsoredBrandsCampaignAdFormat.VIDEO) {
            return [0.15, 39]
          } else {
            return [0.1, 39]
          }
        case AmazonPpcCampaignType.SPONSOREDDISPLAY:
          return [1, 1000]
      }
    case Marketplace.FR:
    case Marketplace.ES:
    case Marketplace.IT:
    case Marketplace.NL:
      switch (campaignType) {
        case AmazonPpcCampaignType.SPONSOREDPRODUCTS:
          return [0.02, 1000]
        case AmazonPpcCampaignType.SPONSOREDBRANDS:
          return [0.1, 39]
        case AmazonPpcCampaignType.SPONSOREDDISPLAY:
          return [1, 1000]
      }
    case Marketplace.UK:
      switch (campaignType) {
        case AmazonPpcCampaignType.SPONSOREDPRODUCTS:
          return [0.02, 1000]
        case AmazonPpcCampaignType.SPONSOREDBRANDS:
          if (adFormat === SponsoredBrandsCampaignAdFormat.VIDEO) {
            return [0.15, 31]
          } else {
            return [0.1, 31]
          }
        case AmazonPpcCampaignType.SPONSOREDDISPLAY:
          return [1, 1000]
      }
    case Marketplace.SE:
      switch (campaignType) {
        case AmazonPpcCampaignType.SPONSOREDPRODUCTS:
          return [0.18, 9300]
        case AmazonPpcCampaignType.SPONSOREDBRANDS:
          return [0.9, 500]
        default:
          throw new Error(`Invalid combination: ${marketplace} ${campaignType}`)
      }
    case Marketplace.PL:
      switch (campaignType) {
        case AmazonPpcCampaignType.SPONSOREDPRODUCTS:
          return [2, 2000000]
        case AmazonPpcCampaignType.SPONSOREDBRANDS:
          if (adFormat === SponsoredBrandsCampaignAdFormat.VIDEO) {
            return [0.3, 200]
          } else {
            return [0.2, 200]
          }
        default:
          throw new Error(`Invalid combination: ${marketplace} ${campaignType}`)
      }
    case Marketplace.US:
    case Marketplace.CA:
    case Marketplace.MX:
      switch (campaignType) {
        case AmazonPpcCampaignType.SPONSOREDPRODUCTS:
          return [0.01, 1000]
        default:
          throw new Error(`Invalid combination: ${marketplace} ${campaignType}`)
      }
  }
}
