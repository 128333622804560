import { ColDef, ColGroupDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { Typography } from 'antd'
import { Suspense, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../../../components/Table/Table'
import { formatMoney } from '../../../../helpers/formatMoney'
import { excelDateToFormattedDateString } from '../../../../helpers/excelDateToFormattedDateString'
import dayjs from 'dayjs'

export type PaymentsRemissionTableRecord = {
  name: string
  asin: string
  sku: string
  date: string
  type: string
  disposition: string
  removalFee: number
  requestedQty: number
  shippedQty: number
  disposedQty: number
  cancelledQty: number
}

type PaymentsRemissionTableProps = {
  remissions: PaymentsRemissionTableRecord[]
}

export const PaymentsRemissionTable = ({ remissions }: PaymentsRemissionTableProps) => {
  const rowData = remissions

  const { t } = useTranslation(['billing'], { keyPrefix: 'payments.details.remissions' })

  const [columnDefs] = useState<
    (ColDef<PaymentsRemissionTableRecord> | ColGroupDef<PaymentsRemissionTableRecord>)[]
  >([
    {
      field: 'name',
      headerName: t('table.name'),
    },
    {
      field: 'asin',
      headerName: t('table.asin'),
    },
    {
      field: 'sku',
      headerName: t('table.sku'),
    },
    {
      field: 'date',
      headerName: t('table.date'),
      initialSort: 'desc',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          const dateAsString = dayjs(cellValue, 'DD-MM-YYYY')
          if (dateAsString == null) {
            return 0
          }
          if (dateAsString < dayjs(filterLocalDateAtMidnight)) {
            return -1
          } else if (dateAsString > dayjs(filterLocalDateAtMidnight)) {
            return 1
          }
          return 0
        },
      },
      valueGetter: (
        params: ValueGetterParams<
          PaymentsRemissionTableRecord,
          PaymentsRemissionTableRecord['date']
        >
      ) => {
        return excelDateToFormattedDateString(params.data?.date)
      },
    },
    {
      field: 'type',
      headerName: t('table.type'),
      filter: 'agSetColumnFilter',
    },
    {
      field: 'disposition',
      headerName: t('table.disposition'),
      filter: 'agSetColumnFilter',
    },
    {
      field: 'removalFee',
      headerName: t('table.fee'),
      cellRenderer: (
        params: ICellRendererParams<
          PaymentsRemissionTableRecord,
          PaymentsRemissionTableRecord['removalFee']
        >
      ) => {
        return params.value !== null && params.value !== undefined
          ? formatMoney(params.value, 'EUR')
          : t('shared.notAvailable')
      },
    },
    {
      field: 'requestedQty',
      headerName: t('table.requestedQuantity'),
    },
    {
      field: 'shippedQty',
      headerName: t('table.shippedQuantity'),
    },
    {
      field: 'disposedQty',
      headerName: t('table.disposedQuantity'),
    },
    {
      field: 'cancelledQty',
      headerName: t('table.cancelledQuantity'),
    },
  ])

  const defaultColDef: ColDef<PaymentsRemissionTableRecord> = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<PaymentsRemissionTableRecord>) => {
        if (params.value === null || params.value === undefined || params.value === '') {
          return t('shared.notAvailable', { ns: 'translation' })
        } else {
          return params.value
        }
      },
    }),
    []
  )

  return (
    <Suspense>
      {!rowData?.length ? (
        <Typography.Text type="secondary">{t('empty')}</Typography.Text>
      ) : (
        <Table<PaymentsRemissionTableRecord>
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={rowData}
          suppressContextMenu
        />
      )}
    </Suspense>
  )
}
