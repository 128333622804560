import { Descriptions, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { StyledMainCard } from '../../../../dashboard/styles'
import { descriptionsStyle } from '../../../../products/components/styles'
import { WholesaleOrderBillingDocument } from './WholesaleOrderBillingDocumentDetailsView'

type WholesaleOrderBillingDocumentCompanyDetailsCardProps = {
  wholesaleOrderBillingDocument: WholesaleOrderBillingDocument
}

export const WholesaleOrderBillingDocumentCompanyDetailsCard = ({
  wholesaleOrderBillingDocument,
}: WholesaleOrderBillingDocumentCompanyDetailsCardProps) => {
  const { t } = useTranslation('wholesale-order-billing-document', {
    keyPrefix: 'wholesaleOrderBillingDocumentCompanyDetailsCard',
  })

  return (
    <Space direction={'vertical'} size={'middle'}>
      <StyledMainCard title={t('creditorCompanyDetails.title')}>
        <Descriptions {...descriptionsStyle}>
          <Descriptions.Item label={t('creditorCompanyDetails.name.label')}>
            {wholesaleOrderBillingDocument.creditorCompany.name}
          </Descriptions.Item>
          <Descriptions.Item label={t('creditorCompanyDetails.sellerId.label')}>
            {wholesaleOrderBillingDocument.creditorCompany.sellerId}
          </Descriptions.Item>
        </Descriptions>
      </StyledMainCard>
      <StyledMainCard title={t('debtorCompanyDetails.title')}>
        <Descriptions {...descriptionsStyle}>
          <Descriptions.Item label={t('debtorCompanyDetails.name.label')}>
            {wholesaleOrderBillingDocument.debtorCompany.name}
          </Descriptions.Item>
          <Descriptions.Item label={t('debtorCompanyDetails.sellerId.label')}>
            {wholesaleOrderBillingDocument.debtorCompany.sellerId}
          </Descriptions.Item>
        </Descriptions>
      </StyledMainCard>
    </Space>
  )
}
