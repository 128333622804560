import { Flex, Tooltip, Typography } from 'antd'
import { formatMoney } from '../../../../helpers/formatMoney'

type DashboardFinanceSummarySectionProps = {
  label: string
  tooltip?: string
  value: number
  decimal?: boolean
}

export const DashboardFinanceSummarySection = ({
  label,
  tooltip,
  value,
  decimal,
}: DashboardFinanceSummarySectionProps) => (
  <Flex justify="space-between" gap={8}>
    <Tooltip title={tooltip}>
      <Typography.Text>{label}</Typography.Text>
    </Tooltip>
    <Typography.Text ellipsis>{`${
      decimal ? formatMoney(value, 'EUR') : value.toString()
    }`}</Typography.Text>
  </Flex>
)
