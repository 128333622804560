import { Table } from '../../../../../components/Table/Table'
import {
  GetAllWholesaleOrderBillingDocumentsQuery,
  WholesaleOrderBillingDocumentType,
} from '../../../../../generated/graphql'
import { TablePersistKey } from '../../../../../stores/useTableStore'
import { useWholesaleOrderBillingDocumentColumnDefs } from './hooks/useWholesaleOrderBillingDocumentColumnDefs'

export type WholesaleOrderBillingDocumentTableRecord = {
  identifier: string
  documentNumber: string
  creditorCompany: string
  debtorCompany: string
  type: WholesaleOrderBillingDocumentType
  issueDate: string
  lineItems: GetAllWholesaleOrderBillingDocumentsQuery['wholesaleOrderBillingDocuments'][number]['lineItems']
}

type WholesaleOrderBillingDocumentTableProps = {
  rowData: WholesaleOrderBillingDocumentTableRecord[]
  loading: boolean
}

export const WholesaleOrderBillingDocumentTable = ({
  rowData,
  loading,
}: WholesaleOrderBillingDocumentTableProps) => {
  const columnDefs = useWholesaleOrderBillingDocumentColumnDefs()

  return (
    <Table<WholesaleOrderBillingDocumentTableRecord>
      columnDefs={columnDefs}
      loading={loading}
      rowData={rowData}
      persist={{
        key: TablePersistKey.WHOLESALE_ORDER_BILLING_DOCUMENT,
        storage: localStorage,
      }}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
      storeSearchInSearchParams
    />
  )
}
