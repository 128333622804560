import { Flex, Space } from 'antd'
import { Dayjs } from 'dayjs'
import { Marketplace } from '../../../../generated/graphql'
import { DashboardFinanceHeaderDatePicker } from './DashboardFinanceHeaderDatePicker'
import { DashboardFinanceHeaderFilter } from './DashboardFinanceHeaderFilter'

type DashboardFinanceHeaderProps = {
  filter: {
    dateRange: [Dayjs, Dayjs]
    setDateRange: React.Dispatch<React.SetStateAction<[Dayjs, Dayjs]>>
    selectedMarketplaces: Marketplace[]
    setSelectedMarketplaces: React.Dispatch<React.SetStateAction<Marketplace[]>>
  }
}

export const DashboardFinanceHeader = ({ filter }: DashboardFinanceHeaderProps) => {
  return (
    <Flex justify="end">
      <Space>
        <DashboardFinanceHeaderDatePicker
          dateRange={filter.dateRange}
          setDateRange={filter.setDateRange}
        />
        <DashboardFinanceHeaderFilter
          selectedMarketplaces={filter.selectedMarketplaces}
          setSelectedMarketplaces={filter.setSelectedMarketplaces}
        />
      </Space>
    </Flex>
  )
}
