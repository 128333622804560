import { Tag } from 'antd'
import { formatMoney } from '../../../../helpers/formatMoney'
import { getTagColor } from '../helpers'

type PaymentsDetailsLabelExtraProps = {
  value: number
}

export const PaymentsDetailsLabelExtra = ({ value }: PaymentsDetailsLabelExtraProps) => (
  <Tag color={getTagColor(value)} style={{ margin: 0 }}>
    {formatMoney(value, 'EUR')}
  </Tag>
)
