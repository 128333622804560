import { z } from 'zod'
import { version } from '../../package.json'

const envSchema = z.object({
  NODE_ENV: z.string().default('development'),
  VITE_APP_VERSION: z.string().default(version),
  VITE_API_ENDPOINT: z.string().default('http://localhost:3000'),
  VITE_WEBSOCKET_ENDPOINT: z.string().default('ws://localhost:3000'),

  // Cognito
  VITE_AWS_APP_CLIENT_ID: z.string(),
  VITE_AWS_IDENTITY_POOL_ID: z.string(),
  VITE_AWS_REGION: z.string(),
  VITE_AWS_USER_POOL_ID: z.string(),

  // S3
  VITE_AWS_S3_BUCKET_NAME: z.string(),
  VITE_AWS_S3_BUCKET_NAME_AMAZON_INBOUND_SHIPMENTS: z.string(),
  VITE_AWS_S3_BUCKET_NAME_AMAZON_LISTING_DOCUMENTS: z.string(),
  VITE_AWS_S3_BUCKET_NAME_BRANDS: z.string(),
  VITE_AWS_S3_BUCKET_NAME_PRODUCT_DOCUMENTS: z.string(),
  VITE_AWS_S3_BUCKET_NAME_TEMPLATES: z.string(),
  VITE_AWS_S3_BUCKET_NAME_WHOLESALE_ORDERS: z.string(),
  VITE_AWS_S3_BUCKET_NAME_COMPANY_CONTRACTS: z.string(),
  VITE_AWS_S3_BUCKET_NAME_SHIPMENT_PLAN_DOCUMENTS: z.string(),

  // Misc
  VITE_AG_GRID_LICENCE_KEY: z.string(),
  VITE_CONFIG_CAT_API_KEY: z.string(),
  VITE_MAGICBELL_API_KEY: z.string(),
  VITE_MAPBOX_ACCESS_TOKEN: z.string(),
  VITE_MIXPANEL_TOKEN: z.string(),
  VITE_SENTRY_DSN: z.string(),
})

try {
  envSchema.parse(import.meta.env)
} catch (error) {
  if (error instanceof z.ZodError) {
    const { fieldErrors } = error.flatten()

    const errorMessage = Object.entries(fieldErrors)
      .map(([field, errors]) => (errors ? `${field}: ${errors.join(', ')}` : field))
      .join('\n- ')

    console.error(`Missing environment variables:\n- ${errorMessage}`)
  }
}

declare global {
  interface ImportMetaEnv extends z.infer<typeof envSchema> {}

  interface ImportMeta {
    readonly env: ImportMetaEnv
  }
}
