import { useMutation } from '@apollo/client'
import { Button, Form, Input, Row, Switch, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'sonner'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import QuillTextarea from '../../../components/Ticket/QuillTextarea'
import { UploadZendeskFile } from '../../../components/Ticket/UploadFile'
import { SendSupportTicketDocument } from '../../../generated/graphql'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { PersonalNoteInput } from '../components/form/PersonalNoteInput'
import { SkuWithQuantityDynamicInput } from '../components/form/SkuWithQuantityDynamicInput'
import { createZendeskTicketOptions } from '../helpers/Zendesk.helper'
import { TicketCreationCard } from '../styles/TicketCreateViewStyles'
import { TicketCategory, TicketMainCategory } from '../ticket.enum'
import { AttachmentProps } from '../ticket.interface'

type FormInstance = {
  skuWithQuantity: SkuWithQuantityItem[]
  phoneNumber: string
  address: string
  messageInput?: string
  confirmation_switch: boolean
  personalNotes: string
}

type SkuWithQuantityItem = {
  sku: string
  quantity: string
}

export const ReturnOrderTicketView = () => {
  const [confirmationSwitchValue, setConfirmationSwitchValue] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<AttachmentProps[]>([])

  const location = useLocation()

  const cognitoUser = useGlobalStore((state) => state.cognitoUser)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)

  const navigate = useNavigate()

  const { t, ready, i18n } = useTranslation(['ticket'], {
    keyPrefix: 'inventory.returnOrderTicketView',
  })
  const { t: generalTranslation } = useTranslation(['ticket'], { keyPrefix: 'ticketCreateView' })

  const [form] = Form.useForm()

  const [sendSupportTicketRequest, { loading }] = useMutation(SendSupportTicketDocument)

  if (!ready) {
    return <LoadingSpinner />
  }

  const initialValues: FormInstance = {
    skuWithQuantity: [{ sku: '', quantity: '' }],
    phoneNumber: '',
    address: '',
    messageInput: '',
    confirmation_switch: false,
    personalNotes: '',
  }

  const onFinish = async (values: FormInstance) => {
    const tokens = attachments.map((attachment) => {
      return attachment.token
    })

    const createSkuListSection = (skus: SkuWithQuantityItem[]) => {
      return skus.map((sku) => {
        return `
          <li><b>SKU:</b> ${sku.sku} - <b>${t('form.sku.quantityInput.placeholder')}:</b> ${
            sku.quantity
          }</li>
        `
      })
    }

    const createCommentSection = (messageInput: string | undefined) => {
      if (messageInput) {
        return `
          <h3>${t('form.comments.label')}</h3>
          <p>${messageInput}</p>
        `
      } else {
        return ''
      }
    }

    const message = `
      <h1>${t('ticketDescriptionTitle')}</h1>
      <h3>${t('form.address.label')}</h3>
      ${values.address}
      <h3>${t('form.phoneNumber.label')}</h3>
      ${values.phoneNumber}
      <h3>${t('form.sku.label')}</h3>
      <ul>${createSkuListSection(values.skuWithQuantity).join('')}</ul>
      ${createCommentSection(values.messageInput)}
    `

    const options = createZendeskTicketOptions({
      categoryTitle: location.state.categoryTitleTag,
      comment: message,
      language: i18n.language,
      mainCategory: TicketMainCategory.INVENTORY,
      subject: t('ticketDescriptionTitle'),
      ticketCategory: TicketCategory.INVENTORY_REQUEST_RETURN_ORDER,
      sellerId: selectedCompany?.sellerId,
      personalNotes: values.personalNotes,
      attachments: tokens,
    })

    try {
      if (selectedCompany !== null && selectedCompany.uuid) {
        await sendSupportTicketRequest({
          variables: {
            companyUuid: selectedCompany.uuid,
            input: options,
            cognitoUsername: cognitoUser!.getUsername(),
          },
        })
        toast.success(generalTranslation('notification.success'))
        form.resetFields()
        navigate('/tickets')
      }
    } catch (error: any) {
      console.error(error)
      toast.error(generalTranslation('notification.error'), { description: error.message })
    }
  }

  return (
    <>
      <SubHeader withBackButton />
      <ViewContainer centered>
        <TicketCreationCard title={t('ticketDescriptionTitle')}>
          <Typography.Paragraph>{t('ticketDescriptionSubtitle')}</Typography.Paragraph>
          <Form<FormInstance>
            name="return-order-ticket"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            layout="vertical"
          >
            <SkuWithQuantityDynamicInput
              translation={useTranslation(['ticket'], {
                keyPrefix: 'inventory.returnOrderTicketView.form.sku',
              })}
            />
            <Form.Item
              name="address"
              label={t('form.address.label')}
              rules={[{ required: true, message: t('form.address.validation.required') }]}
            >
              <QuillTextarea placeholder={t('form.address.placeholder')} />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={t('form.phoneNumber.label')}
              rules={[{ required: true, message: t('form.phoneNumber.validation.required') }]}
            >
              <Input placeholder={t('form.phoneNumber.placeholder')} />
            </Form.Item>
            <Form.Item name="messageInput" label={t('form.comments.label')}>
              <QuillTextarea placeholder={t('form.comments.placeholder')} />
            </Form.Item>
            <PersonalNoteInput />
            <UploadZendeskFile attachments={attachments} setAttachments={setAttachments} />
            <Form.Item
              name="confirmation_switch"
              label={t('confirmationText')}
              valuePropName="checked"
            >
              <Switch onChange={setConfirmationSwitchValue} />
            </Form.Item>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!confirmationSwitchValue}
                loading={loading}
              >
                {t('sendButtonText')}
              </Button>
            </Row>
          </Form>
        </TicketCreationCard>
      </ViewContainer>
    </>
  )
}
