import { useTheme } from '@emotion/react'
import { Col, Flex, Row, Space, Statistic, Tooltip, Typography } from 'antd'
import { ArrowDown, ArrowUp, CircleHelp } from 'lucide-react'
import { Area, AreaChart, Tooltip as ChartTooltip, ResponsiveContainer } from 'recharts'
import { formatMoney } from '../../../../../helpers/formatMoney'
import { formatPercentage } from '../../../../../helpers/formatPercentage'
import { StyledSkeleton, StyledSubCard } from '../../../styles'
import { AmazonAdsMetricTooltip } from './AmazonAdsMetricTooltip'

export type DashboardSubCardAmazonAdsData = {
  total: number
  previousTotal: number
  values: {
    date: string
    value: number
  }[]
}

type DashboardSubCardAmazonAdsProps = {
  title: string
  tooltip: string
  type: 'quantity' | 'money' | 'percentage'
  trendPreference: 'up' | 'down'
  data: DashboardSubCardAmazonAdsData
  loading: boolean
}

export const DashboardSubCardAmazonAds = ({
  title,
  tooltip,
  type,
  trendPreference,
  data,
  loading,
}: DashboardSubCardAmazonAdsProps) => {
  const theme = useTheme()

  const value =
    type === 'money'
      ? formatMoney(data.total, 'EUR')
      : type === 'percentage'
        ? formatPercentage(data.total)
        : data.total
  const delta = data.total && data.previousTotal ? data.total / data.previousTotal : null
  const trend = delta ? (delta > 1 ? 'up' : delta < 1 ? 'down' : 'flat') : 'flat'
  const color =
    trend === 'up'
      ? trendPreference === 'up'
        ? theme.colors.success
        : theme.colors.error
      : trend === 'down'
        ? trendPreference === 'up'
          ? theme.colors.error
          : theme.colors.success
        : `${theme.colors.text}80`

  return (
    <StyledSubCard>
      <StyledSkeleton loading={loading} paragraph={{ rows: 1 }}>
        <Row gutter={8}>
          <Col xs={24}>
            <Statistic
              title={
                <Flex align="center" gap={8}>
                  <Typography.Text>{title}</Typography.Text>
                  <Tooltip title={tooltip}>
                    <CircleHelp size={16} />
                  </Tooltip>
                </Flex>
              }
              value={value}
              suffix={
                <Space style={{ marginLeft: '0.5rem' }}>
                  {trend === 'up' && <ArrowUp size={16} color={color} />}
                  {trend === 'down' && <ArrowDown size={16} color={color} />}
                  {delta && (
                    <Typography.Text style={{ fontSize: '1rem' }}>
                      {formatPercentage(delta - 1)}
                    </Typography.Text>
                  )}
                </Space>
              }
            />
          </Col>
          <Col xs={24}>
            <ResponsiveContainer width="100%" height={96}>
              <AreaChart data={data.values} margin={{ top: 8, right: 0, bottom: 0, left: 0 }}>
                <ChartTooltip
                  content={<AmazonAdsMetricTooltip name={title} type={type} />}
                  cursor={false}
                  wrapperStyle={{ outline: 'none' }}
                />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke={color}
                  strokeWidth={2}
                  fill={color}
                  fillOpacity={0.2}
                />
              </AreaChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </StyledSkeleton>
    </StyledSubCard>
  )
}
