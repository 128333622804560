import { ValueGetterParams } from 'ag-grid-community'
import { BankTransaction } from '../../BankTransactionsView'
import {
  BankTransactionCategories,
  BankTransactionEntities,
  BankTransactionPaymentStatuses,
} from '../hooks/useBankTransactionTableColumns'

export const bankTransactionTableValueGetters = {
  amount: (params: ValueGetterParams<BankTransaction>) => {
    if (params.data && params.data.amount) {
      return Number(params.data.amount)
    } else {
      return null
    }
  },
  sender: (params: ValueGetterParams<BankTransaction>, t: any) => {
    if (params.data && params.data.sender) {
      if (params.data.sender === BankTransactionEntities.CUSTOMER) {
        return t('senderOrRecipient.customer')
      } else {
        return t('senderOrRecipient.spacegoats')
      }
    }
  },
  recipient: (params: ValueGetterParams<BankTransaction>, t: any) => {
    if (params.data && params.data.recipient) {
      if (params.data.recipient === BankTransactionEntities.CUSTOMER) {
        return t('senderOrRecipient.customer')
      } else {
        return t('senderOrRecipient.spacegoats')
      }
    }
  },
  category: (params: ValueGetterParams<BankTransaction>, t: any) => {
    if (params.data && params.data.category) {
      switch (params.data.category) {
        case BankTransactionCategories.NONE:
          return t('category.none')
        case BankTransactionCategories.SETTLEMENT:
          return t('category.settlement')
        case BankTransactionCategories.SETTLEMENT_TRANSACTION:
          return t('category.settlementTransaction')
      }
    }
  },
  paymentStatus: (params: ValueGetterParams<BankTransaction>, t: any) => {
    if (params.data && params.data.paymentStatus) {
      switch (params.data.paymentStatus) {
        case BankTransactionPaymentStatuses.OPEN:
          return t('paymentStatus.open')
        case BankTransactionPaymentStatuses.PART2:
          return t('paymentStatus.openPartTwo')
        case BankTransactionPaymentStatuses.PAID:
          return t('paymentStatus.paid')
        case BankTransactionPaymentStatuses.CLARIFICATION:
          return t('paymentStatus.inClarification')
        case BankTransactionPaymentStatuses.CANCELLED:
          return t('paymentStatus.cancelled')
      }
    }
  },
}
