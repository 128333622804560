import { useTheme } from '@emotion/react'
import { Divider, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { TooltipProps } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { formatMoney } from '../../../../helpers/formatMoney'
import { StyledMainCard } from '../../styles'
import { DashboardFinanceChartData } from './DashboardFinanceChart'

export const DashboardFinanceChartTooltip = (props: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation('dashboard')
  const theme = useTheme()

  const payload: DashboardFinanceChartData | undefined = props.payload?.[0]?.payload

  if (!payload) {
    return null
  }

  return (
    <StyledMainCard
      title={payload.formattedDate}
      style={{
        width: '320px',
        opacity: '0.98',
        boxShadow: `0px 0px 8px 0px ${theme.colors.text}10`,
      }}
    >
      <Flex vertical gap={8}>
        <Flex justify="space-between" gap={8}>
          <Typography.Text ellipsis>{t('finances.shared.sales')}</Typography.Text>
          <Typography.Text>{formatMoney(payload.sales, 'EUR')}</Typography.Text>
        </Flex>
        <Flex justify="space-between" gap={8}>
          <Typography.Text ellipsis>{t('finances.shared.shipped')}</Typography.Text>
          <Typography.Text>{payload.unitsSold}</Typography.Text>
        </Flex>
        <Flex justify="space-between" gap={8}>
          <Typography.Text ellipsis>{t('finances.shared.refunds')}</Typography.Text>
          <Typography.Text>{formatMoney(payload.refunds, 'EUR')}</Typography.Text>
        </Flex>
        <Flex justify="space-between" gap={8}>
          <Typography.Text ellipsis>{t('finances.shared.fees')}</Typography.Text>
          <Typography.Text>{formatMoney(payload.fees, 'EUR')}</Typography.Text>
        </Flex>
        <Flex justify="space-between" gap={8}>
          <Typography.Text ellipsis>{t('finances.shared.promotions')}</Typography.Text>
          <Typography.Text>{formatMoney(payload.promotions, 'EUR')}</Typography.Text>
        </Flex>
        <Flex justify="space-between" gap={8}>
          <Typography.Text ellipsis>{t('finances.shared.sponsoredProducts')}</Typography.Text>
          <Typography.Text>{formatMoney(payload.sponsoredProductCosts, 'EUR')}</Typography.Text>
        </Flex>
        <Flex justify="space-between" gap={8}>
          <Typography.Text ellipsis>{t('finances.shared.sponsoredDisplay')}</Typography.Text>
          <Typography.Text>{formatMoney(payload.sponsoredDisplayCosts, 'EUR')}</Typography.Text>
        </Flex>
        <Divider style={{ margin: 0 }} />
        <Flex justify="space-between" gap={8}>
          <Typography.Text strong ellipsis>
            {t('finances.shared.amazonProfit')}
          </Typography.Text>
          <Typography.Text strong>{formatMoney(payload.amazonGrossProfit, 'EUR')}</Typography.Text>
        </Flex>
      </Flex>
    </StyledMainCard>
  )
}
