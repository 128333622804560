import { Button } from 'antd'
import { PanelRightClose, PanelRightOpen } from 'lucide-react'
import { useLayoutStore } from '../../../stores/useLayoutStore'

export const SidebarToggle = () => {
  const sidebarCollapsed = useLayoutStore((state) => state.sidebarCollapsed)
  const setSidebarCollapsed = useLayoutStore((state) => state.setSidebarCollapsed)

  const handleToggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed)
  }

  return (
    <Button
      type="text"
      icon={sidebarCollapsed ? <PanelRightClose size={16} /> : <PanelRightOpen size={16} />}
      onClick={handleToggleSidebar}
    />
  )
}
