import { Flex, Typography } from 'antd'
import { GetProductVariantQuery } from '../../../../../generated/graphql'
import { EditInternalSkuModal } from './EditInternalSkuModal'

type ProductVariantInternalSkuFieldProps = {
  productVariant: GetProductVariantQuery['productVariant']
  isEditable?: boolean
}

export const ProductVariantInternalSkuField = ({
  productVariant,
  isEditable,
}: ProductVariantInternalSkuFieldProps) => {
  return (
    <Flex justify="space-between">
      <Typography.Text>{productVariant.internalSku ?? '-'}</Typography.Text>
      {isEditable && (
        <EditInternalSkuModal uuid={productVariant.uuid} internalSku={productVariant.internalSku} />
      )}
    </Flex>
  )
}
