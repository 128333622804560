import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { Auth } from 'aws-amplify'
import { Info, Lock } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'sonner'
import PasswordPolicyPopover from './components/PasswordPolicyPopover'

type ForgottenPasswordStep2FormInstance = {
  resetCode: string
  password: string
}

export const ForgotPasswordStep2View = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation('login', { keyPrefix: 'forgotPassword.step2' })
  const navigate = useNavigate()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')

  if (!email) {
    navigate('/login/forgotten-password/step-1')
  }

  const handleSubmit = async (values: ForgottenPasswordStep2FormInstance) => {
    setLoading(true)
    try {
      await Auth.forgotPasswordSubmit(email!, values.resetCode, values.password)
      toast.success('Password successfully reset.')
      setTimeout(() => navigate('/login'), 200)
    } catch (error) {
      toast.error(t('form.error.message'), { description: t('form.error.description') })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Row justify="center">
        <Col flex="0 1 480px">
          <Typography.Title level={3}>{t('heading')}</Typography.Title>
          <Typography.Paragraph>{t('description', { email })}</Typography.Paragraph>
          <Typography.Paragraph>
            <PasswordPolicyPopover />
          </Typography.Paragraph>
          <Form<ForgottenPasswordStep2FormInstance>
            name="forgotten-password-step-2"
            onFinish={handleSubmit}
            size="large"
          >
            <Form.Item
              name="resetCode"
              rules={[
                {
                  pattern: new RegExp('^[0-9]*$'),
                  message: t('form.resetCode.validation.pattern'),
                },
                { required: true, message: t('form.resetCode.validation.required') },
              ]}
            >
              <Input
                aria-label="reset-code-input"
                autoFocus
                prefix={<Info size={16} />}
                placeholder={t('form.resetCode.placeholder')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: t('form.password.validation.required') }]}
            >
              <Input.Password
                aria-label="password-input"
                prefix={<Lock size={16} />}
                placeholder={t('form.password.placeholder')}
              />
            </Form.Item>
            <Form.Item>
              <Row justify="end">
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    aria-label="submit-button"
                    loading={loading}
                  >
                    {t('form.submit')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}
